export default function formapidoctors({
  doctorname,
    chiefcomplaint,
    appointmentdate,
    countofunreadmessages,
    doctorid,
    appointmenttime,
    profilepictureurl,
    imagepath,
    specality
    
  }) {
   
  
    return Object.freeze({
      doctorname,
      chiefcomplaint,
      appointmentdate,
      countofunreadmessages,
      doctorid,
      appointmenttime,
      profilepictureurl,
      imagepath,
      specality
    });
  }
  