import  FetchData, { } from '../../client';
import {getLoginInfo, getUserID}  from '../../../auth';


const VerifyUser = async (inputData) => {
  try {
   // localStorage.clear();
    //console.log(inputData,"verify email input");
    
    const result = await FetchData("midoc_verifyuser", inputData);
    //console.log(result,"verify email response");
    
    //var finalOutput = appointmentsOutput(result)

    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

const FetchLogin = async (inputData) => {
  try {
    //console.log(inputData,"fetch email input");
    const result = await FetchData("midoc_patientsignin", inputData);
    console.log(result,"fetch login response");
    
    //var finalOutput = appointmentsOutput(result)

    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchSocailLogin = async (inputData) => {
  try {
    //console.log(inputData,"fetch email input");
    const result = await FetchData("midoc_patientsignin", inputData);
    console.log(result,"fetch login response");
    
    //var finalOutput = appointmentsOutput(result)

    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchMobileLogin = async (inputData) => {
  try {
    //console.log(inputData,"fetch mobile input");
    const result = await FetchData("midoc_patientmobilesignin", inputData);
    //console.log(result,"fetch mobile login response");
    
    //var finalOutput = appointmentsOutput(result)

    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

  // password
  const ForgotPassword = async (inputData) => {
    try {
  
      const result = await FetchData("midoc_patientforgotpassword", inputData);
      //console.log(result,"ForgotPassword");
      
      //var finalOutput = appointmentsOutput(result)
  
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const Logout = async (inputData) => {
    try {
      inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    // console.log(inputData,"logout");
      const result = await FetchData("midoc_patientlogout", inputData);
      //console.log(result,"logout response");
      
      //var finalOutput = appointmentsOutput(result)
  
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const SwitchUser = async (inputData) => {
    try {
      inputData.patientid = getLoginInfo();
    //inputData.activeuserid = getUserID();
      console.log(inputData,"switch");
      const result = await FetchData("midoc_switchuser", inputData);
      //console.log(result,"logout response");
      
      //var finalOutput = appointmentsOutput(result)
  
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const FetchGuestPatientID = async (inputData) => {
    try {
      //console.log(inputData,"fetch mobile input");
      const result = await FetchData("midoc_bookappointmentbyguestlogin", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
export {FetchLogin,FetchSocailLogin,VerifyUser, FetchMobileLogin, ForgotPassword, Logout, SwitchUser,FetchGuestPatientID};
