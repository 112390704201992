
import formapimodalAlerts from "./alert";



var formattedOutput = null;

const formAPIModelProfileAlerts = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data?.map(item => formapimodalAlerts(item));


  return formattedOutput
};

export default formAPIModelProfileAlerts