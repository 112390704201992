const COLORS = {
    PRIMARY_COLOR: "#2454C7",
    SECONDARY_COLOR: "#3B5CEF",
    PRIMARY_WHITE: "#FFFFFF",
    PRIMARY_GRADIENT: "radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%), #2454C7",
    INPUT_PRIMARY: "#CDCFD4",
    INPUT_FOCUS_DARK: "#384052",
    INPUT_ERROR: "#CA3A31",
    INPUT_SELECT: "#109E6A",
    INPUT_PLACEHOLDER: "#6A707D",
    BTN_DISABLE: "#EAEDF5",
    BTN_DISABLE_TEXT: "#A4B8E8",
    BTN_GRADIENT_DARK: "rgba(255, 255, 255, 0.07)",
    BOX_SHADOW_LIGHT: "0px 0px 0px 3px rgba(230, 231, 233, 0.30), 0px 1px 2px 0px rgba(193, 194, 198, 0.50), 0px 8px 12px 0px rgba(216, 216, 216, 0.20) inset",
    TOASTR_BG: "radial-gradient(100% 100% at 50% 0%, rgba(18, 18, 18, 0.77) 0%, rgba(18, 18, 18, 0.90) 100%)",
    SUCCESS_BG: "linear-gradient(0deg, rgba(153,192,188,1) 0%, rgba(240,248,247,1) 70%)",
    ERROR_BG: "linear-gradient(0deg, rgba(217,152,148,1) 0%, rgba(255,255,255,1) 100%)",
    TOASTR_ICON_SUCCESS: "linear-gradient(180deg, #68D1BF 0%, #35746E 100%)",
    TOASTR_ICON_BG_SUCCESS: "linear-gradient(0deg, rgba(153,192,188,1) 0%, rgba(240,248,247,1) 70%)",
    TOASTR_ICON_ERROR: "linear-gradient(0deg, rgba(186,81,74,1) 0%, rgba(228,117,113,1) 70%)",
    TOASTR_ICON_BG_ERROR: "linear-gradient(0deg, rgba(217,152,148,1) 0%, rgba(255,255,255,1) 100%)",
    PROGRESS_DARK: "#0C7650",
    PROGRESS_LIGHT: "#14C585",
    DROPDOWN: "1C4EC5",
    GOLD_BADGE: "linear-gradient(180deg, #E6B22B 0%, #EBCC66 0.01%, #DA0 100%)",
    GOLD_REWARD: "linear-gradient(0deg, rgba(252,206,53,0) 85%, rgba(252,206,53,1) 110%)",
    BRONZE_REWARD: "linear-gradient(0deg, rgba(225,134,53,0) 85%, rgba(225,134,53,1) 110%)",
    SILVER_REWARD: "linear-gradient(0deg, rgba(174,189,194,0) 85%, rgba(174,189,194,1) 110%)",
    PLATINUM_REWARD: "linear-gradient(0deg, rgba(138,163,226,0) 85%, rgba(138,163,226,1) 110%)",
    PRO_PLAN: "linear-gradient(0deg, rgba(67,209,157,0) 75%, rgba(67,209,157,1) 100%)",
    SIGNUP_SUCCESS: "linear-gradient(180deg, rgba(103, 202, 185, 0.6) 0%, rgba(252, 252, 253, 0) 85.36%)",
    BOX_SHADOW_SUCCESS: "0px -4.488888263702393px 8.977776527404785px 0px #FFFFFF80 inset; box-shadow: 0px 0px 0px 2.2444441318511963px #0000001A inset",
    FAILED: "linear-gradient(180deg, #FF7A71 0%, #FCFCFD 85.36%)",
    BLACK_FILTER: "invert(46%) sepia(3%) saturate(1652%) hue-rotate(183deg) brightness(93%) contrast(87%)",
    BLUE_FILTER: "brightness(0) saturate(100%) invert(23%) sepia(92%) saturate(2805%) hue-rotate(220deg) brightness(79%) contrast(93%)",
    GRAY_FILTER: "invert(61%) sepia(7%) saturate(474%) hue-rotate(183deg) brightness(89%) contrast(87%)",
}

export {COLORS}