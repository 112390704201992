import React, {useEffect,useRef, useState } from "react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import { Box,Text, Flex, Image, Divider, SimpleGrid } from "@chakra-ui/react";
import Footer from "../../components/footer"
import { PrimaryButton, SearchBar } from "../../components";
import DoctorCard from "../../components/doctor-card";
import FilterButton from "../../components/filter-button";
import GrayCard from "../../components/card";
import ModalRight from "../../components/modal-right";
import DateRangeIndex from "../../components/date-range";
import DatePicker from "../../components/date-picker";
import { Calendar } from "react-date-range";
import SlotBooking from "./slotbooking";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { FetchDoctorDetails, FetchDoctorSlots } from "../../middleware/services/appointments";
import moment from "moment";
import { addDays, addMonths, addYears, startOfDay } from 'date-fns';
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { useScreenType } from "../../auth";


const DoctorProfileIndex = () => {
  const [date, setDate] = React.useState(new Date());
  const location = useLocation();
  const { doctorid, fullname, followupdate,  isfreevisit, followupid } = location.state || {};
  const filterModal = useRef()
  var [timeslot, setTimeSlot] = useState();
  var [patientid, setPatientid] = useState();
  var [NavigateDoctorID, setNavigateDoctorID] = useState();
  var [DoctorId, setDoctorid] = useState();
  var [DoctorName, setDoctorName] = useState();
  var [activeuserid, setActiveUser] = useState();
  var [doctordata, setDoctorDetailsData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  var [DoctorSlots, setDoctorSlots] = useState();
  var [DoctorSlotsActive, setDoctorSlotsActive] = useState(false);
  var [MaxBookingDate, setMaxBookingDate] = useState("");
  const navigate = useNavigate()
  const screenType = useScreenType()
  useEffect(() => {
    const currentURL = window.location.href;
    const urlParams = new URLSearchParams(currentURL.split("?")[1]);
    const idValue = urlParams.get("id");
    const nameParam = urlParams.get("name");
    const dateParam = urlParams.get("date");
    const timeParam = urlParams.get("time");
    setDoctorid(idValue);
    setDoctorName(nameParam);
    if (dateParam) {
      const date = new Date(dateParam);
      setDate(date);
    }
    // if (dateParam) {
    //   console.log(dateParam)
    //   const formattedDate = moment(dateParam).format('ddd, D MMM');
    //   setDate(formattedDate);
    //   console.log(formattedDate)
    // }

    if (timeParam) {
      setTimeSlot(timeParam);
      
    }
  }, []);
  useEffect(() => {
    if(DoctorId){
      fetchDoctorDetails(DoctorId);
      setNavigateDoctorID(DoctorId)
      setDoctorSlotsActive(true);
    }else{
      fetchDoctorDetails(doctorid);
      setNavigateDoctorID(doctorid)

    }
    
  }, [DoctorId, doctorid]);
  useEffect(()=>{
    if(isfreevisit == 1){
     // alert(followupdate + "  followupdate")
if(followupdate){
   const formattedDateString = moment(followupdate).format('DD-MM-YYYY');
  // setDate(formattedDateString);
  // setDate(formattedDateString);

   const followupDate = moment(followupdate);
   const today = moment();

    // Check if followupdate is in the past
    if (followupDate.isBefore(today) || followupDate.isSame(today)) {
      // Set date to today's date if followupdate is in the past or today
      setDate(today.format('DD-MM-YYYY'));
  } else {
      // Otherwise, set to the formatted followup date
      setDate(followupDate.format('DD-MM-YYYY'));
  }

  getslots()
}
    }
  },[])
  const fetchDoctorDetails = async (id) => {
    showLoader();
    try {
      const result = await FetchDoctorDetails({
        patientid,
        activeuserid,
        doctorid: id,
      });
      if (result != null) {
        setDoctorDetailsData(result);
      } else {
        //alert("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
  const handleDateChange = (date) => {
    //const formattedDate = format(date, "ddd, D MMM");

    setDate(date);
  };
  
  const getslots = async () => {
    setDoctorSlotsActive(true);
  };
  const calculateMaxDate = (bookinupto,bookinuptovalue) => {

    const numberOfUnits = parseInt(bookinupto, 10);

    let maxDate;
    switch (bookinuptovalue) {
        case 'Day':
            maxDate = addDays(startOfDay(new Date()), numberOfUnits);
            break;
        case 'Month':
            maxDate = addMonths(startOfDay(new Date()), numberOfUnits);
            break;
        case 'Year':
            maxDate = addYears(startOfDay(new Date()), numberOfUnits);
            break;
        default:
          maxDate = addDays(startOfDay(new Date()), numberOfUnits);
    }
    return maxDate;
    };
// const navigatemap = (lat, long) => {
//     const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${long}&travelmode=driving`;
//     window.open(url, '_blank');
// }
const navigatemap = (address1 , address2, city, country) => {
  var address = address1 + address2 + city+country
  const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}&travelmode=driving`;
  window.open(url, '_blank');
};
    const goback = ()=>{
       window.history.back();
    }
  return (
    <>
    {screenType == 'web' ? (
      <Box w="100%">
      <Box top="0" zIndex="banner">
          <Navbar />
          {isLoading && <Loader />}
          <SecondaryNav
            withBack={DoctorName? DoctorName:fullname}
            goBack={goback}
            // withBackBody={<>
            //     <Box display="flex" gap="10px" alignItems="center" pr="25px">
            //      <Box
            //         as="button"
            //         bg="none"
            //         p="10px"
            //         border="1px solid #E6E7E9"
            //         borderRadius="10px"
            //         onClick={()=> filterModal.current.openRight()}
            //       >
            //         <Image w="18px" src="/assets/svgs/filter.svg" />                  
            //       </Box>
                 
            //      <Box
            //         as="button"
            //         bg="none"
            //         p="10px"
            //         border="1px solid #E6E7E9"
            //         borderRadius="10px"
            //       >
            //         <Image w="18px" src="/assets/imgs/search.png" />                  
            //       </Box>
            //     </Box>
            // </>}
          />
        </Box>
        <Box h="100%">
            {/* <Box p="1.25rem 2rem" bg="white" border="1px solid #E6E7E9">
                <SearchBar passInputPlace="Search" />
            </Box> */}
          <Flex h="calc(100vh - 140px)" flexDir="column" bg="#fcfcfd" p="1.25rem 2rem" overflow="hidden auto">
            {/* Main container start */}
            <Box w="100%" display="flex" gap="1rem">
                {/* Doctor profile start */}
                <Flex w="75%" flexDir="column" gap="1rem">
                    <Box w="100%">
                        <DoctorCard 
                        hasFooter={false}
                        // cardFooterText="Book appointment"
                        cardBody={<>
                            <Box>
                            <Box display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                                <Box display='flex' gap="0.75rem">
                                <Box boxSize="3.5rem" borderRadius='full' overflow="hidden">
                                    <Image w="100%" h='100%' src={doctordata && doctordata.doctorimage? doctordata.imagepath+doctordata.doctorimage:"/assets/imgs/no-image.png"} />
                                </Box>
                                <Box>
                                    <Text fontSize="0.625rem" className="textOverflow" w="577px" fontWeight="500" title={doctordata && doctordata.specality} color="#384052">{doctordata && doctordata.specality}</Text>
                                    <Text fontSize="0.875rem" fontWeight="600" color="#061027">{doctordata && doctordata.fullname}</Text>
                                    <Text fontSize="0.75rem" fontWeight="400" color="#384052">{doctordata && doctordata.qualification}</Text>
                                </Box>
                                </Box>
                            </Box>
                            <Box
                                bg="#eaedf5"
                                mt="15px"
                                borderRadius="full"
                                p="8px 16px"
                                display="flex"
                                justifyContent="space-around"
                            >
                                {/* <Box display="flex" alignItems="center" justifyContent="center">
                                <Image w="18px" src="/assets/svgs/star-blue.svg" mr="5px" />
                                <Text color="#384052" fontSize="14px" fontWeight="500">
                                {doctordata && doctordata.ratings? doctordata.ratings:"-"}
                                </Text>
                                </Box> */}
                                {/* <Box as="span" border="1px solid #D2DCF3"></Box> */}
                                <Box display="flex" alignItems="center" justifyContent="center">
                                <Image w="18px" src="/assets/svgs/pricing-blue.svg" mr="5px" />
                                <Text color="#384052" fontSize="14px" fontWeight="500">
                                    {doctordata && doctordata.fee? "$"+doctordata.fee:"-"}
                                </Text>
                                </Box>
                                <Box as="span" border="1px solid #D2DCF3"></Box>
                                <Box display="flex" alignItems="center" justifyContent="center">
                                <Image w="18px" src="/assets/svgs/work-blue-filled.svg" mr="5px" />
                                <Text color="#384052" fontSize="14px" fontWeight="500">
                                {doctordata && doctordata.yearsofexperience? doctordata.yearsofexperience+" +yrs":"-"}
                                </Text>
                                </Box>
                            </Box>
                            </Box>
                        </>}
                        />
                    </Box>

                    <Box w="100%">
                        <GrayCard
                          cardHeader="QUALIFICATION"
                          cardMinHeight="100px"
                          cardHeaderIcon="/assets/svgs/qualification.svg"
                          cardBody={
                            <>
                              <Text fontSize="0.875rem" fontWeight="600">
                              {doctordata && doctordata.qualification}
                              </Text>
                              {/* <Divider my="0.75rem" /> */}
                             
                            </>
                          }
                          hasFooter={false}
                        />
                    </Box>

                    <Box w="100%">
                        <GrayCard
                          cardHeader="services & procedures"
                          cardHeaderIcon="/assets/svgs/vaccines-and-dose.svg"
                          bodyStyle={{ py: "0" }}
                          cardBody = {
                            <>
                              <Box w="100%" display='flex'>
                                <Box w="100%">
                                  {doctordata && doctordata.services && doctordata.services.length > 0 ? (
                                    doctordata.services.map((step, index) => (
                                      <Box
                                        key={index}
                                        display="flex"
                                        flexDirection="column"
                                        flexWrap="wrap"
                                        maxHeight="7.5rem"
                                      >
                                        <Box
                                          display="flex"
                                          gap="0.75rem"
                                          alignItems="center"
                                          py="0.75rem"
                                          borderBottom="1px solid #E6E7E9"
                                        >
                                          <Image opacity="0.75" src="/assets/imgs/check-black.png" />
                                          <Text fontSize="0.875rem" fontWeight="600">
                                            {step.servicename}
                                          </Text>
                                        </Box>
                                      </Box>
                                    ))
                                  ) : (
                                    <Box>No data</Box>
                                  )}
                                </Box>
                                
                              </Box>
                            </>
                          }
                          hasFooter={false}
                          cardFooterText="View more"
                        />
                    </Box>

                    <Box w="100%">
                    <GrayCard
                          cardHeader="Hospital details"
                          cardHeaderIcon="/assets/svgs/hospital-gray.svg"
                          cardBody = {
                            <>
                              {doctordata && doctordata.addresses && doctordata.addresses.length > 0 ? (
                                doctordata.addresses.map((step, index) => (
                                  <React.Fragment key={index}>
                                    <Box display="flex" justifyContent="space-between">
                                      <Box>
                                        <Text fontSize="0.875rem" fontWeight="600">
                                          {step.hospitalname? step.hospitalname:"-"}
                                        </Text>
                                        <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                                          {/* {step.address? step.address:"-"} */}
                                          {step.address1 || step.address2 || step.city || step.state || step.pincode || step.country ? (
                                             <>
                                               {step.address1 ? `${step.address1} ` : ''}
                                               {step.address2 ? `${step.address2}, ` : ''}
                                               {step.city ? `${step.city} ` : ''}
                                               {step.state ? `${step.state} ` : ''}
                                               {step.pincode ? `${step.pincode} ` : ''}
                                               {step.country ? `${step.country} ` : ''}
                                             </>
                                           ) : '-' }

                                        </Text>
                                      </Box>
                          
                                      <Box display="flex" justifyContent="space-between" gap="0.5rem">
                                        <Box
                                          as="button"
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="center"
                                          p="0.5rem"
                                          boxSize="2.25rem"
                                          border="1px solid #E6E7E9"
                                          borderRadius="full"
                                        >
                                          <Image src="/assets/svgs/call-gray.svg" />
                                        </Box>
                                        <Box
                                          as="button"
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="center"
                                          p="0.5rem"
                                          boxSize="2.25rem"
                                          border="1px solid #E6E7E9"
                                          borderRadius="full"
                                          onClick={(e)=>navigatemap(step.address1, step.address2, step.city, step.country)}
                                        >
                                          <Image src="/assets/svgs/location-gray.svg" />
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Divider my="0.75rem" />
                                    <Box display="flex" gap="0.5rem">
                                      <Image src="/assets/svgs/telephone-blue.svg" />
                                      <Text fontSize="0.75rem" fontWeight="500">
                                      {doctordata.mobileno?doctordata.mobileno:"-"}
                                      </Text>
                                    </Box>
                                    <Box display="flex" gap="0.5rem"
                                     as="a" href={step.websiteurl?step.websiteurl:""} target="/"
                                     cursor="pointer" >
                                      <Image src="/assets/svgs/website-blue.svg" />
                                      <Text fontSize="0.75rem" fontWeight="500"
                                       _hover={{  textDecoration: "underline" }}>
                                        {step.websiteurl?step.websiteurl:"-"}
                                      </Text>
                                    </Box>
                                  </React.Fragment>
                                ))
                              ) : (
                                <Box>No data</Box>
                              )}
                            </>
                            }
                          
                          hasFooter={false}
                        />
                    </Box>
                    <Text
                        fontSize="0.75rem"
                        fontWeight="600"
                        color="#6A707D"
                    >
                        <strong>Refund Policy:</strong> In the rare event of
                        dissatisfaction with a consultation, please contact
                        our support team within 48 hours for a refund.
                    </Text>
                </Flex>
                {/* Doctor profile end */}

                {/* Book appoinments start */}
                <Box w="25%">
                    <Box w="100%" bg="white" border="1px solid #E6E7E9" borderRadius="0.75rem" boxShadow="0px 8px 20px 0px #E6E7E966">
                        <Box w="100%" p="1.5rem 2rem" borderBottom="1px solid #E6E7E9">
                            <Text fontSize={{sm: "1.25rem", xl: "1.5rem"}} fontWeight="600" color="#050D1F">Book appointment</Text>
                            <Text fontSize={{sm: "0.875rem", xl: "1rem"}} fontWeight="400" color="#050D1F"> {!DoctorSlotsActive ? "Select a preferred date":"Select a timeslot"}</Text>
                        </Box>

                        <Box  w="100%" display="flex" flexDir="column" justifyContent="center" alignItems="center" bg="white" p="1rem" pt="0">
                        {!DoctorSlotsActive &&
                            <Calendar
                                date={ date}
                                className="customCalendar"
                                onChange={handleDateChange}
                                showMonthAndYearPickers={false}
                                renderStaticRangeLabel={false}
                                minDate={startOfDay(new Date())}
                                maxDate={
                                  doctordata && doctordata.islimitfuturebooking == 1 ?
                                  calculateMaxDate(doctordata && doctordata.bookinupto, doctordata && doctordata.bookinuptovalue)
                                  :undefined
                                }
                            />
                        } 
                            {DoctorSlotsActive && <SlotBooking isfreevisit={isfreevisit} followupid={followupid} date={date} slot={timeslot} doctorid={NavigateDoctorID} bookingupto={doctordata && doctordata.bookinupto} bookinguptotype={doctordata && doctordata.bookinuptovalue}/>}
                            {!DoctorSlotsActive && <PrimaryButton onClick={getslots} buttonText="Continue" btnStyle={{mb: "0", mt: "2rem"}} />}
                        </Box>
                    </Box>
                </Box>
                {/* Book appoinments end */}
            </Box>

            {/* Main container end */}
          </Flex>
          <Footer />
        </Box>
      </Box>
    ) : (
      //Mobile responsive start
      <Box w="100%" bg="#fcfcfd">
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/appointments')}
          header={fullname}
        />

        <Box w="100%" p="1.25rem"  h='calc(100vh - 190px)' overflow="hidden auto">
          <Flex flexDir="column" gap="1rem">
            <DoctorCard 
                hasFooter={false}
                // cardFooterText="Book appointment"
                cardBody={<>
                    <Box>
                    <Box display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                        <Box display='flex' gap="0.75rem">
                        <Box boxSize="3.5rem" borderRadius='full' overflow="hidden">
                            <Image w="100%" h='100%' src={doctordata && doctordata.doctorimage? doctordata.imagepath+doctordata.doctorimage:"/assets/imgs/no-image.png"} />
                        </Box>
                        <Box>
                            <Text fontSize="0.625rem" className="textOverflow" w="200px" fontWeight="500" title={doctordata && doctordata.specality} color="#384052">{doctordata && doctordata.specality}</Text>
                            <Text fontSize="0.875rem" fontWeight="600" color="#061027">{doctordata && doctordata.fullname}</Text>
                            <Text fontSize="0.75rem" fontWeight="400" color="#384052">{doctordata && doctordata.qualification}</Text>
                        </Box>
                        </Box>
                    </Box>
                    <Box
                        bg="#eaedf5"
                        mt="15px"
                        borderRadius="full"
                        p="8px 16px"
                        display="flex"
                        justifyContent="space-around"
                    >
                        <Box display="flex" alignItems="center" justifyContent="center">
                        <Image w="18px" src="/assets/svgs/star-blue.svg" mr="5px" />
                        <Text color="#384052" fontSize="14px" fontWeight="500">
                        {doctordata && doctordata.ratings? doctordata.ratings:"-"}
                        </Text>
                        </Box>
                        <Box as="span" border="1px solid #D2DCF3"></Box>
                        <Box display="flex" alignItems="center" justifyContent="center">
                        <Image w="18px" src="/assets/svgs/pricing-blue.svg" mr="5px" />
                        <Text color="#384052" fontSize="14px" fontWeight="500">
                            {doctordata && doctordata.fee? "$"+doctordata.fee:"-"}
                        </Text>
                        </Box>
                        <Box as="span" border="1px solid #D2DCF3"></Box>
                        <Box display="flex" alignItems="center" justifyContent="center">
                        <Image w="18px" src="/assets/svgs/work-blue-filled.svg" mr="5px" />
                        <Text color="#384052" fontSize="14px" fontWeight="500">
                        {doctordata && doctordata.yearsofexperience? doctordata.yearsofexperience+" +yrs":"-"}
                        </Text>
                        </Box>
                    </Box>
                    </Box>
                </>}
              />
            
            <GrayCard
              cardHeader="QUALIFICATION"
              cardMinHeight="100px"
              cardHeaderIcon="/assets/svgs/qualification.svg"
              cardBody={
                <>
                  <Text fontSize="0.875rem" fontWeight="600">
                  {doctordata && doctordata.qualification}
                  </Text>
                  {/* <Divider my="0.75rem" /> */}
                  
                </>
              }
              hasFooter={false}
            />
          
            <GrayCard
              cardHeader="services & procedures"
              cardHeaderIcon="/assets/svgs/vaccines-and-dose.svg"
              bodyStyle={{ py: "0" }}
              cardBody = {
                <>
                  <Box w="100%" display='flex'>
                    <Box w="100%">
                      {doctordata && doctordata.services && doctordata.services.length > 0 ? (
                        doctordata.services.map((step, index) => (
                          <Box
                            key={index}
                            display="flex"
                            flexDirection="column"
                            flexWrap="wrap"
                            maxHeight="7.5rem"
                          >
                            <Box
                              display="flex"
                              gap="0.75rem"
                              alignItems="center"
                              py="0.75rem"
                              borderBottom="1px solid #E6E7E9"
                            >
                              <Image opacity="0.75" src="/assets/imgs/check-black.png" />
                              <Text fontSize="0.875rem" fontWeight="600">
                                {step.servicename}
                              </Text>
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <Box>No data</Box>
                      )}
                    </Box>
                    
                  </Box>
                </>
              }
              hasFooter={false}
              cardFooterText="View more"
            />

            <GrayCard
              cardHeader="Hospital details"
              cardHeaderIcon="/assets/svgs/hospital-gray.svg"
              cardBody = {
                <>
                  {doctordata && doctordata.addresses && doctordata.addresses.length > 0 ? (
                    doctordata.addresses.map((step, index) => (
                      <React.Fragment key={index}>
                        <Box display="flex" justifyContent="space-between">
                          <Box>
                            <Text fontSize="0.875rem" fontWeight="600">
                              {step.hospitalname? step.hospitalname:"-"}
                            </Text>
                            <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                              {/* {step.address? step.address:"-"} */}
                              {step.address1 || step.address2 || step.city || step.state || step.pincode || step.country ? (
                                 <>
                                   {step.address1 ? `${step.address1} ` : ''}
                                   {step.address2 ? `${step.address2}, ` : ''}
                                   {step.city ? `${step.city} ` : ''}
                                   {step.state ? `${step.state} ` : ''}
                                   {step.pincode ? `${step.pincode} ` : ''}
                                   {step.country ? `${step.country} ` : ''}
                                 </>
                               ) : '-' }
                            </Text>
                          </Box>
              
                          <Box display="flex" justifyContent="space-between" gap="0.5rem">
                            <Box
                              as="button"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              p="0.5rem"
                              boxSize="2.25rem"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                            >
                              <Image src="/assets/svgs/call-gray.svg" />
                            </Box>
                            <Box
                              as="button"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              p="0.5rem"
                              boxSize="2.25rem"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              onClick={(e)=>navigatemap(step.address1, step.address2, step.city, step.country)}
                            >
                              <Image src="/assets/svgs/location-gray.svg" />
                            </Box>
                          </Box>
                        </Box>
                        <Divider my="0.75rem" />
                        <Box display="flex" gap="0.5rem">
                          <Image src="/assets/svgs/telephone-blue.svg" />
                          <Text fontSize="0.75rem" fontWeight="500">
                            {doctordata.mobileno?doctordata.mobileno:"-"}
                          </Text>
                        </Box>
                        <Box display="flex" gap="0.5rem"
                         as="a" href={step.websiteurl?step.websiteurl:""} target="/"
                         cursor="pointer">
                          <Image src="/assets/svgs/website-blue.svg" />
                          <Text fontSize="0.75rem" fontWeight="500"   _hover={{  textDecoration: "underline" }}>
                            {step.websiteurl?step.websiteurl:"-"}
                          </Text>
                        </Box>
                      </React.Fragment>
                    ))
                  ) : (
                    <Box>No data</Box>
                  )}
                </>
                }
              
              hasFooter={false}
            />
            {/* Book appoinments start */}
            <Box w="100%" bg="white" border="1px solid #E6E7E9" borderRadius="0.75rem" boxShadow="0px 8px 20px 0px #E6E7E966">
              <Box w="100%" p="1.5rem 2rem" borderBottom="1px solid #E6E7E9">
                  <Text fontSize={{sm: "1.25rem", xl: "1.5rem"}} fontWeight="600" color="#050D1F">Book appointment</Text>
                  <Text fontSize={{sm: "0.875rem", xl: "1rem"}} fontWeight="400" color="#050D1F"> {!DoctorSlotsActive ? "Select a preferred date":"Select a timeslot"}</Text>
              </Box>

              <Box  w="100%" display="flex" flexDir="column" justifyContent="center" alignItems="center" bg="white" p="1rem" pt="0">
              {!DoctorSlotsActive &&
                  <Calendar
                      date={date}
                      className="customCalendar mobileCalendar"
                      onChange={handleDateChange}
                      showMonthAndYearPickers={false}
                      renderStaticRangeLabel={false}
                      minDate={startOfDay(new Date())}
                      maxDate={
                        doctordata && doctordata.islimitfuturebooking == 1 ?
                        calculateMaxDate(doctordata && doctordata.bookinupto, doctordata && doctordata.bookinuptovalue)
                        :undefined
                      }
                  />
              } 
                  {DoctorSlotsActive && <SlotBooking isfreevisit={isfreevisit} date={date} doctorid={doctorid} bookingupto={doctordata && doctordata.bookinupto} bookinguptotype={doctordata && doctordata.bookinuptovalue}/>}
                  {!DoctorSlotsActive && <PrimaryButton onClick={getslots} buttonText="Continue" btnStyle={{mb: "0", mt: "2rem"}} />}
              </Box>
            </Box>
            {/* Book appoinments end */}

            <Text
              fontSize="0.75rem"
              fontWeight="600"
              color="#6A707D"
            >
              <strong>Refund Policy:</strong> In the rare event of
              dissatisfaction with a consultation, please contact
              our support team within 48 hours for a refund.
            </Text>
          </Flex>

        </Box>
      </Box>
    )}
    </>
  );
};

export default DoctorProfileIndex;
