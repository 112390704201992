import formAPIappointment from "./appointment";


var formattedOutput = null;

const formAPIModelappointment = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIappointment(item));


  return formattedOutput
};

export default formAPIModelappointment