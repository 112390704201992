import React, { useEffect, useRef } from 'react';
import { Gauge } from '@antv/g2plot';

const GaugeChart = ({ value,bodymass, height, rotateAngle }) => {
  const gaugeRef = useRef(null);
  const pointerRef = useRef(null);

  useEffect(() => {
    if (!gaugeRef.current || value === undefined) {
      return;
    }

    const gauge = new Gauge(gaugeRef.current, {
      percent: bodymass / 100,
      range: {
                 //color: 'l(0) 0:#F04438 0.25:#14C585 0.75:#FCCE35 1: #F04438',
     //  color: 'l(0) 0:#B8E1FF 1:#3D76DD',

          ticks: [0, 1 / 4, 2 / 4, 3 / 4, 4 / 4],

          //figma colors original -
         // color: ['#F04438', '#14C585', '#FCCE35','#F04438'],

         // custom light colors -
         // color: ['#FFCCCC ', '#B3FFB3', '#FFFFB3','#FFCCCC '],

         color: bodymass == 0
         ? ['#FFCCCC', '#B3FFB3', '#FFFFB3', '#FFCCCC']
         :
         bodymass <= 25
           ? ['#F04438', '#B3FFB3', '#FFFFB3', '#FFCCCC']
           : bodymass <= 50
           ? ['#FFCCCC', '#14C585', '#FFFFB3', '#FFCCCC']
           : bodymass <= 75
           ? ['#FFCCCC', '#B3FFB3', '#FCCE35', '#FFCCCC']
           :['#FFCCCC', '#B3FFB3', '#FFFFB3', '#F04438'],
           
      },
      startAngle: Math.PI,
      endAngle: 2 * Math.PI,
      indicator: null,
      statistic: {
        content: {
          style: {
            fontSize: '25px',
            lineHeight: '45px',
            color: 'black',
          },
          formatter: () => `${value}`,
        },
      },
    });

    gauge.render();

    return () => {
      gauge.destroy();
    };
  }, [value]);

  useEffect(() => {
    if (!pointerRef.current || value === undefined) {
      return;
    }

    pointerRef.current.style.transform = `translate(-50%, -50%) rotate(${rotateAngle}deg)`;
  }, [rotateAngle]);

  return (
  <div style={{ position: 'relative', height }}>
      <div ref={gaugeRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
      <img
        ref={pointerRef}
        src="/assets/imgs/pointer.png" // Change this to the actual path of your pointer image
        alt="Pointer"
        style={{
          position: 'absolute',
          top: '72%',
          left: '50%',
          width: '50px', // Adjust the width and height according to your pointer image size
          height: 'auto',
          transform: 'translate(-50%, -50%) rotate(0deg)', // Initial rotation
        }}
      />
    </div>
  );
};

export default GaugeChart;
