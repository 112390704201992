import moment from "moment";
export default function formAPIImage({
    chat,
    chattype,
    ispatient,
    createddate,
    imagepath,
    documentsize,
    documentname
   
    
  }) {   
    return Object.freeze({
        chat,
        chattype,
        ispatient,
        createddate,
        imagepath,  
        documentsize,
     documentname     
    });
}


  