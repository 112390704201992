import moment from "moment";
export default function formAPIFamilyMedicalCondition({
  condition,
  condition_cd,
  conditionidentifieddate,
  conditiontype,
  conditiontype_cd
   

})
{
  return Object.freeze({
    condition,
    condition_cd,
    conditionidentifieddate,
    conditiontype,
    conditiontype_cd
   
  });
}