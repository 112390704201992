import moment from "moment";

export default function formapimodalAlerts({
    preferenceid,
    patientid,
    reportalert,
    reportnotification,
    reportsms,
    reportemail,
    appointmentalert,
    appointmentnotification,
    appointmentsms,
    appointmentemail,
    remindappointmentbefore,
    remindbefore,
    messagealert,
    createddate,
    isactive
}) {
  return Object.freeze({
    preferenceid,
    patientid,
    reportalert,
    reportnotification,
    reportsms,
    reportemail,
    appointmentalert,
    appointmentnotification,
    appointmentsms,
    appointmentemail,
    remindappointmentbefore,
    remindbefore,
    messagealert,
    createddate : moment(createddate).format("Do MMM YYYY"),
    isactive
   });
}
