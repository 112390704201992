import { Box, Text, Image } from "@chakra-ui/react";
import React from "react";

const Footer = () => {
  return (
    <Box as="footer" bg="#061027" w="100%" h="40px" p="10px 20px" >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text fontSize="12px" fontWeight="500" color="#898D97">© 2024 Midoc, Inc.</Text>
        <Box display="flex" alignItems="center" gap="10px">
          <Text fontSize="10px" fontWeight="500" color="#898D97">
            A PRODUCT OF 
          </Text>
          <Image src="/assets/imgs/tci-gray-logo.png" />
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
