import {
  Box,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import Footer from "../../components/footer";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import React, { useState, useEffect, useRef } from "react";
import {FetchRecentActivity} from "../../middleware/services/home";
import { useScreenType } from "../../auth";
import MobileNavbar from "../../components/mobile-navbar";
import { useNavigate } from "react-router-dom";




const RecentActivityIndex = () => {
    var [patientid, setPatientid] = useState();
    var [activeuserid, setActiveUser] = useState();
  var [data, setData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const screenType = useScreenType();
  var navigate = useNavigate();


  useEffect(() => {
  showLoader();
  const fetchData = async () => {
    try {
      const result = await FetchRecentActivity({
        patientid,
        activeuserid,
      });

      //console.log(result, " result notifications");
    
      if (result != null) {
        setData(result);
      } else {
      }
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
  dismissLoader();
  fetchData();
}, []);
const activityArray = [
    { id: 748, uniqueName: "You have canceled an appointment.", iconUrl: "/assets/imgs/appointments.png" },
    { id: 747, uniqueName: "You have rescheduled an appointment.", iconUrl: "/assets/imgs/appointments.png" },
    { id: 746, uniqueName: "You have scheduled an appointment.", iconUrl: "/assets/imgs/appointments.png" },
    { id: 532, uniqueName: "You have updated your profile.", iconUrl: "/assets/imgs/account-gray.png" },
    { id: 531, uniqueName: "You have deleted Report.", iconUrl: "/assets/imgs/doc-white.png" },
    { id: 530, uniqueName: "You have deleted Prescription Report.", iconUrl: "/assets/imgs/medication.png" },
    { id: 529, uniqueName: "You have deleted Imaging Report.", iconUrl: "/assets/imgs/hand_bones.png" },
    { id: 528, uniqueName: "You have deleted Lab Report.", iconUrl: "/assets/imgs/biotech.png" },
    { id: 527, uniqueName: "You have deleted Height.", iconUrl: "/assets/imgs/measure.png" },
    { id: 526, uniqueName: "You have deleted Weight.", iconUrl: "/assets/imgs/measure.png" },
    { id: 525, uniqueName: "You have deleted Body Mass Index.", iconUrl: "/assets/imgs/measure.png" },
    { id: 524, uniqueName: "You have deleted Oxygen Saturation.", iconUrl: "/assets/imgs/heart.png" },
    { id: 523, uniqueName: "You have deleted Blood Pressure.", iconUrl: "/assets/imgs/heart.png" },
    { id: 522, uniqueName: "You have deleted Heart Rate.", iconUrl: "/assets/imgs/heart.png" },
    { id: 521, uniqueName: "You have deleted Body Temperature.", iconUrl: "/assets/imgs/measure.png" },
    { id: 520, uniqueName: "You have deleted Respiratory Rate.", iconUrl: "/assets/imgs/heart.png" },
    { id: 519, uniqueName: "You have deleted Social History.", iconUrl: "/assets/imgs/social-history-bar.png" },
    { id: 518, uniqueName: "You have deleted Vaccinations.", iconUrl: "/assets/imgs/syringe.png" },
    { id: 517, uniqueName: "You have deleted Family History.", iconUrl: "/assets/imgs/family-history.png" },
    { id: 516, uniqueName: "You have deleted Allergies.", iconUrl: "/assets/imgs/conditions.png" },
    { id: 515, uniqueName: "You have deleted Medications.", iconUrl: "/assets/imgs/medication.png" },
    { id: 514, uniqueName: "You have deleted Surgeries.", iconUrl: "/assets/imgs/surgeries.png" },
    { id: 513, uniqueName: "You have deleted Health conditions.", iconUrl: "/assets/imgs/conditions.png" },
    { id: 512, uniqueName: "You have updated Prescription Report.", iconUrl: "/assets/imgs/medication.png" },
    { id: 511, uniqueName: "You have updated Imaging Report.", iconUrl: "/assets/imgs/hand_bones.png" },
    { id: 510, uniqueName: "You have updated Lab Report.", iconUrl: "/assets/imgs/biotech.png" },
    { id: 509, uniqueName: "You have updated Height.", iconUrl: "/assets/imgs/measure.png" },
    { id: 508, uniqueName: "You have updated Weight.", iconUrl: "/assets/imgs/measure.png" },
    { id: 507, uniqueName: "You have updated Body Mass Index.", iconUrl: "/assets/imgs/measure.png" },
    { id: 506, uniqueName: "You have updated Oxygen Saturation.", iconUrl: "/assets/imgs/heart.png" },
    { id: 505, uniqueName: "You have updated Blood Pressure.", iconUrl: "/assets/imgs/heart.png" },
    { id: 504, uniqueName: "You have updated Heart Rate.", iconUrl: "/assets/imgs/heart.png" },
    { id: 503, uniqueName: "You have updated Body Temperature.", iconUrl: "/assets/imgs/measure.png" },
    { id: 502, uniqueName: "You have updated Respiratory Rate.", iconUrl: "/assets/imgs/heart.png" },
    { id: 501, uniqueName: "You have updated Social History.", iconUrl: "/assets/imgs/social-history-bar.png" },
    { id: 500, uniqueName: "You have updated Vaccinations.", iconUrl: "/assets/imgs/syringe.png" },
    { id: 499, uniqueName: "You have updated Family History.", iconUrl: "/assets/imgs/family-history.png" },
    { id: 498, uniqueName: "You have updated Allergies.", iconUrl: "/assets/imgs/conditions.png" },
    { id: 497, uniqueName: "You have updated Medications.", iconUrl: "/assets/imgs/medication.png" },
    { id: 496, uniqueName: "You have updated Surgeries.", iconUrl: "/assets/imgs/surgeries.png" },
    { id: 495, uniqueName: "You have updated Health conditions.", iconUrl: "/assets/imgs/conditions.png" },
    { id: 494, uniqueName: "You have added Prescription Report.", iconUrl: "/assets/imgs/medication.png" },
    { id: 493, uniqueName: "You have added Imaging Report.", iconUrl: "/assets/imgs/hand_bones.png" },
    { id: 492, uniqueName: "You have added Lab Report.", iconUrl: "/assets/imgs/biotech.png" },
    { id: 491, uniqueName: "You have added Height.", iconUrl: "/assets/imgs/measure.png" },
    { id: 490, uniqueName: "You have added Weight.", iconUrl: "/assets/imgs/measure.png" },
    { id: 489, uniqueName: "You have added Body Mass Index.", iconUrl: "/assets/imgs/measure.png" },
    { id: 488, uniqueName: "You have added Oxygen Saturation.", iconUrl: "/assets/imgs/heart.png" },
    { id: 487, uniqueName: "You have added Blood Pressure.", iconUrl: "/assets/imgs/heart.png" },
    { id: 486, uniqueName: "You have added Heart Rate.", iconUrl: "/assets/imgs/heart.png" },
    { id: 485, uniqueName: "You have added Body Temperature.", iconUrl: "/assets/imgs/measure.png" },
    { id: 484, uniqueName: "You have added Respiratory Rate.", iconUrl: "/assets/imgs/heart.png" },
    { id: 483, uniqueName: "You have added Social History.", iconUrl: "/assets/imgs/social-history-bar.png" },
    { id: 482, uniqueName: "You have added Vaccinations.", iconUrl: "/assets/imgs/syringe.png" },
    { id: 481, uniqueName: "You have added Family History.", iconUrl: "/assets/imgs/family-history.png" },
    { id: 480, uniqueName: "You have added Allergies.", iconUrl: "/assets/imgs/conditions.png" },
    { id: 479, uniqueName: "You have added Medications.", iconUrl: "/assets/imgs/medication.png" },
    { id: 478, uniqueName: "You have added Surgeries.", iconUrl: "/assets/imgs/surgeries.png" },
    { id: 477, uniqueName: "You have added Health conditions.", iconUrl: "/assets/imgs/conditions.png" }
];

const mapActivityToIcon = (description) => {
  const activity = activityArray.find(item => item.uniqueName === description);
  return activity ? activity.iconUrl : '/default-icon-url.png';
}
  return (
    <>
      

      {screenType == 'web' ? (
        <Box w="100%">
             <Box top="0" zIndex="banner">
             {isLoading && <Loader />}
          <Navbar />
          <SecondaryNav
          
            withBack="Recent Activity"
            goBack={()=> navigate("/home")}
          />
           {isLoading && <Loader />}
        </Box>
        <Box>
          <Flex>
            <Box w="100%">
              {/* Main container start */}
              <Flex w="calc(100vw - 6rem)">
                <Box
                  p="1.5rem 8.25rem"
                  h="calc(100vh - 73px)"
                  overflow="hidden auto"
                  w="100%"
                >
                  <Box w="100%" display="flex" flexDir="column" gap="1.5rem">
                      <Box display="flex" flexDir="column" gap="1rem">
                      {data && data.length > 0 ? (
                        data.map((step, index) => (
                            <Flex key={index} alignItems="center" w="100%" gap="0.5rem" borderBottom="1px solid #E6E7E9" pb="1rem">
                               <Box boxSize="1.5rem" display="flex" justifyContent="center" alignItems="center" border="1px solid #898D97" borderRadius="full">
                              <Image w="0.75rem" 
                                filter={step.title == "Report" ? "brightness(0) saturate(100%) invert(50%) sepia(1%) saturate(0%) hue-rotate(226deg) brightness(95%) contrast(88%)" : ""}
                                src={mapActivityToIcon(step.description)}
                              />
                            </Box>
                                                    
                                <Box display="flex" flexDir="column" w="100%">
                                    <Box display="flex" justifyContent="space-between">
                                        <Text fontSize="0.75rem" fontWeight="500" color="#384052">{step.title}</Text>
                                        <Box display="flex" gap="0.25rem" alignItems="center">
                                            {/* <Box boxSize="4px" bg="#1C4EC5" borderRadius="full"></Box> */}
                                            <Text fontSize="0.625rem" fontWeight="400" color="#384052">{step.timeDifference}</Text>
                                        </Box>
                                    </Box>
                                                    
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Text fontSize="0.875rem" fontWeight="500" color="#384052">{step.description}</Text>
                                        {/* <Image src="/assets/imgs/right-icon.png" /> */}
                                    </Box>
                                </Box>
                            </Flex>
                        ))
                      ) : (
                        <Box display="flex" justifyContent="center" flexDir="column" gap="10px" alignItems="center" h="100vh">
                        <Image src="/assets/svgs/notifications.svg"/>
                        <Text>No Activity</Text>
                      </Box>
                      )}

                      </Box>
                    {/* Footer start */}
                    <Footer />
                    {/* Footer end */}
                  </Box>
                </Box>
              </Flex>
              {/* Main container end */}
            </Box>
          </Flex>
        </Box> 
        </Box>
      ) : (
        <Box w="100%">
          <MobileNavbar />

          <Box
            p="1.5rem"
            h="calc(100vh - 73px)"
            overflow="hidden auto"
            w="100%"
          >
            <Box w="100%" display="flex" flexDir="column" gap="1.5rem">
                <Box display="flex" flexDir="column" gap="1rem">
                {data && data.length > 0 ? (
                  data.map((step, index) => (
                      <Flex key={index} alignItems="center" w="100%" gap="0.5rem" borderBottom="1px solid #E6E7E9" pb="1rem">
                          <Box display="flex" justifyContent="center" alignItems="center" boxSize="2.75rem" border="1px solid #E6E7E9" borderRadius="full">
                              <Image src="/assets/svgs/calendar-edit.svg" />
                          </Box>
                                              
                          <Box display="flex" flexDir="column" w="100%">
                              <Box display="flex" justifyContent="space-between">
                                  <Text fontSize="0.75rem" fontWeight="500" color="#384052">{step.notificationtype}</Text>
                                  <Box display="flex" gap="0.25rem" alignItems="center">
                                      <Box boxSize="4px" bg="#1C4EC5" borderRadius="full"></Box>
                                      <Text fontSize="0.625rem" fontWeight="400" color="#384052">{step.timeDifference}</Text>
                                  </Box>
                              </Box>
                                              
                              <Box display="flex" justifyContent="space-between" alignItems="center">
                                  <Text fontSize="0.875rem" fontWeight="500" color="#384052">{step.notificationcontent}</Text>
                                  <Image src="/assets/imgs/right-icon.png" />
                              </Box>
                          </Box>
                      </Flex>
                  ))
                ) : (
                  <Box display="flex" justifyContent="center" flexDir="column" gap="10px" alignItems="center" h="100vh">
                  <Image src="/assets/svgs/notifications.svg"/>
                  <Text>No Notifications</Text>
                </Box>
                )}

                </Box>
              {/* Footer start */}
              <Footer />
              {/* Footer end */}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default RecentActivityIndex;
