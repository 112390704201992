import  FetchData, { } from '../../client';
import formapimodalhealth from '../../domains/myhealth/myhealths';
import formapimodallabs from '../../domains/myhealthlabs/labs';
import labsReportViewFormatting from '../../domains/myhealthlabs/labsReportViewFormatting';
import {getLoginInfo, getUserID}  from '../../../auth';

const FetchLabsData = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_getpatienthealthreport", inputData);
     console.log(result,"labs db response")
     if (result.output.data) {
       //console.log(result.output.data)
     var finalOutput = formapimodallabs(result.output.data)
     }
    // console.log(result, "Labs Result")
    // console.log(finalOutput, "Labs Formatted output")
    
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

const FetchLabsDataForReportsView = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_getpatienthealthreport", inputData);
     //console.log(result," labs report view original data")
     if (result.output.data) {
       //console.log(result.output.data)
       
     var formattingForReportView = labsReportViewFormatting(result.output.data)
     }
    // console.log(result, "Labs Result")
     //console.log(formattingForReportView, "Labs Formatted data for reports view")
    
    return formattingForReportView;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};


const DeletePatientLabReport = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_deletepatientreport", inputData);
     //console.log(result,"MyHealth db response")
    //  if (result.output.data) {
    //    //console.log(result.output.data)
    //  var finalOutput = formapimodallabs(result.output.data)
    //  }
     //console.log(result, "delete result")
    // console.log(finalOutput, "Labs Formatted output")
    
    return result;

  } catch (error) {
    console.error('Error to delete lab report :', error);
    throw error;
  }
};

export const GetLabTestName = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_getlabtestname", inputData);
     //console.log(result,"MyHealth db response")
    //  if (result.output.data) {
    //    //console.log(result.output.data)
    //  var finalOutput = formapimodallabs(result.output.data)
    //  }
     //console.log(result, "delete result")
    // console.log(finalOutput, "Labs Formatted output")
    
    return result;

  } catch (error) {
    console.error('Error to delete lab report :', error);
    throw error;
  }
};

export const GetTestResultByLabTestId = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_gettestresultbylabtestid", inputData);
     //console.log(result,"MyHealth db response")
    //  if (result.output.data) {
    //    //console.log(result.output.data)
    //  var finalOutput = formapimodallabs(result.output.data)
    //  }
     //console.log(result, "delete result")
    // console.log(finalOutput, "Labs Formatted output")
    
    return result;

  } catch (error) {
    console.error('Error to delete lab report :', error);
    throw error;
  }
};


export {FetchLabsData, DeletePatientLabReport, FetchLabsDataForReportsView};
