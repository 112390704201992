import formAPIrespiratoryrate from "./respiratoryrate";


var formattedOutput = null;

const formAPIModelrespiratoryrate = (data, type) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIrespiratoryrate(item, type));


  return formattedOutput
};

export default formAPIModelrespiratoryrate