import React from "react";
import { Button, Image, Box } from '@chakra-ui/react';
import { styles, submitStyle, iconStyle, iconReset } from './style';


const PrimaryButtonIcon = ({ buttonText,iconSrc,onClick,disabled,sx,iconImg,btnClass,btnStyle,iconCss, ...props }) => {
//   const buttonState = isLoading ? `${buttonText}...` : buttonText;
const iconVal = buttonText ? iconStyle : iconReset

  return (
    <Button className={btnClass} onClick={onClick} sx={{ ...styles, ...sx, ...btnStyle }} {...btnStyle}>
      <Box className={iconImg} as="span" sx={{...iconVal,...iconCss}}>
        <Image src={iconSrc} />
      </Box>
      {buttonText}
    </Button>
  );
};

export default PrimaryButtonIcon;