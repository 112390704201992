import FetchData from '../../client';
import formAPIModelMedications from '../../domains/medications/medications';
import { CONFIG } from '../../../appconfig/appconfig';
//reac the file
import axios from 'axios';
import {getLoginInfo, getUserID}  from '../../../auth';


const FetchMedicationsData = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
       // console.log(inputData,"Medications input")
    const result = await FetchData("midoc_getpatientmedicine", inputData);
    //console.log(result,"Medications db response")
    if (result.output.data) {
    var finalOutput = formAPIModelMedications(result.output.data)
    //console.log(finalOutput)
    }
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const AddMedicine = async (inputData) => {
    try {
      
      inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    //console.log(inputData , " input to add medication")
            const result = await FetchData("midoc_addpatientmedicine", inputData);
     //console.log(result , " db response of add medicane")
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const GetMedicineName = async (uri,name,type) => {
   // alert(uri)
    try {     
      const formData = new FormData();
      formData.append("file", uri);
      // form.append('image', {
      //   uri: uri,
      //   type: type ? 'image/jpeg' : 'image/pdf',
      //   name: name ? 'image.jpg' : 'image.pdf',
      // });
       let patientid = getLoginInfo();
       formData.append("patientid", patientid);
      //console.log(formData)
      const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_medicinename`,formData, {    
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
      });
      //console.log('@@response',response)
      if (response.statusText === "OK") { 
        const result = await response.data;
        //console.log('@@result',result)
        return result
      } else {
        const error = "failed" ;
        return error
      }
    } catch (err) {
      console.log(err); 
      const error = "failed" ;
      return error
    }
  };
  const EditMedicine = async (inputData) => {
    try {
      
      inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
            const result = await FetchData("midoc_updatepatientmedicine", inputData);
     
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const DeleteMedicine = async (inputData) => {
    try {

      inputData.loginid = getLoginInfo();
      inputData.activeuserid = getUserID();
      const result = await FetchData("midoc_deletepatientmedicine", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const GetAIMedicineComposition = async (inputData) => {
    try {

      inputData.loginid = getLoginInfo();
      inputData.activeuserid = getUserID();
      const result = await FetchData("midoc_ai_medicinebyname", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
export {FetchMedicationsData, AddMedicine,EditMedicine, GetMedicineName,DeleteMedicine, GetAIMedicineComposition};
