import moment from "moment";
export default function formAPIpastsurgicalhistory({
  procedure,
  proceduredate,
  proceduretitle,
  proceduretype,
  // proceduretype_cd,
  // procedure_cd,
 

})
{
return Object.freeze({
  procedure,
  proceduredate: proceduredate? moment(proceduredate).format("YYYY-MM-DD"):"-",
  proceduretitle,
  proceduretype,
  // proceduretype_cd,
  // procedure_cd,

 
 
 
});
}