
const formapimodallabs = (inputData) => {
  const formattedData = [];

  inputData.forEach(item => {
    const existingEntry = formattedData.find(entry => entry.eventtitle === item.eventtitle);
    let trendcolor;
    if (
      item.lower_bound !== null && item.lower_bound !== undefined &&
      item.upper_bound !== null && item.upper_bound !== undefined &&
      Number(item.actualresultvalue) >= Number(item.lower_bound) &&
      Number(item.actualresultvalue) <= Number(item.upper_bound)
    ) {
      trendcolor = 'black'; // Case: actualresultvalue is within bounds
    } else if (
      item.lower_bound === null || item.upper_bound === null ||
      item.lower_bound === undefined || item.upper_bound === undefined ||
      Number(item.actualresultvalue) < Number(item.lower_bound) ||
      Number(item.actualresultvalue) > Number(item.upper_bound)
    ) {
      trendcolor = 'red';   // Case: actualresultvalue is outside the bounds or bounds are not provided
    } else {
      trendcolor = 'black'; // This condition is not really necessary in this context
    }
    
    
    if (existingEntry) {
      // If entry already exists, add the current item to its 'data' array
      existingEntry.data.push({
        eventtitle: item.eventtitle,
        reportdescription: item.reportdescription,
        documenturl: item.documenturl,
        imagepath: item.imagepath || "https://midocfileserver.blob.core.windows.net/midocdev/",
        reportname: item.reportname,
        resultvalue: item.resultvalue,
        performdate: item.performdate || "-",
        longtext: item.longtext,
        eventtitle: item.eventtitle,
        documentid: item.documentid,
        documentdate: item.documentdate,
        verifieddate: item.verifieddate || "-",
        reportid:item.reportid,
        resultuom: item.resultuom,
        resultvalue: item.resultvalue,
        trendcolor:trendcolor,
        upper_bound: item.upper_bound,
        lower_bound: item.lower_bound,

      });
    } else {
      
      // If entry doesn't exist, create a new entry
      formattedData.push({
        eventtitle: item.eventtitle,
        resultuom: item.resultuom,
        data: [
          {
            eventtitle: item.eventtitle ,
            reportdescription: item.reportdescription,
            documenturl: item.documenturl,
            imagepath: item.imagepath || "https://midocfileserver.blob.core.windows.net/midocdev/",
            reportname: item.reportname,
            resultvalue: item.resultvalue,
            performdate: item.performdate || "-",
            longtext: item.longtext,
            eventtitle: item.eventtitle,
            documentid: item.documentid,
            documentdate: item.documentdate,
            verifieddate: item.verifieddate || "-",
            reportid:item.reportid,
            resultuom: item.resultuom,
            trendcolor:trendcolor,
            upper_bound: item.upper_bound,
        lower_bound: item.lower_bound,
          }
        ]
      });
    }
  });

  return formattedData;
};

export default formapimodallabs;