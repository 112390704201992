import formAPIFamilyAllergy from "./allergy";


var formattedOutput = null;

const formAPIModelFamilyAllergies = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data?.map(item => formAPIFamilyAllergy(item));


  return formattedOutput
};

export default formAPIModelFamilyAllergies