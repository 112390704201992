import { Box, Flex, Button, Image, Spacer, Text, Table, Tbody, Tr, Td } from "@chakra-ui/react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import TabSection from "../../components/tabs";
import DynamicTable from "../../components/dynamic-table";
import { PrimaryButton, PrimaryButtonIcon, Toaster } from "../../components";
import ReportViewCard from "../../components/report-card";
import ModalRight from "../../components/modal-right";
import React from "react";
import DynamicModal from "../../components/dynamic-modal";
import { useEffect, useState, useRef } from "react";
import {
  DeletePatientLabReport,
  FetchLabsData,
  FetchLabsDataForReportsView,
} from "../../middleware/services/myhealth/labs";
import {
  FetchMedicalRecords,
  FetchMyHealthData,
} from "../../middleware/services/myhealth";
import { useNavigate } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import moment from "moment";
import Footer from "../../components/footer";
import { downloadDocument } from "../../auth";

function PatientLabsIndex() {
  const openCustomModal = React.useRef();
  const dynamicModal = React.useRef();

  const { showSuccessToast, showErrorToast } = Toaster();
  const { isLoading, showLoader, dismissLoader } = useLoader();

  const [patientid, setPatientid] = useState("");
  const [type, setType] = useState("lab");

  const [formattedData, setFormattedData] = useState(null);

  const [formattedDataForTableView, setFormattedDataForTableView] =
    useState(null);
  const [formattedDataForReportView, setFormattedDataForReportView] =
    useState(null);

  const [fetchError, setFetchError] = useState(false);

  const [fetchedData, setFetchedData] = useState(false);
  const fileInputRef = useRef(null);
  const [uuid, setUuid] = useState("");
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  useEffect(() => {
    const currentURL = window.location.href;
    const urlParams = new URLSearchParams(currentURL.split("?")[1]);
    const idValue = urlParams.get("id");
    const idType = urlParams.get("type");

    setUuid(idValue);
    setType(idType);
  }, []);



  const input = {
    uuid,
    type,
  };

  useEffect(() => {
    // const loginid = localStorage.getItem("loginid");
    // setPatientid(loginid);
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     showLoader();
  //     try {
  //       const labsData = await FetchMedicalRecords(input);
  //       const reportData = labsData.reportdata
  //       if (reportData) {
  //         const data = {
  //           rows: reportData?.map((item) => ({
  //             header: item.eventtitle,
  //             unit: item.resultuom,
  //             // values: {
  //               value: item.resultvalue || "-",
  //               date: item.verifieddate || "",
  //             // }
  //             // values: item.map((dataItem) => ({
  //             //   date: dataItem.verifieddate || "",
  //             //   value: dataItem.resultvalue || "-"
  //             // }))
  //             // values: item.data.map(
  //             //   (value) =>
  //             //     `${value.documenturl || ""} ${value.documentdate || ""}`
  //             // ),
  //             // reportid: item.data.map((value) => value.documentid),
  //             // documetURL: item.data.map(
  //             //   (value) => value.imagepath + value.documenturl
  //             // ),
  //             // reportname: item.data.map((value) => value.reportname),
  //             // trendcolor: item.data.map((value) => value.trendcolor),
  //           })),
  //         };
  //         setFormattedData(data);

  //       } else {
  //         setFormattedData("");
  //       }

  //       // const labsDataForReportView = await FetchLabsDataForReportsView(input);
  //       const labsDataForReportView = "";
  //       console.log(reportData, "Report view");
  //       const dataForReportsView = {
  //         rows: reportData?.map((item) => ({
  //           reportid: item?.reportid,
  //           reportname: item?.reportname,
  //           verifieddate: item?.verifieddate,
  //           documentid: item?.documentid,
  //           // uniqueURLs: item.data.map((dataItem) => ({
  //           //   documenturl: dataItem.documenturl,
  //           //   imagepath: dataItem.imagepath,
  //           //   mergedURL: `${dataItem.imagepath}${dataItem.documenturl}`
  //           // }))
  //           // documenturl: item?.documenturl,
  //           // uniqueURLs: item?.data.map((dataItem) => ({
  //           //   documenturl: dataItem?.documenturl,
  //           //   imagepath: dataItem?.imagepath
  //           // })),
  //           // values: item?.data.map((dataItem) => ({
  //           //   date: dataItem?.verifieddate || "",
  //           //   value: dataItem?.resultvalue || "-"
  //           // }))
  //         }))
  //       };
  //       console.log(dataForReportsView, "data for report view card")
  //       setFormattedDataForReportView(dataForReportsView);
        
  //       const dataForTable = {
  //         rows: reportData.map((item) => ({
  //           header: item.eventtitle,
  //           unit: item.resultuom,
  //           // values: {
  //             date: item.verifieddate || "",
  //             value: item.resultvalue || "-"
  //           // }

  //           // values: item.data.map((value) => ({
  //           //   resultvalue: value.resultvalue || "-",
  //           //   documentdate: value.verifieddate || "",
  //           //   trendcolor: value.trendcolor,
  //           // })),
  //         })),
  //       };;
  //       // console.log(dataForTable, "data for table")
  //       setFormattedDataForTableView(dataForTable);

  //       setFetchedData(true);
        
  //     } catch (error) {
  //       // console.log(error, " error in fetching labs data");
  //       setFetchError(true);
  //     }
  //     dismissLoader();
  //   };

  //   // if (patientid && !fetchedData) {
  //   fetchData();
  //   // }
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      try {
        const labsData = await FetchMedicalRecords({ uuid, type });
        
        const reportData = labsData.reportdata || [];

        const formattedData = {
          rows: reportData.map(item => ({
            header: item.eventtitle,
            unit: item.resultuom,
            value: item.resultvalue || "-",
            date: item.verifieddate || "",
          })),
        };
        
        setFormattedData(formattedData);

        // const dataForReportsView = {
        //   rows: reportData.map(item => {
        //     const combinedUrl = item.imagepath + item.documenturl;
        //     const documentCount = item.documenturl ? 1 : 0;
        //     const verifieddate = item.verifieddate != null ? item.verifieddate : ""
        //     const uniqueURLsAndDatesMap = new Map();
        //     Object.entries(item).forEach(([key, value]) => {
        //       if (key === 'imagepath' || key === 'documenturl') {
        //         uniqueURLsAndDatesMap.set(item.imagepath + item.documenturl, verifieddate);
        //       }
        //     });
        //     return {
        //     reportid: item.reportid,
        //     reportname: item.reportname,
        //     verifieddate: verifieddate,
        //     documentid: item.documentid,
        //     documenturl: combinedUrl,
        //     documentCount: documentCount,
        //     uniqueURLs: [{ url: combinedUrl, date: verifieddate }],
        //     // uniqueURLs: Array.from(uniqueURLsAndDatesMap, ([combinedUrl, verifieddate]) => ({
        //     //   combinedUrl,
        //     //   verifieddate,
        //     // })),
        //     // uniqueURLs: "",
        //     }
        //   }),
        // };

              // Group documents by reportname
      const groupedData = reportData.reduce((acc, item) => {
        const reportname = item.reportname;
        if (!acc[reportname]) {
          acc[reportname] = {
            reportid: item.reportid,
            reportname: reportname,
            documents: [],
            reportdescription: item.reportdescription,
          };
        }
        acc[reportname].documents.push({
          documentid: item.documentid,
          documenturl: item.imagepath + item.documenturl,
          verifieddate: item.verifieddate,
        });
        return acc;
      }, {});
      console.log(reportData, 'group data check')
      // Map the grouped data to the desired format
      const dataForReportsView = {
        rows: Object.values(groupedData).map(group => {
          // Filter unique URLs
          const uniqueURLs = Array.from(new Set(group.documents.map(doc => doc.documenturl)))
            .map(url => ({
              url: url,
              date: group.documents.find(doc => doc.documenturl === url).verifieddate,
            }));
          return {
            reportid: group.reportid,
            reportname: group.reportname,
            verifieddate: uniqueURLs[0]?.date || '',
            documentCount: uniqueURLs.length,
            uniqueURLs: uniqueURLs,
            reportDescription: group.reportdescription
          };
        }),
      };

        setFormattedDataForReportView(dataForReportsView);

        const dataForTable = {
          rows: reportData.map(item => ({
            header: item.eventtitle,
            unit: item.resultuom,
            date: item.verifieddate || "",
            value: item.resultvalue || "-",
          })),
        };

        setFormattedDataForTableView(dataForTable);
        console.log(formattedDataForTableView, "report view card data")
        setFetchedData(true);
      } catch (error) {
        setFetchError(true);
        showErrorToast("Failed to fetch data.");
      }
      dismissLoader();
    };

    if (uuid && type && !fetchedData) {
      fetchData();
    }
  }, [uuid, type, fetchedData]);

  const [reportid, setReportid] = useState([]);

  useEffect(()=>{
    console.log(formattedDataForReportView, 'chekcing in useEffect')
  },[formattedDataForReportView])


  const backFunction = () => {
    navigate("/patientmedicalrecord?id=" + uuid)
  };
  var navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("TableViewTab");

  const handleTabClick = (index) => {
    showLoader();
    switch (index) {
      case 0:
        setActiveTab("TableViewTab");
        break;
      case 1:
        setActiveTab("ReportViewTab");
        break;
      default:
        break;
    }

    dismissLoader();
  };

  const [documentDate, setdocumentdate] = useState([
    "initial values",
    "new initial value 2",
  ]);
  const [headerName, setHeaderName] = useState("");
  const [reportName, setReportName] = useState("");

  const [documentDates, setDocumentDates] = useState([]);
  const [documentURLs, setDocumentURLs] = useState([]);

  const [verifiedDate, setVerifiedDate] = useState("");

  const openModalRightWithData = (header, values, documetURL, reportname, verifieddate, reportidArray , rowUniqueUrlsWithDate) => () => {
    console.log(rowUniqueUrlsWithDate ,"rowdata")
    setReportid(reportidArray);
    //  const uniqueDocumentURLs = [...new Set(documetURL)];
     
    //  setDocumentURLs(uniqueDocumentURLs)
    //  setDocumentURLs(rowUniqueUrlsWithDate)
    // Filter unique URLs
    const uniqueDocumentURLs = Array.from(new Set(rowUniqueUrlsWithDate.map(item => item.url)))
    .map(url => rowUniqueUrlsWithDate.find(item => item.url === url));
    setDocumentURLs(uniqueDocumentURLs);
 
     setHeaderName(header);
 
     setDocumentDates(values);
     
     setVerifiedDate(verifieddate)
     setReportName(reportname)
     openCustomModal.current.openRight();
   };

  const handleimageselection = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    showLoader();
    navigate("/uploaddocument", { state: { file } });
    dismissLoader();
  };
  const openUploadLabs = () => {
    fileInputRef.current.click();
  };
  const formatDate = (dateString) => {
    //if (!dateString) return '-';
    return moment(dateString).format("Do MMM, YYYY");
  };

  const boxesPerRow = 10;
  useEffect(()=> {
    console.log(documentURLs)
  }, [documentURLs])
  return (
    <Box w="100%">
      <Box top="0">
        <Box
          top="0"
          display="flex"
          zIndex="banner"
          as="nav"
          w="100%"
          p="10px"
          alignItems="center"
          justifyContent="center"
          wrap="wrap"
          bg="#061027"
        >
          <Image src="/assets/imgs/midoc-logo.png" />
        </Box>

        {isLoading && <Loader />}
        <SecondaryNav
          withBack="Labs"
          goBack={backFunction}
          secondaryNavStyle={{display: {base: "none",sm: "none", md: "flex", lg: "flex"}}}
          withBackBody={
            <>
              <Box display="flex" gap="10px" alignItems="center" pr="25px">
                <TabSection
                  tabs={["Table view", "Reports view"]}
                  onClickTab={handleTabClick}
                />
                <input
                  type="file"
                  id="file-upload"
                  onChange={handleimageselection}
                  ref={fileInputRef} // Set the ref for the file input
                  accept="image/jpeg,image/jpg,image/png,image/tiff"
                  style={{ display: "none" }} // Hide the file input
                />
                {/* {formattedData && (
                  <>
                    <PrimaryButton
                      fontSize="14px"
                      variant="mdBtn"
                      buttonText="Upload report"
                      btnIcon="/assets/imgs/add.png"
                      onClick={openUploadLabs}
                    />
                  </>
                )} */}
              </Box>
            </>
          }
        />

        {/* for mobile navbar */}
        <Box display={{sm: "flex", md: "none", lg: "none"}} flexWrap="wrap" w="100%" bg="white" minH="70px" gap="0.5rem" textAlign="center" alignItems="center" justifyContent="space-between" p="1rem">
          <Box display="flex" justifyContent="space-between" alignItems="center" w="100%" mb="1rem">
            <Box w="40%" textAlign="left">
              <Box as="button" onClick={backFunction}><Image w="15px" mr="5px" src="/assets/imgs/arrow_back.png"/></Box>
            </Box>
            <Box w="60%" textAlign="left">
              <Text as="h1" color="black" fontSize="20px">Labs</Text>
            </Box>
          </Box>
          <TabSection
            tabs={["Table view", "Reports view"]}
            onClickTab={handleTabClick}
          />
        </Box>
      </Box>
      <Box>
        <Flex
          bg="#fcfcfd"
          flexDir="column"
          h="calc(100vh - 180px)"
          overflow="hidden auto"
        >
          {formattedData &&
            (activeTab === "TableViewTab" ? (
              // <DynamicTable TableData={formattedDataForTableView} />
              <Flex direction="column" overflowX="auto" width="100%">
                <Box maxW="100%" overflowX="auto">
                  <Table variant="simple" size="sm" width="100%">
                    <Tbody>
                        {formattedData.rows.map((row, rowIndex) => (
            
                          <Tr key={rowIndex}>
                            <Td  w="210px" h="66px" border="1px solid #E6E7E9" >
                              <Text fontWeight="bold">{row.header}</Text>
                              {row.unit && <Text>({row.unit})</Text>}
                            </Td>
                            {/* {Array.isArray(row.values) && row.values.map((valueArray, index) => ( */}
                              <Td w="160px" p="24px" border="1px solid #E6E7E9">
                               <Box
                                  as="p"
                                  display="flex"
                                  flexDirection="column"
                                  fontSize="14px"
                                  fontWeight="500"
                                  gap="5px"
                                  alignItems="center"
                                  sx={{ textWrap: 'nowrap' }}
                                >
                                  <Text style={{ color: 'black' }}>{row.value}</Text>
                                  <Text>{formatDate(row.date)}</Text>
                                </Box>
                              </Td>
                            {/* ))} */}
                            {Array.from({ length: boxesPerRow - (Array.isArray(row.values) ? row.values.length : 0) }, (_, index) => (
                              <Td w="160px" h="66px" key={`empty-${index}`} p="24px" border="1px solid #E6E7E9" />
                            ))}
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </Box>
              </Flex>
            ) : (
              <Flex
                wrap="wrap"
                p="20px"
                w="100%"
                justifyContent="left"
                gap="20px"
              >
                {formattedDataForReportView &&
                  formattedDataForReportView.rows.map((row, index) => (
                    <Box key={index} flex={{base: "100%",sm: "100%", md: "0 0 calc(33.333% - 20px)", lg: "0 0 calc(33.333% - 20px)"}} mb="20px">
                      <ReportViewCard
                        deleteBtn={false}
                        cardHeader={row.reportname}
                        cardSecondary={formatDate(row.verifieddate)}
                        cardBtn={row.documentCount + " document"}
                        cardText={row.reportDescription}
                        onClick={openModalRightWithData(row.header, row.values, row.documenturl, row.reportname, row.verifieddate, row.reportid, row.uniqueURLs )}
                      />
                    </Box>
                  ))}
              </Flex>
            ))}
          {formattedData == "" && (
            <Box
              w="100%"
              h="100vh"
              display="flex"
              flexDirection="column"
              gap="5px"
              justifyContent="center"
              alignItems="center"
            >
              <Image w="60px" src="/assets/svgs/biotech.svg" />
              <Box as="p">No data available</Box>
            </Box>
          )}
        </Flex>
        <Footer />
      </Box>
      <ModalRight
        ref={openCustomModal}
        secondaryHeader={reportName}
        subHeader={formatDate(verifiedDate)}
        body={
          <Flex pt="15px" flexDirection="column" h="100%">
            {/* ********* */}

            {documentURLs && documentURLs.map((item, index) => (
              <Box key={index} mb="2">
                <Button
                  as="a"
                  download={!item.url.includes("/null")}
                  w="100%"
                  h="48px"
                  borderRadius="10px"
                  border="1px solid #E6E7E9"
                  display="flex"
                  justifyContent="space-between"
                  bg="white"
                  color="#1C4EC5"
                  sx={{ _hover: { bg: "none" } }}
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    fontSize: "12px",
                  }}
                  onClick={() =>
                    downloadDocument(
                      item.url.includes("/null") ? undefined : item.url
                    )
                  }
                  cursor="pointer"
                >
                  <Box
                    as="span"
                    title={reportName}
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    w="70%"
                  >
                    {reportName} <br />
                    {/* Display date */}
                    <p style={{ color: "black", fontSize: "11px" }}>
                      {formatDate(item.date)}
                    </p>
                  </Box>
                  {!item.url.includes("/null") && (
                    <Box as="span">
                      <Image src="/assets/imgs/download.png" />
                    </Box>
                  )}
                </Button>
              </Box>
            ))}
          </Flex>
        }
      />

      <DynamicModal
        ref={dynamicModal}
        modalHeader="Delete report?"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete  <strong>"{reportName}"</strong> 
            </Text>
          </>
        }
        hasFooter={false}
      />
    </Box>
  );
}

export default PatientLabsIndex;
