import moment from "moment";
export default function formAPIOrdersConsultation({
  appointmentdate,
  doctorid,
  doctorname,
  hospital,
  imagepath,
  referredby,
  specality


}) {
  return Object.freeze({
    appointmentdate,
    doctorid,
    doctorname,
    hospital,
    imagepath,
    referredby,
    specality

  });
}