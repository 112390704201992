import formAPIImage from "./image";


var formattedOutput = null;

const formAPIModelImage = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIImage(item));


  return formattedOutput
};


export default formAPIModelImage