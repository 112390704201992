import  FetchData, { } from '../../client';
import {getLoginInfo, getUserID}  from '../../../auth';


//Sign Up 
const signUp = async (inputData) => {
    try {
  
      const result = await FetchData("midoc_patientsignup", inputData);
  
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  //Update Profile 
const updatePatientProfile = async (inputData) => {
  try {

    const result = await FetchData("midoc_updatepatientprofile", inputData);

    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
  //

  const getmedicalcondition = async (inputData) => {
    try {
      
      const result = await FetchData("midoc_getdefaultdata", inputData);
  
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const getallergies = async (inputData) => {
    try {
  
      const result = await FetchData("midoc_getdefaultdata", inputData);
  
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  //
  
  
  
  //OTP Generate 
  const generateOtp = async (inputData) => {
    try {
      let patientId = getLoginInfo();
      if(patientId){
        inputData.patientid = patientId
      }
      inputData.activeuserid = getUserID();
      const result = await FetchData("midoc_generateotp", inputData);
      
  
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  
  //Validated OTP
  
  const ValidatedOtp = async (inputData) => {
    try {
      inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
      const result = await FetchData("midoc_validateotp", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

   // Medical history
   const addMedicalHistory = async (inputData) => {
    try {
  
      const result = await FetchData("midoc_addpatienthealthdetail", inputData);
     
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  // pharmacy
  const getPharmacyList = async (inputData) => {
    try {
  
      const result = await FetchData("midoc_getpatientpreferredpharmacy", inputData);
     
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const updatePreferredPharmacy = async (inputData) => {
    try {
  
      const result = await FetchData("midoc_addpatientpreferredpharmacy", inputData);
  
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  // password
  const passwordupdate = async (inputData) => {
    try {
  
      const result = await FetchData("midoc_patientpasswordupdate", inputData);
  
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
   

  
  //Forgot Password 
  const forgotPassword = async (inputData) => {
    try {
      inputData.patientid = getLoginInfo();
      inputData.activeuserid = getUserID();
      const result = await FetchData("midoc_patientforgotpassword", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

   //verify referral code
   const verifyReferalCode = async (inputData) => {
    try {
      // inputData.patientid = getLoginInfo();
      // inputData.activeuserid = getUserID();

      // to change api
      const result = await FetchData("midoc_validatereferralcode", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };


  export {signUp, forgotPassword, generateOtp, ValidatedOtp, 
          getPharmacyList, passwordupdate, getmedicalcondition, 
          getallergies, updatePreferredPharmacy, addMedicalHistory,
          updatePatientProfile, verifyReferalCode };

  