import formAPIinvestigation from "./investigation";


var formattedOutput = null;

const formAPIModelinvestigation = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIinvestigation(item));


  return formattedOutput
};

export default formAPIModelinvestigation