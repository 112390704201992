import { Box, Button, Card, CardBody, CardFooter, CardHeader, Checkbox, CheckboxGroup, Divider, Flex, Spacer, Stack, Switch, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import Loader from "../../components/loader/loader";
import { DropdownInput, PrimaryButton, PrimaryButtonIcon, Toaster } from "../../components";
import ProfileSideBar from "./profileSidebar";
import useLoader from "../../components/loader/loaderstates";
import Footer from "../../components/footer"
import { FetchAlerts, addUpdateAlerts } from "../../middleware/services/profile/alert";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { useNavigate } from "react-router-dom";

const checkboxLabelStyles = {
  '.chakra-checkbox__label': { // targeting the label part of the checkbox
    fontSize: '14px',
    color: '#061027',
    fontWeight: '600'
  }
};

const AlertsIndex = () => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();
  const [data, setData] = useState("");
  const [autoCheckReports, setAutoCheckReports] = useState(false);
  const [checkedItems, setCheckedItems] = React.useState([false, false, false])

  const [refresh, setRefresh] = useState(false)

  const [report, setReport] = useState("0")
  const [reportNotifi, setReportNotifi] = useState("0")
  const [reportSms, setReportSms] = useState("0")
  const [reportEmail, setReportEmail] = useState("0")

  const [messageAlertRadio, setMessageAlertRadio] = useState(false)
  const [reportAlertRadio, setReportAlertRadio] = useState(false)
  const [appointmentsAlertRadio, setAppointmentsAlertRadio] = useState(false)


  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedValuesAppoitments, setSelectedValuesAppointments] = useState([]);

  const [reportsNotification, setReportsNotification] = useState(false)
  const [reportsSMS, setReportsSMS] = useState(false)
  const [reportsEmail, setReportsEmail] = useState(false)

  const [appointmentNotif, setAppointmentNotif] = useState(false)
  const [appointmentSMS, setAppointmentSMS] = useState(false)
  const [appointmentEmail, setAppointmentEmail] = useState(false)

  const [appointmentRadioState, setAppointmentRadioState] = useState("")
  const [reportRadioState, setReportRadioState] = useState("")
  const [messageRadioState, setMessageRadioState] = useState("")

  const [reportNotificationState, setReportNotificationState] = useState("")
  const [reportSmsState, setReportSmState] = useState("")
  const [reportEmailState, setReportEmailState] = useState("")

  const [appointmentNotifiState, setAppointmentNotifiState] = useState("")
  const [appointmentSmsState, setAppointmentSmsState] = useState("")
  const [appointmentEmailState, setAppointmentEmailState] = useState("")

  const [remindBeforeAppointmentsState, setRemindBeforeAppointmentsState] = useState("60 mins")
  const [remindBeforeState, setRemindBeforeState] = useState("")

  const [reminderAlertRadio, setReminderAlertRadio] = useState(false)

  const [prefrenceId, setPrefrenceId] = useState("")
  const navigate = useNavigate()







  const allChecked = checkedItems.every(Boolean);
  const [disableSetButton , setDisableSetButton] = useState(true)

  useEffect(() => {
    showLoader();
    var input = {};
    var fetchData = async () => {
      try {
        var result = await FetchAlerts(input)
        console.log(result, " get alrets modifyed res")
        if (result) {
          setData(result)
        } else {
          setData("")
          //  showErrorToast("Something went wrong!")
        }

      } catch (error) {
        console.log(error, "error in fetching account data")
      }
      dismissLoader();
    }

    fetchData();

  }, [refresh]);

  useEffect(() => {
    if (data && data.length > 0) {
      const {
        appointmentalert,
        appointmentemail,
        appointmentnotification,
        appointmentsms,
        isactive,
        messagealert,
        preferenceid,
        remindappointmentbefore,
        remindbefore,
        reportalert,
        reportemail,
        reportnotification,
        reportsms
      } = data[0];

      setPrefrenceId(preferenceid)
      if (messagealert == 1) {
        setMessageAlertRadio(true)
        setMessageRadioState(messagealert)
      } else if (messagealert == 0) {
        setMessageAlertRadio(false)
        setMessageRadioState(messagealert)
      }

      if (reportalert == 1) {
        setReportAlertRadio(true)
        setReportRadioState(reportalert)
      } else if (reportalert == 0) {
        setReportAlertRadio(false)
        setReportRadioState(reportalert)
      }

      if (appointmentalert == 1) {
        setAppointmentsAlertRadio(true)
        setAppointmentRadioState(appointmentalert)
      } else if (appointmentalert == 0) {
        setAppointmentsAlertRadio(false)
        setAppointmentRadioState(appointmentalert)
      }

      if (remindbefore) {
        setReminderAlertRadio(true)
      } else {
        setReminderAlertRadio(false)
      }

      if (reportnotification == 1) {
        setReportsNotification(true)
        setReportNotificationState(reportnotification)
      } else if (reportnotification == 0) {
        setReportsNotification(false)
        setReportNotificationState(reportnotification)
      }
      if (reportsms == 1) {
        setReportsSMS(true)
        setReportSmState(reportsms)
      } else if (reportsms == 0) {
        setReportsSMS(false)
        setReportSmState(reportsms)
      }
      if (reportemail == 1) {
        setReportsEmail(true)
        setReportEmailState(reportemail)
      } else if (reportemail == 0) {
        setReportsEmail(false)
        setReportEmailState(reportemail)
      }


      if (appointmentnotification == 1) {
        setAppointmentNotif(true)
        setAppointmentNotifiState(appointmentnotification)
      } else if (appointmentnotification == 0) {
        setAppointmentNotif(false)
        setAppointmentNotifiState(appointmentnotification)
      }
      if (appointmentsms == 1) {
        setAppointmentSMS(true)
        setAppointmentSmsState(appointmentsms)
      } else if (appointmentsms == 0) {
        setAppointmentSMS(false)
        setAppointmentSmsState(appointmentsms)
      }
      if (appointmentemail == 1) {
        setAppointmentEmail(true)
        setAppointmentEmailState(appointmentemail)
      } else if (appointmentemail == 0) {
        setAppointmentEmail(false)
        setAppointmentEmailState(appointmentemail)
      }
      setRemindBeforeAppointmentsState(remindappointmentbefore)
      setRemindBeforeState(remindbefore)
      //setReport(reportalert);
    }
    setDisableSetButton(true)
  }, [data]);


  const handleMessageRadio = () => {
    setDisableSetButton(false)
    setMessageAlertRadio(!messageAlertRadio);
    if (messageAlertRadio) {
      setMessageRadioState("0")
    } else {
      setMessageRadioState("1")
    }
  };
  const handleReportRadio = () => {
    setDisableSetButton(false)
    setReportAlertRadio(!reportAlertRadio);
    if (reportAlertRadio) {
      setReportRadioState("0")
      // alert("unchecked")
      setReportNotificationState("0")
      setReportsNotification(false);


      setReportEmailState("0")
      setReportsEmail(false);

      setReportSmState("0")
      setReportsSMS(false);
    } else {
      setReportRadioState("1")
      setReportNotificationState("1")
      setReportsNotification(true);

      setReportEmailState("1")
      setReportsEmail(true);

      setReportSmState("1")
      setReportsSMS(true);
    }
  };
  const handleAppointmentsRadio = () => {
    setDisableSetButton(false)
    setAppointmentsAlertRadio(!appointmentsAlertRadio);
    if (appointmentsAlertRadio) {
      setAppointmentRadioState("0")
      setRemindBeforeAppointmentsState("")


      setAppointmentNotifiState("0")
      setAppointmentNotif(false);

      setAppointmentEmailState("0")
      setAppointmentEmail(false);

      setAppointmentSmsState("0")
      setAppointmentSMS(false);
    } else {
      setAppointmentRadioState("1")

      setAppointmentNotifiState("1")
      setAppointmentNotif(true);

      setAppointmentEmailState("1")
      setAppointmentEmail(true);

      setAppointmentSmsState("1")
      setAppointmentSMS(true);
    }
  };

  const handleReportNotification = () => {
    setDisableSetButton(false)
    setReportsNotification(!reportsNotification);
    if (reportsNotification) {
      setReportNotificationState("0")
    } else {
      setReportNotificationState("1")
    }
  };
  const handleReportSMS = () => {
    setDisableSetButton(false)
    setReportsSMS(!reportsSMS);
    if (reportsSMS) {
      setReportSmState("0")
    } else {
      setReportSmState("1")
    }
  };
  const handleReportEmail = () => {
    setDisableSetButton(false)
    setReportsEmail(!reportsEmail);
    if (reportsEmail) {
      setReportEmailState("0")
    } else {
      setReportEmailState("1")
    }
  };

  const handleAppointmentNotif = () => {
    setDisableSetButton(false)
    setAppointmentNotif(!appointmentNotif);
    if (appointmentNotif) {
      setAppointmentNotifiState("0")
    } else {
      setAppointmentNotifiState("1")
    }
  };
  const handleAppointmentSMS = () => {
    setDisableSetButton(false)
    setAppointmentSMS(!appointmentSMS);
    if (appointmentSMS) {
      setAppointmentSmsState("0")
    } else {
      setAppointmentSmsState("1")
    }
  };
  const handleAppointmentEmail = () => {
    setDisableSetButton(false)
    setAppointmentEmail(!appointmentEmail);
    if (appointmentEmail) {
      setAppointmentEmailState("0")
    } else {
      setAppointmentEmailState("1")
    }
  };

  const selectRemindBeforeAppointments = (selectedOption) => {
    setDisableSetButton(false)
    setRemindBeforeAppointmentsState(selectedOption)
  }
  const selectRemindBefore = (selectedOption) => {
    setDisableSetButton(false)
    setRemindBeforeState(selectedOption)
  }
  const handleReminderRadio = () => {
    setDisableSetButton(false)
    setReminderAlertRadio(!reminderAlertRadio);
    if (reminderAlertRadio) {
      setRemindBeforeState("")
    }else{
      setRemindBeforeState("10 mins")
    }
  };
  useEffect(()=>{
    if (reportsNotification && reportsSMS && reportsEmail){
      setReportAlertRadio(true)
      setReportRadioState("1")
    }else{
      setReportAlertRadio(false)  
      setReportRadioState("0")
    }
    if (appointmentNotif && appointmentSMS && appointmentEmail){
      setAppointmentsAlertRadio(true)
      setAppointmentRadioState("1")
    }else{
      setAppointmentsAlertRadio(false)  
      setAppointmentRadioState("0")
    }
   // setDisableSetButton(false)
  },[reportsNotification , reportsSMS, reportsEmail,
    appointmentNotif , appointmentSMS, appointmentEmail])
    
  // useEffect(()=>{
  //   if(allChecked){
  //     setReport("1")
  //     setReportNotifi("1")
  //     setReportSms("1")
  //     setReportEmail("1")
  //   }
  // },[allChecked, checkedItems])

  const inputForAddUpdate = {
    "preferenceid": prefrenceId,

    "reportalert": reportRadioState,
    "reportnotification": reportNotificationState,
    "reportsms": reportSmsState,
    "reportemail": reportEmailState,
    "appointmentalert": appointmentRadioState,
    "appointmentnotification": appointmentNotifiState,
    "appointmentsms": appointmentSmsState,
    "appointmentemail": appointmentEmailState,
    "messagealert": messageRadioState,

    "remindappointmentbefore": remindBeforeAppointmentsState,
    "remindbefore": remindBeforeState,
  }
  // useEffect(()=>{
  //   setDisableSetButton(false)
  // },[inputForAddUpdate])
  const setAlert = () => {
    console.log(inputForAddUpdate, " input alert")


    const setAlertCall = async () => {
      if(reportRadioState == 0 && reportNotificationState == 0 && reportSmsState == 0 && reportEmailState == 0 
         && appointmentRadioState == 0  && appointmentNotifiState == 0  && appointmentSmsState == 0  && appointmentEmailState  == 0 
         &&messageRadioState == 0 && remindBeforeState == ""){
          showErrorToast("Please select any alerts to set!")
          return false;
         }
      try {
        showLoader();
        const result = await addUpdateAlerts(inputForAddUpdate);
        console.log(inputForAddUpdate, " input alert")
        console.log(result, " result alert")
        if (result.output) {
          setRefresh(!refresh)
          showSuccessToast("Updated Successfully.")
        } else {
          showErrorToast("Something went wrong!")
        }
      } catch (error) {
        console.log(error, "error in updating patient profile")

      }
      dismissLoader();
    }
    // uncoment below line for API call
    setAlertCall();
  }

  useEffect(() => {
    const updatedSelectedValues = [];
    if (reportsNotification) updatedSelectedValues.push('notification');
    if (reportsSMS) updatedSelectedValues.push('sms');
    if (reportsEmail) updatedSelectedValues.push('email');
    setSelectedValues(updatedSelectedValues);
  }, [reportsNotification, reportsSMS, reportsEmail]);

  useEffect(() => {
    const updatedSelectedValues = [];
    if (appointmentNotif) updatedSelectedValues.push('notification');
    if (appointmentSMS) updatedSelectedValues.push('sms');
    if (appointmentEmail) updatedSelectedValues.push('email');
    setSelectedValuesAppointments(updatedSelectedValues);
  }, [appointmentNotif, appointmentSMS, appointmentEmail]);

  // useEffect(()=>{
  //   alert(appointmentRadioState)
  // },[appointmentRadioState])




  return (
    <>
      <Box w="100%" display={{base: "none", lg: "block"}}>
        <Box top="0" zIndex="banner">
          <Navbar />

          {isLoading && <Loader />}
          <SecondaryNav
            sideBarWidth="250px"
            defaultHeader="Profile"
            defaultSecondayHeader="Alerts"
            defaultNavBody={
              <>
                <Box
                  display="flex"
                  gap="10px"
                  alignItems="center"
                  pr="25px"
                >
                  <PrimaryButton
                    fontSize="14px"
                    variant="mdBtn"
                    buttonText="Set Alerts"
                    isDisabled={disableSetButton}
                    onClick={setAlert}
                  />
                </Box>
              </>
            }
          />
        </Box>
        <Box h="calc(100vh - 140px)" overflow="hidden auto">
          <Flex>
            <Box p="0" width="250px">
              <ProfileSideBar />
            </Box>

            <Flex bg="#fcfcfd" flexDirection="column" gap="20px" w="100%" p="24px 32px">
              {/* Reports start */}
              <Card borderRadius="10px" border="1px solid #E6E7E9" shadow="none">
                <CardHeader>
                  <Flex>
                    <Text fontSize="16px" fontWeight="600">Reports</Text>
                    <Spacer />
                    <Switch colorScheme='green' size='lg'
                      isChecked={reportAlertRadio}
                      onChange={handleReportRadio} />
                  </Flex>
                  <Divider mt="10px" />
                </CardHeader>
                <CardBody pt="0">
                  <CheckboxGroup colorScheme='green'
                    // value={reportsNotification ? ['notification'] : reportsSMS ? ['sms'] : reportsEmail ? ['email'] : []}
                    value={selectedValues}
                  >
                    <Stack spacing={3} direction="column">
                      <Checkbox
                        onChange={handleReportNotification}
                        sx={checkboxLabelStyles} value='notification'>Notification</Checkbox>
                      <Checkbox
                        onChange={handleReportSMS}
                        sx={checkboxLabelStyles} value='sms'>SMS</Checkbox>
                      <Checkbox
                        onChange={handleReportEmail}
                        sx={checkboxLabelStyles} value='email'>Email</Checkbox>
                    </Stack>
                  </CheckboxGroup>
                </CardBody>
              </Card>
              {/* Reports end */}

              {/* Appointments start */}
              <Card borderRadius="10px" border="1px solid #E6E7E9" shadow="none">
                <CardHeader>
                  <Flex>
                    <Text fontSize="16px" fontWeight="600">Appointments</Text>
                    <Spacer />
                    <Switch colorScheme='green' size='lg'
                      isChecked={appointmentsAlertRadio}
                      onChange={handleAppointmentsRadio} />
                  </Flex>
                  <Divider mt="10px" />
                </CardHeader>
                <CardBody pt="0">
                  <CheckboxGroup colorScheme='green' value={selectedValuesAppoitments}>
                    <Stack spacing={3} direction="column">
                      <Checkbox sx={checkboxLabelStyles} value='notification'
                        onChange={handleAppointmentNotif}
                      >Notification</Checkbox>

                      <Checkbox sx={checkboxLabelStyles} value='sms'
                        onChange={handleAppointmentSMS}
                      >SMS</Checkbox>
                      <Checkbox sx={checkboxLabelStyles} value='email'
                        onChange={handleAppointmentEmail}
                      >Email</Checkbox>
                    </Stack>
                  </CheckboxGroup>
                  <Divider mt="10px" />
                </CardBody>
                <CardFooter pt="0" >
                  <DropdownInput
                    inputStyleCss={{ maxW: "50%" }}
                    variant="fullSide"
                    dropDownPlace="Remind before"
                    options={["10 mins", "20 mins", "30 mins","40 mins", "50 mins", "60 mins"]}
                    defaultOption={remindBeforeAppointmentsState}
                    onClick={selectRemindBeforeAppointments}
                  />
                </CardFooter>
              </Card>
              {/* Appointments end */}

              {/* Reminders start */}
              <Card borderRadius="10px" border="1px solid #E6E7E9" shadow="none">
                <CardHeader>
                  <Flex>
                    <Text fontSize="16px" fontWeight="600">Reminders</Text>
                    <Spacer />
                    <Switch colorScheme='green' size='lg'
                      isChecked={reminderAlertRadio}
                      onChange={handleReminderRadio} />
                  </Flex>
                  {reminderAlertRadio &&
                    <Divider mt="10px" />
                  }
                </CardHeader>
                {reminderAlertRadio &&
                  <CardFooter pt="0"  >
                    <DropdownInput
                      inputStyleCss={{ maxW: "50%" }}
                      variant="fullSide"
                      dropDownPlace="Remind before"
                      options={["10 mins", "20 mins", "30 mins","40 mins", "50 mins", "60 mins"]}
                      defaultOption={remindBeforeState}
                      onClick={selectRemindBefore}
                    />
                  </CardFooter>
                }

              </Card>
              {/* Reminders end */}

              {/* Messages start */}
              <Card borderRadius="10px" border="1px solid #E6E7E9" shadow="none">
                <CardHeader>
                  <Flex>
                    <Text fontSize="16px" fontWeight="600">Messages</Text>
                    <Spacer />
                    <Switch colorScheme='green' size='lg'
                      isChecked={messageAlertRadio}
                      onChange={handleMessageRadio} />
                  </Flex>
                </CardHeader>
              </Card>
              {/* Messages end */}
            </Flex>
          </Flex>
          <Footer />
        </Box>
      </Box>

      {/* Mobile responsive start */}
      <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/profile')}
          header="Alerts"
        />

        <Box pos="fixed" bottom="10%" display="flex" justifyContent="center" w="100%" zIndex="99">
          <PrimaryButton
            fontSize="14px"
            variant="mdBtn"
            buttonText="Set Alerts"
            onClick={setAlert}
          />
        </Box>

        <Flex bg="#fcfcfd" flexDir="column" p="1.5rem" pb="4rem" gap="1rem" h="calc(100vh - 190px)" overflow="hidden auto">
          {/* Reports start */}
          <Card borderRadius="10px" border="1px solid #E6E7E9" shadow="none">
                <CardHeader>
                  <Flex>
                    <Text fontSize="16px" fontWeight="600">Reports</Text>
                    <Spacer />
                    <Switch colorScheme='green' size='lg'
                      isChecked={reportAlertRadio}
                      onChange={handleReportRadio} />
                  </Flex>
                  <Divider mt="10px" />
                </CardHeader>
                <CardBody pt="0">
                  <CheckboxGroup colorScheme='green'
                    // value={reportsNotification ? ['notification'] : reportsSMS ? ['sms'] : reportsEmail ? ['email'] : []}
                    value={selectedValues}
                  >
                    <Stack spacing={3} direction="column">
                      <Checkbox
                        onChange={handleReportNotification}
                        sx={checkboxLabelStyles} value='notification'>Notification</Checkbox>
                      <Checkbox
                        onChange={handleReportSMS}
                        sx={checkboxLabelStyles} value='sms'>SMS</Checkbox>
                      <Checkbox
                        onChange={handleReportEmail}
                        sx={checkboxLabelStyles} value='email'>Email</Checkbox>
                    </Stack>
                  </CheckboxGroup>
                </CardBody>
          </Card>
          {/* Reports end */}

          {/* Appointments start */}
          <Card borderRadius="10px" border="1px solid #E6E7E9" shadow="none">
            <CardHeader>
              <Flex>
                <Text fontSize="16px" fontWeight="600">Appointments</Text>
                <Spacer />
                <Switch colorScheme='green' size='lg'
                  isChecked={appointmentsAlertRadio}
                  onChange={handleAppointmentsRadio} />
              </Flex>
              <Divider mt="10px" />
            </CardHeader>
            <CardBody pt="0">
              <CheckboxGroup colorScheme='green' value={selectedValuesAppoitments}>
                <Stack spacing={3} direction="column">
                  <Checkbox sx={checkboxLabelStyles} value='notification'
                    onChange={handleAppointmentNotif}
                  >Notification</Checkbox>

                  <Checkbox sx={checkboxLabelStyles} value='sms'
                    onChange={handleAppointmentSMS}
                  >SMS</Checkbox>
                  <Checkbox sx={checkboxLabelStyles} value='email'
                    onChange={handleAppointmentEmail}
                  >Email</Checkbox>
                </Stack>
              </CheckboxGroup>
              <Divider mt="10px" />
            </CardBody>
            <CardFooter pt="0" >
              <DropdownInput
                // inputStyleCss={{ maxW: "50%" }}
                variant="fullSide"
                dropDownPlace="Remind before"
                options={["10 mins", "20 mins", "30 mins","40 mins", "50 mins", "60 mins"]}
                defaultOption={remindBeforeAppointmentsState}
                onClick={selectRemindBeforeAppointments}
              />
            </CardFooter>
          </Card>
          {/* Appointments end */}

          {/* Reminders start */}
          <Card borderRadius="10px" border="1px solid #E6E7E9" shadow="none">
            <CardHeader>
              <Flex>
                <Text fontSize="16px" fontWeight="600">Reminders</Text>
                <Spacer />
                <Switch colorScheme='green' size='lg'
                  isChecked={reminderAlertRadio}
                  onChange={handleReminderRadio} />
              </Flex>
              {reminderAlertRadio &&
                <Divider mt="10px" />
              }
            </CardHeader>
            {reminderAlertRadio &&
              <CardFooter pt="0"  >
                <DropdownInput
                  // inputStyleCss={{ maxW: "50%" }}
                  variant="fullSide"
                  dropDownPlace="Remind before"
                options={["10 mins", "20 mins", "30 mins","40 mins", "50 mins", "60 mins"]}
                  defaultOption={remindBeforeState}
                  onClick={selectRemindBefore}
                />
              </CardFooter>
            }

          </Card>
          {/* Reminders end */}

          {/* Messages start */}
          <Card borderRadius="10px" border="1px solid #E6E7E9" shadow="none">
            <CardHeader>
              <Flex>
                <Text fontSize="16px" fontWeight="600">Messages</Text>
                <Spacer />
                <Switch colorScheme='green' size='lg'
                  isChecked={messageAlertRadio}
                  onChange={handleMessageRadio} />
              </Flex>
            </CardHeader>
          </Card>
          {/* Messages end */}
        </Flex>
       </Box>
    </>
  );
};

export default AlertsIndex;
