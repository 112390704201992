import React, { useState, useEffect } from "react";
import { saveToken,savePatientDetails,saveUsername,saveLoginID, savePatientImage, savePatientdob, savePatientgender } from "../../auth";
import {
  FetchLogin,
  VerifyUser,
  FetchMobileLogin,
} from "../../middleware/services/login/index";
import { redirect, useNavigate } from "react-router-dom";
import {
  Box,
  Center,
  VStack,
  Image,
  Heading,
  Text,
  InputGroup,
  Button,
  Divider,
  Icon,
  InputRightElement,
} from "@chakra-ui/react";
import { FaGoogle, FaFacebook } from "react-icons/fa";
import {
  PrimaryInputDropdown,
  PrimaryButton,
  PrimaryButtonIcon,
  PasswordInput,
  PrimaryInput,
  Toaster,
} from "../../components";
import { forgotPassword, passwordupdate } from "../../middleware/services/signup";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";

function NewPassword() {
  const { showSuccessToast, showErrorToast } = Toaster();
  const [isError, setIsError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isPasswordButtonDisabled, setIsPasswordButtonDisabled] =
    useState(true);
  const navigate = useNavigate();
  var emailFromLocal = localStorage.getItem("Email")
  const [email, setEmail] = useState(emailFromLocal);
  var mobileFromLocal = localStorage.getItem("mobile")
  const [mobileno, setMobileNo] = useState(mobileFromLocal);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [redirectToPassword, setRedirectTopassword] = useState(false);
  const [redirectToMobilePassword, setRedirectToMobilepassword] = useState(false);
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [isMobileLoginClicked, setIsMobileLoginClicked] = useState(false);
  const [isEmailClicked, setIsEmailLoginClicked] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  var redirect = useNavigate();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  
  const [countryid, setCountryid]= useState("");
  const [countrycode, setCountryCode] = useState("");

  // Functions for handling form inputs and button clicks:
  useEffect(() => {}, []);
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    const newEmail = e.target.value;
    setIsButtonDisabled(newEmail.trim() === "");
    if (newEmail.trim() === "") {
      setIsError(false);
    }
  };
  const handleMobileChange = (e) => {
    const inputText = e.target.value;
    const maxLength = 10;
  
    // Limit the input to a maximum length of 10 characters
    if (inputText.length <= maxLength) {
      setMobileNo(inputText);
    }

    setMobileNo(e.target.value);
    setMobileNo(e.target.value.replace(/\D/g, "").slice(0, 10)); // Remove non-digit characters
    const newMobile = e.target.value;
    setIsButtonDisabled(newMobile.trim() === "");
    if (newMobile.trim() === "") {
      setIsError(false);
    }
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    const newPassword = e.target.value;
    setIsPasswordButtonDisabled(newPassword.trim() === "");
    // if (newPassword.trim() === "") {
    //   setIsError(false);
    // }
  };
  const handleReEnterPassword = (e) => {
    setConfirmPassword(e.target.value);
    const newPassword = e.target.value;
    setIsPasswordButtonDisabled(newPassword.trim() === "");
    // if (newPassword.trim() === "") {
    //   setIsError(false);
    // }
  };
  //Verify User with Email
  const handleSubmit = async () => {
    if (email.trim === "") {
      showErrorToast("Email cannot be empty");
      setIsError(true);
      return;
    } else if (!emailRegex.test(email)) {
      showErrorToast("Please Enter Valid Email Address");
      setIsError(true);
      return;
    } else {
      try {
        const response = await VerifyUser({
          email,
          type: "patient",
        });
        if (response.output.result === "0") {
          localStorage.setItem("NewUserEmail", email)
          setRedirectTopassword(false);
          showErrorToast("Uh-oh! The email you have entered does not exist.");
          setIsError(true);
          navigate("/signup")
        } else {
          setRedirectTopassword(true);
          setIsEmailLoginClicked(false);
          setIsError(false);
        }
      } catch (error) {
        console.error("failed:", error.response.data);
      }
    }
  };
  //Verify User with Mobile
  const handleMobileSubmit = async () => {
    if (mobileno === "") {
      showErrorToast("Mobile cannot be empty");
      return;
    } else {
      try {
        const response = await VerifyUser({
          mobileno,
          type: "patient",
          "countryid": countryid,
        });
        if (response.output.result === "0") {
          setRedirectToMobilepassword(false);
          showErrorToast("Uh-oh! The mobile you have entered does not exist");
          navigate("/signup")
        } else {
          setRedirectToMobilepassword(true);
        }
      } catch (error) {
        console.error("failed:", error.response.data);
      }
    }
  };
  //Submit Password
  const handleLoginSubmit = async (type) => {
    // if (!passwordRegex.test(password)) {
    //   showErrorToast('Password must contain at least one letter, one number, and be at least eight characters long');
    //   return;
    // }
    if (password.trim() === "") {
      showErrorToast("Password cannot be empty");
    } else {
      try {
        if (type === "email") {
          const response = await FetchLogin({
            email,
            password,
          });
          if (response.output.result == "failure") {
            setRedirectToHome(false);
            showErrorToast("Password is incorrect");
          } else {
            // if (response.output.data.firstname) {
            //   saveUsername(response.output.data.firstname);
            // }
            // Save the token
            if (response.output.token) {
              saveToken(response.output.token);
            }
            if(response.output.data){
              savePatientDetails(response.output.data)
            }
            // if (response.output.data.id) {
            //   saveLoginID(response.output.data.id);
            // }
            // if (response.output.data.patientimage) {
            //   const patientimage = response.output.data.imagepath+response.output.data.patientimage;
            //   savePatientImage(patientimage);
            // }
            // if (response.output.data.gender) {
            //   const gender = response.output.data.gender;
            //   savePatientgender(gender);
            // }
            // if (response.output.data.dob) {
            //   const dob = response.output.data.dob;
            //   savePatientdob(dob);
            // }
            // Set the state to redirect to the home page
            setRedirectToHome(true);
            navigate("/home");
          }
        } else {
          const response = await FetchMobileLogin({
            mobileno,
            password,
            "countryid": countryid,
          });
          if (response.output.result == "failure") {
            setRedirectToHome(false);
            showErrorToast("Password is incorrect");
          } else {
            // if (response.output.data.firstname) {
            //   saveUsername(response.output.data.firstname);
            // }
            // Save the token
            if (response.output.token) {
              saveToken(response.output.token);
            }
            if(response.output.data){
              savePatientDetails(response.output.data)
            }
            // if (response.output.data.id) {
            //   saveLoginID(response.output.data.id);
            // }
            // if (response.output.data.patientimage) {
            //   const patientimage = response.output.data.imagepath+response.output.data.patientimage;
            //   savePatientImage(patientimage);
            // }
            // if (response.output.data.gender) {
            //   const gender = response.output.data.gender;
            //   savePatientgender(gender);
            // }
            // if (response.output.data.dob) {
            //   const dob = response.output.data.dob;
            //   savePatientdob(dob);
            // }
            // Set the state to redirect to the home page
            setRedirectToHome(true);
            navigate("/myhealth");
          }
        }

        //console.log(response, "loginres");
      } catch (error) {
        console.error("Login failed:", error.response.data);
      }
    }
  };
  // const countrycode = document.getElementById('countrycode').value;
  // alert(countrycode)
  // Enter Click Submit Email
  const handleEmailKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };
  // Enter Click Submit Mobile
  const handleMobileKeyPress = (e) => {
    if (e.key === "Enter") {
      handleMobileSubmit();
    }
  };
  // Enter Click Email Password Submit
  const handleEmailpassKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLoginSubmit("email");
    }
  };
  // Enter Click mobile Password Submit
  const handleMobilepassKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLoginSubmit("mobile");
    }
  };
  const handleConfirmPasswordKeyPress = (e) => {
    if (e.key === "Enter") {
      ChangePasswordFunction();
    }
  };
  


  const storedEmail = localStorage.getItem('Email');
const storedMobile = localStorage.getItem('mobile');

useEffect(()=>{
  
  var countryidFromLocal = localStorage.getItem("countryidProp")
  var countrycodeFromLocal = localStorage.getItem("countrycodeProp")
  if(countryidFromLocal){
    setCountryid(countryidFromLocal)
  }
  if(countrycodeFromLocal){
    setCountryCode(countrycodeFromLocal)
  }
  
  if(storedEmail){
    setIsEmailLoginClicked(true)
    
    setIsMobileLoginClicked(false)
  }else if(storedMobile){
    setIsMobileLoginClicked(true)
    setIsEmailLoginClicked(false)
  }else {
    
    setIsEmailLoginClicked(true)
    
    setIsMobileLoginClicked(false)
  }
  var imageURL = localStorage.getItem("imageUrl")
  setImageUrl(imageURL)
 
  
},[])

useEffect(()=>{
  const verifyUserFunc = async()=>{
    try {
    const response = await VerifyUser({
      email,
      type: "patient",
    });
    console.log(response)
  } catch (error) {
  console.error("failed:", error.response);
}
verifyUserFunc();

  }
},[])

  const ChangePasswordFunction = async()=>{
    showLoader();
    if(password == ''){
        showErrorToast("Please Enter Password")
      }else if(confirmPassword == ''){
        showErrorToast("Please Enter Confirm Password")
      }else if(password.length < 8 || confirmPassword.length < 8){
        showErrorToast("Password should contain minimum 8 characters.")
      }else if( password.trim() !== confirmPassword.trim()){
        
        showErrorToast("Password does not match!")
      }else{
        var input;
      // api call here 
      if(isEmailClicked){
        input = {
          email, 
          password, 
        };
      }
      if(isMobileLoginClicked){
        input = {
          "mobile":mobileno, 
          password, 
         // "countrycode":"+1",
          "countryid": countryid,
        };
      }
       
       console.log(input, "input")
       try {
        var result = await forgotPassword(input);
       
       console.log(result, "password update ");
       if(result.output.result == "success"){
        showSuccessToast("Password Updated Successfully")
        //signup call here - then navigate
        var inputForLogin = {
          
        email,
        password
        }
        if(isEmailClicked){
          inputForLogin = {
            email,
            password
            }
            //console.log(inputForLogin ," INPUT FOR LOGIN --")
            const response = await FetchLogin(inputForLogin);
            //console.log(inputForLogin," input for login")
           if(response.output.result == "success"){
            
           // showSuccessToast("Successfully Registered")
            setTimeout(() => {
             
            sessionStorage.removeItem('value');
            sessionStorage.removeItem('value2');
            localStorage.clear();
            // if (response.output.data.firstname) {
            //   saveUsername(response.output.data.firstname);
            // }
            // Save the token
            if (response.output.token) {
              saveToken(response.output.token);
            }
            if(response.output.data){
              savePatientDetails(response.output.data)
            }
            // if (response.output.data.id) {
            //   saveLoginID(response.output.data.id);
            // }
            // if (response.output.data.patientimage) {
            //   const patientimage = response.output.data.imagepath+response.output.data.patientimage;
            //   savePatientImage(patientimage);
            // }
            // if (response.output.data.gender) {
            //   const gender = response.output.data.gender;
            //   savePatientgender(gender);
            // }
            // if (response.output.data.dob) {
            //   const dob = response.output.data.dob;
            //   savePatientdob(dob);
            // }
             navigate('/home');
            }, 500);
       
           }else{
            //console.log("error in sign in")
           }
             } else if ( result.output.message == "User  do not exists") {
              showErrorToast("User does not exists.")
             }
          //    showSuccessToast("Password Updated Successfully")
          //    sessionStorage.setItem('value', password);
          //    setTimeout(() => {
          //      redirect("/success")
          //    }, 1000);
       // }
        if(isMobileLoginClicked){
          inputForLogin = {
            "mobile":mobileno,
            password,
           // "countrycode":"+1",
          "countryid": countryid,
            }
          //  console.log(inputForLogin ," INPUT FOR LOGIN mobile--")
            const response = await FetchMobileLogin(inputForLogin);
             
           if(response.output.result == "success"){
            
           // showSuccessToast("Successfully Registered")
            setTimeout(() => {
             
            sessionStorage.removeItem('value');
            sessionStorage.removeItem('value2');
            localStorage.clear();
            // if (response.output.data.firstname) {
            //   saveUsername(response.output.data.firstname);
            // }
            // Save the token
            if (response.output.token) {
              saveToken(response.output.token);
            }
            if(response.output.data){
              savePatientDetails(response.output.data)
            }
            // if (response.output.data.id) {
            //   saveLoginID(response.output.data.id);
            // }
            // if (response.output.data.patientimage) {
            //   const patientimage = response.output.data.imagepath+response.output.data.patientimage;
            //   savePatientImage(patientimage);
            // }
            // if (response.output.data.gender) {
            //   const gender = response.output.data.gender;
            //   savePatientgender(gender);
            // }
            // if (response.output.data.dob) {
            //   const dob = response.output.data.dob;
            //   savePatientdob(dob);
            // }
             navigate('/home');
            }, 500);
       
           }else{
            //console.log("error in sign in")
           }
             } else if ( result.output.message == "User  do not exists") {
              showErrorToast("User does not exists.")
             }
          //    showSuccessToast("Password Updated Successfully")
          //    sessionStorage.setItem('value', password);
          //    setTimeout(() => {
          //      redirect("/success")
          //    }, 1000);
       // }
      //  }
       }
  } catch (error) {
        //console.log(error,"error in change password")
       }
       
      
    }
    dismissLoader();
  }
  const handlebackbutton =()=>{
    redirect("/otpforgotpassword")
  }
  return (
    <Box
      w="100vw"
      h="100vh"
      bg="#061027"
      bgImage="url('/assets/imgs/login-bg.png')"
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      
      {isLoading && <Loader />}
      <Center
        h="100vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
         <Box as="button" bg="none" fontSize="14px" pos="absolute" left="3%" top="5%" color="white" display="flex" justifyContent="center" alignItems="center" gap="8px" p="10px 16px" border="1px solid #384052" borderRadius="8px"
         onClick={handlebackbutton}
         >
        <Image w="14px" src="/assets/imgs/left-arrow-white.png" />
        <Text>Back</Text>
      </Box>
  
        <Box>
          <Image src="/assets/imgs/midoc-logo.png" alt="Logo" />
        </Box>
      
       
          <VStack
            spacing={4}
            p={8}
            m={4}
            bg="white"
            boxShadow="xl"
            borderRadius="lg"
            width={["90%", "400px"]}
            gap="0"
          >
            <Box>
              <Image
                src={imageUrl ? imageUrl : "/assets/imgs/no-image-icon.png"}
                w="48px"
                h="auto"
                borderRadius="10px"
              />
            </Box>
            <Box as="h1">Welcome Back</Box>
            <Box mb="20px">
              <Text>Create a new password to continue</Text>
            </Box>
            <InputGroup>
              {/* <PrimaryInput
                inputPlace="Password"
              //  defaultValue={email}
                onChange={handlePasswordChange}
              /> */}
               <PasswordInput
                passInputPlace="Enter your Password"
                onChange={handlePasswordChange}
               // onKeyPress={handleEmailpassKeyPress}
                autoFocus={true}
                variant="topside"
              />
            </InputGroup>
            <InputGroup>
              {/* <PrimaryInput
                inputPlace="Re-enter Password"
              //  defaultValue={email}
                onChange={handleReEnterPassword}
                onKeyPress={handleConfirmPasswordKeyPress}
              />  */}
              <PasswordInput
              passInputPlace="Re-enter Password"
              onChange={handleReEnterPassword}
              onKeyPress={handleConfirmPasswordKeyPress}
              variant="downSide"
            />

            </InputGroup>
            <PrimaryButton
              colorScheme="blue"
              width="full"
              buttonText={"Continue"}
              isDisabled={false}
              onClick={ChangePasswordFunction}
            />
          </VStack>
       
        <Box as="p" fontSize="14px" color="#898D97">
          © 2024 midoc, Inc.
        </Box>
      </Center>
    </Box>
  );
}

export default NewPassword;
