import { css, Global } from '@emotion/react';
import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom'; 
import { Link, Menu, MenuItem, MenuButton, Button, MenuList } from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";
import {
  Logout,
  SwitchUser
} from "../../middleware/services/login/index";
import {
  FetchFamilyList
} from "../../middleware/services/myhealth/familyhistory";
import {
  Flex,
  Box,
  IconButton,
  Image,
  useColorMode,
  Text,
  Spacer,
} from '@chakra-ui/react';
import { point } from '@antv/g2plot';
import ModalRight from '../modal-right';
import { getNotifications } from '../../middleware/services/notifications';
import useLoader from '../loader/loaderstates';
import Loader from '../loader/loader';
import { getPatientProfile, getusername, savePatientDetails, supportEmail } from '../../auth';
import { AiDoctor } from '../ai-doctor/index';
import LocationSearch from './searchbar';
import {
  PrimaryButton,
    Toaster,
  } from "../../components";
  import { CONFIG } from '../../appconfig/appconfig';
import { FetchHomeData } from '../../middleware/services/home';
import DynamicModal from '../dynamic-modal';
import { FetchSubscription } from '../../middleware/services/profile/subscription';



const Navbar = ({messageModal = false}) => {
const {isLoading, showLoader, dismissLoader } = useLoader();
const [isNestedPage, setIsNestedPage] = useState(false);
const [isHomepage, setIsNestedPageHome] = useState(false);
const [isProfilePage, setIsProfilePage] = useState(false);
const [isAppointment, setIsAppointment] = useState(false);
const [patientimage, setPatientImage] = useState("");
const [userName, setUserName] = useState("");
const [notificationCount, setNotificationCount] = useState(0);
const location = useLocation();
const hideOnRoutes = ['/uploaddocument', '/prescriptionupload', '/payment','/chat', '/doctorprofile', '/appointmentdetails', '/appointmentpayment','/imagingupload', '/vitals'];
const showAiDoctor = !hideOnRoutes.includes(location.pathname);
const switchLocation = useRef()
const upgradePlanModal = useRef();
const contactSalesModal = useRef();
const { showSuccessToast, showErrorToast } = Toaster();


var [data, setData] = useState(null);
  var [patientid, setPatientid] = useState();
  const [currentlocation, setLocation] = useState({
    latitude: null,
    longitude: null,
    address: '',
  });

  var navigate = useNavigate()
  useEffect(()=>{
    var patientimagelocal = localStorage.getItem("patientimage")
    if(patientimagelocal && patientimagelocal != "https://midocfileserver.blob.core.windows.net/midocdev/"){
      setPatientImage(patientimagelocal)
    }else{
      var patientImage = getPatientProfile();
      setPatientImage(patientImage)
    }
    var userNameLocal = localStorage.getItem("username")
    if(userNameLocal){
      setUserName(userNameLocal)
    }else{
      var userName =  (item && (item.firstname || item.lastname || item.fullname)) ? (item.firstname + " " + item.lastname || item.fullname) : "";
      setUserName(userName)
    }
    const mylocation = localStorage.getItem("location")
    if(mylocation == ""){
      getLocation();
    }
    if(mylocation){
      var locationdetails = JSON.parse(mylocation);
      setLocation({
        latitude: locationdetails.latitude,
        longitude: locationdetails.longitude,
        address: locationdetails.address,
      });
    }
    // fetchNotificationCount();

    fetchPlan();
   
    
  },[messageModal])

  // useEffect(()=>{
  //   fetchNotificationCount();
  // },[isHomepage, isNestedPage, isProfilePage, isAppointment])
  
  const jsonData = localStorage.getItem('patientDetails');
  const item = JSON.parse(jsonData);
  const username = (item && (item.fullname || item.firstname)) ? (item.fullname || item.firstname) : "defaultUsername";


  const notification = React.useRef();
  const handleLogout = async () => {
    try {
      const result = await Logout({
        patientid,
      });
      //console.log(result, "logout");
      showLoader();
      if (result.output.result === "success") {
        localStorage.clear();
        sessionStorage.clear();
        
        navigate('/login', { replace: true })
        dismissLoader();
      } else {
        //alert("No data");
      }
    } catch (error) {
      console.error("Error in DashboardIndex:", error);
    }
    
  };
  const linkStyle = {
    textDecoration: 'none',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    height: '100%', 
    padding: '0 5px',
  };

  const activeLinkStyle = {
    ...linkStyle,
    // borderBottom: '5px solid #14C585',
    padding: "0 5px",
    position: 'relative', 
    _after: {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '5px',
      backgroundColor: 'green',
      borderRadius: '5px 5px 0 0',
      left: '50%' // Rounded top corners
    },
  };

  var input = {

  }
  const showNotifications = () =>{

    const fetchNotifications  = async() =>{
      showLoader();
      try {
        var result = await getNotifications(input)
       // console.log(result, "Notifictions formatted data")
        setData(result)

        setTimeout(async() => {
         fetchNotificationCount();

        }, 100);

      } catch (error) {
        console.log(error, "error in fetching notifications")
      }
      dismissLoader();
    }
    fetchNotifications();
    notification.current.openRight()
  }

  const fetchNotificationCount = async() =>{
    showLoader();
    try {

        const resultOfHomePage = await FetchHomeData({
          "platformtype":"web"
        });
        if(resultOfHomePage){
          
          var notificationcount = localStorage.getItem("notificationcount")
          setNotificationCount(notificationcount)
        }


    } catch (error) {
      console.log(error, "error in fetching notifications")
    }
    dismissLoader();

  }
  const myHealthRelatedPaths = [
    "/myhealth",
    "/vitals",
    "/conditions",
    "/surgeries",
    "/medications",
    "/allergies",
    "/familyhistory",
    "/vaccination",
    "/socialhistory",
    "/prescriptionupload",
    "/uploaddocument",
    "/myhealth/labs",
    "/myhealth/imaging",
    "/imagingupload"
  ];

  const profilePaths = [
    "/profile",
    "/account",
    "/rewards",
    "/aitokens",
    "/reports",
    "/family",
    "/orders",
    "/profile-appointment",
    "/doctors",
    "/pharmacy",
    "/medicalrecord",
    "/invoices",
    "/subscription",
    "/paymentmethods",
    "/refer-a-friend",
    "/videolibrary",
    "/alerts",
    "/profileappointment"
]

const appointmentPaths = [
  "/appointments",
  "/all-appoinments",
  "/doctorprofile",
  "/appointmentdetails",
  "/appointmentpayment",
  "/appointments/doctors",
  "/visitoverview"
]
const homePaths = [
  "/home/treatmentteam",
  "/home/recentactivity",
  "/home",
  "/home/labs"
]


  useEffect(() => {
    const currentPath = location.pathname;
    setIsNestedPage(myHealthRelatedPaths.includes(currentPath));
    setIsNestedPageHome(homePaths.includes(currentPath));
    setIsProfilePage(profilePaths.includes(currentPath));
    setIsAppointment(appointmentPaths.includes(currentPath));

    fetchNotificationCount();

  }, [location.pathname]);

  const handlefamilydetails = ()=>{
    fetchData();
  }
  const fetchData = async () => {
    //showLoader();
    try {
      const result = await FetchFamilyList({
        patientid,
        type: "switchuser",
      });
      //console.log(result, "family response");
      if(result.output.result === "success") {
        setData(result.output.data);
        //console.log(data)
        //dismissLoader();
      } else {
        //alert("No data");
      }
    } catch (error) {
      console.error("Error in DashboardIndex:", error);
    }
  };

  var fetchPlan = async () => {
    var input = {};
    showLoader();
    try {
      var result = await FetchSubscription(input)
      console.log(result, " subs modified data")
      if (result) {
        if(result[0].subscriptionname == "Premium"){
          if(messageModal){
  
            contactSalesModal.current.openModal();
          }
        }else if(result[0].subscriptionname != "Premium"){
          if(messageModal){
  
            upgradePlanModal.current.openModal();
          }
        }
   
      } else {
        // setData("")
          showErrorToast("Something went wrong!")
       
        
      }

    } catch (error) {
      console.log(error, "error in fetching account data")
    }
    dismissLoader();
  }
  const handleswitchuser = async (id, primaryuser)=>{
    let input = {"patientid":"","activeuserid": id,"isprimaryuser":primaryuser  }
    try {
      showLoader()
      const result = await SwitchUser(input);
      //console.log(result, "switch response");
      
    localStorage.removeItem("username")
      if(result.output) {
          localStorage.setItem('patientDetails', JSON.stringify(result.output));
        window.location.reload();
        username = result.output.fullname
      } else {
        //alert("No data");
        dismissLoader();
      }
    } catch (error) {
      console.error("Error in DashboardIndex:", error);
    }
  };
  
  const users = [
    { name: "Adnan Jaweed", image: "/assets/imgs/adnan.png", switchUser: false },
    { name: "Abrar Parker", image: "/assets/imgs/abrar-parker.jpg", switchUser: true },
    { name: "Ayesha Khan", image: "/assets/imgs/ayesha.png", switchUser: true }
  ]


  
  const getLocation = () => {
    switchLocation.current.closeRight();
    
    if (navigator.geolocation) {
      const options = {
        enableHighAccuracy: true, // Request high accuracy
        timeout: 10000, // Wait up to 10 seconds before giving up
        maximumAge: 0 // Do not use cached location
      };
  
      navigator.geolocation.getCurrentPosition(showPosition, showError, options);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const showPosition = async (position) => {
    console.log(position.coords)
    const lat = position.coords.latitude;
    const lon = position.coords.longitude;
    const address = await getAddress(lat, lon);
    setLocation({
      latitude: lat,
      longitude: lon,
      address: address,
    });
    const data = {
      latitude: lat,
      longitude: lon,
      address: address,
    }
    localStorage.setItem("location",JSON.stringify(data))
    window.location.reload();
  };

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        // showErrorToast("User denied the request for Geolocation.");        
        break;
      case error.POSITION_UNAVAILABLE:
        // showErrorToast("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        // showErrorToast("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        // showErrorToast("An unknown error occurred.");
        break;
      default:
        //showErrorToast("An unknown error occurred.");
    }
  };

  const getAddress = async (lat, lon) => {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${CONFIG.GoogleMapKey}`);
    const data = await response.json();
    console.log(data)
    if (data.status === "OK") {
      return data.results[0].formatted_address;
    } else {
      return "Address not found";
    }
  };
  const handleLocationSelect = (selectedlocation) => {
    setLocation({
      latitude: selectedlocation.lat,
      longitude: selectedlocation.lng,
      address: selectedlocation.address,
    });
    const data = {
      latitude: selectedlocation.lat,
      longitude: selectedlocation.lng,
      address: selectedlocation.address,
    }
    localStorage.setItem("location", JSON.stringify(data))
    switchLocation.current.closeRight();
    window.location.reload();
  };

 const redirectToSubscription = ()=>{
  
    showLoader()
    setTimeout(() => {
      
    navigate("/subscription");
    localStorage.setItem("openPlanModal" , true)
    }, 1000);
    dismissLoader()
  }

  const contactSupport = ()=>{
  
    showLoader()
    setTimeout(() => {
      
    window.open(`https://mail.google.com/mail/u/0/?fs=1&to=${supportEmail}&tf=cm`,"_blank");
   
    }, 500);
    dismissLoader()
  }

  const handleGetModalStatus = (openModal) => {
    messageModal = openModal;
    if(openModal){
      fetchPlan();
    }
  };
  const Redirect = (item)=>{
    if(item.notificationfor == 'medication' || item.notificationfor == 'medicine'){
      navigate("/medications")
    }else if(item.notificationfor == 'vitals'){
      navigate('/vitals')
    }else if(item.notificationfor == 'condition'){
      navigate('/conditions')
    }else if(item.notificationfor == 'surgeries'){
      navigate('/surgeries')
    }else if(item.notificationfor == 'allergies'){
      navigate('/allergies')
    }else if(item.notificationfor == 'appointment' || item.notificationfor == 'hpi' || item.notificationfor == 'chief complaint'){
      if(item.entityid != null){
        navigate("/appointmentdetails", { state: { appointmentid: item.entityid }})
      }else{

      }
    }
  }
  return (
    <>
    <Global
        styles={css`
          .active.activeLink .greenBorder::after {
            content: "";
            position: absolute;
            left: 21%;
            transform: translateX(-18%) translateY(440%);
            border-radius: 5px 5px 0 0;
            bottom: 32%;
            background: #14C585;
            height: 5px;
            width: 100%;
            z-index: 1;
          }
        `}
      />
    <Flex
      as="nav"
      align="center"
      alignItems="center"
      justify="space-between"
      wrap="wrap"
      bg="#061027"
      color="white"
      h="70px"
    >
      {isLoading && <Loader/>}
      <Flex align="center" ml="25px" mr="45px" m={{base:"0 25px 0 15px",lg: "0 25px 0 15px", xl: "0 45px 0 25px"}} cursor="pointer">
        <Image
          src="/assets/imgs/midoc-logo.png"
          alt="Logo"
          w={{lg:"75px", xl: "100px"}}
          onClick={() => navigate('/home')}
        />
      </Flex>
      <Box display="flex" alignItems="center" gap={{base: "10px", xl: "20px"}} h="full">
      
      <NavLink to="/home" className={({isActive}) => isActive ? 'active activeLink' : 'noActiveLink'} style={({ isActive }) => (isActive || isHomepage ? activeLinkStyle : linkStyle)}>
          <Image src={isHomepage ? "/assets/svgs/home-white.svg" : "/assets/imgs/home.png"} alt="Home" />
          <Box as="span" fontWeight="600" color={isHomepage ? "white" : "#6A707D"} fontSize="14px" ml="2">Home</Box>
          <Box as="span" className='greenBorder'></Box>
        </NavLink>
      <NavLink to="/myhealth" className={({isActive}) => isActive || isNestedPage ? 'active activeLink' : 'noActiveLink'} style={({ isActive }) => (isActive || isNestedPage ? activeLinkStyle : linkStyle)}>
          <Image src={isNestedPage ? "/assets/svgs/my-health-white.svg" : "/assets/svgs/my-health.svg"} alt="My Health" />
          <Box as="span" fontWeight="600" color={isNestedPage ? "white" : "#6A707D"} fontSize="14px" ml="2">My Health</Box>
          <Box as="span" className='greenBorder'></Box>
        </NavLink>
      <NavLink to="/appointments" className={({isActive}) => isActive || isAppointment ? 'active activeLink' : 'noActiveLink'} style={({ isActive }) => (isActive || isAppointment ? activeLinkStyle : linkStyle)}>
          <Image src={isAppointment ? "/assets/svgs/appointment-white.svg" : "/assets/imgs/appointments.png"} alt="Appointments" />
          <Box as="span" fontWeight="600" color={isAppointment ? "white" : "#6A707D"} fontSize="14px" ml="2">Appointments</Box>
          <Box as="span" className='greenBorder'></Box>
        </NavLink>
      <NavLink to="/chat" className={({isActive}) => isActive ? 'active activeLink' : 'noActiveLink'} style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}>
          <Image src={location.pathname == "/chat" ? "/assets/svgs/chat-white-nav.svg" : "/assets/imgs/chats.png"} alt="Chat" />
          <Box as="span" fontWeight="600" color={location.pathname == "/chat" ? "white" : "#6A707D"} fontSize="14px" ml="2">Chat</Box>
          <Box as="span" className='greenBorder'></Box>
        </NavLink>
        <NavLink to="/profile" className={({isActive}) => isActive || isProfilePage ? 'active activeLink' : 'noActiveLink'} style={({ isActive }) => (isActive || isProfilePage ? activeLinkStyle : linkStyle)}>
          <Image src={isProfilePage ? "/assets/svgs/profile-white.svg" : "/assets/imgs/account-gray.png"} alt="Profile" />
          <Box as="span" fontWeight="600" color={isProfilePage ? "white" : "#6A707D"} fontSize="14px" ml="2">Profile</Box>
          <Box as="span" className='greenBorder'></Box>
        </NavLink>
    </Box>
      <Spacer />

      <Box display="flex" alignItems="center" gap="10px">
        <Box onClick={()=> switchLocation.current.openRight()} as="button"  display="flex" flexDirection="row" alignItems="center" gap="10px" px="15px" h="32px"  border="1px solid #0B1F4F" borderRadius="full">
            <Image src="/assets/imgs/location.png" /> 
            <Box as="span" className="textOverflow" w="200px" fontSize="12px" title={currentlocation.address}>{currentlocation.address? currentlocation.address:"Please select location"}</Box> 
            <Image src="/assets/imgs/arrow-down.png" /> 
        </Box>
        <Box as="button" onClick={showNotifications} display="flex" flexDirection="row" alignItems="center" justifyContent="center" gap={notificationCount > 0 ? "10px" : ""}  px="15px" minH="38px" minW={notificationCount > 0 ? "58px" : "40px"} border="1px solid #0B1F4F" borderRadius="full">
            <Image src="/assets/imgs/notifications.png" /> 
            <Box as="span" fontSize="12px">{notificationCount > 0 ? notificationCount : ""}</Box>
        </Box>
        <Box as="button">
          <Menu>
            <MenuButton onClick={handlefamilydetails} as="button" display="flex" justifyContent="center" sx={{minWidth:"155px"}}>
              <Flex alignItems="center" justifyContent="center" border="1px solid #0B1F4F" borderRadius="full" p="4px 12px 4px 4px" mr="25px">
                <Image
                  src={patientimage?patientimage:"/assets/imgs/no-image-icon.png"}
                  w="28px"
                  borderRadius="full"
                  // mr="5px"
                  h="28px"
                />
                <Text w="100%" ml="2" fontSize="12px" fontWeight="600">
                  {userName ? userName :""}
                </Text>
              </Flex>
            </MenuButton>
            <MenuList p="2px" minW="200px" maxH="220px" overflowY="auto" mr="25px" zIndex="1801">
              {data && data.map((user, index)=> (
                <MenuItem key={index}  onClick={()=>handleswitchuser(user.activeuserid, user.isprimaryuser)} display="flex" justifyContent="space-between" gap="10px">
                    <Flex>
                      <Image
                        mr="6px"
                        borderRadius="full"
                        w="24px"
                         h="24px"
                        src={
                          user.image
                            ? user.image.startsWith('http')
                              ? user.image
                              : user.imagepath
                              ? user.imagepath + user.image
                              : "/assets/imgs/no-image-icon.png"
                            : "/assets/imgs/no-image-icon.png"
                        }
                      />
                      <Text fontSize="14px" fontWeight="500" color="#384052">{user.fullname} {user.isprimaryuser === 1 ? "(You)" : null}</Text>
                    </Flex>
                    {user.switchUser === true && (
                      <Box>
                        <Image src='/assets/imgs/change-user.png' />
                      </Box>
                    )}
                  </MenuItem>
                ))}
              <NavLink to="/profile"><MenuItem  fontSize="14px" fontWeight="500" color="#384052"><Image mr="6px" w="24px" h="24px" objectFit="scale-down" src="/assets/imgs/account.png" />Profile</MenuItem></NavLink>
              <MenuItem fontSize="14px" fontWeight="500" color="#AA2E26" onClick={handleLogout}><Image mr="6px" w="24px" h="24px" objectFit="scale-down" src="/assets/imgs/logout.png" />Logout</MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Box>
    </Flex>

    <ModalRight 
      ref={notification}
      modalWidth="560px"
      header="Notifications"
        body={<>
        {data && data.length> 0 ?(
          data.map((notification , index)=>(
            <Box key={index} display="flex" gap="10px" py="20px" borderBottom="1px solid #E6E7E9" alignItems="flex-start" justifyContent='space-between'>
              <Flex alignItems='center' gap='0.5rem'>
                <Box display="flex" p="6px"  w="30px" h="auto" alignItems="baseline" borderRadius="full" border="1px solid #E6E7E9" bg="white">
                  <Image w="40px"  src='/assets/imgs/calendar.png' />
                </Box>
                <Box>
                <Box as='p' fontSize="12px" fontWeight="500">
                {notification.notificationfor 
                  ? notification.notificationfor.charAt(0).toUpperCase() + notification.notificationfor.slice(1)
                  : "-"}
              </Box>
                  <Box as='p' fontSize="14px" maxW='380px' title={notification.notificationcontent} fontWeight="500">{notification.notificationcontent}</Box>
                </Box>
              </Flex>
              <Flex alignItems="end" flexDir='column' gap='6px'>
                <Box display='flex' alignItems='center'>
                  <Box as='span' w="4px" p="2px" h="4px" bg="#1C4EC5" mr="4px" borderRadius="full"></Box>
                  <Box as='span' fontSize="10px" fontWeight="400">{notification.timeDifference}</Box>
                </Box>
                <Image src="/assets/imgs/right-icon.png" cursor='pointer' onClick={()=>Redirect(notification)}/>
              </Flex>
            </Box>
          ))
 
        ):(
          <Flex flexDir="column" minH="100vh" justifyContent="center" alignItems="center">
          <Image src='/assets/svgs/notifications.svg' />
          <Text fontSize="16px" fontWeight="500">Notifications</Text>
        </Flex> 
        )}
         
          {/* <Box display="flex" gap="10px" py="20px" borderBottom="1px solid #E6E7E9" alignItems="flex-start">
            <Box display="flex" p="5px"  w="40px" h="auto" alignItems="baseline" borderRadius="full" border="1px solid #E6E7E9" bg="white">
              <Image w="40px"  src='/assets/imgs/calendar.png' />
            </Box>
            <Box>
              <Box as='p' fontSize="12px" fontWeight="500">Appointments</Box>
              <Box as='p' fontSize="14px" fontWeight="500">Your appointment is in 15 minutes. Get ready for your scheduled consultation.</Box>
            </Box>
            <Flex alignItems="center">
              <Box as='span' w="4px" p="2px" h="4px" bg="#1C4EC5" mr="4px" borderRadius="full"></Box>
              <Box as='span' fontSize="10px" fontWeight="400">2m</Box>
            </Flex>
          </Box>
          <Box display="flex" gap="10px" py="20px" borderBottom="1px solid #E6E7E9" alignItems="flex-start">
            <Box display="flex" p="5px"  w="40px" h="auto" alignItems="baseline" borderRadius="full" border="1px solid #E6E7E9" bg="white">
              <Image w="40px"  src='/assets/imgs/calendar.png' />
            </Box>
            <Box>
              <Box as='p' fontSize="12px" fontWeight="500">Appointments</Box>
              <Box as='p' fontSize="14px" fontWeight="500">Your appointment is in 15 minutes. Get ready for your scheduled consultation.</Box>
            </Box>
            <Flex alignItems="center">
              <Box as='span' w="4px" p="2px" h="4px" bg="#1C4EC5" mr="4px" borderRadius="full"></Box>
              <Box as='span' fontSize="10px" fontWeight="400">2m</Box>
            </Flex>
          </Box> */}

         
        </>}
    />

      <ModalRight 
        ref={switchLocation}
        modalWidth="360px"
        header={<>
        <Box my="1rem">
          <Text as="h2" fontSize="1.5rem" fontWeight="600" color="#050D1F">Switch Location</Text>
        </Box>
          <Box display="flex" flexDir="column" gap="1rem">
          <LocationSearch onSelect={handleLocationSelect} />
              <Box display="flex" gap="0.5rem">
                <Image src="/assets/svgs/detect-location-blue.svg" />
                <Text fontSize="0.875rem" cursor="pointer" fontWeight="600" color="#1C4EC5" onClick={getLocation}>Auto detect location</Text>
              </Box>
            </Box>
        </>}
        bodyStyle={{p: "24px 32px"}}
        body={
          <>
          </>
        }
      />

<DynamicModal
          ref={upgradePlanModal}
          modalHeader="Upgrade Required"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Your Token Balance is insufficient. Please upgrade your plan!
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                buttonText="Upgrade"
                onClick={redirectToSubscription}
              />
            </>
          }
        />

        
<DynamicModal
          ref={contactSalesModal}
          modalHeader="Token Limit Reached"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                You have reached the maximum token limit available for the Premium subscription. Please contact our support team for further assistance.
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                buttonText="Contact Support"
                onClick={contactSupport}
              />
            </>
          }
        />

        
        {showAiDoctor && <AiDoctor showUpgradePlanModal={handleGetModalStatus} />}
    </> 
  );
};

export default Navbar;
