import formapiSpecialities from "./speciality";


var formattedOutput = null;

const formapimodalSpecialities = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formapiSpecialities(item));


  return formattedOutput
};

export default formapimodalSpecialities