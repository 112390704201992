import moment from "moment";
export default function formAPIFamilyAllergy({
    allergy,
    allergy_cd,
    allergyidentifieddate,
    allergytype,
    allergytype_cd,
    familyid,
    nomenclatureid,
    patientid
   

})
{
  return Object.freeze({
    allergy,
    allergy_cd,
    allergyidentifieddate,
    allergytype,
    allergytype_cd,
    familyid,
    nomenclatureid,
    patientid
   
  });
}