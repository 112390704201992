import moment from "moment";
import formAPIModelImage from "./images/images";
import formAPIModelVoice from "./voice/voices";
import formAPIModelDocument from "./documents/documents";
import formAPIModelLink from "./links/links";
import formAPIModelAboutdoctor from "./aboutdoctor";
import formAPIModelAppointment from '../../domains/chatmedia/appointment/apointments'




export default function formapimodalchatmedia({
  doctorname,
  doctorimage, 
    imagepath,
    appointmentdate,
    appointmenttime,
    appointmentid,
    fee,
    qualification,
    specality,
    yearsofexperience,
    ratings,
    IMAGE,
    voice,
    link,
    document,
    aboutdoctor,
    appointment,
    websiteurl,
    uniqueid
})
{
  return Object.freeze({
    doctorname,
  doctorimage, 
    imagepath,
    appointmentdate,
    appointmenttime,
    appointmentid,
    fee,
    qualification,
    specality,
    yearsofexperience,
    ratings,
    IMAGE: IMAGE !== null ? formAPIModelImage(IMAGE):[],
    voice: voice !== null ? formAPIModelVoice(voice):[],
    link:  link !== null ? formAPIModelLink(link):[],
    document: document !== null ? formAPIModelDocument(document):[],
    aboutdoctor: aboutdoctor !== null ? formAPIModelAboutdoctor(aboutdoctor):{},
    appointment: appointment !== null ? formAPIModelAppointment(appointment):[],
    websiteurl,
    uniqueid
  });
}