import formapidoctorslots from "./slot";


var formattedOutput = null;

const formapimodaldoctorslots = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formapidoctorslots(item));


  return formattedOutput
};

export default formapimodaldoctorslots