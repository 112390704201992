import moment from "moment";
export default function formAPIModelimaging({
  longtext,
  eventtitle,
  verifieddate,
    
   

})
{
  return Object.freeze({
    longtext,
  eventtitle,
  verifieddate : verifieddate ? moment(verifieddate).format("YYYY-MM-DD") : "-"
   
  });
}