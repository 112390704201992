import moment from "moment";
export default function formapimodaldoctors({
    doctorid,
fullname,
email,
gender,
mobileno,
specality,
ratings,
yearsofexperience,
qualification,
fee,
profilepictureurl,
hospitalname,
imagepath,
address
  
})
{ 
  return Object.freeze({
    doctorid,
fullname,
email,
gender,
mobileno,
specality,
ratings,
yearsofexperience,
qualification,
fee,
profilepictureurl,
hospitalname,
imagepath,
address
  });
}