
import formAPIInvoices from "./profileinvoice";


var formattedOutput = null;

const formAPIModelProfileInvoices = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data?.map(item => formAPIInvoices(item));


  return formattedOutput
};

export default formAPIModelProfileInvoices