import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'

const MobileSecondaryNav = ({handlebackarrow, header, rightArea, bottomArea = false, headerStyle}) => {
  return (
    <>
        <Box w="100%" p="1.25rem" bg="white" borderBottom='1px solid #E6E7E9'>
            <Flex w="100%" justifyContent="space-between" {...headerStyle}>
                <Box as="button" onClick={handlebackarrow}>
                    <Image src="/assets/imgs/left-arrow-icon.png" />
                </Box>

                <Box>
                    <Text fontSize="1.25rem" fontWeight="600">{header}</Text>
                </Box>

                <Box>
                    {rightArea}
                </Box>
            </Flex>

            {bottomArea && (
                <Box mt="1rem">
                   {bottomArea} 
                </Box>
            )}
        </Box>
    </>
  )
}

export default MobileSecondaryNav