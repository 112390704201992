function formatHPIMasterData(inputData) {
    const formattedData = [];
    const categories = {};
  
    inputData.forEach((item) => {
      const { category, key, value, id } = item;
  
      if (!categories[category]) {
        categories[category] = {};
      }
  
      if (!categories[category][key]) {
        categories[category][key] = [];
      }
  
      categories[category][key].push({ value, id });
    });
  
    for (const category in categories) {
      const formattedCategory = { type: category.toLowerCase(), value: [] };
  
      for (const key in categories[category]) {
        const formattedSubtypes = {
          type: key, // Modify this line to include the key as the type
          subtype: categories[category][key],
        };
        formattedCategory.value.push(formattedSubtypes);
      }
  
      formattedData.push(formattedCategory);
    }
  
    //   console.log(formattedData);s
    return formattedData;
  }
  
  export { formatHPIMasterData };
  