import { COLORS } from "../styles/colors";

export const styles = {
    borderRadius: "0 0 10px 10px",
    background: COLORS.PRIMARY_WHITE,
    boxShadow: "none",
    padding: "12px 65px 0 10px",
    height: "48px",
    width: "100%",
    _disabled: {
      bg: COLORS.BTN_DISABLE,
      color: COLORS.INPUT_FOCUS_DARK,
      cursor: "not-allowed",
    },
    _hover: {
      borderRadius: "10px",
      border: `2px solid`,
      borderColor: COLORS.INPUT_FOCUS_DARK,
      // zIndex: "22",
      outline: "none",
      bg: "white"
    },
    _focus: {
      borderRadius: "10px",
      border: "2px solid var(--input-focus-dark)",
      borderColor: COLORS.INPUT_FOCUS_DARK,
      // zIndex: "22",
      outline: "none"
    },
    _focusVisible: {
      borderColor: "none"
    }
  };
  
export const btnStyle = {
  top: "6px",
  background: "none",
  color: "black",
  zIndex: "9" 
}