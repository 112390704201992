import moment from "moment";

export default function formAPISubscription({
    subscriptionstartdate,
    subscriptionpriceunit,
    subscriptionprice,
    subscriptionplanid,
    subscriptionname,
    subscriptionenddate,
    subscriptiondescription,
    isactive,
    durationinmonths,
    duration,
    createddate,
    iscurrent,
    cancelplan
}) {
  return Object.freeze({
    subscriptionstartdate: moment(subscriptionstartdate).format("Do MMM, YYYY"),
    subscriptionpriceunit,
    subscriptionprice,
    subscriptionplanid,
    subscriptionname,
    subscriptionenddate: moment(subscriptionenddate).format("Do MMM, YYYY"),
    subscriptiondescription,
    durationinmonths,
    duration,
    createddate : moment(createddate).format("Do MMM, YYYY"),
    isactive,
    iscurrent,
    cancelplan
   });
}
