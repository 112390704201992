
import formAPIDoctor from "./doctor";
import formAPIDoctors from "./doctor";
import formAPIInvoices from "./doctor";


var formattedOutput = null;

const formAPIModelDoctors = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data?.map(item => formAPIDoctor(item));


  return formattedOutput
};

export default formAPIModelDoctors