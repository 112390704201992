import formAPIvitals from "./vital";


var formattedOutput = null;

const formAPIModelvitals = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIvitals(item));


  return formattedOutput
};

export default formAPIModelvitals