import formAPIfamilyhistory from "./familyhistory";


var formattedOutput = null;

const formAPIModelfamilyhistory = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIfamilyhistory(item));


  return formattedOutput
};

export default formAPIModelfamilyhistory