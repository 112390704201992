export default function formAPIsocialhistory({
    //duration,
    //durationtype,
    frequency,
    frequencytype,
    socialhistorytype,
    iscurrentstatus,
    fromdate,
    todate,
    smokelesstype,
    
    iscurrent
   

})
{
 
  return Object.freeze({
    //duration,
    //durationtype,
    frequency: frequency,
    frequencytype,
    socialhistorytype,
    iscurrentstatus: iscurrentstatus === 1 ? "Yes" : iscurrentstatus === 0 ? "Never" : "Quit",
    fromdate,
    todate,
    smokelesstype,
    //iscurrent:iscurrentstatus ? iscurrentstatus : iscurrent,
    //iscurrent:iscurrentstatus,
    iscurrent,
    
   
  });
}