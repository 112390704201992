import React,{useState,useEffect,useRef} from "react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import Loader from "../../components/loader/loader";
import { PrimaryButton, Toaster } from "../../components";
import ProfileSideBar from "./profileSidebar";
import useLoader from "../../components/loader/loaderstates";
import Footer from "../../components/footer";
import { Box, Card, CardBody, CardFooter, CardHeader, Divider, Flex, Image, Text } from "@chakra-ui/react";
import { COLORS } from "../../components/styles/colors";
import GrayCard from "../../components/card";
import ModalRight from "../../components/modal-right";
import { useNavigate } from "react-router-dom";
import { FetchSubscription, FetchPlans} from "../../middleware/services/profile/subscription";
import { steps } from "framer-motion";
import { UpdatePatientPlan} from "../../middleware/services/profile/subscription";
import DynamicModal from "../../components/dynamic-modal";
import moment from "moment";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { useScreenType } from "../../auth";



const SubscriptionIndex = () => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  var [patientid, setPatientid] = useState();
  const { showSuccessToast, showErrorToast } = Toaster();
  const [data, setData] = useState("");
  const [plansdata, setPlansData] = useState("");
  const upgradeModal = React.useRef()
  const cancelPlan = useRef();
  const infoModal = useRef();
  var navigate = useNavigate();
  const [PlanEndDate, setPlanEndDate] = useState("");
  const [ infoArray , setInfoArray ] = useState([])
  const screenType = useScreenType();
  useEffect(() => {
    fetchData();
  }, []);
  var input = {};
  var fetchData = async () => {
    showLoader();
    try {
      var result = await FetchSubscription(input)
      //console.log(result, " subs modified data")
      if (result) {
        setData(result)
        let openModal = localStorage.getItem("openPlanModal")
    if(openModal){
      fetchPlansData()
      upgradeModal.current.openRight()
      setTimeout(() => {
        localStorage.removeItem("openPlanModal")
      }, 2000);
    }
      } else {
        setData("")
        //  showErrorToast("Something went wrong!")
      }

    } catch (error) {
      console.log(error, "error in fetching account data")
    }
    dismissLoader();
  }
  var fetchPlansData = async () => {
    try {
      var result = await FetchPlans({
        patientid,
      })
      //console.log(result, " plans modified data")
      if (result) {
        setPlansData(result);
        upgradeModal.current.openRight()
      } else {
        setPlansData("")
        //  showErrorToast("Something went wrong!")
      }

    } catch (error) {
      console.log(error, "error in fetching account data")
    }
    dismissLoader();
  }
const upgradeplan = (planid)=>{
  navigate("/payment?id="+planid)
}
const cancelCurrentPlan = (date) =>{
  setPlanEndDate(date)
  cancelPlan.current.openModal();
}
const closepopupcancelplan = () =>{
  cancelPlan.current.closeModal();
}
const cancelactivePlan = async ()=>{
  var inputForSuscription = {
    planid: 1
  }
    showLoader();
    try {
      const result = await UpdatePatientPlan(inputForSuscription);
      //console.log(result , " result of professiontl details")

      if (result.output.result == "success") {

        fetchData();
         cancelPlan.current.closeModal();
         
      }else {
        console.error(result, "Something went wrong in Submit.")
        showErrorToast("Something went wrong!")
      }
      dismissLoader();
    } catch (error) {
      console.error('Error in SubmitFunction:', error);
    }
}
// const formatDate = (dateString) => {
//   if (!dateString) return "-";
//   return moment(dateString).format("Do MMM, YYYY");
// };
const openInfoModal = (desc)=>{
  if(desc && desc.info){

    setInfoArray(desc.info)
  }
  infoModal.current.openRight();
}
  return (
    <>
    {screenType == 'web' ? (
      <Box w="100%" display={{base: "none", lg: "block"}}>
        <Box top="0" zIndex="banner">
          <Navbar />

          {isLoading && <Loader />}
          <SecondaryNav
            sideBarWidth="250px"
            defaultHeader="Profile"
            defaultSecondayHeader="Subscription"
            defaultNavBody={
              <>
                <Flex gap="15px" alignItems="center" pr="px" >
                {data && data[0].subscriptionname != "Basic" && (
                  <PrimaryButton
                    variant="grayBtnFull"
                    color="#AA2E26"
                    btnStyle={{
                      color: "#AA2F27",
                      width: "100%!important",
                      height: "40px!important",
                      mr: "1rem"
                    }}
                    buttonText="Cancel"
                    isDisabled={data && data[0].cancelplan === 0 ? false : true}
                    onClick={()=>cancelCurrentPlan(data && data[0].subscriptionenddate)}
                  />
                 )}  
                 
{data && data[0].subscriptionname != "Premium" && (
                  <PrimaryButton
                    btnStyle={{
                      width: "100%!important",
                      height: "40px!important",
                      mr: "1rem"
                    }}
                    variant="defaultSide"
                    buttonText="Upgrade"
                    onClick={fetchPlansData}
                  />
                )}  
                </Flex>
              </>
            }
          />
        </Box>
        <Box h="calc(100vh - 140px)" overflow="hidden auto">
          <Flex>
            <Box p="0" width="250px">
              <ProfileSideBar />
            </Box>
            {data !== null && data.length > 0 ? 
              data && 
                data.map((step, index) => (
            <Flex flexDir="column" gap="20px"  key={index}
              // backgroundImage={COLORS.GOLD_REWARD}
              // backgroundImage={COLORS.BRONZE_REWARD}
              // backgroundImage={COLORS.SILVER_REWARD}
              backgroundImage={COLORS.PLATINUM_REWARD}
              w="100%" p="24px 32px">
                  <Flex w="100%" flexDir="column" gap="20px">
                      {/* Current plan and Joining date start */}
                      <Flex w="100%" gap="20px">
                          {/* Current plan start */}
                          <Card h="100px" w="100%" border="1px solid #E6E7E9" borderRadius="12px" boxShadow="0px 8px 20px 0px #E6E7E966">
                              <Flex flexDir="column" p="20px" justifyContent="end" h="100%">
                                  <Flex justifyContent="space-between" w="100%" alignItems="center">
                                      <Text fontSize="24px" fontWeight="600">{step.subscriptionname? step.subscriptionname:"-"}</Text>
                                      <Box  fontSize="10px" fontWeight="700" border="1px solid #A1E8CE" textAlign="center" borderRadius="full" p="5px 10px" color="#0C7650">
                                          CURRENT PLAN
                                      </Box>
                                  </Flex>
                                  {step.subscriptionname === "Basic" ? (
                  <Text color="#384052" fontSize="20px" fontWeight="500">
                    Free                  
                  </Text>
                  ):(
                    <Text color="#384052" fontSize="20px" fontWeight="500">
                                  ${step.subscriptionprice?step.subscriptionprice:"0"}/Year  <Box as="span" pl="5px" fontSize="8px" fontWeight="500">*Billed annually</Box>
                                  </Text>
                  )}
                                 
                              </Flex>
                          </Card>
                          {/* Current plan end */}

                          {/* Joining date start */}
                          <Card h="100px" w="100%" border="1px solid #E6E7E9" borderRadius="12px" boxShadow="0px 8px 20px 0px #E6E7E966">
                              <Flex flexDir="column" justifyContent="space-between" p="20px" h="100%">
                                  <Flex justifyContent="space-between" w="100%" alignItems="center">
                                      <Text fontSize="16px" fontWeight="400" color="#384052">Member since:</Text>
                                      <Text fontSize="16px" fontWeight="500" color="#061027">{step.subscriptionstartdate? step.subscriptionstartdate:"-"}</Text>
                                  </Flex>
                                  {step.subscriptionname !== "Basic" && 
                                  <Flex justifyContent="space-between" w="100%" alignItems="center">
                                      <Text fontSize="16px" fontWeight="400" color="#384052">Renews on:</Text>
                                      <Text fontSize="16px" fontWeight="500" color="#061027">{step.subscriptionenddate?step.subscriptionenddate:"-"}</Text>
                                  </Flex>
                                  }
                              </Flex>
                          </Card>
                          {/* Joining date end */}
                      </Flex>
                      {/* Current plan and Joining date end */}

                      {/* Plan features start */}
                      <Box w="100%">
                          <GrayCard
                              cardHeader="You have access to"
                              cardBody={<>
                                  <Flex flexDir="column" gap="10px">
                                  {step.subscriptiondescription && step.subscriptiondescription.map((desc, index) => (
                  <Box key={index} display="flex" gap="10px" alignItems="center">
                      <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                      <Text fontSize="14px" fontWeight="700"><Box as="span" fontWeight="500">{desc.name?desc.name:"-"}</Box></Text>
                      {step.subscriptiondescription[index].info &&

                        <Image cursor="pointer" w="12px" h="auto" src="/assets/svgs/info.svg" onClick={()=>openInfoModal(step.subscriptiondescription[index])} />
                      }
                  </Box>
              ))}
                                      {/* <Box display="flex" gap="10px" alignItems="center">
                                          <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                                          <Text fontSize="14px" fontWeight="700">345/10000 <Box as="span" fontWeight="500">documents upload</Box></Text>
                                      </Box>
                                      <Box display="flex" gap="10px" alignItems="center">
                                          <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                                          <Text fontSize="14px" fontWeight="700">100/100 <Box as="span" fontWeight="500">credits for AI assist</Box></Text>
                                      </Box>
                                      <Box display="flex" gap="10px" alignItems="center">
                                          <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                                          <Text fontSize="14px" fontWeight="500">Doctor appointments</Text>
                                      </Box>
                                      <Box display="flex" gap="10px" alignItems="center">
                                          <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                                          <Text fontSize="14px" fontWeight="500">Reports</Text>
                                      </Box>
                                      <Box display="flex" gap="10px" alignItems="center">
                                          <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                                          <Text fontSize="14px" fontWeight="500">Data sharing to physicians</Text>
                                      </Box>
                                      <Box display="flex" gap="10px" alignItems="center">
                                          <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                                          <Text fontSize="14px" fontWeight="500">Add family members</Text>
                                      </Box> */}
                                  </Flex>
                              </>}
                              hasFooter={false}
                          />
                      </Box>
                      {/* Plan features end */}
                      {step.subscriptionplanid != 1 &&
                      <Text fontSize="12px" color="#898D97" fontWeight="500">Your subscription will renew on the date listed above. Please cancel before the renewal date to avoid  being charged.</Text>
                      
                    }      
                  </Flex>
              </Flex>
            )
            ) : (
              <Box
              w="100%"
              h="100vh"
              display="flex"
              flexDirection="column"
              gap="5px"
              justifyContent="center"
              alignItems="center"
            >
              <Image w="60px"  opacity="0.5" src="/assets/svgs/currency_exchange.svg" />
              <Box as="p">No data available</Box>
            </Box>
            )}
          </Flex>
          <Footer />
        </Box>
      </Box>
    ) : (
      //Mobile responsive start
      <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/profile')}
          header="Subscription"
        />

        <Box pos="fixed" bottom="10%" display="flex" justifyContent="center" w="100%" zIndex="99">
          <Flex gap="15px" alignItems="center" px="10px" w="100%">
            {data && data[0].subscriptionname != "Basic" && (
              <PrimaryButton
                variant="grayBtnFull"
                color="#AA2E26"
                btnStyle={{
                  color: "#AA2F27",
                  width: "100%!important",
                  height: "40px!important",
                  mr: "1rem"
                }}
                buttonText="Cancel"
                isDisabled={data && data[0].cancelplan === 0 ? false : true}
                onClick={()=>cancelCurrentPlan(data && data[0].subscriptionenddate)}
              />
              )}  

{data && data[0].subscriptionname != "Premium" && (
              <PrimaryButton
                btnStyle={{
                  width: "100%!important",
                  height: "40px!important",
                  mr: "1rem"
                }}
                variant="defaultSide"
                buttonText="Upgrade"
                onClick={fetchPlansData}
              />
            )}  
          </Flex>
        </Box>

        <Flex bg="#fcfcfd" flexDir="column" pb="3.5rem" h="calc(100vh - 180px)" overflow="hidden auto">
          {data !== null && data.length > 0 ? 
            data && 
            data.map((step, index) => (
              <Flex flexDir="column" gap="20px"  key={index}
                // backgroundImage={COLORS.GOLD_REWARD}
                // backgroundImage={COLORS.BRONZE_REWARD}
                // backgroundImage={COLORS.SILVER_REWARD}
                backgroundImage={COLORS.PLATINUM_REWARD}
                w="100%" p="24px 32px">
                    <Flex w="100%" flexDir="column" gap="20px">
                        {/* Current plan and Joining date start */}
                        <Flex w="100%" gap="20px">
                            <Card w="100%" border="1px solid #E6E7E9" borderRadius="12px" boxShadow="0px 8px 20px 0px #E6E7E966">
                                <Flex flexDir="column" p="20px" justifyContent="end" h="100%">
                                    <Flex justifyContent="space-between" w="100%" alignItems="center">
                                        <Text fontSize="24px" fontWeight="600">{step.subscriptionname? step.subscriptionname:"-"}</Text>
                                        <Box  fontSize="10px" fontWeight="700" border="1px solid #A1E8CE" textAlign="center" borderRadius="full" p="5px 10px" color="#0C7650">
                                            CURRENT PLAN
                                        </Box>
                                    </Flex>
                                    <Text color="#384052" fontSize="20px" fontWeight="500">
                                    ${step.subscriptionprice?step.subscriptionprice:"0"}/{step.duration?step.duration:"-"}<Box as="span" pl="5px" fontSize="8px" fontWeight="500">*Billed annually</Box>
                                    </Text>
                                </Flex>

                                <Flex flexDir="column" borderTop="1px solid #E6E7E9" justifyContent="space-between" p="20px" h="100%">
                                    <Flex justifyContent="space-between" w="100%" alignItems="center">
                                        <Text fontSize="16px" fontWeight="400" color="#384052">Member since:</Text>
                                        <Text fontSize="14px" fontWeight="500" color="#061027">{step.subscriptionstartdate? step.subscriptionstartdate:"-"}</Text>
                                    </Flex>
                                    <Flex justifyContent="space-between" w="100%" alignItems="center">
                                        <Text fontSize="16px" fontWeight="400" color="#384052">Renews on:</Text>
                                        <Text fontSize="14px" fontWeight="500" color="#061027">{step.subscriptionenddate?step.subscriptionenddate:"-"}</Text>
                                    </Flex>
                                </Flex>
                            </Card>
                        </Flex>
                        {/* Current plan and Joining date end */}

                        {/* Plan features start */}
                        <Box w="100%">
                            <GrayCard
                                cardHeader="You have access to"
                                cardBody={<>
                                    <Flex flexDir="column" gap="10px">
                                    {step.subscriptiondescription && step.subscriptiondescription.map((desc, index) => (
                                        <Box key={index} display="flex" gap="10px" alignItems="center">
                                            <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                                            <Text fontSize="14px" fontWeight="700"><Box as="span" fontWeight="500">{desc.name?desc.name:"-"}</Box></Text>
                                              {step.subscriptiondescription[index].info &&
                                               <Image cursor="pointer" w="12px" h="auto" src="/assets/svgs/info.svg" onClick={()=>openInfoModal(step.subscriptiondescription[index])} />
                                              }
                                        </Box>
                                    ))}
                                        {/* <Box display="flex" gap="10px" alignItems="center">
                                            <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                                            <Text fontSize="14px" fontWeight="700">345/10000 <Box as="span" fontWeight="500">documents upload</Box></Text>
                                        </Box>
                                        <Box display="flex" gap="10px" alignItems="center">
                                            <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                                            <Text fontSize="14px" fontWeight="700">100/100 <Box as="span" fontWeight="500">credits for AI assist</Box></Text>
                                        </Box>
                                        <Box display="flex" gap="10px" alignItems="center">
                                            <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                                            <Text fontSize="14px" fontWeight="500">Doctor appointments</Text>
                                        </Box>
                                        <Box display="flex" gap="10px" alignItems="center">
                                            <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                                            <Text fontSize="14px" fontWeight="500">Reports</Text>
                                        </Box>
                                        <Box display="flex" gap="10px" alignItems="center">
                                            <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                                            <Text fontSize="14px" fontWeight="500">Data sharing to physicians</Text>
                                        </Box>
                                        <Box display="flex" gap="10px" alignItems="center">
                                            <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                                            <Text fontSize="14px" fontWeight="500">Add family members</Text>
                                        </Box> */}
                                    </Flex>
                                </>}
                                hasFooter={false}
                            />
                        </Box>
                        {/* Plan features end */}

                        {step.subscriptionplanid != 1 &&
                        <Text fontSize="12px" color="#898D97" fontWeight="500">Your subscription will renew on the date listed above. Please cancel before the renewal date to avoid  being charged.</Text>
                        }
                        </Flex>
                </Flex>
              )
              ) : (
                <Box
                w="100%"
                h="100vh"
                display="flex"
                flexDirection="column"
                gap="5px"
                justifyContent="center"
                alignItems="center"
              >
                <Image w="60px"  opacity="0.5" src="/assets/svgs/currency_exchange.svg" />
                <Box as="p">No data available</Box>
              </Box>
              )}
        </Flex>
       </Box>
    )}
      <ModalRight 
      ref={upgradeModal}
      modalWidth="360px"
      header="Upgrade subscription"
      bodyStyle={{p: "24px 32px"}}
      body={
        <>
           {plansdata &&  plansdata.map((plan, index) => (
          <Card key={index} className="border-pro-plan" mb="20px" boxShadow="0px 8px 24px 0px #B4B4B452">
            <CardHeader backgroundImage="linear-gradient(180deg, #72DCB6 -675%, rgba(114, 220, 182, 0) 100%)" borderRadius="12px">
              <Flex flexDir="column" justifyContent="end" h="100%">
                  <Flex justifyContent="space-between" w="100%" alignItems="center">
                      <Text fontSize="24px" fontWeight="600">{plan.subscriptionname ? plan.subscriptionname :"-"}</Text>
                      {plan.iscurrent === 1 &&
                      <Box  fontSize="10px" fontWeight="700" border="1px solid #A1E8CE" textAlign="center" borderRadius="full" p="5px 10px" color="#0C7650">
                          CURRENT PLAN
                      </Box>
                      }
                      
                  </Flex>
                  {plan.subscriptionname === "Basic" ? (
                  <Text color="#384052" fontSize="20px" fontWeight="500">
                    Free                  
                  </Text>
                  ):(
                    <Text color="#384052" fontSize="20px" fontWeight="500">
                    ${plan.subscriptionprice?plan.subscriptionprice:"0"}/Year <Box as="span" pl="5px" fontSize="8px" fontWeight="500">*Billed annually</Box>
                </Text>
                  )}
                 
              </Flex>
              <Divider mt="15px" />
            </CardHeader>

            <CardBody py="0">
              <Flex flexDir="column" gap="10px">
              {plan.subscriptiondescription && plan.subscriptiondescription.map((desc, index) => (
                  <Box key={index} display="flex" gap="10px" alignItems="center">
                      <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                      <Text fontSize="14px" fontWeight="700"><Box as="span" fontWeight="500">{desc.name?desc.name:"-"}</Box></Text>
                      {plan.subscriptiondescription[index].info &&
                       <Image cursor="pointer" w="12px" h="auto" src="/assets/svgs/info.svg" onClick={()=>openInfoModal(plan.subscriptiondescription[index])} />
                      }
                  </Box>
              ))}
                  {/* <Box display="flex" gap="10px" alignItems="center">
                      <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                      <Text fontSize="14px" fontWeight="700">500<Box as="span" fontWeight="500">credits for AI assist</Box></Text>
                  </Box>
                  <Box display="flex" gap="10px" alignItems="center">
                      <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                      <Text fontSize="14px" fontWeight="500">Doctor appointments</Text>
                  </Box>
                  <Box display="flex" gap="10px" alignItems="center">
                      <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                      <Text fontSize="14px" fontWeight="500">Reports</Text>
                  </Box>
                  <Box display="flex" gap="10px" alignItems="center">
                      <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                      <Text fontSize="14px" fontWeight="500">Data sharing to physicians</Text>
                  </Box>
                  <Box display="flex" gap="10px" alignItems="center">
                      <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                      <Text fontSize="14px" fontWeight="500">Add family members</Text>
                  </Box> */}
              </Flex>
            </CardBody>

            <CardFooter>
              <PrimaryButton 
               isDisabled={plansdata.some(item => item.subscriptionname === "Premium" && item.iscurrent === 1) ||plan.iscurrent === 1 || plan.subscriptionplanid === 1} 
              // isDisabled={
              //   plansdata.some(
              //     p => p.subscriptionname === "Premium" && p.iscurrent === 1
              //   ) || plan.iscurrent === 1 || plan.subscriptionplanid === 1
              // } 
              buttonText={plan.iscurrent === 1?"Selected":"Select"} onClick={()=>upgradeplan(plan.subscriptionplanid)} btnStyle={{m: "0"}}/>
            </CardFooter>
          </Card>
          ))}
          {/* <Card className="border-basic-plan" mb="20px" borderRadius="12px" boxShadow="0px 8px 24px 0px #B4B4B452">
            <CardHeader borderRadius="12px">
              <Flex flexDir="column" justifyContent="end" h="100%">
                  <Flex justifyContent="space-between" w="100%" alignItems="center">
                      <Text fontSize="24px" fontWeight="600">Basic</Text>
                      <Box  fontSize="10px" fontWeight="700" border="1px solid #A1E8CE" textAlign="center" borderRadius="full" p="5px 10px" color="#0C7650">
                          CURRENT PLAN
                      </Box>
                  </Flex>
                  <Text color="#384052" fontSize="20px" fontWeight="500">
                      $0/month <Box as="span" pl="5px" fontSize="8px" fontWeight="500">*Billed annually</Box>
                  </Text>
              </Flex>
              <Divider mt="15px" />
            </CardHeader>
            <CardBody pt="0">
              <Flex flexDir="column" gap="10px">
                  <Box display="flex" gap="10px" alignItems="center">
                      <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                      <Text fontSize="14px" fontWeight="700">10 <Box as="span" fontWeight="500">documents upload</Box></Text>
                  </Box>
                  <Box display="flex" gap="10px" alignItems="center">
                      <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                      <Text fontSize="14px" fontWeight="700">10 <Box as="span" fontWeight="500">credits for AI assist</Box></Text>
                  </Box>
                  <Box display="flex" gap="10px" alignItems="center">
                      <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                      <Text fontSize="14px" fontWeight="500">Doctor appointments</Text>
                  </Box>
                  <Box display="flex" gap="10px" alignItems="center">
                      <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                      <Text fontSize="14px" fontWeight="500">Reports</Text>
                  </Box>
                  <Box display="flex" gap="10px" alignItems="center">
                      <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                      <Text fontSize="14px" fontWeight="500">Data sharing to physicians</Text>
                  </Box>
                  <Box display="flex" gap="10px" alignItems="center">
                      <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                      <Text fontSize="14px" fontWeight="500">Add family members</Text>
                  </Box>
              </Flex>
            </CardBody>
            <CardFooter>
              <PrimaryButton buttonText="Downgrade" btnStyle={{m: "0", bg: "none", color: "#061027", border: "2px solid #E6E7E9", borderRadius: "10px", boxShadow: "0px 0px 0px 3px #E6E7E94D, 0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset"}}/>
            </CardFooter>
          </Card> */}
        </>
      }
      />
        <DynamicModal
        ref={cancelPlan}
        modalHeader="Confirm Cancellation"
        modalBody={
          <>
            <Box>
              <Text fontSize="0.87rem" mb="1rem" fontWeight="400">
                If you confirm and end your subscription now, you can still access it until <Text as="span" fontSize="0.9rem" fontWeight="600">{PlanEndDate}</Text>.
              </Text>

              {/* <Flex flexDir="column" gap="0.55rem">
                <Box display="flex" gap="0.55rem" alignItems="center">
                  <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                  <Text fontSize="14px" fontWeight="700">
                    Unlimited{" "}
                    <Box as="span" fontWeight="500">
                      appointments per month
                    </Box>
                  </Text>
                </Box>
                <Box display="flex" gap="0.55rem" alignItems="center">
                  <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                  <Text fontSize="14px" fontWeight="700">
                    500{" "}
                    <Box as="span" fontWeight="500">
                      500 credits for AI assist
                    </Box>
                  </Text>
                </Box>
                <Box display="flex" gap="0.55rem" alignItems="center">
                  <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                  <Text fontSize="14px" fontWeight="500">
                    Reports
                  </Text>
                </Box>
                <Box display="flex" gap="0.55rem" alignItems="center">
                  <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                  <Text fontSize="14px" fontWeight="500">
                    Add appointments manually
                  </Text>
                </Box>
              </Flex> */}
            </Box>
          </>
        }
        modalFooterBtn={
          <>
           <Box display="flex" gap="1rem" w="100%">
              <PrimaryButton onClick={closepopupcancelplan} variant="grayBtnFull" buttonText="Not now" />
              <PrimaryButton onClick={cancelactivePlan} variant="deleteBtn" buttonText="Confirm" />
           </Box>
          </>
        }
      />

<ModalRight
        ref={infoModal}
      header="You have access to"
        // subHeader="You have access to."
        body={
          <Flex pt="15px" flexDirection="column" h="100%">
            {
              infoArray && infoArray.map((item, key)=>(
                <Box index={key} display="flex" alignItems="baseline" justifyContent="left" my="0.6rem">
                <Image mx="0.5rem" w="12px" h="auto" src="/assets/imgs/check-black.png" />
                <Text fontSize="14px" fontWeight="600">{item.name}</Text>
                </Box>
              ))
            }
         
          </Flex>
        }
      />
    </>
  );
};

export default SubscriptionIndex;
