import moment from "moment";

export default function formAPINotifications(dataArray) {
  // Assuming dataArray is an array of objects
  return dataArray.map(({ 
    cohortlinkid,
    createddate, 
    notificationcontent,
    notificationfor,
    notificationtype,
    entityid,


    }) => {
   //  const testDate = "2022-12-06T10:25:57.293";
   const formattedDate = moment.utc(createddate); // Parse input as UTC
   const currentDate = moment(); // Assuming current date/time is in local time
   formattedDate.local(); // Convert parsed UTC time to local time
   const minutesDifference = currentDate.diff(formattedDate, 'minutes');
   const hoursDifference = Math.floor(minutesDifference / 60);
   const daysDifference = Math.floor(minutesDifference / (60 * 24));
   let timeDifference;
   if (daysDifference > 7) {
     const weeks = Math.floor(daysDifference / 7);
     const months = Math.floor(weeks/4);
     const years = Math.floor(months / 12);
     if(weeks == 1) {
       timeDifference = "1 week ago"
   
     }else if(weeks > 1 && weeks < 5){
       timeDifference = `${weeks} weeks ago`
   
     }else if (months == 1){
       timeDifference = "1 month ago"
   
     }else if (months > 1 && months < 12){
       timeDifference = `${months} months ago`
   
     }else if (years == 1){
       timeDifference = "1 year ago"
   
     }else if (years > 1){
       timeDifference = `${years} years ago`
   
     }
    // timeDifference = formattedDate.format("Do MMM YYYY");
   } else {
     timeDifference = daysDifference > 1 ? `${daysDifference} days ago` : 
                      daysDifference == 1 ? `${daysDifference} day ago` :
                     hoursDifference > 1 ? `${hoursDifference} hrs ago` : 
                     hoursDifference == 1 ? `${hoursDifference} hr ago` : 
                     minutesDifference == 1 ? `${minutesDifference} min ago` : 
                     minutesDifference < 1 ? `Updated Just Now` : 
                     `${minutesDifference} mins ago`;
   }
    return Object.freeze({
        cohortlinkid,
        createddate, 
        notificationcontent,
        notificationfor,
        notificationtype,
        timeDifference:timeDifference,
        entityid,
      
    });
  });
}
 