import React, { useEffect, useRef, useState } from 'react'
import { FetchAccountData, FetchRefferal, updatePatientProfile, uploadImageInProfile } from '../../middleware/services/profile/account'
import { Image } from '@chakra-ui/image'
import { Box, Flex } from '@chakra-ui/layout'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Textarea,
  RadioGroup,
  Radio,
} from '@chakra-ui/react'
import SecondaryNav from '../../components/seconday-nav'
import { PrimaryButtonIcon } from '../../components'
import ProfileSideBar from './profileSidebar'
import ModalRight from '../../components/modal-right'
import {COLORS} from '../../components/styles/colors'
import { VStack, Text, Spacer, Center, Input ,
  Divider,
  Menu,
  MenuButton,} from "@chakra-ui/react";
import { DropdownInput, PrimaryButton, PrimaryInput, PrimaryInputDropdown, SearchBar, TagsInput, Toaster } from "../../components";
import MiniDropdownInput from '../../components/mini-dropdown';
import { GetMedicineName } from '../../middleware/services/myhealth/medications'
import useLoader from '../../components/loader/loaderstates'
import Loader from '../../components/loader/loader'
import { format } from 'date-fns'
import DatePicker from '../../components/date-picker'
import FetchData from '../../middleware/client'
import { FetchLogin, Logout } from '../../middleware/services/login'
import moment from 'moment'
import DynamicModal from '../../components/dynamic-modal'
import { cmToFtConverter, ftToCmConverter, getCroppedImg, getPatientCountryid, kgToLbConverter, lbToKgConverter } from '../../auth'
import ImageCropper from '../../components/image-cropper'
import DropdownInputForVitals from '../../components/dropdown-input-forVitals'
import MobileNavbar from '../../components/mobile-navbar'
import MobileSecondaryNav from '../../components/mobile-secondary-navbar'
import { FetchRewards } from '../../middleware/services/profile/rewards'
import { Link, useNavigate } from 'react-router-dom'
import CopyToClipboard from "react-copy-to-clipboard";


function Account() {

  const { showSuccessToast, showErrorToast } = Toaster();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [patientid, setPatientid] = useState("")
  const [accountData, setAccountData] = useState("")
  const [Profilepic, setProfilepic] = useState("")
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [gender, setGender] = useState('');
  const [height, setHeight] = useState(null);
  const [heightunit_cd, setHeightunit_cd] = useState('152');
  const [weight, setWeight] = useState(null);
  const [weightInitial, setWeightInitial] = useState(null);
  const [weightunit_cd, setWeightunit_cd] = useState('154');
  const [dob, setDob] = useState('');
  const [mobileno, setMobileno] = useState('');
  const [email, setEmail] = useState('');
  const [countrycode, setCountrycode] = useState('')
  const [patientimage, setPatientimage] = useState('')
  const [imagePath, setImagePath] = useState("https://midocfileserver.blob.core.windows.net/midocdev/"
  )
  const [datePrePopulate, setDatePrePopulate] = useState('');
  const [defaultCountryCode , setDefaultCountryCode] = useState('');
  const [address, setAddress] = useState('');
  
  const editPhoto = useRef();
  const [showCropper, setShowCropper] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [vitalunitheight, setVitalUnitHeight] = useState("");
  const [vitalunitheight_cd, setVitalUnitHeight_cd] = useState("");
  const [BMICalculatorType, setBMICalculatorType] = useState('standard');

  const [vitalunitweight, setVitalUnitWeight] = useState("");
  
  const [feetValue , setFeetValue] = useState("")
  const [inchValue , setInchValue] = useState("")
  const [inchToCalculate , setInchToCalculate] = useState("")
  
  const [heightOptionsInBMI, setHeightOptionsInBMI] = useState([]);
  const [weightOptionsInBMI, setWeightOptionsInBMI] = useState([]);
  
 const [ weightUnitBMI , setWeightUnitBMI ]= useState("")
 const [ heightUnitBMI , setHeightUnitBMI ]= useState("")
 const [showInchesInput , setShowInchesInput] = useState(true)
 const [feetOptions, setFeetOptions] = useState([]);
 const [inchesOptions, setInchesOptions] = useState([]);
 const [rewardData, setRewardData] = useState("");
 const [boxColor , setBoxColor] = useState("#E98D3A")
 const navigate = useNavigate()
 const [data, setData] = useState("");
 const [copied, setCopied] = useState(false);
 const referFriend = useRef();
 
 const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
 const [time, setTime] = useState(moment().format("HH:mm"));

  var input = {
    // patientid
  }

  useEffect(() => {
    showLoader();
    // var loginid = localStorage.getItem("loginid")
    // setPatientid(loginid)
    var countrycode = localStorage.getItem("countryid")
    if (countrycode == "+1") {
      setCountrycode("229")
    } else if (countrycode == "+93") {
      setCountrycode("1")
    } else if (countrycode == "+91") {
      setCountrycode("95")
    }

    var fetchData = async () => {
      try {
        var result = await FetchAccountData(input)
        //console.log(result , "get result of account ")
        if (result) {
          setAccountData(result);
          if (result && result.patientimage) {
            if (result.patientimage.startsWith('http')) {
              setProfilepic(result.patientimage);
            } else if (result.imagepath) {
              setProfilepic(result.imagepath + result.patientimage);
            }
          }
      //  alert(accountData.heightunit_cd)
          setFirstname(accountData.firstname)
          setLastname(accountData.lastname)
          setDob(accountData.dob)
          setGender(accountData.gender)
          setHeight(accountData.height)
          if (accountData.heightunit_cd == "feet") {
            setHeightunit_cd("152")
          } else {
            setHeightunit_cd("151")
          }
          setWeight(accountData.weight)
          setWeight(result.weight)
          setWeightInitial(result.weight)

          if (result.heightunit_cd == "feet") {
            setHeightunit_cd("152")
            if(result.height){
              let parts = result.height.toString().split('.');
              setFeetValue(parts[0])
              setInchValue(parts[1])
              
              setInchToCalculate(parts[1])
            }
          } else {
            setHeightunit_cd("151")
            if(result.height){
              var heightVlue = cmToFtConverter(result.height)
              setFeetValue(heightVlue.feet)
              setInchValue(heightVlue.inches)
              setInchToCalculate(heightVlue.inchesToCalculate)
            }
          }
          
          
          setHeightUnitBMI(result.weightunit_cd)

          if (accountData.weightunit_cd == "kg") {
            setWeightunit_cd("154")
          } else {
            setWeightunit_cd("155")
          }
          setMobileno(accountData.mobileno)
          setEmail(accountData.email)
        } else {
          showErrorToast("Something went wrong!")
        }

        if (result) {
          var countryCode = result.countrycode;
          if (countryCode == "229") {
            sessionStorage.setItem("country-id", "+1")
            sessionStorage.setItem("country-flag", "/assets/imgs/usa.png")

          } else if (countryCode == "1") {
            sessionStorage.setItem("country-id", "+93")
            sessionStorage.setItem("country-flag", "/assets/imgs/afg.png")

          } else if (countryCode == "95") {
            sessionStorage.setItem("country-id", "+91")
            sessionStorage.setItem("country-flag", "/assets/imgs/india.jpg")

          }
          // setCountrycode(countryCode)
        }

      } catch (error) {
        console.log(error, "error in fetching account data")
      }
      dismissLoader();
    }
    fetchData();

    // dismissLoader();
  }, [])


  var customHeightValue;
  if(BMICalculatorType == "standard"){
    customHeightValue = feetValue;
    if(inchValue && feetValue){
      
      customHeightValue = feetValue && inchValue ? feetValue + "." + inchValue : feetValue;
    }
  }else{
    customHeightValue = height;
  }
  var inputForUpdate = {
    patientid,
    firstname,
    lastname,
    gender,

    "height":customHeightValue,
    "heightunit_cd": vitalunitheight == "ft" ? 152 : 151,
    "weight":weight,
    "weightunit_cd":  vitalunitweight == "kg" ? 154 : 155,
    dob,
    mobileno,
    email,
    countryid: getPatientCountryid(),
    address,
    
    "vitaladdeddate": `${date} ${time}`,
  }
 
  const isAtLeast13YearsOld = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
  
    // Adjust age if the birth month is later in the year than the current month
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age >= 13;
  };
  const editProfile = () => {
    const editProfile = async () => {
      try {
        showLoader();
        const result = await updatePatientProfile(inputForUpdate);
        if (result.output) {
          showSuccessToast("Updated Successfully.")
          var patientDetails = localStorage.getItem("patientDetails")
          if(patientDetails){
            
          var formateddata = JSON.parse(patientDetails)
           var activeUserId = formateddata.activeuserid;
           if(activeUserId == null ){
            
          localStorage.setItem("username", firstname + " " + lastname);
          var newdob = moment(dob).format("YYYY-MM-DD");
          const updatedPatientDetails = { ...formateddata, dob: newdob };
          localStorage.setItem('patientDetails', JSON.stringify(updatedPatientDetails));
          }

          }
          //  window.location.reload();
        } else {
          showErrorToast("Something went wrong!")
        }
      } catch (error) {
        console.log(error, "error in updating patient profile")

      }
      editProfileModal.current.closeRight()
      var result = await FetchAccountData(input)
      setAccountData(result)
      dismissLoader();
    }

    let decimalPartAsInt ;
    if(height){
      const valueStr = height.toString();
      const decimalIndex = valueStr.indexOf('.');
      if (decimalIndex !== -1) {
          const decimalPart = valueStr.substring(decimalIndex + 1);
           decimalPartAsInt = parseInt(decimalPart, 10);
      }
    }
   
    
  //   if (heightunit_cd == "151" && height !== null  && height !== "" && height.length < 2) {
  //     showErrorToast("Please enter at least two digits for height in centimeters.");
  //      return false;
  //  }
  //  else 
  if (!firstname) {
    showErrorToast("Please enter a First Name!");
     return false;
 }
   if (heightunit_cd == "151" && height !== null  && (height < 30 || height > 303)) {
    showErrorToast("Please enter a valid height value, ranging between 30 to 303 in centimeters!");
     return false;
 } else if (BMICalculatorType == "standard" && feetValue !== null  && (feetValue < 1 || feetValue > 10)) {
  showErrorToast("Please enter a valid height value, ranging between 1 to 10 in Feet!");
   return false;
}  else if (weightunit_cd == "154" && weight !== null  && (weight < 1 || weight > 250)) {
  showErrorToast("Please enter a valid weight value, ranging between 1 to 250 in kg!");
   return false;
} else if (weightunit_cd == "155" && weight !== null  && (weight < 1 || weight > 479)) {
showErrorToast("Please enter a valid weight value, ranging between 1 to 479 in lbs!");
 return false;
}
// else if (heightunit_cd == "152" && height !== null && decimalPartAsInt >= 12) {
//   showErrorToast("Please enter a valid height value in feet");
//   return false;
// }
else if (BMICalculatorType == "standard" &&  (inchValue && ( inchValue < 0 || inchValue > 12)) ) {
  showErrorToast("Please enter a valid inch value!");
  return false;
} else if (!isAtLeast13YearsOld(dob)) {
  showErrorToast("Age must be at least 13 years!")
  return false;
}else{
    editProfile();
   }


  }
  useEffect(()=>{
    if(vitalunitheight == "ft"){
      setShowInchesInput(true)
     // alert(vitalunitheight)
    }else{
      setShowInchesInput(false)
    }
  }, [vitalunitheight])
  const openEditProfileModal = () => {
    setVitalUnitHeight("ft");
    setVitalUnitWeight("lbs");

    if(accountData.weightunit_cd && accountData.weightunit_cd == "kg"){
      if(accountData.weight !== null){
        setWeight(accountData.weight)
      }else{
        setWeight(null)
      }
      var converterValue = kgToLbConverter(weightInitial)
      setWeight(converterValue.value)
    }
    setBMICalculatorType("standard")
    const tempOptions = [];
    const tempOptionsInches = [];
    const tempOptionsHeight = [];
    const tempOptionsWeightLB = [];
    for (let i = 1; i <= 10; i++) {
      tempOptions.push(i);
    }
    for (let i = 1; i <= 11; i++) {
      tempOptionsInches.push(i);
    }
    for (let i = 1; i <= 10; i++) {
      tempOptionsHeight.push(i);
       for (let j = 1; j <= 11; j++) {
        tempOptionsHeight.push((`${i}.${j}`));
            }
    }
    for (let i = 1; i <= 479; i++) {
      tempOptionsWeightLB.push(i);
    }
    setFeetOptions(tempOptions)
    setInchesOptions(tempOptionsInches)
    setHeightOptionsInBMI(tempOptionsHeight)
    setWeightOptionsInBMI(tempOptionsWeightLB)

    setFirstname(accountData.firstname)
    setLastname(accountData.lastname)
    setDob(accountData.dob)
    if(accountData.dob == "-"){
      setDob(null)
    }else{
      setDob(accountData.dob)
    }
    if (accountData.dob !== "-") {
      var formatedDate = moment(accountData.dob).format("DD-MM-yyyy");
      setDatePrePopulate(formatedDate)
    } else {
      setDatePrePopulate("")
    }
    setDefaultCountryCode(accountData.countryid)
    setGender(accountData.gender)
    if(accountData.height !== null ){
      setHeight(accountData.height)
    }else{
      setHeight(null)
    }
    if (accountData.heightunit_cd == "feet") {
      setHeightunit_cd("152")
    } else if (accountData.heightunit_cd == "cms") {
      setHeightunit_cd("151")
    }
 
      setAddress(accountData.patientaddress)
    

    if (accountData.weightunit_cd == "kg") {
      setWeightunit_cd("154")
    } else if (accountData.weightunit_cd == "lbs") {
      setWeightunit_cd("155")
    }
    setMobileno(accountData.mobileno)
    setEmail(accountData.email)
    editProfileModal.current.openRight()
  }

  const editProfileModal = React.useRef()

  const handleChangeFirstName = (event) => {
    var value = event.target.value.replace(/[^\x00-\x7F]/g, '');
    setFirstname(value);
  }
  const handleChangeLastName = (event) => {
    var value = event.target.value.replace(/[^\x00-\x7F]/g, '');
    setLastname(value);
  }
  const handleChangeaddress = (event) => {
    var value = event.target.value.replace(/[^\x00-\x7F]/g, '');
    setAddress(value);
  }
  const selectGender = (selectedOption) => {
    if (selectedOption == "Male") {
      setGender("2")
    } else if (selectedOption == "Female") {
      setGender("1")
    } else if (selectedOption == "Other") {
      setGender("3")
    }
  }

  const handleSetHeight = (event) => {

    let cleanedValue = event.target.value.replace(/[^0-9.]/g, '');

    if (cleanedValue.match(/[0-9]{4,}/)) {
      cleanedValue = cleanedValue.slice(0, -1);
    }
    const decimalIndex = cleanedValue.indexOf('.');
    if (decimalIndex !== -1) {
      const parts = cleanedValue.split('.');
      cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
    }
    setHeight(cleanedValue);
    if(cleanedValue == ""){
      setHeight(null);
      }
  };

  const handleChangeHeightUnit = (selectedOption) => {
    setHeightunit_cd(selectedOption.value);
  };
  const handleChangeWeightUnit = (selectedOption) => {
    setWeightunit_cd(selectedOption.value);
  };
  const handleSetWeight = (event) => {
    let cleanedValue = event.target.value.replace(/[^0-9.]/g, '');
    if (cleanedValue.match(/[0-9]{4,}/)) {
      cleanedValue = cleanedValue.slice(0, -1);
    }
    const decimalIndex = cleanedValue.indexOf('.');
    if (decimalIndex !== -1) {
      const parts = cleanedValue.split('.');
      cleanedValue = parts[0] + '.' + parts[1].slice(0, 1);
    }
    setWeight(cleanedValue);
    if(cleanedValue == ""){
      setWeight(null);
      }
  };
  const handleChangeDOB = (selectedDate) => {
    const formattedDate = format(selectedDate, "yyyy-MM-dd");

    setDob(formattedDate); 
  };
  const handleMobileChange = (e) => {
    var countrycode = localStorage.getItem("countrycode")
    setCountrycode(countrycode)

    setMobileno(e.target.value.replace(/[^0-9]/g, "").slice(0, 10));
  }
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };


  const [imageUrl, setImageUrl] = useState("/assets/imgs/no-image-icon.png");
  const inputRef = useRef(null);

  const changeImage = () => {
    if (inputRef.current) {
      inputRef.current.click(); 
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const newImageUrl = URL.createObjectURL(file);

      setImageUrl(newImageUrl); 
    }
  };

  const inputDate = dob;

  // Parse the input date in the local time zone
  const parsedDate = new Date(inputDate);

  // Format the date to YYYY-MM-DD
  const formattedDate = `${parsedDate.getFullYear()}-${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getDate().toString().padStart(2, '0')}`;

  const handleimageselection = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
   const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
    if (!allowedTypes.includes(file.type)) {
      showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG file.");
    dismissLoader();
      return false;
    }
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
        setSelectedImage(reader.result);
        setShowCropper(true);
        editPhoto.current.openModal();
      };
      reader.readAsDataURL(file);
    }
  };

 const updatePatientProfileOnChange = async (imagesrc) => {
    var inputAfterImageUpload = {
      patientid,
      firstname,
      lastname,
      gender,
  
      height,
      heightunit_cd,
      weight,
      weightunit_cd,
      dob,
      mobileno,
      email,
      countryid: countrycode,
      patientimage: imagesrc,
    }
    try {
      showLoader();
      const result = await updatePatientProfile(inputAfterImageUpload);
      if (result) {
           localStorage.setItem("patientimage", imagePath + imagesrc);
           var patientDetails = localStorage.getItem("patientDetails")
          if(patientDetails){
            
          var formateddata = JSON.parse(patientDetails)
          const updatedPatientDetails = { ...formateddata, patientimage: imagesrc };
          localStorage.setItem('patientDetails', JSON.stringify(updatedPatientDetails));
          window.location.reload();
          }

      }
      var response = await FetchAccountData(input);
      if(response){
        setAccountData(response);
      }else {
        console.error("Error in setting account data after image upload")
      }
    } catch (error) {
      console.log(error, "error in updating patient profile");
    }
      dismissLoader();
  };

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  
  const handleCrop = async () => {
    showLoader()
    try {
      const croppedImage = await getCroppedImg(selectedImage, croppedAreaPixels);
      if (!croppedImage) {
        console.error('Cropped image is null or undefined');
        return;
      }
      const croppedUrl = URL.createObjectURL(croppedImage);
      setImageUrl(croppedUrl);

  
      const result = await uploadImageInProfile(croppedImage, 'cropped.jpg');
      const srcInResult = result.name;
      localStorage.setItem("patientimage", imagePath + srcInResult);
           var patientDetails = localStorage.getItem("patientDetails")
          if(patientDetails){
            
          var formateddata = JSON.parse(patientDetails)
          const updatedPatientDetails = { ...formateddata, patientimage: srcInResult };
          localStorage.setItem('patientDetails', JSON.stringify(updatedPatientDetails));
          }
      setPatientimage(srcInResult);
  
      const imageUpload = await updatePatientProfileOnChange(srcInResult);
         
      if (imageUpload.output.success === 1) {
        window.location.reload();
      }
  
      setShowCropper(false);
      editPhoto.current.closeModal(); // Close the modal after cropping
    } catch (error) {
      console.error('Error handling cropped image:', error);
    }
    dismissLoader()
  };

  // BMI functions
  const switchBMICalculation = (value) => {
    const tempOptionsWeightLB = [];
    const tempOptionsHeight = [];
    setBMICalculatorType(value);
    if (value == "metric") {
     // setWeight("")
      setVitalUnitHeight("cms");
      setVitalUnitWeight("kg");
      setHeightunit_cd(151)
      setWeightunit_cd(154)

      var converterValue = lbToKgConverter(weight)
      setWeight(converterValue.value.toString())

      var heightValueOfFeet = feetValue && inchValue ? feetValue + "." + inchValue : feetValue;
    //  alert(inchToCalculate)
      var converterValue = ftToCmConverter(feetValue, inchToCalculate)
      setHeight(converterValue)
      
      for (let i = 1; i <= 250; i++) {
        tempOptionsWeightLB.push(i);
      }
      for (let i = 30; i <= 303; i++) {
        tempOptionsHeight.push(i);
      }
    setHeightOptionsInBMI(tempOptionsHeight)
    setWeightOptionsInBMI(tempOptionsWeightLB)
    } else if (value == "standard") {
    //  setWeight("")
      setVitalUnitHeight("ft");
      setVitalUnitWeight("lbs");
      
      setHeightunit_cd(155)
      setWeightunit_cd(152)

      var converterValue = kgToLbConverter(weight)
      setWeight(converterValue.value)

      for (let i = 1; i <= 479; i++) {
        tempOptionsWeightLB.push(i);
      }
      for (let i = 1; i <= 10; i++) {
        tempOptionsHeight.push(i);
        for (let j = 1; j <= 9; j++) {
          tempOptionsHeight.push((`${i}.${j}`));
        }
      }
    setHeightOptionsInBMI(tempOptionsHeight)
    setWeightOptionsInBMI(tempOptionsWeightLB)
    } 
  };
  const setInchValueInDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setInchValue(stringValue);
    setInchToCalculate(stringValue)
  }
  const handleBMIinchValue = (e)=>{
    /// alert(e.target.value)
     var value = e.target.value;
     const numbersRegex = /^[0-9]+$/;
     if (numbersRegex.test(value) || value === "") { // Also allow empty value
      setInchValue(value)
    setInchToCalculate(value)
     }
     
   }
   const setFeetValueInDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setFeetValue(stringValue)
  }
  const handleBMIFeetValue = (e)=>{
    var value = e.target.value;
    const numbersRegex = /^[0-9]+$/;
    if (numbersRegex.test(value) || value === "") { 
      setFeetValue(value)
    }
    
  }

   // THis is BMI on change event
   const handlebmivitalvaluechange = (e) => {
    const { id, value } = e.target;
    const maxLength = 6;
    switch (id) {
      case "weight":
        let inputValue = value;
       const inputText = e.target.value;
        if (inputText.length <= maxLength) {
          
        let cleanedValue = inputText.replace(/[^0-9.]/g, '');
        if(cleanedValue.startsWith(".")){
          cleanedValue = cleanedValue.substring(1)
        }
        if (cleanedValue.match(/[0-9]{4,}/)) {
          cleanedValue = cleanedValue.slice(0, -1);
        }
        const decimalIndex = cleanedValue.indexOf('.');
        if (decimalIndex !== -1) {
          const parts = cleanedValue.split('.');
          cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
        }
        setWeight(cleanedValue);
        }
        break;
      case "height":
        const input = e.target.value;
      
        if (input.length <= maxLength) {
          
        let cleanedValue = input.replace(/[^0-9.]/g, '');
        if(cleanedValue.startsWith(".")){
          cleanedValue = cleanedValue.substring(1)
        }
        if (cleanedValue.match(/[0-9]{4,}/)) {
          cleanedValue = cleanedValue.slice(0, -1);
        }
        const decimalIndex = cleanedValue.indexOf('.');
        if (decimalIndex !== -1) {
          const parts = cleanedValue.split('.');
          cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
        }
        setHeight(cleanedValue);
        }
        break;
       
      default:
        break;
    }
  };
  const setHeightValueInBMIDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setHeight(stringValue)
  }
  const setWeightvalueInBMIDropdown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setWeight(stringValue)
  }

  // Rewards API call
  useEffect(() => {
    showLoader();

    var input = {}
    var getRewards = async () => {
      try {
        var result = await FetchRewards(input);
        console.log(result , " rewards Result ")
        if (result) {
         setRewardData(result);
         switch (result.position) {
          case "Gold":
            setBoxColor("#FCCE35");
            break;
          case "Bronze":
            setBoxColor("#E98D3A");
            break;
          case "Silver":
            setBoxColor("#6A707D");
            break;
          case "Platinum":
            setBoxColor("#4A67AE");
            break;
          default:
            setBoxColor("#E6E7E9");
            break;
        }
        } 

      } catch (error) {
        console.log(error, "error in fetching reward data")
      }
      dismissLoader();
    }

    getRewards();
  }, [])

  const handleLogout = async () => {
    try {
      const result = await Logout({
        patientid,
      });
      //console.log(result, "logout");
      showLoader();
      if (result.output.result === "success") {
        localStorage.clear();
        sessionStorage.clear();

        navigate("/login", { replace: true });
        dismissLoader();
      } else {
        //alert("No data");
      }
    } catch (error) {
      console.error("Error in DashboardIndex:", error);
    }
  };

  

  const copyURLToClipBoard =  () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const referAFriendFunction = ()=>{
    showLoader();
    var input = {};
    var fetchData = async () => {
      try {
        var result = await FetchRefferal(input)
        //console.log(result, " ref modified data")
        if (result.output.referralcode) {
          setData(result.output.referralcode)
        } else {
          setData("")
          //  showErrorToast("Something went wrong!")
        }
  
      } catch (error) {
        console.log(error, "error in fetching account data")
      }
      dismissLoader();
    }
  
    fetchData();
    referFriend.current.openModal()
  }

  const css = `
  .StandardMetric {
    border-top: none;
    border-bottom: none;
  }

  .bigNavItem {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    border: 1px solid #E6E7E9;
    width: 50%;
    gap: 0.625rem;
  }

  .navList {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 0;
    border-bottom: 1px solid #E6E7E9;
  }

`

  return (
    <>
      <style>{css}</style>
      {isLoading && <Loader />}
      <Box w="100%" display={{base: "none", lg: "block"}}>
        <Box position="sticky" top="0" zIndex="banner">

          <SecondaryNav
            sideBarWidth="250px"
            defaultHeader="Profile"
            defaultSecondayHeader="Account"
            defaultNavBody={
              <>
                <Box display="flex" alignItems="center" pr="25px">
                  <PrimaryButtonIcon
                    btnClass="uploadBtn"
                    buttonText="Edit Details"
                    iconSrc={false}
                    btnStyle={{ color: "#1C4EC5" }}
                    onClick={() => openEditProfileModal()}
                    iconImg="uploadDoc"
                    sx={{
                      ".uploadDoc": {
                        width: "0px!important",
                      }
                    }}
                  />
                </Box>
              </>
            }
          />
        </Box>
        <Flex>


          {/* <Box p="0" display="flex" flexDir="row"> */}
          <Box p="0" width="250px" overflow="hidden auto" height="calc(100vh - 150px)">
            <ProfileSideBar />
          </Box>
          <Box flex="1" p="24px 32px">


            <Box mb="20px">

              <Image border="1px solid #E6E7E9" w="85px" h="85px" borderRadius="full" src={accountData && accountData.patientimage !== "-" || "" ? Profilepic : "/assets/imgs/no-image-icon.png"} />
              
            </Box>
            <Box>
              <Box>
                <TableContainer border="1px solid #E6E7E9" borderRadius="10px" maxW="90%">
                  <Table variant='simple'>
                    <Tbody>
                      <Tr>
                        <Td fontSize="12px" fontWeight="500" w="25%">First Name</Td>
                        <Td fontSize="14px" fontWeight="600">{accountData?.firstname ? accountData?.firstname:"-"}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize="12px" fontWeight="500">Last Name</Td>
                        <Td fontSize="14px" fontWeight="600">{accountData?.lastname? accountData?.lastname:"-"}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize="12px" fontWeight="500">Gender</Td>
                        <Td fontSize="14px" fontWeight="600">{accountData?.gender == "1" ? "Female" : accountData?.gender == "2" ? "Male" : accountData?.gender == "3" ? "Other" : ""}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize="12px" fontWeight="500">Height</Td>
                        <Td fontSize="14px" fontWeight="600">{accountData?.height}  {accountData?.heightunit_cd}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize="12px" fontWeight="500">Weight</Td>
                        <Td fontSize="14px" fontWeight="600">{accountData?.weight}  {accountData?.weightunit_cd}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize="12px" fontWeight="500">Date of Birth</Td>
                        <Td fontSize="14px" fontWeight="600">{accountData?.dob}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize="12px" fontWeight="500">Mobile Number</Td>
                        <Td fontSize="14px" fontWeight="600">{accountData?.mobileno}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize="12px" fontWeight="500">Email Address</Td>
                        <Td fontSize="14px" fontWeight="600">{accountData?.email}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize="12px" fontWeight="500">Address</Td>
                        <Td style={{wordWrap:"break-word", whiteSpace:"normal"}} fontSize="14px" fontWeight="600">{accountData.patientaddress ? accountData.patientaddress : "-"}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize="12px" fontWeight="500">User ID</Td>
                        <Td fontSize="14px" fontWeight="600">{accountData?.uniqueid}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
          {/* </Box> */}
          <ModalRight
            ref={editProfileModal}
            modalWidth="360px"
            header="Edit Profile"
            body={
              <>
                <Box w="100%" py="10px" px="0">
                  <Center>
                    <VStack spacing={0} w="100%">
                      <Box>
                        <Image border="1px solid #E6E7E9" w="85px" h="85px" borderRadius="full" 
                        
                        // src={accountData && accountData.patientimage !== "-" || "" ? accountData.imagepath + accountData.patientimage : "/assets/imgs/no-image-icon.png"}
                        src={accountData && accountData.patientimage !== "-" || "" ? Profilepic : "/assets/imgs/no-image-icon.png"}
                         />
                        <Box as="Button" pos="relative" bottom="25px" left="58px" bg="white" p="6px" border="1px solid #E6E7E9" borderRadius="full"
                          onClick={() => document.getElementById("file-upload").click()} >
                          <input
                            type="file"
                            id="file-upload"
                            style={{ display: "none" }}
                            onChange={handleimageselection}
                          />
                          <Image w="11px" h="auto" src='/assets/imgs/edit.png' onClick={changeImage} />
                        


                        </Box>
                      </Box>
                      <Flex w="100%">
                        <PrimaryInput variant="defaultSide" inputPlace="First Name*" id="firstName" defaultValue={firstname} inputValue={firstname} onChange={handleChangeFirstName} />
                      </Flex>
                      <Flex w="100%">
                        <PrimaryInput variant="bothSide" inputPlace="Last Name" id="lastName" defaultValue={lastname} inputValue={lastname} onChange={handleChangeLastName} />
                      </Flex>
                      <Flex w="100%" zIndex="1001">
                        <DropdownInput variant="bothSide" dropDownPlace="Gender" options={["Male", "Female", "Other"]}
                          id="gender" defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                          inputValue={gender} onClick={selectGender} />
                      </Flex>

                      {/* <Flex w="100%" zIndex="999">
                        <MiniDropdownInput
                          variant="bothSide"
                          options={[
                            { label: "ft", value: "152" },
                            { label: "cm", value: "151" }
                          ]}
                          placeholderProp="Height"
                          id="heightUnits"
                          maxLength="5"
                          defaultOptionProp={heightunit_cd}
                          onSelect={handleChangeHeightUnit}
                          defaultValue={height}
                          inputVal={height}
                          handleInputChange={handleSetHeight}
                        />
                        <MiniDropdownInput
                          variant="bothSide"
                          placeholderProp="Weight"
                          options={[
                            { label: "kg", value: "154" },
                            { label: "lb", value: "155" }
                          ]}
                          id="weightUnits"
                          maxLength="5"
                          onSelect={handleChangeWeightUnit}
                          defaultOptionProp={weightunit_cd}
                          defaultValue={weight}
                          inputVal={weight}
                          handleInputChange={handleSetWeight}
                        />
                      </Flex> */}

                      
          {/* <Divider /> */}
                    <RadioGroup 
                      onChange={switchBMICalculation}
                      value={BMICalculatorType}
                      w="100%"
                      bg="#FFFFFF"
                      border="1px solid #e2e8f0"
                      pl="10px"
                    >
                    <Box display="flex" justifyContent="start" gap="1.5rem" py="10px" w="100%">
                      <Radio value="standard" colorScheme="green">
                        Standard
                      </Radio>
                    
                      <Radio value="metric" colorScheme="green">
                        Metric
                      </Radio>

                    </Box>
                  </RadioGroup>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  // py="2 0px"
                  pl="10px"
                  w="100%"
                  bg="#FFFFFF"
                  border="1px solid #e2e8f0"
                >
                  <Box fontSize="14px" fontWeight="600" w="20%">
                      Weight
                      <Box
                        as="span"
                        color="#384052"
                        fontSize="12px"
                        fontWeight="500"
                      >
  
                      </Box>
                      <>
                          <Menu>
                            <MenuButton
                              sx={{
                                color: "#1C4EC5",
                                display: "flex",
                                alignItems: "center",
                                cursor:"default"
                              }}
                            >
                              {vitalunitweight}{" "}
                              {/* <Image
                                ml="5px"
                                pos="relative"
                                bottom="2px"
                                display="inline-block"
                                src="/assets/imgs/arrow-down-gray.png"
                              /> */}
                            </MenuButton>
                           
                          </Menu>
                      </>
                  </Box>
                     <DropdownInputForVitals
                        variant="bothSide"
                        dropDownPlace="Enter Value*"
                        options={weightOptionsInBMI}
                        onClick={setWeightvalueInBMIDropdown}
                        onChange={handlebmivitalvaluechange}
                        readOnly=""
                        inputStyleCss={{w: "80%"}}
                        className="StandardMetric"
                        id="weight"
                        //  defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                        inputValueProp={weight}
                      />
                </Box>

                {/* <Divider /> */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  // py="20px"
                  pl="10px"
                  w="100%"
                  bg="#FFFFFF"
                  border="1px solid #e2e8f0"
                >
                  <Box fontSize="14px" fontWeight="600" w="20%">
                      Height
                      <Box
                        as="span"
                        color="#384052"
                        fontSize="12px"
                        fontWeight="500"
                      >
  
                      </Box>
                      <>
                      <Menu>
                            <MenuButton
                              sx={{
                                color: "#1C4EC5",
                                display: "flex",
                                alignItems: "center",
                                cursor:"default"
                              }}
                            >
                              {vitalunitheight}{" "}
                              {/* <Image
                                ml="5px"
                                pos="relative"
                                bottom="2px"
                                display="inline-block"
                                src="/assets/imgs/arrow-down-gray.png"
                              /> */}
                            </MenuButton>
                          </Menu>
                      </>
                  </Box>
                  {!showInchesInput && 
              
                  <DropdownInputForVitals
                    variant="bothSide"
                    dropDownPlace="Enter value*"
                    options={heightOptionsInBMI}
                    onClick={setHeightValueInBMIDropDown}
                    onChange={handlebmivitalvaluechange}
                    readOnly=""
                    inputStyleCss={{w: "80%"}}
                    className="StandardMetric"
                    id="height"
                    // defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                    inputValueProp={height}
                  />
                  }
                  
                  {showInchesInput && 
                  <>
                   <Box w="80%" display="flex">
                      <DropdownInputForVitals
                        variant="bothSide"
                        dropDownPlace="Feet*"
                        options={feetOptions}
                        onClick={setFeetValueInDropDown}
                        onChange={handleBMIFeetValue}
                        readOnly=""
                        inputStyleCss={{w: "50%"}}
                        className="StandardMetric"
                        id="height"
                        inputValueProp={feetValue}
                        maxLength={1}
                      />

                
                      <DropdownInputForVitals
                        variant="bothSide"
                        dropDownPlace="Inches*"
                        options={inchesOptions}
                        onClick={setInchValueInDropDown}
                        onChange={handleBMIinchValue}
                        readOnly=""
                        inputStyleCss={{w: "50%"}}
                        className="StandardMetric"
                        id="height"
                        inputValueProp={inchValue}
                        maxLength={2}
                      />
                    </Box>
                  </>
                  }
                </Box>

                {/* <Divider /> */}


                      <Flex w="100%" zIndex="9">

                        <DatePicker
                        disableFutureDates={true}
                          defaultDate={datePrePopulate}
                          onDateChange={(selectedDateValue) =>
                            handleChangeDOB(selectedDateValue)
                          }
                          placeholderProp={"Date of Birth"}
                        />
                      </Flex>
                      <Flex w="100%">
                        <PrimaryInputDropdown
                          id="mobile"
                          variant="bothSide"
                          onChange={(e) => handleMobileChange(e)}
                          defaultValue={mobileno}
                          inputValue={mobileno}
                          isDisabled={true}
                          defaultCountryCode={defaultCountryCode}
                        />
                      </Flex>
                      <Flex w="100%">

                        <PrimaryInput isDisabled={true} variant="bothSide" inputType="Email" inputPlace="Enter Email Address" id="Email" onChange={handleChangeEmail} inputValue={email} inputStyleCss={{ zIndex: "0" }} />

                      </Flex>
                      {/* <Flex w="100%">
                        <PrimaryInput variant="downSide" inputPlace="Address" id="address" defaultValue={address} inputValue={address} onChange={handleChangeaddress} />
                      </Flex> */}
                      <Flex w="100%">
                      <Textarea
                        maxLength="500"
                        value={address}
                        onChange={handleChangeaddress}
                        placeholder='Address'
                        size='sm'
                        borderRadius="0 0 10px 10px"
                      />
          </Flex>



                    </VStack>
                  </Center>
                </Box>

              </>
            }
            footerStyle={{ bg: "white", boxShadow: "0px -8px 20px 0px #E6E7E966" }}
            footerActions={<>
              <Box w="100%">
                <PrimaryButton buttonText="Update profile" id="submitButton" onClick={editProfile} />
              </Box>
            </>}
          />
          <div>
          </div>
        </Flex>
        
      <DynamicModal
        ref={editPhoto}
        modalHeader="Upload Photo"
        modalBody={
          <>
            {showCropper && selectedImage &&  (
              <Box display="flex" justifyContent="center" alignItems="center" h='400px' >
              <ImageCropper
                src={selectedImage}
                onCropComplete={handleCropComplete}
              />
              </Box>
            )}
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton 
            btnStyle={{m: "10px"}}
            buttonText="Upload" 
            onClick={() => {
              handleCrop();
              editPhoto.current.closeModal();
            }} 
            />
            <PrimaryButton
            
            btnStyle={{m: "10px"}}
             buttonText="Cancel" onClick={() => editPhoto.current.closeModal() } />
          </>
        }
        size="lg"
      />
      </Box>
      
      {/* Mobile responsive start */}
      <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
        <MobileNavbar/>

        {/* User section start */}
        <Box bg="#061027" bgImage={`url("/assets/imgs/square-bg.png")`} bgPos="right" bgRepeat="no-repeat">
          {/* <Box  top="0" zIndex="1" w="100%" h="100px" pos="absolute" /> */}
          <Flex w="100%" p="1.5rem" as='button' onClick={() => openEditProfileModal()} justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center" gap="0.75rem" w="100%">
              <Box>
                <Image border="1px solid #E6E7E9" boxSize="64px" borderRadius="full" src={accountData && accountData.patientimage !== "-" || "" ? accountData.imagepath + accountData.patientimage : "/assets/imgs/no-image-icon.png"} />
              </Box>
              <Box display="flex" flexDir="column">
                <Text fontSize="1.25rem" fontWeight="600" color='white'>{accountData?.firstname + accountData?.lastname}</Text>
                <Text fontSize="0.875rem" fontWeight="400" color='white'>{accountData?.email}</Text>
              </Box>
            </Box>
            <Image src="/assets/imgs/right-icon.png" />
          </Flex>

          <Divider opacity="0.25" />

          {/* Plan section start */}
          <Flex py="1rem" as='button' onClick={()=> navigate('/rewards')} px="1.5rem" w="100%" alignItems="center" justifyContent="space-between">
            <Box display="flex" gap="0.75rem" alignItems="center">
              <Image
                w="32px"
                h="32px"
                src={
                  rewardData.position === "Gold" ? "/assets/imgs/gold-badge.png" :
                  rewardData.position === "Bronze" ? "/assets/imgs/bronze-badge.png" :
                  rewardData.position === "Silver" ? "/assets/imgs/silver-badge.png" :
                  rewardData.position === "Platinum" ? "/assets/imgs/platinum-badge.png" :
                  rewardData.position === null ? "/assets/imgs/platinum-badge.png" :
                  "/assets/imgs/bronze-badge.png" // Default value if data.position doesn't match any of the above
                }
              />
                <Text fontSize="1rem" fontWeight="700" color="white">{rewardData.position  == null ? "Bronze" : rewardData.position}</Text>
            </Box>
            <Image src='/assets/svgs/arrow-right.svg' />
          </Flex>
          {/* Plan section end */}
        </Box>
        <Box w="100%" as="span" h="6px" display="flex" bg={boxColor}></Box>
        {/* User section end */}
        
        <Box w="100%" h='calc(100vh - 300px)' overflow="auto" bg="#FCFCFD">
          {/* Big menus start */}
          <Flex w="100%" flexDir="column" bg='#FFFFFF'>
            <Box w="100%" display="flex" justifyContent="space-between">
              <Link to='/reports' className='bigNavItem'>
                <Image boxSize="2.25rem" src='/assets/svgs/reports-gray.svg' />
                <Text fontSize="0.875rem" textAlign="center" fontWeight="600" color="#061027">Reports</Text>
              </Link>
              
              <Link to='/family' className='bigNavItem'>
                <Image boxSize="2.25rem" src='/assets/svgs/family-gray.svg' />
                <Text fontSize="0.875rem" textAlign="center" fontWeight="600" color="#061027">Family</Text>
              </Link>
            </Box>
            
            <Box w="100%" display="flex" justifyContent="space-between">
              <Link to='/orders' className='bigNavItem'>
                <Image boxSize="2.25rem" src='/assets/svgs/orders-gray.svg' />
                <Text fontSize="0.875rem" textAlign="center" fontWeight="600" color="#061027">Orders</Text>
              </Link>
              
              <Link to='/profileappointment' className='bigNavItem'>
                <Image boxSize="2.25rem" src='/assets/svgs/appointments-gray.svg' />
                <Text fontSize="0.875rem" textAlign="center" fontWeight="600" color="#061027">Appointments</Text>
              </Link>
            </Box>
          </Flex>
          {/* Big menus end */}

          {/* Menu sections start */}
          <Box w="100%" p="1.5rem" >
            <Text textTransform="uppercase" fontSize='0.75rem' fontWeight="600">Account</Text>
            <Link to='/doctors' className='navList'>
                <Box display='flex' gap='0.75rem'>
                  <Image src="/assets/svgs/doctors-blue.svg" />
                  <Text fontSize='0.875rem' fontWeight="600">Doctors</Text>
                </Box>
                <Image src="/assets/imgs/right-icon.png" />
            </Link>
            <Link to='/medicalrecord' className='navList'>
                <Box display='flex' gap='0.75rem'>
                  <Image src="/assets/svgs/share-records-blue.svg" />
                  <Text fontSize='0.875rem' fontWeight="600">Share records</Text>
                </Box>
                <Image src="/assets/imgs/right-icon.png" />
            </Link>
          </Box>

          <Box w="100%" p="1.5rem" >
            <Text textTransform="uppercase" fontSize='0.75rem' fontWeight="600">General</Text>
            <Link to='/invoices' className='navList'>
                <Box display='flex' gap='0.75rem'>
                  <Image src="/assets/svgs/invoices-blue.svg" />
                  <Text fontSize='0.875rem' fontWeight="600">Invoices</Text>
                </Box>
                <Image src="/assets/imgs/right-icon.png" />
            </Link>
            <Link to='/subscription' className='navList'>
                <Box display='flex' gap='0.75rem'>
                  <Image src="/assets/svgs/subscription-blue.svg" />
                  <Text fontSize='0.875rem' fontWeight="600">Subscriptions</Text>
                </Box>
                <Image src="/assets/imgs/right-icon.png" />
            </Link>
            <Link onClick={referAFriendFunction} className='navList'>
                <Box display='flex' gap='0.75rem'>
                  <Image src="/assets/svgs/refer-blue.svg" />
                  <Text fontSize='0.875rem' fontWeight="600">Refer a friend</Text>
                </Box>
                <Image src="/assets/imgs/right-icon.png" />
            </Link>
            <Link to='/videolibrary' className='navList'>
                <Box display='flex' gap='0.75rem'>
                  <Image src="/assets/svgs/video-blue.svg" />
                  <Text fontSize='0.875rem' fontWeight="600">Video library</Text>
                </Box>
                <Image src="/assets/imgs/right-icon.png" />
            </Link>
            <Link to='/alerts' className='navList'>
                <Box display='flex' gap='0.75rem'>
                  <Image src="/assets/svgs/alerts-blue.svg" />
                  <Text fontSize='0.875rem' fontWeight="600">Alerts</Text>
                </Box>
                <Image src="/assets/imgs/right-icon.png" />
            </Link>

            <Box pb="1rem" pt="2rem">
              <Image src='/assets/svgs/midoc-profile.svg' />
            </Box>

            <Box>
              <PrimaryButton onClick={handleLogout} btnIcon='/assets/svgs/logout-red.svg' btnStyle={{bg: "#D800270D", color: "#D80027", my: "0", _hover: {bg: "#d8002729"}}} buttonText="Logout" variant="deleteBtn" />
            </Box>
          </Box>
          {/* Menu sections end */}
        </Box>
      </Box>

      <DynamicModal
        hasHeader={false}
        hasFooter={false}
        hasCloseBtn={false}
        modalBodyStyle={{ w: "360px", maxWidth: "360px", m: "auto",  p:"24px" }}
        ref={referFriend}
        modalBody={
          <>
            <Box w="100%">
              <Flex w="100%" justifyContent="center" alignItems="center">
                <Box display="flex" alignItems="center" zIndex="6">
                  <Box borderRadius="full" mr="-8%" border="2px solid #E6E7E9">
                    <Image
                      boxSize="40px"
                      borderRadius="full"
                      border="1px solid rgb(183 183 183)"
                      src="/assets/svgs/Avatar (4).svg"
                    />
                  </Box>
                  <Box borderRadius="full" border="2px solid #E6E7E9">
                    <Image
                      boxSize="64px"
                      borderRadius="full"
                      border="1px solid rgb(183 183 183)"
                      src="/assets/svgs/Avatar (2).svg"
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  borderRadius="full"
                  mx="-8%"
                  border="2px solid #E6E7E9"
                  zIndex="10"
                >
                  <Image
                    boxSize="74px"
                    borderRadius="full"
                    border="1px solid rgb(183 183 183)"
                    src="/assets/svgs/Avatar.svg"
                  />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                  zIndex="6"
                >
                  <Box
                    borderRadius="full"
                    border="2px solid #E6E7E9"
                    zIndex="3"
                  >
                    <Image
                      boxSize="64px"
                      borderRadius="full"
                      border="1px solid rgb(183 183 183)"
                      src="/assets/svgs/Avatar (1).svg"
                    />
                  </Box>
                  <Box
                    borderRadius="full"
                    ml="-8%"
                    border="2px solid #E6E7E9"
                    zIndex="2"
                  >
                    <Image
                      boxSize="40px"
                      borderRadius="full"
                      border="1px solid rgb(183 183 183)"
                      src="/assets/svgs/Avatar (3).svg"
                    />
                  </Box>
                </Box>
              </Flex>
              <Box py="10px">
                <Text textAlign="center" fontSize="24px" fontWeight="600">Refer a friend</Text>
                <Text textAlign="center" fontSize="14px" fontWeight="400">
                  Refer a friend for a free month subscription upto 12 months!
                </Text>
              </Box>
              <Divider my="10px" />
              <Text py="10px" textAlign="center" fontSize="14px" fontWeight="600">Share this link with your friend</Text>
              <Box
                w="100%"
                as="button"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                border="1px solid rgba(230, 231, 233, 1)"
                p="14px 16px"
                borderRadius="10px"
              >
                <Box
                  as="span"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  textAlign="left"
                  whiteSpace="nowrap"
                  w="75%"
                >
                  {data && data? data:""}
                </Box>
                {!copied ? (
                <Box as="button" pr="5px">
                  <CopyToClipboard
                     text={data}
                     onCopy={copyURLToClipBoard}>
                       <Image src="/assets/imgs/copy.png" />
                  </CopyToClipboard>
                </Box>
                ):(
                 <p>Copied</p>
                )}
              </Box>

              <PrimaryButton
                variant="grayBtnFull"
                btnStyle={{
                  marginTop: "20px",
                  marginBottom: "0",
                  color: "#1C4EC5",
                  width: "100%!important",
                  height: "40px!important",
                }}
                buttonText="Dismiss"
                onClick={() => referFriend.current.closeModal()}
              />
            </Box>
          </>
        }
      />
    </>
  )
}

export default Account