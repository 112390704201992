import moment from "moment";
export default function formAPIcountry({
    countryid,
    countryname,
    countrynamelocal,
    currencyname,
    flagimageurl,
    countrycode,
    shortcountrycode,
    phonenumberlength,
    createddate,
    createdby,
    modifieddate,
    modifiedby,
    isactive
    
   

})
{
  return Object.freeze({
    countryid,
    countryname,
    countrynamelocal,
    currencyname,
    flagimageurl,
    countrycode,
    shortcountrycode,
    phonenumberlength,
    createddate,
    createdby,
    modifieddate,
    modifiedby,
    isactive
   
  });
}