import formAPIFamilyAllergy from "./allergy";
import formAPIFamilyMedication from "./medication";


var formattedOutput = null;

const formAPIModelFamilyMedications = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data?.map(item => formAPIFamilyMedication(item));


  return formattedOutput
};

export default formAPIModelFamilyMedications