import { Box, Flex, Text, Spacer } from "@chakra-ui/layout";
import GrayCard from "../../components/card";
import SimpleTable from "../../components/simple-table";
import Footer from "../../components/footer";
import React, { useEffect, useRef, useState } from "react";
import { SimpleGrid, Center } from "@chakra-ui/react";
import { FetchAccountData } from '../../middleware/services/profile/account'

import {
  FetchMedicalRecords,
  FetchMyHealthData,
} from "../../middleware/services/myhealth";
import {
  Image,
  List,
  ListItem,
  ListIcon,
  UnorderedList,
  StepSeparator,
  Stack,
  VStack,
  Circle,
  Divider,
} from "@chakra-ui/react";
import ModalRight from "../../components/modal-right";
import DoctorCard from "../../components/doctor-card";
import { useNavigate } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import moment from "moment";
import { capitalizeFirstLetter, formatDate_yyyy } from "../../auth";

const PatientShareRecord = () => {
  var [data, setData] = useState(null);
  var [patientid, setPatientid] = useState("193");
  const [uuid, setUuid] = useState("");
  const treatmentplan = React.useRef();
  const pastmedicalhistory = React.useRef();
  const pastsurgicalhistory = React.useRef();
  const historyofillness = React.useRef();
  const vitalsModals = React.useRef();
  var navigate = useNavigate();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [expired, setExpired] = useState(false);
  const [patientInfo, setPatientInfo] = useState("")
  const dataTable = {
    "White blood cells": "",
    Hemoglobin: "",
    Hematocrit: "",
    "Platelet count": "",
    Chloride: "",
    Sodium: "",
    Potassium: "",
    CO2: "",
    BUN: "",
    Creatinine: "",
    Glucose: "",
  };

  const dataEntries = Object.entries(dataTable);
  const removeDateIcon = {
    'input[type="date"]': {
      "&::-webkit-inner-spin-button": {
        display: "none",
        WebkitAppearance: "none",
      },
      "&::-webkit-calendar-picker-indicator": {
        display: "none",
        WebkitAppearance: "none",
      },
    },
  };
  useEffect(() => {
    const currentURL = window.location.href;
    const urlParams = new URLSearchParams(currentURL.split("?")[1]);
    const idValue = urlParams.get("id");
    setUuid(idValue);
  }, []);

  useEffect(() => {
    if (uuid) {
      fetchMedicalRecords();
    }
  }, [uuid]);

  const fetchData = async () => {
    showLoader();
    try {
      const result = await FetchMyHealthData({
        patientid,
        // appointmentid: "",
      });

      if (result != null) {
        // setDataOnPageLoad(result);
        // setData(result);
        dismissLoader();
      } else {
        //alert("No data");
      }
    } catch (error) {
      console.error("Error in DashboardIndex:", error);
    }
  };

  const inputForMedicalRecords = {
    uuid: uuid,
  };
  const fetchMedicalRecords = async () => {
    showLoader();
    try {
      const result = await FetchMedicalRecords(inputForMedicalRecords);
      console.log(result, "patient data")
      if (result != null) {
        setData(result);
        setPatientInfo(result.patientinfo)
        setExpired(false);
        dismissLoader();
      } else if (!result) {
        setExpired(true);
        dismissLoader();
      } else {
        console.error("Something went wrong");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in DashboardIndex:", error);
    }
  };



  var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') == -1 &&
  navigator.userAgent.indexOf('FxiOS') == -1;


  const fileInputRef = useRef(null);

  const navigatePatientLabs = () => {
    navigate("/patientlabs?id="+ uuid + "&type=lab");
    window.scrollTo(0, 0);
  };

  const navigatePatientImaging = () => {
    // fileInputRef.current.click();
    navigate("/patientimaging?id="+ uuid + "&type=imaging");

    window.scrollTo(0, 0);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };
  return (
    <Box w="100%">
      <Box
        top="0"
        display="flex"
        zIndex="banner"
        as="nav"
        w="100%"
        p="10px"
        alignItems="center"
        justifyContent="center"
        wrap="wrap"
        bg="#061027"
      >
        <Image src="/assets/imgs/midoc-logo.png" />
      </Box>

      {isLoading && <Loader />}
      {!expired ? (
        <Flex overflowY="auto" height="calc(100vh - 105px)">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            gap="20px"
            width="100vw"
            p={4}
          >
            <Flex
              flexDirection="row"
              justifyContent="flex-start"
              gap="20px"
              boxSizing="border-box"
              w="100%"
              flexWrap="wrap"
            >
              {/* Patient profile */}
              <Box w="100%" display="flex" gap="1rem" alignItems="flex-start" flexWrap={{ base: "wrap", sm: "wrap", md: "nowrap", lg: "nowrap" }} p="1rem 0">
                {data && data.patientinfo != null && (
                <>
                <Box borderRadius="full" w="5.5rem" h="5.5rem" overflow="hidden">
                  <Image w="100%" h="100%" objectFit="cover"src={patientInfo.patientimage 
                                                                 ? (patientInfo.patientimage.includes("http") ? patientInfo.patientimage 
                                                                                                             : patientInfo.imagepath + patientInfo.patientimage) 
                                                                 : "/assets/imgs/no-image-icon.png"}
                                                                 />
                </Box>

                <Box display='flex' flexDir="column" w='100%'>
                    <Box w="100%">
                      <Text fontSize="1.375rem" fontWeight="600">{patientInfo.firstname + patientInfo.lastname}</Text>
                    </Box>
                  <Box display="flex" gap="1rem">
                      <Box>
                        <Box display="flex" alignItems="center">
                          <Image boxSize="14px" src="/assets/svgs/email.svg" />
                          <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                              {patientInfo.email}
                          </Text>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Image boxSize="14px" src="/assets/svgs/phone-number.svg" />
                          <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                            {patientInfo.mobileno}
                          </Text>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Image boxSize="14px" src="/assets/svgs/gender.svg" />
                          <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                            {patientInfo.gender}
                          </Text>
                        </Box>
                      </Box>

                      <Box>
                        <Box display="flex" alignItems="center">
                          <Image boxSize="14px" src="/assets/svgs/height.svg" />
                          <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                              {patientInfo.height} <Box as="sup">{patientInfo.heightunit}</Box>
                          </Text>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Image boxSize="14px" src="/assets/svgs/weight.svg" />
                          <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                              {patientInfo.weight} {patientInfo.weightunit} 
                          </Text>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Image boxSize="14px" src="/assets/svgs/cake.svg" />
                          <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                          {formatDate(patientInfo.dob)}
                          </Text>
                        </Box>
                      </Box>
                  </Box>

                </Box>

                <Box>
                  
                </Box>
                </>
                )}
              </Box>
              {/* Demographics */}
              {data && data.demographics ? (
                <GrayCard
                  w={{base: "100%", md: "32%", lg: "32%"}}
                  // minW={{sm: "100%", md: "32%", lg: "32%"}}
                  // maxW="32%"
                  cardHeader="Demographics"
                  cardHeaderIcon="/assets/imgs/measure.png"
                  bodyStyle={{ p: "0" }}
                  cardBody={
                    <>
                      <Flex direction="row" justify="space-around" h="full">
                        <Box p="10px" alignItems="center" w="100%">
                          <Box
                            as="p"
                            display="inline-block"
                            fontSize="22px"
                            fontWeight="600"
                          >
                            {data.demographics.height
                              ? data.demographics.height
                              : "-"}
                          </Box>
                          <Box
                            display="inline-block"
                            as="p"
                            fontSize="15px"
                            fontWeight="400"
                            pl={"5px"}
                          >
                            {data.demographics.height
                              ? data.demographics.heightunit
                              : ""}
                          </Box>
                          <Box
                            as="p"
                            fontSize="12px"
                            fontWeight="500"
                            color="#6A707D"
                          >
                            Height
                          </Box>
                        </Box>
                        <Box
                          p="10px"
                          w="100%"
                          alignItems="center"
                          borderRight="1px solid #E6E7E9"
                          borderLeft="1px solid #E6E7E9"
                          _last={{ borderRight: 0 }}
                        >
                          <Box
                            as="p"
                            display="inline-block"
                            fontSize="22px"
                            fontWeight="600"
                          >
                            {data.demographics.weight
                              ? data.demographics.weight
                              : "-"}
                          </Box>
                          <Box
                            display="inline-block"
                            as="p"
                            fontSize="15px"
                            fontWeight="400"
                            pl={"5px"}
                          >
                            {data.demographics.weight
                              ? data.demographics.weightunit
                              : ""}
                          </Box>
                          <Box
                            as="p"
                            fontSize="12px"
                            fontWeight="500"
                            color="#6A707D"
                          >
                            Weight
                          </Box>
                        </Box>
                        <Box p="10px" w="100%" alignItems="center">
                          <Box
                            as="p"
                            display="inline-block"
                            fontSize="22px"
                            fontWeight="600"
                          >
                            {data.demographics.bmi
                              ? data.demographics.bmi
                              : "-"}
                          </Box>
                          <Box
                            display="inline-block"
                            as="p"
                            fontSize="15px"
                            fontWeight="400"
                          ></Box>
                          <Box
                            as="p"
                            fontSize="12px"
                            fontWeight="500"
                            color="#6A707D"
                          >
                            BMI
                          </Box>
                        </Box>
                      </Flex>
                    </>
                  }
                  hasFooter={false}
                  // cardFooterText="Update Data"
                  // cardFooterClickEvent={navigateToVitals}
                />
              ) : (
                <GrayCard
                  w={{base: "100%", md: "32%", lg: "32%"}}
                  // minW={{sm: "100%", md: "32%", lg: "32%"}}
                  // maxW="32%"
                  cardHeader="Demographics"
                  cardHeaderIcon="/assets/imgs/measure.png"
                  bodyStyle={{ p: "0" }}
                  cardBody={
                    <>
                      <Flex direction="row" justify="space-around" h="full">
                        <Box p="10px" alignItems="center" w="100%">
                          <Box
                            as="p"
                            display="inline-block"
                            fontSize="22px"
                            fontWeight="600"
                          >
                            -
                          </Box>
                          <Box
                            display="inline-block"
                            as="p"
                            fontSize="15px"
                            fontWeight="400"
                          ></Box>
                          <Box
                            as="p"
                            fontSize="12px"
                            fontWeight="500"
                            color="#6A707D"
                          >
                            Height
                          </Box>
                        </Box>
                        <Box
                          p="10px"
                          w="100%"
                          alignItems="center"
                          borderRight="1px solid #E6E7E9"
                          borderLeft="1px solid #E6E7E9"
                          _last={{ borderRight: 0 }}
                        >
                          <Box
                            as="p"
                            display="inline-block"
                            fontSize="22px"
                            fontWeight="600"
                          >
                            -
                          </Box>
                          <Box
                            display="inline-block"
                            as="p"
                            fontSize="15px"
                            fontWeight="400"
                          ></Box>
                          <Box
                            as="p"
                            fontSize="12px"
                            fontWeight="500"
                            color="#6A707D"
                          >
                            Height
                          </Box>
                        </Box>
                        <Box p="10px" w="100%" alignItems="center">
                          <Box
                            as="p"
                            display="inline-block"
                            fontSize="22px"
                            fontWeight="600"
                          >
                            -
                          </Box>
                          <Box
                            display="inline-block"
                            as="p"
                            fontSize="15px"
                            fontWeight="400"
                          ></Box>
                          <Box
                            as="p"
                            fontSize="12px"
                            fontWeight="500"
                            color="#6A707D"
                          >
                            Height
                          </Box>
                        </Box>
                      </Flex>
                    </>
                  }
                  hasFooter={false}
                  // cardFooterText="Add data"
                />
              )}
              {/* Chief Complaint */}
              {/* {data &&
                data.chiefcomplaint &&
                data.chiefcomplaint.length > 0 && (
                  <GrayCard
                    w={{base: "100%", md: "32%", lg: "32%"}}
                    // minW={{sm: "100%", md: "32%", lg: "32%"}}
                    // maxW="32%"
                    cardHeader="chief complaint"
                    cardHeaderIcon="/assets/imgs/cheif-complaint.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                          alignItems="center"
                        >
                          <Box
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            <Image
                              w="26px"
                              opacity="0.5"
                              src="/assets/imgs/cheif-complaint.png"
                            />
                          </Box>
                          <Text
                            textAlign="left"
                            fontSize="12px"
                            fontWeight="500"
                          >
                            No Data available
                          </Text>
                        </Box>
                      </>
                    }
                    cardFooterText="Add data"
                  />
                )} */}
              {/* Present Illness */}
              {/* {data && data.historyofillness != null && (
                <GrayCard
                  w={{base: "100%", md: "32%", lg: "32%"}}
                  // minW={{sm: "100%", md: "32%", lg: "32%"}}
                  // maxW="32%"
                  cardHeader="history of present illness"
                  cardHeaderIcon="/assets/imgs/medical-history.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/medical-history.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    </>
                  }
                  cardFooterText="Add data"
                  onClick={() => historyofillness.current.openRight()}
                />
              )} */}
              {/* Physical Exam */}
              {/* {data && data.physicalexam && data.physicalexam.length > 0 && (
                <GrayCard
                  w={{base: "100%", md: "32%", lg: "32%"}}
                  // minW={{sm: "100%", md: "32%", lg: "32%"}}
                  // maxW="32%"
                  cardHeader="physical exam"
                  cardHeaderIcon="/assets/imgs/physical-exam.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/physical-exam.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    </>
                  }
                  cardFooterText="Add data"
                />
              )} */}
              {/* Doctor Assesment */}
              {/* {data &&
                data.doctorsassessment &&
                data.doctorsassessment.length > 0 && (
                  <GrayCard
                    w={{base: "100%", md: "32%", lg: "32%"}}
                    // minW={{sm: "100%", md: "32%", lg: "32%"}}
                    // maxW="32%"
                    cardHeader="doctors assessment"
                    cardHeaderIcon="/assets/imgs/doctor-tool.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                          alignItems="center"
                        >
                          <Box
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            <Image
                              w="26px"
                              opacity="0.5"
                              src="/assets/imgs/doctor-tool.png"
                            />
                          </Box>
                          <Text
                            textAlign="left"
                            fontSize="12px"
                            fontWeight="500"
                          >
                            No Data available
                          </Text>
                        </Box>
                      </>
                    }
                    cardFooterText="Add data"
                  />
                )} */}

              {/* Current medication */}
              {data &&
              data.currentmedications &&
              data.currentmedications.length > 0 ? (
                <GrayCard
                  w={{base: "100%", md: "32%", lg: "32%"}}
                  // minW={{sm: "100%", md: "32%", lg: "32%"}}
                  // maxW="32%"
                  bodyStyle={{
                    p: "10px 20px",
                    overflow: "auto",
                    maxH: "120px",
                  }}
                  cardHeader="current medications"
                  cardHeaderIcon="/assets/imgs/medication.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        gap="50px"
                      >
                        <Box>
                          {data &&
                            data.currentmedications &&
                            data.currentmedications.map((step, index) => (
                              <Box mb="0.75rem" key={index}>
                              <Text fontSize="14px" fontWeight="600">
                                {step.medicinename? <strong>●  {step.medicinename}</strong> :""}
                                   
                                </Text>
                                <Text
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#384052"
                                >
                                {step.chemicalcomposition ? `${step.chemicalcomposition},  ${step.dosage}` : ""}
                                </Text>
                                </Box>
                            ))}
                        </Box>
                      </Box>
                    </>
                  }
                  hasFooter={false}
                  // cardFooterText="Update data"
                  // cardFooterClickEvent={navigateTomedications}
                />
              ) : (
                <GrayCard
                  w={{base: "100%", md: "32%", lg: "32%"}}
                  // minW={{sm: "100%", md: "32%", lg: "32%"}}
                  // maxW="32%"
                  cardHeader="Current medications"
                  cardHeaderIcon="/assets/imgs/treatment-plan.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/treatment-plan.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    </>
                  }
                  hasFooter={false}
                  // cardFooterText="Add data"
                  // cardFooterClickEvent={navigateTomedications}
                />
              )}

              {/* Social History */}
              {data && data.socialhistory && data.socialhistory.length > 0 ? (
                <>
                  <GrayCard
                    w={{base: "100%", md: "32%", lg: "32%"}}
                    // minW={{sm: "100%", md: "32%", lg: "32%"}}
                    // maxW="32%"
                    bodyStyle={{
                      p: "10px 20px",
                      overflow: "auto",
                      maxH: "120px",
                    }}
                    cardHeader="social History"
                    cardHeaderIcon="/assets/imgs/social-history-bar.png"
                    cardBody={
                      <>
                        <Box>
                        {data &&
        data.socialhistory &&
        data.socialhistory.map((step, index) => {
          const fromDate = new Date(step.fromdate);
          const toDate = new Date(step.todate);
          const diffInYears = toDate.getFullYear() - fromDate.getFullYear();
          const consumptionYears = diffInYears == 1 ? diffInYears + " year"  :
                                   diffInYears > 1 ? diffInYears + " years"  : 
                                                                              "less than a year";

          const getSocialHistoryLabel = (socialhistory) => {
            switch (socialhistory) {
              case "Smoking":
                return "Packs";
              case "Alcohol":
                return "Drinks";
              case "Smokeless":
                return "Cans";
              case "Illicit Drugs":
                return "Times";
              default:
                return "Packs";
            }
          };

          return (
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
              gap="20px"
            >
              <Box>
                <Text fontSize="16px" fontWeight="600">
                  {step.socialhistorytype ? step.socialhistorytype : "-"}
                </Text>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Text
                    fontSize="12px"
                    fontWeight="500"
                    color="#384052"
                  >
                     { <span style={{fontWeight:"bold"}}> 
                                    {step.iscurrent == 1 ? "Current - " : 
                                     step.iscurrent == 0 ? "Never" : 
                                     step.iscurrent == 2 ? "Former - " :"" }</span>}
                    {step.frequency && step.frequency !== 0 ? (
                      <>
                        {`${step.frequency} ${getSocialHistoryLabel(step.socialhistorytype)} Per ${step.frequencytype} x ${consumptionYears}`}
                        {step.iscurrent == 2 && (
                          <>
                            {` Until ${step.todate ? formatDate_yyyy(step.todate) : ""}`}
                          </>
                        )}
                      </>
                    ):(
                      ""
                    )}
                  </Text>       
                  {/* <Image
                        w="13px"
                        h="9px"
                        mx="3px"
                        src="/assets/imgs/green-chart.png"
                      />
                      <Text
                        fontSize="12px"
                        fontWeight="500"
                        color="#0C7650"
                      >
                        3
                      </Text> */}
                </Box>
              </Box>
              <Divider size="1px" colorScheme="#E6E7E9" />
            </Box>
          );
        })}
                          {/* <Box >
    <Text fontSize="16px" fontWeight="600">Alcohol</Text>
    <Box display="flex" flexDirection="row" alignItems="center">
      <Text fontSize="12px" fontWeight="500" color="#384052">Average 2 / per day</Text>
      <Image w="16px" h="14px" mx="3px" src="/assets/imgs/red-chart.png" />
      <Text fontSize="12px" fontWeight="500" color="#F04438">2</Text>
    </Box>
  </Box> */}
                        </Box>
                      </>
                    }
                    hasFooter={false}
                    // cardFooterText="Update data"
                    // cardFooterClickEvent={navigateToSocialHistory}
                  />
                </>
              ) : (
                <>
                  <GrayCard
                    w={{base: "100%", md: "32%", lg: "32%"}}
                    // minW={{sm: "100%", md: "32%", lg: "32%"}}
                    // maxW="32%"
                    cardHeader="social History"
                    cardHeaderIcon="/assets/imgs/social-history-bar.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                          alignItems="center"
                        >
                          <Box
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            <Image
                              w="26px"
                              opacity="0.5"
                              src="/assets/imgs/social-history-bar.png"
                            />
                          </Box>
                          <Text
                            textAlign="left"
                            fontSize="12px"
                            fontWeight="500"
                          >
                            No Data available
                          </Text>
                        </Box>
                      </>
                    }
                    hasFooter={false}
                    // cardFooterText="Add data"
                    // cardFooterClickEvent={navigateToSocialHistory}
                  />
                </>
              )}

              {/* Past Medical HIstory */}
              {data &&
              data.pastmedicalhistory &&
              data.pastmedicalhistory.length > 0 ? (
                <GrayCard
                  w={{base: "100%", md: "32%", lg: "32%"}}
                  // minW={{sm: "100%", md: "32%", lg: "32%"}}
                  // maxW="32%"
                  bodyStyle={{
                    p: "10px 20px",
                    overflow: "auto",
                    maxH: "170px",
                  }}
                  cardHeader="Past Medical History"
                  cardHeaderIcon="/assets/imgs/medical-history.png"
                  cardBody={
                    <>
                      <Box>
                        <VStack align="stretch">
                          {data &&
                            data.pastmedicalhistory &&
                            data.pastmedicalhistory.map((step, index) => (
                              <React.Fragment key={index}>
                                <Stack direction="row" align="center">
                                  <Circle
                                    size="12px"
                                    bg="white"
                                    border="1px solid gray"
                                    borderRadius="full"
                                    color="black"
                                  />
                                  <Box flex="1">
                                    <Text fontSize="16px" fontWeight="600">
                                      {step.condition}
                                    </Text>
                                    <Text fontSize="12px" fontWeight="600">
                                      {formatDate(step.conditionidentifieddate)}
                                    </Text>
                                    {index <
                                      data.pastmedicalhistory.length - 1 && (
                                      <Box
                                        as="div"
                                        w="2px"
                                        h="5px"
                                        borderColor="black"
                                        m="0 auto"
                                      >
                                        {/* <Image w="2px" h="auto" position="relative" left="-15px" top="-15px" src="/assets/imgs/step-line.png" /> */}
                                      </Box>
                                    )}
                                  </Box>
                                </Stack>
                              </React.Fragment>
                            ))}
                        </VStack>
                      </Box>
                    </>
                  }
                  hasFooter={false}
                  // cardFooterText="View full timeline"
                  // onClick={() => pastmedicalhistory.current.openRight()}
                />
              ) : (
                <GrayCard
                  w={{base: "100%", md: "32%", lg: "32%"}}
                  // minW={{sm: "100%", md: "32%", lg: "32%"}}
                  // maxW="32%"
                  cardHeader="past medical history"
                  cardHeaderIcon="/assets/imgs/medical-history.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/medical-history.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    </>
                  }
                  hasFooter={false}
                  // cardFooterText="Add data"
                  // onClick={() => pastmedicalhistory.current.openRight()}
                />
              )}
              {/* Past Surgical HIstory */}
              {data &&
              data.pastsurgicalhistory &&
              data.pastsurgicalhistory.length > 0 ? (
                <GrayCard
                  w={{base: "100%", md: "32%", lg: "32%"}}
                  // minW={{sm: "100%", md: "32%", lg: "32%"}}
                  // maxW="32%"
                  bodyStyle={{
                    p: "10px 20px",
                    overflow: "auto",
                    maxH: "170px",
                  }}
                  cardHeader="Past surgical history"
                  cardHeaderIcon="/assets/imgs/doctor-tool.png"
                  cardBody={
                    <>
                      <Box>
                        <VStack align="stretch">
                          {data &&
                            data.pastsurgicalhistory &&
                            data.pastsurgicalhistory.map((step, index) => (
                              <React.Fragment key={index}>
                                <Stack direction="row" align="center">
                                  <Circle
                                    size="12px"
                                    bg="white"
                                    border="1px solid gray"
                                    borderRadius="full"
                                    color="black"
                                  />
                                  <Box flex="1">
                                    <Text fontSize="16px" fontWeight="600">
                                      {step.proceduretitle}
                                    </Text>
                                    <Text fontSize="12px" fontWeight="600">
                                      {/* {step.proceduredate
                              ? step.proceduredate
                              : "-"} */}
                                      {formatDate(step.proceduredate)}
                                    </Text>
                                    {index < data.pastsurgicalhistory - 1 && (
                                      <Box
                                        as="div"
                                        w="2px"
                                        h="5px"
                                        borderColor="black"
                                        m="0 auto"
                                      >
                                        {/* <Image w="2px" h="auto" position="relative" left="-15px" top="-15px" src="/assets/imgs/step-line.png" /> */}
                                      </Box>
                                    )}
                                  </Box>
                                </Stack>
                              </React.Fragment>
                            ))}
                        </VStack>
                      </Box>
                    </>
                  }
                  hasFooter={false}
                  // cardFooterText="View full timeline"
                  // onClick={() => pastsurgicalhistory.current.openRight()}
                />
              ) : (
                <GrayCard
                  w={{base: "100%", md: "32%", lg: "32%"}}
                  // minW={{sm: "100%", md: "32%", lg: "32%"}}
                  // maxW="32%"
                  cardHeader="Past surgical history"
                  cardHeaderIcon="/assets/imgs/doctor-tool.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/doctor-tool.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    </>
                  }
                  hasFooter={false}
                  // cardFooterText="Add data"
                />
              )}

              {/* Family History */}
              {data && data.familyhistory && data.familyhistory.length > 0 ? (
                <GrayCard
                  w={{base: "100%", md: "32%", lg: "32%"}}
                  // minW={{sm: "100%", md: "32%", lg: "32%"}}
                  // maxW="32%"
                  bodyStyle={{
                    p: "10px 20px",
                    overflow: "auto",
                    maxH: "170px",
                  }}
                  cardHeader="family history"
                  cardHeaderIcon="/assets/imgs/family-history.png"
                  cardBody={
                    <>
                      <Box display="flex" flexDirection="column" gap="10px">
                        {data &&
                          data.familyhistory &&
                          data.familyhistory.map((step, index) => (
                            <Box
                              key={index}
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                              // borderBottom="1px solid black"
                            >
                              <Box>
                                <Text fontSize="16px" fontWeight="600">
                                  {step.relation ? step.relation : "-"}
                                </Text>
                                <Text
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#384052"
                                >
                                  {formatDate(step.dob)}
                                  {/* {step.updateddate ? step.updateddate : "-"} */}
                                </Text>
                  
                                <Box display="flex" alignItems="center" flexWrap="wrap">
                                  {step.medicalcondition ? (
                                    step.medicalcondition.map((condition, idx) => (
                                      <Box
                                        as="p"
                                        display="flex"
                                        alignItems="center"
                                        flexDir="row"
                                        fontSize="10px"
                                        key={idx}
                                        title={step.medicalcondition.map(cond => cond.condition).join(", ")}
                                      >
                                        <Box as="span" fontSize="8px" mr="3px">● </Box> {condition.condition}
                                        {idx < step.medicalcondition.length - 1 && ", "}
                                      </Box>
                                    ))
                                  ) : null}
                                </Box>
                              </Box>
                  
                              <Box
                                display="flex"
                                alignItems="center"
                                border="1px solid #E6E7E966"
                                bg="#E6E7E966"
                                p="4px"
                                px="6px"
                                borderRadius="full"
                              >
                                <Text
                                  as="span"
                                  fontSize="10px"
                                  ml="2px"
                                  color={
                                    step.isdeceased === 1
                                      ? "#fe3333"
                                      : "#009700"
                                  }
                                >
                                  {step.isdeceased === 1 ? "Deceased" : "Alive"}
                                </Text>
                              </Box>
                            </Box>
                          ))}
                      </Box>
                    </>
                  }
                  
                  hasFooter={false}
                  // cardFooterText="View All"
                  // cardFooterClickEvent={navigateToFamilyHistory}
                />
              ) : (
                <GrayCard
                  w={{base: "100%", md: "32%", lg: "32%"}}
                  // minW={{sm: "100%", md: "32%", lg: "32%"}}
                  // maxW="32%"
                  cardHeader="family history"
                  cardHeaderIcon="/assets/imgs/family-history.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/family-history.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    </>
                  }
                  hasFooter={false}
                  // cardFooterText="Add data"
                  // cardFooterClickEvent={navigateToFamilyHistory}
                />
              )}

              {/* Treament Team */}
              {data && data.treatmentteam && data.treatmentteam.length > 0 ? (
                <GrayCard
                  w={{base: "100%", md: "32%", lg: "32%"}}
                  // minW={{sm: "100%", md: "32%", lg: "32%"}}
                  // maxW="32%"
                  bodyStyle={{ p: "0 20px", overflow: "auto", maxH: "150px" }}
                  cardHeader="Treatment Team"
                  cardHeaderIcon="/assets/imgs/treatment-plan.png"
                  cardBody={
                    <>
                      <Box>
                        {data &&
                          data.treatmentteam &&
                          data.treatmentteam.map((step, index) => (
                            <Box
                              key={index}
                              borderBottom="1px solid #E6E7E9"
                              py="15px"
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box display="flex" alignItems="center">
                                <Image
                                  w="40px"
                                  borderRadius="full"
                                  src={step.doctorimage ? step.imagepath + step.doctorimage : "/assets/imgs/no-image-icon.png"}
                               
                                  mr="3"
                                />
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  justifyContent="center"
                                >
                                  <Text
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#384052"
                                  >
                                    {step.entitytype ? step.entitytype : ""}
                                  </Text>
                                  <Text fontSize="14px" fontWeight="600">
                                    {step.doctorname ? step.doctorname : "-"}
                                  </Text>
                                </Box>
                              </Box>
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >
                                {formatDate(step.date)}
                              </Text>
                            </Box>
                          ))}
                      </Box>
                    </>
                  }
                  hasFooter={false}
                  // cardFooterText="View all"
                  // onClick={() => treatmentplan.current.openRight()}
                />
              ) : (
                <GrayCard
                  w={{base: "100%", md: "32%", lg: "32%"}}
                  // minW={{sm: "100%", md: "32%", lg: "32%"}}
                  // maxW="32%"
                  cardHeader="Treatment Team"
                  cardHeaderIcon="/assets/imgs/treatment-plan.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/treatment-plan.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    </>
                  }
                  hasFooter={false}
                  // cardFooterText="Add data"
                />
              )}

              {/* Vitals */}
              <Box
                display="flex"
                justifyContent="space-between"
                gap="20px"
                w="100%"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  gap="20px"
                  w="100%"
                >
                  {data && data.vitals ? (
                    <GrayCard
                      bodyStyle={{ p: "0" }}
                      cardHeader="vitals"
                      cardHeaderIcon="/assets/imgs/heart.png"
                      cardBody={
                        <>
                          {/* <Box
                display="flex"
                flexDirection="row"
                gap="20px"
                py="10px"
                justifyContent="space-between"
              >
                {data &&
                  data.vitals &&
                  data.vitals.map((step, index) => (
                    <>
                      <Box
                      
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="end"
                      >
                        <Box>
                          <Box
                            as="p"
                            fontSize="12px"
                            fontWeight="500"
                            color="#6A707D"
                          >
                            {step.vitalname ? step.vitalname : "-"}
                          </Box>
                          <Box
                            as="p"
                            display="inline-block"
                            fontSize="22px"
                            fontWeight="600"
                          >
                            {step.vitalvalue ? step.vitalvalue : "-"}
                          </Box>
                          <Box
                            display="inline-block"
                            as="p"
                            fontSize="15px"
                            pl={"5px"}
                            fontWeight="400"
                          >
                            {step.vitalunit ? step.vitalunit : ""}
                          </Box>
                          <Box
                            as="p"
                            fontSize="12px"
                            fontWeight="500"
                            color="#6A707D"
                          >
                            {step.vitaladdeddate
                              ? step.vitaladdeddate
                              : "-"}
                          </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
    <Image w="12px" h="auto" src="/assets/imgs/check.png" />
    <Box as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Box>
  </Box>
                      </Box>
                      <Box borderRight="1px solid #E6E7E9"></Box>
                    </>
                  ))}
              </Box> */}
                          {/* <Wrap justify="space-between" spacing={0}>
{data && data.vitals && data.vitals.map((step, index) => (
<WrapItem  gap="0" key={index} flexGrow={1} minW={{ base: "32%", md: "calc(20% - 16px)" }} maxW="calc(20% - 16px)">
<Box borderWidth="1px" p={4} width="100%">
  <Text fontSize="12px" fontWeight="500" color="#6A707D">
    {step.vitalname || "-"}
  </Text>
  <Text display="inline-block" fontSize="22px" fontWeight="600">
    {step.vitalvalue || "-"}
  </Text>
  <Text display="inline-block" fontSize="15px" pl="5px" fontWeight="400">
    {step.vitalunit || ""}
  </Text>
  <Text fontSize="12px" fontWeight="500" color="#6A707D">
    {step.vitaladdeddate || "-"}
  </Text>

</Box>
</WrapItem>
// ))}
</Wrap> */}

                          <Box width="100%" overflowX="auto">
                            <SimpleGrid columns={{base: 2, md: 4, xl: 5}}>
                              {data &&
                                data.vitals &&
                                data.vitals.map((step, index) => (
                                  <Box key={index} borderWidth="1px" p={4}>
                                    <Text
                                      fontSize="12px"
                                      fontWeight="500"
                                      color="#6A707D"
                                    >
                                      {step.vitalname || "-"}
                                    </Text>
                                    <Text
                                      display="inline-block"
                                      fontSize="22px"
                                      fontWeight="600"
                                    >
                                      {step.vitalvalue || "-"}
                                    </Text>
                                    <Text
                                      display="inline-block"
                                      fontSize="15px"
                                      pl={"5px"}
                                      fontWeight="400"
                                    >
                                      {step.vitalunit || ""}
                                    </Text>
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      justifyContent="space-between"
                                    >
                                      <Box>
                                        <Text
                                          fontSize={{base: "10px", lg: "12px"}}
                                          fontWeight="500"
                                          color="#6A707D"
                                        >
                                          {formatDate(step.vitaladdeddate)}
                                          {/* {step.vitaladdeddate || "-"} */}
                                        </Text>
                                      </Box>
                                      {/* <Box display="flex" alignItems="center">
                          <Image w="12px" h="auto" src="/assets/imgs/check.png" />
                          <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text>
                        </Box>  */}
                                      <Box display="flex" alignItems="center">
                                        <Image
                                          w={{base: "10px", lg: "12px"}}
                                          h="auto"
                                          src={
                                            step.status &&
                                            step.status.toLowerCase() === "low"
                                              ? "/assets/imgs/low-yellow.png"
                                              : step.status === "High"
                                              ? "/assets/imgs/high-red.png"
                                              : step.status &&
                                                step.status.toLowerCase() ===
                                                  "normal"
                                              ? "/assets/imgs/check.png"
                                              : ""
                                          }
                                        />
                                        <Text
                                          as="span"
                                          fontSize={{base: "10px", lg: "12px"}}
                                          fontWeight="600"
                                          ml="2px"
                                        >
                                          {" "}
                                          {step.status &&
                                            (step.status.toLowerCase() ===
                                            "normal"
                                              ? "Ok"
                                              : step.status)}
                                        </Text>
                                      </Box>
                                    </Box>
                                  </Box>
                                ))}
                            </SimpleGrid>
                          </Box>
                        </>
                      }
                      hasFooter={false}
                      // cardFooterText="Update Data"
                      // cardFooterClickEvent={navigateToVitals}
                    />
                  ) : (
                    <GrayCard
                      bodyStyle={{ p: "0 20px" }}
                      cardHeader="vitals"
                      cardHeaderIcon="/assets/imgs/heart.png"
                      cardBody={
                        <>
                          <Box
                            display="flex"
                            flexDirection="row"
                            gap="20px"
                            py="10px"
                            justifyContent="space-between"
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="end"
                            >
                              <Box>
                                <Box
                                  as="p"
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#6A707D"
                                >
                                  Body temperature
                                </Box>
                                <Box
                                  as="p"
                                  display="inline-block"
                                  fontSize="22px"
                                  fontWeight="600"
                                >
                                  -
                                </Box>
                              </Box>
                            </Box>
                            <Box borderRight="1px solid #E6E7E9"></Box>

                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="end"
                            >
                              <Box>
                                <Box>
                                  <Box
                                    as="p"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    Body temperature
                                  </Box>
                                  <Box
                                    as="p"
                                    display="inline-block"
                                    fontSize="22px"
                                    fontWeight="600"
                                  >
                                    -
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box borderRight="1px solid #E6E7E9"></Box>

                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="end"
                            >
                              <Box>
                                <Box>
                                  <Box
                                    as="p"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    Body temperature
                                  </Box>
                                  <Box
                                    as="p"
                                    display="inline-block"
                                    fontSize="22px"
                                    fontWeight="600"
                                  >
                                    -
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box borderRight="1px solid #E6E7E9"></Box>

                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="end"
                            >
                              <Box>
                                <Box>
                                  <Box
                                    as="p"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    Body temperature
                                  </Box>
                                  <Box
                                    as="p"
                                    display="inline-block"
                                    fontSize="22px"
                                    fontWeight="600"
                                  >
                                    -
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box borderRight="1px solid #E6E7E9"></Box>

                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="end"
                            >
                              <Box>
                                <Box>
                                  <Box
                                    as="p"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    Body temperature
                                  </Box>
                                  <Box
                                    as="p"
                                    display="inline-block"
                                    fontSize="22px"
                                    fontWeight="600"
                                  >
                                    -
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box borderRight="1px solid #E6E7E9"></Box>

                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="end"
                            >
                              <Box>
                                <Box>
                                  <Box
                                    as="p"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    Body temperature
                                  </Box>
                                  <Box
                                    as="p"
                                    display="inline-block"
                                    fontSize="22px"
                                    fontWeight="600"
                                  >
                                    -
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box borderRight="1px solid #E6E7E9"></Box>
                          </Box>
                        </>
                      }
                      hasFooter={false}
                      // cardFooterText="Add Data"
                      // cardFooterClickEvent={navigateToVitals}
                    />
                  )}
                </Box>
              </Box>
              {/* Investigations */}
              <Box
                display="flex"
                justifyContent="space-between"
                gap="20px"
                w="100%"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  gap="20px"
                  w="100%"
                >
                  {data &&
                  data.investigations &&
                  data.investigations.length > 0 ? (
                    <GrayCard
                      bodyStyle={{ p: "0" }}
                      cardHeader="investigations"
                      cardHeaderIcon="/assets/imgs/investigation.png"
                      cardBody={
                        <>
                          <Box width="100%" overflowX="auto">
                            <SimpleGrid minChildWidth="220px">
                              {data &&
                                data.investigations &&
                                data.investigations.map((step, index) => (
                                  <Box key={index} borderWidth="1px" p={4}>
                                    <Text
                                      fontSize="12px"
                                      fontWeight="500"
                                      color="#6A707D"
                                    >
                                      {step.eventtitle ? step.eventtitle : "-"}
                                    </Text>
                                    <Text
                                      display="inline-block"
                                      fontSize="22px"
                                      fontWeight="600"
                                    >
                                      {step.actualresultvalue
                                        ? step.actualresultvalue
                                        : "-"}
                                    </Text>
                                    {/* <Text display="inline-block" fontSize="15px" fontWeight="400">
                      {row.resultMeasurement}
                    </Text> */}
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      justifyContent="space-between"
                                    >
                                      <Box>
                                        <Text
                                          fontSize="12px"
                                          fontWeight="500"
                                          color="#6A707D"
                                        >
                                          {formatDate(step.verifieddate)}
                                          {/* {step.verifieddate
                                          ? step.verifieddate
                                          : "-"} */}
                                        </Text>
                                      </Box>
                                      {step.status === "low" &&
                                      step.trendColor === "red" ? (
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          transform="scaleY(-1)"
                                        >
                                          <Image
                                            w="12px"
                                            h="auto"
                                            src="/assets/imgs/red-chart.png"
                                            alt="Normal"
                                          />
                                          {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                        </Box>
                                      ) : step.status === "high" &&
                                        step.trendColor === "red" ? (
                                        <Box display="flex" alignItems="center">
                                          <Image
                                            w="12px"
                                            h="auto"
                                            src="/assets/imgs/red-chart.png"
                                            alt="Normal"
                                          />
                                          {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                        </Box>
                                      ) : step.status === "low" &&
                                        step.trendColor === "green" ? (
                                        <Box display="flex" alignItems="center">
                                          <Image
                                            w="12px"
                                            h="auto"
                                            src="/assets/imgs/green-chart.png"
                                            alt="Normal"
                                          />
                                          {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                        </Box>
                                      ) : step.status === "high" &&
                                        step.trendColor === "green" ? (
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          transform="scaleY(-1)"
                                        >
                                          <Image
                                            w="12px"
                                            h="auto"
                                            src="/assets/imgs/green-chart.png"
                                            alt="Normal"
                                          />
                                          {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                        </Box>
                                      ) : null}
                                    </Box>
                                  </Box>
                                ))}
                            </SimpleGrid>
                          </Box>
                        </>
                      }
                      hasFooter={false}
                      // cardFooterText="View all reports"
                      // cardFooterClickEvent={navigatetoinvestigation}
                    />
                  ) : (
                    <GrayCard
                      bodyStyle={{ p: "0" }}
                      cardHeader="investigations"
                      cardHeaderIcon="/assets/imgs/investigation.png"
                      cardBody={
                        <>
                          <Flex
                            direction="row"
                            justify="space-around"
                            h="full"
                            w="100%"
                            flexWrap="wrap"
                          >
                            {dataEntries.map((index) => (
                              <SimpleTable NoDataHeader={index} />
                            ))}
                          </Flex>
                        </>
                      }
                      hasFooter={false}
                      // cardFooterText="Add data"
                      // cardFooterClickEvent={navigatetoinvestigation}
                    />
                  )}
                </Box>
              </Box>
              {/* Lab and Imaging */}
              <Box
                display="flex"
                justifyContent="start"
                gap="20px"
                w="100%"
                mb={isSafari ? "4rem" : "1.25rem"}
                flexWrap={{ base: "wrap", md: "nowrap" }}
              >
                {data && data.labs && data.labs.eventtitle != null ? (
                  <GrayCard
                    w={{ base: "100%", md: "32%" }}
                    // minW="32%"
                    // maxW="100%"
                    bodyStyle={{ p: "20px", overflow: "auto", maxH: "220px" }}
                    cardHeader="labs"
                    cardHeaderIcon="/assets/imgs/biotech.png"
                    cardBody={
                      <>
                        <Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-around"
                            gap="20px"
                          >
                            <Box>
                              <Box
                                mb="3px"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Text
                                  fontSize="16px"
                                  fontWeight="600"
                                  color="#061027"
                                >
                                  {data.labs.eventtitle
                                    ? data.labs.eventtitle
                                    : ""}
                                </Text>
                                <Text
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#384052"
                                >
                                  {formatDate(data.labs.verifieddate)}
                                  {/* {data.labs.verifieddate
                          ? data.labs.verifieddate
                          : ""} */}
                                </Text>
                              </Box>
                              <Text fontSize="14px" fontWeight="500">
                                {data.labs.longtext ? data.labs.longtext : ""}
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                      </>
                    }
                    // hasFooter={false}
                    cardFooterText="View all reports"
                    cardFooterClickEvent={navigatePatientLabs}
                  />
                ) : (
                  <GrayCard
                    w={{ base: "100%", md: "32%" }}
                    // minW="32%"
                    // maxW="100%"
                    cardHeader="labs"
                    cardHeaderIcon="/assets/imgs/biotech.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                          alignItems="center"
                        >
                          <Box
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            <Image
                              w="26px"
                              opacity="0.5"
                              src="/assets/imgs/biotech.png"
                            />
                          </Box>
                          <Text
                            textAlign="left"
                            fontSize="12px"
                            fontWeight="500"
                          >
                            No Data available
                          </Text>
                        </Box>
                      </>
                    }
                    hasFooter={false}
                    // cardFooterText="Add data"
                    // cardFooterClickEvent={navigateToLabs}
                  />
                )}

                {data && data.imaging && data.imaging.eventtitle != null ? (
                  <GrayCard
                    w={{ base: "100%", md: "32%" }}
                    // minw={{ base: "100%", md: "32%" }}
                    // maxW="32%"
                    bodyStyle={{ p: "20px", overflow: "auto", maxH: "220px" }}
                    cardHeader="IMAGING"
                    cardHeaderIcon="/assets/imgs/hand_bones.png"
                    cardBody={
                      <>
                        <Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-around"
                            gap="20px"
                          >
                            <Box>
                              <Box
                                mb="3px"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Text
                                  fontSize="16px"
                                  fontWeight="600"
                                  color="#061027"
                                >
                                  {data.imaging.eventtitle
                                    ? data.imaging.eventtitle
                                    : ""}
                                </Text>
                                <Text
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#384052"
                                >
                                  {formatDate(data.imaging.verifieddate)}
                                  {/* {data.verifieddate ? data.verifieddate : ""} */}
                                </Text>
                              </Box>
                              <Text fontSize="14px" fontWeight="500">
                                {data.imaging.longtext
                                  ? data.imaging.longtext
                                  : ""}
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                      </>
                    }
                    // hasFooter={false}
                    cardFooterText="View all reports"
                    cardFooterClickEvent={navigatePatientImaging}
                  />
                ) : (
                  <GrayCard
                    w={{ base: "100%", md: "32%" }}
                    // minW={{sm: "100%", md: "32%", lg: "32%"}}
                    // maxW="32%"
                    cardHeader="IMAGING"
                    cardHeaderIcon="/assets/imgs/hand_bones.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                          alignItems="center"
                        >
                          <Box
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            <Image
                              w="26px"
                              opacity="0.5"
                              src="/assets/imgs/hand_bones.png"
                            />
                          </Box>
                          <Text
                            textAlign="left"
                            fontSize="12px"
                            fontWeight="500"
                          >
                            No Data available
                          </Text>
                        </Box>
                      </>
                    }
                    hasFooter={false}
                    // cardFooterText="Add data"
                    // cardFooterClickEvent={navigatePatientImaging}
                  />
                )}
              </Box>

              {/* <Box display="flex" width="100%" alignItems="center" pr="25px">
      <input
        type="file"
        id="prescriptionupload"
        style={{ display: 'none' }}
      //  onChange={uploadprescription}
      />
      <PrimaryInput
       inputType="file"
       uploadText="Upload prescription"
       id="prescriptionupload"
       docBtnWidth="100%"
       docBtnColor="#1C4EC5"
       //iconImg="/assets/imgs/file_open-blue.png"
      // onChange={uploadprescription}
       variant="grayBtnFull"
     />
    </Box> */}
            </Flex>
          </Box>
          {/* <input
type='file'
id='file-upload'
//   onChange={handleimageselection}
ref={fileInputRef} // Set the ref for the file input
style={{ display: 'none' }} // Hide the file input
/> */}
        </Flex>
      ) : (
        <Box
          display="flex"
          flexDir="column"
          gap="30px"
          justifyContent="center"
          alignItems="center"
          h="100vh"
          overflow="hidden"
        >
          <Image w="200px" h="200px" src="/assets/imgs/failed-icon-red.png" />
          <Text as="h1" fontSize="28px" fontWeight="600">
            The Link has Expired
          </Text>
        </Box>
      )}
      <ModalRight
        ref={historyofillness}
        header="History of present illness"
        body={
          <>
            <Box as="p" pt="15px" fontSize="14px" fontWeight="500">
              Patient is an 83-year-old male with a history of Guillain-Barré,
              coronary disease, on metoprolol, history of cardiac bypass, A-fib
              presenting with feeling of unwell today with a low heart rate in
              the 40s at home as well as testicular swelling right greater than
              left for the last week. It is highly important that this patient
              seeks immediate care and gets admitted.
            </Box>
          </>
        }
      />

      <ModalRight
        ref={treatmentplan}
        modalSize="sm"
        header="Treatment Team"
        body={
          <>
            <Box>
              {data &&
                data.treatmentteam &&
                data.treatmentteam.map((step, index) => (
                  <DoctorCard
                    key={index}
                    DocCardStyle={{ my: "10px" }}
                    cardBody={
                      <>
                        <Box
                          py="10px"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box display="flex" alignItems="center">
                            <Image
                              w="40px"
                              borderRadius="full"
                              src="/assets/imgs/avatar.png"
                              mr="3"
                            />
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                            >
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >
                                {step.entitytype ? step.entitytype : ""}
                              </Text>
                              <Text fontSize="14px" fontWeight="600">
                                {step.doctorname ? step.doctorname : "-"}
                              </Text>
                            </Box>
                          </Box>
                          <Text
                            fontSize="12px"
                            fontWeight="500"
                            color="#384052"
                          >
                            {step.date ? step.date : ""}
                          </Text>
                        </Box>
                      </>
                    }
                    cardFooterText="View appointment"
                  />
                ))}
              ;
            </Box>
          </>
        }
      />

      <ModalRight
        ref={pastmedicalhistory}
        header="Past Medical History"
        body={
          <>
            <Box>
              <VStack align="stretch">
                {data &&
                  data.pastmedicalhistory &&
                  data.pastmedicalhistory.map((step, index) => (
                    <React.Fragment key={index}>
                      <Stack direction="column" align="flex-start" gap="0">
                        <Box display="flex" alignItems="center" gap="5px">
                          <Circle
                            size="12px"
                            bg="white"
                            border="2px solid gray"
                            borderRadius="full"
                            color="black"
                          />
                          <Text fontSize="16px" fontWeight="600">
                            {step.condition}
                          </Text>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="flex-start"
                          h="auto"
                          gap="5px"
                        >
                          <Center
                            h="35px"
                            ml="5px"
                            borderLeft={
                              index < data.pastmedicalhistory.length - 1
                                ? "1px dotted #CDCFD4"
                                : "none"
                            }
                          ></Center>
                          <Text fontSize="12px" ml="7px" fontWeight="600">
                            {formatDate(step.conditionidentifieddate)}
                          </Text>
                        </Box>
                        {index < data.pastmedicalhistory.length - 1 && (
                          <Box
                            as="div"
                            w="2px"
                            h="5px"
                            borderColor="black"
                            m="0 auto"
                          >
                            {/* <Image w="2px" h="auto" position="relative" left="-15px" top="-15px" src="/assets/imgs/step-line.png" /> */}
                          </Box>
                        )}
                      </Stack>
                    </React.Fragment>
                  ))}
              </VStack>
            </Box>
          </>
        }
      />
      <ModalRight
        ref={pastsurgicalhistory}
        header="Past Surgical History"
        body={
          <>
            <Box>
              <VStack align="stretch">
                {data &&
                  data.pastsurgicalhistory &&
                  data.pastsurgicalhistory.map((step, index) => (
                    <React.Fragment key={index}>
                      <Stack direction="column" align="flex-start" gap="0">
                        <Box display="flex" alignItems="center" gap="5px">
                          <Circle
                            size="12px"
                            bg="white"
                            border="2px solid gray"
                            borderRadius="full"
                            color="black"
                          />
                          <Text fontSize="16px" fontWeight="600">
                            {step.proceduretitle}
                          </Text>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="flex-start"
                          h="auto"
                          gap="5px"
                        >
                          <Center
                            h="35px"
                            ml="5px"
                            borderLeft={
                              index < data.pastsurgicalhistory.length - 1
                                ? "1px dotted #CDCFD4"
                                : "none"
                            }
                          ></Center>
                          <Text fontSize="12px" ml="7px" fontWeight="600">
                            {formatDate(step.proceduredate)}
                          </Text>
                        </Box>
                        {index < data.pastsurgicalhistory.length - 1 && (
                          <Box
                            as="div"
                            w="2px"
                            h="5px"
                            borderColor="black"
                            m="0 auto"
                          >
                            {/* <Image w="2px" h="auto" position="relative" left="-15px" top="-15px" src="/assets/imgs/step-line.png" /> */}
                          </Box>
                        )}
                      </Stack>
                    </React.Fragment>
                  ))}
              </VStack>
            </Box>
          </>
        }
      />
      <Footer />
    </Box>
  );
};

export default PatientShareRecord;
