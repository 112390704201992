import React from 'react';
import { Input, InputGroup, InputRightElement, Button, FormControl, FormLabel, Image } from '@chakra-ui/react';
import * as Styles from './styles';
import { inputDesign, activeLabelStyles, variantStyles } from "../styles/theme";

export default function PromoCode({
  promoCode,
  onChange,
  onApplyClick,
  placeholder = "Referal code",
  applyButtonText = "Apply",
  variant,
  isDisabled,
  ...props
}) {
  const variantStyle = variantStyles[variant] || variantStyles.defaultSide;

  return (
    <FormControl sx={inputDesign.formStyles.container}>
      <InputGroup size="md" zIndex={"22"} sx={inputDesign.formStyles.inputGroup}>
        <Input
          sx={{ ...Styles.styles, ...variantStyle, ...inputDesign.formStyles.input }}
          type="text"
          placeholder=" "
          value={promoCode}
          onChange={onChange}
          pr="65px"
          {...props}
          errorBorderColor="red.300"
        />
        <FormLabel sx={inputDesign.formStyles.label}>{placeholder}</FormLabel>
        <InputRightElement width="4.5rem" zIndex={"99"}>
          <Button sx={Styles.btnStyle} size="sm" onClick={onApplyClick} isDisabled={isDisabled}>
            {applyButtonText}
          </Button>
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
}
