import moment from "moment";
export default function formAPIMedications({
    morning,
    afternoon,
    evening,
    night,
    beforefood,
    afterfood,
    dosage,
    dosageunit,
    frequency,
    prescribedby,
    prescribedhospitalname,
    medicinename,
    medicinetype,
    medicationstartdate,
    medicationenddate,
    patientid,
   id,
   documents,
   chemicalcomposition,
   medicineexpirydate,
   iscurrentmedicine,
   isalert,


})
{ 
  return Object.freeze({
    morning,
    afternoon,
    evening,
    night,
    beforefood,
    afterfood,
    dosage,
    dosageunit,
    frequency,
    prescribedby,
    prescribedhospitalname,
    medicinename,
    medicinetype,
    medicationstartdate: medicationstartdate,
    medicationenddate: medicationenddate,
    patientid,
   id,
   documents,
   chemicalcomposition,
   medicineexpirydate,
   iscurrentmedicine,
   isalert,
   
  });
}