import formapichat from "./chat";


var formattedOutput = null;

const formapimodalchat = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formapichat(item));


  return formattedOutput
};


export default formapimodalchat