import moment from "moment";
export default function formapiSpecialities({
    url,
    imagepath,
    specialityname,
    id,
  
})
{ 
  return Object.freeze({
    url,
    imagepath,
    specialityname,
    id,
  });
}