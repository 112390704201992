
import  FetchData, { } from '../../client';
import {getLoginInfo, getUserID}  from '../../../auth';
import formAPIModelSubscription from '../../domains/plans/plans';


const FetchSubscription = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    // to change API Name here
    const result = await FetchData("midoc_patientgetplan", inputData);
    //console.log(result , " db response plans ")
     if (result.output.data) {
     var finalOutput = formAPIModelSubscription(result.output.data)
     
     return finalOutput;
     }
    //return result;

  } catch (error) {
    console.error('Error Account fetching :', error);
    throw error;
  }
};
const FetchPlans = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    // to change API Name here
    const result = await FetchData("midoc_getplan", inputData);
    //console.log(result , " db response plans ")
    if (result.output.data) {
      var finalOutput = formAPIModelSubscription(result.output.data)
      
      return finalOutput;
      }
    return result;

  } catch (error) {
    console.error('Error Account fetching :', error);
    throw error;
  }
};
const UpdatePatientPlan = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    // to change API Name here
    const result = await FetchData("midoc_updatepatientplan", inputData);
    //console.log(result , " update db ")
   
    return result;

  } catch (error) {
    console.error('Error Account fetching :', error);
    throw error;
  }
};






export {FetchSubscription, FetchPlans, UpdatePatientPlan};
