import formAPIsocialhistory from "./socialhistory";


var formattedOutput = null;

const formAPIModelsocialhistory = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIsocialhistory(item));


  return formattedOutput
};

export default formAPIModelsocialhistory