import React, { useEffect, useState, useLayoutEffect } from "react";
import { Box,Flex, Center, Image, Text,Spacer } from "@chakra-ui/react";
import { COLORS } from "../../components/styles/colors";
import { PrimaryButton, Toaster,PrimaryButtonIcon } from "../../components";
import { AiAbnormalCall}  from "../../middleware/services/myhealth/uploaddocument";

import AiModal from "../../components/ai-modal";
import { useNavigate } from "react-router-dom";
import useLoader  from "../../components/loader/loaderstates";
import Loader  from "../../components/loader/loader";
import {
  FetchAIAnswer,
} from "../../middleware/services/myhealth/vitals";
import { getDob, getusername, getgender, displayMillion_ThoudandValues } from "../../auth";
import TypingLoader from "../../components/typing-loader";
import { FetchTokens } from "../../middleware/services/profile/rewards";

function SuccessUploadIndex({ data, testname }) {
    var navigate = useNavigate();
     //Ai Response state
  const [aiquestion, setAIAnalysis] = useState(null);
  //Ai Answer state
  const [aianswers, setAIAnswers] = useState(null);
  const [remainingquestions, setRemainingQuestions] = useState('');
  const {isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();
  const AiQuestions = React.useRef();
  const AIChatModel = React.useRef();
  const [headername, setHeaderName] = useState("");
  const [headericon, setHeaderIcon] = useState("");
  const [vitalname, setVitalName] = useState("");
  const [vitalunit, setVitalUnit] = useState("");
  const [vitalvalue, setVitalValue] = useState("");
  const chatContainerRef = React.useRef(null);
  const [remainingTokens, setRemainingTokens] = useState();

  useEffect(() => {
    getTokens();
  }, [])
  var tokeninput = {}
    var getTokens = async () => {
      try {
        var result = await FetchTokens(tokeninput);
        if (result) {
          setRemainingTokens(result)
        } else {
          showErrorToast("Something went wrong!")
        }
      } catch (error) {
        console.log(error, "error in fetching account data")
      }
      //dismissLoader();
    }
  useEffect(() => {  
    if(data){
      if(data.length > 0){
        handleAbnormalCall();
      }
    }
   
    
  }, []);
  let patientgender = getgender();
  let gender = patientgender;
  if (gender === 1) {
    gender = "Female";
  } else if (gender === 2) {
    gender = "Male";
  } else if(gender === 3) {
    gender = "Others";
  }
    // Get Patient details for AI Calls

const patientname = getusername();
const dob = getDob();

    const age = calculateAge(dob);
    function calculateAge(dateOfBirth) {
      const today = new Date();
      const dob = new Date(dateOfBirth);
      let age = today.getFullYear() - dob.getFullYear();
      const monthDiff = today.getMonth() - dob.getMonth();
  
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
        age--;
      }
      return age;
    }
    
  
  const modifiedData = data && (data.map(item => {
    const { suggestions, resultvalue, test_index,test_description, ...rest } = item;
    return { ...rest, test_value: resultvalue };
  }));
  
  let input = {
    age: age,
    name: getusername(),
    gender: gender,
    data:{
      test_data: modifiedData
    }
  };
  // console.log(input, "Abnorma Input");

  const handleAbnormalCall = async () => {
    showLoader();
     const response = await AiAbnormalCall(input);
     dismissLoader();       
          console.log(response, "AI Response");
          if (response) {            
            setAIAnalysis(response);
            AiQuestions.current.openRight();
            
          }
  };
  const GetAIAnswer = async (prompt) => {
    AIChatModel.current.openRight();
    let AichatInput = {
      type:"",
      value: "",
      unit: "",
      gender: gender,
      age: age,
      name: patientname,
      prompt: prompt,
    };

    // Display question immediately
    const newQuestion = { question: prompt, answer: "" };
    setAIAnswers(prevState => {
      if (prevState === null) {
        return [newQuestion];
      } else {
        return [...prevState, newQuestion];
      }
    });
    //showLoader();
    const response = await FetchAIAnswer(AichatInput);
    console.log("AI Reponse", response)
    //dismissLoader(); 
    if (response) {
      // Replace "typing" with actual answer
      setAIAnswers(prevState => {
        const updatedAnswers = prevState.map(item => {
          if (item.question === prompt && item.answer === "") {
            return { question: item.question, answer: response.answer };
          }
          return item;
        });
        getTokens();
        return updatedAnswers;
      });
      const indexesToRemove = [];
      aiquestion.generated_prompts.forEach((question, index) => {
        if (question.value === prompt) {
          indexesToRemove.push(index);
        }
      });

      // Remove the objects at the indexes collected above
      indexesToRemove.reverse().forEach((index) => {
        aiquestion.generated_prompts.splice(index, 1);
      });
      setRemainingQuestions(aiquestion.generated_prompts);
      
      window.scrollTo(0, document.body.scrollHeight);   
     }
     AIChatModel.current.openRight();
  };
  const handlenavigatetohome =  () => {
    navigate("/myhealth")
    window.scrollTo(0, 0);
  };
  const handleSkip = () => {
    AiQuestions.current.closeRight();
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };


  // Use `useLayoutEffect` to ensure the scroll happens after DOM updates
  useLayoutEffect(() => {
    scrollToBottom();
  }, [aianswers]);
  const closeChatModel = ()=>{   
    AIChatModel.current.closeRight();
    AiQuestions.current.closeRight();            
  }

  const footerHeight = data && remainingquestions && remainingquestions.length > 0 ? "30%" : "auto";

  return (
    <Box
      w="100vw"
      h="100vh"
      bg={`url('/assets/imgs/success-bg.png'), ${COLORS.SIGNUP_SUCCESS}`}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        bgImage="url('/assets/imgs/login-bg.png')"
        boxShadow={COLORS.BOX_SHADOW_SUCCESS}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
      ></Box>
      <Center
        h="100vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box>
          <Image src="/assets/imgs/success-alert.png" />
        </Box>
        <Box>
          <Box as="h1" fontSize="28px" textAlign="center" mt="20px">
            Data Recorded!
          </Box>
          <Box mb="16px" textAlign="center" fontWeight="400">
            <Text>
              Your data from the document has been successfully recorded!
            </Text>
          </Box>
        </Box>
        <Box w={{base: "75%", lg: "100%"}}>
         <PrimaryButton buttonText="Continue" onClick={handlenavigatetohome} />
        </Box>
      </Center>
      {isLoading && <Loader/>}
       {/* AI Question model */}
       <AiModal
          bodyStyle={{p: "0"}}
          ref={AiQuestions}
          backDropBlur="10px"
          modalSize="420px"
          header={testname ? testname : "-"}
          body={
            <>
              <Flex flexDirection="column" height="100%">
                <Box
                  p="24px 32px"
                  pt="0"
                  display="flex"
                  h="100%"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Box w="100%" py="20px">
                    {data && aiquestion &&
                      aiquestion.generated_prompts
                      &&
                      aiquestion.generated_prompts
                      .map((step, index) => (
                        <Box
                        key={index}
                          as="button"
                          w="100%"
                          p="16px"
                          mb="16px"
                          fontSize="14px"
                          display="flex"
                          textAlign="left"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          gap="20px"
                          lineHeight="normal"
                          borderRadius="10px"
                          border="1px solid #E6E7E9"
                          onClick={() => GetAIAnswer(step.value)}
                        >
                          {step.value ? step.value : "-"}
                          <Image src="/assets/imgs/right-icon.png" />
                        </Box>
                      ))}
                  </Box>
                  <PrimaryButtonIcon
                    buttonText="Skip Now"
                    onClick={handleSkip}
                  />
                </Box>
                <Spacer />
              </Flex>
            {/* AI Chat Model */}
        <AiModal
          ref={AIChatModel}
          modalSize="420px"
          //icon="/assets/imgs/ai-doctor.png"
          header={<>
            <Box display="flex" flexDir='column' gap='5px'>
            <Box display='flex' gap='6px'>
              <Text fontSize="1rem"
                fontWeight="700" 
                backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)" 
                color="transparent" 
                backgroundClip="text"
              >Aira - AI</Text>
              <Box display='flex' alignItems='center' gap='5px' border='1px solid #0d7e54' bg='#d5ece3'  p='3px 5px' borderRadius='full'>
                <Image src='/assets/imgs/spark.png' /> 
                <Text fontSize='10px' fontWeight='500' color='#000'>{remainingTokens && remainingTokens.remainingtokens ? displayMillion_ThoudandValues(remainingTokens.remainingtokens):"0"} tokens available</Text>
              </Box>
            </Box>
            <Text fontSize="0.75rem" fontWeight="400">Chat with your AI health guide</Text>
          </Box>
          </>}
          headerStyle={{ p: "24px 32px", lineHeight: "normal" }}
          footerStyle={{ maxH: "35%", overflow: "hidden auto", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
          backDropBlur="10px"
          closeBtnStyle={{ top: "18px" }}
          withoutBg={true}
          handlechatclose={closeChatModel}
          body={
            <>
              <Flex flexDirection="column" height="100%">
                <Box p="24px 32px" className="chat-container">
                  {data && aianswers &&
                    aianswers.map((step, index) => (
                      <>
                        <Box
                        key={index}
                          as="div"
                          bg="#061027"
                          p="12px 16px"
                          w="fit-content"
                          float="right"
                          borderRadius="10px 10px 1px 10px"
                        >
                          <Text fontSize="14px" fontWeight="500" color="white">
                            {step.question ? step.question : "-"}
                          </Text>
                        </Box>

                        <Box
                          as="div"
                          my="20px"
                          w="fit-content"
                          display="flex"
                          alignItems="end"
                          float="left"
                        >
                          <Image
                            bg="#061027"
                            p="4px"
                            mr="8px"
                            borderRadius="6px"
                            src="/assets/imgs/ai-chat-logo.png"
                          />
                          <Text
                            bg="#E6E7E980"
                            p="12px 16px"
                            fontSize="14px"
                            fontWeight="500"
                            color="#061027"
                            borderRadius="10px 10px 10px 1px"
                          >
                            {step.answer ? step.answer : <TypingLoader />}
                          </Text>
                        </Box>
                      </>
                    ))}
                </Box>
              </Flex>
            </>
          }
          footerActions={
            <>
              <Box
                p="24px 32px"
                display="flex"
                w="100%"
                h="100%"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box w="100%" mt="auto">
                  {data && remainingquestions &&
                    remainingquestions.map((step, index) => (
                      <Box
                      key={index}
                        as="button"
                        w="100%"
                        p="16px"
                        mb="16px"
                        fontSize="14px"
                        fontWeight="600"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        borderRadius="10px"
                        border="1px solid #7795DC"
                        bg="#EAEDF5"
                        color="#163E9E"
                        gap='1rem'
                        textAlign='left'
                        onClick={() => GetAIAnswer(step.value)}
                      >
                        {step.value ? step.value : "-"}
                        <Image src="/assets/imgs/right-icon.png" />
                      </Box>
                    ))}
                  <Box
                    as="button"
                    w="100%"
                    p="16px"
                    mb="16px"
                    fontSize="14px"
                    fontWeight="600"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    borderRadius="10px"
                    border="1px solid #7795DC"
                    bg="#EAEDF5"
                    color="#163E9E"
                    onClick={handleSkip}
                    
                  >
                            Skip Now
                    <Image src="/assets/imgs/right-icon.png" />
                  </Box>
                </Box>
              </Box>
            </>
          }
        />
            </>
          }
        />
        
    </Box>
    
  );
}

export default SuccessUploadIndex;
