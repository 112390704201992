import  FetchData, { } from '../../client';
import formAPIModelSocialHistory from '../../domains/socialhistorymyhealth/socialhistories';
import {getLoginInfo, getUserID}  from '../../../auth';

const FetchSocialHistoryData = async (inputData) => {
  try {
    
    inputData.loginid = getLoginInfo();
      inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_getpatientsocialhistory", inputData);
    console.log(result,"Social History db response")
    if (result.output.data) {
    var finalOutput = formAPIModelSocialHistory(result.output.data)
    //console.log(finalOutput)
    }
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const GetSocialHistoryTypes = async (inputData) => {
  try {
    
    const result = await FetchData("midoc_getdefaultdata", inputData);
    //console.log(result,"db res type social")
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const AddSocialHistory = async (inputData) => {
  try {
    
    inputData.loginid = getLoginInfo();
      inputData.activeuserid = getUserID();
        //console.log(inputData)
    const result = await FetchData("midoc_addpatientsocialhistory", inputData);
    //console.log(result,"db res add social")
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const UpdateSocialHistory = async (inputData) => {
  try {
    
    inputData.loginid = getLoginInfo();
      inputData.activeuserid = getUserID();
        //console.log(inputData)
    const result = await FetchData("midoc_updatepatientsocialhistory", inputData);
    //console.log(result,"db res edit social")
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const DeleteSocialHistory = async (inputData) => {
  try {
    
    inputData.loginid = getLoginInfo();
      inputData.activeuserid = getUserID();
        //console.log(inputData)
    const result = await FetchData("midoc_deletepatientsocialhistory", inputData);
    //console.log(result,"db res add social")
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
export {FetchSocialHistoryData, AddSocialHistory,GetSocialHistoryTypes,DeleteSocialHistory,UpdateSocialHistory};
