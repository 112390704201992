import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import ProfileSideBar from "../../pages/profile/profileSidebar";
import SecondaryNav from "../../components/seconday-nav";
import { Box, Text, Flex, Image, SimpleGrid, Divider, UnorderedList, ListItem, Spacer, Button } from "@chakra-ui/react";
import Footer from "../../components/footer";
import Loader from "../../components/loader/loader";
import useLoader from "../../components/loader/loaderstates";
import DoctorCard from "../../components/doctor-card";
import { Global, css } from "@emotion/react";
import { getOrdersList } from "../../middleware/services/profile/orders";
import { PrimaryButton, Toaster } from "../../components";
import { displayMillion_ThoudandValues, downloadDocument, formatDate_Do_MMM_YYYY } from "../../auth";
import { useNavigate } from "react-router-dom";
import ModalRight from "../../components/modal-right";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import moment from "moment";

const OrdersIndex = () => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [activeTab, setActiveTab] = useState("prescriptions");

  const [data, setData] = useState('');
  const [showNoData, setShowNoData] = useState(false);
  const [identifiedData, setIdentifiedData] = useState("");
  
  const { showSuccessToast, showErrorToast } = Toaster();
  
  const documentsModal = React.useRef();
  const [documentURLs, setDocumentURLs] = useState([]);
  const [header, setHeader] = useState("");

  let navigate = useNavigate();

  useEffect(()=>{
    
    fetchData();
  },[])

  let input = {
    "type":"all"
  }

  const fetchData = async () => {
    showLoader();
    try {
      var result = await getOrdersList(input)
        if(result && result.output && result.output.result == "failure"){
          
          setData(null)
        }
        // else if (result == null) {
        //   setData(null)
        //} 
        else if (result != null) {
          setData(result.output);
        }
        else {
          showErrorToast("Something went wrong!")
        }
    } catch (error) {
      console.log(error, "error in get orders in index")
    }
    dismissLoader();
  };
  useEffect(()=>{
    if(activeTab == "prescriptions" && !data.medication){
      setShowNoData(true)
    }else if(activeTab == "labs" && !data.labs){
      setShowNoData(true)
    }else if(activeTab == "imaging" && !data.imaging){
      setShowNoData(true)
    }else if(activeTab == "procedures" && !data.procedures){
      setShowNoData(true)
    }else if(activeTab == "consultations" && !data.consultations){
      setShowNoData(true)
    }else if(activeTab == "followup" && !data.followup){
      setShowNoData(true)
    }else{
      setShowNoData(false)
    }
  },[activeTab, data])

  const openDocumentsModal = (item) => {
    //setAllergytype(allergytype)
    setIdentifiedData(item.prescribeddate)
    setHeader(item.medicinename)
    setDocumentURLs(item.documents)
    documentsModal.current.openRight();
  }
  return (
    <>
    <Global
        styles={css`
          .activeTab .whiteBorder::after {
            content: '';
            position: absolute;
            left: 0%;
            transform: translateX(-20%);
            transform: translateY(440%);
            border-radius: 5px 5px 0 0;
            top: 120%;
            background: #0B1F4F;
            height: 3px;
            width: 100%;
            z-index: 1;
          }
        `}
      />
      <Box w="100%" display={{base: "none", lg: "block"}}>
        <Box top="0" zIndex="banner">
          <Navbar />

          {isLoading && <Loader />}
          <SecondaryNav
            sideBarWidth="250px"
            defaultHeader="Profile"
            defaultSecondayHeader="Orders"
          />
        </Box>
        <Box h="calc(100vh - 140px)" overflow="hidden auto">
          <Flex>
            <Box p="0" width="250px">
              <ProfileSideBar />
            </Box>

            <Flex bg="#fcfcfd" flexDirection="column" w="100%">
              {/* Tab buttons start */}
              <Box display='flex' gap="1rem" borderBottom="1px solid #E6E7E9" bg="white" p="1.25rem 1.5rem">
                <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "prescriptions" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" onClick={() => setActiveTab('prescriptions')} color={activeTab == "prescriptions" ? "#061027" : "#898D97"}><Text>Prescriptions</Text><Box as="span" className="whiteBorder"/></Box>
                <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "labs" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" onClick={() => setActiveTab('labs')} color={activeTab == "labs" ? "#061027" : "#898D97"}><Text>Labs</Text><Box as="span" className="whiteBorder"/></Box>
                <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "imaging" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" onClick={() => setActiveTab('imaging')} color={activeTab == "imaging" ? "#061027" : "#898D97"}><Text>Imaging</Text><Box as="span" className="whiteBorder"/></Box>
                <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "procedures" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" onClick={() => setActiveTab('procedures')} color={activeTab == "procedures" ? "#061027" : "#898D97"}><Text>Procedures</Text><Box as="span" className="whiteBorder"/></Box>
                <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "consultations" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" onClick={() => setActiveTab('consultations')} color={activeTab == "consultations" ? "#061027" : "#898D97"}><Text>Consultations</Text><Box as="span" className="whiteBorder"/></Box>
                <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "followup" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" onClick={() => setActiveTab('followup')} color={activeTab == "followup" ? "#061027" : "#898D97"}><Text>Followups</Text><Box as="span" className="whiteBorder"/></Box>
              </Box>
              {/* Tabs button end */}
              <Box w="100%" p="1.5rem 2rem">
                <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} spacing="1rem">
                  {activeTab == "prescriptions" && (
                    data && data.medication ?(  
                      <>
                      { data.medication.map((item , index)=>
                       <DoctorCard
                       key={index}
                       hasFooter={false}
                       cardBody={
                         <>
                           <Box
                             display="flex"
                             justifyContent="space-between"
                             w="100%"
                             alignItems="flex-start"
                           >
                             <Box display="flex" gap="0.75rem">
                               <Box>
                                 <Text
                                 className="textOverflow" w="167px"
                                   fontSize="0.625rem"
                                   fontWeight="500"
                                   color="#384052"
                                   title={item.specialization}
                                 >
                                   {item.specialization}
                                 </Text>
                                 <Text
                                   fontSize="0.875rem"
                                   fontWeight="600"
                                   color="#061027"
                                   title={item.doctorname}
                                 >
                                   {item.doctorname}
                                 </Text>
                               </Box>
                             </Box>
                             <Text
                               fontSize="0.75rem"
                               fontWeight="500"
                               color="#384052"
                             >
                               {formatDate_Do_MMM_YYYY(item.prescribeddate)}
                             </Text>
                           </Box>
 
                           <Divider my="0.75rem" />
                           <Flex gap="8px" align="center">
                            <Text fontSize="12px" fontWeight="500">
                              {item.bookingid ? "#"+item.bookingid : "-"}
                            </Text>
                          </Flex>
 
                           <Box w="100%">
                             <Box
                               as="button"
                               onClick={()=>downloadDocument(item.imagepath + item.documents, "Order")}

                               w="100%"
                               border="1px solid #E6E7E9"
                               p="10px"
                               borderRadius="10px"
                               mt="15px"
                               height="50px"
                               display="flex"
                               alignItems="center"
                               justifyContent="center">
                               <Box
                                 display="flex"
                                 fontSize="12px"
                                 flexDirection="column"
                                 justifyContent="center"
                                 fontWeight="500"
                               >
                                Download
                               
                               </Box>
                               {/* <Box borderRadius="full" p="10px">
                                 <Image w="8px" src="/assets/imgs/right-icon.png" />
                               </Box> */}
                             </Box>
                           </Box>
                         </>
                       }
                     />
                      )}
                      </>
                     
                    ):(
                      null
                    )
                      
                    
                  )}
                  {activeTab == "labs" && (
                    data && data.labs ? (
                      data.labs.map((item , index)=>
                        <DoctorCard
                      hasFooter={false}
                      cardBody={
                        <>
                          <Box
                          key={index}
                            display="flex"
                            justifyContent="space-between"
                            w="100%"
                            alignItems="flex-start"
                          >
                            <Box display="flex" gap="0.75rem">
                              <Box>
                                <Text
                                  fontSize="0.875rem"
                                  fontWeight="600"
                                  color="#061027"
                                >
                                  {item.ordername}
                                </Text>
                                <Text
                                  fontSize="0.625rem"
                                  fontWeight="500"
                                  color="#384052"
                                >
                                 {formatDate_Do_MMM_YYYY(item.orderdate)}
                                </Text>
                              </Box>
                            </Box>
                          </Box>

                          <Divider my="0.75rem" />

                          <Box display='flex' flexDir="column">
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="12px" h="13px" src="/assets/svgs/stethoscope.svg" />
                                  <Text fontSize="0.75rem" fontWeight="500">{item.doctorname}</Text>
                                </Box>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="13px" h="10px" src="/assets/svgs/hospital.svg" />
                                  <Text fontSize="0.75rem" fontWeight="500">{item.hospital}</Text>
                                </Box>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="13px" h="10px" src="/assets/svgs/location-blue.svg" /> 
                                  <Text fontSize="0.75rem" fontWeight="500">{(item.address === null || item.address === '') && (item.city === null || item.city === '') && (item.country === null || item.country === '') ? '-' : `${item.address || ''}${item.address && item.city ? ', ' : ''}${item.city || ''}${(item.city && item.country) || (item.address && item.country) ? ', ' : ''}${item.country || ''}`}</Text>


                                </Box>
                            </Box>

                          <Box w="100%">
                            {/* <Box
                              as="button"
                              // onClick={onClick}
                              w="100%"
                              border="1px solid #E6E7E9"
                              p="10px"
                              borderRadius="10px"
                              mt="15px"
                              height="50px"
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between">
                              <Box
                                display="flex"
                                fontSize="12px"
                                flexDirection="column"
                                justifyContent="center"
                                fontWeight="500"
                              >
                              2 documents
                              </Box>
                              <Box borderRadius="full" p="10px">
                                <Image w="8px" src="/assets/imgs/right-icon.png" />
                              </Box>
                            </Box> */}
                          </Box>
                        </>
                      }
                    />
                      )
                    ):(
                      null
                    )
                   
                  )}

                  {activeTab == "imaging" && (
                    data && data.imaging ? (
                      data.imaging.map((item , index)=>
                        <DoctorCard
                      hasFooter={false}
                      cardBody={
                        <>
                          <Box
                          key={index}
                            display="flex"
                            justifyContent="space-between"
                            w="100%"
                            alignItems="flex-start"
                          >
                            <Box display="flex" gap="0.75rem">
                              <Box>
                                <Text
                                  fontSize="0.875rem"
                                  fontWeight="600"
                                  color="#061027"
                                >
                                  {item.ordername}
                                </Text>
                                <Text
                                  fontSize="0.625rem"
                                  fontWeight="500"
                                  color="#384052"
                                >
                                 {formatDate_Do_MMM_YYYY(item.orderdate)}
                                </Text>
                              </Box>
                            </Box>
                          </Box>

                          <Divider my="0.75rem" />

                          <Box display='flex' flexDir="column">
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="12px" h="13px" src="/assets/svgs/stethoscope.svg" />
                                  <Text fontSize="0.75rem" fontWeight="500">{item.doctorname}</Text>
                                </Box>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="13px" h="10px" src="/assets/svgs/hospital.svg" />
                                  <Text fontSize="0.75rem" fontWeight="500">{item.hospital}</Text>
                                </Box>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="13px" h="10px" src="/assets/svgs/location-blue.svg" /> 
                                  <Text fontSize="0.75rem" fontWeight="500">{(item.address === null || item.address === '') && (item.city === null || item.city === '') && (item.country === null || item.country === '') ? '-' : `${item.address || ''}${item.address && item.city ? ', ' : ''}${item.city || ''}${(item.city && item.country) || (item.address && item.country) ? ', ' : ''}${item.country || ''}`}</Text>


                                </Box>
                            </Box>

                          <Box w="100%">
                            
                          </Box>
                        </>
                      }
                    />
                      )
                    ):(
                      null
                    )
                   
                  )}



                  {activeTab == "procedures" && (
                    data && data.procedures ? (
                      data.procedures.map((item , index)=>
                        <DoctorCard
                      hasFooter={false}
                      cardBody={
                        <>
                          <Box
                          key={index}
                            display="flex"
                            justifyContent="space-between"
                            w="100%"
                            alignItems="flex-start"
                          >
                            <Box display="flex" gap="0.75rem">
                              <Box>
                                <Text
                                  fontSize="0.875rem"
                                  fontWeight="600"
                                  color="#061027"
                                >
                                  {item.ordername}
                                </Text>
                                <Text
                                  fontSize="0.625rem"
                                  fontWeight="500"
                                  color="#384052"
                                >
                                  {formatDate_Do_MMM_YYYY(item.orderdate)}
                                </Text>
                              </Box>
                            </Box>
                          </Box>

                          <Divider my="0.75rem" />

                          <Box display='flex' flexDir="column">
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="12px" h="13px" src="/assets/svgs/stethoscope.svg" />
                                  <Text fontSize="0.75rem" fontWeight="500">{item.doctorname}</Text>
                                </Box>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="13px" h="10px" src="/assets/svgs/hospital.svg" />
                                  <Text fontSize="0.75rem" fontWeight="500">{item.hospital}</Text>
                                </Box>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="13px" h="10px" src="/assets/svgs/location-blue.svg" />
                                  <Text fontSize="0.75rem" fontWeight="500">
                                  {(item.address === null || item.address === '') && (item.city === null || item.city === '') && (item.country === null || item.country === '') ? '-' : `${item.address || ''}${item.address && item.city ? ', ' : ''}${item.city || ''}${(item.city && item.country) || (item.address && item.country) ? ', ' : ''}${item.country || ''}`}


                                  </Text>
                                </Box>
                          </Box>

                          <Box w="100%">
                            {/* <Box
                              as="button"
                              // onClick={onClick}
                              w="100%"
                              border="1px solid #E6E7E9"
                              p="10px"
                              borderRadius="10px"
                              mt="15px"
                              height="50px"
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between">
                              <Box
                                display="flex"
                                fontSize="12px"
                                flexDirection="column"
                                justifyContent="center"
                                fontWeight="500"
                              >
                              2 documents
                              </Box>
                              <Box borderRadius="full" p="10px">
                                <Image w="8px" src="/assets/imgs/right-icon.png" />
                              </Box>
                            </Box> */}
                          </Box>
                        </>
                      }
                    />
                      )
                    ):(
                      null
                    )
                  )}
                  {activeTab == "consultations" && (
                    data && data.consultations ? (
                      data.consultations.map((item, index)=>
                        <DoctorCard
                      hasFooter={false}
                      cardBody={
                        <>
                          <Box
                          key={index}
                            display="flex"
                            justifyContent="space-between"
                            w="100%"
                            alignItems="flex-start"
                          >
                            <Box display="flex" gap="0.75rem">
                              <Box>
                                <Text
                                className="textOverflow" w="167px"
                                  fontSize="0.625rem"
                                  fontWeight="500"
                                  color="#384052"
                                  title={item.specality}
                                >
                                  {item.specality}
                                </Text>
                                <Text
                                  fontSize="0.875rem"
                                  fontWeight="600"
                                  color="#061027"
                                  title={item.doctorname}
                                >
                                  {item.doctorname}
                                </Text>
                              </Box>
                            </Box>
                            <Text
                              fontSize="0.75rem"
                              fontWeight="500"
                              color="#384052"
                            >
                              {formatDate_Do_MMM_YYYY(item.appointmentdate)}
                            </Text>
                          </Box>

                          <Divider my="0.75rem" />

                          <Box display='flex' flexDir="column">
                            <Box display="flex" alignItems="center" gap="0.5rem">
                              <Image w="12px" h="13px" src="/assets/svgs/stethoscope.svg" />
                              <Text fontSize="0.75rem" fontWeight="500">{item.doctorname}</Text>
                            </Box>
                            <Box display="flex" alignItems="center" gap="0.5rem">
                              <Image w="13px" h="10px" src="/assets/svgs/hospital.svg" />
                              <Text fontSize="0.75rem" fontWeight="500">{item.hospital}</Text>
                            </Box>
                          </Box>

                          <Box w="100%">
                            {/* <Box
                              as="button"
                              // onClick={onClick}
                              w="100%"
                              border="1px solid #E6E7E9"
                              p="10px"
                              borderRadius="10px"
                              mt="15px"
                              height="50px"
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between">
                              <Box
                                display="flex"
                                fontSize="12px"
                                flexDirection="column"
                                justifyContent="center"
                                fontWeight="500"
                              >
                              2 documents
                              </Box>
                              <Box borderRadius="full" p="10px">
                                <Image w="8px" src="/assets/imgs/right-icon.png" />
                              </Box>
                            </Box> */}
                          </Box>
                        </>
                      }
                    />
                      )
                    ):(
                      null
                    )
                    
                  )}
                 {activeTab == "followup" && data && data.followup ? (
                  data.followup.map((item, index) => {
                    const today = new Date().toISOString().split('T')[0]; // 'YYYY-MM-DD'
                    const followUpDate = item.followupdate ? item.followupdate.split('T')[0] : '';
                    const cardFooterText = `${item.isfreevisit === 1 && item?.isfreevisitbooked === 0  ? "Book" : "View"} Appointment`;
                    const handleOnClick = () => {
                      if (cardFooterText.startsWith("View")) {
                        navigate("/appointmentdetails", { state: { appointmentid: item.appointmentid } });
                      } else {
                       if(item?.isdoctoractive == 0 ){
                        showErrorToast("Doctor Unavailable!");
                        dismissLoader();
                        return false;
                       }else{
                        // if(moment(item.followupdate).isBefore(moment(), "day")){
                        //   showErrorToast("You have missed your free follow up visit, the scheduled date has passed!");
                        // }else{
                          navigate("/doctorprofile", { state: { doctorid: item.doctorid, fullname: item.fullname, followupdate: item.followupdate, isfreevisit:item.isfreevisit, followupid:item.followupid } });
                        // }
                       }
                      }
                    };
                
                    return (
                    <DoctorCard 
                      key={index} // Add key here for better React rendering
                      onClick={handleOnClick}
                      cardFooterText={cardFooterText}
                      cardBody={
                        <Box display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                          <Box display='flex' gap="0.75rem">
                            <Box boxSize="2.375rem" borderRadius='full' overflow="hidden">
                              <Image src={item.doctorimage ? item.imagepath + item.doctorimage : "/assets/imgs/no-image.png"} />
                            </Box>
                            <Box>
                              <Text className="textOverflow" w="167px" fontSize="0.625rem" fontWeight="500" color="#384052" title={item.specality}>{item.specality}</Text>
                              <Text fontSize="0.875rem" fontWeight="600" color="#061027" title={item.doctorname}>{item.doctorname}</Text>
                            </Box>
                          </Box>
                          <Text fontSize="0.75rem" fontWeight="500" color="#384052">{formatDate_Do_MMM_YYYY(item.followupdate)}</Text>
                        </Box>
                      }
                    />
                  );
                })
              ) : null}

                  
                </SimpleGrid>
              </Box>
              {showNoData  && (
                <>
                 <Box
                 w="100%"
                 h="100vh"
                 display="flex"
                 flexDirection="column"
                 gap="5px"
                 justifyContent="center"
                 alignItems="center"
               >
                 <Image w="60px" opacity="0.5" src="/assets/svgs/clinical_notes.svg" /> 
                 <Box as="p">No data available</Box>
                
                  </Box>
                 </>

              )}
              {/*
             */}
            </Flex>
          </Flex>
          <Footer />
        </Box>
      </Box>
      
      {/* Mobile responsive start */}
      <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/profile')}
          header="Orders"
        />

        <Box w="100%">
          {/* Tab buttons start */}
          <Box display='flex' gap="0.5rem" borderBottom="1px solid #E6E7E9" bg="white" p="1.25rem 1rem" w="100%" justifyContent="space-between">
            <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "prescriptions" ? "activeTab" : ""} fontSize="0.7rem" fontWeight="600" onClick={() => setActiveTab('prescriptions')} color={activeTab == "prescriptions" ? "#061027" : "#898D97"}><Text>Prescriptions</Text><Box as="span" className="whiteBorder"/></Box>
            <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "labs" ? "activeTab" : ""} fontSize="0.7rem" fontWeight="600" onClick={() => setActiveTab('labs')} color={activeTab == "labs" ? "#061027" : "#898D97"}><Text>Labs</Text><Box as="span" className="whiteBorder"/></Box>
            <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "imaging" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" onClick={() => setActiveTab('imaging')} color={activeTab == "imaging" ? "#061027" : "#898D97"}><Text>Imaging</Text><Box as="span" className="whiteBorder"/></Box>
            <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "procedures" ? "activeTab" : ""} fontSize="0.7rem" fontWeight="600" onClick={() => setActiveTab('procedures')} color={activeTab == "procedures" ? "#061027" : "#898D97"}><Text>Procedures</Text><Box as="span" className="whiteBorder"/></Box>
            <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "consultations" ? "activeTab" : ""} fontSize="0.7rem" fontWeight="600" onClick={() => setActiveTab('consultations')} color={activeTab == "consultations" ? "#061027" : "#898D97"}><Text>Consultations</Text><Box as="span" className="whiteBorder"/></Box>
            <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "followup" ? "activeTab" : ""} fontSize="0.7rem" fontWeight="600" onClick={() => setActiveTab('followup')} color={activeTab == "followup" ? "#061027" : "#898D97"}><Text>Followups</Text><Box as="span" className="whiteBorder"/></Box>
          </Box>
          {/* Tabs button end */}
        </Box>
        <Flex bg="#fcfcfd" flexDir="column" h="calc(100vh - 255px)" overflow="hidden auto">
          <Box w="100%" p="1.5rem">
            <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} spacing="1rem">
              {activeTab == "prescriptions" && (
                data && data.medication ?(  
                  <>
                  { data.medication.map((item , index)=>
                  <DoctorCard
                  key={index}
                  hasFooter={false}
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        w="100%"
                        alignItems="flex-start"
                      >
                        <Box display="flex" gap="0.75rem">
                          <Box>
                            <Text
                            className="textOverflow" w="167px"
                              fontSize="0.625rem"
                              fontWeight="500"
                              color="#384052"
                              title={item.specialization}
                            >
                              {item.specialization}
                            </Text>
                            <Text
                              fontSize="0.875rem"
                              fontWeight="600"
                              color="#061027"
                              title={item.doctorname}
                            >
                              {item.doctorname}
                            </Text>
                          </Box>
                        </Box>
                        <Text
                          fontSize="0.75rem"
                          fontWeight="500"
                          color="#384052"
                        >
                          {formatDate_Do_MMM_YYYY(item.prescribeddate)}
                        </Text>
                      </Box>

                      <Divider my="0.75rem" />
                      <Flex gap="8px" align="center">
                        <Text fontSize="12px" fontWeight="500">
                          {item.bookingid ? "#"+item.bookingid : "-"}
                        </Text>
                      </Flex>

                      <Box w="100%">
                        <Box
                          as="button"
                          onClick={()=>downloadDocument(item.imagepath + item.documents, "Order")}

                          w="100%"
                          border="1px solid #E6E7E9"
                          p="10px"
                          borderRadius="10px"
                          mt="15px"
                          height="50px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center">
                          <Box
                            display="flex"
                            fontSize="12px"
                            flexDirection="column"
                            justifyContent="center"
                            fontWeight="500"
                          >
                            Download
                          
                          </Box>
                          {/* <Box borderRadius="full" p="10px">
                            <Image w="8px" src="/assets/imgs/right-icon.png" />
                          </Box> */}
                        </Box>
                      </Box>
                    </>
                  }
                />
                  )}
                  </>
                
                ):(
                  null
                )
                  
                
              )}
              {activeTab == "labs" && (
                data && data.labs ? (
                  data.labs.map((item , index)=>
                    <DoctorCard
                  hasFooter={false}
                  cardBody={
                    <>
                      <Box
                      key={index}
                        display="flex"
                        justifyContent="space-between"
                        w="100%"
                        alignItems="flex-start"
                      >
                        <Box display="flex" gap="0.75rem">
                          <Box>
                            <Text
                              fontSize="0.875rem"
                              fontWeight="600"
                              color="#061027"
                            >
                              {item.ordername}
                            </Text>
                            <Text
                              fontSize="0.625rem"
                              fontWeight="500"
                              color="#384052"
                            >
                            {formatDate_Do_MMM_YYYY(item.orderdate)}
                            </Text>
                          </Box>
                        </Box>
                      </Box>

                      <Divider my="0.75rem" />

                      <Box display='flex' flexDir="column">
                            <Box display="flex" alignItems="center" gap="0.5rem">
                              <Image w="12px" h="13px" src="/assets/svgs/stethoscope.svg" />
                              <Text fontSize="0.75rem" fontWeight="500">{item.doctorname}</Text>
                            </Box>
                            <Box display="flex" alignItems="center" gap="0.5rem">
                              <Image w="13px" h="10px" src="/assets/svgs/hospital.svg" />
                              <Text fontSize="0.75rem" fontWeight="500">{item.hospital}</Text>
                            </Box>
                            <Box display="flex" alignItems="center" gap="0.5rem">
                              <Image w="13px" h="10px" src="/assets/svgs/location-blue.svg" /> 
                              <Text fontSize="0.75rem" fontWeight="500">{(item.address === null || item.address === '') && (item.city === null || item.city === '') && (item.country === null || item.country === '') ? '-' : `${item.address || ''}${item.address && item.city ? ', ' : ''}${item.city || ''}${(item.city && item.country) || (item.address && item.country) ? ', ' : ''}${item.country || ''}`}</Text>


                            </Box>
                        </Box>

                      <Box w="100%">
                     
                      </Box>
                    </>
                  }
                />
                  )
                ):(
                  null
                )
              
              )}

            {activeTab == "imaging" && (
                    data && data.imaging ? (
                      data.imaging.map((item , index)=>
                        <DoctorCard
                      hasFooter={false}
                      cardBody={
                        <>
                          <Box
                          key={index}
                            display="flex"
                            justifyContent="space-between"
                            w="100%"
                            alignItems="flex-start"
                          >
                            <Box display="flex" gap="0.75rem">
                              <Box>
                                <Text
                                  fontSize="0.875rem"
                                  fontWeight="600"
                                  color="#061027"
                                >
                                  {item.ordername}
                                </Text>
                                <Text
                                  fontSize="0.625rem"
                                  fontWeight="500"
                                  color="#384052"
                                >
                                 {formatDate_Do_MMM_YYYY(item.orderdate)}
                                </Text>
                              </Box>
                            </Box>
                          </Box>

                          <Divider my="0.75rem" />

                          <Box display='flex' flexDir="column">
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="12px" h="13px" src="/assets/svgs/stethoscope.svg" />
                                  <Text fontSize="0.75rem" fontWeight="500">{item.doctorname}</Text>
                                </Box>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="13px" h="10px" src="/assets/svgs/hospital.svg" />
                                  <Text fontSize="0.75rem" fontWeight="500">{item.hospital}</Text>
                                </Box>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="13px" h="10px" src="/assets/svgs/location-blue.svg" /> 
                                  <Text fontSize="0.75rem" fontWeight="500">{(item.address === null || item.address === '') && (item.city === null || item.city === '') && (item.country === null || item.country === '') ? '-' : `${item.address || ''}${item.address && item.city ? ', ' : ''}${item.city || ''}${(item.city && item.country) || (item.address && item.country) ? ', ' : ''}${item.country || ''}`}</Text>


                                </Box>
                            </Box>

                          <Box w="100%">
                            {/* <Box
                              as="button"
                              // onClick={onClick}
                              w="100%"
                              border="1px solid #E6E7E9"
                              p="10px"
                              borderRadius="10px"
                              mt="15px"
                              height="50px"
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between">
                              <Box
                                display="flex"
                                fontSize="12px"
                                flexDirection="column"
                                justifyContent="center"
                                fontWeight="500"
                              >
                              2 documents
                              </Box>
                              <Box borderRadius="full" p="10px">
                                <Image w="8px" src="/assets/imgs/right-icon.png" />
                              </Box>
                            </Box> */}
                          </Box>
                        </>
                      }
                    />
                      )
                    ):(
                      null
                    )
                   
                  )}

              {activeTab == "procedures" && (
                data && data.procedures ? (
                  data.procedures.map((item , index)=>
                    <DoctorCard
                  hasFooter={false}
                  cardBody={
                    <>
                      <Box
                      key={index}
                        display="flex"
                        justifyContent="space-between"
                        w="100%"
                        alignItems="flex-start"
                      >
                        <Box display="flex" gap="0.75rem">
                          <Box>
                            <Text
                              fontSize="0.875rem"
                              fontWeight="600"
                              color="#061027"
                            >
                              {item.ordername}
                            </Text>
                            <Text
                              fontSize="0.625rem"
                              fontWeight="500"
                              color="#384052"
                            >
                              {formatDate_Do_MMM_YYYY(item.orderdate)}
                            </Text>
                          </Box>
                        </Box>
                      </Box>

                      <Divider my="0.75rem" />

                      <Box display='flex' flexDir="column">
                            <Box display="flex" alignItems="center" gap="0.5rem">
                              <Image w="12px" h="13px" src="/assets/svgs/stethoscope.svg" />
                              <Text fontSize="0.75rem" fontWeight="500">{item.doctorname}</Text>
                            </Box>
                            <Box display="flex" alignItems="center" gap="0.5rem">
                              <Image w="13px" h="10px" src="/assets/svgs/hospital.svg" />
                              <Text fontSize="0.75rem" fontWeight="500">{item.hospital}</Text>
                            </Box>
                            <Box display="flex" alignItems="center" gap="0.5rem">
                              <Image w="13px" h="10px" src="/assets/svgs/location-blue.svg" />
                              <Text fontSize="0.75rem" fontWeight="500">
                              {(item.address === null || item.address === '') && (item.city === null || item.city === '') && (item.country === null || item.country === '') ? '-' : `${item.address || ''}${item.address && item.city ? ', ' : ''}${item.city || ''}${(item.city && item.country) || (item.address && item.country) ? ', ' : ''}${item.country || ''}`}


                              </Text>
                            </Box>
                      </Box>

                      <Box w="100%">
                        {/* <Box
                          as="button"
                          // onClick={onClick}
                          w="100%"
                          border="1px solid #E6E7E9"
                          p="10px"
                          borderRadius="10px"
                          mt="15px"
                          height="50px"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between">
                          <Box
                            display="flex"
                            fontSize="12px"
                            flexDirection="column"
                            justifyContent="center"
                            fontWeight="500"
                          >
                          2 documents
                          </Box>
                          <Box borderRadius="full" p="10px">
                            <Image w="8px" src="/assets/imgs/right-icon.png" />
                          </Box>
                        </Box> */}
                      </Box>
                    </>
                  }
                />
                  )
                ):(
                  null
                )
              )}
              {activeTab == "consultations" && (
                data && data.consultations ? (
                  data.consultations.map((item, index)=>
                    <DoctorCard
                  hasFooter={false}
                  cardBody={
                    <>
                      <Box
                      key={index}
                        display="flex"
                        justifyContent="space-between"
                        w="100%"
                        alignItems="flex-start"
                      >
                        <Box display="flex" gap="0.75rem">
                          <Box>
                            <Text
                            className="textOverflow" w="167px"
                              fontSize="0.625rem"
                              fontWeight="500"
                              color="#384052"
                              title={item.specality}
                            >
                              {item.specality}
                            </Text>
                            <Text
                              fontSize="0.875rem"
                              fontWeight="600"
                              color="#061027"
                              title={item.doctorname}
                            >
                              {item.doctorname}
                            </Text>
                          </Box>
                        </Box>
                        <Text
                          fontSize="0.75rem"
                          fontWeight="500"
                          color="#384052"
                        >
                          {formatDate_Do_MMM_YYYY(item.appointmentdate)}
                        </Text>
                      </Box>

                      <Divider my="0.75rem" />

                      <Box display='flex' flexDir="column">
                        <Box display="flex" alignItems="center" gap="0.5rem">
                          <Image w="12px" h="13px" src="/assets/svgs/stethoscope.svg" />
                          <Text fontSize="0.75rem" fontWeight="500">{item.doctorname}</Text>
                        </Box>
                        <Box display="flex" alignItems="center" gap="0.5rem">
                          <Image w="13px" h="10px" src="/assets/svgs/hospital.svg" />
                          <Text fontSize="0.75rem" fontWeight="500">{item.hospital}</Text>
                        </Box>
                      </Box>

                      <Box w="100%">
                        {/* <Box
                          as="button"
                          // onClick={onClick}
                          w="100%"
                          border="1px solid #E6E7E9"
                          p="10px"
                          borderRadius="10px"
                          mt="15px"
                          height="50px"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between">
                          <Box
                            display="flex"
                            fontSize="12px"
                            flexDirection="column"
                            justifyContent="center"
                            fontWeight="500"
                          >
                          2 documents
                          </Box>
                          <Box borderRadius="full" p="10px">
                            <Image w="8px" src="/assets/imgs/right-icon.png" />
                          </Box>
                        </Box> */}
                      </Box>
                    </>
                  }
                />
                  )
                ):(
                  null
                )
                
              )}
              {activeTab == "followup" && (
                data && data.followup ? (
                  data.followup.map((item, index)=>
                    <DoctorCard 
                  onClick={()=> navigate("/appointmentdetails", { state: { appointmentid: item.appointmentid }})}
                  cardFooterText={`${item.isfreevisit == 1 ? "Book" : "View"} Appointment`}
                  cardBody={<>
                    <Box key={index} display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                      <Box display='flex' gap="0.75rem">
                        <Box boxSize="2.375rem" borderRadius='full' overflow="hidden">
                          <Image src={item.doctorimage ? item.imagepath + item.doctorimage : "/assets/imgs/no-image.png"} />
                        </Box>
                        <Box>
                          <Text className="textOverflow" w="167px" fontSize="0.625rem" fontWeight="500" color="#384052" title={item.specality}>{item.specality}</Text>
                          <Text fontSize="0.875rem" fontWeight="600" color="#061027" title={item.doctorname}>{item.doctorname}</Text>
                        </Box>
                      </Box>
                      <Text fontSize="0.75rem" fontWeight="500" color="#384052">{formatDate_Do_MMM_YYYY(item.followupdate)}</Text>
                    </Box>
                  </>} 
                />
                  )
                ):(
                  null
                )
            
              )}
              
            </SimpleGrid>
          </Box>
          {showNoData  && (
            <>
            <Box
            w="100%"
            h="100vh"
            display="flex"
            flexDirection="column"
            gap="5px"
            justifyContent="center"
            alignItems="center"
          >
            <Image w="60px" opacity="0.5" src="/assets/svgs/clinical_notes.svg" /> 
            <Box as="p">No data available</Box>
            
              </Box>
            </>

          )}
        </Flex>
      </Box>

      <ModalRight
          ref={documentsModal}
          secondaryHeader={header}
          subHeader={formatDate_Do_MMM_YYYY(identifiedData) }
          body={
            <Flex pt="15px" flexDirection="column" h="100%">
              { documentURLs?.map((document, index) => (
                <Box key={index} mb="2"
                onClick={()=> downloadDocument(document.imagepath + document.documenturl)}
                cursor="pointer"
                >
                  <Button
                    rel="noopener noreferrer"
                    w="100%"
                    h="48px"
                    borderRadius="10px"
                    border="1px solid #E6E7E9"
                    display="flex"
                    justifyContent="space-between"
                    bg="white"
                    color="#1C4EC5"
                    sx={{ _hover: { bg: "none" } }}
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontSize: "12px",
                    }}
                  >
                    Document {index + 1}
                    <Box as="span">
                      <Image src="/assets/imgs/download.png" />
                    </Box>
                  </Button>
                </Box>
              ))}
              <Spacer />
             
            </Flex>
          }
        />
    </>
  );
};

export default OrdersIndex;
