import { Flex, Box, Text, Table, Tbody, Tr, Td, Image, Spacer, Slider } from '@chakra-ui/react';
import React, { useEffect, useState, useLayoutEffect, useReducer } from 'react';
import moment from "moment";
import ModalRight from '../modal-right';
import { displayMillion_ThoudandValues, formatDate_Do_MMM_YYYY, isValidNumber } from '../../auth';
import { AiAbnormalCall}  from "../../middleware/services/myhealth/uploaddocument";
import TypingLoader from "../../components/typing-loader";
import AiModal from "../../components/ai-modal";
import {
  FetchAIAnswer,
} from "../../middleware/services/myhealth/vitals";
import useLoader  from "../../components/loader/loaderstates";
import Loader  from "../../components/loader/loader";
import { getDob, getusername, getgender } from "../../auth";


import { PrimaryButton, Toaster,PrimaryButtonIcon } from "../../components";
import { FetchTokens } from '../../middleware/services/profile/rewards';

function DynamicTable({ TableData, onClick, withModal = false , showUpgradePlanModal}) {
  const [shouldRender, setShouldRender] = useState(false);
  const boxesPerRow = 10;
  const {isLoading, showLoader, dismissLoader } = useLoader();
  const [date, setDate] = useState("");
  const [header, setHeader] = useState("");
  const [normalResultText, setNormalResultText] = useState("");
  const [abnormalResultText, setAbnormalResultText] = useState("");
  const [interpretitionText, setInterpretitionText] = useState("");
  const modalLearnMore = React.useRef();
  const [aiquestion, setAIAnalysis] = useState(null);
  const [aianswers, setAIAnswers] = useState(null);
  const [testname, setTestName] = useState(null);
  const [testresult, setTestResult] = useState(null);
  const [upperbound, setUpperBound] = useState(null);
  const [lowerbound, setlowerBound] = useState(null);
  const [testunit, setTestUnit] = useState(null);
  const [remainingquestions, setRemainingQuestions] = useState('');
  const AiQuestions = React.useRef();
  const AIChatModel = React.useRef();
  const chatContainerRef = React.useRef(null);
  const [meter, setMeter] = useState(50)
  const [remainingTokens, setRemainingTokens] = useState();
  const { showSuccessToast, showErrorToast } = Toaster();
  
  useEffect(() => {
    getTokens();
  }, [])
  var input = {}
  var getTokens = async () => {
    try {
      var result = await FetchTokens(input);
      if (result) {
        setRemainingTokens(result)
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.log(error, "error in fetching account data")
    }
    //dismissLoader();
  }
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShouldRender(true);
    }, 0);
    return () => clearTimeout(timeoutId);
  }, []);

  const onClickFuncInModal = (cellData) => {
    if (cellData) {
      setDate(formatDate_Do_MMM_YYYY(cellData.documentdate))
      setHeader(cellData.header)
      setNormalResultText(cellData.results_interpretation.normal_results)
      setAbnormalResultText(cellData.results_interpretation.abnormal_results)
      setInterpretitionText(cellData.results_interpretation.report_interpretation)
    }
    modalLearnMore.current.openRight()
  }
    let patientgender = getgender();
    let gender = patientgender;
    if (gender === 1) {
      gender = "Female";
    } else if (gender === 2) {
      gender = "Male";
    } else if(gender === 3) {
      gender = "Others";
    }
    // Get Patient details for AI Calls
    const patientname = getusername();
    const dob = getDob();
    const age = calculateAge(dob);
    function calculateAge(dateOfBirth) {
      const today = new Date();
      const dob = new Date(dateOfBirth);
      let age = today.getFullYear() - dob.getFullYear();
      const monthDiff = today.getMonth() - dob.getMonth();
  
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
        age--;
      }
      return age;
    }
    let barWidth;
    let triangleWidth;
    let totalSections;
    let sectionWidth;

    let lowerBoundNum;
    let upperBoundNum;
    let actualResultValueNum;

    let lowerThreshold;
    let upperThresholdValue;
    let upperThresholdDup;
    let upperThreshold;
    let trianglePosition;
    let [pointerPos , setPointerPos] = useState(46)

  const onClickFuncAI = async (data, name, unit) => {
    console.log(data)
    setTestName(name);
    setTestResult(data.resultvalue);
    setUpperBound(data.upper_bound);
    setlowerBound(data.lower_bound);

    // calculatiuon start ------ 
     barWidth = 86;
     triangleWidth = 10;
     totalSections = 5;
     sectionWidth = barWidth / totalSections;

     lowerBoundNum = Number(data.lower_bound);
     upperBoundNum = Number(data.upper_bound);
     actualResultValueNum = Number(data.resultvalue);

     lowerThreshold = lowerBoundNum - 0.1 * lowerBoundNum;
     upperThresholdValue = upperBoundNum + 0.1 * upperBoundNum;
     upperThresholdDup = upperThresholdValue - upperBoundNum;
     upperThreshold = upperBoundNum - upperThresholdDup;
       trianglePosition = barWidth / 2;

       if (actualResultValueNum < lowerBoundNum && actualResultValueNum < lowerThreshold) {
        trianglePosition = 14
      } else if (actualResultValueNum < lowerBoundNum && actualResultValueNum >= lowerThreshold) {
        trianglePosition = 30
      } else if (actualResultValueNum >= lowerBoundNum && actualResultValueNum < upperThreshold) {
      
        trianglePosition = 46
      } else if (actualResultValueNum >= upperBoundNum) {
         trianglePosition = 80
      } else if (actualResultValueNum > lowerBoundNum && actualResultValueNum >= upperThreshold && actualResultValueNum <= upperBoundNum) {
         trianglePosition = 62
      } 
      // else if (actualResultValueNum < upperBoundNum && actualResultValueNum <= upperThreshold) {
     
      // } 
      else {
        trianglePosition = 46;
      }
      setPointerPos(trianglePosition)
     // calculateion end --------

    setTestUnit(unit)
  let testlist = []
   const object = {
    "test_name": name,
    "test_unit": unit,
    "test_value": data.resultvalue,
    "lower_bound":data.lower_bound,
    "upper_bound":data.upper_bound,
   }
   testlist.push(object)
   let input = {
    age: age,
    name: getusername(),
    gender: gender,
    data:{
      test_data: testlist
    }
  };
  showLoader();
     const response = await AiAbnormalCall(input);
     dismissLoader();       
          console.log(response, "AI Response");
          if(response?.output?.message == "No credits are available"){
            showUpgradePlanModal(true)
          }else if (response) {            
            setAIAnalysis(response);
            AiQuestions.current.openRight();
          }
  }
  const GetAIAnswer = async (prompt) => {
    AIChatModel.current.openRight();
    let AichatInput = {
      type:"",
      value: "",
      unit: "",
      gender: gender,
      age: age,
      name: patientname,
      prompt: prompt,
    };

    // Display question immediately
    const newQuestion = { question: prompt, answer: "" };
    setAIAnswers(prevState => {
      if (prevState === null) {
        return [newQuestion];
      } else {
        return [...prevState, newQuestion];
      }
    });
    //showLoader();
    const response = await FetchAIAnswer(AichatInput);
    console.log("AI Reponse", response)
    //dismissLoader(); 
    if (response) {
      // Replace "typing" with actual answer
      setAIAnswers(prevState => {
        const updatedAnswers = prevState.map(item => {
          if (item.question === prompt && item.answer === "") {
            return { question: item.question, answer: response.answer };
          }
          return item;
        });
        getTokens();
        return updatedAnswers;
      });
      const indexesToRemove = [];
      aiquestion.generated_prompts.forEach((question, index) => {
        if (question.value === prompt) {
          indexesToRemove.push(index);
        }
      });

      // Remove the objects at the indexes collected above
      indexesToRemove.reverse().forEach((index) => {
        aiquestion.generated_prompts.splice(index, 1);
      });
      setRemainingQuestions(aiquestion.generated_prompts);
      
      window.scrollTo(0, document.body.scrollHeight);   
     }
     //AIChatModel.current.openRight();
  };
  const handleSkip = () => {
    AiQuestions.current.closeRight();
  };
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };
    // Use `useLayoutEffect` to ensure the scroll happens after DOM updates
    useLayoutEffect(() => {
      scrollToBottom();
    }, [aianswers]);
    const closeChatModel = ()=>{   
      AIChatModel.current.closeRight();
      AiQuestions.current.closeRight();            
    }
  return (
    <Flex direction="column" overflowX="auto" width="100%">
      <Box maxW="100%" overflowX="auto">
      {isLoading && <Loader/>}
        <Table variant="simple" size="sm" width="100%">
          <Tbody>
            {shouldRender && TableData && TableData.rows && TableData.rows.map((row, rowIndex) => (

              <Tr key={row.header}>
                <Td w="210px" h="66px" border="1px solid #E6E7E9" >
                  <Text fontWeight="bold">{row.header}</Text>
                  {row.unit && <Text>({row.unit})</Text>}
                </Td>
                {row.values.map((value, index) => (
                  <Td w="160px" key={index} p="24px" border="1px solid #E6E7E9"
                  >
                    {withModal == true &&
                      <Box as='button' onClick={() => onClickFuncInModal(value)}>
                        <DelayedRender value={value} delay={rowIndex * 0} />
                      </Box>
                    }

                    {withModal == false &&
                     <Box as='button'  onClick={() => onClickFuncAI(value, row.header, row.unit)}>
                      <DelayedRender value={value} delay={rowIndex * 0} />
                      </Box>
                    }

                  </Td>
                ))}
                {Array.from({ length: boxesPerRow - row.values.length }, (_, index) => (
                  <Td w="160px" key={`empty-${index}`} p="24px" border="1px solid #E6E7E9" />
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <ModalRight
        ref={modalLearnMore}
        isBg={true}
        modalWidth="480px"
        customHeader={false}
        bodyStyle={{ p: "0", overflow: "hidden" }}
        footerStyle={{ borderTop: "1px solid #E6E7E9" }}
        body={
          <>
            <Box bg="#061027" p="35px">
              <Box display="flex" alignItems="center" gap="10px">
                <Box display="flex" flexDir="row" justifyContent="space-between" gap="5px" alignItems="center">
                  <Box fontSize="20px" fontWeight="600" color="#E6E7E9">
                      {header ? header : "-"}
                    <Box as="p" pt="15px" fontSize="14px" fontWeight="400" lineHeight="normal" color="white">
                      {date ? date : "-"}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box bg="#fbfbfc" overflow="hidden auto" h="calc(100% - 220px)">
              <Box overflowY="auto" p="24px 32px">
                <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">Normal results</Box>
                <Box as="h5" fontSize="16px" pb="20px" fontWeight="300">
                  {normalResultText ? normalResultText : "-"}
                </Box>

                <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">Abnormal results</Box>
                <Box as="h5" fontSize="16px" pb="20px" fontWeight="300">
                  {abnormalResultText ? abnormalResultText : "-"}
                </Box>
                <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">Report Interpretition</Box>
                <Box as="h5" fontSize="16px" pb="20px" fontWeight="300">
                  {interpretitionText ? interpretitionText : "-"}
                </Box>
              </Box>
            </Box>
          </>
        }
      />
        <AiModal
          bodyStyle={{p: "0"}}
          ref={AiQuestions}
          backDropBlur="10px"
          modalSize="420px"
          header={<>
            <Box bg="#061027" w='100%'>
              <Box display="flex" alignItems="center" gap="10px" w='100%'>
                <Box display="flex" w='100%' flexDir="row" justifyContent="space-between" gap="5px" alignItems="center">
                  <Box fontSize="20px" fontWeight="600" color="#E6E7E9" w='100%'>
                  {/* {testunit ? testname + " (" + testunit + ")" : testname} */}
                  {testunit ? `${testname} (${testresult?testresult:""} ${testunit})` : testname}
                    {/* <Box display='flex' gap='10px' w='100%'> */}
                      <Box display='flex' gap='10px' justifyContent='space-between' w='100%'>
                        {/* <Box as="p" pt="12px" fontSize="12px" fontWeight="400" lineHeight="normal" color="white">
                          Result : {testresult?testresult:""}
                        </Box> */}
                        <Box as="p" pt="12px" fontSize="12px" fontWeight="400" lineHeight="normal" color="white">
                        Lower Bound : {lowerbound?lowerbound:"-"}
                        </Box>
                        <Box as="p" pt="12px" fontSize="12px" fontWeight="400" lineHeight="normal" color="white">
                        Upper Bound : {upperbound?upperbound:"-"}
                         
                        </Box>
                      </Box>
                    {/* </Box> */}
                  </Box>
                </Box>
              </Box>
             {upperbound && lowerbound && isValidNumber(testresult) &&
              <Box
                  width="100%"
                  borderRadius="50px"
                  display="flex"
                  position="relative"
                  mt="20px"
                  zIndex='999'
                >
                  {/* {/ Green Seon /} */}
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="30%"
                    height="60%"
                    bg="red.400"
                    zIndex="9"
                    // outline="1px solid #FFBF00"
                    borderRight='none'
                    borderRadius="30px 0 0 30px"
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      fontSize="10px"
                      alignItems="center"
                      h="100%"
                      color="white"
                    >
                      LOW
                    </Box>
                  </Box>

                  <Box
                    position="absolute"
                    top="0"
                    left="28%"
                    width="7%"
                    height="60%"
                    zIndex="9"
                    bg="#FFBF00"
                  >
                  </Box>

                  {/* {/ Yellow Section /} */}
                  <Box
                    position="absolute"
                    top="0"
                    left="33%"
                    width="30%"
                    height="60%"
                    bg="green.400"
                    //bg="yellow.400"
                    zIndex="-1"
                    // outline="1px solid #9edc9e"
                    borderRight='none'
                    borderLeft='none'
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      color="white"
                      fontSize="10px"
                      alignItems="center"
                      h="100%"
                    >
                        NORMAL
                    </Box>
                  </Box>

                  <Box
                    position="absolute"
                    top="0"
                    left="60%"
                    width="7%"
                    height="60%"
                    zIndex="9"
                    bg="#FFBF00"
                  >
                  </Box>

                  {/* {/ Red Section /} */}
                  <Box
                    position="absolute"
                    top="0"
                    left="66%"
                    width="30%"
                    height="60%"
                    bg="red.400"
                    zIndex="-1"
                    // outline="1px solid #FFBF00"
                    borderLeft='none'
                    borderRadius="0 30px 30px 0"
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      color="white"
                      fontSize="10px"
                      alignItems="center"
                      h="100%"
                    >
                     HIGH
                    </Box>
                  </Box>

                  
                  {/* {/ Meter Indicator (based on slider value) /} */}
                  {/* <Box display='flex' justifyContent='space-around' w='100%' pos='relative' top='-18px' left='24%'>
                    <Text fontSize='10px' color='white'>0</Text>
                    <Text fontSize='10px' color='white' ml='6px'>5</Text>
                    <Text fontSize='10px' color='white'>10</Text>
                  </Box> */}

                  <Box
                    position="absolute"
                    top="30px"
                    left={`${pointerPos}%`}
                    
                    // transform="rotate(180deg)"
                    width="0"
                    height="0"
                    borderLeft="6px solid transparent"
                    borderRight="6px solid transparent"
                    borderBottom="12px solid white"
                  />


                  {/* {/ Slider to control the value /} */}
                  <Slider
                    mt={10}
                    defaultValue={50}
                    min={0}
                    max={100}
                    step={1}
                    onChange={(val) => setMeter(val)}
                  >
                  
                  </Slider>
                </Box>
             }
            </Box>
          </>}
          body={
            <>
              <Flex flexDirection="column" height="100%">
                <Box
                  p="24px 32px"
                  pt="0"
                  display="flex"
                  h="100%"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Box w="100%" py="20px">
                    {aiquestion &&
                      aiquestion.generated_prompts
                      &&
                      aiquestion.generated_prompts
                      .map((step, index) => (
                        <Box
                        key={index}
                          as="button"
                          w="100%"
                          p="16px"
                          mb="16px"
                          fontSize="14px"
                          display="flex"
                          textAlign="left"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          gap="20px"
                          lineHeight="normal"
                          borderRadius="10px"
                          border="1px solid #E6E7E9"
                          onClick={() => GetAIAnswer(step.value)}
                        >
                          {step.value ? step.value : "-"}
                          <Image src="/assets/imgs/right-icon.png" />
                        </Box>
                      ))}
                  </Box>
                  <PrimaryButtonIcon
                    buttonText="Skip Now"
                    onClick={handleSkip}
                  />
                </Box>
                <Spacer />
              </Flex>
            {/* AI Chat Model */}
        <AiModal
          ref={AIChatModel}
          modalSize="420px"
          //icon="/assets/imgs/ai-doctor.png"
          header={<>
            <Box display="flex" flexDir='column' gap='5px'>
            <Box display='flex' gap='6px'>
              <Text fontSize="1rem"
                fontWeight="700" 
                backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)" 
                color="transparent" 
                backgroundClip="text"
              >Aira - AI</Text>
              <Box display='flex' alignItems='center' gap='5px' border='1px solid #0d7e54' bg='#d5ece3'  p='3px 5px' borderRadius='full'>
                <Image src='/assets/imgs/spark.png' /> 
                <Text fontSize='10px' fontWeight='500' color='#000'>{remainingTokens && remainingTokens.remainingtokens ? displayMillion_ThoudandValues(remainingTokens.remainingtokens):"0"} tokens available</Text>
              </Box>
            </Box>
            <Text fontSize="0.75rem" fontWeight="400">Chat with your AI health guide</Text>
          </Box>
          </>}
          headerStyle={{ p: "24px 32px", lineHeight: "normal" }}
          footerStyle={{ maxH: "35%", overflow: "hidden auto", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
          backDropBlur="10px"
          closeBtnStyle={{ top: "18px" }}
          withoutBg={true}
          handlechatclose={closeChatModel}
          body={
            <>
              <Flex flexDirection="column" height="100%">
                <Box p="24px 32px" className="chat-container">
                  {aianswers &&
                    aianswers.map((step, index) => (
                      <>
                        <Box
                        key={index}
                          as="div"
                          bg="#061027"
                          p="12px 16px"
                          w="fit-content"
                          float="right"
                          borderRadius="10px 10px 1px 10px"
                        >
                          <Text fontSize="14px" fontWeight="500" color="white">
                            {step.question ? step.question : "-"}
                          </Text>
                        </Box>

                        <Box
                          as="div"
                          my="20px"
                          w="fit-content"
                          display="flex"
                          alignItems="end"
                          float="left"
                        >
                          <Image
                            bg="#061027"
                            p="4px"
                            mr="8px"
                            borderRadius="6px"
                            src="/assets/imgs/ai-chat-logo.png"
                          />
                          <Text
                            bg="#E6E7E980"
                            p="12px 16px"
                            fontSize="14px"
                            fontWeight="500"
                            color="#061027"
                            borderRadius="10px 10px 10px 1px"
                          >
                            {step.answer ? step.answer : <TypingLoader />}
                          </Text>
                        </Box>
                      </>
                    ))}
                </Box>
              </Flex>
            </>
          }
          footerActions={
            <>
              <Box
                p="24px 32px"
                display="flex"
                w="100%"
                h="100%"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box w="100%" mt="auto">
                  {remainingquestions &&
                    remainingquestions.map((step, index) => (
                      <Box
                      key={index}
                        as="button"
                        w="100%"
                        p="16px"
                        mb="16px"
                        fontSize="14px"
                        fontWeight="600"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        borderRadius="10px"
                        border="1px solid #7795DC"
                        bg="#EAEDF5"
                        color="#163E9E"
                        gap='1rem'
                        textAlign='left'
                        onClick={() => GetAIAnswer(step.value)}
                      >
                        {step.value ? step.value : "-"}
                        <Image src="/assets/imgs/right-icon.png" />
                      </Box>
                    ))}
                  <Box
                    as="button"
                    w="100%"
                    p="16px"
                    mb="16px"
                    fontSize="14px"
                    fontWeight="600"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    borderRadius="10px"
                    border="1px solid #7795DC"
                    bg="#EAEDF5"
                    color="#163E9E"
                    onClick={handleSkip}
                    
                  >
                            Skip Now
                    <Image src="/assets/imgs/right-icon.png" />
                  </Box>
                </Box>
              </Box>
            </>
          }
        />
            </>
          }
        />
    </Flex>
  );
}

const DelayedRender = ({ value, delay }) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShouldRender(true);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [delay]);
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return moment(dateString).format('Do MMM, YYYY');
  };
  return shouldRender ? (
    <Box
      display="flex"
      flexDirection="column"
      fontSize="14px"
      fontWeight="500"
      gap="5px"
      w='120px'
      textAlign='center'
      alignItems="center"
      sx={{ textWrap: 'wrap' }}
    >
      {/* <Image w="13px" h="16px" src="/assets/imgs/uploaded-doc.png" /> */}
      {value.trendcolor === "red" ? (
        <Text style={{ color: 'red' }}>{value.resultvalue}</Text>
      ) : (
        <Text style={{ color: 'black' }}>{value.resultvalue}</Text>
      )}
      <Text sx={{textDecoration:"underline",_hover:{color:"blue", textDecoration:"underline"}}}>{formatDate(value.documentdate)}</Text>
    </Box>
  ) : null;
};




export default DynamicTable;