import React, { useState, useEffect,useRef } from "react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import { Box,Text, Flex, Image, Divider, SimpleGrid } from "@chakra-ui/react";
import Footer from "../../components/footer"
import { PrimaryButton, SearchBar } from "../../components";
import DoctorCard from "../../components/doctor-card";
import TabSection from "../../components/tabs";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { FetchAppointments } from "../../middleware/services/appointments";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { useScreenType } from "../../auth";




const AllAppointment = () => {
  var [patientid, setPatientid] = useState();
  var [activeuserid, setActiveUser] = useState();
  var [data, setData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [search, setSearch] = useState("");
  const [showhidesearch, ShowHideSearch] = useState(false);
  const navigate = useNavigate()
  const screenType = useScreenType()
  useEffect(() => {
    fetchAppointments(1);  
  }, []);
  const fetchAppointments = async (type) => {
    setData("")
    showLoader();
    try {
      const result = await FetchAppointments({
        patientid,
        activeuserid,
        appointmentstatus:type
      });
      if (result != null) {
        setData(result);
      } else {
        //alert("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
  const SearchIconClicked = () => {
    ShowHideSearch(true);
  };
  const closeSearchBar =()=>{
    setSearch("")
    ShowHideSearch(false)
  }
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };
  const formatTime = (timeString) => {
    if (!timeString) return "-";
    return moment(timeString, 'HH:mm:ss').format('HH:mm');
};
const handleTabClick = (index) => {
  switch (index) {
    case 0:
      fetchAppointments(1);
      break;
    case 1:
      fetchAppointments(2);
      break;
    
    default:
      break;
  }
};
const backFunction = ()=>{
  navigate("/appointments")
}
  return (
    <>
    {isLoading && <Loader />}

    {screenType == 'web' ? (
      <Box w="100%">
      <Box top="0" zIndex="banner">
          <Navbar />
          
          {/* {isLoading && <Loader />} */}
          <SecondaryNav
            withBack="Appointments"
            goBack={backFunction}
            withBackBody={<>
                <Box display="flex" gap="10px" alignItems="center" pr="25px">
                  <TabSection
                  defaultIndex={0}
                    tabs={["Upcoming","Past"]}
                    onClickTab={handleTabClick}
                  />
                 <Box
                    as="button"
                    bg="none"
                    p="10px"
                    border="1px solid #E6E7E9"
                    borderRadius="10px"
                  >
                    <Image w="18px" src="/assets/imgs/search.png" 
                    onClick={SearchIconClicked}/>                  
                  </Box>
                </Box>
            </>}
          />
        </Box>
        <Box h="100%">
        {showhidesearch && (
            <Box p="1.25rem 2rem" bg="white" border="1px solid #E6E7E9">
                <SearchBar passInputPlace="Search" 
                onChange={(event) => setSearch(event.target.value)}
                iconSrc="/assets/imgs/remove-icon.png"
                    handleClick={closeSearchBar}/>
            </Box>
            )}
          <Flex h="calc(100vh - 180px)" flexDir="column" bg="#fcfcfd" p="1.25rem 2rem" overflow="hidden auto">
            {/* Main container start */}
            <Box w="100%">
              {/* Appointments start */}
              <Box w="100%">
              {data && data.length > 0  &&  
                data
  .filter(step => step && step.doctorname && step.doctorname.toLowerCase().includes(search.toLowerCase()))
  .length > 0 ? (
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="1rem">
                  {data
                    .filter(step => step && step.doctorname && step.doctorname.toLowerCase().includes(search.toLowerCase()))
                    .map((step, index) => (
                      <DoctorCard
                        key={index}
                        cardBody={
                          <>
                            <Box>
                              <Box display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                                <Box display='flex' gap="0.75rem">
                                  <Box boxSize="2.375rem" borderRadius='full' overflow="hidden">
                                    <Image src={step.doctorimage?step.imagepath+step.doctorimage:"/assets/imgs/no-image-icon.png"} />
                                  </Box>
                                  <Box>
                                    <Text fontSize="0.625rem" fontWeight="500" color="#384052">{step.specality? step.specality:"-"}</Text>
                                    <Text fontSize="0.875rem" fontWeight="600" color="#061027">{step.doctorname? step.doctorname:"-"}</Text>
                                  </Box>
                                </Box>
                                <Text fontSize="0.625rem" fontWeight="600" color={step.appointmentstatus === 1? "#FC8935" : "#384052"} borderColor={step.appointmentstatus === 1? "#FC89351A" : "#f5f5f6"} border="1px solid" p="0.35rem 0.5rem" borderRadius="full" bg={step.appointmentstatus === 1? "#fff3eb" : "#f5f5f6"}>{step.appointmentstatus === 1? "Upcoming": step.appointmentstatus === 2?"Cancelled": step.appointmentstatus === 3 ? "Completed": step.appointmentstatus === 4 ? "No show": step.appointmentstatus === 0 ? "Pending" :""}</Text>
                              </Box>

                              <Divider my="0.75rem" />
                              <Box display='flex' gap="1rem">
                                <Box>
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="12px" h="13px" src="/assets/imgs/calendar-blue.png" />
                                    <Text fontSize="0.75rem" fontWeight="500">{formatDate(step.appointmentdate)}</Text>
                                  </Box>
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="13px" h="10px" src="/assets/imgs/images-blue.png" />
                                    <Text fontSize="0.75rem" fontWeight="500">{step.appointmenttype_cd? step.appointmenttype_cd:"-"}</Text>
                                  </Box>
                                </Box>
                                <Box>
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="13px" h="13px" src="/assets/imgs/schedule-blue.png" />
                                    <Text fontSize="0.75rem" fontWeight="500">{formatTime(step.appointmenttime)}</Text>
                                  </Box>
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="14px" h="13px" src="/assets/imgs/hospital-blue.png" />
                                    <Text fontSize="0.75rem"  fontWeight="500">{step.hospitalname? step.hospitalname:"-"}</Text>
                                  </Box>
                                </Box>
                              </Box>
                              {step.notes &&
                              <Box border="1px solid #E6E7E9" mt="0.75rem" borderRadius="0.75rem" bg="#FCFCFD" p="0.75rem">
                                <Text fontSize="0.625rem" fontWeight="500" color="#384052">Notes</Text>
                                <Text fontSize="0.75rem" fontWeight="500" color="#061027">{step.notes?step.notes:"-"}</Text>
                              </Box>
                               }
                            </Box>
                          </>
                        }
                        cardFooterText="View appointment"
                        onClick={()=> navigate("/appointmentdetails", { state: { appointmentid: step.appointmentid }})}
                      />
                    ))
                  }
                </SimpleGrid>
              ) : (
                <>
                <Box display="flex" flexDir="column" justifyContent="center" gap="1rem" alignItems="center" h="calc(100vh - 160px)" overflow="hidden auto">
                  <Image w="3.5rem" opacity='0.5' src="/assets/svgs/appointments.svg" />
                  <Text fontSize="1rem" fontWeight="700" color="#6A707D">No appointments</Text>
                  <PrimaryButton buttonText="Book appointment" btnStyle={{w: "fit-content"}} onClick={()=> navigate("/appointments/doctors")} />
                  </Box>
                </> 
              )}

              </Box>
              {/* Appointments end */}
            </Box>

             {/* No appoinments start */}
              {/* <Image w="3.5rem" src="/assets/svgs/notifications.svg" />
              <Text fontSize="1rem" fontWeight="700" color="#6A707D">No appointments</Text> */}
              {/* No appoinments end */}

            {/* Main container end */}
           
            {/* <Image w="25%"  src="/assets/imgs/midoc-black.png" />
            <Text fontSize="35px" mt="35px" fontWeight="700" backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)" color="transparent" backgroundClip="text">Coming soon</Text> */}
          </Flex>
          <Footer />
        </Box>
      </Box>
    ) : (
      //Mobile responsive start
      <Box w="100%" bg="#fcfcfd">
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/appointments')}
          header="Appointments"
          bottomArea={<>
            <Box display="flex" gap="10px" justifyContent="center" alignItems="center" w="100%">
            {showhidesearch ? (
              <Box w="100%">
                <SearchBar
                  passInputPlace="Search doctors"
                  onChange={(e) => setSearch(e.target.value)}
                  iconSrc="/assets/imgs/remove-icon.png"
                  handleClick={closeSearchBar}
                />
              </Box>
            ) : 
              <Box w="100%">
                <TabSection
                  defaultIndex={0}
                  tabs={["Upcoming","Past"]}
                  onClickTab={handleTabClick}
                />
              </Box>
            }
            </Box>
          </>}
          rightArea={<>
            {data &&
              data !== null && (
                <>
                  <Box as="button" onClick={SearchIconClicked}>
                    <Image
                      p="10px"
                      borderRadius="10px"
                      border="1px solid #E6E7E9"
                      src="/assets/imgs/search.png"
                    />
                  </Box>
                </>
             )}
          </>}
        />

        <Box w="100%" p="1.25rem"  h='calc(100vh - 270px)' overflow="hidden auto">
          {/* Appointments start */}
            <Box w="100%">
            {data && data.length > 0   &&  
                data
  .filter(step => step && step.doctorname && step.doctorname.toLowerCase().includes(search.toLowerCase()))
  .length > 0 ? (
              <SimpleGrid columns={1} spacing="1rem">
                {data
                  .filter(step => step && step.doctorname && step.doctorname.toLowerCase().includes(search.toLowerCase()))
                  .map((step, index) => (
                    <DoctorCard
                      key={index}
                      cardBody={
                        <>
                          <Box>
                            <Box display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                              <Box display='flex' gap="0.75rem">
                                <Box boxSize="2.375rem" borderRadius='full' overflow="hidden">
                                  <Image src={step.doctorimage?step.imagepath+step.doctorimage:"/assets/imgs/no-image-icon.png"} />
                                </Box>
                                <Box>
                                  <Text fontSize="0.625rem" fontWeight="500" color="#384052">{step.specality? step.specality:"-"}</Text>
                                  <Text fontSize="0.875rem" fontWeight="600" color="#061027">{step.doctorname? step.doctorname:"-"}</Text>
                                </Box>
                              </Box>
                              <Text fontSize="0.625rem" fontWeight="600" color={step.appointmentstatus === 1? "#FC8935" : "#384052"} borderColor={step.appointmentstatus === 1? "#FC89351A" : "#f5f5f6"} border="1px solid" p="0.35rem 0.5rem" borderRadius="full" bg={step.appointmentstatus === 1? "#fff3eb" : "#f5f5f6"}>{step.appointmentstatus === 1? "Upcoming": step.appointmentstatus === 2?"Cancelled": step.appointmentstatus === 3 ? "Completed": step.appointmentstatus === 4 ? "No show": step.appointmentstatus === 0 ? "Pending" :""}</Text>
                            </Box>

                            <Divider my="0.75rem" />
                            <Box display='flex' gap="1rem">
                              <Box>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="12px" h="13px" src="/assets/imgs/calendar-blue.png" />
                                  <Text fontSize="0.75rem" fontWeight="500">{formatDate(step.appointmentdate)}</Text>
                                </Box>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="13px" h="10px" src="/assets/imgs/images-blue.png" />
                                  <Text fontSize="0.75rem" fontWeight="500">{step.appointmenttype_cd? step.appointmenttype_cd:"-"}</Text>
                                </Box>
                              </Box>
                              <Box>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="13px" h="13px" src="/assets/imgs/schedule-blue.png" />
                                  <Text fontSize="0.75rem" fontWeight="500">{formatTime(step.appointmenttime)}</Text>
                                </Box>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="14px" h="13px" src="/assets/imgs/hospital-blue.png" />
                                  <Text fontSize="0.75rem" fontWeight="500" className="textOverflow" w="150px" title={step.hospitalname? step.hospitalname:"-"}>{step.hospitalname? step.hospitalname:"-"}</Text>
                                </Box>
                              </Box>
                            </Box>
                            {step.notes &&
                            <Box border="1px solid #E6E7E9" mt="0.75rem" borderRadius="0.75rem" bg="#FCFCFD" p="0.75rem">
                              <Text fontSize="0.625rem" fontWeight="500" color="#384052">Notes</Text>
                              <Text fontSize="0.75rem" fontWeight="500" color="#061027">{step.notes?step.notes:"-"}</Text>
                            </Box>
                              }
                          </Box>
                        </>
                      }
                      cardFooterText="View appointment"
                      onClick={()=> navigate("/appointmentdetails", { state: { appointmentid: step.appointmentid }})}
                    />
                  ))
                }
              </SimpleGrid>
            ) : (
              <>
              <Box display="flex" flexDir="column" justifyContent="center" gap="1rem" alignItems="center" h="calc(100vh - 160px)" overflow="hidden auto">
                <Image w="3.5rem" opacity='0.5' src="/assets/svgs/appointments.svg" />
                <Text fontSize="1rem" fontWeight="700" color="#6A707D">No appointments</Text>
                <PrimaryButton buttonText="Book appointment" btnStyle={{w: "fit-content"}} onClick={()=> navigate("/appointments/doctors")} />
                </Box>
              </>
            )}

            </Box>
          {/* Appointments end */}
        </Box>
      </Box>
    )}
    </>
  );
};

export default AllAppointment;
