import React, { useEffect, useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { IoIosArrowDown } from 'react-icons/io';
import { styles,variantStyles } from './style';
import { inputDesign } from "../styles/theme";

const MiniDropdownInput = ({ options = [], onSelect,variant,placeholderProp,
  inputVal,handleInputChange, defaultValue, defaultOptionProp,dropdownStyle,inputContainer, ...props }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [inputValue, setInputValue] = useState('');
  const variantStyle = variantStyles[variant] || variantStyles.defaultSide;

  useEffect(() => {
    // Set the default value initially
    const defaultOption = options.find(option => option.value === defaultOptionProp);
    if (defaultOption) {
      setSelectedOption(defaultOption);
    }
  }, [options, defaultOptionProp]);

  const handleMenuItemClick = (option) => {
    setSelectedOption(option);
    onSelect(option);
  };

  

  return (
    <FormControl sx={inputDesign.formStyles.container} {...inputContainer}  zIndex="99"> 
    <InputGroup>
      <Input
        placeholder=" "
        sx={{ ...styles,...variantStyle, ...inputDesign.formStyles.input }}
        value={inputVal}
        defaultValue={defaultValue}
        onChange={handleInputChange}
        {...props}
        pr="50px"
        textOverflow="ellipsis"
      />
      <FormLabel sx={inputDesign.formStyles.label}>{placeholderProp}</FormLabel>
      <InputRightElement width="4.5rem">
        <Menu>
          <MenuButton as={Button} bg="none" size="sm" top="5px" color="#1C4EC5" _hover={{ bg: 'none' }} _active={{ bg: 'none', borderColor: 'none' }} _focus={{ boxShadow: 'none' }} rightIcon={<IoIosArrowDown />} {...dropdownStyle}>
            {selectedOption.label}
          </MenuButton>
          <MenuList minWidth="75px" p="3px" bg="white">
            {options.map((option) => (
              <MenuItem key={option.value} onClick={() => handleMenuItemClick(option)}>
                {option.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </InputRightElement>
    </InputGroup>
    </FormControl>
  );
};

export default MiniDropdownInput;
