import  FetchData, { } from '../../client';
import formAPIConditions from '../../domains/conditions';
import formapimodalhealth from '../../domains/myhealth/myhealths';
import formapimodallabs from '../../domains/myhealthlabs/labs';
import labsReportViewFormatting from '../../domains/myhealthlabs/labsReportViewFormatting';
import formAPIVaccinations from '../../domains/vaccination';
import {getLoginInfo, getUserID}  from '../../../auth';
import axios from 'axios';
import { getToken } from '../../../auth';
import { CONFIG } from '../../../appconfig/appconfig';

const getVaccinations = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_getpatientvaccination", inputData);
       if (result && result.output && result.output.data && result.output.data == null) {
        
       var finalOutput = formAPIVaccinations(result.output.data)
        }else{
         
         finalOutput = result;
        }
    
    return finalOutput;

  } catch (error) {
    console.error('Error fetching getVaccinations data:', error);
    throw error;
  }
};

const getVaccineList = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo()
    const result = await FetchData("midoc_getdefaultdata", inputData);

    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};


const addVaccination = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_addpatientvaccination", inputData);
    
    return result;

  } catch (error) {
    console.error('Error fetching medical conditions data:', error);
    throw error;
  }
};


const deleteVacinations = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_deletepatientvaccination", inputData);
    
    return result;

  } catch (error) {
    console.error('Error in delete Vacinations data:', error);
    throw error;
  }
};


const editVaccinations = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_updatepatientvaccination", inputData);
    
    return result;

  } catch (error) {
    console.error('Error in delete vacinations data:', error);
    throw error;
  }
};
const FetchVaccinationAIPrompts = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo();
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_vaccinationgetprompts`, 
      inputData,
    {
      headers: headers,
    });
    
    return response.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchVaccinationAIAnswer = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo()
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_vaccinationdescribeprompts`, 
      inputData,
    {
      headers: headers,
    });
    
    return response.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
export {getVaccinations, getVaccineList, addVaccination, deleteVacinations,editVaccinations, FetchVaccinationAIPrompts, FetchVaccinationAIAnswer };
