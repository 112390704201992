import  FetchData, { } from '../../client';
import formAPIConditions from '../../domains/conditions';
import formapimodalhealth from '../../domains/myhealth/myhealths';
import formapimodallabs from '../../domains/myhealthlabs/labs';
import labsReportViewFormatting from '../../domains/myhealthlabs/labsReportViewFormatting';
import formAPISurgeries from '../../domains/surgeries';
import {getLoginInfo, getUserID}  from '../../../auth';
import axios from 'axios';
import { getToken } from '../../../auth';
import { CONFIG } from '../../../appconfig/appconfig';

const getSurgeries = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_getpatientprocedure", inputData);

       if (result && result.output && result.output.data && result.output.data == null) {
       var finalOutput = formAPISurgeries(result.output.data)
       }else{
        
        finalOutput = result;
       }
    
    return finalOutput;

  } catch (error) {
    console.error('Error fetching medical conditions data:', error);
    throw error;
  }
};


const addSurgeries = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_addpatientprocedure", inputData);
    
    return result;

  } catch (error) {
    console.error('Error fetching medical conditions data:', error);
    throw error;
  }
};

const editSurgeries = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_updatepatientprocedure", inputData);
    
    return result;

  } catch (error) {
    console.error('Error in delete medical conditions data:', error);
    throw error;
  }
};

const deleteSurgeries = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_deletepatientprocedure", inputData);
    
    return result;

  } catch (error) {
    console.error('Error in delete medical conditions data:', error);
    throw error;
  }
};
const FetchSurgeriesAIPrompts = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo();
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_surgicalgetprompts`, 
      inputData,
    {
      headers: headers,
    });
    
    return response.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchSurgeriesAIAnswer = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo()
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_surgicaldescribeprompts`, 
      inputData,
    {
      headers: headers,
    });
    
    return response.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
export {getSurgeries,addSurgeries ,editSurgeries, deleteSurgeries, FetchSurgeriesAIPrompts, FetchSurgeriesAIAnswer};
