import { Flex, VStack, Box, Image, Text, Spacer, Center } from "@chakra-ui/react";
import { NavLink } from 'react-router-dom'; 

function SideBar() {
    const linkStyle = {
        textDecoration: 'none',
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: "0 15px",
        fontSize: "14px",
        fontWeight: "600",
        height: "48px",
      };
    
      const activeLinkStyle = {
        ...linkStyle,
        border: '1px solid #E6E7E9',
        background: "white",
        borderRadius: "12px",
        color: '#1C4EC5',
  
      };

    return (
        <>
        <VStack minW="205px" h="100vh" bg="#FCFCFD" borderRight="1px solid #E6E7E9" p={6} spacing={8} align="stretch">
        <Box as="aside">
        <NavLink to="/myhealth" style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}>
          <Image boxSize="16px" objectFit="contain" src="/assets/imgs/home.png" alt="Home" />
          <Box as="span" fontSize="14px" ml="3">Overview</Box>
        </NavLink>
        <NavLink to="/vitals" style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}>
          <Image boxSize="16px" objectFit="contain" src="/assets/imgs/heart.png" alt="Home" />
          <Box as="span" fontSize="14px" ml="3">Vitals</Box>
        </NavLink>
        <NavLink to="/conditions" style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}>
          <Image boxSize="16px" objectFit="contain" src="/assets/imgs/conditions.png" alt="Home" />
          <Box as="span" fontSize="14px" ml="3">Conditions</Box>
        </NavLink>
        <NavLink to="/surgeries" style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}>
          <Image boxSize="16px" objectFit="contain" src="/assets/imgs/surgeries.png" alt="Home" />
          <Box as="span" fontSize="14px" ml="3">Surgeries</Box>
        </NavLink>
        <NavLink to="/medications" style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}>
          <Image boxSize="16px" objectFit="contain" src="/assets/imgs/medication.png" alt="Home" />
          <Box as="span" fontSize="14px" ml="3">Medication</Box>
        </NavLink>
        <NavLink to="/allergies" style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}>
          <Image boxSize="16px" objectFit="contain" src="/assets/imgs/allergy.png" alt="Home" />
          <Box as="span" fontSize="14px" ml="3">Allergies</Box>
        </NavLink>
        <NavLink to="/familyhistory" style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}>
          <Image boxSize="16px" objectFit="contain" src="/assets/imgs/family.png" alt="Home" />
          <Box as="span" fontSize="14px" ml="3">Family History</Box>
        </NavLink>
        <NavLink to="/vaccination" style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}>
          <Image boxSize="16px" objectFit="contain" src="/assets/imgs/syringe.png" alt="Home" />
          <Box as="span" fontSize="14px" ml="3">Vaccinations</Box>
        </NavLink>
        <NavLink to="/socialhistory" style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}>
          <Image boxSize="16px" objectFit="contain" src="/assets/imgs/social-history.png" alt="Home" />
          <Box as="span" fontSize="14px" ml="3">Social History</Box>
        </NavLink>
        </Box>
        
        
      </VStack>
        </>
    )
}

export default SideBar;