import  FetchData, { } from '../../client';
import formAPIConditions from '../../domains/conditions';
import formapimodalhealth from '../../domains/myhealth/myhealths';
import formapimodallabs from '../../domains/myhealthlabs/labs';
import labsReportViewFormatting from '../../domains/myhealthlabs/labsReportViewFormatting';
import {getLoginInfo, getUserID}  from '../../../auth';
import axios from 'axios';
import { getToken } from '../../../auth';
import { CONFIG } from '../../../appconfig/appconfig';

const getMedicalConditions = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_getmedicalcondition", inputData);
      if (result && result.output && result.output.data) {
      var finalOutput = formAPIConditions(result.output.data)
      }else{
        
       finalOutput = result;
      }
    
    return finalOutput;

  } catch (error) {
    console.error('Error fetching medical conditions data:', error);
    throw error;
  }
};

const addMedicalConditions = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_addmedicalcondition", inputData);
    
    return result;

  } catch (error) {
    console.error('Error fetching medical conditions data:', error);
    throw error;
  }
};
const EditCondition = async (inputData) => {
  try {
    
    // inputData.patientid = getLoginInfo();
    // inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_updatemedicalcondition", inputData);
    
    return result;

  } catch (error) {
    console.error('Error fetching medical conditions data:', error);
    throw error;
  }
};

const deleteMedicalConditions = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_deletemedicalcondition", inputData);
    
    return result;

  } catch (error) {
    console.error('Error in delete medical conditions data:', error);
    throw error;
  }
};
const FetchConditionAIPrompts = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo();
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_healthconditiongetprompts`, 
      inputData,
    {
      headers: headers,
    });
    
    return response.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchConditionAIAnswer = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo()
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_healthconditiondescribeprompts`, 
      inputData,
    {
      headers: headers,
    });
    
    return response.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
export {getMedicalConditions , addMedicalConditions, deleteMedicalConditions, EditCondition,FetchConditionAIPrompts,FetchConditionAIAnswer};
