import { useCallback } from 'react';
import { useToast, Box, CloseButton, Icon } from '@chakra-ui/react';
import { GrFormCheckmark } from "react-icons/gr";
import { FaExclamation } from "react-icons/fa6";
import { COLORS } from '../styles/colors';
import { iconStyleSuccess, iconBgStyleSuccess, iconStyleError, iconBgStyleError } from './style';

export const Toaster = () => {
  const toast = useToast();

  const showToast = useCallback((message, status) => {
    const IconComponent = status === 'success' ? GrFormCheckmark : FaExclamation;
    const currentIconStyle = status === 'success' ? iconStyleSuccess : iconStyleError;
    const currentIconBgStyle = status === 'success' ? iconBgStyleSuccess : iconBgStyleError;
    const toastStyle = {
      width: "345px",
      height: "64px",
      background: COLORS.TOASTR_BG,
      color: 'white',
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      padding: "12px",
      borderRadius: "35px",
      gap: "5px"
      
    };

    toast({
      duration: 6000,
      isClosable: true,
      position: 'bottom',
      render: ({ onClose }) => (
        <Box style={toastStyle}>
          <Box sx={currentIconStyle}><Icon as={IconComponent} sx={currentIconBgStyle} /></Box>
          <Box as="span" fontSize="12px">{message}</Box>
          <CloseButton onClick={onClose} />
        </Box>
      ),
    });
  }, [toast]);

  const showSuccessToast = (message) => showToast(message, 'success');
  const showErrorToast = (message) => showToast(message, 'error');

  return { showSuccessToast, showErrorToast };
};

export default Toaster;
