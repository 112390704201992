import React, { useState, useEffect,useRef } from "react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import { Box,Text, Flex, Image, Divider, SimpleGrid } from "@chakra-ui/react";
import Footer from "../../components/footer"
import { PrimaryButton, SearchBar } from "../../components";
import DoctorCard from "../../components/doctor-card";
import TabSection from "../../components/tabs";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { FetchAppointments } from "../../middleware/services/appointments";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ProfileSideBar from "./profileSidebar";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";




const ProfileAppointment = () => {
  var [patientid, setPatientid] = useState();
  var [activeuserid, setActiveUser] = useState();
  var [data, setData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [search, setSearch] = useState("");
  const [showhidesearch, ShowHideSearch] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    fetchAppointments();  
  }, []);
  const fetchAppointments = async () => {
    setData("")
    showLoader();
    try {
      const result = await FetchAppointments({
        patientid,
        activeuserid,
        type:"all",
      });
      if (result != null) {
        setData(result);
      } else {
        //alert("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };
  const formatTime = (timeString) => {
    if (!timeString) return "-";
    return moment(timeString, 'HH:mm:ss').format('HH:mm');
};


  return (
    <>
      <Box w="100%" display={{base: "none", lg: "block"}}>
        <Box top="0" zIndex="banner">
          <Navbar />

          {isLoading && <Loader />}
          <SecondaryNav
            sideBarWidth="250px"
            defaultHeader="Profile"
            defaultSecondayHeader="Appointments"
            defaultNavBody={
              <>
                <Box
                  display="flex"
                  gap="10px"
                  alignItems="center"
                  pr="25px"
                >
                 
                </Box>
              </>
            }
          />
        </Box>
        <Box h="calc(100vh - 140px)" overflow="hidden auto">
          <Flex>
            <Box p="0" width="250px">
              <ProfileSideBar />
            </Box>

            <Flex bg="#fcfcfd" flexDirection="column" gap="20px" w="100%" p="24px 32px">
            {data && data.length > 0 ? (
                <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} spacing="1rem">
                  {data
                    .filter(step => step && step.doctorname && step.doctorname.toLowerCase().includes(search.toLowerCase()))
                    .map((step, index) => (
                      <DoctorCard
                        key={index}
                        cardBody={
                          <>
                            <Box>
                              <Box display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                                <Box display='flex' gap="0.75rem">
                                  <Box boxSize="2.375rem" borderRadius='full' overflow="hidden">
                                    <Image src={step.doctorimage?step.imagepath+step.doctorimage:"/assets/imgs/no-image-icon.png"} />
                                  </Box>
                                  <Box>
                                    <Text fontSize="0.625rem" fontWeight="500" color="#384052" maxW="170px" className="textOverflow" title={step.specality? step.specality:"-"}>{step.specality? step.specality:"-"}</Text>
                                    <Text fontSize="0.875rem" className="textOverflow" maxW="170px" fontWeight="600" color="#061027" title={step.doctorname? step.doctorname:"-"}>{step.doctorname? step.doctorname:"-"}</Text>
                                  </Box>
                                </Box>
                                <Text fontSize="0.625rem" fontWeight="600" color={step.appointmentstatus === 1? "#FC8935" : "#384052"} borderColor={step.appointmentstatus === 1? "#FC89351A" : "#f5f5f6"} border="1px solid" p="0.35rem 0.5rem" borderRadius="full" bg={step.appointmentstatus === 1? "#fff3eb" : "#f5f5f6"}>{step.appointmentstatus === 1? "Upcoming": step.appointmentstatus === 2?"Cancelled": step.appointmentstatus === 3 ? "Completed":step.appointmentstatus === 4 ? "No show": step.appointmentstatus === 0 ? "Pending" :""}</Text>
                              </Box>

                              <Divider my="0.75rem" />
                              <Box display='flex' gap="1rem">
                                <Box>
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="12px" h="13px" src="/assets/imgs/calendar-blue.png" />
                                    <Text fontSize="0.75rem" className="textOverflow" w="70px" fontWeight="500">{formatDate(step.appointmentdate)}</Text>
                                  </Box>
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="13px" h="10px" src="/assets/imgs/images-blue.png" />
                                    <Text fontSize="0.75rem" className="textOverflow" w="70px" fontWeight="500">{step.appointmenttype_cd? step.appointmenttype_cd:"-"}</Text>
                                  </Box>
                                </Box>
                                <Box>
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="13px" h="13px" src="/assets/imgs/schedule-blue.png" />
                                    <Text fontSize="0.75rem" fontWeight="500">{formatTime(step.appointmenttime)}</Text>
                                  </Box>
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="14px" h="13px" src="/assets/imgs/hospital-blue.png" />
                                    <Text fontSize="0.75rem" className="textOverflow" w="100px" fontWeight="500">{step.hospitalname? step.hospitalname:"-"}</Text>
                                  </Box>
                                </Box>
                              </Box>
                              {step.notes &&
                              <Box border="1px solid #E6E7E9" mt="0.75rem" borderRadius="0.75rem" bg="#FCFCFD" p="0.75rem">
                                <Text fontSize="0.625rem" fontWeight="500" color="#384052">Notes</Text>
                                <Text fontSize="0.75rem" fontWeight="500" color="#061027">{step.notes?step.notes:"-"}</Text>
                              </Box>
                               }
                            </Box>
                          </>
                        }
                        cardFooterText="View appointment"
                        onClick={()=> navigate("/appointmentdetails", { state: { appointmentid: step.appointmentid }})}
                      />
                    ))
                  }
                </SimpleGrid>
              ) : (
                <>
                <Box display="flex" flexDir="column" justifyContent="center" alignItems="center" h="calc(100vh - 160px)" overflow="hidden auto">
                  <Image w="3.5rem" src="/assets/imgs/switch_account.png" />
                  <Text fontSize="1rem" fontWeight="700" color="#6A707D">No appointments</Text>
                  </Box>
                </>
              )}

            </Flex>
          </Flex>
          <Footer />
        </Box>
      </Box>

      {/* Mobile responsive start */}
      <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/profile')}
          header="Appointments"
        />

        <Flex bg="#fcfcfd" flexDir="column" p="1.5rem" h="calc(100vh - 200px)" overflow="hidden auto">
          {data && data.length > 0 ? (
            <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} spacing="1rem">
              {data
                .filter(step => step && step.doctorname && step.doctorname.toLowerCase().includes(search.toLowerCase()))
                .map((step, index) => (
                  <DoctorCard
                    key={index}
                    cardBody={
                      <>
                        <Box>
                          <Box display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                            <Box display='flex' gap="0.75rem">
                              <Box boxSize="2.375rem" borderRadius='full' overflow="hidden">
                                <Image src={step.doctorimage?step.imagepath+step.doctorimage:"/assets/imgs/no-image-icon.png"} />
                              </Box>
                              <Box>
                                <Text fontSize="0.625rem" fontWeight="500" color="#384052" maxW="170px" className="textOverflow" title={step.specality? step.specality:"-"}>{step.specality? step.specality:"-"}</Text>
                                <Text fontSize="0.875rem" className="textOverflow" maxW="170px" fontWeight="600" color="#061027" title={step.doctorname? step.doctorname:"-"}>{step.doctorname? step.doctorname:"-"}</Text>
                              </Box>
                            </Box>
                            <Text fontSize="0.625rem" fontWeight="600" color={step.appointmentstatus === 1? "#FC8935" : "#384052"} borderColor={step.appointmentstatus === 1? "#FC89351A" : "#f5f5f6"} border="1px solid" p="0.35rem 0.5rem" borderRadius="full" bg={step.appointmentstatus === 1? "#fff3eb" : "#f5f5f6"}>{step.appointmentstatus === 1? "Upcoming": step.appointmentstatus === 2?"Cancelled": step.appointmentstatus === 3 ? "Completed":step.appointmentstatus === 4 ? "No show": step.appointmentstatus === 0 ? "Pending" :""}</Text>
                          </Box>

                          <Divider my="0.75rem" />
                          <Box display='flex' gap="1rem">
                            <Box>
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="12px" h="13px" src="/assets/imgs/calendar-blue.png" />
                                <Text fontSize="0.75rem" className="textOverflow" w="70px" fontWeight="500">{formatDate(step.appointmentdate)}</Text>
                              </Box>
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="13px" h="10px" src="/assets/imgs/images-blue.png" />
                                <Text fontSize="0.75rem" className="textOverflow" w="70px" fontWeight="500">{step.appointmenttype_cd? step.appointmenttype_cd:"-"}</Text>
                              </Box>
                            </Box>
                            <Box>
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="13px" h="13px" src="/assets/imgs/schedule-blue.png" />
                                <Text fontSize="0.75rem" fontWeight="500">{formatTime(step.appointmenttime)}</Text>
                              </Box>
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="14px" h="13px" src="/assets/imgs/hospital-blue.png" />
                                <Text fontSize="0.75rem" className="textOverflow" w="100px" fontWeight="500">{step.hospitalname? step.hospitalname:"-"}</Text>
                              </Box>
                            </Box>
                          </Box>
                          {step.notes &&
                          <Box border="1px solid #E6E7E9" mt="0.75rem" borderRadius="0.75rem" bg="#FCFCFD" p="0.75rem">
                            <Text fontSize="0.625rem" fontWeight="500" color="#384052">Notes</Text>
                            <Text fontSize="0.75rem" fontWeight="500" color="#061027">{step.notes?step.notes:"-"}</Text>
                          </Box>
                            }
                        </Box>
                      </>
                    }
                    cardFooterText="View appointment"
                    onClick={()=> navigate("/appointmentdetails", { state: { appointmentid: step.appointmentid }})}
                  />
                ))
              }
            </SimpleGrid>
          ) : (
            <>
            <Box display="flex" flexDir="column" justifyContent="center" alignItems="center" h="calc(100vh - 160px)" overflow="hidden auto">
              <Image w="3.5rem" src="/assets/imgs/switch_account.png" />
              <Text fontSize="1rem" fontWeight="700" color="#6A707D">No appointments</Text>
              </Box>
            </>
          )}
      </Flex>
      </Box>
    </>
  );
};

export default ProfileAppointment;
