import { COLORS } from "../styles/colors";

export const styles = {
  borderRadius: "10px",
  width: "100%",
  // // background: COLORS.PRIMARY_GRADIENT_BLUE,
  // color: COLORS.PRIMARY_WHITE,
  // boxShadow: "none",
  // fontFamily: `"Plus Jakarta Sans", sans-serif"`,
  padding: "1.25rem",
  margin: "10px 0",
  cursor: "pointer",
  background: "#FCFCFD",
  boxShadow: "0px 1px 3px #E6E7E9",
  height: "3rem",
  _disabled: {
    bg: COLORS.BTN_DISABLE,
    color: COLORS.BTN_DISABLE_TEXT,
    cursor: "not-allowed",
  },
  _hover: {
    backgroundImage: "-webkit-linear-gradient(top, #FCFCFD, #E6E7E9)",
    backgroundImage: "-moz-linear-gradient(top, #FCFCFD, #E6E7E9)",
    backgroundImage: "-ms-linear-gradient(top, #FCFCFD, #E6E7E9)",
    backgroundImage: "-o-linear-gradient(top, #FCFCFD, #E6E7E9)",
    backgroundImage: "linear-gradient(to bottom, #FCFCFD, #E6E7E9)",
    // bg: COLORS.PRIMARY_COLOR,
  },
};

export const submitStyle = {
  background: COLORS.BTN_DISABLE,
  color: COLORS.BTN_DISABLE_TEXT,
  padding: "25px",
  margin: "0",
  opacity: "50%",
};

export const iconStyle = {
  position: "relative",
  right: "75px",
  width: "20px",
  height: "auto"
};

export const iconReset = {
  width: "24px",
  height: "auto"
};
