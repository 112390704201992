export default function formAPIdoctorsassessment({
    appointmentid,
    doctorimage,
    doctorname,
    notes,
    date,
    diagnosis,
    plan,
    specality,
    DATE,
    imagepath,
    doctorid

})
{
  return Object.freeze({
    appointmentid,
    doctorimage,
    doctorname,
    notes,
    date,
    diagnosis,
    plan,
    specality,
    DATE,
    imagepath,
    doctorid
  });
}