import { Box, Flex, Button, Image, Spacer, Text } from "@chakra-ui/react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import TabSection from "../../components/tabs";
import DynamicTable from "../../components/dynamic-table";
import { PrimaryButton, PrimaryButtonIcon, Toaster } from "../../components";
import ReportViewCard from "../../components/report-card";
import ModalRight from "../../components/modal-right";
import React, { useEffect, useRef, useState } from "react";
import DynamicModal from "../../components/dynamic-modal";
import FilterButton from "../../components/filter-button";
import { useNavigate } from "react-router-dom";
import {
  DeletePatientLabReport,
  FetchLabsData,
  FetchLabsDataForReportsView,
} from "../../middleware/services/myhealth/labs";
import { FetchImagingData } from "../../middleware/services/myhealth/imaging";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import moment from "moment";
import Footer from "../../components/footer";
import { downloadDocument } from "../../auth";
import { FetchMedicalRecords } from "../../middleware/services/myhealth";

function PatientImagingIndex() {
  const rightModal = React.useRef();
  const dynamicModal = React.useRef();
  const openCustomModal2 = React.useRef();

  const [type, setType] = useState("imaging");
  const [formattedData, setFormattedData] = useState("");
  const [formattedDataImaging, setFormattedDataImaging] = useState("");
  const [dataForTableImaging, setDataForTableImaging] = useState("");
  const [formattedDataForReportView, setFormattedDataForReportView] =
    useState(null);
  const [fetchError, setFetchError] = useState(false);
  const [activeTab, setActiveTab] = useState("TableViewTab");
  const [reportid, setReportid] = useState([]);
  const [headerName, setHeaderName] = useState("");
  const [reportName, setReportName] = useState("");
  const [documentDates, setDocumentDates] = useState([]);
  const [documentURLs, setDocumentURLs] = useState([]);
  const [documentUrlImaging, setDocumentUrlImaging] = useState("");
  const [reportDescription, setReportDescription] = useState("");
  const [reportDate, setReportDate] = useState("");

  const { showSuccessToast, showErrorToast } = Toaster();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  var navigate = useNavigate();

  const [uuid, setUuid] = useState("");

  useEffect(() => {
    const currentURL = window.location.href;
    const urlParams = new URLSearchParams(currentURL.split("?")[1]);
    const idValue = urlParams.get("id");
    const idType = urlParams.get("type");

    setUuid(idValue);
    setType(idType);
  }, []);

  const input = {
    uuid,
    type,
  };

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      try {
        const res = await FetchMedicalRecords(input);
        const imagingData = res.reportdata;
        setFormattedData(imagingData);
        const dataForReportsViewImaging = {
          rows: imagingData?.map((item) => ({
            reportname: item.reportname ? item.reportname : "-",
            reportid: item.reportid,
            documetURL: item.imagepath + item.documenturl,
            //  reportname: item.reportname,
            reportdescription: item.reportdescription,
            documentdate: item.documentdate,
            verifieddate: item.verifieddate,
            uniqueURLs: Array.from(
              new Set(
                imagingData
                  .filter((report) => report.reportid == item.reportid)
                  .map((report) => report.documenturl)
              )
            ),
          })),
        };
        setFormattedDataImaging(dataForReportsViewImaging);

        const dataForTable = {
          rows: imagingData.map((item) => ({
            header: item.reportname ? item.reportname : "-",
            values: [
              {
                resultvalue: item.resultvalue || "",
                documentdate: item.verifieddate || "-",
              },
            ],
          })),
        };
        console.log(dataForTable, " dataForTable imaging");
        setDataForTableImaging(dataForTable);

        const labsDataForReportView = await FetchLabsDataForReportsView(input);
        const dataForReportsView = {
          rows: labsDataForReportView?.map((item) => ({
            reportname: item.reportname ? item.reportname : "-",
            values: item.data.map(
              (value) =>
                `${value.documenturl || ""} ${value.documentdate || ""}`
            ),
            reportid: item.data.map((value) => value.reportid),
            documetURL: item.data.map(
              (value) => value.imagepath + value.documenturl
            ),
          })),
        };
        setFormattedDataForReportView(dataForReportsView);
      } catch (error) {
        console.log(error, " error in fetching  imaging data");
        setFetchError(true);
      }
      dismissLoader();
    };

    fetchData();
  }, [uuid, type]);

  const backFunction = () => {
    navigate("/patientmedicalrecord?id=" + uuid)
  };

  const handleTabClick = (index) => {
    showLoader();
    switch (index) {
      case 0:
        setActiveTab("TableViewTab");
        break;
      case 1:
        setActiveTab("ReportViewTab");
        break;
      default:
        break;
    }
    dismissLoader();
  };

  const openDeletePopup = (reportidArray, reportname, verifieddate) => () => {
    setReportid(reportidArray);
    setReportName(reportname);
    setVerifiedDate(verifieddate);
    dynamicModal.current.openModal();
  };

  const deletePatientReports = async () => {
    showLoader();
    try {
      const reportIds = Array.isArray(reportid) ? reportid : [reportid];

      for (const id of reportIds) {
        const inputForDelete = {
          reportid: [{ id }],
        };
        var result = await DeletePatientLabReport(inputForDelete);
        showSuccessToast("Deleted Reports Successfully");

        const imagingData = await FetchImagingData(input);
        setFormattedData(imagingData);

        const dataForReportsViewImaging = {
          rows: imagingData?.map((item) => ({
            reportname: item.reportname ? item.reportname : "-",
            reportid: item.reportid,
            documetURL: item.imagepath + item.documenturl,
            //  reportname: item.reportname,
            reportdescription: item.reportdescription,
            documentdate: item.documentdate,
            verifieddate: item.verifieddate,
            uniqueURLs: Array.from(
              new Set(
                imagingData
                  .filter((report) => report.reportid == item.reportid)
                  .map((report) => report.documenturl)
              )
            ),
          })),
        };
        if (
          dataForReportsViewImaging &&
          dataForReportsViewImaging.rows !== undefined
        ) {
          setFormattedDataImaging(dataForReportsViewImaging);
        } else {
          setFormattedDataImaging("");
        }

        const dataForTable = {
          rows: Array.isArray(imagingData)
            ? imagingData.map((item) => ({
                header: item.reportname ? item.reportname : "-",
                // values: Array.isArray(item.data)
                //   ? item.data.map((value) => ({
                //       resultvalue: value.resultvalue || "",
                //       documentdate: value.documentdate || "",
                //     }))
                //   : [],
                values: [
                  {
                    resultvalue: item.resultvalue || "",
                    documentdate: item.verifieddate || "-",
                  },
                ],
              }))
            : [],
        };

        if (dataForTable && dataForTable.rows !== undefined) {
          setDataForTableImaging(dataForTable);
        } else {
          setDataForTableImaging("");
        }
      }
    } catch (error) {
      console.error(`Error deleting report with reportid: ${reportid}`, error);
    }
    dynamicModal.current.closeModal();
    rightModal.current.closeRight();
    openCustomModal2.current.closeRight();
    dismissLoader();
  };

  const openModalRightWithData =
    (header, values, documentURL, reportname) => () => {
      setHeaderName(header);
      setDocumentDates(values);
      setDocumentURLs(documentURL);
      setReportName(reportname);
      rightModal.current.openRight();
    };
  const [verifiedDate, setVerifiedDate] = useState("");

  const openModalRightImaging =
    (
      description,
      documentURL,
      reportname,
      reportdate,
      reportid,
      verifieddate,
      documentdate
    ) =>
    () => {
      // alert(documentdate)
      setDocumentUrlImaging(documentURL);
      setReportName(reportname);
      setReportDescription(description);
      setReportDate(reportdate);
      setReportid(reportid);
      setVerifiedDate(verifieddate);
      openCustomModal2.current.openRight();
    };

  const fileInputRef = useRef(null);
  const handleImagingUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    showLoader();
    navigate("/imagingupload", { state: { file } });
    dismissLoader();
  };
  const openUploadImaging = () => {
    fileInputRef.current.click();
  };
  const formatDate = (dateString) => {
    return moment(dateString).format("Do MMM, YYYY");
  };

  const downloadUrlImaging = () => {
    showLoader()
    downloadDocument(documentUrlImaging)
    setTimeout(() => {
      dismissLoader()
    }, 1000);
  }

  return (
    <Box w="100%">
      <Box top="0">
        <Box
          top="0"
          display="flex"
          zIndex="banner"
          as="nav"
          w="100%"
          p="10px"
          alignItems="center"
          justifyContent="center"
          wrap="wrap"
          bg="#061027"
        >
          <Image src="/assets/imgs/midoc-logo.png" />
        </Box>

        {isLoading && <Loader />}
        <SecondaryNav
          withBack="Imaging"
          goBack={backFunction}
          secondaryNavStyle={{display: {base: "none",sm: "none", md: "flex", lg: "flex"}}}
          withBackBody={
            <>
              <Box display="flex" gap="10px" alignItems="center" pr="25px">
                <TabSection
                  tabs={["Table view", "Reports view"]}
                  onClickTab={handleTabClick}
                />
                
              </Box>
            </>
          }
        />

        {/* for mobile navbar */}
        <Box display={{sm: "flex", md: "none", lg: "none"}} flexWrap="wrap" w="100%" bg="white" minH="70px" gap="0.5rem" textAlign="center" alignItems="center" justifyContent="space-between" p="1rem">
          <Box display="flex" justifyContent="space-between" alignItems="center" w="100%" mb="1rem">
            <Box w="40%" textAlign="left">
              <Box as="button" onClick={backFunction}><Image w="15px" mr="5px" src="/assets/imgs/arrow_back.png"/></Box>
            </Box>
            <Box w="60%" textAlign="left">
              <Text as="h1" color="black" fontSize="20px">Imaging</Text>
            </Box>
          </Box>
          <TabSection
            tabs={["Table view", "Reports view"]}
            onClickTab={handleTabClick}
          />
        </Box>
      </Box>
      <Box>
        <Flex
          bg="#fcfcfd"
          flexDirection="column"
          overflow="hidden auto"
          height="calc(100vh - 175px)"
        >
          {activeTab === "TableViewTab"
            ? formattedData !== null && (
                <DynamicTable TableData={dataForTableImaging} />
              )
            : formattedDataImaging !== null && (
                <Flex
                  wrap="wrap"
                  p="20px"
                  w="100%"
                  justifyContent="flex-start"
                  gap="20px"
                >
                  {formattedDataImaging &&
                    (formattedDataImaging.rows != undefined || null) &&
                    formattedDataImaging.rows.map((row, index) => (
                      <Box
                        key={index}
                        flex={{base: "100%",sm: "100%", md: "0 0 calc(33.333% - 20px)", lg: "0 0 calc(33.333% - 20px)"}}
                        mb="20px"
                      >
                        <ReportViewCard
                          cardHeader={row.reportname}
                          cardSecondary={formatDate(row.verifieddate)}
                          cardBtn={
                            row.uniqueURLs.length < 2
                              ? row.uniqueURLs.length + " document"
                              : row.uniqueURLs.length + " documents"
                          }
                          cardText={row.reportdescription}
                          onClick={openModalRightImaging(
                            row.reportdescription,
                            row.documetURL,
                            row.reportname,
                            row.documentdate,
                            row.reportid,
                            row.verifieddate,
                            row.documentdate
                          )}
                          deleteBtn={false}
                        />
                      </Box>
                    ))}
                </Flex>
              )}
          {!formattedData && (
            <Box
              w="100%"
              h="100vh"
              display="flex"
              flexDirection="column"
              gap="5px"
              justifyContent="center"
              alignItems="center"
            >
              <Image w="60px" src="/assets/svgs/hand_bones.svg" />
              <Box as="p">No data available</Box>
            </Box>
          )}
        </Flex>
        <Footer />
      </Box>
      <ModalRight
        ref={rightModal}
        secondaryHeader="Hemoglobin"
        subHeader="10th Jan, 2023"
        body={
          <>
            <Flex pt="15px" flexDirection="column" h="100%">
              <Box as="p" pb="20px" fontSize="14px" fontWeight="500">
                Lumbar Spine MRI indicates mild disc degeneration at the L4-L5
                level, contributing to occasional lower back discomfort.
              </Box>
              <Box>
                <Button
                  w="100%"
                  h="48px"
                  borderRadius="10px"
                  border="1px solid #E6E7E9"
                  display="flex"
                  justifyContent="space-between"
                  bg="white"
                  color="#1C4EC5"
                  sx={{ _hover: { bg: "none" } }}
                >
                  12/01/24_Report.pdf
                  <Box as="span">
                    <Image src="/assets/imgs/download.png" />
                  </Box>
                </Button>
              </Box>
              <Spacer />
              <Box>
                <PrimaryButtonIcon
                  buttonText="Delete"
                  //  onClick={deletePatientReports}
                  color="#AA2E26"
                />
              </Box>
            </Flex>
          </>
        }
      />

      <DynamicModal
        ref={dynamicModal}
        modalHeader="Delete report?"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete <strong>"{reportName}"</strong> from{" "}
              {formatDate(verifiedDate)}
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deletePatientReports}
            />
          </>
        }
      />

      <ModalRight
        ref={openCustomModal2}
        secondaryHeader={reportName}
        subHeader="10th Jan, 2023"
        body={
          <Flex pt="15px" flexDirection="column" h="100%">
            <Box mb="2">
              <Button
                as="a"
                w="100%"
                h="48px"
                borderRadius="10px"
                border="1px solid #E6E7E9"
                display="flex"
                justifyContent="space-between"
                bg="white"
                color="#1C4EC5"
                sx={{ _hover: { bg: "none" } }}
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  fontSize: "12px",
                }}
                onClick={() => downloadDocument(documentUrlImaging)}
                cursor="pointer"
              >
                {reportName}
                <Box as="span">
                  <Image src="/assets/imgs/download.png" />
                </Box>
              </Button>
            </Box>

            <Spacer />
            <Box>
              <PrimaryButtonIcon
                buttonText="Delete"
                onClick={deletePatientReports}
                color="#AA2E26"
              />
            </Box>
          </Flex>
        }
      />
      <ModalRight
        ref={openCustomModal2}
        secondaryHeader={reportName}
        subHeader={formatDate(verifiedDate)}
        body={
          <Flex pt="15px" flexDirection="column" h="100%">
            <Box as="p" pb="20px" fontSize="14px" fontWeight="500">
              {reportDescription}{" "}
            </Box>
            <Box mb="2">
              <Button
                as="a"
                w="100%"
                h="48px"
                borderRadius="10px"
                border="1px solid #E6E7E9"
                display="flex"
                justifyContent="space-between"
                bg="white"
                color="#1C4EC5"
                sx={{ _hover: { bg: "none" } }}
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  fontSize: "12px",
                }}
                onClick={downloadUrlImaging}
                cursor="pointer"
              >
                <Box
                  as="span"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  w="80%"
                >
                  {reportName}
                  <br />
                  <p style={{ color: "black", fontSize: "11px" }}>
                    {formatDate(verifiedDate)}
                  </p>
                </Box>
                <Box as="span">
                  <Image src="/assets/imgs/download.png" />
                </Box>
              </Button>
            </Box>
          </Flex>
        }
      />
    </Box>
  );
}

export default PatientImagingIndex;
