import moment from "moment";
export default function formAPIMedication({
  doctorname,
  documentcount,
  documents,
  dosage,
  dosageunit,
  frequency,
  id,
  imagepath,
  iscurrentmedicine,
  medicationenddate,
  medicationstartdate,
  medicationstatus_cd,
  medicineid,
  medicinename,
  medicinetype,
  medicineunit,
  patientid,
  prescribedby,
  prescribeddate,
  prescribedhospitalname,
  specialization,
  bookingid

})
{
  return Object.freeze({
    doctorname,
    documentcount,
    documents,
    dosage,
    dosageunit,
    frequency,
    id,
    imagepath,
    iscurrentmedicine,
    medicationenddate,
    medicationstartdate,
    medicationstatus_cd,
    medicineid,
    medicinename,
    medicinetype,
    medicineunit,
    patientid,
    prescribedby,
    prescribeddate,
    prescribedhospitalname,
    specialization,
    bookingid
  });
}