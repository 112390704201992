import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../components/navbar";
import SideBar from "../../components/sidebar";
import SecondaryNav from "../../components/seconday-nav";
import { Box, Divider, Flex, Text } from "@chakra-ui/layout";
import {
  Image,
  Button,
  Spacer,
  Center,
  VStack,
  Checkbox,
  Input,
  Stack,
  Radio,
  RadioGroup,
  CheckboxGroup,
  InputRightElement,
  InputGroup,
  List,
  ListItem,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
  SimpleGrid,
  Switch
} from "@chakra-ui/react";
import {
  DropdownInput,
  PrimaryButton,
  PrimaryButtonIcon,
  PrimaryInput,
  SearchBar,
  Toaster,
} from "../../components";
import DynamicModal from "../../components/dynamic-modal";
import ModalRight from "../../components/modal-right";
import {
  FetchMedicationsData,
  AddMedicine,
  EditMedicine,
  GetMedicineName,
  DeleteMedicine,
  GetAIMedicineComposition
} from "../../middleware/services/myhealth/medications";
import ReportViewCard from "../../components/report-card";
import moment from "moment";
import TabSection from "../../components/tabs";
import FilterButton from "../../components/filter-button";
import MiniDropdownInput from "../../components/mini-dropdown";
import { COLORS } from "../../components/styles/colors";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { uploadImageInProfile } from "../../middleware/services/profile/account";
import { downloadDocument, getDob, getusername, getgender, useScreenType, handleAlphaNumericDotAndSpace, validationOfNumberWithOnly1Decimal, handleAlphaDotAndSpace, displayMillion_ThoudandValues } from "../../auth";
import DatePicker from "../../components/date-picker";
import { format } from 'date-fns';
import { inputDesign } from "../../components/styles/theme";
import Footer from "../../components/footer";
import { m } from "framer-motion";
import { FetchTreatmentTeam } from "../../middleware/services/home";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { useNavigate } from "react-router-dom";
import { FetchMyHealthAIData} from "../../middleware/services/home";

import {FetchMedicineAIPrompts, FetchMedicineAIAnswer } from "../../middleware/services/home";
import TypingLoader from "../../components/typing-loader";
import AiModal from '../../components/ai-modal/index';
import DatePickerMonthYear from "../../components/date-monthyear";
import { FetchTokens } from "../../middleware/services/profile/rewards";




const MedicationsIndex = () => {
  var [data, setData] = useState(null);
  var [patientid, setPatientid] = useState();
  var [loginid, setLoginid] = useState();
  const { showSuccessToast, showErrorToast } = Toaster();
  const detailsmodel = React.useRef();
  const showmedicinename = React.useRef();
  const addmedications = React.useRef();
  const editmedications = React.useRef();
  const dynamicModal = React.useRef();
  const learnMoreModal = React.useRef();
  const AIChatModel = useRef();
  const navigate = useNavigate();
  var [id, setID] = useState();
  var [title, setTitle] = useState();
  // const [fromdate, setFromDate] = useState(moment().format("DD-MM-yyyy"));
  // const [todate, setToDate] = useState(moment().format("DD-MM-yyyy"));
  
  const [selectedImage, setSelectedImage] = useState(null);
  const today = moment().format("YYYY-MM-DD HH:mm:ss.SSS");
  const [prescribedby, setPrescribedBy] = useState(null);
  const [hospitalname, setHospitalname] = useState(null);
  const [medicinename, setMedicineName] = useState("");
  const [Composition, setComposition] = useState("");
  const [medicinedata, setMedicinedata] = useState(null);
  //const [medicineenddata, setMedicineEndDate] = useState('');
  const [dosage, setMedicineDosage] = useState(null);
  const [dosageunit, setMedicineDosageUnit] = useState("mg");
  const [frequency, setMedicineDosageFrequency] = useState("");
  const [medicinetype, setMedicineType] = useState("Tablet");
  const [morning, setMorning] = useState("0");
  const [setevening, setEvening] = useState("0");
  const [setnight, setNight] = useState("0");
  const [beforefood, setBeforefood] = useState("0");
  const [afterfood, setAfterfood] = useState("0");
  const [filenameArray, setFilenameArray] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showhidesearch, ShowHideSearch] = useState(false);
  const [medicineData, setMedicineData] = useState([])
  const [medicineTypeRadio, setMedicineTypeRadio] = useState("Tablet")
  const [medicinetypeFilter, setMedicineTypeFilter] = useState("All")
  const [filenameforUpload, setFilenameforUpload] = useState([])
  const [daysDiffrence, setDaysDiffrence] = useState("")
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [activeTab, setActiveTab] = useState(0);
  const [minDateValue, setMinDateValue] = useState("");
  const [benifit, setBenifit] = useState('')
  const [sideEffects, setSideEffects] = useState('')
  const [medicineexpirydate, setMedicineExpiryDate] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [treatmentteamdata, setTreatmentTeamData] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState({});
  const [boolValForSuggestions , setBoolValForSuggestions] = useState(true)
  const [isChecked , setIsChecked] = useState(0)
  const chatContainerRef = useRef(null);
  const [aianswers, setAIAnswers] = useState([]);
  const [remainingquestions, setRemainingQuestions] = useState([]);
  const [aiquestion, setAIAnalysis] = useState(null);
  const [AiInput, setAiInput] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [animate, setAnimate] = useState(false);
  
  const [  boolCheck , setBoolCheck ] = useState(false);
  const [  Medicinealert , setMedicinealert ] = useState(false);

  const [  initialPageLoad , setInitialPageLoad ] = useState(false)

  const [showDocNamesDropdown , setShowDocNamesDropdown] = useState(true)
  const [showAIModal , setShowAIModal] = useState(true)
  
  const [upgradePlanModal , setUpgradePlanModal] = useState(false)
  const screenType = useScreenType()
  const [remainingTokens, setRemainingTokens] = useState();

  useEffect(() => {
    getTokens();
  }, [])
  var tokensinput = {}
  var getTokens = async () => {
    try {
      var result = await FetchTokens(tokensinput);
      if (result) {
        setRemainingTokens(result)
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.log(error, "error in fetching account data")
    }
    //dismissLoader();
  }
  useEffect(() => {
    fetchData("All", 1);
    fetchTreatmentTeam();
  }, []);

  useEffect(() => {
    if (data) {
      const filteredData = data.filter((step) =>
        step.medicinename && step.medicinename.toLowerCase().includes(searchValue.toLowerCase())
      );
      
      setFilteredData(filteredData)
    }
  }, [searchValue, data])
  useEffect(()=>{
    setBoolValForSuggestions(!boolValForSuggestions)
  },[suggestions])
  const fetchData = async (type, tab) => {
    showLoader();
    setData("");
    try {
      const result = await FetchMedicationsData({
        patientid,
        medicinetype: type,
        // iscurrent: tab,
      });
      //console.log(result, "modified medications res");
      if (result != null) {
        setData(result);
      } else {
        //alert("No data");
      }
      setTimeout(() => {
        
        dismissLoader();
      }, 1000);
    } catch (error) {
      console.error("Error in DashboardIndex:", error);
    }
  };
  const fetchTreatmentTeam = async () => {
    try {
      const result = await FetchTreatmentTeam({
        patientid,
      });
      if (result != null) {
        setTreatmentTeamData(result);
        
      } else {
        //alert("No data");
      }
      setTimeout(() => {
        
        dismissLoader();
      }, 1000);
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
let patientgender = getgender();
let gender = patientgender;
if (gender === 1) {
  gender = "Female";
} else if (gender === 2) {
  gender = "Male";
} else if (gender === 3) {
  gender = "Others";
}else{
  gender = "Male";
}
  const dob = getDob();
  const patientname = getusername();

  const age = calculateAge(dob);
  function calculateAge(dateOfBirth) {
    const today = new Date();
    const dob = new Date(dateOfBirth);
    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }
    return age;
  }
  const handlemedicinetime = (values) => {
    if (values.includes("morning")) {
      setMorning(1);
    } else {
      setMorning(0);
    }
  
    if (values.includes("evening")) {
      setEvening(1);
    } else {
      setEvening(0);
    }
  
    if (values.includes("night")) {
      setNight(1);
    } else {
      setNight(0);
    }
  };
  const handlemedicinetimebeforeafter = (values) => {
    if (values.includes("beforefood")) {
      setBeforefood(1);
    } else {
      setBeforefood(0);
    }
  
    if (values.includes("afterfood")) {
      setAfterfood(1);
    } else {
      setAfterfood(0);
    }
  };

  const filterSurgeonNames = (surgeonName) => {
    const filtered = treatmentteamdata && treatmentteamdata.filter((surgeon) =>
      surgeon.doctorname?.toLowerCase().includes(surgeonName.toLowerCase())
    );
    setSuggestions(filtered);
  };

  const handleSurgeonNameChange = (surgeonName) => {
    let formattedValue = surgeonName.replace(/[^0-9a-zA-Z\s.]/g, "")
    setPrescribedBy(formattedValue);

    // Filter surgeon names based on input
    if (formattedValue) {
      filterSurgeonNames(formattedValue);
    } else {
      setSuggestions([]);
    }
    setPrescribedBy(formattedValue)
  };

  const handleSelect = (suggestion) => {
    setPrescribedBy(suggestion.doctorname);
    setSuggestions([]);
  };
  let input = {
    patientid: "",
    documenttype_cd: "",
    documentcategory_cd: "",
    documentname: "",
    documenturl: "",
    prescribedby: prescribedby,
    hospitalname: hospitalname,
    patientname: patientname,
    age: age,
    documentdate: today,
    medicinedetail: [
      {
        medicinename: medicinename,
        chemical_composition: Composition,
        medicinetype: medicinetype,
        dosage: dosage,
        dosageunit:dosageunit,
        // startdate: fromdate,
        // enddate: todate,
        frequency: frequency,
        morning: morning,
        afternoon: "",
        evening: setevening,
        night: setnight,
        beforefood: beforefood,
        afterfood: afterfood,
        medicineexpirydate: medicineexpirydate,
        iscurrentmedicine: isChecked,
        longtext: "",
        documents: filenameArray.map(filename => ({ filename }))
      },
    ],
  };

  const handleimageselection = async (e) => {
    e.preventDefault();
    setMedicineName("")
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(file);
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
    if (!allowedTypes.includes(file.type)) {
      showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG file.");
    dismissLoader();
      return false;
    }
    showLoader();
    const result = await GetMedicineName(file, file.name, file.type);
    if(result?.output?.message  == "No credits are available"){
     // showErrorToast("No sufficient tokens available");
      setUpgradePlanModal(true)
          setTimeout(() => {
            setUpgradePlanModal(false)
          }, 100);
    }else{
    setMedicineName(result.medicine_name);
    setComposition(result.chemical_composition)

     if(result.expiry_date !== "00-0000"){
      //const formatteddate = moment(result.expiry_date).format("DD-MM-yyyy")
      setMedicineExpiryDate(result.expiry_date);
     }
    setBenifit(result.benefit);
    setSideEffects(result.side_effects);
    showmedicinename.current.openRight();
    }
    
    
  e.target.value = '';
    dismissLoader();
  };


  const handlemedicinenamecontinue = () => {
    showmedicinename.current.closeRight();
    
  };

  // Add Medicine
  const addmedicine = async () => {
    // const parsedDate = moment(medicineexpirydate, "MM-yyyy");
    // const mfgMonth = parsedDate.month() +1;
    // const mfgYear = parsedDate.year();
    const currentMonth = moment().month() + 1;
    const currentYear = moment().year();

    const parsedDateExp = moment(medicineexpirydate, "MM-yyyy");
    const expMonth = parsedDateExp.month() +1;
    const expYear = parsedDateExp.year();
    
    showLoader();
    if (moment(input.medicinedetail[0].startdate, 'DD-MM-YYYY', true).isValid()) {
    input.medicinedetail[0].startdate = moment(input.medicinedetail[0].startdate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }
    if (moment(input.medicinedetail[0].enddate, 'DD-MM-YYYY', true).isValid()) {
    input.medicinedetail[0].enddate = moment(input.medicinedetail[0].enddate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }
    //console.log(input , " input to add medictiaon")
    if (medicinename == "") {
      showErrorToast("Please specify or upload any medicine");
      setTimeout(() => {
      dismissLoader();
      }, 1000);
      return false;
    }else if(medicineexpirydate && expYear == currentYear && expMonth < currentMonth){
      showErrorToast("Exp month cannot be less than current Month!");
      dismissLoader();
        return false;
    } else {
      const result = await AddMedicine(input);
      
      if (result) {
        if(showAIModal){
          await fetchAiDoctorPrompts(medicinename);
        }
        showSuccessToast("Medicine created Successfully");
        fetchData(medicinetypeFilter, activeTab === 1? "0": "1");
        addmedications.current.closeRight();
        
      }
    }
    dismissLoader();
  };
  
  const [documentURLForEdit, setDocumentURLForEdit] = useState([]);
  const handleDeleteDocInEdit = (index) => {
    const updatedUrls = [...documentURLForEdit];
    updatedUrls.splice(index, 1);
   // setUploadedDocuments(updatedUrls);
    
    setDocumentURLForEdit(updatedUrls);

    if (updatedUrls != null) {

      setFilenameforUpload(updatedUrls?.map(doc => doc.documentname));
    }
  };
  const handlechangemedicinealert = (item, e,showAIModal )=>{
    setShowAIModal(showAIModal)
    setInitialPageLoad(true)
    setID(item.id);
    setMedicineName(item.medicinename);
    setComposition(item.chemicalcomposition);
    setMedicineType(item.medicinetype);
    setMedicineDosage(item.dosage);
    setMedicineDosageUnit(item.dosageunit);
    setMedicineDosageFrequency(item.frequency);
    setMorning(item.morning);
    setEvening(item.evening);
    setNight(item.night);
    setBeforefood(item.beforefood);
    setAfterfood(item.afterfood);
    setPrescribedBy(item.prescribedby)
    setHospitalname(item.prescribedhospitalname)
    setIsChecked(item.iscurrentmedicine)
    setMaxDateStateEdit("")
    setMinDateStateEdit("")
    setMedicineExpiryDate(item.medicineexpirydate)
    if(e.target.checked){
      setMedicinealert(1);
      //editmedicine();
    }else{
      setMedicinealert(0);
      //editmedicine();
    }
  }
  useEffect(()=>{
    if(initialPageLoad)editmedicine()

  },[Medicinealert])
  // Update data
  const editmedicine = async () => {
    let input = {
      patientid: "",
      "id": id,
      documenttype_cd: "",
      documentcategory_cd: "",
      documentname: "",
      documenturl: "",
      prescribedby: prescribedby,
      hospitalname: hospitalname,
      patientname: patientname,
      age: age,
      documentdate: today,
      medicinename: medicinename,
      chemical_composition: Composition,
      medicinetype: medicinetype,
      dosage: dosage,
      dosageunit:dosageunit,
      // startdate: fromdate,
      // enddate: todate,
      frequency: frequency,
      morning: morning,
      afternoon: "",
      evening: setevening,
      night: setnight,
      beforefood: beforefood,
      afterfood: afterfood,
      medicineexpirydate: medicineexpirydate,
      iscurrentmedicine: isChecked,
      longtext: "",
      isalert:Medicinealert,
      documents: filenameforUpload.map(filename => ({ filename }))

    };
    if (moment(input.startdate, 'DD-MM-YYYY', true).isValid()) {
      input.startdate = moment(input.startdate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }
    if (moment(input.enddate, 'DD-MM-YYYY', true).isValid()) {
      input.enddate = moment(input.enddate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }
   
    const currentMonth = moment().month() + 1;
    const currentYear = moment().year();

    const parsedDateExp = moment(medicineexpirydate, "MM-yyyy");
    const expMonth = parsedDateExp.month() +1;
    const expYear = parsedDateExp.year();
   //console.log(input,"Edit input")
    if (medicinename == "") {
      showErrorToast("Please specify or upload any medicine");
      return false;
    }
    else if(medicineexpirydate && expYear == currentYear && expMonth < currentMonth){
      showErrorToast("Exp month cannot be less than current Month!");
      dismissLoader();
        return false;
    } else {
     
      showLoader();
      const result = await EditMedicine(input);
      
      if (result) {
        if(showAIModal){
        await fetchAiDoctorPrompts(input.medicinename);
        }
        showSuccessToast("Medicine updated Successfully");
        fetchData(medicinetypeFilter, activeTab === 1? "0": "1");
        editmedications.current.closeRight();
        detailsmodel.current.closeRight();
        
      }
      setTimeout(() => {
        
      dismissLoader();
      }, 2000);
    }
  };
  const opendetailsmodel = (medicine) => {
    //console.log(medicine , "medicine ")
    setFilenameforUpload([])
    setMedicinedata("");
    setMedicineData([])
    // const medicationEndDate = moment(medicine.medicationenddate);
    //   const today = moment();
    //   const differenceInDays = medicationEndDate.diff(today, 'days');
    setMedicinedata(medicine);
    
    setDocumentURLForEdit(medicine.documents)

    var enddate = moment(medicine.medicationenddate);
    var startdate = moment(medicine.medicationstartdate);
    var daysDiffrence = enddate.diff(startdate  ,"days" )
    var totalDays = daysDiffrence + 1;
    if(totalDays <= 1 ) {
    setDaysDiffrence("1 day")
    }else {
      setDaysDiffrence(totalDays + " days")
    }
    setMedicineData(medicine.documents)
    if (medicine.documents) {

      const documentNames = medicine.documents.map(doc => doc.documentname);
      setFilenameforUpload(documentNames);
    }

    detailsmodel.current.openRight();
  };

  const setDetailsOfEdit = (item, e, showAIModal) => {
    
    setShowAIModal(showAIModal)
    setInitialPageLoad(true)
    setID(item.id);
    setMedicineName(item.medicinename);
    setComposition(item.chemicalcomposition);
    setMedicineType(item.medicinetype);
    setMedicineDosage(item.dosage);
    setMedicineDosageUnit(item.dosageunit);
    // const stratdate =  moment(item.medicationstartdate, 'YYYY-MM-DD').format('DD-MM-yyyy');
    // const enddate =  moment(item.medicationenddate, 'YYYY-MM-DD').format('DD-MM-yyyy');
    // setFromDate(stratdate)
    // setToDate(enddate);
    setMedicineDosageFrequency(item.frequency);
    setMorning(item.morning);
    setEvening(item.evening);
    setNight(item.night);
    setBeforefood(item.beforefood);
    setAfterfood(item.afterfood);
    setPrescribedBy(item.prescribedby)
    setHospitalname(item.prescribedhospitalname)
    setIsChecked(item.iscurrentmedicine)
    setMaxDateStateEdit("")
    setMinDateStateEdit("")
    setMedicineExpiryDate(item.medicineexpirydate)

      if(e.target.checked){
        setIsChecked(1)
        setBoolCheck(!boolCheck)
        
      }else{
        setIsChecked(0)
        setBoolCheck(!boolCheck)
      }
   // detailsmodel.current.openRight();
  };

  useEffect(()=>{
    if(initialPageLoad)editmedicine()

  },[boolCheck])

  const handleChangeDosageUnit = (selectedOption) => {
    setMedicineDosageUnit(selectedOption.label);
  };
  const handleconsumptionfrequencychange = (selectedOption) => {
    if(selectedOption == "1"){
      setMedicineDosageFrequency("1");
    }else  if(selectedOption == "2"){
      setMedicineDosageFrequency("2");
    }else  if(selectedOption == "3"){
      setMedicineDosageFrequency("3");
    }else  if(selectedOption == "4"){
      setMedicineDosageFrequency("4");
    }
  };
  const handlemedicinetypechange = (event) => {

    setMedicineType(event);
    if(event == "Tablet"){
      setMedicineDosageUnit("mg");
    }else if (event == "Syrup"){
      
      setMedicineDosageUnit("ml");
    }
  };
  const handlemedicinename = (event) => {
    setMedicineName(event.target.value);
  };
  const handletabswitch = (index) => { 
   
      switch (index) {
        case 0:
          setActiveTab(0);
          fetchData(medicinetypeFilter,1);
          break;
        case 1:
          setActiveTab(1);
          fetchData(medicinetypeFilter,0);
          break;     
        default:
          break;
      }
  };
  const handlefilterswitch = (label) => {
    if (label === "Tablet") {
      fetchData("Tablet", activeTab === 1? "0": "1");
      setMedicineTypeRadio("Tablet")
      setMedicineType("Tablet")
      setMedicineDosageUnit("mg")
      setMedicineTypeFilter("Tablet")
    } else if (label === "Syrup") {
      fetchData("Syrup",activeTab === 1? "0": "1");
      setMedicineTypeRadio("Syrup")
      setMedicineType("Syrup")
      setMedicineTypeFilter("Syrup")
      setMedicineDosageUnit("ml")
    } else {
      fetchData("All",activeTab === 1? "0": "1");
      setMedicineTypeRadio("Tablet")
      setMedicineType("Tablet")
      setMedicineTypeFilter("All")
      setMedicineDosageUnit("mg")
    }
  };
  const SearchIconClicked = () => {
    ShowHideSearch(true);
  };
  const DeleteMedicineModel = (title, id) => {
    setID(id);
    setTitle(title);
    dynamicModal.current.openModal();
  };
  const deletemedicine = async () => {
    const result = await DeleteMedicine({
      loginid,
      id: id,
    });
    if (result) {
      showSuccessToast("Medicine deleted successfully");
      fetchData(medicinetypeFilter,activeTab === 1? "0": "1");
      dynamicModal.current.closeModal();
      detailsmodel.current.closeRight();
    }
  };

const handleeditmedication = (item)=>{

  setID(item.id);
  setMedicineName(item.medicinename);
  setComposition(item.chemicalcomposition);
  setMedicineType(item.medicinetype);
  setMedicineDosage(item.dosage);
  setMedicineDosageUnit(item.dosageunit);
  // const stratdate =  moment(item.medicationstartdate, 'YYYY-MM-DD').format('DD-MM-yyyy');
  // const enddate =  moment(item.medicationenddate, 'YYYY-MM-DD').format('DD-MM-yyyy');
  // setFromDate(stratdate)
  // setToDate(enddate);

  setMedicineDosageFrequency(item.frequency);
  setMorning(item.morning);
  setEvening(item.evening);
  setNight(item.night);
  setBeforefood(item.beforefood);
  setAfterfood(item.afterfood);
  setPrescribedBy(item.prescribedby)
  setHospitalname(item.prescribedhospitalname)
  setIsChecked(item.iscurrentmedicine)
  setMaxDateStateEdit("")
  setMinDateStateEdit("")
  setMedicineExpiryDate(item.medicineexpirydate)
  setShowDocNamesDropdown(false)
  setShowAIModal(true)
  editmedications.current.openRight();
}

  const noDataStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
  const addData = () => {
    setFilenameArray([]);
    setMedicineName("") 
    setComposition("") 
    // setFromDate(moment().format("DD-MM-yyyy"))
    // setToDate(moment().format("DD-MM-yyyy"))
    setHospitalname(null)
    setPrescribedBy(null)
    //setMedicineType("Tablet")
    //setMedicineDosageUnit("mg")
    setMedicineDosage(null)
    setMedicineDosageFrequency("")
    setMorning("0")
    setEvening("0")
    setNight("0")
    setComposition("")
    setMedicineExpiryDate("")
    setShowAIModal(true)
    addmedications.current.openRight()
    setShowDocNamesDropdown(false)
   
  }


  const fileInputRef = useRef(null);
  const fileInputRefUpdate = useRef(null);
  const handleMedicationUpload = async (e) => {
    e.preventDefault();
    showLoader();

    const file2 = e.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
    if (!allowedTypes.includes(file2.type)) {
      showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG file.");
      setTimeout(() => {
        
        dismissLoader();
      }, 1000);
      return false;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file2);
    //console.log(file2 , "File")
    const result = await uploadImageInProfile(file2, file2.name);
    //console.log(result , "result.name ");
    const filename = result.name;
    setFilenameArray(prev => [...prev, filename]);
    
    e.target.value = ''; 
    dismissLoader();

  };
  const addMedicationUploadDocument = () => {
    fileInputRef.current.click();
  }

  const handleDelete = (index) => {
    const newArray = [...filenameArray];
    newArray.splice(index, 1);
    setFilenameArray(newArray);
  };

  const updateMedicationDocuments = () => {

    fileInputRefUpdate.current.click();
  }
  const handleMedicationUploadUpdate = async (e) => {
    e.preventDefault();
    showLoader();

    const file2 = e.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
    if (!allowedTypes.includes(file2.type)) {
      showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG file.");
      setTimeout(() => {
        
        dismissLoader();
      }, 1000);
      return false;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file2);
    
    const result = await uploadImageInProfile(file2, file2.name);
 
    const filename = result.name;
    // setMedicineData(prevData => [...prevData, filename]);
    setFilenameforUpload(prevFilenames => [...prevFilenames, filename]);
    //console.log(filenameforUpload, "filenameforUpload")

    // below we are setting the documents uploaded in edit, and we are checking if the prevFileNames is an array or not
    //  as when prevFileNames is empty it is giving error that iprevFileNames is not an array.
    setDocumentURLForEdit(prevFileNames => {
      if(!Array.isArray(prevFileNames)){
        return[{
          documentname: filename,
          documenturl: filename,
          imagepath: "https://midocfileserver.blob.core.windows.net/midocdev/"
        }]
      }else {
        return[
          ...prevFileNames, 
          {
            documentname: filename,
            documenturl: filename,
            imagepath: "https://midocfileserver.blob.core.windows.net/midocdev/"
       
          }
        ]
      }
    })
    showSuccessToast("Document Added Successfully.");
    e.target.value = '';
    dismissLoader();

  };


  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return moment(dateString).format('Do MMM, YYYY');
  };

   const handleclose = ()=>{
  showhidesearch(false)
   }
   
  const [minDateState , setMinDateState] = useState('')
  const [maxDateState , setMaxDateState] = useState('')

  const [minDateStateEdit , setMinDateStateEdit] = useState('')
  const [maxDateStateEdit , setMaxDateStateEdit] = useState('')
  //  const handleFromDateChange = (selecteddate) => {
  //   const formattedDate = moment(selecteddate).format('YYYY-MM-DD');
  //   setFromDate(formattedDate);

  //   setMinDateState(selecteddate)
    
  //   setMinDateStateEdit(selecteddate)
    
  //   const formattedDateToSetAsMinDate = moment(selecteddate).format('DD-MM-YYYY');
  //   setMinDateValue(formattedDateToSetAsMinDate)
  // };
  
  // const handleToDateChange = (selecteddate) => {
  //   const formattedDate = moment(selecteddate).format('YYYY-MM-DD');
  //   setToDate(formattedDate);
  //   setMaxDateState(selecteddate)
    
  //   setMaxDateStateEdit(selecteddate)
  // };
  const handleExpiryDateChange = (event) => {
    //const formattedDate = moment(selecteddate).format('YYYY-MM-DD');
    setMedicineExpiryDate(event);
  };
  const closeSearchBar =()=>{
    setSearchValue("")
    ShowHideSearch(false)
  }
  const fetchComposition = async (medicineName) => {
    const inputData = {
      input: {
        name: medicineName,
      },
    };
    if(medicineName){
    try {
       showLoader();
      const result = await GetAIMedicineComposition(inputData.input);
      // console.log(result);
      if (result) {
        showSuccessToast("Medicine composition fetched successfully");
        setComposition(result.chemical_composition);
        if(result.expiry_date){
          setMedicineExpiryDate(result.expiry_date);
        }
        setBenifit(result.benefit);
    setSideEffects(result.side_effects);
      }
      dismissLoader()
    } catch (error) {
      console.error("Error Fetching Medicine composition:", error);
      showErrorToast("An error occurred while Fetching Medicine composition");
    } 
      
  }else{
    showErrorToast("Please provide medicine name or upload medicine photo")
  }
  };
  const makeAicall = async ()=>{
    const inputData = {
      input: {
        name: medicinename,
      },
    };
    if(medicinename){
    try {
     showLoader();
    const result = await GetAIMedicineComposition(inputData.input);
    if(result){
        // setMedicineName(result.medicine_name);
        setComposition(result.chemical_composition)

        if(result.expiry_date !== "00-0000"){
          //const formatteddate = moment(result.expiry_date).format("DD-MM-yyyy")
          setMedicineExpiryDate(result.expiry_date);
        }
        setBenifit(result.benefit);
        setSideEffects(result.side_effects);
        learnMoreModal.current.openModal()
         dismissLoader();
    }
    }catch(error){

    }
  }
};
useEffect(() => {
  if (animate) {
    const timer = setTimeout(() => {
      setAnimate(false);
    }, 500);
    return () => clearTimeout(timer);
  }
}, [animate]);

useEffect(() => {
  // Scroll to bottom whenever aianswers changes
  if (chatContainerRef.current) {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }
}, [aianswers]);
const fetchAiDoctorPrompts = async (medicinename)=>{
  showLoader();
  setRemainingQuestions([])
  setAiInput(null);
  setAIAnswers([])
  setInitialRender(true)
  const result = await FetchMyHealthAIData({
    patientid,
  });
  console.log(result)
  let Aiprompts = { ...result, medicinename };
    setAiInput(Aiprompts);
    const response = await FetchMedicineAIPrompts(Aiprompts);
    if (response.generated_question) {
      setAIAnalysis(response);
      setRemainingQuestions(response.generated_question);
      AIChatModel.current.openRight();
    }else{
      // showErrorToast("Ai tokens are not available");
      setUpgradePlanModal(true)
      setTimeout(() => {
        setUpgradePlanModal(false)
      }, 100);
      AIChatModel.current.closeRight();
     }
    dismissLoader();
}

const GetAIAnswer = async (prompt) => {
  // Display question immediately
  const newQuestion = { question: prompt, answer: "" };
  setAIAnswers((prevState) => [...prevState, newQuestion]);

  // Remove the question from remaining questions immediately
  setRemainingQuestions((prevState) => prevState.filter((question) => question.value !== prompt));

  // Trigger animation
  setAnimate(true);

  // Set initialRender to false after the first question is clicked
  setInitialRender(false);
  let AichatInput = { ...AiInput, query:prompt };


  const response = await FetchMedicineAIAnswer(AichatInput);

  if (response) {
    // Replace "typing" with actual answer
    setAIAnswers((prevState) =>
      prevState.map((item) =>
        item.question === prompt && item.answer === ""
          ? { question: item.question, answer: response.answer }
          : item
      )
    );
    getTokens();
  }
};
const handleIsCurrent = (e)=>{
  if(e.target.checked){
    setIsChecked(1)
  }else{
    setIsChecked(0)
  }
}
const listOfSurgeonNames = ( isImage) => {
 
  const allSuggestions = treatmentteamdata && treatmentteamdata

  setSuggestions(allSuggestions);
  // setBoolValForSuggestions(!boolValForSuggestions) 
  if(isImage){
    setShowDocNamesDropdown(!showDocNamesDropdown)

  }
};

const handleMonthYearChange = (date) => {
  setMedicineExpiryDate(moment(date).format('MM-yyyy'));
};

useEffect(()=>{
if(!medicinename){
  setComposition("");
};
},[medicinename]);


  return (
    <>
    {screenType == 'web' ? (
      <Box w="100%" display={{base: "none", lg: "block"}}>
        <Box top="0" zIndex="banner">
          <Navbar messageModal={upgradePlanModal}/>
          {isLoading && <Loader />}
          <SecondaryNav
            defaultHeader="My Health"
            defaultSecondayHeader="Medications"
            defaultNavBody={
                
                  <Box display="flex" gap="10px" alignItems="center" pr="25px">
                    {/* <TabSection
                    defaultIndex={activeTab} 
                      tabs={["Current", "Past"]}
                      onClickTab={handletabswitch}
                    /> */}
                     {data &&
              data !== null && (
                <>
                    <Box as="button" onClick={SearchIconClicked}>
                      <Image
                        p="10px"
                        borderRadius="10px"
                        border="1px solid #E6E7E9"
                        src="/assets/imgs/search.png"
                      />
                    </Box>
                  
                    <PrimaryButton
                      fontSize="14px"
                      variant="mdBtn"
                      buttonText="Add Medication"
                      onClick={addData}
                      btnIcon="/assets/imgs/add.png"
                    />
                    </>
                    )}
                  </Box>
                
              
            }
          />
        </Box>
        <Box h="calc(100vh - 180px)" overflow="hidden auto">
        <Flex>
          <Box position="sticky" top="0" zIndex="sticky" h="100vh">
            <SideBar />
            {/* {isLoading && <Loader />} */}
          </Box>
          <Box w="100%">
            {showhidesearch && (
              <Box p="20px" pb="0" pt="10px">
                <SearchBar
                  passInputPlace="Search medicines"
                  onChange={(e) => setSearchValue(e.target.value)}
                  iconSrc="/assets/imgs/remove-icon.png"
                  handleClick={closeSearchBar}
                />
              </Box>
            )}

            <Box p="20px" pb="0" display="flex" justifyContent="start">
              <FilterButton
                tabs={["All", "Tablet", "Syrup"]}
                onClick={(label) => handlefilterswitch(label)}
              />
            </Box>
            {data && data != null ? (
              <Flex
                wrap="wrap"
                p="20px"
                w="100%"
                justifyContent="left"
                alignContent="flex-start"
                h="100%"
                gap="20px"
              >
            {filteredData.length > 0  ? (
                    filteredData.map((step, index) => (
                    <Box key={index} flex="0 0 calc(33.333% - 20px)" mb="20px">
                      <ReportViewCard
                        deleteBtn={false}
                        cardBtn={false}
                        cardHeight="220px"
                        maxCardWidth="350px"
                        // onClick={()=>opendetailsmodel(step.medicinename, step.medicationenddate)}
                        cardBody={
                          <>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="flex-start"
                              gap="10px"
                              overflow="hidden"
                            >
                              <Flex w="100%" alignItems="center" justifyContent="space-between">
                                <Box
                                  fontSize="10px"
                                  fontWeight="600"
                                  bg="#E6E7E966"
                                  border="1px solid #E6E7E9"
                                  p="5px 10px"
                                  borderRadius="full"
                                  w="fit-content"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                >
                                  {step.medicinetype ? step.medicinetype : "-"}
                                </Box>

                                <Box
                                  as="button"
                                  // onClick={}
                                  border="1px solid #E6E7E9"
                                  borderRadius="full"
                                  w="36px"
                                  h="auto"
                                  p="6px"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  onClick={()=>fetchAiDoctorPrompts(step.medicinename)}
                                >
                                  <Image w="26px" src="/assets/svgs/ai-star-outline.svg" />
                                </Box>
                              </Flex>
                              <Text title={step.medicinename} fontSize="15px" fontWeight="600" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                                {step.medicinename}
                              </Text>
                              {/* <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >
                                {formatDate(step.medicationenddate)} */}
                                {/* {step.medicationenddate} */}

                              {/* </Text> */}
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >
                                {step.chemicalcomposition ? (
                                  <>
                                  <Text fontWeight="bold">
                                    Composition :  <span style={{fontWeight:"500"}}>{step.chemicalcomposition}</span>
                                  </Text>
                                  </>
                                ):"-"}
                                {/* {step.medicationenddate} */}

                              </Text>
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >
                                {step.medicineexpirydate ? (
                                  <>
                                  <Text fontWeight="bold">
                                    Expiry :  <span style={{fontWeight:"500"}}>{step.medicineexpirydate}</span>
                                  </Text>
                                  </>
                                ):"-"}
                                {/* {step.medicationenddate} */}

                              </Text>
                              <Box  display="flex"  justifyContent="space-between">
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="start"
                                  gap="10px"
                                >
                                  <Image src="/assets/svgs/pill-blue.svg" />
                                  <Text fontSize="12px" fontWeight="500">
                                    {step.frequency ? step.frequency : "-"}x per
                                    day
                                  </Text>
                                  <Box display="flex" gap="5px">
                                    <Box
                                      as="span"
                                      bg={
                                        step.morning === 1
                                          ? "#384052"
                                          : "#E6E7E9"
                                      }
                                      borderRadius="full"
                                      w="8px"
                                      h="8px"
                                      p="4px"
                                    ></Box>
                                    <Box
                                      as="span"
                                      bg={
                                        step.evening === 1
                                          ? "#384052"
                                          : "#E6E7E9"
                                      }
                                      borderRadius="full"
                                      w="8px"
                                      h="8px"
                                      p="4px"
                                    ></Box>
                                    <Box
                                      as="span"
                                      bg={
                                        step.night === 1 ? "#384052" : "#E6E7E9"
                                      }
                                      borderRadius="full"
                                      w="8px"
                                      h="8px"
                                      p="4px"
                                    ></Box>
                                  </Box>

                                
                                </Box>
                                </Box>
                              <Box  display="flex"  justifyContent="space-between">
                                <Box display="flex" alignItems="center" >
                                  <Text mx="0.2rem" 
                                     fontSize="13px"
                                     fontWeight="500"
                                     color="#384052">Current : </Text>
                                     <Switch colorScheme='green' mx="0.2rem" 
                                     isChecked={step.iscurrentmedicine == 1}
                                     onChange={(e)=>setDetailsOfEdit(step, e, false)}
                                  />
                                </Box>
                                <Box display="flex" alignItems="center" >
                                  <Text mx="0.2rem" 
                                  fontSize="13px"
                                  fontWeight="500"
                                  color="#384052">Medicine Alert : </Text>
                                  <Switch colorScheme='green' mx="0.2rem" 
                                   isChecked={step.isalert == 1}
                                   onChange={(e)=>handlechangemedicinealert(step, e, false)}
                                />
                                </Box>
                              </Box>
                            </Box>
                          </>
                        }
                        hasFooter={true}
                        cardFooterText="View details"
                        footerOnClick={() => opendetailsmodel(step)}
                        style={{ height: "300px" }}
                      />
                    </Box>
                  ))
                ) : (
                  <Box
                  w="100%"
                  mt="-100px"
                  display="flex"
                  flexDirection="column"
                  gap="5px"
                  justifyContent="center"
                  alignItems="center"
                  h="100%"
                >
                  <Image w="60px" src="/assets/svgs/medication-nodata.svg" />
                  <Box as="p">No data available</Box>
                  
                </Box>
                )}
              </Flex>
            ) : (
              <Box
                w="100%"
                mt="-100px"
                display="flex"
                flexDirection="column"
                gap="5px"
                justifyContent="center"
                alignItems="center"
                h="100%"
              >
                <Image w="60px" src="/assets/svgs/medication-nodata.svg" />
                <Box as="p">No data available</Box>
                <PrimaryButton
                  fontSize="14px"
                  variant="mdBtn"
                  buttonText="Add Medication"
                  btnIcon="/assets/imgs/add.png"
                  onClick={addData}
                />
              </Box>
            )}
          </Box>
        </Flex>
        </Box>
          <Footer />
        
      <style jsx>{`
        .question-box, .answer-box {
          animation: slide-in 0.5s forwards;
        }
        @keyframes slide-in {
          from {
            transform: translateY(20px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
      </Box>
    ) : (
      //Mobile responsive start
      <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/myhealth')}
          header="Medications"
          bottomArea={<>
            <Box display="flex" gap="10px" justifyContent="center" alignItems="center" w="100%">
            {showhidesearch &&
              <Box w="100%">
                <SearchBar
                  passInputPlace="Search medicines"
                  onChange={(e) => setSearchValue(e.target.value)}
                  iconSrc="/assets/imgs/remove-icon.png"
                  handleClick={closeSearchBar}
                />
              </Box>
            }
            </Box>
          </>}
          rightArea={<>
                <Box as="button" onClick={SearchIconClicked}>
                  <Image
                    p="10px"
                    borderRadius="10px"
                    border="1px solid #E6E7E9"
                    src="/assets/imgs/search.png"
                  />
                </Box>
          </>}
        />
        {data && data != null && (
        <Box pos="fixed" bottom="10%" display="flex" justifyContent="center" w="100%" zIndex="99">
          <PrimaryButton
            fontSize="14px"
            variant="mdBtn"
            buttonText="Add Medications"
            btnIcon="/assets/imgs/add.png"
            onClick={addData}
            />
        </Box>
        )}
        <Box p="20px" pb="0" display="flex" justifyContent="start">
          <FilterButton
            tabs={["All", "Tablet", "Syrup"]}
            onClick={(label) => handlefilterswitch(label)}
          />
        </Box>
        <Box w="100%" p="1.5rem" pb="5rem" h='calc(100vh - 270px)' overflow="hidden auto">
        {data && data != null ? (
            <SimpleGrid
              gap="20px"
              columns={1}
            >
              {filteredData.length > 0  ? (
                    filteredData.map((step, index) => (
                    <Box key={index}>
                      <ReportViewCard
                        deleteBtn={false}
                        cardBtn={false}
                        cardHeight="220px"
                        // maxCardWidth="350px"
                        // onClick={()=>opendetailsmodel(step.medicinename, step.medicationenddate)}
                        cardBody={
                          <>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="flex-start"
                              gap="10px"
                              overflow="hidden"
                            >
                              <Flex w="100%" alignItems="center" justifyContent="space-between">
                                <Box
                                  fontSize="10px"
                                  fontWeight="600"
                                  bg="#E6E7E966"
                                  border="1px solid #E6E7E9"
                                  p="5px 10px"
                                  borderRadius="full"
                                  w="fit-content"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                >
                                  {step.medicinetype ? step.medicinetype : "-"}
                                </Box>

                                <Box
                                  as="button"
                                  // onClick={}
                                  border="1px solid #E6E7E9"
                                  borderRadius="full"
                                  w="36px"
                                  h="auto"
                                  p="6px"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  onClick={()=>fetchAiDoctorPrompts(step.medicinename)}
                                >
                                  <Image w="26px" src="/assets/svgs/ai-star-outline.svg" />
                                </Box>
                              </Flex>
                              <Text title={step.medicinename} fontSize="15px" fontWeight="600" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                                {step.medicinename}
                              </Text>
                              {/* <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >
                                {formatDate(step.medicationenddate)} */}
                                {/* {step.medicationenddate} */}

                              {/* </Text> */}
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >
                                {step.chemicalcomposition ? (
                                  <>
                                  <Text fontWeight="bold">
                                    Composition :  <span style={{fontWeight:"500"}}>{step.chemicalcomposition}</span>
                                  </Text>
                                  </>
                                ):"-"}
                                {/* {step.medicationenddate} */}

                              </Text>
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >
                                {step.medicineexpirydate ? (
                                  <>
                                  <Text fontWeight="bold">
                                    Expiry :  <span style={{fontWeight:"500"}}>{step.medicineexpirydate}</span>
                                  </Text>
                                  </>
                                ):"-"}
                                {/* {step.medicationenddate} */}

                              </Text>
                              <Box  display="flex"  justifyContent="space-between">
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="start"
                                  gap="10px"
                                >
                                  <Image src="/assets/svgs/pill-blue.svg" />
                                  <Text fontSize="12px" fontWeight="500">
                                    {step.frequency ? step.frequency : "-"}x per
                                    day
                                  </Text>
                                  <Box display="flex" gap="5px">
                                    <Box
                                      as="span"
                                      bg={
                                        step.morning === 1
                                          ? "#384052"
                                          : "#E6E7E9"
                                      }
                                      borderRadius="full"
                                      w="8px"
                                      h="8px"
                                      p="4px"
                                    ></Box>
                                    <Box
                                      as="span"
                                      bg={
                                        step.evening === 1
                                          ? "#384052"
                                          : "#E6E7E9"
                                      }
                                      borderRadius="full"
                                      w="8px"
                                      h="8px"
                                      p="4px"
                                    ></Box>
                                    <Box
                                      as="span"
                                      bg={
                                        step.night === 1 ? "#384052" : "#E6E7E9"
                                      }
                                      borderRadius="full"
                                      w="8px"
                                      h="8px"
                                      p="4px"
                                    ></Box>
                                  </Box>

                                
                                </Box>
                                <Box display="flex" alignItems="center" >
                                  <Text mx="0.2rem" 
                                     fontSize="13px"
                                fontWeight="500"
                                color="#384052">Current : </Text>
                                  <Switch colorScheme='green' mx="0.2rem" 
                      isChecked={step.iscurrentmedicine == 1}
                      onChange={(e)=>setDetailsOfEdit(step, e, false)}
                       />
                      </Box>
                              </Box>
                            </Box>
                          </>
                        }
                        hasFooter={true}
                        cardFooterText="View details"
                        footerOnClick={() => opendetailsmodel(step)}
                        style={{ height: "300px" }}
                      />
                    </Box>
                  ))
                ) : (
                  <Box
                  w="100%"
                  mt="-100px"
                  display="flex"
                  flexDirection="column"
                  gap="5px"
                  justifyContent="center"
                  alignItems="center"
                  h="100%"
                >
                  <Image w="60px" src="/assets/svgs/medication-nodata.svg" />
                  <Box as="p">No data available</Box>
                  
                </Box>
                )}
              </SimpleGrid>
            ) : (
              <Box
                w="100%"
                // mt="50px"
                display="flex"
                flexDirection="column"
                gap="5px"
                justifyContent="center"
                alignItems="center"
                h="100%"
              >
                <Image w="60px" src="/assets/svgs/medication-nodata.svg" />
                <Box as="p">No data available</Box>
                <PrimaryButton
                  fontSize="14px"
                  variant="mdBtn"
                  buttonText="Add Medication"
                  btnIcon="/assets/imgs/add.png"
                  onClick={addData}
                />
              </Box>
            )}
        </Box>
      </Box>
    )}

      <ModalRight
        ref={detailsmodel}
        //header="Medicine details"
        bodyStyle={{ p: "24px 32px" }}
        modalWidth="360px"
        body={
          <>
            <Flex flexDir="column">
              <Box w="100%" mb="15px">
                <Box
                  fontSize="10px"
                  fontWeight="600"
                  bg="#E6E7E966"
                  border="1px solid #E6E7E9"
                  p="5px 10px"
                  borderRadius="full"
                  w="fit-content"
                >
                  {medicinedata && medicinedata.medicinetype
                    ? medicinedata.medicinetype
                    : ""}
                </Box>
                <Text fontSize="15px" fontWeight="600">
                  {medicinedata && medicinedata.medicinename
                    ? medicinedata.medicinename
                    : "-"}
                </Text>
                {/* <Text fontSize="12px" fontWeight="500" color="#384052"> */}
                  {/* {medicinedata && medicinedata.medicationenddate
                    ? medicinedata.medicationenddate
                    : "-"} */}
                  {/* {formatDate(medicinedata && medicinedata.medicationstartdate && medicinedata.medicationstartdate)}
                </Text> */}
                  
                <Text fontWeight="bold" fontSize="12px">
                Composition :
                <span style={{fontWeight:"500"}}>
                  {medicinedata && medicinedata.chemicalcomposition
                    ? medicinedata.chemicalcomposition
                    : "-"}
                    </span>
                </Text>
                <Text fontWeight="bold" fontSize="12px">
                Expiry :
                <span style={{fontWeight:"500"}}>
                  {medicinedata && medicinedata.medicineexpirydate
                    ? medicinedata.medicineexpirydate
                    : "-"}
                    </span>
                </Text>
              </Box>
              <Divider />
              <Box
                display="flex"
                flexDir="column"
                mt="15px"
                gap="12px"
                border="1px solid #E6E7E9"
                borderRadius="10px"
                p="20px"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="start"
                  gap="10px"
                >
                  <Image src="/assets/svgs/pill-blue.svg" />
                  <Text fontSize="14px" fontWeight="500">
                    {medicinedata && medicinedata.frequency
                      ? medicinedata.frequency
                      : "-"}
                    x per day
                  </Text>
                  <Box display="flex" gap="5px">
                    <Box
                      as="span"
                      bg={
                        medicinedata && medicinedata.morning === 1
                          ? "#384052"
                          : "#E6E7E9"
                      }
                      borderRadius="full"
                      w="8px"
                      h="8px"
                      p="4px"
                    ></Box>
                    <Box
                      as="span"
                      bg={
                        medicinedata && medicinedata.evening === 1
                          ? "#384052"
                          : "#E6E7E9"
                      }
                      borderRadius="full"
                      w="8px"
                      h="8px"
                      p="4px"
                    ></Box>
                    <Box
                      as="span"
                      bg={
                        medicinedata && medicinedata.night === 1
                          ? "#384052"
                          : "#E6E7E9"
                      }
                      borderRadius="full"
                      w="8px"
                      h="8px"
                      p="4px"
                    ></Box>
                  </Box>
                </Box>
                {/* <Box display="flex" gap="10px" pr="4px">
                  <Image src="/assets/svgs/calendar_month.svg" />
                  <Text fontSize="14px" fontWeight="500">
                    {daysDiffrence} 
                  </Text>
                </Box> */}
                <Box display="flex" gap="10px" pr="4px">
                  <Image src="/assets/svgs/stethoscope.svg" />
                  <Text fontSize="14px" fontWeight="500" className="textOverflow">
                    {medicinedata && medicinedata.prescribedby
                      ? medicinedata.prescribedby
                      : "-"}
                  </Text>
                </Box>
                <Box display="flex" gap="10px" pr="4px">
                  <Image src="/assets/svgs/hospital.svg" />
                  <Text fontSize="14px" fontWeight="500" className="textOverflow">
                    {medicinedata && medicinedata.prescribedhospitalname
                      ? medicinedata.prescribedhospitalname
                      : "-"}
                  </Text>
                </Box>
              </Box>
              {/* <Button w="100%" h="48px" my="15px" borderRadius="10px" border="1px solid #E6E7E9" display="flex" justifyContent="space-between" bg="white" color="#1C4EC5" sx={{_hover: {bg:"none"}}}>
                    12/01/24_Report.pdf
                    <Box as="span">
                        <Image src="/assets/imgs/download.png" />
                    </Box>
                </Button>  */}
              {medicinedata && medicinedata.documents && medicinedata.documents.map((document, index) => (
                <Button
                  key={index}
                  w="100%"
                  h="48px"
                  my="15px"
                  borderRadius="10px"
                  border="1px solid #E6E7E9"
                  display="flex"
                  justifyContent="space-between"
                  bg="white"
                  color="#1C4EC5"
                  sx={{ _hover: { bg: "none" } }}
                  onClick={()=>downloadDocument(document.imagepath + document.documenturl)}
                  cursor="pointer"
                >
                  Document {index + 1}
                  {document.documenturl != null && (
                    <Box as="span">
                      <Image src="/assets/imgs/download.png" />
                    </Box>
                  )}
                </Button>
              ))}
            </Flex>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton onClick={() => handleeditmedication(medicinedata)} btnStyle={{fontSize:"14px", my: "0"}} variant="grayBtnFull" buttonText="Update Medication" isDisabled={false} />
              <PrimaryButton
                variant="grayBtnFull"
                color="#AA2E26"
                btnStyle={{ color: "#AA2F27", fontSize:"14px", my: "0" }}
                buttonText="Delete Medication"
                isDisabled={false}
                onClick={() =>
                  DeleteMedicineModel(
                    medicinedata && medicinedata.medicinename,
                    medicinedata && medicinedata.id
                  )
                }
              />
            </Box>
          </>
        }
      />
      <ModalRight
        ref={showmedicinename}
        header="Upload medication"
        bodyStyle={{ p: "24px 32px" }}
        modalWidth="360px"
        body={
          <>
            <Flex>
              <Box w="100%">
                <Center>
                  <VStack spacing={6} w="450px">
                    {/* <img
                      alt="not found"
                      width={"250px"}
                      src={selectedImage}
                    /> */}
                    <Image
                      alt="Uploaded medication"
                      src={selectedImage}
                      // boxSize="297px" // Sets both width and height to 297px
                      objectFit="contain" // This will ensure the aspect ratio is maintained without stretching
                    />
                    {/* <Input placeholder="Medicine Name" onChange={(event)=> setMedicineName(event.target.value)}/> */}
                    <PrimaryInput
                      defaultValue={medicinename}
                      inputValue={medicinename}
                      variant="fullRound"
                      inputPlace="Medicine Name"
                      mt="20px"
                      onChange={(event) =>
                        setMedicineName(event.target.value)
                      }
                      id="MedicineNameAdd"
                    />
                  </VStack>
                </Center>
              </Box>
            </Flex>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" alignItems="center" gap="15px">
              <PrimaryInput
                inputType="file"
                uploadText="Re-Upload"
                id="file-upload"
                onChange={handleimageselection}
                variant="grayBtnFull"
                buttonText="Reupload"
                isDisabled={false}
              />
              <input
                type="file"
                id="file-upload"
                style={{ display: "none" }}
                //onChange={(e) => setFile(e.target.files[0])}
                onChange={handleimageselection}
              />
              <PrimaryButton
                onClick={handlemedicinenamecontinue}
                buttonText="Continue"
                isDisabled={false}
              />
            </Box>
          </>
        }
      />

      <ModalRight
        ref={addmedications}
        header="Add Medication"
        bodyStyle={{ p: "24px 32px" }}
        modalWidth="360px"
        body={
          <>
            <Box display="flex" flexDir="column" gap="20px">
              <Stack>
                <InputGroup>
                  <Input
                    pr="4.5rem" // Padding right to make room for the icon
                    type="text"
                    placeholder="Medicine name*"
                    value={medicinename}
                    sx={{
                      height: "48px",
                      borderRadius: "10px",
                      _hover: {
                        border: "2px solid",
                        borderColor: COLORS.INPUT_FOCUS_DARK,
                        outline: "none",
                        bg: "none",
                        _focus: {
                          borderRadius: "10px",
                          borderColor: COLORS.INPUT_FOCUS_DARK,
                          outline: "none",
                        },
                        _focusVisible: { borderColor: "none" },
                      },
                    }}
                    onChange={handlemedicinename}
                    maxLength={80}
                  />
                  <InputRightElement width="4.5rem">
                    <Box
                      as="button"
                      h="1.75rem"
                      bg="none"
                      pt="8px"
                      onClick={() =>
                        document.getElementById("file-upload").click()
                      }
                      sx={{ _hover: { bg: "none" } }}
                    >
                      <Image src="/assets/svgs/image-search-blue.svg" />
                    </Box>
                    <input
                      type="file"
                      id="file-upload"
                      style={{ display: "none" }}
                      //onChange={(e) => setFile(e.target.files[0])}
                      onChange={handleimageselection}
                    />
                  </InputRightElement>
                </InputGroup>
                <InputGroup>
                <PrimaryInput
                variant="bothSide"
                  inputValue={Composition}
                  onChange={(event)=> setComposition(event.target.value)}
                  inputPlace="Medicine Composition"
                />
                  <PrimaryButton
                          buttonText="Fetch"
                          verticalAlign="bottom"
                          variant="grayBtn"
                          btnStyle={{
                            w: "15%",
                            color: COLORS.PRIMARY_COLOR,
                            verticalAlign: "bottom",
                            ml: "4px",
                            mb: "0px",
                            h: "2.8rem",
                            m: "0"
                          }}
                          onClick={() =>
                            fetchComposition(medicinename)
                          }
                          isDisabled={false}
                        />
                        
                        </InputGroup>
                        {medicinename != '' &&
                        <Box as="button" onClick={makeAicall} textAlign="right" fontSize="12px" textDecoration="underline">Learn more</Box>
                        }
                        <RadioGroup
                  defaultValue={medicineTypeRadio}
                  onChange={handlemedicinetypechange}
                >
                  <Stack direction="row" gap="20px">
                    <Radio value="Tablet">Tablet</Radio>
                    <Radio value="Syrup">Syrup</Radio>
                  </Stack>
                </RadioGroup>
                <Divider />
                <Box>
                  {/* {medicinetype == "Tablet" && */}
                    <MiniDropdownInput
                    variant="bothside"
                    options={ [
                    { label: "mg", value: "mg" },
                    { label: "ml", value: "ml" },
                      ] }
                    placeholderProp="Dosage"
                    onSelect={handleChangeDosageUnit}
                    defaultOptionProp={dosageunit}
                    handleInputChange={(e) =>
                      setMedicineDosage(validationOfNumberWithOnly1Decimal(e.target.value))
                    }
                    inputVal={dosage}
                    
                    maxLength="6"
                  />
                    {/* } */}
                      {/* {medicinetype == "Syrup" &&
                    <MiniDropdownInput
                    variant="bothside"
                    options={ [
                    { label: "ml", value: "152" },
                        { label: "mg", value: "151" },
                      ] }
                    placeholderProp="Dosage"
                    onSelect={handleChangeDosageUnit}
                    handleInputChange={(e) =>
                      setMedicineDosage(validationOfNumberWithOnly1Decimal(e.target.value))
                    }
                    
                    inputVal={dosage}
                    maxLength="6"
                  />
                    } */}
                  

                  {/* <Box display="flex" flexDir="row"> */}
                  
                      {/* <PrimaryInput

                      variant="bothSide"
                      id="vitaldate"
                      inputType="date"
                      inputPlace="From"
                      inputStyleCss={{ w: "50%" }}
                      defaultValue={fromdate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                    <PrimaryInput
                      variant="bothSide"
                      id="vitaldate"
                      inputType="date"
                      inputPlace="To"
                      inputStyleCss={{ w: "50%" }}
                      defaultValue={todate}
                      onChange={(e) => setToDate(e.target.value)}
                    /> */}
                      {/* <DatePicker 
                      disableFutureDates={true}
                      maxDate={maxDateState}
                      defaultDate={fromdate} 
                      onDateChange={(selectedDateValue) =>
                        handleFromDateChange(selectedDateValue)
                      }
                      placeholderProp={"Start Date"} 
                      //calendarStlye={{transform: "scale(0.9)", left:"-15px", top: "30px"}}
                      calendarStlye={{left: "-18px",  right: "unset"}}
                    />
                    <DatePicker 
                      disablePastDates={true}
                      minDate={minDateState}
                      defaultDate={todate} 
                      onDateChange={(selectedDateValue) =>
                        handleToDateChange(selectedDateValue)
                      }
                      placeholderProp={"End Date"} 
                      calendarStlye={{transform: "scale(0.9)", right: "-22px", top: "30px"}}
                    /> */}
                  {/* </Box> */}
                  <DropdownInput
                    variant="downSide"
                    dropDownPlace="Consumption frequency"
                    options={["1", "2", "3", "4"]}
                    onClick={handleconsumptionfrequencychange}
                  />
                    {/* <PrimaryInput
                    inputPlace="Medicine Expiry"
                    variant="defaultSide"
                    inputValue={medicineexpirydate}
                    onChange={(event) => handleExpiryDateChange(event.target.value)}
                  /> */}

<DatePickerMonthYear
        placeholderProp="Medicine Expiry"
        value={medicineexpirydate}
        onDateChange={handleMonthYearChange}
        datePickerInputStyle={{ borderRadius: "0!important" }}
        minYear={moment().year()}
        

      />
                  <CheckboxGroup
                    colorScheme="green"
                    onChange={handlemedicinetime}
                  >
                    <Stack spacing={2} pt="20px" direction="row">
                      <Checkbox value="morning">Morning</Checkbox>
                      <Checkbox value="evening">Evening</Checkbox>
                      <Checkbox value="night">Night</Checkbox>
                    </Stack>
                  </CheckboxGroup>
                  <Divider />
                  <CheckboxGroup
                    colorScheme="green"
                    onChange={handlemedicinetimebeforeafter}
                  >
                    <Stack spacing={2} pt="20px" direction="row">
                      <Checkbox value="beforefood">Before Food</Checkbox>
                      <Checkbox value="afterfood">After Food</Checkbox>
                    </Stack>
                  </CheckboxGroup>
                  <Divider />
                  
                </Box>
                <Divider />
                <Box>
                <Box w="100%" pos='relative' cursor="pointer">
                  <PrimaryInput
                    inputPlace="Prescribed by"
                    variant="defaultSide"
                    inputValue={prescribedby}
                    onChange={(e) =>
                      handleSurgeonNameChange(handleAlphaDotAndSpace(e.target.value,50))
                    }
                    onClick={() =>
                      listOfSurgeonNames(false)
                    }
                inputIdProp={"DoctorNameInAdd"}
                boolProp={boolValForSuggestions}
                  />
                    <Image top="33%" right="6.5%" w="15px" 
                          onClick={() =>
                            listOfSurgeonNames( true)
                          }
                            pos='absolute' src="/assets/imgs/double-arrow.png" />
                          
                  </Box>
                    {showDocNamesDropdown &&  suggestions.length > 0 && (
                <Menu isOpen={true}>
                <MenuButton as={Box} w="100%" display="block" pos="relative" zIndex="999">
                  <Box pos="absolute" top="100%" left="0" w="100%" bg="white" borderTop="none" border="1px solid #E6E7E9" borderRadius="0 0 12px 12px" />
                </MenuButton>
                <MenuList w="100%" borderRadius="0 0 12px 12px" zIndex="999" bg="white" maxH="150px" overflow="hidden auto">
                  {suggestions.map((suggestion, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleSelect(suggestion)}
                      cursor="pointer"
                      p="1rem"
                      py="0.5rem"
                      borderBottom="1px solid #E6E7E9"
                    >
                      <Text fontSize="0.875rem" fontWeight="500">{suggestion.doctorname}</Text>
                    </MenuItem>
                  ))}
                  </MenuList>
                </Menu>
              )}


                  <PrimaryInput
                    inputPlace="Hospital name"
                    variant="downSide"
                    onChange={(e) => setHospitalname(handleAlphaNumericDotAndSpace(e.target.value))}
                    inputValue={hospitalname}
                  />
                  <Box mt="15px">
                  <Checkbox
                colorScheme="green"
                sx={{
                  ".chakra-checkbox__control": {
                    width: "20px",
                    height: "20px",
                    borderRadius: "5px",
                  },
                }}
                onChange={handleIsCurrent}
              >
                  Current
              </Checkbox>
              </Box>
                </Box>
                {filenameArray.map((filename, indexToDelete) => (
                  <Button fontSize="14px" fontWeight="500" w="100%" h="48px" borderRadius="10px" border="1px solid #E6E7E9" display="flex" justifyContent="space-between" bg="white" color="#1C4EC5" sx={{ _hover: { bg: "none" } }}>
                    Document {indexToDelete + 1}
                    <Box as="span" onClick={() => handleDelete(indexToDelete)}>
                      <Image src="/assets/imgs/delete.png" />
                    </Box>
                  </Button>
                ))}
                <Box onClick={addMedicationUploadDocument} as="button" display="flex" alignItems="center" gap="10px" fontSize="14px" fontWeight="600" color="#1C4EC5" textAlign="left">
                  <Image w="11px" src="/assets/imgs/add-blue.png" />
                  Add Document
                </Box>
                <input
                  type='file'
                  id='file-upload'
                  onChange={(e) => handleMedicationUpload(e)}
                  ref={fileInputRef} // Set the ref for the file input
                  style={{ display: 'none' }} // Hide the file input
                />
              </Stack>
            </Box>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Add Medication"
                onClick={addmedicine}
                isDisabled={false}
              />
            </Box>
          </>
        }
      />
      <ModalRight
        ref={editmedications}
        header="Update Medication"
        bodyStyle={{ p: "24px 32px" }}
        modalWidth="360px"
        body={
          <>
            <Box display="flex" flexDir="column" gap="20px">
              <Stack spacing={4}>
                <InputGroup>
                  <Input
                    pr="4.5rem" // Padding right to make room for the icon
                    type="text"
                    placeholder="Medicine name*"
                    value={medicinename}
                    sx={{
                      height: "48px",
                      borderRadius: "10px",
                      _hover: {
                        border: "2px solid",
                        borderColor: COLORS.INPUT_FOCUS_DARK,
                        outline: "none",
                        bg: "none",
                        _focus: {
                          borderRadius: "10px",
                          borderColor: COLORS.INPUT_FOCUS_DARK,
                          outline: "none",
                        },
                        _focusVisible: { borderColor: "none" },
                      },
                    }}
                    maxLength={80}
                    onChange={handlemedicinename}
                  />
                  <InputRightElement width="4.5rem">
                    <Box
                      as="button"
                      h="1.75rem"
                      bg="none"
                      pt="8px"
                      onClick={() =>
                        document.getElementById("file-upload").click()
                      }
                      sx={{ _hover: { bg: "none" } }}
                    >
                      <Image src="/assets/svgs/image-search-blue.svg" />
                    </Box>
                    <input
                      type="file"
                      id="file-upload"
                      style={{ display: "none" }}
                      //onChange={(e) => setFile(e.target.files[0])}
                      onChange={handleimageselection}
                    />
                  </InputRightElement>
                </InputGroup>
                <InputGroup>
                <PrimaryInput
                variant="bothSide"
                  inputValue={Composition}
                  onChange={(event)=> setComposition(event.target.value)}
                  inputPlace="Chemical Composition"
                />
                  <PrimaryButton
                          buttonText="Fetch"
                          verticalAlign="bottom"
                          variant="grayBtn"
                          btnStyle={{
                            w: "15%",
                            color: COLORS.PRIMARY_COLOR,
                            verticalAlign: "bottom",
                            ml: "4px",
                            mb: "0px",
                            h: "2.8rem",
                            m: "0"
                          }}
                          onClick={() =>
                            fetchComposition(medicinename)
                          }
                          isDisabled={false}
                        />
                </InputGroup>
                {medicinename != ''  &&
                        <Box as="button" onClick={makeAicall} textAlign="right" fontSize="12px" textDecoration="underline">Learn more</Box>
                        }
                <RadioGroup
                  defaultValue={medicinetype}
                  onChange={handlemedicinetypechange}
                >
                  <Stack direction="row" gap="20px">
                    <Radio value="Tablet">Tablet</Radio>
                    <Radio value="Syrup">Syrup</Radio>
                  </Stack>
                </RadioGroup>
                <Divider />
                <Box>
                  <MiniDropdownInput
                    variant="bothside"
                    options={[
                      { label: "mg", value: "mg" },
                      { label: "ml", value: "ml" },
                    ]}
                    placeholderProp="Dosage"
                    defaultValue={dosage}
                    inputVal={dosage}
                    defaultOptionProp={dosageunit}
                    onSelect={handleChangeDosageUnit}
                    handleInputChange={(e) =>
                      setMedicineDosage(validationOfNumberWithOnly1Decimal(e.target.value))
                    }
                    maxLength="6"
                  />

                  <Box display="flex" flexDir="row">
                  {/* <PrimaryInput

                      variant="bothSide"
                      id="vitaldate"
                      inputType="date"
                      inputPlace="From"
                      inputStyleCss={{ w: "50%" }}
                      defaultValue={fromdate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                    <PrimaryInput
                      variant="bothSide"
                      id="vitaldate"
                      inputType="date"
                      inputPlace="To"
                      inputStyleCss={{ w: "50%" }}
                      defaultValue={todate}
                      onChange={(e) => setToDate(e.target.value)}
                    /> */}
                      {/* <DatePicker 
                      disableFutureDates={true}
                      defaultDate={fromdate} 
                      onDateChange={(selectedDateValue) =>
                        handleFromDateChange(selectedDateValue)
                      }
                      placeholderProp={"Start Date"} 
                      calendarStlye={{left: "-18px",  right: "unset"}}
                      maxDate={maxDateStateEdit}
                    />
                    <DatePicker 
                      defaultDate={todate} 
                      onDateChange={(selectedDateValue) =>
                        handleToDateChange(selectedDateValue)
                      }
                      placeholderProp={"End Date"} 
                      calendarStlye={{transform: "scale(0.9)", right: "-22px", top: "30px"}}
                      
                      disablePastDates={true}
                      
                      minDate={minDateStateEdit}
                      
                    /> */}


                  </Box>
                  <DropdownInput
                    variant="downSide"
                    dropDownPlace="Consumption frequency"
                    options={["1", "2", "3", "4"]}
                    onClick={handleconsumptionfrequencychange}
                    defaultOption={frequency ? frequency + "" : ""}
                  />
                  {/* <PrimaryInput
                    inputPlace="Medicine Expiry"
                    variant="defaultSide"
                    defaultValue={medicineexpirydate}
                    inputValue={medicineexpirydate}
                    onChange={(event) => handleExpiryDateChange(event.target.value)}
                  /> */}
                  <DatePickerMonthYear
        placeholderProp="Medicine Expiry"
        value={medicineexpirydate}
        onDateChange={handleMonthYearChange}
        datePickerInputStyle={{ borderRadius: "0!important" }}
        
        defaultDate={medicineexpirydate}
        minYear={moment().year()}

      />
                  <CheckboxGroup
                    colorScheme="green"
                  //onChange={handlemedicinetime}               
                  >
                    <Stack spacing={2} pt="20px" direction="row">
                      <Checkbox isChecked={morning === 1} onChange={() => setMorning(morning === 1 ? 0 : 1)}>Morning</Checkbox>
                      <Checkbox isChecked={setevening === 1} onChange={() => setEvening(setevening === 1 ? 0 : 1)}>Evening</Checkbox>
                      <Checkbox isChecked={setnight === 1} onChange={() => setNight(setnight === 1 ? 0 : 1)}>Night</Checkbox>
                    </Stack>
                  </CheckboxGroup>
                  <Divider />
                  <CheckboxGroup
                    colorScheme="green"
                  >
                    <Stack spacing={2} pt="20px" direction="row">
                      <Checkbox isChecked={beforefood === 1} onChange={() => setBeforefood(beforefood === 1 ? 0 : 1)}>Before Food</Checkbox>
                      <Checkbox isChecked={afterfood === 1} onChange={() => setAfterfood(afterfood === 1 ? 0 : 1)}>After Food</Checkbox>
                    </Stack>
                  </CheckboxGroup>
                  
                </Box>
                <Divider />
                <Box>
                  
                <Box w="100%" pos='relative' cursor="pointer">
                  <PrimaryInput
                    inputPlace="Prescribed by"
                    variant="defaultSide"
                    onChange={(e) =>
                      handleSurgeonNameChange(handleAlphaDotAndSpace(e.target.value,50))
                    }
                    onClick={() =>
                      listOfSurgeonNames(false)
                    }
                    defaultValue={prescribedby}
                    inputValue={prescribedby}

                    
                inputIdProp={"DoctorNameInEdit"}
                boolProp={boolValForSuggestions}
                  />
                    <Image top="33%" right="6.5%" w="15px" 
                          onClick={() =>
                            listOfSurgeonNames( true)
                          }
                            pos='absolute' src="/assets/imgs/double-arrow.png" />

                  {showDocNamesDropdown &&  suggestions.length > 0 && (
                <Menu isOpen={true}>
                <MenuButton as={Box} w="100%" display="block" pos="relative" zIndex="999">
                  <Box pos="absolute" top="100%" left="0" w="100%" bg="white" borderTop="none" border="1px solid #E6E7E9" borderRadius="0 0 12px 12px" />
                </MenuButton>
                <MenuList w="100%" borderRadius="0 0 12px 12px" zIndex="999" bg="white" maxH="150px" overflow="hidden auto">
                  {suggestions.map((suggestion, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleSelect(suggestion)}
                      cursor="pointer"
                      p="1rem"
                      py="0.5rem"
                      borderBottom="1px solid #E6E7E9"
                    >
                      <Text fontSize="0.875rem" fontWeight="500">{suggestion.doctorname}</Text>
                    </MenuItem>
                  ))}
                  </MenuList>
                </Menu>
              )}
              </Box>
                  <PrimaryInput
                    inputPlace="Hospital name"
                    variant="downSide"
                    onChange={(e) => setHospitalname(handleAlphaNumericDotAndSpace(e.target.value))}
                    defaultValue={hospitalname}
                  />
                </Box>
                <Box mt="15px">
                  <Checkbox
                colorScheme="green"
                sx={{
                  ".chakra-checkbox__control": {
                    width: "20px",
                    height: "20px",
                    borderRadius: "5px",
                  },
                }}
                isChecked={isChecked == 1}
                onChange={handleIsCurrent}
              >
                  Current
              </Checkbox>
              </Box>

                {/* {filenameforUpload && filenameforUpload.map((filename, index) => ( */}
                {documentURLForEdit?.map((document, index) => (
              
                  <Button
                    key={index}
                    w="100%"
                    h="48px"
                    my="15px"
                    borderRadius="10px"
                    border="1px solid #E6E7E9"
                    display="flex"
                    justifyContent="space-between"
                    bg="white"
                    color="#1C4EC5"
                    sx={{ _hover: { bg: "none" } }}
                    // onClick={()=>downloadDocument(`https://midocfileserver.blob.core.windows.net/midocdev/${filename}`)}
                    onClick={() => handleDeleteDocInEdit(index)}
                    cursor="pointer"
                  >
                    Document {index + 1}
                    <Box as="span">
                      <Image src="/assets/imgs/delete.png" />
                    </Box>
                  </Button>
                ))}
                {/* <Button fontSize="14px" fontWeight="500" w="100%" h="48px" borderRadius="10px" border="1px solid #E6E7E9" display="flex" justifyContent="space-between" bg="white" color="#1C4EC5" sx={{_hover: {bg:"none"}}}>
                  12/01/24_Report.pdf
                  <Box as="span">
                      <Image src="/assets/imgs/delete.png" />
                  </Box>
                </Button>*/}
                <Box onClick={updateMedicationDocuments} as="button" display="flex" alignItems="center" gap="10px" fontSize="14px" fontWeight="600" color="#1C4EC5" textAlign="left">
                  <Image w="11px" src="/assets/imgs/add-blue.png" />
                  Add Document
                </Box>
                <input
                  type='file'
                  id='file-upload'
                  onChange={(e) => handleMedicationUploadUpdate(e)}
                  ref={fileInputRefUpdate} // Set the ref for the file input
                  style={{ display: 'none' }} // Hide the file input
                />
              </Stack>
            </Box>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Update Medication"
                onClick={editmedicine}
                isDisabled={false}
                btnStyle={{my: "0"}}
              />
            </Box>
          </>
        }
      />
      <DynamicModal
        ref={dynamicModal}
        modalHeader="Delete Medicine?"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete <br />
              <strong>"{title ? title : "-"}"</strong>
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deletemedicine}
            />
          </>
        }
      />
      <DynamicModal
        ref={learnMoreModal}
        modalHeader={medicinename}

        modalStyle={{maxWidth: "calc(100vw - 45%)",maxHeight: "calc(100vh - 30%)", overflow:"hidden auto", w: "100%"}}
        modalBody={
          <>
          
          <Box mb="10px" >
            <Text fontSize="14px" fontWeight="600">
              Benifit  
            </Text>
            <Box as="span" >{benifit ? benifit : ''}</Box>
            </Box>
            
            <Box mb="10px" >
            <Text fontSize="14px" fontWeight="600">
              Side Effect 
            </Text>
            <Box as="span"  > {sideEffects ? sideEffects : ''}</Box>
            </Box>
            
          <Box mb="10px" >
            <Text fontSize="14px" fontWeight="600">
                {Composition == "Not Available" ? "" : "Composition" }
            </Text>
            <Box as="span" mb="10px">{Composition == "Not Available" ? "" : Composition }</Box>
            </Box>
          </>
        }
        
      />
      <AiModal
        ref={AIChatModel}
      modalSize="420px"
      //icon="/assets/imgs/ai-doctor.png"
      header={<>
        <Box display="flex" flexDir='column' gap='5px'>
            <Box display='flex' gap='6px'>
              <Text fontSize="1rem"
                fontWeight="700" 
                backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)" 
                color="transparent" 
                backgroundClip="text"
              >Aira - AI</Text>
              <Box display='flex' alignItems='center' gap='5px' border='1px solid #0d7e54' bg='#d5ece3'  p='3px 5px' borderRadius='full'>
                <Image src='/assets/imgs/spark.png' /> 
                <Text fontSize='10px' fontWeight='500' color='#000'>{remainingTokens && remainingTokens.remainingtokens ? displayMillion_ThoudandValues(remainingTokens.remainingtokens):"0"} tokens available</Text>
              </Box>
            </Box>
            <Text fontSize="0.75rem" fontWeight="400">Chat with your AI health guide</Text>
          </Box>
      </>}
      headerStyle={{ p: "24px 32px", lineHeight: "normal" }}
      closeBtnStyle={{ top: "18px" }}
      footerStyle={{ maxH: "35%", overflow: "hidden auto", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      withoutBg={true}
      backDropBlur="10px"
      body={
        <>
          <Flex flexDirection="column" height="100%">
            <Box
              p="24px 32px"
              ref={chatContainerRef}
              className="chat-container"
              style={{ overflowY: "auto", maxHeight: 'calc(100vh - 160px)' }}
            >
              {initialRender && remainingquestions.length > 0 && (
                <Box>
                  {remainingquestions.map((step, index) => (
                    <Box
                      key={index}
                      as="button"
                      w="100%"
                      p="16px"
                      mb="16px"
                      fontSize="14px"
                      fontWeight="600"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      textAlign='left'
                      gap='10px'
                      justifyContent="space-between"
                      borderRadius="10px"
                      border="1px solid #7795DC"
                      bg="#EAEDF5"
                      color="#163E9E"
                      wordBreak="break-all"
                      onClick={() => GetAIAnswer(step.value)}
                      className={`question-box ${animate ? 'slide-in' : ''}`}
                    >
                      {step.value ? step.value : "-"}
                      <Image src="/assets/imgs/right-icon.png" />
                    </Box>
                  ))}
                </Box>
              )}
              {aianswers.map((step, index) => (
                <React.Fragment key={index}>
                  <Box
                    as="div"
                    bg="#061027"
                    p="12px 16px"
                    w="fit-content"
                    float="right"
                    borderRadius="10px 10px 1px 10px"
                    className={`answer-box ${animate ? 'slide-in' : ''}`}
                  >
                    <Text fontSize="14px" fontWeight="500" color="white">
                      {step.question ? step.question : "-"}
                    </Text>
                  </Box>

                  <Box
                    as="div"
                    my="20px"
                    w="fit-content"
                    display="flex"
                    alignItems="end"
                    float="left"
                    className={`answer-box ${animate ? 'slide-in' : ''}`}
                  >
                    <Image
                      bg="#061027"
                      p="4px"
                      mr="8px"
                      borderRadius="6px"
                      src="/assets/imgs/ai-chat-logo.png"
                    />
                    <Text
                      bg="#E6E7E980"
                      p="12px 16px"
                      fontSize="14px"
                      fontWeight="500"
                      color="#061027"
                      borderRadius="10px 10px 10px 1px"
                    >
                      {step.answer ? step.answer : <TypingLoader />}
                    </Text>
                  </Box>
                </React.Fragment>
              ))}
            </Box>
          </Flex>
        </>
      }
      footerActions={
        !initialRender && (
          <Box
            p="24px 32px"
            display="flex"
            w="100%"
            h="100%"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box w="100%">
              {remainingquestions.map((step, index) => (
                <Box
                  key={index}
                  as="button"
                  w="100%"
                  p="16px"
                  mb="16px"
                  fontSize="14px"
                  fontWeight="600"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius="10px"
                  border="1px solid #7795DC"
                  bg="#EAEDF5"
                  color="#163E9E"
                  onClick={() => GetAIAnswer(step.value)}
                  className={`question-box ${animate ? 'slide-in' : ''}`}
                >
                  {step.value ? step.value : "-"}
                  <Image src="/assets/imgs/right-icon.png" />
                </Box>
              ))}
            </Box>
          </Box>
        )
      }
      />
    </>
  );
};

export default MedicationsIndex;
