import { PaymentElement } from "@stripe/react-stripe-js";
import React, { useState, useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Box, Flex } from "@chakra-ui/layout";
import {
  Image,
  VStack,
  Center,
} from "@chakra-ui/react";
import ModalRight from "../../components/modal-right";
import {
  PrimaryButton,
  Toaster,
} from "../../components";
import { useNavigate } from "react-router-dom";
import { SavePayment } from "../../middleware/services/payment/payment";
import { loadStripe } from "@stripe/stripe-js";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";




export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  var navigate = useNavigate();
  const [patientid, setPatientID] = useState("");
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const { showSuccessToast, showErrorToast } = Toaster();
  const addcard = React.useRef();
  const { isLoading, showLoader, dismissLoader } = useLoader();


  useEffect(() => {
    addcard.current.openRight();
    if (!stripe) {
      return;
    }
    
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    showLoader();
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);
    const {paymentIntent, error } = await stripe.confirmPayment({
      elements,
      // confirmParams: {
      //   // Make sure to change this to your payment completion page
      //   return_url: `${window.location.origin}/completion`,
      // },
      redirect:"if_required",
    });

    if (error) {
      setIsProcessing(false);
      showErrorToast (error.message);
      var result = await SavePayment(error)     
      dismissLoader();
    }
    else if (paymentIntent && paymentIntent.status === 'succeeded') {  
      showLoader()    
      var resp = await SavePayment(paymentIntent)
      window.location.reload();
      dismissLoader();
    } 
    else {
      showErrorToast("An unexpected error occured.");
    }

    setIsProcessing(false);
    dismissLoader();
  };
  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <>
    {isLoading && <Loader />}
    <ModalRight
          ref={addcard}
          header="Add Card"
          bodyStyle={{ p: "24px 32px" }}
          modalWidth="400px"
          
          body={
            <>
              <Flex>
                <Box w="100%">
                  <Center>
                    <VStack spacing={6} w="450px">
                      {/* <img
                        alt="not found"
                        width={"250px"}
                        src={selectedImage}
                      /> */}
                      {/* <Image
                        alt="Uploaded medication"
                        src="/assets/imgs/card-png.png"
                        objectFit="contain" // This will ensure the aspect ratio is maintained without stretching
                      /> */}
                        <form id="payment-form" onSubmit={handleSubmit}>
                          <PaymentElement id="payment-element" />      
                          {/* Show any error or success messages */}
                          {message && <div id="payment-message">{message}</div>}
                        </form>
                     <Image mt="1.5rem" src="/assets/imgs/powered-stripe.png" />
                    </VStack>
                  </Center>
                </Box>
              </Flex>
            </>
          }
          footerActions={
            <>
              <Box w="100%" display="flex" alignItems="center" gap="15px">
               
                <PrimaryButton
                  onClick={handleSubmit}
                  buttonText="Pay"
                  //isDisabled={isProcessing} 
                  id="submit"
                />
              </Box>
            </>
          }
        />
    </>
  );

}
