import  FetchData, { } from '../../client';
import {getLoginInfo, getUserID}  from '../../../auth';
import formapimodaldoctors from '../../domains/patientdoctors/doctors';
import formapimodalchat from '../../domains/chat/chats';
import formapimodalchatmedia from '../../domains/chatmedia/index'


const fetchDoctors = async (inputData) => {
    try {
        inputData.patientid = getLoginInfo();
        inputData.activeuserid = getUserID();
      const result = await FetchData("midoc_getchatdoctorlistforpatient", inputData);
      //console.log(result,"db response patients")
      if (result.output.data) {
      var finalOutput = formapimodaldoctors(result.output.data)
      }
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const FetchChatData = async (inputData) => {
    try {
        inputData.patientid = getLoginInfo();
        inputData.activeuserid = getUserID();
      const result = await FetchData("midoc_getchatbypatient", inputData);
      //console.log(result,"db response chats")
      if (result.output.data) {
      var finalOutput = formapimodalchat(result.output.data)
      }
      if(result.output.appointmentdetails){
        var chatheaderoutput = result.output.appointmentdetails[0];
      }
      if(result.output){
        var chatenablesettings = result.output.chatenabledetail;
        }
      return {finalOutput,chatheaderoutput,chatenablesettings};
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const AddMessage = async (inputData) => {
    try {
        inputData.patientid = getLoginInfo();
        inputData.activeuserid = getUserID();
      const result = await FetchData("midoc_addchat", inputData);
      //console.log(result,"db response add chat")
      
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const FetchDoctorChatMedia = async (inputData) => {
    try {
        inputData.patientid = getLoginInfo();
        inputData.activeuserid = getUserID();
      const result = await FetchData("midoc_getchatmediabypatient", inputData);
      if (result.output.doctordata) {
        var finalOutput = formapimodalchatmedia(result.output.doctordata[0])
        }
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };




 
//   const updatechatmessageread = async (inputData) => {
//     try {
//         inputData.patientid = getLoginInfo();
//         inputData.activeuserid = getUserID();
//       const result = await FetchData("midoc_updatechat", inputData);

//       return result;
  
//     } catch (error) {
//       console.error('Error fetching data in forms.js:', error);
//       throw error;
//     }
//   };
  



export {fetchDoctors, FetchChatData, FetchDoctorChatMedia, AddMessage};
