import moment from "moment";
export default function formAPIpatientData({
    firstname,
    lastname,
    height,
    weight,
    weightunit,
    heightunit,
    gender,
    dob,
    mobileno,
    email,
    patientid,
    id,
    patientimage,
    imagepath,  

})
{
  return Object.freeze({
    firstname,
    lastname,
    height,
    weight,
    weightunit,
    heightunit,
    gender,
    dob,
    mobileno,
    email,
    patientid,
    id,
    patientimage,
    imagepath,
   
   
  });
}