import moment from "moment";
export default function formAPIMedicalCondition({
  condition,
  condition_cd,
  conditionidentifieddate,
  id,
  conditiontype,
  conditiontype_cd
   

})
{
  return Object.freeze({
    condition,
    condition_cd,
    conditionidentifieddate: conditionidentifieddate? moment(conditionidentifieddate).format("YYYY-MM-DD"): "-",
    id,
    conditiontype,
    conditiontype_cd

   
  });
}