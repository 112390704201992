import moment from "moment";
export default function formAPIProcedure({
    address,
    city,
    country,
    doctorid,
    doctorname,
    entityid,
    entitytype,
    hospital,
    imagepath,
    orderdate,
    orderid,
    ordername,
    state,


}) {
  return Object.freeze({
    address,
    city,
    country,
    doctorid,
    doctorname,
    entityid,
    entitytype,
    hospital,
    imagepath,
    orderdate,
    orderid,
    ordername,
    state,


  });
}