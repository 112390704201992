import formAPItreatmentteam from "./treatmentteam";


var formattedOutput = null;

const formAPIModeltreatmentteam = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPItreatmentteam(item));


  return formattedOutput
};

export default formAPIModeltreatmentteam