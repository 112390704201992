import moment from "moment";
export default function formAPIProfileAITokens({
  pointsdebited,
  entitytype,
  createddate,
  patientid,
   

})
{
  return Object.freeze({
    pointsdebited,
    entitytype,
    patientid,
    createddate : moment(createddate).format("Do MMM YYYY"),
   
  });
}