import React, { useState, useEffect, useRef } from "react";
import {
  Text,
  Box,
  Image,
  Heading,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import {
  PrimaryButton,
  Toaster,
} from "../../components";
import { useLocation } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { useNavigate } from "react-router-dom";
import {
    FetchGenericDocument,
} from "../../middleware/services/myhealth/uploaddocument";

import {
  formatDate_DD_MM_YYYY,
  getLoginInfo,
  getUserID,
} from "../../auth";
import moment from "moment";
import { MessageToaster } from "../../components/manualmessage";
import DynamicModal from "../../components/dynamic-modal";

function GenericUpload() {
  const location = useLocation();
  var navigate = useNavigate();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  var [data, setData] = useState(null);
  var [actualdata, setActualData] = useState(null);
  var [name, setName] = useState("");
  var [age, setAge] = useState("");
  var [doctorname, setDoctorName] = useState("");
  var [reportdate, setReportDate] = useState("");
  var [reportname, setReportName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [showpreview, setShowPreview] = useState(true);
  const [file, setFile] = useState(location.state.file);
  const { showMessageToast, closeMessageToast } = MessageToaster();
  const { showSuccessToast, showErrorToast } = Toaster();
  const consentForm = useRef();
  const fileInputRef = useRef(null);
  const modalBodyRef = useRef();
  const [boolVal, setBoolVal] = useState(false);
  const [type , setType] = useState("")

  const [upgradePlanModal , setUpgradePlanModal] = useState(false)

  useEffect(() => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const fileName = file.name.split(".").pop().toLowerCase(); // Use 'split' instead of 'Split', and 'pop' instead of 'Last'
      if (fileName === "pdf") {
        // Compare with 'pdf' instead of '.pdf'
        setSelectedImage("/assets/imgs/pdf.jpeg");
        setType("pdf")
      } else {
        setSelectedImage(reader.result);
        setType("")
      }
      consentForm.current.openModal();
    };
    reader.readAsDataURL(file);
    setFile(file);
  }, []);


  const fetchData = async () => {
    var loginid = getLoginInfo();
    var activeuserid = getUserID();
    showLoader();
    showMessageToast("Please wait while we are fetching data");
    let attempts = 0;
    const maxAttempts = 4;

    const makeFetchCall = async () => {
      try {
        if(type === "pdf"){
            const response = await FetchGenericDocument(file, file.name, file.type);
            if (response?.output?.message == "No credits are available"){
             // showErrorToast("No sufficient tokens available");
              setUpgradePlanModal(true)
          setTimeout(() => {
            setUpgradePlanModal(false)
          }, 100);
              closeMessageToast();
              dismissLoader();
              return false;
            }
            if (
              response.data.length === 0 || 
              (response.data.length > 0 && Object.keys(response.data[0]).length === 0) || 
              !response?.documenturl
            ) {
              showErrorToast("Something went wrong! please try again");
              closeMessageToast();
              dismissLoader();
              return false;
            }
           if(response.data.length > 0){
            closeMessageToast();
            dismissLoader();
            if(response.img_type == "Lab"){
                navigate("/uploaddocument", { state: { result: response, filename: file }})
              }
           }else{
            showErrorToast("Something went wrong! please try again");
            dismissLoader();
           }
           
          }else{
        const result = await FetchGenericDocument(file, file.name, file.type);
        if (result?.output?.message == "No credits are available") {
          // showErrorToast("No sufficient tokens available");
          setUpgradePlanModal(true)
          setTimeout(() => {
            setUpgradePlanModal(false)
          }, 100);
          closeMessageToast();
          dismissLoader();
          return false;
        }
        if (result && result.patient_info) {
          if (result && result.patient_info) {
            result.patient_info.patientid = loginid;
            result.patient_info.activeuserid = activeuserid;
            result.patient_info.documenturl = result.documenturl;

            if (
              result.patient_info.date_of_report == "" ||
              result.patient_info.date_of_report == "0000-00-00"
            ) {
              const today = moment().format("YYYY-MM-DD");
              result.patient_info.date_of_report = today;
            }
          }
          delete result.documenturl;
          closeMessageToast();
          dismissLoader();
          if(result.img_type == "Imaging"){
            if (result && result.report_info) {
                result.report_info.type = "imaging";
            }
            navigate("/imagingupload", { state: { result: result, filename: file }})
          }else if(result.img_type == "Prescription"){
            navigate("/prescriptionupload", { state: { result: result, filename: file }})
          }
          else if(result.img_type == "Lab"){
            navigate("/uploaddocument", { state: { result: result, filename: file }})
          }
        } else {
          attempts++;
          if (attempts < maxAttempts) {
            await makeFetchCall(); // Retry the call
          } else {
            closeMessageToast();
            handleError(); // Handle the error after max attempts
          }
        }
       }
      } catch (error) {
        console.error("Error in Upload Documents:", error);
        attempts++;
        if (attempts < maxAttempts) {
          await makeFetchCall(); // Retry the call
        } else {
          handleError(); // Handle the error after max attempts
        }
      }
    };

    const handleError = () => {
      showErrorToast("Something went wrong! please try again");
      setShowPreview(true);
      dismissLoader();
    };

    await makeFetchCall();
  };
  const backFunction = () => {
    navigate("/myhealth");
  };
  const openUploadImaging = () => {
    setBoolVal(false);
    fileInputRef.current.click();
  };
  const handleGenericUpload = async (e) => {
    e.preventDefault();
    //setFile("")
    const file = e.target.files[0];
    // Check if a file is selected
    if (!file) {
      showErrorToast("No file selected");
      return false;
    }

    console.log(e.target.files, " e.target.files");
    showLoader();
    navigate("/docupload", { state: { file } });
    const reader = new FileReader();
    reader.onloadend = () => {
      const fileName = file.name.split(".").pop().toLowerCase(); // Use 'split' instead of 'Split', and 'pop' instead of 'Last'
      if (fileName === "pdf") {
        // Compare with 'pdf' instead of '.pdf'
        setSelectedImage("/assets/imgs/pdf.jpeg");
      } else {
        setSelectedImage(reader.result);
      }
    };
    reader.readAsDataURL(file);
    setFile(file);
    setBoolVal(true);
    dismissLoader();
  };

  const submitconsentform = () => {
    consentForm.current.closeModal();
  };
  const closeconsentform = async () => {
    window.history.back();
  };
  return (
    <>
      <Box w="100%">
        {/* <SuccessIndex /> */}
        <Box top="0">
          <Navbar messageModal={upgradePlanModal} />
          {isLoading && <Loader />}
          <SecondaryNav withBack="Upload document" goBack={backFunction} />
        </Box>
        <Box
          w="100%"
          p="32px"
          bg="#E6E7E9"
          overflow="hidden auto"
          height="calc(100vh - 130px)"
        >
          <Box
            display="flex"
            pb="25px"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              w="calc(100vw - 70%)"
              boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;"
              h="auto"
              src={selectedImage}
            />
          </Box>

          <Box
            w="calc(100vw - 70%)"
            display="flex"
            marginInline="auto"
            alignItems="center"
            gap="20px"
            pos="sticky"
            bottom="0"
          >
            <PrimaryButton
              buttonText="Re-upload"
              id="imaging-upload"
              variant="grayBtnFull"
              onClick={openUploadImaging}
              inputStyleCss={{
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
                borderRadius: "10px",
              }}
            />
            <input
              type="file"
              id="imaging-upload"
              onChange={handleGenericUpload}
              ref={fileInputRef} // Set the ref for the file input
              accept="image/jpeg,image/jpg,image/png,image/tiff"
              style={{ display: "none" }} // Hide the file input
            />
            <PrimaryButton onClick={fetchData} buttonText="Confirm" />
          </Box>
        </Box>
      </Box>
      <DynamicModal
        ref={consentForm}
        modalAttributes={{ closeOnOverlayClick: false }}
        hasCloseBtn={false}
        customClose={true}
        customCloseClick={closeconsentform}
        modalStyle={{ minW: "600px", maxW: "600px", maxH: "100vh" }}
        modalHeader="Consent Form"
        modalBody={
          <>
            <Box
              style={{ height: "300px", overflowY: "scroll" }}
              p={6}
              h="calc(80vh - 100px)"
              overflow="hidden auto"
              lineHeight="1.6"
              ref={modalBodyRef}
            >
              <Heading as="h2" fontSize="1.5rem" mb={4}>
                Our Commitment to Privacy
              </Heading>

              <Text mb={4}>
                At Midoc, your privacy is paramount. We are committed to
                protecting the privacy of your personal information while
                providing you with the highest level of service. This Privacy
                Policy explains how Midoc, LLC ("Midoc," "we," or "us") may
                collect, use, and share your personal information when you
                access our services through the Midoc website or our mobile
                application (collectively, the "Services").{" "}
              </Text>

              <Heading as="h3" size="md" mb={2}>
                Consent
              </Heading>

              <Text mb={4}>
                By using our Services, you consent to the terms outlined in this
                Privacy Policy. If you do not agree with any part of this
                policy, please do not use our Services. Continued use of the
                Services following any changes to this policy will signify your
                acceptance of those changes.{" "}
              </Text>

              <Text mb={2}> Personal Information You Provide:</Text>
              <UnorderedList mb={4}>
                <ListItem>
                  <strong>Registration and Account Setup:</strong> We collect
                  personal details when you register or set up an account, which
                  may include your name, email address, phone number, and
                  address.
                </ListItem>
                <ListItem>
                  <strong>Health Records:</strong> Through our mobile
                  application, you may voluntarily provide medical and
                  health-related information to enhance service customization.
                </ListItem>
                <ListItem>
                  <strong>Interactions:</strong> We gather information when you
                  interact with our Services, such as customer service
                  inquiries, responses to surveys, and participation in
                  promotions.
                </ListItem>
              </UnorderedList>

              <Text mb={2}> Automated Information Collection:</Text>
              <UnorderedList mb={4}>
                <ListItem>
                  <strong>Cookies and Tracking Technologies:</strong> We use
                  cookies to enhance your experience, personalize content and
                  ads, and analyze our site traffic. We also employ web beacons
                  to collect aggregate statistics about website usage.
                </ListItem>
                <ListItem>
                  <strong>Geo-location Data:</strong> With your permission, we
                  may collect precise location data to provide location-specific
                  services.
                </ListItem>
              </UnorderedList>

              <Text mb={2}> How We Use Your Information</Text>
              <UnorderedList mb={4}>
                <ListItem>
                  <strong>Service Provision:</strong> To manage your account,
                  provide customer support, process transactions, and send
                  important account and service information.
                </ListItem>
                <ListItem>
                  <strong>Improvement and Personalization: </strong> To enhance
                  and personalize your experience, and to develop new features
                  and services that meet your needs.
                </ListItem>
                <ListItem>
                  <strong>Compliance and Protection:</strong>To comply with
                  legal requirements and protect the rights and safety of Midoc
                  and its users.
                </ListItem>
              </UnorderedList>

              <Text mb={2}> Sharing of Information</Text>
              <UnorderedList mb={4}>
                <ListItem>
                  <strong>With Service Providers:</strong>We share information
                  with third-party service providers to facilitate services on
                  our behalf, such as payment processing, data analysis, email
                  delivery, and hosting services.
                </ListItem>
                <ListItem>
                  <strong>Legal and Safety Reasons:</strong>We may disclose
                  information to respond to legal processes or to protect the
                  rights, property, or safety of Midoc, our users, or others.
                </ListItem>
                <ListItem>
                  <strong>Business Transfers: </strong>In the event of a merger,
                  sale, or asset transfer, your information may be disclosed and
                  transferred as part of the transaction.
                </ListItem>
              </UnorderedList>

              <Text mb={2}> Your Rights and Choices</Text>
              <UnorderedList mb={4}>
                <ListItem>
                  <strong>Account Information: </strong>You can review, update,
                  or delete your account information at any time.
                </ListItem>
                <ListItem>
                  <strong>Cookies: </strong> You can configure your browser to
                  reject cookies or to alert you when cookies are being sent.
                </ListItem>
                <ListItem>
                  <strong>Opt-Out: </strong>You may opt out of receiving
                  promotional communications from us by following the
                  instructions in the communication.
                </ListItem>
              </UnorderedList>

              <Heading as="h3" size="md" mb={2}>
                Children’s Privacy
              </Heading>

              <Text mb={4}>
                We do not knowingly collect or solicit information from anyone
                under the age of 13. If you are under 13, please do not attempt
                to register for the Services or send any personal information
                about yourself to us.
              </Text>

              <Heading as="h3" size="md" mb={2}>
                International Users.
              </Heading>

              <Text mb={4}>
                Our Services are hosted in the United States and are intended
                for users located within the United States. If you are located
                outside of the United States, please be aware that any
                information you provide to us will be transferred to and
                processed in the United States.
              </Text>

              <Heading as="h3" size="md" mb={2}>
                Changes to This Policy
              </Heading>

              <Text mb={4}>
                We may update this Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page. We encourage you to review our Privacy Policy
                whenever you access the Services to stay informed about our
                information practices and the ways you can help protect your
                privacy.
              </Text>

              <Text mb={4}>
                If you have any questions about this Privacy Policy or our
                privacy practices, please contact us at: privacy@midoc.com.
              </Text>
            </Box>
          </>
        }
        hasFooter={true}
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Agree" onClick={submitconsentform} />
          </>
        }
      />
    </>
  );
}

export default GenericUpload;
