import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import Loader from "../../components/loader/loader";
import ProfileSideBar from "./profileSidebar";
import useLoader from "../../components/loader/loaderstates";
import Footer from "../../components/footer"
import VideoPlayerModal from "../../components/videoplayer-modal";
import { FetchVideoLibraryFromAI, FetchVideoLibraryFromDB } from "../../middleware/services/profile/videolibrary";
import { point } from "@antv/g2plot";
import { SearchBar } from "../../components";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { useNavigate } from "react-router-dom";


const VideoIndex = () => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const videoplayerModal = React.useRef();
  const [data, setData] = useState("");
  const [videourl, setVideoURL] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [showhidesearch, ShowHideSearch] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    showLoader();
    var input = {};
    var fetchData = async () => {
      try {
        var result = await FetchVideoLibraryFromAI(input)
        var resultDB = await FetchVideoLibraryFromDB(input)
        console.log(result, " videos modified data")
        if (result) {
          const modifiedData = result.videos.map(video => ({
            ...video,
            "thumbnail": youtubeThumbnailMaker(video.embed, video.videoId)
          }));
          console.log(modifiedData)
          setData(modifiedData);
        } else {
          setData("")
          //  showErrorToast("Something went wrong!")
        }

      } catch (error) {
        console.log(error, "error in fetching account data")
      }
      dismissLoader();
    }

    fetchData();

  }, []);

  useEffect(() => {
    if (data) {
      const filteredData = data.filter((step) =>
        step.title && step.title.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredData(filteredData)
    }
  }, [searchValue, data])

  const youtubeThumbnailMaker = (embedLink, videoId) => {
    // const startIndex = embedLink.lastIndexOf('/') + 1;
    // const endIndex = embedLink.indexOf('?');
    // const videoId = embedLink.slice(startIndex, endIndex !== -1 ? endIndex : undefined);

    const thumbnailLink = `http://img.youtube.com/vi/${videoId}/0.jpg`

    return thumbnailLink
  }
  const openvideomodel = (url) => {
    setVideoURL(url);
    videoplayerModal.current.openModal();
  }
  const SearchIconClicked = () => {
    ShowHideSearch(true);
  };
  const closeSearchBar = () => {
    setSearchValue("")
    ShowHideSearch(false)
  }
  return (
    <>
      <Box w="100%" display={{base: "none", lg: "block"}}>
        <Box top="0" zIndex="banner">
          <Navbar />

          {isLoading && <Loader />}
          <SecondaryNav
            sideBarWidth="250px"
            defaultHeader="Profile"
            defaultSecondayHeader="Video Library"
            defaultNavBody={<>
              <Box as="button" mr="25px" onClick={SearchIconClicked}>
                <Image
                  p="10px"
                  borderRadius="10px"
                  border="1px solid #E6E7E9"
                  src="/assets/imgs/search.png"
                />
              </Box>
            </>}
          />
        </Box>

        <Box h="calc(100vh - 140px)" overflow="hidden auto">
          <Flex>
            <Box p="0" width="250px">
              <ProfileSideBar />
            </Box>


            {data !== null && data.length > 0  ? (
              <Flex bg="#fcfcfd" flexDirection="column" w="100%" p="24px 32px">

                <Box w="100%">
                  {showhidesearch && (
                    <Box mb="1rem" >

                      <SearchBar
                        passInputPlace="Search videos"
                        onChange={(e) => setSearchValue(e.target.value)}
                        iconSrc="/assets/imgs/remove-icon.png"
                        handleClick={closeSearchBar}
                      />
                    </Box>
                  )}

                </Box>
                <Flex w="100%" flexWrap="wrap" gap="20px">
                  {filteredData.length > 0 ? (
                    filteredData.map((step, index) => (
                      <Box key={index} w="calc(33.333% - 20px)" onClick={() => openvideomodel(step.embed)}>
                        <Box pos="relative">
                          <Image borderRadius="8px" src={step.thumbnail ? step.thumbnail : "/assets/video-library.jpg"} />
                          <Image pos="absolute" cursor="pointer" top="45%" right="45%" w="44px" h="auto" src="/assets/imgs/play-button.png" />
                          <Box as="span" pos="absolute" top="6%" right="6%" bg="#fcfcfd" border="1px solid #fcfcfd" borderRadius="full" fontSize={{ lg: "0.75rem", xl: "0.875rem" }} p="0.25rem 0.5rem">Sponsored</Box>
                        </Box>
                        <Text mt="10px" fontSize="14px" fontWeight="600">{step.title ? step.title : "-"}</Text>
                        {/* <Text mt="5px" lineHeight="normal" fontSize="12px" fontWeight="500" color="#384052">Learn how to easily upload and manage your prescriptions for a seamless healthcare experience.</Text> */}
                      </Box>
                    ))
                  ) : (
                    <Box
                      w="100%"
                      h="100vh"
                      display="flex"
                      flexDirection="column"
                      gap="5px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image w="60px" opacity="0.5" src="/assets/svgs/video-library.svg" />
                      <Box as="p">No data available</Box>
                    </Box>
                  )}
                </Flex>
              </Flex>
            ) : (
              <Box
                w="100%"
                h="100vh"
                display="flex"
                flexDirection="column"
                gap="5px"
                justifyContent="center"
                alignItems="center"
              >
                      <Image w="60px" opacity="0.5" src="/assets/svgs/video-library.svg" />
                <Box as="p">No data available</Box>
              </Box>
            )}

          </Flex>
          <Footer />
        </Box>
      </Box>

      {/* Mobile responsive start */}
      <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/profile')}
          header="Video library"
          bottomArea={<>
            <SearchBar
              passInputPlace="Search videos"
              onChange={(e) => setSearchValue(e.target.value)}
              handleClick={closeSearchBar}
            />
          </>}
        />

        <Flex bg="#fcfcfd" flexDir="column" p="1.5rem" h="calc(100vh - 260px)" gap="1.5rem" overflow="hidden auto">
          {filteredData.length > 0 ? (
            filteredData.map((step, index) => (
              <Box key={index} w="100%" onClick={() => openvideomodel(step.embed)}>
                <Box pos="relative">
                  <Image borderRadius="8px" src={step.thumbnail ? step.thumbnail : "/assets/video-library.jpg"} />
                  <Image pos="absolute" cursor="pointer" top="45%" right="45%" w="44px" h="auto" src="/assets/imgs/play-button.png" />
                  <Box as="span" pos="absolute" top="6%" right="6%" bg="#fcfcfd" border="1px solid #fcfcfd" borderRadius="full" fontSize="0.65rem" p="0.25rem 0.5rem">Sponsored</Box>
                </Box>
                <Text mt="10px" fontSize="14px" fontWeight="600">{step.title ? step.title : "-"}</Text>
                {/* <Text mt="5px" lineHeight="normal" fontSize="12px" fontWeight="500" color="#384052">Learn how to easily upload and manage your prescriptions for a seamless healthcare experience.</Text> */}
              </Box>
            ))
          ) : (
            <Box
              w="100%"
              h="100vh"
              display="flex"
              flexDirection="column"
              gap="5px"
              justifyContent="center"
              alignItems="center"
            >
              <Image w="60px" opacity="0.5" src="/assets/svgs/video-library.svg" />
              <Box as="p">No data available</Box>
            </Box>
          )}
        </Flex>
       </Box>
      <VideoPlayerModal
        ref={videoplayerModal}
        modalBody={
          <>
            <iframe width="900" height="450" src={videourl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </>
        }

      />
    </>

  )
}

export default VideoIndex