import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/navbar";
import SideBar from "../../components/sidebar";
import SecondaryNav from "../../components/seconday-nav";
import { Box, Divider, Flex, Text } from "@chakra-ui/layout";
import {
  Image,
  SimpleGrid,
  Checkbox,
  useSafeLayoutEffect,
  Stack,
  RadioGroup,
  Input,
  Heading
} from "@chakra-ui/react";
import { PrimaryButton, PrimaryButtonIcon, PrimaryInput, SearchBar, Toaster } from "../../components";
import DynamicModal from "../../components/dynamic-modal";
import ModalRight from "../../components/modal-right";
import { addMedicalConditions, deleteMedicalConditions, getMedicalConditions } from "../../middleware/services/myhealth/conditions";
import { getmedicalcondition } from "../../middleware/services/signup";
import { addVaccination, deleteVacinations, editVaccinations, getVaccinations, getVaccineList ,
  FetchVaccinationAIAnswer, FetchVaccinationAIPrompts
} from "../../middleware/services/myhealth/vaccination";
import moment from "moment";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import DatePicker from "../../components/date-picker";
import { format } from "date-fns";
import Footer from "../../components/footer";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { useNavigate } from "react-router-dom";
import TypingLoader from "../../components/typing-loader";
import AiModal from '../../components/ai-modal/index';
import { FetchMyHealthAIData} from "../../middleware/services/home";
import { displayMillion_ThoudandValues } from "../../auth";
import { FetchTokens } from "../../middleware/services/profile/rewards";

const VaccinationIndex = () => {
  const dynamicModal = React.useRef();
  const addCondition = React.useRef();
  const addCondition2 = React.useRef();

  const addCondition3 = React.useRef();

  const [data, setData] = useState('')
  var [conditionsList, setConditionsList] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [patientid, setPatientid] = useState("")
  const [selectedDates, setSelectedDates] = useState({});
  const [selectedDueDates, setSelectedDueDates] = useState({});
  const [condition, setCondition] = useState('')
  const [vaccineid, setVaccineid] = useState('')
  const [id, setId] = useState('')
  const [vaccineName, setVaccineName] = useState("")
  const [adminDate, setAdminDate] = useState("")
  const [dueDate, setDueDate] = useState("")

  const { isLoading, showLoader, dismissLoader } = useLoader();
  
  const scrollDiv = useRef(null);
  const navigate = useNavigate()
  const chatContainerRef = useRef(null);
  const [aianswers, setAIAnswers] = useState([]);
  const [remainingquestions, setRemainingQuestions] = useState([]);
  const [aiquestion, setAIAnalysis] = useState(null);
  const [AiInput, setAiInput] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [animate, setAnimate] = useState(false);
  const AIChatModel = useRef();

  
  const [upgradePlanModal , setUpgradePlanModal] = useState(false)
  const [remainingTokens, setRemainingTokens] = useState();

  useEffect(() => {
    getTokens();
  }, [])
  var input = {}
  var getTokens = async () => {
    try {
      var result = await FetchTokens(input);
      if (result) {
        setRemainingTokens(result)
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.log(error, "error in fetching account data")
    }
    //dismissLoader();
  }
  var inputForGet = {

  }

  useEffect(() => {

    fetchData();
  }, [])

  const fetchData = async () => {
    showLoader();
    try {
      const result = await getVaccinations(inputForGet);
      if(result && result.output && result.output.result == "failure"){
        setData(null)
      }else if (result.output.data == null) {
        setData(null)
      } else if (result.output.data != null) {
        setData(result.output.data);
      }else {
        showErrorToast("Something went wrong!")
      }

    } catch (error) {
      console.error("Error in getVaccinations:", error);
    }
    dismissLoader();
  };

  const fetchMedicalConditionList = async () => {
    try {
      const result = await getVaccineList({
        type: "vaccine",
      });
      if (result.output.result == "success") {
        setConditionsList(result.output.data);
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.error("Error", error);
    }
  };




  const handleCheckboxChange = (condition) => {
    const isSelected = selectedConditions.some(
      (item) => item.condition_cd === condition.condition_cd
    );

    if (isSelected) {
      setSelectedConditions((prevConditions) =>
        prevConditions.filter(
          (item) => item.condition_cd !== condition.condition_cd
        )
      );
    } else {
      setSelectedConditions([...selectedConditions, condition]);
    }
  };
  const [minDateState , setMinDateState] = useState('')
  const [maxDateState , setMaxDateState] = useState('')
  const handleDateChange = (conditionCd, date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setMinDateState(date)
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [conditionCd]: formattedDate,
    }));
  };
  const handleDueDateChange = (conditionCd, date) => {
    setMaxDateState(date)
    const formattedDate = format(date, "yyyy-MM-dd");
    setSelectedDueDates((prevDates) => ({
      ...prevDates,
      [conditionCd]: formattedDate,
    }));
  };

  var inputForAdd = {
    "vaccination": selectedConditions.map((condition, index) => ({
      "vaccine_cd": condition.condition_cd,
      "vaccinename": condition.condition,
      "admindate": selectedDates[condition.condition_cd] || null,
      "duedate": selectedDueDates[condition.condition_cd] || null,

    })),
  }

  const AddMedicalConditions = () => {
    if (selectedConditions.length === 0 && typedCondition == "") {
      showErrorToast("Please Select a Vaccine to Continue")
    } else {

      // enter manually 
      if (typedCondition !== "") {
        const newSelectedCondition = {
          condition:typedCondition,
          condition_cd:null,
          conditiontype:"other"
        };
    
        setSelectedConditions((prevConditions) => {
          // Filter out any existing condition with the same type
          const filteredConditions = prevConditions.filter(
            (item) => item?.conditiontype !== "other"
          );
          // Add the new condition
          return [...filteredConditions, newSelectedCondition];
        });
      }

      //--------------------------------
      setSelectedDates({})
      setSelectedDueDates({})
      addCondition2.current.openRight()
    }

  }


  const AddMedicalConditionsCall = () => {
    const addConditions = async () => {
      showLoader();
      try {
        const result = await addVaccination(inputForAdd);
        if (result.output.result == "success") {
          showSuccessToast("Successfully Added")
          if (scrollDiv.current) {
           scrollDiv.current.scrollTop = 0;
         }
          fetchData();

          addCondition2.current.closeRight();
          addCondition.current.closeRight();
        } else {
          showErrorToast("Something went wrong!")
        }
      } catch (error) {
        console.error("Error in DashboardIndex:", error);
      }
      dismissLoader();
    };

    const isDateMissing = selectedConditions.some(
      // (condition) => !selectedDates[condition.condition_cd] || !selectedDueDates[condition.condition_cd]
      (condition) => !selectedDates[condition.condition_cd] 
    );

    if (isDateMissing) {
      showErrorToast("Please select admin date");
      return false;
    } else {
      addConditions();
    }

  }

  const { showSuccessToast, showErrorToast } = Toaster();
  var inputForDelete = {
    vaccineid,
  }
  const deleteMedicalCondition = async () => {

    const deleteCondition = async () => {
      showLoader();
      try {
        const result = await deleteVacinations(inputForDelete);
        if (result.output.result == "success") {
          showErrorToast(condition + " has been Deleted")
          fetchData();
          dynamicModal.current.closeModal();
        } else {
          showErrorToast("Something went wrong!")
        }
        dismissLoader();
      } catch (error) {
        console.error("Error in vaciations:", error);
      }
    };
    deleteCondition();
  }

  const openDeleteModal = (id, condition) => {

    setVaccineid(id)
    setCondition(condition)

    dynamicModal.current.openModal();

  }
  const openVaccinationsModal = () => {
   // if (conditionsList == null) {
      fetchMedicalConditionList();
   // }
    setSearchValue('')
    setSelectedConditions([])
    setSelectedDueDates({})
    setTypedCondition("")
    addCondition.current.openRight()
  }

  const openEditModal = (id, vaccinename, admindate, duedate) => {
    // const formattedAdminDate = admindate.split('T')[0];
    // const formattedDueDate = duedate.split('T')[0];

    var formatedAdminDate = moment(admindate).format("DD-MM-yyyy");
    if(duedate){
      var formatedDueDate = moment(duedate).format("DD-MM-yyyy");
    setDueDate(formatedDueDate);
    }
    setId(id);
    setVaccineName(vaccinename);
    setAdminDate(formatedAdminDate);

    addCondition3.current.openRight()

  }




  const EditVaccineDates = async () => {

    const editVaccineDatesFunction = async () => {
      let formatedAdminDate = moment(adminDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
      let formatedDueDate = moment(dueDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
      //  alert(adminDate)

      var inputForEdit = {
        "vaccine": [
          {
            "vaccineid": id,
            "admindate": formatedAdminDate,
            "duedate": formatedDueDate,
          },
        ]
      }
      try {
        showLoader();
        const result = await editVaccinations(inputForEdit);
        if (result.output.result == "success") {
          showSuccessToast("Updated Successfully")
          fetchData();
          addCondition3.current.closeRight()
        } else {
          showErrorToast("Something went wrong!")
        }
      } catch (error) {
        console.error("Error in editVaccineDatesFunction:", error);
      }
    };
    editVaccineDatesFunction();

    fetchData();

  }
  const handleEditAdminDate = (selectedDate) => {
    //  const formattedDate = format(selectedDate, "yyyy-MM-dd");
    setAdminDate(selectedDate)
  }
  const handleEditDueDate = (selectedDate) => {

    //  const formattedDate = format(selectedDate, "yyyy-MM-dd");
    setDueDate(selectedDate)
  }

  const formatDate = (dateString) => {
    return moment(dateString).format('Do MMM, YYYY');
  };

  const [typedCondition , setTypedCondition] = useState("")
  const handleCondition = (e) => {
    const inputText = e.target.value;
    const maxLength = 80;
  
    if (inputText.length <= maxLength) {
      setTypedCondition(inputText);
    }
  
  }
  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [animate]);
  
  useEffect(() => {
    // Scroll to bottom whenever aianswers changes
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [aianswers]);
  const fetchAiDoctorPrompts = async (vaccinename)=>{
    showLoader();
    setRemainingQuestions([])
    setAiInput(null);
    setAIAnswers([])
    setInitialRender(true)
    const result = await FetchMyHealthAIData({
      patientid,
    });
    let Aiprompts = { ...result, vaccinename };
      setAiInput(Aiprompts);
      const response = await FetchVaccinationAIPrompts(Aiprompts);
      if (response.generated_question) {
        setAIAnalysis(response);
        setRemainingQuestions(response.generated_question);
        AIChatModel.current.openRight();
      }else{
       // showErrorToast("Ai tokens are not available");
       setUpgradePlanModal(true)
       setTimeout(() => {
         setUpgradePlanModal(false)
       }, 100);
        AIChatModel.current.closeRight();
      }
      dismissLoader();
  }
  
  const GetAIAnswer = async (prompt) => {
    // Display question immediately
    const newQuestion = { question: prompt, answer: "" };
    setAIAnswers((prevState) => [...prevState, newQuestion]);
  
    // Remove the question from remaining questions immediately
    setRemainingQuestions((prevState) => prevState.filter((question) => question.value !== prompt));
  
    // Trigger animation
    setAnimate(true);
  
    // Set initialRender to false after the first question is clicked
    setInitialRender(false);
    let AichatInput = { ...AiInput, query:prompt };
  
  
    const response = await FetchVaccinationAIAnswer(AichatInput);
  
    if (response) {
      // Replace "typing" with actual answer
      setAIAnswers((prevState) =>
        prevState.map((item) =>
          item.question === prompt && item.answer === ""
            ? { question: item.question, answer: response.answer }
            : item
        )
      );
      getTokens();
    }else {
      // showErrorToast("Ai tokens are not available");
      setUpgradePlanModal(true)
      setTimeout(() => {
        setUpgradePlanModal(false)
      }, 100);
      AIChatModel.current.closeRight();
    }
  };
  return (
    <>
      <Box w="100%" display={{base: "none", lg: "block"}}>
        <Box top="0" zIndex="banner">
          <Navbar   messageModal={upgradePlanModal} />
          {isLoading && <Loader/>}
          <SecondaryNav
            defaultHeader="My Health"
            defaultSecondayHeader="Vaccinations"
            defaultNavBody={
              <>
                <Box display="flex" gap="10px" alignItems="center" pr="25px">
                {data != null && (
                  <PrimaryButton
                    fontSize="14px"
                    variant="mdBtn"
                    buttonText="Add Vaccine"
                    onClick={openVaccinationsModal}
                    btnIcon="/assets/imgs/add.png"
                  />
                )}
                </Box>
              </>
            }
          />
        </Box>
        <Box  ref={scrollDiv} h="calc(100vh - 140px)" overflow="hidden auto">
        <Flex>
          <Box position="sticky" top="0" zIndex="sticky" h="100vh">
            <SideBar />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap="20px"
            w="100%"
          >
            {data &&
          <Box w="100%" h="100%" p={4}>
              <Flex wrap="wrap" pt="20px" w="100%" justifyContent="left" gap="20px">
              {data && Array.isArray(data) && data.map(({ vaccinename, admindate, duedate,id }, innerIndex) => (
                  <Box key={innerIndex} flex="0 0 calc(33.333% - 20px)" mb="20px"
                      border="1px solid #E6E7E9"
                      borderRadius="12px"
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between">
                    <Box
                      as="button"
                      p="20px"
                      w="100%"
                      h="100%"
                      display="flex"
                      flexDir="column"
                      alignItems="stretch"
                      justifyContent="space-between"
                      >
                      <Box display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                          >
                            <Text fontSize="16px" textAlign="left" lineHeight="normal" fontWeight="600">
                              {vaccinename}
                            </Text>
                            <Text
                              fontSize="12px"
                              fontWeight="500"
                              textAlign="left"
                              color="#384052"
                            >
                                {formatDate(admindate)} <br />
                            </Text>
                          </Box>
                        </Box>
                        <Box display='flex' flexDir='column' gap="8px" alignItems="flex-end">
                        
                        <Box
                                  as="button"
                                  // onClick={}
                                  border="1px solid #E6E7E9"
                                  borderRadius="full"
                                  w="36px"
                                  h="auto"
                                  p="6px"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  onClick={()=>fetchAiDoctorPrompts(vaccinename)}
                                >
                                  <Image w="26px" src="/assets/svgs/ai-star-outline.svg" />
                                </Box>
                                <Box display='flex' w="100%" gap='0.5rem' mt='1rem' justifyContent='flex-end'>
                                <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              onClick={() => openEditModal(id, vaccinename , admindate , duedate)}
                            >
                              <Image w="13px" src="/assets/imgs/edit-gray.png" />
                            </Box>
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              onClick={() => openDeleteModal(id, vaccinename)}
                            >
                              <Image w="13px" src="/assets/imgs/delete.png" />
                            </Box>
                          </Box>
                        </Box>

                      </Box>
                        {/* <Divider my="15px" /> */}
                        <Text fontSize="12px" pt='15px' mt="10px" borderTop="1px solid #E6E7E9" fontWeight="500"textAlign="left"color="#384052">
                          Next Dosage: {duedate != null? formatDate(duedate):"-"}
                        </Text>
                    </Box>
                  </Box>
                ))}
              </Flex>
              <Divider />
            </Box>
            }

          {/* No Data page */}
          {data == null && (
            <Box w="100%" mt="-100px" h="100vh" display="flex" flexDirection="column" gap="5px" justifyContent="center" alignItems="center">
            <Image w="60px" opacity="0.5" src="/assets/svgs/syringe-nodata.svg" />
                <Box as="p">No data available</Box>
            <PrimaryButton fontSize="14px" variant="mdBtn" buttonText="Add Vaccine" btnIcon="/assets/imgs/add.png"
            onClick={openVaccinationsModal}/>
        </Box>  
          )}
          </Box>

           
        
        </Flex>
          <Footer />
        </Box>
        <DynamicModal
          ref={dynamicModal}
          modalHeader="Delete Vaccination?"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete "{condition}"
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                  onClick={deleteMedicalCondition}
              />
            </>
          }
        />

        <ModalRight
          ref={addCondition}
          header="Add vaccine"
          body={
            <>
                  <SearchBar passInputPlace="Search condition" onChange={(e) => setSearchValue(e.target.value)} />
                  <RadioGroup>
                    <Stack direction="column" gap="0">
                    <Box display="flex" justifyContent="end" w="100%">
  <Stack direction="column" gap="0" w="100%">
    {conditionsList && conditionsList.length > 0 ? (
      conditionsList.some(category =>
        category.data.some(condition =>
          condition.condition.toLowerCase().includes(searchValue.toLowerCase())
        )
      ) ? (
        conditionsList.map(category => (
          <React.Fragment key={category.category_id}>
            {category.data
              .filter(condition =>
                condition.condition.toLowerCase().includes(searchValue.toLowerCase())
              )
              .map(condition => (
                <label key={condition.condition_cd}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #e2e8f0"
                    p="15px"
                                    pl="5px"
                                    minH="50px"
                    width="100%"
                    sx={{
                                      _hover: { border: "2px solid #384052", borderRadius: "10px" },
                    }}
                  >
                    <Box
                      key={condition.condition_cd}
                      display="flex"
                      alignItems="center"
                    >
                      <Text fontSize="16px" lineHeight="normal" fontWeight="600">
                        {condition.condition}
                      </Text>
                    </Box>
                    <Box display="flex" justifyContent="end">
                      <Checkbox
                        colorScheme="green"
                        sx={{
                          ".chakra-checkbox__control": {
                            width: "20px",
                            height: "20px",
                            borderRadius: "5px",
                          },
                        }}
                                        onChange={() =>
                                          handleCheckboxChange(condition)
                                        }
                        isChecked={selectedConditions.some(
                                          (item) =>
                                            item.condition_cd ===
                                            condition.condition_cd
                        )}
                      ></Checkbox>
                    </Box>
                  </Box>
                </label>
              ))}
          </React.Fragment>
        ))
      ) : (
        <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
         No matching vaccinations found!
        </Box>
      )
    ) : (
      <p>Loading...</p>
    )}
     <Divider/>
          <br/>
          <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                   Other Vaccination
                  </Heading>
        <PrimaryInput
         inputPlace="Vaccination"
         variant="bothSide"
                onChange={handleCondition} inputValue={typedCondition}
                />
                <br/>
  </Stack>
</Box>
                    </Stack>
                  </RadioGroup>


                  <ModalRight
          ref={addCondition2}
          header="Add Vaccination"
          body={
            <>
    <Box display="flex" flexDir="column" gap="20px">
      {selectedConditions.map((condition, index) => (
        <Box key={index}>
          <Text fontSize="16px" py="10px" fontWeight="600">
            {condition.condition}
          </Text>

             <DatePicker 
                disableFutureDates={true}
                //minDate={null} 
                maxDate={maxDateState} 
                onDateChange={(selectedDateValue) => handleDateChange(condition.condition_cd, selectedDateValue)}
                placeholderProp="Admin Date*" /> 
            
            <DatePicker 
            disablePastDates={true}
                //minDate={minDateState}
                onDateChange={(selectedDateValue) => handleDueDateChange(condition.condition_cd, selectedDateValue)}
                placeholderProp="Due Date" />  
        </Box>
      ))}
    </Box>
  </>
          }
          footerActions={<>
             <Box
                w="100%"
                display="flex"
                flexDirection="row"
                gap="15px">

                <PrimaryButton
                buttonText="Add Vaccine"
                isDisabled={false}
                onClick={AddMedicalConditionsCall}
                />
            </Box>
          </>}
        />
                </>
          }
          footerActions={<>
             <Box
                w="100%"
                display="flex"
                flexDirection="row"
                gap="15px">

                <PrimaryButton
                buttonText="Continue"
                isDisabled={false}
                onClick={AddMedicalConditions}
                />
            </Box>
          </>}
        />



<ModalRight
          ref={addCondition3}
          header="Edit Vaccination "
          body={
            <>
    <Box display="flex" flexDir="column" gap="20px">
        <Box >
          <Text fontSize="16px" py="10px" fontWeight="600">
            {vaccineName}
          </Text>
         
              <DatePicker 
                disableFutureDates={true}
                minDate={null} 
                maxDate={new Date().toISOString()} 
                defaultDate={adminDate}
                onDateChange={(selectedDateValue) => handleEditAdminDate( selectedDateValue)}
                placeholderProp="Admin Date*"
                inputStyle={{borderRadius: "10px 10px 0 0"}} /> 

              <DatePicker 
                defaultDate={dueDate}
                minDate={new Date(new Date().setHours(0, 0, 0, 0))}
                onDateChange={(selectedDateValue) => handleEditDueDate( selectedDateValue)}
                placeholderProp="Due Date"
                inputStyle={{borderRadius: "0 0 10px 10px"}} />  

         

             

        </Box>
    
    </Box>
  </>
          }
          footerActions={<>
             <Box
                w="100%"
                display="flex"
                flexDirection="row"
                gap="15px">

                <PrimaryButton
                buttonText="Update Vaccine"
                isDisabled={false}
                onClick={EditVaccineDates}
                />
            </Box>
          </>}
        />
         <AiModal
        ref={AIChatModel}
      modalSize="420px"
      //icon="/assets/imgs/ai-doctor.png"
      header={<>
        <Box display="flex" flexDir='column' gap='5px'>
            <Box display='flex' gap='6px'>
              <Text fontSize="1rem"
                fontWeight="700" 
                backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)" 
                color="transparent" 
                backgroundClip="text"
              >Aira - AI</Text>
              <Box display='flex' alignItems='center' gap='5px' border='1px solid #0d7e54' bg='#d5ece3'  p='3px 5px' borderRadius='full'>
                <Image src='/assets/imgs/spark.png' /> 
                <Text fontSize='10px' fontWeight='500' color='#000'>{remainingTokens && remainingTokens.remainingtokens ? displayMillion_ThoudandValues(remainingTokens.remainingtokens):"0"} tokens available</Text>
              </Box>
            </Box>
            <Text fontSize="0.75rem" fontWeight="400">Chat with your AI health guide</Text>
          </Box>
      </>}
      headerStyle={{ p: "24px 32px", lineHeight: "normal" }}
      closeBtnStyle={{ top: "18px" }}
      footerStyle={{ maxH: "35%", overflow: "hidden auto", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      withoutBg={true}
      backDropBlur="10px"
      body={
        <>
          <Flex flexDirection="column" height="100%">
            <Box
              p="24px 32px"
              ref={chatContainerRef}
              className="chat-container"
              style={{ overflowY: "auto", maxHeight: 'calc(100vh - 160px)' }}
            >
              {initialRender && remainingquestions.length > 0 && (
                <Box>
                  {remainingquestions.map((step, index) => (
                    <Box
                      key={index}
                      as="button"
                      w="100%"
                      p="16px"
                      mb="16px"
                      fontSize="14px"
                      fontWeight="600"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius="10px"
                      border="1px solid #7795DC"
                      bg="#EAEDF5"
                      color="#163E9E"
                      onClick={() => GetAIAnswer(step.value)}
                      className={`question-box ${animate ? 'slide-in' : ''}`}
                    >
                      {step.value ? step.value : "-"}
                      <Image src="/assets/imgs/right-icon.png" />
                    </Box>
                  ))}
                </Box>
              )}
              {aianswers.map((step, index) => (
                <React.Fragment key={index}>
                  <Box
                    as="div"
                    bg="#061027"
                    p="12px 16px"
                    w="fit-content"
                    float="right"
                    borderRadius="10px 10px 1px 10px"
                    className={`answer-box ${animate ? 'slide-in' : ''}`}
                  >
                    <Text fontSize="14px" fontWeight="500" color="white">
                      {step.question ? step.question : "-"}
                    </Text>
                  </Box>

                  <Box
                    as="div"
                    my="20px"
                    w="fit-content"
                    display="flex"
                    alignItems="end"
                    float="left"
                    className={`answer-box ${animate ? 'slide-in' : ''}`}
                  >
                    <Image
                      bg="#061027"
                      p="4px"
                      mr="8px"
                      borderRadius="6px"
                      src="/assets/imgs/ai-chat-logo.png"
                    />
                    <Text
                      bg="#E6E7E980"
                      p="12px 16px"
                      fontSize="14px"
                      fontWeight="500"
                      color="#061027"
                      borderRadius="10px 10px 10px 1px"
                    >
                      {step.answer ? step.answer : <TypingLoader />}
                    </Text>
                  </Box>
                </React.Fragment>
              ))}
            </Box>
          </Flex>
        </>
      }
      footerActions={
        !initialRender && (
          <Box
            p="24px 32px"
            display="flex"
            w="100%"
            h="100%"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box w="100%">
              {remainingquestions.map((step, index) => (
                <Box
                  key={index}
                  as="button"
                  w="100%"
                  p="16px"
                  mb="16px"
                  fontSize="14px"
                  fontWeight="600"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius="10px"
                  border="1px solid #7795DC"
                  bg="#EAEDF5"
                  color="#163E9E"
                  onClick={() => GetAIAnswer(step.value)}
                  className={`question-box ${animate ? 'slide-in' : ''}`}
                >
                  {step.value ? step.value : "-"}
                  <Image src="/assets/imgs/right-icon.png" />
                </Box>
              ))}
            </Box>
          </Box>
        )
      }
      />
      </Box>

      {/* Mobile responsive start */}
      <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/myhealth')}
          header="Vaccinations"
        />
        {data && data !== null && (
        <Box pos="fixed" bottom="10%" display="flex" justifyContent="center" w="100%" zIndex="99">
          <PrimaryButton
            fontSize="14px"
            variant="mdBtn"
            buttonText="Add Vaccine"
            btnIcon="/assets/imgs/add.png"
            onClick={openVaccinationsModal}
            />
        </Box>
        )}

        <Box w="100%" p="1.5rem" pb="5rem" h='calc(100vh - 190px)' overflow="auto">
          {data &&
              <SimpleGrid
                columns={{base: 1, sm: 2}}
                gap="20px"
              >
                {data && Array.isArray(data) && data.map(({ vaccinename, admindate, duedate,id }, innerIndex) => (
                    <Box
                      key={innerIndex}
                      as="button"
                      p="20px"
                      w="100%"
                      h="100%"
                      display="flex"
                      flexDir="column"
                      alignItems="stretch"
                      justifyContent="space-between"
                      border="1px solid #E6E7E9"
                      borderRadius="12px"
                      >
                      <Box display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                          >
                            <Text fontSize="16px" textAlign="left" lineHeight="normal" fontWeight="600">
                              {vaccinename}
                            </Text>
                            <Text
                              fontSize="12px"
                              fontWeight="500"
                              textAlign="left"
                              color="#384052"
                            >
                                {formatDate(admindate)} <br />
                            </Text>
                          </Box>
                        </Box>
                          <Box display='flex' gap="8px" alignItems="center">
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              onClick={() => openEditModal(id, vaccinename , admindate , duedate)}
                            >
                              <Image w="13px" src="/assets/imgs/edit-gray.png" />
                            </Box>
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              onClick={() => openDeleteModal(id, vaccinename)}
                            >
                              <Image w="13px" src="/assets/imgs/delete.png" />
                            </Box>
                          </Box>
                      </Box>
                        {/* <Divider my="15px" /> */}
                        <Text fontSize="12px" pt='15px' mt="10px" borderTop="1px solid #E6E7E9" fontWeight="500"textAlign="left"color="#384052">
                          Next Dosage: {duedate != null? formatDate(duedate):"-"}
                        </Text>
                    </Box>
                  // </Box>
                ))}
              </SimpleGrid>            
            }

            {/* No Data page */}
            {data == null && (
              <Box w="100%" mt="-100px" h="100vh" display="flex" flexDirection="column" gap="5px" justifyContent="center" alignItems="center">
                <Image w="60px" src="/assets/svgs/health-icon.svg" />
                <Box as="p">No data available</Box>
                <PrimaryButton fontSize="14px" variant="mdBtn" buttonText="Add Vaccination" btnIcon="/assets/imgs/add.png" onClick={openVaccinationsModal} />
              </Box>
            )}
        </Box>
      </Box>
    </>
  );
};

export default VaccinationIndex;
