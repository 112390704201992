import { useState } from "react";

const useLoader = () => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoader = () => {
    setIsLoading(true);
  };

  const dismissLoader = () => {
    setIsLoading(false);
  };

  return { isLoading, showLoader, dismissLoader };
};

export default useLoader;
