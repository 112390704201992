import moment from "moment";
export default function formAPIModelFamilyAllergy({
    allergy,
    allergy_cd,
    allergyidentifieddate,
    allergytype,
    allergytype_cd,
    familyid,
    nomenclatureid,
    patientid
   

})
{
  return Object.freeze({
    condition : allergy,
    condition_cd : allergy_cd,
    conditionidentifieddate : allergyidentifieddate,
    condition_type : allergytype,
    conditiontype_cd : allergytype_cd,
    familyid,
    nomenclatureid,
    patientid

   
  });
}