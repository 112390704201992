import { Box, Center,Image,Text } from "@chakra-ui/react";
import { COLORS } from "../../components/styles/colors";
import { PrimaryButton, Toaster } from "../../components";
import { signUp, updatePatientProfile } from "../../middleware/services/signup";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FetchLogin } from "../../middleware/services/login";
import { saveToken,savePatientDetails,saveUsername,saveLoginID, savePatientImage, savePatientdob, savePatientgender } from "../../auth";
import Loader from "../../components/loader/loader";
import useLoader from "../../components/loader/loaderstates";


function SuccessIndex() {

  const [patientid, setPatientid] = useState('')
  const [issignupcompleted, setIssignupcompleted] = useState('1')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  
  const { showSuccessToast, showErrorToast } = Toaster();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const navigate = useNavigate();
  
  const location = useLocation();
  const { promoCode } = location.state || {};

  useEffect(() => {
    var patientID = localStorage.getItem("patientId")
    setPatientid(patientID)
  }, []);

  useEffect(() => {
    const password = sessionStorage.getItem('value');
    const email = sessionStorage.getItem('value2');
    setPassword(password)
    setEmail(email)
  }, [password]);


  const input = {
    issignupcompleted,
	  patientid,
    "referralcode":promoCode,
    "platformtype":"web"
  }

  const SubmitFunction = async() =>{
    showLoader();
    try {
      const result = await updatePatientProfile(input);
      const response = await FetchLogin({
        email,
        password
     });
     if(response.output.result == "success"){
     // showSuccessToast("Successfully Registered")
      setTimeout(() => {
       
      sessionStorage.removeItem('value');
      sessionStorage.removeItem('value2');
      localStorage.clear();
      if (response.output.token) {
        saveToken(response.output.token);
      }
      if(response.output.data){
        savePatientDetails(response.output.data)
      }
      // if (response.output.data.id) {
      //   saveLoginID(response.output.data.id);
      // }
      // if (response.output.data.patientimage) {
      //   const patientimage = response.output.data.imagepath+response.output.data.patientimage;
      //   savePatientImage(patientimage);
      // }
      // if (response.output.data.gender) {
      //   const gender = response.output.data.gender;
      //   savePatientgender(gender);
      // }
      // if (response.output.data.dob) {
      //   const dob = response.output.data.dob;
      //   savePatientdob(dob);
      // }
      sessionStorage.setItem('NewUser', 'true');
       navigate('/home');
      }, 500);
 
     }else{
      console.log("error in sign in")
     }
      
    } catch (error) {
      console.error('Error in SubmitFunction:', error);
    }
    dismissLoader();  
  }


 

    return (
        <Box
        w="100vw"
      h="100vh"
      bg={`url('/assets/imgs/success-bg.png'), ${COLORS.SIGNUP_SUCCESS}`}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      >
        
        {isLoading && <Loader />}
        <Box bgImage="url('/assets/imgs/login-bg.png')"
        boxShadow={COLORS.BOX_SHADOW_SUCCESS}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover">
        </Box>
        <Center
          h="100vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
            <Box>
                <Image src="/assets/imgs/success-alert.png" />
            </Box>
            <Box>
            <Box as="h1" fontSize="28px" textAlign="center" mt="20px">Success!</Box>
            <Box mb="16px" textAlign="center" fontWeight="400">
              <Text>Your account has been successfully created</Text>
            </Box>
            </Box>
            <Box>
                <PrimaryButton buttonText="Continue" onClick={SubmitFunction} />
            </Box>
            </Center>
            </Box>
            
    )
}

export default SuccessIndex;