import { COLORS } from "../styles/colors";

const tabContainer = {
  bg: "#E6E7E980",
  padding: '1',
  borderRadius: "10px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  gap: "5px",
}


const tabBtn = {
  borderRadius: "8px",
  width: "100%",
  height: "32px",
  minWidth: "140px",
  // margin: "10px 0",
  color: "#061027",
  cursor: "pointer",
  background: "#FCFCFD",
  _disabled: {
    bg: COLORS.BTN_DISABLE,
    color: COLORS.BTN_DISABLE_TEXT,
    cursor: "not-allowed",
  },
  _hover: {
    backgroundImage: "-webkit-linear-gradient(top, #FCFCFD, #E6E7E9)",
    backgroundImage: "-moz-linear-gradient(top, #FCFCFD, #E6E7E9)",
    backgroundImage: "-ms-linear-gradient(top, #FCFCFD, #E6E7E9)",
    backgroundImage: "-o-linear-gradient(top, #FCFCFD, #E6E7E9)",
    backgroundImage: "linear-gradient(to bottom, #FCFCFD, #E6E7E9)",
    // bg: COLORS.PRIMARY_COLOR,
  },
  '&[aria-selected="true"]': {
    bg: "white", 
    color: "black", 
    boxShadow: "0px 1px 3px #E6E7E9",
  },
  '&[aria-selected="false"]': {
    bg: "none",
    color: "#898D97",
  },
};

export {tabContainer, tabBtn}