import axios from 'axios';
import { CONFIG } from '../../../appconfig/appconfig';
import  FetchData, { } from '../../client';
import formapimodalaccount from '../../domains/profile';
import {getLoginInfo, getUserID}  from '../../../auth';


const FetchAccountData = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_getpatientdetails", inputData);
    
     if (result.output.data) {
     var finalOutput = formapimodalaccount(result.output.data)
     }
     return finalOutput;

  } catch (error) {
    console.error('Error Account fetching :', error);
    throw error;
  }
};

const updatePatientProfile = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_updatepatientprofile", inputData);
     return result;

  } catch (error) {
    console.error('Error  in updating patient profile :', error);
    throw error;
  }
};

const imageUpload = async (inputData) => {
  try {
    
    const result = await FetchData("midoc_documentupload", inputData);
     return result;

  } catch (error) {
    console.error('Error  in updating patient profile :', error);
    throw error;
  }
};


// const uploadImageInProfile = async (uri,name) => {
//   // alert(uri)
//    try {     
//      const formData = new FormData();
//      formData.append("file", uri);
//      const response = await axios.post(`${BaseUrl.APIBaseUrl}midoc_documentupload`,formData, {    
//        headers: {
//          'Content-Type': 'multipart/form-data', 
//        },
//      });
//      //console.log('@@response',response)
//      if (response.statusText === "OK") { 
//        const result = await response.data;
//        //console.log('@@result',result)
//        return result
//      } else {
//        const error = "failed" ;
//        return error
//      }
//    } catch (err) {
//      console.log(err); 
//      const error = "failed" ;
//      return error
//    }
//  };
const uploadImageInProfile = async (file, filename) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', filename);

    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_documentupload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.statusText === 'OK') {
      const result = await response.data;
      return result;
    } else {
      const error = 'failed';
      return error;
    }
  } catch (err) {
    console.log(err);
    const error = 'failed';
    return error;
  }
};

const FetchRefferal = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_getreferral", inputData);
    
    //  if (result.output.data) {
    //  var finalOutput = formapimodalaccount(result.output.data)
    //  }
     return result;

  } catch (error) {
    console.error('Error Account fetching :', error);
    throw error;
  }
};
export {FetchAccountData, updatePatientProfile,imageUpload, uploadImageInProfile, FetchRefferal};
