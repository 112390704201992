import { Box, Flex, Button, Image, Spacer, Text, VStack, SimpleGrid } from "@chakra-ui/react"
import Navbar from "../../components/navbar"
import SecondaryNav from "../../components/seconday-nav"
import TabSection from "../../components/tabs"
import DynamicTable from "../../components/dynamic-table"
import { PrimaryButton, PrimaryButtonIcon, Toaster } from "../../components"
import ReportViewCard from "../../components/report-card"
import ModalRight from '../../components/modal-right'
import React, { useEffect, useRef, useState } from "react"
import DynamicModal from "../../components/dynamic-modal"
import FilterButton from "../../components/filter-button"
import { useNavigate } from "react-router-dom"
import { DeletePatientLabReport, FetchLabsData, FetchLabsDataForReportsView } from "../../middleware/services/myhealth/labs"
import { FetchImagingData } from "../../middleware/services/myhealth/imaging"
import useLoader from "../../components/loader/loaderstates"
import Loader from "../../components/loader/loader"
import moment from "moment"
import Footer from "../../components/footer"
import { downloadDocument, formatDate_Do_MMM_YYYY, useScreenType } from "../../auth"
import MobileNavbar from "../../components/mobile-navbar"
import MobileSecondaryNav from "../../components/mobile-secondary-navbar"

function ImagingIndex() {
    const rightModal = React.useRef();
    const dynamicModal = React.useRef();
    const openCustomModal2 = React.useRef();

 const [type, setType] = useState("imaging")
 const [formattedData, setFormattedData] = useState("");
 const [formattedDataImaging, setFormattedDataImaging] = useState("");
 const [dataForTableImaging, setDataForTableImaging] = useState("");
 const [formattedDataForReportView, setFormattedDataForReportView] = useState(null);
 const [fetchError, setFetchError] = useState(false);
 const [activeTab, setActiveTab] = useState('TableViewTab'); 
 const [reportid, setReportid] = useState([]);
 const [headerName, setHeaderName] = useState("");
 const [reportName, setReportName] = useState("");
 const [documentDates, setDocumentDates] = useState([]);
 const [documentURLs, setDocumentURLs] = useState([]);
 const [documentUrlImaging , setDocumentUrlImaging] = useState("")
 const [reportDescription , setReportDescription] = useState("")
 const [reportDate , setReportDate] = useState("")
 
 const [header, setHeader] = useState("");
 const [normalResultText, setNormalResultText] = useState("");
 const [abnormalResultText, setAbnormalResultText] = useState("");
 const [interpretitionText, setInterpretitionText] = useState("");
 const [date, setDate] = useState("");
 const modalLearnMore = React.useRef();

const { showSuccessToast, showErrorToast } = Toaster();
const {isLoading, showLoader, dismissLoader } = useLoader();
var navigate = useNavigate();
const screenType = useScreenType()

    const input = {
        type
    }

  useEffect(() =>{

    const fetchData = async () => {
      showLoader();
       try {
       const imagingData = await FetchImagingData(input)
       setFormattedData(imagingData)
       
      const dataForReportsViewImaging = {
        rows: imagingData?.map((item) => ({
         reportname: item.reportname ? item.reportname : "-" ,
           reportid: item.reportid,
           documetURL :  item.imagepath+item.documenturl,
        //  reportname: item.reportname,
          reportdescription: item.reportdescription,
          documentdate: item.documentdate,
          verifieddate: item.verifieddate,
          results_interpretation: item.results_interpretation,
          uniqueURLs: Array.from(new Set(imagingData
            .filter((report) => report.reportid == item.reportid)
            .map((report) => report.documenturl))),

        })),
      };
      setFormattedDataImaging(dataForReportsViewImaging);

      const dataForTable = {
        rows: imagingData.map((item) => ({
          header: item.reportname ? item.reportname : "-",
          // values: [
          //   {
          //     resultvalue: item.resultvalue || "",
          //     documentdate: item.verifieddate || "-",
          //   }
          // ],
          values: item.data.map((value) => ({
            
          "header": item.reportname ? item.reportname : "-",
            "resultvalue": value.resultvalue || "",
                 "documentdate": value.verifieddate || "-",
                  "results_interpretation" : value.results_interpretation ||  [],
          })),
          reportid: item.data.map((value) => value.documentid),
          documetURL :  item.data.map((value) => value.imagepath+value.documenturl),
          reportname: item.data.map((value) => value.reportname),
        }))
      };
      setDataForTableImaging(dataForTable);

       const labsDataForReportView = await FetchLabsDataForReportsView(input);
       const dataForReportsView = {
         rows: labsDataForReportView?.map((item) => ({
         reportname: item.reportname ? item.reportname : "-" ,
           values: item.data.map(
             (value) =>
               `${value.documenturl || ""} ${value.documentdate || ""}`
           ),
           reportid: item.data.map((value) => value.reportid),
           documetURL :  item.data.map((value) => value.imagepath+value.documenturl),
         })),
       };
       setFormattedDataForReportView(dataForReportsView)

       } catch (error) {
           console.log(error , " error in fetching  imaging data")
           setFetchError(true);
       }
       dismissLoader();
    }

    fetchData();
  },[]);



    const backFunction =()=>{
        navigate("/myhealth")
      }
    
    const handleTabClick = (index) => {
  showLoader();
      switch (index) {
        case 0:
          setActiveTab('TableViewTab');
          break;
        case 1:
          setActiveTab('ReportViewTab');
          break;
        default:
          break;
      }
      dismissLoader();
    };

    
    const openDeletePopup = (reportidArray, reportname, verifieddate) => () => {
      setReportid(reportidArray);
      setReportName(reportname)
      setVerifiedDate(verifieddate)
      dynamicModal.current.openModal();
    };

    const deletePatientReports = async () => {
      showLoader();
      try {
        const reportIds = Array.isArray(reportid) ? reportid : [reportid];
    
        for (const id of reportIds) {
          const inputForDelete = {
            reportid: [{ id }],
          };
           var result = await DeletePatientLabReport(inputForDelete);
           showSuccessToast("Deleted Reports Successfully")
           
       const imagingData = await FetchImagingData(input)
       setFormattedData(imagingData)
     
      const dataForReportsViewImaging = {
        rows: imagingData?.map((item) => ({
          reportname: item.reportname ? item.reportname : "-" ,
           reportid: item.reportid,
           documetURL :  item.imagepath+item.documenturl,
        //  reportname: item.reportname,
          reportdescription: item.reportdescription,
          documentdate: item.documentdate,
          verifieddate: item.verifieddate,
          uniqueURLs: Array.from(new Set(imagingData
            .filter((report) => report.reportid == item.reportid)
            .map((report) => report.documenturl))),
        })),
      };
      if (dataForReportsViewImaging && dataForReportsViewImaging.rows !== undefined ){
        setFormattedDataImaging(dataForReportsViewImaging);
      }else{
        setFormattedDataImaging("");
        
      }
     
      const dataForTable = {
        rows: Array.isArray(imagingData)
          ? imagingData.map((item) => ({
            header: item.reportname ? item.reportname : "-",
            // values: [
            //   {
            //     resultvalue: item.resultvalue || "",
            //     documentdate: item.verifieddate || "-",
            //   }
            // ],
            values: item.data.map((value) => ({
              "header": item.reportname ? item.reportname : "-",
                "resultvalue": value.resultvalue || "",
                     "documentdate": value.verifieddate || "-",
                      "results_interpretation" : value.results_interpretation ||  [],
            })),
            reportid: item.data.map((value) => value.documentid),
            documetURL :  item.data.map((value) => value.imagepath+value.documenturl),
            reportname: item.data.map((value) => value.reportname),
            }))
          : [], 
      };
      
      if (dataForTable && dataForTable.rows !== undefined ){
        setDataForTableImaging(dataForTable);
      }else{
        setDataForTableImaging("");
      }
             }
           } catch (error) {
             console.error(
               `Error deleting report with reportid: ${reportid}`,
               error
             );
           }
  dynamicModal.current.closeModal();
  rightModal.current.closeRight();
  openCustomModal2.current.closeRight();
  dismissLoader();
 };

 

 const openModalRightWithData = (header, values, documentURL, reportname) => () => {
   setHeaderName(header);
   setDocumentDates(values);
   setDocumentURLs(documentURL)
   setReportName(reportname)
   rightModal.current.openRight();
 };
const [verifiedDate, setVerifiedDate] = useState("")
 
 const openModalRightImaging = ( description , documentURL, reportname, reportdate, reportid, verifieddate, documentdate) => () => {
// alert(documentdate)
  setDocumentUrlImaging(documentURL)
   setReportName(reportname)
   setReportDescription(description)
   setReportDate(reportdate)
   setReportid(reportid)
   setVerifiedDate(verifieddate)
   openCustomModal2.current.openRight();
 };
 
 const fileInputRef = useRef(null);
 const handleImagingUpload = async (e) => {
  e.preventDefault();
  const file = e.target.files[0];
  showLoader();
  navigate("/imagingupload", { state: { file } });
  dismissLoader();

};
const openUploadImaging = () => {
  fileInputRef.current.click(); 
}
const formatDate = (dateString) => {
  return moment(dateString).format('Do MMM, YYYY');
};

const openLearnMoreModal = (modalData)=>{
  if(modalData){
    setHeader(modalData.reportname)
    setDate(formatDate_Do_MMM_YYYY(modalData.documentdate))
    setNormalResultText(modalData.results_interpretation.normal_results)
    setAbnormalResultText(modalData.results_interpretation.abnormal_results)
    setInterpretitionText(modalData.results_interpretation.results_interpretation)
  }
  modalLearnMore.current.openRight()
}

    return (
      <>
      {screenType == 'web' ? (
       <Box w="100%">
            <Box position="sticky" top="0" zIndex="sticky">
                <Navbar />
        {isLoading && <Loader/>}
                <SecondaryNav 
                withBack="Imaging"
                goBack={backFunction}
                withBackBody={<>
                <Box display="flex" gap="10px" alignItems="center" pr="25px">
                <TabSection  tabs={["Table view", "Reports view"]}
                     onClickTab={handleTabClick} />
                       {!fetchError  && (
                    <PrimaryButton fontSize="14px" variant="mdBtn" buttonText="Upload report" btnIcon="/assets/imgs/add.png" onClick={openUploadImaging}/>
                       )}
                         <input
        type='file'
        id='file-upload'
        onChange={handleImagingUpload}
        ref={fileInputRef}
        accept="image/jpeg,image/jpg,image/png,image/tiff,application/pdf"
        style={{ display: 'none' }} 
      />
                </Box>
                </>} />
            </Box>
            <Box>
            <Flex bg="#fcfcfd" flexDirection="column" overflow="hidden auto" height="calc(100vh - 180px)">
                
                {activeTab === 'TableViewTab' ? (
                  formattedData !== null &&(
                 <DynamicTable TableData={dataForTableImaging} 
                  withModal={true}
                 /> )
                 ) : (formattedDataImaging !== null && (
                <Flex wrap="wrap" p="20px" w="100%" justifyContent="flex-start" gap="20px">
                    
                     {formattedDataImaging && (formattedDataImaging.rows != undefined || null) && formattedDataImaging.rows.map((row, index) => (
        <Box key={index} flex="0 0 calc(33.333% - 20px)" mb="20px">
          <ReportViewCard
            cardHeader={row.reportname}
            headerClick={()=>openLearnMoreModal(row)}
            cardSecondary={formatDate( row.verifieddate) }
            cardBtn={row.uniqueURLs.length < 2 ? row.uniqueURLs.length + " document" :  row.uniqueURLs.length + " documents" }
             cardText={row.reportdescription}  
           onClick={openModalRightImaging( row.reportdescription, row.documetURL, row.reportname, row.documentdate, row.reportid, row.verifieddate, row.documentdate)}
       
          deleteBtn={openDeletePopup(row.reportid, row.reportname, row.verifieddate)}
          />
        </Box>
      ))}
                    
                </Flex>
                 )
                )}
                {!formattedData   && (
               <Box w="100%" h="100vh" display="flex" flexDirection="column" gap="5px" justifyContent="center" alignItems="center">
                    <Image w="60px" src="/assets/svgs/hand_bones.svg" />
                    <Box as="p">No data available</Box>
                    <PrimaryButton fontSize="14px" variant="mdBtn" buttonText="Upload report" btnIcon="/assets/imgs/add.png" onClick={openUploadImaging}/>
                </Box> 
                )}
            </Flex>
            <Footer />
            </Box>
            <ModalRight 
                ref={rightModal}
                secondaryHeader="Hemoglobin"
                subHeader="10th Jan, 2023"
                body={<>
                    <Flex pt="15px" flexDirection="column" h="100%">
                        <Box as="p" pb="20px" fontSize="14px" fontWeight="500">
                        Lumbar Spine MRI indicates mild disc degeneration at the L4-L5 level, contributing to occasional lower back discomfort.
                        </Box>
                        <Box>
                            <Button w="100%" h="48px" borderRadius="10px" border="1px solid #E6E7E9" display="flex" justifyContent="space-between" bg="white" color="#1C4EC5" sx={{_hover: {bg:"none"}}}>
                                12/01/24_Report.pdf
                                <Box as="span">
                                    <Image src="/assets/imgs/download.png" />
                                </Box>
                            </Button>
                        </Box>
                        <Spacer />
                        <Box>
                            <PrimaryButtonIcon buttonText="Delete"
                            //  onClick={deletePatientReports}
                             color="#AA2E26" />
                        </Box>
                    </Flex>
                </>}
                />

            <DynamicModal 
            ref={dynamicModal}
            modalHeader="Delete report?"
            modalBody={
                <>
                    <Text fontSize="14px" fontWeight="600">Are you sure you want to delete - <strong>"{reportName}"</strong> from {formatDate(verifiedDate) }</Text>
                </>
            }
            modalFooterBtn={
                <>
                    <PrimaryButton variant="deleteBtn"
                     buttonText="Delete" 
                      onClick={deletePatientReports}
                     />
                </>
            }
            />

<ModalRight
        ref={openCustomModal2}
        secondaryHeader={reportName}
        subHeader="10th Jan, 2023"
        body={
          <Flex pt="15px" flexDirection="column" h="100%">
        <Box  mb="2">
          <Button 
           as="a" 
          w="100%" h="48px" borderRadius="10px" border="1px solid #E6E7E9" display="flex" justifyContent="space-between" bg="white" color="#1C4EC5" sx={{_hover: {bg:"none"}}} 

          style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize:"12px" }}
          onClick={()=>downloadDocument(documentUrlImaging)}
          cursor="pointer"
                     
     >
     {reportName}
        <Box as="span">
          <Image src="/assets/imgs/download.png" />
        </Box>
          </Button>
        </Box>
      
            <Spacer />
            <Box>
              <PrimaryButtonIcon
                buttonText="Delete"
                  onClick={deletePatientReports} color="#AA2E26"
              />
            </Box>
          </Flex>
        }
      />
      <ModalRight
        ref={openCustomModal2}
        secondaryHeader={reportName}
        subHeader={formatDate(verifiedDate) }
        body={
          <Flex pt="15px" flexDirection="column" h="100%">
              <Box as="p" pb="20px" fontSize="14px" fontWeight="500">
                      {reportDescription}  </Box>
        <Box  mb="2">
          <Button 
           as="a"
          w="100%" h="48px" borderRadius="10px" border="1px solid #E6E7E9" display="flex" justifyContent="space-between" bg="white" color="#1C4EC5" sx={{_hover: {bg:"none"}}} 

          style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize:"12px" }}
          onClick={()=>downloadDocument(documentUrlImaging)}
          cursor="pointer"           
     >
     <Box as="span" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" w="80%">
     {reportName} 
     <br/>
     <p style={{color:"black", fontSize:"11px"}}>{formatDate(verifiedDate)}</p>
        </Box>
        <Box as="span">
          <Image src="/assets/imgs/download.png" />
        </Box>
          </Button>
        </Box>
      
            <Spacer />
            <Box>
              <PrimaryButtonIcon
                buttonText="Delete"
                  onClick={deletePatientReports} btnStyle={{color:"#AA2E26"}}
              />
            </Box>
          </Flex>
        }
      />




<ModalRight
        ref={modalLearnMore}
        isBg={true}
        modalWidth="480px"
        customHeader={false}
        bodyStyle={{ p: "0", overflow: "hidden" }}
        footerStyle={{ borderTop: "1px solid #E6E7E9" }}
        body={
          <>
            <Box bg="#061027" p="35px">
              <Box display="flex" alignItems="center" gap="10px">
                <Box display="flex" flexDir="row" justifyContent="space-between" gap="5px" alignItems="center">
                  <Box fontSize="20px" fontWeight="600" color="#E6E7E9">
                      {header ? header : "-"}
                    <Box as="p" pt="15px" fontSize="14px" fontWeight="400" lineHeight="normal" color="white">
                      {date ? date : "-"}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box bg="#fbfbfc" overflow="hidden auto" h="calc(100% - 220px)">
              <Box overflowY="auto" p="24px 32px">
                <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">Normal results</Box>
                <Box as="h5" fontSize="16px" pb="20px" fontWeight="300">
                  {normalResultText ? normalResultText : "-"}
                </Box>

                <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">Abnormal results</Box>
                <Box as="h5" fontSize="16px" pb="20px" fontWeight="300">
                  {abnormalResultText ? abnormalResultText : "-"}
                </Box>
                <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">Report Interpretition</Box>
                <Box as="h5" fontSize="16px" pb="20px" fontWeight="300">
                  {interpretitionText ? interpretitionText : "-"}
                </Box>
              </Box>
            </Box>
          </>
        }
      />
        </Box>
      ) : (

        //Mobile responsive start
        <Box w="100%" bg="#fcfcfd">
        {isLoading && <Loader />}
          <MobileNavbar/>
          <MobileSecondaryNav 
            handlebackarrow={()=> navigate('/myhealth')}
            header="Imaging"
            bottomArea={<>
              <Box w="100%">
                <TabSection
                  tabs={["Table view", "Reports view"]}
                  onClickTab={handleTabClick}
                />
              </Box>
            </>}
          />
          {formattedData && formattedDataImaging !== null && (
          <Box pos="fixed" bottom="10%" display="flex" justifyContent="center" w="100%" zIndex="99">
            <PrimaryButton
              fontSize="14px"
              variant="mdBtn"
              buttonText="Upload report"
              btnIcon="/assets/imgs/add.png"
              onClick={openUploadImaging}
              />
          </Box>
          )}
          <Flex bg="#fcfcfd" flexDirection="column" overflow="hidden auto" height="calc(100vh - 180px)">
            {activeTab === 'TableViewTab' ? (
              formattedData !== null &&(
              <DynamicTable TableData={dataForTableImaging} 
              withModal={true}
              /> )
              ) : (formattedDataImaging !== null && (
            <SimpleGrid p="20px" columns={1} gap="20px">
              {formattedDataImaging && (formattedDataImaging.rows != undefined || null) && formattedDataImaging.rows.map((row, index) => (
                <Box key={index} flex="0 0 calc(33.333% - 20px)" mb="20px">
                  <ReportViewCard
                    cardHeader={row.reportname}
                    headerClick={()=>openLearnMoreModal(row)}
                    cardSecondary={formatDate( row.verifieddate) }
                    cardBtn={row.uniqueURLs.length < 2 ? row.uniqueURLs.length + " document" :  row.uniqueURLs.length + " documents" }
                    cardText={row.reportdescription}  
                  onClick={openModalRightImaging( row.reportdescription, row.documetURL, row.reportname, row.documentdate, row.reportid, row.verifieddate, row.documentdate)}
              
                  deleteBtn={openDeletePopup(row.reportid, row.reportname, row.verifieddate)}
                  />
                </Box>
                ))}
                    
                </SimpleGrid>
                 )
                )}
                {!formattedData   && (
                <Box w="100%" h="100vh" display="flex" flexDirection="column" gap="5px" justifyContent="center" alignItems="center">
                      <Image w="60px" src="/assets/svgs/hand_bones.svg" />
                      <Box as="p">No data available</Box>
                      <PrimaryButton fontSize="14px" variant="mdBtn" buttonText="Upload report" btnIcon="/assets/imgs/add.png" onClick={openUploadImaging}/>
                  </Box> 
                )}
            </Flex>
        </Box>
      )}
      </>
    )
}

export default ImagingIndex;