import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../components/navbar";
import SideBar from "../../components/sidebar";
import SecondaryNav from "../../components/seconday-nav";
import { Box, Divider, Flex, Text } from "@chakra-ui/layout";
import {
  Image,
  Button,
  Spacer,
  Center,
  VStack,
  Checkbox,
  useSafeLayoutEffect,
  Stack,
  RadioGroup,
  Heading,
  SimpleGrid
} from "@chakra-ui/react";
import { PrimaryButton, PrimaryButtonIcon, PrimaryInput, SearchBar, Toaster } from "../../components";
import DynamicModal from "../../components/dynamic-modal";
import ModalRight from "../../components/modal-right";
import { addMedicalConditions, FetchConditionAIPrompts, FetchConditionAIAnswer, deleteMedicalConditions, getMedicalConditions, EditCondition} from "../../middleware/services/myhealth/conditions";
import { getmedicalcondition } from "../../middleware/services/signup";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import DatePicker from "../../components/date-picker";
import { format } from "date-fns";
import Footer from "../../components/footer";
import moment from "moment";
import {displayMillion_ThoudandValues, getLoginInfo, getUserID, handleAlphaNumericDotAndSpace, useScreenType}  from '../../auth';
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import MobileNavbar from "../../components/mobile-navbar";
import { useNavigate } from "react-router-dom";
import TypingLoader from "../../components/typing-loader";
import AiModal from '../../components/ai-modal/index';
import { FetchMyHealthAIData} from "../../middleware/services/home";
import { FetchTokens } from "../../middleware/services/profile/rewards";



const ConditionIndex = () => {
  const dynamicModal = React.useRef();
  const addCondition = React.useRef();
  const editCondition = React.useRef();
  const addCondition2 = React.useRef();
  const addConditionModalInsideEdit = React.useRef();
  const navigate = useNavigate()
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();

  const [data, setData] = useState('')
  var [conditionsList, setConditionsList] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [patientid, setPatientid] = useState("")
  const [selectedDates, setSelectedDates] = useState({});
  const [id, setId] = useState('')
  const [condition, setCondition] = useState('')
  const [ConditionName, setConditionName] = useState('')
  const [ConditionDate, setConditionDate] = useState('')
  const [ConditionID, setConditionID] = useState('')
  const [ConditionTypeID, setConditionTypeID] = useState('')
  
  const [typedCondition , setTypedCondition] = useState("")
  const screenType = useScreenType();
  const chatContainerRef = useRef(null);
  const [aianswers, setAIAnswers] = useState([]);
  const [remainingquestions, setRemainingQuestions] = useState([]);
  const [aiquestion, setAIAnalysis] = useState(null);
  const [AiInput, setAiInput] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [animate, setAnimate] = useState(false);
  const AIChatModel = useRef();

  const [upgradePlanModal , setUpgradePlanModal] = useState(false)
  const [remainingTokens, setRemainingTokens] = useState();

  useEffect(() => {
    getTokens();
  }, [])
  var input = {}
  var getTokens = async () => {
    try {
      var result = await FetchTokens(input);
      if (result) {
        setRemainingTokens(result)
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.log(error, "error in fetching account data")
    }
    //dismissLoader();
  }
  useEffect(() => {
    fetchData();
  }, []);

  var inputForGet = {

  }
  const fetchData = async () => {
    showLoader();
    try {
      const result = await getMedicalConditions(inputForGet);
      if(result && result.output && result.output.result == "failure"){
        setData(null)
      }else if (result) {
        const sortedData = result.map(item => ({
          ...item,
          contiondata: item.contiondata.sort((a, b) => parseInt(b.id) - parseInt(a.id))
        }));
        setData(sortedData)
      } else {
        showErrorToast("Something went wrong!")
      }


    } catch (error) {
      console.error("Error in DashboardIndex:", error);
    }
    dismissLoader();
  };

  const fetchMedicalConditionList = async () => {
    try {
      const result = await getmedicalcondition({
        type: "healthcondition",
        "patientid":getLoginInfo()
      });
      if (result.output.result == "success") {
        setConditionsList(result.output.data);
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleCheckboxChange = (condition, category) => {
    const isSelected = selectedConditions.some(
      (item) => item.condition_cd === condition.condition_cd
    );

    if (isSelected) {
      setSelectedConditions((prevConditions) =>
        prevConditions.filter(
          (item) => item.condition_cd !== condition.condition_cd
        )
      );
    } else {
      setSelectedConditions((prevConditions) => [
        ...prevConditions,
        { ...condition, category },
      ]);
    }
  };

  const handleCheckboxChangeForEdit = (selectedCondition, category) => {
    setSelectedConditions([])
    setConditionName(selectedCondition.condition)
    setConditionType(category.conditiontype)
    // Create a new array with only the selected condition
    const newSelectedConditions = [{
      condition_cd: selectedCondition.condition_cd,
      condition: selectedCondition.condition,
      category: category // Assuming you want to store the category of the condition as well
    }];
    setSelectedConditions(newSelectedConditions);
  };
  

  const handleDateChange = (conditionCd, date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [conditionCd]: formattedDate,
    }));
  };

  var inputForAdd = {
    medicalcondition: selectedConditions.map((condition) => ({
      conditiontype_cd: condition?.category?.conditiontype_cd ,
      condition_cd: condition.condition_cd,
      nomenclatureid: condition.condition_cd,
      conditionidentifieddate: selectedDates[condition.condition_cd] || "",
      "condition":condition.condition,
      "conditiontype":condition.category.conditiontype
    })),
  };


  const AddMedicalConditions = () => {
    if (selectedConditions.length === 0 && typedCondition == "") {
      showErrorToast("Please Select a Condition to Continue")
    } else {
      if (typedCondition !== "") {
        const newSelectedCondition = {
          category: {
            conditiontype: "other",
            conditiontype_cd: null,
          },
          condition: typedCondition,
          condition_cd: null,
        };
    
        setSelectedConditions((prevConditions) => {
          // Filter out any existing condition with the same type
          const filteredConditions = prevConditions.filter(
            (item) => item.category.conditiontype !== "other"
          );
          // Add the new condition
          return [...filteredConditions, newSelectedCondition];
        });
      }
     
      setSelectedDates({})
      addCondition2.current.openRight()
    }

  }
  const AddMedicalConditionsCall = () => {

    const addConditions = async () => {
      showLoader();
      try {
        console.log(inputForAdd ," inputForAdd")
        const result = await addMedicalConditions(inputForAdd);
        if (result.output.result == "success") {
          showSuccessToast("Condition added Successfully!")
          setTimeout(() => {

            addCondition2.current.closeRight();
            addCondition.current.closeRight();
          //  dismissLoader();
          }, 700);
          fetchData();
        } else {
          showErrorToast("Something went wrong!")
        }
      } catch (error) {
        console.error("Error in DashboardIndex:", error);
      }
    };
    const isDateMissing = selectedConditions.some(
      (condition) => !selectedDates[condition.condition_cd]
    );

    if (isDateMissing) {

      showErrorToast("Please Select the Date")
      dismissLoader();
      return false;
    } else {

      addConditions();


    }
  }

  var inputForDelete = {
    id
  }
  const deleteMedicalCondition = async () => {

    const deleteCondition = async () => {
      showLoader();
      try {
        const result = await deleteMedicalConditions(inputForDelete);
        if (result.output.result == "successfully deleted") {
          
          if(condition.length > 30){
            showErrorToast(
              <span>
                <Box maxWidth="200px">
                <Text className="textOverflow" wordBreak="break-all">{condition}</Text> has been deleted! 
                </Box>
              </span>
            );
          }else{
            showErrorToast(condition + "  has been deleted!")
          }
     
          fetchData();
          dynamicModal.current.closeModal();
        } else {
          showErrorToast("Somethnig went wrong!")
        }

      } catch (error) {
        console.error("Error in delete:", error);
      }
      dismissLoader();
    };
    deleteCondition();
  }

  const openDeleteModal = (id, condition) => {
    setId(id)
    setCondition(condition)

    dynamicModal.current.openModal();

  }

  const openAddConditionModal = () => {
    // if (conditionsList == null) {
      fetchMedicalConditionList();
    // }
    setSearchValue('')
    setSelectedConditions([]);
    setSelectedDates({})
    setTypedCondition("")
    addCondition.current.openRight()
  }
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return moment(dateString).format('Do MMM, YYYY');
  };
  const handleDateChangeEditModel = (date) => {
    setConditionDate(date)
  };
  const [conditionType  , setConditionType] = useState("")
  const openEditModal = (condition, conditionid, date, id, conditiontype_cd , type , type_cd) => {
    console.log({condition, conditionid, date, id, conditiontype_cd, type , type_cd
    })
    
    fetchMedicalConditionList();
    setConditionType(type)
    setTypedCondition("")
    // if(type == "Other"){
    //   setTypedCondition(condition)
    // }
    setId(id)
    setConditionTypeID(conditiontype_cd)
    setConditionName(condition)
    setConditionID(conditionid)
    const conditiondate =  moment(date, 'YYYY-MM-DD').format('DD-MM-yyyy');
    setConditionDate(conditiondate)
    const newSelectedConditions = [{
      conditiontype_cd: conditiontype_cd,
      condition: condition,
       category: "" // Assuming you want to store the category of the condition as well
    }];
    setSelectedConditions(newSelectedConditions);
    editCondition.current.openRight()
  }
  var inputForEdit = {
    
    condition_cd: ConditionID ? ConditionID : selectedConditions[0]?.condition_cd,
    conditiontype_cd: ConditionTypeID ? ConditionTypeID : selectedConditions[0]?.category.conditiontype_cd,

      conditionidentifieddate: ConditionDate,
      id: id,
      patientid: getLoginInfo(),
      activeuserid: getUserID(),

      "condition":ConditionName,
    "conditiontype":conditionType
  };
  const UpdateCondition = async()=>{
    if (moment(inputForEdit.conditionidentifieddate, 'DD-MM-YYYY', true).isValid()) {
      inputForEdit.conditionidentifieddate = moment(inputForEdit.conditionidentifieddate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }
    if (inputForEdit.conditionidentifieddate == "") {
      showErrorToast("Please select any date");
      return false;
    } else {
     
      showLoader();
      const result = await EditCondition(inputForEdit);
      
      if (result) {
        showSuccessToast("Condition updated Successfully");
        fetchData();
        editCondition.current.closeRight()
      }
      dismissLoader();
    }
  }
  const editConditionName = () =>{
   if (conditionsList == null) {
    fetchMedicalConditionList();
  }
  setSearchValue('')
 // setSelectedConditions([]);
  setSelectedDates({})
  
   addConditionModalInsideEdit.current.openRight();
   setConditionID("")
   setConditionTypeID("")
  }
  const handleCondition = (e) => {
    const inputText = e.target.value;
    const maxLength = 80;
  
    if (inputText.length <= maxLength) {
      setTypedCondition(inputText);
    }
  
  }

  const selectConditionInEdit = ()=>{
      if(typedCondition){
        setConditionType("other")
        setConditionName(typedCondition)
      }
    addConditionModalInsideEdit.current.closeRight()
  }
  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [animate]);
  
  useEffect(() => {
    // Scroll to bottom whenever aianswers changes
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [aianswers]);
  const fetchAiDoctorPrompts = async (conditionname)=>{
    showLoader();
    setRemainingQuestions([])
    setAiInput(null);
    setAIAnswers([])
    setInitialRender(true)
    const result = await FetchMyHealthAIData({
      patientid,
    });
    let Aiprompts = { ...result, conditionname };
      setAiInput(Aiprompts);
      const response = await FetchConditionAIPrompts(Aiprompts);
      if (response.generated_question) {
        setAIAnalysis(response);
        setRemainingQuestions(response.generated_question);
        AIChatModel.current.openRight();
      }else{
       // showErrorToast("Ai tokens are not available");
        setUpgradePlanModal(true)
        setTimeout(() => {
          setUpgradePlanModal(false)
        }, 100);
        AIChatModel.current.closeRight();
      }
      dismissLoader();
  }
  
  const GetAIAnswer = async (prompt) => {
    // Display question immediately
    const newQuestion = { question: prompt, answer: "" };
    setAIAnswers((prevState) => [...prevState, newQuestion]);
  
    // Remove the question from remaining questions immediately
    setRemainingQuestions((prevState) => prevState.filter((question) => question.value !== prompt));
  
    // Trigger animation
    setAnimate(true);
  
    // Set initialRender to false after the first question is clicked
    setInitialRender(false);
    let AichatInput = { ...AiInput, query:prompt };
  
  
    const response = await FetchConditionAIAnswer(AichatInput);
  
    if (response) {
      // Replace "typing" with actual answer
      setAIAnswers((prevState) =>
        prevState.map((item) =>
          item.question === prompt && item.answer === ""
            ? { question: item.question, answer: response.answer }
            : item
        )
      );
      getTokens();
    }else {
     // showErrorToast("Ai tokens are not available");
     setUpgradePlanModal(true)
     setTimeout(() => {
       setUpgradePlanModal(false)
     }, 100);
      AIChatModel.current.closeRight();
    }
  };
  return (
    <>
    {screenType == 'web' ? (
      <Box w="100%">
        <Box top="0" zIndex="banner">
          <Navbar   messageModal={upgradePlanModal} />
          {isLoading && <Loader />}
          <SecondaryNav
            defaultHeader="My Health"
            defaultSecondayHeader="Health conditions"
            defaultNavBody={
              <>
                <Box display="flex" gap="10px" alignItems="center" pr="25px">
                  {data != null && (
                    <PrimaryButton
                      fontSize="14px"
                      variant="mdBtn"
                      buttonText="Add Condition"
                      onClick={openAddConditionModal}
                      btnIcon="/assets/imgs/add.png"
                    />
                  )}
                </Box>
              </>
            }
          />
        </Box>
        <Box h="calc(100vh - 140px)" overflow="hidden auto">
        <Flex>
          <Box position="sticky" top="0" zIndex="sticky" h="100vh">
            <SideBar />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap="20px"
            w="100%"
          >
            {data &&
            <Box overflow="hidden auto" p={4} h="100%">
            {data && Array.isArray(data) && data.map(({ conditiontype, conditiontype_cd, contiondata }, index) => (
              <Box key={index} w="100%" h="auto">
                <Box>
                  <Box as="h1" fontSize="12px" letterSpacing="2px" fontWeight="600">
                    {conditiontype ? conditiontype.toUpperCase() : conditiontype}
                  </Box>
                </Box>
                <Flex wrap="wrap" pt="20px" w="100%" justifyContent="left" gap="20px">
                  {contiondata.map(({ condition, condition_cd, conditionidentifieddate, id }, innerIndex) => (
                    <Box key={innerIndex} flex="0 0 calc(33.333% - 20px)" mb="20px">
                      <Box
                        as="button"
                        p="20px"
                        w="100%"
                        display="flex"
                        flexDirection="row"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        border="1px solid #E6E7E9"
                        borderRadius="12px"
                      >
                        <Box display="flex" flexDir='column' alignItems="center">
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            maxW="350px"
                          >
                            <Text title={condition} fontSize="16px" textAlign="left" fontWeight="600" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                              {condition}
                            </Text>
                            <Text
                              fontSize="12px"
                              fontWeight="500"
                              textAlign="left"
                              color="#384052"
                            >
                              {formatDate(conditionidentifieddate)}
                            </Text>
                          </Box>
                        </Box>
                        
                        <Box display='flex' flexDir='column' gap="8px" alignItems="flex-end">
                        
                        <Box
                                  as="button"
                                  // onClick={}
                                  border="1px solid #E6E7E9"
                                  borderRadius="full"
                                  w="36px"
                                  h="auto"
                                  p="6px"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  onClick={()=>fetchAiDoctorPrompts(condition)}
                                >
                                  <Image w="26px" src="/assets/svgs/ai-star-outline.svg" />
                                </Box>

                            <Box display='flex' w="100%" gap='0.5rem' mt='1rem' justifyContent='flex-end'>
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              //mx="10px"
                              onClick={() => openEditModal(condition, condition_cd, conditionidentifieddate, id, conditiontype_cd , conditiontype , conditiontype_cd)}
                            >
                              <Image w="13px" src="/assets/imgs/edit.png" />
                            </Box>
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              onClick={() => openDeleteModal(id, condition)}
                            >
                              <Image w="13px" src="/assets/imgs/delete.png" />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Flex>
                <Divider mb='1rem' />
              </Box>
            ))}
            </Box>
}
            {/* No Data page */}
            {data == null && (
              <Box w="100%" mt="-100px" h="100vh" display="flex" flexDirection="column" gap="5px" justifyContent="center" alignItems="center">
                <Image w="60px" src="/assets/svgs/health-icon.svg" />
                <Box as="p">No data available</Box>
                <PrimaryButton fontSize="14px" variant="mdBtn" buttonText="Add Condition" btnIcon="/assets/imgs/add.png" onClick={openAddConditionModal} />
              </Box>
            )}
          </Box>



        </Flex>
        <Footer />
        </Box>
      </Box>
    ) : (
      //Mobile responsive start
      <Box w="100%" bg="#fcfcfd">
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/myhealth')}
          header="Conditions"
        />

        {data && data && (
        <Box pos="fixed" bottom="10%" display="flex" justifyContent="center" w="100%" zIndex="99">
          <PrimaryButton
            fontSize="14px"
            variant="mdBtn"
            buttonText="Add Condition"
            btnIcon="/assets/imgs/add.png"
            onClick={openAddConditionModal}
            />
        </Box>
        )}

        <Box w="100%" p="1.5rem" pb="5rem" h='calc(100vh - 190px)' overflow="auto">
          <SimpleGrid gap="1rem">
          {data &&
            <Box overflow="hidden auto" h="100%">
            {data && Array.isArray(data) && data.map(({ conditiontype, conditiontype_cd, contiondata }, index) => (
              <Box key={index} w="100%" h="auto">
                <Box>
                  <Box as="h1" fontSize="12px" letterSpacing="2px" fontWeight="600">
                    {conditiontype ? conditiontype.toUpperCase() : conditiontype}
                  </Box>
                </Box>
                <Flex wrap="wrap" pt="20px" w="100%" justifyContent="left" gap="20px">
                {contiondata.map(({ condition, condition_cd, conditionidentifieddate, id }, innerIndex) => (
                    <Box key={innerIndex} w="100%" mb="20px">
                      <Box
                        as="button"
                        p="20px"
                        w="100%"
                        display="flex"
                        flexDirection="row"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        border="1px solid #E6E7E9"
                        borderRadius="12px"
                      >
                        <Box display="flex" flexDir='column' alignItems="center">
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            maxW="350px"
                          >
                            <Text title={condition} fontSize="16px" textAlign="left" fontWeight="600" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                              {condition}
                            </Text>
                            <Text
                              fontSize="12px"
                              fontWeight="500"
                              textAlign="left"
                              color="#384052"
                            >
                              {formatDate(conditionidentifieddate)}
                            </Text>
                          </Box>
                        </Box>
                        
                        <Box display='flex' flexDir='column' gap="8px" alignItems="flex-end">
                        
                        <Box
                                  as="button"
                                  // onClick={}
                                  border="1px solid #E6E7E9"
                                  borderRadius="full"
                                  w="36px"
                                  h="auto"
                                  p="6px"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  onClick={()=>fetchAiDoctorPrompts(condition)}
                                >
                                  <Image w="26px" src="/assets/svgs/ai-star-outline.svg" />
                                </Box>

                            <Box display='flex' w="100%" gap='0.5rem' mt='1rem' justifyContent='flex-end'>
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              //mx="10px"
                              onClick={() => openEditModal(condition, condition_cd, conditionidentifieddate, id, conditiontype_cd , conditiontype , conditiontype_cd)}
                            >
                              <Image w="13px" src="/assets/imgs/edit.png" />
                            </Box>
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              onClick={() => openDeleteModal(id, condition)}
                            >
                              <Image w="13px" src="/assets/imgs/delete.png" />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Flex>
                <Divider mb='1rem' />
              </Box>
            ))}
            </Box>
            }
            {/* No Data page */}
            {data == null && (
              <Box w="100%" mt="-100px" h="100vh" display="flex" flexDirection="column" gap="5px" justifyContent="center" alignItems="center">
                <Image w="60px" src="/assets/svgs/health-icon.svg" />
                <Box as="p">No data available</Box>
                <PrimaryButton fontSize="14px" variant="mdBtn" buttonText="Add Condition" btnIcon="/assets/imgs/add.png" onClick={openAddConditionModal} />
              </Box>
            )}
          </SimpleGrid>
        </Box>
      </Box>
    )}

        <DynamicModal
          ref={dynamicModal}
          modalHeader="Delete condition?"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete "{condition}"
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                onClick={deleteMedicalCondition}
              />
            </>
          }
        />

        <ModalRight
          ref={addCondition}
          modalWidth="360px"
          header="Add condition"
          body={
            <>
              <SearchBar passInputPlace="Search condition" 
              onChange={(e) => setSearchValue(handleAlphaNumericDotAndSpace(e.target.value))}
              searchValue={searchValue} />
              <RadioGroup>
                <Stack direction="column" gap="0">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #e2e8f0"
                  >
                  <Box display="flex" justifyContent="space-between" w="100%">
  <Stack direction="column" w="100%" gap="0">
    {conditionsList && conditionsList.length > 0 ? (
      <>
        {conditionsList.map((category) => {
          const filteredConditions = category.data.filter((condition) =>
            condition.condition.toLowerCase().includes(searchValue.toLowerCase())
          );

          return (
            <React.Fragment key={category.conditiontype_cd}>
              {filteredConditions.length > 0 && (
                <>
                  <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                    {category.conditiontype}
                  </Heading>
                  {filteredConditions.map((condition) => (
                    <label key={condition.condition_cd}>
                      <Box
                        key={condition.condition_cd}
                        display="flex"
                        justifyContent="space-between"
                        borderBottom="1px solid #e2e8f0"
                        p="15px"
                        pl="5px"
                        minH="50px"
                        width="100%"
                        sx={{
                          _hover: { border: "2px solid #384052", borderRadius: "10px" },
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          w='240px'
                        >
                          <Text fontSize="16px" whiteSpace='pre-wrap' overflow='hidden' lineHeight="normal" fontWeight="600">
                            {condition.condition}
                          </Text>
                        </Box>
                        <Box display="flex" justifyContent="end">
                          <Checkbox
                            colorScheme="green"
                            sx={{
                              ".chakra-checkbox__control": {
                                width: "20px",
                                height: "20px",
                                borderRadius: "5px",
                              },
                            }}
                            onChange={() => handleCheckboxChange(condition, category)}
                            isChecked={selectedConditions.some(
                              (item) => item.condition_cd === condition.condition_cd,
                            )}
                          ></Checkbox>
                        </Box>
                      </Box>
                    </label>
                  ))}
                </>
              )}
            </React.Fragment>
          );
        })}
        {/* Display "No matching conditions found" only if no conditions were found in any category */}
        {conditionsList.every((category) =>
          category.data.every((condition) =>
            !condition.condition.toLowerCase().includes(searchValue.toLowerCase())
          )
        ) && (
          <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
          No matching conditions found!
        </Box>
        )}
        <Divider/>
          <br/>
          <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                   Other Condition
                  </Heading>
        <PrimaryInput
         inputPlace="Condition"
         variant="bothSide"
                onChange={handleCondition} inputValue={typedCondition}
                />
                <br/>
      </>
      
    ) : (
      <p>Loading...</p>
    )}
  </Stack>
</Box>

                  </Box>
                </Stack>
              </RadioGroup>
              <ModalRight
                ref={addCondition2}
                header="Add condition"
                body={
                  <>
                    <Box display="flex" flexDir="column" gap="20px">
                      {selectedConditions.map((condition, index) => (
                        <Box key={index}>
                          <Text fontSize="16px" py="10px" fontWeight="600">
                            {condition.condition}
                          </Text>


                          <DatePicker
                            disableFutureDates={true}
                            onDateChange={(e) => handleDateChange(condition.condition_cd, e)}
                            placeholderProp="Select a Date*"
                            calendarStlye={{ transform: "scale(0.9)", right: "-20px", top: "30px" }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </>
                }
                footerActions={<>
                  <Box
                    w="100%"
                    display="flex"
                    flexDirection="row"
                    gap="15px">

                    <PrimaryButton
                      buttonText="Add Condition"
                      isDisabled={false}
                      onClick={AddMedicalConditionsCall}
                    />
                  </Box>
                </>}
              />
                 
            </>
            
          }
          footerActions={<>
            <Box
              w="100%"
              display="flex"
              flexDirection="row"
              gap="15px">

              <PrimaryButton
                buttonText="Continue"
                isDisabled={false}
                onClick={AddMedicalConditions}
              />
            </Box>
          </>}
        />
        <ModalRight
          ref={editCondition}
          header="Edit condition"
          body={
            <>
              <Box display="flex" flexDir="column" gap="20px">
                  <Box>
                    <Box  onClick={editConditionName} alignItems="center" py="10px" borderBottom="1px solid var(--chakra-colors-chakra-border-color);" mb="10px" display="flex" justifyContent="space-between" cursor="pointer">
                    <Text title={ConditionName} w='230px' fontSize="16px" className="textOverflow" fontWeight="600" onClick={editConditionName}>
                      {ConditionName}
                    </Text>
                    <Image src="/assets/imgs/right-icon.png" />
                    </Box>


                    <DatePicker
                      disableFutureDates={true}
                      defaultDate={ConditionDate}
                      onDateChange={(e) => handleDateChangeEditModel(e)}
                      placeholderProp="Select a Date*"
                      calendarStlye={{ transform: "scale(0.9)", right: "-20px", top: "30px" }}
                    />
                  </Box>
                
              </Box>

              

              <ModalRight
    ref={addConditionModalInsideEdit}
    modalWidth="360px"
    header="Update condition"
    body={
      <>
        <SearchBar passInputPlace="Search condition" onChange={(e) => setSearchValue(e.target.value)} />
        <RadioGroup>
          <Stack direction="column" gap="0">
            <Box
              display="flex"
              justifyContent="space-between"
              borderBottom="1px solid #e2e8f0"
            >
            <Box display="flex" justifyContent="space-between" w="100%">
<Stack direction="column" w="100%" gap="0">
{conditionsList && conditionsList.length > 0 ? (
<>
  {conditionsList.map((category) => {
    const filteredConditions = category.data.filter((condition) =>
      condition.condition.toLowerCase().includes(searchValue.toLowerCase())
    );

    return (
      <React.Fragment key={category.conditiontype_cd}>
        {filteredConditions.length > 0 && (
          <>
            <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
              {category.conditiontype}
            </Heading>
            {filteredConditions.map((condition) => (
              <label key={condition.condition_cd}>
                <Box
                  key={condition.condition_cd}
                  display="flex"
                  justifyContent="space-between"
                  borderBottom="1px solid #e2e8f0"
                  p="15px"
                  pl="5px"
                  minH="50px"
                  width="100%"
                  sx={{
                    _hover: { border: "2px solid #384052", borderRadius: "10px" },
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                  >
                    <Text fontSize="16px" lineHeight="normal" fontWeight="600">
                      {condition.condition}
                    </Text>
                  </Box>
                  <Box display="flex" justifyContent="end">
                    <Checkbox
                      colorScheme="green"
                      sx={{
                        ".chakra-checkbox__control": {
                          width: "20px",
                          height: "20px",
                          borderRadius: "5px",
                        },
                      }}
                      onChange={() => handleCheckboxChangeForEdit(condition, category)}
                      isChecked={selectedConditions.some(
                        (item) => item.condition == condition.condition,
                      )}
                    ></Checkbox>
                  </Box>
                </Box>
              </label>
            ))}
          </>
        )}
      </React.Fragment>
    );
  })}
  {/* Display "No matching conditions found" only if no conditions were found in any category */}
  {conditionsList.every((category) =>
    category.data.every((condition) =>
      !condition.condition.toLowerCase().includes(searchValue.toLowerCase())
    )
  ) && (
    <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
    No matching conditions found!
  </Box>
  )}
</>
) : (
<p>Loading...</p>
)}
<Divider/>
    <br/>
    <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
              Other Condition
            </Heading>
  <PrimaryInput
    inputPlace="Condition"
    variant="bothSide"
          onChange={handleCondition} inputValue={typedCondition}
          />
          <br/>
</Stack>
</Box>

            </Box>
          </Stack>
        </RadioGroup>
        
            
      </>
      
    }
    footerActions={<>
      <Box
        w="100%"
        display="flex"
        flexDirection="row"
        gap="15px">

        <PrimaryButton
          buttonText="Continue"
          isDisabled={false}
          onClick={selectConditionInEdit}
        />
      </Box>
    </>}
  />
            </>
          }
          footerActions={<>
            <Box
              w="100%"
              display="flex"
              flexDirection="row"
              gap="15px">

              <PrimaryButton
                buttonText="Update Condition"
                isDisabled={false}
                onClick={UpdateCondition}
              />
            </Box>
          </>}
        />
         <AiModal
        ref={AIChatModel}
      modalSize="420px"
      //icon="/assets/imgs/ai-doctor.png"
      header={<>
        <Box display="flex" flexDir='column' gap='5px'>
            <Box display='flex' gap='6px'>
              <Text fontSize="1rem"
                fontWeight="700" 
                backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)" 
                color="transparent" 
                backgroundClip="text"
              >Aira - AI</Text>
              <Box display='flex' alignItems='center' gap='5px' border='1px solid #0d7e54' bg='#d5ece3'  p='3px 5px' borderRadius='full'>
                <Image src='/assets/imgs/spark.png' /> 
                <Text fontSize='10px' fontWeight='500' color='#000'>{remainingTokens && remainingTokens.remainingtokens ? displayMillion_ThoudandValues(remainingTokens.remainingtokens):"0"} tokens available</Text>
              </Box>
            </Box>
            <Text fontSize="0.75rem" fontWeight="400">Chat with your AI health guide</Text>
          </Box>
      </>}
      headerStyle={{ p: "24px 32px", lineHeight: "normal" }}
      closeBtnStyle={{ top: "18px" }}
      footerStyle={{ maxH: "35%", overflow: "hidden auto", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      withoutBg={true}
      backDropBlur="10px"
      body={
        <>
          <Flex flexDirection="column" height="100%">
            <Box
              p="24px 32px"
              ref={chatContainerRef}
              className="chat-container"
              style={{ overflowY: "auto", maxHeight: 'calc(100vh - 160px)' }}
            >
              {initialRender && remainingquestions.length > 0 && (
                <Box>
                  {remainingquestions.map((step, index) => (
                    <Box
                      key={index}
                      as="button"
                      w="100%"
                      p="16px"
                      mb="16px"
                      fontSize="14px"
                      fontWeight="600"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius="10px"
                      border="1px solid #7795DC"
                      bg="#EAEDF5"
                      color="#163E9E"
                      onClick={() => GetAIAnswer(step.value)}
                      className={`question-box ${animate ? 'slide-in' : ''}`}
                    >
                      {step.value ? step.value : "-"}
                      <Image src="/assets/imgs/right-icon.png" />
                    </Box>
                  ))}
                </Box>
              )}
              {aianswers.map((step, index) => (
                <React.Fragment key={index}>
                  <Box
                    as="div"
                    bg="#061027"
                    p="12px 16px"
                    w="fit-content"
                    float="right"
                    borderRadius="10px 10px 1px 10px"
                    className={`answer-box ${animate ? 'slide-in' : ''}`}
                  >
                    <Text fontSize="14px" fontWeight="500" color="white">
                      {step.question ? step.question : "-"}
                    </Text>
                  </Box>

                  <Box
                    as="div"
                    my="20px"
                    w="fit-content"
                    display="flex"
                    alignItems="end"
                    float="left"
                    className={`answer-box ${animate ? 'slide-in' : ''}`}
                  >
                    <Image
                      bg="#061027"
                      p="4px"
                      mr="8px"
                      borderRadius="6px"
                      src="/assets/imgs/ai-chat-logo.png"
                    />
                    <Text
                      bg="#E6E7E980"
                      p="12px 16px"
                      fontSize="14px"
                      fontWeight="500"
                      color="#061027"
                      borderRadius="10px 10px 10px 1px"
                    >
                      {step.answer ? step.answer : <TypingLoader />}
                    </Text>
                  </Box>
                </React.Fragment>
              ))}
            </Box>
          </Flex>
        </>
      }
      footerActions={
        !initialRender && (
          <Box
            p="24px 32px"
            display="flex"
            w="100%"
            h="100%"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box w="100%">
              {remainingquestions.map((step, index) => (
                <Box
                  key={index}
                  as="button"
                  w="100%"
                  p="16px"
                  mb="16px"
                  fontSize="14px"
                  fontWeight="600"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius="10px"
                  border="1px solid #7795DC"
                  bg="#EAEDF5"
                  color="#163E9E"
                  onClick={() => GetAIAnswer(step.value)}
                  className={`question-box ${animate ? 'slide-in' : ''}`}
                >
                  {step.value ? step.value : "-"}
                  <Image src="/assets/imgs/right-icon.png" />
                </Box>
              ))}
            </Box>
          </Box>
        )
      }
      />
    </>
  );
};

export default ConditionIndex;
