import formapidoctors from "./doctor";


var formattedOutput = null;

const formapimodaldoctors = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formapidoctors(item));


  return formattedOutput
};

export default formapimodaldoctors