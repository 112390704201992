import moment from "moment";
export default function formAPIDoctorAssessment({
    diagnosis,
    id,
    date,
    plan,
   

  
})
{ 
  return Object.freeze({
    diagnosis,
    id,
    date,
    plan,
  });
}