import {
  Input,
  Select,
  Modal,
  InputRightElement,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Image,
  Stack,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Box,
  VStack,
  HStack,
  RadioGroup,
  Radio,
  Text,
  Divider,
} from "@chakra-ui/react";
import { MdKeyboardArrowDown } from "react-icons/md";

import { useState, useRef, useEffect } from "react";
import { inputDesign } from "../styles/theme";
import { styles, variantStyles } from "./style";

import ModalRight from "../modal-right";
import SearchBar from "../search-bar";
import { getCountry } from "../../middleware/services/country";

const countries = [
  {
    code: "+1",
    image:
      "https://nouahsark.com/data/images/infocenter/worldwide/north_america/flags_big/united_states.png",
    name: "United States",
  },
  // ... add other countries here
];


function PrimaryInputDropdown({
  onChange,
  onKeyPress,
  variant,
  inputValue,
  id,
  defaultValue,
  isDisabled,
  defaultCountryCode,
  useLocalStorage = true,
  placeholderProp,
  onCountryChange, // New callback function to pass the selected country data to the parent component
}) {
  
const [Modalcountries , setModalCountries]= useState([]);

  const rightModal = useRef();
  const [value, setValue] = useState("");
  const variantStyle = variantStyles[variant] || variantStyles.defaultSide;
  const [selectedCountry, setSelectedCountry] = useState({
    code: "+1",
    countryid:"229",
    image:
      "https://nouahsark.com/data/images/infocenter/worldwide/north_america/flags_big/united_states.png",
    name: "United States",
  });
  const [search, setSearch] = useState("");
  const [isError, setIsError] = useState(false);
  const borderStyle = isError ? "1.5px solid #CA3A31" : "1px solid #e2e8f0";
  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(search.toLowerCase())
  );

  function closefnc() {
    return rightModal.current.closeRight();
  }
  const [data, setData] = useState([])

  var input = {};
  var getAllCountries = async () => {
    try {
      var result = await getCountry(input)
      if (result) {
        setData(result)
        setModalCountries(result)
      }  else {
        setData("")
        //  showErrorToast("Something went wrong!")
      }

    } catch (error) {
      console.log(error, "error in Countries")
    }
  //  dismissLoader();
  }

  useEffect(()=>{
  
    getAllCountries();
  },[defaultCountryCode])

  

  useEffect(() => {
    if (useLocalStorage) {
      const countryId = sessionStorage.getItem("country-id");
      const countryFlag = sessionStorage.getItem("country-flag");
      const countryName = sessionStorage.getItem("country-name");
      const countryCode = sessionStorage.getItem("country-code");
      if (countryId && countryFlag && countryName) {
        setSelectedCountry({
          code: countryId,
          image: countryFlag,
          name: countryName,
          countryid: countryCode,
        });
      }
    }
  }, [useLocalStorage]);

  const handleRadioChange = (event) => {
    const selectedCountryName = event.target.value;
    const selectedCountry = Modalcountries.find(
      (country) => country.countryname === selectedCountryName
    );
    setSelectedCountry({
      code: selectedCountry.countrycode,
      name: selectedCountry.countryname,
      image: selectedCountry.flagimageurl,
      
      countryid: selectedCountry.countryid,
    });

    if (useLocalStorage) {
      sessionStorage.setItem("country-id", selectedCountry.countrycode);
      sessionStorage.setItem("country-flag", selectedCountry.flagimageurl);
      sessionStorage.setItem("country-name", selectedCountry.countryname);
      sessionStorage.setItem("country-code", selectedCountry.countryid);
    } else {
      if (onCountryChange) {
        onCountryChange({
          code: selectedCountry.countrycode,
          name: selectedCountry.countryname,
          image: selectedCountry.flagimageurl,
          countryid: selectedCountry.countryid,
        });
      }
    }
    closefnc();
  };

  useEffect(() => {
    if (useLocalStorage) {
      var countryid = selectedCountry.countryid;
      var countrycode = selectedCountry.code;

      localStorage.setItem("countryid", countryid);
      localStorage.setItem("countrycode", countrycode);
    }
  }, [selectedCountry.code, selectedCountry.countryid , useLocalStorage]);

  const openModal = () => {
    setSearch("");
    rightModal.current.openRight();
  };

  useEffect(() => {
    if (defaultCountryCode) {
      const selectedCountry = Modalcountries && Modalcountries.find(
        (country) => country.countryid === defaultCountryCode
      );
      if (selectedCountry) {
        setSelectedCountry({
          code: selectedCountry.countrycode,
          name: selectedCountry.countryname,
          image: selectedCountry.flagimageurl,   
          
          countryid: selectedCountry.countryid,
        });
        if (useLocalStorage) {
          sessionStorage.setItem("country-id", selectedCountry.countrycode);
          sessionStorage.setItem("country-flag", selectedCountry.flagimageurl);
          sessionStorage.setItem("country-name", selectedCountry.countryname);
          sessionStorage.setItem("country-code", selectedCountry.countryid);
        }
      }
    }
  }, [defaultCountryCode, useLocalStorage, data]);


  return (
    <>
      <Box w="100%">
        <HStack border={borderStyle} {...variantStyle} bg="white">
          <Box
            h="48px"
            borderRight={isError ? "1.5px solid #CA3A31" : "1px solid #e2e8f0"}
            maxW="100%"
            display="flex"
            alignItems="center"
          >
            <Button
              h="1.75rem"
              w="100%"
              size="sm"
              sx={styles}
              onClick={openModal}
              isDisabled={isDisabled}
              p={2}
              bg="#ffffff"
            >
              <Box as="span" mr="2" display="flex" alignItems="center">
                <Image
                  src={selectedCountry.image}
                  boxSize="20px"
                  borderRadius="full"
                  objectFit="cover"
                  alt="Flag"
                />
                <Box as="span" ml={2}>
                  {selectedCountry.code}
                </Box>
              </Box>
              <MdKeyboardArrowDown />
            </Button>
          </Box>
          <Box h="48px" maxW="100%" display="flex" alignItems="center">
            <Input
              w="100%"
              type="text"
              sx={{
                ...styles,
                ...variantStyle,
                ...inputDesign.formStyles.input,
              }}
              isDisabled={isDisabled}
              width="94%"
              border="none"
              pl="5px"
              placeholder={placeholderProp ? placeholderProp : "Phone number*"}
              onChange={onChange}
              value={inputValue}
              defaultValue={defaultValue}
              id={id}

              onKeyPress={onKeyPress}
            />
            {isError && (
              <InputRightElement h="100%">
                <Image src="/assets/imgs/error.png" w="18px" h="auto" />
              </InputRightElement>
            )}
          </Box>
        </HStack>
      </Box>
      <ModalRight
        ref={rightModal}
        header="Select country"
        body={
          <>
            <SearchBar
              onChange={(event) => setSearch(event.target.value)}
              passInputPlace="Search country"
            />
            <RadioGroup
              onChange={setValue}
              value={selectedCountry ? selectedCountry.name : ""}
            >
              <Stack direction="column" gap="0">
                {Modalcountries.filter(
                  (country) =>
                    country.countryname.toLowerCase().includes(search.toLowerCase()) ||
                    country.countrycode.toLowerCase().includes(search.toLowerCase())
                ).map((country) => (
                  <Box
                    key={country.countryid}
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #e2e8f0"
                    py="15px"
                    onClick={() =>
                      handleRadioChange({ target: { value: country.countryname } })
                    }
                    cursor="pointer"
                  >
                    <Box display="flex" alignItems="center">
                      <Image
                        boxSize="16px"
                        src={country.flagimageurl}
                        alt={`${country.countryname} flag`}
                        mr={2}
                      />
                      <Text fontSize="md">
                        {country.countryname} ({country.countrycode})
                      </Text>
                    </Box>

                    <Box display="flex" justifyContent="end">
                      <Radio
                        onChange={() => {}}
                        value={country.countryname}
                        checked={selectedCountry.code === country.countrycode}
                      />
                    </Box>
                  </Box>
                ))}
              </Stack>
            </RadioGroup>
          </>
        }
      />
    </>
  );
}

export default PrimaryInputDropdown;