import React,{useEffect,useState} from "react";
import {
  Box,
  Flex,
  Image,
  Card,
  CardBody,
  Text,
  Divider,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
import { DropdownInput, PrimaryButton, PrimaryInput,Toaster } from "../../components";
import SecondaryNav from "../../components/seconday-nav";
import ProfileSideBar from "./profileSidebar";
import Navbar from "../../components/navbar";
import ModalRight from "../../components/modal-right";
import moment from "moment";
import { FetchMedialRecords, RequestMedicalRecords, RevokeURL } from "../../middleware/services/profile/medicalrecords";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import CopyToClipboard from "react-copy-to-clipboard";
import Footer from "../../components/footer";
import DatePicker from '../../components/date-picker'
import { format } from "date-fns";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { useNavigate } from "react-router-dom";
import { CONFIG } from '../../appconfig/appconfig';


function MedicalRecordIndex() {
  const reqRecordModal = React.useRef()
  const editreqRecordModal = React.useRef()
  var [id, setID] = useState(null);
  var [data, setData] = useState(null);
  var [patientid, setPatientid] = useState();
  var [dataOnPageLoad, setDataOnPageLoad] = useState("");
  var [uuid, setUUID] = useState("");
  const [expiry, SetExpiry] = useState('24');
  const [expirttype, setExpiryType] = useState('Hours');
  const [recordURL, setRecordsURL] = useState('');
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();
  const URL = CONFIG.ShareRecordURL;
  const [copied, setCopied] = useState(false);
  const [expiryDate, setExpiryDate] = useState(null);
  const navigate = useNavigate()

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    showLoader();
    try {
      const result = await FetchMedialRecords({
        patientid,
      });
      console.log(result, "modified res");
        setData(result.output.data);
        dismissLoader();

    } catch (error) {
      console.error("Error in DashboardIndex:", error);
    }
  };
 const generateUUID = ()=> {
    const chars = '0123456789abcdef';
    let uuid = '';
    for (let i = 0; i < 16; i++) {
      if (i === 4 || i === 8 || i === 12) {
        uuid += '-';
      } else {
        const index = Math.floor(Math.random() * chars.length);
        uuid += chars[index];
      }
    }
    return uuid;
  }

  const handlerequestrecords = ()=>{
    SetExpiry('24')
    setExpiryType('Hours')
    setExpiryDate(null)
    const generatedUUID = generateUUID();
    setUUID(generatedUUID);
    setRecordsURL(`${window.location.origin}/patientmedicalrecord?id=${generatedUUID}`)
    reqRecordModal.current.openRight()
  };
  const setExpireFunction =(e)=>{
    const inputText = e.target.value;
    const maxLength = 3;
  
    if (inputText.length <= maxLength) {
      
    SetExpiry(e.target.value)
    }
  }
  const handleexpiryInputChange = (selectedOption) => {
    setExpiryType(selectedOption)
  }
  let input = {
    "patientid": "",
    "baseurl":recordURL,
    "uuid":uuid,
    "duration":expiry,
    "durationtype":expirttype,
    "expirydate":expiryDate,
  }
  
  const requestrecords = async ()=> {
    console.log(input)
    showLoader();
    try {
      const result = await RequestMedicalRecords(input);
      console.log(result)
      if(result.output != null){
        showSuccessToast("Saved and Copied Successfully");
        reqRecordModal.current.closeRight()
        fetchData();
      }
      dismissLoader();
    } catch (error) {
      console.error("Error:", error);
    }
  }
  const revokeURL = async ()=> {
    console.log(input)
    showLoader();
    try {
      const result = await RevokeURL({
        id: id,
        patientid: "",
      });
      console.log(result, "Revoked")
      if(result.output != null){
        showSuccessToast("Revoked Successfully");
        editreqRecordModal.current.closeRight()
        fetchData();
      }
      dismissLoader();
    } catch (error) {
      console.error("Error:", error);
    }
  }
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return moment(dateString).format('Do MMM, YYYY');
  };
  // const handlecopy =()=>{
  //   const textToCopy = recordURL;
  //   navigator.clipboard.writeText(textToCopy).then(() => {
  //     setCopied(true);
  //     setTimeout(() => {
  //       setCopied(false);
  //     }, 2000); // Reset copied state after 2 seconds
  //   }).catch((error) => {
  //     console.error('Error copying text: ', error);
  //   });
  // }
const copyToClipBoard = async (copyMe) => {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(copyMe);
    } else {
      await copyToClipboardFallback(copyMe);
    }
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  } catch (error) {
    console.error('Error copying text: ', error);
  }
};

const copyToClipboardFallback = async (copyMe) => {
  try {
      const tempInput = document.createElement('input');
      tempInput.value = copyMe;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
      
      setCopied(true);
      setTimeout(() => {
          setCopied(false);
      }, 2000);
  }
   catch (error) {
      console.error('Error copying text: ', error);
  }
};

const copyURLToClipBoard =  () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
};
const [showDateInput , setShowDateInput] = useState(false);
const [btnDisable , setBtnDisable] = useState(false);
  const handleedit = (item) =>{
    console.log(item)
    if(item.expirydate != null ){
      var formattedDate = format(item.expirydate , "dd-MM-yyyy");
     setExpiryDate(formattedDate)
      setShowDateInput(true)
    }else{
      
      setShowDateInput(false)
    }
    editreqRecordModal.current.openRight();
    setID(item.id)
    setRecordsURL(item.baseurl);
    SetExpiry(item.duration);
    setExpiryType(item.durationtype);
    if(item.isexpired == 1){
      setBtnDisable(true)
    }else{
      setBtnDisable(false)
    }
  }
  const handleChangeExpiryDate = (selectedDate) => {
    const formattedDate = format(selectedDate, "yyyy-MM-dd");
    SetExpiry(null)
    setExpiryType(null)
    setExpiryDate(formattedDate); 
  };
  return (
    <>
      <Box w="100%" display={{base: "none", lg: "block"}}>
        <Box top="0" zIndex="banner">
          <Navbar />
          <SecondaryNav
            sideBarWidth="250px"
            defaultHeader="Profile"
            defaultSecondayHeader="Share record"
            defaultNavBody={
              <>
                <Box display="flex" gap="10px" alignItems="center" pr="25px">
                  <PrimaryButton
                    fontSize="14px"
                    variant="mdBtn"
                    buttonText="Share record"
                    btnIcon="/assets/imgs/add.png"
                    onClick={handlerequestrecords}
                  />
                </Box>
              </>
            }
          />
        </Box>
        <Box h="calc(100vh - 140px)" overflow="hidden auto">
        <Flex>
          <Box p="0" width="250px">
            <ProfileSideBar />
            {isLoading && <Loader />}
          </Box>
          <Flex bg="#fcfcfd" flexDirection="column" w="100%" >
            {data && data != null ? (
              <Flex
                wrap="wrap"
                p="24px 32px"
                w="100%"
                justifyContent="start"
                gap="15px"
              >
                {data &&
                  data.map((step, index) => (
                    <Box key={index} flex="0 0 calc(33.333% - 20px)" mb="20px">
                      <Card
                        borderRadius="12px"
                        minH="130px"
                        w="100%"
                        border="1px solid #E6E7E9"
                        boxShadow="0px 8px 20px 0px #E6E7E966"
                      >
                        <CardBody>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box display="flex" alignItems="center">
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                              >
                                <Text fontSize="15px" fontWeight="600">
                                  Report {index + 1}
                                </Text>

                                <Text
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#384052"
                                >
                                  {formatDate(step.createddate)}
                                </Text>
                              </Box>
                            </Box>

                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              onClick={() => handleedit(step)}
                            >
                              <Image w="13px" src="/assets/imgs/edit.png" />
                            </Box>
                          </Box>

                          {/* <Divider my="10px" />
                    <Flex gap="8px" py="5px" align="center">
                      <Image src="/assets/svgs/phone.svg" boxSize="14px" />
                      <Text fontSize="12px" fontWeight="500">
                        +1(647)523-9898
                      </Text>
                    </Flex> */}
                          <Divider my="10px" />
                          <Text fontSize="12px" pt="5px" fontWeight="500" color={step.isexpired === 1 ? "#AA2E26" : ""}>
                            {step.isexpired === 1
                              ? "Expired" : `Expires on ${formatDate(step.expirydate)}`}                          
                          </Text>
                        </CardBody>
                      </Card>
                    </Box>
                  ))}
              </Flex>
            ) : (
              <Box
                w="100%"
                h="100vh"
                display="flex"
                flexDirection="column"
                gap="5px"
                justifyContent="center"
                alignItems="center"
              >
                <Image w="60px"  opacity="0.5" src="/assets/svgs/medicalrecords-nodata.svg" />
                <Box as="p">No data available</Box>
                {/* <PrimaryButton
                fontSize="14px"
                variant="mdBtn"
                buttonText="Upload report"
                btnIcon="/assets/imgs/add.png"
              /> */}
              </Box>
            )}
          </Flex>
        </Flex>
        <Footer />
        </Box>
      </Box>

       {/* Mobile responsive start */}
       <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/profile')}
          header="Share record"
        />

        <Box pos="fixed" bottom="10%" display="flex" justifyContent="center" w="100%" zIndex="99">
          <PrimaryButton
            fontSize="14px"
            variant="mdBtn"
            buttonText="Share record"
            btnIcon="/assets/imgs/add.png"
            onClick={handlerequestrecords}
          />
        </Box>

        <Flex bg="#fcfcfd" flexDir="column" p="1.5rem" h="calc(100vh - 200px)" overflow="hidden auto">
          {data && data != null ? (
              <SimpleGrid columns={1} gap='1rem' pb="3rem">
                {data &&
                  data.map((step, index) => (
                    <Card
                      borderRadius="12px"
                      minH="130px"
                      w="100%"
                      border="1px solid #E6E7E9"
                      boxShadow="0px 8px 20px 0px #E6E7E966"
                    >
                      <CardBody>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box display="flex" alignItems="center">
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                            >
                              <Text fontSize="15px" fontWeight="600">
                                Report {index + 1}
                              </Text>

                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >
                                {formatDate(step.createddate)}
                              </Text>
                            </Box>
                          </Box>

                          <Box
                            as="button"
                            border="1px solid #E6E7E9"
                            borderRadius="full"
                            w="36px"
                            h="auto"
                            p="10px"
                            onClick={() => handleedit(step)}
                          >
                            <Image w="13px" src="/assets/imgs/edit.png" />
                          </Box>
                        </Box>

                        {/* <Divider my="10px" />
                  <Flex gap="8px" py="5px" align="center">
                    <Image src="/assets/svgs/phone.svg" boxSize="14px" />
                    <Text fontSize="12px" fontWeight="500">
                      +1(647)523-9898
                    </Text>
                  </Flex> */}
                        <Divider my="10px" />
                        <Text fontSize="12px" pt="5px" fontWeight="500" color={step.isexpired === 1 ? "#AA2E26" : ""}>
                          {step.isexpired === 1
                            ? "Expired" : `Expires on ${formatDate(step.expirydate)}`}                          
                        </Text>
                      </CardBody>
                    </Card>
                  ))}
              </SimpleGrid>
            ) : (
              <Box
                w="100%"
                h="100vh"
                display="flex"
                flexDirection="column"
                gap="5px"
                justifyContent="center"
                alignItems="center"
              >
                <Image w="60px"  opacity="0.5" src="/assets/svgs/medicalrecords-nodata.svg" />
                <Box as="p">No data available</Box>
                {/* <PrimaryButton
                fontSize="14px"
                variant="mdBtn"
                buttonText="Upload report"
                btnIcon="/assets/imgs/add.png"
              /> */}
              </Box>
            )}
        </Flex>
       </Box>

      <ModalRight
        ref={reqRecordModal}
        modalWidth="360px"
        bodyStyle={{ p: "0" }}
        header={
          <>
            <Box>
              <Text fontSize="24px" fontWeight="600">
                Share record
              </Text>
              <Text fontSize="12px" fontWeight="400">
                Share your medical records easily with doctors or
                family.
              </Text>
            </Box>
          </>
        }
        body={
          <>
            <Box p="24px 32px">
              <Box
                border="1px solid #E6E7E9"
                bg="white"
                mb="10px"
                borderRadius="10px"
                w="100%"
                h="48px"
                px="10px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text
                  fontSize="14px"
                  fontWeight="500"
                  isTruncated
                 // maxWidth="calc(100% - 50px)"
                  title={recordURL}
                >
                  {recordURL}
                </Text>
                {/* {!copied ? (
                <Box as="button" pr="5px"> */}
                  {/* <Image onClick={(e) => copyToClipBoard(recordURL)} src="/assets/imgs/copy.png" /> */}
                  {/* <CopyToClipboard
                     text={recordURL}
                     onCopy={copyURLToClipBoard}
                     >
                       <Image src="/assets/imgs/copy.png" />
                  </CopyToClipboard>
                
                
                </Box>
                ):(
                 <p>Copied</p>
                )} */}
              </Box>
              <Box display="flex" flexDir="row">
                <PrimaryInput
                  variant="fullLeft"
                  defaultValue={"24"}
                  inputType="number"
                  inputStyleCss={{ w: "60%" }}
                  inputPlace="Expiry"
                  onChange={(e)=>setExpireFunction(e)}
                  inputValue={expiry}
                  isDisabled={true}
                />
                <DropdownInput
                  variant="fullRight"
                  defaultOption={"Hours"}
                  dropDownPlace="Type"
                  inputStyleCss={{ w: "40%" }}
                  options={["Hours"]}
                  onClick={handleexpiryInputChange}
                  readOnly={true}
                />
              </Box>
              
              <Text fontSize="16px" my="15px" fontWeight="400" textAlign="center">
               -or-
              </Text>
              <Box>
                {/* <Flex w="100%" zIndex="9"> */}

                        <DatePicker
                       // disableFutureDates={true}
                          // defaultDate={datePrePopulate}
                          onDateChange={(selectedDateValue) =>
                            handleChangeExpiryDate(selectedDateValue)
                          }
                          placeholderProp={"Expiry date"}
                          inputStyle={{borderRadius: "10px"}}
                           minDate={new Date().toISOString()}
                        />
                      {/* </Flex> */}
              </Box>
            </Box>
          </>
        }
        footerActions={
          <>
            {/* <Box w="100%">
            <PrimaryButton buttonText="Save" />
          </Box> */}
            <Box w="100%" display="flex" gap="10px">
              {/* <PrimaryButton buttonText="Revoke link" variant="grayBtnFull" btnStyle={{ color: "#AA2F27" }} /> */}
             
              <CopyToClipboard
                     text={recordURL}
                     > 
                     <PrimaryButton buttonText="Save and Copy" onClick={requestrecords} />
                  </CopyToClipboard>

            </Box>
          </>
        }
      />
      <ModalRight
        ref={editreqRecordModal}
        modalWidth="360px"
        bodyStyle={{ p: "0" }}
        header={
          <>
            <Box>
              <Text fontSize="24px" fontWeight="600">
                Edit record
              </Text>
              {/* <Text fontSize="12px" fontWeight="400">
                Request and share your medical records easily with doctors or
                family.
              </Text> */}
            </Box>
          </>
        }
        body={
          <>
            <Box p="24px 32px">
              <Box
                border="1px solid #E6E7E9"
                bg="white"
                mb="10px"
                borderRadius="10px"
                w="100%"
                h="48px"
                px="10px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text
                  fontSize="14px"
                  fontWeight="500"
                  isTruncated
                  maxWidth="calc(100% - 50px)"
                  title={recordURL}
                >
                  {recordURL}
                </Text>
                <Box as="button" pr="5px">
                  {/* <Image onClick={(e) => copyToClipBoard(recordURL)} src="/assets/imgs/copy.png" /> */}
                  <CopyToClipboard
                       text={recordURL}
                       onCopy={copyURLToClipBoard}>
                         <Image src="/assets/imgs/copy.png" />
                  </CopyToClipboard>
                </Box>
                {copied && <p>Copied</p>}
              </Box>
              { !showDateInput &&
                  <Box display="flex" flexDir="row">
                  <PrimaryInput
                    variant="leftDown"
                    defaultValue={expiry}
                    inputType="number"
                    inputStyleCss={{ w: "60%" }}
                    inputPlace="Expiry"
                    onChange={(e) => SetExpiry(e.target.value)}
                  />
                  <DropdownInput
                    variant="rightDown"
                    defaultOption={expirttype}
                    dropDownPlace="Type"
                    inputStyleCss={{ w: "40%" }}
                    options={["Hours", "Day"]}
                    onClick={handleexpiryInputChange}
                  />
                </Box>
              }
            
              { showDateInput &&
                <Box>
                {/* <Flex w="100%" zIndex="9"> */}


<PrimaryInput
                    variant="fullRound"
                    defaultValue={expiryDate}
                    inputPlace="Expiry Date"
                    isDisabled={true}
                  />
                        
                      {/* </Flex> */}
              </Box>
              }
              
              <Box></Box>
            </Box>
          </>
        }
        footerActions={
          <>
            {/* <Box w="100%">
            <PrimaryButton buttonText="Save" />
          </Box> */}
            <Box w="100%" display="flex" gap="10px">
              <PrimaryButton isDisabled={btnDisable} buttonText="Revoke link" onClick={revokeURL} variant="grayBtnFull" btnStyle={{ color: "#AA2F27" }} />
              {/* <PrimaryButton buttonText="Update" onClick={requestrecords} /> */}
            </Box>
          </>
        }
      />
    </>
  );
}

export default MedicalRecordIndex;
