import formAPIphysicalexam from "./physicalexam";


var formattedOutput = null;

const formAPIModelphysicalexam = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIphysicalexam(item));


  return formattedOutput
};

export default formAPIModelphysicalexam