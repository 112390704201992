
import formAPIModelcondition from "./condition";


var formattedOutput = null;
//var formattedArray = [];
const formAPIModelconditiondata = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIModelcondition(item));

  return formattedOutput
};

export default formAPIModelconditiondata


