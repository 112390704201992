import React, { forwardRef, useImperativeHandle } from 'react';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure } from '@chakra-ui/react';

const VideoPlayerModal = forwardRef(({modalHeader,modalBody,modalBodyStyle,modalFooterBtn,modalFooterBtnStyle, onClick, hasHeader = true, hasCloseBtn = true, hasFooter = true }, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useImperativeHandle(ref, () => ({
    openModal: () => onOpen(),
    closeModal: () => onClose(),
  }));

  return (
    <>
      <Modal isOpen={isOpen} h="200px" borderRadius="12px" onClose={onClose} isCentered> 
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent w="950px" maxWidth="fit-content">
          {hasHeader && (
            <ModalHeader fontSize="24px" fontWeight="600">{modalHeader}</ModalHeader>
          )}

          {hasCloseBtn && (
            <ModalCloseButton />
          )}
          <ModalBody {...modalBodyStyle}>
            {modalBody}
          </ModalBody>

          {hasFooter && (

            <ModalFooter>
       
              {/* <Button mr={3} onClick={onClick} {...modalFooterBtnStyle}> */}
                {modalFooterBtn}
              {/* </Button> */}
           
          </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
});

export default VideoPlayerModal;
