import formAPIheight from "./height";


var formattedOutput = null;

const formAPIModelheight = (data, type) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIheight(item,type));


  return formattedOutput
};

export default formAPIModelheight