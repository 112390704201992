import moment from "moment";
import formAPIModelFamilyMedicalCondition from "./medicalconditions";
import formAPIModelFamilyAllergies from "./allergies";
import formAPIModelFamilyMedications from "./medications";


export default function formAPIAllergies(dataArray) {
  // Assuming dataArray is an array of objects
  return dataArray.map(({ 
    age,
    bloodtype_cd,
    dob,
    email,
    familyid,
    familyimage,
    firstname,
    fullname,
    gender,
    gender_cd,
    height,
    heightunit_cd,
    imagepath,
    isadopted,
    isdeceased,
    lastname,
    maritalstatus,
    maritalstatus_cd,
    medicalcondition,
    medications,
    allergies,
    mobileno,
    patientid,
    relation,
    relation_cd,
    vaccinated,
    weight,
    weightunit_cd,
    countrycode,
    countryid

    }) => {
    return Object.freeze({
      age,
      bloodtype_cd,
      dob: dob ? moment(dob).format("YYYY-MM-DD") : null,
      email,
      familyid,
      familyimage,
      firstname,
      fullname,
      gender,
      gender_cd,
      height,
      heightunit_cd,
      imagepath,
      isadopted,
      isdeceased,
      lastname,
      maritalstatus,
      maritalstatus_cd,
      medicalcondition: medicalcondition !== null ? formAPIModelFamilyMedicalCondition(medicalcondition) : [],
      allergies: allergies !== null ? formAPIModelFamilyAllergies(allergies) : [],
      medications: medications !== null ? formAPIModelFamilyMedications(medications) : [],
      mobileno,
      patientid,
      relation,
      relation_cd,
      vaccinated,
      weight,
      weightunit_cd,
      countrycode,
      countryid
    });
  });
}
