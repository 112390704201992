import  FetchData, { } from '../../client';
import formapimodalhealth from '../../domains/myhealth/myhealths';
import formapimodalvisitoverview from '../../domains/visitoverview/index'
import {getLoginInfo, getUserID}  from '../../../auth';

const FetchMyHealthData = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_getpatienthealth", inputData);
    
    if (result.output.data) {
      //console.log(result.output.data)
    var finalOutput = formapimodalhealth(result.output.data)
    }
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchVisitOverviewData = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_getpatienthealth", inputData);
    
    if (result.output.data) {
      //console.log(result.output.data)
    var finalOutput = formapimodalvisitoverview(result.output.data)
    }
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchMedicalRecords = async (inputData) => {
  try {
    
    const result = await FetchData("midoc_getmedicalreportbyid", inputData);
    if (result.output.data) {
      //console.log(result.output.data)
    var finalOutput = formapimodalhealth(result.output.data)
    }
    return finalOutput;
    //return result;

  } catch (error) {
    console.error('Error get medical records:', error);
    throw error;
  }
};

export {FetchMyHealthData,FetchVisitOverviewData, FetchMedicalRecords};
