import moment from "moment";
import formAPIModelMedicalCondition from '../../domains/medicalconditions/medicalconditions.';
import formAPIAllergies from "../profilefamily";
import formAPIModelFamilyAllergies from "../profilefamily/allergies";
import formAPIModelAllergiesForFamilyHistory from "../familyhistoryallergies/allergies";
export default function formAPIFamilyHistory({
    age,
bloodtype_cd,
dob,
email,
familyid,
familyimage,
firstname,
fullname,
gender,
gender_cd,
height,
heightunit_cd,
imagepath,
isadopted,
isdeceased,
lastname,
maritalstatus,
maritalstatus_cd,
medicalcondition,
mobileno,
patientid,
relation,
relation_cd,
vaccinated,
weight,
weightunit_cd,
allergies,
countrycode,
countryid
   

})
{ 
  return Object.freeze({
age,
bloodtype_cd,
dob: dob,
email,
familyid,
familyimage,
firstname,
fullname:fullname?fullname:"-",
gender,
gender_cd,
height,
heightunit_cd,
imagepath,
isadopted,
isdeceased,
lastname,
maritalstatus,
maritalstatus_cd,
medicalcondition : medicalcondition !== null ? formAPIModelMedicalCondition(medicalcondition) : [],
allergies : allergies !== null ? formAPIModelAllergiesForFamilyHistory(allergies) : [],
mobileno,
patientid,
relation,
relation_cd,
vaccinated,
weight,
weightunit_cd,
   
countrycode,
countryid
   
  });
}