import  FetchData, { } from '../../client';
import formapimodalFamily from '../../domains/profilefamily';
import {getLoginInfo, getUserID}  from '../../../auth';


const getFamiliyList = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_getpatientfamilylist", inputData);
    

      if (result && result.output && result.output.data || result.output.data == null) {
      var finalOutput = formapimodalFamily(result.output.data);
        }else{
         finalOutput = result;
        }

     return finalOutput;

  } catch (error) {
    console.error('Error familly fetching :', error);
    throw error;
  }
};

const addFamilyMember = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_addpatientfamily", inputData);
    
      return result;

  } catch (error) {
    console.error('Error familly fetching :', error);
    throw error;
  }
};
const updateFamilyMember = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_updatepatientfamily", inputData);
    
      return result;

  } catch (error) {
    console.error('Error familly fetching :', error);
    throw error;
  }
};

export {getFamiliyList, addFamilyMember, updateFamilyMember};
