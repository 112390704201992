export default function formAPIcurrentmedications({
    //medicineid,
    medicinename,
    dosage,
    chemicalcomposition,
    medicinetype
    
   

})
{
 
  return Object.freeze({
    //medicineid,
    medicinename,
    dosage,
    chemicalcomposition,
    medicinetype
   
  });
}