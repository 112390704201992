import moment from "moment";
import formAPIResultInterpretition from "./result_interpretition";

const formAPIImaging = (inputData) => {
  const formattedData = [];

  inputData.forEach(item => {
    const existingEntry = formattedData.find(entry => entry.reportname === item.reportname);

    if (existingEntry) {
      // If entry already exists, add the current item to its 'data' array
      existingEntry.data.push({

        documentdate: item.documentdate,
        documenturl: item.documenturl,
        imagepath: item.imagepath,
        reportdescription: item.reportdescription,
        reportid: item.reportid,
        reportname: item.reportname,
        verifieddate: item.verifieddate,
        results_interpretation: item.results_interpretation,

      });
    } else {

      // If entry doesn't exist, create a new entry
      formattedData.push({

        reportname: item.reportname,
        data: [
          {
            documentdate: item.documentdate,
            documenturl: item.documenturl,
            imagepath: item.imagepath,
            reportdescription: item.reportdescription,
            reportid: item.reportid,
            reportname: item.reportname,
            verifieddate: item.verifieddate,
            results_interpretation: item.results_interpretation ? formAPIResultInterpretition(item.results_interpretation) : {},
          }
        ],
        documentdate: item.documentdate,
        documenturl: item.documenturl,
        imagepath: item.imagepath,
        reportdescription: item.reportdescription,
        reportid: item.reportid,
        reportname: item.reportname,
        verifieddate: item.verifieddate,
        results_interpretation: item.results_interpretation ? formAPIResultInterpretition(item.results_interpretation) : {},
     
      });
    }
  });

  return formattedData;
};

export default formAPIImaging;