import formAPIVoice from "./voice";


var formattedOutput = null;

const formAPIModelVoice = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIVoice(item));


  return formattedOutput
};


export default formAPIModelVoice