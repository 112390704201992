import moment from "moment";
export default function formAPIModelcondition({
  condition,
  condition_cd,
  conditionidentifieddate,
  id,
   

})
{
  return Object.freeze({
    condition,
    condition_cd,
    conditionidentifieddate:conditionidentifieddate,
    id,

   
  });
}