import moment from "moment";
export default function formAPIDoctor({
  address,
  appointmentid,
  city,
  country,
  doctorid,
  email,
  fee,
  fullname,
  gender,
  hospitalname,
  imagepath,
  latitude,
  longitude,
  mobileno,
  postalcode,
  profilepictureurl,
  qualification,
  ratings,
  specality,
  state,
  yearsofexperience,
  appointmentdate


}) {
  return Object.freeze({
    address,
    appointmentid,
    city,
    country,
    doctorid,
    email,
    fee,
    fullname,
    gender,
    hospitalname,
    imagepath,
    latitude,
    longitude,
    mobileno,
    postalcode,
    profilepictureurl,
    qualification,
    ratings,
    specality,
    state,
    yearsofexperience,
    appointmentdate

  });
}