import React,{useState, useEffect, useRef} from "react";
import { Flex, Box, Image, Spacer, Radio, RadioGroup, Stack, Divider, InputGroup, InputRightElement,
     Input ,    
  CheckboxGroup,
  Checkbox,
  Text,
  UnorderedList,
  ListItem,
  Heading
    } from "@chakra-ui/react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import { DropdownInput, PrimaryButton, PrimaryInput, Toaster } from "../../components";
import SuccessUploadIndex from "./success";
import { useLocation } from "react-router-dom";
import {FetchPrescriptionuploadData}  from "../../middleware/services/myhealth/uploaddocument";
import useLoader  from "../../components/loader/loaderstates";
import Loader  from "../../components/loader/loader";
import { useNavigate } from "react-router-dom";
import DatePicker from "../../components/date-picker";
import { format, isValid, parse } from 'date-fns'
import moment from 'moment';
import MiniDropdownInput from "../../components/mini-dropdown";
import {
    AddMedicine,
  } from "../../middleware/services/myhealth/medications";
import { uploadImageInProfile } from "../../middleware/services/profile/account";
import DynamicModal from "../../components/dynamic-modal";
import { displayMillion_ThoudandValues, formatDate_DD_MM_YYYY, handleAlphaDotAndSpace } from "../../auth";
import { MessageToaster } from "../../components/manualmessage";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { FetchMedicineAIAnswer, FetchMedicineAIPrompts } from "../../middleware/services/home";
import TypingLoader from "../../components/typing-loader";
import AiModal from '../../components/ai-modal/index';
import { FetchTokens } from "../../middleware/services/profile/rewards";




function PrescriptionUpload() {
  const location = useLocation();
  const { result, filename} = location.state || {};
  var navigate = useNavigate();
  const {isLoading, showLoader, dismissLoader } = useLoader();
  var [data, setData] = useState(null);
  var [actualdata, setActualData] = useState(null);
  var [reportdate, setReportDate] = useState(null);
  var [reportname, setReportName] = useState("");
  var [name, setName] = useState("");
  var [age, setAge] = useState("");
  
  var [doctorname, setDoctorName] = useState("");
  
  const [selectedImage, setSelectedImage] = useState(null);
  const [showpatientdetails, setShowPatientDetails] = useState(true);
  const [showpreview, setShowPreview] = useState(true);
  const [activeTestIndex, setActiveTestIndex] = useState(0);
  const [showedittestname, setShowEditTestName] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [testNameInput, setTestNameInput] = useState('');
  const [showsucess, setShowSuccess] = useState(false);
  const { showSuccessToast, showErrorToast } = Toaster();
  const [testname, setTestName] = useState("");
  const [fromdate, setFromDate] = useState(moment().format("DD-MM-yyyy"));
  const [todate, setToDate] = useState(moment().format("DD-MM-yyyy"));
  const [dosageunit, setMedicineDosageUnit] = useState("mg");
  const [frequency, setMedicineDosageFrequency] = useState("");
  const [dosage, setMedicineDosage] = useState(null);
  const [selectedRadioValues, setSelectedRadioValues] = useState([]);
  const [hospitalname, SetHospitalName] = useState("");
  const [reupload, setReupload] = useState(false);
  const [file, setFile] = useState(location.state.file || null);
  const [fileUrl, setFileUrl] = useState(null);
  
  const [minDateState , setMinDateState] = useState('')
  const [maxDateState , setMaxDateState] = useState('')

  const [medcineName , setMedcineName] = useState('')
  const [benifit , setBenifit] = useState('')
  const [composition , setComposition] = useState('')
  const [sideEffects , setSideEffects] = useState('')
  const learnMoreModal = React.useRef();
  const { showMessageToast, closeMessageToast  } = MessageToaster();

  const [medicinetype, setMedicineType] = useState("Tablet");
  const [MedicineList, setMedicineList] = useState(null);
  const consentForm = useRef();
  const AIChatModel = useRef();
  const [animate, setAnimate] = useState(false);
  const [AiInput, setAiInput] = useState(null);
  const [aianswers, setAIAnswers] = useState([]);
  const [remainingquestions, setRemainingQuestions] = useState([]);
  const [aiquestion, setAIAnalysis] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const chatContainerRef = useRef(null);


  const [upgradePlanModal , setUpgradePlanModal] = useState(false);
  const [remainingTokens, setRemainingTokens] = useState();

  useEffect(() => {
    getTokens();
  }, [])
  var input = {}
  var getTokens = async () => {
    try {
      var result = await FetchTokens(input);
      if (result) {
        setRemainingTokens(result)
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.log(error, "error in fetching account data")
    }
    dismissLoader();
  }
  //const file = location.state.file;
  const ageinyears = [];
  
  for (let i = 1; i <= 100; i++) {
    ageinyears.push(String(i));
  }
  useEffect(() => { 
    if (filename) { 
    const reader = new FileReader();
    reader.onloadend = () => {
      const fileName = filename && filename.name.split('.').pop().toLowerCase(); // Use 'split' instead of 'Split', and 'pop' instead of 'Last'
      if (fileName === 'pdf') { // Compare with 'pdf' instead of '.pdf'
        setSelectedImage("/assets/imgs/pdf.jpeg");
      } else {
        setSelectedImage(reader.result);
      }
    };
    reader.readAsDataURL(filename);
    setFile(filename);
    setShowPreview(false);
    setData(result);
    setActualData(result);
    setName(result && result.patient_info && result.patient_info.name);
            //setAge(result && result.patient_info && result.patient_info.age);
            const age = result && result.patient_info && result.patient_info.age;
             // Regular expression to check for valid numeric age
            const isValidAge = /^\d+$/.test(age);
            const ageNumber = Number(age);

            // Check if age is valid and within the specified range
            if (isValidAge && ageNumber >= 1 && ageNumber <= 200) {
              setAge(ageNumber); // Set state to the valid age
            } else {
              setAge(null); // Set state to null if age is invalid
            }
            setDoctorName(result && result.patient_info && result.patient_info.doctor_name);
             const dateOfReport = result?.patient_info?.date_of_report;
            if (dateOfReport) {
              const formattedDate = formatDate_DD_MM_YYYY(dateOfReport);
              if (formattedDate === '-') {
                const today = new Date();
                setReportDate(today);
              } else {
                setReportDate(formattedDate);
              }
            } else {
              const today = new Date();
              setReportDate(today);
            }
            setReportName(result && result.report_info && result.report_info.name);
  }
  }, [result]);
    useEffect(() => {  
      if (file && !filename) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileName = file.name.split('.').pop().toLowerCase(); // Use 'split' instead of 'Split', and 'pop' instead of 'Last'
        if (fileName === 'pdf') { // Compare with 'pdf' instead of '.pdf'
          setSelectedImage("/assets/imgs/pdf.jpeg");
        } else {
          setSelectedImage(reader.result);
        }
        consentForm.current.openModal();
      };
      reader.readAsDataURL(file);
      setFile(file);
    }
    }, [file, filename]);

    const fetchData = async () => {
      showLoader();
      showMessageToast("Please wait while we are fetching data")
      let attempts = 0;
      const maxAttempts = 4;
    
      const makeFetchCall = async () => {
        try {
          const result = await FetchPrescriptionuploadData(file, file.name, file.type);
          if (result?.output?.message == "No credits are available"){
           // showErrorToast("No sufficient tokens available");
            setUpgradePlanModal(true)
          setTimeout(() => {
            setUpgradePlanModal(false)
          }, 100);
            closeMessageToast();
            dismissLoader();
            return false;
          }
          
          if (result.patient_info) {
            if(result.patient_info.date_of_report == "" || result.patient_info.date_of_report == "0000-00-00"){
              const today = moment().format('YYYY-MM-DD');
              result.patient_info.date_of_report = today;
            }
            setData(result);
            setActualData(result)
            setShowPreview(false);
            const reader = new FileReader();
            reader.onloadend = () => {
              const fileName = file.name.split('.').pop().toLowerCase(); // Use 'split' instead of 'Split', and 'pop' instead of 'Last'
              if (fileName === 'pdf') { // Compare with 'pdf' instead of '.pdf'
                setSelectedImage("/assets/imgs/pdf.jpeg");
              } else {
                setSelectedImage(reader.result);
              }
            };
            reader.readAsDataURL(file);
            const dateOfReport = result?.patient_info?.date_of_report;
            if (dateOfReport) {
              let formattedDate = formatDate_DD_MM_YYYY(dateOfReport);
              if (formattedDate === '-') {
                const today = new Date();
                setReportDate(today);
              } else {
                setReportDate(formattedDate);
              }
            } 
            setReportName(result && result.report_info && result.report_info.name);
            setName(result && result.patient_info && result.patient_info.name);
            //setAge(result && result.patient_info && result.patient_info.age && String(result.patient_info.age));
            const age = result && result.patient_info && result.patient_info.age;
            // Regular expression to check for valid numeric age
            const isValidAge = /^\d+$/.test(age);
            const ageNumber = Number(age);

            // Check if age is valid and within the specified range
            if (isValidAge && ageNumber >= 1 && ageNumber <= 200) {
              setAge(ageNumber); // Set state to the valid age
            } else {
              setAge(null); // Set state to null if age is invalid
            }
            setDoctorName(result && result.patient_info && result.patient_info.doctor_name);
            const fileUrl = await uploadImageInProfile(file, file.name);
            setFileUrl(fileUrl.name);            
            dismissLoader();
            closeMessageToast();
          } else {
            attempts++;
            if (attempts < maxAttempts) {
              await makeFetchCall(); // Retry the call
            } else {
              closeMessageToast();
              handleError(); // Handle the error after max attempts
            }
          }
        
        } catch (error) {
          console.error("Error in Upload Documents:", error);
          attempts++;
          if (attempts < maxAttempts) {
            await makeFetchCall(); // Retry the call
          } else {
            handleError(); // Handle the error after max attempts
          }
        }
      };
    
      const handleError = () => {
        showErrorToast("Something went wrong! please try again");
        setShowPreview(true);
        dismissLoader();
      };
    
      await makeFetchCall();
    };
    
    const uploadprescription = async (e) => {
      e.preventDefault();
      const file = e.target.files[0];
      showLoader();
      const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(file);
    setFile(file)
      dismissLoader();
    };
    const handlePatientDetailsConfirm = ()=>{
      setShowPatientDetails(false);
    } 

    const backFunction = () => {
        navigate("/myhealth");    
    };
    const handlenamechange = (event) => {
        let { value } = event.target;
        value  = handleAlphaDotAndSpace(value, 50);
        setName(value);  

        setData(prevData => ({
          ...prevData,
          patient_info: {
            ...prevData.patient_info,
            name: value
          }
        }));
      };
      const handledoctornamechange = (event) => {
        let { value } = event.target;
        value  = handleAlphaDotAndSpace(value, 50);
        setDoctorName(value);  

        setData(prevData => ({
          ...prevData,
          patient_info: {
            ...prevData.patient_info,
            doctor_name: value
          }
        }));
      };
      const handlereportnamechange = (event) => {
        const { value } = event.target;
        setData(prevData => ({
          ...prevData,
          patient_info: {
            ...prevData.patient_info,
            name: value
          }
        }));
      };
      const handledobChange = (selectedOption) => {
        setData(prevData => ({
          ...prevData,
          patient_info: {
            ...prevData.patient_info,
            age: selectedOption
          }
        }));
      };
      const handleDateChange = (selectedDate) => {
        let formattedDate = moment(selectedDate).format('YYYY-MM-DD');
        setReportDate(formattedDate); // Update state if needed
        // setData(prevData => ({
        //   ...prevData,
        //   patient_info: {
        //     ...prevData.patient_info,
        //     date_of_report: formattedDate
        //   }
        // }));
      };
      const handletestnamechange = (event) =>{
        setTestNameInput(event.target.value)
      }
      const handlehospitalnamechange = (event) =>{
        SetHospitalName(event.target.value)
      }
      const handleChangeDosageUnit = (selectedOption) => {
        setMedicineDosageUnit(selectedOption.value);
      };

      const handleTestNameUpdate = (index) => {
        setShowEditTestName(false);
        // Make sure testNameInput is not empty
        if (testNameInput.trim() !== '') {
          setData(prevData => {
            const updatedData = { ...prevData };
            updatedData.medicine_list[index].medicine_name = testNameInput;
            return updatedData;
          });
           // Set showedittestname to false after updating test name
        }
      };
      const handleedittestname = () => {
        setShowEditTestName(true);
      };
      const handleRadioChange = (event, medicineIndex) => {
        //const { value } = event;
        setMedicineType(event)
        setData(prevData => {
          const updatedMedicineList = [...prevData.medicine_list];
          updatedMedicineList[medicineIndex].medicinetype = event;
          return { ...prevData, medicine_list: updatedMedicineList };
        });
      };
      const handleDosageChange = (event, medicineIndex) => {
        const { value } = event.target;
      
        setData(prevData => {
          const updatedMedicineList = [...prevData.medicine_list];
          updatedMedicineList[medicineIndex].dosage = value;
          return { ...prevData, medicine_list: updatedMedicineList };
        });
      };
      const handleFromDateChange = (selecteddate, medicineIndex) => {
        let formattedDate = moment(selecteddate).format('YYYY-MM-DD');
        setData(prevData => {
          const updatedMedicineList = [...prevData.medicine_list];
          updatedMedicineList[medicineIndex].startdate = formattedDate; 
          return { ...prevData, medicine_list: updatedMedicineList };
        });
        setMinDateState(selecteddate)
      };
      
      const handleToDateChange = (selecteddate, medicineIndex) => {
        let formattedDate = moment(selecteddate).format('YYYY-MM-DD');
        setData(prevData => {
          const updatedMedicineList = [...prevData.medicine_list];
          updatedMedicineList[medicineIndex].enddate = formattedDate;
          return { ...prevData, medicine_list: updatedMedicineList };
        });
        setMaxDateState(selecteddate)
      };
      
      const handleConsumptionFrequencyChange = (selectedOption, medicineIndex) => {
        let frequencyValue;
        if(selectedOption == "1x per day"){
          frequencyValue = "1";
        }else  if(selectedOption == "2x per day"){
          frequencyValue = "2";
        }else  if(selectedOption == "3x per day"){
          frequencyValue = "3";
        }else  if(selectedOption == "4x per day"){
          frequencyValue = "4";
        }
      
        setData(prevData => {
          const updatedMedicineList = [...prevData.medicine_list];
          updatedMedicineList[medicineIndex].frequency = frequencyValue;
          return { ...prevData, medicine_list: updatedMedicineList };
        });
      };
      const handleMedicineTimeChange = (selectedTimes, medicineIndex) => {
        const morning = selectedTimes.includes("morning") ? 1 : 0;
        //const afternoon = selectedTimes.includes("afternoon") ? 1 : 0;
        const evening = selectedTimes.includes("evening") ? 1 : 0;
        const night = selectedTimes.includes("night") ? 1 : 0;
      
        setData(prevData => {
          const updatedMedicineList = [...prevData.medicine_list];
          updatedMedicineList[medicineIndex].morning = morning;
          //updatedMedicineList[medicineIndex].afternoon = afternoon;
          updatedMedicineList[medicineIndex].evening = evening;
          updatedMedicineList[medicineIndex].night = night;
          return { ...prevData, medicine_list: updatedMedicineList };
        });
      };
      const handleConfirm = async () => {
        setMaxDateState('')
        setMinDateState('')
        if (activeTestIndex === data.medicine_list.length - 1) {          
          setIsSubmitting(true);
          const updatedMedicineList = data.medicine_list.map(medicine => {
            if (!medicine.startdate) {
              let formattedDate = moment(fromdate, 'DD-MM-YYYY').format('YYYY-MM-DD');
              medicine.startdate = formattedDate; // You can use any default value here
            }
            if (!medicine.enddate) {
              let formattedDate = moment(todate, 'DD-MM-YYYY').format('YYYY-MM-DD');
              medicine.enddate = formattedDate;// You can use any default value here
            }
            if (!medicine.dosage) {
              medicine.dosage = medicine.medicine_quantity; // You can use any default value here
            }
            if (!medicine.iscurrentmedicine) {
              medicine.iscurrentmedicine = 1;
            }
            if (medicine.hasOwnProperty('medicine_name')) {
                medicine.medicinename = medicine.medicine_name;
                delete medicine.medicine_name;
              }
              delete medicine.medicine_quantity;
            return medicine;
          });
          const updatedMedicineNames = updatedMedicineList.map(medicine => ({ name: medicine.medicinename }));
          let medicinenameprompt = {
            medicinename: []
          };
          medicinenameprompt.medicinename.push(...updatedMedicineNames);
          setMedicineList(medicinenameprompt);
          var formattedDate;
          if(reportdate){
            const parsedDate = moment(reportdate, "DD-MM-YYYY"); // parse it first
            formattedDate = parsedDate.format("YYYY-MM-DD"); // now format it
          }else{
            formattedDate = null;
          }
          
          let input = {
            patientid: "",
            documenttype_cd: "",
            documentcategory_cd: "",
            documentname: "",
            documenturl: fileUrl,
            prescribedby: data.patient_info.doctor_name,
            hospitalname: hospitalname,
            patientname: data.patient_info.name,
            age: data.patient_info.age,
            documentdate:  formattedDate || null,
            medicinedetail: updatedMedicineList,
          };
          const FinalInput = { ...input, actualinput: actualdata };
          showLoader();
          const result = await AddMedicine(FinalInput);
          if (result.output.result === "success") {

            await fetchAiPrompts(medicinenameprompt);           
            showSuccessToast("Prescription Submitted Successfully");
            setShowSuccess(true);           

            dismissLoader();
          }
        } else {
            if (!data.medicine_list[activeTestIndex].medicinetype) {
                showErrorToast("Please select any medicine type");
                return; // Prevent further execution
              }
          // Increment activeTestIndex to display the next test object
          setActiveTestIndex(prevIndex => prevIndex + 1);
          
        }
      };
      const openLearnMoreModal = (medicineDetails)=>{
        setMedcineName(medicineDetails.medicine_name)

        setBenifit(medicineDetails.benefit)
        setComposition(medicineDetails.chemical_composition)
        setSideEffects(medicineDetails.side_effects)
    learnMoreModal.current.openModal();
      }
      const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
      const modalBodyRef = useRef();
    
      const handleScroll = () => {
       if (modalBodyRef.current) {
         const { scrollTop, scrollHeight, clientHeight } = modalBodyRef.current;
         const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10;
         setIsScrolledToBottom((prev) => {
           // Log the previous state and new calculation for debugging purposes
           console.log('Previous state:', prev, 'New calculation:', isAtBottom);
           return isAtBottom;
         });
       }
     };
     useEffect(() => {
      if (animate) {
        const timer = setTimeout(() => {
          setAnimate(false);
        }, 500);
        return () => clearTimeout(timer);
      }
    }, [animate]);
    useEffect(() => {
      // Scroll to bottom whenever aianswers changes
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    }, [aianswers]);
    const fetchAiPrompts = async (medicinenameprompt)=>{
      showLoader();
      setRemainingQuestions([])
      setAiInput(null);
      setAIAnswers([])
      setInitialRender(true)
      const response = await FetchMedicineAIPrompts(medicinenameprompt);
            if (response.generated_question) {
              setAIAnalysis(response);
              setRemainingQuestions(response.generated_question);
              AIChatModel.current.openRight();
            }
        dismissLoader();
    }
    const GetAIAnswer = async (prompt) => {
      // Display question immediately
      const newQuestion = { question: prompt, answer: "" };
      setAIAnswers((prevState) => [...prevState, newQuestion]);
    
      // Remove the question from remaining questions immediately
      setRemainingQuestions((prevState) => prevState.filter((question) => question.value !== prompt));
    
      // Trigger animation
      setAnimate(true);
    
      // Set initialRender to false after the first question is clicked
      setInitialRender(false);
      let AichatInput = { ...MedicineList, query:prompt };
    
    
      const response = await FetchMedicineAIAnswer(AichatInput);
    
      if (response) {
        // Replace "typing" with actual answer
        setAIAnswers((prevState) =>
          prevState.map((item) =>
            item.question === prompt && item.answer === ""
              ? { question: item.question, answer: response.answer }
              : item
          )
        );
        getTokens();
      }
    };
     useEffect(() => {
       const modalBodyElement = modalBodyRef.current;
       if (modalBodyElement) {
         modalBodyElement.addEventListener('scroll', handleScroll);
         // Perform an initial check to see if the content is already at the bottom
         handleScroll();
       }
       return () => {
         if (modalBodyElement) {
           modalBodyElement.removeEventListener('scroll', handleScroll);
         }
       };
     }, [modalBodyRef.current]);
    
     useEffect(() => {
       if (modalBodyRef.current) {
         setTimeout(() => {
           handleScroll();
         }, 100); // Adding a small delay to ensure dimensions are correct
       }
     }, []);
    
    const submitconsentform = async ()=>{
     consentForm.current.closeModal();
    }
    const closeconsentform = async ()=>{
      window.history.back();
     }
     return (
        <>
        {!showsucess ? (  
        <> 
          <Box w="100%" display={{base: "none", lg: "block"}}>
            {/* <SuccessIndex /> */}
            <Box top="0">
              <Navbar messageModal={upgradePlanModal} />
              {isLoading && <Loader/>}
              <SecondaryNav
                withBack="Upload prescription"
                goBack={backFunction}
              />
            </Box>
            {showpreview &&
            <Box w="100%" p="32px" bg="#E6E7E9" overflow="hidden auto" height="calc(100vh - 140px)">
              <Box display="flex" pb="25px" justifyContent="center" alignItems="center">
                <Image w="calc(100vw - 70%)" boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;" h="auto" src={selectedImage} />
              </Box>

              <Box w="calc(100vw - 70%)" display="flex" marginInline="auto" alignItems="center" gap="20px" pos="sticky" bottom="0">
                          <input
                  type="file"
                  id="prescriptionupload"
                  style={{ display: 'none' }}
                  accept="image/jpeg,image/jpg,image/png,image/tiff"
                  onChange={uploadprescription}
                />
                <PrimaryInput
                    inputType="file"
                    uploadText="Re-Upload"
                    id="prescriptionupload"
                    onChange={uploadprescription}
                    variant="grayBtnFull"
                    buttonText="Reupload"
                    //isDisabled={false}
                    inputStyleCss={{boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;", borderRadius: "10px"}}
                  />
                <PrimaryButton
                  onClick={fetchData}
                  buttonText="Confirm"
                />
              </Box>
            </Box>
            }
            {!showpreview && 
            <Flex overflowY="auto" height="100vh" direction="row">
              {/* Image section start */}
              <Box flex="1" overflowY="scroll" p="32px" h="calc(100vh - 100px)" bg="#E6E7E9">

                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  h="100vh">

                  <Image w="calc(100vh - 25%)" src={selectedImage} />
                </Box>
              </Box>
            
              {/* Image section end */}

              <Box w="500px" h="calc(100vh - 140px)" overflow="hidden auto"  display="flex" flexDirection="column" justifyContent="space-between">
  {(showpatientdetails && data && data.patient_info &&
  <>
                <Box overflowY="auto" p="24px 32px" h="100%" >
                  <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">Please verify the details below</Box>
                  <PrimaryInput inputPlace="Name" onChange={handlenamechange} inputValue={name} defaultValue={name && name ? name :""} variant="defaultSide" />
                  <DropdownInput variant="bothSide" onClick={handledobChange} defaultOption={age} dropDownPlace="Age" options={ageinyears}/>
                  <PrimaryInput inputPlace="Doctor name" onChange={handledoctornamechange} inputValue={doctorname} defaultValue={doctorname} variant="bothSide" />
                  <DatePicker 
                  disableFutureDates={true} 
                  defaultDate={reportdate}
                  placeholderProp="Date of document" 
                  leftPos={{left: "3px"}} 
                  //inputStyle={{borderRadius: "0"}} 
                  onDateChange={(selectedDateValue) => 
                  handleDateChange( selectedDateValue)
                  } />                
                  <PrimaryInput inputPlace="Hospital Name" onChange={handlehospitalnamechange}  variant="bothSide" />
                  <PrimaryInput inputPlace="Document name" onChange={handlereportnamechange} defaultValue={reportname}  variant="downSide" />
                </Box>

                <Box w="100%" display="flex" gap="20px" alignItems="center" p="24px 32px">
                {/* <input
                  type="file"
                  id="prescriptionupload"
                  style={{ display: 'none' }}
                  onChange={uploadprescription}
                />
                <PrimaryInput
                    inputType="file"
                    uploadText="Re-Upload"
                    id="prescriptionupload"
                    onChange={uploadprescription}
                    variant="grayBtnFull"
                    buttonText="Reupload"
                    //isDisabled={false}
                  /> */}
                  <PrimaryButton onClick={handlePatientDetailsConfirm} buttonText="Confirm" btnStyle={{my: "0"}}  />
                </Box>
  </>
  )}
  {(!showpatientdetails &&
    <>
    {data && data.medicine_list && data.medicine_list.map((step, index) => (
  index === activeTestIndex && (

                <Box overflowY="auto" h="100%" key={index}>
                    <Box bg="#061027" minH="115px" p="18px 28px" display="flex" flexDir="column" gap="8px">
                    <Box display="flex" gap="8px">
                    {data.medicine_list.map((_, i) => (
                      <Box
                        as="button"
                        key={i}
                        p="0"
                        borderTop={`3px solid ${i === activeTestIndex ? '#14C585' : '#212b40'}`}
                        w="16.666%"
                        h="20px"
                      ></Box>
                    ))}
                  </Box>
                      <Box>
                        <Box display="flex" flexDir="row" justifyContent="space-between" gap="10px" alignItems="center">
                          <Box fontSize="20px" fontWeight="600" color="#E6E7E9">
                          {(!showedittestname &&
                          <>
                            {step.medicine_name ? step.medicine_name :"-"} 
                            <Image w="13px" ml="10px" onClick={handleedittestname} h="13px" display="inline-block" src="/assets/imgs/edit-white.png"/>
                            </>
                            )}
                            {(showedittestname &&
                            <>
                            <Box>
                            <Box display="flex" flexDir="row" justifyContent="space-between" gap="5px" alignItems="center">
                              <InputGroup>
                                <Input type="text" defaultValue={step.medicine_name} color="white" fontSize="16px" fontWeight="600" border="2px solid white" borderRadius="10px" height="48px" minW="50px" onChange={handletestnamechange}/>
                                  <InputRightElement top="4px">
                                    <Box as="button" bg="white" display="flex" justifyContent="center" alignItems="center" height="48px" minW="50px" borderRadius="0px 10px 10px 0" onClick={() => handleTestNameUpdate(index)}><Image src="/assets/imgs/check-black.png" /></Box>
                                  </InputRightElement>
                              </InputGroup>
                            </Box>                      
                          </Box>
                          </>
                            )}
                          </Box>
                          <Box as="button" border="1px solid #0B1F4F" color="white" fontSize="12px" fontWeight="600" borderRadius="full" p="5px 10px" onClick={()=>openLearnMoreModal(step)}>
                            Learn more
                          </Box>
                        </Box>                      
                      </Box>
                    </Box>
                    <Box overflowY="auto" p="24px 32px" h="100%">
                    <RadioGroup
                      //defaultValue="Tablet"
                      onChange={(event) => handleRadioChange(event, index)}
                    >
                      <Stack direction="row" gap="20px">
                        <Radio value="Tablet">Tablet</Radio>
                        <Radio value="Syrup">Syrup</Radio>
                      </Stack>
                    </RadioGroup>
                    <Divider />
                    <Box>
                      {/* <MiniDropdownInput
                        variant="bothside"
                        options={[
                          { label: "mg", value: "151" },
                          { label: "ml", value: "152" },
                        ]}
                        placeholderProp="Dosage"
                        onSelect={handleChangeDosageUnit}
                        defaultValue={step.medicine_quantity ? step.medicine_quantity : ""}
                        handleInputChange={(event) =>
                          handleDosageChange(event, index)
                        }
                      /> */}
                          {medicinetype == "Tablet" &&
                      <MiniDropdownInput
                      variant="bothside"
                      options={ [
                        { label: "mg", value: "151" },
                        { label: "ml", value: "152" },
                        ] }
                        placeholderProp="Dosage"
                        onSelect={handleChangeDosageUnit}
                        defaultValue={step.medicine_quantity ? step.medicine_quantity : ""}
                        handleInputChange={(event) =>
                          handleDosageChange(event, index)
                        }
                    />
                      }
                        {medicinetype == "Syrup" &&
                      <MiniDropdownInput
                      variant="bothside"
                      options={ [
                        { label: "ml", value: "152" },
                          { label: "mg", value: "151" },
                        ] }
                        placeholderProp="Dosage"
                        onSelect={handleChangeDosageUnit}
                        defaultValue={step.medicine_quantity ? step.medicine_quantity : ""}
                        handleInputChange={(event) =>
                          handleDosageChange(event, index)
                        }
                    />
                      }
                      <Box display="flex" flexDir="row">
                        {/* <PrimaryInput
                          variant="bothSide"
                          id="vitaldate"
                          inputType="date"
                          inputPlace="Start Date"
                          inputStyleCss={{ w: "50%" }}
                          defaultValue={fromdate}
                          onChange={(event) => handleFromDateChange(event, index)}
                        />
                        <PrimaryInput
                          variant="bothSide"
                          id="vitaldate"
                          inputType="date"
                          inputPlace="End Date"
                          inputStyleCss={{ w: "50%" }}
                          defaultValue={todate}
                          onChange={(event) => handleToDateChange(event, index)}
                        /> */}
                        <DatePicker
                        disableFutureDates={true}
                          maxDate={maxDateState}
                          defaultDate={fromdate} 
                          onDateChange={(selectedDateValue) =>
                            handleFromDateChange(selectedDateValue, index)
                          }
                          placeholderProp={"Start Date"} 
                          calendarStlye={{left: "-18px",  right: "unset"}}
                        />
                        <DatePicker 
                        disablePastDates={true}
                        minDate={minDateState}
                          defaultDate={todate} 
                          onDateChange={(selectedDateValue) =>
                            handleToDateChange(selectedDateValue, index)
                          }
                          placeholderProp={"End Date"} 
                        />
                      </Box>
                      <DropdownInput
                        variant="downSide"
                        dropDownPlace="Consumption frequency"
                        options={["1x per day", "2x per day", "3x per day", "4x per day"]}
                        onClick={(selectedOption) => handleConsumptionFrequencyChange(selectedOption, index)}
                      />
                      <CheckboxGroup
                        colorScheme="green"
                        onChange={(selectedTimes) => handleMedicineTimeChange(selectedTimes, index)}
                      >
                        <Stack spacing={2} pt="20px" direction="row">
                          <Checkbox value="morning">Morning</Checkbox>
                          <Checkbox value="evening">Evening</Checkbox>
                          <Checkbox value="night">Night</Checkbox>
                        </Stack>
                      </CheckboxGroup>
                    </Box>
                    <Divider />
                    </Box>
                </Box>
  )
    ))}
    <Box w="100%" p="18px 28px">
    <PrimaryButton  buttonText={activeTestIndex === data && data.medicine_list && data.medicine_list.length - 1 ? "Submit" : "Confirm"}
                //isDisabled={isSubmitting}
                onClick={handleConfirm} btnStyle={{my: "0"}} />
  </Box>    
  </>    
                )}

              </Box>
            </Flex>
          }
          </Box>

          {/* Mobile responsive start */}
          <Box w="100%" display={{base: "block", lg: "none"}}>
          {isLoading && <Loader />}
            <MobileSecondaryNav 
              handlebackarrow={()=> navigate('/myhealth')}
              header="Upload prescription"
            />
            {showpreview &&
            <Box w="100%" p="32px" bg="#E6E7E9" overflow="hidden auto" height="calc(100vh - 140px)">
              <Box display="flex" pb="25px" justifyContent="center" alignItems="center">
                <Image w="calc(100vw - 20%)" boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;" h="auto" src={selectedImage} />
              </Box>

              <Box w="calc(100vw - 20%)" display="flex" marginInline="auto" alignItems="center" gap="20px" pos="sticky" bottom="0">
                <input
                  type="file"
                  id="prescriptionupload"
                  style={{ display: 'none' }}
                  accept="image/jpeg,image/jpg,image/png,image/tiff"
                  onChange={uploadprescription}
                />
                <PrimaryInput
                    inputType="file"
                    uploadText="Re-Upload"
                    id="prescriptionupload"
                    onChange={uploadprescription}
                    variant="grayBtnFull"
                    buttonText="Reupload"
                    //isDisabled={false}
                    inputStyleCss={{boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;", borderRadius: "10px"}}
                  />
                <PrimaryButton
                  onClick={fetchData}
                  buttonText="Confirm"
                />
              </Box>
            </Box>
            }
            {!showpreview && 
            <Flex overflowY="auto" height="100vh" direction="row">
              <Box w="100%" h="calc(100vh - 140px)" overflow="hidden auto"  display="flex" flexDirection="column" justifyContent="space-between">
                {(showpatientdetails && data && data.patient_info &&
                <>
                <Box overflowY="auto" p="24px 32px" h="100%" >
                  <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">Please verify the details below</Box>
                  <PrimaryInput inputPlace="Name" onChange={handlenamechange} inputValue={name}  defaultValue={name && name ? name :""} variant="defaultSide" />
                  <DropdownInput variant="bothSide" onClick={handledobChange} defaultOption={age} dropDownPlace="Age" options={ageinyears}/>
                  <PrimaryInput inputPlace="Doctor name" onChange={handledoctornamechange} inputValue={doctorname} defaultValue={doctorname} variant="bothSide" />
                  <DatePicker 
                  disableFutureDates={true} 
                  defaultDate={reportdate} 
                  placeholderProp="Date of document" 
                  leftPos={{left: "3px"}} 
                  inputStyle={{borderRadius: "0"}} 
                  isEdit={true}
                  onDateChange={(selectedDateValue) => handleDateChange( selectedDateValue)} />                
                  <PrimaryInput inputPlace="Hospital Name" onChange={handlehospitalnamechange}  variant="bothSide" />
                  <PrimaryInput inputPlace="Document name" onChange={handlereportnamechange} defaultValue={reportname}  variant="downSide" />
                </Box>

                <Box w="100%" display="flex" gap="20px" alignItems="center" p="24px 32px">
                {/* <input
                  type="file"
                  id="prescriptionupload"
                  style={{ display: 'none' }}
                  onChange={uploadprescription}
                />
                <PrimaryInput
                    inputType="file"
                    uploadText="Re-Upload"
                    id="prescriptionupload"
                    onChange={uploadprescription}
                    variant="grayBtnFull"
                    buttonText="Reupload"
                    //isDisabled={false}
                  /> */}
                  <PrimaryButton onClick={handlePatientDetailsConfirm} buttonText="Confirm" btnStyle={{my: "0"}}  />
                </Box>
              </>
              )}
              {(!showpatientdetails &&
              <>
              {data && data.medicine_list && data.medicine_list.map((step, index) => (
                index === activeTestIndex && (

                <Box overflowY="auto" h="100%" key={index}>
                    <Box bg="#061027" minH="115px" p="18px 28px" display="flex" flexDir="column" gap="8px">
                    <Box display="flex" gap="8px">
                    {data.medicine_list.map((_, i) => (
                      <Box
                        as="button"
                        key={i}
                        p="0"
                        borderTop={`3px solid ${i === activeTestIndex ? '#14C585' : '#212b40'}`}
                        w="16.666%"
                        h="20px"
                      ></Box>
                    ))}
                  </Box>
                      <Box>
                        <Box display="flex" flexDir="row" justifyContent="space-between" gap="10px" alignItems="center">
                          <Box fontSize="20px" fontWeight="600" color="#E6E7E9">
                          {(!showedittestname &&
                          <>
                            {step.medicine_name ? step.medicine_name :"-"} 
                            <Image w="13px" ml="10px" onClick={handleedittestname} h="13px" display="inline-block" src="/assets/imgs/edit-white.png"/>
                            </>
                            )}
                            {(showedittestname &&
                            <>
                            <Box>
                            <Box display="flex" flexDir="row" justifyContent="space-between" gap="5px" alignItems="center">
                              <InputGroup>
                                <Input type="text" defaultValue={step.medicine_name} color="white" fontSize="16px" fontWeight="600" border="2px solid white" borderRadius="10px" height="48px" minW="50px" onChange={handletestnamechange}/>
                                  <InputRightElement top="4px">
                                    <Box as="button" bg="white" display="flex" justifyContent="center" alignItems="center" height="48px" minW="50px" borderRadius="0px 10px 10px 0" onClick={() => handleTestNameUpdate(index)}><Image src="/assets/imgs/check-black.png" /></Box>
                                  </InputRightElement>
                              </InputGroup>
                            </Box>                      
                          </Box>
                          </>
                            )}
                          </Box>
                          <Box as="button" border="1px solid #0B1F4F" color="white" fontSize="12px" fontWeight="600" borderRadius="full" p="5px 10px" onClick={()=>openLearnMoreModal(step)}>
                            Learn more
                          </Box>
                        </Box>                      
                      </Box>
                    </Box>
                    <Box overflowY="auto" p="24px 32px" h="100%">
                    <RadioGroup
                      //defaultValue="Tablet"
                      onChange={(event) => handleRadioChange(event, index)}
                    >
                      <Stack direction="row" gap="20px">
                        <Radio value="Tablet">Tablet</Radio>
                        <Radio value="Syrup">Syrup</Radio>
                      </Stack>
                    </RadioGroup>
                    <Divider />
                    <Box>
                      {/* <MiniDropdownInput
                        variant="bothside"
                        options={[
                          { label: "mg", value: "151" },
                          { label: "ml", value: "152" },
                        ]}
                        placeholderProp="Dosage"
                        onSelect={handleChangeDosageUnit}
                        defaultValue={step.medicine_quantity ? step.medicine_quantity : ""}
                        handleInputChange={(event) =>
                          handleDosageChange(event, index)
                        }
                      /> */}
                          {medicinetype == "Tablet" &&
                      <MiniDropdownInput
                      variant="bothside"
                      options={ [
                        { label: "mg", value: "151" },
                        { label: "ml", value: "152" },
                        ] }
                        placeholderProp="Dosage"
                        onSelect={handleChangeDosageUnit}
                        defaultValue={step.medicine_quantity ? step.medicine_quantity : ""}
                        handleInputChange={(event) =>
                          handleDosageChange(event, index)
                        }
                    />
                      }
                        {medicinetype == "Syrup" &&
                      <MiniDropdownInput
                      variant="bothside"
                      options={ [
                        { label: "ml", value: "152" },
                          { label: "mg", value: "151" },
                        ] }
                        placeholderProp="Dosage"
                        onSelect={handleChangeDosageUnit}
                        defaultValue={step.medicine_quantity ? step.medicine_quantity : ""}
                        handleInputChange={(event) =>
                          handleDosageChange(event, index)
                        }
                    />
                      }
                      <Box display="flex" flexDir="row">
                        {/* <PrimaryInput
                          variant="bothSide"
                          id="vitaldate"
                          inputType="date"
                          inputPlace="Start Date"
                          inputStyleCss={{ w: "50%" }}
                          defaultValue={fromdate}
                          onChange={(event) => handleFromDateChange(event, index)}
                        />
                        <PrimaryInput
                          variant="bothSide"
                          id="vitaldate"
                          inputType="date"
                          inputPlace="End Date"
                          inputStyleCss={{ w: "50%" }}
                          defaultValue={todate}
                          onChange={(event) => handleToDateChange(event, index)}
                        /> */}
                        <DatePicker
                        disableFutureDates={true}
                          maxDate={maxDateState}
                          defaultDate={fromdate} 
                          onDateChange={(selectedDateValue) =>
                            handleFromDateChange(selectedDateValue, index)
                          }
                          placeholderProp={"Start Date"} 
                          calendarStlye={{left: "-18px",  right: "unset"}}
                        />
                        <DatePicker 
                        disablePastDates={true}
                        minDate={minDateState}
                          defaultDate={todate} 
                          onDateChange={(selectedDateValue) =>
                            handleToDateChange(selectedDateValue, index)
                          }
                          placeholderProp={"End Date"} 
                        />
                      </Box>
                      <DropdownInput
                        variant="downSide"
                        dropDownPlace="Consumption frequency"
                        options={["1x per day", "2x per day", "3x per day", "4x per day"]}
                        onClick={(selectedOption) => handleConsumptionFrequencyChange(selectedOption, index)}
                      />
                      <CheckboxGroup
                        colorScheme="green"
                        onChange={(selectedTimes) => handleMedicineTimeChange(selectedTimes, index)}
                      >
                        <Stack spacing={2} pt="20px" direction="row">
                          <Checkbox value="morning">Morning</Checkbox>
                          <Checkbox value="evening">Evening</Checkbox>
                          <Checkbox value="night">Night</Checkbox>
                        </Stack>
                      </CheckboxGroup>
                    </Box>
                    <Divider />
                    </Box>
                </Box>
                  )
                    ))}
                    <Box w="100%" p="18px 28px">
                    <PrimaryButton  buttonText={activeTestIndex === data.medicine_list.length - 1 ? "Submit" : "Confirm"}
                                //isDisabled={isSubmitting}
                                onClick={handleConfirm} btnStyle={{my: "0"}} />
                  </Box>    
                  </>    
                )}

              </Box>
            </Flex>
          }
          </Box>
        </>
         ) : (
        <SuccessUploadIndex/>
      )}
       <DynamicModal
          ref={learnMoreModal}
          modalHeader={medcineName}

          modalStyle={{maxWidth: "calc(100vw - 45%)",maxHeight: "calc(100vh - 30%)", overflow:"hidden auto", w: "100%"}}
          modalBody={
            <>
            
            <Box mb="10px" >
              <Text fontSize="14px" fontWeight="600">
                Benifit  
              </Text>
              <Box as="span" >{benifit ? benifit : ''}</Box>
              </Box>
              
              <Box mb="10px" >
              <Text fontSize="14px" fontWeight="600">
                Side Effect 
              </Text>
              <Box as="span"  > {sideEffects ? sideEffects : ''}</Box>
              </Box>
              
            <Box mb="10px" >
              <Text fontSize="14px" fontWeight="600">
                  {composition == "Not Available" ? "" : "Composition" }
              </Text>
              <Box as="span" mb="10px">{composition == "Not Available" ? "" : composition }</Box>
              </Box>
            </>
          }
         
        />
         <DynamicModal
        ref={consentForm}
        modalAttributes={{closeOnOverlayClick: false}}
        hasCloseBtn={false}
        customClose={true}
        customCloseClick={closeconsentform}
        modalStyle={{minW:{base: "100%", lg: "600px"}, maxW:{base: "100%", lg: "600px"}, maxH:"100vh"}}
        modalHeader="Consent Form"
        modalBody={<>
          <Box style={{ height: '300px', overflowY: 'scroll' }} h="calc(80vh - 100px)" overflow="hidden auto" lineHeight="1.6" ref={modalBodyRef}>
            <Heading as="h2" fontSize="1.5rem" mb={4}>
            Our Commitment to Privacy
            </Heading>

            <Text mb={4}>
            At Midoc, your privacy is paramount. We are committed to protecting the privacy of your personal information while providing you with the highest level of service. This Privacy Policy explains how Midoc, LLC ("Midoc," "we," or "us") may collect, use, and share your personal information when you access our services through the Midoc website or our mobile application (collectively, the "Services").            </Text>

            <Heading as="h3" size="md" mb={2}>
            Consent
            </Heading>

            <Text mb={4}>
            By using our Services, you consent to the terms outlined in this Privacy Policy. If you do not agree with any part of this policy, please do not use our Services. Continued use of the Services following any changes to this policy will signify your acceptance of those changes.            </Text>



            <Text mb={2}> Personal Information You Provide:</Text>
            <UnorderedList mb={4}>
              <ListItem><strong>Registration and Account Setup:</strong> We collect personal details when you register or set up an account, which may include your name, email address, phone number, and address.</ListItem>
              <ListItem><strong>Health Records:</strong> Through our mobile application, you may voluntarily provide medical and health-related information to enhance service customization.</ListItem>
              <ListItem><strong>Interactions:</strong> We gather information when you interact with our Services, such as customer service inquiries, responses to surveys, and participation in promotions.</ListItem>
              
            </UnorderedList>

            <Text mb={2}> Automated Information Collection:</Text>
            <UnorderedList mb={4}>
              <ListItem><strong>Cookies and Tracking Technologies:</strong> We use cookies to enhance your experience, personalize content and ads, and analyze our site traffic. We also employ web beacons to collect aggregate statistics about website usage.</ListItem>
              <ListItem><strong>Geo-location Data:</strong> With your permission, we may collect precise location data to provide location-specific services.</ListItem>              
            </UnorderedList>

            <Text mb={2}> How We Use Your Information</Text>
            <UnorderedList mb={4}>
              <ListItem><strong>Service Provision:</strong> To manage your account, provide customer support, process transactions, and send important account and service information.</ListItem>
              <ListItem><strong>Improvement and Personalization: </strong> To enhance and personalize your experience, and to develop new features and services that meet your needs.</ListItem>
              <ListItem><strong>Compliance and Protection:</strong>To comply with legal requirements and protect the rights and safety of Midoc and its users.</ListItem>
              
            </UnorderedList>

            <Text mb={2}> Sharing of Information</Text>
            <UnorderedList mb={4}>
              <ListItem><strong>With Service Providers:</strong>We share information with third-party service providers to facilitate services on our behalf, such as payment processing, data analysis, email delivery, and hosting services.</ListItem>
              <ListItem><strong>Legal and Safety Reasons:</strong>We may disclose information to respond to legal processes or to protect the rights, property, or safety of Midoc, our users, or others.</ListItem>
              <ListItem><strong>Business Transfers: </strong>In the event of a merger, sale, or asset transfer, your information may be disclosed and transferred as part of the transaction.</ListItem>
              
            </UnorderedList>

            <Text mb={2}> Your Rights and Choices</Text>
            <UnorderedList mb={4}>
              <ListItem><strong>Account Information: </strong>You can review, update, or delete your account information at any time.</ListItem>
              <ListItem><strong>Cookies: </strong> You can configure your browser to reject cookies or to alert you when cookies are being sent.</ListItem>
              <ListItem><strong>Opt-Out: </strong>You may opt out of receiving promotional communications from us by following the instructions in the communication.</ListItem>
              
            </UnorderedList>

            <Heading as="h3" size="md" mb={2}>
            Children’s Privacy
            </Heading>

            <Text mb={4}>
            We do not knowingly collect or solicit information from anyone under the age of 13. If you are under 13, please do not attempt to register for the Services or send any personal information about yourself to us.
          </Text>

          <Heading as="h3" size="md" mb={2}>
          International Users.
            </Heading>

            <Text mb={4}>
            Our Services are hosted in the United States and are intended for users located within the United States. If you are located outside of the United States, please be aware that any information you provide to us will be transferred to and processed in the United States.
             </Text>

             <Heading as="h3" size="md" mb={2}>
             Changes to This Policy
            </Heading>

            <Text mb={4}>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We encourage you to review our Privacy Policy whenever you access the Services to stay informed about our information practices and the ways you can help protect your privacy.
              </Text>

              <Text mb={4}>
              If you have any questions about this Privacy Policy or our privacy practices, 
              please contact us at: privacy@midoc.com.
                 </Text>


           
          </Box>
        </>}
        hasFooter={true}
        modalFooterBtn={<>
          <PrimaryButton buttonText="Agree" onClick={submitconsentform}/>
        </>}
      />
      <AiModal
        ref={AIChatModel}
      modalSize="420px"
      //icon="/assets/imgs/ai-doctor.png"
      header={<>
        <Box display="flex" flexDir='column' gap='5px'>
            <Box display='flex' gap='6px'>
              <Text fontSize="1rem"
                fontWeight="700" 
                backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)" 
                color="transparent" 
                backgroundClip="text"
              >Aira - AI</Text>
              <Box display='flex' alignItems='center' gap='5px' border='1px solid #0d7e54' bg='#d5ece3'  p='3px 5px' borderRadius='full'>
                <Image src='/assets/imgs/spark.png' /> 
                <Text fontSize='10px' fontWeight='500' color='#000'>{remainingTokens && remainingTokens.remainingtokens ? displayMillion_ThoudandValues(remainingTokens.remainingtokens):"0"} tokens available</Text>
              </Box>
            </Box>
            <Text fontSize="0.75rem" fontWeight="400">Chat with your AI health guide</Text>
          </Box>
      </>}
      headerStyle={{ p: "24px 32px", lineHeight: "normal" }}
      closeBtnStyle={{ top: "18px" }}
      footerStyle={{ maxH: "35%", overflow: "hidden auto", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      withoutBg={true}
      backDropBlur="10px"
      body={
        <>
          <Flex flexDirection="column" height="100%">
            <Box
              p="24px 32px"
              ref={chatContainerRef}
              className="chat-container"
              style={{ overflowY: "auto", maxHeight: 'calc(100vh - 160px)' }}
            >
              {initialRender && remainingquestions.length > 0 && (
                <Box>
                  {remainingquestions.map((step, index) => (
                    <Box
                      key={index}
                      as="button"
                      w="100%"
                      p="16px"
                      mb="16px"
                      fontSize="14px"
                      fontWeight="600"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius="10px"
                      border="1px solid #7795DC"
                      bg="#EAEDF5"
                      color="#163E9E"
                                              gap='1rem'
                        textAlign='left'
                      onClick={() => GetAIAnswer(step.value)}
                      className={`question-box ${animate ? 'slide-in' : ''}`}
                    >
                      {step.value ? step.value : "-"}
                      <Image src="/assets/imgs/right-icon.png" />
                    </Box>
                  ))}
                </Box>
              )}
              {aianswers.map((step, index) => (
                <React.Fragment key={index}>
                  <Box
                    as="div"
                    bg="#061027"
                    p="12px 16px"
                    w="fit-content"
                    float="right"
                    borderRadius="10px 10px 1px 10px"
                    className={`answer-box ${animate ? 'slide-in' : ''}`}
                  >
                    <Text fontSize="14px" fontWeight="500" color="white">
                      {step.question ? step.question : "-"}
                    </Text>
                  </Box>

                  <Box
                    as="div"
                    my="20px"
                    w="fit-content"
                    display="flex"
                    alignItems="end"
                    float="left"
                    className={`answer-box ${animate ? 'slide-in' : ''}`}
                  >
                    <Image
                      bg="#061027"
                      p="4px"
                      mr="8px"
                      borderRadius="6px"
                      src="/assets/imgs/ai-chat-logo.png"
                    />
                    <Text
                      bg="#E6E7E980"
                      p="12px 16px"
                      fontSize="14px"
                      fontWeight="500"
                      color="#061027"
                      borderRadius="10px 10px 10px 1px"
                    >
                      {step.answer ? step.answer : <TypingLoader />}
                    </Text>
                  </Box>
                </React.Fragment>
              ))}
            </Box>
          </Flex>
        </>
      }
      footerActions={
        !initialRender && (
          <Box
            p="24px 32px"
            display="flex"
            w="100%"
            h="100%"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box w="100%">
              {remainingquestions.map((step, index) => (
                <Box
                  key={index}
                  as="button"
                  w="100%"
                  p="16px"
                  mb="16px"
                  fontSize="14px"
                  fontWeight="600"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius="10px"
                  border="1px solid #7795DC"
                  bg="#EAEDF5"
                  color="#163E9E"
                  onClick={() => GetAIAnswer(step.value)}
                  className={`question-box ${animate ? 'slide-in' : ''}`}
                >
                  {step.value ? step.value : "-"}
                  <Image src="/assets/imgs/right-icon.png" />
                </Box>
              ))}
            </Box>
          </Box>
        )
      }
      />
        </>
        
    );
    
}

export default PrescriptionUpload;
