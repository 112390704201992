import moment from "moment";

export default function formapimodalaccount({
  age,
  bloodtype_cd,
  countrycode,
  countryid,
  dob,
  email,
  fcmtoken,
  firstname,
  fullname,
  gender,
  height,
  heightunit_cd,
  imagepath,
  lastname,
  logouttime,
  mobileno,
  patientid,
  patientimage,
  preferredlang,
  socialid,
  socialtype,
  vaccinated,
  weight,
  weightunit_cd,
  patientaddress,
  uniqueid
}) {
  return Object.freeze({
    age: age ? age : "-",
    bloodtype_cd: bloodtype_cd ? bloodtype_cd : "-",
    countrycode: countrycode ? countrycode : "-",
    countryid: countryid ? countryid : "-",
    dob: dob ? moment(dob).format("MMMM DD, YYYY") : "-",
    email: email ? email : "-",
    fcmtoken: fcmtoken ? fcmtoken : "-",
    firstname: firstname,
    fullname: fullname ? fullname : "-",
    // gender: gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "others" : "",
    gender : gender , 
    height: height ? height : null,
    heightunit_cd: heightunit_cd == 151 ? "cms" : heightunit_cd == 152 ? "feet" : "-",
    imagepath: imagepath ? imagepath : "-",
    lastname: lastname,
    logouttime: logouttime ? logouttime : "-",
    mobileno: mobileno ? mobileno : "-",
    patientid: patientid ? patientid : "-",
    patientimage: patientimage ? patientimage : "-",
    preferredlang: preferredlang ? preferredlang : "-",
    socialid: socialid ? socialid : "-",
    socialtype: socialtype ? socialtype : "-",
    vaccinated: vaccinated ? vaccinated : "-",
    weight: weight ? weight : null,
    weightunit_cd: weightunit_cd == "154" ? "kg" : weightunit_cd == "155" ? "lbs" : "-",
    patientaddress,
    uniqueid
   });
}
