
import formAPIProfileAITokens from "./aitoken";


var formattedOutput = null;

const formAPIModelProfileAITokens = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data?.map(item => formAPIProfileAITokens(item));


  return formattedOutput
};

export default formAPIModelProfileAITokens