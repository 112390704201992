import moment from "moment";
export default function formAPILab({
  address,
  city,
  country,
  doctorid,
  doctorname,
  entityid,
  entitytype,
  hospital,
  imagepath,
  orderdate,
  orderid,
  ordername,
  state,


}) {
  return Object.freeze({
    address,
    city,
    country,
    doctorid,
    doctorname,
    entityid,
    entitytype,
    hospital,
    imagepath,
    orderdate,
    orderid,
    ordername,
    state,


  });
}