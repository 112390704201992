import formAPIModelDoctorAddresses from "./doctoraddresses/addresses";
import formAPIModelDoctorServices from "./doctorservices/services";
export default function formapimodaldoctordetails({
    doctorid,
    firstname,
    lastname,
    fullname,
    email,
    mobileno,
    specality,
    ratings,
    ratedby,
    ondemandavailability,
    doctorimage,
    imagepath,
    appointmenttype,
    fee,
    qualification,
    yearsofexperience,
    bookinupto,
    bookinuptovalue,
    services,
    addresses,
    websiteurl,
    longitude,
    latitude,
    islimitfuturebooking
})
{
  return Object.freeze({
    doctorid,
    firstname,
    lastname,
    fullname,
    email,
    mobileno,
    specality,
    ratings,
    ratedby,
    ondemandavailability,
    doctorimage,
    imagepath,
    appointmenttype,
    fee:`${fee}.00`,
    qualification,
    bookinupto,
    bookinuptovalue,
    yearsofexperience,
    services:services !== null ? formAPIModelDoctorServices(services):[],
    addresses:addresses !== null ? formAPIModelDoctorAddresses(addresses):[],
    websiteurl,
    longitude,
    latitude,
    islimitfuturebooking
  });
}