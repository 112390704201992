import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';

const MotionBox = motion(Box);

const TypingLoader = () => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start(i => ({
      y: [-5, 5],
      transition: {
        delay: i * 0.1,
        repeat: Infinity,
        repeatType: "reverse",
        duration: 0.4,
      },
    }));
  }, [controls]);

  return (
    <>
      <Flex justify="center" align="center">
        {[...Array(3)].map((_, i) => {
          const bgColor = i === 1 ? '#898D97' : '#CDCFD4';

          return (
            <MotionBox
              key={`circle-${i}`}
              w="10px"
              h="10px"
              bg={bgColor}
              borderRadius="full"
              m="1"
              custom={i}
              animate={controls}
            />
          );
        })}
      </Flex>
    </>
  );
};

export default TypingLoader;
