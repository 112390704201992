import React, { useEffect, useRef, useState } from 'react';
import { Column } from '@antv/g2plot';

const GroupedColumnRangePlot = ({ data, height }) => {
  const chartRef = useRef(null);
  const [systolic, setSystolic] = useState(0);
  const [diastolic, setDiastolic] = useState(0);

  useEffect(() => {
    if (data && data.length > 0) {
      const latestData = data[data.length - 1];
      setSystolic(latestData.value[latestData.value.length - 2]);
      setDiastolic(latestData.value[latestData.value.length - 1]);
    }

    const reversedData = data.map(item => ({
      ...item,
      value: item.value.slice().reverse(),
    }));
    const chart = new Column(chartRef.current, {
      data: reversedData,
      xField: 'x',
      yField: 'value',
      seriesField: 'color', // This will group the columns by color
      colorField: 'color', // This will assign the colors from the data
      color: ['#1C4EC5', '#F04438'], // Explicitly set the colors to ensure they match your data
      columnWidthRatio: 0.06,
      marginRatio: 0.5,
      legend: false,
      yAxis: {
        min: 0,
        max: 300,
        tickInterval: 30,
      },
      tooltip: {
        shared: true,
        customContent: (title, items) => {
          let content = `<div class="g2-tooltip-title">${items[0] && items[0].data.tooltipLabel}</div><ul class="g2-tooltip-list">`;
    
          items.forEach((item) => {
            const values = item.data.value;

            for (let i = 0; i < values.length; i += 2) {
              const systolic = values[i];
              const diastolic = values[i + 1] || '';
              content += `<li class="g2-tooltip-list-item"><span class="g2-tooltip-marker" style="background-color:${item.color};"></span>${diastolic}/${systolic}</li>`;
            }
          });

          content += `</ul>`;
          return content;
        }
      },
    });
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data, height]);

  return (
    <div>
      <div className="legend" style={{ display: "flex", width: "100%", justifyContent: "flex-start" }}>
        <div style={{ display: "flex", flexDirection: "column", width: "50%", paddingBottom: "15px" }}>
          <span className="legend-color" style={{ fontSize: "24px", fontWeight: "700" }}>{systolic}</span>
          <span className="legend-value" style={{ fontSize: "12px", fontWeight: "600", color: "#6A707D" }}>Systolic</span>
        </div>

        <div style={{ display: "flex", flexDirection: "column", width: "50%", paddingBottom: "15px" }}>
          <span className="legend-color" style={{ fontSize: "24px", fontWeight: "700" }}>{diastolic}</span>
          <span className="legend-value" style={{ fontSize: "12px", fontWeight: "600", color: "#6A707D" }}>Diastolic</span>
        </div>
      </div>
      <div ref={chartRef} style={{ height }} />
    </div>
  );
};

export default GroupedColumnRangePlot;
