import { Flex, VStack, Box, Image, Text, Spacer, Center } from "@chakra-ui/react";
import { PrimaryButton, PrimaryButtonIcon, PrimaryInput, SearchBar, TagsInput } from "../../components";
import DropdownInput from "../../components/dropdown-input";
import PersonalDetails from "./personal-detail";
import MedicalHistory from "./medical-history";
import Pharmacy from "./pharmacy";
import OtpIndex from "./otp";
import PasswordIndex from "./password"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function SignUpIndex() {
  const [activeComponent, setActiveComponent] = useState('personal'); 
  const navigate = useNavigate();

  const [textColor, setTextColor] = useState({
    personal: 'white',
    medical: '#384052',
    pharmacy: "#384052",
    passwordIndex:"#384052",
  });
  const [borderColor, setBorderColor] = useState({
    personal: '#14C585',
    medical: '#eaedf545',
    pharmacy: "#384052",
    passwordIndex: "#384052",
  });

  useEffect(() => {
    const storedActiveComponent = localStorage.getItem('activeComponent');
    
    if(storedActiveComponent){
      setActiveComponent(storedActiveComponent)
    
    setTextColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#384052';
      });
      updatedColors[storedActiveComponent] = 'white';
    
      return updatedColors;
    });
    setBorderColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#384052'; // Set default border color
      });
      updatedColors[storedActiveComponent] = '#14C585';
      return updatedColors;
    });
    setTimeout(() => {
      
    localStorage.removeItem('activeComponent');
    }, 2000);
  }else{
    
    setTextColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#384052';
      });
      updatedColors['personal'] = 'white';
    
      return updatedColors;
    });
  }
  }, []);

  const handleBoxClick = (component) => {
    localStorage.removeItem('activeComponent');
    // alert(activeComponent  + " current active component")
    // alert(component  + " clicked component")
    if(activeComponent == "personal" && (component == "medical" || component == "passwordIndex")){
      return false;
    }
    if(activeComponent == "medical" && component ==  "passwordIndex"){
      return false;
    }
    setActiveComponent(component);
    setTextColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#384052';
      });
      updatedColors[component] = 'white';
    
      return updatedColors;
    });
    setBorderColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#384052'; // Set default border color
      });
      updatedColors[component] = '#14C585';
      return updatedColors;
    });
  };
  const handleActiveComponentChange = (newActiveComponent) => {
    
    setTimeout(() => {
      
    setActiveComponent(newActiveComponent);
    setTextColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#384052';
      });
      updatedColors[newActiveComponent] = 'white';
    
      return updatedColors;
    });
    setBorderColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#384052'; // Set default border color
      });
      updatedColors[newActiveComponent] = '#14C585';
      return updatedColors;
    });
    }, 1000);
  };

  const backBtnMobile = (newActiveComponent)=>{
   
    let navigatBackTo = "personal";
    if(newActiveComponent == "medical"){
      navigatBackTo = "personal"
    }else if (newActiveComponent == "passwordIndex"){
      navigatBackTo = "medical"
    }else if (newActiveComponent == "personal"){
      navigate("/")
    }
    setTimeout(() => {
      
      setActiveComponent(navigatBackTo);
      setTextColor((prevColors) => {
        const updatedColors = {};
        Object.keys(prevColors).forEach((key) => {
          updatedColors[key] = '#384052';
        });
        updatedColors[navigatBackTo] = 'white';
      
        return updatedColors;
      });
      setBorderColor((prevColors) => {
        const updatedColors = {};
        Object.keys(prevColors).forEach((key) => {
          updatedColors[key] = '#384052'; // Set default border color
        });
        updatedColors[navigatBackTo] = '#14C585';
        return updatedColors;
      });
      }, 1000);
  }

  return (
    <>
    <Flex h="100vh" display={{base: "none", lg: "flex"}}>

      <VStack w="30%" bg="#061027" p={8} h="100vh" align="stretch">
        <Box>
          <Image w="120px" src="/assets/imgs/midoc-logo.png" />
        </Box>
        <Spacer />
        <VStack spacing={4} align="start">
          <Text color="white" fontSize="20px" fontWeight="600">
            Signup with us in <br /> four simple steps
          </Text>

          <Box as="button" borderLeft={`5px solid ${borderColor.personal}`} onClick={() => handleBoxClick('personal')}>
            <Text textAlign="left" color={textColor.personal} fontSize="14px" fontWeight="600" pl="15px">Personal details</Text>
            <Text textAlign="left" color={textColor.personal} fontSize="12px" fontWeight="400" pl="15px">
              Enter your basic information to personalize your healthcare experience.
            </Text>
          </Box>

          <Box as="button" borderLeft={`5px solid ${borderColor.medical}`}    onClick={() => handleBoxClick('medical')}>
            <Text textAlign="left"  color={textColor.medical}  fontSize="14px" fontWeight="600" pl="15px">Medical history</Text>
            <Text textAlign="left"  color={textColor.medical}  fontSize="12px" fontWeight="400" pl="15px">
            Provide details about your medical conditions for tailored recommendations.
            </Text>
          </Box>

          {/* <Box as="button"  borderLeft={`5px solid ${borderColor.pharmacy}`} onClick={() => handleBoxClick('pharmacy')}>
            <Text textAlign="left" color={textColor.pharmacy} fontSize="14px" fontWeight="600" pl="15px">Pharmacy</Text>
            <Text textAlign="left" color={textColor.pharmacy} fontSize="12px" fontWeight="400" pl="15px">
            Specify your preferred pharmacy to streamline prescription management and refills.
            </Text>
          </Box> */}

          <Box as="button" borderLeft={`5px solid ${borderColor.passwordIndex}`} onClick={() => handleBoxClick('passwordIndex')}>
            <Text textAlign="left"  color={textColor.passwordIndex}  fontSize="14px" fontWeight="600" pl="15px">Password</Text>
            <Text textAlign="left"  color={textColor.passwordIndex}  fontSize="12px" fontWeight="400" pl="15px">
            Secure your account with a password to protect your health information.
            </Text>
          </Box>
        </VStack>
        <Spacer />
        <Box as="p" fontSize="12px" color="#898D97">© 2024 midoc, Inc.</Box>
      </VStack>


      <Box w="70%" h="100%" pt={{base: "6rem", lg: "6rem", xl: "5rem"}} overflow={{base: "hidden auto", lg: "hidden auto", xl: "hidden auto"}} bg="#FCFCFD" display="flex" justifyContent="center" alignItems="center">
       <Center h="100vh">
        {activeComponent === 'personal' && <PersonalDetails />}
        {activeComponent === 'medical' && <MedicalHistory  onSubmit={handleActiveComponentChange}  />}
        {/* {activeComponent === 'pharmacy' && <Pharmacy onSubmit={handleActiveComponentChange} />} */}
        {activeComponent === 'passwordIndex' && <PasswordIndex   />}
        {/* <PasswordIndex /> */}
         
       
       </Center>
      </Box>
    </Flex>

    <Flex display={{base: "flex", lg: "none"}} flexDir="column" w="100%" h="100vh" overflow="hidden auto">
        <Box w="100%" bg="#061027" p={6} h="270px">
            <Box w="100%" as="button" mb="1rem">
              <Image w="1rem" h="auto" transform="rotate(270deg)" src="/assets/imgs/arrow-up.png" onClick={()=>backBtnMobile(activeComponent)} />
            </Box>

            <Box display="flex" flexDir="column" gap="0.75rem">
              <Text fontSize="0.875rem" color="white">SIGN UP</Text>
            </Box>
        </Box>

        <Box w="100%" p="0 1.5rem" mt="-150px" h="calc(100vh - 200px)" overflow='hidden auto'>
          {activeComponent === 'personal' && <PersonalDetails />}
          {activeComponent === 'medical' && <MedicalHistory  onSubmit={handleActiveComponentChange}  />}
          {/* {activeComponent === 'pharmacy' && <Pharmacy onSubmit={handleActiveComponentChange} />} */}
          {activeComponent === 'passwordIndex' && <PasswordIndex   />}
        </Box>
    </Flex>
    </>
  );
}

export default SignUpIndex;