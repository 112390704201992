import  FetchData, { } from '../../client';
import {getLoginInfo, getUserID}  from '../../../auth';
import formapimodalSpecialities from '../../domains/doctorspecialities/specialities';
import formapimodaldoctors from '../../domains/doctors/doctors';
import formAPIModelappointment from '../../domains/appointment/apointments';
import formapimodaldoctordetails from '../../domains/doctordetails';
import formapimodaldoctorslots from '../../domains/doctorslots/slots';
import formapimodalAppointmentdetails from '../../domains/appointmentdetails';
import { formatHPIMasterData } from '../../domains/appointmentdetails/hpimasterdata';



const FetchAppointments = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    //console.log(inputData);
    const result = await FetchData("midoc_getpatientappointmentlist", inputData);
    //console.log(result,"Home db response")
    if (result.output.data) {
      //console.log(result.output.data)
    var finalOutput = formAPIModelappointment(result.output.data)
    }
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchDoctors = async (inputData) => {
    try {
      inputData.patientid = getLoginInfo();
      inputData.activeuserid = getUserID();
      //console.log(inputData);
      const result = await FetchData("midoc_getdoctorlist", inputData);
      //console.log(result,"Home db response")
      if (result.output.data) {
      var finalOutput = formapimodaldoctors(result.output.data)
      return finalOutput;
      }
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const FetchSpecialities = async (inputData) => {
    try {
      inputData.patientid = getLoginInfo();
      inputData.activeuserid = getUserID();
      //console.log(inputData);
      const result = await FetchData("midoc_getdefaultdata", inputData);
      //console.log(result,"Home db response")
      if (result.output.data) {
        //console.log(result.output.data)
      var finalOutput = formapimodalSpecialities(result.output.data)
      }
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const FetchDoctorDetails = async (inputData) => {
    try {
      inputData.patientid = getLoginInfo();
      inputData.activeuserid = getUserID();
      //console.log(inputData);
      const result = await FetchData("midoc_getdoctordetailforpatient", inputData);
      //console.log(result,"Home db response")
      if (result.output.data) {
      var finalOutput = formapimodaldoctordetails(result.output.data)
      }
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const FetchDoctorDetailsGuest = async (inputData) => {
    try {
      // inputData.patientid = getLoginInfo();
      // inputData.activeuserid = getUserID();
      //console.log(inputData);
      const result = await FetchData("midoc_guestgetdoctordetailforpatient", inputData);
      //console.log(result,"Home db response")
      if (result.output.data) {
      var finalOutput = formapimodaldoctordetails(result.output.data)
      }
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const FetchDoctorSlots = async (inputData) => {
    try {
      inputData.patientid = getLoginInfo();
      inputData.activeuserid = getUserID();
      //console.log(inputData);
      const result = await FetchData("midoc_getdoctorslot", inputData);
      //console.log(result,"Home db response")
      if (result.output.data) {
      var finalOutput = formapimodaldoctorslots(result.output.data)
      }
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const BookAppointment = async (inputData) => {
    try {
      inputData.patientid = getLoginInfo();
      inputData.activeuserid = getUserID();
      //console.log(inputData);
      const result = await FetchData("midoc_bookappointment", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const BookAppointmentGuest = async (inputData) => {
    try {
      //console.log(inputData);
      const result = await FetchData("midoc_guestbookappointment", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const UpdateAppointment = async (inputData) => {
    try {
      inputData.patientid = getLoginInfo();
      inputData.activeuserid = getUserID();
      //console.log(inputData);
      const result = await FetchData("midoc_patientupdateappointment", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const FetchAppointmentDetails = async (inputData) => {
    try {
      inputData.patientid = getLoginInfo();
      inputData.activeuserid = getUserID();
      //console.log(inputData);
      const result = await FetchData("midoc_getpatientappointmentdetail", inputData);
      //console.log(result,"Home db response")
      if (result.output.data) {
      var finalOutput = formapimodalAppointmentdetails(result.output.data)
      }
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const CancelAppointment = async (inputData) => {
    try {
      inputData.patientid = getLoginInfo();
      inputData.activeuserid = getUserID();
      const result = await FetchData("midoc_patientupdateappointment", inputData);
     
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const FetchHPIMasterData = async (inputData) => {
    try {
      inputData.patientid = getLoginInfo();
      inputData.activeuserid = getUserID();
      const result = await FetchData("midoc_getdefaultdata", inputData);
      if (result.output.data) {
        var finalOutput = formatHPIMasterData(result.output.data)
        }
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const updateHPI = async (inputData) => {
    try {
      inputData.patientid = getLoginInfo();
      inputData.activeuserid = getUserID();
      const result = await FetchData("midoc_patientupdateappointment", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const DeleteHPI = async (inputData) => {
    try {
      const result = await FetchData("midoc_deleteorderofpatient", inputData);
      return result;
    } catch (error) {
      console.error("Error fetching data in forms.js:", error);
      throw error;
    }
  };
export {FetchAppointments,FetchDoctors,FetchSpecialities,FetchDoctorDetails,FetchDoctorSlots, BookAppointment, BookAppointmentGuest,FetchAppointmentDetails, CancelAppointment,FetchHPIMasterData, UpdateAppointment, updateHPI ,DeleteHPI,FetchDoctorDetailsGuest };
