import formAPIDoctorAddresses from "./address";


var formattedOutput = null;

const formAPIModelDoctorAddresses = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIDoctorAddresses(item));


  return formattedOutput
};

export default formAPIModelDoctorAddresses