import moment from "moment";
export default function formapichat({
    chattitle,
    chatdescription,
    chat,
    createddate,
    ispatient,
    starttime,
    endtime,
    chattype,
    documentname,
    documentsize,
    imagepath,
    
  }) {
    //  const testDate = "2022-12-06T10:25:57.293";
    const formattedDate = moment.utc(createddate); // Parse input as UTC
    formattedDate.local(); // Convert parsed UTC time to local time
    const formattedTime = moment(formattedDate).format('hh:mm A');  
    const formattedDateTime = moment(formattedDate).format('Do MMM, YYYY');  
    return Object.freeze({
        chattitle,
        chatdescription,
        chat,
        formattedtime: formattedTime,
        ispatient,
        starttime,
        endtime,
        chattype,
        documentname,
        documentsize,
        formattedDateTime,
        imagepath,
        createddate
    });
}
// export function formapichatheader({
//   patientname,
//   appointmentid,
//   appointmentdate,
  
  
// }) {
//   //  const testDate = "2022-12-06T10:25:57.293";
//   const formattedDate = moment.utc(appointmentdate); // Parse input as UTC
//   formattedDate.local(); // Convert parsed UTC time to local time 
//   return Object.freeze({
//     patientname,
//     appointmentid,
//     appointmentdate:formattedDate,
//   });
// }

  