import  FetchData, { } from '../../client';
import formapimodalhome from '../../domains/home/home';
import {getLoginInfo, getUserID}  from '../../../auth';

//reac the file

const FetchMedialRecords = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    console.log(inputData);
    const result = await FetchData("midoc_getrequestdocument", inputData);
    console.log(result,"records")
    // if (result.output.data) {
    //   //console.log(result.output.data)
    // var finalOutput = formapimodalmedicalrecords(result.output.data)
    // }
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const RequestMedicalRecords = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    console.log(inputData);
    const result = await FetchData("midoc_addrequestdocument", inputData);
    //console.log(result,"medical record db")
    // if (result.output.data) {
    //   //console.log(result.output.data)
    // var finalOutput = formapimodalmedicalrecords(result.output.data)
    // }
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const RevokeURL = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_updateexpiryofrequest", inputData);
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
export {FetchMedialRecords, RequestMedicalRecords, RevokeURL};
