import { COLORS } from "../styles/colors";

export const styles = {
    borderRadius: "10px 10px 0 0",
    background: COLORS.PRIMARY_WHITE,
    boxShadow: "none",
    padding: "12px 10px 0 10px",
    // marginBottom: "-2px",
    height: "48px",
    width: "100%",
    _disabled: {
      bg: COLORS.BTN_DISABLE,
      color: COLORS.INPUT_FOCUS_DARK,
      cursor: "not-allowed",
    },
    _hover: {
      borderRadius: "10px",
      border: `2px solid`,
      borderColor: COLORS.INPUT_FOCUS_DARK,
      // zIndex: "22",
      outline: "none",
      bg: "white"
    },
    _focus: {
      borderRadius: "10px",
      borderColor: COLORS.INPUT_FOCUS_DARK,
      // zIndex: "22",
      bg: "white",
      outline: "none"
    },
    _focusVisible: {
      borderColor: "none",
      bg: "white"
    }
  };
  
  export const variantStyles = {
    defaultSide: {
      borderRadius: "10px 10px 0 0", 
    },
    rightSide: {
      borderRadius: "0 10px 0 0", 
    },
    leftSide: {
      borderRadius: "10px 0 0 0px",
    },
    leftDown: {
      borderRadius: "0 0px 0 10px", 
    },
    rightDown: {
      borderRadius: "0px 0 10px 0px",
    },
    bothSide: {
      borderRadius: "0",
    },
    downSide: {
      borderRadius: "0 0 10px 10px"
    },
    fullLeft: {
      borderRadius: "10px 0 0 10px"
    },
    fullRight: {
      borderRadius: "0 10px 10px 0"
    },
    fullSide: {
      borderRadius: "10px"
    },

  };