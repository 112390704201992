
import formAPIModelreaction from "./reaction";


var formattedOutput = null;
//var formattedArray = [];
const formAPIreaction = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIModelreaction(item));

  return formattedOutput
};

export default formAPIreaction


