import formAPIbodytempurature from "./bodytempurature";


var formattedOutput = null;

const formAPIModelbodytempurature = (data,type) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIbodytempurature(item,type));


  return formattedOutput
};

export default formAPIModelbodytempurature