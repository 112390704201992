import  FetchData, { } from '../../client';
import formapimodalhome from '../../domains/home/home';
import {getLoginInfo, getUserID}  from '../../../auth';
import formAPIModeltreatmentteam from '../../domains/treatmentteam/treatmentteams';
import formAPIModelrecentactivity from '../../domains/recentactivity/recentactivities';
import formapimodalaidotor from '../../domains/aidoctor/index'
import axios from 'axios';
import { getToken } from '../../../auth';
import { CONFIG } from '../../../appconfig/appconfig';
//reac the file

const FetchHomeData = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    //console.log(inputData);
    const result = await FetchData("midoc_getpatienthomepage", inputData);
    //console.log(result,"Home db response")
    if (result.output.data) {
      //console.log(result.output.data)
    var finalOutput = formapimodalhome(result.output.data)
    
    localStorage.setItem("notificationcount" , result.output.notificationcount)
    }
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchTreatmentTeam = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    //console.log(inputData);
    const result = await FetchData("midoc_getpatienttreatmentteam", inputData);
    //console.log(result,"Home db response")
    if (result.output.data) {
      //console.log(result.output.data)
    var finalOutput = formAPIModeltreatmentteam(result.output.data)
    }
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchRecentActivity = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_getpatientactivityreport", inputData);
    //console.log(result,"recent activity db response")
    if (result.output.data) {
      //console.log(result.output.data)
    var finalOutput = formAPIModelrecentactivity(result.output.data)
    }
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchMyHealthAIData = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    //console.log(inputData)
    const result = await FetchData("midoc_getpatienthealth", inputData);
    if (result.output.data) {
      //console.log(result.output.data)
    var finalOutput = formapimodalaidotor(result.output.data)
    }
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchAIPrompts = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo();
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_getprompts`, 
      inputData,
    {
      headers: headers,
    });
    
    return response.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchMedicineAIPrompts = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo();
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_medicinegetprompts`, 
      inputData,
    {
      headers: headers,
    });
    
    return response.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchAIAnswer = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo()
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_dashbaorddescribeprompts`, 
      inputData,
    {
      headers: headers,
    });
    
    return response.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchMedicineAIAnswer = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo()
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_medicinedescribeprompts`, 
      inputData,
    {
      headers: headers,
    });
    
    return response.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const SubmitConsentForm = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_updateconsentform", inputData);   
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
export {FetchHomeData, FetchTreatmentTeam,FetchMedicineAIAnswer, FetchRecentActivity, FetchMyHealthAIData,FetchAIPrompts,FetchMedicineAIPrompts,FetchAIAnswer, SubmitConsentForm};
