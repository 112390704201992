import moment from "moment";
import formAPIModelDoctorAssessment from './doctorassessment/assessments'
export default function formapimodalAppointmentdetails({
    doctorid,
appointmentid,
doctorname,
specality,
appointmentdate,
appointmenttype,
hospitalname,
appointmenttime,
appointmentstatus,
doctorimage,
imagepath,
patientnotes,
doctornotes,
bookingid,
ratings,
yearsofexperience,
qualification,
payment,
location,
doctorsassessment,
doctoraddress,
chiefcomplaint,
historyofpresentillness,
isappointmentstarted,
paymenttype,
isdoctoractive,

})
{ 
  return Object.freeze({
    doctorid,
    appointmentid,
    doctorname,
    specality,
    appointmentdate,
    appointmenttype,
    hospitalname,
    appointmenttime,
    appointmentstatus,
    doctorimage,
    imagepath,
    patientnotes,
    doctornotes,
    bookingid,
    ratings,
    yearsofexperience,
    qualification,
    payment,
    location,
    doctoraddress,
    chiefcomplaint,
    historyofpresentillness,
    isappointmentstarted,
    paymenttype,
    doctorsassessment:doctorsassessment !== null ? formAPIModelDoctorAssessment(doctorsassessment):[],
    isdoctoractive
  });
}