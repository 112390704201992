import moment from "moment";
export default function formAPItreatmentplan({
    appointmentid,
    doctorid,
    doctorimage,
    doctorname,
    dosage,
    frequency,
    medicationenddate,
    medicationprescribeddate,
    medicationstartdate,
    medicineid,
    medicinename,
    nomenclatureid,
    patientid,
    prescriptionid,

})
{

  return Object.freeze({
    appointmentid,
    doctorid,
    doctorimage,
    doctorname,
    dosage,
    frequency,
    medicationenddate: medicationenddate ?  moment(medicationenddate).format("YYYY-MM-DD") :"-",
    medicationprescribeddate: medicationprescribeddate?  moment(medicationprescribeddate).format("YYYY-MM-DD"): "-",
    medicationstartdate: medicationstartdate? moment(medicationstartdate).format("YYYY-MM-DD"):"-",
    medicineid,
    medicinename,
    nomenclatureid,
    patientid,
    prescriptionid,

   
  });
}