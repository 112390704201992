import {
  Table,
  TableContainer,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Box,
} from "@chakra-ui/react";

function SimpleTable({
  NoDataHeader = true,
  onClick,
  headers,
  tableBody,
  footerSection,
  tableStyle,
  tableHeader,
  isTh = true,
  tableHeaders,
  footerVisible = true,
  isDataPresent,
  ThStyle,
  footerStyle,
  className,
  tableCss,
  isThCss,
}) {
  return (
    <>
      {NoDataHeader ? (
        <Box
          p="10px"
          flex="1"
          flexBasis="calc(20% - 20px)"
          alignItems="center"
          borderRight="1px solid #E6E7E9"
          borderBottom="1px solid #E6E7E9"
          borderLeft="1px solid #E6E7E9"
        >
          <Box as="p" fontSize="12px" fontWeight="500" color="#6A707D">
            {NoDataHeader}
          </Box>
          <Box as="p" display="inline-block" fontSize="22px" fontWeight="600">
            -
          </Box>
        </Box>
      ) : (
        <>
          <TableContainer {...tableStyle} className={className}>
            <Table {...tableCss}>
              <Thead
                {...tableHeader}
                pos="sticky"
                top="0"
                bg="white"
                borderBottom="1px solid #E6E7E9"
                zIndex="999"
              >
                {tableHeaders}
              </Thead>
              <Tbody bg="#ffffff">
                {isDataPresent ? (
                  tableBody
                ) : (
                  <Tr>
                    <Td
                      colSpan={headers.length}
                      textAlign="center"
                      height="18rem"
                    >
                      No data available...
                    </Td>
                  </Tr>
                )}
              </Tbody>
              {footerVisible && (
                <Tfoot bg="#ffffff" {...footerStyle}>
                  {footerSection}
                </Tfoot>
              )}
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}

export default SimpleTable;
