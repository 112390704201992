import React from 'react'
import {FormControl, Input, InputGroup, InputRightElement, Button, FormLabel, Image, Box } from '@chakra-ui/react'
import { MdOutlineSearch } from "react-icons/md";
import {btnStyle, THEME, inputStyles} from './styles'
import { inputDesign } from "../styles/theme";
// import * as THEME from "../styles/theme/index"


export default function SearchBar({searchValue,searchPlace,onChange,onKeyDown,handleClick,passInputPlace, onKeyUp,iconSrc = "/assets/imgs/search.png", ...props}) {
    const [show, setShow] = React.useState(false)
    //const handleClick = () => setShow(!show)
  
    return (
      <FormControl sx={inputDesign.formStyles.container}>
      <InputGroup size='md' flexDirection='column' >
        <Input
          sx={{ ...inputStyles, ...inputDesign.formStyles.input, pr: "2.5rem" }}
          type='text'
          value={searchValue}
          placeholder=" "
          {...props}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          errorBorderColor="red.300"
        />
        <FormLabel sx={THEME.formStyles.label}>{passInputPlace}</FormLabel>
        <InputRightElement width='3rem' zIndex="23" top="5px">
          <Box as="button" sx={btnStyle} size='lg' opacity={'50%'} onClick={handleClick}>
            <Image src={iconSrc} />
          </Box>
        </InputRightElement>
      </InputGroup>
      </FormControl>
    )
  }