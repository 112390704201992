import moment from "moment";
import formAPIModelSocialHistoryDrugNames from "../socialhistorydrugnames/socialhistories";
export default function formAPISocialHistory({
    duration,
durationtype,
durationtype_cd,
frequency,
frequencytype,
frequencytype_cd,
id,
iscurrent,
patientid,
socialhistory,
socialhistorytype_cd,
fromdate, 
todate,
smokelesstype,
drugnames,
drugtype
   

})
{ 
  return Object.freeze({
   
    duration:duration,
    durationtype:durationtype?durationtype:"-",
    durationtype_cd:durationtype_cd,
    frequency:frequency,
    frequencytype:frequencytype?frequencytype:"-",
    frequencytype_cd:frequencytype_cd,
    id:id,
    iscurrent:iscurrent,
    patientid:patientid,
    socialhistory:socialhistory?socialhistory:"-",
    socialhistorytype_cd:socialhistorytype_cd,
    fromdate, 
    todate,
    smokelesstype,
    drugnames: drugnames ? formAPIModelSocialHistoryDrugNames(drugnames): "",
    drugtype
   
  });
}