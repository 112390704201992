import { Box, Image, Text, Link } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { COLORS } from "../styles/colors";
// import { Link } from "react-router-dom";

const MobileNavbar = () => {
  const [activeLink, setActiveLink] = useState(null);
  const [isNestedPage, setIsNestedPage] = useState(false);
  const [isHomepage, setIsNestedPageHome] = useState(false);
  const [isProfilePage, setIsProfilePage] = useState(false);
  const [isAppointment, setIsAppointment] = useState(false);
  const location = useLocation();
  // Handle functions
  const handleLinkClick = (section) => {
    setActiveLink(section);
  };

  const myHealthRelatedPaths = [
    "/myhealth",
    "/vitals",
    "/conditions",
    "/surgeries",
    "/medications",
    "/allergies",
    "/familyhistory",
    "/vaccination",
    "/socialhistory",
    "/prescriptionupload",
    "/uploaddocument",
    "/myhealth/labs",
    "/myhealth/imaging",
    "/imagingupload"
  ];

  const profilePaths = [
    "/profile",
    "/account",
    "/rewards",
    "/aitokens",
    "/reports",
    "/family",
    "/orders",
    "/profile-appointment",
    "/doctors",
    "/pharmacy",
    "/medicalrecord",
    "/invoices",
    "/subscription",
    "/paymentmethods",
    "/refer-a-friend",
    "/videolibrary",
    "/alerts",
    "/profileappointment"
  ]

  const appointmentPaths = [
    "/appointments",
    "/all-appoinments",
    "/doctorprofile",
    "/appointmentdetails",
    "/appointmentpayment",
    "/appointments/doctors",
    "/visitoverview"
  ]
  const homePaths = [
    "/home/treatmentteam",
    "/home"
  ]
  
  useEffect(() => {
    const currentPath = location.pathname;
    setIsNestedPage(myHealthRelatedPaths.includes(currentPath));
    setIsNestedPageHome(homePaths.includes(currentPath));
    setIsProfilePage(profilePaths.includes(currentPath));
    setIsAppointment(appointmentPaths.includes(currentPath));
  }, [location.pathname]);

  return (
    <>
      <Box pos="relative">
        <Box
          as="nav"
          pos="fixed"
          zIndex="999"
          alignItems="center"
          justifyContent="space-between"
          p="0.5rem"
          bottom="0"
          left="0"
          right="0"
          borderTop="1px solid #E6E7E9"
          bg="#FFFFFF"
          spacing={4}
          display={{ base: "flex", md: "none" }}
        >
          <Link
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            p="0.5rem"
            fontSize="0.75rem"
            fontWeight="600"
            href="/home"
            color={activeLink === "home" ? "#061027" : "#6A707D"}
            onClick={() => handleLinkClick("home")}
          >
            <Image
              src={isHomepage ? "/assets/svgs/home-white.svg" : "/assets/imgs/home.png"}
              filter={isHomepage ? COLORS.GRAY_FILTER : ""}
              w="1.125rem"
              h="1.25rem"
              className={activeLink == "home" ? "lightBtn" : "darkBtn"}
            />
            <Text letterSpacing="-0.03em" fontSize="0.625rem">Home</Text>
          </Link>
          <Link
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            p="0.5rem"
            fontSize="0.75rem"
            fontWeight="600"
            href="/appointments"
            color={activeLink === "appointments" ? "#061027" : "#6A707D"}
            onClick={() => handleLinkClick("appointments")}
          >
            <Image
              src={isAppointment ? "/assets/svgs/appointment-white.svg" : "/assets/imgs/appointments.png"}
              filter={isAppointment ? COLORS.GRAY_FILTER : ""}
              w="1.125rem"
              h="1.25rem"
              className={activeLink == "appointments" ? "lightBtn" : "darkBtn"}
            />
            <Text letterSpacing="-0.03em" fontSize="0.625rem">Appointments</Text>
          </Link>
          <Link
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            p="0.5rem"
            fontSize="0.75rem"
            fontWeight="600"
            href="/myhealth"
            color={activeLink === "myhealth" ? "#061027" : "#6A707D"}
            onClick={() => handleLinkClick("myhealth")}
          >
            <Image
              src={isNestedPage ? "/assets/svgs/my-health-white.svg" : "/assets/svgs/my-health.svg"}
              filter={isNestedPage ? COLORS.GRAY_FILTER : ""}
              w="1.125rem"
              h="1.25rem"
              className={
                activeLink == "myhealth" ? "lightBtn" : "darkBtn"
              }
            />
            <Text letterSpacing="-0.03em" fontSize="0.625rem">My Health</Text>
          </Link>
          <Link
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            p="0.5rem"
            fontSize="0.75rem"
            fontWeight="600"
            href="/chat"
            color={activeLink === "chat" ? "#061027" : "#6A707D"}
            onClick={() => handleLinkClick("chat")}
          >
            <Image
              src={location.pathname == "/chat" ? "/assets/svgs/chat-white-nav.svg" : "/assets/imgs/chats.png"}
              filter={location.pathname == "/chat" ? COLORS.GRAY_FILTER : ""}
              w="1.125rem"
              h="1.25rem"
              className={activeLink == "chat" ? "lightBtn" : "darkBtn"}
            />
            <Text letterSpacing="-0.03em" fontSize="0.625rem">Chat</Text>
          </Link>
          <Link
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            p="0.5rem"
            fontSize="0.75rem"
            fontWeight="600"
            href="/profile"
            color={activeLink === "profile" ? "#061027" : "#6A707D"}
            onClick={() => handleLinkClick("profile")}
          >
            <Image
              src={isProfilePage ? "/assets/svgs/profile-white.svg" : "/assets/imgs/account-gray.png"}
              filter={isProfilePage ? COLORS.GRAY_FILTER : ""}
              w="1.125rem"
              h="1.25rem"
              className={activeLink == "profile" ? "lightBtn" : "darkBtn"}
            />
            <Text letterSpacing="-0.03em" fontSize="0.625rem">Profile</Text>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default MobileNavbar;
