export default function formAPIinvestigation({
  eventtitle,
  verifieddate,
  resultuom_cd,
  resultuom,
  actualresultvalue,
  resultvalue,
  lower_bound,
  upper_bound,
  preactualresultvalue
}) {
  let status;
  let trendColor;
  let difference = null;
  if (actualresultvalue && preactualresultvalue) {
    difference = actualresultvalue - preactualresultvalue;
  }
  if (actualresultvalue > preactualresultvalue) {
    status = 'high';
  } else {
    status = 'low';
  }

  // Check if actualresultvalue and preactualresultvalue are within or outside the normal range
  const isActualInNormalRange = actualresultvalue >= lower_bound && actualresultvalue <= upper_bound;
  const isPreactualInNormalRange = preactualresultvalue >= lower_bound && preactualresultvalue <= upper_bound;

  if (isActualInNormalRange && isPreactualInNormalRange) {
    trendColor = 'green'; // Normal - Normal
  } else if (isActualInNormalRange && !isPreactualInNormalRange) {
    trendColor = 'red'; // Normal - Abnormal
  } else if (!isActualInNormalRange && !isPreactualInNormalRange) {
    trendColor = 'red'; // Abnormal - Abnormal
  } else {
    trendColor = 'green'; // Abnormal - Normal
  }

  return Object.freeze({
    eventtitle,
    verifieddate: verifieddate,
    resultuom_cd,
    resultuom,
    actualresultvalue,
    resultvalue,
    status,
    trendColor,
    // difference
  });
}
