import moment from "moment";
import formAPIModelProfileRewards from "./rewards";

export default function formapimodalRewards({
  unredeempint,
  redeempoint,
  position,
  pointtounlocknext,
  availablepoint,
  totalpoint,
  data
}) {
  return Object.freeze({
    unredeempint,
    redeempoint,
    position,
    pointtounlocknext,
    availablepoint,
    totalpoint,
    data: data !== null ? formAPIModelProfileRewards(data) : [],

  });
}
