import moment from "moment";
import formAPIModelProfileAITokens from "./aitokens";

export default function formapimodalAITokens({
  remainingtokens,
  usedtokens,
  totaltokens,
  planname,
  data
}) {
  return Object.freeze({
    remainingtokens,
    usedtokens,
    totaltokens,
    planname,
    data: data !== null ? formAPIModelProfileAITokens(data) : [],

  });
}
