import formAPILink from "./link";


var formattedOutput = null;

const formAPIModelLink = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPILink(item));


  return formattedOutput
};


export default formAPIModelLink