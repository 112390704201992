import moment from "moment";
export default function formAPIModelAboutdoctor({
    addresses,
    qualifications,
    services,
    
   
    
  }) {   
    return Object.freeze({
        addresses,
    qualifications,
    services,
    });
}


  