import Loader from "../../components/loader/loader";
import { Box, Center, Image, Text } from "@chakra-ui/react";
import { COLORS } from "../../components/styles/colors";
import { PrimaryButton, Toaster } from "../../components";
import useLoader from "../../components/loader/loaderstates";
import React from "react";
import { useNavigate } from "react-router-dom";

const FailedAppointments = () => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  var navigate = useNavigate();
  return (
    <>
      <Box
        w="100vw"
        h="100vh"
        bg={`url('/assets/imgs/success-bg.png'), ${COLORS.FAILED}`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {isLoading && <Loader />}
        <Box
          bgImage="url('/assets/imgs/login-bg.png')"
          boxShadow={COLORS.BOX_SHADOW_SUCCESS}
          bgPosition="center"
          bgRepeat="no-repeat"
          bgSize="cover"
        ></Box>
        <Center
          h="100vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          w="25%"
        >
          <Box>
            {/* <Image src="/assets/imgs/success-alert.png" /> */}
            <Image  w="200px" h="200px" src="/assets/imgs/failed-icon-red.png" />
          </Box>
          <Box>
            <Box as="h1" fontSize="28px" textAlign="center" mt="20px">
              {/* Booking Successful! */}
              Booking Failed
            </Box>
            <Box mb="16px" textAlign="center" fontWeight="400">
              <Text>
                {/* Your appointment for Fri, <strong>6 Jan at 8:30 AM</strong>{" "}
                <br /> with <strong>Dr. Samantha Raulf</strong> is booked! */}
                There was an issue booking the <br/>
                appointment, please try again.
              </Text>
            </Box>
          </Box>
          <Box w="100%">
            <PrimaryButton buttonText="Try again" />
            <PrimaryButton variant="grayBtnFull" buttonText="Go back" onClick={()=>navigate("/appointments")}/>
          </Box>
        </Center>
      </Box>
      {/* <Box
        display="flex"
        flexDir="column"
        gap="30px"
        justifyContent="center"
        alignItems="center"
        h="100vh"
        overflow="hidden"
      >
        <Image w="200px" h="200px" src="/assets/imgs/failed-icon-red.png" />
        <Text as="h1" fontSize="28px" fontWeight="600">
          The Link has Expired
        </Text>
      </Box> */}
    </>
  );
};

export default FailedAppointments;
