import React, { useEffect, useState, useRef } from "react";
import GrayCard from "../../components/card";
import { Box, Flex, Text } from "@chakra-ui/layout";
import Navbar from "../../components/navbar";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import SecondaryNav from "../../components/seconday-nav";
import { Checkbox, Divider, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  CreatePaymentIntent,
  SavePaymentInitialCall,
} from "../../middleware/services/payment/payment";
import CheckoutForm from "./checkout-form";
import { PrimaryButton } from "../../components";
import { COLORS } from "../../components/styles/colors";
import { FetchPlans} from "../../middleware/services/profile/subscription";
import { CONFIG } from '../../appconfig/appconfig';
import ModalRight from "../../components/modal-right";


const PaymentIndex = () => {
  //console.log(data,"payment")
    const { isLoading, showLoader, dismissLoader } = useLoader();
    var navigate = useNavigate();
    const showmedicinename = React.useRef();
    const [clientSecret, setClientSecret] = useState(null);
    const [stripePromise, setStripePromise] = useState(null);
    const [planid, setPlanid] = useState("");
    //const [plandata, setData] = useState(data);
    const [plansdata, setPlansData] = useState("");
    var [patientid, setPatientid] = useState();
    const infoModal = useRef();
    const [ infoArray , setInfoArray ] = useState([])

    useEffect(()=>{
      const currentURL = window.location.href;
      const urlParams = new URLSearchParams(currentURL.split('?')[1]);
      const idValue = urlParams.get('id');
      setPlanid(idValue)
      fetchPlansData(idValue);
    },[])

    var fetchPlansData = async (plan) => {
      showLoader()
      try {
        var result = await FetchPlans({
          patientid,
        })
        if (result) {
          const filteredPlans = result.filter(item => item.subscriptionplanid == plan);
          setPlansData(filteredPlans);
        } else {
          setPlansData("")
          //  showErrorToast("Something went wrong!")
        }
       dismissLoader();
      } catch (error) {
        console.log(error, "error in fetching account data")
      }
      dismissLoader();
    }
      const paynowmodel = async ()=>{
        showLoader();
        const amount = parseFloat(plansdata && plansdata[0].subscriptionprice) * 100;
        try {
            const result = await CreatePaymentIntent({
              amount: amount,
            });
            //console.log(result,"Initial resp");
            result.amount = plansdata[0].subscriptionprice;
            result.paymenttypeid = "1";
            const resp = await SavePaymentInitialCall(result);
            //console.log(resp,"Initial db resp");
            //setClientSecret("pi_3PAucsIpkkS0IXSH0Y2lFxG8_secret_2YLi2vpWMmcIfVkjUM4i52GU6");
            setClientSecret(result.client_secret);
            setStripePromise(loadStripe(CONFIG.StripePublicKey));
          } catch (error) {
            console.error("Error in Payment:", error);
          }
          dismissLoader();
      }
     
      const backFunction = () => {
        const currentUrl = window.location.href;
       navigate("/subscription")
      };
      const openInfoModal = (desc)=>{
        if(desc && desc.info){
      
          setInfoArray(desc.info)
        }
        infoModal.current.openRight();
      }
  return (
    <div>
      <Box top="0">
        <Navbar />
        {isLoading && <Loader />}
        <SecondaryNav withBack="Upgrade Plan" goBack={backFunction} />
      </Box>
      <Box h="calc(100vh - 140px)" overflow="hidden auto" backgroundImage={COLORS.PLATINUM_REWARD}>
        <Flex p="24px 32px" gap="15px">
          {/* Plan details start */}
          <Flex w="75%">
            <Box w="100%" display="flex" flexDir="column" gap="15px">
              {/* <GrayCard
                cardHeader="Overview"
                cardMinHeight="115px"
                hasFooter={false}
                cardBody={
                  <>
                    <Box w="100%">
                      <Flex w="100%" justifyContent="space-between" gap="20px">
                      <Box display="flex" w="100%" justifyContent="space-between" alignItems="center">
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="left"
                        >
                          <Text fontSize="10px" fontWeight="500" color="#384052">
                            Dentist
                          </Text>

                          <Text
                            fontSize="16px"
                            fontWeight="600"
                          >
                            Dr. Samantha Raulf
                          </Text>
                        </Box>
                        
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="right"
                        >
                          <Text fontSize="10px" fontWeight="500" color="#384052">
                            Total amount
                          </Text>

                          <Text
                            fontSize="16px"
                            fontWeight="600"
                          >
                            $111
                          </Text>
                        </Box>
                      </Box>

                      <Divider h="inherit" my="-2%" orientation='vertical' />

                      <Box display="flex" w="100%" justifyContent="space-between" alignItems="center">
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="left"
                        >
                          <Text fontSize="10px" fontWeight="500" color="#384052">
                            Appointment schedule
                          </Text>

                          <Text
                            fontSize="16px"
                            fontWeight="600"
                            display="inline-flex"
                            gap="5px"
                          >
                            <Image src="/assets/svgs/calendar_plain.svg" />
                            Fri, 6 Jan at 8:30 AM
                          </Text>
                        </Box>
                        
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="right"
                        >
                          <Box
                            as="button"
                            fontSize="14px"
                            fontWeight="600"
                            color="#1C4EC5"
                          >
                            Reschedule
                          </Box>
                        </Box>
                      </Box>
                      </Flex>
                    </Box>
                  </>
                }
              /> */}

<GrayCard
                cardHeader="Overview"
                cardMinHeight="115px"
                hasFooter={false}
                cardBody={
                  <>
                    <Box w="100%">
                      <Flex w="100%" justifyContent="space-between" gap="20px">
                      <Box display="flex" w="100%" justifyContent="space-between" alignItems="center">
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="left"
                        >
                          <Text fontSize="10px" fontWeight="500" color="#384052">
                            Plan
                          </Text>

                          <Text
                            fontSize="16px"
                            fontWeight="600"
                          >
                            {plansdata && plansdata[0].subscriptionname? plansdata[0].subscriptionname:""}
                          </Text>
                        </Box>
                        
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="right"
                        >
                          {/* <Text fontSize="10px" fontWeight="500" color="#384052">
                            Total amount
                          </Text> */}

                          <Text
                            fontSize="16px"
                            fontWeight="600"
                            textAlign="right"
                          >
                            ${plansdata && plansdata[0].subscriptionprice? plansdata[0].subscriptionprice:"0"}/Year <Box as="span" pl="5px" fontSize="8px" fontWeight="500">*Billed annually</Box>
                          </Text>
                        </Box>
                      </Box>
                      </Flex>
                    </Box>
                  </>
                }
              />
 {/* Plan features start */}
 <Box w="100%">
                          <GrayCard
                              cardHeader="You have access to"
                              cardBody={<>
                                  <Flex flexDir="column" gap="10px">
                                  {plansdata && plansdata[0].subscriptiondescription && plansdata[0].subscriptiondescription.map((desc, index) => (
                  <Box key={index} display="flex" gap="10px" alignItems="center">
                      <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                      <Text fontSize="14px" fontWeight="700"><Box as="span" fontWeight="500">{desc.name?desc.name:"-"}</Box></Text>
                      {plansdata && plansdata[0].subscriptiondescription[index].info &&

                        <Image cursor="pointer" w="12px" h="auto" src="/assets/svgs/info.svg" onClick={()=>openInfoModal(plansdata && plansdata[0].subscriptiondescription[index])} />
                      }
                  </Box>
              ))}
                                  </Flex>
                              </>}
                              hasFooter={false}
                          />
                      </Box>
                      {/* Plan features end */}
              {/* <GrayCard 
                cardHeader="Preferred"
                cardMinHeight="115px"
                hasFooter={false}
                cardBody={<>
                  <Box w="100%">
                    <Flex w="100%">
                      <Box display="flex" justifyContent="space-between" alignItems="center" w="100%">
                        <Flex gap="20px">
                        <Box>
                          <Image src="/assets/svgs/Payment-visa-icon.svg" />
                        </Box>
                        <Box>
                        <Text fontSize="14px" fontWeight="500">
                            Adnan’s card
                          </Text>
                          <Text
                            fontSize="12px"
                            fontWeight="200"
                          >
                             • • • •   1 2 2 8
                          </Text>
                        </Box>
                        </Flex>
                        <Box>
                          <Checkbox colorScheme="green" />
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                </>}
              />
              
              
              <GrayCard 
                cardHeader="credit/debit cards"
                cardMinHeight="115px"
                cardFooterText="Add a card"
                cardBody={<>
                  <Box w="100%">
                    <Flex w="100%" flexDir="column" gap="20px">
                      <Box display="flex" justifyContent="space-between" alignItems="center" w="100%">
                        <Flex gap="20px">
                        <Box>
                          <Image src="/assets/svgs/Payment-visa-icon.svg" />
                        </Box>
                        <Box>
                        <Text fontSize="14px" fontWeight="500">
                            Adnan’s card
                          </Text>
                          <Text
                            fontSize="12px"
                            fontWeight="200"
                          >
                             • • • •   1 2 2 8
                          </Text>
                        </Box>
                        </Flex>
                        <Box>
                          <Checkbox colorScheme="green" />
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between" alignItems="center" w="100%">
                        <Flex gap="20px">
                        <Box>
                          <Image src="/assets/svgs/Payment-master-icon.svg" />
                        </Box>
                        <Box>
                        <Text fontSize="14px" fontWeight="500">
                            Adnan’s card
                          </Text>
                          <Text
                            fontSize="12px"
                            fontWeight="200"
                          >
                             • • • •   1 2 2 8
                          </Text>
                        </Box>
                        </Flex>
                        <Box>
                          <Checkbox colorScheme="green" />
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                </>}
              />
              
              
              <GrayCard 
                cardHeader="wallet"
                cardMinHeight="115px"
                hasFooter={false}
                cardBody={<>
                  <Box w="100%">
                    <Flex w="100%" flexDir="column" gap="20px">
                      <Box display="flex" justifyContent="space-between" alignItems="center" w="100%">
                        <Flex gap="20px" alignItems="center">
                        <Box>
                          <Image src="/assets/svgs/Payment-apple-icon.svg" />
                        </Box>
                        <Box>
                        <Text fontSize="14px" fontWeight="500">
                            Apple Pay
                          </Text>
                        </Box>
                        </Flex>
                        <Box>
                          <Checkbox colorScheme="green" />
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between" alignItems="center" w="100%">
                        <Flex gap="20px" alignItems="center">
                        <Box>
                          <Image src="/assets/svgs/Payment-paypal-icon.svg" />
                        </Box>
                        <Box>
                        <Text fontSize="14px" fontWeight="500">
                            Paypal
                          </Text>
                          
                        </Box>
                        </Flex>
                        <Box>
                          <Checkbox colorScheme="green" />
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                </>}
              /> */}
            </Box>
          </Flex>
          {/* Plan details end */}

          {/* Summary start */}
          <Flex w="25%">
              <Box w="100%" display="flex" flexDir="column" gap="12px" minH="260px" h="max-content" p="24px" bg="#FFFFFF" border="1px solid #E6E7E9" borderRadius="12px">
                  <Flex w="100%" justifyContent="space-between">
                    <Text fontSize="14px" fontWeight="400">
                      Plan fee
                    </Text>
                    <Text fontSize="14px" fontWeight="500">
                    ${plansdata && plansdata[0].subscriptionprice? plansdata[0].subscriptionprice:"0"}
                    </Text>
                  </Flex>

                  <Flex w="100%" justifyContent="space-between">
                    <Text fontSize="14px" fontWeight="400">
                      Service fee
                    </Text>
                    <Text fontSize="14px" fontWeight="500">
                      $0
                    </Text>
                  </Flex>

                  <Flex w="100%" justifyContent="space-between">
                    <Text fontSize="14px" fontWeight="400">
                      Tax
                    </Text>
                    <Text fontSize="14px" fontWeight="500">
                      $0
                    </Text>
                  </Flex>

                  <Divider />
                  
                  <Flex w="100%" justifyContent="space-between">
                    <Text fontSize="18px" fontWeight="400">
                      Total Amount
                    </Text>
                    <Text fontSize="18px" fontWeight="600">
                    ${plansdata && plansdata[0].subscriptionprice? plansdata[0].subscriptionprice:"0"}
                    </Text>
                  </Flex>

                  <PrimaryButton
  buttonText={"Pay $" + (plansdata && plansdata[0].subscriptionprice ? plansdata[0].subscriptionprice : "0")}
  onClick={paynowmodel}
/>              </Box>    
          </Flex>
          {/* Summary end */}

        </Flex>
      </Box>
      <ModalRight
        ref={infoModal}
      header="You have access to"
        // subHeader="You have access to."
        body={
          <Flex pt="15px" flexDirection="column" h="100%">
            {
              infoArray && infoArray.map((item, key)=>(
                <Box index={key} display="flex" alignItems="baseline" justifyContent="left" my="0.6rem">
                <Image mx="0.5rem" w="12px" h="auto" src="/assets/imgs/check-black.png" />
                <Text fontSize="14px" fontWeight="600">{item.name}</Text>
                </Box>
              ))
            }
         
          </Flex>
        }
      />
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm planid={planid} clientSecret={clientSecret} />
        </Elements>
      )}
    </div>
  );
};

export default PaymentIndex;
