import React from 'react'
import Account from './account'
import { Box, Flex } from '@chakra-ui/layout'
import { VStack, Image, Text, Spacer, Center, Input } from "@chakra-ui/react";
import Navbar from '../../components/navbar'
import SecondaryNav from '../../components/seconday-nav'
import ProfileSidebar from '../profile/profileSidebar'
import ModalRight from '../../components/modal-right'
import { DropdownInput,PrimaryButton, PrimaryButtonIcon, PrimaryInput, PrimaryInputDropdown, SearchBar, TagsInput, Toaster } from "../../components";
import MiniDropdownInput from '../../components/mini-dropdown';
import Loader from '../../components/loader/loader';
import useLoader from '../../components/loader/loaderstates';
import Footer from '../../components/footer';
import MobileNavbar from '../../components/mobile-navbar';



function ProfileIndex() {
  const editProfileModal = React.useRef();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  
  return (
    <>
    <Box display={{base: "none", lg: "block"}}>
      
      {isLoading && <Loader />}
      <Box>
        <Navbar />
        {/* <SecondaryNav
        sideBarWidth="250px"
        defaultHeader="Profile"
        defaultSecondayHeader="Account"
        defaultNavBody={
            <>
              <Box display="flex" alignItems="center" pr="25px">
                <PrimaryButtonIcon
                  btnClass="uploadBtn"
                  buttonText="Edit Details"
                  iconSrc={false}
                  btnStyle={{color: "#1C4EC5"}}
                  onClick={()=> editProfileModal.current.openRight()}
                  iconImg="uploadDoc"
                  sx={{
                    ".uploadDoc": {
                      width: "0px!important",
                    }
                  }}
                />
              </Box>
            </>
          }
        /> */}
      </Box>
      <Box h="calc(100vh - 70px)" overflow="hidden auto">
      <Flex>
        {/* <Box>
          <ProfileSidebar />
        </Box> */}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gap="20px"
          width="100%"
          >

      <Account/>
        </Box>
        </Flex>
        <Footer />
        </Box>
        <ModalRight 
          ref={editProfileModal}
          modalWidth="360px"
          header="Edit Profile"
          body={
            <>
            <Box w="100%" py="10px" px="0">
              <Center>
                <VStack spacing={0} w="100%">
                  <Flex w="100%">
                    <PrimaryInput variant="defaultSide" inputPlace="First Name" id="firstName" />
                  </Flex>
                  <Flex w="100%">
                    <PrimaryInput variant="bothSide" inputPlace="Last Name" id="lastName" />
                  </Flex>
                  <Flex w="100%">
                    <DropdownInput variant="bothSide" dropDownPlace="Gender" options={["Male","Female", "Others"]} id="gender" />
                  </Flex>
                  <Flex w="100%">
                    <MiniDropdownInput
                      variant="bothSide"
                      options={[
                        { label: "cms", value: "151" },
                        { label: "inch", value: "152" }
                      ]}
                      placeholderProp="Height"
                      id="heightUnits"
                      maxLength="3"
                    />
                    <MiniDropdownInput
                      variant="bothSide"
                      placeholderProp="Weight"
                      options={[
                        { label: "kg", value: "154" },
                        { label: "Pound", value: "155" }
                      ]}
                      id="weightUnits"
                      maxLength="3"
                    />
                  </Flex>
                  <Flex w="100%">
                    <Input
                      placeholder="Date of birth"
                      size="md"
                      type="date"
                      defaultValue=""
                      id="dob"
                      borderRadius="0"
                      height="48px"
                      bg="white"
                    />
                  </Flex>
                  <Flex w="100%">
                    <PrimaryInputDropdown
                      id="mobile"
                      variant="bothSide"
                    />
                  </Flex>
                  <Flex w="100%">
                    <PrimaryInput variant="downSide" inputType="Email" inputPlace="Enter Email Address" id="Email" />
                  </Flex>
                  
                </VStack>
              </Center>
            </Box>

            </>
          }
          footerActions={<>
          <Box w="100%">
              <PrimaryButton buttonText="Save changes" id="submitButton" />
          </Box>
          </>}
        />

    </Box>

    {/* Mobile responsive start */}
    <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
      <MobileNavbar/>
      
      <Account/>
    </Box>
    </>
  )
}

export default ProfileIndex