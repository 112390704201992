import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar";
import SideBar from "../../components/sidebar";
import SecondaryNav from "../../components/seconday-nav";
import { Box, Divider, Flex } from "@chakra-ui/layout";
import {
  Image,
  Checkbox,
  Text,
  RadioGroup,
  Radio,
  Stack,
  SimpleGrid
} from "@chakra-ui/react";
import { DropdownInput, PrimaryButton, PrimaryInput, Toaster, SearchBar, PrimaryButtonIcon } from "../../components";

import DynamicModal from "../../components/dynamic-modal";

import ModalRight from "../../components/modal-right";
import { FetchSocialHistoryData, AddSocialHistory, UpdateSocialHistory, GetSocialHistoryTypes, DeleteSocialHistory } from "../../middleware/services/myhealth/socialhistory";
import ReportViewCard from "../../components/report-card";
import TabSection from "../../components/tabs";
import { center } from "@antv/g2plot/lib/plots/sankey/sankey";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import Footer from "../../components/footer";
import DatePicker from "../../components/date-picker";
import moment from "moment";
import { format } from "date-fns";
import { COLORS } from "../../components/styles/colors";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, formatDate_yyyy } from "../../auth";


const SocialHistoryIndex = () => {
  var [data, setData] = useState(null);
  var [typedata, setTypeData] = useState([]);
  var [socialtypedata, setSocialTypeData] = useState([]);
  var [loginid, setPatientid] = useState();
  var [id, setID] = useState();
  var [title, setTitle] = useState();
  const addsocialhistory = React.useRef();
  const editsocialhistory = React.useRef();
  const dynamicModal = React.useRef();
  const [type, setType] = useState('');
  const [duration, setDuration] = useState('');
  const [durationtimespan, setDurationTime] = useState('');
  const [frequency, setFrequency] = useState('');
  const [frequencytimespan, setFrequencyTime] = useState('');
  const [frequency_cd, setFrequencyTimeID] = useState('327');
  const [duration_cd, setDurationTimeID] = useState('');
  const [type_cd, setTypeID] = useState('');
  const [current, setCurrent] = useState(0);
  const [smokingSelected, setSmokingSelected] = useState(false);
  const [smokeLessSelected, setSmokeLessSelected] = useState(false);
  const [hideFrequency, setHideFrequency] = useState(false);
  const [showDateSelection, setShowDateSelection] = useState(false);
  const [yearsDiff, setYearsDiff] = useState('');
  const [showQuitCheckBox, setShowQuitCheckBox] = useState(false);
  const [showIsCurentCheckBox, setShowIsCurentCheckBox] = useState(false);
  const [text, setText] = useState("");
  const [sendSmoklessInInput, setSendSmoklessInInput] = useState(false);
  const [sendDrugTypeInInput, setSendDrugTypeInInput] = useState(false);
  const [smokelessType, setSmokelessType] = useState(null);
  const [drugType, setDrugType] = useState(null);
  const [drugsSelected, setDrugsSelected] = useState(false);
  const [showFrequencyCalculation, setShowFrequencyCalculation] = useState(false);

  const addCondition3 = React.useRef();
  const navigate = useNavigate()

  const [searchValue, setSearchValue] = useState("");

  const [reasonsArray, setReasonsArray] = useState([]);
  const [reactionsContinueBtnDisabled, setReactionsContinueBtnDisabled] = useState(true)
  const [allergyID, setAllergieID] = useState("");
  const [selectedReactions, setSelectedReactions] = useState([]);
  const floatLabel = { color: "#384052", fontSize: "0.775rem", position: "relative", top: "-4px", left: "-4px", opacity: "0.5", width: "100%" };

  var [fromYearOptions, setFromYearOptions] = useState([]);
  var [toYearOptions, setToYearOptions] = useState([]);
  var [showFrequencyTag, setShowFrequencyTag] = useState(true);
  const [radioValue, setRadioValue] = useState("");

  const [fromdate, setFromDate] = useState(moment().format("DD-MM-yyyy"));
  const [todate, setToDate] = useState("");
  const [showFrequencyInEditModal, setShowFrequencyInEditModal] = useState(true);
  const today = moment().format("YYYY-MM-DD HH:mm:ss.SSS");

  const [fromdateYear, setFromDateYear] = useState("")
  const [toDateYear, setToDateYear] = useState("")

  const [fromYearForValidation, setFromYearForValidation] = useState("")
  const [toYearForValidation, setToYearForValidation] = useState("")

  const { showSuccessToast, showErrorToast } = Toaster();
  const [activeTab, setActiveTab] = useState(0);
  const { isLoading, showLoader, dismissLoader } = useLoader();

  useEffect(() => {
    fetchData("1");
  }, []);
  const fetchData = async (tab) => {
    showLoader();
    setData("")
    try {
      const result = await FetchSocialHistoryData({
        loginid,
        // "iscurrent": tab,
      });
      // console.log(result, "modified social history res");
      if (result != null) {
        setData(result);
        //setActiveTab(0);
      } else {
        //alert("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in DashboardIndex:", error);
    }
  };
  const OpenAddSocialHistory = async () => {
    clearState();
    const result = await GetSocialHistoryTypes({
      type: "socialhistorytype",
    });
    if (result) {
      // Iterate through the options and create an array of formatted strings
      const formattedOptions = result.output.data[0].data.map(option => (
        [option.condition]
      ));

      setTypeData(formattedOptions);
    }
    const currentYear = new Date().getFullYear();
    const fromYears = Array.from({ length: 101 }, (_, index) => currentYear - index);
    const fromOptions = fromYears.map((year) => String(year));
    setFromYearOptions(fromOptions);

    const toYears = Array.from({ length: 101 }, (_, index) => currentYear - index);
    const toOptions = toYears.map((year) => String(year));
    setToYearOptions(toOptions);

    setSocialTypeData(result.output.data[0].data)
    setDuration("")
    setFrequency("")
    addsocialhistory.current.openRight()
  }
  useEffect(() => {
    setFromYearForValidation(fromdateYear)
    setToYearForValidation(toDateYear)

  }, [fromdateYear, toDateYear])
  useEffect(() => {
    if (fromYearForValidation) {
      const currentYear = new Date().getFullYear();
      const fromYear = parseInt(fromYearForValidation); // Convert to number
      const toYears = Array.from({ length: currentYear - fromYear + 1 }, (_, index) => fromYear + index);
      const toOptions = toYears.map((year) => String(year));
      setToYearOptions(toOptions);
    }
  }, [fromYearForValidation]);
  useEffect(() => {
    if (toYearForValidation) {
      const toYears = Array.from({ length: 101 }, (_, index) => toYearForValidation - index);
      const toOptions = toYears.map((year) => String(year));
      setFromYearOptions(toOptions);
    }
  }, [toYearForValidation, id])

  const clearState = () => {
    setTypeID('')
    setHideFrequency(false)
    setSmokeLessSelected(false)
    setSmokingSelected(false)

    setNeverSelected(false)
    setShowDateSelection(false)
    setShowQuitCheckBox(false)
    setShowIsCurentCheckBox(false)
    setShowQuitCheckBox(false)
    setYearsDiff("")
    setReasonsArray([])
    setDrugsSelected(false)
    setToDateYear("")
    setToDate("")
    setFromDateYear("")
    setFromDate("")
    setShowYearsDiffTag(false)
    setSmokelessType(null)
    setDrugType(null)
    setCalculatedConsumption("")
    setFrequency('')
  }

  var [reactionList, setReactionList] = useState(null);
  const getDrugList = async () => {
    const result = await GetSocialHistoryTypes({
      type: "drug",
    });
    setReactionList(result.output.data[0].data)

  }
  const handleTypeInputChange = (selectedOption) => {
    var foundObject = socialtypedata && socialtypedata.find(x => x.condition == selectedOption);

    clearState();
    if (foundObject) {

      setRadioValue("")
      setShowQuitCheckBox(true)
      if (foundObject.condition == "Smoking") {
        setText("Packs")
        setSmokingSelected(true)
        setShowFrequencyCalculation(true)

        setSmokeLessSelected(false)
        setDrugsSelected(false)
      } else if (foundObject.condition == "Alcohol") {
        setText("Drinks")
        setSmokingSelected(true)
        setShowFrequencyCalculation(false)

        setDrugsSelected(false)

        setSmokeLessSelected(false)
      } else if (foundObject.condition == "Smokeless") {
        setText("Cans")
        setSmokingSelected(true)
        // setHideFrequency(true)
        setSmokeLessSelected(true)
        setSendSmoklessInInput(true)
        setShowFrequencyCalculation(false)

        setDrugsSelected(false)
      }
      else if (foundObject.condition == "Illicit Drugs") {
        //setText("drinks")

        setText("Times")
        setSmokingSelected(true)
        setDrugsSelected(true)
        setSmokeLessSelected(false)
        setShowFrequencyCalculation(false)
        setSendDrugTypeInInput(true)
        // getDrugList();
      }
      else {
        setSmokingSelected(false)
      }
      setTypeID(foundObject.condition_cd)
    }
  }
  const handledurationInputChange = (selectedOption) => {
    let value;
    switch (selectedOption) {
      case 'Hour':
        value = 325;
        break;
      case 'Day':
        value = 326;
        break;
      case 'Week':
        value = 327;
        break;
      case 'Month':
        value = 328;
        break;
      case 'Year':
        value = 329;
        break;
      default:
        value = 0; // Default value if no match is found
    }
    setDurationTimeID(value);
  }

  const handlefrequencyInputChange = (selectedOption) => {
    let value;
    switch (selectedOption) {
      case 'Hour':
        value = 325;
        break;
      case 'Day':
        value = 326;
        break;
      case 'Week':
        value = 327;
        break;
      case 'Month':
        value = 328;
        break;
      case 'Year':
        value = 329;
        break;
      default:
        value = 0; // Default value if no match is found
    }
    setFrequencyTimeID(value)
  }

  const handleCheckboxChange = (event) => {
    if (event.target.checked == true) {
      setCurrent(1);

    } else {
      setCurrent(0);

    }
  };
  const handleCheckboxChangeQuit = (event) => {
    if (event.target.checked == true) {
      setCurrent(0);

    }
  };

  const handleaddsocialhistory = async () => {
    //setCurrent(current === false ? 0 : 1);
    if (type_cd == "") {
      showErrorToast("Please select any type")
      return false;
    } else if (radioValue == "") {
      showErrorToast("Please select a value")
      return false;
    }
    //  else if(type_cd == 533){
    //   if(smokelessType == null){
    //   showErrorToast("Please select smokeless type")
    //   }

    // }
    else if (radioValue != "never" && type_cd == "322" && drugType == null) {
      showErrorToast("Please select drug type")
      return false;
    } else if (radioValue != "never" && type_cd == "322" && reasonsArray.length == 0) {
      showErrorToast("Please select a drug")
      return false;
    }
    else if (radioValue != "never" && type_cd == "533" && smokelessType == null) {
      showErrorToast("Please select smokeless type")
      return false;
    }
    else if (radioValue != "never" && fromdateYear == "") {
      showErrorToast("Please select start year")
      return false;
    } else if (radioValue != "never" && toDateYear == "" && todate == "") {
      showErrorToast("Please select end year")
      return false;
    }
    else if (radioValue != "never" && type_cd != 533 && frequency == "") {
      showErrorToast("Please enter frequency")
      return false;
    } else if (radioValue != "never" && frequency == "") {
      showErrorToast("Please enter frequency")
      return false;
    }

    //  else if (duration == "") {
    //   showErrorToast("Please enter the duration")
    //   return false;
    // }
    else {
      showLoader();
      const formData = {
        loginid: "",
        socialhistory: [
          {
            "socialhistorytype_cd": type_cd,
            //"duration": duration,
            "frequency": frequency,
            "iscurrent": current,
            "frequencytype_cd": frequency_cd,
            //"durationtype_cd": duration_cd,
            "fromdate": fromdate ? fromdate : null,
            "todate": todate ? todate : null,
            ...(sendSmoklessInInput && { "smokelesstype": smokelessType }),
            ...(sendDrugTypeInInput && { "drugtype": drugType }),
            ...(reasonsArray.length > 0 && {
              drugnames: reasonsArray.map((reason) => ({
                name: reason.condition
              }))
            })
          },
        ],
      };
      // if(sendSmoklessInInput){
      //   if (formData.socialhistory.length > 0) {
      //     alert("hit")
      //     formData.socialhistory[0].push("smokelessType");
      //   }
      // }
      //  console.log(formData, " input for add")
      const result = await AddSocialHistory(formData);


      if (result) {
        showSuccessToast("Social history added successfully");
        // fetchData(activeTab === 1 ? "0" : "1");
        fetchData(activeTab === 1 ? "2" : "1");
        //setActiveTab(0);
        //window.location.reload();
        addsocialhistory.current.closeRight();
      }
    }
    dismissLoader();
  }
  const Editsocialhistory = async () => {

    if (type == "") {
      showErrorToast("Please select any type")
      return false;
    } else if (duration == "") {
      showErrorToast("Please enter the duration")
      return false;
    } else if (radioValue != "never" && type_cd == "322" && reasonsArray.length == 0) {
      showErrorToast("Please select a drug")
      return false;
    } else if (radioValue != "never" && fromdateYear == "") {
      showErrorToast("Please select start year")
      return false;
    } else if (radioValue != "never" && toDateYear == "" && todate == "") {
      showErrorToast("Please select end year")
      return false;
    } else if (radioValue != "never" && frequency == "") {
      showErrorToast("Please enter frequency")
      return false;
    }
    else {
      const formData = {
        loginid: "",
        "id": id,
        "socialhistorytype_cd": type_cd,
        // "duration": duration,
        "frequency": frequency,
        "iscurrent": current,
        "frequencytype_cd": frequency_cd,
        //"durationtype_cd": duration_cd,
        "fromdate": fromdate ? fromdate : null,
        "todate": todate ? todate : null,
        ...(sendSmoklessInInput && { "smokelesstype": smokelessType }),
        ...(sendDrugTypeInInput && { "drugtype": drugType }),
        ...(reasonsArray.length > 0 && {
          drugnames: reasonsArray.map((reason) => ({
            name: reason.condition
          }))
        })
      };
      const result = await UpdateSocialHistory(formData);
      if (result) {
        showSuccessToast("Social history updated successfully");
        fetchData(activeTab === 1 ? "2" : "1");
        //setActiveTab(0);
        //window.location.reload();
        editsocialhistory.current.closeRight();
      }
    }
  }

  const handleTabClick = (index) => {
    switch (index) {
      case 0:
        setActiveTab(0);
        fetchData("1");
        break;
      case 1:
        setActiveTab(1);
        fetchData("2");
        break;
      default:
        break;
    }
  };
  const deletesocialhistorymodel = (id, title) => {
    setID(id)
    setTitle(title)
    dynamicModal.current.openModal()
  }
  const deletesocialhistory = async () => {
    const result = await DeleteSocialHistory({
      loginid,
      id: id,
    });
    if (result) {
      showSuccessToast("Social history deleted successfully");
      fetchData(activeTab === 1 ? "2" : "1");
      //setActiveTab(0);
      //window.location.reload();
      dynamicModal.current.closeModal();
    }
  }
  const handleeditsocialhistory = async (item, consumptionYears) => {
   //console.log(item, " item sdf")
    //here
    setID(item.id);
    setType(item.socialhistory);
    setTypeID(item.socialhistorytype_cd);
    setDuration(item.duration);
    setDurationTime(item.durationtype);
    setDurationTimeID(item.durationtype_cd);
    setFrequency(item.frequency);
    if (item.iscurrent == 0) {
      setFrequency('')
    }
    setFrequencyTime(item.frequencytype);
    setFrequencyTimeID(item.frequencytype_cd);
    setCurrent(item.iscurrent);

    const currentYear = new Date().getFullYear();
    // const toYears = Array.from({ length: 101 }, (_, index) => currentYear - index);
    // const toOptions = toYears.map((year) => String(year));
    // setToYearOptions(toOptions);

    const fromYears = Array.from({ length: 101 }, (_, index) => currentYear - index);
    const fromOptions = fromYears.map((year) => String(year));
    setFromYearOptions(fromOptions);

    if (item.iscurrent == 1) {
      setShowIsCurentCheckBox(true)
      setShowQuitCheckBox(false)
      setNeverSelected(false)
      setRadioValue("yes")
      setSmokelessType(item.smokelesstype)
    } else if (item.iscurrent == 2) {
      setShowIsCurentCheckBox(false)
      setShowQuitCheckBox(true)
      setNeverSelected(false)
      setRadioValue("currentlyNot")
      setSmokelessType(item.smokelesstype)
    } else if (item.iscurrent == 0) {
      setNeverSelected(true)
      setShowQuitCheckBox(false)
      setShowIsCurentCheckBox(false)
      setRadioValue("never")
    }
    setDrugType(item.drugtype)
    if (item.socialhistory == "Smoking") {
      setShowFrequencyCalculation(true)
    } else {

      setShowFrequencyCalculation(false)
    }
    if (item.smokelesstype || item.socialhistory == "Smokeless") {
      setSmokeLessSelected(true)
    } else {

      setSmokeLessSelected(false)
    }
    if (item.iscurrent == 0) {
      setSmokeLessSelected(false)
    }
    if (item.smokelesstype || item.socialhistory == "Illicit Drugs") {
      setSendDrugTypeInInput(true)
    } else {

      setSendDrugTypeInInput(false)
    }

    if (item.drugnames) {

      setReasonsArray([])
      item.drugnames.forEach((drug) => {
        // Assuming each drug has a "name" property
        const newReason = {
          condition: drug.name
        };
        setReasonsArray((prevArray) => [...prevArray, newReason]);
      });
      setDrugsSelected(true)
    } else {
      if (item.socialhistory == "Illicit Drugs" && item.drugtype) {

        setDrugsSelected(true)
      } else {
        setDrugsSelected(false)
      }
      setReasonsArray([])
    }
    if (item.socialhistory == "Smoking") {
      setText("Packs")
    } else if (item.socialhistory == "Alcohol") {
      setText("Drinks")
    } else if (item.socialhistory == "Smokeless") {
      setText("Cans")
    } else if (item.socialhistory == "Illicit Drugs") {
      setText("Times")
    } else {
      setText("Packs")
    }
    // if (item.socialhistory == "Smokeless") {
    //   setShowFrequencyInEditModal(false)
    // } else {
    //   setShowFrequencyInEditModal(true)
    // }

    setcalculatedYearsForEdit(consumptionYears)
    if (item.fromdate) {
      var formattedfromdate = format(item.fromdate, "yyyy")
      setFromDate(formattedfromdate);
      setFromDateYear(formattedfromdate)
    } else {
      setFromDate("");
    }
    if (item.todate) {
      var formattedToDate = format(item.todate, "yyyy")
      setToDate(formattedToDate);
      setToDateYear(formattedToDate)
    }

    if (item.fromdate && item.todate) {
      const diffYears = moment(item.todate).diff(moment(item.fromdate), 'years');


      if (diffYears > 0) {
        setYearsDiff(diffYears)
      } else {
        setYearsDiff("")
      }
    }

    const result = await GetSocialHistoryTypes({
      type: "socialhistorytype",
    });
    if (result) {
      // Iterate through the options and create an array of formatted strings
      const formattedOptions = result.output.data[0].data.map(option => (
        [option.condition]
      ));

      setTypeData(formattedOptions);
    }
    setSocialTypeData(result.output.data[0].data);

    editsocialhistory.current.openRight();
  }
  const handleDuration = (e) => {
    const inputText = e.target.value;
    const maxLength = 3;

    if (inputText.length <= maxLength) {
      setDuration(inputText);
    }
  }
  const handleFrequency = (e) => {
    let inputText = e.target.value;
    const maxLength = 3;
    const regex = /^\d+$/;

    inputText = inputText.replace(/\./g, '');
    if (inputText === '' || regex.test(inputText)) {
      if (inputText.length <= maxLength) {
        if (inputText.startsWith(0)) {
          inputText = inputText.substring(1);
        }
        setFrequency(inputText);
      }
    }
  }
  const [calculatedConsumption, setCalculatedConsumption] = useState("")
  const [calculatedYearsForEdit, setcalculatedYearsForEdit] = useState("")
  useEffect(() => {
    //var consumptionPreYear = (frequency / 7) * 365;
    //
    if (yearsDiff) {
      var consumptionPreYear = (frequency / 7) * yearsDiff;
      //var consumptionByYears = consumptionPreYear ;
      const finalCalculation = consumptionPreYear.toFixed(2);

      if (finalCalculation > 0) {

        setCalculatedConsumption(`${text} per year : ` + finalCalculation)
      } else {
        setCalculatedConsumption("")
      }

    }
  }, [frequency])

  // const handleYesSelected = () =>{
  //   setShowDateSelection(!showDateSelection)
  // }
  const [neverSelected, setNeverSelected] = useState(false);
  const handleYesSelected = (value) => {

    if (value == "yes") {
      setRadioValue("yes")
      setShowQuitCheckBox(false)
      setShowDateSelection(true)
      setShowIsCurentCheckBox(true)

      const currentYear = new Date().getFullYear();

      var janOne = "-01-01"
      var fullDate = currentYear + janOne
      setToDate(fullDate)
      setCurrent(1)
      setNeverSelected(false)
      setFrequencyTimeID("327")

      if (type == "Smokeless") {
        setSmokeLessSelected(true)
      }
      if (type == "Illicit Drugs") {
        setDrugsSelected(true)
      }
    } else if (value == "currentlyNot") {

      setRadioValue("currentlyNot")
      setShowQuitCheckBox(true)
      setShowDateSelection(true)
      // setFromDate("")
      // setFromDateYear("")
      const currentYear = new Date().getFullYear();
      var janOne = "-01-01"
      var fullDate = currentYear + janOne
      // setToDate(fullDate)
      setToDate("")

      setNeverSelected(false)
      setShowIsCurentCheckBox(false)
      setCurrent(2)

      setFrequencyTimeID("327")
      if (type == "Smokeless") {
        setSmokeLessSelected(true)
      }
      if (type == "Illicit Drugs") {
        setDrugsSelected(true)
      }
    } else if (value == "never") {
      setNeverSelected(true)
      setRadioValue("never")
      setShowDateSelection(false)
      setShowQuitCheckBox(false)
      setCurrent(0)
      setShowIsCurentCheckBox(false)
      setYearsDiff('')
      setToDate("")

      setFrequency("")
      setFrequencyTimeID("")
      setFromDateYear("")
      setFromDate("")

      setCalculatedConsumption("")
      setSmokeLessSelected(false)

      setDrugsSelected(false)
      setDrugType(null)
      setSendDrugTypeInInput(false)
      //here
      setSendSmoklessInInput(true)
      setSmokelessType(null)
      if (type == "Illicit Drugs") {
        setDrugsSelected(false)
        // setSendDrugTypeInInput(false)
        setDrugType(null)
        setReasonsArray([])
      }
    }
  };
  const handleSmokelessType = (value) => {

    if (value == "Chew") {
      setSmokelessType("chew")
    } else if (value == "Snuff") {
      setSmokelessType("snuff")
    }
  }
  const handleDrugTypeType = (value) => {

    if (value == "Smoke") {
      setDrugType("smoke")
    } else if (value == "Snort") {
      setDrugType("snort")
    } else if (value == "ivDrug") {
      setDrugType("ivDrug")
    }
  }
  const handleFromDateChange = (selecteddate) => {
    const formattedDate = moment(selecteddate).format('YYYY-MM-DD');
    setFromDate(formattedDate);
  };
  const handleFromYearChange = (selecteddate) => {
    setFromDateYear(selecteddate)

    var janOne = "-01-01"
    var yearWithAddedDayAndMonth = selecteddate + janOne;
    setFromDate(yearWithAddedDayAndMonth);
  };

  const handleToDateChange = (selecteddate) => {
    const formattedDate = moment(selecteddate).format('YYYY-MM-DD');
    setToDate(formattedDate);
  };
  const handleToYearChange = (selecteddate) => {
    setToDateYear(selecteddate)

    var janOne = "-01-01"
    var yearWithAddedDayAndMonth = selecteddate + janOne;
    setToDate(yearWithAddedDayAndMonth);
  };
  const [showYearsDiffTag, setShowYearsDiffTag] = useState(false)
  useEffect(() => {
    if (fromdate && todate) {

      const diffYears = moment(todate).diff(moment(fromdate), 'years');


      if (diffYears > 0) {
        setcalculatedYearsForEdit("Consumption Years : " + diffYears)
        setYearsDiff(diffYears)
        // not showing the "Consuming Years:3years" tag in Add so commenting below line :
        // setShowYearsDiffTag(true)
      } else {

        setYearsDiff("")
        setShowYearsDiffTag(false)
      }
    }
  }, [todate, fromdate])
  const closeDrugsModal = () => {
    addCondition3.current.closeRight();
  };
  const openDrugListModal = () => {
    getDrugList();
    //here 
    setSearchValue("")
    addCondition3.current.openRight()
  };

  useEffect(() => {
    if (reasonsArray.length === 0) {
      setReactionsContinueBtnDisabled(true);
    } else {
      setReactionsContinueBtnDisabled(false);

    }
  }, [reasonsArray]);

  const handleReactionCheckboxChange = (condition) => {
    const isSelected = reasonsArray.some(
      (item) =>
        item.condition === condition.condition
    );

    if (isSelected) {
      // If already selected, remove the item from reasonsArray
      setReasonsArray((prevConditions) =>
        prevConditions.filter(
          (item) =>
            item.condition !== condition.condition
        )
      );
    } else {
      // If not selected, add the item to reasonsArray
      setReasonsArray([...reasonsArray, { ...condition }]);
    }
  };
  const removeTag = (index) => {
    const updatedReasonsArray = [...reasonsArray];
    updatedReasonsArray.splice(index, 1);
    setReasonsArray(updatedReasonsArray);
  };

  const css = `
  .socialBtn {
    right: 0;
  }
  `
  return (
    <>
      <style>{css}</style>
      <Box w="100%" display={{ base: "none", lg: "block" }}>
        <Box top="0" zIndex="banner">
          <Navbar />
          {isLoading && <Loader />}
          <SecondaryNav
            defaultHeader="My Health"
            defaultSecondayHeader="Social History"
            defaultNavBody={

              <>
                <Box display="flex" gap="10px" alignItems="center" pr="25px">
                  {/* <TabSection defaultIndex={activeTab} tabs={["Current", "Past"]} onClickTab={handleTabClick} /> */}
                </Box>
                {data && data != null && (
                  <Box display="flex" gap="10px" alignItems="center" pr="25px">
                    <PrimaryButtonIcon
                      fontSize="14px"
                      // variant="mdBtn"
                      btnStyle={{ color: "white", bg: COLORS.PRIMARY_GRADIENT, h: "40px", _hover: { bg: COLORS.PRIMARY_GRADIENT } }}
                      buttonText="Add Social History"
                      iconImg="socialBtn"
                      onClick={OpenAddSocialHistory}
                      iconSrc="/assets/imgs/add.png"
                    />
                  </Box>
                )}
              </>



            }
          />
        </Box>
        <Box h="calc(100vh - 140px)" overflow="hidden auto">
          <Flex>
            <Box position="sticky" top="0" zIndex="sticky" h="100vh">
              <SideBar />
            </Box>
            <Box w="100%">
              {data && data != null ? (
                <Flex wrap="wrap" p="24px 32px" w="100%" justifyContent="left" alignContent="flex-start" gap="20px" height="100%" overflow="hidden auto">
                  {/* {data.map((step, index) => (
                    
                    <Box key={index} flex="0 0 calc(33.333% - 20px)" mb="20px">
                      <ReportViewCard
                        cardHeight="125px"
                        cardHeader={step.socialhistory}
                        cardSecondary={step.fromdate + ' ' + step.todate}
                        cardText={step.frequency + 'x Per ' + step.frequencytype}
                        cardBtn={false}
                        deleteBtn={false}
                        editBtn={() => handleeditsocialhistory(step)}
                        editDeleteBtn={() => deletesocialhistorymodel(step.id, step.socialhistory)}

                      />

                    </Box>
                  ))} */}
                  {data.map((step, index) => {
                    // Calculate the difference in years
                    const fromDate = new Date(step.fromdate);
                    const toDate = new Date(step.todate);
                    const diffInYears = toDate.getFullYear() - fromDate.getFullYear();

                    let consumptionYears = diffInYears > 0 ? `Consumption years : ${diffInYears} ` : "Consumption years : Less than a year";

                    return (
                      <Box key={index} flex="0 0 calc(33.333% - 20px)" mb="20px">
                        <ReportViewCard
                          cardStyle={{ h: "205px" }}
                          cardHeader={step.socialhistory}
                          cardSecondary={

                            <>
                              {/*  showing consumption years on main page only for somking ,*/}
                              {/* {step.socialhistory == "Smoking" && (
                               diffInYears > 0 ? (
                                <> */}
                              <Text fontWeight="bold">
                                {"Status: "}
                                <span style={{ fontWeight: "500" }}>
                                  {step.iscurrent == 1 ? "Current" :
                                    step.iscurrent == 0 ? "Never" :
                                      step.iscurrent == 2 ? "Former" : ""}</span>
                              </Text>
                              {/* </>
                              ) : (
                                <p>Less than a year</p>
                              ) */}
                              {/* ) */}
                              {/* } */}
                            </>
                          } // Show the difference in years
                          cardTextStyle={{ mt: "3.5px" }}
                          cardText={
                            <>
                              {step.smokelesstype ? capitalizeFirstLetter(step.smokelesstype) : ""}
                              {step.drugtype ? capitalizeFirstLetter(step.drugtype) : ""}
                              <br />
                              {step.frequency && step.frequency !== 0 ? `${step.frequency} 
                            
                            ${step.socialhistory && step.socialhistory == "Smoking" ? "Packs" :
                                  step.socialhistory == "Alcohol" ? "Drinks" :
                                    step.socialhistory == "Smokeless" ? "Cans" :
                                      step.socialhistory == "Illicit Drugs" ? "Times"
                                        : "Packs"} 
                            Per ${step.frequencytype}   
                            x ${diffInYears == 1 ? diffInYears + " year" :
                                  diffInYears > 1 ? diffInYears + " years" :
                                    "less than a year"}  
                           `


                                : null}
                              {step.iscurrent == 2 && (
                                <>
                                  {` Until ${step.todate ? formatDate_yyyy(step.todate) : ""}`}
                                </>
                              )}
                            </>}
                          //cardText={step.frequency && step.frequency !== 0 ? `${step.frequency}x Per ${step.frequencytype}` : null}
                          cardBtn=
                          {step.socialhistory == "Illicit Drugs" ?
                            <div style={{ display: "inline-block", maxHeight: "40px", overflow: 'hidden' }}>
                              {step.drugnames && step.drugnames.map((item, index) => (
                                <span key={index} title={step.drugnames.map(item => item.name).join(", ")}>
                                  {item.name}
                                  {index < step.drugnames.length - 1 && ", "}
                                </span>
                              ))}
                            </div>
                            :
                            <div style={{ display: "inline-block", maxHeight: "40px", overflow: 'hidden' }}>

                            </div>
                          }
                          BtnRightIcon={{ display: "none" }}
                          deleteBtn={false}
                          editBtn={() => handleeditsocialhistory(step, consumptionYears)}
                          editDeleteBtn={() => deletesocialhistorymodel(step.id, step.socialhistory)}
                        />
                      </Box>
                    );
                  })}
                </Flex>

              ) : (

                <Box w="100%" mt="-100px" display="flex" flexDirection="column" gap="5px" justifyContent="center" alignItems="center" h="100%">
                  <Image w="60px" src="/assets/svgs/social-history-icon.svg" />
                  <Box as="p">No data available</Box>
                  <PrimaryButton fontSize="14px" variant="mdBtn" buttonText="Add Social History" btnIcon="/assets/imgs/add.png" onClick={OpenAddSocialHistory} />
                </Box>
              )}
            </Box>
          </Flex>
          <Footer />
        </Box>
        <ModalRight
          ref={addsocialhistory}
          bodyStyle={{ p: "24px 32px" }}
          modalWidth={"360px"}
          header="Add Social History"
          body={
            <>
              <Box display="flex" flexDir="column">
                <DropdownInput
                  variant="defaultSide" dropDownPlace="Type*"
                  options={typedata}
                  onClick={handleTypeInputChange} />
                {smokingSelected &&
                  <RadioGroup onChange={handleYesSelected} value={radioValue}>
                    <Box display="flex" justifyContent="space-between" py="20px" >
                      <Radio value="yes" colorScheme="green" >
                        Current
                      </Radio>

                      <Radio value="currentlyNot" colorScheme="green">
                        Former
                      </Radio>

                      <Radio value="never" colorScheme="green">
                        Never
                      </Radio>
                    </Box>
                  </RadioGroup>
                }

                {
                  showDateSelection &&
                  <>
                    {
                      smokeLessSelected &&
                      <>
                        <Divider />
                        <RadioGroup
                          onChange={handleSmokelessType}
                        >
                          <Box display="flex" justifyContent="start" gap="15px" py="20px">
                            <Radio value="Chew" colorScheme="green">
                              Chew
                            </Radio>

                            <Radio value="Snuff" colorScheme="green">
                              Snuff
                            </Radio>

                          </Box>
                        </RadioGroup>
                      </>
                    }
                    {
                      drugsSelected &&
                      <>
                        <Divider />
                        <RadioGroup
                          onChange={handleDrugTypeType}
                        >
                          <Box display="flex" justifyContent="space-around" py="20px">
                            <Radio value="Smoke" colorScheme="green">
                              Smoke
                            </Radio>

                            <Radio value="Snort" colorScheme="green">
                              Snort
                            </Radio>
                            <Radio value="ivDrug" colorScheme="green">
                              IV Drug use
                            </Radio>

                          </Box>
                        </RadioGroup>
                      </>
                    }
                    {
                      drugsSelected &&
                      <Box
                        as="div"
                        role="button"
                        aria-haspopup="dialog"
                        display="flex"
                        alignItems="center"
                        tabIndex={0}
                        border="1px solid #e2e8f0"
                        borderRadius="10px 10px 0 0"
                        p="10px"
                        height="auto"
                        width="100%"
                        sx={{
                          _hover: { border: "2px solid #384052" },
                        }}
                        // onClick={() => showReactionList(condition.condition_cd)}
                        onClick={openDrugListModal}
                        minHeight="48px"
                        flexWrap="wrap"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          gap="5px"
                          w="100%"
                          justifyContent={reasonsArray.length === 0 ? "space-between" : "start"}
                        >
                          {reasonsArray.length === 0 ? (
                            <Text pl="2" color="gray.400">
                              Drugs<sup>*</sup>
                            </Text>
                          ) : reasonsArray.some(
                            (item) => item.condition_cd === item.condition_cd
                          ) ? (
                            <Text pl="2" color="gray.400" sx={{ ...reasonsArray.length === 0 ? {} : floatLabel }} >Drugs<sup>*</sup></Text>
                          ) : (
                            <Text pl="2" color="gray.400">
                              Drugs<sup>*</sup>
                            </Text>
                          )}
                          {reasonsArray.length === 0 && (
                            <Image src="/assets/imgs/right-icon.png" />
                          )}
                          {reasonsArray

                            .map((tag, index) => (
                              <Box
                                key={index}
                                display="flex"
                                alignItems="center"
                                gap="5px"
                                bg="gray.100"
                                borderRadius="full"
                                p="1"
                              >
                                <Text fontSize="sm" px="5px">
                                  {tag.condition}
                                </Text>
                                <Box
                                  as="button"
                                  p="5px"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeTag(index);
                                  }}
                                  aria-label="Remove condition"
                                >
                                  <Image src="/assets/imgs/close.png" />
                                </Box>
                              </Box>
                            ))}

                        </Box>
                      </Box>
                    }
                    {/* <Box display="flex" flexDir="row">
                      <DatePicker
                        disableFutureDates={true}
                        // defaultDate={fromdate} 
                        onDateChange={(selectedDateValue) =>
                          handleFromDateChange(selectedDateValue)
                        }
                        placeholderProp={"Start Date"}
                        //calendarStlye={{transform: "scale(0.9)", left:"-15px", top: "30px"}}
                        calendarStlye={{ left: "-18px", right: "unset" }}
                      />
                      <DatePicker
                        disableFutureDates={true}
                        //defaultDate={todate} 
                        onDateChange={(selectedDateValue) =>
                          handleToDateChange(selectedDateValue)
                        }
                        placeholderProp={"End Date"}
                        calendarStlye={{ transform: "scale(0.9)", right: "-22px", top: "30px" }}
                      />
                    </Box> */}
                    <Box display="flex" flexDir="row">
                      <DropdownInput
                        variant="defaultSide" dropDownPlace="Start year*"
                        options={fromYearOptions}
                        onClick={(selectedDateValue) =>
                          handleFromYearChange(selectedDateValue)
                        } />
                      {!showIsCurentCheckBox &&
                        <DropdownInput
                          variant="defaultSide" dropDownPlace="End year*"
                          options={toYearOptions}
                          onClick={(selectedDateValue) =>
                            handleToYearChange(selectedDateValue)
                          } />
                      }

                    </Box>

                    {showYearsDiffTag &&
                      <Box my="2px" >
                        <p>
                          Consuming Years : {yearsDiff}</p>

                      </Box>
                    }

                    {!hideFrequency &&
                      <Box display="flex" flexDir="row">
                        <PrimaryInput variant="leftDown" inputPlace={`${text} per week*`}
                          onChange={handleFrequency} inputValue={frequency}
                        />
                        {/* <DropdownInput
                          variant="rightDown" dropDownPlace="Time Span" options={["Week"]}
                          defaultOption={"Week"}
                          onClick={handlefrequencyInputChange}
                        /> */}
                      </Box>
                    }
                    {showFrequencyCalculation &&
                      <Box my="2px" >

                        <p>{calculatedConsumption} </p>
                      </Box>
                    }

                    {showQuitCheckBox &&

                      <Box display="flex" justifyContent="start" py="20px">
                        <Checkbox
                          colorScheme="green"
                          sx={{
                            ".chakra-checkbox__control": {
                              width: "20px",
                              height: "20px",
                              borderRadius: "5px",
                            },
                          }}
                          defaultChecked={current === 2}
                          isDisabled={true}
                        >
                          I Quit
                        </Checkbox>

                      </Box>
                    }

                    {showIsCurentCheckBox &&
                      <Box display="flex" justifyContent="start" py="20px">
                        <Checkbox
                          colorScheme="green"
                          sx={{
                            ".chakra-checkbox__control": {
                              width: "20px",
                              height: "20px",
                              borderRadius: "5px",
                            },
                          }}
                          defaultChecked={current === 1}
                          isDisabled={true}
                        >
                          Current
                        </Checkbox>
                      </Box>

                    }

                  </>
                }
                {/* <Box display="flex" flexDir="row">
                    <PrimaryInput variant="bothSide" inputStyleCss={{w: "60%"}} inputType="number" inputPlace="Duration*"
                    onChange={handleDuration} inputValue={duration}/>
              <DropdownInput 
                    variant="bothSide" dropDownPlace="Time Span" inputStyleCss={{w: "40%"}} options={["Hour", "Day","Week","Month", "Year"]}
                    onClick={handledurationInputChange}/>
                    </Box>
                    <Box display="flex" flexDir="row">
                    <PrimaryInput variant="leftDown" inputType="number" inputStyleCss={{w: "60%"}} inputPlace="Frequency"
                    onChange={handleFrequency} inputValue={frequency}/>             
              <DropdownInput 
                    variant="rightDown" dropDownPlace="Time Span" inputStyleCss={{w: "40%"}} options={["Hour", "Day","Week","Month", "Year"]}
                    onClick={handlefrequencyInputChange}/>
                     </Box> */}
                {/* <Box display="flex" justifyContent="start" py="20px">
                  <Checkbox
                    colorScheme="green"
                    sx={{
                      ".chakra-checkbox__control": {
                        width: "20px",
                        height: "20px",
                        borderRadius: "5px",
                      },
                    }}
                    onChange={handleCheckboxChange}>
                    Current
                  </Checkbox>
                </Box> */}
                <Divider />
                {/* <Box as="button" display="flex" alignItems="center" gap="10px" py="20px" fontSize="14px" fontWeight="600" color="#1C4EC5" textAlign="left">
                    <Image w="11px" src="/assets/imgs/add-blue.png"/> Add social history</Box> */}
              </Box>
            </>
          }
          footerActions={<>
            <Box
              w="100%"
              display="flex"
              flexDirection="row"
              gap="15px">

              <PrimaryButton
                buttonText="Add Social History"
                onClick={handleaddsocialhistory}
                isDisabled={false}
              />
            </Box>
          </>}
        />
        <ModalRight
          ref={editsocialhistory}
          bodyStyle={{ p: "24px 32px" }}
          modalWidth={"360px"}
          header="Edit Social History"
          body={
            <>
              <Box display="flex" flexDir="column">
                <DropdownInput
                  variant="defaultSide" dropDownPlace="Type*"
                  options={typedata}
                  defaultOption={type}
                  onClick={handleTypeInputChange}

                  nonClickable={true} />
                {/* {smokingSelected && */}
                <RadioGroup onChange={handleYesSelected} value={current == "1" ? "yes" : current == "0" ? "never" : "currentlyNot"}>
                  <Box display="flex" justifyContent="space-between" py="20px" >
                    <Radio value="yes" colorScheme="green" >
                      Current
                    </Radio>

                    <Radio value="currentlyNot" colorScheme="green">
                      Former
                    </Radio>

                    <Radio value="never" colorScheme="green">
                      Never
                    </Radio>
                  </Box>
                </RadioGroup>
                {
                  smokeLessSelected &&
                  <>
                    <Divider />
                    <RadioGroup
                      onChange={handleSmokelessType}
                      value={smokelessType == "snuff" ? "Snuff" : smokelessType == "chew" ? "Chew" : ""}
                    >
                      <Box display="flex" justifyContent="start" gap="15px" py="20px">
                        <Radio value="Chew" colorScheme="green">
                          Chew
                        </Radio>

                        <Radio value="Snuff" colorScheme="green">
                          Snuff
                        </Radio>

                      </Box>
                    </RadioGroup>
                  </>
                }
                {
                  drugsSelected &&
                  <>
                    <Divider />
                    <RadioGroup
                      onChange={handleDrugTypeType}
                      value={drugType == "smoke" ? "Smoke" : drugType == "snort" ? "Snort" : drugType == "ivDrug" ? "ivDrug" : ""}

                    >
                      <Box display="flex" justifyContent="space-around" py="20px">
                        <Radio value="Smoke" colorScheme="green">
                          Smoke
                        </Radio>

                        <Radio value="Snort" colorScheme="green">
                          Snort
                        </Radio>
                        <Radio value="ivDrug" colorScheme="green">
                          IV Drug use
                        </Radio>

                      </Box>
                    </RadioGroup>
                  </>
                }

                {!neverSelected &&
                  <>
                    {
                      drugsSelected &&
                      <Box
                        as="div"
                        role="button"
                        aria-haspopup="dialog"
                        display="flex"
                        alignItems="center"
                        tabIndex={0}
                        border="1px solid #e2e8f0"
                        borderRadius="10px 10px 0 0"
                        p="10px"
                        height="auto"
                        width="100%"
                        sx={{
                          _hover: { border: "2px solid #384052" },
                        }}
                        onClick={openDrugListModal}
                        minHeight="48px"
                        flexWrap="wrap"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          gap="5px"
                          w="100%"
                          justifyContent={reasonsArray.length === 0 ? "space-between" : "start"}
                        >
                          {reasonsArray.length === 0 ? (
                            <Text pl="2" color="gray.400">
                              Drugs<sup>*</sup>
                            </Text>
                          ) : reasonsArray.some(
                            (item) => item.condition_cd === item.condition_cd
                          ) ? (
                            <Text pl="2" color="gray.400" sx={{ ...reasonsArray.length === 0 ? {} : floatLabel }} >Drugs<sup>*</sup></Text>
                          ) : (
                            <Text pl="2" color="gray.400">
                              Drugs<sup>*</sup>
                            </Text>
                          )}
                          {reasonsArray.length === 0 && (
                            <Image src="/assets/imgs/right-icon.png" />
                          )}
                          {reasonsArray

                            .map((tag, index) => (
                              <Box
                                key={index}
                                display="flex"
                                alignItems="center"
                                gap="5px"
                                bg="gray.100"
                                borderRadius="full"
                                p="1"
                              >
                                <Text fontSize="sm" px="5px">
                                  {tag.condition}
                                </Text>
                                <Box
                                  as="button"
                                  p="5px"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeTag(index);
                                  }}
                                  aria-label="Remove condition"
                                >
                                  <Image src="/assets/imgs/close.png" />
                                </Box>
                              </Box>
                            ))}

                        </Box>
                      </Box>
                    }
                    {/* <Box display="flex" flexDir="row">
                  <PrimaryInput variant="bothSide" defaultValue={duration} inputStyleCss={{ w: "60%" }} inputType="text" inputPlace="Duration*"
                    onChange={handleDuration} inputValue={duration} />
                  <DropdownInput
                    variant="bothSide" defaultOption={durationtimespan} dropDownPlace="Time Span" inputStyleCss={{ w: "40%" }} options={["Hour", "Day", "Week", "Month", "Year"]}
                    onClick={handledurationInputChange} />
                </Box> */}


                    <Box display="flex" flexDir="row">
                      <DropdownInput

                        defaultOption={fromdateYear}
                        variant="defaultSide" dropDownPlace="Start year*"
                        options={fromYearOptions}
                        onClick={(selectedDateValue) =>
                          handleFromYearChange(selectedDateValue)
                        } />

                      {!showIsCurentCheckBox &&
                        <DropdownInput

                          defaultOption={toDateYear}
                          variant="defaultSide" dropDownPlace="End year*"
                          options={toYearOptions}
                          onClick={(selectedDateValue) =>
                            handleToYearChange(selectedDateValue)
                          } />
                      }
                    </Box>
                    {/*  not showing Consumption years in Edit as well , so commented below code -
                    <Box my="2px" >

                      <p>{calculatedYearsForEdit} </p>
                    </Box> */}
                    {showFrequencyInEditModal &&
                      <Box display="flex" flexDir="row">
                        <PrimaryInput variant="leftDown" defaultValue={frequency} inputPlace={`${text} per week*`}
                          onChange={handleFrequency} inputValue={frequency} />
                        {/* <DropdownInput
                          variant="rightDown" defaultOption={frequencytimespan} dropDownPlace="Time Span" inputStyleCss={{ w: "40%" }} options={["Week"]}
                          onClick={handlefrequencyInputChange} /> */}
                      </Box>
                    }

                    {showFrequencyCalculation &&
                      <Box my="2px" >

                        <p>{calculatedConsumption} </p>
                      </Box>
                    }
                  </>
                }

                {showQuitCheckBox &&
                  <Box display="flex" justifyContent="start" py="20px">
                    <Checkbox
                      colorScheme="green"
                      sx={{
                        ".chakra-checkbox__control": {
                          width: "20px",
                          height: "20px",
                          borderRadius: "5px",
                        },
                      }}
                      defaultChecked={current === 2}
                      isDisabled={true}
                      onChange={handleCheckboxChangeQuit}>
                      I Quit
                    </Checkbox>
                  </Box>
                }

                {showIsCurentCheckBox &&
                  <Box display="flex" justifyContent="start" py="20px">
                    <Checkbox
                      colorScheme="green"
                      sx={{
                        ".chakra-checkbox__control": {
                          width: "20px",
                          height: "20px",
                          borderRadius: "5px",
                        },
                      }}
                      defaultChecked={current === 1}
                      isDisabled={true}

                      onChange={handleCheckboxChange}>
                      Current
                    </Checkbox>
                  </Box>

                }

                <Divider />
                {/* <Box as="button" display="flex" alignItems="center" gap="10px" py="20px" fontSize="14px" fontWeight="600" color="#1C4EC5" textAlign="left">
                    <Image w="11px" src="/assets/imgs/add-blue.png"/> Add social history</Box> */}
              </Box>
            </>
          }
          footerActions={<>
            <Box
              w="100%"
              display="flex"
              flexDirection="row"
              gap="15px">

              <PrimaryButton
                buttonText="Update Social History"
                onClick={Editsocialhistory}
                isDisabled={false}
              />
            </Box>
          </>}
        />
        <DynamicModal
          ref={dynamicModal}
          modalHeader="Delete Social History?"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete. <br />
                {title ? title : "-"}
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                onClick={deletesocialhistory}
              />
            </>
          }
        />
      </Box>

      {/* Mobile responsive start */}
      <Box w="100%" bg="#fcfcfd" display={{ base: "block", lg: "none" }}>
        {isLoading && <Loader />}
        <MobileNavbar />
        <MobileSecondaryNav
          handlebackarrow={() => navigate('/myhealth')}
          header="Social History"
        // bottomArea={<>
        //   <Box display="flex" gap="10px" justifyContent="center" alignItems="center" w="100%">
        //     <TabSection defaultIndex={activeTab} tabs={["Current", "Past"]} onClickTab={handleTabClick} />
        //   </Box>
        // </>
        // }
        />

        {data && data !== null && (
          <Box pos="fixed" bottom="10%" display="flex" justifyContent="center" w="100%" zIndex="99">
            <PrimaryButton
              fontSize="14px"
              variant="mdBtn"
              buttonText="Add Social History"
              btnIcon="/assets/imgs/add.png"
              onClick={OpenAddSocialHistory}
            />
          </Box>
        )}

        <Box w="100%" p="1.5rem" pb="5rem" h='calc(100vh - 270px)' overflow="hidden auto">
          {data && data != null ? (
            <SimpleGrid
              gap="20px"
              columns={1}
            >
              {data.map((step, index) => {
                // Calculate the difference in years
                const fromDate = new Date(step.fromdate);
                const toDate = new Date(step.todate);
                const diffInYears = toDate.getFullYear() - fromDate.getFullYear();

                let consumptionYears = diffInYears > 0 ? `Consumption years : ${diffInYears} ` : "Consumption years : Less than a year";

                return (
                  <Box key={index} flex="0 0 calc(33.333% - 20px)" mb="20px">
                    <ReportViewCard
                      cardStyle={{ h: "205px" }}
                      cardHeader={step.socialhistory}
                      cardSecondary={

                        <>
                          {/*  showing consumption years on main page only for somking ,*/}
                          {/* {step.socialhistory == "Smoking" && (
                               diffInYears > 0 ? (
                                <> */}
                          <Text fontWeight="bold">
                            {"Status: "}
                            <span style={{ fontWeight: "500" }}>
                              {step.iscurrent == 1 ? "Current" :
                                step.iscurrent == 0 ? "Never" :
                                  step.iscurrent == 2 ? "Former" : ""}</span>
                          </Text>
                          {/* </>
                              ) : (
                                <p>Less than a year</p>
                              ) */}
                          {/* ) */}
                          {/* } */}
                        </>
                      } // Show the difference in years
                      cardTextStyle={{ mt: "3.5px" }}
                      cardText={
                        <>
                          {step.smokelesstype ? capitalizeFirstLetter(step.smokelesstype) : ""}
                          {step.drugtype ? capitalizeFirstLetter(step.drugtype) : ""}
                          <br />
                          {step.frequency && step.frequency !== 0 ? `${step.frequency} 
                            
                            ${step.socialhistory && step.socialhistory == "Smoking" ? "Packs" :
                              step.socialhistory == "Alcohol" ? "Drinks" :
                                step.socialhistory == "Smokeless" ? "Cans" :
                                  step.socialhistory == "Illicit Drugs" ? "Times"
                                    : "Packs"} 
                            Per ${step.frequencytype}   
                            x ${diffInYears == 1 ? diffInYears + " year" :
                              diffInYears > 1 ? diffInYears + " years" :
                                "less than a year"}  
                           `


                            : null}
                          {step.iscurrent == 2 && (
                            <>
                              {` Until ${step.todate ? formatDate_yyyy(step.todate) : ""}`}
                            </>
                          )}
                        </>}
                      //cardText={step.frequency && step.frequency !== 0 ? `${step.frequency}x Per ${step.frequencytype}` : null}
                      cardBtn=
                      {step.socialhistory == "Illicit Drugs" ?
                        <div style={{ display: "inline-block", maxHeight: "40px", overflow: 'hidden' }}>
                          {step.drugnames && step.drugnames.map((item, index) => (
                            <span key={index} title={step.drugnames.map(item => item.name).join(", ")}>
                              {item.name}
                              {index < step.drugnames.length - 1 && ", "}
                            </span>
                          ))}
                        </div>
                        :
                        <div style={{ display: "inline-block", maxHeight: "40px", overflow: 'hidden' }}>

                        </div>
                      }
                      BtnRightIcon={{ display: "none" }}
                      deleteBtn={false}
                      editBtn={() => handleeditsocialhistory(step, consumptionYears)}
                      editDeleteBtn={() => deletesocialhistorymodel(step.id, step.socialhistory)}
                    />
                  </Box>
                );
              })}
            </SimpleGrid>
          ) : (
            <Box
              w="100%"
              mt="-100px"
              display="flex"
              flexDirection="column"
              gap="5px"
              justifyContent="center"
              alignItems="center"
              h='calc(100vh - 270px)'
            >
              <Image w="60px" src="/assets/svgs/medication-nodata.svg" />
              <Box as="p">No data available</Box>
              <PrimaryButton
                fontSize="14px"
                variant="mdBtn"
                buttonText="Add Social history"
                btnIcon="/assets/imgs/add.png"
                onClick={OpenAddSocialHistory}
              />
            </Box>
          )}
        </Box>
      </Box>

      <ModalRight
        ref={addCondition3}
        handlebackarrow={() => addCondition3.current.closeRight()}
        withBackBtn={true}
        withBackHeader="Drugs"
        body={
          <>
            <SearchBar
              passInputPlace="Search Drugs"
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <RadioGroup>
              <Stack direction="column" gap="0">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  borderBottom="1px solid #e2e8f0"
                  py="15px"
                >
                  {/* <Box display="flex" justifyContent="end" w="100%">
                            <Stack direction="column" gap="0" w="100%">
                              {reactionList && reactionList.length > 0 ? (
                                    reactionList.map((condition) => (
                                      <label key={condition.condition_cd} >

                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          borderBottom="1px solid #e2e8f0"
                                          p="15px"
                                          height="50px"
                                          width="100%"
                                          sx={{
                                            _hover: {
                                              border: "2px solid #384052",
                                              borderRadius: "10px",
                                            },
                                          }}
                                        >
                                          <Box
                                            key={condition.condition_cd}
                                            display="flex"
                                            alignItems="center"
                                          >
                                            <Text fontSize="16px" fontWeight="600">
                                              {condition.condition}
                                              <br />
                                              <Box
                                                as="span"
                                                fontSize="12px"
                                                fontWeight="500"
                                                color="#6A707D"
                                              ></Box>
                                            </Text>
                                          </Box>
                                          <Box display="flex" justifyContent="end">
                                            <Checkbox
                                              colorScheme="green"
                                              sx={{
                                                ".chakra-checkbox__control": {
                                                  width: "20px",
                                                  height: "20px",
                                                  borderRadius: "5px",
                                                },
                                              }}
                                              onChange={() =>
                                                handleReactionCheckboxChange(
                                                  condition
                                                )
                                              }
                                              isChecked={reasonsArray.some(
                                                (item) =>
                                                  item.condition_cd ===
                                                  condition.condition_cd
                                              )}
                                            ></Checkbox>
                                          </Box>
                                        </Box>

                                      </label>
                                    ))
                                
                              ) : (
                                <p>Loading...</p>
                              )}
                            </Stack>
                          </Box> */}
                  <Box display="flex" justifyContent="end" w="100%">
                    <Stack direction="column" gap="0" w="100%">
                      {reactionList && reactionList.length > 0 ? (
                        reactionList
                          .flatMap((category) => category)
                          .filter((condition) =>
                            condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                          ).length === 0 ? (
                          <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
                            No matching drugs found!
                          </Box>
                        ) : (
                          reactionList
                            .flatMap((category) => category)
                            .filter((condition) =>
                              condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                            )
                            .map((condition) => (
                              <label key={condition.condition_cd}>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  borderBottom="1px solid #e2e8f0"
                                  p="15px"
                                  height="50px"
                                  width="100%"
                                  sx={{
                                    _hover: {
                                      border: "2px solid #384052",
                                      borderRadius: "10px",
                                    },
                                  }}
                                >
                                  <Box key={condition.condition_cd} display="flex" alignItems="center">
                                    <Text fontSize="16px" fontWeight="600">
                                      {condition.condition}
                                      <br />
                                      <Box
                                        as="span"
                                        fontSize="12px"
                                        fontWeight="500"
                                        color="#6A707D"
                                      ></Box>
                                    </Text>
                                  </Box>
                                  <Box display="flex" justifyContent="end">
                                    <Checkbox
                                      colorScheme="green"
                                      sx={{
                                        ".chakra-checkbox__control": {
                                          width: "20px",
                                          height: "20px",
                                          borderRadius: "5px",
                                        },
                                      }}
                                      onChange={() => handleReactionCheckboxChange(condition)}
                                      isChecked={reasonsArray.some(
                                        (item) => item.condition === condition.condition
                                      )}
                                    ></Checkbox>
                                  </Box>
                                </Box>
                              </label>
                            ))
                        )
                      ) : (
                        <p>Loading...</p>
                      )}
                    </Stack>

                  </Box>



                </Box>
              </Stack>
            </RadioGroup>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Add Drugs"
                isDisabled={reactionsContinueBtnDisabled}
                onClick={() => closeDrugsModal()}
              />
            </Box>
          </>
        }
      />
    </>
  );
};

export default SocialHistoryIndex;
