import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Box,
  Image,
  Divider,
} from "@chakra-ui/react";

function ReportViewCard({
  bodyStyle,
  cardHeader = true,
  cardSecondary,
  cardBtn = true,
  onClick,
  deleteBtn = true,
  cardText,
  cardHeight = "150px",
  editBtn = false,
  editDeleteBtn = false,
  withBadge = false,
  withBadgeHeader,
  withBadgeSecondary,
  BtnRightIcon,
  hasFooter,
  footerOnClick,
  cardFooterText,
  cardFooterClickEvent,
  cardBody,
  withBadgeStyle,
  cardTextStyle,
  maxCardHeight,
  maxCardWidth,
  headerClick,
  disableBtn = false,
  cardStyle
}) {
  return (
    <Card
      borderRadius="12px"
      minH={cardHeight} 
      maxH={maxCardHeight} 
      w="100%"
      maxW={maxCardWidth}
      border="1px solid #E6E7E9"
      boxShadow="0px 8px 20px 0px #E6E7E966"
      {...cardStyle}
    >
      <CardBody {...bodyStyle} p={{base: "0.875rem",lg: "0.875rem", xl: "1.25rem"}}>
        <Box
          pb="10px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {withBadge && (
            <Box as="p" fontSize="10px" color="#384052" bg="#E6E7E966" borderRadius="full" border="1px solid #E6E7E9" p="5px 10px ">
              {withBadge}
            </Box>
          )}
          {cardHeader && (
          <Box  cursor="pointer" onClick={headerClick} display="flex" alignItems="center">
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Text fontSize="15px" title={cardHeader} fontWeight="600" className="textOverflow" maxW={{base: "180px", xl:"220px"}}>
                {cardHeader}
              </Text>
              <Text fontSize="12px" fontWeight="500" color="#384052">
                {cardSecondary}
              </Text>
            </Box>
          </Box>
          )}
          {deleteBtn && (
            <Box
              as="button"
              onClick={deleteBtn}
              border="1px solid #E6E7E9"
              borderRadius="full"
              w="36px"
              h="auto"
              p="10px"
            >
              <Image w="13px" src="/assets/imgs/delete.png" />
            </Box>
          )}

          {editBtn && (
            <>
            <Box display="flex" gap="10px">
                <Box
                  as="button"
                  onClick={editBtn}
                  border="1px solid #E6E7E9"
                  borderRadius="full"
                  w="36px"
                  h="auto"
                  p="10px"
                >
                  <Image w="13px" src="/assets/imgs/edit-gray.png" />
                </Box>

                <Box
                  as="button"
                  onClick={editDeleteBtn}
                  border="1px solid #E6E7E9"
                  borderRadius="full"
                  w="36px"
                  h="auto"
                  p="10px"
                >
                  <Image w="13px" src="/assets/imgs/delete.png" />
                </Box>
              </Box>
            </>
          )}
        </Box>
        {withBadge && (
          <Box display="flex" alignItems="center" pb="10px" {...withBadgeStyle}>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Text fontSize="15px" fontWeight="600">
                {withBadgeHeader}
              </Text>
              <Text fontSize="12px" fontWeight="500" color="#384052">
                {withBadgeSecondary}
              </Text>
            </Box>
          </Box>
          )}
          {!cardBody && (
            <Divider />
          )}
          {cardBody}
        {cardText && (
          <Box as="p" fontSize="14px" title={cardText} fontWeight="500" mt="15px" {...cardTextStyle}>
            {cardText}
          </Box>
        )}

      {cardBtn && (
        <Box
          as="button"
          onClick={onClick}
          w="100%"
          border="1px solid #E6E7E9"
          p="10px"
          borderRadius="10px"
          mt="15px"
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent="space-between">
          <Box
            display="flex"
            fontSize="12px"
            flexDirection="column"
            justifyContent="center"
            fontWeight="500"
          >
            {cardBtn}
          </Box>
          <Box borderRadius="full" p="10px">
            <Image w="8px" {...BtnRightIcon} src="/assets/imgs/right-icon.png" />
          </Box>
        </Box>
        )}
      </CardBody>

      {hasFooter && (
          !disableBtn ? (
            <CardFooter h={{lg: "35px", xl: "50px"}}  display="flex" flexDirection="row" alignItems="center" justifyContent="center" p="15px" borderTop="1px solid #E6E7E9" onClick={cardFooterClickEvent}>
            <Box as="button" onClick={footerOnClick} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
             <Box color="#1C4EC5" mr="8px" fontSize={{lg: "12px", xl: "14px"}} fontWeight="600">{cardFooterText}</Box>
          
            <Box><Image src="/assets/imgs/right-blue-arrow.png" /></Box>
            
           </Box>
           </CardFooter>
           ):(
            <CardFooter h={{lg: "35px", xl: "50px"}}  display="flex" flexDirection="row" alignItems="center" justifyContent="center" p="15px" borderTop="1px solid #E6E7E9" >
            <Box  as="button" display="flex" flexDirection="row" alignItems="center" justifyContent="center">
             <Box color="#b6bed1" mr="8px" fontSize={{lg: "12px", xl: "14px"}} fontWeight="600">{cardFooterText}</Box>
        
            <Box></Box>
            
           </Box>
          </CardFooter>
           )

        )}
    </Card>
  );
}

export default ReportViewCard;
