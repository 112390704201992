import formAPIdoctorsassessment from "./doctorsassessment";


var formattedOutput = null;

const formAPIModeldoctorsassessment = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIdoctorsassessment(item));


  return formattedOutput
};

export default formAPIModeldoctorsassessment