import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  VStack,
  HStack,
  Text,
  RadioGroup,
  Radio,
  Stack,
  Checkbox,
  SimpleGrid,
  GridItem,
  Select,
  Divider,
  InputRightElement,
  InputGroup,
  Grid,
  Input,
  Image
} from "@chakra-ui/react";
import MiniDropdownInput from "../../components/mini-dropdown";
import { PrimaryButton, Toaster } from "../../components";
import { updateHPI } from "../../middleware/services/appointments";


const AddEditHPI = ({ 
  patientId,
  doctorId,
  appointmentId,
  type,
  siteOptions,
  onsetOptions,
  chronicityOptions,
  onsetQualityOptions,
  frequencyOptions,
  associatedSymptomsOptions,
  fetchDoctorNotes,
  modalRef,
  editHPIData,
  isEditMode,
 }) => {
  const [selectedSites, setSelectedSites] = useState([]);
  const [rating, setRating] = useState(5);
  const [onsetDate, setOnsetDate] = useState("");
  const [chronicity, setChronicity] = useState("");
  const [onsetQuality, setOnsetQuality] = useState("");
  const [frequency, setFrequency] = useState("");
  const [selectedSymptoms, setAssociatedSymptoms] = useState([]);
  const [timePeriod, setTimePeriod] = useState("Hours");
  const [timeValue, setTimeValue] = useState("");
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const { showSuccessToast, showErrorToast } = Toaster();
  const noneSymptom = "None";


  useEffect(() => {
    if (isEditMode && editHPIData) {
      setSelectedSites(
        editHPIData.details
          .filter((detail) => detail.attribute === "Site")
          .flatMap((detail) => detail.values.map((v) => v.value))
      );
      setOnsetDate(
        editHPIData.details.find((detail) => detail.attribute === "Onset")
          ?.value || ""
      );
      setChronicity(
        editHPIData.details.find((detail) => detail.attribute === "Chronicity")
          ?.value || ""
      );
      setOnsetQuality(
        editHPIData.details.find(
          (detail) => detail.attribute === "Onset Quality"
        )?.value || ""
      );
      setFrequency(
        editHPIData.details.find((detail) => detail.attribute === "Frequency")
          ?.value || ""
      );
      setAssociatedSymptoms(
        editHPIData.details
          .find((detail) => detail.attribute === "Associated Symptoms")
          ?.values.map((v) => v.value) || []
      );
      setRating(
        editHPIData.details.find(
          (detail) => detail.attribute === "Rating Scale"
        )?.value || 5
      );
      setTimePeriod(
        editHPIData.details.find((detail) => detail.attribute === "Time")
          ?.value || "Hours"
      );
      setTimeValue(
        editHPIData.details.find((detail) => detail.attribute === "Time Value")
          ?.value || ""
      );
      setIsSaveButtonDisabled(false); // Enable save button when editing
    }
  }, [isEditMode, editHPIData]);

  const toggleSelection = (item, setFunction, list) => {
    setFunction(
      list.includes(item) ? list.filter((i) => i !== item) : [...list, item]
    );
    setIsSaveButtonDisabled(false);
  };

  const handleRatingClick = (value) => {
    setRating(value);
    setIsSaveButtonDisabled(false);
  };

  const transformStateToAPIFormat = () => {
    const details = [];

    if (selectedSites.length > 0) {
      details.push({
        attribute: "Site",
        values: selectedSites.map((site) => ({ value: site })),
      });
    }

    if (onsetDate) {
      details.push({
        attribute: "Onset",
        value: onsetDate,
      });
    }

    if (chronicity) {
      details.push({
        attribute: "Chronicity",
        value: chronicity,
      });
    }

    if (onsetQuality) {
      details.push({
        attribute: "Onset Quality",
        value: onsetQuality,
      });
    }

    if (frequency) {
      details.push({
        attribute: "Frequency",
        value: frequency,
      });
    }

    if (selectedSymptoms.length > 0) {
      details.push({
        attribute: "Associated Symptoms",
        values: selectedSymptoms.map((symptom) => ({ value: symptom })),
      });
    }

    if (rating) {
      details.push({
        attribute: "Rating Scale",
        value: rating,
      });
    }

    if (timePeriod && timeValue) {
      details.push({
        attribute: "Time",
        value: timePeriod,
      });

      details.push({
        attribute: "Time Value",
        value: timeValue,
      });
    }

    return details.length > 0
      ? {
          patientid: patientId,
          doctorid: doctorId,
          appointmentid: appointmentId,
          type:"updatedetail",
          hpi: [
            {
              id: isEditMode ? editHPIData.id : null,
              type,
              details,
            },
          ],
        }
      : null;
  };

  const UpdateHPI = async () => {
    const inputToSave = {
      input: transformStateToAPIFormat(),
    };

    if (!inputToSave.input) {
      return;
    }

    try {
      // console.log(inputToSave);
      const result = await updateHPI(inputToSave.input);
      if (result) {
        showSuccessToast("HPI Saved successfully");
        fetchDoctorNotes();
        modalRef.current.closeRight();
      }
    } catch (error) {
      console.error("Error in Update Doctor Notes:", error);
      showErrorToast("Error Happened While Saving.");
    }
  };

  const isSaveDisabled = () => {
    return isSaveButtonDisabled;
  };
  const handletimechange = (selectedtime)=>{
    let inputValue = selectedtime;
    inputValue = inputValue.slice(0, 3);
    selectedtime = inputValue;
    setTimeValue(selectedtime)
  }
  const handletimeoptionchange = (selectedtime)=>{
    setTimePeriod(selectedtime.label);
  }
  const checkBtn = {
    _hover: {
      bg: "#010308",
      color: "white",
    },
    _focus: {
      bg: "#010308",
      color: "white",
    }
  }
  const hpiModalCSS = `
  .css-xl71ch {
    min-width: fit-content;
  }
  
  .css-1nur6r0 {
    height: 24px;
    width: 24px;
  }
  
  .css-1nur6r0[data-checked] {
    background: green;
    border-color: green;
  }
  
  .css-1cgf557 {
    font-size: 0.875rem;
    font-weight: 600;
  }`
  return (
    <>
     <style>{hpiModalCSS}</style>
    <Box width="100%" px="8px">
    <Text
      fontSize="0.75rem"
      letterSpacing="0.1rem"
      fontWeight="600"
      textTransform="uppercase"
      color="#061027"
      mb="0.75rem"
    >
      Site
    </Text>
    <HStack spacing={{base: 2, lg: 4}} wrap="wrap" mt="8px">
    {siteOptions.map((site, index) => (
        <Button
        key={index}
        sx={checkBtn}
        onClick={() =>
          toggleSelection(site, setSelectedSites, selectedSites)
        }
        bg={selectedSites.includes(site) ? "black" : "white"}
        color={selectedSites.includes(site) ? "white" : "black"}
        borderRadius="full"
        border={"2px"}
        borderColor="gray.200"
      >
        {selectedSites.includes(site) && (
          <img
          src="/assets/svgs/check-white.svg"
          alt="selected"
          style={{ marginRight: "8px" }}
          />
        )}
        {site}
      </Button>
      ))}
    </HStack>
  </Box>
  <Divider my="1rem" />
  <SimpleGrid
    columns={{base: 1, lg: 2}}
    spacing={4}
    width="full"
    pl="8px"
    pr="8px"
    pb="8px"
  >
    <GridItem>
      <Text
        fontSize="0.75rem"
        letterSpacing="0.1rem"
        fontWeight="600"
        textTransform="uppercase"
        color="#061027"
        mb="0.75rem"
      >
        Onset
      </Text>
      <RadioGroup onChange={setOnsetDate} value={onsetDate}>
        <SimpleGrid columns={2} spacing={2} mb="8px" mt="8px">
        {onsetOptions.map((onset, index) => (
                <Radio size='lg' colorScheme="blackAlpha" key={index} value={onset}>
                  {onset}
                </Radio>
              ))}
          
        </SimpleGrid>
      </RadioGroup>
    </GridItem>

    <GridItem>
      <Text
        fontSize="0.75rem"
        letterSpacing="0.1rem"
        fontWeight="600"
        textTransform="uppercase"
        color="#061027"
        mb="0.75rem"
      >
        Chronicity
      </Text>
      <RadioGroup onChange={setChronicity} value={chronicity}>
        <SimpleGrid columns={2} spacing={2} mb="8px" mt="8px">
        {chronicityOptions.map((chronicityOption, index) => (
                <Radio size='lg' colorScheme="blackAlpha" key={index} value={chronicityOption}>
                  {chronicityOption}
                </Radio>
              ))}
        
        </SimpleGrid>
      </RadioGroup>
    </GridItem>
  </SimpleGrid>
  <Divider my="1rem" />
  <SimpleGrid
    columns={{base: 1, lg: 2}}
    spacing={4}
    width="full"
    pl="8px"
    pr="8px"
    pb="8px"
  >
    <GridItem>
      <Text
        fontSize="0.75rem"
        letterSpacing="0.1rem"
        fontWeight="600"
        textTransform="uppercase"
        color="#061027"
        mb="0.75rem"
      >
        onset quality
      </Text>
      <RadioGroup onChange={setOnsetQuality} value={onsetQuality}>
        <SimpleGrid columns={2} spacing={2} mb="8px" mt="8px">
        {onsetQualityOptions.map((qualityOption, index) => (
                <Radio size='lg' colorScheme="blackAlpha" key={index} value={qualityOption}>
                  {qualityOption}
                </Radio>
              ))}
          
        </SimpleGrid>
      </RadioGroup>
    </GridItem>

    <GridItem>
      <Text
        fontSize="0.75rem"
        letterSpacing="0.1rem"
        fontWeight="600"
        textTransform="uppercase"
        color="#061027"
      >
        Frequency
      </Text>
      <RadioGroup onChange={setFrequency} value={frequency}>
        <SimpleGrid columns={2} spacing={2} mb="8px" mt="8px">
        {frequencyOptions.map((frequencyOption, index) => (
                <Radio size='lg' colorScheme="blackAlpha" key={index} value={frequencyOption}>
                  {frequencyOption}
                </Radio>
              ))}
         
        </SimpleGrid>
      </RadioGroup>
    </GridItem>
  </SimpleGrid>
  <Divider my="1rem" />
  <Box width="full" px="8px">
    <Text
      fontSize="0.75rem"
      letterSpacing="0.1rem"
      fontWeight="600"
      textTransform="uppercase"
      color="#061027"
      mb="0.75rem"
    >
      Rating Scale
    </Text>
    <HStack gap="0" width="100%">
      {[...Array(10).keys()].map((i) => (
         <Button
         key={i + 1}
         onClick={() => handleRatingClick(i + 1)}
         bg={rating >= i + 1 ? "black" : "white"}
         color={rating >= i + 1 ? "white" : "black"}
         border="1px solid white"
         borderRadius={i === 0 ? "10px 0 0 10px" : i === 9 ? "0 10px 10px 0" : "0"}
         flex="1"
         sx={checkBtn}
         px={{base: "0.5rem", lg: "1rem"}}
         minW={{base: "1rem", lg: "2.5rem" }}
       >
         {i + 1}
       </Button>
      
      ))}
    </HStack>
    <SimpleGrid columns={10} spacing={0} mt={2} width="full">
      <GridItem
        colSpan={2}
        borderTop="2px solid #1C4EC5"
        ml={"6px"}
        mr={"6px"}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={rating >= 1 && rating <= 2 ? "4xl" : "lg"}>😁</Text>
      </GridItem>
      <GridItem
        colSpan={2}
        borderTop="2px solid #14C585"
        ml={"6px"}
        mr={"6px"}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={rating >= 3 && rating <= 4 ? "4xl" : "lg"}>😊</Text>
      </GridItem>
      <GridItem
        colSpan={2}
        borderTop="2px solid #F2AE40"
        ml={"6px"}
        mr={"6px"}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={rating >= 5 && rating <= 6 ? "4xl" : "lg"}>😐</Text>
      </GridItem>
      <GridItem
        colSpan={2}
        borderTop="2px solid #F27540"
        ml={"6px"}
        mr={"6px"}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={rating >= 7 && rating <= 8 ? "4xl" : "lg"}>😞</Text>
      </GridItem>
      <GridItem
        colSpan={2}
        borderTop="2px solid #DF1C41"
        ml={"6px"}
        mr={"6px"}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={rating >= 9 && rating <= 10 ? "4xl" : "lg"}>
          😢
        </Text>
      </GridItem>
    </SimpleGrid>
  </Box>
  <Divider my="1rem" />
  <Box width="full" pl="8px" pr="8px">
    <Text
      fontSize="0.75rem"
      letterSpacing="0.1rem"
      fontWeight="600"
      textTransform="uppercase"
      color="#061027"
      mb="0.75rem"
    >
      Associated Symptoms
    </Text>
    <SimpleGrid columns={{base: 2, lg: 4}} spacing={{base: 2, lg: 4}} width="full" mb="8px" mt="8px">
    {associatedSymptomsOptions.map((symptom, index) => (
  <Checkbox
    colorScheme="green"
    fontWeight="600"
    size="sm"
    sx={{
      ".chakra-checkbox__control": {
        width: "24px!important",
        height: "24px!important",
        borderRadius: "5px",
      },
    }}
    key={index}
    isChecked={selectedSymptoms.includes(symptom)}
    isDisabled={
      (selectedSymptoms.includes(noneSymptom) && symptom !== noneSymptom) ||
      (selectedSymptoms.includes(symptom) && noneSymptom === symptom && selectedSymptoms.length > 1)
    }
    onChange={() => {
      if (symptom === noneSymptom) {
        if (selectedSymptoms.includes(noneSymptom)) {
          // Unselect "None"
          setAssociatedSymptoms(
            selectedSymptoms.filter((s) => s !== noneSymptom)
          );
        } else {
          // Select "None" and unselect other options
          setAssociatedSymptoms([noneSymptom]);
        }
      } else {
        toggleSelection(symptom, setAssociatedSymptoms, selectedSymptoms);
      }
    }}
  >
    {symptom}
  </Checkbox>
))}
    </SimpleGrid>
  </Box>
  <Divider my="1rem" />
  <Box w="100%" px="8px"> 
    <Text
      fontSize="0.75rem"
      letterSpacing="0.1rem"
      fontWeight="600"
      textTransform="uppercase"
      color="#061027"
      mb="0.75rem"
    >
       Time Period
    </Text>
    
    <Box w="100%" display='flex' alignItems='center' gap='1rem'>
    <MiniDropdownInput
      variant="roundSide"
      dropdownStyle={{right: "10px"}}
      options={ [
        { label: "Hours", value: "Hours" },{ label: "Days", value: "Days" },
        { label: "Weeks", value: "Weeks" },{ label: "Months", value: "Months" },
      ] }
      placeholderProp="Time"
      inputVal={timeValue}
      defaultValue={timeValue}
      handleInputChange={(e) => {
        handletimechange(e.target.value);
        setIsSaveButtonDisabled(false);
      }}
      onSelect={(e) => {
        handletimeoptionchange(e)
        setIsSaveButtonDisabled(false);
      }}
      defaultOptionProp={timePeriod}
      
    />

    <PrimaryButton buttonText="Save HPI"
     onClick={UpdateHPI}
     isDisabled={isSaveDisabled()}/>
    </Box>
  </Box>
  </>
  );
};

export default AddEditHPI;
