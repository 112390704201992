
import  FetchData, { } from '../../client';
import {getLoginInfo, getUserID}  from '../../../auth';


const FetchVideoLibraryFromDB = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    // to change API Name here
    const result = await FetchData("midoc_getpatientvideolibrary", inputData);
    console.log(result , " db response video llib ")
    //  if (result.output.data) {
    //  var finalOutput = formAPIModelSubscription(result.output.data)
     
    //  return finalOutput;
    //  }
    return result;

  } catch (error) {
    console.error('Error Account fetching :', error);
    throw error;
  }
};

const FetchVideoLibraryFromAI = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    // to change API Name here
    const result = await FetchData("midoc_ai_youtube", inputData);
    //console.log(result , " db response plans ")
    //  if (result.output.data) {
    //  var finalOutput = formAPIModelSubscription(result.output.data)
     
    //  return finalOutput;
    //  }
    return result;

  } catch (error) {
    console.error('Error Account fetching :', error);
    throw error;
  }
};







export {FetchVideoLibraryFromDB, FetchVideoLibraryFromAI};
