import React from 'react'
import { Input, InputGroup, InputRightElement, Button, FormControl, FormLabel,Image } from '@chakra-ui/react'
import { LuEye, LuEyeOff } from "react-icons/lu";
import * as Styles from './styles'
import { inputDesign ,activeLabelStyles ,variantStyles } from "../styles/theme";


export default function PasswordInput({passInputPlace, onClick, onChange, autoFocus,variant, ...props}) {
    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)
    const variantStyle = variantStyles[variant] || variantStyles.defaultSide;

    return (
<FormControl sx={inputDesign.formStyles.container}>
        <InputGroup size='md' zIndex={"22"} sx={inputDesign.formStyles.inputGroup}>
          <Input
            sx={{ ...Styles.styles, ...variantStyle,...inputDesign.formStyles.input }}
            type={show ? 'text' : 'password'}
            placeholder=' '
            onClick={onClick}
            onChange={onChange}
            autoFocus={autoFocus}
            pr="65px"
            {...props}
            errorBorderColor="red.300"
          />
          <FormLabel sx={inputDesign.formStyles.label}>{passInputPlace}</FormLabel>
          <InputRightElement width='4.5rem' zIndex={"99"}>
            <Button sx={Styles.btnStyle} size='sm' onClick={handleClick}>
              {<Image src={show ? "./assets/imgs/hide-pass.png" : "./assets/imgs/show-pass.png"} width="21px" />}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
    )
  }