import {
    Flex,
    Box,
    IconButton,
    Image,
    useColorMode,
    Spacer,
  } from '@chakra-ui/react';
  import {
    PrimaryButtonIcon
  } from "../../components";

function SecondaryNav({defaultHeader,defaultSecondayHeader,sideBarWidth,defaultNavBody,withBack ,withBackBody, goBack,secondaryNavStyle}) {
    return (
        <Flex
        as="nav"
        align="center"
        alignItems="center"
        justify="space-between"
        wrap="wrap"
        bg="#FFFFFF"
        color="white"
        h="70px"
        borderBottom="1px solid #E6E7E9"
        {...secondaryNavStyle}
      >
      <Flex minW={sideBarWidth ? sideBarWidth : "205px"} align="center" pl="25px" pr="45px" h="full"  sx={{ ...(defaultHeader && { borderRight: "1px solid #E6E7E9" }) }}>
        {defaultHeader && (
          <Box as="h1" color="black" fontSize="20px">
            {defaultHeader}
          </Box>
        )}
          
        {withBack && (
          <Box as="button" display="flex" flexDirection="row" alignItems="center" gap="10px" sx={{bg: "none", _hover: {bg:"none"}}} onClick={goBack}>
            {/* <Box> */}
              <Image w="15px" mr="5px" src="/assets/imgs/arrow_back.png"/>
              <Box as="h1" color="black" fontSize="20px">{withBack}</Box>
            {/* </Box> */}
          </Box>
        )}
      </Flex>

        <Box display="flex" alignItems="center" h="full" pl="25px">
        <Box as="h1" color="black" fontSize="20px">{defaultSecondayHeader}</Box>
      </Box>

        <Spacer />
  
        {defaultNavBody}
        {withBackBody}
      </Flex>
    )
}

SecondaryNav.defaultProps = {
  defaultHeader: null,
  defaultSecondayHeader: null,
  defaultNavBody: null,
  withBackBody: null,
  withBack: null,
  onBack: () => {},
};

export default SecondaryNav;
