import React, { useState, useEffect } from "react";
import { saveToken,saveUsername,saveLoginID, savePatientImage, savePatientdob, savePatientgender } from "../../auth";
import {
  FetchLogin,
  VerifyUser,
  FetchMobileLogin,
} from "../../middleware/services/login/index";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Center,
  VStack,
  Image,
  Heading,
  Text,
  InputGroup,
  Button,
  Divider,
  Icon,
  InputRightElement,
} from "@chakra-ui/react";
import { FaGoogle, FaFacebook } from "react-icons/fa";
import {
  PrimaryInputDropdown,
  PrimaryButton,
  PrimaryButtonIcon,
  PasswordInput,
  PrimaryInput,
  Toaster,
} from "../../components";
import { generateOtp } from "../../middleware/services/signup";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";

function ForgotPassword() {
  const { showSuccessToast, showErrorToast } = Toaster();
  const [isError, setIsError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isPasswordButtonDisabled, setIsPasswordButtonDisabled] =
    useState(true);
  const navigate = useNavigate();
  var emailFromLocal = localStorage.getItem("Email")
  const [email, setEmail] = useState(emailFromLocal);
  var mobileFromLocal = localStorage.getItem("mobile")
  const [mobile, setMobileNo] = useState(mobileFromLocal);
  const [password, setPassword] = useState("");
  const [redirectToPassword, setRedirectTopassword] = useState(false);
  const [redirectToMobilePassword, setRedirectToMobilepassword] = useState(false);
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [isMobileLoginClicked, setIsMobileLoginClicked] = useState(false);
  const [isEmailClicked, setIsEmailLoginClicked] = useState(true);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  var redirect = useNavigate();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  // Functions for handling form inputs and button clicks:
  useEffect(() => {}, []);

  const [countryid, setCountryid] = useState('')
  const [countrycode, setCountrycode] = useState('')

  
  const location = useLocation();
  const countrycodeProp = location.state?.countrycode || '';
  const countryidProp = location.state?.countryid || '';

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    const newEmail = e.target.value;
    setIsButtonDisabled(newEmail.trim() === "");
    // if (newEmail.trim() === "") {
    //   setIsError(false);
    // }
    
  };
  const handleMobileChange = (e) => {
    const inputText = e.target.value;
    const maxLength = 10;
  
    // Limit the input to a maximum length of 10 characters
    if (inputText.length <= maxLength) {
      setMobileNo(inputText);
    }

    setMobileNo(e.target.value);
    setMobileNo(e.target.value.replace(/\D/g, "").slice(0, 10)); // Remove non-digit characters
    const newMobile = e.target.value;
    setIsButtonDisabled(newMobile.trim() === "");
    if (newMobile.trim() === "") {
      setIsError(false);
    }
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    const newPassword = e.target.value;
    setIsPasswordButtonDisabled(newPassword.trim() === "");
    if (newPassword.trim() === "") {
      setIsError(false);
    }
  };
  //Verify User with Email
  const handleSubmit = async () => {
    if (email.trim === "") {
      showErrorToast("Email cannot be empty");
      setIsError(true);
      return;
    } else if (!emailRegex.test(email)) {
      showErrorToast("Please Enter Valid Email Address");
      setIsError(true);
      return;
    } else {
      try {
        const response = await VerifyUser({
          email,
          type: "patient",
        });
        
        if (response.output.result === "0") {
          localStorage.setItem("NewUserEmail", email)
          setRedirectTopassword(false);
          showErrorToast("Uh-oh! The email you have entered does not exist.");
          setIsError(true);
          navigate("/signup")
        } else {
          setRedirectTopassword(true);
          setIsEmailLoginClicked(false);
          setIsError(false);
        }
      } catch (error) {
        console.error("failed:", error.response.data);
      }
    }
  };
  //Verify User with Mobile
  const handleMobileSubmit = async () => {
    if (mobile === "") {
      showErrorToast("Mobile cannot be empty");
      return;
    } else {
      try {
        const response = await VerifyUser({
          mobileno: mobile,
          type: "patient",
          "countryid":countryidProp
        });
       if (response.output.result === "0") {
          setRedirectToMobilepassword(false);
          showErrorToast("Uh-oh! The mobile you have entered does not exist");
          navigate("/signup")
        } else {
          setRedirectToMobilepassword(true);
        }
      } catch (error) {
        console.error("failed:", error.response.data);
      }
    }
  };
  //Submit Password
  const handleLoginSubmit = async (type) => {
    
  };
  var inputToGenerateOTP;
  if(isMobileLoginClicked){
     inputToGenerateOTP = {
      // "otp": "1234",
      "countrycode": countrycodeProp,
      "countryid": countryidProp,
      "language": "eng",
      "type": "forgot password",
      mobile
     }
    
  }else if(isEmailClicked){

     inputToGenerateOTP = {
      // "otp": "1234",
      "countrycode": "+1",
      "countryid": 1,
      "language": "eng",
      "type": "forgot password",
      email
     }
  }

  const generateOtpFunction = async() =>{
  showLoader();
if(isEmailClicked){
  localStorage.setItem("Email" , email)
    if (email.trim === "") {
      showErrorToast("Email cannot be empty");
     // setIsError(true);
      return;
    } else if (!emailRegex.test(email)) {
      showErrorToast("Please Enter Valid Email Address");
    //  setIsError(true);
      return;
    } else {
      try {
        const response = await VerifyUser({
          email,
          type: "patient",
         // "countryid":229
        });
        if (response.output.result === "0") {
          localStorage.setItem("NewUserEmail", email)
          showErrorToast("Uh-oh! The email you have entered does not exist.");
         // setIsError(true);
          navigate("/signup")
        } else {
          const fetchData = async () => {
            try {
              
    localStorage.setItem("Email" , email)
    if(response.output.imagepath && response.output.patientimage){
      if (response && response.output.patientimage) {
        let img = response.output.patientimage;
        if (img.startsWith('https')) {
          localStorage.setItem("imageUrl" , img)
        } else if (response.output.imagepath) {
          let imageURL  = response.output.imagepath + response.output.patientimage 
      localStorage.setItem("imageUrl" , imageURL)
        }
      }
     
    }
              const result = await generateOtp(inputToGenerateOTP);
              if(result.output.result == "failure"){
                showErrorToast("User dosent exists")
              }else if (result.output.result == "success"){
                
          redirect("/otpforgotpassword")
              }
            } catch (error) {
              console.error('Error generating OTP:', error);
            }
          };
        
          fetchData();
        }
      } catch (error) {
        console.error("failed:", error.response.data);
      }
    }
  }
  else if(isMobileLoginClicked){
    
  localStorage.setItem("mobile" , mobile)
  if (mobile == "") {
    showErrorToast("Mobile cannot be empty");
    setIsMobileLoginClicked(true)
   // setIsError(true);
  }  else {
    try {
      const response = await VerifyUser({
        "mobileno":mobile,
        type: "patient",
       // "countryid":229
        "countryid":countryidProp
      });
      
    //  console.log(mobile , "mobile in verify user")
    //  console.log(response , "response verify user")
      if (response.output.result === "0") {
       // localStorage.setItem("NewUserEmail", email)
        showErrorToast("Uh-oh! The mobile number you have entered does not exist.");
       // setIsError(true);
        navigate("/signup")
      } else {
        var inputToGenerateOTPMobile = {
          "otp": "1234",
          "countrycode": countrycodeProp,
          // "countryid": 229,
          "countryid": countryidProp,
          "language": "eng",
          "type": "forgot password",
          "mobileno":mobile,
         }
        const fetchData = async () => {
          try {
            
  localStorage.setItem("mobile" , mobile)
            const generateOTP = await generateOtp(inputToGenerateOTPMobile);
          } catch (error) {
            console.error('Error generating OTP mobile:', error);
          }
        };
      
        localStorage.setItem("countryidProp", countryidProp)
        localStorage.setItem("countrycodeProp", countrycodeProp)
        fetchData();
        redirect("/otpforgotpassword" , { state: { countrycode , countryid } })
      }
    }catch(error){
      console.log(error, "error in verify user in mobile")
    }
  }
  }
    dismissLoader();
  }
  // const countrycode = document.getElementById('countrycode').value;
  // alert(countrycode)
  // Enter Click Submit Email
  const handleEmailKeyPress = (e) => {
    if (e.key === "Enter") {
      generateOtpFunction();
    }
  };
  // Enter Click Submit Mobile
  const handleMobileKeyPress = (e) => {
    if (e.key === "Enter") {
      handleMobileSubmit();
    }
  };
  // Enter Click Email Password Submit
  const handleEmailpassKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLoginSubmit("email");
    }
  };
  // Enter Click mobile Password Submit
  const handleMobilepassKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLoginSubmit("mobile");
    }
  };
  useEffect(()=>{

  var countryid = localStorage.getItem("countryid")
  var countrycode = localStorage.getItem("countrycode")
  setCountryid(countryid)
  setCountrycode(countrycode)
    if(email !== ""){
        setIsPasswordButtonDisabled(false)
    }else if(email == ""){
        setIsPasswordButtonDisabled(true)
    }
  },[email])
  const handlebackbutton =()=>{
         redirect("/")
  }

  const storedEmail = localStorage.getItem('Email');
const storedMobile = localStorage.getItem('mobile');

useEffect(()=>{
  if(storedEmail){
    setIsEmailLoginClicked(true)
    
    setIsMobileLoginClicked(false)
  }else if(storedMobile){
    setIsMobileLoginClicked(true)
    setIsEmailLoginClicked(false)
  }else {
    
    setIsEmailLoginClicked(true)
    
    setIsMobileLoginClicked(false)
  }
},[email, mobile])



  return (
    <Box
      w="100vw"
      h="100vh"
      bg="#061027"
      bgImage="url('/assets/imgs/login-bg.png')"
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      
      {isLoading && <Loader />}
      <Center
        h="100vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
         <Box as="button" bg="none" fontSize="14px" pos="absolute" left="3%" top="5%" color="white" display="flex" justifyContent="center" alignItems="center" gap="8px" p="10px 16px" border="1px solid #384052" borderRadius="8px"
         onClick={handlebackbutton}
         >
        <Image w="14px" src="/assets/imgs/left-arrow-white.png" />
        <Text>Back</Text>
      </Box>
  
        <Box>
          <Image src="/assets/imgs/midoc-logo.png" alt="Logo" />
        </Box>
      
       
          <VStack
            spacing={4}
            p={8}
            m={4}
            bg="white"
            boxShadow="xl"
            borderRadius="lg"
            width={["90%", "400px"]}
            gap="0"
          >
            <Box mb="10px">
              <Image
                src="/assets/imgs/forgot-pass.png"
                w="48px"
                h="auto"
                borderRadius="10px"
              />
            </Box>
            <Box as="h1">Forgot Password</Box>
            {isEmailClicked && (
            <Box mb="20px">
              <Text textAlign="center" lineHeight="normal">Enter your email, and we'll send a code to verify your account.</Text>
            </Box>
             )}
                {isMobileLoginClicked && (
            <Box mb="20px">
              <Text>Enter your mobile, and we'll send a code to verify your account.</Text>
            </Box>
            )}
            <InputGroup>
            {isEmailClicked && (
      <PrimaryInput
        inputPlace="Enter Email Address"
        defaultValue={storedEmail}
        onChange={handleEmailChange}
        isDisabled={true}
        onKeyPress={handleEmailKeyPress}
      />
    )}

    {isMobileLoginClicked && (
      <PrimaryInput
        inputPlace="Enter Mobile"
        defaultValue={storedMobile}
        onChange={handleMobileChange}  // Assuming you have a handleMobileChange function
        isDisabled={true}
        onKeyPress={handleMobileKeyPress}  // Assuming you have a handleMobileKeyPress function
      />
    )}
              
              
            </InputGroup>
            <PrimaryButton
              colorScheme="blue"
              width="full"
              buttonText={"Continue"}
              isDisabled={isPasswordButtonDisabled}
              onClick={ generateOtpFunction}
            />
          </VStack>
       
        <Box as="p" fontSize="14px" color="#898D97">
          © 2024 midoc, Inc.
        </Box>
      </Center>
    </Box>
  );
}

export default ForgotPassword;
