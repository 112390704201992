import { Box } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';


const LottieAnimation = ({ animationPath, lottieWidth = "30em" }) => {
  const animationContainer = useRef(null);

  useEffect(() => {
    // Create script element to load Lottie from a CDN
    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.7.5/lottie.min.js';
    script.onload = () => {
      // Initialize the animation after the script is loaded
      if (window.lottie) {
        window.lottie.loadAnimation({
          container: animationContainer.current, // the DOM element to host the animation
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path: animationPath // the path to the animation JSON data
        });
      }
    };
    document.body.appendChild(script);

    return () => {
      // Remove script and animation instance on cleanup
      if (window.lottie && animationContainer.current) {
        window.lottie.destroy();
      }
      document.body.removeChild(script);
    };
  }, [animationPath]);

  return (
    <Box ref={animationContainer} w={lottieWidth} h="auto"></Box>
  );
};

export default LottieAnimation;
