import  FetchData, { } from '../../client';
import {getLoginInfo, getUserID}  from '../../../auth';
import formAPIOrdersIndex from '../../domains/profileorders';


const getOrdersList = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
   // inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_getorderforpatient", inputData);
    
      if (result && result.output) {
      var finalOutput = formAPIOrdersIndex(result.output);
        }else{
         finalOutput = result;
        }

     return result;

  } catch (error) {
    console.error('Error in formatting ordres data :', error);
    throw error;
  }
};

export {getOrdersList};
