
import formAPIProcedure from "./procedure";


var formattedOutput = null;

const formAPIModelProcedures = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data?.map(item => formAPIProcedure(item));


  return formattedOutput
};

export default formAPIModelProcedures