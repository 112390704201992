import formAPIpulse from "./pulse";


var formattedOutput = null;

const formAPIModelpulse = (data,type) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIpulse(item,type));


  return formattedOutput
};

export default formAPIModelpulse