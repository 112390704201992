import React, { useState, useEffect,useRef } from "react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import { Box,Text, Flex, Image, Divider, SimpleGrid } from "@chakra-ui/react";
import Footer from "../../components/footer"
import { PrimaryButton, SearchBar, Toaster } from "../../components";
import DoctorCard from "../../components/doctor-card";
import ModalRight from "../../components/modal-right";
import { useNavigate } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { FetchAppointments, FetchDoctors, FetchSpecialities } from "../../middleware/services/appointments";
import moment from "moment";
import MobileNavbar from "../../components/mobile-navbar";
import LocationSearch from "../../components/navbar/searchbar";
import { useScreenType } from "../../auth";


const AppointmentIndex = () => {
  const switchLocation = useRef()
  const navigate = useNavigate()
  var [patientid, setPatientid] = useState();
  var [activeuserid, setActiveUser] = useState();
  var [data, setData] = useState(null);
  var [doctorsdata, setDoctorsData] = useState(null);
  var [specialitiesdata, setSpecialitiesData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();
  const screenType = useScreenType()
  const [currentlocation, setLocation] = useState({
    latitude: null,
    longitude: null,
    address: '',
  });

  useEffect(() => {
    const detailsFromLocal = localStorage.getItem("location");
    if(detailsFromLocal){
      var locationdetails = JSON.parse(detailsFromLocal);
      fetchDoctors(locationdetails.latitude, locationdetails.longitude);
    }

    const mylocation = localStorage.getItem("location")
    if(mylocation == ""){
      getLocation();
    }
    if(mylocation){
      var locationdetails = JSON.parse(mylocation);
      setLocation({
        latitude: locationdetails.latitude,
        longitude: locationdetails.longitude,
        address: locationdetails.address,
      });
    }

    fetchAppointments();
    fetchSpecialities();   
  }, []);
  const fetchAppointments = async () => {
    showLoader();
    try {
      const result = await FetchAppointments({
        patientid,
        activeuserid,
        appointmentstatus:"1",
      });
      if (result != null) {
        setData(result);
      } else {
        //alert("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
  const fetchDoctors = async (lat, long) => {
    showLoader();
    try {
      const result = await FetchDoctors({
        patientid,
        activeuserid,
        type:"dashboard",
        latitude: lat,
        longitude: long,
      });
      if (result != null) {
        setDoctorsData(result);
      } else {
        //alert("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
  const fetchSpecialities = async () => {
    showLoader();
    try {
      const result = await FetchSpecialities({
        patientid,
        activeuserid,
        type:"speciality"
      });
      if (result != null) {
        setSpecialitiesData(result);
      } else {
        //alert("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };
  const formatTime = (timeString) => {
    if (!timeString) return "-";
    return moment(timeString, 'HH:mm:ss').format('HH:mm');
};

const getLocation = () => {
  switchLocation.current.closeRight();
  
  if (navigator.geolocation) {
    const options = {
      enableHighAccuracy: true, // Request high accuracy
      timeout: 10000, // Wait up to 10 seconds before giving up
      maximumAge: 0 // Do not use cached location
    };

    navigator.geolocation.getCurrentPosition(showPosition, showError, options);
  } else {
    alert("Geolocation is not supported by this browser.");
  }
};

const showPosition = async (position) => {
  console.log(position.coords)
  const lat = position.coords.latitude;
  const lon = position.coords.longitude;
  const address = await getAddress(lat, lon);
  setLocation({
    latitude: lat,
    longitude: lon,
    address: address,
  });
  const data = {
    latitude: lat,
    longitude: lon,
    address: address,
  }
  localStorage.setItem("location",JSON.stringify(data))
};

const getAddress = async (lat, lon) => {
  const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=AIzaSyCbdgkv53uWSq5pFaDG3IC6o_kGfQICSb0`);
  const data = await response.json();
  console.log(data)
  if (data.status === "OK") {
    return data.results[0].formatted_address;
  } else {
    return "Address not found";
  }
};

const showError = (error) => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      // showErrorToast("User denied the request for Geolocation.");        
      break;
    case error.POSITION_UNAVAILABLE:
      // showErrorToast("Location information is unavailable.");
      break;
    case error.TIMEOUT:
      // showErrorToast("The request to get user location timed out.");
      break;
    case error.UNKNOWN_ERROR:
      // showErrorToast("An unknown error occurred.");
      break;
    default:
      showErrorToast("An unknown error occurred.");
  }
};

const handleLocationSelect = (selectedlocation) => {
  setLocation({
    latitude: selectedlocation.lat,
    longitude: selectedlocation.lng,
    address: selectedlocation.address,
  });
  const data = {
    latitude: selectedlocation.lat,
    longitude: selectedlocation.lng,
    address: selectedlocation.address,
  }
  localStorage.setItem("location", JSON.stringify(data))
  switchLocation.current.closeRight();
  window.location.reload();
};

  return (
    <>
    {screenType == 'web' ? (
      <Box w="100%">
        <Box top="0" zIndex="banner">
          <Navbar />
          {isLoading && <Loader />}
          {/* <SecondaryNav
            defaultHeader="Appointments"
            defaultSecondayHeader=""
            defaultNavBody={
              <>
                <Box display="flex" alignItems="center" pr="25px"></Box>
              </>
            }
          /> */}
        </Box>
        <Box h="100%">
          <Flex h="calc(100vh - 110px)" flexDir="column" p="1.25rem 2rem" overflow="hidden auto">
            {/* Main container start */}
            <Box w="100%">
              {/* Appointments start */}
              <Box w="100%">
                <Box w="100%" mb="1rem" display="flex" justifyContent="space-between" alignItems="center">
                  <Text fontSize="1.25rem" fontWeight="600" color="#061027">Appointments</Text>
                  <Box display="flex" gap="1rem" alignItems="center">
                    {/* <Box
                      w="100%"
                      boxSize="2.5rem"
                      display='flex'
                      justifyContent="center"
                      alignItems="center"
                      border="1px solid #E6E7E9"
                      borderRadius="8px"
                      as="button"
                      // onClick={()=> switchLocation.current.openRight()}
                    >
                      <Image src="/assets/imgs/search.png" />
                    </Box> */}
                     {/* {data && data != null &&  */}
                    <PrimaryButton btnStyle={{my: "0"}} variant="grayBtn" buttonText="View all" onClick={()=>navigate("/all-appoinments")} />
                     {/* } */}
                    </Box>
                </Box>
                {data && data != null ? (
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="1rem">
                 {data && data.slice(0,3).map((step, index) => (
                    <Box key={index}>
                    <DoctorCard 
                     
                      cardBody={<>
                        <Box>
                          <Box display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                            <Box display='flex' gap="0.75rem">
                              <Box boxSize="2.375rem" borderRadius='full' overflow="hidden">
                                <Image src={step.doctorimage?step.imagepath+step.doctorimage:"/assets/imgs/no-image-icon.png"} />
                              </Box>
                              <Box>
                                <Text fontSize="0.625rem" fontWeight="500" color="#384052">{step.specality? step.specality:"-"}</Text>
                                <Text fontSize="0.875rem" fontWeight="600" color="#061027">{step.doctorname? step.doctorname:"-"}</Text>
                              </Box>
                            </Box>
                            <Text fontSize="0.625rem" fontWeight="600" color={step.appointmentstatus === 1? "#FC8935" : "#384052"} borderColor={step.appointmentstatus === 1? "#FC89351A" : "#f5f5f6"} border="1px solid" p="0.35rem 0.5rem" borderRadius="full" bg={step.appointmentstatus === 1? "#fff3eb" : "#f5f5f6"}>{step.appointmentstatus === 1? "Upcoming": step.appointmentstatus === 2?"Cancelled": step.appointmentstatus === 3 ? "Completed": step.appointmentstatus === 0 ? "Pending" :""}</Text>
                          </Box>

                          <Divider my="0.75rem" />
                          <Box display='flex' gap="1rem">
                            <Box maxW="45%">
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="12px" h="13px" src="/assets/imgs/calendar-blue.png" />
                                <Text fontSize="0.75rem" className="textOverflow" title={formatDate(step.appointmentdate)} fontWeight="500">{formatDate(step.appointmentdate)}</Text>
                              </Box>
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="13px" h="10px" src={step.appointmenttype_cd === "clinical visit"?"/assets/svgs/hospital.svg": "/assets/imgs/images-blue.png" }/>
                                <Text fontSize="0.75rem" className="textOverflow" title={step.appointmenttype_cd? step.appointmenttype_cd:"-"} fontWeight="500">{step.appointmenttype_cd? step.appointmenttype_cd:"-"}</Text>
                              </Box>
                            </Box>
                            <Box maxW="45%">
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="13px" h="13px" src="/assets/imgs/schedule-blue.png  " />
                                <Text fontSize="0.75rem" className="textOverflow" title={formatTime(step.appointmenttime)} fontWeight="500">{formatTime(step.appointmenttime)}</Text>
                              </Box>
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="14px" h="13px" src="/assets/imgs/hospital-blue.png" />
                                <Text fontSize="0.75rem" className="textOverflow" title={step.hospitalname? step.hospitalname:"-"} fontWeight="500">{step.hospitalname? step.hospitalname:"-"}</Text>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </>}
                       cardFooterText="View appointment"
                       onClick={()=> navigate("/appointmentdetails", { state: { appointmentid: step.appointmentid }})}
                    />
                    </Box>
                 ))}
                
                </SimpleGrid>
                ) : (
                  <Box fontSize="1rem" fontWeight="700" color="#6A707D" w="100%" display="flex" justifyContent="center">No data</Box>
                )}    
              </Box>
              {/* Appointments end */}


              {/* Top doctors start */}
              <Box w="100%">
                <Box w="100%" mb="1rem" mt="2rem" display="flex" justifyContent="space-between" alignItems="center">
                  <Text fontSize="1.25rem" fontWeight="600" color="#061027">Top doctors around you</Text>
                  {doctorsdata && doctorsdata != null && 
                  <Box display="flex" gap="1rem" alignItems="center">
                    <PrimaryButton btnStyle={{my: "0"}} variant="grayBtn" buttonText="View all" onClick={()=>navigate("/appointments/doctors", )} />
                  </Box>
                  }
                </Box>
                {doctorsdata && doctorsdata.length > 0 ? (
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing="1rem">
                {doctorsdata && doctorsdata.slice(0, 4).map((step, index) => (
                  <DoctorCard 
                  key={index} 
                      cardBody={<>
                        <Box>
                          <Box display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                            <Box display='flex' gap="0.75rem">
                              <Box boxSize="3.5rem" borderRadius='full' overflow="hidden">
                                <Image w="100%" h='100%' src={step.profilepictureurl? step.imagepath+step.profilepictureurl:"/assets/imgs/no-image.png" }/>
                              </Box>
                              <Box>

                                <Text fontSize="0.625rem" w="120px" fontWeight="500" className="textOverflow" color="#384052" title={step.specality?step.specality:"-"}>{step.specality?step.specality:"-"}</Text>

                                <Text fontSize="0.875rem" fontWeight="600" color="#061027">{step.fullname?step.fullname:"-"}</Text>
                                <Text fontSize="0.75rem" fontWeight="400" color="#384052">{step.qualification?step.qualification:"-"}</Text>
                              </Box>
                            </Box>
                          </Box>

                          <Divider my="0.75rem" />
                          <Box display='flex' flexDir="column">
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="12px" h="13px" src="/assets/svgs/location-blue.svg" />
                                <Text fontSize="0.75rem" className="textOverflow" w="229px" fontWeight="500">{step.address? step.address:"-"}</Text>
                              </Box>
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="13px" h="10px" src="/assets/svgs/work-blue.svg" />
                                <Text fontSize="0.75rem" fontWeight="500">{step.yearsofexperience?step.yearsofexperience + " years of experience":"-"}</Text>
                              </Box>
                          </Box>

                          <Box display="flex" gap="0.5rem" mt="0.75rem">
                            {/* <Box display="inline-flex" gap="0.25rem" border='1px solid #E6E7E9' borderRadius="full" p="0.15rem 0.5rem">
                              <Image src="/assets/svgs/Stars.svg" />
                              <Text fontSize="0.75rem" fontWeight="500" color="#384052">{step.ratings? step.ratings:"-"}</Text>
                            </Box> */}
                            <Box display="inline-flex" gap="0.25rem" border='1px solid #E6E7E9' borderRadius="full" p="0.15rem 0.5rem">
                              <Image src="/assets/svgs/pricing-blue.svg" />
                              <Text fontSize="0.75rem" fontWeight="500" color="#384052">{step.fee? "$"+step.fee:"$0"}</Text>
                            </Box>
                          </Box>
                        </Box>
                      </>}
                      cardFooterText="Book appointment"
                  onClick={()=> navigate("/doctorprofile", { state: { doctorid: step.doctorid, fullname: step.fullname }})}
                    />
                ))}   
                </SimpleGrid>
                 ) : (
                  <Box fontSize="1rem" fontWeight="700" color="#6A707D" w="100%" display="flex" justifyContent="center">No data</Box>
                )}
              </Box>
              {/* Top doctors end */}


              {/* find a doctors start */}
              <Box w="100%">
                <Box w="100%" mb="1rem" mt="2rem">
                  <Text fontSize="1.25rem" fontWeight="600" color="#061027">Find a doctor</Text>
                </Box>

                <SimpleGrid columns={{ base: 2,lg: 4, xl: 6 }} spacing="1rem">
                  {specialitiesdata && specialitiesdata.map((profile, index) => (
                      <Box
                        key={index}
                        as="button"
                        minW="200px"
                        h="105px"
                        borderRadius="8px"
                        display="flex"
                        flexDirection="column"
                        bg="#FCFCFD"
                        lineHeight="normal"
                        alignItems="center"
                        justifyContent="center"
                        onClick={()=> navigate("/appointments/doctors", { state: { specialityname: profile.specialityname } })}
                        boxShadow="0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset"
                      >
                        <Image src={profile.url? profile.imagepath+profile.url:"/assets/imgs/no-image.png"} />
                        <Box as="p" color="black" mt="8px" textAlign="center" fontSize="14px" whiteSpace="nowrap" fontWeight="500">
                          {profile.specialityname}
                        </Box>
                      </Box>
                    ))}
                </SimpleGrid>
              </Box>
              {/* find a doctors end */}
            </Box>
            {/* Main container end */}
           
            {/* <Image w="25%"  src="/assets/imgs/midoc-black.png" />
            <Text fontSize="35px" mt="35px" fontWeight="700" backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)" color="transparent" backgroundClip="text">Coming soon</Text> */}
          </Flex>
          <Footer />
        </Box>
      </Box>
    ) : (  
      // mobile responsive
      <Box overflow="hidden">
          {isLoading && <Loader />}
          <MobileNavbar/>

          {/* Menu section start */}
          <Box bg="#061027" p="1.5rem">
            <Box w="100%" as="button" onClick={()=> switchLocation.current.openRight()} display="flex" gap="0.5rem" alignItems="center">
              <Box as="span" className="textOverflow" w="150px" color="white" fontSize="1rem"title={currentlocation.address}>{currentlocation.address? currentlocation.address:"Please select location"}</Box> 
              <Image src="/assets/imgs/arrow-down.png" />   
            </Box>
          </Box>
          {/* Menu section start */}
          
          <Box bg="#fcfcfd"h='calc(100vh - 260px)' p="1.25rem" overflow="hidden auto">
            {/* Appointments start */}
            <Box w="100%">
              <Box w="100%" mb="1rem" display="flex" justifyContent="space-between" alignItems="center">
                <Text fontSize="1rem" fontWeight="700" color="#061027">Appointments</Text>
                <Box as="button" fontWeight="600" textDecor="underline" onClick={()=>navigate("/all-appoinments")}>
                  View all
                </Box>
              </Box>
              {data && data != null ? (
              <SimpleGrid columns={1} spacing="1rem">
                {data && data.slice(0,3).map((step, index) => (
                  <Box key={index}>
                  <DoctorCard 
                    cardBody={<>
                      <Box>
                        <Box display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                          <Box display='flex' gap="0.75rem">
                            <Box boxSize="2.375rem" borderRadius='full' overflow="hidden">
                              <Image src={step.doctorimage?step.imagepath+step.doctorimage:"/assets/imgs/no-image-icon.png"} />
                            </Box>
                            <Box>
                              <Text fontSize="0.625rem" fontWeight="500" color="#384052">{step.specality? step.specality:"-"}</Text>
                              <Text fontSize="0.875rem" fontWeight="600" color="#061027">{step.doctorname? step.doctorname:"-"}</Text>
                            </Box>
                          </Box>
                          <Text fontSize="0.625rem" fontWeight="600" color={step.appointmentstatus === 1? "#FC8935" : "#384052"} borderColor={step.appointmentstatus === 1? "#FC89351A" : "#f5f5f6"} border="1px solid" p="0.35rem 0.5rem" borderRadius="full" bg={step.appointmentstatus === 1? "#fff3eb" : "#f5f5f6"}>{step.appointmentstatus === 1? "Upcoming": step.appointmentstatus === 2?"Cancelled": step.appointmentstatus === 3 ? "Completed": step.appointmentstatus === 0 ? "Pending" :""}</Text>
                        </Box>

                        <Divider my="0.75rem" />
                        <Box display='flex' gap="1rem">
                          <Box  maxW="45%">
                            <Box display="flex" alignItems="center" gap="0.5rem">
                              <Image w="12px" h="13px" src="/assets/imgs/calendar-blue.png" />
                              <Text fontSize="0.75rem" className="textOverflow" title={formatDate(step.appointmentdate)} fontWeight="500">{formatDate(step.appointmentdate)}</Text>
                            </Box>
                            <Box display="flex" alignItems="center" gap="0.5rem">
                              <Image w="13px" h="10px" src={step.appointmenttype_cd === "clinical visit"?"/assets/svgs/hospital.svg": "/assets/imgs/images-blue.png" }/>
                              <Text fontSize="0.75rem" className="textOverflow" title={step.appointmenttype_cd? step.appointmenttype_cd:"-"} fontWeight="500">{step.appointmenttype_cd? step.appointmenttype_cd:"-"}</Text>
                            </Box>
                          </Box>
                          <Box  maxW="45%">
                            <Box display="flex" alignItems="center" gap="0.5rem">
                              <Image w="13px" h="13px" src="/assets/imgs/schedule-blue.png  " />
                              <Text fontSize="0.75rem" className="textOverflow" title={formatTime(step.appointmenttime)} fontWeight="500">{formatTime(step.appointmenttime)}</Text>
                            </Box>
                            <Box display="flex" alignItems="center" gap="0.5rem" maxW="10%">
                              <Image w="14px" h="13px" src="/assets/imgs/hospital-blue.png" />
                              <Text fontSize="0.75rem" className="textOverflow" title={step.hospitalname? step.hospitalname:"-"} fontWeight="500" w="150px">{step.hospitalname? step.hospitalname:"-"}</Text>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </>}
                      cardFooterText="View appointment"
                      onClick={()=> navigate("/appointmentdetails", { state: { appointmentid: step.appointmentid }})}
                  />
                  </Box>
                ))}
              
              </SimpleGrid>
              ) : (
                <Box fontSize="1rem" fontWeight="700" color="#6A707D" w="100%" display="flex" justifyContent="center">No data</Box>
              )}    
            </Box>
            {/* Appointments end */}

            {/* Top doctors start */}
            <Box w="100%">
              <Box w="100%" mb="1rem" mt="2rem" display="flex" justifyContent="space-between" alignItems="center">
              <Text fontSize="1rem" fontWeight="700" color="#061027">Top doctors around you</Text>
                {doctorsdata && doctorsdata != null && 
                <Box display="flex" gap="1rem" alignItems="center">
                  <Box as="button" fontWeight="600" textDecor="underline" onClick={()=>navigate("/appointments/doctors", )}>
                    View all
                  </Box>
                </Box>
                }
              </Box>
              {doctorsdata && doctorsdata.length > 0 ? (
              <SimpleGrid columns={1} spacing="1rem">
              {doctorsdata && doctorsdata.slice(0, 2).map((step, index) => (
                <DoctorCard 
                key={index} 
                    cardBody={<>
                      <Box>
                        <Box display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                          <Box display='flex' gap="0.75rem">
                            <Box boxSize="3.5rem" borderRadius='full' overflow="hidden">
                              <Image w="100%" h='100%' src={step.profilepictureurl? step.imagepath+step.profilepictureurl:"/assets/imgs/no-image.png" }/>
                            </Box>
                            <Box>

                              <Text fontSize="0.625rem" w="120px" fontWeight="500" className="textOverflow" color="#384052" title={step.specality?step.specality:"-"}>{step.specality?step.specality:"-"}</Text>

                              <Text fontSize="0.875rem" fontWeight="600" color="#061027">{step.fullname?step.fullname:"-"}</Text>
                              <Text fontSize="0.75rem" fontWeight="400" color="#384052">{step.qualification?step.qualification:"-"}</Text>
                            </Box>
                          </Box>
                        </Box>

                        <Divider my="0.75rem" />
                        <Box display='flex' flexDir="column">
                            <Box display="flex" alignItems="center" gap="0.5rem">
                              <Image w="12px" h="13px" src="/assets/svgs/location-blue.svg" />
                              <Text fontSize="0.75rem" className="textOverflow" w="229px" fontWeight="500">{step.address? step.address:"-"}</Text>
                            </Box>
                            <Box display="flex" alignItems="center" gap="0.5rem">
                              <Image w="13px" h="10px" src="/assets/svgs/work-blue.svg" />
                              <Text fontSize="0.75rem" fontWeight="500">{step.yearsofexperience?step.yearsofexperience + " years of experience":"-"}</Text>
                            </Box>
                        </Box>

                        <Box display="flex" gap="0.5rem" mt="0.75rem">
                          <Box display="inline-flex" gap="0.25rem" border='1px solid #E6E7E9' borderRadius="full" p="0.15rem 0.5rem">
                            <Image src="/assets/svgs/Stars.svg" />
                            <Text fontSize="0.75rem" fontWeight="500" color="#384052">{step.ratings? step.ratings:"-"}</Text>
                          </Box>
                          <Box display="inline-flex" gap="0.25rem" border='1px solid #E6E7E9' borderRadius="full" p="0.15rem 0.5rem">
                            <Image src="/assets/svgs/pricing-blue.svg" />
                            <Text fontSize="0.75rem" fontWeight="500" color="#384052">{step.fee? "$"+step.fee:"$0"}</Text>
                          </Box>
                        </Box>
                      </Box>
                    </>}
                    cardFooterText="Book appointment"
                onClick={()=> navigate("/doctorprofile", { state: { doctorid: step.doctorid, fullname: step.fullname }})}
                  />
              ))}   
              </SimpleGrid>
                ) : (
                <Box fontSize="1rem" fontWeight="700" color="#6A707D" w="100%" display="flex" justifyContent="center">No data</Box>
              )}
            </Box>
            {/* Top doctors end */}

            {/* find a doctors start */}
            <Box w="100%">
              <Box w="100%" mb="1rem" mt="2rem">
                <Text fontSize="1rem" fontWeight="700" color="#061027">Find a doctor</Text>
              </Box>

              <SimpleGrid columns={2} spacing="1rem">
                {specialitiesdata && specialitiesdata.map((profile, index) => (
                    <Box
                      key={index}
                      as="button"
                      minW="100px"
                      h="105px"
                      borderRadius="8px"
                      display="flex"
                      flexDirection="column"
                      bg="#FCFCFD"
                      lineHeight="normal"
                      alignItems="center"
                      justifyContent="center"
                      onClick={()=> navigate("/appointments/doctors", { state: { specialityname: profile.specialityname } })}
                      boxShadow="0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset"
                    >
                      <Image src={profile.url? profile.imagepath+profile.url:"/assets/imgs/no-image.png"} />
                      <Box as="p" color="black" mt="8px" textAlign="center" fontSize="14px" whiteSpace="nowrap" fontWeight="500">
                        {profile.specialityname}
                      </Box>
                    </Box>
                  ))}
              </SimpleGrid>
            </Box>
            {/* find a doctors end */}
          </Box>
      </Box>
    )}
      <ModalRight 
        ref={switchLocation}
        modalWidth="360px"
        header={<>
        <Box my="1rem">
          <Text as="h2" fontSize="1.5rem" fontWeight="600" color="#050D1F">Switch Location</Text>
        </Box>
          <Box display="flex" flexDir="column" gap="1rem">
          <LocationSearch onSelect={handleLocationSelect} />
              <Box display="flex" gap="0.5rem">
                <Image src="/assets/svgs/detect-location-blue.svg" />
                <Text fontSize="0.875rem" cursor="pointer" fontWeight="600" color="#1C4EC5" onClick={getLocation}>Auto detect location</Text>
              </Box>
            </Box>
        </>}
        bodyStyle={{p: "24px 32px"}}
        body={
          <>
          </>
        }
      />
    </>
  );
};

export default AppointmentIndex;
