export const styles = {
    borderRadius: "10px",
    background: "var(--primary-white)",
    boxShadow: "none",
    // padding: "12px 10px 0 10px",
    height: "48px",
    width: "100%",
    _disabled: {
      bg: "var(--btn-disable)",
      color: "var(--btn-disable-text)",
      cursor: "not-allowed",
    },
    _hover: {
      // borderRadius: "10px",
      // border: "2px solid var(--input-focus-dark)",
      // zIndex: "22",
      // outline: "none",
      bg: "none"
    },
    _focus: {
      borderRadius: "10px",
      // border: "2px solid var(--input-focus-dark)",
      // zIndex: "22",
      // outline: "none"
    },
    _focusVisible: {
      // borderColor: "none"
    }
  };
  


  export const variantStyles = {
    defaultSide: {
      borderRadius: "10px 10px 0 0", 
    },
    rightSide: {
      borderRadius: "0 10px 0 0", 
    },
    leftSide: {
      borderRadius: "10px 0 0 0px",
    },
    bothSide: {
      borderRadius: "0",
    },
    downSide: {
      borderRadius: "0 0 10px 10px"
    },
    roundSide: {
      borderRadius: "10px"
    }

  };