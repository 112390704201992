import moment from "moment";
export default function formAPIFamilyMedication({
    medication,
    medication_cd,
    medicationname,
    medicationtype,
    medicationtype_cd,
    familyid,
    patientid
   

})
{
  return Object.freeze({
    medication,
    medication_cd,
    medicationname,
    medicationtype,
    medicationtype_cd,
    familyid,
    patientid
   
  });
}