import moment from "moment";
export default function formAPIResultInterpretition({
    normal_results,
    abnormal_results,
    results_interpretation,
    report_interpretation


})
{ 
  return Object.freeze({
    normal_results,
    abnormal_results,
    results_interpretation,
    report_interpretation

   
  });
}