import formAPIcurrentmedications from "./currentmedication";


var formattedOutput = null;

const formAPIModelcurrentmedications = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIcurrentmedications(item));


  return formattedOutput
};

export default formAPIModelcurrentmedications