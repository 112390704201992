import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure } from '@chakra-ui/react';

const DynamicModal = forwardRef(({modalHeader,modalBody,modalBodyStyle,modalFooterBtn,modalFooterBtnStyle, onClick, modalWidth = "360px", hasHeader = true, hasCloseBtn = true, hasFooter = true,modalStyle, modalAttributes, customClose = false, customCloseClick }, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bodyRef = useRef(document.body);

  useImperativeHandle(ref, () => ({
    openModal: () => onOpen(),
    closeModal: () => onClose(),
  }));

  useEffect(() => {
    if (isOpen) {
      bodyRef.current.style.overflow = 'hidden';
      bodyRef.current.style.touchAction = 'none';
    } else {
      bodyRef.current.style.overflow = '';
      bodyRef.current.style.touchAction = '';
    }
  }, [isOpen]);

  return (
    <>
      <Modal {...modalAttributes} isOpen={isOpen} h="74px" borderRadius="12px" onClose={onClose} isCentered blockScrollOnMount={false}> 
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent w={modalWidth} {...modalStyle}>
          {hasHeader && (
            <ModalHeader fontSize="24px" fontWeight="600">{modalHeader}</ModalHeader>
          )}

          {hasCloseBtn && (
            <ModalCloseButton />
          )}
          
          {customClose && (
            <ModalCloseButton onClick={customCloseClick} />
          )}
          <ModalBody {...modalBodyStyle}>
            {modalBody}
          </ModalBody>

          {hasFooter && (

            <ModalFooter>
       
              {/* <Button mr={3} onClick={onClick} {...modalFooterBtnStyle}> */}
                {modalFooterBtn}
              {/* </Button> */}
           
          </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
});

export default DynamicModal;
