import moment from "moment";
export default function formAPIappointment({
    appointmentdate,
    appointmenttime,
    appointmenttype,
    doctorimage,
    doctorname,
    hospitalname,
    imagepath,
    specality,
    appointmentid,
appointmentstatus,
bookingid,
chiefcomplaint,
   notes,
   appointmenttype_cd

})
{ 
  return Object.freeze({
    appointmentdate: appointmentdate ? moment(appointmentdate).format("YYYY-MM-DD"):"-",
    appointmenttime: appointmenttime,
    appointmenttype: appointmenttype,
    doctorimage: doctorimage,
    doctorname: doctorname,
    hospitalname: hospitalname,
    imagepath: imagepath,
    specality: specality,
    appointmentid,
    appointmentstatus,
    bookingid,
    chiefcomplaint,
    notes,
    appointmenttype_cd
   
  });
}