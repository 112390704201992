import { COLORS } from "../styles/colors";

export const btnStyle = {
  height: "1.75rem",
  background: "none",
  color: "black",
  zIndex: "9",
  top: "6px",
  left: "10px",
  border: "none", // Ensure there's no border by default
  outline: "none", // Ensure there's no outline by default
  _hover: {
    bg: "none",
    border: "none",
    outline: "none"
  },
  _active: {
    border: "none",
    outline: "none",
  },
  _focus: {
    border: "none",
    outline: "none",
  },
  _focusVisible: {
    border: "none",
    outline: "none",
  }
}


const activeLabelStyles = {
  transform: "scale(0.85) translateY(-10px)",
  fontSize: "sm",
  color: COLORS.INPUT_FOCUS_DARK,
};

export const THEME = {
formStyles: {
  container: {
    position: "relative",
    paddingTop: "0",
  },
  label: {
    position: "absolute",
    top: "6px",
    left: 0,
    // zIndex: 99,
    mx: 2.5,
    px: 0,
    cursor: "auto",
    my: 2,
    opacity: "50%",
    transformOrigin: "left top",
    'input:focus + &': {
      ...activeLabelStyles,
    },
    'input:not(:placeholder-shown) + &': {
      ...activeLabelStyles,
    },
  },
  input: {
    _focus: {
      "+ label": {
        ...activeLabelStyles,
      },
    },
    ":not(:placeholder-shown) + label": {
      ...activeLabelStyles,
    },
  },
}
}

export const inputStyles = {
  borderRadius: "10px",
  background: COLORS.PRIMARY_WHITE,
  boxShadow: "none",
  padding: "12px 10px 0 10px",
  width: "100%",
  height: "48px",
  _disabled: {
    bg: COLORS.BTN_DISABLE,
    color: COLORS.BTN_DISABLE_TEXT,
    cursor: "not-allowed",
  },
  _hover: {
    borderRadius: "10px",
    border: `2px solid`,
    borderColor: COLORS.INPUT_FOCUS_DARK,
    // zIndex: "22",
    // outline: "none",
    bg: "white"
  },
  _focus: {
    borderRadius: "10px",
    // border: "2px solid var(--input-focus-dark)",
    borderColor: COLORS.INPUT_FOCUS_DARK,
    // zIndex: "22",
    outline: "none",
    bg: "white"
  },
  _focusVisible: {
    // borderColor: "none"
  }
};
