import React from 'react';
import { tabContainer, tabBtn } from './styles';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';


function TabSection({ tabs, onClickTab, defaultIndex, tabBtnStyle }) {

  return (
    <Tabs defaultIndex={defaultIndex} variant="soft-rounded" isFitted={true}>
      <TabList sx={tabContainer}>

        {tabs.map((tab, index) => (
          <Tab key={index} onClick={() => onClickTab(index)} sx={{ ...tabBtn, ...tabBtnStyle }}>
            {tab}
          </Tab>
        ))}

      </TabList>
    </Tabs>
  );
}

export default TabSection;
