import formAPIreviewofsystem from "./reviewofsystem";


var formattedOutput = null;

const formAPIModelreviewofsystem = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIreviewofsystem(item));


  return formattedOutput
};

export default formAPIModelreviewofsystem