import React, { useEffect, useState } from "react";
import GrayCard from "../../components/card";
import { Box, Flex, Text } from "@chakra-ui/layout";
import Navbar from "../../components/navbar";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import SecondaryNav from "../../components/seconday-nav";
import { Checkbox, Divider, Image } from "@chakra-ui/react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  CreatePaymentIntent,
  SavePaymentInitialCall,
} from "../../middleware/services/payment/payment";
import CheckoutForm from "./checkout-form";
import { PrimaryButton, Toaster } from "../../components";
import { COLORS } from "../../components/styles/colors";
import { FetchPlans} from "../../middleware/services/profile/subscription";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { BookAppointment, FetchDoctorDetails } from "../../middleware/services/appointments";
import { formatDate_ddd_D_MMM } from "../../auth";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { CONFIG } from '../../appconfig/appconfig';




const AppointmentPaymentIndex = () => {
  //console.log(data,"payment")
    const { isLoading, showLoader, dismissLoader } = useLoader();
    var navigate = useNavigate();
    const showmedicinename = React.useRef();
    const [clientSecret, setClientSecret] = useState(null);
    const [stripePromise, setStripePromise] = useState(null);
    var [patientid, setPatientid] = useState();
    const location = useLocation();
    const { input,  followupid } = location.state || {}; 
    console.log(input)
    var [patientid, setPatientid] = useState();
  var [activeuserid, setActiveUser] = useState();
  var [doctordata, setDoctorDetailsData] = useState(null);
   
  const { showSuccessToast, showErrorToast } = Toaster();


    useEffect(()=>{
      fetchdoctordetaiils(input.doctorid)
    },[])
    var fetchdoctordetaiils = async (id) => {
      showLoader()
      try {
        var result = await FetchDoctorDetails({
          patientid,
          activeuserid,
          doctorid:id,
        })
        if (result) {
          setDoctorDetailsData(result);
        } else {
          setDoctorDetailsData("")
          //  showErrorToast("Something went wrong!")
        }
       dismissLoader();
      } catch (error) {
        console.log(error, "error in fetching account data")
      }
      dismissLoader();
    }

      const paynowmodel = async (paymenttype)=>{
        showLoader();
        //below 2 states are being set null , due to the check-out form is not opening
        setClientSecret(null);
        setStripePromise(null);
        const amount = parseFloat(doctordata && doctordata.fee) * 100;
        if(paymenttype == "online"){
        try {
            const result = await CreatePaymentIntent({
              amount: amount,
            });
            result.amount = doctordata && doctordata.fee;
            result.doctorid = input.doctorid;
            result.paymenttypeid = "2";
            const resp = await SavePaymentInitialCall(result);
            //console.log(resp,"Initial db resp");
            //setClientSecret("pi_3PAucsIpkkS0IXSH0Y2lFxG8_secret_2YLi2vpWMmcIfVkjUM4i52GU6");
            setClientSecret(result.client_secret);
            setStripePromise(loadStripe(CONFIG.StripePublicKey));
          } catch (error) {
            console.error("Error in Payment:", error);
          }
        }else if(paymenttype == "cash"){
          input.paymenttype = "cash"
          const result = await BookAppointment(input);
          //console.log(result, " plan upgrade")
          if (result.output.result === "success") {
            //addcard.current.openRight();
            showLoader()
            navigate('/successappointments', { state: { "data":input } })
          } else {
            navigate('/failedappointments')
              showErrorToast("Something went wrong!")
          }
         }else if(paymenttype == "free"){
          input.paymenttype = "free"
          input.followupid = followupid;
          const result = await BookAppointment(input);
          //console.log(result, " plan upgrade")
          if (result.output.result === "success") {
            //addcard.current.openRight();
            showLoader()
            navigate('/successappointments', { state: { "data":input } })
          } else {
            navigate('/failedappointments')
              showErrorToast("Something went wrong!")
          }
        }
          dismissLoader();
      }

      const backFunction = () => {
        const currentUrl = window.location.href;
       navigate("/subscription")
      };
      const formatDate = (dateString) => {
        if (!dateString) return "-";
        return moment(dateString).format("ddd, D MMM");
      };
  return (
    <>
    <Box w="100%" display={{base: "none", lg: "block"}}>
      <Box top="0">
        <Navbar />
        {isLoading && <Loader />}
        <SecondaryNav withBack="Book Appointment" goBack={()=> navigate("/doctorprofile", { state: { doctorid: input.doctorid, fullname: doctordata && doctordata.fullname }})} />
      </Box>
      <Box h="calc(100vh - 140px)" overflow="hidden auto" backgroundImage={COLORS.PLATINUM_REWARD}>
        <Flex p="24px 32px" gap="15px">
          {/* Plan details start */}
          <Flex w="75%">
            <Box w="100%" display="flex" flexDir="column" gap="15px">
              <GrayCard
                cardHeader="Overview"
                cardMinHeight="115px"
                hasFooter={false}
                cardBody={
                  <>
                    <Box w="100%">
                      <Flex w="100%" justifyContent="space-between" gap="20px">
                      <Box display="flex" w="100%" justifyContent="space-between" alignItems="center">
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="left"
                        >
                          <Text fontSize="10px" className="textOverflow" title={doctordata && doctordata.specality} w="200px" fontWeight="500" color="#384052">
                          {doctordata && doctordata.specality}
                          </Text>

                          <Text
                            fontSize="16px"
                            fontWeight="600"
                          >
                            {doctordata && doctordata.fullname}
                          </Text>
                        </Box>
                        
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="right"
                        >
                          <Text fontSize="10px" fontWeight="500" color="#384052">
                            Total amount
                          </Text>

                          <Text
                            fontSize="16px"
                            fontWeight="600"
                          >
                           {doctordata && doctordata.fee? "$"+doctordata.fee: "$0"}
                          </Text>
                        </Box>
                      </Box>

                      <Divider h="inherit" my="-2%" orientation='vertical' />

                      <Box display="flex" w="100%" justifyContent="space-between" alignItems="center">
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="left"
                        >
                          <Text fontSize="10px" fontWeight="500" color="#384052">
                            Appointment schedule
                          </Text>

                          <Text
                            fontSize="16px"
                            fontWeight="600"
                            display="inline-flex"
                            gap="5px"
                          >
                            <Image src="/assets/svgs/calendar_plain.svg" />
                            {formatDate_ddd_D_MMM(input.appointmentdate)} at {input.appointmenttime}
                          </Text>
                        </Box>
                        
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="right"
                        >
                          <Box
                            as="button"
                            fontSize="14px"
                            fontWeight="600"
                            color="#1C4EC5"
                            // onClick={()=>navigate(-1)}
                            onClick={()=> navigate("/doctorprofile", { state: { doctorid: input.doctorid, fullname: doctordata && doctordata.fullname }})}
                          >
                            Reschedule
                          </Box>
                        </Box>
                      </Box>
                      </Flex>
                    </Box>
                  </>
                }
              />

{/* <GrayCard
                cardHeader="Overview"
                cardMinHeight="115px"
                hasFooter={false}
                cardBody={
                  <>
                    <Box w="100%">
                      <Flex w="100%" justifyContent="space-between" gap="20px">
                      <Box display="flex" w="100%" justifyContent="space-between" alignItems="center">
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="left"
                        >
                          <Text fontSize="10px" fontWeight="500" color="#384052">
                            Plan
                          </Text>

                          <Text
                            fontSize="16px"
                            fontWeight="600"
                          >
                            MD VIQAR
                          </Text>
                        </Box>
                        
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="right"
                        >
                          <Text fontSize="10px" fontWeight="500" color="#384052">
                            Total amount
                          </Text>

                          <Text
                            fontSize="16px"
                            fontWeight="600"
                            textAlign="right"
                          >
$50                          </Text>
                        </Box>
                      </Box>
                      </Flex>
                    </Box>
                  </>
                }
              /> */}

              {/* <GrayCard 
                cardHeader="Preferred"
                cardMinHeight="115px"
                hasFooter={false}
                cardBody={<>
                  <Box w="100%">
                    <Flex w="100%">
                      <Box display="flex" justifyContent="space-between" alignItems="center" w="100%">
                        <Flex gap="20px">
                        <Box>
                          <Image src="/assets/svgs/Payment-visa-icon.svg" />
                        </Box>
                        <Box>
                        <Text fontSize="14px" fontWeight="500">
                            Adnan’s card
                          </Text>
                          <Text
                            fontSize="12px"
                            fontWeight="200"
                          >
                             • • • •   1 2 2 8
                          </Text>
                        </Box>
                        </Flex>
                        <Box>
                          <Checkbox colorScheme="green" />
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                </>}
              />
              
              
              <GrayCard 
                cardHeader="credit/debit cards"
                cardMinHeight="115px"
                cardFooterText="Add a card"
                cardBody={<>
                  <Box w="100%">
                    <Flex w="100%" flexDir="column" gap="20px">
                      <Box display="flex" justifyContent="space-between" alignItems="center" w="100%">
                        <Flex gap="20px">
                        <Box>
                          <Image src="/assets/svgs/Payment-visa-icon.svg" />
                        </Box>
                        <Box>
                        <Text fontSize="14px" fontWeight="500">
                            Adnan’s card
                          </Text>
                          <Text
                            fontSize="12px"
                            fontWeight="200"
                          >
                             • • • •   1 2 2 8
                          </Text>
                        </Box>
                        </Flex>
                        <Box>
                          <Checkbox colorScheme="green" />
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between" alignItems="center" w="100%">
                        <Flex gap="20px">
                        <Box>
                          <Image src="/assets/svgs/Payment-master-icon.svg" />
                        </Box>
                        <Box>
                        <Text fontSize="14px" fontWeight="500">
                            Adnan’s card
                          </Text>
                          <Text
                            fontSize="12px"
                            fontWeight="200"
                          >
                             • • • •   1 2 2 8
                          </Text>
                        </Box>
                        </Flex>
                        <Box>
                          <Checkbox colorScheme="green" />
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                </>}
              />
              
              
              <GrayCard 
                cardHeader="wallet"
                cardMinHeight="115px"
                hasFooter={false}
                cardBody={<>
                  <Box w="100%">
                    <Flex w="100%" flexDir="column" gap="20px">
                      <Box display="flex" justifyContent="space-between" alignItems="center" w="100%">
                        <Flex gap="20px" alignItems="center">
                        <Box>
                          <Image src="/assets/svgs/Payment-apple-icon.svg" />
                        </Box>
                        <Box>
                        <Text fontSize="14px" fontWeight="500">
                            Apple Pay
                          </Text>
                        </Box>
                        </Flex>
                        <Box>
                          <Checkbox colorScheme="green" />
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between" alignItems="center" w="100%">
                        <Flex gap="20px" alignItems="center">
                        <Box>
                          <Image src="/assets/svgs/Payment-paypal-icon.svg" />
                        </Box>
                        <Box>
                        <Text fontSize="14px" fontWeight="500">
                            Paypal
                          </Text>
                          
                        </Box>
                        </Flex>
                        <Box>
                          <Checkbox colorScheme="green" />
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                </>}
              /> */}
            </Box>
          </Flex>
          {/* Plan details end */}

          {/* Summary start */}
          <Flex w="25%">
              <Box w="100%" display="flex" flexDir="column" gap="12px" minH="260px" h="max-content" p="24px" bg="#FFFFFF" border="1px solid #E6E7E9" borderRadius="12px">
                  <Flex w="100%" justifyContent="space-between">
                    <Text fontSize="14px" fontWeight="400">
                      Consultation fee
                    </Text>
                    <Text fontSize="14px" fontWeight="500">
                    {doctordata && doctordata.fee? "$"+doctordata.fee: "$0"}
                    </Text>
                  </Flex>

                  <Flex w="100%" justifyContent="space-between">
                    <Text fontSize="14px" fontWeight="400">
                      Service fee
                    </Text>
                    <Text fontSize="14px" fontWeight="500">
                      $0
                    </Text>
                  </Flex>

                  <Flex w="100%" justifyContent="space-between">
                    <Text fontSize="14px" fontWeight="400">
                      Tax
                    </Text>
                    <Text fontSize="14px" fontWeight="500">
                      $0
                    </Text>
                  </Flex>

                  <Divider />
                  
                  <Flex w="100%" justifyContent="space-between">
                    <Text fontSize="18px" fontWeight="400">
                      Total
                    </Text>
                    <Text fontSize="18px" fontWeight="600">
                    {doctordata && doctordata.fee? "$"+doctordata.fee: "$0"}                  
                      </Text>
                  </Flex>
                  {input.isfreevisit == 1 && 
                   <PrimaryButton
                   buttonText={"Book Free Appointment"}
                   onClick={()=>paynowmodel("free")} // this needs to be confirmed, not sure to send the type as free or cash
                   />   

                  }
                  {!input.isfreevisit && 
                  <>
                  <PrimaryButton
                  buttonText={"Pay $" + (doctordata && doctordata.fee? doctordata.fee: "0")}
                  onClick={()=>paynowmodel("online")}
                  />             
                  <PrimaryButton
                  buttonText={"Cash $" + (doctordata && doctordata.fee? doctordata.fee: "0")}
                  onClick={()=>paynowmodel("cash")}
                  />  
                  </>
                  }
                  
                 </Box>    
          </Flex>
          {/* Summary end */}

        </Flex>
      </Box>
     
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm data={input} clientSecret={clientSecret} />
        </Elements>
      )}
    </Box>

    {/* Mobile responsive start */}
    <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate("/doctorprofile", { state: { doctorid: input.doctorid, fullname: doctordata && doctordata.fullname }})}
          header="Book Appointment"
        />

        <Box w="100%" p="1.25rem"  h='calc(100vh - 190px)' overflow="hidden auto">
          <Flex flexDir="column" gap="1rem">
            <GrayCard
              cardHeader="Overview"
              cardMinHeight="115px"
              hasFooter={false}
              cardBody={
                <>
                  <Box w="100%">
                    <Flex w="100%" flexDir="column" justifyContent="space-between" gap="20px">
                    <Box display="flex" w="100%" justifyContent="space-between" alignItems="center">
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                      >
                        <Text fontSize="10px" className="textOverflow" title={doctordata && doctordata.specality} w="200px" fontWeight="500" color="#384052">
                        {doctordata && doctordata.specality}
                        </Text>

                        <Text
                          fontSize="16px"
                          fontWeight="600"
                        >
                          {doctordata && doctordata.fullname}
                        </Text>
                      </Box>
                      
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="right"
                      >
                        <Text fontSize="10px" fontWeight="500" color="#384052">
                          Total amount
                        </Text>

                        <Text
                          fontSize="16px"
                          fontWeight="600"
                        >
                          {doctordata && doctordata.fee? "$"+doctordata.fee: "$0"}
                        </Text>
                      </Box>
                    </Box>

                    <Divider h="inherit"  />

                    <Box display="flex" w="100%" justifyContent="space-between" alignItems="end">
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                      >
                        <Text fontSize="10px" fontWeight="500" color="#384052">
                          Appointment schedule
                        </Text>

                        <Text
                          fontSize="16px"
                          fontWeight="600"
                          display="inline-flex"
                          gap="5px"
                        >
                          <Image src="/assets/svgs/calendar_plain.svg" />
                          {formatDate_ddd_D_MMM(input.appointmentdate)} at {input.appointmenttime}
                        </Text>
                      </Box>
                      
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="right"
                      >
                        <Box
                          as="button"
                          fontSize="14px"
                          fontWeight="600"
                          color="#1C4EC5"
                          // onClick={()=>navigate(-1)}
                          onClick={()=> navigate("/doctorprofile", { state: { doctorid: input.doctorid, fullname: doctordata && doctordata.fullname }})}
                        >
                          Reschedule
                        </Box>
                      </Box>
                    </Box>
                    </Flex>
                  </Box>
                </>
              }
            />

            <Box w="100%" display="flex" flexDir="column" gap="12px" minH="260px" h="max-content" p="24px" bg="#FFFFFF" border="1px solid #E6E7E9" borderRadius="12px">
              <Flex w="100%" justifyContent="space-between">
                <Text fontSize="14px" fontWeight="400">
                  Consultation fee
                </Text>
                <Text fontSize="14px" fontWeight="500">
                {doctordata && doctordata.fee? "$"+doctordata.fee: "$0"}
                </Text>
              </Flex>

              <Flex w="100%" justifyContent="space-between">
                <Text fontSize="14px" fontWeight="400">
                  Service fee
                </Text>
                <Text fontSize="14px" fontWeight="500">
                  $0
                </Text>
              </Flex>

              <Flex w="100%" justifyContent="space-between">
                <Text fontSize="14px" fontWeight="400">
                  Tax
                </Text>
                <Text fontSize="14px" fontWeight="500">
                  $0
                </Text>
              </Flex>

              <Divider />
              
              <Flex w="100%" justifyContent="space-between">
                <Text fontSize="18px" fontWeight="400">
                  Total
                </Text>
                <Text fontSize="18px" fontWeight="600">
                {doctordata && doctordata.fee? "$"+doctordata.fee: "$0"}                  
                  </Text>
              </Flex>
              {input.isfreevisit == 1 && 
                   <PrimaryButton
                   buttonText={"Book Free Appointment"}
                   onClick={()=>paynowmodel("cash")}
                   />   

                  }
                  {!input.isfreevisit && 
                  <>
              <PrimaryButton
                buttonText={"Pay $" + (doctordata && doctordata.fee? doctordata.fee: "0")}
                onClick={()=>paynowmodel("online")}
                />  
                 <PrimaryButton
                    buttonText={"Cash $" + (doctordata && doctordata.fee? doctordata.fee: "0")}
                    onClick={()=>paynowmodel("cash")}
                    />           
                    </>
                  }   
              </Box>
          </Flex>

        </Box>
      </Box>
    </>
  );
};

export default AppointmentPaymentIndex;
