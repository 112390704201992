import React, { useState, useEffect, useRef } from "react";
import {
  Flex,
  Text,
  Box,
  Image,
  Spacer,
  Radio,
  RadioGroup,
  Stack,
  Divider,
  InputGroup,
  InputRightElement,
  Input,
  Toast,
  Heading,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import {
  DropdownInput,
  PrimaryButton,
  PrimaryInput,
  Toaster,
} from "../../components";
import SuccessIndex from "./success";
import { redirect, useLocation } from "react-router-dom";
import FetchPrescriptionData from "../../middleware/services/myhealth/uploaddocument";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { useNavigate } from "react-router-dom";
import {
  uploadImagingDocument,
  FetchImagingDataAI,
} from "../../middleware/services/myhealth/imagingupload";
import DatePicker from "../../components/date-picker";
import { format, isValid, parse, parseISO } from "date-fns";
import {
  formatDate_DD_MM_YYYY,
  formatDate_Do_MMM_YYYY,
  getLoginInfo,
  getUserID,
} from "../../auth";
import moment from "moment";
import { MessageToaster } from "../../components/manualmessage";
import DynamicModal from "../../components/dynamic-modal";

function ImagingUpload() {
  const location = useLocation();
  const { result, filename} = location.state || {};
  var navigate = useNavigate();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  var [data, setData] = useState(null);
  var [actualdata, setActualData] = useState(null);
  var [name, setName] = useState("");
  var [age, setAge] = useState("");
  var [doctorname, setDoctorName] = useState("");
  var [reportdate, setReportDate] = useState("");
  var [reportname, setReportName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [showpatientdetails, setShowPatientDetails] = useState(true);
  const [showpreview, setShowPreview] = useState(true);
  const [activeTestIndex, setActiveTestIndex] = useState(0);
  const [showcustominput, setShowCustomInput] = useState(false);
  const [showedittestname, setShowEditTestName] = useState(false);
  const [selectedRadioValues, setSelectedRadioValues] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [manualInput, setManualInput] = useState("");
  const [testNameInput, setTestNameInput] = useState("");
  const [abnormalTests, setAbnormalTests] = useState([]);
  const [reUpload, setReUpload] = useState(false);
  const [file, setFile] = useState(location.state.file || null);
  const { showMessageToast, closeMessageToast } = MessageToaster();
  const { showSuccessToast, showErrorToast } = Toaster();
  const consentForm = useRef();

  const [upgradePlanModal , setUpgradePlanModal] = useState(false)

  //const [file, setFile] = useState("");

  const buttonData = ["10.9", "71", "15700", "21", "Enter manually"];
  //const file = location.state.file;
  const ageinyears = [];

  for (let i = 1; i <= 100; i++) {
    ageinyears.push(String(i));
  }

  useEffect(() => { 
    if (filename) { 
    const reader = new FileReader();
    reader.onloadend = () => {
      const fileName = filename && filename.name.split('.').pop().toLowerCase(); // Use 'split' instead of 'Split', and 'pop' instead of 'Last'
      if (fileName === 'pdf') { // Compare with 'pdf' instead of '.pdf'
        setSelectedImage("/assets/imgs/pdf.jpeg");
      } else {
        setSelectedImage(reader.result);
      }
    };
    reader.readAsDataURL(filename);
    setFile(filename);
    setShowPreview(false);
    setCustomInput(result)
    setData(result);
    setActualData(result);
    setName(result && result.patient_info && result.patient_info.name);
            //setAge(result && result.patient_info && result.patient_info.age);
            const age = result && result.patient_info && result.patient_info.age;
             // Regular expression to check for valid numeric age
            const isValidAge = /^\d+$/.test(age);
            const ageNumber = Number(age);

            // Check if age is valid and within the specified range
            if (isValidAge && ageNumber >= 1 && ageNumber <= 200) {
              setAge(ageNumber); // Set state to the valid age
            } else {
              setAge(null); // Set state to null if age is invalid
            }
            setDoctorName(result && result.patient_info && result.patient_info.doctor_name);
             const dateOfReport = result?.patient_info?.date_of_report;
            if (dateOfReport) {
              const formattedDate = formatDate_DD_MM_YYYY(dateOfReport);
              if (formattedDate === '-') {
                const today = new Date();
                setReportDate(today);
              } else {
                setReportDate(formattedDate);
              }
            } else {
              const today = new Date();
              setReportDate(today);
            }
            setReportName(result && result.report_info && result.report_info.name);
  }
  }, [result]);
  
    useEffect(() => {  
      if (file && !filename) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileName = file.name.split('.').pop().toLowerCase(); // Use 'split' instead of 'Split', and 'pop' instead of 'Last'
        if (fileName === 'pdf') { // Compare with 'pdf' instead of '.pdf'
          setSelectedImage("/assets/imgs/pdf.jpeg");
        } else {
          setSelectedImage(reader.result);
        }
        consentForm.current.openModal();
      };
      reader.readAsDataURL(file);
      setFile(file);
    }
    }, [file, filename]);
     var [customInput , setCustomInput] =useState('')
     var redirect = useNavigate();

     const fetchData = async () => {
      var loginid = getLoginInfo();
      var activeuserid = getUserID();
      showLoader();
      showMessageToast("Please wait while we are fetching data")
      let attempts = 0;
      const maxAttempts = 4;
    
      const makeFetchCall = async () => {
        try {
          const result = await FetchImagingDataAI(file, file.name, file.type);
          if (result?.output?.message == "No credits are available"){
            //showErrorToast("No sufficient tokens available");
            setUpgradePlanModal(true)
          setTimeout(() => {
            setUpgradePlanModal(false)
          }, 100);
            closeMessageToast();
            dismissLoader();
            return false;
          }
          if(result && result.patient_info){
            if(result && result.patient_info){
              result.patient_info.patientid = loginid;
              result.patient_info.activeuserid = activeuserid;
              result.patient_info.documenturl = result.documenturl;
  
              if(result.patient_info.date_of_report == "" || result.patient_info.date_of_report == "0000-00-00"){
                const today = moment().format('YYYY-MM-DD');
                result.patient_info.date_of_report = today;
              }
            }
            if(result && result.report_info){
              result.report_info.type = "imaging";
            }
            delete result.documenturl;
            setCustomInput(result)
            setData(result);
            setActualData(result)
            const reader = new FileReader();
            // reader.onloadend = () => {
            //   setSelectedImage(reader.result);
            // };
            reader.onloadend = () => {
              const fileName = file.name.split('.').pop().toLowerCase(); // Use 'split' instead of 'Split', and 'pop' instead of 'Last'
              if (fileName === 'pdf') { // Compare with 'pdf' instead of '.pdf'
                setSelectedImage("/assets/imgs/pdf.jpeg");
              } else {
                setSelectedImage(reader.result);
              }
            };
            reader.readAsDataURL(file);
            setName(result && result.patient_info && result.patient_info.name);
            //setAge(result && result.patient_info && result.patient_info.age);
            const age = result && result.patient_info && result.patient_info.age;
             // Regular expression to check for valid numeric age
            const isValidAge = /^\d+$/.test(age);
            const ageNumber = Number(age);

            // Check if age is valid and within the specified range
            if (isValidAge && ageNumber >= 1 && ageNumber <= 200) {
              setAge(ageNumber); // Set state to the valid age
            } else {
              setAge(null); // Set state to null if age is invalid
            }
            setDoctorName(result && result.patient_info && result.patient_info.doctor_name);
             const dateOfReport = result?.patient_info?.date_of_report;
            // let formattedDate = '';
            // if (dateOfReport && moment(dateOfReport, undefined, true).isValid()) {
            //   formattedDate = moment(dateOfReport).format('DD-MM-yyyy');
            // }
            // setReportDate(formattedDate ? formattedDate : '');
            // console.log(dateOfReport , " dateOfReport ")
            // if (dateOfReport) {
            //   // const dateFormats = [
            //   //   "yyyy-MM-dd",
            //   //   "ddMMMyyyy",
            //   //   "dd MMM yyyy",
            //   //   "MMMM dd, yyyy",
            //   //   "MM/dd/yyyy",  
            //   //   "dd-MMM-yyyy",
            //   //   "MMM, YYYY", 
            //   // ];
            //   // let parsedDate = null;
            //   // for (const format of dateFormats) {
            //   //   parsedDate = parse(dateOfReport, format, new Date());
            //   //   if (isValid(parsedDate)) {
            //   //     break;
            //   //   }
            //   // }
            //   // if (isValid(parsedDate)) {
            //   //   setReportDate(parsedDate);
            //   // } else {
            //   //   const today = new Date();
            //   //   setReportDate(today);
            //   // }
            //   setReportDate(formatDate_DD_MM_YYYY(dateOfReport));
            // } else {
            //   const today = new Date();
            //   setReportDate(today);
            // }
            if (dateOfReport) {
              const formattedDate = formatDate_DD_MM_YYYY(dateOfReport);
              if (formattedDate === '-') {
                const today = new Date();
                setReportDate(today);
              } else {
                setReportDate(formattedDate);
              }
            } else {
              const today = new Date();
              setReportDate(today);
            }
            setReportName(result && result.report_info && result.report_info.name);
            setShowPreview(false);
            dismissLoader();
            closeMessageToast();
          } else {
            attempts++;
            if (attempts < maxAttempts) {
              await makeFetchCall(); // Retry the call
            } else {
              closeMessageToast();
              handleError(); // Handle the error after max attempts
            }
          }
        } catch (error) {
          console.error("Error in Upload Documents:", error);
          attempts++;
          if (attempts < maxAttempts) {
            await makeFetchCall(); // Retry the call
          } else {
            handleError(); // Handle the error after max attempts
          }
        }
      };
    
      const handleError = () => {
        showErrorToast("Something went wrong! please try again");
        setShowPreview(true);
        dismissLoader();
      };
    
      await makeFetchCall();
    };

  const handlePatientDetailsConfirm = () => {
    setShowPatientDetails(false);
  };
  const handleRadioChange = (event, testIndex) => {
    //const { value } = event;
    if (event === "manual") {
      setShowCustomInput(true);
    } else {
      setShowCustomInput(false);
    }
    setSelectedRadioValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[testIndex] = event;
      return updatedValues;
    });
  };

  const handleConfirm = () => {
    var uploadImagingDoc = async () => {
      try {
        const FinalInput = { ...customInput, actualinput: actualdata };
        var date = FinalInput.patient_info.date_of_report;

        //   const formattedDate = format(date, "yyyy-MM-dd");
        const yyyyMMddFormat = /^\d{4}-\d{2}-\d{2}$/;
        // Check if the date string does not match the YYYY-MM-DD format
        if (!yyyyMMddFormat.test(date)) {
          const formattedDate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");

          FinalInput.patient_info.date_of_report = formattedDate;
        }

        var result = await uploadImagingDocument(FinalInput);
        if (result && result.output.result == "success") {
          redirect("/successupload");
        } else {
          showErrorToast("Something went wrong! please upload again");

          //navigate("/myhealth");
        }
      } catch (error) {
        console.log(error, "error in imaging upload");
      }
    };

    uploadImagingDoc();
  };

  const handleedittestname = () => {
    setShowEditTestName(true);
  };

  const handleManualInputChange = (event, index) => {
    const { value } = event;
    setManualInput((prevInputs) => ({
      ...prevInputs,
      [index]: value,
    }));
  };

  const backFunction = () => {
    navigate("/myhealth");
  };

  const handleTestNameUpdate = () => {
    setCustomInput((prevCustomInput) => ({
      ...prevCustomInput,
      patient_info: {
        ...prevCustomInput.patient_info,
        date_of_report: reportdate,
        name: name,
        age: age,
        doctor_name: doctorname,
      },
      report_info: {
        ...prevCustomInput.report_info,
        name: reportname, // Set name inside report_info
      },
    }));
    setShowEditTestName(!showedittestname);
  };

  const handleName = (e) => {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z\s.]*$/;

    if (regex.test(inputValue) || inputValue === "") {
      // Allow empty string
      setName(inputValue);
    }
  };
  const handleAge = (selectedOption) => {
    setAge(selectedOption);
  };
  const handleDoctorName = (e) => {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z\s.]*$/;

    if (regex.test(inputValue) || inputValue === "") {
      // Allow empty string
      setDoctorName(inputValue);
    }
  };
  const handleDocumentName = (e) => {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z\s.]*$/;

    if (regex.test(inputValue) || inputValue === "") {
      // Allow empty string
      setReportName(inputValue);
    }
  };
  const handleChangeDOB = (selectedDate) => {
    const formattedDate = format(selectedDate, "yyyy-MM-dd");
    setReportDate(formattedDate); // Update state if needed
  };
  //    useEffect(()=>{
  //     //customInput.patient_info.date_of_report = reportdate;
  //     setCustomInput(prevCustomInput => ({
  //         ...prevCustomInput,
  //         patient_info: {
  //           ...prevCustomInput.patient_info,
  //           date_of_report: reportdate
  //         }
  //       }));
  //    }, [reportdate])

  useEffect(() => {
    setCustomInput((prevCustomInput) => ({
      ...prevCustomInput,
      patient_info: {
        ...prevCustomInput.patient_info,
        date_of_report: reportdate,
        name: name,
        age: age,
        doctor_name: doctorname,
      },
      report_info: {
        ...prevCustomInput.report_info,
        name: reportname, // Set name inside report_info
      },
    }));
  }, [reportdate, name, age, doctorname, reportname, showedittestname]);

  const fileInputRef = useRef(null);

  const [boolVal, setBoolVal] = useState(false);
  const handleImagingUpload = async (e) => {
    e.preventDefault();
    //setFile("")
    const file = e.target.files[0];
    // Check if a file is selected
    if (!file) {
      showErrorToast("No file selected");
      return false;
    }

    console.log(e.target.files, " e.target.files");
    showLoader();
    navigate("/imagingupload", { state: { file } });
    const reader = new FileReader();
    reader.onloadend = () => {
      const fileName = file.name.split(".").pop().toLowerCase(); // Use 'split' instead of 'Split', and 'pop' instead of 'Last'
      if (fileName === "pdf") {
        // Compare with 'pdf' instead of '.pdf'
        setSelectedImage("/assets/imgs/pdf.jpeg");
      } else {
        setSelectedImage(reader.result);
      }
    };
    reader.readAsDataURL(file);
    setFile(file);
    setBoolVal(true);
    //e.target.files = ""
    dismissLoader();
  };

  useEffect(() => {
    if (boolVal) {
      fetchData();
    }
  }, [boolVal]);

  const openUploadImaging = () => {
    setBoolVal(false);
    fileInputRef.current.click();
  };
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const modalBodyRef = useRef();

  const handleScroll = () => {
    if (modalBodyRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = modalBodyRef.current;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10;
      setIsScrolledToBottom((prev) => {
        // Log the previous state and new calculation for debugging purposes
        console.log("Previous state:", prev, "New calculation:", isAtBottom);
        return isAtBottom;
      });
    }
  };

  useEffect(() => {
    const modalBodyElement = modalBodyRef.current;
    if (modalBodyElement) {
      modalBodyElement.addEventListener("scroll", handleScroll);
      // Perform an initial check to see if the content is already at the bottom
      handleScroll();
    }
    return () => {
      if (modalBodyElement) {
        modalBodyElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [modalBodyRef.current]);

  useEffect(() => {
    if (modalBodyRef.current) {
      setTimeout(() => {
        handleScroll();
      }, 100); // Adding a small delay to ensure dimensions are correct
    }
  }, []);

  const submitconsentform = () => {
    consentForm.current.closeModal();
  };
  const closeconsentform = async () => {
    window.history.back();
  };
  return (
    <>
      <Box w="100%">
        {/* <SuccessIndex /> */}
        <Box top="0">
          <Navbar  messageModal={upgradePlanModal} />
          {isLoading && <Loader />}
          <SecondaryNav withBack="Upload document" goBack={backFunction} />
        </Box>
        {showpreview && (
          <Box
            w="100%"
            p="32px"
            bg="#E6E7E9"
            overflow="hidden auto"
            height="calc(100vh - 130px)"
          >
            <Box
              display="flex"
              pb="25px"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                w="calc(100vw - 70%)"
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;"
                h="auto"
                src={selectedImage}
              />
            </Box>

            <Box
              w="calc(100vw - 70%)"
              display="flex"
              marginInline="auto"
              alignItems="center"
              gap="20px"
              pos="sticky"
              bottom="0"
            >
              <PrimaryButton
                buttonText="Re-upload"
                id="imaging-upload"
                variant="grayBtnFull"
                onClick={openUploadImaging}
                inputStyleCss={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
                  borderRadius: "10px",
                }}
              />
              <input
                type="file"
                id="imaging-upload"
                onChange={handleImagingUpload}
                ref={fileInputRef} // Set the ref for the file input
                accept="image/jpeg,image/jpg,image/png,image/tiff"
                style={{ display: "none" }} // Hide the file input
              />
              <PrimaryButton onClick={fetchData} buttonText="Confirm" />
            </Box>
          </Box>
        )}
        {!showpreview && (
          <Flex
            overflow="hidden auto"
            height="calc(100vh - 150px)"
            direction="row"
          >
            {/* Image section start */}
            <Box flex="1" overflowY="scroll" p="32px" bg="#E6E7E9">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                h="100vh"
              >
                <Image w="calc(100vh - 25%)" src={selectedImage} />
              </Box>
            </Box>
            {/* Image section end */}

            <Box
              w="500px"
              h="100%"
              display="flex"
              flexDirection="column"
              overflow="hidden auto"
              height="calc(100vh - 150px)"
            >
              {showpatientdetails && data && data.patient_info && (
                <>
                  <Box
                    //    overflowY="auto"
                    p="24px 32px"
                  >
                    <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">
                      Please verify the details below
                    </Box>
                    <PrimaryInput
                      inputPlace="Name"
                      defaultValue={name && name ? name : ""}
                      variant="defaultSide"
                      onChange={handleName}
                      inputValue={name}
                    />
                    <DropdownInput
                      variant="bothSide"
                      defaultOption={age}
                      dropDownPlace="Age"
                      options={ageinyears}
                      onClick={handleAge}
                    />
                    <PrimaryInput
                      inputPlace="Doctor name"
                      defaultValue={doctorname}
                      variant="bothSide"
                      onChange={handleDoctorName}
                      inputValue={doctorname}
                    />
                    {/* <PrimaryInput inputPlace="Date of document" defaultValue={reportdate} variant="bothSide" inputType="date" /> */}

                    <DatePicker
                      disableFutureDates={true}
                      defaultDate={reportdate}
                      onDateChange={(selectedDateValue) =>
                        handleChangeDOB(selectedDateValue)
                      }
                      placeholderProp="Date of document"
                    />

                    <PrimaryInput
                      inputPlace="Document name"
                      defaultValue={reportname}
                      variant="downSide"
                      onChange={handleDocumentName}
                      inputValue={reportname}
                    />
                  </Box>

                  <Box w="100%" display="flex" gap="20px" mt="auto">
                    <PrimaryButton
                      buttonText="Re-upload"
                      id="imaging-upload"
                      variant="grayBtnFull"
                      onClick={openUploadImaging}
                    />
                    <input
                      type="file"
                      id="imaging-upload"
                      onChange={handleImagingUpload}
                      ref={fileInputRef} // Set the ref for the file input
                      style={{ display: "none" }} // Hide the file input
                    />
                    <PrimaryButton
                      onClick={handlePatientDetailsConfirm}
                      buttonText="Confirm"
                    />
                  </Box>
                </>
              )}
              {!showpatientdetails && data && data.report_info && (
                <>
                  <Box overflowY="auto">
                    <Box
                      bg="#061027"
                      minH="180px"
                      p="18px 28px"
                      display="flex"
                      flexDir="column"
                      gap="8px"
                    >
                      {/* <Box display="flex" gap="8px"> */}
                      {/* Assuming you want to create buttons based on some properties of report_info */}
                      {/* Modify the property names accordingly */}
                      {/* {Object.keys(data.report_info).map((property, i) => (
            <Box
              as="button"
              key={i}
              p="0"
              borderTop={`3px solid ${i === activeTestIndex ? '#14C585' : '#212b40'}`}
              w="16.666%"
              h="20px"
            ></Box>
          ))} */}
                      {/* </Box> */}
                      <Box>
                        {/* <Box display="flex" flexDir="row" justifyContent="space-between" gap="5px" alignItems="center">
           
          
          </Box> */}
                        <Box
                          display="flex"
                          flexDir="row"
                          justifyContent="space-between"
                          gap="5px"
                          alignItems="center"
                        >
                          <Box fontSize="20px" fontWeight="600" color="#E6E7E9">
                            {!showedittestname && (
                              <>
                                {customInput.report_info.name
                                  ? customInput.report_info.name
                                  : "-"}
                                <Image
                                  w="13px"
                                  onClick={handleedittestname}
                                  h="13px"
                                  display="inline-block"
                                  ml="15px"
                                  src="/assets/imgs/edit-white.png"
                                />
                              </>
                            )}
                            {showedittestname && (
                              <>
                                <Box>
                                  <Box
                                    display="flex"
                                    flexDir="row"
                                    justifyContent="space-between"
                                    gap="5px"
                                    alignItems="center"
                                  >
                                    <InputGroup>
                                      <Input
                                        type="text"
                                        defaultValue={
                                          customInput.report_info.name
                                        }
                                        color="white"
                                        fontSize="16px"
                                        fontWeight="600"
                                        border="2px solid white"
                                        borderRadius="10px"
                                        height="48px"
                                        minW="50px"
                                        onChange={(e) =>
                                          setReportName(e.target.value)
                                        }
                                      />
                                      <InputRightElement top="4px">
                                        <Box
                                          as="button"
                                          bg="white"
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          height="48px"
                                          minW="50px"
                                          borderRadius="0px 10px 10px 0"
                                          onClick={handleTestNameUpdate}
                                        >
                                          <Image src="/assets/imgs/check-black.png" />
                                        </Box>
                                      </InputRightElement>
                                    </InputGroup>
                                  </Box>
                                </Box>
                              </>
                            )}
                            {/* <Box as="button" border="1px solid #0B1F4F" color="white" fontSize="12px" fontWeight="600" borderRadius="full" p="5px 10px">
                          Learn more
                        </Box> */}
                            <Box
                              as="p"
                              pt="15px"
                              fontSize="14px"
                              fontWeight="400"
                              lineHeight="normal"
                              color="white"
                            >
                              {customInput.report_info.description || "-"}
                            </Box>
                          </Box>
                        </Box>
                        {/* Other UI components based on properties of report_info */}
                      </Box>
                    </Box>
                    <Box overflowY="auto" p="24px 32px">
                      <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">
                        Normal results
                      </Box>
                      <Box as="h5" fontSize="16px" pb="20px" fontWeight="300">
                        {" "}
                        {data.results_interpretation.normal_results || "-"}
                      </Box>

                      <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">
                        Abnormal results
                      </Box>
                      <Box as="h5" fontSize="16px" pb="20px" fontWeight="300">
                        {" "}
                        {data.results_interpretation.abnormal_results || "-"}
                      </Box>
                      <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">
                        Report Interpretition
                      </Box>
                      <Box as="h5" fontSize="16px" pb="20px" fontWeight="300">
                        {" "}
                        {data.results_interpretation.report_interpretation ||
                          "-"}
                      </Box>
                    </Box>
                  </Box>
                  <Box w="100%" p="18px 28px" mt="auto">
                    <PrimaryButton
                      buttonText={"Confirm"}
                      isDisabled={isSubmitting}
                      onClick={handleConfirm}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Flex>
        )}
      </Box>
      <DynamicModal
        ref={consentForm}
        modalAttributes={{ closeOnOverlayClick: false }}
        hasCloseBtn={false}
        customClose={true}
        customCloseClick={closeconsentform}
        modalStyle={{ minW: "600px", maxW: "600px", maxH: "100vh" }}
        modalHeader="Consent Form"
        modalBody={
          <>
            <Box
              style={{ height: "300px", overflowY: "scroll" }}
              p={6}
              h="calc(80vh - 100px)"
              overflow="hidden auto"
              lineHeight="1.6"
              ref={modalBodyRef}
            >
              <Heading as="h2" fontSize="1.5rem" mb={4}>
                Our Commitment to Privacy
              </Heading>

              <Text mb={4}>
                At Midoc, your privacy is paramount. We are committed to
                protecting the privacy of your personal information while
                providing you with the highest level of service. This Privacy
                Policy explains how Midoc, LLC ("Midoc," "we," or "us") may
                collect, use, and share your personal information when you
                access our services through the Midoc website or our mobile
                application (collectively, the "Services").{" "}
              </Text>

              <Heading as="h3" size="md" mb={2}>
                Consent
              </Heading>

              <Text mb={4}>
                By using our Services, you consent to the terms outlined in this
                Privacy Policy. If you do not agree with any part of this
                policy, please do not use our Services. Continued use of the
                Services following any changes to this policy will signify your
                acceptance of those changes.{" "}
              </Text>

              <Text mb={2}> Personal Information You Provide:</Text>
              <UnorderedList mb={4}>
                <ListItem>
                  <strong>Registration and Account Setup:</strong> We collect
                  personal details when you register or set up an account, which
                  may include your name, email address, phone number, and
                  address.
                </ListItem>
                <ListItem>
                  <strong>Health Records:</strong> Through our mobile
                  application, you may voluntarily provide medical and
                  health-related information to enhance service customization.
                </ListItem>
                <ListItem>
                  <strong>Interactions:</strong> We gather information when you
                  interact with our Services, such as customer service
                  inquiries, responses to surveys, and participation in
                  promotions.
                </ListItem>
              </UnorderedList>

              <Text mb={2}> Automated Information Collection:</Text>
              <UnorderedList mb={4}>
                <ListItem>
                  <strong>Cookies and Tracking Technologies:</strong> We use
                  cookies to enhance your experience, personalize content and
                  ads, and analyze our site traffic. We also employ web beacons
                  to collect aggregate statistics about website usage.
                </ListItem>
                <ListItem>
                  <strong>Geo-location Data:</strong> With your permission, we
                  may collect precise location data to provide location-specific
                  services.
                </ListItem>
              </UnorderedList>

              <Text mb={2}> How We Use Your Information</Text>
              <UnorderedList mb={4}>
                <ListItem>
                  <strong>Service Provision:</strong> To manage your account,
                  provide customer support, process transactions, and send
                  important account and service information.
                </ListItem>
                <ListItem>
                  <strong>Improvement and Personalization: </strong> To enhance
                  and personalize your experience, and to develop new features
                  and services that meet your needs.
                </ListItem>
                <ListItem>
                  <strong>Compliance and Protection:</strong>To comply with
                  legal requirements and protect the rights and safety of Midoc
                  and its users.
                </ListItem>
              </UnorderedList>

              <Text mb={2}> Sharing of Information</Text>
              <UnorderedList mb={4}>
                <ListItem>
                  <strong>With Service Providers:</strong>We share information
                  with third-party service providers to facilitate services on
                  our behalf, such as payment processing, data analysis, email
                  delivery, and hosting services.
                </ListItem>
                <ListItem>
                  <strong>Legal and Safety Reasons:</strong>We may disclose
                  information to respond to legal processes or to protect the
                  rights, property, or safety of Midoc, our users, or others.
                </ListItem>
                <ListItem>
                  <strong>Business Transfers: </strong>In the event of a merger,
                  sale, or asset transfer, your information may be disclosed and
                  transferred as part of the transaction.
                </ListItem>
              </UnorderedList>

              <Text mb={2}> Your Rights and Choices</Text>
              <UnorderedList mb={4}>
                <ListItem>
                  <strong>Account Information: </strong>You can review, update,
                  or delete your account information at any time.
                </ListItem>
                <ListItem>
                  <strong>Cookies: </strong> You can configure your browser to
                  reject cookies or to alert you when cookies are being sent.
                </ListItem>
                <ListItem>
                  <strong>Opt-Out: </strong>You may opt out of receiving
                  promotional communications from us by following the
                  instructions in the communication.
                </ListItem>
              </UnorderedList>

              <Heading as="h3" size="md" mb={2}>
                Children’s Privacy
              </Heading>

              <Text mb={4}>
                We do not knowingly collect or solicit information from anyone
                under the age of 13. If you are under 13, please do not attempt
                to register for the Services or send any personal information
                about yourself to us.
              </Text>

              <Heading as="h3" size="md" mb={2}>
                International Users.
              </Heading>

              <Text mb={4}>
                Our Services are hosted in the United States and are intended
                for users located within the United States. If you are located
                outside of the United States, please be aware that any
                information you provide to us will be transferred to and
                processed in the United States.
              </Text>

              <Heading as="h3" size="md" mb={2}>
                Changes to This Policy
              </Heading>

              <Text mb={4}>
                We may update this Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page. We encourage you to review our Privacy Policy
                whenever you access the Services to stay informed about our
                information practices and the ways you can help protect your
                privacy.
              </Text>

              <Text mb={4}>
                If you have any questions about this Privacy Policy or our
                privacy practices, please contact us at: privacy@midoc.com.
              </Text>
            </Box>
          </>
        }
        hasFooter={true}
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Agree" onClick={submitconsentform} />
          </>
        }
      />
    </>
  );
}

export default ImagingUpload;
