
import { CONFIG } from '../../../appconfig/appconfig';
import axios from 'axios';
import  FetchData, { } from '../../client';
import { getLoginInfo } from '../../../auth';


const FetchGenericDocument = async (uri,name,type) => {
  //console.log("test first")
  try {     
    const formData = new FormData();
    formData.append("file", uri);

    
    let patientid = getLoginInfo();
    formData.append("patientid", patientid);
    //console.log(formData);
    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_genericreport`,formData, {    
      headers: {
        'Content-Type': 'multipart/form-data', 
      },
    });
    //'@@response',response)
    if (response.status === 200) {
      const result = await response.data;
      return result
    } else {
      const error = "failed" ;
      return error
    }
  } catch (err) {
    console.log(err); 
    const error = "failed" ;
    return error
  }
};
  const FetchPrescriptionData = async (uri,name,type) => {
    //console.log("test first")
    try {     
      const formData = new FormData();
      formData.append("file", uri);

      
      let patientid = getLoginInfo();
      formData.append("patientid", patientid);
      //console.log(formData);
      const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_labreport`,formData, {    
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
      });
      //'@@response',response)
      if (response.status === 200) {
        const result = await response.data;
        return result
      } else {
        const error = "failed" ;
        return error
      }
    } catch (err) {
      console.log(err); 
      const error = "failed" ;
      return error
    }
  };
  const FetchPrescriptionDataPDF = async (uri,name,type) => {
    //console.log("test first")
    try {     
      const formData = new FormData();
      formData.append("file", uri);

      
      let patientid = getLoginInfo();
      formData.append("patientid", patientid);
      //console.log(formData);
      const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_labreport_pdf`,formData, {    
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
      });
      //'@@response',response)
      if (response.status === 200) {
        const result = await response.data;
        return result
      } else {
        const error = "failed" ;
        return error
      }
    } catch (err) {
      console.log(err); 
      const error = "failed" ;
      return error
    }
  };
  const AddMedicalRecord = async (inputData) => {
    try {
      
      const result = await FetchData("midoc_addmedicalrecord", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching medical conditions data:', error);
      throw error;
    }
  };
  const AiAbnormalCall = async (inputData) => {
    try {
      //console.log(inputData);
      
      inputData.patientid = getLoginInfo();
      const result = await FetchData("midoc_ai_labreportprompts", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching:', error);
      throw error;
    }
  };
  const FetchPrescriptionuploadData = async (uri,name,type) => {
    try {     
      const formData = new FormData();
      formData.append("file", uri);
      //console.log(formData);
      
       let patientid = getLoginInfo();
       formData.append("patientid", patientid);

      const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_prescription`,formData, {    
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
      });
      //console.log('@@response',response)
      if (response.status === 200) {
        const result = await response.data;
        return result
      } else {
        const error = "failed" ;
        return error
      }
    } catch (err) {
      console.log(err); 
      const error = "failed" ;
      return error
    }
  };
  const lablearnMore = async (inputData) => {
    try {
      
      const result = await FetchData("midoc_ai_lablearnmore", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching medical conditions data:', error);
      throw error;
    }
  };
export {FetchGenericDocument, FetchPrescriptionData,FetchPrescriptionDataPDF,AddMedicalRecord,AiAbnormalCall,FetchPrescriptionuploadData, lablearnMore};
