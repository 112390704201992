import formAPISocialHistory from "./socialhistory";


var formattedOutput = null;

const formAPIModelSocialHistory = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data?.map(item => formAPISocialHistory(item));


  return formattedOutput
};

export default formAPIModelSocialHistory