import moment from "moment";
export default function formAPIDoctorServices({
    id,
servicename,

  
})
{ 
  return Object.freeze({
    id,
servicename,
  });
}