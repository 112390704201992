import React, { useEffect, useState, useLayoutEffect } from "react";
import {
  FetchVitalsData,
  AddVitalData,
  FetchAIAnalysis,
  FetchAIAnswer,
} from "../../middleware/services/myhealth/vitals";
import ColumnChart from "./barcharts";
import moment from "moment";
import Navbar from "../../components/navbar";
import SideBar from "../../components/sidebar";
import SecondaryNav from "../../components/seconday-nav";
import { Box, Flex, Text } from "@chakra-ui/layout";
import {
  Stack,
  VStack,
  Circle,
  Spacer,
  Divider,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  RadioGroup,
  Radio,
  SimpleGrid
} from "@chakra-ui/react";
import GrayCard from "../../components/card";
import { Image } from "@chakra-ui/image";
import AreaPlotChart from "./areaplotchart";
import GroupedColumnPlot from "./groupedcolumnchart";
import GaugeChart from "./guagechart";
import TabSection from "../../components/tabs";
import {
  PrimaryButton,
  PrimaryInput,
  Toaster,
  PrimaryButtonIcon,
  DropdownInput,
} from "../../components";
import ModalRight from "../../components/modal-right";
import Vitalsmodel from "./vitalsdata/vitalcomponent";
import AiModal from "../../components/ai-modal";
import { useNavigate } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import DatePicker from "../../components/date-picker";
import { calculateBMIStatus, displayMillion_ThoudandValues, ftToCmConverter, ftToCmConverterBasic, getDob, getgender, getusername, kgToLbConverter, lbToKgConverterUsingDBValue } from "../../auth";
import TypingLoader from "../../components/typing-loader";
import { format, isValid, parse } from "date-fns";
import DateRangeIndex from "../../components/date-range";



import GroupedColumnRangePlot from "./columnrangechart";
import PointChart from "./pointchart";
import Footer from "../../components/footer"
import DropdownInputForVitals from "../../components/dropdown-input-forVitals";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { conversionTag } from "@antv/g2plot/lib/adaptor/conversion-tag";
import AreaPlotChartHeight from "./areaplotchartheight";
import { FetchTokens } from "../../middleware/services/profile/rewards";



const VitalsIndex = () => {
  const [data, setData] = useState(null);
  const [patientid, setPatientid] = useState();
  var navigate = useNavigate();
  const today = moment().format("YYYY-MM-DD HH:mm:ss.SSS");
  const thisweekstartdate = moment()
    .startOf("week")
    .format("YYYY-MM-DD HH:mm:ss.SSS");
  const thismonthstartdate = moment()
    .startOf("month")
    .format("YYYY-MM-DD HH:mm:ss.SSS");
  const thisyearstartdate = moment()
    .startOf("year")
    .format("YYYY-MM-DD HH:mm:ss.SSS");
  // This is to by default set this week dates
  const [activeTab, setActiveTab] = useState(thisyearstartdate);
  //Add data model and forms common model
  const adddata = React.useRef();
  const vitalsModals = React.useRef();
  const vitalsbmiModals = React.useRef();
  const AiQuestions = React.useRef();
  const AIChatModel = React.useRef();
  const chatContainerRef = React.useRef(null);
  //Ai Response state
  const [aiquestion, setAIAnalysis] = useState(null);
  //Ai Answer state
  const [aianswers, setAIAnswers] = useState(null);
  const [remainingquestions, setRemainingQuestions] = useState("");
  //Vital Add Form Header Name, Icon, Body Name, Unit states
  const [headername, setHeaderName] = useState("");
  const [headericon, setHeaderIcon] = useState("");
  const [vitalname, setVitalName] = useState("");
  const [vitalunit, setVitalUnit] = useState("");
  const [vitaldynamicunit, setVitalDynamicUnit] = useState("");
  const [vitalBPdynamicunit, setBPVitalDynamicUnit] = useState("false");
  const [vitalHeightdynamicunit, setHeightVitalDynamicUnit] = useState("false");
  const [vitalWeightdynamicunit, setWeightVitalDynamicUnit] = useState("false");
  const [showdropdownvitalunit, setShowdropdownunit] = useState(false);
  // Vital Add Form Date and Time Input Fields States
  const [date, setDate] = useState(moment().format("DD-MM-yyyy"));
  const [time, setTime] = useState(moment().format("HH:mm"));
  const [timeWithSeconds, setTimeWithSeconds] = useState("");
  const [incrementalSeconds, setIncrementalSeconds] = useState("00");
  //Vital Add Form Values given by the patient and BP is for blood pressure where we have systolic/diastolic
  const [vitalvalue, setVitalValue] = useState("");
  const [vitalvalueBP, setBPValueName] = useState("");
  const [vitalvalueunit, setVitalValueUnit] = useState("");
  //With this state we are checking if Blood Pressure Form is open or not to display extra value option
  const [activepage, setActivePage] = useState("");
  const { showSuccessToast, showErrorToast } = Toaster();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [weightlabel, setWeightLabel] = useState(null);
  const [heightlabel, setHeightLabel] = useState(null);
  const [oxygensaturationlabel, setOxygenSaturationLabel] = useState(null);
  const [pulselabel, setPulseLabel] = useState(null);
  const [bodytemplabel, setBodyTempLabel] = useState(null);
  const [respiratorylabel, setRespiratoryLabel] = useState(null);
  const openCalendar = React.useRef(null)
  const openCalendarMob = React.useRef(null)
  const [weightvalue, setWeightValue] = useState("");
  const [heightvalue, setHeightValue] = useState("");
  const [vitalunitweight, setVitalUnitWeight] = useState("");
  const [vitalunitheight, setVitalUnitHeight] = useState("");
  const [vitalunitheight_cd, setVitalUnitHeight_cd] = useState("");
  const [BMICalculatorType, setBMICalculatorType] = useState('standard');
  const [options, setOptions] = useState([]);
  const [feetOptions, setFeetOptions] = useState([]);
  const [inchesOptions, setInchesOptions] = useState([]);
  const [heightOptionsInBMI, setHeightOptionsInBMI] = useState([]);
  const [weightOptionsInBMI, setWeightOptionsInBMI] = useState([]);
  const [optionsForBP, setOptionsForBP] = useState([]);
 const [ heightValuesToNumeric , setHeightValuesToNumeric ]= useState("")

 const [ weightUnitBMI , setWeightUnitBMI ]= useState("")
 const [ heightUnitBMI , setHeightUnitBMI ]= useState("")
 
 const [showInchesInput , setShowInchesInput] = useState(true)
 const [feetValue , setFeetValue] = useState("")
 const [inchValue , setInchValue] = useState("")

 const [inchValueToCalculateInitial, setInchValueToCalculateInitial] = useState(0);
 const [weightvalueInitial, setweightvalueInitial] = useState("");
 const [isCalendarVisible, setCalendarVisibility] = useState(false);

 const [valueToCalculateFromLb, setValueToCalculateFromLb] = useState("");
 const [initailRender, setInitialRender] = useState(true);

 const [upgradePlanModal , setUpgradePlanModal] = useState(false)
 
 const [remainingTokens, setRemainingTokens] = useState();

  useEffect(() => {
    getTokens();
  }, [])
  var tokensinput = {}
  var getTokens = async () => {
    try {
      var result = await FetchTokens(tokensinput);
      if (result) {
        setRemainingTokens(result)
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.log(error, "error in fetching account data")
    }
    //dismissLoader();
  }
  let qaArray = [];

  useEffect(() => {
  var secondsFromLocal = localStorage.getItem("incrementalSeconds")
  if(secondsFromLocal){
    setIncrementalSeconds(secondsFromLocal)
    
  }
    fetchData();
  }, []);
  useEffect(()=>{
    if(vitalunitheight == "cms"){
      setVitalUnitHeight_cd(151)
    }else if ( vitalunitheight == "ft"){
      setVitalUnitHeight_cd(152)
    }
  },[vitalunitheight])

  function formatNumber(value) {
    const str = value.toString();
    const match = str.match(/(\d+)(\.(\d+))?/);
    
    if (match && match[3]) {
        const decimalPlaces = match[3].length;
        if (decimalPlaces === 2) {
            return parseFloat(str).toFixed(2);
        }
    }
    
    return parseFloat(str);
}


  const fetchData = async () => {
    setAIAnswers(null);
    showLoader();
    try {
      const result = await FetchVitalsData({
        patientid,
        type: "",
        fromdate: thisyearstartdate,
        todate: today,
      },"Year");
      if (result != null) {
        setData(result);

        if (result.weight.length > 0) {
          const weightobject = result.weight[result.weight.length - 1].value;
          const actualValue = result.weight[result.weight.length - 1].actualvitalvalue;
          setWeightLabel(weightobject);
          setWeightValue(weightobject)
          setweightvalueInitial(actualValue)
         // alert(actualValue)
          const weightUnitobject = result.weight[result.weight.length - 1].vitalunit;
          setWeightUnitBMI(weightUnitobject)
        }
        if (result.height.length > 0) {
          const heightobject = result.height[result.height.length - 1].value;
          
          setHeightLabel(heightobject);
          setHeightValue(heightobject)
          if(heightobject != null){
            let parts = heightobject.toString().split('.');
            setFeetValue(parts[0])
            setInchValue(parts[1])
          }

          // setting actual value of feet to calculate initially 
          const actualValue = result.height[result.height.length - 1].actualvitalvalue;
         // alert(actualValue)
          if(actualValue != null){
          let partsOfActulaValue = actualValue.toString().split('.');
            if(partsOfActulaValue[1]){

              setInchValueToCalculateInitial(partsOfActulaValue[1]);
            }else {
              setInchValueToCalculateInitial(0);

            }
          }

          const heightUnitObject = result.height[result.height.length - 1].vitalunit;
          setHeightUnitBMI(heightUnitObject)

          const convertedValuesToNeumeric = result.height.map(item => ({
            ...item,
            value: formatNumber(item.value)
        }));
          setHeightValuesToNumeric(convertedValuesToNeumeric)
        }
        if (result.oxygensaturation.length > 0) {
          const oxygensaturationobject =
            result.oxygensaturation[result.oxygensaturation.length - 1].value;
          setOxygenSaturationLabel(oxygensaturationobject);
        }
        if (result.pulse.length > 0) {
          const pulseobject = result.pulse[result.pulse.length - 1].value;
          setPulseLabel(pulseobject);
        }
        if (result.bodytempurature.length > 0) {
          const bodytempuratureobject =
            result.bodytempurature[result.bodytempurature.length - 1].value;
          setBodyTempLabel(bodytempuratureobject);
        }
        if (result.respiratoryrate.length) {
          const respiratoryrateobject =
            result.respiratoryrate[result.respiratoryrate.length - 1].value;
          setRespiratoryLabel(respiratoryrateobject);
        }

        dismissLoader();
      } else {
        //alert("No data");
      }
    } catch (error) {
      console.error("Error in DashboardIndex:", error);
    }
  };
  const handleTabClick = (index) => {
    switch (index) {
      case 0:
        setActiveTab(today);
        fetchFilterData(today, 'Today');
        break;
      case 1:
        setActiveTab(thisweekstartdate);
        fetchFilterData(thisweekstartdate, 'Week');
        break;
      case 2:
        setActiveTab(thismonthstartdate);
        fetchFilterData(thismonthstartdate, 'Month');
        break;
      case 3:
        setActiveTab(thisyearstartdate);
        fetchFilterData(thisyearstartdate, 'Year');
        break;
       
      default:
        break;
    }
  };

  const fetchFilterData = async (fromdate, type) => {
    showLoader();
    try {
      const result = await FetchVitalsData({
        patientid,
        type: "",
        fromdate: fromdate,
        todate: today,
      }, type);
      if (result != null) {
        setData(result);
        if (result.weight.length > 0) {
          const weightobject = result.weight[result.weight.length - 1].value;
          setWeightLabel(weightobject);
        }
        if (result.height.length > 0) {
          const heightobject = result.height[result.height.length - 1].value;
          setHeightLabel(heightobject);
          
          const convertedValuesToNeumeric = result.height.map(item => ({
            ...item,
            value: formatNumber(item.value),
        }));

          setHeightValuesToNumeric(convertedValuesToNeumeric)

        }
        if (result.oxygensaturation.length > 0) {
          const oxygensaturationobject =
            result.oxygensaturation[result.oxygensaturation.length - 1].value;
          setOxygenSaturationLabel(oxygensaturationobject);
        }
        if (result.pulse.length > 0) {
          const pulseobject = result.pulse[result.pulse.length - 1].value;
          setPulseLabel(pulseobject);
        }
        if (result.bodytempurature.length > 0) {
          const bodytempuratureobject =
            result.bodytempurature[result.bodytempurature.length - 1].value;
          setBodyTempLabel(bodytempuratureobject);
        }
        if (result.respiratoryrate.length) {
          const respiratoryrateobject =
            result.respiratoryrate[result.respiratoryrate.length - 1].value;
          setRespiratoryLabel(respiratoryrateobject);
        }
      } else {
       // alert("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in DashboardIndex:", error);
    }
  };
  const [ vitalValueToCalculateOnModalopen ,setVitalValueToCalculateOnModalopen] = useState("")
  // This is the click function of all vital forms where we are sending header and body dynamcally
  const openVitalform = (
    headername,
    headericon,
    vitalname,
    vitalunit,
    isActivePageTrue = false
  ) => {
    setHeaderName(headername);
    setHeaderIcon(headericon);
    setVitalName(vitalname);
    setVitalUnit(vitalunit);
    setActivePage(isActivePageTrue ? "True" : "");
    if (vitalname === "Temperatures") {
      setBPVitalDynamicUnit("true");
      setWeightVitalDynamicUnit("false");
      setHeightVitalDynamicUnit("false");
    } else if (
      vitalname === "Pulse" ||
      vitalname === "Systolic" ||
      vitalname === "Respiratory rate" ||
      vitalname === "Oxygen Saturation"
    ) {
      setBPVitalDynamicUnit("false");
      setWeightVitalDynamicUnit("false");
      setHeightVitalDynamicUnit("false");
    } else if (vitalname === "Weight") {
      setWeightVitalDynamicUnit("true");
      setBPVitalDynamicUnit("false");
      setHeightVitalDynamicUnit("false");
    } else if (vitalname === "Height") {
      setHeightVitalDynamicUnit("true");
      setBPVitalDynamicUnit("false");
      setWeightVitalDynamicUnit("false");
    }
    setDate(moment().format("DD-MM-yyyy"));
    setTime(moment().format("HH:mm"));
    setTimeWithSeconds(moment().format(`HH:mm:${incrementalSeconds}`));
    setIncrementalSeconds(prevSeconds => {
      let newSeconds = parseInt(prevSeconds, 10) + 1; // Increment the value
      if (newSeconds < 10) {
        newSeconds = "0" + newSeconds; // Ensure it's a two-digit string
      } else if (newSeconds >= 60) {
        newSeconds = "00"; // Reset to "00" after "59"
      }

    localStorage.setItem("incrementalSeconds", newSeconds.toString());
      return newSeconds.toString();
    });
    
    setVitalValue("");
    setBPValueName("");
    vitalsModals.current.openVitals();

    //setting options on modal open
    if(headername == "Body temperature"){
      const tempOptions = [];
      //  if(vitalunit == "Celsius"){
      //   for (let i = 32; i <= 52; i++) {
      //     tempOptions.push(i);
      //     for (let j = 1; j <= 9; j++) {
      //       tempOptions.push(parseFloat(`${i}.${j}`));
      //     }
      //   }
      // }else if(vitalunit == "Fahrenheit"){
      //   for (let i = 90; i <= 110; i++) {
      //     tempOptions.push(i);
      //     for (let j = 1; j <= 9; j++) {
      //       tempOptions.push(parseFloat(`${i}.${j}`));
      //     }
      //   }
      // }
      for (let i = 90; i <= 110; i++) {
        tempOptions.push(i);
        for (let j = 1; j <= 9; j++) {
          tempOptions.push((`${i}.${j}`));
        }
      }
      setOptions(tempOptions);
      
    }else if (headername == "Pulse"){
      const tempOptions = [];
      for (let i = 50; i <= 250; i++) {
        tempOptions.push(i);
      }
      setOptions(tempOptions);
    }
    else if (headername == "Blood pressure"){
      const tempOptions = [];
      for (let i = 10; i <= 260; i++) {
        tempOptions.push(i);
      }
      setOptions(tempOptions);
      setOptionsForBP(tempOptions);
      
    }
    else if (headername == "Respiratory rate"){
      const tempOptions = [];
      for (let i = 10; i <= 210; i++) {
        tempOptions.push(i);
      }
      setOptions(tempOptions);
    }
    else if (headername == "Oxygen Saturation"){
      const tempOptions = [];
      for (let i = 30; i <= 100; i++) {
        tempOptions.push(i);
      }
      setOptions(tempOptions);
    }
    else if (headername == "Weight"){
      setBMICalculatorType("standard")
      
      let convertedValue = kgToLbConverter(weightvalueInitial)
     // alert(convertedValue.value)
      setWeightValue(convertedValue.value)
      setVitalValueToCalculateOnModalopen(convertedValue.valueToCalculate)
      setVitalValue(convertedValue.value)
      setVitalUnit("lbs");
      
      setVitalUnitWeight("lbs");
      const tempOptions = [];
      ///alert(vitalunit)
        if(vitalunit){
          if(vitalunit == "kg"){
            for (let i = 1; i <= 250; i++) {
              tempOptions.push(i);
              // for (let j = 1; j <= 9; j++) {
              //   tempOptions.push(parseFloat(`${i}.${j}`));
              // }
            }
          }else{
            for (let i = 1; i <= 100; i++) {
              tempOptions.push(i);
              // for (let j = 1; j <= 9; j++) {
              //   tempOptions.push(parseFloat(`${i}.${j}`));
              // }
            }
          }
        }
      
      setOptions(tempOptions);
      const tempOptionsWeightLB = [];
      for (let i = 1; i <= 479; i++) {
             tempOptionsWeightLB.push(i);
           }
      setWeightOptionsInBMI(tempOptionsWeightLB)
      
    }else if (headername == "Height"){
      setVitalUnit("ft");
      setBMICalculatorType("standard")
      setVitalUnitHeight("ft");
      const tempOptions1 = [];
      ///alert(vitalunit)
      if(vitalunit){
        if(vitalunit == "cms"){
          for (let i = 30; i <= 303; i++) {
            tempOptions1.push(i);
            // for (let j = 1; j <= 9; j++) {
            //   tempOptions.push(parseFloat(`${i}.${j}`));
            // }
          }
        }else{
          for (let i = 1; i <= 10; i++) {
            tempOptions1.push(i);
            for (let j = 1; j <= 11; j++) {
              tempOptions1.push((`${i}.${j}`));
            }
          }
        }
      }
      
      setOptions(tempOptions1);

      const tempOptions = [];
      const tempOptionsInches = [];
      const tempOptionsHeight = [];
      const tempOptionsWeightLB = [];
      for (let i = 1; i <= 10; i++) {
        tempOptions.push(i);
      }
      for (let i = 1; i <= 11; i++) {
        tempOptionsInches.push(i);
      }
      for (let i = 1; i <= 10; i++) {
        tempOptionsHeight.push(i);
         for (let j = 1; j <= 11; j++) {
          tempOptionsHeight.push((`${i}.${j}`));
              }
      }
      for (let i = 1; i <= 479; i++) {
        tempOptionsWeightLB.push(i);
      }
      setFeetOptions(tempOptions)
      setInchesOptions(tempOptionsInches)
      setHeightOptionsInBMI(tempOptionsHeight)
      setWeightOptionsInBMI(tempOptionsWeightLB)
    }
   
  };
  //This is the click of BMI vital
  const openVitalBMIform = () => { 
  
    
    if(weightUnitBMI && weightUnitBMI == "kg"){

      var converterValue = kgToLbConverter(weightvalueInitial)
      setWeightValue(converterValue.value)
    }
    // if(heightUnitBMI && heightUnitBMI == "ft"){
    //   var converterValue = kgToLbConverter(weightvalue)
    //   setHeightValue(converterValue)
    // }
    setBMICalculatorType("standard")
    setVitalUnitHeight("ft");
    setVitalUnitWeight("lbs");
    setDate(moment().format("DD-MM-yyyy"));
    setTime(moment().format("HH:mm"));
    setVitalValue("");
    setBPValueName("");
    vitalsbmiModals.current.openVitals();

    const tempOptions = [];
    const tempOptionsInches = [];
    const tempOptionsHeight = [];
    const tempOptionsWeightLB = [];
    for (let i = 1; i <= 10; i++) {
      tempOptions.push(i);
    }
    for (let i = 1; i <= 11; i++) {
      tempOptionsInches.push(i);
    }
    for (let i = 1; i <= 10; i++) {
      tempOptionsHeight.push(i);
       for (let j = 1; j <= 11; j++) {
        tempOptionsHeight.push((`${i}.${j}`));
            }
    }
    for (let i = 1; i <= 479; i++) {
      tempOptionsWeightLB.push(i);
    }
    setFeetOptions(tempOptions)
    setInchesOptions(tempOptionsInches)
    setHeightOptionsInBMI(tempOptionsHeight)
    setWeightOptionsInBMI(tempOptionsWeightLB)
  };
  // This is onchange event of input fields
  const handlevitalvaluechange = (e) => {
    const { id, value, inputValue } = e.target;
    const maxLength = 6;
    switch (id) {
      case "vitalvalue":
        //let inputValue = value;
        // inputValue = inputValue.replace(/[^0-9.]/g, "");
        // inputValue = inputValue.replace(/\.(?=.*\.)/g, "");
        // inputValue = inputValue.replace(/(\.\d\d).+/g, "$1");
        // inputValue = inputValue.slice(0, 6);
        if(headername == "Blood pressure" || headername == "Pulse" || headername == "Respiratory rate" || headername == "Oxygen Saturation"){
          const inputText = e.target.value;
          if (inputText.length <= maxLength) {
          let cleanedValue = inputText.replace(/[^0-9]/g, '');
          if (cleanedValue.match(/[0-9]{4,}/)) {
            cleanedValue = cleanedValue.slice(0, -1);
          }
            setVitalValue(cleanedValue);
          }
        }else if(headername == "Body temperature" && vitalunit == "°F"){
         // alert("bt")
         const inputText = e.target.value;
         if (inputText.length <= maxLength) {
         let cleanedValue = inputText.replace(/[^0-9.]/g, '');
         if(cleanedValue.startsWith(".")){
           cleanedValue = cleanedValue.substring(1)
         }
         if (cleanedValue.match(/[0-9]{4,}/)) {
           cleanedValue = cleanedValue.slice(0, -1);
         }
         const decimalIndex = cleanedValue.indexOf('.');
         if (decimalIndex !== -1) {
           const parts = cleanedValue.split('.');
           cleanedValue = parts[0] + '.' + parts[1].slice(0, 1);
         }
           setVitalValue(cleanedValue);
         }

        }
        else{
          const inputText = e.target.value;
          if (inputText.length <= maxLength) {
          let cleanedValue = inputText.replace(/[^0-9.]/g, '');
          if(cleanedValue.startsWith(".")){
            cleanedValue = cleanedValue.substring(1)
          }
          if (cleanedValue.match(/[0-9]{4,}/)) {
            cleanedValue = cleanedValue.slice(0, -1);
          }
          const decimalIndex = cleanedValue.indexOf('.');
          if (decimalIndex !== -1) {
            const parts = cleanedValue.split('.');
            cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
          }
            setVitalValue(cleanedValue);
          }
        }
        
     
        break;
      case "vitalvalueunit":
        setVitalValueUnit(value);
        break;
      case "vitalvalueBP":
       const input = e.target.value;
        if (input.length <= maxLength) {
          
          let cleanedValue = input.replace(/[^0-9]/g, '');
          if (cleanedValue.match(/[0-9]{4,}/)) {
            cleanedValue = cleanedValue.slice(0, -1);
          }
          // const decimalIndex = cleanedValue.indexOf('.');
          // if (decimalIndex !== -1) {
          //   const parts = cleanedValue.split('.');
          //   cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
          // }
          setBPValueName(cleanedValue);
          }
        break;
       
      default:
        break;
    }
  };
  const [ weightInputChanged, setWeightInputChanged] = useState(false)
  // THis is BMI on change event
  const handlebmivitalvaluechange = (e) => {
    const { id, value } = e.target;
    const maxLength = 6;
    switch (id) {
      case "weightvalue":
        let inputValue = value;
       const inputText = e.target.value;
      // alert(inputText)
       setWeightInputChanged(true)
        if (inputText.length <= maxLength) {
          
        let cleanedValue = inputText.replace(/[^0-9.]/g, '');
        if(cleanedValue.startsWith(".")){
          cleanedValue = cleanedValue.substring(1)
        }
        if (cleanedValue.match(/[0-9]{4,}/)) {
          cleanedValue = cleanedValue.slice(0, -1);
        }
        const decimalIndex = cleanedValue.indexOf('.');
        if (decimalIndex !== -1) {
          const parts = cleanedValue.split('.');
          cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
        }
        setWeightValue(cleanedValue);
        setVitalValue(cleanedValue)
        }
        break;
      case "heightvalue":
        const input = e.target.value;
      
        if (input.length <= maxLength) {
          
        let cleanedValue = input.replace(/[^0-9.]/g, '');
        if(cleanedValue.startsWith(".")){
          cleanedValue = cleanedValue.substring(1)
        }
        if (cleanedValue.match(/[0-9]{4,}/)) {
          cleanedValue = cleanedValue.slice(0, -1);
        }
        const decimalIndex = cleanedValue.indexOf('.');
        if (decimalIndex !== -1) {
          const parts = cleanedValue.split('.');
          cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
        }
        setHeightValue(cleanedValue);
        setVitalValue(cleanedValue)
        }
        break;
       
      default:
        break;
    }
  };
  //This is input for all Forms and separate input for BP form

  let input = {
    patientid: "",
    vitals: [
      {
        vitalname: headername,
        vitalvalue: vitalvalue,
        vitalunit: vitalunit,
        vitaltype_cd: "",
        vitaladdeddate: `${date} ${timeWithSeconds}`,
      },
    ],
  };

  let BPinput = {
    patientid: "",
    vitals: [
      {
        vitalname: "Systolic Blood Pressure",
        vitalvalue: vitalvalue,
        vitalunit: vitalunit,
        vitaltype_cd: "",
        vitaladdeddate: `${date} ${time}`,
      },
      {
        vitalname: "Diastolic Blood Pressure",
        vitalvalue: vitalvalueBP,
        vitalunit: vitalunit,
        vitaltype_cd: "",
        vitaladdeddate: `${date} ${time}`,
      },
    ],
  };
  // Get Patient details for AI Calls

  const patientname = getusername();
  const dob = getDob();
  let patientgender = getgender();
  let gender = patientgender;
  if (gender === 1) {
    gender = "Female";
  } else if (gender === 2) {
    gender = "Male";
  } else if (gender === 3) {
    gender = "Others";
  }else{
    gender = "Male";
  }
  function calculateAge(dateOfBirth) {
    const today = new Date();
    const dob = new Date(dateOfBirth);
    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }
    return age;
  }
  const age = calculateAge(dob);

  const validationRules = {
    "Weight": {
      "kg": { min: 1, max: 250 },
      "lbs": { min: 1, max: 479 }
    },
    "Height": {
      "cms": { min: 30, max: 303 },
      "ft": { min: 1, max: 10.11 }
    },
    "Body temperature": {
      "°F": { min: 90, max: 110.9 },
      "°C": { min: 32, max: 52.99 }
    },
    "Respiratory rate":  { min: 10, max: 210.99 },
  };
  const validationRulesCustom = {
    "Respiratory rate":  { min: 10, max: 210.99 },
    "Oxygen Saturation":  { min: 30, max: 100 },
    "Blood pressure":  { min: 10, max: 260.99 },
    "Pulse":  { min: 50, max: 250.99 },
  };

  useEffect(()=>{
    if(vitalunit == "ft"){
      var heightValue = feetValue && inchValue ? feetValue + "." + inchValue : feetValue;
      //var heightValue = feetValue + "." + inchValue;
      setVitalValue(heightValue)

    }else if (vitalunit == "cms"){
      setVitalValue(heightvalue)
    }
  },[feetValue, inchValue, vitalunit])
  const Submitvitalform = async () => {
    //  var date = document.getElementById("vitaldate")
    //  var dateinput = date.value;

    //  var time = document.getElementById("vitaltime")
    //  var timeinput = time.value;

    let min, max;

    if (validationRules[headername] && validationRules[headername][vitalunit]) {
      min = validationRules[headername][vitalunit].min;
      max = validationRules[headername][vitalunit].max;
      console.log( validationRules[headername][vitalunit] , " validationRules[headername][vitalunit] ")
    } else if (validationRulesCustom[headername]) {
      min = validationRulesCustom[headername].min;
      max = validationRulesCustom[headername].max;
      
      console.log( validationRules[headername] , " validationRules[headername] ")
    } else {
      // Handle case where validation rules are not found
      console.error("Validation rules not found for the measurement");
      return false;
    }

    if (moment(date, "DD-MM-YYYY", true).isValid()) {
      const formatteddate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
      const vitaldate = formatteddate + " " + timeWithSeconds;
      input.vitals[0].vitaladdeddate = vitaldate;
      BPinput.vitals[0].vitaladdeddate = vitaldate;
      BPinput.vitals[1].vitaladdeddate = vitaldate;
    }
    setIsSubmitting(true);
    // var value = document.getElementById("vitalvalue");
    // var valueinput = value.value;
    
    var valueinput = vitalvalue;
    let decimalPartAsInt ;
    const valueStr = vitalvalue ?  vitalvalue.toString(): "";
    const decimalIndex = valueStr.indexOf('.');
    if (decimalIndex !== -1) {
        const decimalPart = valueStr.substring(decimalIndex + 1);
         decimalPartAsInt = parseInt(decimalPart, 10);
    }
    if (activepage === "True") {
      var value = document.getElementById("vitalvalueBP");
      var BPvalueinput = value.value;
    }
    //  if(dateinput == ""){
    //    showErrorToast("Please select any date")
    //    return false;
    //  }else if(timeinput == ""){
    //    showErrorToast("Please select time")
    //    return false;
    //  }else
    if (valueinput == "") {
      if(headername == "Blood pressure"){

        showErrorToast("Please enter systolic value");
      }
      else{
      showErrorToast("Please enter any value ");
      }
      return false;
    } else if (activepage === "True" && vitalvalueBP == "") {
      showErrorToast("Please enter diastolic value ");
      return false;
    } else  if (!valueinput){

      showErrorToast("Please enter any value");
      return false;
    }
else  if (valueinput < min || valueinput > max) {
  
  if(headername == "Blood pressure"){

    showErrorToast("Please enter a valid systolic value");
  }else{
    showErrorToast("Please enter a valid value");
  }
  return false;
} 
else  if (headername === "Height" && vitalunit === "ft" && decimalPartAsInt >= 12) {

      showErrorToast("Please enter a valid height value in feet");
      return false;

}
else if (activepage === "True" && (vitalvalueBP < 10 || vitalvalueBP > 260)) {
  showErrorToast("Please enter valid diastolic value ");
  return false;
}
   else {
      if (activepage === "True") {
        input = BPinput;
      }
      try {
        showLoader();
        const result = await AddVitalData(input);
        if (result.output.success == 1) {
          let AiInput = {
            type: headername,
            value: vitalvalue,
            unit: vitalunit,
            gender: gender,
            age: age,
            name: patientname,
          };
          const response = await FetchAIAnalysis(AiInput);
          dismissLoader(); 
          if (response.generated_question) {
            setAIAnalysis(response);
            AiQuestions.current.openRight();
          }else if (response?.output?.message == "No credits are available"){
           // showErrorToast("No sufficient tokens available");
            vitalsModals.current.closeVitals();
            vitalsbmiModals.current.closeVitals();
            fetchData();
            setUpgradePlanModal(true)
          setTimeout(() => {
            setUpgradePlanModal(false)
          }, 100);
            // setTimeout(() => {
              
            // window.location.reload();
            // }, 1000);
          } else {
            window.location.reload();
          }
        } else {
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error in SubmitFunction:", error);
      }
   // }
  }
  };
  const SubmitBMIform = async () => {   
    let Weightinput = {
      patientid: "",
      vitals: [
        {
          vitalname: "Weight",
          vitalvalue: weightvalue,
          vitalunit: vitalunitweight,
          vitaltype_cd: "",
          vitaladdeddate: `${date} ${time}`,
        },
      ],
    };
    var customHeightValue;
    if(BMICalculatorType == "standard"){
      customHeightValue = feetValue;
      if(inchValue && feetValue){
        
        customHeightValue = feetValue && inchValue ? feetValue + "." + inchValue : feetValue;
      }
    }else{
      customHeightValue = heightvalue;
    }
    let Heightinput = {
      patientid: "",
      vitals: [
        {
          vitalname: "Height",
          vitalvalue:  customHeightValue,
          vitalunit: vitalunitheight,
          vitaltype_cd: "238",
          vitaladdeddate: `${date} ${time}`,
        },
      ],
    };
    if (moment(date, "DD-MM-YYYY", true).isValid()) {
      const formatteddate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
      const vitaldate = formatteddate + " " + time;
      Weightinput.vitals[0].vitaladdeddate = vitaldate;
      Heightinput.vitals[0].vitaladdeddate = vitaldate;
    }
    setIsSubmitting(true);
    var value = document.getElementById("weightvalue");
    var weightinput = value.value;

      var value = document.getElementById("heightvalue");
      var heightinput = value.value;
      
    if (weightinput == "") {
      showErrorToast("Please enter weight value ");
      return false;
    } else if (BMICalculatorType == "standard" && (weightinput < 40 || weightinput > 479)) {
      showErrorToast("Please enter a valid weight value ");
      return false;
    }else if (BMICalculatorType == "metric" && (weightinput < 1 || weightinput > 250)) {
      showErrorToast("Please enter a valid weight value ");
      return false;
    } else if (heightinput == "") {
      showErrorToast("Please enter height value ");
      return false;
    } else if (BMICalculatorType == "standard" && (heightinput < 1 || heightinput > 12) ) {
      showErrorToast("Please enter a valid height value ");
      return false;
    }else if (BMICalculatorType == "standard" &&  (inchValue && ( inchValue < 0 || inchValue > 12)) ) {
      showErrorToast("Please enter a valid inch value");
      return false;
    }else if (BMICalculatorType == "metric" && (heightinput < 30 || heightinput > 303)) {
      showErrorToast("Please enter a valid height value ");
      return false;
    } else {
      try {
        showLoader();
        const response = await AddVitalData(Weightinput);
        
        if (response.output.success == 1) {
          const result = await AddVitalData(Heightinput);
          if (result.output.success == 1) {
          let AiInput = {
            type: "Weight",
            value: weightvalue,
            unit: vitalunitweight,
            gender: gender,
            age: age,
            name: patientname,
          };
          const response = await FetchAIAnalysis(AiInput);
          dismissLoader();
          if (response.generated_question) {
            setAIAnalysis(response);
            AiQuestions.current.openRight();
          }else if (response?.output?.message == "No credits are available"){
            //showErrorToast("No sufficient tokens available");
            vitalsModals.current.closeVitals();
            vitalsbmiModals.current.closeVitals();
            fetchData();
            setUpgradePlanModal(true)
          setTimeout(() => {
            setUpgradePlanModal(false)
          }, 100);
            // setTimeout(() => {
              
            // window.location.reload();
            // }, 1000);
          } else {
            window.location.reload();
          }
        }
        } else {
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error in SubmitFunction:", error);
      }
    }
  };
  const handleSkip = () => {
    window.location.reload();
  };

  const GetAIAnswer = async (prompt) => {
    AIChatModel.current.openRight();
    let AichatInput = {
      type: headername,
      value: vitalvalue,
      unit: vitalunit,
      gender: gender,
      age: age,
      name: patientname,
      prompt: prompt,
    };

    // Display question immediately
    const newQuestion = { question: prompt, answer: "" };
    setAIAnswers((prevState) => {
      if (prevState === null) {
        return [newQuestion];
      } else {
        return [...prevState, newQuestion];
      }
    });
    //showLoader();
    const response = await FetchAIAnswer(AichatInput);
    // dismissLoader();

    if (response) {
      // Replace "typing" with actual answer
      setAIAnswers((prevState) => {
        const updatedAnswers = prevState.map((item) => {
          if (item.question === prompt && item.answer === "") {
            return { question: item.question, answer: response.answer };
          }
          return item;
        });
        getTokens();
        return updatedAnswers;
      });
      const indexesToRemove = [];
      aiquestion.generated_question.forEach((question, index) => {
        if (question.value === prompt) {
          indexesToRemove.push(index);
        }
      });

      // Remove the objects at the indexes collected above
      indexesToRemove.reverse().forEach((index) => {
        aiquestion.generated_question.splice(index, 1);
      });
      setRemainingQuestions(aiquestion.generated_question);

      window.scrollTo(0, document.body.scrollHeight);
    } else {
    }
  };
  const handleMenuItemClick = (unit) => {
    if (unit === "Celsius") {
      setVitalUnit("°C");
    } else if (unit === "Fahrenheit") {
      setVitalUnit("°F");
    } else if (unit === "Kilogram") {
      setVitalUnit("kg");
    } else if (unit === "lbs") {
      setVitalUnit("lbs");
    } else if (unit === "Feet") {
      setVitalUnit("ft");
    } else if (unit === "Centimeter") {
      setVitalUnit("cms");
    } 
  const tempOptions = [];
  if(unit){
    if(unit == "Kilogram"){
      for (let i = 1; i <= 250; i++) {
        tempOptions.push(i);
        // for (let j = 1; j <= 9; j++) {
        //   tempOptions.push(parseFloat(`${i}.${j}`));
        // }
      }
    }else if(unit == "lbs"){
      for (let i = 1; i <= 479; i++) {
        tempOptions.push(i);
        // for (let j = 1; j <= 9; j++) {
        //   tempOptions.push(parseFloat(`${i}.${j}`));
        // }
      }
    }else if(unit == "Centimeter"){
      for (let i = 30; i <= 303; i++) {
        tempOptions.push(i);
        // for (let j = 1; j <= 9; j++) {
        //   tempOptions.push(parseFloat(`${i}.${j}`));
        // }
      }
    }else if(unit == "Feet"){
      for (let i = 1; i <= 10; i++) {
        tempOptions.push(i);
        for (let j = 1; j <= 12; j++) {
          tempOptions.push((`${i}.${j}`));
        }
      }
    }
    else if(unit == "Celsius"){
      for (let i = 32; i <= 52; i++) {
        tempOptions.push(i);
        for (let j = 1; j <= 9; j++) {
          tempOptions.push((`${i}.${j}`));
        }
      }
    }else if(unit == "Fahrenheit"){
      for (let i = 90; i <= 110; i++) {
        tempOptions.push(i);
        for (let j = 1; j <= 9; j++) {
          tempOptions.push((`${i}.${j}`));
        }
      }
    }
  }
  
  setOptions(tempOptions);
  
  };
  // THis is BMI vital unit change
  const handleMenuItemClickBMI = (unit) => {
     if (unit === "Kilogram") {
      setVitalUnitWeight("kg");
    } else if (unit === "lbs") {
      setVitalUnitWeight("lbs");
    } else if (unit === "Feet") {
      setVitalUnitHeight("ft");
    } else if (unit === "Centimeter") {
      setVitalUnitHeight("cms");
    }
  };
  const handledatechange = (selectedDate) => {
    const formattedDate = format(selectedDate, "yyyy-MM-dd");
    setDate(formattedDate);
  };
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  // Use `useLayoutEffect` to ensure the scroll happens after DOM updates
  useLayoutEffect(() => {
    scrollToBottom();
  }, [aianswers]);
  const closeChatModel = () => {
    // fetchData();
    // AIChatModel.current.closeRight();
    // AiQuestions.current.closeRight();
    // vitalsModals.current.closeVitals();
    // adddata.current.closeRight();
    window.location.reload();
  };
  const handleCalendar = () => {
    setCalendarVisibility(!isCalendarVisible);
    // openCalendar.current.toggleCalendar();
  }
 
  const handleCalendarMob = () => {
    setCalendarVisibility(!isCalendarVisible);
    // openCalendarMob.current?.toggleCalendar();
  }
  const handlerangedate = async (selecteddate)=>{
    showLoader();
    try {
      const result = await FetchVitalsData({
        patientid,
        type: "",
        fromdate: selecteddate.startDate,
        todate: selecteddate.endDate,
      });
      if (result != null) {
        setData(result);
        if (result.weight.length > 0) {
          const weightobject = result.weight[result.weight.length - 1].value;
          setWeightLabel(weightobject);
        }
        if (result.height.length > 0) {
          const heightobject = result.height[result.height.length - 1].value;
          setHeightLabel(heightobject);
        }
        if (result.oxygensaturation.length > 0) {
          const oxygensaturationobject =
            result.oxygensaturation[result.oxygensaturation.length - 1].value;
          setOxygenSaturationLabel(oxygensaturationobject);
        }
        if (result.pulse.length > 0) {
          const pulseobject = result.pulse[result.pulse.length - 1].value;
          setPulseLabel(pulseobject);
        }
        if (result.bodytempurature.length > 0) {
          const bodytempuratureobject =
            result.bodytempurature[result.bodytempurature.length - 1].value;
          setBodyTempLabel(bodytempuratureobject);
        }
        if (result.respiratoryrate.length) {
          const respiratoryrateobject =
            result.respiratoryrate[result.respiratoryrate.length - 1].value;
          setRespiratoryLabel(respiratoryrateobject);
        }
      } else {
        console.error("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in DashboardIndex:", error);
    }
  }
  const switchBMICalculation = (value) => {
    const tempOptionsWeightLB = [];
    const tempOptionsHeight = [];
    setBMICalculatorType(value);
    if (value == "metric") {
     // setWeightValue("")
      setVitalUnitHeight("cms");
      setVitalUnit("cms");
      setVitalUnitWeight("kg");
      //here
      var converterValueW = lbToKgConverterUsingDBValue(weightvalue)
      setWeightValue(converterValueW.value)

      setVitalValue(converterValueW.value)

      var heightValueOfFeet = feetValue && inchValue ? feetValue + "." + inchValue : feetValue;
       //var heightValueOfFeet = feetValue + "." + inchValue;
      //  if(initailRender){
         var converterValueH = ftToCmConverterBasic(feetValue +"."+ inchValueToCalculateInitial )
         
      //  }else {

      //    var converterValueH = ftToCmConverter(feetValue ,inchValue )
      //  }
       setInitialRender(false)
     // alert(converterValueH)
      setHeightValue(converterValueH)
      setVitalValue(converterValueH)
      
      for (let i = 1; i <= 250; i++) {
        tempOptionsWeightLB.push(i);
      }
      for (let i = 30; i <= 303; i++) {
        tempOptionsHeight.push(i);
      }
    setHeightOptionsInBMI(tempOptionsHeight)
    setWeightOptionsInBMI(tempOptionsWeightLB)
    } else if (value == "standard") {
    //  setWeightValue("")
    var converterValue = kgToLbConverter(weightvalue)
    setWeightValue(converterValue.value)
    setVitalValue(converterValue.value)
      setVitalUnitHeight("ft");
      setVitalUnit("ft");
      setVitalUnitWeight("lbs");
      
      for (let i = 1; i <= 479; i++) {
        tempOptionsWeightLB.push(i);
      }
      for (let i = 1; i <= 10; i++) {
        tempOptionsHeight.push(i);
        for (let j = 1; j <= 9; j++) {
          tempOptionsHeight.push((`${i}.${j}`));
        }
      }
    setHeightOptionsInBMI(tempOptionsHeight)
    setWeightOptionsInBMI(tempOptionsWeightLB)
    } 
  };

  const switchBMICalculationForWeight = (value) => {
    const tempOptionsWeightLB = [];
    const tempOptionsHeight = [];
    setBMICalculatorType(value);
  //  alert(weightvalue)
    if (value == "metric") {
     // setWeightValue("")
      setVitalUnitHeight("cms");
      setVitalUnit("kg");
      setVitalUnitWeight("kg");
      
      var converterValueW;
      if(weightInputChanged){
         converterValueW = lbToKgConverterUsingDBValue(weightvalue)
      }else{
        
         converterValueW = lbToKgConverterUsingDBValue(vitalValueToCalculateOnModalopen)
         // alert(vitalValueToCalculateOnModalopen)
       }
      setWeightValue(converterValueW.value)
      
      setVitalValue(converterValueW.value)

      
      for (let i = 1; i <= 250; i++) {
        tempOptionsWeightLB.push(i);
      }
      for (let i = 30; i <= 303; i++) {
        tempOptionsHeight.push(i);
      }
    setWeightOptionsInBMI(tempOptionsWeightLB)
    } else if (value == "standard") {
    //  setWeightValue("")
    var converterValue;
      if(weightInputChanged){
        converterValue = kgToLbConverter(weightvalue)
      }else{
        
        converterValue = kgToLbConverter(weightvalueInitial)
      }
    //var converterValue = kgToLbConverter(weightvalue)
    setWeightValue(converterValue.value)
    setVitalValue(converterValue.value)
      setVitalUnitHeight("ft");
      setVitalUnit("lbs");
      setVitalUnitWeight("lbs");
      
      for (let i = 1; i <= 479; i++) {
        tempOptionsWeightLB.push(i);
      }
      for (let i = 1; i <= 10; i++) {
        tempOptionsHeight.push(i);
        for (let j = 1; j <= 9; j++) {
          tempOptionsHeight.push((`${i}.${j}`));
        }
      }
    setHeightOptionsInBMI(tempOptionsHeight)
    setWeightOptionsInBMI(tempOptionsWeightLB)
    } 
  };

  useEffect(()=>{
    if(vitalunitheight == "ft"){
      setShowInchesInput(true)
     // alert(vitalunitheight)
    }else{
      setShowInchesInput(false)
    }
  }, [vitalunitheight])
  const setVitalValueInDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setVitalValue(stringValue)
  }
  const setVitalValueInDropDownBP = (value) =>{
    const stringValue = String(value); // Convert value to string
    setBPValueName(stringValue)
  }
  const setFeetValueInDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setFeetValue(stringValue)
  }
  const handleBMIFeetValue = (e)=>{
    var value = e.target.value;
    const numbersRegex = /^[0-9]+$/;
    if (numbersRegex.test(value) || value === "") { 
      setFeetValue(value)
    }
    
  }
  const setHeightValueInBMIDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setHeightValue(stringValue)
    setVitalValue(stringValue)
  }
  const setWeightvalueInBMIDropdown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setWeightValue(stringValue)
    setVitalValue(stringValue)
  }
  const setInchValueInDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setInchValue(stringValue)
  }
  const handleBMIinchValue = (e)=>{
    /// alert(e.target.value)
     var value = e.target.value;
     const numbersRegex = /^[0-9]+$/;
     if (numbersRegex.test(value) || value === "") { // Also allow empty value
      setInchValue(value)
      setInchValueToCalculateInitial(value)
     }
     
   }
  const handleFeetValueChange = (value) => {
    const inputValue = value;
};


const setTimeWithSecondsFunc = (e) => {
  var selectedTime = e.target.value;
  var currentTime = new Date();
  var [inputHours, inputMinutes] = selectedTime.split(":").map(Number);

  var inputDate = new Date();
  inputDate.setHours(inputHours);
  inputDate.setMinutes(inputMinutes);

  // Check if the input time is in the future
  if (inputDate > currentTime) {
    
    showErrorToast("Future time cannot be selected.");
  } else {
    var selectedTimeWithSeconds = selectedTime + `:${incrementalSeconds}`;
    setTimeWithSeconds(selectedTimeWithSeconds);

    setTime(selectedTime);
    setIncrementalSeconds((prevSeconds) => {
      let newSeconds = parseInt(prevSeconds, 10) + 1; // Increment the value
      if (newSeconds < 10) {
        newSeconds = "0" + newSeconds; // Ensure it's a two-digit string
      } else if (newSeconds >= 60) {
        newSeconds = "00"; // Reset to "00" after "59"
      }

      localStorage.setItem("incrementalSeconds", newSeconds.toString());
      return newSeconds.toString();
    });
  }
};

const handleTimeChange = (event) => {
  const inputTime = event.target.value;
  const currentTime = new Date();
  const [inputHours, inputMinutes] = inputTime.split(":").map(Number);

  const inputDate = new Date();
  inputDate.setHours(inputHours);
  inputDate.setMinutes(inputMinutes);

  // Check if the input time is in the future
  if (inputDate > currentTime) {
    
    setTime(moment().format("HH:mm"));
    showErrorToast("Future time cannot be selected.");
  } else {
    setTime(inputTime);
  }
};

  return (
    <>
      <Box w="100%" display={{base: "none", lg: "block"}}>
        <Box top="0" zIndex="banner">
          <Navbar  messageModal={upgradePlanModal}/>
          {isLoading && <Loader />}
          <SecondaryNav
            defaultHeader="My Health"
            defaultSecondayHeader="Vitals"
            defaultNavBody={
              <>
                <Box display="flex" gap="10px" zIndex='99' alignItems="center" pr="25px">
                  <TabSection
                  defaultIndex={3}
                    tabs={["Today","This week", "This month", "This year"]}
                    onClickTab={handleTabClick}
                    tabBtnStyle={{minW: {lg: "120px",xl: "140px"},}}
                  />
                 <Box
                    as="button"
                    bg="none"
                    p="10px"
                    border="1px solid #E6E7E9"
                    borderRadius="10px"
                    onClick={handleCalendar}
                  >
                    <Image w="18px" src="/assets/imgs/calendar.png" />                  
                  </Box>
                  {isCalendarVisible && (
                    <DateRangeIndex ref={openCalendar} 
                    onDateChange={(selectedDateValue) =>
                      handlerangedate(selectedDateValue)
                    }/>
                  )}
                  {/* <Box
                    as="button"
                    bg="none"
                    p="10px"
                    border="1px solid #E6E7E9"
                    borderRadius="10px"
                  >
                    <Image w="18px" src="/assets/imgs/uploaded-doc.png" />
                  </Box> */}
                  <PrimaryButton
                    fontSize="14px"
                    variant="mdBtn"
                    buttonText="Add data"
                    btnIcon="/assets/imgs/add.png"
                    onClick={() => adddata.current.openRight()}
                  />
                </Box>
              </>
            }
          />
        </Box>
        <Box h="calc(100vh - 140px)" overflow="hidden auto">
        <Flex>
          <Box position="sticky" top="0" zIndex="sticky">
            <SideBar />
          </Box>
          <Box w="100%">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            gap="20px"
            // w="calc(100vw - 205px)"
            w="100%"
            >
               <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap="20px"
              p={4}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                gap="20px"
                w="100%"
              >
                {data !== null && data.weight.length > 0 ? (
                  <GrayCard
                    cardHeader="Weight"
                    cardHeaderIcon="/assets/imgs/weight.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="left"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            {weightlabel} Kg
                          </Box>
                          <AreaPlotChart
                            originalData={data.weight}
                            colors="l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff"
                            lineColor="#1C4EC5"
                            height = "200px"
                          />
                        </Box>
                      </>
                    }
                    cardFooterText="Add Weight"
                    onClick={() =>
                      openVitalform(
                        "Weight",
                        "/assets/imgs/monitor_weight-blue.png",
                        "Weight",
                        "kg"
                      )
                    }
                  />
                ) : (
                  <GrayCard
                    cardHeader="Weight"
                    cardHeaderIcon="/assets/imgs/weight.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="left"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="28px"
                            fontWeight="600"
                          >
                            No data
                          </Box>
                          <Box
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            <Image
                              w="100%"
                              opacity="0.5"
                              src="/assets/imgs/weight-chart.png"
                            />
                          </Box>
                        </Box>
                      </>
                    }
                    cardFooterText="Add Weight"
                    onClick={() =>
                      openVitalform(
                        "Weight",
                        "/assets/imgs/monitor_weight-blue.png",
                        "Weight",
                        "kg"
                      )
                    }
                  />
                )}
              </Box>
              {data !== null && data.height.length > 0 ? (
                <GrayCard
                  cardHeader="Height"
                  cardHeaderIcon="/assets/imgs/weight.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                        gap="10px"
                      >
                        <Box
                          as="h1"
                          textAlign="left"
                          fontSize="16px"
                          fontWeight="600"
                        >
                          {heightlabel} ft
                        </Box>
                        <AreaPlotChartHeight
                          originalData={heightValuesToNumeric}
                          colors="l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff"
                          lineColor="#1C4EC5"
                          height = "200px"
                          chartName="heightChart"
                        />
                      </Box>
                    </>
                  }
                  cardFooterText="Add Height"
                  onClick={() =>
                    openVitalform(
                      "Height",
                      "/assets/imgs/height-blue.png",
                      "Height",
                      "ft"
                    )
                  }
                />
              ) : (
                <GrayCard
                  cardHeader="Height"
                  cardHeaderIcon="/assets/imgs/weight.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                        gap="10px"
                      >
                        <Box
                          as="h1"
                          textAlign="left"
                          fontSize="28px"
                          fontWeight="600"
                        >
                          No data
                        </Box>
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="100%"
                            opacity="0.5"
                            src="/assets/imgs/weight-chart.png"
                          />
                        </Box>
                      </Box>
                    </>
                  }
                  cardFooterText="Add Height"
                  onClick={() =>
                    openVitalform(
                      "Height",
                      "/assets/imgs/height-blue.png",
                      "Height",
                      "ft"
                    )
                  }
                />
              )}
            </Box>
            <Box
              p={4}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap="20px"
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                gap="20px"
                w="100%"
              >
                {data !== null && data.respiratoryrate.length > 0 ? (
                  <GrayCard
                    cardHeader="Respiratory Rate"
                    cardHeaderIcon="/assets/imgs/lungs.png"
                    cardBody={
                      <>
                        <Box w="100%">
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                            pb="10px"
                          >
                            {respiratorylabel} bpm
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap="10px"
                          >
                            <AreaPlotChart
                              // data={data.respiratoryrate}
                              // height={"200px"}
                              // color={"#109E6A"}

                              originalData={data.respiratoryrate}
                              colors="l(270) 0:#ffffff 0.5:#109E6A 1:#109E6A"
                              lineColor="#109E6A"
                              height = "200px"
                               chartName="respChart"
                            dotColor="#109E6A"
                            />
                          </Box>
                        </Box>
                      </>
                    }
                    cardFooterText="Add Respiratory Rate"
                    onClick={() =>
                      openVitalform(
                        "Respiratory rate",
                        "/assets/imgs/pulmonology-blue.png",
                        "Respiratory rate",
                        "bpm"
                      )
                    }
                  />
                ) : (
                  <GrayCard
                    cardHeader="Respiratory Rate"
                    cardHeaderIcon="/assets/imgs/lungs.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="28px"
                            fontWeight="600"
                          >
                            No data
                          </Box>
                          <Box
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            <Image
                              w="100%"
                              opacity="0.5"
                              src="/assets/imgs/res-chart.png"
                            />
                          </Box>
                        </Box>
                      </>
                    }
                    cardFooterText="Add Respiratory Rate"
                    onClick={() =>
                      openVitalform(
                        "Respiratory rate",
                        "/assets/imgs/pulmonology-blue.png",
                        "Respiratory rate",
                        "bpm"
                      )
                    }
                  />
                )}
              </Box>
              {data !== null && data.bodytempurature.length > 0 ? (
                <GrayCard
                  cardHeader="body Temperature"
                  cardHeaderIcon="/assets/imgs/thermo.png"
                  cardBody={
                    <>
                      <Box w="100%">
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="left"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            {bodytemplabel} °C
                          </Box>
                          {/* <PointChart
                            data={data.bodytempurature}
                            height={"200px"}
                            color={"#1C4EC5"}
                          /> */}
                          <AreaPlotChart

                              originalData={data.bodytempurature}
                              colors="l(270) 0:#ffffff 0.5:#1C4EC5 1:#1C4EC5"
                              lineColor="#1C4EC5"
                              height = "200px"
                               chartName="bodyTempChart"
                            dotColor="#1C4EC5"
                            />
                        </Box>
                      </Box>
                    </>
                  }
                  cardFooterText="Add Body Temperature"
                  onClick={() =>
                    openVitalform(
                      "Body temperature",
                      "/assets/imgs/thermostats-blue.png",
                      "Temperatures",
                      "°F"
                    )
                  }
                />
              ) : (
                <GrayCard
                  cardHeader="body Temperature"
                  cardHeaderIcon="/assets/imgs/thermo.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                        gap="10px"
                      >
                        <Box
                          as="h1"
                          textAlign="left"
                          fontSize="28px"
                          fontWeight="600"
                        >
                          No data
                        </Box>
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="100%"
                            opacity="0.5"
                            src="/assets/imgs/temp-chart.png"
                          />
                        </Box>
                      </Box>
                    </>
                  }
                  cardFooterText="Add Body Temperature"
                  onClick={() =>
                    openVitalform(
                      "Body temperature",
                      "/assets/imgs/thermostats-blue.png",
                      "Temperatures",
                      "°F"
                    )
                  }
                />
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap="20px"
              p={4}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                gap="20px"
                w="100%"
              >
               

                {data !== null && data.oxygensaturation.length > 0 ? (
                  <GrayCard
                    cardHeader="Oxygen Saturation"
                    cardHeaderIcon="/assets/imgs/spo2.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            {oxygensaturationlabel} %
                          </Box>
                          {/* <PointChart
                            data={data.oxygensaturation}
                            height={"260px"}
                            color={"#F04438"}
                          /> */}
                          <AreaPlotChart
                              originalData={data.oxygensaturation}
                              colors="l(270) 0:#ffffff 0.5:#F04438 1:#F04438"
                              lineColor="#F04438"
                              height = "290px"
                               chartName="oxySatChart"
                            dotColor="#F04438"
                            />
                        </Box>
                      </>
                    }
                    cardFooterText="Add Oxygen Saturation"
                    onClick={() =>
                      openVitalform(
                        "Oxygen Saturation",
                        "/assets/imgs/spo2-blue.png",
                        "Oxygen Saturation",
                        "%"
                      )
                    }
                  />
                ) : (
                  <GrayCard
                    cardHeader="Oxygen Saturation"
                    cardHeaderIcon="/assets/imgs/spo2.png"                  
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="28px"
                            fontWeight="600"
                          >
                            No data
                          </Box>
                          <Box
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            <Image
                              w="100%"
                              opacity="0.5"
                              src="/assets/imgs/oxygen-chart.png"
                            />
                          </Box>
                        </Box>
                      </>
                    }
                    cardFooterText="Add Oxygen Saturation"
                    onClick={() =>
                      openVitalform(
                        "Oxygen Saturation",
                        "/assets/imgs/spo2-blue.png",
                        "Oxygen Saturation",
                        "%"
                      )
                    }
                  />
                )}

              </Box>
              {data !== null && data.bloodpressure.length > 0 ? (
                <GrayCard
                  cardHeader="Blood Pressure"
                  cardHeaderIcon="/assets/imgs/blood_pressure.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                        gap="5px"
                      >
                        {/* <Box display="flex">
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                            marginRight="80px" // Add spacing between the elements if needed
                            marginLeft="14px"
                          >
                             {data && data.bloodpressure ? data.bloodpressure[0].value:""}
                          </Box>
                          <Box
                            as="h1"
                            textAlign="right"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            {data && data.bloodpressure ? data.bloodpressure[1].value:""}
                          </Box>
                        </Box> */}

                        <GroupedColumnRangePlot
                          data={data.bloodpressure}
                          height={"250px"}
                        />
                      </Box>
                    </>
                  }
                  cardFooterText="Add Blood Pressure"
                  onClick={() =>
                    openVitalform(
                      "Blood pressure",
                      "/assets/imgs/blood_pressure-blue.png",
                      "Systolic",
                      "mmHg",
                      "true"
                    )
                  }
                />
              ) : (
                <GrayCard
                  cardHeader="Blood Pressure"
                  cardHeaderIcon="/assets/imgs/blood_pressure.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                        gap="10px"
                      >
                        <Box
                          as="h1"
                          textAlign="left"
                          fontSize="28px"
                          fontWeight="600"
                        >
                          No data
                        </Box>
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="100%"
                            opacity="0.5"
                            src="/assets/imgs/blood-pressure-charts.png"
                          />
                        </Box>
                      </Box>
                    </>
                  }
                  cardFooterText="Add Blood Pressure"
                  onClick={() =>
                    openVitalform(
                      "Blood pressure",
                      "/assets/imgs/blood_pressure-blue.png",
                      "Systolic",
                      "mmHg",
                      "true"
                    )
                  }
                />
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap="20px"
              p={4}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                gap="20px"
                w="100%"
              >
                {data !== null && data.pulse.length > 0 ? (
                  <GrayCard
                    cardHeader="heart rate"
                    cardHeaderIcon="/assets/imgs/heart-rate.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            {pulselabel} bpm
                          </Box>
                          <AreaPlotChart
                            originalData={data.pulse}
                            colors="l(270) 0:#ffffff 0.5:#D80027 1:#D80027"
                            lineColor="#D80027"
                            height = "200px"
                            chartName="redChart"
                            dotColor="#D80027"
                            
                          />
                        </Box>
                      </>
                    }
                    cardFooterText="Add Heart Rate"
                    onClick={() =>
                      openVitalform(
                        "Pulse",
                        "/assets/imgs/ecg_heart-blue.png",
                        "Pulse",
                        "bpm"
                      )
                    }
                  />
                ) : (
                  <GrayCard
                    cardHeader="heart rate"
                    cardHeaderIcon="/assets/imgs/heart-rate.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="28px"
                            fontWeight="600"
                          >
                            No data
                          </Box>
                          <Box
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            <Image
                              w="100%"
                              opacity="0.5"
                              src="/assets/imgs/heart-.png"
                            />
                          </Box>
                        </Box>
                      </>
                    }
                    cardFooterText="Add Heart Rate"
                    onClick={() =>
                      openVitalform(
                        "Pulse",
                        "/assets/imgs/ecg_heart-blue.png",
                        "Pulse",
                        "bpm"
                      )
                    }
                  />
                )}
              </Box>
              {data !== null && data.bodymassindexvalue ? (
                <GrayCard
                  cardHeader="BODY MASS INDEX"
                  cardHeaderIcon="/assets/imgs/body-mass.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                        gap="10px"
                      >
                        <GaugeChart
                          value={data.bodymassindexvalue}
                          bodymass={data.bodymassindex}
                          height={"144px"}
                          rotateAngle={data.rotateangle}
                        />
                    <Text display="flex" justifyContent="center" alignItems="center" mt="20px" fontWeight="500">
                    Your BMI Score is  <Box as="span" ml="5px" fontWeight="600"> {calculateBMIStatus(data.bodymassindexvalue)} </Box> 

                    </Text>

                      </Box>
                    </>
                  }
                  cardFooterText="Update Body Mass Index"
                  onClick={openVitalBMIform}
                />
              ) : (
                <GrayCard
                  cardHeader="BODY MASS INDEX"
                  cardHeaderIcon="/assets/imgs/body-mass.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                        gap="10px"
                      >
                        <Box
                          as="h1"
                          textAlign="left"
                          fontSize="28px"
                          fontWeight="600"
                        >
                          No data
                        </Box>
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="100%"
                            opacity="0.5"
                            src="/assets/imgs/bmi.png"
                          />
                        </Box>
                      </Box>
                    </>
                  }
                  cardFooterText="Update Body Mass Index"
                  onClick={openVitalBMIform}
                />
              )}
            </Box>

           
          </Box>
          </Box>
        </Flex>
          <Footer />
        </Box>
        <ModalRight
          ref={adddata}
          header="Add data"
          body={
            <>
              <Box>
                <VStack align="stretch">
                  <React.Fragment>
                    <Stack direction="row" align="center">
                      {/* <Circle
                                  size="12px"
                                  bg="white"
                                  border="1px solid gray"
                                  borderRadius="full"
                                  color="black"
                                /> */}

                      <Box flex="1">
                        <Box
                          display="flex"
                          w="100%"
                          p="15px 10px"
                          justifyContent="space-between"
                          alignItems="center"
                          as="button"
                          fontSize="16px"
                          fontWeight="600"
                          onClick={() =>
                            openVitalform(
                              "Body temperature",
                              "/assets/imgs/thermostats-blue.png",
                              "Temperatures",
                              "°F"
                            )
                          }
                        >
                          <Image src="/assets/imgs/thermostats-blue.png" />
                          <Text fontSize="14px" fontWeight="600" ml="10px">
                            Body temperature
                          </Text>
                          <Spacer />
                          <Image src="/assets/imgs/right-icon.png" />
                        </Box>
                        <Divider />
                        <Box
                          display="flex"
                          w="100%"
                          p="15px 10px"
                          justifyContent="space-between"
                          alignItems="center"
                          as="button"
                          fontSize="16px"
                          fontWeight="600"
                          onClick={() =>
                            openVitalform(
                              "Pulse",
                              "/assets/imgs/ecg_heart-blue.png",
                              "Pulse",
                              "bpm"
                            )
                          }
                        >
                          <Image src="/assets/imgs/ecg_heart-blue.png" />
                          <Text fontSize="14px" fontWeight="600" ml="10px">
                            Pulse
                          </Text>
                          <Spacer />
                          <Image src="/assets/imgs/right-icon.png" />
                        </Box>

                        <Divider />
                        <Box
                          display="flex"
                          w="100%"
                          p="15px 10px"
                          justifyContent="space-between"
                          alignItems="center"
                          as="button"
                          fontSize="16px"
                          fontWeight="600"
                          onClick={() =>
                            openVitalform(
                              "Blood pressure",
                              "/assets/imgs/blood_pressure-blue.png",
                              "Systolic",
                              "mmHg",
                              "true"
                            )
                          }
                        >
                          <Image src="/assets/imgs/blood_pressure-blue.png" />
                          <Text fontSize="14px" fontWeight="600" ml="10px">
                            Blood pressure
                          </Text>
                          <Spacer />
                          <Image src="/assets/imgs/right-icon.png" />
                        </Box>
                        <Divider />

                        <Box
                          display="flex"
                          w="100%"
                          p="15px 10px"
                          justifyContent="space-between"
                          alignItems="center"
                          as="button"
                          fontSize="16px"
                          fontWeight="600"
                          onClick={() =>
                            openVitalform(
                              "Respiratory rate",
                              "/assets/imgs/pulmonology-blue.png",
                              "Respiratory rate",
                              "bpm"
                            )
                          }
                        >
                          <Image src="/assets/imgs/pulmonology-blue.png" />
                          <Text fontSize="14px" fontWeight="600" ml="10px">
                            Respiratory rate
                          </Text>
                          <Spacer />
                          <Image src="/assets/imgs/right-icon.png" />
                        </Box>
                        <Divider />
                        <Box
                          display="flex"
                          w="100%"
                          p="15px 10px"
                          justifyContent="space-between"
                          alignItems="center"
                          as="button"
                          fontSize="16px"
                          fontWeight="600"
                          onClick={() =>
                            openVitalform(
                              "Oxygen Saturation",
                              "/assets/imgs/spo2-blue.png",
                              "Oxygen Saturation",
                              "%"
                            )
                          }
                        >
                          <Image src="/assets/imgs/spo2-blue.png" />
                          <Text fontSize="14px" fontWeight="600" ml="10px">
                            Oxygen Saturation
                          </Text>
                          <Spacer />
                          <Image src="/assets/imgs/right-icon.png" />
                        </Box>
                        <Divider />
                        <Box
                          display="flex"
                          w="100%"
                          p="15px 10px"
                          justifyContent="space-between"
                          alignItems="center"
                          as="button"
                          fontSize="16px"
                          fontWeight="600"
                          onClick={() =>
                            openVitalform(
                              "Weight",
                              "/assets/imgs/monitor_weight-blue.png",
                              "Weight",
                              "kg"
                            )
                          }
                        >
                          <Image src="/assets/imgs/monitor_weight-blue.png" />
                          <Text fontSize="14px" fontWeight="600" ml="10px">
                            Weight
                          </Text>
                          <Spacer />
                          <Image src="/assets/imgs/right-icon.png" />
                        </Box>
                        <Divider />
                        <Box
                          display="flex"
                          w="100%"
                          p="15px 10px"
                          justifyContent="space-between"
                          alignItems="center"
                          as="button"
                          fontSize="16px"
                          fontWeight="600"
                          onClick={() =>
                            openVitalform(
                              "Height",
                              "/assets/imgs/height-blue.png",
                              "Height",
                              "ft"
                            )
                          }
                        >
                          <Image src="/assets/imgs/height-blue.png" />
                          <Text fontSize="14px" fontWeight="600" ml="10px">
                            Height
                          </Text>
                          <Spacer />
                          <Image src="/assets/imgs/right-icon.png" />
                        </Box>
                        <Divider />
                        <Box
                          display="flex"
                          w="100%"
                          p="15px 10px"
                          justifyContent="space-between"
                          alignItems="center"
                          as="button"
                          fontSize="16px"
                          fontWeight="600"
                          onClick={openVitalBMIform}
                        >
                          <Image src="/assets/imgs/monitor_weight-blue.png" />
                          <Text fontSize="14px" fontWeight="600" ml="10px">
                            BMI
                          </Text>
                          <Spacer />
                          <Image src="/assets/imgs/right-icon.png" />
                        </Box>
                        {/* <Box
                          display="flex"
                          w="100%"
                          p="15px 10px"
                          justifyContent="space-between"
                          alignItems="center"
                          as="button"
                          fontSize="16px"
                          fontWeight="600"
                        >
                          <Image src="/assets/imgs/file_open-blue.png" />
                          <Text fontSize="14px" fontWeight="600" ml="10px">
                            Upload document
                          </Text>
                          <Spacer />
                          <Image src="/assets/imgs/right-icon.png" />
                        </Box>
                        <Divider /> */}

                        <Box
                          as="div"
                          w="2px"
                          h="5px"
                          borderColor="black"
                          m="0 auto"
                        ></Box>
                      </Box>
                    </Stack>
                  </React.Fragment>
                </VStack>
              </Box>
            
            </>
          }
        />
       
          {/* Vitals Add Form */}
          <Vitalsmodel
          ref={vitalsModals}
          withBackBtn={true}
          modalSize={{ w: "350px!important" }}
          modalHeaderIcon={headericon}
          header={headername}
          body={
            <>
              <Box>
                <Box display="flex" py="20px">
                  {/* <PrimaryInput
                    variant="fullLeft"
                    id="vitaldate"
                    inputType="date"
                    inputPlace="Date"
                    defaultValue={date}
                    onChange={(e) => setDate(e.target.value)}
                  />  */}
                  <DatePicker
                    onDateChange={(selectedDateValue) =>
                      handledatechange(selectedDateValue)
                    }
                    disableFutureDates={true}
                    defaultDate={date}
                    // leftPos={{left: "5px"}}
                    placeholderProp="Date"
                    calendarStlye={{left: "-18px",  right: "unset"}}
                  />
                  {/* <DatePicker 
                    // onDateChange={(selectedDateValue) => handleDateChange(condition.condition_cd, selectedDateValue)}
                    // onDateChange={handleTimeChange}
                    placeholderProp="Select Time"
                    includeTime={true}
                    includeDate={false} />  */}
                  <PrimaryInput
                    variant="fullRight"
                    id="vitaltime"
                    inputType="time"
                    inputPlace="Time"
                    pr="10px"
                    defaultValue={time}
                     onChange={(e) => setTimeWithSecondsFunc(e)}
                    inputValue={time}
                   // onChange={handleTimeChange }
                  />
                </Box>
                {(vitalHeightdynamicunit != "true" && vitalWeightdynamicunit != "true") && 

<Divider />
}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  py={(vitalHeightdynamicunit != "true" && vitalWeightdynamicunit != "true") && "20px"}
                >
                  <Box fontSize="14px" fontWeight="600" w="100%">
                  {(vitalHeightdynamicunit != "true" && vitalWeightdynamicunit != "true") && 
                    vitalname
} <br />
                    {vitalBPdynamicunit !== "true" &&
                    vitalWeightdynamicunit !== "true" &&
                    vitalHeightdynamicunit !== "true" ? (
                      <Box
                        as="span"
                        color="#384052"
                        fontSize="12px"
                        fontWeight="500"
                      >
                        {vitalunit}
                      </Box>
                    ) : (
                      <>
                        {vitalBPdynamicunit === "true" && (
                          <Menu>
                            <MenuButton
                              sx={{
                                color: "#1C4EC5",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {vitalunit}{" "}
                              <Image
                                ml="5px"
                                pos="relative"
                                bottom="2px"
                                display="inline-block"
                                src="/assets/imgs/arrow-down-gray.png"
                              />
                            </MenuButton>
                            <MenuList
                              p="2px"
                              minW="160px"
                              mr="25px"
                              zIndex="overlay"
                            >
                              <MenuItem
                                fontSize="14px"
                                fontWeight="500"
                                color="#384052"
                                onClick={() =>
                                  handleMenuItemClick("Fahrenheit")
                                }
                              >
                                Fahrenheit
                              </MenuItem>
                              <MenuItem
                                fontSize="14px"
                                fontWeight="500"
                                color="#384052"
                                onClick={() => handleMenuItemClick("Celsius")}
                              >
                                Celsius
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        )}

                        {vitalWeightdynamicunit === "true" && (
                          // <Menu>
                          //   <MenuButton
                          //     sx={{
                          //       color: "#1C4EC5",
                          //       display: "flex",
                          //       alignItems: "center",
                          //     }}
                          //   >
                          //     {vitalunit}{" "}
                          //     <Image
                          //       ml="5px"
                          //       pos="relative"
                          //       bottom="2px"
                          //       display="inline-block"
                          //       src="/assets/imgs/arrow-down-gray.png"
                          //     />
                          //   </MenuButton>
                          //   <MenuList
                          //     p="2px"
                          //     minW="160px"
                          //     mr="25px"
                          //     zIndex="overlay"
                          //   >
                          //     <MenuItem
                          //       fontSize="14px"
                          //       fontWeight="500"
                          //       color="#384052"
                          //       onClick={() => handleMenuItemClick("Kilogram")}
                          //     >
                          //       Kilogram
                          //     </MenuItem>
                          //     <MenuItem
                          //       fontSize="14px"
                          //       fontWeight="500"
                          //       color="#384052"
                          //       onClick={() => handleMenuItemClick("Pounds")}
                          //     >
                          //       Pounds
                          //     </MenuItem>
                          //   </MenuList>
                          // </Menu>
                          <></>
                        )}

                        {vitalHeightdynamicunit === "true" && (
                          // <Menu>
                          //   <MenuButton
                          //     sx={{
                          //       color: "#1C4EC5",
                          //       display: "flex",
                          //       alignItems: "center",
                          //     }}
                          //   >
                          //     {vitalunit}{".... "}
                          //     <Image
                          //       ml="5px"
                          //       pos="relative"
                          //       bottom="2px"
                          //       display="inline-block"
                          //       src="/assets/imgs/arrow-down-gray.png"
                          //     />
                          //   </MenuButton>
                          //   <MenuList
                          //     p="2px"
                          //     minW="160px"
                          //     mr="25px"
                          //     zIndex="overlay"
                          //   >
                          //     <MenuItem
                          //       fontSize="14px"
                          //       fontWeight="500"
                          //       color="#384052"
                          //       onClick={() => handleMenuItemClick("Feet")}
                          //     >
                          //       Feet
                          //     </MenuItem>
                          //     <MenuItem
                          //       fontSize="14px"
                          //       fontWeight="500"
                          //       color="#384052"
                          //       onClick={() =>
                          //         handleMenuItemClick("Centimeter")
                          //       }
                          //     >
                          //       Centimeter
                          //     </MenuItem>
                          //   </MenuList>
                          // </Menu>


                          <>
                          
                         
                            
                {/* BMI start */}
                {headername == "Height" && 
                <>
              <Divider />
                      <RadioGroup 
                 onChange={switchBMICalculation}
                 value={BMICalculatorType}
                >
                    <Box display="flex" justifyContent="space-around" py="20px">
                      <Radio value="standard" colorScheme="green">
                        Standard
                      </Radio>
                     
                      <Radio value="metric" colorScheme="green">
                        Metric
                      </Radio>

                    </Box>
                  </RadioGroup>
               

                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  py="20px"
                >
                  <Box fontSize="14px" fontWeight="600" w="100%">
                      Height
                      <Box
                        as="span"
                        color="#384052"
                        fontSize="12px"
                        fontWeight="500"
                      >
  
                      </Box>
                      <>
                      <Menu>
                            <MenuButton
                              sx={{
                                color: "#1C4EC5",
                                display: "flex",
                                alignItems: "center",
                                cursor:"default"
                              }}
                            >
                              {vitalunitheight}{" "}
                              {/* <Image
                                ml="5px"
                                pos="relative"
                                bottom="2px"
                                display="inline-block"
                                src="/assets/imgs/arrow-down-gray.png"
                              /> */}
                            </MenuButton>
                          </Menu>
                      </>
                  </Box>
                  {!showInchesInput && 
              
                  <DropdownInputForVitals
                  variant="bothSide"
                  dropDownPlace="Enter value"
                  options={heightOptionsInBMI}
                  onClick={setHeightValueInBMIDropDown}
                onChange={handlebmivitalvaluechange}
                 readOnly=""
                 id="heightvalue"
                 // defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                 inputValueProp={heightvalue}
                />
                  }
                  
                  {showInchesInput && 
                  <>
                   
                    <DropdownInputForVitals
                variant="bothSide"
                dropDownPlace="Feet"
                options={feetOptions}
                onClick={setFeetValueInDropDown}
                  onChange={handleBMIFeetValue}
               readOnly=""
               id="heightvalue"
                 inputValueProp={feetValue}
                maxLength={1}
              />

               
                    <DropdownInputForVitals
                variant="bothSide"
                dropDownPlace="Inches"
                options={inchesOptions}
                onClick={setInchValueInDropDown}
              onChange={handleBMIinchValue}
               readOnly=""
               id="heightvalue"
               inputValueProp={inchValue}
                maxLength={2}
              />
                  </>
                  }
                </Box>

                <Divider />
                </>
                 }



                {/* BMI end */}
                
             
                </>
                        )}
                      </>
                    )}


{headername == "Weight" && 
                <>
              <Divider />
                      <RadioGroup 
                 onChange={switchBMICalculationForWeight}
                 value={BMICalculatorType}
                >
                    <Box display="flex" justifyContent="space-around" py="20px">
                      <Radio value="standard" colorScheme="green">
                        Standard
                      </Radio>
                     
                      <Radio value="metric" colorScheme="green">
                        Metric
                      </Radio>

                    </Box>
                  </RadioGroup>
               

                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  py="20px"
                >
                  <Box fontSize="14px" fontWeight="600" w="100%">
                      Weight
                      <Box
                        as="span"
                        color="#384052"
                        fontSize="12px"
                        fontWeight="500"
                      >
  
                      </Box>
                      <>
                          <Menu>
                            <MenuButton
                              sx={{
                                color: "#1C4EC5",
                                display: "flex",
                                alignItems: "center",
                                cursor:"default"
                              }}
                            >
                              {vitalunitweight}{" "}
                              {/* <Image
                                ml="5px"
                                pos="relative"
                                bottom="2px"
                                display="inline-block"
                                src="/assets/imgs/arrow-down-gray.png"
                              /> */}
                            </MenuButton>
                           
                          </Menu>
                      </>
                  </Box>
                     <DropdownInputForVitals
                variant="bothSide"
                dropDownPlace="Enter Value"
                options={weightOptionsInBMI}
                onClick={setWeightvalueInBMIDropdown}
             onChange={handlebmivitalvaluechange}
               readOnly=""
               id="weightvalue"
              //  defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                inputValueProp={weightvalue}
              />
                </Box>

                <Divider />
                </>
                 }
                  </Box>
                  {/* <Input
                    placeholder="Enter value"
                    h="48px"
                    // w="160px"
                    type="text"
                    w="80%"
                    fontSize="24px"
                    textAlign="center"
                    outline="none"
                    sx={{
                      _hover: {
                        border: "2px solid",
                        borderColor: "COLORS.INPUT_FOCUS_DARK", // Replace with your variable or color code
                      },
                      _focus: {
                        border: "2px solid",
                        borderColor: "COLORS.INPUT_FOCUS_DARK", // Replace with your variable or color code
                      },
                      _focusVisible: {
                        borderColor: "none",
                      },
                      "&::placeholder": {
                        fontSize: "14px",
                        color: "#6A707D",
                      },
                      // ... Add more styles if needed
                    }}
                    id="vitalvalue"
                    onChange={handlevitalvaluechange}
                    value={vitalvalue}
                  /> */}
                  
                  {(vitalHeightdynamicunit != "true" && vitalWeightdynamicunit != "true") && (
                  <DropdownInputForVitals
                variant="bothSide"
                dropDownPlace="Enter value"
                options={options}
                onClick={setVitalValueInDropDown}
                onChange={handlevitalvaluechange}
               readOnly=""
               id="vitalvalue"
              
               inputValueProp={vitalvalue}
                maxLength={6}
              />
                  )}
                </Box>
                {activepage === "True" && (
                  <Box display="flex" justifyContent="space-between">
                    <Box fontSize="14px" fontWeight="600" w="100%">
                      Diastolic <br />
                      <Box
                        as="span"
                        color="#384052"
                        fontSize="12px"
                        fontWeight="500"
                      >
                        mmHg
                      </Box>
                    </Box>
                    {/* <Input
                      placeholder="Enter value//"
                      h="48px"
                      // w="160px"
                      type="text"
                      w="80%"
                      fontSize="24px"
                      textAlign="center"
                      outline="none"
                      sx={{
                        _hover: {
                          border: "2px solid",
                          borderColor: "COLORS.INPUT_FOCUS_DARK", // Replace with your variable or color code
                        },
                        _focus: {
                          border: "2px solid",
                          borderColor: "COLORS.INPUT_FOCUS_DARK", // Replace with your variable or color code
                        },
                        _focusVisible: {
                          borderColor: "none",
                        },
                        "&::placeholder": {
                          fontSize: "14px",
                          color: "#6A707D",
                        },
                        // ... Add more styles if needed
                      }}
                      id="vitalvalueBP"
                      onChange={handlevitalvaluechange}
                      value={vitalvalueBP}
                    /> */}
                     <DropdownInputForVitals
                variant="bothSide"
                dropDownPlace="Enter value"
                options={optionsForBP}
                onClick={setVitalValueInDropDownBP}
               onChange={handlevitalvaluechange}
               readOnly=""
               id="vitalvalueBP"
               // defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                inputValueProp={vitalvalueBP}
                maxLength={6}
              />
                  </Box>
                )}
              </Box>
               
            </>
          }
          footerStyle={{
            bg: "white",
            boxShadow: "0px -8px 20px 0px #E6E7E966",
          }}
          footerActions={
            <>
              <Box w="100%">
                <PrimaryButton
                  buttonText={"Add "+ headername}
                  onClick={Submitvitalform}
                  //isDisabled={isSubmitting}
                />
              </Box>
            </>
          }
        />

       {/* AI Question model */}
       <AiModal
          bodyStyle={{ p: "0" }}
          ref={AiQuestions}
          modalSize="420px"
          icon="/assets/imgs/thermo-white.png"
          header={aiquestion && aiquestion.value ? aiquestion.value : "-"}
          measure={aiquestion && aiquestion.units ? aiquestion.units : "-"}
          secondaryHeader={
            aiquestion && aiquestion.type ? aiquestion.type : "-"
          }
          body={
            <>
              <Flex flexDirection="column" height="100%">
                {aiquestion && aiquestion.status && (
                  <>
                    {aiquestion.status === "high" && (
                      <Box bg="#FEE4E2" p="16px 32px">
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          color="#F04438"
                          fontSize="14px"
                          fontWeight="600"
                        >
                          <Image
                            src="/assets/imgs/error-red.png"
                            w="16px"
                            mr="8px"
                          />
                          {aiquestion && aiquestion.suggestion
                            ? aiquestion.suggestion
                            : "-"}
                        </Box>
                      </Box>
                    )}
                    {aiquestion.status === "normal" && (
                      <Box bg="#E6E7E9" p="16px 32px">
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          color="#384052"
                          fontSize="14px"
                          fontWeight="600"
                        >
                          <Image
                            src="/assets/imgs/body-temp-ok.png"
                            w="16px"
                            mr="8px"
                          />
                          {aiquestion && aiquestion.suggestion
                            ? aiquestion.suggestion
                            : "-"}
                        </Box>
                      </Box>
                    )}
                    {aiquestion.status === "low" && (
                      <Box bg="#FFF0E1" p="16px 32px">
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          color="#D46F11"
                          fontSize="14px"
                          fontWeight="600"
                          lineHeight="normal"
                        >
                          <Image
                            src="/assets/imgs/error-yellow.png"
                            w="16px"
                            mr="8px"
                          />
                          {aiquestion && aiquestion.suggestion
                            ? aiquestion.suggestion
                            : "-"}
                        </Box>
                      </Box>
                    )}
                  </>
                )}

                <Box p="24px 32px" pb="0">
                  <Box as="h1" fontSize="16px" pb="16px" fontWeight="600">
                    Would you like to learn more about how this will impact your
                    health?
                  </Box>
                </Box>
                <Box
                  p="24px 32px"
                  pt="0"
                  display="flex"
                  h="100%"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Box w="100%">
                    {aiquestion &&
                      aiquestion.generated_question &&
                      aiquestion.generated_question.map((step, index) => (
                        <Box
                          key={index}
                          as="button"
                          w="100%"
                          p="16px"
                          mb="16px"
                          fontSize="14px"
                          display="flex"
                          gap="15px"
                          textAlign="left"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          borderRadius="10px"
                          border="1px solid #E6E7E9"
                          onClick={() => GetAIAnswer(step.value)}
                        >
                          {step.value ? step.value : "-"}
                          <Image src="/assets/imgs/right-icon.png" />
                        </Box>
                      ))}
                  </Box>
                  <PrimaryButtonIcon
                    buttonText="Skip Now"
                    onClick={handleSkip}
                  />
                </Box>
                <Spacer />
              </Flex>
               
                    </>
                  }
                />

                {/* AI Chat Model */}
                <AiModal
                  ref={AIChatModel}
                  modalSize="420px"
                  //icon="/assets/imgs/ai-doctor.png"
                  header={<>
                    <Box display="flex" flexDir='column' gap='5px'>
                      <Box display='flex' gap='6px'>
                        <Text fontSize="1rem"
                          fontWeight="700" 
                          backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)" 
                          color="transparent" 
                          backgroundClip="text"
                        >Aira - AI</Text>
                        <Box display='flex' alignItems='center' gap='5px' border='1px solid #0d7e54' bg='#d5ece3'  p='3px 5px' borderRadius='full'>
                          <Image src='/assets/imgs/spark.png' /> 
                          <Text fontSize='10px' fontWeight='500' color='#000'>{remainingTokens && remainingTokens.remainingtokens ? displayMillion_ThoudandValues(remainingTokens.remainingtokens):"0"} tokens available</Text>
                        </Box>
                      </Box>
                      <Text fontSize="0.75rem" fontWeight="400">Chat with your AI health guide</Text>
                    </Box>
                  </>}
                  headerStyle={{ p: "24px 32px", lineHeight: "normal" }}
                  footerStyle={{h: "30%", overflow: "hidden auto"}}
                  closeBtnStyle={{ top: "18px" }}
                  withoutBg={true}
                  handlechatclose={closeChatModel}
                  body={
                    <>
                      <Flex flexDirection="column" height="100%">
                        <Box
                          p="24px 32px"
                          ref={chatContainerRef}
                          className="chat-container"
                          style={{ overflowY: "auto" }}
                        >
                          {aianswers &&
                            aianswers.map((step, index) => (
                              <>
                                <Box
                                  key={index}
                                  as="div"
                                  bg="#061027"
                                  p="12px 16px"
                                  w="fit-content"
                                  float="right"
                                  borderRadius="10px 10px 1px 10px"
                                >
                                  <Text fontSize="14px" fontWeight="500" color="white">
                                    {step.question ? step.question : "-"}
                                  </Text>
                                </Box>

                                <Box
                                  as="div"
                                  my="20px"
                                  w="fit-content"
                                  display="flex"
                                  alignItems="end"
                                  float="left"
                                >
                                  <Image
                                    bg="#061027"
                                    p="4px"
                                    mr="8px"
                                    borderRadius="6px"
                                    src="/assets/imgs/ai-chat-logo.png"
                                  />
                                  <Text
                                    bg="#E6E7E980"
                                    p="12px 16px"
                                    fontSize="14px"
                                    fontWeight="500"
                                    color="#061027"
                                    borderRadius="10px 10px 10px 1px"
                                  >
                                    {step.answer ? step.answer : <TypingLoader />}
                                  </Text>
                                </Box>
                              </>
                            ))}
                        </Box>
                      </Flex>
                    </>
                  }
                  footerActions={
                    <>
                      <Box
                        p="24px 32px"
                        display="flex"
                        w="100%"
                        h="100%"
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <Box w="100%">
                          {remainingquestions &&
                            remainingquestions.map((step, index) => (
                              <Box
                                key={index}
                                as="button"
                                w="100%"
                                p="16px"
                                mb="16px"
                                fontSize="14px"
                                fontWeight="600"
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-between"
                                borderRadius="10px"
                                border="1px solid #7795DC"
                                bg="#EAEDF5"
                                color="#163E9E"
                                gap='1rem'
                        textAlign='left'
                                onClick={() => GetAIAnswer(step.value)}
                              >
                                {step.value ? step.value : "-"}
                                <Image src="/assets/imgs/right-icon.png" />
                              </Box>
                            ))}
                          <Box
                            as="button"
                            w="100%"
                            p="16px"
                            mb="16px"
                            fontSize="14px"
                            fontWeight="600"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="10px"
                            border="1px solid #7795DC"
                            bg="#EAEDF5"
                            color="#163E9E"
                            onClick={handleSkip}
                          >
                            Skip Now
                            {/* Book an appointment */}
                            {/* <Image src="/assets/imgs/right-icon.png" /> */}
                            
                          </Box>
                        </Box>
                      </Box>
                    </>
                  }
                />
        {/* BMI Add Form */}
        <Vitalsmodel
          ref={vitalsbmiModals}
          withBackBtn={true}
          modalSize={{ w: "350px!important" }}
          modalHeaderIcon="/assets/imgs/monitor_weight-blue.png"
          header="BMI"
          body={
            <>
              <Box>
                <Box display="flex" py="20px">

                  <DatePicker
                    onDateChange={(selectedDateValue) =>
                      handledatechange(selectedDateValue)
                    }
                    defaultDate={date}
                    // leftPos={{left: "5px"}}
                    disableFutureDates={true}
                    placeholderProp="Date"
                    calendarStlye={{left: "-18px",  right: "unset"}}
                  />
                  <PrimaryInput
                    variant="fullRight"
                    id="vitaltime"
                    inputType="time"
                    inputPlace="Time"
                    pr="10px"
                    defaultValue={time}
                    inputValue={time}
                    onChange={handleTimeChange }
                  />
                </Box>
                <Divider />
                <RadioGroup 
                 onChange={switchBMICalculation}
                 value={BMICalculatorType}
                >
                    <Box display="flex" justifyContent="space-around" py="20px">
                      <Radio value="standard" colorScheme="green">
                        Standard
                      </Radio>
                     
                      <Radio value="metric" colorScheme="green">
                        Metric
                      </Radio>

                    </Box>
                  </RadioGroup>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  py="20px"
                >
                  <Box fontSize="14px" fontWeight="600" w="100%">
                      Weight
                      <Box
                        as="span"
                        color="#384052"
                        fontSize="12px"
                        fontWeight="500"
                      >
  
                      </Box>
                      <>
                          <Menu>
                            <MenuButton
                              sx={{
                                color: "#1C4EC5",
                                display: "flex",
                                alignItems: "center",
                                cursor:"default"
                              }}
                            >
                              {vitalunitweight}{" "}
                              {/* <Image
                                ml="5px"
                                pos="relative"
                                bottom="2px"
                                display="inline-block"
                                src="/assets/imgs/arrow-down-gray.png"
                              /> */}
                            </MenuButton>
                           
                          </Menu>
                      </>
                  </Box>
                     <DropdownInputForVitals
                variant="bothSide"
                dropDownPlace="Enter Value"
                options={weightOptionsInBMI}
                onClick={setWeightvalueInBMIDropdown}
             onChange={handlebmivitalvaluechange}
               readOnly=""
               id="weightvalue"
              //  defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                inputValueProp={weightvalue}
              />
                </Box>

                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  py="20px"
                >
                  <Box fontSize="14px" fontWeight="600" w="100%">
                      Height
                      <Box
                        as="span"
                        color="#384052"
                        fontSize="12px"
                        fontWeight="500"
                      >
  
                      </Box>
                      <>
                      <Menu>
                            <MenuButton
                              sx={{
                                color: "#1C4EC5",
                                display: "flex",
                                alignItems: "center",
                                cursor:"default"
                              }}
                            >
                              {vitalunitheight}{" "}
                              {/* <Image
                                ml="5px"
                                pos="relative"
                                bottom="2px"
                                display="inline-block"
                                src="/assets/imgs/arrow-down-gray.png"
                              /> */}
                            </MenuButton>
                          </Menu>
                      </>
                  </Box>
                  {!showInchesInput && 
              
                  <DropdownInputForVitals
                  variant="bothSide"
                  dropDownPlace="Enter value"
                  options={heightOptionsInBMI}
                  onClick={setHeightValueInBMIDropDown}
                onChange={handlebmivitalvaluechange}
                 readOnly=""
                 id="heightvalue"
                 // defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                 inputValueProp={heightvalue}
                />
                  }
                  
                  {showInchesInput && 
                  <>
                   
                    <DropdownInputForVitals
                variant="bothSide"
                dropDownPlace="Feet"
                options={feetOptions}
                onClick={setFeetValueInDropDown}
                  onChange={handleBMIFeetValue}
               readOnly=""
               id="heightvalue"
                 inputValueProp={feetValue}
                maxLength={1}
              />

                    <DropdownInputForVitals
                variant="bothSide"
                dropDownPlace="Inches"
                options={inchesOptions}
                onClick={setInchValueInDropDown}
              onChange={handleBMIinchValue}
               readOnly=""
               id="heightvalue"
               inputValueProp={inchValue}
                maxLength={2}
              />
                  </>
                  }
                </Box>
              </Box>
            </>
          }
          footerStyle={{
            bg: "white",
            boxShadow: "0px -8px 20px 0px #E6E7E966",
          }}
          footerActions={
            <>
              <Box w="100%">
                <PrimaryButton
                  buttonText="Add BMI"
                  onClick={SubmitBMIform}
                  //isDisabled={isSubmitting}
                />
              </Box>
            </>
          }
        />
      {/* <Footer/> */}
      </Box>

      {/* Mobile responsive start */}
      <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/myhealth')}
          header="Vitals"
          rightArea={<>
            <Box w="100%">
              <Box as="button" onClick={handleCalendarMob}>
                <Image src="/assets/imgs/calendar.png" />
              </Box>
              {isCalendarVisible && (
                <DateRangeIndex ref={openCalendarMob} 
                  onDateChange={(selectedDateValue) =>
                  handlerangedate(selectedDateValue)
                }/>
              )}
            </Box>
          </>}
          bottomArea={<>
            <TabSection
              defaultIndex={3}
              tabs={["Today","Week", "Month", "Year"]}
              onClickTab={handleTabClick}
              tabBtnStyle={{minW: {lg: "120px",xl: "140px"},}}
            />
          </>}
        />

        <Box pos="fixed" bottom="10%" display="flex" justifyContent="center" w="100%" zIndex="99">
          <PrimaryButton
            fontSize="14px"
            variant="mdBtn"
            buttonText="Add data"
            btnIcon="/assets/imgs/add.png"
            onClick={() => adddata.current.openRight()}
          />
        </Box>

        <Box w="100%" p="1.5rem" pb="5rem" h='calc(100vh - 250px)' overflow="hidden auto">
            <SimpleGrid columns={1} gap="1.5rem">
              {/* Weight start */}
              {data !== null && data.weight.length > 0 ? (
                  <GrayCard
                    cardHeader="Weight"
                    cardHeaderIcon="/assets/imgs/weight.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="left"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            {weightlabel} Kg
                          </Box>
                          <AreaPlotChart
                            originalData={data.weight}
                            colors="l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff"
                            lineColor="#1C4EC5"
                            height = "200px"
                          />
                        </Box>
                      </>
                    }
                    cardFooterText="Add Weight"
                    onClick={() =>
                      openVitalform(
                        "Weight",
                        "/assets/imgs/monitor_weight-blue.png",
                        "Weight",
                        "kg"
                      )
                    }
                  />
                ) : (
                  <GrayCard
                    cardHeader="Weight"
                    cardHeaderIcon="/assets/imgs/weight.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="left"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="28px"
                            fontWeight="600"
                          >
                            No data
                          </Box>
                          <Box
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            <Image
                              w="100%"
                              opacity="0.5"
                              src="/assets/imgs/weight-chart.png"
                            />
                          </Box>
                        </Box>
                      </>
                    }
                    cardFooterText="Add Weight"
                    onClick={() =>
                      openVitalform(
                        "Weight",
                        "/assets/imgs/monitor_weight-blue.png",
                        "Weight",
                        "kg"
                      )
                    }
                  />
              )}
              {/* Weight end */}

              {/* Height start */}
              {data !== null && data.height.length > 0 ? (
                <GrayCard
                  cardHeader="Height"
                  cardHeaderIcon="/assets/imgs/weight.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                        gap="10px"
                      >
                        <Box
                          as="h1"
                          textAlign="left"
                          fontSize="16px"
                          fontWeight="600"
                        >
                          {heightlabel} ft
                        </Box>
                        <AreaPlotChartHeight
                          originalData={heightValuesToNumeric}
                          colors="l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff"
                          lineColor="#1C4EC5"
                          height = "200px"
                          chartName="heightChart"
                        />
                      </Box>
                    </>
                  }
                  cardFooterText="Add Height"
                  onClick={() =>
                    openVitalform(
                      "Height",
                      "/assets/imgs/height-blue.png",
                      "Height",
                      "ft"
                    )
                  }
                />
              ) : (
                <GrayCard
                  cardHeader="Height"
                  cardHeaderIcon="/assets/imgs/weight.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                        gap="10px"
                      >
                        <Box
                          as="h1"
                          textAlign="left"
                          fontSize="28px"
                          fontWeight="600"
                        >
                          No data
                        </Box>
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="100%"
                            opacity="0.5"
                            src="/assets/imgs/weight-chart.png"
                          />
                        </Box>
                      </Box>
                    </>
                  }
                  cardFooterText="Add Height"
                  onClick={() =>
                    openVitalform(
                      "Height",
                      "/assets/imgs/height-blue.png",
                      "Height",
                      "ft"
                    )
                  }
                />
              )}
              {/* Height end */}

              {/* respiratory rate start */}
              {data !== null && data.respiratoryrate.length > 0 ? (
                  <GrayCard
                    cardHeader="Respiratory Rate"
                    cardHeaderIcon="/assets/imgs/lungs.png"
                    cardBody={
                      <>
                        <Box w="100%">
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                            pb="10px"
                          >
                            {respiratorylabel} bpm
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap="10px"
                          >
                            {/* <PointChart
                              data={data.respiratoryrate}
                              height={"200px"}
                              color={"#109E6A"}
                            /> */}
                            <AreaPlotChart
                              // data={data.respiratoryrate}
                              // height={"200px"}
                              // color={"#109E6A"}

                              originalData={data.respiratoryrate}
                              colors="l(270) 0:#ffffff 0.5:#109E6A 1:#109E6A"
                              lineColor="#109E6A"
                              height = "200px"
                               chartName="respChart"
                            dotColor="#109E6A"
                            />
                          </Box>
                        </Box>
                      </>
                    }
                    cardFooterText="Add Respiratory Rate"
                    onClick={() =>
                      openVitalform(
                        "Respiratory rate",
                        "/assets/imgs/pulmonology-blue.png",
                        "Respiratory rate",
                        "bpm"
                      )
                    }
                  />
                ) : (
                  <GrayCard
                    cardHeader="Respiratory Rate"
                    cardHeaderIcon="/assets/imgs/lungs.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="28px"
                            fontWeight="600"
                          >
                            No data
                          </Box>
                          <Box
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            <Image
                              w="100%"
                              opacity="0.5"
                              src="/assets/imgs/res-chart.png"
                            />
                          </Box>
                        </Box>
                      </>
                    }
                    cardFooterText="Add Respiratory Rate"
                    onClick={() =>
                      openVitalform(
                        "Respiratory rate",
                        "/assets/imgs/pulmonology-blue.png",
                        "Respiratory rate",
                        "bpm"
                      )
                    }
                  />
                )}
               {/* respiratory rate end */}

              {/* body tempurature rate start */}
              {data !== null && data.bodytempurature.length > 0 ? (
                <GrayCard
                  cardHeader="body Temperature"
                  cardHeaderIcon="/assets/imgs/thermo.png"
                  cardBody={
                    <>
                      <Box w="100%">
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="left"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            {bodytemplabel} °C
                          </Box>
                          {/* <PointChart
                            data={data.bodytempurature}
                            height={"200px"}
                            color={"#1C4EC5"}
                          /> */}
                           <AreaPlotChart
                             originalData={data.bodytempurature}
                             colors="l(270) 0:#ffffff 0.5:#1C4EC5 1:#1C4EC5"
                             lineColor="#1C4EC5"
                             height = "200px"
                              chartName="bodyTempChart"
                             dotColor="#1C4EC5"
                             />
                        </Box>
                      </Box>
                    </>
                  }
                  cardFooterText="Add Body Temperature"
                  onClick={() =>
                    openVitalform(
                      "Body temperature",
                      "/assets/imgs/thermostats-blue.png",
                      "Temperatures",
                      "°F"
                    )
                  }
                />
              ) : (
                <GrayCard
                  cardHeader="body Temperature"
                  cardHeaderIcon="/assets/imgs/thermo.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                        gap="10px"
                      >
                        <Box
                          as="h1"
                          textAlign="left"
                          fontSize="28px"
                          fontWeight="600"
                        >
                          No data
                        </Box>
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="100%"
                            opacity="0.5"
                            src="/assets/imgs/temp-chart.png"
                          />
                        </Box>
                      </Box>
                    </>
                  }
                  cardFooterText="Add Body Temperature"
                  onClick={() =>
                    openVitalform(
                      "Body temperature",
                      "/assets/imgs/thermostats-blue.png",
                      "Temperatures",
                      "°F"
                    )
                  }
                />
              )}
              {/* body tempurature rate end */}

              {/* oxygen saturation rate end */}
              {data !== null && data.oxygensaturation.length > 0 ? (
                  <GrayCard
                    cardHeader="Oxygen Saturation"
                    cardHeaderIcon="/assets/imgs/spo2.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            {oxygensaturationlabel} %
                          </Box>
                          {/* <PointChart
                            data={data.oxygensaturation}
                            height={"260px"}
                            color={"#F04438"}
                          /> */}
                           <AreaPlotChart
                              originalData={data.oxygensaturation}
                              colors="l(270) 0:#ffffff 0.5:#F04438 1:#F04438"
                              lineColor="#F04438"
                              height = "290px"
                               chartName="oxySatChart"
                            dotColor="#F04438"
                            />
                        </Box>
                      </>
                    }
                    cardFooterText="Add Oxygen Saturation"
                    onClick={() =>
                      openVitalform(
                        "Oxygen Saturation",
                        "/assets/imgs/spo2-blue.png",
                        "Oxygen Saturation",
                        "%"
                      )
                    }
                  />
                ) : (
                  <GrayCard
                    cardHeader="Oxygen Saturation"
                    cardHeaderIcon="/assets/imgs/spo2.png"                  
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="28px"
                            fontWeight="600"
                          >
                            No data
                          </Box>
                          <Box
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            <Image
                              w="100%"
                              opacity="0.5"
                              src="/assets/imgs/oxygen-chart.png"
                            />
                          </Box>
                        </Box>
                      </>
                    }
                    cardFooterText="Add Oxygen Saturation"
                    onClick={() =>
                      openVitalform(
                        "Oxygen Saturation",
                        "/assets/imgs/spo2-blue.png",
                        "Oxygen Saturation",
                        "%"
                      )
                    }
                  />
              )}
              {/* oxygen saturation rate end */}

              {/* blood pressure start */}
              {data !== null && data.bloodpressure.length > 0 ? (
                <GrayCard
                  cardHeader="Blood Pressure"
                  cardHeaderIcon="/assets/imgs/blood_pressure.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                        gap="5px"
                      >
                        {/* <Box display="flex">
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                            marginRight="80px" // Add spacing between the elements if needed
                            marginLeft="14px"
                          >
                             {data && data.bloodpressure ? data.bloodpressure[0].value:""}
                          </Box>
                          <Box
                            as="h1"
                            textAlign="right"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            {data && data.bloodpressure ? data.bloodpressure[1].value:""}
                          </Box>
                        </Box> */}

                        <GroupedColumnRangePlot
                          data={data.bloodpressure}
                          height={"250px"}
                        />
                      </Box>
                    </>
                  }
                  cardFooterText="Add Blood Pressure"
                  onClick={() =>
                    openVitalform(
                      "Blood pressure",
                      "/assets/imgs/blood_pressure-blue.png",
                      "Systolic",
                      "mmHg",
                      "true"
                    )
                  }
                />
              ) : (
                <GrayCard
                  cardHeader="Blood Pressure"
                  cardHeaderIcon="/assets/imgs/blood_pressure.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                        gap="10px"
                      >
                        <Box
                          as="h1"
                          textAlign="left"
                          fontSize="28px"
                          fontWeight="600"
                        >
                          No data
                        </Box>
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="100%"
                            opacity="0.5"
                            src="/assets/imgs/blood-pressure-charts.png"
                          />
                        </Box>
                      </Box>
                    </>
                  }
                  cardFooterText="Add Blood Pressure"
                  onClick={() =>
                    openVitalform(
                      "Blood pressure",
                      "/assets/imgs/blood_pressure-blue.png",
                      "Systolic",
                      "mmHg",
                      "true"
                    )
                  }
                />
              )}
              {/* blood pressure end */}

              {/* pulse start */}
              {data !== null && data.pulse.length > 0 ? (
                  <GrayCard
                    cardHeader="heart rate"
                    cardHeaderIcon="/assets/imgs/heart-rate.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            {pulselabel} bpm
                          </Box>
                          <AreaPlotChart
                            originalData={data.pulse}
                            colors="l(270) 0:#ffffff 0.5:#D80027 1:#D80027"
                            lineColor="#D80027"
                            height = "200px"
                            chartName="redChart"
                            dotColor="#D80027"
                            
                          />
                        </Box>
                      </>
                    }
                    cardFooterText="Add Heart Rate"
                    onClick={() =>
                      openVitalform(
                        "Pulse",
                        "/assets/imgs/ecg_heart-blue.png",
                        "Pulse",
                        "bpm"
                      )
                    }
                  />
                ) : (
                  <GrayCard
                    cardHeader="heart rate"
                    cardHeaderIcon="/assets/imgs/heart-rate.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="28px"
                            fontWeight="600"
                          >
                            No data
                          </Box>
                          <Box
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            <Image
                              w="100%"
                              opacity="0.5"
                              src="/assets/imgs/heart-.png"
                            />
                          </Box>
                        </Box>
                      </>
                    }
                    cardFooterText="Add Heart Rate"
                    onClick={() =>
                      openVitalform(
                        "Pulse",
                        "/assets/imgs/ecg_heart-blue.png",
                        "Pulse",
                        "bpm"
                      )
                    }
                  />
                )}
              {/* pulse end */}

              {/* body mass index start */}
              {data !== null && data.bodymassindexvalue ? (
                <GrayCard
                  cardHeader="BODY MASS INDEX"
                  cardHeaderIcon="/assets/imgs/body-mass.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                        gap="10px"
                      >
                        <GaugeChart
                          value={data.bodymassindexvalue}
                          bodymass={data.bodymassindex}
                          height={"144px"}
                          rotateAngle={data.rotateangle}
                        />
                      </Box>
                    </>
                  }
                  cardFooterText="Update Body Mass Index"
                  onClick={openVitalBMIform}
                />
              ) : (
                <GrayCard
                  cardHeader="BODY MASS INDEX"
                  cardHeaderIcon="/assets/imgs/body-mass.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                        gap="10px"
                      >
                        <Box
                          as="h1"
                          textAlign="left"
                          fontSize="28px"
                          fontWeight="600"
                        >
                          No data
                        </Box>
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="100%"
                            opacity="0.5"
                            src="/assets/imgs/bmi.png"
                          />
                        </Box>
                      </Box>
                    </>
                  }
                  cardFooterText="Update Body Mass Index"
                  onClick={openVitalBMIform}
                />
              )}
              {/* body mass index end */}
            </SimpleGrid>
        </Box>
      </Box>
    </>
  );
};

export default VitalsIndex;
