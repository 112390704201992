import formAPIDocument from "./document";


var formattedOutput = null;

const formAPIModelDocument = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIDocument(item));


  return formattedOutput
};


export default formAPIModelDocument