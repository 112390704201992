import formAPIpastmedicalhistory from "./pastmedicalhistory";


var formattedOutput = null;

const formAPIModelpastmedicalhistory = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIpastmedicalhistory(item));
//console.log(formattedOutput, "Medicalhistory formatted");

  return formattedOutput
};

export default formAPIModelpastmedicalhistory