import moment from "moment";
export default function formapidoctorslots({
    date,
    doctorid,
    endtime,
    shift,
    starttime,
    appointmenttype,
    appointmenttype_cd,

  
})
{ 
  return Object.freeze({
    date,
    doctorid,
    endtime,
    shift,
    starttime,
    appointmenttype,
    appointmenttype_cd,
  });
}