import moment from "moment";
export default function formAPIpastmedicalhistory({
  condition,
  conditionidentifieddate,
  conditiontype,
  // conditiontype_cd,
  // condition_cd,
 

})
{
return Object.freeze({
condition,
   conditionidentifieddate: conditionidentifieddate? moment(conditionidentifieddate).format("YYYY-MM-DD"):"-",
  conditiontype,
  // conditiontype_cd,
  // condition_cd, 
 
});
}