import moment from "moment";
export default function formAPIbloodpressure({
    diastolicbloodpressure,
    patientid,
    patientvitalid,
    systolicbloodpressure,
    vitaladdedday,
    vitaladdedtime,
    vitaladdeddate,
    vitalunit,
    lower_boundsystolic,
    upper_boundsystolic,
    upper_bounddiastolic,
    lower_bounddiastolic,

}, type)
{
  let dateFormat = "DD-MMM";
  let formattedDate = "";
  if(type){
    if (type === 'Month') {
      dateFormat = "DD-MMM";
  } else if (type === 'Year') {
      dateFormat = "DD MMM, YY";
  } else if (type === 'Week') {
      dateFormat = "ddd";
  } else {
      dateFormat = "HH:mm";
  }
  }
//to show time in the tooltip
let dateFormatForTooltip = "DD-MMM HH:mm";
let tooltipLabel = "";
if(type){
if (type === 'Month') {
  dateFormatForTooltip = "DD-MMM HH:mm";
} else if (type === 'Year') {
  dateFormatForTooltip = "DD MMM, YY  HH:mm";
} else if (type === 'Week') {
  dateFormatForTooltip = "ddd HH:mm";
} else {
  dateFormatForTooltip = "HH:mm";
}
}
  
  if (type === "Today") {
      formattedDate = moment(vitaladdedtime, "HH:mm").format(dateFormat);
      tooltipLabel =moment(vitaladdedtime, "HH:mm").format(dateFormat);
  } else {
      formattedDate = moment(vitaladdedday, "DD-MM-YY").format(dateFormat);
      //to show time in the tooltip
      tooltipLabel = moment(vitaladdeddate).format(dateFormatForTooltip);
  }
  let status;
  const lowerBoundsystolic = parseFloat(lower_boundsystolic);
  const upperBoundsystolic = parseFloat(upper_boundsystolic);
  const lowerBounddiastolic = parseFloat(lower_bounddiastolic)
  const upperBounddiastolic = parseFloat(upper_bounddiastolic)

  const actualSystolic = parseFloat(systolicbloodpressure);
  const actualDiastolic = parseFloat(diastolicbloodpressure);

  let systolicStatus = '';
  let diastolicStatus = '';

  if (!isNaN(actualSystolic) && !isNaN(actualDiastolic)) {
    if (actualSystolic <= lowerBoundsystolic && actualSystolic < 90) {
      systolicStatus = 'low';
    } else if (actualSystolic <= lowerBoundsystolic && actualSystolic < 120) {
      systolicStatus = 'low';
    } else if (actualSystolic <= lowerBoundsystolic && actualSystolic > 130) {
      systolicStatus = 'high';
    } else if (actualSystolic > upperBoundsystolic && actualSystolic < 90) {
      systolicStatus = 'low';
    } else if (actualSystolic > upperBoundsystolic && actualSystolic < 130) {
      systolicStatus = 'normal';
    } else if (actualSystolic > upperBoundsystolic && actualSystolic > 130) {
      systolicStatus = 'high';
    } else if (actualSystolic >= lowerBoundsystolic && actualSystolic <= upperBoundsystolic && actualSystolic < 90) {
      systolicStatus = 'low';
    } else if (actualSystolic >= lowerBoundsystolic && actualSystolic <= upperBoundsystolic && actualSystolic < 130) {
      systolicStatus = 'normal';
    } else if (actualSystolic >= lowerBoundsystolic && actualSystolic <= upperBoundsystolic && actualSystolic > 130) {
      systolicStatus = 'high';
    }

    if (actualDiastolic < lowerBounddiastolic && actualDiastolic < 60) {
      diastolicStatus = 'low';
    } else if (actualDiastolic < lowerBounddiastolic && actualDiastolic < 120) {
      diastolicStatus = 'low';
    } else if (actualDiastolic > upperBounddiastolic && actualDiastolic < 90) {
      diastolicStatus = 'low';
    } else if (actualDiastolic > upperBounddiastolic && actualDiastolic < 130) {
      diastolicStatus = 'normal';
    } else if (actualDiastolic > upperBounddiastolic && actualDiastolic > 130) {
      diastolicStatus = 'high';
    } else if (actualDiastolic >= lowerBounddiastolic && actualDiastolic <= upperBounddiastolic && actualDiastolic < 90) {
      diastolicStatus = 'low';
    } else if (actualDiastolic >= lowerBounddiastolic && actualDiastolic <= upperBounddiastolic && actualDiastolic < 130) {
      diastolicStatus = 'normal';
    } else if (actualDiastolic >= lowerBounddiastolic && actualDiastolic <= upperBounddiastolic && actualDiastolic > 130) {
      diastolicStatus = 'high';
    }

    // Low 	low  	
    // High high	
    // Normal normaal

    // Low normal	
    // normal High 
    // high Low 

    if (systolicStatus === 'low' && diastolicStatus === 'low') {
      status = 'high';
    } else if (systolicStatus === 'normal' && diastolicStatus === 'normal') {
      status = 'normal';
    } else if (systolicStatus === 'high' && diastolicStatus === 'high') {
      status = 'high';
    } else if (systolicStatus === 'low' && diastolicStatus === 'high') {
      // Prioritize low over high for systolic low and diastolic high
      status = 'low';
    } else if (systolicStatus === 'normal' && diastolicStatus === 'high') {
      // Prioritize high over normal for systolic normal and diastolic high
      status = 'high';
    } else if (systolicStatus === 'normal' && diastolicStatus === 'low') {
      // Prioritize high over low for systolic high and diastolic low
      status = 'normal';
    }
    else if (systolicStatus === 'high' && diastolicStatus === 'normal') {
      status = 'high';
    }
    else if (systolicStatus === 'high' && diastolicStatus === 'low') {
      status = 'high';
    }
    else if (systolicStatus === 'high' && diastolicStatus === 'high') {
      status = 'high';
    }
  }
  return Object.freeze({
    diastolicbloodpressure,
    patientid,
    patientvitalid,
    systolicbloodpressure,
    vitaladdedday : formattedDate? formattedDate: "",
    vitaladdeddate: vitaladdeddate? moment(vitaladdeddate).format("YYYY-MM-DD"):"-",
    vitalunit,
    color: status === "high" ? "#F04438" : "#1C4EC5",
    tooltipLabel: tooltipLabel ? tooltipLabel : '',
   
  });
 
}