import { COLORS } from "../styles/colors";

const tabContainer = {
    padding: '1',
    borderRadius: "10px",
    height: "40px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    gap: "3", 
  };
  


const tabBtn = {
    borderRadius: "8px",
    height: "32px",
    borderRadius: "full",
    padding: "12px 20px", 
    fontSize: "sm",
    fontWeight: "semibold",
    color: "#061027",
    cursor: "pointer",
    bg: "transparent", 
    borderWidth: "1px",
    borderColor: "gray.200",
    _hover: {
      bg: "gray.50", 
    },
    '&[aria-selected="true"]': {
      bg: "#061027", 
      color: "white", 
      borderColor: "blue.500",
      boxShadow: "sm", 
    },
    '&[aria-selected="false"]': {
      bg: "transparent", 
      color: "gray.600",
    },
    _focus: {
      boxShadow: "none", 
    },
  };
  

export {tabContainer, tabBtn}