import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import { Box, Center } from "@chakra-ui/layout";
import {
  Text,
  Image,
  VStack,
  Stack,
  Circle,
  Flex,
  Grid,
  GridItem,
  Divider,
  SimpleGrid,
  Spacer,
} from "@chakra-ui/react";
import DoctorCard from "../../components/doctor-card";

import { FetchTreatmentTeam } from "../../middleware/services/home";
import { useNavigate } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import moment from "moment";
import Footer from "../../components/footer";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { useScreenType } from "../../auth";

const TreatmentTeamIndex = () => {

  
  var [data, setData] = useState(null);
  var [patientid, setPatientid] = useState();
  var [activeuserid, setActiveUser] = useState();
  var navigate = useNavigate();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const screenType = useScreenType();
  useEffect(() => {
    showLoader();
    const fetchData = async () => {
      try {
        const result = await FetchTreatmentTeam({
          patientid,
          activeuserid,
        });
        console.log(result, "modified treatment res");
        if (result != null) {
          setData(result);
          
        } else {
          //alert("No data");
        }
        dismissLoader();
      } catch (error) {
        console.error("Error in home:", error);
      }
    };
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };
  return (
    <>
    {screenType == 'web' ? (
      <Box w="100%">
        <Box top="0" zIndex="banner">
          <Navbar />
          <SecondaryNav
            withBack="Treatment Team"
            goBack={()=> navigate("/home")}
          />
           {isLoading && <Loader />}
        </Box>
      
          <Box  
            
            // overflowY="auto"
            >

          {/* Main content start */}
          <Flex bg="#fcfcfd" flexDirection="column" w="100%" h="calc(100vh - 180px)" overflow="hidden auto">
            
            {data && data.length> 0 ?(
              
                        <Flex
                          wrap="wrap"
                          p="20px"
                          w="100%"
                          justifyContent="start"
                          gap="20px"
                        >
            
                          {data &&
                              data.map((step, index) => (
                          <Box key={index} flex="0 0 calc(33.333% - 20px)" mb="20px">
                            <DoctorCard
                              disableFooterText={{fontSize:"12px"}}
                              cardBody={
                                <>
                                  <Flex alignItems="flex-start" gap="10px">
                                  
                                     <Box>
                                     <Image
                              w="40px"
                              h="40px"
                              borderRadius="full"
                              src={step.doctorimage ? step.imagepath + step.doctorimage : "/assets/imgs/no-image-icon.png"}
                               
                              mr="3"
                            />
                                        </Box>
                                    <Box>
                                      <Box as="h1" fontSize="16px" fontWeight="600">
                                      
                                      {step.doctorname ? step.doctorname : "-"} 
                                      </Box>
                                      <Box as="p" fontSize="12px" fontWeight="400">
                                      {step.entitytype ? step.entitytype : ""} 
                                      </Box>
                                    </Box>
                                  </Flex>
                                  <Divider my="10px" />
                                  <Flex gap="8px" align="center">
                                    <Text fontSize="12px" fontWeight="500">
                                    {formatDate(step.date)}
                                    </Text>
                                  </Flex>
                                 
                                </>
                              }
                                // hasFooter={step.ismidocdoctor === 1? true: false}
                                isButtonDisabled={(step.ismidocdoctor === 1? false: true) || (step.isdoctoractive === 1? false: true)}
                                cardFooterText={step.ismidocdoctor === 1? "Book Appointment": "Outside doctor appointments unavailable."}
                                onClick={ step.ismidocdoctor === 1?  ()=> navigate("/doctorprofile", { state: { doctorid: step.doctorid, fullname: step.doctorname }}): null}
                              
                             
                            />
                          </Box>
                         
                         ))}
                        </Flex>
                        ):(
                           <Box
                          w="100%"
                          h="100vh"
                          display="flex"
                          flexDirection="column"
                          gap="5px"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Image w="60px" src="/assets/imgs/treatment-plan.png" />
                          <Box as="p">No data available</Box>
                         
                        </Box> 
                         )}
                      </Flex>
          <Footer />
          {/* Main content end */}
        </Box>
      </Box>
    ) : (
      //Mobile responsive start
      <Box w="100%" bg="#fcfcfd">
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/home')}
          header="Treatment Team"
        />

        <Box w="100%" p="1.5rem" h='calc(100vh - 200px)' overflow="hidden auto">
        {data && data.length> 0 ?(
              <SimpleGrid
                columns={1}
                gap="20px"
              >
  
                {data &&
                    data.map((step, index) => (
                <Box key={index} flex="0 0 calc(33.333% - 20px)" mb="20px">
                  <DoctorCard
                    cardBody={
                      <>
                        <Flex alignItems="flex-start" gap="10px">
                        
                           <Box>
                           <Image
                    w="40px"
                    h="40px"
                    
                    borderRadius="full"
                    src={step.doctorimage ? step.imagepath + step.doctorimage : "/assets/imgs/no-image-icon.png"}
                               
                    mr="3"
                  />
                              </Box>
                          <Box>
                            <Box as="h1" fontSize="16px" fontWeight="600">
                            
                            {step.doctorname ? step.doctorname : "-"} 
                            </Box>
                            <Box as="p" fontSize="12px" fontWeight="400">
                            {step.entitytype ? step.entitytype : ""} 
                            </Box>
                          </Box>
                        </Flex>
                        <Divider my="10px" />
                        <Flex gap="8px" align="center">
                          <Text fontSize="12px" fontWeight="500">
                          {formatDate(step.date)}
                          </Text>
                        </Flex>
                       
                      </>
                    }
                    isButtonDisabled={(step.ismidocdoctor === 1? false: true) || (step.isdoctoractive === 1? false: true)}
                    cardFooterText={step.ismidocdoctor === 1? "Book Appointment": "Outside doctor appointments unavailable."}
                    onClick={ step.ismidocdoctor === 1?  ()=> navigate("/doctorprofile", { state: { doctorid: step.doctorid, fullname: step.doctorname }}): null}
                  />
                </Box>
               
               ))}
              </SimpleGrid>
              ):(
                 <Box
                w="100%"
                h="100vh"
                display="flex"
                flexDirection="column"
                gap="5px"
                justifyContent="center"
                alignItems="center"
              >
                <Image w="60px" src="/assets/imgs/treatment-plan.png" />
                <Box as="p">No data available</Box>
               
              </Box> 
            )}
        </Box>
      </Box>
    )}
    </>
  );
};

export default TreatmentTeamIndex;
