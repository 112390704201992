import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import { RadioButton, RadioList } from "../../components/radio-button";
import {
  Text,
  Image,
  Flex,
} from "@chakra-ui/react";
import {loadStripe} from '@stripe/stripe-js';

export default function TestIndex() {
  const [selectedOption, setSelectedOption] = useState("syrup"); // Default value can be adjusted

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };
  const makePayment = async()=>{
    const stripe = await loadStripe("pk_test_51P6VZWSCJ9fqDA30yhBWiP5ChfbMEYAMEoZ5fGE0yVDHn9Nv1rUUmjJC2fgFqJMgLIW8lldfZ7Yy4oM1GmB76K1o00DSJgbipX");

    const body = {
        products: [{
            amount: 100, // Amount in paisa (100 paisa = 1 INR)
            currency: "INR"
        }]
    };
    const headers = {
        "Content-Type":"application/json"
    }
    const response = await fetch("http://localhost:7000/api/create-checkout-session",{
        method:"POST",
        headers:headers,
        body:JSON.stringify(body)
    });

    const session = await response.json();
    console.log(session,"res")
    alert(response)
    const result = stripe.redirectToCheckout({
     
        sessionId:session.id
    });
    
    if(result.error){
        console.log(result.error);
    }
}
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="20px"
        h="100vh"
        w="300px"
        marginInline="auto"
      >
        <RadioList defaultValue="honey" onChange={handleOptionChange} name="flavor">
          <RadioButton label="Syrup" value="syrup" />
          <RadioButton label="Honey" value="honey" />
          <RadioButton label="Sugar" value="sugar" />
        </RadioList>
        <Flex gap="20px" justifyContent="space-between">
                    <Box w="100%" cursor="pointer" boxShadow="0px 8px 20px 0px #E6E7E966" onClick={makePayment}>
                      <Flex
                        p="16px 20px"
                        borderRadius="15px 15px 0 0"
                        h="70px"
                        gap="8px"
                        background="radial-gradient(circle, rgba(59,109,228,1) 0%, rgba(28,78,197,1) 100%)"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text fontSize="16px" fontWeight="600" color="white">
                          Silver Plan
                        </Text>
                      </Flex>

                      <Box
                        as="button"
                        w="100%"
                        border="1px solid #E6E7E9"
                        borderRadius="0 0 13px 13px"
                        bg="white"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p="12px"
                      >
                        <Text fontSize="12px" fontWeight="500">
                          Pay Now
                        </Text>
                        <Image src="/assets/imgs/right-icon.png" />
                      </Box>
                    </Box>
                  </Flex>
      </Box>

  
    </>
  );
}
