import moment from "moment";
export default function formAPIRewards({
    rewardid,
    patientid,
    rewardname,
    pointsearned,
    dateearned
   

})
{
  return Object.freeze({
    rewardid,
    patientid,
    rewardname,
    pointsearned,
    dateearned : moment(dateearned).format("Do MMM YYYY"),
   
  });
}