
import { CONFIG } from '../../../appconfig/appconfig';
import axios from 'axios';
import FetchData from '../../client';
import { getLoginInfo } from '../../../auth';

  const FetchImagingDataAI = async (uri,name,type) => {
    try {     
      const formData = new FormData();
      formData.append("file", uri);

      
      let patientid = getLoginInfo();
      formData.append("patientid", patientid);
      //console.log(formData);
      const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_imagereport`,formData, {    
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
      });
      //console.log('@@response',response)
      if (response.status === 200) {
        const result = await response.data;
        return result
      } else {
        const error = "failed" ;
        return error
      }
    } catch (err) {
      console.log(err, " error in Fetch data AI"); 
      const error = "failed" ;
      return error
    }
  };
 
  const uploadImagingDocument = async (inputData) => {
    try {
      
    // inputData.patientid = localStorage.getItem("loginid");
      const result = await FetchData("midoc_addmedicalrecord", inputData);
      // if (result.output.data) {
      //   //console.log(result.output.data)
      // var finalOutput = formAPIImaging(result.output.data)
      // }
      // console.log(result, "Labs Result")
      // console.log(finalOutput, "Labs Formatted output")
      
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
 
export {FetchImagingDataAI, uploadImagingDocument};
