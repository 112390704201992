import { Flex, VStack, Box, Image, Text, Spacer, Center, Input ,  Divider,
  Menu,
  MenuButton,
  RadioGroup,
  Radio,} from "@chakra-ui/react";
import { DropdownInput, PrimaryButton, PrimaryButtonIcon, PrimaryInput, PrimaryInputDropdown, SearchBar, TagsInput, Toaster } from "../../components";
import { generateOtp, signUp } from "../../middleware/services/signup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import MiniDropdownInput from "../../components/mini-dropdown";
import DatePicker from "../../components/date-picker";
import { format, toDate } from "date-fns";
import Loader from "../../components/loader/loader";
import useLoader from "../../components/loader/loaderstates";
import { useReducedMotion } from "framer-motion";
import { cmToFtConverter, ftToCmConverter,  kgToLbConverter, lbToKgConverter, formatDate_DD_MM_YYYY, useScreenType } from '../../auth'
import moment from "moment";
import DropdownInputForVitals from "../../components/dropdown-input-forVitals";
import { MdHomeFilled } from "react-icons/md";

function PersonalDetails() {

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [fullname, setFullname] = useState('')
  const [gender, setGender] = useState(null)
  const [height, setHeight] = useState(null)
  const [heightunit_cd, setHeightunit_cd] = useState(152)
  const [weight, setWeight] = useState(null)
  const [weightunit_cd, setWeightunit_cd] = useState(155)
  const [age, setAge] = useState('')
  const [dob, setDob] = useState(null)
  const [bloodtype_cd, setBloodtype_cd] = useState('')
  const [mobileno, setMobileno] = useState('')
  const [email, setEmail] = useState('')
  const [countryid, setCountryid] = useState('299')
  const [countrycode, setCountrycode] = useState('+1')
  const [password, setPassword] = useState('')
  const [vaccinated, setVaccinated] = useState('')
  const [newUserEmail, setNewUserEmail] = useState('')
  const [newUserMobile, setNewUserMobile] = useState('')
  const [ispatient, setIspatient] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [inputClicked, setInputClicked] = useState(false);
  const [defaultGender, setDefaultGender] = useState(null)
  
  const { isLoading, showLoader, dismissLoader } = useLoader();
  
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [time, setTime] = useState(moment().format("HH:mm"));


  //BMI states 

  const [vitalunitheight, setVitalUnitHeight] = useState("");
  const [vitalunitheight_cd, setVitalUnitHeight_cd] = useState("");
  const [BMICalculatorType, setBMICalculatorType] = useState('standard');

  const [vitalunitweight, setVitalUnitWeight] = useState("");
  
  const [feetValue , setFeetValue] = useState("")
  const [inchValue , setInchValue] = useState("")
  
  const [heightOptionsInBMI, setHeightOptionsInBMI] = useState([]);
  const [weightOptionsInBMI, setWeightOptionsInBMI] = useState([]);
  
 const [ weightUnitBMI , setWeightUnitBMI ]= useState("")
 const [ heightUnitBMI , setHeightUnitBMI ]= useState("")
 const [showInchesInput , setShowInchesInput] = useState(false)
 const [feetOptions, setFeetOptions] = useState([]);
 const [inchesOptions, setInchesOptions] = useState([]);

 const detailsFromLocal = JSON.parse(localStorage.getItem('personalDetails'));
 const formattedDate = detailsFromLocal && detailsFromLocal.dob
   ? moment(detailsFromLocal.dob).format('DD-MM-yyyy')
   : '';
 const [datePrePopulate, setDatePrePopulate] = useState(formattedDate);
 const navigate = useNavigate();
 const screenType = useScreenType()
  useEffect(() => {
    var storedPersonalDetails = localStorage.getItem("personalDetails");
    var BMICalculatorType = localStorage.getItem("BMICalculatorType");
    // Parse the string back into an object
    var detailsFromLocal = JSON.parse(storedPersonalDetails);
    if (detailsFromLocal) {
      setFirstname(detailsFromLocal.firstname)
      setLastname(detailsFromLocal.lastname)
      //setHeight(detailsFromLocal.height)
     // setWeight(detailsFromLocal.weight)
      setMobileno(detailsFromLocal.mobileno)
      if(detailsFromLocal.dob == "Invalid date"){
        setDob(null)
      }else{
        const formattedDob = moment(detailsFromLocal.dob).format('YYYY-MM-DD');
        setDob(formattedDob);

        var formatedDate = moment(detailsFromLocal.dob).format("DD-MM-yyyy");
        setDatePrePopulate(formatedDate)
      }
      setEmail(detailsFromLocal.email)

      if (detailsFromLocal.gender == "2") {
        setDefaultGender("Male")
      } else if (detailsFromLocal.gender == "1") {
        setDefaultGender("Female")
      } else if (detailsFromLocal.gender == "3") {
        setDefaultGender("Others")
      }
      
    }
    if(detailsFromLocal){
      if(detailsFromLocal.heightunit_cd == 152){
        // var convertedHeight = cmToFtConverter(detailsFromLocal.height)
        // setFeetValue(convertedHeight.feet)
        // setInchValue(convertedHeight.inches)
        let parts = detailsFromLocal.height.toString().split('.');
        setFeetValue(parts[0])
        setInchValue(parts[1])
      }else if (detailsFromLocal.heightunit_cd == 151){
        // let parts = detailsFromLocal.height.toString().split('.');
        // setFeetValue(parts[0])
        // setInchValue(parts[1])
        setHeight(detailsFromLocal.height)
        
      }
      setWeight(detailsFromLocal.weight)
    }
   
    showLoader();
    const fetchData = async () => {
      var countrycode = localStorage.getItem("countryid")
      if (countrycode == "+1") {
        setCountrycode("+1")
        setCountryid("229")
      } else if (countrycode == "+93") {
        setCountrycode("+93")
        setCountryid("1")
      } else if (countrycode == "+91") {
        setCountrycode("+91")
        setCountryid("95")
      }
      // try {
      //   const generateOTP = await generateOtp(inputToGenerateOTP);
      // } catch (error) {
      //   console.error('Error generating OTP:', error);
      // }
    };
    if(BMICalculatorType){
      //alert(BMICalculatorType)
    switchBMICalculation(BMICalculatorType)
    }


    if(BMICalculatorType == "standard"){

    setVitalUnitHeight("ft");
    setVitalUnitWeight("lbs");
    }else if(BMICalculatorType == "metric"){

    setVitalUnitHeight("cms");
    setVitalUnitWeight("kg");
    }else{

    setVitalUnitHeight("ft");
    setVitalUnitWeight("lbs");
    }
    
    const tempOptions = [];
    const tempOptionsInches = [];
    const tempOptionsHeight = [];
    const tempOptionsWeightLB = [];
    for (let i = 1; i <= 10; i++) {
      tempOptions.push(i);
    }
    for (let i = 1; i <= 11; i++) {
      tempOptionsInches.push(i);
    }
  
    for (let i = 1; i <= 479; i++) {
      tempOptionsWeightLB.push(i);
    }
    setFeetOptions(tempOptions)
    setInchesOptions(tempOptionsInches)
    setWeightOptionsInBMI(tempOptionsWeightLB)
  

    fetchData();
    
    dismissLoader();
  }, []);

  // useEffect(()=>{
  //   console.log(datePrePopulate , " datePrePopulate ")
  // },[dob])

  useEffect(() => {
    setMobileno(newUserMobile ? newUserMobile : mobileno)
    if (mobileno == "" || firstname == '' || mobileno.length < 10) {
      setButtonDisabled(true)
    } else {

      setButtonDisabled(false)
    }
  }, [firstname, email, mobileno, newUserMobile]);

  useEffect(() => {
    var NewUserEmail = localStorage.getItem("NewUserEmail");
    if (NewUserEmail) {
      setNewUserEmail(NewUserEmail);
    }

    var NewUserMobile = localStorage.getItem("NewUserMobile");
    
    if (NewUserMobile != null && NewUserMobile != "undefined" && NewUserMobile !== "") {
      setNewUserMobile(NewUserMobile);
    }else{
      
      setNewUserMobile("");
    }
  }, []);

  useEffect(() => {
    handleDefaultValueUpdate(newUserEmail);
    handleDefaultValueUpdateMobile(newUserMobile)
  }, [newUserEmail, newUserMobile]);


  var inputForSignUpPersonalDetails = {
    firstname,
    lastname,
    fullname,
    gender,
    "height" : height ? height : (feetValue && inchValue ? feetValue + "." + inchValue : feetValue),
    heightunit_cd,
    weight,
    weightunit_cd,
    age,
    dob,
    bloodtype_cd,
    mobileno,
    email,
    countryid,
    countrycode,
    password,
    vaccinated,
    "addeddate": `${date} ${time}`,
  }

  const handleDateChange = (date) => {
     const formattedDate = format(date, "yyyy-MM-dd");
     setDob(formattedDate);
  };


  var inputToGenerateOTP = {
   // otp: "1234",
   // mobileno,
    countrycode,
    type: "registration",
    language: "eng",
    countryid,
    ispatient:1,
    email
  }

  const { showSuccessToast, showErrorToast } = Toaster();
  const isAtLeast13YearsOld = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
  
    // Adjust age if the birth month is later in the year than the current month
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age >= 13;
  };
  const SubmitFunction = async () => {
    setEmail(newUserEmail)
    
    if (firstname == "") {
      showErrorToast("Please Enter Name")
      return false;
    } else if (!isAtLeast13YearsOld(dob)) {
      showErrorToast("Age must be at least 13 years")
      return false;
    }
    else if (mobileno == "") {
      showErrorToast("Please Enter Mobile Number ")
      return false;
    }
    //  else if (heightunit_cd == "151" && height !== null  && height !== "" && height.length < 2) {
    //    showErrorToast("Please enter at least two digits for height in centimeters.");
    //     return false;
    // }
    else if (heightunit_cd == "151" && height != null  && height != "" && (height < 30 || height > 303.99)) {
     
      showErrorToast("Please enter a valid height value, ranging between 30 to 303 for centimeter");
       return false;
   }  else if ( weight == null  ) {
    showErrorToast("Please enter weight value ");
     return false;
 }
    else if (weightunit_cd == "155" && weight !== null  && weight !== "" && (weight < 1 || weight > 479.99)) {
    showErrorToast("Please enter a valid weight value, ranging between 1 to 479 for lbs");
     return false;

 }
 else if (weightunit_cd == "154" && weight !== null  && weight !== "" && (weight < 1 || weight > 250)) {
  showErrorToast("Please enter a valid weight value, ranging between 1 to 250 for kg");
   return false;

}
 else if (BMICalculatorType == "metric" && (height == "" ||height == null )    ) {
  showErrorToast("Please enter height value ");
  return false;
}
 else if (BMICalculatorType == "standard" &&   feetValue == ""  ) {
  showErrorToast("Please enter height value ");
  return false;
} else if (BMICalculatorType == "standard" &&   feetValue != "" &&(feetValue < 1 || feetValue > 12) ) {
  showErrorToast("Please enter a valid height value ");
  return false;
}else if (BMICalculatorType == "standard" &&  (inchValue && ( inchValue < 0 || inchValue > 12)) ) {
  showErrorToast("Please enter a valid inch value");
  return false;
} else if (dob == null) {
  showErrorToast("Please Enter Date of Birth ")
  return false;
} else if (email == "") {
  showErrorToast("Please Enter Email Address ")
  return false;
} else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
  showErrorToast("Please Enter a Valid Email Address");
  return false;
} 
    else {
      showLoader();
      try {
        
        var countryCode = localStorage.getItem("countrycode")
        var countryId = localStorage.getItem("countryid")
        
        inputForSignUpPersonalDetails.countrycode = countryCode
        inputForSignUpPersonalDetails.countryid = countryId

        const result = await signUp(inputForSignUpPersonalDetails);
        
        localStorage.setItem("personalDetails", JSON.stringify(inputForSignUpPersonalDetails));
        localStorage.setItem("BMICalculatorType", BMICalculatorType)
        var patientID = result.output.id
        var MobileNo = result.output.mobileno;
        localStorage.setItem('patientId', patientID);
        localStorage.setItem('NewUserMobile', MobileNo);

        setIspatient(patientID)

        sessionStorage.setItem('value2', email);


        if (result.output.message == "Successfully Registered") {
          try {
            const generateOTP = await generateOtp(inputToGenerateOTP);
          } catch (error) {
            console.error('Error generating OTP:', error);
          }
          // showSuccessToast("Successfully Registered")
          setTimeout(() => {

          navigate('/otp');
          }, 1000);

        }else if (result.output.message == "Email already exists") {

          showErrorToast("Email already exists")
          
      dismissLoader();
          return false;

        }else if (result.output.message == "Mobile number already exists") {

          showErrorToast("Mobile Number already exists.")
          
      dismissLoader();
          return false;

        }else {
          console.error(result, "Something went wrong in Submit.")
          
      dismissLoader();
          showErrorToast("Something went wrong!")
        }

      } catch (error) {
        
      dismissLoader();
        console.error('Error in SubmitFunction:', error);
      }
      dismissLoader();
    }
  };

  const handleChangeWeightUnit = (selectedOption) => {
    setWeightunit_cd(selectedOption.value);
  };

  const handleChangeHeightUnit = (selectedOption) => {
    setHeightunit_cd(selectedOption.value);
  };





  const handleDefaultValueUpdate = (email) => {
    const inputElement = document.getElementById("Email");
    if (inputElement) {
      inputElement.defaultValue = email;
    }
  };
  const handleDefaultValueUpdateMobile = (mobile) => {
    const inputElement = document.getElementById("mobile");
    if (inputElement) {
      inputElement.defaultValue = mobile;
    }
  };





  const handleMobileChange = (e) => {
    // var countrycode = localStorage.getItem("countryid")
    // if (countrycode == "+1") {
    //   setCountrycode("+1")
    //   setCountryid("229")
    // } else if (countrycode == "+93") {
    //   setCountrycode("+93")
    //   setCountryid("1")
    // } else if (countrycode == "+91") {
    //   setCountrycode("+91")
    //   setCountryid("95")
    // }
    
    var countryId = localStorage.getItem("countryid")
    var countryCode = localStorage.getItem("countrycode")
    
    setCountrycode(countryCode)
    setCountryid(countryId)

    setNewUserMobile(e.target.value.replace(/[^0-9]/g, "").slice(0, 10));
    setMobileno(e.target.value.replace(/[^0-9]/g, "").slice(0, 10));
    var NewUserMobile = localStorage.getItem("NewUserMobile");
    if (NewUserMobile) {
      localStorage.setItem("NewUserMobile", e.target.value.replace(/[^0-9]/g, "").slice(0, 10));
    }

  }
  const handleSetHeight = (event) => {
    let cleanedValue = event.target.value.replace(/[^0-9.]/g, '');
    if(cleanedValue.startsWith(".")){
      cleanedValue = cleanedValue.substring(1);
    }
    if(heightunit_cd == "152"){
      if (cleanedValue.match(/[0-9]{2,}/)) {
        cleanedValue = cleanedValue.slice(0, -1);
    }
    }else{
      if (cleanedValue.match(/[0-9]{4,}/)) {
        cleanedValue = cleanedValue.slice(0, -1);
    }
    }
    
    const decimalIndex = cleanedValue.indexOf('.');
    if (decimalIndex !== -1) {
        const parts = cleanedValue.split('.');
        cleanedValue = parts[0] + '.' + parts[1].slice(0, 1); 
    }
    setHeight(cleanedValue);
    if(event.target.value < 1){
    setHeight(null);
    }
};

  

const handleSetWeight = (event) => {
  let cleanedValue = event.target.value.replace(/[^0-9.]/g, ''); 
  if(cleanedValue.startsWith(".")){
    cleanedValue = cleanedValue.substring(1)
  }
  if (cleanedValue.match(/[0-9]{4,}/)) { 
      cleanedValue = cleanedValue.slice(0, -1); 
  }

  const decimalIndex = cleanedValue.indexOf('.'); 
  if (decimalIndex !== -1) { 
      const parts = cleanedValue.split('.'); 
      cleanedValue = parts[0] + '.' + parts[1].slice(0, 1); 
  }

  setWeight(cleanedValue); 
  if(event.target.value < 1){
    setWeight(null);
    }
};


  const selectGender = (selectedOption) => {
    if (selectedOption == "Male") {
      setGender("2")
    } else if (selectedOption == "Female") {
      setGender("1")
    } else if (selectedOption == "Others") {
      setGender("3")
    }
  };

  const handleMobilepassKeyPress = (e) => {
    if (e.key === "Enter") {
      SubmitFunction();
    }
  };

  const handleChangeEmail = (event) => {
    const sanitizedValue = event.target.value.replace(/[^\x00-\x7F]/g, '')
    setNewUserEmail(sanitizedValue);
    setEmail(sanitizedValue);
    var NewUserEmail = localStorage.getItem("NewUserEmail");
    if (NewUserEmail) {
      localStorage.setItem("NewUserEmail", sanitizedValue);
    }
  };

  const handleChangeFirstName = (event) => {
    const newEmail = localStorage.getItem("NewUserEmail")
    if(newEmail){
      setEmail(newEmail)
    }
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^A-Za-z\s]/g, '').slice(0, 50);
    event.target.value = inputValue;
    setFirstname(event.target.value);
  };
  const handleLastName = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^A-Za-z\s]/g, '').slice(0, 50);
    event.target.value = inputValue;
    setLastname(event.target.value);
  };

  //BMI functions 

  useEffect(()=>{
    if(vitalunitheight == "ft"){
      setShowInchesInput(true)
     // alert(vitalunitheight)
    }else{
      setShowInchesInput(false)
    }
  }, [vitalunitheight])

  const switchBMICalculation = (value) => {
    const tempOptionsWeightLB = [];
    const tempOptionsHeight = [];
    setBMICalculatorType(value);
    if (value == "metric") {
     // setWeight("")
      setVitalUnitHeight("cms");
      setVitalUnitWeight("kg");

      // CM - 151
      setHeightunit_cd(151)

      //kg  - 154
      setWeightunit_cd(154)

      if(weight){
        var converterValueWeight = lbToKgConverter(weight)
        setWeight(converterValueWeight.value)
      }
      if(feetValue){
          var converterValueHeigt = ftToCmConverter(feetValue, inchValue)
          setHeight(converterValueHeigt)
        
      }
      
      for (let i = 1; i <= 250; i++) {
        tempOptionsWeightLB.push(i);
      }
      for (let i = 30; i <= 303; i++) {
        tempOptionsHeight.push(i);
      }
    setHeightOptionsInBMI(tempOptionsHeight)
    setWeightOptionsInBMI(tempOptionsWeightLB)
    } else if (value == "standard") {
    //  setWeight("")
      setVitalUnitHeight("ft");
      setVitalUnitWeight("lbs");
      // ft - 152
      setHeightunit_cd(152)

      // lb - 155
      setWeightunit_cd(155)

      if(weight){
        var converterValueWeight = kgToLbConverter(weight)
        setWeight(converterValueWeight.value)

      }

      if(height){

          var converterValueHeigt = cmToFtConverter(height)
          setFeetValue(converterValueHeigt.feet)
          setInchValue(converterValueHeigt.inches)
          setHeight(null)
      }

      for (let i = 1; i <= 479; i++) {
        tempOptionsWeightLB.push(i);
      }
      // for (let i = 1; i <= 10; i++) {
      //   tempOptionsHeight.push(i);
      //   for (let j = 1; j <= 9; j++) {
      //     tempOptionsHeight.push((`${i}.${j}`));
      //   }
      // }
   // setHeightOptionsInBMI(tempOptionsHeight)
    setWeightOptionsInBMI(tempOptionsWeightLB)
    } 
  };
  const setInchValueInDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setInchValue(stringValue)
  }
  const handleBMIinchValue = (e)=>{
    /// alert(e.target.value)
     var value = e.target.value;
     const numbersRegex = /^[0-9]+$/;
     if (numbersRegex.test(value) || value === "") { // Also allow empty value
      setInchValue(value)
     }
     
   }
   const setFeetValueInDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setFeetValue(stringValue)
  }
  const handleBMIFeetValue = (e)=>{
    var value = e.target.value;
    const numbersRegex = /^[0-9]+$/;
    if (numbersRegex.test(value) || value === "") { 
      setFeetValue(value)
    }
    
  }

   // THis is BMI on change event
   const handlebmivitalvaluechange = (e) => {
    const { id, value } = e.target;
    const maxLength = 6;
    switch (id) {
      case "weight":
        let inputValue = value;
       const inputText = e.target.value;
        if (inputText.length <= maxLength) {
          
        let cleanedValue = inputText.replace(/[^0-9.]/g, '');
        if(cleanedValue.startsWith(".")){
          cleanedValue = cleanedValue.substring(1)
        }
        if (cleanedValue.match(/[0-9]{4,}/)) {
          cleanedValue = cleanedValue.slice(0, -1);
        }
        const decimalIndex = cleanedValue.indexOf('.');
        if (decimalIndex !== -1) {
          const parts = cleanedValue.split('.');
          cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
        }
        setWeight(cleanedValue);
        }
        break;
      case "height":
        const input = e.target.value;
      
        if (input.length <= maxLength) {
          
        let cleanedValue = input.replace(/[^0-9.]/g, '');
        if(cleanedValue.startsWith(".")){
          cleanedValue = cleanedValue.substring(1)
        }
        if (cleanedValue.match(/[0-9]{4,}/)) {
          cleanedValue = cleanedValue.slice(0, -1);
        }
        const decimalIndex = cleanedValue.indexOf('.');
        if (decimalIndex !== -1) {
          const parts = cleanedValue.split('.');
          cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
        }
        setHeight(cleanedValue);
        }
        break;
       
      default:
        break;
    }
  };
  const setHeightValueInBMIDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setHeight(stringValue)
  }
  const setWeightvalueInBMIDropdown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setWeight(stringValue)
  }

  const css = `
    .StandardMetric {
      border-top: none;
      border-bottom: none;
    }
  `
  return (
    <>
    <style>{css}</style>
      {isLoading && <Loader />}

      {screenType == 'web' ? (
        <Flex display={{base: "none", lg: "flex"}}>
          <Box w="100%" p={4}>
            <Center>
              <VStack spacing={0} w="450px">
                <Box as="p" fontSize="22px" fontWeight="600" mb={5}>Tell us a bit about yourself to <br /> personalise your experience</Box>
                <Flex w="100%">
                  <PrimaryInput variant="leftSide" inputPlace="First Name*" id="firstName" onChange={handleChangeFirstName}  inputValue={firstname}/>
                  <PrimaryInput variant="rightSide" inputPlace="Last Name" id="lastName" onChange={handleLastName} inputValue={lastname} />
                </Flex>
                <Flex w="100%">
                  <DropdownInput variant="bothSide" dropDownPlace="Gender"
                    options={["Male", "Female", "Others"]}
                    readOnly={true}
                    id="gender" onClick={selectGender}
                    menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                  defaultOption={defaultGender} />
                </Flex>
                {/* <Flex w="100%" zIndex="99">
                  <MiniDropdownInput
                    variant="bothSide"
                    options={[
                      { label: "ft", value: "152" },
                      { label: "cm", value: "151" }
                    ]}
                    placeholderProp="Height"
                    id="heightUnits"
                    onSelect={handleChangeHeightUnit}
                    inputVal={height}
                    handleInputChange={handleSetHeight}
                    maxLength="5"
                  />
                  <MiniDropdownInput
                    variant="bothSide"
                    dropdownStyle={{ pl: "0" }}
                    placeholderProp="Weight"
                    options={[
                      { label: "lb", value: "155" },
                      { label: "kg", value: "154" }
                    ]}
                    id="weightUnits"
                    onSelect={handleChangeWeightUnit}
                    inputVal={weight}
                    handleInputChange={handleSetWeight}
                    maxLength="5"
                  />
                </Flex> */}
                

                  {/* BMI start */}

                  <RadioGroup 
                  onChange={switchBMICalculation}
                  value={BMICalculatorType}
                  w="100%"
                  bg="#FFFFFF"
                  border="1px solid #e2e8f0"
                  pl="10px"
                  >
                      <Box display="flex" justifyContent="start" gap="1.5rem" py="10px" w="100%">
                        <Radio value="standard" colorScheme="green">
                          Standard
                        </Radio>
                      
                        <Radio value="metric" colorScheme="green">
                          Metric
                        </Radio>

                      </Box>
                    </RadioGroup>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    // py="2 0px"
                    pl="10px"
                    w="100%"
                    bg="#FFFFFF"
                    border="1px solid #e2e8f0"
                  >
                    <Box fontSize="14px" mr="1rem" fontWeight="600" w="20%">
                        Weight*
                        <Box
                          as="span"
                          color="#384052"
                          fontSize="12px"
                          fontWeight="500"
                        >
    
                        </Box>
                        <>
                            <Menu>
                              <MenuButton
                                sx={{
                                  color: "#1C4EC5",
                                  display: "flex",
                                  alignItems: "center",
                                  cursor:"default"
                                }}
                              >
                                {vitalunitweight}{" "}
                                {/* <Image
                                  ml="5px"
                                  pos="relative"
                                  bottom="2px"
                                  display="inline-block"
                                  src="/assets/imgs/arrow-down-gray.png"
                                /> */}
                              </MenuButton>
                            
                            </Menu>
                        </>
                    </Box>
                      <DropdownInputForVitals
                          variant="bothSide"
                          dropDownPlace="Enter Value"
                          options={weightOptionsInBMI}
                          onClick={setWeightvalueInBMIDropdown}
                          onChange={handlebmivitalvaluechange}
                          menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                          readOnly=""
                          id="weight"
                          inputStyleCss={{w: "80%"}}
                          className="StandardMetric"
                          //  defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                            inputValueProp={weight}
                        />
                  </Box>

                  {/* <Divider /> */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    // py="20px"
                    pl="10px"
                    w="100%"
                    bg="#FFFFFF"
                    border="1px solid #e2e8f0"
                  >
                    <Box fontSize="14px" mr="1rem" fontWeight="600" w="20%">
                        Height*
                        <Box
                          as="span"
                          color="#384052"
                          fontSize="12px"
                          fontWeight="500"
                        >
    
                        </Box>
                        <>
                        <Menu>
                              <MenuButton
                                sx={{
                                  color: "#1C4EC5",
                                  display: "flex",
                                  alignItems: "center",
                                  cursor:"default"
                                }}
                              >
                                {vitalunitheight}{" "}
                                {/* <Image
                                  ml="5px"
                                  pos="relative"
                                  bottom="2px"
                                  display="inline-block"
                                  src="/assets/imgs/arrow-down-gray.png"
                                /> */}
                              </MenuButton>
                            </Menu>
                        </>
                    </Box>
                    {!showInchesInput && 
                
                    <DropdownInputForVitals
                      variant="bothSide"
                      inputStyleCss={{w: "80%"}}
                      dropDownPlace="Enter value"
                      options={heightOptionsInBMI}
                      onClick={setHeightValueInBMIDropDown}
                      onChange={handlebmivitalvaluechange}
                      menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                      readOnly=""
                      id="height"
                      className="StandardMetric"
                      // defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                      inputValueProp={height}
                    />
                    }
                    
                    {showInchesInput && 
                    <>
                    <Box w="80%" display="flex">
                        <DropdownInputForVitals
                          variant="bothSide"
                          inputStyleCss={{w: "50%"}}
                          dropDownPlace="Feet"
                          options={feetOptions}
                          onClick={setFeetValueInDropDown}
                          onChange={handleBMIFeetValue}
                          readOnly=""
                          id="height"
                          className="StandardMetric"
                          menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                          inputValueProp={feetValue}
                          maxLength={1}
                        />

                  
                        <DropdownInputForVitals
                          variant="bothSide"
                          inputStyleCss={{w: "50%"}}
                          dropDownPlace="Inches"
                          options={inchesOptions}
                          onClick={setInchValueInDropDown}
                          onChange={handleBMIinchValue}
                          readOnly=""
                          id="height"
                          className="StandardMetric"
                          menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                          inputValueProp={inchValue}
                          maxLength={2}
                        />
                      </Box>
                    </>
                    }
                  </Box>

                  {/* <Divider /> */}
                  
                  {/* BMI end */}

                <Flex w="100%">
                  <DatePicker disableFutureDates={true} onDateChange={(e)=>handleDateChange(e)} placeholderProp="Date of Birth*" leftPos={{ left: "5px" }}
                  defaultDate={datePrePopulate} /> 
                  {/* <PrimaryInput
                    variant="bothside"
                    inputType="date"
                    inputPlace="Date of Birth"
                    onChange={(e)=>handleDateChange(e.target.value)}
                  /> */}
                </Flex>
                <Flex w="100%">
                  <PrimaryInputDropdown
                    onChange={(e) => handleMobileChange(e)}
                    inputValue={mobileno}
                    defaultValue={mobileno}
                    id="mobile"
                    variant="bothSide"
                  />
                </Flex>
                <Flex w="100%">
                  <PrimaryInput variant="downSide" inputType="Email" inputPlace="Enter Email Address*" id="Email" onChange={handleChangeEmail}
                    inputValue={newUserEmail ?newUserEmail : email}
                    onKeyPress={handleMobilepassKeyPress} />
                </Flex>
                <Box display="flex" gap="8px" my={5} flexDirection="row" alignItems={"center"}>
                  <Box as="span">
                    <Image w="18px" src="/assets/imgs/info-safe.png" />
                  </Box>
                  <Box as="p" lineHeight="normal" fontSize="12px">Your information is safe. Only our doctors<br /> can access it for your personalized care.</Box>
                </Box>
                <Box w="100%">
                  <PrimaryButton buttonText="Continue" id="submitButton" isDisabled={buttonDisabled} onClick={SubmitFunction} />
                </Box>
              </VStack>

            </Center>
          </Box>
        </Flex>
      ) : (
        //Mobile component start
        <Flex flexDir="column" display={{base: "flex", lg: "none"}}>
          <Box as="p" fontSize="1.25rem" color="white" fontWeight="600">Tell us a bit about yourself to personalise your experience</Box>
          <Box w="100%" bg="black" h="6px" borderRadius="full" my="1rem">
            <Box as="span" w="33.33%" bg='#14C585' h="100%" borderRadius="full" display="flex"></Box>
          </Box>
          <Flex w="100%">
            <PrimaryInput variant="leftSide" inputPlace="First Name*" id="firstName" onChange={handleChangeFirstName}  inputValue={firstname}/>
            <PrimaryInput variant="rightSide" inputPlace="Last Name" id="lastName" onChange={handleLastName} inputValue={lastname} />
          </Flex>
          <Flex w="100%">
            <DropdownInput variant="bothSide" dropDownPlace="Gender"
              options={["Male", "Female", "Others"]}
              readOnly={true}
              id="gender" onClick={selectGender}
              menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
            defaultOption={defaultGender} />
          </Flex>
          {/* BMI start */}

          <RadioGroup 
            onChange={switchBMICalculation}
            value={BMICalculatorType}
            w="100%"
            bg="#FFFFFF"
            border="1px solid #e2e8f0"
            pl="10px"
            >
                <Box display="flex" justifyContent="start" gap="1.5rem" py="10px" w="100%">
                  <Radio value="standard" colorScheme="green">
                    Standard
                  </Radio>
                
                  <Radio value="metric" colorScheme="green">
                    Metric
                  </Radio>

                </Box>
              </RadioGroup>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              // py="2 0px"
              pl="10px"
              w="100%"
              bg="#FFFFFF"
              border="1px solid #e2e8f0"
            >
              <Box fontSize="14px" mr="1rem" fontWeight="600" w="20%">
                  Weight*
                  <Box
                    as="span"
                    color="#384052"
                    fontSize="12px"
                    fontWeight="500"
                  >

                  </Box>
                  <>
                      <Menu>
                        <MenuButton
                          sx={{
                            color: "#1C4EC5",
                            display: "flex",
                            alignItems: "center",
                            cursor:"default"
                          }}
                        >
                          {vitalunitweight}{" "}
                          {/* <Image
                            ml="5px"
                            pos="relative"
                            bottom="2px"
                            display="inline-block"
                            src="/assets/imgs/arrow-down-gray.png"
                          /> */}
                        </MenuButton>
                      
                      </Menu>
                  </>
              </Box>
                <DropdownInputForVitals
                    variant="bothSide"
                    dropDownPlace="Enter Value"
                    options={weightOptionsInBMI}
                    onClick={setWeightvalueInBMIDropdown}
                    onChange={handlebmivitalvaluechange}
                    menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                    readOnly=""
                    id="weight"
                    inputStyleCss={{w: "80%"}}
                    className="StandardMetric"
                    //  defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                      inputValueProp={weight}
                  />
            </Box>

            {/* <Divider /> */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              // py="20px"
              pl="10px"
              w="100%"
              bg="#FFFFFF"
              border="1px solid #e2e8f0"
            >
              <Box fontSize="14px" mr="1rem" fontWeight="600" w="20%">
                  Height*
                  <Box
                    as="span"
                    color="#384052"
                    fontSize="12px"
                    fontWeight="500"
                  >

                  </Box>
                  <>
                  <Menu>
                        <MenuButton
                          sx={{
                            color: "#1C4EC5",
                            display: "flex",
                            alignItems: "center",
                            cursor:"default"
                          }}
                        >
                          {vitalunitheight}{" "}
                          {/* <Image
                            ml="5px"
                            pos="relative"
                            bottom="2px"
                            display="inline-block"
                            src="/assets/imgs/arrow-down-gray.png"
                          /> */}
                        </MenuButton>
                      </Menu>
                  </>
              </Box>
              {!showInchesInput && 
          
              <DropdownInputForVitals
                variant="bothSide"
                inputStyleCss={{w: "80%"}}
                dropDownPlace="Enter value"
                options={heightOptionsInBMI}
                onClick={setHeightValueInBMIDropDown}
                onChange={handlebmivitalvaluechange}
                menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                readOnly=""
                id="height"
                className="StandardMetric"
                // defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                inputValueProp={height}
              />
              }
              
              {showInchesInput && 
              <>
              <Box w="80%" display="flex">
                  <DropdownInputForVitals
                    variant="bothSide"
                    inputStyleCss={{w: "50%"}}
                    dropDownPlace="Feet"
                    options={feetOptions}
                    onClick={setFeetValueInDropDown}
                    onChange={handleBMIFeetValue}
                    readOnly=""
                    id="height"
                    className="StandardMetric"
                    menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                    inputValueProp={feetValue}
                    maxLength={1}
                  />

            
                  <DropdownInputForVitals
                    variant="bothSide"
                    inputStyleCss={{w: "50%"}}
                    dropDownPlace="Inches"
                    options={inchesOptions}
                    onClick={setInchValueInDropDown}
                    onChange={handleBMIinchValue}
                    readOnly=""
                    id="height"
                    className="StandardMetric"
                    menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                    inputValueProp={inchValue}
                    maxLength={2}
                  />
                </Box>
              </>
              }
            </Box>

            {/* <Divider /> */}
            
            {/* BMI end */}
          <Flex w="100%">
              <DatePicker disableFutureDates={true} onDateChange={(e)=>handleDateChange(e)} placeholderProp="Date of Birth*" leftPos={{ left: "5px" }}
              defaultDate={datePrePopulate} /> 
            {/* <PrimaryInput
              variant="bothside"
              inputType="date"
              inputPlace="Date of Birth"
              onChange={(e)=>handleDateChange(e.target.value)}
            /> */}
          </Flex>
          <Flex w="100%">
            <PrimaryInputDropdown
              onChange={(e) => handleMobileChange(e)}
              inputValue={mobileno}
              defaultValue={mobileno}
              id="mobile"
              variant="bothSide"
            />
          </Flex>
          <Flex w="100%">
            <PrimaryInput variant="downSide" inputType="Email" inputPlace="Enter Email Address*" id="Email" onChange={handleChangeEmail}
              inputValue={newUserEmail ?newUserEmail : email}
              onKeyPress={handleMobilepassKeyPress} />
          </Flex>
          <Box display="flex" gap="8px" my={5} mt={8} flexDirection="row" justifyContent="center" alignItems="center">
            <Box as="span">
              <Image w="18px" src="/assets/imgs/info-safe.png" />
            </Box>
            <Box as="p" lineHeight="normal" fontSize="12px">Your information is safe. Only our doctors<br /> can access it for your personalized care.</Box>
          </Box>
          <Box w="100%">
            <PrimaryButton buttonText="Continue" id="submitButton" isDisabled={buttonDisabled} onClick={SubmitFunction} />
          </Box>
        </Flex>
      )}
    </>
  )
}

export default PersonalDetails;