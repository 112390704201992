import React, { useState } from 'react';
import { Box, Text, Image, Input, List, ListItem } from '@chakra-ui/react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';

const LocationSearch = ({ onSelect }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    setValue(description, false);
    clearSuggestions();

    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      onSelect({
        address: description,
        lat,
        lng,
      });
    });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <ListItem
          key={place_id}
          onClick={handleSelect(suggestion)}
          cursor="pointer"
          p="1rem"
          py="0.5rem"
          borderBottom="1px solid #E6E7E9"
        >
          <Text fontSize="0.875rem" fontWeight="500">{main_text}</Text>
          <Text fontSize="0.65rem" fontWeight="400" color="gray.500">
            {secondary_text}
          </Text>
        </ListItem>
      );
    });

  return (
    <Box>
      <Input
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Search location"
      />
      {status === 'OK' && <List>{renderSuggestions()}</List>}
    </Box>
  );
};

export default LocationSearch;
