import './App.css';
import { useEffect, useState } from 'react';
import { theme } from './components/styles/theme';
import Routing from './routing/rounting';
import { ChakraProvider } from '@chakra-ui/react';
import { GoogleOAuthProvider } from '@react-oauth/google';

const CLIENT_ID = '632146016434-6ov5q3667vjniv5ig551o3qdd17l2vm7.apps.googleusercontent.com';
const FACEBOOK_APP_ID = '911246150412915';

function App() {
  const [isFBSDKLoaded, setIsFBSDKLoaded] = useState(false);

  useEffect(() => {
    const loadFacebookSDK = () => {
      return new Promise((resolve, reject) => {
        if (window.FB) {
          resolve();
          return;
        }

        window.fbAsyncInit = function() {
          window.FB.init({
            appId: FACEBOOK_APP_ID,
            cookie: true,
            xfbml: true,
            version: 'v10.0'
          });
          window.FB.AppEvents.logPageView();
          setIsFBSDKLoaded(true);
          resolve();
        };

        (function(d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) { return; }
          js = d.createElement(s); js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          js.onload = () => {
            console.log('Facebook SDK script loaded');
          };
          js.onerror = (error) => {
            console.error('Error loading Facebook SDK script', error);
            reject(error);
          };
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      });
    };

    if (window.location.protocol === 'http:') {
      // alert('Facebook login requires HTTPS.');
    } else {
      loadFacebookSDK()
        .then(() => {
          console.log('Facebook SDK successfully loaded and initialized');
        })
        .catch((error) => {
          console.error('Error loading Facebook SDK', error);
        });
    }
  }, []);

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <ChakraProvider theme={theme}>
        <Routing isFBSDKLoaded={isFBSDKLoaded} />
      </ChakraProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
