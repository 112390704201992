import { useCallback, useRef } from 'react';
import { useToast, Box, Portal } from '@chakra-ui/react';
import { COLORS } from '../styles/colors';


export const MessageToaster = () => {
  const toast = useToast();
  const toastIdRef = useRef();

  const showToast = useCallback((message) => {


    toastIdRef.current = toast({
      duration: null,
      isClosable: false,
      position: 'top',
      render: () => (
        <Portal>
          <Box
            w="100%"
            pos='fixed'
            top='72px'
            color="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            gap="5px"
            zIndex="999999999"
          >
            <Box 
              bg={COLORS.TOASTR_BG}
              borderRadius="35px" 
              display="flex"
              justifyContent="center"
              alignItems="center"
              p="12px" 
              h="64px"
              w="350px"
              as="span"
              fontSize="12px">{message}</Box>
          </Box>
        </Portal>
      ),
    });
  }, [toast]);

  const showMessageToast = (message, status) => showToast(message, status);
  const closeMessageToast = () => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  };

  return { showMessageToast, closeMessageToast };
};

export default MessageToaster;
