import moment from "moment";
export default function formAPIModellabs({
    longtext,
    eventtitle,
    verifieddate,
})
{
  return Object.freeze({
    longtext,
    eventtitle,
    verifieddate : verifieddate,
   
  });
}