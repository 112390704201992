import formAPIModeldemogrpich from "../demograpich/demographic";
import formAPIModelpastmedicalhistory from "../pastmedicalhistory/pastmedicalhistories";
import formAPIModelpastsurgicalhistory from "../pastsurgicalhistory/pastsurgicalhistories";
import formAPIModelsocialhistory from "../socialhistory/socialhistories";
import formAPIModelcurrentmedications from "../currentmedications/currentmedications";
import moment from "moment";
import { getDob, getgender, getusername } from "../../../auth";
import formAPIAllergies from "../allergies";


export default function formapimodalaidotor({
  demographics,
  pastmedicalhistory,
  pastsurgicalhistory,
  socialhistory,
  currentmedications,
  patient_info,
  allergies,
})
{
  const patientname = getusername();
  const dob = getDob();
  let patientgender = getgender();
  let gender = patientgender;
  if (gender === 1) {
    gender = "Female";
  } else if (gender === 2) {
    gender = "Male";
  } else if (gender === 3) {
    gender = "Others";
  }
  function calculateAge(dateOfBirth) {
    const today = new Date();
    const dob = new Date(dateOfBirth);
    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }
    return age;
  }
  const age = calculateAge(dob);
  const moment = require('moment');
const today = moment().format('YYYY-MM-DD');

  patient_info = {
    "name": patientname,
    "dateofbirth": dob,
    "age": age,
    "gender": gender,
    "dateofrequest": today
  }
return Object.freeze({
  patient_info, 
  demographics:demographics !== null ? formAPIModeldemogrpich(demographics) : {},
  pastmedicalhistory: pastmedicalhistory !== null ? formAPIModelpastmedicalhistory(pastmedicalhistory) : [],
  pastsurgicalhistory:pastsurgicalhistory !== null ? formAPIModelpastsurgicalhistory(pastsurgicalhistory) : [],
  socialhistory:socialhistory !== null ? formAPIModelsocialhistory(socialhistory) : [],
  currentmedications:currentmedications !== null ? formAPIModelcurrentmedications(currentmedications) : [],
  allergies: allergies !== null ? formAPIAllergies(allergies) : [],
});
}