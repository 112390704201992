import Navbar from "../../components/navbar";
import SideBar from "../../components/sidebar";
import SecondaryNav from "../../components/seconday-nav";
import { Box, Flex, Text, Spacer } from "@chakra-ui/layout";
import GrayCard from "../../components/card";
import SimpleTable from "../../components/simple-table";

import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Tbody,
  Tr,
  Td,
  SimpleGrid,
  Wrap,
  WrapItem,
  Input,
  Stepper,
  StepTitle,
  StepDescription,
  Step,
  StepIndicator,
} from "@chakra-ui/react";

import { FetchMyHealthData } from "../../middleware/services/myhealth";
import {
  Image,
  List,
  ListItem,
  ListIcon,
  Center,
  UnorderedList,
  StepSeparator,
  Stack,
  VStack,
  Circle,
  Divider,
} from "@chakra-ui/react";
import VerticalStepper from "../../components/stepper";
import ModalRight from "../../components/modal-right";
import DoctorCard from "../../components/doctor-card";
import { PrimaryButtonIcon, PrimaryInput ,PrimaryButton} from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { COLORS } from "../../components/styles/colors";
import AiModal from "../../components/ai-modal";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import moment from "moment";
import Footer from "../../components/footer";
import MobileNavbar from "../../components/mobile-navbar";
import { capitalizeFirstLetter, formatDate_yyyy, useIsIOS, useScreenType } from "../../auth";

const boxesPerRow = 15;



const MyHealthindex = () => {
  var [data, setData] = useState(null);
  var [patientid, setPatientid] = useState();
  var [dataOnPageLoad, setDataOnPageLoad] = useState("");
  const treatmentplan = React.useRef();
  const pastmedicalhistory = React.useRef();
  const pastsurgicalhistory = React.useRef();
  const historyofillness = React.useRef();
  //const steps = data && data.pastmedicalhistory.map(title => ({ title }));
  const vitalsModals = React.useRef();
  var navigate = useNavigate();
  const location = useLocation();
  const { appointmentid } = location.state || {};
  const { isLoading, showLoader, dismissLoader } = useLoader();
  var [investigationsWithStaticValues, setInvestigationsWithStaticValues] = useState([]);
  const fileInputRefMobile = useRef(null);
  const screenType = useScreenType()
  const isIos = useIsIOS()
  const handleButtonClick = () => {
    fileInputRefMobile.current.click();
  };

  const dataTable = {
    "White blood cells": "",
    Hemoglobin: "",
    Hematocrit: "",
    "Platelet count": "",
    Chloride: "",
    Sodium: "",
    Potassium: "",
    CO2: "",
    BUN: "",
    Creatinine: "",
    Glucose: "",
  };

  const dataEntries = Object.entries(dataTable);
  const removeDateIcon = {
    'input[type="date"]': {
      "&::-webkit-inner-spin-button": {
        display: "none",
        WebkitAppearance: "none",
      },
      "&::-webkit-calendar-picker-indicator": {
        display: "none",
        WebkitAppearance: "none",
      },
    },
  };

  useEffect(() => {
    if(appointmentid){
      fetchData(appointmentid);
    }else{
      fetchData();
    }
    
  }, []);
  const fetchData = async (id) => {
    showLoader();
    try {
      const result = await FetchMyHealthData({
        patientid,
        appointmentid: id,
      });
      if (result != null) {
        console.log(result , " reslt of overview")
        setDataOnPageLoad(result);
        setData(result);
        // if(result && result.investigations){
        // const titlesToFilter = [
        //   "WBC",
        //   "Hemoglobin",
        //   "Hematocrit",
        //   "Platelets",
        //   "Sodium",
        //   "Potassium",
        //   "Urea",
        //   "Creatinine",
        //   "Glucose"
        // ];
        // const filteredInvestigations = titlesToFilter.map(title => {
        //   const found = result.investigations.find(investigation =>
        //     investigation.eventtitle.toLowerCase().includes(title.toLowerCase())
        //   );
        //   return found || {
        //     eventtitle: title,
        //     verifieddate: null,
        //     resultuom_cd: "-",
        //     resultuom: "-",
        //     actualresultvalue: "-",
        //     resultvalue: "-",
        //     status: "-",
        //     trendColor: "-"
        //   };
        // });
        // // Array containing investigations with titles not in titlesToFilter
        // const otherInvestigations = result.investigations.filter(investigation => {
        //   return !titlesToFilter.some(title => investigation.eventtitle.toLowerCase().includes(title.toLowerCase()));
        // });
        // const finalInvestigations = [...filteredInvestigations, ...otherInvestigations];
        // setInvestigationsWithStaticValues(finalInvestigations)
        // }

      } else {
        //alert("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in overview Index:", error);
    }
  };
  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  const navigateToLabs = () => {
    fileInputRef.current.click();
    //navigate("labs");
    //window.scrollTo(0, 0);
  };
  const navigateToLabswithoutupload = () => {
    navigate("labs");
    window.scrollTo(0, 0);
  };
  const navigatetoinvestigation = () => {
    navigate("labs");
    window.scrollTo(0, 0);
  };
  const navigateToImaging = () => {
     fileInputRef2.current.click();
    // navigate("imaging");
    // window.scrollTo(0, 0);
  };
  const navigateToImagingWithoutUpload = () => {
    navigate("imaging");
    window.scrollTo(0, 0);
 };
  const navigateToVitals = () => {  
    navigate("/vitals");
    window.scrollTo(0, 0);
  };
  const handleimageselection = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    showLoader();
    navigate("/uploaddocument", { state: { file } });
    dismissLoader();
  };
  const handleimagingUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    showLoader();
    navigate("/imagingupload", { state: { file } });
    dismissLoader();
  };
  const uploadprescription = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    showLoader();
    navigate("/prescriptionupload", { state: { file } });
    dismissLoader();
  };

    const GenericUpload = async (e) => {
      e.preventDefault();
      const file = e.target.files[0];
      showLoader();
      navigate("/docupload", { state: { file } });
      dismissLoader();
    };
const navigateTomedications = ()=>{
  navigate("/medications");
};
const navigateToSocialHistory = ()=>{
  navigate("/socialhistory");
};
const navigateToFamilyHistory = ()=>{
  navigate("/familyhistory");
};
const viewpastsurgicalhistory = ()=>{
  navigate("/surgeries");
};
const viewpastmedicalhistory = ()=>{
  navigate("/conditions");
};
const formatDate = (dateString) => {
  if (!dateString) return '-';
  return moment(dateString).format('Do MMM, YYYY');
};

const generateDynamicText = (value) => {
  let outputTexts = [];
  let gender = data && data.patientinfo && data.patientinfo.gender;
  let age = calculateAge(data.patientinfo && data.patientinfo.dob);

  value.forEach((item, index) => {
      let attributes = {};
      item.values.forEach(attribute => {
          if (attribute.attribute === "Site") {
              attributes.site = attribute.values.map(v => v.value).join(', ');
          } else if (attribute.attribute === "Onset") {
              attributes.onset = attribute.value;
          } else if (attribute.attribute === "Chronicity") {
              attributes.chronicity = attribute.value;
          } else if (attribute.attribute === "Onset Quality") {
              attributes.onsetQuality = attribute.value;
          } else if (attribute.attribute === "Frequency") {
              attributes.frequency = attribute.value;
          } else if (attribute.attribute === "Associated Symptoms") {
              attributes.symptoms = attribute.values.map(v => v.value).join(', ');
          } else if (attribute.attribute === "Rating Scale") {
              attributes.ratingScale = attribute.value;
          } else if (attribute.attribute === "Time") {
              attributes.time = attribute.value;
          } else if (attribute.attribute === "Time Value") {
              attributes.timeValue = attribute.value;
          }
      });

      // let patientText = index === 0 ? `The patient is a ${age}-year-old individual(${gender}) ` : '';
      // let template = `${patientText}presenting with ${attributes.site || '-'} ${item.key}. The ${item.key} started ${attributes.onset || '-'} and is described as ${attributes.chronicity || '-'} in nature. The onset of the ${item.key} was ${attributes.onsetQuality || '-'}. The ${item.key} is ${attributes.frequency || '-'} and is rated as ${attributes.ratingScale || 0} out of 10 in intensity. Associated with ${attributes.symptoms || '-'}.`;
      // outputTexts.push(template);
      let patientText = index === 0 ? `The patient is a ${age}-year-old individual (${gender}) ` : '';
      let template = `${patientText}`;

      if (attributes.site) {
        template += `presenting with ${attributes.site} ${item.key}. `;
      }

      if (attributes.onset) {
        template += `The ${item.key} started ${attributes.onset} and is described as `;
        if (attributes.chronicity) {
          template += `${attributes.chronicity} in nature. `;
        }
      }

      if (attributes.onsetQuality) {
        template += `The onset of the ${item.key} was ${attributes.onsetQuality}. `;
      }

      if (attributes.frequency) {
        template += `The ${item.key} is ${attributes.frequency} and `;
      }

      if (attributes.ratingScale) {
        template += `is rated as ${attributes.ratingScale} out of 10 in intensity. `;
      }

      if (attributes.symptoms) {
        template += `Associated with ${attributes.symptoms}.`;
      }

      outputTexts.push(template);
  });

  return outputTexts;
};
function calculateAge(dateOfBirth) {
  const today = new Date();
  const dob = new Date(dateOfBirth);
  let age = today.getFullYear() - dob.getFullYear();
  const monthDiff = today.getMonth() - dob.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
    age--;
  }
  return age;
}

const myHealthIcons = [
  { name: "Vitals", imageUrl: "/assets/imgs/heart.png", navLinkUrl: "/vitals" },
  { name: "Conditions", imageUrl: "/assets/imgs/conditions.png", navLinkUrl: "/conditions" },
  { name: "Surgeries", imageUrl: "/assets/imgs/surgeries.png", navLinkUrl: "/surgeries" },
  { name: "Medication", imageUrl: "/assets/imgs/medication.png", navLinkUrl: "/medications" },
  { name: "Allergies", imageUrl: "/assets/imgs/allergy.png", navLinkUrl: "/allergies" },
  { name: "Family History", imageUrl: "/assets/imgs/family.png", navLinkUrl: "/familyhistory" },
  { name: "Vaccinations", imageUrl: "/assets/imgs/syringe.png", navLinkUrl: "/vaccination" },
  { name: "Social History", imageUrl: "/assets/imgs/social-history.png", navLinkUrl: "/socialhistory" }
];

 return (
  <>
  {screenType == 'web' ? (
    <Box w="100%">
      <Box top="0" zIndex="banner">
        <Navbar />
        <SecondaryNav
          defaultHeader="My Health"
          defaultSecondayHeader="Overview"
          defaultNavBody={
            <>
            <Box display="flex" alignItems="center" pr="25px">
              <input
                type="file"
                id="genericupload"
                style={{ display: 'none' }}
                accept="image/jpeg,image/jpg,image/png,image/tiff,application/pdf" // Specify accepted file types
                onChange={GenericUpload}
              />
              <PrimaryInput
               inputType="file"
               uploadText="Upload document"
               id="genericupload"
               docBtnWidth="195px"
               docBtnColor="#1C4EC5"
               iconImg="/assets/svgs/prescriptions.svg"
               iconImgCss={{width:"30px" , height:"auto" , color:"#1C4EC5" , 
                filter: "brightness(0) saturate(100%) invert(24%) sepia(84%) saturate(2247%) hue-rotate(215deg) brightness(83%) contrast(96%)"
                }}
               onChange={GenericUpload}
               variant="grayBtnFull"
             />
             
             </Box>
            {/* <Box display="flex" alignItems="center" pr="25px">
              <input
                type="file"
                id="prescriptionupload"
                style={{ display: 'none' }}
                accept="image/jpeg,image/jpg,image/png,image/tiff,application/pdf" // Specify accepted file types
                onChange={uploadprescription}
              />
              <PrimaryInput
               inputType="file"
               uploadText="Upload prescription"
               id="prescriptionupload"
               docBtnWidth="195px"
               docBtnColor="#1C4EC5"
               iconImg="/assets/svgs/prescriptions.svg"
               iconImgCss={{width:"30px" , height:"auto" , color:"#1C4EC5" , 
                filter: "brightness(0) saturate(100%) invert(24%) sepia(84%) saturate(2247%) hue-rotate(215deg) brightness(83%) contrast(96%)"
                }}
               onChange={uploadprescription}
               variant="grayBtnFull"
             />
             
             </Box>
              <Box display="flex" alignItems="center" pr="25px">
                <input
                  type="file"
                  id="Labupload"
                  style={{ display: "none" }}
                  accept="image/jpeg,image/jpg,image/png,image/tiff, application/pdf" // Specify accepted file types
                  onChange={handleimageselection}
                />
                <PrimaryInput
                  inputType="file"
                  uploadText="Upload Labs"
                  docBtnWidth="140px"
                  id="Labupload"
                  docBtnColor="#1C4EC5"
                  iconImg="/assets/imgs/file_open-blue.png"
                  onChange={handleimageselection}
                  //onClick={handleimageselection}
                  variant="grayBtnFull"
                />
              </Box>
              <Box display="flex" alignItems="center" pr="25px">
              <input
                type="file"
                id="imagingupload"
                style={{ display: 'none' }}
                accept="image/jpeg,image/jpg,image/png,image/tiff,application/pdf" // Specify accepted file types
                onChange={handleimagingUpload}
              />
              <PrimaryInput
               inputType="file"
               uploadText="Upload Imaging"
               id="imagingupload"
               docBtnWidth="175px"
               docBtnColor="#1C4EC5"
               iconImg="/assets/svgs/hand_bones.svg"
               iconImgCss={{width:"32px" , height:"auto" , color:"#1C4EC5" , 
                filter: "brightness(0) saturate(100%) invert(24%) sepia(84%) saturate(2247%) hue-rotate(215deg) brightness(83%) contrast(96%)"
                }}
               onChange={handleimagingUpload}
               variant="grayBtnFull"
             />
             
             </Box> */}

              {/* here */}
             
            </>
          }
        />
      </Box>
      <Box h="calc(100vh - 140px)" overflow="hidden auto">   
      <Flex>
        <Box position="sticky" top="0" zIndex="sticky" h="100vh">
          <SideBar />
          {isLoading && <Loader />}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gap="20px"
          width="calc(100vw - 212px)"
        >
          <Flex
            // display="flex"
            // flexDirection="row"
            // justifyContent="space-between"
            // gap="20px"
            // w="100%"
            flexDirection="row"
            justifyContent="space-between"
            gap="20px"
            w="100%"
            flexWrap="wrap"
            p={4}
          >
            {/* Demographics */}
            {data && data.demographics ? (
              <GrayCard
                w="calc(50% - 10px)"
                minW="calc(50% - 10px)"
                maxW="calc(50% - 10px)"
                cardHeader="Demographics"
                cardHeaderIcon="/assets/imgs/measure.png"
                bodyStyle={{ p: "0" }}
                cardBody={
                  <>
                    <Flex direction="row" justify="space-around" h="full">
                      <Box p="10px" alignItems="center" w="100%">
                        <Box
                          as="p"
                          display="inline-block"
                          fontSize="22px"
                          fontWeight="600"
                        >
                          {data.demographics.height
                            ? data.demographics.height
                            : "-"}
                        </Box>
                        <Box
                          display="inline-block"
                          as="p"
                          fontSize="15px"
                          fontWeight="400"
                          pl={"5px"}
                        >
                          {data.demographics.height
                            ? data.demographics.heightunit
                            : ""}
                        </Box>
                        <Box
                          as="p"
                          fontSize="12px"
                          fontWeight="500"
                          color="#6A707D"
                        >
                          Height
                        </Box>
                      </Box>
                      <Box
                        p="10px"
                        w="100%"
                        alignItems="center"
                        borderRight="1px solid #E6E7E9"
                        borderLeft="1px solid #E6E7E9"
                        _last={{ borderRight: 0 }}
                      >
                        <Box
                          as="p"
                          display="inline-block"
                          fontSize="22px"
                          fontWeight="600"
                        >
                          {data.demographics.weight
                            ? data.demographics.weight
                            : "-"}
                        </Box>
                        <Box
                          display="inline-block"
                          as="p"
                          fontSize="15px"
                          fontWeight="400"
                          pl={"5px"}
                        >
                          {data.demographics.weight
                            ? data.demographics.weightunit
                            : ""}
                        </Box>
                        <Box
                          as="p"
                          fontSize="12px"
                          fontWeight="500"
                          color="#6A707D"
                        >
                          Weight
                        </Box>
                      </Box>
                      <Box p="10px" w="100%" alignItems="center">
                        <Box
                          as="p"
                          display="inline-block"
                          fontSize="22px"
                          fontWeight="600"
                        >
                          {data.demographics.bmi ? data.demographics.bmi : "-"}
                        </Box>
                        <Box
                          display="inline-block"
                          as="p"
                          fontSize="15px"
                          fontWeight="400"
                        ></Box>
                        <Box
                          as="p"
                          fontSize="12px"
                          fontWeight="500"
                          color="#6A707D"
                        >
                          BMI
                        </Box>
                      </Box>
                    </Flex>
                  </>
                }
                cardFooterText="Update Demographics"
                cardFooterClickEvent={navigateToVitals}
              />
            ) : (
              <GrayCard
                w="calc(50% - 10px)"
                minW="calc(50% - 10px)"
                maxW="calc(50% - 10px)"
                cardHeader="Demographics"
                cardHeaderIcon="/assets/imgs/measure.png"
                bodyStyle={{ p: "0" }}
                cardBody={
                  <>
                    <Flex direction="row" justify="space-around" h="full">
                      <Box p="10px" alignItems="center" w="100%">
                        <Box
                          as="p"
                          display="inline-block"
                          fontSize="22px"
                          fontWeight="600"
                        >
                          -
                        </Box>
                        <Box
                          display="inline-block"
                          as="p"
                          fontSize="15px"
                          fontWeight="400"
                        ></Box>
                        <Box
                          as="p"
                          fontSize="12px"
                          fontWeight="500"
                          color="#6A707D"
                        >
                          Height
                        </Box>
                      </Box>
                      <Box
                        p="10px"
                        w="100%"
                        alignItems="center"
                        borderRight="1px solid #E6E7E9"
                        borderLeft="1px solid #E6E7E9"
                        _last={{ borderRight: 0 }}
                      >
                        <Box
                          as="p"
                          display="inline-block"
                          fontSize="22px"
                          fontWeight="600"
                        >
                          -
                        </Box>
                        <Box
                          display="inline-block"
                          as="p"
                          fontSize="15px"
                          fontWeight="400"
                        ></Box>
                        <Box
                          as="p"
                          fontSize="12px"
                          fontWeight="500"
                          color="#6A707D"
                        >
                          Height
                        </Box>
                      </Box>
                      <Box p="10px" w="100%" alignItems="center">
                        <Box
                          as="p"
                          display="inline-block"
                          fontSize="22px"
                          fontWeight="600"
                        >
                          -
                        </Box>
                        <Box
                          display="inline-block"
                          as="p"
                          fontSize="15px"
                          fontWeight="400"
                        ></Box>
                        <Box
                          as="p"
                          fontSize="12px"
                          fontWeight="500"
                          color="#6A707D"
                        >
                          Height
                        </Box>
                      </Box>
                    </Flex>
                  </>
                }
                cardFooterText="Add demographics"
              />
            )}
            {/* Chief Complaint */}
            {data && data.chiefcomplaint != null && (
              <GrayCard
                w="calc(50% - 10px)"
                minW="calc(50% - 10px)"
                maxW="calc(50% - 10px)"
                cardHeader="chief complaint"
                cardHeaderIcon="/assets/imgs/cheif-complaint.png"
                cardBody={
                  <>
                  <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      gap="50px"
                      h="100%"
                    >
                       <Flex flexDir="column" justifyContent="space-between" h="100%">
                                                <Text fontSize="0.875rem" fontWeight="500">
                                                {data && data.chiefcomplaint && data.chiefcomplaint.chiefcomplaint?data.chiefcomplaint.chiefcomplaint:"-"}
                                                    
                                                </Text>
                                                {/* <Spacer /> */}
                                                <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                                                    {/* 10th Jan, 2023 */}
                                                    {formatDate(data.chiefcomplaint.date)}
                                                </Text>
                        </Flex>
                      {/* <Text fontSize="14px" fontWeight="500">
                                {data && data.chiefcomplaint && data.chiefcomplaint.chiefcomplaint}
                              </Text>
                       */}
                    </Box>
                    {/* {formatDate(data.chiefcomplaint.date)} */}
                  </>
                }
                hasFooter={false}
                cardFooterText={"Add data"}
              />
            )}
            {/* Present Illness */}
            {data && data.historyofpresentillness != null && (
              <GrayCard
                w="calc(50% - 10px)"
                minW="calc(50% - 10px)"
                maxW="calc(50% - 10px)"
                bodyStyle={{maxH: "210px", overflow:"auto"}}
                cardHeader="history of present illness"
                cardHeaderIcon="/assets/imgs/medical-history.png"
                cardBody={
                  <>
                  <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      gap="50px"
                    >
                       <Flex flexDir="column" justifyContent="space-between" h="100%">
                            <Text fontSize="0.875rem" fontWeight="500">
                            {generateDynamicText(data && data.historyofpresentillness).map((text, index) => (
                            <React.Fragment key={index}>
                              {text}
                              <br />
                              <br />
                            </React.Fragment>
                          ))}
                            </Text>
                        </Flex>
                    </Box>
                  </>
                }
                hasFooter={false}
              />
            )}
            {/* ROS */}
            {data && data.reviewofsystem !== null &&  (
              <GrayCard
              hasFooter={false}
              w="calc(50% - 10px)"
              minW="calc(50% - 10px)"
              maxW="calc(50% - 10px)"
              cardHeader="Review of system (ROS)"
              bodyStyle={{maxH: "210px", overflow:"auto"}}
              cardHeaderIcon="/assets/imgs/physical-exam.png"
              cardBody={
                  <>
                   {data &&
                          data.reviewofsystem &&
                          data.reviewofsystem.map((ros, index) => (
                      <Box mb="10px" key={index}>
                      <Text fontSize="12px" fontWeight="500" color="#0C7650">{ros.rosname}</Text>
                      {ros.rosvalue.length > 0 && (
                            <Text
                                fontSize="16px"
                                fontWeight="600"
                                color="#061027"
                            >
                                {ros.rosvalue.map(val => val.value).join(', ')}
                            </Text>
                        )}
                      <Text fontSize="12px" fontWeight="500" color="#384052" mb="10px">{ros.DATE ? formatDate(ros.DATE): ('')}</Text>
                      <Divider />
                      </Box>
                          ))}
                      {/* <Box display="flex" alignItems="center" bg="#F04438" p="4px" px="6px" borderRadius="full">
                          <Image w="12px" h="12px" src="/assets/imgs/error-white.png" alt="Critical" />
                          <Text as="span" color="white" fontSize="10px" ml="2px">Critical</Text>
                      </Box> */}
                  </>

                  
              }
              />         
            )}
            {/* Physical Exam */}
            {data && data.physicalexam != null && (
              <GrayCard
              hasFooter={false}
              w="calc(50% - 10px)"
              minW="calc(50% - 10px)"
              maxW="calc(50% - 10px)"
              cardHeader="physical exam"
              cardHeaderIcon="/assets/imgs/physical-exam.png"
              cardBody={
                  <>
                   {data &&
                          data.physicalexam &&
                          data.physicalexam.map((step, index) => (
                      <Box mb="10px" key={index}>
                      <Text fontSize="12px" fontWeight="500" color="#0C7650">{step.type}</Text>
                      {step.physicalexam.length > 0 && (
                            <Text
                                fontSize="16px"
                                fontWeight="600"
                                color="#061027"
                            >
                                {step.physicalexam.map(val => val.value).join(', ')}
                            </Text>
                        )}
                      <Text fontSize="12px" fontWeight="500" color="#384052" mb="10px">{step.timeDifference}</Text>
                      <Divider />
                      </Box>
                          ))}
                      {/* <Box display="flex" alignItems="center" bg="#F04438" p="4px" px="6px" borderRadius="full">
                          <Image w="12px" h="12px" src="/assets/imgs/error-white.png" alt="Critical" />
                          <Text as="span" color="white" fontSize="10px" ml="2px">Critical</Text>
                      </Box> */}
                  </>

                  
              }
              />
          
            )}
            {/* Doctor Assesment */}
            {data && data.doctorsassessment != null && (
              <GrayCard
              w="calc(50% - 10px)"
              minW="calc(50% - 10px)"
              maxW="calc(50% - 10px)"
              cardHeader="doctors assessment"
              cardHeaderIcon="/assets/imgs/doctor-tool.png"
              bodyStyle={{pb: "0.75rem", overflow: "hidden auto", maxH: "150px"}}
              cardBody={
                <>
                  <Box display="flex" justifyContent="space-between" flexDir="column" h="100%">
                    <VStack align="stretch">
                      {data &&
                        data.doctorsassessment &&
                        data.doctorsassessment.map((step, index) => (
                          <React.Fragment key={index}>
                            <Stack direction="column" align="flex-start" gap="0">
                              <Box display="flex" alignItems="center" gap="5px">
                                <Circle
                                  size="12px"
                                  bg="white"
                                  border="2px solid gray"
                                  borderRadius="full"
                                  color="black"
                                />
                                  <Text fontSize="16px" fontWeight="600">
                                    {step.diagnosis}
                                  </Text>
                                </Box>
                                <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                                  <Center h='35px' ml="5px" borderLeft={index < data.doctorsassessment.length - 1 ? "1px dotted #CDCFD4" : "none"}></Center>
                                    <Text fontSize="12px" ml="7px" fontWeight="600">
                                      {step.plan}
                                    </Text>
                                  </Box>
                                  <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                                  <Center h='35px' ml="5px" borderLeft={index < data.doctorsassessment.length - 1 ? "1px dotted #CDCFD4" : "none"}></Center>
                                    <Text fontSize="12px" ml="7px" fontWeight="600">
                                      {formatDate(step.DATE)}
                                    </Text>
                                  </Box>
                                  {index <
                                    data.doctorsassessment.length - 1 && (
                                    <Box
                                      as="div"
                                      w="2px"
                                      h="5px"
                                      borderColor="black"
                                      m="0 auto"
                                    >
                                      {/* <Image w="2px" h="auto" position="relative" left="-15px" top="-15px" src="/assets/imgs/step-line.png" /> */}
                                    </Box>
                                  )}
                            </Stack>
                          </React.Fragment>
                        ))}
                    </VStack>
                    <Box
                      // key={index}
                        borderTop="1px solid #E6E7E9"
                        pt="15px"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box display="flex" alignItems="center" w="50%">
                          <Image
                            w="40px"
                            h="40px"
                            borderRadius="full"
                            src={data.doctorsassessment && data.doctorsassessment[0].doctorimage? data.doctorsassessment[0].imagepath+data.doctorsassessment[0].doctorimage:"/assets/imgs/no-image-icon.png"}
                            mr="3"
                          />
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            w="50%"
                          >
                            <Text
                              fontSize="12px"
                              fontWeight="500"
                              color="#384052"
                            >
                              {data.doctorsassessment && data.doctorsassessment[0].specality}
                            </Text>
                            <Text fontSize="14px" fontWeight="600" title="Fayaq" className="textOverflow">
                            {data.doctorsassessment && data.doctorsassessment[0].doctorname}
                            </Text>
                          </Box>
                        </Box>
                        <Text
                          fontSize="12px"
                          fontWeight="500"
                          color="#384052"
                        >
                          {data.doctorsassessment && formatDate(data.doctorsassessment[0].DATE)}                    
                        </Text>
                      </Box>
                  </Box>
                </>
              }
              cardFooterText="Book Appointment"
              onClick={()=> navigate("/doctorprofile", { state: { doctorid: data.doctorsassessment && data.doctorsassessment[0].doctorid, fullname: data.doctorsassessment && data.doctorsassessment[0].doctorname }})}
            />

            )}

            {/* Current medication */}
            {data && data.currentmedications.length > 0 ? (
              <GrayCard
                w="calc(50% - 10px)"
                minW="calc(50% - 10px)"
                maxW="calc(50% - 10px)"
                bodyStyle={{ p: "10px 20px", overflow: "auto",  maxH: "110px"}}
                cardHeader="current medications"
                cardHeaderIcon="/assets/imgs/medication.png"
                cardBody={
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      gap="50px"
                    >
                      <Box>
                        {data &&
                          data.currentmedications &&
                          data.currentmedications.map((step, index) => (
                            // <UnorderedList key={index}>
                            //   <ListItem fontSize="14px" fontWeight="600">
                            <Box mb="0.75rem" key={index}>
                            <Text fontSize="14px" fontWeight="600">
                              {step.medicinename? <strong>●  {step.medicinename}</strong> :""}
                                 
                              </Text>
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >
                               {step.chemicalcomposition && (
                                  <span>{step.chemicalcomposition}{step.dosage ? `, ${step.dosage}` : ""}</span>
                                )}
                              </Text>
                              </Box>
                            //   </ListItem>
                            // </UnorderedList>
                          ))}
                      </Box>
                    </Box>
                  </>
                }
                cardFooterText="Update Medications"
                cardFooterClickEvent={navigateTomedications}
              />
            ) : (
              <GrayCard
                w="calc(50% - 10px)"
                minW="calc(50% - 10px)"
                maxW="calc(50% - 10px)"
                cardHeader="Current medications"
                cardHeaderIcon="/assets/imgs/medication.png"
                cardBody={
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      gap="10px"
                      alignItems="center"
                      h="100%"
                    >
                      <Box textAlign="left" fontSize="16px" fontWeight="600">
                        <Image
                          w="26px"
                          opacity="0.5"
                          src="/assets/imgs/medication.png"
                        />
                      </Box>
                      <Text textAlign="left" fontSize="12px" fontWeight="500">
                        No Data available
                      </Text>
                    </Box>
                  </>
                }
                cardFooterText="Add Medications"
                cardFooterClickEvent={navigateTomedications}
              />
            )}

            {/* Treament Team */}
            {data && data.treatmentteam.length > 0 ? (
              <GrayCard
                w="calc(50% - 10px)"
                minW="calc(50% - 10px)"
                maxW="calc(50% - 10px)"
                bodyStyle={{ p: "0 20px", overflow: "auto", maxH: "220px" }}
                cardHeader="Treatment Team"
                cardHeaderIcon="/assets/imgs/treatment-plan.png"
                cardBody={
                  <>
                    <Box>
                      {data &&
                        data.treatmentteam &&
                        data.treatmentteam.map((step, index) => (
                          <Box
                          key={index}
                            borderBottom="1px solid #E6E7E9"
                            py="15px"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box display="flex" alignItems="center" w="50%">
                              <Image
                                w="40px"
                                h="40px"
                                borderRadius="full"
                                src={step.doctorimage ? step.imagepath + step.doctorimage : "/assets/imgs/no-image-icon.png"}
                                mr="3"
                              />
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                w="50%"
                              >
                                <Text
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#384052"
                                >
                                  {step.entitytype ? step.entitytype : ""}
                                </Text>
                                <Text fontSize="14px" fontWeight="600" title={step.doctorname ? step.doctorname : "-"} className="textOverflow">
                                  {step.doctorname ? step.doctorname : "-"}
                                </Text>
                              </Box>
                            </Box>
                            <Text
                              fontSize="12px"
                              fontWeight="500"
                              color="#384052"
                            >
                              {formatDate(step.date)}
                              
                            </Text>
                          </Box>
                        ))}
                    </Box>
                  </>
                }
                cardFooterText="View all"
                onClick={() => navigate("/home/treatmentteam")}
              />
            ) : (
              <GrayCard
                w="calc(50% - 10px)"
                minW="calc(50% - 10px)"
                maxW="calc(50% - 10px)"
                cardHeader="Treatment Team"
                cardHeaderIcon="/assets/imgs/treatment-plan.png"
                cardBody={
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      gap="10px"
                      alignItems="center"
                      h="100%"
                    >
                      <Box textAlign="left" fontSize="16px" fontWeight="600">
                        <Image
                          w="26px"
                          opacity="0.5"
                          src="/assets/imgs/treatment-plan.png"
                        />
                      </Box>
                      <Text textAlign="left" fontSize="12px" fontWeight="500">
                        No Data available
                      </Text>
                    </Box>
                  </>
                }
                cardFooterText="View all"
                onClick={() => navigate("/home/treatmentteam")}
              />
            )}
            {/* Past Medical HIstory */}
            {data && data.pastmedicalhistory.length > 0 ? (
              <GrayCard
                w="calc(50% - 10px)"
                minW="calc(50% - 10px)"
                maxW="calc(50% - 10px)"
                bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
                cardHeader="Past Medical History"
                cardHeaderIcon="/assets/imgs/medical-history.png"
                cardBody={
                  <>
                    <Box>
                      <VStack align="stretch">
                        {data &&
                          data.pastmedicalhistory &&
                          data.pastmedicalhistory.map((step, index) => (
                            <React.Fragment key={index}>
                              <Stack direction="column" align="flex-start" gap="0">
                                <Box display="flex" alignItems="center" gap="5px">
                                  <Circle
                                    size="12px"
                                    bg="white"
                                    border="2px solid gray"
                                    borderRadius="full"
                                    color="black"
                                  />
                                    <Text fontSize="16px" fontWeight="600" className="textOverflow" w='350px' maxW='350px'>
                                      {step.condition}
                                    </Text>
                                  </Box>
                                  <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                                  <Center h='35px' ml="5px" borderLeft={index < data.pastmedicalhistory.length - 1 ? "1px dotted #CDCFD4" : "none"}></Center>
                                    <Text fontSize="12px" ml="7px" fontWeight="600">
                                      {formatDate(step.conditionidentifieddate)}
                                    </Text>
                                  </Box>
                                  {index <
                                    data.pastmedicalhistory.length - 1 && (
                                    <Box
                                      as="div"
                                      w="2px"
                                      h="5px"
                                      borderColor="black"
                                      m="0 auto"
                                    >
                                      {/* <Image w="2px" h="auto" position="relative" left="-15px" top="-15px" src="/assets/imgs/step-line.png" /> */}
                                    </Box>
                                  )}
                              </Stack>
                            </React.Fragment>
                          ))}
                      </VStack>
                    </Box>
                  </>
                }
                cardFooterText="View full timeline"
                onClick={() => pastmedicalhistory.current.openRight()}
              />
            ) : (
              <GrayCard
                w="calc(50% - 10px)"
                minW="calc(50% - 10px)"
                maxW="calc(50% - 10px)"
                cardHeader="past medical history"
                cardHeaderIcon="/assets/imgs/medical-history.png"
                cardBody={
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      gap="10px"
                      alignItems="center"
                      h="100%"
                    >
                      <Box textAlign="left" fontSize="16px" fontWeight="600">
                        <Image
                          w="26px"
                          opacity="0.5"
                          src="/assets/imgs/medical-history.png"
                        />
                      </Box>
                      <Text textAlign="left" fontSize="12px" fontWeight="500">
                        No Data available
                      </Text>
                    </Box>
                  </>
                }
                cardFooterText="Add Medical History"
                cardFooterClickEvent={viewpastmedicalhistory}
              />
            )}
            {/* Past Surgical HIstory */}
            {data && data.pastsurgicalhistory.length > 0 ? (
              <GrayCard
                w="calc(50% - 10px)"
                minW="calc(50% - 10px)"
                maxW="calc(50% - 10px)"
                cardHeader="Past surgical history"
                bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
                cardHeaderIcon="/assets/imgs/surgeries.png"
                cardBody={
                  <>
                    <Box>
                      <VStack align="stretch">
                        {data &&
                          data.pastsurgicalhistory &&
                          data.pastsurgicalhistory.map((step, index) => (
                            <React.Fragment key={index}>
                              <Stack direction="column" align="flex-start" gap="0">
                                  <Box display="flex" alignItems="center" gap="5px">
                                    <Circle
                                      size="12px"
                                      bg="white"
                                      border="2px solid gray"
                                      borderRadius="full"
                                      color="black"
                                    />
                                      <Text fontSize="16px" fontWeight="600">
                                        {step.proceduretitle}
                                      </Text>
                                  </Box>
                                  <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                                      <Center h='35px' ml="5px" borderLeft={index < data.pastsurgicalhistory.length - 1 ? "1px dotted #CDCFD4" : "none"}></Center>
                                    <Text fontSize="12px" ml="7px" fontWeight="600">
                                      {/* {step.proceduredate
                                        ? step.proceduredate
                                      : "-"} */}
                                        {formatDate(step.proceduredate)}
                                    </Text>
                                  </Box>
                                  {index < data.pastsurgicalhistory - 1 && (
                                    <Box
                                      as="div"
                                      w="2px"
                                      h="5px"
                                      borderColor="black"
                                      m="0 auto"
                                    >
                                      {/* <Image w="2px" h="auto" position="relative" left="-15px" top="-15px" src="/assets/imgs/step-line.png" /> */}
                                    </Box>
                                  )}
                               
                              </Stack>
                            </React.Fragment>
                          ))}
                      </VStack>
                    </Box>
                  </>
                }
                cardFooterText="View full timeline"
                onClick={() => pastsurgicalhistory.current.openRight()}
              />
            ) : (
              <GrayCard
                w="calc(50% - 10px)"
                minW="calc(50% - 10px)"
                maxW="calc(50% - 10px)"
                cardHeader="Past surgical history"
                cardHeaderIcon="/assets/imgs/surgeries.png"
                cardBody={
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      gap="10px"
                      alignItems="center"
                      h="100%"
                    >
                      <Box textAlign="left" fontSize="16px" fontWeight="600">
                        <Image
                          w="26px"
                          opacity="0.5"
                          src="/assets/imgs/surgeries.png"
                        />
                      </Box>
                      <Text textAlign="left" fontSize="12px" fontWeight="500">
                        No Data available
                      </Text>
                    </Box>
                  </>
                }
                cardFooterText="Add Surgical History"
                cardFooterClickEvent={viewpastsurgicalhistory}
              />
            )}

            {/* Family History */}
            {data && data.familyhistory.length > 0 ? (
              <GrayCard
                w="calc(50% - 10px)"
                minW="calc(50% - 10px)"
                maxW="calc(50% - 10px)"
                bodyStyle={{ p: "0 20px", overflow: "auto", maxH: "220px" }}
                cardHeader="family history"
                cardHeaderIcon="/assets/imgs/family-history.png"
                cardBody={
                  <>
                    <Box display="flex" flexDirection="column" gap="10px">
                      {data &&
                        data.familyhistory &&
                        data.familyhistory.map((step, index) => (
                          <Box
                          key={index}
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            // borderBottom="1px solid black"
                          >
                            <Box>
                              <Text fontSize="16px" fontWeight="600">
                                {step.relation ? step.relation : "-"}
                              </Text>
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >
                                {formatDate(step.dob)}
                                {/* {step.updateddate ? step.updateddate : "-"} */}
                              </Text>
                              <Box display="flex" alignItems="center" flexWrap='wrap'>
                              {step.medicalcondition && step.medicalcondition.map((condition, index) => (
                                <Box as="p" display="flex" alignItems="center" flexDir="row" fontSize="10px" key={index} title={step.medicalcondition.map(condition => condition.condition).join(", ")}>
                                  <Box as="span" fontSize="8px" mr="3px">● </Box> {condition.condition}
                                  {index < step.medicalcondition.length - 1 && ", "}
                                </Box>
                              ))}

                            </Box>
                            </Box>

                            <Box display="flex" alignItems="center" border="1px solid #E6E7E966" bg="#E6E7E966" p="4px" px="6px" borderRadius="full">
                            <Text as="span" fontSize="10px" ml="2px" color={step.isdeceased === 1 ? "#fe3333" : "#009700"}>
                              {step.isdeceased === 1 ? "Deceased" : "Alive"}
                            </Text>  
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  </>
                }
                cardFooterText="View All"
                cardFooterClickEvent={navigateToFamilyHistory}
              />
            ) : (
              <GrayCard
                w="calc(50% - 10px)"
                minW="calc(50% - 10px)"
                maxW="calc(50% - 10px)"
                cardHeader="family history"
                cardHeaderIcon="/assets/imgs/family-history.png"
                cardBody={
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      gap="10px"
                      alignItems="center"
                    >
                      <Box textAlign="left" fontSize="16px" fontWeight="600">
                        <Image
                          w="26px"
                          opacity="0.5"
                          src="/assets/imgs/family-history.png"
                        />
                      </Box>
                      <Text textAlign="left" fontSize="12px" fontWeight="500">
                        No Data available
                      </Text>
                    </Box>
                  </>
                }
                cardFooterText="Add Family History"
                cardFooterClickEvent={navigateToFamilyHistory}
              />
            )}
          
            {/* Social History */}
            {data && data.socialhistory.length > 0 ? (
              <>
                <GrayCard
                  w="calc(50% - 10px)"
                  minW="calc(50% - 10px)"
                  maxW="calc(50% - 10px)"
                  bodyStyle={{ p: "0 20px", overflow: "auto", maxH: "220px" }}
                  cardHeader="social History"
                  cardHeaderIcon="/assets/imgs/social-history-bar.png"
                  cardBody={
                    <>
                      <Box>
                      {data &&
        data.socialhistory &&
        data.socialhistory.map((step, index) => {
          const fromDate = new Date(step.fromdate);
          const toDate = new Date(step.todate);
          const diffInYears = toDate.getFullYear() - fromDate.getFullYear();
          // const consumptionYears = diffInYears > 0 ? `${diffInYears} years` : "less than a year";
          const consumptionYears = diffInYears == 1 ? diffInYears + " year"  :
                                   diffInYears > 1 ? diffInYears + " years" : 
                                                                             "less than a year";

          const getSocialHistoryLabel = (socialhistory) => {
            switch (socialhistory) {
              case "Smoking":
                return "Packs";
              case "Alcohol":
                return "Drinks";
              case "Smokeless":
                return "Cans";
              case "Illicit Drugs":
                return "Times";
              default:
                return "Packs";
            }
          };

          return (
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
              gap="20px"
            >
              <Box>
                <Text fontSize="16px" fontWeight="600">
                  {step.socialhistorytype ? step.socialhistorytype : "-"}
                </Text>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Text
                    fontSize="12px"
                    fontWeight="500"
                    color="#384052"
                  >
                    { <span style={{fontWeight:"bold"}}> 
                                    {step.iscurrentstatus == "Yes" ? "Current - " : 
                                     step.iscurrentstatus == "Never" ? "Never" : 
                                     step.iscurrentstatus == "Quit" ? "Former - " :"" }
                                     {/* {step.iscurrentstatus ? step.iscurrentstatus : ""} */}
                                     </span>}
                           
                                     
                    {step.frequency && step.frequency !== 0 ? (
                      <>
                        {`${step.frequency} ${
                          (
                            step.socialhistorytype == "Smoking" ? "packs" :
                            step.socialhistorytype == "Alcohol" ? "Drinks" :
                            step.socialhistorytype == "Illicit Drugs" ? "Times" :
                            step.socialhistorytype == "Smokeless" ? "Cans" : "packs"

                        )
                      } Per ${step.frequencytype} x ${consumptionYears}`}
                        {step.iscurrent == 2 && (
                          <>
                            {` Until ${step.todate ? formatDate_yyyy(step.todate) : ""}`}
                          </>
                        )}
                      </>
                    ) : ""}
                  </Text>
                </Box>
              </Box>
              <Divider size="1px" colorScheme="#E6E7E9" />
            </Box>
          );
        })}
                        {/* <Box >
                <Text fontSize="16px" fontWeight="600">Alcohol</Text>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Text fontSize="12px" fontWeight="500" color="#384052">Average 2 / per day</Text>
                  <Image w="16px" h="14px" mx="3px" src="/assets/imgs/red-chart.png" />
                  <Text fontSize="12px" fontWeight="500" color="#F04438">2</Text>
                </Box>
              </Box> */}
                      </Box>
                    </>
                  }
                  cardFooterText="Update Social History"
                  cardFooterClickEvent={navigateToSocialHistory}
                />
              </>
            ) : (
              <>
                <GrayCard
                  w="calc(50% - 10px)"
                  minW="calc(50% - 10px)"
                  maxW="calc(50% - 10px)"
                  cardHeader="social History"
                  cardHeaderIcon="/assets/imgs/social-history-bar.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                        h="100%"
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/social-history-bar.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    </>
                  }
                  cardFooterText="Add Social History"
                  cardFooterClickEvent={navigateToSocialHistory}
                />
              </>
            )}

            {/* Vitals */}
            <Box
              display="flex"
              justifyContent="space-between"
              gap="20px"
              w="100%"
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                gap="20px"
                w="100%"
              >
                {data && data.vitals ? (
                  <GrayCard
                    bodyStyle={{ p: "0" }}
                    cardHeader="vitals"
                    cardHeaderIcon="/assets/imgs/heart.png"
                    cardBody={
                      <>
                        {/* <Box
                        display="flex"
                        flexDirection="row"
                        gap="20px"
                        py="10px"
                        justifyContent="space-between"
                      >
                        {data &&
                          data.vitals &&
                          data.vitals.map((step, index) => (
                            <>
                              <Box
                              
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="end"
                              >
                                <Box>
                                  <Box
                                    as="p"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    {step.vitalname ? step.vitalname : "-"}
                                  </Box>
                                  <Box
                                    as="p"
                                    display="inline-block"
                                    fontSize="22px"
                                    fontWeight="600"
                                  >
                                    {step.vitalvalue ? step.vitalvalue : "-"}
                                  </Box>
                                  <Box
                                    display="inline-block"
                                    as="p"
                                    fontSize="15px"
                                    pl={"5px"}
                                    fontWeight="400"
                                  >
                                    {step.vitalunit ? step.vitalunit : ""}
                                  </Box>
                                  <Box
                                    as="p"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    {step.vitaladdeddate
                                      ? step.vitaladdeddate
                                      : "-"}
                                  </Box>
                                </Box>
                                <Box display="flex" flexDirection="row" alignItems="center">
            <Image w="12px" h="auto" src="/assets/imgs/check.png" />
            <Box as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Box>
          </Box>
                              </Box>
                              <Box borderRight="1px solid #E6E7E9"></Box>
                            </>
                          ))}
                      </Box> */}
                        {/* <Wrap justify="space-between" spacing={0}>
              {data && data.vitals && data.vitals.map((step, index) => (
                <WrapItem  gap="0" key={index} flexGrow={1} minW={{ base: "calc(50% - 10px)", md: "calc(20% - 16px)" }} maxW="calc(20% - 16px)">
                  <Box borderWidth="1px" p={4} width="100%">
                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                      {step.vitalname || "-"}
                    </Text>
                    <Text display="inline-block" fontSize="22px" fontWeight="600">
                      {step.vitalvalue || "-"}
                    </Text>
                    <Text display="inline-block" fontSize="15px" pl="5px" fontWeight="400">
                      {step.vitalunit || ""}
                    </Text>
                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                      {step.vitaladdeddate || "-"}
                    </Text>

                  </Box>
                </WrapItem>
              // ))}
            </Wrap> */}

                        <Box width="100%" overflowX="auto">
                          <SimpleGrid minChildWidth="220px">
                            {data &&
                              data.vitals &&
                              data.vitals.map((step, index) => (
                                <Box key={index} borderWidth="1px" p={4}>
                                  <Text
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    {step.vitalname || "-"}
                                  </Text>
                                  <Text
                                    display="inline-block"
                                    fontSize="22px"
                                    fontWeight="600"
                                  >
                                    {step.vitalvalue || "-"}
                                  </Text>
                                  <Text
                                    display="inline-block"
                                    fontSize="15px"
                                    pl={"5px"}
                                    fontWeight="400"
                                  >
                                    {step.vitalunit || ""}
                                  </Text>
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                  >
                                    <Box>
                                      <Text
                                        fontSize="12px"
                                        fontWeight="500"
                                        color="#6A707D"
                                      >
                                        {formatDate(step.vitaladdeddate)}
                                        {/* {step.vitaladdeddate || "-"} */}
                                      </Text>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                  <Image w="12px" h="auto" src={
                                      step.status && step.status.toLowerCase() === "low" ? "/assets/imgs/low-yellow.png" :
                                      step.status && step.status.toLowerCase() === "high" ? "/assets/imgs/high-red.png" :
                                      step.status && step.status.toLowerCase() === "normal" ? "/assets/imgs/check.png" : ""
                                    } />
                                  <Text as="span" fontSize="12px" fontWeight="600" ml="2px"> {step.status && (step.status.toLowerCase() === "normal" ? "Ok" : step.status)}</Text>
                                </Box>
                                  </Box>
                                </Box>
                              ))}
                          </SimpleGrid>
                        </Box>
                      </>
                    }
                    cardFooterText="Update Vitals"
                    cardFooterClickEvent={navigateToVitals}
                  />
                ) : (
                  <GrayCard
                    bodyStyle={{ p: "0 20px" }}
                    cardHeader="vitals"
                    cardHeaderIcon="/assets/imgs/heart.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="row"
                          gap="20px"
                          py="10px"
                          justifyContent="space-between"
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="end"
                          >
                            <Box>
                              <Box
                                as="p"
                                fontSize="12px"
                                fontWeight="500"
                                color="#6A707D"
                              >
                                SPO2
                              </Box>
                              <Box
                                as="p"
                                display="inline-block"
                                fontSize="22px"
                                fontWeight="600"
                              >
                                -
                              </Box>
                            </Box>
                          </Box>
                          <Box borderRight="1px solid #E6E7E9"></Box>

                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="end"
                          >
                            <Box>
                              <Box>
                                <Box
                                  as="p"
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#6A707D"
                                >
                                  Respiratory
                                </Box>
                                <Box
                                  as="p"
                                  display="inline-block"
                                  fontSize="22px"
                                  fontWeight="600"
                                >
                                  -
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box borderRight="1px solid #E6E7E9"></Box>

                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="end"
                          >
                            <Box>
                              <Box>
                                <Box
                                  as="p"
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#6A707D"
                                >
                                  Heart rate
                                </Box>
                                <Box
                                  as="p"
                                  display="inline-block"
                                  fontSize="22px"
                                  fontWeight="600"
                                >
                                  -
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box borderRight="1px solid #E6E7E9"></Box>

                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="end"
                          >
                            <Box>
                              <Box>
                                <Box
                                  as="p"
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#6A707D"
                                >
                                  BP
                                </Box>
                                <Box
                                  as="p"
                                  display="inline-block"
                                  fontSize="22px"
                                  fontWeight="600"
                                >
                                  -
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box borderRight="1px solid #E6E7E9"></Box>

                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="end"
                          >
                            <Box>
                              <Box>
                                <Box
                                  as="p"
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#6A707D"
                                >
                                  Body temperature
                                </Box>
                                <Box
                                  as="p"
                                  display="inline-block"
                                  fontSize="22px"
                                  fontWeight="600"
                                >
                                  -
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box borderRight="1px solid #E6E7E9"></Box>
                        </Box>
                      </>
                    }
                    cardFooterText="Add Vitals"
                    cardFooterClickEvent={navigateToVitals}
                  />
                )}
              </Box>
            </Box>
            {/* Investigations */}
            <Box
              display="flex"
              justifyContent="space-between"
              gap="20px"
              w="100%"
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                gap="20px"
                w="100%"
              >
                {data && data.investigations.length > 0 ? (
                  <GrayCard
                    bodyStyle={{ p: "0" }}
                    cardHeader="investigations"
                    cardHeaderIcon="/assets/imgs/investigation.png"
                    cardBody={
                      <>
                        <Box width="100%" overflowX="auto">
                          <SimpleGrid minChildWidth="220px">
                            {data &&
                              data.investigations &&
                              data.investigations.map((step, index) => (
                                <Box key={index} borderWidth="1px" p={4}>
                                  <Text
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    {step.eventtitle ? step.eventtitle : "-"}
                                  </Text>
                                  <Text
                                    display="inline-block"
                                    fontSize="22px"
                                    fontWeight="600"
                                  >
                                    {step.actualresultvalue
                                      ? step.actualresultvalue
                                      : "-"}
                                  </Text>
                                  {step.resultuom && 
                                  <Text display="inline-block" fontSize="15px" fontWeight="400">
                                   ({step.resultuom == "None" ? "-" : step.resultuom  ? step.resultuom : "-"})
                                  </Text>
                                  }
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                  >
                                    <Box>
                                      <Text
                                        fontSize="12px"
                                        fontWeight="500"
                                        color="#6A707D"
                                      >
                                        {formatDate(step.verifieddate)}
                                        {/* {step.verifieddate
                                          ? step.verifieddate
                                          : "-"} */}
                                      </Text>
                                    </Box>
                                    {step.actualresultvalue && (
                                        step.status === 'low' && step.trendColor === 'red' ? (
                                          <Box display="flex" alignItems="center" transform="scaleY(-1)">
                                            <Image w="12px" h="auto" src="/assets/imgs/red-chart.png" alt="Normal" />
                                            {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                          </Box>
                                        ) : step.status === 'high' && step.trendColor === 'red' ? (
                                          <Box display="flex" alignItems="center">
                                            <Image w="12px" h="auto" src="/assets/imgs/red-chart.png" alt="Normal" />
                                            {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                          </Box>
                                        ) : step.status === 'low' && step.trendColor === 'green' ? (
                                          <Box display="flex" alignItems="center">
                                            <Image w="12px" h="auto" src="/assets/imgs/green-chart.png" alt="Normal" />
                                            {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                          </Box>
                                        ) : step.status === 'high' && step.trendColor === 'green' ? (
                                          <Box display="flex" alignItems="center" transform="scaleY(-1)">
                                            <Image w="12px" h="auto" src="/assets/imgs/green-chart.png" alt="Normal" />
                                            {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                          </Box>
                                        ) : null
                                      )}


                                  </Box>
                                </Box>
                              ))}
                          </SimpleGrid>
                        </Box>
                      </>
                    }
                    cardFooterText="View all reports"
                    cardFooterClickEvent={navigatetoinvestigation}
                  />
                ) : (
                  <GrayCard
                    bodyStyle={{ p: "0" }}
                    cardHeader="investigations"
                    cardHeaderIcon="/assets/imgs/investigation.png"
                    cardBody={
                      <>
                        <Flex
                          direction="row"
                          justify="space-around"
                          h="full"
                          w="100%"
                          flexWrap="wrap"
                        >
                          {dataEntries.map((index) => (
                            <SimpleTable NoDataHeader={index} />
                          ))}
                        </Flex>
                      </>
                    }
                    cardFooterText="Add Investigations"
                    cardFooterClickEvent={navigatetoinvestigation}
                  />
                )}
              </Box>
            </Box>
            {/* Lab and Imaging */}
            <Box
              display="flex"
              justifyContent="space-between"
              gap="20px"
              w="100%"
            >
              {data && data.labs.eventtitle != null ? (
                <GrayCard
                  w="calc(50% - 10px)"
                  minW="calc(50% - 10px)"
                  maxW="calc(50% - 10px)"
                  bodyStyle={{ p: "0 20px", overflow: "auto", maxH: "220px" }}
                  cardHeader="labs"
                  cardHeaderIcon="/assets/imgs/biotech.png"
                  cardBody={
                    <>
                      <Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-around"
                          gap="20px"
                        >
                          <Box>
                            <Box
                              mb="3px"
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Text
                                fontSize="16px"
                                fontWeight="600"
                                color="#061027"
                              >
                                {data.labs.eventtitle
                                  ? data.labs.eventtitle
                                  : ""}
                              </Text>
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >
                                {formatDate(data.labs.verifieddate)}
                                {/* {data.labs.verifieddate
                                  ? data.labs.verifieddate
                                  : ""} */}
                              </Text>
                            </Box>
                            <Text fontSize="14px" fontWeight="500">
                              {data.labs.longtext ? data.labs.longtext : ""}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  }
                  cardFooterText="View all reports"
                  cardFooterClickEvent={navigateToLabswithoutupload}
                />
              ) : (
                <GrayCard
                  w="calc(50% - 10px)"
                  minW="calc(50% - 10px)"
                  maxW="calc(50% - 10px)"
                  cardHeader="labs"
                  cardHeaderIcon="/assets/imgs/biotech.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                        h="100%"
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/biotech.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    </>
                  }
                  cardFooterText="Add Labs data"
                  cardFooterClickEvent={navigateToLabs}
                />
              )}

              {data && data.imaging.eventtitle != null? (
                <GrayCard
                  w="calc(50% - 10px)"
                  minW="calc(50% - 10px)"
                  maxW="calc(50% - 10px)"
                  bodyStyle={{ p: "0 20px", overflow: "auto", maxH: "220px" }}
                  cardHeader="IMAGING"
                  cardHeaderIcon="/assets/imgs/hand_bones.png"
                  cardBody={
                    <>
                      <Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-around"
                          gap="20px"
                        >
                          <Box>
                            <Box
                              mb="3px"
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Text
                                fontSize="16px"
                                fontWeight="600"
                                color="#061027"
                              >
                                {data.imaging.eventtitle ? data.imaging.eventtitle : ""}
                              </Text>
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >
                                {formatDate(data.imaging.verifieddate)}
                                {/* {data.verifieddate ? data.verifieddate : ""} */}
                              </Text>
                            </Box>
                            <Text fontSize="14px" fontWeight="500">
                              {data.imaging.longtext ? data.imaging.longtext : ""}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  }
                  cardFooterText="View all reports"
                  cardFooterClickEvent={navigateToImagingWithoutUpload}
                />
              ) : (
                <GrayCard
                  w="calc(50% - 10px)"
                  minW="calc(50% - 10px)"
                  maxW="calc(50% - 10px)"
                  cardHeader="IMAGING"
                  cardHeaderIcon="/assets/imgs/hand_bones.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                        h="100%"
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/hand_bones.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    </>
                  }
                  cardFooterText="Add Imaging data"
                  cardFooterClickEvent={navigateToImaging}
                />
              )}
            </Box>

            <Box display="flex" width="100%" alignItems="center" pr="25px">
              <input
                type="file"
                id="prescriptionupload"
                style={{ display: 'none' }}
                accept="image/jpeg,image/jpg,image/png,image/tiff,application/pdf" // Specify accepted file types
                onChange={uploadprescription}
              />
              <PrimaryInput
               inputType="file"
               uploadText="Upload prescription"
               id="prescriptionupload"
               docBtnWidth="100%"
               docBtnColor="#1C4EC5"
               //iconImg="/assets/imgs/file_open-blue.png"
               onChange={uploadprescription}
               variant="grayBtnFull"
             />
            </Box>
          </Flex>
        
        </Box>
        <input
        type='file'
        id='file-upload'
        onChange={handleimageselection}
        ref={fileInputRef} // Set the ref for the file input
        accept="image/jpeg,image/jpg,image/png,image/tiff,application/pdf" // Specify accepted file types
        style={{ display: 'none' }} // Hide the file input
      />
       <input
        type='file'
        id='file-upload'
        onChange={handleimagingUpload}
        ref={fileInputRef2} // Set the ref for the file input
        accept="image/jpeg,image/jpg,image/png,image/tiff,application/pdf" // Specify accepted file types
        style={{ display: 'none' }} // Hide the file input
      />
      </Flex>
      <Footer />
      </Box>
      <ModalRight
        ref={historyofillness}
        header="History of present illness"
        body={
          <>
            <Box as="p" pt="15px" fontSize="14px" fontWeight="500">
              Patient is an 83-year-old male with a history of Guillain-Barré,
              coronary disease, on metoprolol, history of cardiac bypass, A-fib
              presenting with feeling of unwell today with a low heart rate in
              the 40s at home as well as testicular swelling right greater than
              left for the last week. It is highly important that this patient
              seeks immediate care and gets admitted.
            </Box>
          </>
        }
      />

      <ModalRight
        ref={treatmentplan}
        modalSize="sm"
        header="Treatment Team"
        body={
          <>
            <Box>
              {data &&
                data.treatmentteam &&
                data.treatmentteam.map((step, index) => (
                  <DoctorCard
                  key={index}
                    DocCardStyle={{ my: "10px" }}
                    cardBody={
                      <>
                        <Box
                          py="10px"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box display="flex" alignItems="center">
                            <Image
                              w="40px"
                              borderRadius="full"
                              src="/assets/imgs/avatar.png"
                              mr="3"
                            />
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                            >
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >
                                {step.entitytype ? step.entitytype : ""}
                              </Text>
                              <Text fontSize="14px" fontWeight="600">
                                {step.doctorname ? step.doctorname : "-"}
                              </Text>
                            </Box>
                          </Box>
                          <Text
                            fontSize="12px"
                            fontWeight="500"
                            color="#384052"
                          >
                            {step.date ? step.date : ""}
                          </Text>
                        </Box>
                      </>
                    }
                    cardFooterText="View appointment"
                  />
                ))}
              ;
            </Box>
          </>
        }
      />

      <ModalRight
        ref={pastmedicalhistory}
        header="Past Medical History"
        body={
          <>
            <Box>
              <VStack align="stretch">
                {data &&
                  data.pastmedicalhistory &&
                  data.pastmedicalhistory.map((step, index) => (
                    <React.Fragment key={index}>
                      <Stack direction="column" align="flex-start" gap="0">
                        <Box display="flex" alignItems="center" gap="5px">
                          <Circle
                            size="12px"
                            bg="white"
                            border="2px solid gray"
                            borderRadius="full"
                            color="black"
                          />
                            <Text fontSize="16px" fontWeight="600" className="textOverflow" w="250px">
                              {step.condition}
                            </Text>
                        </Box>
                        <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                          <Center h='35px' ml="5px" borderLeft={index < data.pastmedicalhistory.length - 1 ? "1px dotted #CDCFD4" : "none"}></Center>
                          <Text fontSize="12px" ml="7px" fontWeight="600">
                          {formatDate(step.conditionidentifieddate)}
                          </Text>
                          </Box>
                          {index < data.pastmedicalhistory.length - 1 && (
                            <Box
                              as="div"
                              w="2px"
                              h="5px"
                              borderColor="black"
                              m="0 auto"
                            >
                              {/* <Image w="2px" h="auto" position="relative" left="-15px" top="-15px" src="/assets/imgs/step-line.png" /> */}
                            </Box>
                          )}
                      </Stack>
                    </React.Fragment>
                  ))}
              </VStack>
            </Box>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" alignItems="center" gap="15px">
              <PrimaryButton
                
                onClick={viewpastmedicalhistory}
                variant="grayBtnFull"
                buttonText="View Details"
                isDisabled={false}
              />
               
            </Box>
          </>
        }
      />
        <ModalRight
        ref={pastsurgicalhistory}
        header="Past Surgical History"
        body={
          <>
            <Box>
              <VStack align="stretch">
                {data &&
                  data.pastsurgicalhistory &&
                  data.pastsurgicalhistory.map((step, index) => (
                    <React.Fragment key={index}>
                      <Stack direction="column" align="flex-start" gap="0">
                        <Box display="flex" alignItems="center" gap="5px">
                          <Circle
                            size="12px"
                            bg="white"
                            border="2px solid gray"
                            borderRadius="full"
                            color="black"
                          />
                            <Text fontSize="16px" fontWeight="600">
                              {step.proceduretitle}
                            </Text>
                        </Box>
                        <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                          <Center h='35px' ml="5px" borderLeft={index < data.pastsurgicalhistory.length - 1 ? "1px dotted #CDCFD4" : "none"}></Center>
                          <Text fontSize="12px" ml="7px" fontWeight="600">
                            {formatDate(step.proceduredate)}
                          </Text>
                          </Box>
                          {index < data.pastsurgicalhistory.length - 1 && (
                            <Box
                              as="div"
                              w="2px"
                              h="5px"
                              borderColor="black"
                              m="0 auto"
                            >
                              {/* <Image w="2px" h="auto" position="relative" left="-15px" top="-15px" src="/assets/imgs/step-line.png" /> */}
                            </Box>
                          )}
                      </Stack>
                    </React.Fragment>
                  ))}
              </VStack>
            </Box>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" alignItems="center" gap="15px">
              <PrimaryButton
                
                onClick={viewpastsurgicalhistory}
                variant="grayBtnFull"
                buttonText="View Details"
                isDisabled={false}
              />
               
            </Box>
          </>
        }
      />
    </Box>
  ) : (
    //mobile responsive
    <Box bg="#fcfcfd" h={isIos ? 'calc(100vh - 160px)' : 'calc(100vh - 125px)'} overflow="hidden auto">
        {isLoading && <Loader />}
        <MobileNavbar/>

        {/* Menu section start */}
        <Box bg="#061027" p="1.5rem">
          <Box bg={`url("/assets/imgs/square-bg.png")`} top="0" zIndex="1" w="100%" h="100px" pos="absolute" />
          <Box w="100%" my="1rem">
            <Text fontSize="28px" color="white" fontWeight="600">My Health</Text>
          </Box>
          <Box w="100%">
            <SimpleGrid columns={{base: 4, sm: 6}} spacing="1rem">
              {myHealthIcons.map((item, index)=> (
                <Box key={index} zIndex="3" display="flex" justifyContent="center" alignItems="center" textAlign='center' flexDir='column' gap="0.5rem">
                <Box as="button" boxSize="4rem"  onClick={()=> navigate(item.navLinkUrl)} borderRadius="8px" bg="#0B1F4F" border="1px solid #0B1F4F4D" display="flex" justifyContent="center" alignItems="center">
                  <Image src={item.imageUrl} alt={item.name} filter="invert(100%) sepia(95%) saturate(21%) hue-rotate(278deg) brightness(205%) contrast(105%)" />
                </Box>
                <Text color="#CDCFD4" fontSize="0.75rem" letterSpacing="-0.05rem" whiteSpace="nowrap">{item.name}</Text>
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        </Box>
        {/* Menu section start */}

        {/* Upload document start */}
          <input
            type="file"
            id="Labupload"
            style={{ display: "none" }}
            accept="image/jpeg,image/jpg,image/png,image/tiff, application/pdf" // Specify accepted file types
            onChange={handleimageselection}
            ref={fileInputRefMobile}
          />
         <Box as="button" onClick={handleButtonClick} w="100%" display="flex" bg="white" justifyContent="space-between" alignItems="center" p="1.25rem 1.5rem" borderBottom="1px solid #E6E7E9">
          <Box display="flex" justifyContent="center" alignItems='center' gap="0.75rem">
            <Image src="/assets/imgs/file_open-blue.png" />
            <Text fontSize="1rem" fontWeight="600">Upload document</Text>
          </Box>
          <Image filter={COLORS.BLACK_FILTER} src="/assets/svgs/arrow-right.svg" />
        </Box>
        {/* Upload document end */}

        {/* Main container start */}
        <Box w="100%">
          <SimpleGrid columns={{base: 1, sm: 2}} p="1.5rem" spacing="1rem">
              {/* Demographics */}
            {data && data.demographics ? (
              <GrayCard
                w="100%"
                cardHeader="Demographics"
                cardHeaderIcon="/assets/imgs/measure.png"
                bodyStyle={{ p: "0" }}
                cardBody={
                  <>
                    <Flex direction="row" justify="space-around" h="full">
                      <Box p="10px" alignItems="center" w="100%">
                        <Box
                          as="p"
                          display="inline-block"
                          fontSize="22px"
                          fontWeight="600"
                        >
                          {data.demographics.height
                            ? data.demographics.height
                            : "-"}
                        </Box>
                        <Box
                          display="inline-block"
                          as="p"
                          fontSize="15px"
                          fontWeight="400"
                          pl={"5px"}
                        >
                          {data.demographics.height
                            ? data.demographics.heightunit
                            : ""}
                        </Box>
                        <Box
                          as="p"
                          fontSize="12px"
                          fontWeight="500"
                          color="#6A707D"
                        >
                          Height
                        </Box>
                      </Box>
                      <Box
                        p="10px"
                        w="100%"
                        alignItems="center"
                        borderRight="1px solid #E6E7E9"
                        borderLeft="1px solid #E6E7E9"
                        _last={{ borderRight: 0 }}
                      >
                        <Box
                          as="p"
                          display="inline-block"
                          fontSize="22px"
                          fontWeight="600"
                        >
                          {data.demographics.weight
                            ? data.demographics.weight
                            : "-"}
                        </Box>
                        <Box
                          display="inline-block"
                          as="p"
                          fontSize="15px"
                          fontWeight="400"
                          pl={"5px"}
                        >
                          {data.demographics.weight
                            ? data.demographics.weightunit
                            : ""}
                        </Box>
                        <Box
                          as="p"
                          fontSize="12px"
                          fontWeight="500"
                          color="#6A707D"
                        >
                          Weight
                        </Box>
                      </Box>
                      <Box p="10px" w="100%" alignItems="center">
                        <Box
                          as="p"
                          display="inline-block"
                          fontSize="22px"
                          fontWeight="600"
                        >
                          {data.demographics.bmi ? data.demographics.bmi : "-"}
                        </Box>
                        <Box
                          display="inline-block"
                          as="p"
                          fontSize="15px"
                          fontWeight="400"
                        ></Box>
                        <Box
                          as="p"
                          fontSize="12px"
                          fontWeight="500"
                          color="#6A707D"
                        >
                          BMI
                        </Box>
                      </Box>
                    </Flex>
                  </>
                }
                cardFooterText="Update Demographics"
                cardFooterClickEvent={navigateToVitals}
              />
            ) : (
              <GrayCard
                w="100%"
                cardHeader="Demographics"
                cardHeaderIcon="/assets/imgs/measure.png"
                bodyStyle={{ p: "0" }}
                cardBody={
                  <>
                    <Flex direction="row" justify="space-around" h="full">
                      <Box p="10px" alignItems="center" w="100%">
                        <Box
                          as="p"
                          display="inline-block"
                          fontSize="22px"
                          fontWeight="600"
                        >
                          -
                        </Box>
                        <Box
                          display="inline-block"
                          as="p"
                          fontSize="15px"
                          fontWeight="400"
                        ></Box>
                        <Box
                          as="p"
                          fontSize="12px"
                          fontWeight="500"
                          color="#6A707D"
                        >
                          Height
                        </Box>
                      </Box>
                      <Box
                        p="10px"
                        w="100%"
                        alignItems="center"
                        borderRight="1px solid #E6E7E9"
                        borderLeft="1px solid #E6E7E9"
                        _last={{ borderRight: 0 }}
                      >
                        <Box
                          as="p"
                          display="inline-block"
                          fontSize="22px"
                          fontWeight="600"
                        >
                          -
                        </Box>
                        <Box
                          display="inline-block"
                          as="p"
                          fontSize="15px"
                          fontWeight="400"
                        ></Box>
                        <Box
                          as="p"
                          fontSize="12px"
                          fontWeight="500"
                          color="#6A707D"
                        >
                          Height
                        </Box>
                      </Box>
                      <Box p="10px" w="100%" alignItems="center">
                        <Box
                          as="p"
                          display="inline-block"
                          fontSize="22px"
                          fontWeight="600"
                        >
                          -
                        </Box>
                        <Box
                          display="inline-block"
                          as="p"
                          fontSize="15px"
                          fontWeight="400"
                        ></Box>
                        <Box
                          as="p"
                          fontSize="12px"
                          fontWeight="500"
                          color="#6A707D"
                        >
                          Height
                        </Box>
                      </Box>
                    </Flex>
                  </>
                }
                cardFooterText="Add demographics"
              />
            )}
            {/* Chief Complaint */}
            {data && data.chiefcomplaint != null && (
              <GrayCard
                w="100%"
                cardHeader="chief complaint"
                cardHeaderIcon="/assets/imgs/cheif-complaint.png"
                cardBody={
                  <>
                  <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      gap="50px"
                      h="100%"
                    >
                       <Flex flexDir="column" justifyContent="space-between" h="100%">
                                                <Text fontSize="0.875rem" fontWeight="500">
                                                {data && data.chiefcomplaint && data.chiefcomplaint.chiefcomplaint?data.chiefcomplaint.chiefcomplaint:"-"}
                                                    
                                                </Text>
                                                {/* <Spacer /> */}
                                                <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                                                    {/* 10th Jan, 2023 */}
                                                    {formatDate(data.chiefcomplaint.date)}
                                                </Text>
                        </Flex>
                      {/* <Text fontSize="14px" fontWeight="500">
                                {data && data.chiefcomplaint && data.chiefcomplaint.chiefcomplaint}
                              </Text>
                       */}
                    </Box>
                    {/* {formatDate(data.chiefcomplaint.date)} */}
                  </>
                }
                hasFooter={false}
                cardFooterText={"Add data"}
              />
            )}
            {/* Present Illness */}
            {data && data.historyofpresentillness != null && (
              <GrayCard
                w="100%"
                bodyStyle={{maxH: "210px", overflow:"auto"}}
                cardHeader="history of present illness"
                cardHeaderIcon="/assets/imgs/medical-history.png"
                cardBody={
                  <>
                  <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      gap="50px"
                    >
                       <Flex flexDir="column" justifyContent="space-between" h="100%">
                            <Text fontSize="0.875rem" fontWeight="500">
                            {generateDynamicText(data && data.historyofpresentillness).map((text, index) => (
                            <React.Fragment key={index}>
                              {text}
                              <br />
                              <br />
                            </React.Fragment>
                          ))}
                            </Text>
                        </Flex>
                    </Box>
                  </>
                }
                hasFooter={false}
              />
            )}
            {/* ROS */}
            {data && data.reviewofsystem !== null &&  (
              <GrayCard
              hasFooter={false}
              w="calc(50% - 10px)"
              minW="calc(50% - 10px)"
              maxW="calc(50% - 10px)"
              cardHeader="Review of system (ROS)"
              bodyStyle={{maxH: "210px", overflow:"auto"}}
              cardHeaderIcon="/assets/imgs/physical-exam.png"
              cardBody={
                  <>
                   {data &&
                          data.reviewofsystem &&
                          data.reviewofsystem.map((ros, index) => (
                      <Box mb="10px" key={index}>
                      <Text fontSize="12px" fontWeight="500" color="#0C7650">{ros.rosname}</Text>
                      {ros.rosvalue.length > 0 && (
                            <Text
                                fontSize="16px"
                                fontWeight="600"
                                color="#061027"
                            >
                                {ros.rosvalue.map(val => val.value).join(', ')}
                            </Text>
                        )}
                      <Text fontSize="12px" fontWeight="500" color="#384052" mb="10px">{ros.DATE ? formatDate(ros.DATE): ('')}</Text>
                      <Divider />
                      </Box>
                          ))}
                      {/* <Box display="flex" alignItems="center" bg="#F04438" p="4px" px="6px" borderRadius="full">
                          <Image w="12px" h="12px" src="/assets/imgs/error-white.png" alt="Critical" />
                          <Text as="span" color="white" fontSize="10px" ml="2px">Critical</Text>
                      </Box> */}
                  </>

                  
              }
              />         
            )}
            {/* Physical Exam */}
            {data && data.physicalexam != null && (
              <GrayCard
              hasFooter={false}
              w="calc(50% - 10px)"
              minW="calc(50% - 10px)"
              maxW="calc(50% - 10px)"
              cardHeader="physical exam"
              cardHeaderIcon="/assets/imgs/physical-exam.png"
              cardBody={
                  <>
                   {data &&
                          data.physicalexam &&
                          data.physicalexam.map((step, index) => (
                      <Box mb="10px" key={index}>
                      <Text fontSize="12px" fontWeight="500" color="#0C7650">{step.type}</Text>
                      {step.physicalexam.length > 0 && (
                            <Text
                                fontSize="16px"
                                fontWeight="600"
                                color="#061027"
                            >
                                {step.physicalexam.map(val => val.value).join(', ')}
                            </Text>
                        )}
                      <Text fontSize="12px" fontWeight="500" color="#384052" mb="10px">{step.timeDifference}</Text>
                      <Divider />
                      </Box>
                          ))}
                      {/* <Box display="flex" alignItems="center" bg="#F04438" p="4px" px="6px" borderRadius="full">
                          <Image w="12px" h="12px" src="/assets/imgs/error-white.png" alt="Critical" />
                          <Text as="span" color="white" fontSize="10px" ml="2px">Critical</Text>
                      </Box> */}
                  </>

                  
              }
              />
          
            )}
            {/* Doctor Assesment */}
            {data && data.doctorsassessment != null && (
              <GrayCard
              w="calc(50% - 10px)"
              minW="calc(50% - 10px)"
              maxW="calc(50% - 10px)"
              cardHeader="doctors assessment"
              cardHeaderIcon="/assets/imgs/doctor-tool.png"
              cardBody={
                <>
                  <Box>
                    <VStack align="stretch">
                      {data &&
                        data.doctorsassessment &&
                        data.doctorsassessment.map((step, index) => (
                          <React.Fragment key={index}>
                            <Stack direction="column" align="flex-start" gap="0">
                              <Box display="flex" alignItems="center" gap="5px">
                                <Circle
                                  size="12px"
                                  bg="white"
                                  border="2px solid gray"
                                  borderRadius="full"
                                  color="black"
                                />
                                  <Text fontSize="16px" fontWeight="600">
                                    {step.diagnosis}
                                  </Text>
                                </Box>
                                <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                                  <Center h='35px' ml="5px" borderLeft={index < data.doctorsassessment.length - 1 ? "1px dotted #CDCFD4" : "none"}></Center>
                                    <Text fontSize="12px" ml="7px" fontWeight="600">
                                      {step.plan}
                                    </Text>
                                  </Box>
                                  <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                                  <Center h='35px' ml="5px" borderLeft={index < data.doctorsassessment.length - 1 ? "1px dotted #CDCFD4" : "none"}></Center>
                                    <Text fontSize="12px" ml="7px" fontWeight="600">
                                      {formatDate(step.DATE)}
                                    </Text>
                                  </Box>
                                  {index <
                                    data.doctorsassessment.length - 1 && (
                                    <Box
                                      as="div"
                                      w="2px"
                                      h="5px"
                                      borderColor="black"
                                      m="0 auto"
                                    >
                                      {/* <Image w="2px" h="auto" position="relative" left="-15px" top="-15px" src="/assets/imgs/step-line.png" /> */}
                                    </Box>
                                  )}
                            </Stack>
                          </React.Fragment>
                        ))}
                    </VStack>
                  </Box>
                </>
              }
              cardFooterText="View full timeline"
              onClick={() => pastmedicalhistory.current.openRight()}
            />

            )}

            {/* Current medication */}
            {data && data.currentmedications.length > 0 ? (
              <GrayCard
                w="100%"
                bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
                cardHeader="current medications"
                cardHeaderIcon="/assets/imgs/medication.png"
                cardBody={
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      gap="50px"
                    >
                      <Box>
                        {data &&
                          data.currentmedications &&
                          data.currentmedications.map((step, index) => (
                            // <UnorderedList key={index}>
                            //   <ListItem fontSize="14px" fontWeight="600">
                            <Box mb="0.75rem" key={index}>
                            <Text fontSize="14px" fontWeight="600">
                              {step.medicinename? <strong>●  {step.medicinename}</strong> :""}
                                 
                              </Text>
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >
                              {step.chemicalcomposition ? `${step.chemicalcomposition},  ${step.dosage}` : ""}
                              </Text>
                              </Box>
                            //   </ListItem>
                            // </UnorderedList>
                          ))}
                      </Box>
                    </Box>
                  </>
                }
                cardFooterText="Update Medications"
                cardFooterClickEvent={navigateTomedications}
              />
            ) : (
              <GrayCard
                w="100%"
                cardHeader="Current medications"
                cardHeaderIcon="/assets/imgs/medication.png"
                cardBody={
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      gap="10px"
                      alignItems="center"
                      h="100%"
                    >
                      <Box textAlign="left" fontSize="16px" fontWeight="600">
                        <Image
                          w="26px"
                          opacity="0.5"
                          src="/assets/imgs/medication.png"
                        />
                      </Box>
                      <Text textAlign="left" fontSize="12px" fontWeight="500">
                        No Data available
                      </Text>
                    </Box>
                  </>
                }
                cardFooterText="Add Medications"
                cardFooterClickEvent={navigateTomedications}
              />
            )}

            {/* Treament Team */}
            {data && data.treatmentteam.length > 0 ? (
              <GrayCard
                w="100%"
                bodyStyle={{ p: "0 20px", overflow: "auto", maxH: "220px" }}
                cardHeader="Treatment Team"
                cardHeaderIcon="/assets/imgs/treatment-plan.png"
                cardBody={
                  <>
                    <Box>
                      {data &&
                        data.treatmentteam &&
                        data.treatmentteam.map((step, index) => (
                          <Box
                          key={index}
                            borderBottom="1px solid #E6E7E9"
                            py="15px"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box display="flex" alignItems="center" w="50%">
                              <Image
                                w="40px"
                                h="40px"
                                borderRadius="full"
                                src={step.doctorimage ? step.imagepath + step.doctorimage : "/assets/imgs/no-image-icon.png"}
                                mr="3"
                              />
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                w="50%"
                              >
                                <Text
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#384052"
                                >
                                  {step.entitytype ? step.entitytype : ""}
                                </Text>
                                <Text fontSize="14px" fontWeight="600" title={step.doctorname ? step.doctorname : "-"} className="textOverflow">
                                  {step.doctorname ? step.doctorname : "-"}
                                </Text>
                              </Box>
                            </Box>
                            <Text
                              fontSize="12px"
                              fontWeight="500"
                              color="#384052"
                            >
                              {formatDate(step.date)}
                              
                            </Text>
                          </Box>
                        ))}
                    </Box>
                  </>
                }
                cardFooterText="View all"
                onClick={() => navigate("/home/treatmentteam")}
              />
            ) : (
              <GrayCard
                w="100%"
                cardHeader="Treatment Team"
                cardHeaderIcon="/assets/imgs/treatment-plan.png"
                cardBody={
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      gap="10px"
                      alignItems="center"
                      h="100%"
                    >
                      <Box textAlign="left" fontSize="16px" fontWeight="600">
                        <Image
                          w="26px"
                          opacity="0.5"
                          src="/assets/imgs/treatment-plan.png"
                        />
                      </Box>
                      <Text textAlign="left" fontSize="12px" fontWeight="500">
                        No Data available
                      </Text>
                    </Box>
                  </>
                }
                cardFooterText="View all"
                onClick={() => navigate("/home/treatmentteam")}
              />
            )}
            {/* Past Medical HIstory */}
            {data && data.pastmedicalhistory.length > 0 ? (
              <GrayCard
                w="100%"
                bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
                cardHeader="Past Medical History"
                cardHeaderIcon="/assets/imgs/medical-history.png"
                cardBody={
                  <>
                    <Box>
                      <VStack align="stretch">
                        {data &&
                          data.pastmedicalhistory &&
                          data.pastmedicalhistory.map((step, index) => (
                            <React.Fragment key={index}>
                              <Stack direction="column" align="flex-start" gap="0">
                                <Box display="flex" alignItems="center" gap="5px">
                                  <Circle
                                    size="12px"
                                    bg="white"
                                    border="2px solid gray"
                                    borderRadius="full"
                                    color="black"
                                  />
                                    <Text fontSize="16px" fontWeight="600">
                                      {step.condition}
                                    </Text>
                                  </Box>
                                  <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                                  <Center h='35px' ml="5px" borderLeft={index < data.pastmedicalhistory.length - 1 ? "1px dotted #CDCFD4" : "none"}></Center>
                                    <Text fontSize="12px" ml="7px" fontWeight="600">
                                      {formatDate(step.conditionidentifieddate)}
                                    </Text>
                                  </Box>
                                  {index <
                                    data.pastmedicalhistory.length - 1 && (
                                    <Box
                                      as="div"
                                      w="2px"
                                      h="5px"
                                      borderColor="black"
                                      m="0 auto"
                                    >
                                      {/* <Image w="2px" h="auto" position="relative" left="-15px" top="-15px" src="/assets/imgs/step-line.png" /> */}
                                    </Box>
                                  )}
                              </Stack>
                            </React.Fragment>
                          ))}
                      </VStack>
                    </Box>
                  </>
                }
                cardFooterText="View full timeline"
                onClick={() => pastmedicalhistory.current.openRight()}
              />
            ) : (
              <GrayCard
                w="100%"
                cardHeader="past medical history"
                cardHeaderIcon="/assets/imgs/medical-history.png"
                cardBody={
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      gap="10px"
                      alignItems="center"
                      h="100%"
                    >
                      <Box textAlign="left" fontSize="16px" fontWeight="600">
                        <Image
                          w="26px"
                          opacity="0.5"
                          src="/assets/imgs/medical-history.png"
                        />
                      </Box>
                      <Text textAlign="left" fontSize="12px" fontWeight="500">
                        No Data available
                      </Text>
                    </Box>
                  </>
                }
                cardFooterText="Add Medical History"
                cardFooterClickEvent={viewpastmedicalhistory}
              />
            )}
            {/* Past Surgical HIstory */}
            {data && data.pastsurgicalhistory.length > 0 ? (
              <GrayCard
                w="100%"
                cardHeader="Past surgical history"
                bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
                cardHeaderIcon="/assets/imgs/surgeries.png"
                cardBody={
                  <>
                    <Box>
                      <VStack align="stretch">
                        {data &&
                          data.pastsurgicalhistory &&
                          data.pastsurgicalhistory.map((step, index) => (
                            <React.Fragment key={index}>
                              <Stack direction="column" align="flex-start" gap="0">
                                  <Box display="flex" alignItems="center" gap="5px">
                                    <Circle
                                      size="12px"
                                      bg="white"
                                      border="2px solid gray"
                                      borderRadius="full"
                                      color="black"
                                    />
                                      <Text fontSize="16px" fontWeight="600">
                                        {step.proceduretitle}
                                      </Text>
                                  </Box>
                                  <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                                      <Center h='35px' ml="5px" borderLeft={index < data.pastsurgicalhistory.length - 1 ? "1px dotted #CDCFD4" : "none"}></Center>
                                    <Text fontSize="12px" ml="7px" fontWeight="600">
                                      {/* {step.proceduredate
                                        ? step.proceduredate
                                      : "-"} */}
                                        {formatDate(step.proceduredate)}
                                    </Text>
                                  </Box>
                                  {index < data.pastsurgicalhistory - 1 && (
                                    <Box
                                      as="div"
                                      w="2px"
                                      h="5px"
                                      borderColor="black"
                                      m="0 auto"
                                    >
                                      {/* <Image w="2px" h="auto" position="relative" left="-15px" top="-15px" src="/assets/imgs/step-line.png" /> */}
                                    </Box>
                                  )}
                               
                              </Stack>
                            </React.Fragment>
                          ))}
                      </VStack>
                    </Box>
                  </>
                }
                cardFooterText="View full timeline"
                onClick={() => pastsurgicalhistory.current.openRight()}
              />
            ) : (
              <GrayCard
                w="100%"
                cardHeader="Past surgical history"
                cardHeaderIcon="/assets/imgs/surgeries.png"
                cardBody={
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      gap="10px"
                      alignItems="center"
                      h="100%"
                    >
                      <Box textAlign="left" fontSize="16px" fontWeight="600">
                        <Image
                          w="26px"
                          opacity="0.5"
                          src="/assets/imgs/surgeries.png"
                        />
                      </Box>
                      <Text textAlign="left" fontSize="12px" fontWeight="500">
                        No Data available
                      </Text>
                    </Box>
                  </>
                }
                cardFooterText="Add Surgical History"
                cardFooterClickEvent={viewpastsurgicalhistory}
              />
            )}

            {/* Family History */}
            {data && data.familyhistory.length > 0 ? (
              <GrayCard
                w="100%"
                bodyStyle={{ p: "0 20px", overflow: "auto", maxH: "220px" }}
                cardHeader="family history"
                cardHeaderIcon="/assets/imgs/family-history.png"
                cardBody={
                  <>
                    <Box display="flex" flexDirection="column" gap="10px">
                      {data &&
                        data.familyhistory &&
                        data.familyhistory.map((step, index) => (
                          <Box
                          key={index}
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            // borderBottom="1px solid black"
                          >
                            <Box>
                              <Text fontSize="16px" fontWeight="600">
                                {step.relation ? step.relation : "-"}
                              </Text>
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >
                                {formatDate(step.dob)}
                                {/* {step.updateddate ? step.updateddate : "-"} */}
                              </Text>
                              <Box display="flex" alignItems="center" flexWrap='wrap'>
                              {step.medicalcondition && step.medicalcondition.map((condition, index) => (
                                <Box as="p" display="flex" alignItems="center" flexDir="row" fontSize="10px" key={index} title={step.medicalcondition.map(condition => condition.condition).join(", ")}>
                                  <Box as="span" fontSize="8px" mr="3px">● </Box> {condition.condition}
                                  {index < step.medicalcondition.length - 1 && ", "}
                                </Box>
                              ))}

                            </Box>
                            </Box>

                            <Box display="flex" alignItems="center" border="1px solid #E6E7E966" bg="#E6E7E966" p="4px" px="6px" borderRadius="full">
                            <Text as="span" fontSize="10px" ml="2px" color={step.isdeceased === 1 ? "#fe3333" : "#009700"}>
                              {step.isdeceased === 1 ? "Deceased" : "Alive"}
                            </Text>  
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  </>
                }
                cardFooterText="View All"
                cardFooterClickEvent={navigateToFamilyHistory}
              />
            ) : (
              <GrayCard
                w="100%"
                cardHeader="family history"
                cardHeaderIcon="/assets/imgs/family-history.png"
                cardBody={
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      gap="10px"
                      alignItems="center"
                    >
                      <Box textAlign="left" fontSize="16px" fontWeight="600">
                        <Image
                          w="26px"
                          opacity="0.5"
                          src="/assets/imgs/family-history.png"
                        />
                      </Box>
                      <Text textAlign="left" fontSize="12px" fontWeight="500">
                        No Data available
                      </Text>
                    </Box>
                  </>
                }
                cardFooterText="Add Family History"
                cardFooterClickEvent={navigateToFamilyHistory}
              />
            )}
          
            {/* Social History */}
            {data && data.socialhistory.length > 0 ? (
              <>
                <GrayCard
                  w="100%"
                  bodyStyle={{ p: "0 20px", overflow: "auto", maxH: "220px" }}
                  cardHeader="social History"
                  cardHeaderIcon="/assets/imgs/social-history-bar.png"
                  cardBody={
                    <>
                      <Box>
                      {data &&
        data.socialhistory &&
        data.socialhistory.map((step, index) => {
          const fromDate = new Date(step.fromdate);
          const toDate = new Date(step.todate);
          const diffInYears = toDate.getFullYear() - fromDate.getFullYear();
          // const consumptionYears = diffInYears > 0 ? `${diffInYears} years` : "less than a year";
          const consumptionYears = diffInYears == 1 ? diffInYears + " year"  :
                                   diffInYears > 1 ? diffInYears + " years" : 
                                                                             "less than a year";

          const getSocialHistoryLabel = (socialhistory) => {
            switch (socialhistory) {
              case "Smoking":
                return "Packs";
              case "Alcohol":
                return "Drinks";
              case "Smokeless":
                return "Cans";
              case "Illicit Drugs":
                return "Times";
              default:
                return "Packs";
            }
          };

          return (
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
              gap="20px"
            >
              <Box>
                <Text fontSize="16px" fontWeight="600">
                  {step.socialhistorytype ? step.socialhistorytype : "-"}
                </Text>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Text
                    fontSize="12px"
                    fontWeight="500"
                    color="#384052"
                  >
                    { <span style={{fontWeight:"bold"}}> 
                                    {/* {step.iscurrent == 1 ? "Current - " : 
                                     step.iscurrent == 0 ? "Never" : 
                                     step.iscurrent == 2 ? "Former - " :"" } */}
                                     {step.iscurrentstatus == "Yes" ? "Current - " : 
                                     step.iscurrentstatus == "Never" ? "Never" : 
                                     step.iscurrentstatus == "Quit" ? "Former - " :"" }
                                     </span>}
                           
                                     
                    {step.frequency && step.frequency !== 0 ? (
                      <>
                        {`${step.frequency} ${
                          (
                            step.socialhistorytype == "Smoking" ? "packs" :
                            step.socialhistorytype == "Alcohol" ? "Drinks" :
                            step.socialhistorytype == "Illicit Drugs" ? "Times" :
                            step.socialhistorytype == "Smokeless" ? "Cans" : "packs"

                        )
                      } Per ${step.frequencytype} x ${consumptionYears}`}
                        {step.iscurrent == 2 && (
                          <>
                            {` Until ${step.todate ? formatDate_yyyy(step.todate) : ""}`}
                          </>
                        )}
                      </>
                    ) : ""}
                  </Text>
                </Box>
              </Box>
              <Divider size="1px" colorScheme="#E6E7E9" />
            </Box>
          );
        })}
                        {/* <Box >
                <Text fontSize="16px" fontWeight="600">Alcohol</Text>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Text fontSize="12px" fontWeight="500" color="#384052">Average 2 / per day</Text>
                  <Image w="16px" h="14px" mx="3px" src="/assets/imgs/red-chart.png" />
                  <Text fontSize="12px" fontWeight="500" color="#F04438">2</Text>
                </Box>
              </Box> */}
                      </Box>
                    </>
                  }
                  cardFooterText="Update Social History"
                  cardFooterClickEvent={navigateToSocialHistory}
                />
              </>
            ) : (
              <>
                <GrayCard
                  w="100%"
                  cardHeader="social History"
                  cardHeaderIcon="/assets/imgs/social-history-bar.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                        h="100%"
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/social-history-bar.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    </>
                  }
                  cardFooterText="Add Social History"
                  cardFooterClickEvent={navigateToSocialHistory}
                />
              </>
            )}

            {/* Vitals */}
            <Box
              display="flex"
              justifyContent="space-between"
              gap="20px"
              w="100%"
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                gap="20px"
                w="100%"
              >
                {data && data.vitals ? (
                  <GrayCard
                    bodyStyle={{ p: "0" }}
                    cardHeader="vitals"
                    cardHeaderIcon="/assets/imgs/heart.png"
                    cardBody={
                      <>
                        {/* <Box
                        display="flex"
                        flexDirection="row"
                        gap="20px"
                        py="10px"
                        justifyContent="space-between"
                      >
                        {data &&
                          data.vitals &&
                          data.vitals.map((step, index) => (
                            <>
                              <Box
                              
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="end"
                              >
                                <Box>
                                  <Box
                                    as="p"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    {step.vitalname ? step.vitalname : "-"}
                                  </Box>
                                  <Box
                                    as="p"
                                    display="inline-block"
                                    fontSize="22px"
                                    fontWeight="600"
                                  >
                                    {step.vitalvalue ? step.vitalvalue : "-"}
                                  </Box>
                                  <Box
                                    display="inline-block"
                                    as="p"
                                    fontSize="15px"
                                    pl={"5px"}
                                    fontWeight="400"
                                  >
                                    {step.vitalunit ? step.vitalunit : ""}
                                  </Box>
                                  <Box
                                    as="p"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    {step.vitaladdeddate
                                      ? step.vitaladdeddate
                                      : "-"}
                                  </Box>
                                </Box>
                                <Box display="flex" flexDirection="row" alignItems="center">
            <Image w="12px" h="auto" src="/assets/imgs/check.png" />
            <Box as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Box>
          </Box>
                              </Box>
                              <Box borderRight="1px solid #E6E7E9"></Box>
                            </>
                          ))}
                      </Box> */}
                        {/* <Wrap justify="space-between" spacing={0}>
              {data && data.vitals && data.vitals.map((step, index) => (
                <WrapItem  gap="0" key={index} flexGrow={1} minW={{ base: "calc(50% - 10px)", md: "calc(20% - 16px)" }} maxW="calc(20% - 16px)">
                  <Box borderWidth="1px" p={4} width="100%">
                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                      {step.vitalname || "-"}
                    </Text>
                    <Text display="inline-block" fontSize="22px" fontWeight="600">
                      {step.vitalvalue || "-"}
                    </Text>
                    <Text display="inline-block" fontSize="15px" pl="5px" fontWeight="400">
                      {step.vitalunit || ""}
                    </Text>
                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                      {step.vitaladdeddate || "-"}
                    </Text>

                  </Box>
                </WrapItem>
              // ))}
            </Wrap> */}

                        <Box width="100%" overflowX="auto">
                          <SimpleGrid columns={{base: 2, md: 4, xl: 5}}>
                            {data &&
                              data.vitals &&
                              data.vitals.map((step, index) => (
                                <Box key={index} borderWidth="1px" p={4}>
                                  <Text
                                    fontSize={{base: "10px", lg: "12px"}}
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    {step.vitalname || "-"}
                                  </Text>
                                  <Text
                                    display="inline-block"
                                    fontSize="22px"
                                    fontWeight="600"
                                  >
                                    {step.vitalvalue || "-"}
                                  </Text>
                                  <Text
                                    display="inline-block"
                                    fontSize="15px"
                                    pl={"5px"}
                                    fontWeight="400"
                                  >
                                    {step.vitalunit || ""}
                                  </Text>
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                  >
                                    <Box>
                                      <Text
                                        fontSize={{base: "10px", lg: "12px"}}
                                        fontWeight="500"
                                        color="#6A707D"
                                      >
                                        {formatDate(step.vitaladdeddate)}
                                        {/* {step.vitaladdeddate || "-"} */}
                                      </Text>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                  <Image w={{base: "10px", lg: "12px"}} h="auto" src={
                                      step.status && step.status.toLowerCase() === "low" ? "/assets/imgs/low-yellow.png" :
                                      step.status === "High" ? "/assets/imgs/high-red.png" :
                                      step.status && step.status.toLowerCase() === "normal" ? "/assets/imgs/check.png" : ""
                                    } />
                                  <Text as="span" fontSize={{base: "10px", lg: "12px"}} fontWeight="600" ml="2px"> {step.status && (step.status.toLowerCase() === "normal" ? "Ok" : step.status)}</Text>
                                </Box>
                                  </Box>
                                </Box>
                              ))}
                          </SimpleGrid>
                        </Box>
                      </>
                    }
                    cardFooterText="Update Vitals"
                    cardFooterClickEvent={navigateToVitals}
                  />
                ) : (
                  <GrayCard
                    bodyStyle={{ p: "0 20px" }}
                    cardHeader="vitals"
                    cardHeaderIcon="/assets/imgs/heart.png"
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="row"
                          gap="20px"
                          py="10px"
                          justifyContent="space-between"
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="end"
                          >
                            <Box>
                              <Box
                                as="p"
                                fontSize="12px"
                                fontWeight="500"
                                color="#6A707D"
                              >
                                SPO2
                              </Box>
                              <Box
                                as="p"
                                display="inline-block"
                                fontSize="22px"
                                fontWeight="600"
                              >
                                -
                              </Box>
                            </Box>
                          </Box>
                          <Box borderRight="1px solid #E6E7E9"></Box>

                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="end"
                          >
                            <Box>
                              <Box>
                                <Box
                                  as="p"
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#6A707D"
                                >
                                  Respiratory
                                </Box>
                                <Box
                                  as="p"
                                  display="inline-block"
                                  fontSize="22px"
                                  fontWeight="600"
                                >
                                  -
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box borderRight="1px solid #E6E7E9"></Box>

                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="end"
                          >
                            <Box>
                              <Box>
                                <Box
                                  as="p"
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#6A707D"
                                >
                                  Heart rate
                                </Box>
                                <Box
                                  as="p"
                                  display="inline-block"
                                  fontSize="22px"
                                  fontWeight="600"
                                >
                                  -
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box borderRight="1px solid #E6E7E9"></Box>

                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="end"
                          >
                            <Box>
                              <Box>
                                <Box
                                  as="p"
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#6A707D"
                                >
                                  BP
                                </Box>
                                <Box
                                  as="p"
                                  display="inline-block"
                                  fontSize="22px"
                                  fontWeight="600"
                                >
                                  -
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box borderRight="1px solid #E6E7E9"></Box>

                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="end"
                          >
                            <Box>
                              <Box>
                                <Box
                                  as="p"
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#6A707D"
                                >
                                  Body temperature
                                </Box>
                                <Box
                                  as="p"
                                  display="inline-block"
                                  fontSize="22px"
                                  fontWeight="600"
                                >
                                  -
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box borderRight="1px solid #E6E7E9"></Box>
                        </Box>
                      </>
                    }
                    cardFooterText="Add Vitals"
                    cardFooterClickEvent={navigateToVitals}
                  />
                )}
              </Box>
            </Box>
            {/* Investigations */}
            <Box
              display="flex"
              justifyContent="space-between"
              gap="20px"
              w="100%"
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                gap="20px"
                w="100%"
              >
                {data && data.investigations.length > 0 ? (
                  <GrayCard
                    bodyStyle={{ p: "0" }}
                    cardHeader="investigations"
                    cardHeaderIcon="/assets/imgs/investigation.png"
                    cardBody={
                      <>
                        <Box width="100%" overflowX="auto">
                          <SimpleGrid minChildWidth="220px">
                            {data &&
                              data.investigations &&
                              data.investigations.map((step, index) => (
                                <Box key={index} borderWidth="1px" p={4}>
                                  <Text
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    {step.eventtitle ? step.eventtitle : "-"}
                                  </Text>
                                  <Text
                                    display="inline-block"
                                    fontSize="22px"
                                    fontWeight="600"
                                  >
                                    {step.actualresultvalue
                                      ? step.actualresultvalue
                                      : "-"}
                                  </Text>
                                  {step.resultuom && 
                                  <Text display="inline-block" fontSize="15px" fontWeight="400">
                                   ({step.resultuom == "None" ? "-" : step.resultuom  ? step.resultuom : "-"})
                                  </Text>
                                  }
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                  >
                                    <Box>
                                      <Text
                                        fontSize="12px"
                                        fontWeight="500"
                                        color="#6A707D"
                                      >
                                        {formatDate(step.verifieddate)}
                                        {/* {step.verifieddate
                                          ? step.verifieddate
                                          : "-"} */}
                                      </Text>
                                    </Box>
                                    {step.actualresultvalue && (
                                      step.status === 'low' && step.trendColor === 'red' ? (
                                        <Box display="flex" alignItems="center" transform="scaleY(-1)">
                                          <Image w="12px" h="auto" src="/assets/imgs/red-chart.png" alt="Normal" />
                                          {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                        </Box>
                                      ) : step.status === 'high' && step.trendColor === 'red' ? (
                                        <Box display="flex" alignItems="center">
                                          <Image w="12px" h="auto" src="/assets/imgs/red-chart.png" alt="Normal" />
                                          {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                        </Box>
                                      ) : step.status === 'low' && step.trendColor === 'green' ? (
                                        <Box display="flex" alignItems="center">
                                          <Image w="12px" h="auto" src="/assets/imgs/green-chart.png" alt="Normal" />
                                          {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                        </Box>
                                      ) : step.status === 'high' && step.trendColor === 'green' ? (
                                        <Box display="flex" alignItems="center" transform="scaleY(-1)">
                                          <Image w="12px" h="auto" src="/assets/imgs/green-chart.png" alt="Normal" />
                                          {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                        </Box>
                                      ) : null
                                    )}


                                  </Box>
                                </Box>
                              ))}
                          </SimpleGrid>
                        </Box>
                      </>
                    }
                    cardFooterText="View all reports"
                    cardFooterClickEvent={navigatetoinvestigation}
                  />
                ) : (
                  <GrayCard
                    bodyStyle={{ p: "0" }}
                    cardHeader="investigations"
                    cardHeaderIcon="/assets/imgs/investigation.png"
                    cardBody={
                      <>
                        <Flex
                          direction="row"
                          justify="space-around"
                          h="full"
                          w="100%"
                          flexWrap="wrap"
                        >
                          {dataEntries.map((index) => (
                            <SimpleTable NoDataHeader={index} />
                          ))}
                        </Flex>
                      </>
                    }
                    cardFooterText="Add Investigations"
                    cardFooterClickEvent={navigatetoinvestigation}
                  />
                )}
              </Box>
            </Box>
            {/* Lab and Imaging */}
            <Box
              display="flex"
              flexDir="column"
              gap="20px"
              w="100%"
            >
              {data && data.labs.eventtitle != null ? (
                <GrayCard
                  w="100%"
                  bodyStyle={{ p: "20px", overflow: "auto", maxH: "220px" }}
                  cardHeader="labs"
                  cardHeaderIcon="/assets/imgs/biotech.png"
                  cardBody={
                    <>
                      <Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-around"
                          gap="20px"
                        >
                          <Box>
                            <Box
                              mb="3px"
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Text
                                fontSize="16px"
                                fontWeight="600"
                                color="#061027"
                              >
                                {data.labs.eventtitle
                                  ? data.labs.eventtitle
                                  : ""}
                              </Text>
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >
                                {formatDate(data.labs.verifieddate)}
                                {/* {data.labs.verifieddate
                                  ? data.labs.verifieddate
                                  : ""} */}
                              </Text>
                            </Box>
                            <Text fontSize="14px" fontWeight="500">
                              {data.labs.longtext ? data.labs.longtext : ""}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  }
                  cardFooterText="View all reports"
                  cardFooterClickEvent={navigateToLabswithoutupload}
                />
              ) : (
                <GrayCard
                  w="100%"
                  cardHeader="labs"
                  cardHeaderIcon="/assets/imgs/biotech.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                        h="100%"
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/biotech.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    </>
                  }
                  cardFooterText="Add Labs data"
                  cardFooterClickEvent={navigateToLabs}
                />
              )}

              {data && data.imaging.eventtitle != null? (
                <GrayCard
                  w="100%"
                  bodyStyle={{ p: "20px", overflow: "auto", maxH: "220px" }}
                  cardHeader="IMAGING"
                  cardHeaderIcon="/assets/imgs/hand_bones.png"
                  cardBody={
                    <>
                      <Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-around"
                          gap="20px"
                        >
                          <Box>
                            <Box
                              mb="3px"
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Text
                                fontSize="16px"
                                fontWeight="600"
                                color="#061027"
                              >
                                {data.imaging.eventtitle ? data.imaging.eventtitle : ""}
                              </Text>
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >
                                {formatDate(data.imaging.verifieddate)}
                                {/* {data.verifieddate ? data.verifieddate : ""} */}
                              </Text>
                            </Box>
                            <Text fontSize="14px" fontWeight="500">
                              {data.imaging.longtext ? data.imaging.longtext : ""}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  }
                  cardFooterText="View all reports"
                  cardFooterClickEvent={navigateToImagingWithoutUpload}
                />
              ) : (
                <GrayCard
                  w="100%"
                  cardHeader="IMAGING"
                  cardHeaderIcon="/assets/imgs/hand_bones.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                        h="100%"
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/hand_bones.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    </>
                  }
                  cardFooterText="Add Imaging data"
                  cardFooterClickEvent={navigateToImaging}
                />
              )}
            </Box>
          </SimpleGrid>
          
          {/* upload prescription start */}
          <Box display="flex" width="100%" alignItems="center" bg="white">
              <input
                type="file"
                id="prescriptionupload"
                style={{ display: 'none' }}
                accept="image/jpeg,image/jpg,image/png,image/tiff, application/pdf" // Specify accepted file types
                onChange={uploadprescription}
              />
              <PrimaryInput
                inputBox={{bg: "white"}}
               inputType="file"
               uploadText="Upload prescription"
               id="prescriptionupload"
               docBtnWidth="100%"
               docBtnColor="#1C4EC5"
               //iconImg="/assets/imgs/file_open-blue.png"
               onChange={uploadprescription}
               variant="grayBtnFull"
             />
          </Box>
          {/* upload prescription end */}
        </Box>
        {/* Main container end */}
    </Box>
  )}
  </>
  );
};

export default MyHealthindex;
