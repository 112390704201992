
import formAPILab from "./lab";


var formattedOutput = null;

const formAPIModelLabs = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data?.map(item => formAPILab(item));


  return formattedOutput
};

export default formAPIModelLabs