import moment from "moment";
export default function formAPIVoice({
    chat,
    chattype,
    ispatient,
    createddate,
    imagepath,
   
    
  }) {   
    return Object.freeze({
        chat,
        chattype,
        ispatient,
        createddate,
        imagepath,       
    });
}


  