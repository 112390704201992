import React, { useState, useEffect } from "react";
import {
    Box,
    Center,
    VStack,
    Image,
    Heading,
    Text,
    InputGroup,
    Button,
    Divider,
    Icon,
    PinInput, PinInputField,
    HStack
  } from "@chakra-ui/react";
  import {
    PrimaryInputDropdown,
    PrimaryButton,
    PrimaryButtonIcon,
    PasswordInput,
    PrimaryInput,
    Toaster,
  } from "../../components";
  
import { useLocation } from "react-router-dom";
import { generateOtp, ValidatedOtp } from "../../middleware/services/signup";
import { useNavigate } from "react-router";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { VerifyUser } from "../../middleware/services/login";

function OtpIndexForgotPassword() {
  const [otp, setOtp] = useState("1234")
  var emailFromLocal = localStorage.getItem("Email")
  const [email, setEmail] = useState(emailFromLocal)
  
  var mobileFromLocal = localStorage.getItem("mobile")
  const [mobileno, setMobile] = useState(mobileFromLocal)
  var redirect = useNavigate();
  const { isLoading, showLoader, dismissLoader } = useLoader();

  
  const [isMobileLoginClicked, setIsMobileLoginClicked] = useState(false);
  const [isEmailClicked, setIsEmailLoginClicked] = useState(true);
const { showSuccessToast, showErrorToast } = Toaster();

const [valueOfPins, setValueOfPins] = useState("");


const navigate = useNavigate();


const [moblieNo, setMoblieNo] = useState('');
const [countryCode, setCountryCode] = useState('');
const [countryId, setCountryId] = useState('');

const [countdown, setCountdown] = useState(30);

const [resendClicked, setResendClicked] = useState(false);


var mobileFromLocal = localStorage.getItem("mobile")
const [mobile, setMobileNo] = useState(mobileFromLocal);
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const location = useLocation();
const countrycodeProp = location.state?.countrycode || '';
const countryidProp = location.state?.countryid || '';

useEffect(() => {
  const storedValue = localStorage.getItem('MobileNo');
  const countryCode = localStorage.getItem('countryid');
  const countryId = localStorage.getItem('countryid');
  setCountryId(countryId)
  if (storedValue) {
    setMoblieNo(storedValue);
  }
  if (countryCode) {
    setCountryCode(countryCode);
  }
  const intervalId = setInterval(() => {
    setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : 0));
  }, 1000);

  setTimeout(() => {
    
  var pin1 = document.getElementById("pin1-0");
  pin1.focus();
  }, 10);

  // Cleanup interval on component unmount
  return () => clearInterval(intervalId);
}, [resendClicked]); 



  var inputForValidateOTP = {
    "otp": valueOfPins,
    email,
    "type": "forgot password"
  }
  var inputForValidateOTPMobile = {
    "otp": valueOfPins,
    mobileno,
    "type": "forgot password",
    "countryid":countryId
  }
  
  const handleLoginSubmit = async () =>{
    showLoader();
     var pin1 = document.getElementById("pin1-0")
     var pin2 = document.getElementById("pin1-1")
     var pin3 = document.getElementById("pin1-2")
     var pin4 = document.getElementById("pin1-3")
     var pin1value = pin1.value;
     var pin2value = pin2.value;
     var pin3value = pin3.value;
     var pin4value = pin4.value;
     

     var otpEnteredValue = pin1value + pin2value + pin3value + pin4value;

     if(pin1value == "" || pin2value == "" || pin3value == "" || pin4value == "" ){
      
     dismissLoader();
     showErrorToast("Please Enter the OTP")
      return false;
     }
    //  else if(otpEnteredValue !== otp){
      
    //   showErrorToast("Uh-oh! The code that you have entered is incorrect, please try again...")
    //   setTimeout(() => {
    //  dismissLoader();
    //   }, 2000);
    //    return false;
       
    //   }
      else {
    if(isEmailClicked){
       // console.log(inputForValidateOTP , "input for validate otp")
    const result = await ValidatedOtp(inputForValidateOTP);

    var outputResult = result.output.result;

    if(outputResult == "success"){
    // alert("sucess")
    
    localStorage.setItem('activeComponent', 'medical');
     navigate('/newpassword');
    }else if (outputResult == "failure"){
      
      showErrorToast("Uh-oh! The code that you have entered is incorrect, please try again...")
    }
    
  dismissLoader();
   }else if(isMobileLoginClicked){
   //console.log(inputForValidateOTPMobile , "input for validate otp mobile")
    const result = await ValidatedOtp(inputForValidateOTPMobile);
    //console.log(result , "mobile otp vaildation Result");
    var outputResult = result.output.result;

    if(outputResult == "success"){
    // alert("sucess")
    
    localStorage.setItem('activeComponent', 'medical');
     navigate('/newpassword');
    }else if (outputResult == "failure"){
      
      showErrorToast("Uh-oh! The code that you have entered is incorrect, please try again...")
    }
    
  dismissLoader();
   }
  }
  dismissLoader();
  }
  
const handleResendClick = () => {
  generateOtpFunction();
  setTimeout(() => {
  setCountdown(120);
  setResendClicked(true);
  setValueOfPins("")
  }, 1000);
  
};
const handleMobilepassKeyPress = (e) => {
  if (e.key === "Enter") {
    handleLoginSubmit();
  }
};
const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
  return `${formattedMinutes}:${formattedSeconds}`;
};
const handlebackbutton =()=>{
    redirect("/forgotpassword")
  }

  

  const storedEmail = localStorage.getItem('Email');
const storedMobile = localStorage.getItem('mobile');

useEffect(()=>{
  if(storedEmail){
    setIsEmailLoginClicked(true)
    
    setIsMobileLoginClicked(false)
  }else if(storedMobile){
    setIsMobileLoginClicked(true)
    setIsEmailLoginClicked(false)
  }else {
    
    setIsEmailLoginClicked(true)
    
    setIsMobileLoginClicked(false)
  }
},[])

const handlePinValues = (value)=>{
  setValueOfPins(value)
}
var inputToGenerateOTP;
if(isMobileLoginClicked){
   inputToGenerateOTP = {
    // "otp": "1234",
    "countrycode": countrycodeProp,
    "countryid": countryidProp,
    "language": "eng",
    "type": "forgot password",
    mobile
   }
  
}else if(isEmailClicked){

   inputToGenerateOTP = {
    // "otp": "1234",
    "countrycode": "+1",
    "countryid": 1,
    "language": "eng",
    "type": "forgot password",
    email
   }
}

const generateOtpFunction = async() =>{
  showLoader();
if(isEmailClicked){
  localStorage.setItem("Email" , email)
    if (email.trim === "") {
      showErrorToast("Email cannot be empty");
     // setIsError(true);
      return;
    } else if (!emailRegex.test(email)) {
      showErrorToast("Please Enter Valid Email Address");
    //  setIsError(true);
      return;
    } else {
      try {
        const response = await VerifyUser({
          email,
          type: "patient",
         // "countryid":229
        });
        if (response.output.result === "0") {
          localStorage.setItem("NewUserEmail", email)
          showErrorToast("Uh-oh! The email you have entered does not exist.");
         // setIsError(true);
          navigate("/signup")
        } else {
          const fetchData = async () => {
            try {
              
    localStorage.setItem("Email" , email)
    if(response.output.imagepath && response.output.patientimage){

      let imageURL  = response.output.imagepath + response.output.patientimage 
      localStorage.setItem("imageUrl" , imageURL)
    }
              const result = await generateOtp(inputToGenerateOTP);
              if(result.output.result == "failure"){
                showErrorToast("User dosent exists")
              }else if (result.output.result == "success"){
                
          redirect("/otpforgotpassword")
              }
            } catch (error) {
              console.error('Error generating OTP:', error);
            }
          };
        
          fetchData();
        }
      } catch (error) {
        console.error("failed:", error.response.data);
      }
    }
  }
  else if(isMobileLoginClicked){
    
  localStorage.setItem("mobile" , mobile)
  if (mobile == "") {
    showErrorToast("Mobile cannot be empty");
    setIsMobileLoginClicked(true)
   // setIsError(true);
  }  else {
    try {
      const response = await VerifyUser({
        "mobileno":mobile,
        type: "patient",
       // "countryid":229
        "countryid":countryidProp
      });
      
    //  console.log(mobile , "mobile in verify user")
    //  console.log(response , "response verify user")
      if (response.output.result === "0") {
       // localStorage.setItem("NewUserEmail", email)
        showErrorToast("Uh-oh! The mobile number you have entered does not exist.");
       // setIsError(true);
        navigate("/signup")
      } else {
        var inputToGenerateOTPMobile = {
          "otp": "1234",
          "countrycode": countrycodeProp,
          // "countryid": 229,
          "countryid": countryidProp,
          "language": "eng",
          "type": "forgot password",
          "mobileno":mobile,
         }
        const fetchData = async () => {
          try {
            
  localStorage.setItem("mobile" , mobile)
            const generateOTP = await generateOtp(inputToGenerateOTPMobile);
          } catch (error) {
            console.error('Error generating OTP mobile:', error);
          }
        };
      
        // localStorage.setItem("countryidProp", countryidProp)
        // localStorage.setItem("countrycodeProp", countrycodeProp)
        fetchData();
        redirect("/otpforgotpassword")
      }
    }catch(error){
      console.log(error, "error in verify user in mobile")
    }
  }
  }
    dismissLoader();
  }


    return (
        <Box
        w="100vw"
        h="100vh"
        bg="#061027"
        bgImage="url('/assets/imgs/login-bg.png')"
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
      >
        
      {isLoading && <Loader />}
        <Center
          h="100vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
             <Box as="button" bg="none" fontSize="14px" pos="absolute" left="3%" top="5%" color="white" display="flex" justifyContent="center" alignItems="center" gap="8px" p="10px 16px" border="1px solid #384052" borderRadius="8px"
         onClick={handlebackbutton}
         >
        <Image w="14px" src="/assets/imgs/left-arrow-white.png" />
        <Text>Back</Text>
      </Box>
          <Box>
            <Image src="/assets/imgs/midoc-logo.png" alt="Logo" />
          </Box>
          <VStack
            spacing={4}
            p={8}
            m={4}
            bg="white"
            boxShadow="xl"
            borderRadius="lg"
            width={["90%", "400px"]}
            gap="0"
            display="flex"
            alignItems="center"
          >
            <Box mb="15px">
              <Image
                src="/assets/imgs/verify-code.png"
                w="48px"
                h="auto"
                borderRadius="10px"
              />
            </Box>
            <Box as="h1">Verify Code</Box>
            {isEmailClicked && (
            <Box mb="20px">
              <Text fontSize={16}>Enter the OTP that was sent to your email<br/>  
            
              </Text>
            </Box>
            )}
            {isMobileLoginClicked && (
            <Box mb="20px">
              <Text fontSize={16}>Enter the OTP that was sent to your mobile<br/>  
            
              </Text>
            </Box>
            )}

            <HStack>
  <PinInput  id="pin1" value={valueOfPins} onChange={(e)=>handlePinValues(e)} placeholder='-' size="lg">
    <PinInputField sx={{ width: "72px", height: "72px", fontSize: "2xl" }}  />
    <PinInputField sx={{ width: "72px", height: "72px", fontSize: "2xl" }} />
    <PinInputField sx={{ width: "72px", height: "72px", fontSize: "2xl" }} />
    <PinInputField sx={{ width: "72px", height: "72px", fontSize: "2xl" }}  
                onKeyPress={handleMobilepassKeyPress}/>
  </PinInput>
</HStack>

{countdown > 0 && (
<Button
              sx={{
                bg: "none",
                color: "#6A707D",
                fontSize: "12px",
                fontWeight: "600",
                _hover: { bg: "none" },
              }}
            >
            
        Resend OTP in: {formatTime(countdown)}
            </Button>
            )}
            {countdown <= 0 && (
              <div style={{display:"flex", justifyContent:"space-between", width: "100%"}}>
              <Button
              sx={{
                bg: "none",
                color: "#6A707D",
                fontSize: "12px",
                fontWeight: "600",
                _hover: { bg: "none" },
              }}
              
            onClick={handleResendClick}
            >
              Didnt Receive OTP?
            </Button>

              <Button
              
            onClick={handleResendClick}
              sx={{
                bg: "none",
                color: "#6A707D",
                fontSize: "12px",
                fontWeight: "600",
                _hover: { bg: "none" },
              }}
            >
              Resend OTP
            </Button>
              </div>
            )}

            
            <PrimaryButton
              colorScheme="blue"
              width="full"
              buttonText={"Continue"}
              isDisabled={false}
              onClick={() => {
                 handleLoginSubmit();
              }}
            />
          </VStack>
      </Center>
    </Box>
    )
}

export default OtpIndexForgotPassword