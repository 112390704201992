import { COLORS } from '../styles/colors';

export const styles = {
    borderRadius: "10px",
    background: "var(--primary-gradient-blue)",
    color: "var(--primary-white)",
    boxShadow: "none",
    padding: "8px 10px",
    margin: "0",
    _disabled: {
      bg: "var(--btn-disable)",
      color: "var(--btn-disable-text)",
      cursor: "not-allowed",
    },
    _hover: {
      bg: "var(--primary-color)"
    },
  };
  
  export const toastrStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    color: "white",
    padding: "12px",
    background: COLORS.TOASTR_BG,
    borderRadius: "35px"
  }

  export const iconStyleSuccess = {
    background: COLORS.TOASTR_ICON_SUCCESS,
    borderRadius: "50%",
    padding: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
  
  export const iconBgStyleSuccess = {
    bg: COLORS.TOASTR_ICON_BG_SUCCESS,
    borderRadius: "50%",
    color: "#469186",
    boxShadow: "10px 10px 34px -8px rgba(61,127,120,1)"
  }

  export const iconStyleError = {
    background: COLORS.TOASTR_ICON_ERROR,
    borderRadius: "50%",
    padding: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
  
  export const iconBgStyleError = {
    bg: COLORS.TOASTR_ICON_BG_SUCCESS,
    borderRadius: "50%",
    color: "#B9504B",
    padding: "1.5px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25), 0px 4px 10px rgba(0, 0, 0, 0.15);"
  }