import { Box, Divider, Textarea } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import TimeSlotPicker from "../../components/timeslot";
import DatePickerAppointment from '../../components/date-picker-appointment';
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { FetchDoctorSlots, UpdateAppointment } from "../../middleware/services/appointments";
import moment from 'moment';
import { PrimaryButton, Toaster } from '../../components';
import { useNavigate } from "react-router-dom";
import { addDays, addMonths, addYears, startOfDay } from 'date-fns';

const groupSlots = (slots, selectedDate) => {
  const morningSlots = [];
  const afternoonSlots = [];
  const eveningSlots = [];
  const nightSlots = [];

  const currentTime = moment();
  const isToday = moment(selectedDate).isSame(currentTime, 'day');

  slots.forEach(slot => {
    if (slot.starttime) {
      const time = slot.starttime.split(':');
      const hour = parseInt(time[0], 10);
      const minute = parseInt(time[1], 10);
      const slotTime = moment(selectedDate).hour(hour).minute(minute);
      const formattedTime = `${time[0]}:${time[1]}`;
      const isDisabled = isToday && slotTime.isBefore(currentTime);

      const slotData = { 
        time: formattedTime, 
        isSelected: false, 
        appointmenttype_cd: slot.appointmenttype_cd,
        isDisabled 
      };

      if (hour >= 6 && hour < 12) {
        morningSlots.push(slotData);
      } else if (hour >= 12 && hour < 18) {
        afternoonSlots.push(slotData);
      } else if (hour >= 18 && hour < 21) {
        eveningSlots.push(slotData);
      } else {
        nightSlots.push(slotData);
      }
    }
  });
  return { morningSlots, afternoonSlots, eveningSlots, nightSlots };
};

const SlotBooking = ({ date,slot, doctorid, bookingupto, bookinguptotype, selectedTime: initialSelectedTime, 
                       type, appointmentid, onSubmit, textAreaDefaultValue, isfreevisit, followupid }) => {
  const [selectedTime, setSelectedTime] = useState();
  const [selectedAppointmentTypeCd, setSelectedAppointmentTypeCd] = useState(null);
  const [slots, setSlots] = useState({
    morningSlots: [],
    afternoonSlots: [],
    eveningSlots: [],
    nightSlots: []
  });
  //this below change is done - as when redirecting from orders , it is showing the wrong month (if the month is not current month) , in the defalue date, ref - Bug 6521
  // const [selectedDate, setSelectedDate] = useState(date);
  const [selectedDate, setSelectedDate] = useState(date && type != "reschedule" ? moment(date, 'DD-MM-YYYY').format("ddd, D MMM") : date);

  const [dateToSendInInput, setDateToSendInInput] = useState("");
  const [Notes, setNotes] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [patientid, setPatientid] = useState();
  const [activeuserid, setActiveUser] = useState();
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = Toaster();
  const [ initialRender , setinitialRender] = useState(true);

  useEffect(() => {
    if (date) {
      handleDateChangeInEdit(date);
      setSelectedDate(date);
    }
    setinitialRender(false)
  }, [date, doctorid]);
  const convertTo24HourFormat = (time) => {
    const [timePart, modifier] = time.split(' ');
    let [hours, minutes] = timePart.split(':');
  
    if (hours === '12') {
      hours = '00';
    }
  
    if (modifier === 'PM' && hours !== '12') {
      hours = parseInt(hours, 10) + 12;
    }
  
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };
  useEffect(() => {
    if (slot) {
      const convertedSlotTime = convertTo24HourFormat(slot);
      setSelectedTime(convertedSlotTime);
    } else {
      setSelectedTime(initialSelectedTime);
    }
  }, [slot, initialSelectedTime]);
  const handleSlotClick = (time, appointmenttype_cd, isDisabled) => {
    if (!isDisabled) {
      setSelectedTime(time);
      setSelectedAppointmentTypeCd(appointmenttype_cd);
    }
  };

  // const handleOnClick = () =>{
  //   if(isfreevisit == 1 && initialRender == false){
  //     setSelectedDate(date);
  //     showErrorToast("This is a Free Visit, cannot change the date!");
  //     return false;
     
  //   }
  // }

  const handleDateChangeInEdit = async (selecteddate) => {
      
    setSelectedTime(null);
    const formattedDate = moment(selecteddate).format("YYYY-MM-DD");
    showLoader();
    try {
      const result = await FetchDoctorSlots({
        patientid,
        activeuserid,
        doctorid: doctorid,
        date: formattedDate,
      });
      if (result != null) {
        const { morningSlots, afternoonSlots, eveningSlots, nightSlots } = groupSlots(result, selecteddate);
        setSlots({ morningSlots, afternoonSlots, eveningSlots, nightSlots });
        const formatdate = moment(result[0].date).format("ddd, D MMM");
        setSelectedDate(formatdate);
        setDateToSendInInput(result[0].date);
      } else {
        // Handle no data scenario
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
      dismissLoader();
    }
  };

  const handlenotes = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.slice(0, 500);
    event.target.value = inputValue;
    setNotes(event.target.value);
  }

  const bookappointment = async () => {
    if (slots.morningSlots.length === 0 && slots.afternoonSlots.length === 0 && slots.eveningSlots.length === 0 && slots.nightSlots.length === 0) {
      showErrorToast("No slots available")
    } else if (selectedTime === null || selectedTime == undefined ) {
      showErrorToast("Please select any slot")
    } else {
      let input = {
        patientid: "", doctorid: doctorid,
        appointmentdate: dateToSendInInput, appointmenttime: selectedTime, chiefcomplaint: Notes,
        appointmenttype_cd: selectedAppointmentTypeCd, client_secret: "",
        "paymenttype":"online",  isfreevisit:isfreevisit,
      }
      if(type === "reschedule"){
        const formatteddate  = moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
        let input = {
          "patientid":"",doctorid: doctorid,
          appointmentdate: formatteddate, appointmenttime: selectedTime, chiefcomplaint: Notes,
          appointmentid:appointmentid,type:"reschedule",isfreevisit:isfreevisit,
         
        }
        const result = await UpdateAppointment(input);
        if (result.output.result === "success") {
          onSubmit("rescheduled");
        } else {
            showErrorToast("Something went wrong!")
        }
      }else{
        navigate("/appointmentpayment", { state: { input,  "followupid": followupid } })
      }
    }
  }

  const calculateMaxDate = (bookingupto, bookinguptotype) => {
    const numberOfUnits = parseInt(bookingupto, 10);

    let maxDate;
    switch (bookinguptotype) {
      case 'Day':
        maxDate = addDays(startOfDay(new Date()), numberOfUnits);
        break;
      case 'Month':
        maxDate = addMonths(startOfDay(new Date()), numberOfUnits);
        break;
      case 'Year':
        maxDate = addYears(startOfDay(new Date()), numberOfUnits);
        break;
      default:
        maxDate = addDays(startOfDay(new Date()), numberOfUnits);
    }
    return maxDate;
  };

  return (
    <Box w="100%">
      <TimeSlotPicker
        morningSlots={slots.morningSlots.map(slot => ({
          ...slot,
          isSelected: slot.time === selectedTime,
          onClick: () => handleSlotClick(slot.time, slot.appointmenttype_cd, slot.isDisabled),
          isDisabled: slot.isDisabled
        }))}
        afternoonSlots={slots.afternoonSlots.map(slot => ({
          ...slot,
          isSelected: slot.time === selectedTime,
          onClick: () => handleSlotClick(slot.time, slot.appointmenttype_cd, slot.isDisabled),
          isDisabled: slot.isDisabled
        }))}
        eveningSlots={slots.eveningSlots.map(slot => ({
          ...slot,
          isSelected: slot.time === selectedTime,
          onClick: () => handleSlotClick(slot.time, slot.appointmenttype_cd, slot.isDisabled),
          isDisabled: slot.isDisabled
        }))}
        nightSlots={slots.nightSlots.map(slot => ({
          ...slot,
          isSelected: slot.time === selectedTime,
          onClick: () => handleSlotClick(slot.time, slot.appointmenttype_cd, slot.isDisabled),
          isDisabled: slot.isDisabled
        }))}
      />
      {isLoading && <Loader />}
      <DatePickerAppointment
        disableFutureDates={false}
        inputStyle={{ borderRadius: "0.75rem!important", pl: "11px" }}
        customText="Change"
        onDateChange={handleDateChangeInEdit}
        placeholderProp="Selected date"
        defaultDate={selectedDate && selectedDate}
       // onClick={isfreevisit == 1 && initialRender == false ? handleOnClick : false}
        maxDate={calculateMaxDate(bookingupto, bookinguptotype)}
      />
      <Divider my="1rem" />
      <Textarea placeholder='Chief complaint'
        onChange={handlenotes}
        defaultValue={textAreaDefaultValue}
        sx={{ _hover: { outline: "none", } }}></Textarea>
      <PrimaryButton buttonText={type === "reschedule"? "Reschedule Appointment":"Book Appointment"} btnStyle={{ mb: "0", mt: "2rem" }}
        onClick={bookappointment}
      />
    </Box>
  );
};

export default SlotBooking;
