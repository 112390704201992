import { Box, Divider, Flex, Image, Text, Textarea, VStack,Stack , Center, Circle, AccordionButton, AccordionItem, Badge, AccordionIcon, AccordionPanel, Accordion, SimpleGrid, HStack, Button, RadioGroup, Radio, GridItem, Checkbox} from "@chakra-ui/react";import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import { PrimaryButton, Toaster } from "../../components";
import { COLORS } from "../../components/styles/colors";
import GrayCard from "../../components/card";
import ModalRight from "../../components/modal-right";
import TimeSlotPicker from "../../components/timeslot";
import DatePicker from "../../components/date-picker";
import DynamicModal from "../../components/dynamic-modal";
import { Calendar } from "react-date-range";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { FetchAppointmentDetails, CancelAppointment, DeleteHPI } from "../../middleware/services/appointments";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import moment from "moment";
import SlotBooking from "./slotbooking";
import { FetchDoctorDetails, FetchHPIMasterData } from "../../middleware/services/appointments";
import ToggleTab from "../../components/accordion";
import ReportViewCard from "../../components/report-card";
import AddEditHPI from "./addedithpi";
import HPIViewCard from "./hpi-view-card";
import { getLoginInfo, useIsIOS } from "../../auth";
import { color } from "framer-motion";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";

const data1 = [
  { header: "Onset", content: "Today" },
  { header: "Chronicity", content: "Chronic" },
  { header: "Onset quality", content: "Sudden" },
  { header: "Frequency", content: "Continuous" },
  { header: "Rating scale", content: "5" },
  { header: "Associated symptoms", content: "None" },
  { header: "Time", content: "1 week" },
  { header: "Frequency", content: "Continuous" },
];




const AppointmentDetails = () => {
  const reschedule = useRef();
  const preferDate = useRef();
  const editNotes = useRef();
  const chiefcomplaint = useRef();
  const viewDocProfile = useRef();
  const cancelBooking = useRef();
  const hpiModal = useRef();
  const navigate = useNavigate()
  const [selectedTime, setSelectedTime] = useState(null);
  const [date, setDate] = React.useState(new Date());
  const location = useLocation();
  const { appointmentid , isfreevisit} = location.state || {};
  var [AppointmentData, setAppointmentData] = useState(null);
  var [AppointmentDate, setAppointmentDate] = useState(null);
  var [patientid, setPatientid] = useState(getLoginInfo());
  var [activeuserid, setActiveUser] = useState();
  var [notesupdated, setNotesUpdated] = useState();
  var [ChiefComplaintUpdated, setChiefComplaintUpdated] = useState();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  var [doctordata, setDoctorDetailsData] = useState(null);
  var [HPIData, setHPIData] = useState(null);
  const [siteOptions, setSiteOptions] = useState([]);
  const [onsetOptions, setOnsetOptions] = useState([]);
  const [chronicityOptions, setChronicityOptions] = useState([]);
  const [onsetQualityOptions, setOnsetQualityOptions] = useState([]);
  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [associatedSymptomsOptions, setAssociatedSymptomsOptions] = useState(
    []
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const [editHPIData, setEditHPIData] = useState(null);
  const { showSuccessToast, showErrorToast } = Toaster();
  const isIoS = useIsIOS()


  useEffect(() => {
    fetchAppointmentDetails();
    fetchHPIMaster();
  }, []);
  const fetchAppointmentDetails = async () => {
    showLoader();
    try {
      const result = await FetchAppointmentDetails({
        patientid,
        activeuserid,
        appointmentid: appointmentid,
      });
      if (result != null) {
        setAppointmentData(result);
      } else {
        //alert("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
  const fetchHPIMaster = async () => {
    showLoader();
    try {
      const result = await FetchHPIMasterData({
        patientid,
        activeuserid,
        type: "hpi",
      });
      if (result != null) {
        console.log(result)
        setHPIData(result);
      } else {
        //alert("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
  const cancelappointment = async ()=>{
   let input = {"patientid":"","doctorid":AppointmentData && AppointmentData.doctorid,"appointmentid":appointmentid,"reason":"","type":"cancel"}
   try {
    const result = await CancelAppointment(input);
    if (result != null) {
      navigate("/appointments")
    } else {
    }
    dismissLoader();
  } catch (error) {
    console.error("Error in home:", error);
  }
  }
  const handleSlotClick = (time) => {
    setSelectedTime(time);
  };

  const handleDateChange = (date) => {
    setDate(date);
  };

  const cancelBtnStyle = {
    my: "0", 
    bg: "#D800270D", 
    color: "#D80027",
    _hover: {
        bg: "#D800270D"
    }
  }
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("ddd, D MMM YYYY");
  };
  const rescheduleappointment = ()=>{
    if(AppointmentData && AppointmentData.appointmentstatus <= 1 && AppointmentData && AppointmentData.isappointmentstarted === 0){
      reschedule.current.openRight();
    }else if(AppointmentData && AppointmentData.isdoctoractive == 0 ){
      showErrorToast("Doctor Unavailable!");
      return false;
    }else{
      navigate("/doctorprofile", { state: { doctorid: AppointmentData && AppointmentData.doctorid, fullname: AppointmentData && AppointmentData.doctorname }});
    }
    
  }
  const updatenotes =async ()=>{
  let input = {patientid, doctorid:AppointmentData && AppointmentData.doctorid, appointmentid:appointmentid, appointmentfor:notesupdated,type:"updatenotes"}
  try {
    const result = await CancelAppointment(input);
    if (result != null) {
      fetchAppointmentDetails();
      editNotes.current.closeRight()
    } else {
    }
    dismissLoader();
  } catch (error) {
    console.error("Error in home:", error);
  } 
}
const updatechiefcomplaint =async ()=>{
  let input = {patientid, doctorid:AppointmentData && AppointmentData.doctorid, appointmentid:appointmentid, chiefcomplaint:ChiefComplaintUpdated,type:"updatedetail"}
  try {
    const result = await CancelAppointment(input);
    if (result != null) {
      fetchAppointmentDetails();
      chiefcomplaint.current.closeRight()
    } else {
    }
    dismissLoader();
  } catch (error) {
    console.error("Error in home:", error);
  } 
}
  const viewdoctorprofile = async ()=>{
    
    showLoader();
    try {
      const result = await FetchDoctorDetails({
        patientid,
        activeuserid,
        doctorid: AppointmentData && AppointmentData.doctorid,
      });
      if (result != null) {
        setDoctorDetailsData(result);
        viewDocProfile.current.openRight();
      } else {
        //alert("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  }
//   const navigatemap = (lat, long) => {
//     const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${long}&travelmode=driving`;
//     window.open(url, '_blank');
// }
const navigatemap = (address1 , address2, city, country) => {
  var address = address1 + address2 + city+country
  const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}&travelmode=driving`;
  window.open(url, '_blank');
};
  const handleReScheduleSubmit = (type) => {
    if(type === "rescheduled"){
      fetchAppointmentDetails();
      reschedule.current.closeRight();
    }
  };

  //Add/Edit HPI
  const addHpi = useRef();
  const [addHPIType, setAaddHPIType] = useState("");

  const handleAddHPI = (event, type) => {
    event.stopPropagation();
    prepareAndOpenModal(type);
  };

  const prepareAndOpenModal = (type, editData = null) => {
    const selectedItem = HPIData.find((item) => item.type === type);
    if (selectedItem) {
      // S-Site Filter Master Data
      const filteredsiteOptions = selectedItem.value
        .filter((item) => item.type === "Site")
        .flatMap((item) => item.subtype.map((subItem) => subItem.value));

      // Onset Filter Master Data
      const filteredOnsetOptions = selectedItem.value
        .filter((item) => item.type === "Onset")
        .flatMap((item) => item.subtype.map((subItem) => subItem.value));

      // Chronicity Filter Master Data
      const filteredChronicityOptions = selectedItem.value
        .filter((item) => item.type === "Chronicity")
        .flatMap((item) => item.subtype.map((subItem) => subItem.value));

      // Onset Quality Filter Master Data
      const filteredOnsetQualityOptions = selectedItem.value
        .filter((item) => item.type === "Onset Quality")
        .flatMap((item) => item.subtype.map((subItem) => subItem.value));

      // Onset Filter Master Data
      const filteredFrequencyOptions = selectedItem.value
        .filter((item) => item.type === "Frequency")
        .flatMap((item) => item.subtype.map((subItem) => subItem.value));

      // S-Site Filter Master Data
      const filteredAssociatedSymptomsOptions = selectedItem.value
        .filter((item) => item.type === "Associated Symptoms")
        .flatMap((item) => item.subtype.map((subItem) => subItem.value));

      setSiteOptions(filteredsiteOptions);
      setOnsetOptions(filteredOnsetOptions);
      setChronicityOptions(filteredChronicityOptions);
      setOnsetQualityOptions(filteredOnsetQualityOptions);
      setFrequencyOptions(filteredFrequencyOptions);
      setAssociatedSymptomsOptions(filteredAssociatedSymptomsOptions);

      setAaddHPIType(type);

      setIsEditMode(!!editData);
      setEditHPIData(editData);

      // console.log(editData);

      // open Modal Call
      openEditModal();
    }
  };

  const openEditModal = () => {
    if (hpiModal.current) {
      hpiModal.current.openRight();
    }
  };

  const editHPI = (note) => {
    const type = note.type;
    prepareAndOpenModal(type, note);
  };
 
// Delete HPI
const dynamicModalDelteHPI = useRef();

const [doctorNotesHPIDeleteId, setDoctorNotesHPIDeleteId] = useState("");

const openDeleteModal = (orderId) => {
  setDoctorNotesHPIDeleteId(orderId);
  if (dynamicModalDelteHPI.current) {
    dynamicModalDelteHPI.current.openModal();
  }
};

const deleteSelectedHPI = async () => {
  const inputForDelete = {
    input: {
      id: doctorNotesHPIDeleteId,
      patientid,
      type: "hpi",
    },
  };

  const deleteOrder = async () => {
    try {
      const result = await DeleteHPI(inputForDelete.input);
      if (result.output.message === "Successfully Deleted") {
        showSuccessToast("Order has been deleted");
        fetchAppointmentDetails();
        dynamicModalDelteHPI.current.closeModal();
      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.error("Error in delete:", error);
    }
  };
  deleteOrder();
};
const goback = ()=>{
  window.history.back();
}

const openNotesModal = (notes)=>{
  setNotesUpdated(notes)
  editNotes.current.openRight();
}
  return (
    <>
   
      <Box w="100%" display={{base: "none", lg: "block"}}>
        <Box top="0" zIndex="banner">
          <Navbar />
          {isLoading && <Loader />}
          <SecondaryNav
            withBack="Appointments details"
            goBack={goback}
            withBackBody={
              <>
                <Box display="flex" gap="10px" alignItems="center" pr="25px">
                  <PrimaryButton
                    variant="mdBtn"
                    buttonText="Chat with doctor"
                    onClick={()=> navigate("/chat", { state: { id: AppointmentData && AppointmentData.doctorid,
                                                               name: AppointmentData && AppointmentData.doctorname,
                                                               appointmentDate: AppointmentData && AppointmentData.appointmentdate,
                                                               appointmentTime: AppointmentData && AppointmentData.appointmenttime,
                                                               appointmentId: AppointmentData && AppointmentData.appointmentid }})}
                  />
                </Box>
              </>
            }
          />
        </Box>
        <Box
          h="calc(100vh - 140px)"
          overflow="hidden auto"
          backgroundImage={COLORS.PRO_PLAN}
        >
          <Box></Box>
          <Flex p="24px 32px" gap="15px">
            {/* Plan details start */}
            <Flex w="75%">
              <Box w="100%" display="flex" flexDir="column" gap="15px">
                <Box
                  w="100%"
                  bg="#FFFFFF"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderRadius="0.75rem"
                  p="1.5rem"
                  boxShadow="md"
                >
                  <Box>
                    <Text fontSize="1.75rem" fontWeight="600" color="#040A17">
                      Thank you!
                    </Text>
                    <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                      Your booking has been confirmed
                    </Text>
                  </Box>
                  <Box boxSize="3.25rem">
                    <Image src="/assets/imgs/success-alert.png" />
                  </Box>
                </Box>

                <GrayCard
                  cardHeader="Booking"
                  noIconCss={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  noIcon={
                    <>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text
                          fontSize="0.625rem"
                          fontWeight="600"
                          color={AppointmentData && AppointmentData.appointmentstatus === 1? "#FC8935" : "#384052"} borderColor={AppointmentData && AppointmentData.appointmentstatus === 1? "#FC89351A" : "#f5f5f6"} border="1px solid" p="0.35rem 0.5rem" borderRadius="full" bg={AppointmentData && AppointmentData.appointmentstatus === 1? "#fff3eb" : "#f5f5f6"}
                        >
                          {AppointmentData && AppointmentData.appointmentstatus === 1? "Upcoming": AppointmentData && AppointmentData.appointmentstatus === 2?"Cancelled": AppointmentData && AppointmentData.appointmentstatus === 3 ? "Completed": AppointmentData && AppointmentData.appointmentstatus === 4 ? "No show": AppointmentData && AppointmentData.appointmentstatus === 0 ? "Pending" :""}
                        </Text>
                      </Box>
                    </>
                  }
                  cardBody={
                    <>
                      <Box w="100%">
                        <Flex w="100%">
                          <Box w="50%">
                            <Text fontSize="0.75rem" color="gray.500">
                              Booking ID
                            </Text>
                            <Text fontSize="0.875rem" fontWeight="500">
                              {AppointmentData && AppointmentData.bookingid? "#"+AppointmentData.bookingid:"-"}
                            </Text>
                          </Box>
                          <Box w="50%">
                            <Text fontSize="0.75rem" color="gray.500">
                              Appointment schedule
                            </Text>
                            <Flex alignItems="center">
                              <Image
                                boxSize="0.875rem"
                                src="/assets/svgs/calendar-gray.svg"
                                alt="icon"
                                mr={2}
                              />
                              <Text fontSize="0.875rem" fontWeight="500">
                              {formatDate(AppointmentData && AppointmentData.appointmentdate)} at {AppointmentData && AppointmentData.appointmenttime}
                              </Text>
                            </Flex>
                          </Box>
                        </Flex>
                        <Box mt={4}>
                          <Text fontSize="0.75rem" color="gray.500">
                            Type
                          </Text>
                          <Flex alignItems="center">
                            <Image
                              boxSize="0.875rem"
                              src={AppointmentData && AppointmentData.appointmenttype === 693?"/assets/svgs/hospital-gray.svg":"/assets/svgs/videocam-gray.svg"}
                              alt="icon"
                              mr={2}
                            />
                            <Text fontSize="0.875rem" fontWeight="500">
                              {AppointmentData && AppointmentData.appointmenttype === 693? "Clinic Visit": "Video Visit"}
                            </Text>
                          </Flex>
                        </Box>
                      </Box>
                    </>
                  }
               //   hasFooter={AppointmentData && AppointmentData.isappointmentstarted == 1 && AppointmentData.appointmentstatus < 2 ? false : true}
                  cardFooterText={AppointmentData && AppointmentData.appointmentstatus <= 1 && AppointmentData && AppointmentData.isappointmentstarted ===0?"Reschedule":"Book again"}
                  cardFooterClickEvent={rescheduleappointment}
                 // disableBtn={AppointmentData && AppointmentData.isdoctoractive == 0 ? true : false}
                />

                <GrayCard
                  cardHeader="your doctor"
                  
                  cardBody={
                    <>
                      <Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          w="100%"
                          alignItems="flex-start"
                        >
                          <Box display="flex" gap="0.75rem">
                            <Box
                              boxSize="3.5rem"
                              borderRadius="full"
                              overflow="hidden"
                            >
                              <Image
                                w="100%"
                                h="100%"
                                src={AppointmentData && AppointmentData.doctorimage? AppointmentData.imagepath+AppointmentData.doctorimage:"/assets/imgs/no-image.png"}
                              />
                            </Box>
                            <Box maxWidth="90%">
                              <Text
                                fontSize="0.625rem"
                                fontWeight="500"
                                color="#384052"
                                wordBreak="break-word"
                              >
                                {AppointmentData && AppointmentData.specality}
                              </Text>
                              <Text
                                fontSize="0.875rem"
                                fontWeight="600"
                                color="#061027"
                                wordBreak="break-word"
                              >
                               
                                {AppointmentData && AppointmentData.doctorname}
                              </Text>
                              <Text
                                fontSize="0.75rem"
                                fontWeight="400"
                                color="#384052"
                                wordBreak="break-word"
                              >
                                {AppointmentData && AppointmentData.qualification}
                              </Text>
                            </Box>
                          </Box>
                          {/* <Box display="inline-flex" gap="0.25rem" border='1px solid #E6E7E9' borderRadius="full" p="0.15rem 0.5rem">
                              <Image src="/assets/svgs/Stars.svg" />
                              <Text fontSize="0.75rem" fontWeight="500" color="#384052">{AppointmentData && AppointmentData.ratings}</Text>
                            </Box> */}
                        </Box>

                        <Divider my="0.75rem" />
                        <Box display="flex" flexDir="column">
                          <Box display="flex" alignItems="center" gap="0.5rem">
                            <Image
                              w="12px"
                              h="13px"
                              src="/assets/svgs/location-blue.svg"
                            />
                            <Text fontSize="0.75rem" fontWeight="500" wordBreak="break-word">
                              {AppointmentData && AppointmentData.doctoraddress? AppointmentData.doctoraddress.address+", "+AppointmentData.doctoraddress.city+", "+AppointmentData.doctoraddress.country:"-"}
                            </Text>
                          </Box>
                          <Box display="flex" alignItems="center" gap="0.5rem">
                            <Image
                              w="13px"
                              h="10px"
                              src="/assets/svgs/work-blue.svg"
                            />
                            <Text fontSize="0.75rem" fontWeight="500">
                            {AppointmentData && AppointmentData.yearsofexperience > 1? AppointmentData.yearsofexperience+ " years of experience":"-"}
                            </Text>
                          </Box>
                          <Box display="flex" alignItems="center" gap="0.5rem">
                            <Image
                              w="13px"
                              h="10px"
                              src="/assets/svgs/hospital.svg"
                            />
                            <Text fontSize="0.75rem" fontWeight="500" 
                                wordBreak="break-word">
                            {AppointmentData && AppointmentData.hospitalname}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  }
                  cardFooterText="View profile"
                  cardFooterClickEvent={viewdoctorprofile}
                />
                
                {/* Notes card */}
                {/* <GrayCard
                  cardHeader="Notes"
                  
                  cardBody={
                    <>
                      <Box w="100%">
                        <Text fontSize="0.875rem">
                        {AppointmentData && AppointmentData.patientnotes? AppointmentData.patientnotes:"-"}
                        </Text>
                      </Box>
                    </>
                  }
                  cardFooterText="Edit notes"
                  cardFooterClickEvent={()=> openNotesModal(AppointmentData && AppointmentData.patientnotes)}
                /> */}
                {/* Notes card end */}
                {
                AppointmentData && AppointmentData.isappointmentstarted === 0 && (
                  <>
                              {/* Chief COmplaint card */}
                              <GrayCard
                                cardHeader="Chief complaint"
                                
                                cardBody={
                                  <>
                                    <Box w="100%">
                                      <Text fontSize="0.875rem">
                                      {AppointmentData && AppointmentData.chiefcomplaint? AppointmentData.chiefcomplaint.chiefcomplaint:"-"}
                                      </Text>
                                    </Box>
                                  </>
                                }
                                cardFooterText="Chief complaint"
                                cardFooterClickEvent={()=> chiefcomplaint.current.openRight()}
                              />
                              {/* Chief COmplaint card end */}
                              {/* HPI */}
                              <GrayCard 
  cardHeader="HPI"
  hasFooter={false}
  bodyStyle={{ p: "0" }}
  cardBody={
    <>
      {HPIData && HPIData.map((item, index) => {
        // Check if there is any data for the current item.type
        const hasData = AppointmentData?.historyofpresentillness?.some(
          note => note.type.toLowerCase() === item.type.toLowerCase()
        );

        return (
          <Box bg="white" key={index}>
            <ToggleTab 
              title={item.type.toUpperCase()}
              isBtnVisible={true}
              BtnText="Add"
              onClick={(event) => handleAddHPI(event, item.type)}
              isOpen={hasData} // Open by default if there is data
              body={
                <>
                  {AppointmentData && AppointmentData.historyofpresentillness && AppointmentData.historyofpresentillness
                    .filter(note => note.type.toLowerCase() === item.type.toLowerCase())
                    .map(note => (
                      <Box p="4" key={note.id}>
                        <HPIViewCard
                          id={note.id}
                          site={note.details.find(detail => detail.attribute === "Site")?.values.map(v => v.value) || []}
                          onset={note.details.find(detail => detail.attribute === "Onset")?.value || ""}
                          chronicity={note.details.find(detail => detail.attribute === "Chronicity")?.value || ""}
                          onsetQuality={note.details.find(detail => detail.attribute === "Onset Quality")?.value || ""}
                          frequency={note.details.find(detail => detail.attribute === "Frequency")?.value || ""}
                          ratingScale={note.details.find(detail => detail.attribute === "Rating Scale")?.value || ""}
                          associatedSymptoms={note.details.find(detail => detail.attribute === "Associated Symptoms")?.values.map(v => v.value) || []}
                          time={note.details.find(detail => detail.attribute === "Time")?.value || ""}
                          timeValue={note.details.find(detail => detail.attribute === "Time Value")?.value || ""}
                          deleteHPI={() => openDeleteModal(note.id)}
                          editHPI={() => editHPI(note)}
                        />
                      </Box>
                    ))}
                </>
              }
            />
          </Box>
        );
      })}
    </>
  }
/>

                          </>
                )
              }
                {/* Doctor assestment card */}
            {AppointmentData && AppointmentData.appointmentstatus === 3 && AppointmentData.doctorsassessment.length > 0 &&
              <GrayCard
              cardHeader="Doctors assessment"
                  hasFooter={false}
                cardBody={
                  <>
                    <Box>
                      <VStack align="stretch">
                        {AppointmentData &&
                          AppointmentData.doctorsassessment &&
                          AppointmentData.doctorsassessment.map((step, index) => (
                            <React.Fragment key={index}>
                              <Stack direction="column" align="flex-start" gap="0">
                                <Box display="flex" alignItems="center" gap="5px">
                                  <Circle
                                    size="12px"
                                    bg="white"
                                    border="2px solid gray"
                                    borderRadius="full"
                                    color="black"
                                  />
                                    <Text fontSize="16px" fontWeight="600">
                                      {step.diagnosis}
                                    </Text>
                                  </Box>
                                  <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                                  <Center h='35px' ml="5px" borderLeft={index < AppointmentData.doctorsassessment.length - 1 ? "1px dotted #CDCFD4" : "none"}></Center>
                                    <Text fontSize="12px" ml="7px" fontWeight="600">
                                      {step.plan}
                                    </Text>
                                  </Box>
                                  <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                                  <Center h='35px' ml="5px" borderLeft={index < AppointmentData.doctorsassessment.length - 1 ? "1px dotted #CDCFD4" : "none"}></Center>
                                    <Text fontSize="12px" ml="7px" fontWeight="600">
                                      {formatDate(step.date)}
                                    </Text>
                                  </Box>
                                  {index <
                                    AppointmentData.doctorsassessment.length - 1 && (
                                    <Box
                                      as="div"
                                      w="2px"
                                      h="5px"
                                      borderColor="black"
                                      m="0 auto"
                                    >
                                      {/* <Image w="2px" h="auto" position="relative" left="-15px" top="-15px" src="/assets/imgs/step-line.png" /> */}
                                    </Box>
                                  )}
                              </Stack>
                            </React.Fragment>
                          ))}
                      </VStack>
                    </Box>
                  </>
                }
              />
            }
               
                {/* Doctor assestment end */}
              </Box>
            </Flex>
            {/* Plan details end */}

            {/* Summary start */}
            <Flex w="25%" h="fit-content" flexDir="column">
              {AppointmentData && AppointmentData.paymenttype === "cash" ?(
                <GrayCard 
                cardHeader="Bill details"
                hasFooter={false}
                cardMinHeight = "125px"
                bodyStyle={{p:"0"}}
                cardBody={<>
                    <Box
                        w="100%"
                        display="flex"
                        flexDir="column"
                        gap="12px"
                        bg="#FFFFFF"
                        p="1.25rem"
                    >
                        <Flex textAlign="center"  w="100%" justifyContent="center">

                        <Text  fontSize="18px" fontWeight="500">
                            Paid in Cash
                        </Text>

                        </Flex>
                    </Box>
                </>}
                
               
              />
              ):(
              <GrayCard 
                cardHeader="Bill details"
                hasFooter={false}
                simpleFooter={AppointmentData && AppointmentData.appointmentstatus === 1 ? true : false}
                bodyStyle={{p:"0"}}
                cardBody={<>
                    <Box
                        w="100%"
                        display="flex"
                        flexDir="column"
                        gap="12px"
                        bg="#FFFFFF"
                        p="1.25rem"
                    >
                        <Flex w="100%" justifyContent="space-between">
                        <Text fontSize="14px" fontWeight="400">
                            Consultation fee
                        </Text>
                        <Text fontSize="14px" fontWeight="500">
                        {AppointmentData && AppointmentData.payment && AppointmentData.payment.amount? "$"+AppointmentData.payment.amount+".00": "$0"}
                        </Text>
                        </Flex>
                        <Flex w="100%" justifyContent="space-between">
                        <Text fontSize="14px" fontWeight="400">
                            Service fee
                        </Text>
                        <Text fontSize="14px" fontWeight="500">
                            $0
                        </Text>
                        </Flex>
                        <Flex w="100%" justifyContent="space-between">
                        <Text fontSize="14px" fontWeight="400">
                            Tax
                        </Text>
                        <Text fontSize="14px" fontWeight="500">
                            $0
                        </Text>
                        </Flex>
                        <Divider />
                        <Flex w="100%" justifyContent="space-between">
                        <Text fontSize="18px" fontWeight="500">
                            Total
                        </Text>
                        <Text fontSize="18px" fontWeight="600">
                        {AppointmentData && AppointmentData.payment && AppointmentData.payment.amount? "$"+AppointmentData.payment.amount+".00": "$0"}
                        </Text>
                        </Flex>
                    </Box>

                    {/* <Flex p="1.25rem" borderTop="1px solid #E6E7E9" borderBottom="1px solid #E6E7E9" justifyContent="space-between" w="100%" alignItems="center">
                        <Box>
                            <Text fontSize="14px" fontWeight="500">
                                Adnan’s card
                                </Text>
                                <Text
                                fontSize="12px"
                                fontWeight="200"
                                >
                                    • • • •   1 2 2 8
                            </Text>
                        </Box>

                        <Box>
                            <Image src="/assets/svgs/Payment-visa-icon.svg" />
                        </Box>
                    </Flex> */}
                </>}
                
                footerArea={<>

                {AppointmentData && AppointmentData.appointmentstatus === 1 &&
                    <PrimaryButton btnStyle={cancelBtnStyle} onClick={()=> cancelBooking.current.openModal()} buttonText="Cancel booking" />
                  }
               </>}
              />
              )}
              {AppointmentData && AppointmentData.appointmentstatus === 3 &&
              <PrimaryButton variant="grayBtnFull" btnStyle={{color:COLORS.PRIMARY_COLOR}} onClick={()=> navigate("/visitoverview", { state: { appointmentid: AppointmentData.appointmentid}})} buttonText="Visit overview" />
              }
              </Flex>

            {/* Summary end */}
          </Flex>
        </Box>
      </Box>
      
      {/* Mobile responsive start */}
      <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/appointments')}
          header="Appointments"
        />
        {/* Chat with doctor start */}
        <Box pos="fixed" bottom="10%" display="flex" justifyContent="center" w="100%" zIndex="99">
          <PrimaryButton
            variant="mdBtn"
            buttonText="Chat with doctor"
            onClick={()=> navigate("/chat", { state: { id: AppointmentData && AppointmentData.doctorid, name: AppointmentData && AppointmentData.doctorname}})}
            />
        </Box>
        {/* Chat with doctor end */}
        <Box w="100%" p="1.25rem"  h='calc(100vh - 190px)' overflow="hidden auto" backgroundImage={COLORS.PRO_PLAN}>
          <Box w="100%" display="flex" flexDir="column" gap="15px" mb="2.5rem">
            <Box
              w="100%"
              bg="#FFFFFF"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderRadius="0.75rem"
              p="1.5rem"
              boxShadow="md"
            >
              <Box>
                <Text fontSize="1.75rem" fontWeight="600" color="#040A17">
                  Thank you!
                </Text>
                <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                  Your booking has been confirmed
                </Text>
              </Box>
              <Box boxSize="3.25rem">
                <Image src="/assets/imgs/success-alert.png" />
              </Box>
            </Box>

            <GrayCard
              cardHeader="Booking"
              noIconCss={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              noIcon={
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text
                      fontSize="0.625rem"
                      fontWeight="600"
                      color={AppointmentData && AppointmentData.appointmentstatus === 1? "#FC8935" : "#384052"} borderColor={AppointmentData && AppointmentData.appointmentstatus === 1? "#FC89351A" : "#f5f5f6"} border="1px solid" p="0.35rem 0.5rem" borderRadius="full" bg={AppointmentData && AppointmentData.appointmentstatus === 1? "#fff3eb" : "#f5f5f6"}
                    >
                      {AppointmentData && AppointmentData.appointmentstatus === 1? "Upcoming": AppointmentData && AppointmentData.appointmentstatus === 2?"Cancelled": AppointmentData && AppointmentData.appointmentstatus === 3 ? "Completed": AppointmentData && AppointmentData.appointmentstatus === 4 ? "No show": AppointmentData && AppointmentData.appointmentstatus === 0 ? "Pending" :""}
                    </Text>
                  </Box>
                </>
              }
              cardBody={
                <>
                  <Box w="100%">
                    <Flex w="100%">
                      <Box w="50%">
                        <Text fontSize="0.75rem" color="gray.500">
                          Booking ID
                        </Text>
                        <Text fontSize="0.875rem" fontWeight="500">
                          {AppointmentData && AppointmentData.bookingid? "#"+AppointmentData.bookingid:"-"}
                        </Text>
                      </Box>
                      <Box w="50%">
                        <Text fontSize="0.75rem" color="gray.500">
                          Appointment schedule
                        </Text>
                        <Flex alignItems="center">
                          <Image
                            boxSize="0.875rem"
                            src="/assets/svgs/calendar-gray.svg"
                            alt="icon"
                            mr={2}
                          />
                          <Text fontSize="0.875rem" fontWeight="500">
                          {formatDate(AppointmentData && AppointmentData.appointmentdate)} at {AppointmentData && AppointmentData.appointmenttime}
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                    <Box mt={4}>
                      <Text fontSize="0.75rem" color="gray.500">
                        Type
                      </Text>
                      <Flex alignItems="center">
                        <Image
                          boxSize="0.875rem"
                          src={AppointmentData && AppointmentData.appointmenttype === 693?"/assets/svgs/hospital-gray.svg":"/assets/svgs/videocam-gray.svg"}
                          alt="icon"
                          mr={2}
                        />
                        <Text fontSize="0.875rem" fontWeight="500">
                          {AppointmentData && AppointmentData.appointmenttype === 693? "Clinic Visit": "Video Visit"}
                        </Text>
                      </Flex>
                    </Box>
                  </Box>
                </>
              }
             // hasFooter={AppointmentData && AppointmentData.isappointmentstarted == 1 && AppointmentData.appointmentstatus == 0 ? false : true}
              cardFooterText={AppointmentData && AppointmentData.appointmentstatus <= 1 && AppointmentData && AppointmentData.isappointmentstarted ===0?"Reschedule":"Book again"}
              cardFooterClickEvent={rescheduleappointment}
             // disableBtn={AppointmentData && AppointmentData.isdoctoractive == 0 ? true : false}
               
            />

            <GrayCard
              cardHeader="your doctor"
              cardBody={
                <>
                  <Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      w="100%"
                      alignItems="flex-start"
                    >
                      <Box display="flex" gap="0.75rem">
                        <Box
                          boxSize="3.5rem"
                          borderRadius="full"
                          overflow="hidden"
                        >
                          <Image
                            w="100%"
                            h="100%"
                            src={AppointmentData && AppointmentData.doctorimage? AppointmentData.imagepath+AppointmentData.doctorimage:"/assets/imgs/no-image.png"}
                          />
                        </Box>
                        <Box>
                          <Text
                            fontSize="0.625rem"
                            fontWeight="500"
                            color="#384052"
                          >
                            {AppointmentData && AppointmentData.specality}
                          </Text>
                          <Text
                            fontSize="0.875rem"
                            fontWeight="600"
                            color="#061027"
                          >
                            
                            {AppointmentData && AppointmentData.doctorname}
                          </Text>
                          <Text
                            fontSize="0.75rem"
                            fontWeight="400"
                            color="#384052"
                          >
                            {AppointmentData && AppointmentData.qualification}
                          </Text>
                        </Box>
                      </Box>
                      {/* <Box display="inline-flex" gap="0.25rem" border='1px solid #E6E7E9' borderRadius="full" p="0.15rem 0.5rem">
                          <Image src="/assets/svgs/Stars.svg" />
                          <Text fontSize="0.75rem" fontWeight="500" color="#384052">{AppointmentData && AppointmentData.ratings}</Text>
                        </Box> */}
                    </Box>

                    <Divider my="0.75rem" />
                    <Box display="flex" flexDir="column">
                      <Box display="flex" alignItems="center" gap="0.5rem">
                        <Image
                          w="12px"
                          h="13px"
                          src="/assets/svgs/location-blue.svg"
                        />
                        <Text fontSize="0.75rem" fontWeight="500">
                          {AppointmentData && AppointmentData.doctoraddress? AppointmentData.doctoraddress.address:"-"}
                        </Text>
                      </Box>
                      <Box display="flex" alignItems="center" gap="0.5rem">
                        <Image
                          w="13px"
                          h="10px"
                          src="/assets/svgs/work-blue.svg"
                        />
                        <Text fontSize="0.75rem" fontWeight="500">
                        {AppointmentData && AppointmentData.yearsofexperience > 1? AppointmentData.yearsofexperience+ " years of experience":"-"}
                        </Text>
                      </Box>
                      <Box display="flex" alignItems="center" gap="0.5rem">
                        <Image
                          w="13px"
                          h="10px"
                          src="/assets/svgs/hospital.svg"
                        />
                        <Text fontSize="0.75rem" fontWeight="500">
                        {AppointmentData && AppointmentData.hospitalname}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </>
              }
              cardFooterText="View profile"
              cardFooterClickEvent={viewdoctorprofile}
            />
            
            {/* Notes card */}
            {/* <GrayCard
              cardHeader="Notes"
              cardBody={
                <>
                  <Box w="100%">
                    <Text fontSize="0.875rem">
                    {AppointmentData && AppointmentData.patientnotes? AppointmentData.patientnotes:"-"}
                    </Text>
                  </Box>
                </>
              }
              cardFooterText="Edit notes"
              cardFooterClickEvent={()=> openNotesModal(AppointmentData && AppointmentData.patientnotes)}
              /> */}
            {/* Notes card end */}

            {AppointmentData && AppointmentData.isappointmentstarted === 0 && (
              <>
                {/* Chief COmplaint card */}
                <GrayCard
                  cardHeader="Chief complaint"
                  cardBody={
                    <>
                      <Box w="100%">
                        <Text fontSize="0.875rem">
                        {AppointmentData && AppointmentData.chiefcomplaint? AppointmentData.chiefcomplaint.chiefcomplaint:"-"}
                        </Text>
                      </Box>
                    </>
                  }
                  cardFooterText="Chief complaint"
                  cardFooterClickEvent={()=> chiefcomplaint.current.openRight()}
                />
                {/* Chief COmplaint card end */}
                {/* HPI */}
                <GrayCard 
                  cardHeader="HPI"
                  hasFooter={false}
                  bodyStyle={{ p: "0" }}
                  cardBody={
                    <>
                      {HPIData && HPIData.map((item, index) => {
                        // Check if there is any data for the current item.type
                        const hasData = AppointmentData?.historyofpresentillness?.some(
                          note => note.type.toLowerCase() === item.type.toLowerCase()
                        );

                        return (
                          <Box bg="white" key={index} borderRadius="12px">
                            <ToggleTab 
                              title={item.type.toUpperCase()}
                              isBtnVisible={true}
                              BtnText="Add"
                              onClick={(event) => handleAddHPI(event, item.type)}
                              isOpen={hasData} // Open by default if there is data
                              body={
                                <>
                                  {AppointmentData && AppointmentData.historyofpresentillness && AppointmentData.historyofpresentillness
                                    .filter(note => note.type.toLowerCase() === item.type.toLowerCase())
                                    .map(note => (
                                      <Box p="2" key={note.id}>
                                        <HPIViewCard
                                          id={note.id}
                                          site={note.details.find(detail => detail.attribute === "Site")?.values.map(v => v.value) || []}
                                          onset={note.details.find(detail => detail.attribute === "Onset")?.value || ""}
                                          chronicity={note.details.find(detail => detail.attribute === "Chronicity")?.value || ""}
                                          onsetQuality={note.details.find(detail => detail.attribute === "Onset Quality")?.value || ""}
                                          frequency={note.details.find(detail => detail.attribute === "Frequency")?.value || ""}
                                          ratingScale={note.details.find(detail => detail.attribute === "Rating Scale")?.value || ""}
                                          associatedSymptoms={note.details.find(detail => detail.attribute === "Associated Symptoms")?.values.map(v => v.value) || []}
                                          time={note.details.find(detail => detail.attribute === "Time")?.value || ""}
                                          timeValue={note.details.find(detail => detail.attribute === "Time Value")?.value || ""}
                                          deleteHPI={() => openDeleteModal(note.id)}
                                          editHPI={() => editHPI(note)}
                                        />
                                      </Box>
                                    ))}
                                </>
                              }
                            />
                          </Box>
                        );
                      })}
                    </>
                  }
                />
              </>
            )
          }
          {/* Doctor assestment card */}
          {AppointmentData && AppointmentData.appointmentstatus === 3 && AppointmentData.doctorsassessment.length > 0 &&
            <GrayCard
              cardHeader="Doctors assessment"
              hasFooter={false}
              cardBody={
                <>
                  <Box>
                    <VStack align="stretch">
                      {AppointmentData &&
                        AppointmentData.doctorsassessment &&
                        AppointmentData.doctorsassessment.map((step, index) => (
                          <React.Fragment key={index}>
                            <Stack direction="column" align="flex-start" gap="0">
                              <Box display="flex" alignItems="center" gap="5px">
                                <Circle
                                  size="12px"
                                  bg="white"
                                  border="2px solid gray"
                                  borderRadius="full"
                                  color="black"
                                />
                                  <Text fontSize="16px" fontWeight="600">
                                    {step.diagnosis}
                                  </Text>
                                </Box>
                                <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                                <Center h='35px' ml="5px" borderLeft={index < AppointmentData.doctorsassessment.length - 1 ? "1px dotted #CDCFD4" : "none"}></Center>
                                  <Text fontSize="12px" ml="7px" fontWeight="600">
                                    {step.plan}
                                  </Text>
                                </Box>
                                <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                                <Center h='35px' ml="5px" borderLeft={index < AppointmentData.doctorsassessment.length - 1 ? "1px dotted #CDCFD4" : "none"}></Center>
                                  <Text fontSize="12px" ml="7px" fontWeight="600">
                                    {formatDate(step.date)}
                                  </Text>
                                </Box>
                                {index <
                                  AppointmentData.doctorsassessment.length - 1 && (
                                  <Box
                                    as="div"
                                    w="2px"
                                    h="5px"
                                    borderColor="black"
                                    m="0 auto"
                                  >
                                    {/* <Image w="2px" h="auto" position="relative" left="-15px" top="-15px" src="/assets/imgs/step-line.png" /> */}
                                  </Box>
                                )}
                            </Stack>
                          </React.Fragment>
                        ))}
                    </VStack>
                  </Box>
                </>
              }
            />} 
          {/* Doctor assestment end */}
          
          {/* Fees breakdown start */}
          <Flex w="100%" mb={isIoS ? "4rem" : ""} h="fit-content" flexDir="column">
              {AppointmentData && AppointmentData.paymenttype === "cash" ?(
                <GrayCard 
                cardHeader="Bill details"
                hasFooter={false}
                cardMinHeight = "125px"
                bodyStyle={{p:"0"}}
                cardBody={<>
                    <Box
                        w="100%"
                        display="flex"
                        flexDir="column"
                        gap="12px"
                        bg="#FFFFFF"
                        p="1.25rem"
                    >
                        <Flex textAlign="center"  w="100%" justifyContent="center">

                        <Text  fontSize="18px" fontWeight="500">
                            Paid in Cash
                        </Text>

                        </Flex>
                    </Box>
                </>}
                
               
              />
              ):(
              <GrayCard 
                cardHeader="Bill details"
                hasFooter={false}
                simpleFooter={AppointmentData && AppointmentData.appointmentstatus === 1 ? true : false}
                bodyStyle={{p:"0"}}
                cardBody={<>
                    <Box
                        w="100%"
                        display="flex"
                        flexDir="column"
                        gap="12px"
                        bg="#FFFFFF"
                        p="1.25rem"
                    >
                        <Flex w="100%" justifyContent="space-between">
                        <Text fontSize="14px" fontWeight="400">
                            Consultation fee
                        </Text>
                        <Text fontSize="14px" fontWeight="500">
                        {AppointmentData && AppointmentData.payment && AppointmentData.payment.amount? "$"+AppointmentData.payment.amount+".00": "$0"}
                        </Text>
                        </Flex>
                        <Flex w="100%" justifyContent="space-between">
                        <Text fontSize="14px" fontWeight="400">
                            Service fee
                        </Text>
                        <Text fontSize="14px" fontWeight="500">
                            $0
                        </Text>
                        </Flex>
                        <Flex w="100%" justifyContent="space-between">
                        <Text fontSize="14px" fontWeight="400">
                            Tax
                        </Text>
                        <Text fontSize="14px" fontWeight="500">
                            $0
                        </Text>
                        </Flex>
                        <Divider />
                        <Flex w="100%" justifyContent="space-between">
                        <Text fontSize="18px" fontWeight="500">
                            Total
                        </Text>
                        <Text fontSize="18px" fontWeight="600">
                        {AppointmentData && AppointmentData.payment && AppointmentData.payment.amount? "$"+AppointmentData.payment.amount+".00": "$0"}
                        </Text>
                        </Flex>
                    </Box>

                    {/* <Flex p="1.25rem" borderTop="1px solid #E6E7E9" borderBottom="1px solid #E6E7E9" justifyContent="space-between" w="100%" alignItems="center">
                        <Box>
                            <Text fontSize="14px" fontWeight="500">
                                Adnan’s card
                                </Text>
                                <Text
                                fontSize="12px"
                                fontWeight="200"
                                >
                                    • • • •   1 2 2 8
                            </Text>
                        </Box>


                        <Box>
                            <Image src="/assets/svgs/Payment-visa-icon.svg" />
                        </Box>
                    </Flex> */}
                </>}
                
                footerArea={<>

                {AppointmentData && AppointmentData.appointmentstatus === 1 &&
                    <PrimaryButton btnStyle={cancelBtnStyle} onClick={()=> cancelBooking.current.openModal()} buttonText="Cancel booking" />
                  }
               </>}
              />
              )}
              
              {/* <PrimaryButton variant="grayBtnFull" btnStyle={{color:COLORS.PRIMARY_COLOR}} onClick={()=> navigate("/visitoverview", { state: { appointmentid: AppointmentData.appointmentid}})} buttonText="Visit overview" /> */}
            </Flex>
          {AppointmentData && AppointmentData.appointmentstatus === 3 &&
            <PrimaryButton variant="grayBtnFull" btnStyle={{color:COLORS.PRIMARY_COLOR, mb: "3em"}} onClick={()=> navigate("/myhealth", { state: { appointmentid: AppointmentData.appointmentid}})} buttonText="Visit overview" />

          }
          {/* Fees breakdown end */}
          </Box>
        </Box>
      </Box>

      <ModalRight 
        ref={reschedule}
        header={<>
            <Text as='h3' fontSize="1.5rem" fontWeight="600" color="#050D1F">Reschedule</Text>
            <Text fontSize="0.75rem" fontWeight="400" color="#050D1F">Select a timeslot</Text>
        </>}
        body={<>
          <SlotBooking date={formatDate(AppointmentData && AppointmentData.appointmentdate)} 
          doctorid={AppointmentData && AppointmentData.doctorid} selectedTime={AppointmentData && AppointmentData.appointmenttime} 
          type={"reschedule"} appointmentid={AppointmentData && AppointmentData.appointmentid}  
          textAreaDefaultValue=
          {AppointmentData && AppointmentData.patientnotes? AppointmentData.patientnotes:"-"} onSubmit={handleReScheduleSubmit}/>
        </>}
        hasFooter={false}
      />
      <ModalRight
        ref={editNotes}
        header="Edit notes"
        
        body={<>
            <Textarea placeholder='Edit notes'  
              h="200px"
            sx={{_hover: {outline: "none", }}}
            defaultValue={AppointmentData && AppointmentData.patientnotes && AppointmentData.patientnotes}
            onChange={(e)=> setNotesUpdated(e.target.value)}>
            </Textarea>
        </>}
        footerActions={<>
            <PrimaryButton buttonText="Update notes" onClick={updatenotes}/>
        </>}
      />
      <ModalRight
        ref={chiefcomplaint}
        header="Edit chief complaint"
        body={<>
            <Textarea placeholder='Edit chief complaint' 
            h="200px"
            sx={{_hover: {outline: "none", }}}
            defaultValue={AppointmentData && AppointmentData.chiefcomplaint && AppointmentData.chiefcomplaint.chiefcomplaint}
            onChange={(e)=> setChiefComplaintUpdated(e.target.value)}>
            </Textarea>
        </>}
        footerActions={<>
            <PrimaryButton buttonText="Update chief complaint" onClick={updatechiefcomplaint}/>
        </>}
      />
      <ModalRight 
        ref={preferDate}
        header="Preferred Date"
        modalWidth="22.5rem"
        body={<>
            <Box w="100%">
                <Calendar
                    date={date}
                    className="preferDate"
                    onChange={handleDateChange}
                    showMonthAndYearPickers={false}
                    renderStaticRangeLabel={false}
                />
            </Box>
        </>}
        footerActions={<>
            <PrimaryButton buttonText="Confirm" />
        </>}
      />

      <DynamicModal 
        ref={cancelBooking}
        modalHeader="Cancel Booking"
        modalBody={<>
            <Text fontSize="0.75rem" fontWeight="400">Are you sure you want to cancel your appointment for <strong>{formatDate(AppointmentData && AppointmentData.appointmentdate)} at {AppointmentData && AppointmentData.appointmenttime}</strong></Text>
        </>}
        modalFooterBtn={<>
            <PrimaryButton onClick={cancelappointment} buttonText="Cancel booking" variant="deleteBtn" />
        </>}
      />
      <ModalRight
        ref={viewDocProfile}
        isBg={true}
        modalWidth="480px"
        customHeader={false}
        bodyStyle={{ p: "0", overflow: "hidden" }}
        body={
          <>
          <Box bg="#061027" p="24px">
            <Box display="flex" alignItems="center" gap="10px">
              <Box>
                <Image
                  boxSize="80px"
                  borderRadius="full"
                  src={doctordata && doctordata.doctorimage? doctordata.imagepath+doctordata.doctorimage:"/assets/imgs/no-image.png"}
                />
              </Box>
              <Box display="flex" flexDir="column">
                <Text fontSize="12px" fontWeight="500" w="200px" className="textOverflow" color="#E6E7E9" title={doctordata && doctordata.specality}>
                {doctordata && doctordata.specality}
                </Text>
                <Text fontSize="20px" fontWeight="600" color="white">
                {doctordata && doctordata.fullname}
                </Text>
                <Text fontSize="14px" fontWeight="400" color="#E6E7E9">
                {doctordata && doctordata.qualification}
                </Text>
              </Box>
            </Box>
            <Box
              bg="#0B1F4F80"
              mt="15px"
              borderRadius="full"
              p="8px 16px"
              display="flex"
              justifyContent="space-around"
            >
              {/* <Box display="flex" alignItems="center" justifyContent="center">
                <Image w="18px" src="/assets/imgs/stars-doc.png" mr="5px" />
                <Text color="white" fontSize="14px" fontWeight="500">
                {doctordata && doctordata.ratings? doctordata.ratings:"-"}
                </Text>
              </Box> */}
              {/* <Box as="span" border="1px solid #0B1F4F"></Box> */}
              <Box display="flex" alignItems="center" justifyContent="center">
                <Image w="18px" src="/assets/imgs/payments.png" mr="5px" />
                <Text color="white" fontSize="14px" fontWeight="500">
                {doctordata && doctordata.fee? "$"+doctordata.fee:"-"}
                </Text>
              </Box>
              <Box as="span" border="1px solid #0B1F4F"></Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Image w="18px" src="/assets/imgs/work.png" mr="5px" />
                <Text color="white" fontSize="14px" fontWeight="500">
                {doctordata && doctordata.yearsofexperience? doctordata.yearsofexperience+" +yrs":"-"}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box bg="#fbfbfc" overflow="hidden auto" h="calc(100% - 190px)">
            <VStack>
              <Flex flexDir="column" w="100%">

                    <Box w="100%" p="1.5rem">
                      <Flex flexDir="column" gap="1rem">
                        <GrayCard 
                           cardHeader="QUALIFICATION"
                           cardHeaderIcon="/assets/svgs/qualification.svg"
                           cardBody={<>
                              {doctordata && doctordata.qualification}
                            </>}
                           hasFooter={false}
                        />

                        <GrayCard 
                           cardHeader="services & procedures"
                           cardHeaderIcon="/assets/svgs/vaccines-and-dose.svg"
                           bodyStyle={{py: "0"}}
                           cardBody={<>
                             {doctordata && doctordata.services && doctordata.services.length > 0 ? (
                                    doctordata.services.map((step, index) => (
                                      <Box
                                        key={index}
                                        display="flex"
                                        gap="0.75rem"
                                        alignItems="center"
                                        py="0.75rem"
                                        borderBottom="1px solid #E6E7E9"
                                      >
                                        <Image opacity="0.75" src="/assets/imgs/check-black.png" />
                                        <Text fontSize="0.875rem" fontWeight="600">
                                          {step.servicename}
                                        </Text>
                                      </Box>
                                    ))
                                  ) : (
                                    <Box>No data</Box>
                                  )}
                           </>}
                            hasFooter={false}
                           cardFooterText="View more"
                        />

                        <GrayCard 
                           cardHeader="Hospital details"
                           cardHeaderIcon="/assets/svgs/hospital-gray.svg"
                           cardBody={<>
                            {doctordata && doctordata.addresses && doctordata.addresses.length > 0 ? (
                                doctordata.addresses.map((step, index) => (
                                  <React.Fragment key={index}>
                                    <Box display="flex" justifyContent="space-between">
                                      <Box>
                                        <Text fontSize="0.875rem" fontWeight="600">
                                          {step.hospitalname? step.hospitalname:"-"}
                                        </Text>
                                        <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                                          {/* {step.address? step.address:"-"} */}
                                          {step.address1 || step.address2 || step.city || step.state || step.pincode || step.country ? (
                                 <>
                                   {step.address1 ? `${step.address1} ` : ''}
                                   {step.address2 ? `${step.address2}, ` : ''}
                                   {step.city ? `${step.city} ` : ''}
                                   {step.state ? `${step.state} ` : ''}
                                   {step.pincode ? `${step.pincode} ` : ''}
                                   {step.country ? `${step.country} ` : ''}
                                 </>
                               ) : '-' }
                                        </Text>
                                      </Box>
                          
                                      <Box display="flex" justifyContent="space-between" gap="0.5rem">
                                        <Box
                                          as="button"
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="center"
                                          p="0.5rem"
                                          boxSize="2.25rem"
                                          border="1px solid #E6E7E9"
                                          borderRadius="full"
                                        >
                                          <Image src="/assets/svgs/call-gray.svg" />
                                        </Box>
                                        <Box
                                          as="button"
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="center"
                                          p="0.5rem"
                                          boxSize="2.25rem"
                                          border="1px solid #E6E7E9"
                                          borderRadius="full"
                                          onClick={(e)=>navigatemap(step.address1, step.address2, step.city, step.country)}
                                        >
                                          <Image src="/assets/svgs/location-gray.svg" />
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Divider my="0.75rem" />
                                    <Box display="flex" gap="0.5rem">
                                      <Image src="/assets/svgs/telephone-blue.svg" />
                                      <Text fontSize="0.75rem" fontWeight="500">
                                        {step.mobileno?step.mobileno:"-"}
                                      </Text>
                                    </Box>
                                    <Box display="flex" gap="0.5rem"
                         as="a" href={step.websiteurl?step.websiteurl:""} target="/"
                         cursor="pointer">
                          <Image src="/assets/svgs/website-blue.svg" />
                          <Text fontSize="0.75rem" fontWeight="500"   _hover={{  textDecoration: "underline" }}>
                            {step.websiteurl?step.websiteurl:"-"}
                          </Text>
                        </Box>
                                  </React.Fragment>
                                ))
                              ) : (
                                <Box>No data</Box>
                              )}
                           </>}
                           hasFooter={false}
                        />
                        <Text fontSize="0.75rem" fontWeight="600" color="#6A707D"><strong>Refund Policy:</strong>  In the rare event of dissatisfaction with a consultation, please contact our support team within 48 hours for a refund.</Text>
                      </Flex>
                    </Box>

              </Flex>

            </VStack>
          </Box>
        </>
        } 
      />
     <ModalRight 
        ref={hpiModal}
        modalWidth={{base: "100%", lg: "calc(100vw - 30%)"}}
        header="Add HPI"
        bodyStyle={{ p: {base: "0.75rem", lg: "0.75rem 1.5rem"}, overflow: "hidden auto" }}
        body={<>
          <AddEditHPI
           patientId={patientid}
           doctorId={AppointmentData && AppointmentData.doctorid}
           appointmentId={appointmentid}
           type={addHPIType}
           siteOptions={siteOptions}
           onsetOptions={onsetOptions}
           chronicityOptions={chronicityOptions}
           onsetQualityOptions={onsetQualityOptions}
           frequencyOptions={frequencyOptions}
           associatedSymptomsOptions={associatedSymptomsOptions}
           fetchDoctorNotes={fetchAppointmentDetails}          
           modalRef={hpiModal}
           editHPIData={editHPIData}
           isEditMode={isEditMode}
          />
        </>}
        hasFooter={false}
        // footerActions={<>
        //   <Box w="50%">
        //       <PrimaryButton buttonText="Save" />
        //   </Box>
        // </>}
      />
      <DynamicModal
        ref={dynamicModalDelteHPI}
        modalHeader={`Delete HPI?`}
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete?
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deleteSelectedHPI}
            />
          </>
        }
      />
    </>
  );
};

export default AppointmentDetails;
