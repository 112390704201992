import React, { useState, useEffect } from "react";
import { saveToken,savePatientDetails,saveUsername,saveLoginID, savePatientImage, savePatientdob, savePatientgender } from "../../auth";
import {
  FetchLogin,
  FetchSocailLogin,
  VerifyUser,
  FetchMobileLogin,
  FetchGuestPatientID,
} from "../../middleware/services/login/index";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";

import {
  Box,
  Center,
  VStack,
  Image,
  Heading,
  Text,
  InputGroup,
  Button,
  Divider,
  Icon,
  InputRightElement,
  Flex,
} from "@chakra-ui/react";
import { FaGoogle, FaFacebook } from "react-icons/fa";
import {
  PrimaryInputDropdown,
  PrimaryButton,
  PrimaryButtonIcon,
  PasswordInput,
  PrimaryInput,
  Toaster,
  DropdownInput,
} from "../../components";
import useLoader from '../../components/loader/loaderstates';
import Loader from "../../components/loader/loader";
import DatePicker from "../../components/date-picker";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  CreatePaymentIntent,
  SavePaymentInitialCall,
} from "../../middleware/services/payment/payment";
import CheckoutForm from "./checkout-form";
import { CONFIG } from '../../appconfig/appconfig';
import { FetchDoctorDetailsGuest } from "../../middleware/services/appointments";



function LoginIndex() {
  const {isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();
  const [isError, setIsError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isPasswordButtonDisabled, setIsPasswordButtonDisabled] =
    useState(true);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [mobile, setMobileNo] = useState("");
  const [password, setPassword] = useState("");
  const [redirectToPassword, setRedirectTopassword] = useState(false);
  const [redirectToMobilePassword, setRedirectToMobilepassword] = useState(false);
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [isMobileLoginClicked, setIsMobileLoginClicked] = useState(false);
  const [isEmailClicked, setIsEmailLoginClicked] = useState(true);
  const [showBackButton, setshowBackButton] = useState(false);
  const [Profilepicture, setProfilepicture] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  const [ updateCountryId , setUpdateCountryId] = useState(false)
  const CLIENT_ID = '632146016434-6ov5q3667vjniv5ig551o3qdd17l2vm7.apps.googleusercontent.com';
  const [isFBInitialized, setIsFBInitialized] = useState(false);
  var redirect = useNavigate();
  const [guestfirstname, setFirstName] = useState("");
  const [guestlastname, setLastName] = useState("");
  const [guestemail, setGuestEmail] = useState("");
  const [guestmobile, setGuestMobile] = useState("");
  const [guestgender, setGuestGender] = useState("2");
  const [guestdob, setGuestDOB] = useState("");
  const [guestcountrycode, setSelectedGuestCountry] = useState("+1");
  const [showhideappointmentview, setShowAppointmentDetails] = useState(false);
  const [IsGuestLogin, setShowGuestLogin] = useState(false);
  const [guestloginid, setGuestLoginID] = useState("");
  const [appointmentparams, setAppointmentParams] = useState("");

  var [doctordata, setDoctorDetailsData] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);

  // Functions for handling form inputs and button clicks:
useEffect(()=>{
  const storedParams = JSON.parse(localStorage.getItem('doctorProfileParams'));
  if (storedParams) {
    setAppointmentParams(storedParams);
    setShowGuestLogin(true);
    // Navigate to the doctor profile with the parameters
    //return <navigate to={`/doctorprofile?id=${doctorId}&name=${name}&date=${date}&time=${time}`} />;
  }else{
    setShowGuestLogin(false);
  }
  localStorage.removeItem("personalDetails")
  //localStorage.clear();
},[])
  const handleEmailChange = (e) => {
    const sanitizedValue = e.target.value.replace(/[^\x00-\x7F]/g, '')
    setIsError(false);
      setEmail(sanitizedValue.slice(0, 50));

    const newEmail = e.target.value.replace(/[^\x00-\x7F]/g, '');
    setIsButtonDisabled(newEmail.trim() === "");
    if (newEmail.trim() === "") {
      setIsError(false);
    }
  };

  useEffect(() => {
    const checkFBInit = setInterval(() => {
      if (window.FB) {
        setIsFBInitialized(true);
        clearInterval(checkFBInit);
      }
    }, 100);
  }, []);
  useEffect(()=>{
    var countrycode = localStorage.getItem("countryid")
    if (countrycode == "+1") {
      setCountrycode("+1")
      setCountryid("229")
    } else if (countrycode == "+93") {
      setCountrycode("+93")
      setCountryid("1")
    } else if (countrycode == "+91") {
      setCountrycode("+91")
      setCountryid("95")
    }
  },[updateCountryId])
  const [countryid, setCountryid] = useState('')
  const [countrycode, setCountrycode] = useState('')
  const handleMobileChange = (e) => {
    // var countrycode = localStorage.getItem("countryid")
    // if (countrycode == "+1") {
    //   setCountrycode("+1")
    //   setCountryid("229")
    // } else if (countrycode == "+93") {
    //   setCountrycode("+93")
    //   setCountryid("1")
    // } else if (countrycode == "+91") {
    //   setCountrycode("+91")
    //   setCountryid("95")
    // }
    var countryId = localStorage.getItem("countryid")
    var countryCode = localStorage.getItem("countrycode")
    
    setCountrycode(countryCode)
    setCountryid(countryId)

    setIsError(false);
    const inputText = e.target.value;
    const maxLength = 10;
  
    // Limit the input to a maximum length of 10 characters
    if (inputText.length <= maxLength) {
      setMobileNo(inputText);
    }

    setMobileNo(e.target.value);
    setMobileNo(e.target.value.replace(/\D/g, "").slice(0, 10)); // Remove non-digit characters
    const newMobile = e.target.value;
    setIsButtonDisabled(newMobile.trim() === "");
    if (newMobile.trim() === "") {
      setIsError(false);
    }
  };
  const switchtomobile =() =>{
    setIsMobileLoginClicked(true);
    setIsError(false);
  }
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    const newPassword = e.target.value;
    setIsPasswordButtonDisabled(newPassword.trim() === "");
    if (newPassword.trim() === "") {
      setIsError(false);
    }
  };
  //Verify User with Email
  const handleSubmit = async () => {
    showLoader();
    if (email.trim === "") {
      showErrorToast("Email cannot be empty");
      setIsError(true);
      dismissLoader();
      return;
    } else if (!emailRegex.test(email)) {
      showErrorToast("Please Enter Valid Email Address");
      setIsError(true);
      dismissLoader();
      return;
    } else {
      try {
        const response = await VerifyUser({
          email,
          type: "patient",
        });
       // console.log(response , " resp - ")
        if (response.output.result === "0") {
          showLoader();
          localStorage.setItem("NewUserEmail", email)
          setRedirectTopassword(false);
          showErrorToast("Uh-oh! The email you have entered does not exist.");
          setIsError(true);
          navigate("/signup")
          dismissLoader();
        } else {
          // if(response.output.patientimage){
          //   setProfilepicture(response.output.imagepath+response.output.patientimage);
          // }
          if (response && response.output.patientimage) {
            let img = response.output.patientimage;
            if (img.startsWith('https')) {
              setProfilepicture(img);
            } else if (response.output.imagepath) {
              setProfilepicture(response.output.imagepath + response.output.patientimage);
            }
          }
          setRedirectTopassword(true);
          setIsEmailLoginClicked(false);
          setshowBackButton(true)
          setIsError(false);
          dismissLoader();
        }
      } catch (error) {
        console.error("failed:", error.response);
      }
    }
    dismissLoader();
  };
  //Verify User with Mobile
  const handleMobileSubmit = async () => {
    showLoader();
    setUpdateCountryId(!updateCountryId)
    if (mobile === "") {
      showErrorToast("Mobile number cannot be empty");
      dismissLoader();
      return;
    } else if (mobile.length < 10) {
      showErrorToast("Please Enter a Valid Mobile Number.");
      dismissLoader();
      return;
    } else {
      try {
        showLoader();
        var countryCode = localStorage.getItem("countrycode")
        var countryId = localStorage.getItem("countryid")
        

        const response = await VerifyUser({
          mobileno: mobile,
          type: "patient",
          "countrycode":countryCode,
          "countryid":countryId
        });
        if (response.output.result === "0") {
          localStorage.setItem("NewUserMobile", mobile)
          setRedirectToMobilepassword(false);
          
          showErrorToast("Uh-oh! The mobile number you have entered does not exist");
          navigate("/signup")
          dismissLoader();
        } else {
          setshowBackButton(true)
          setIsEmailLoginClicked(false)
          setRedirectToMobilepassword(true);
          
          localStorage.setItem("countryid", countryid)
          localStorage.setItem("countrycode", countrycode);
          dismissLoader();
        }
      } catch (error) {
        console.error("failed:", error);
      }
      dismissLoader();
    }
  };
  //Submit Password
  const handleLoginSubmit = async (type) => {
    // if (!passwordRegex.test(password)) {
    //   showErrorToast('Password must contain at least one letter, one number, and be at least eight characters long');
    //   return;
    // }
    if (password.trim() === "") {
      showErrorToast("Password cannot be empty");
    } else {
      try {
        if (type === "email") {
          const response = await FetchLogin({
            email,
            password,
            "platformtype":"web"
          });
          console.log(response)
          if (response.output.result == "failure") {
            setRedirectToHome(false);
            showErrorToast("Password is incorrect");
          } else {
            // if (response.output.data.firstname) {
            //   saveUsername(response.output.data.firstname);
            // }
            // Save the token
            if (response.output.token) {
              saveToken(response.output.token);
            }
            if(response.output.data){
              savePatientDetails(response.output.data)
            }
            // if (response.output.data.id) {
            //   saveLoginID(response.output.data.id);
            // }
            // if (response.output.data.patientimage) {
            //   const patientimage = response.output.data.imagepath+response.output.data.patientimage;
            //   savePatientImage(patientimage);
            // }
            // if (response.output.data.gender) {
            //   const gender = response.output.data.gender;
            //   savePatientgender(gender);
            // }
            // if (response.output.data.dob) {
            //   const dob = response.output.data.dob;
            //   savePatientdob(dob);
            // }
            // Set the state to redirect to the home page
            setRedirectToHome(true);
            if(appointmentparams != ""){
              navigate(`/doctorprofile?id=${appointmentparams.id}&name=${appointmentparams.name}&date=${appointmentparams.date}&time=${appointmentparams.time}`);
            }else{
              navigate("/home");
            }
           
          }
        } else {
          const response = await FetchMobileLogin({
            mobile,
            password,
            countryid,
            "platformtype":"web"
          });
          if (response.output.result == "failure") {
            setRedirectToHome(false);
            showErrorToast("Password is incorrect");
          } else {
            // if (response.output.data.firstname) {
            //   saveUsername(response.output.data.firstname);
            // }
            // Save the token
            if (response.output.token) {
              saveToken(response.output.token);
            }
            if(response.output.data){
              savePatientDetails(response.output.data)
            }
            // if (response.output.data.id) {
            //   saveLoginID(response.output.data.id);
            // }
            // if (response.output.data.patientimage) {
            //   const patientimage = response.output.data.imagepath+response.output.data.patientimage;
            //   savePatientImage(patientimage);
            // }
            // if (response.output.data.gender) {
            //   const gender = response.output.data.gender;
            //   savePatientgender(gender);
            // }
            // if (response.output.data.dob) {
            //   const dob = response.output.data.dob;
            //   savePatientdob(dob);
            // }
            // Set the state to redirect to the home page
            setRedirectToHome(true);
            navigate("/myhealth");
          }
        }

        //console.log(response, "loginres");
      } catch (error) {
        console.error("Login failed:", error.response.data);
      }
    }
  };
  // const countrycode = document.getElementById('countrycode').value;
  // alert(countrycode)
  // Enter Click Submit Email
  const handleEmailKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };
  // Enter Click Submit Mobile
  const handleMobileKeyPress = (e) => {
    if (e.key === "Enter") {
      showLoader();
      handleMobileSubmit();
    }
  };
  // Enter Click Email Password Submit
  const handleEmailpassKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLoginSubmit("email");
    }
  };
  // Enter Click mobile Password Submit
  const handleMobilepassKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLoginSubmit("mobile");
    }
  };
  const handlebackbutton =()=>{
    setIsEmailLoginClicked(true);
    setRedirectTopassword(false);
    setRedirectToMobilepassword(false);
  }
  const redirectToForgotPassword =()=>{
    localStorage.setItem( "Email" ,email )
    redirect("/forgotpassword")
  }
  const redirectToForgotPasswordMobile =()=>{
    localStorage.setItem( "mobile" ,mobile )
    redirect("/forgotpassword" , { state: { countrycode , countryid } })
  }
  const handleEmailLogin = ()=>{
    setEmail("")
    setMobileNo("")
     setIsMobileLoginClicked(false)
  }
  const onSuccessGoogle = (response) => {
    console.log('[Login Success] currentUser:', response.profileObj);
    // Handle login success (e.g., store user information in state or context)
  };

  const onFailureGoogle = (response) => {
    console.log('[Login Failed] response:', response);
    // Handle login failure
  };
  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      console.log('Login Success:', response);

      const { code } = response;
      
      try {
        // Exchange the authorization code for tokens
        const tokenResponse = await axios.post('https://oauth2.googleapis.com/token', {
          code: code,
          client_id: CLIENT_ID,
          client_secret: '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',
          redirect_uri:  window.location.origin, // e.g., http://localhost:3000
          grant_type: 'authorization_code',
        });

        const { id_token, access_token } = tokenResponse.data;
 

        // Get user information using the access token
        const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            'Authorization': `Bearer ${access_token}`,
          },
        });

        const userInfo = userInfoResponse.data;
        console.log('User Info:', userInfo);

        // Store the user information as needed
        const user = {
          type: 'google',
          idtoken: id_token,
          email: userInfo.email,
          
          // issue with the firstname and lastname being swapped with each other , so sending it reverse.
          // firstname: userInfo.given_name,
          // lastname: userInfo.family_name,
          firstname: userInfo.family_name,
          lastname:userInfo.given_name ,

          id: userInfo.sub,
          name: userInfo.name,
          photo: userInfo.picture,
        };

        // Save the user information in your application's state or context
        
        const response = await FetchSocailLogin(user);
        console.log(response)
        if (response.output.result == "failure") {
          setRedirectToHome(false);
          showErrorToast("Password is incorrect");
        } else {
          // if (response.output.data.firstname) {
          //   saveUsername(response.output.data.firstname);
          // }
          // Save the token
          if (response.output.token) {
            saveToken(response.output.token);
          }
          if(response.output.data){
            savePatientDetails(response.output.data)
          }
         
          setRedirectToHome(true);
          navigate("/home");
        }
        // Further processing or navigation can be done here

      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    },
    onError: (errorResponse) => console.log('Login Failed:', errorResponse),
    flow: 'auth-code',
    scope: 'openid email profile',
  });

  //facebook login
  const checkLoginState = () => {
    window.FB.getLoginStatus(function(response) {
      statusChangeCallback(response);
    });
  };

  const statusChangeCallback = (response) => {
    if (response.status === 'connected') {
      console.log('Logged in and authenticated');
      fetchUserProfile();
    } else {
      console.log('Not authenticated');
    }
  };

  const fetchUserProfile =  async () => {
    window.FB.api('/me', {fields: 'name,email,picture'}, function(response) {
      console.log('User profile:', response);
      const user = {
        type: 'facebook',
        idtoken: response.id,
        email: response.email,
        firstname: "",
        lastname:response.name,
        id: response.id,
        name: response.name,
        photo: "",
      };

      // Save the user information in your application's state or context
      showLoader();
      FetchSocailLogin(user)
      .then(response => {
        console.log(response);
        dismissLoader();
        if (response.output.result === "failure") {
          setRedirectToHome(false);
          showErrorToast("Password is incorrect");
        } else {
          // if (response.output.data.firstname) {
          //   saveUsername(response.output.data.firstname);
          // }
          // Save the token
          if (response.output.token) {
            saveToken(response.output.token);
          }
          if (response.output.data) {
            savePatientDetails(response.output.data);
          }
          setRedirectToHome(true);
          navigate("/home");
        }
      })
      .catch(error => {
        console.error('Error during social login:', error);
        showErrorToast("An error occurred during login");
      });
    });
  };

  const handleFBLogin = () => {
    console.log("handle fb")
    if (isFBInitialized) {
      window.FB.login(checkLoginState, {scope: 'public_profile,email'});
    } else {
      console.log('Facebook SDK is not initialized yet.');
    }
  };

  const handleGuestEmailChange = (event) => {
    const sanitizedValue = event.target.value.replace(/[^\x00-\x7F]/g, '')
    setGuestEmail(sanitizedValue);
  };
  const handleguestmobilechange = (e) => {
    setGuestMobile(e.target.value.replace(/[^0-9]/g, "").slice(0, 10));
  };
  
  const handleGenderChange = (value) => {
    let numericValue;
    switch (value) {
      case "Male":
        numericValue = 2;
        break;
      case "Female":
        numericValue = 1;
        break;
      case "Other":
        numericValue = 3;
        break;
      default:
        numericValue = null;
    }
    // console.log("Setting gender: ", value, numericValue);
    setGuestGender(numericValue);
  };
  const handleGuestCountryChange = (country) => {
    setSelectedGuestCountry(country.countryid);
  };
  const VerifyGuestLogin = async ()=>{
    if (guestemail == "") {
      showErrorToast("Please specify your email address");
       return false;
     }else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(guestemail)) {
      showErrorToast("Please Enter a Valid Email Address");
      return false;
    } 
     else if (guestmobile == "") {
      showErrorToast("Please specify your mobile number");
       return false;
     }else{
    let input = {
      "firstname": guestfirstname,
      "lastname": guestlastname,
      "fullname": guestfirstname + guestlastname,
      "email": guestemail,
      "countrycode": guestcountrycode,
      "mobileno": guestmobile,
      "gender": guestgender,
      "dob": guestdob
    }
    showLoader();
    try {
      const result = await FetchGuestPatientID(input);
      if (result.output) {
        setShowAppointmentDetails(true);
        setGuestLoginID(result.output.patientid)
        const res = await FetchDoctorDetailsGuest({
          "patientid": result.output.patientid,
          "doctorid": appointmentparams && appointmentparams.id,
        });
        if (res != null) {
          setDoctorDetailsData(res);
        } else {
          //alert("No data");
        }
      } else {
        showErrorToast("Somthing went wrong!")
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }

  }
  }
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("ddd, D MMM");
  };
  // const fetchDoctordata = async () => {
  //   showLoader();
  //   try {
  //     const result = await FetchDoctorDetailsGuest({
  //       patientid: guestloginid,
  //       "doctorid": appointmentparams && appointmentparams.id,
  //     });
  //     if (result != null) {
  //       setDoctorDetailsData(result);
  //     } else {
  //       //alert("No data");
  //     }
  //     dismissLoader();
  //   } catch (error) {
  //     console.error("Error in home:", error);
  //   }
  // };
  let input = {
    patientid: guestloginid, doctorid: appointmentparams.id,
    appointmentdate: appointmentparams.date, appointmenttime: appointmentparams.time, chiefcomplaint: "",
    appointmenttype_cd: "693", client_secret: "",
    "paymenttype":"online",  isfreevisit:"0"
  }
  const paynowmodel = async ()=>{
    showLoader();
    //below 2 states are being set null , due to the check-out form is not opening
    setClientSecret(null);
    setStripePromise(null);
    const amount = parseFloat(doctordata && doctordata.fee) * 100;
    //const amount = 100;
    try {
        const result = await CreatePaymentIntent({
          amount: amount,
        });
        result.amount = doctordata && doctordata.fee;
        result.doctorid = appointmentparams && appointmentparams.id;
        result.paymenttypeid = "2";
        const resp = await SavePaymentInitialCall(result);
        //console.log(resp,"Initial db resp");
        //setClientSecret("pi_3PAucsIpkkS0IXSH0Y2lFxG8_secret_2YLi2vpWMmcIfVkjUM4i52GU6");
        setClientSecret(result.client_secret);
        setStripePromise(loadStripe(CONFIG.StripePublicKey));
      } catch (error) {
        console.error("Error in Payment:", error);
      }
      dismissLoader();
  }
    const handleClose = ()=>{
      window.location.reload();
      localStorage.clear();
    }
  return (
    <Box
      w="100vw"
      h="100vh"
      bg="#061027"
      bgImage="url('/assets/imgs/login-bg.png')"
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      overflow={{
        base: "hidden auto",
        md: "hidden auto",
        lg: "hidden auto",
        xl: "hidden",
      }}
    >
      {isLoading && <Loader />}
      <Box w="100%" display="flex" flexDir="column">
        <Center
          h={{
            base: "unset",
            md: "unset",
            lg: "unset",
            xl: "calc(100vh - 90px)",
          }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          p={{
            base: "50px 0",
            sm: "50px 0",
            md: "50px 0",
            lg: "50px 0",
            xl: "unset",
          }}
          w="100%"
        >
          <Box mt='1rem'>
            <Image src="/assets/imgs/midoc-logo.png" alt="Logo" />
          </Box>
          <Box display="flex" w="100%" alignItems="center" mt="2rem" gap="1rem">
            <Box display="flex" flexDir="column" w={IsGuestLogin? "50%": "100%"} alignItems={IsGuestLogin? "end":"center"}>
              {!isEmailClicked && showBackButton && (
                <Box
                  as="button"
                  bg="none"
                  fontSize="14px"
                  pos="absolute"
                  left="3%"
                  top="5%"
                  color="white"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap="8px"
                  p="10px 16px"
                  border="1px solid #384052"
                  borderRadius="8px"
                  onClick={handlebackbutton}
                >
                  <Image w="14px" src="/assets/imgs/left-arrow-white.png" />
                  <Text>Back</Text>
                </Box>
              )}
              {!redirectToMobilePassword &&
                !redirectToPassword &&
                !isMobileLoginClicked &&
                isEmailClicked && (
                  <VStack
                    spacing={4}
                    p="2rem"
                    // m="0.65rem"
                    mb="0"
                    bg="white"
                    boxShadow="xl"
                    borderRadius="lg"
                    width={["90%", "400px"]}
                    gap="0"
                  >
                    <Box as="h1" fontSize="1.75rem">
                      Login
                    </Box>
                    <Box mb="1rem">
                      <Text textAlign="center">
                        Enter your email address to continue
                      </Text>
                    </Box>
                    <InputGroup>
                      <PrimaryInput
                        inputPlace="Enter Email Address"
                        onChange={(e) => handleEmailChange(e)}
                        onKeyPress={handleEmailKeyPress}
                        inputValue={email}
                      />
                      {isError && (
                        <InputRightElement
                          h="65%"
                          mr="5px"
                          bg="white"
                          top="10px"
                        >
                          <Image
                            src="/assets/imgs/error.png"
                            w="18px"
                            h="auto"
                          />
                        </InputRightElement>
                      )}
                    </InputGroup>
                    <PrimaryButton
                      colorScheme="blue"
                      width="full"
                      buttonText={"Continue"}
                      isDisabled={isButtonDisabled}
                      onClick={() => handleSubmit()}
                    />
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      w="100%"
                    >
                      <Divider opacity="1" my="0.5rem" w="50%" />
                      <Box
                        as="span"
                        p="0.375rem 0.625rem"
                        fontSize="12px"
                        fontWeight="700"
                        color="#6A707D"
                      >
                        OR
                      </Box>
                      <Divider opacity="1" my="0.5rem" w="50%" />
                    </Box>
                    <PrimaryButtonIcon
                      width="100%"
                      buttonText="Login with phone number"
                      iconSrc="/assets/imgs/phone.png"
                      iconImg="iconBtn"
                      onClick={switchtomobile}
                      iconCss={{ pos: "unset" }}
                    />

                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      w="100%"
                      gap="15px"
                    >
                      {/* <PrimaryButtonIcon
                  width="100%"
                  iconSrc="/assets/imgs/google.png"
                  buttonText=""
                  btnStyle={{mt: "0"}}
                /> */}

                      <PrimaryButtonIcon
                        width="100%"
                        iconSrc="/assets/imgs/google.png"
                        buttonText=""
                        btnStyle={{ mt: "0" }}
                        onClick={googleLogin}
                      />

                      <PrimaryButtonIcon
                        width="100%"
                        iconSrc="/assets/imgs/facebook.png"
                        buttonText=""
                        btnStyle={{ mt: "0" }}
                        onClick={handleFBLogin}
                      />
                    </Box>
                  </VStack>
                )}
              {!redirectToMobilePassword &&
                !redirectToPassword &&
                isMobileLoginClicked && (
                  <VStack
                    spacing={4}
                    p='1.5rem'
                    bg="white"
                    boxShadow="xl"
                    borderRadius="lg"
                    width={["90%", "400px"]}
                    gap="0"
                  >
                    <Box as="h1">Login</Box>
                    <Box mb="20px">
                      <Text textAlign="center">
                        Enter your mobile number to continue
                      </Text>
                    </Box>

                    <InputGroup>
                      <PrimaryInputDropdown
                        onChange={(e) => handleMobileChange(e)}
                        onKeyPress={handleMobileKeyPress}
                        inputValue={mobile}
                        variant="roundSide"
                        inputStyle={{ borderRadius: "0 0 10px 10px" }}
                      />
                      {isError && (
                        <InputRightElement h="100%">
                          <Image
                            src="/assets/imgs/error.png"
                            w="18px"
                            h="auto"
                          />
                        </InputRightElement>
                      )}
                    </InputGroup>
                    <InputGroup></InputGroup>
                    <PrimaryButton
                      colorScheme="blue"
                      width="full"
                      buttonText={"Continue"}
                      isDisabled={isButtonDisabled}
                      onClick={() => {
                        handleMobileSubmit();
                      }}
                    />
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      w="100%"
                    >
                      <Divider opacity="1" my={4} w="50%" />
                      <Box
                        as="span"
                        p="10px"
                        fontSize="12px"
                        fontWeight="700"
                        color="#6A707D"
                      >
                        OR
                      </Box>
                      <Divider opacity="1" my={4} w="50%" />
                    </Box>
                    <PrimaryButtonIcon
                      width="100%"
                      buttonText="Login with Email"
                      iconCss={{ pos: "unset", mr: "8px" }}
                      iconSrc="/assets/imgs/mail.png"
                      onClick={handleEmailLogin}
                    />
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      w="100%"
                      gap="15px"
                    >
                      <PrimaryButtonIcon
                        width="100%"
                        iconSrc="/assets/imgs/google.png"
                        buttonText=""
                      />
                      <PrimaryButtonIcon
                        width="100%"
                        iconSrc="/assets/imgs/facebook.png"
                        buttonText=""
                      />
                    </Box>
                  </VStack>
                )}
              {redirectToPassword && (
                <VStack
                  spacing={4}
                  p={8}
                  m={4}
                  bg="white"
                  boxShadow="xl"
                  borderRadius="lg"
                  width={["90%", "400px"]}
                  gap="0"
                >
                  <Box>
                    <Image
                      src={
                        Profilepicture && Profilepicture
                          ? Profilepicture
                          : "/assets/imgs/no-image-icon.png"
                      }
                      w="48px"
                      h="48px"
                      borderRadius="10px"
                    />
                  </Box>
                  <Box as="h1">Welcome Back</Box>
                  <Box mb="20px">
                    <Text>Enter your password to continue</Text>
                  </Box>
                  <InputGroup>
                    <PrimaryInput
                      inputPlace="Enter Email Address"
                      defaultValue={email}
                      isDisabled={true}
                      inputCss={{ opacity: "1" }}
                    />
                  </InputGroup>
                  <InputGroup>
                    <PasswordInput
                      passInputPlace="Enter your Password"
                      onChange={(e) => handlePasswordChange(e)}
                      onKeyPress={handleEmailpassKeyPress}
                      autoFocus={true}
                      variant="downSide"
                    />
                    {isError && (
                      <InputRightElement h="100%">
                        <Image src="/assets/imgs/error.png" w="18px" h="auto" />
                      </InputRightElement>
                    )}
                  </InputGroup>
                  <Button
                    sx={{
                      bg: "none",
                      fontSize: "14px",
                      fontWeight: "700",
                      _hover: { bg: "none" },
                    }}
                    onClick={redirectToForgotPassword}
                  >
                    Forgot Password
                  </Button>
                  <PrimaryButton
                    colorScheme="blue"
                    width="full"
                    buttonText={"Continue"}
                    isDisabled={isPasswordButtonDisabled}
                    onClick={() => {
                      handleLoginSubmit("email");
                    }}
                  />
                </VStack>
              )}
              {redirectToMobilePassword && (
                <VStack
                  spacing={4}
                  p={8}
                  m={4}
                  bg="white"
                  boxShadow="xl"
                  borderRadius="lg"
                  width={["90%", "400px"]}
                  gap="0"
                >
                  <Box>
                    <Image
                      src="/assets/imgs/no-image-icon.png"
                      w="48px"
                      h="auto"
                      borderRadius="10px"
                    />
                  </Box>
                  <Box as="h1">Welcome Back</Box>
                  <Box mb="20px">
                    <Text>Enter your password to continue</Text>
                  </Box>
                  <InputGroup>
                    <PrimaryInput
                      inputPlace="Enter Mobile"
                      defaultValue={mobile}
                      isDisabled={true}
                      inputCss={{ opacity: "1" }}
                    />
                  </InputGroup>
                  <InputGroup>
                    <PasswordInput
                      passInputPlace="Enter your Password"
                      onKeyPress={handleMobilepassKeyPress}
                      onChange={(e) => handlePasswordChange(e)}
                      autoFocus={true}
                    />
                    {isError && (
                      <InputRightElement h="100%">
                        <Image src="/assets/imgs/error.png" w="18px" h="auto" />
                      </InputRightElement>
                    )}
                  </InputGroup>
                  <Button
                    sx={{
                      bg: "none",
                      fontSize: "14px",
                      fontWeight: "700",
                      _hover: { bg: "none" },
                    }}
                    onClick={redirectToForgotPasswordMobile}
                  >
                    Forgot Password
                  </Button>
                  <PrimaryButton
                    colorScheme="blue"
                    width="full"
                    buttonText={"Continue"}
                    isDisabled={isPasswordButtonDisabled}
                    onClick={() => {
                      handleLoginSubmit("mobile");
                    }}
                  />
                </VStack>
              )}
            </Box>
            {IsGuestLogin &&
            <>
            <Divider
              orientation="vertical"
              borderLeftWidth="3px"
              opacity="1"
              h="80%"
            />
            <Box
              w="50%"
              h="auto"
              display="flex"
              justifyContent="flex-start"
            >
              <Center>
                <VStack
                  w="450px"
                  gap="0"
                  p="22px"
                  mb="0"
                  bg="white"
                  boxShadow="xl"
                  borderRadius="lg"
                  width={["90%", "400px"]}
                  position="relative" 
                >
                   <Box
                    position="absolute"
                    top="10px"
                    right="10px"
                    onClick={handleClose}  // Add your own close handler
                    cursor="pointer"
                  >
                    <Image src="/assets/svgs/close.svg" alt="Close" w="12px" h="14px" position="relative" top="15px" right="16px" />
                  </Box>
                  <Box as="h1" fontSize="1.75rem" pb={2}>
                    Guest Login
                  </Box>

                {!showhideappointmentview &&
                <>
                  <Flex w="100%">
                    <PrimaryInput
                      variant="leftSide"
                      inputPlace="First Name"
                      id="firstName"
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow only alphabetic characters (a-z, A-Z, spaces)
                        if (/^[a-zA-Z\s]*$/.test(value)) {
                          setFirstName(value);
                        }
                      }}
                      inputValue={guestfirstname}
                      maxLength={50}
                    />
                    <PrimaryInput
                      variant="rightSide"
                      inputPlace="Last Name"
                      id="lastName"
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow only alphabetic characters (a-z, A-Z, spaces)
                        if (/^[a-zA-Z\s]*$/.test(value)) {
                          setLastName(value);
                        }
                      }}
                      inputValue={guestlastname}
                      maxLength={50}
                    />
                  </Flex>

                  <Flex w="100%">
                      <PrimaryInput
                        variant="bothSide"
                        inputPlace="Enter Email Address*"
                        onChange={(e) => handleGuestEmailChange(e)}
                        inputValue={guestemail}
                      />
                  </Flex>

                  <Flex w="100%">
                    <PrimaryInputDropdown
                    onCountryChange={handleGuestCountryChange}
                    onChange={(e) => handleguestmobilechange(e)}
                    maxLength={10}
                      inputValue={guestmobile}
                      id="mobile"
                      variant="bothSide"
                    />
                  </Flex>

                  <Flex w="100%">
                    <DropdownInput
                      variant="bothSide"
                      dropDownPlace="Gender"
                      defaultOption="Male"
                      options={["Male", "Female", "Others"]}
                      readOnly={true}
                      id="gender"
                       onClick={handleGenderChange}
                      menuList={{
                        pos: "absolute",
                        right: "-45px",
                        minW: "115px",
                      }}
                      // defaultOption={defaultGender}
                    />
                  </Flex>

                  <Flex w="100%">
                    <DatePicker
                      disableFutureDates={true}
                      placeholderProp="Date of Birth"
                      leftPos={{ left: "5px" }}
                      inputStyle={{ borderRadius: "0 0 10px 0" }}
                      onDateChange={(selectedDateValue) =>
                        setGuestDOB(moment(selectedDateValue).format("YYYY-MM-DD"))
                      }
                    />
                  </Flex>
                  <Box w="100%">
                    <PrimaryButton
                      buttonText="Continue"
                      id="submitButton"
                      btnStyle={{ mt: 6 }}
                      isDisabled={""}
                      onClick={VerifyGuestLogin}
                    />
                  </Box>
                  </>
                }
                 {/* Second Screen */}
                 {showhideappointmentview &&
                 <>
                  <Box display="flex" w="100%" gap='0.75rem' justifyContent="space-between" alignItems="center">
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="left"
                      w='50%'
                      maxW='50%'
                    >
                      <Text fontSize="10px" mb='5px' className="textOverflow" 
                       title={doctordata && doctordata.specality} 
                      maxW="200px" fontWeight="500" color="#384052">
                      {doctordata && doctordata.specality}
                      </Text>

                      <Text
                        fontSize="14px"
                        fontWeight="600"
                      >
                        {appointmentparams && appointmentparams.name}
                      </Text>
                    </Box>
                    
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="left"
                      w='50%'
                    >
                      <Text fontSize="10px" mb='5px' fontWeight="500" color="#384052">
                        Appointment schedule
                      </Text>

                      <Text
                        fontSize="14px"
                        fontWeight="600"
                        display="inline-flex"
                        gap="5px"
                      >
                        <Image src="/assets/svgs/calendar_plain.svg" />
                        {formatDate(appointmentparams && appointmentparams.date)} at {appointmentparams && appointmentparams.time}
                      </Text>
                    </Box>
                  </Box>
                  <Divider h="inherit" my="10px" orientation="horizontal" />

                  <Flex w="100%" justifyContent="space-between">
                    <Text fontSize="14px" fontWeight="400">
                      Consultation fee
                    </Text>
                    <Text fontSize="14px" fontWeight="500">
                    {doctordata && doctordata.fee? "$"+doctordata.fee: "$0"}
                    {/* $100 */}
                    </Text>
                  </Flex>
                  <Flex w="100%" justifyContent="space-between">
                    <Text fontSize="14px" fontWeight="400">
                      Service fee
                    </Text>
                    <Text fontSize="14px" fontWeight="500">
                      $0
                    </Text>
                  </Flex>
                  <Flex w="100%" justifyContent="space-between">
                    <Text fontSize="14px" fontWeight="400">
                      Tax
                    </Text>
                    <Text fontSize="14px" fontWeight="500">
                      $0
                    </Text>
                  </Flex>
                  <Divider mt="10px" />
                  <Flex w="100%" justifyContent="space-between">
                    <Text fontSize="16px" fontWeight="600">
                      Total
                    </Text>
                    <Text fontSize="16px" fontWeight="600">
                    {doctordata && doctordata.fee? "$"+doctordata.fee: "$0"} 
                    {/* $100 */}
                    </Text>
                  </Flex>
                  <Box
                    display="flex"
                    gap="8px"
                    my={3}
                    flexDirection="row"
                    alignItems={"center"}
                  >
                    <Box as="span">
                      <Image w="18px" src="/assets/imgs/info-safe.png" />
                    </Box>
                    <Box as="p" lineHeight="normal" fontSize="12px">
                      Your information is safe. Only our doctors
                      <br /> can access it for your personalized care.
                    </Box>
                  </Box>

                  <Box w="100%">
                    <PrimaryButton
                      buttonText="Pay now"
                      id="submitButton"
                      btnStyle={{ mb: 6 }}
                      isDisabled={""}
                      onClick={paynowmodel}
                    />
                  </Box>
                  </>
                }
                </VStack>
              </Center>
            </Box>
            </>
            }
          </Box>
        </Center>
        <Box
          as="p"
          py="0.625rem"
          textAlign="center"
          fontSize="14px"
          color="#898D97"
        >
          © 2024 midoc, Inc.
        </Box>
        <Box
          // position="absolute"
          // bottom="0"
          w="100%" // Full width to center the content
          display="flex"
          justifyContent="center" // Center the content horizontally
          alignItems="center"
          padding="0.625rem"
          gap="10px"
        >
          <Text
            fontSize="10px"
            fontWeight="500"
            letterSpacing="1px"
            color="#898D97"
          >
            A PRODUCT OF
          </Text>
          <Image src="/assets/svgs/tci-logo.svg" />
        </Box>

        {/* Guest login start */}
        {/* <Box
          h={{base: "unset", md: "unset", lg: "unset", xl: "100vh"}}
          display="flex"
          p={{base: "50px 0", sm: "50px 0", md: "50px 0", lg: "50px 0", xl: "unset"}}
          w='50%'
          maxH="100vh"
          overflow="hidden auto"
        >
          
      </Box> */}
        {/* Guest login start */}
        {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm data={input} clientSecret={clientSecret} />
        </Elements>
      )}
      </Box>
    </Box>
  );
}

export default LoginIndex;
