import React, { useState, useEffect } from "react";
import {
    Box,
    Center,
    VStack,
    Image,
    Heading,
    Text,
    InputGroup,
    Button,
    Divider,
    Icon,
    PinInput, PinInputField,
    HStack
  } from "@chakra-ui/react";
  import {
    PrimaryInputDropdown,
    PrimaryButton,
    PrimaryButtonIcon,
    PasswordInput,
    PrimaryInput,
    Toaster,
  } from "../../components";
import { generateOtp, ValidatedOtp } from "../../middleware/services/signup";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { VerifyUser } from "../../middleware/services/login";

function OtpIndex() {

  const [mobileno, setMobileno] = useState("1234567")
  const [otp, setOtp] = useState("1234")
  const [type, setType] = useState("registration")
  const [moblieNo, setMoblieNo] = useState('');
  const [email, setEmail] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [countdown, setCountdown] = useState(30);
  const [resendClicked, setResendClicked] = useState(false);
  const [mobileSignin, setMobileSignin] = useState(false);
  const [emailSignin, setEmailSignin] = useState(false);
  
  const { showSuccessToast, showErrorToast } = Toaster();
  const [emailifMobileLogin, setEmailifMobileLogin] = useState('');
  
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [valueOfPins, setValueOfPins] = useState("");
  
  const navigate = useNavigate();

  
useEffect(() => {
  const mobileOtp = localStorage.getItem('NewUserMobile');
  const emailOtp = localStorage.getItem('NewUserEmail');
if(mobileOtp){
  setMobileSignin(true)
}else if (emailOtp) {
  setEmailSignin(true)
}

// if the user sign up with mobile , then will not get the email from local, so we are getting from personalDetails
var storedPersonalDetails = localStorage.getItem("personalDetails");
if(storedPersonalDetails){
  var detailsFromLocal = JSON.parse(storedPersonalDetails);
  if(detailsFromLocal){
    setEmailifMobileLogin(detailsFromLocal.email)
     setEmail(detailsFromLocal.email);
  }
}

  
  const storedValue = localStorage.getItem('NewUserMobile');
  const storedValueEmail = localStorage.getItem('NewUserEmail');
  const countryCode = localStorage.getItem('countryid');
  if (storedValue) {
    setMoblieNo(storedValue);
  }
  // if (storedValueEmail) {
  //   setEmail(storedValueEmail);
  // }
  if (countryCode) {
    setCountryCode(countryCode);
  }
  const intervalId = setInterval(() => {
    setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : 0));
  }, 1000);

  setTimeout(() => {
    
  var pin1 = document.getElementById("pin1-0");
  pin1.focus();
  }, 10);

  return () => clearInterval(intervalId);
}, [resendClicked]);
  

  var inputForValidateOTP = {
    email,
    "otp":valueOfPins,
    type
  }
  
  const handleLoginSubmit = async () =>{
     var pin1 = document.getElementById("pin1-0")
     var pin2 = document.getElementById("pin1-1")
     var pin3 = document.getElementById("pin1-2")
     var pin4 = document.getElementById("pin1-3")
     var pin1value = pin1.value;
     var pin2value = pin2.value;
     var pin3value = pin3.value;
     var pin4value = pin4.value;
     

     var otpEnteredValue = pin1value + pin2value + pin3value + pin4value;

     if(pin1value == "" || pin2value == "" || pin3value == "" || pin4value == ""){
      
     showErrorToast("Please Enter the OTP")
      return false;
     }else {
    showLoader();
    const result = await ValidatedOtp(inputForValidateOTP);
    var outputResult = result.output.result;

    if(outputResult == "success"){
     // showSuccessToast("OTP Matched")
    localStorage.setItem('activeComponent', 'medical');
     navigate('/signup');
     sessionStorage.removeItem("country-id");
     sessionStorage.removeItem("country-flag");
    }else if (outputResult == "failure"){
     
      showErrorToast("Uh-oh! The code that you have entered is incorrect, please try again...")
    }
    dismissLoader();
  }
  }
  

 
const handleResendClick = () => {
  generateOtpFunction();
  setTimeout(() => {
  setCountdown(120);
  setResendClicked(true);
  setValueOfPins("")
  }, 1000);
  
};
const handleMobilepassKeyPress = (e) => {
  if (e.key === "Enter") {
    handleLoginSubmit();
  }
};
const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
  return `${formattedMinutes}:${formattedSeconds}`;
};
const handlePinValues = (value)=>{
  setValueOfPins(value)
}


// resend otp functionality  ------




const generateOtpFunction = async() =>{
  showLoader();
  var inputToGenerateOTP = {
    // "otp": "1234",
    "countrycode": "+1",
    "countryid": 1,
    "language": "eng",
    "type": "registration",
    email
   }
   

  localStorage.setItem("Email" , email)
    
      try {
          const fetchData = async () => {
            try {
              const generateOTP = await generateOtp(inputToGenerateOTP);
            } catch (error) {
              console.error('Error generating OTP:', error);
            }
          };
        
          fetchData();
      
      } catch (error) {
        console.error("failed:", error.response.data);
      }
   

  
    dismissLoader();
  }

    return (
        <Box
        w="100vw"
        h="100vh"
        bg="#061027"
        bgImage="url('/assets/imgs/login-bg.png')"
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
      >
        
        {isLoading && <Loader />}
        <Center
          h="100vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box>
            <Image src="/assets/imgs/midoc-logo.png" alt="Logo" />
          </Box>
          <VStack
            spacing={4}
            p={{base: "1.5rem", lg: "2rem"}}
            m={4}
            bg="white"
            boxShadow="xl"
            borderRadius="lg"
            width={["90%", "400px"]}
            gap="0"
            display="flex"
            alignItems="left"
          >
            <Box mb="15px">
              {/* {mobileSignin ?(
                <Image
                src="/assets/imgs/otp-icon.png"
                w="48px"
                h="auto"
                borderRadius="10px"
              />
              ):( */}
                <Image
                src="/assets/imgs/email-icon.png"
                w="48px"
                h="auto"
                borderRadius="10px"
              />
              {/* )} */}
              
            </Box>
            {/* {mobileSignin ? (
  <Box>
    <Box as="h1">SMS OTP</Box>
    <Box mb="20px">
      <Text fontSize={16}>Enter OTP from SMS that was sent to <br/>  
        {countryCode}  &nbsp; {moblieNo}
      </Text>
    </Box>
  </Box>
) : ( */}
    <Box>
      <Box as="h1">Email OTP</Box>
      <Box mb="20px">
        <Text fontSize={16}>Enter OTP code that was sent to <br/>  
          {email ? email : emailifMobileLogin}
        </Text>
      </Box>
    </Box>
  
{/* )} */}



            <HStack justifyContent="space-between">
  <PinInput  id="pin1"  value={valueOfPins} onChange={(e)=>handlePinValues(e)} placeholder='-' size="lg">
    <PinInputField sx={{ width: "72px", height: "72px", fontSize: "2xl" }}  />
    <PinInputField sx={{ width: "72px", height: "72px", fontSize: "2xl" }} />
    <PinInputField sx={{ width: "72px", height: "72px", fontSize: "2xl" }} />
    <PinInputField sx={{ width: "72px", height: "72px", fontSize: "2xl" }}  
                onKeyPress={handleMobilepassKeyPress}/>
  </PinInput>
</HStack>

{countdown > 0 && (
<Button
              sx={{
                bg: "none",
                color: "#6A707D",
                fontSize: "12px",
                fontWeight: "600",
                _hover: { bg: "none" },
              }}
            >
            
        Resend OTP in: {formatTime(countdown)}
            </Button>
            )}
            {countdown <= 0 && (
              <div style={{display:"flex", justifyContent:"space-between"}}>
              <Button
              sx={{
                bg: "none",
                color: "#6A707D",
                fontSize: "12px",
                fontWeight: "600",
                _hover: { bg: "none" },
              }}
              onClick={handleResendClick}
            >
              Didnt Receive OTP?
            </Button>

              <Button
              
            onClick={handleResendClick}
              sx={{
                bg: "none",
                color: "#6A707D",
                fontSize: "12px",
                fontWeight: "600",
                _hover: { bg: "none" },
              }}
            >
              Resend OTP
            </Button>
              </div>
            )}

            
            <PrimaryButton
              colorScheme="blue"
              width="full"
              buttonText={"Continue"}
              isDisabled={false}
              onClick={() => {
                 handleLoginSubmit();
              }}
            />
          </VStack>
      </Center>
    </Box>
    )
}

export default OtpIndex