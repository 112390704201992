import moment from "moment";
export default function formAPIreportData({
documentid,
eventtitle,
longtext,
verifieddate,
documentdate,
performdate,
documenturl,
resultvalue,
resultuom,
reportname,
reportdescription,
imagepath,
reportid,
lower_bound,
upper_bound,
actualresultvalue

   

})
{
  return Object.freeze({
    documentid,
    eventtitle,
    longtext,
    verifieddate,
    documentdate,
    performdate,
    documenturl,
    resultvalue,
    resultuom,
    reportname,
    reportdescription,
    imagepath,
    reportid,
    lower_bound,
    upper_bound,
    actualresultvalue
   
   
  });
}