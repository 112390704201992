import React from 'react';
import { Box, Button, VStack, Text, HStack, Image, SimpleGrid } from '@chakra-ui/react';

const TimeSlotButton = ({ time, isSelected, onClick, isDisabled}) => (
  <Button
  onClick={onClick}
  borderRadius="md"
  borderWidth="0.09375rem"
  borderColor={isSelected ? '#1C4EC5' : '#E2E8F0'}
  bg={isSelected ? '#eaedf5' : (isDisabled ? '#E2E8F0' : '#FFFFFF')}
  color={isSelected ? '#3182ce' : (isDisabled ? 'gray' : '#2D3748')}
  fontWeight="500"
  fontSize="0.875rem"
  _hover={{ bg: isDisabled ? '#E2E8F0' : '#E2E8F0' }}
  cursor={isDisabled ? 'not-allowed' : 'pointer'}
  isDisabled={isDisabled}
  >
    {time}
  </Button>
);

const TimeSlots = ({ label, slots,slotIcon }) => (
  <VStack align="start" spacing={2}>
    <HStack align="center">
      <Image
        boxSize="20px"
        src={slotIcon}
        alt="icon"
      />
      <Text fontSize="md" fontWeight="bold">
        {label}
      </Text>
      <Text fontSize="sm" color="gray.500">
        {slots.length} slots
      </Text>
    </HStack>
    <SimpleGrid columns={3} spacing="1rem">
      {slots.map((slot, index) => (
        <TimeSlotButton key={index} time={slot.time} isSelected={slot.isSelected} onClick={slot.onClick} isDisabled={slot.isDisabled}/>
      ))}
    </SimpleGrid>
  </VStack>
);

const TimeSlotPicker = ({ morningSlots, afternoonSlots, eveningSlots, nightSlots }) => (
  <Box p="1.25rem 0">
    <VStack spacing={6} align="start" maxH="40vh" overflow="hidden auto">
      <TimeSlots label="Morning" slotIcon="/assets/svgs/morning-slot.svg" slots={morningSlots} />
      <TimeSlots label="Afternoon" slotIcon="/assets/svgs/afternoon-slot.svg" slots={afternoonSlots} />
      <TimeSlots label="Evening" slotIcon="/assets/svgs/evening-slot.svg" slots={eveningSlots} />
      <TimeSlots label="Night" slotIcon="/assets/svgs/night-slot.svg" slots={nightSlots} />
    </VStack>
  </Box>
);

export default TimeSlotPicker;
