import {
  Box,
  Flex,
  Button,
  Image,
  Spacer,
  Text,
  SimpleGrid,
  Td,
  Th,
  Tr,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  filter,
} from "@chakra-ui/react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import TabSection from "../../components/tabs";
import DynamicTable from "../../components/dynamic-table";
import {
  DropdownInput,
  PrimaryButton,
  PrimaryButtonIcon,
  PrimaryInput,
  Toaster,
} from "../../components";
import ReportViewCard from "../../components/report-card";
import ModalRight from "../../components/modal-right";
import React from "react";
import DynamicModal from "../../components/dynamic-modal";
import { useEffect, useState, useRef } from "react";
import {
  DeletePatientLabReport,
  FetchLabsData,
  FetchLabsDataForReportsView,
  GetLabTestName,
  GetTestResultByLabTestId,
} from "../../middleware/services/myhealth/labs";
import { useNavigate } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import moment from "moment";
import Footer from "../../components/footer";
import { downloadDocument, getLoginInfo,getPatientAge,getPatientCountryid,getPatientFullName,getUserID, handleAlphaDotAndSpace } from "../../auth";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import DatePicker from "../../components/date-picker";
import SimpleTable from "../../components/simple-table";
import {
  AddMedicalRecord,
} from "../../middleware/services/myhealth/uploaddocument";
import DropdownInputForVitals from "../../components/dropdown-input-forVitals";
import { FetchTreatmentTeam } from "../../middleware/services/home";

const initialRow = {
  test_name: '',
  resultvalue: '',
  test_unit: '',
};

function LabsIndex() {
  const openCustomModal = React.useRef();
  const dynamicModal = React.useRef();
  const enterManually = useRef();
  const { showSuccessToast, showErrorToast } = Toaster();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [patientid, setPatientid] = useState("");
  const [type, setType] = useState("lab");
  const [formattedData, setFormattedData] = useState(null);
  const [formattedDataForTableView, setFormattedDataForTableView] =
    useState(null);
  const [formattedDataForReportView, setFormattedDataForReportView] =
    useState(null);
  const [fetchError, setFetchError] = useState(false);
  const [fetchedData, setFetchedData] = useState(false);
  const [activeTab, setActiveTab] = useState("TableViewTab");
  const [rows, setRows] = useState([]);
  const fileInputRef = useRef(null);
  const [ inputId , setInputId ] = useState("");
  const [rowToDelete, setRowToDelete] = useState(null);
  const [showpatientinfo, setShowPatientInfo] = useState(true);
  const deleteRowModal = useRef();
  const [manualreportname, setManualReportName] = useState(null);
  const [patientInfo, setPatientInfo] = useState({
    name: '',
    age: '',
    doctor_name: '',
    date_of_report: '',
    hospital_name: '',
  });

  const input = {
    patientid,
    type,
  };
  const ageinyears = [];
  for (let i = 1; i <= 100; i++) {
    ageinyears.push(String(i));
  }
  // useEffect(() => {
  //   // const loginid = localStorage.getItem("loginid");
  //   // setPatientid(loginid);
  //   if(!dummyTestDetail){

  //     setRows([{...initialRow}]);
  //   }
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      try {
        const labsData = await FetchLabsData(input);
        // console.log(labsData, "labsData");
        if (labsData) {
          const data = {
            rows: labsData?.map((item) => ({
              header: item.eventtitle,
              units: item.resultuom,
              values: item.data.map(
                (value) =>
                  `${value.documenturl || ""} ${value.documentdate || ""}`
              ),
              reportid: item.data.map((value) => value.documentid),
              documetURL: item.data.map(
                (value) => value.imagepath + value.documenturl
              ),
              reportname: item.data.map((value) => value.reportname),
              trendcolor: item.data.map((value) => value.trendcolor),
            })),
          };
          setFormattedData(data);
        } else {
          setFormattedData("");
        }

        const labsDataForReportView = await FetchLabsDataForReportsView(input);
        // console.log(labsDataForReportView, "Report view");
        const dataForReportsView = {
          rows: labsDataForReportView.map((item) => {
            const uniqueURLsAndDatesMap = new Map();
            item.data.forEach((value) => {
              const { imagepath, documenturl, verifieddate } = value;
              if (documenturl) {
                uniqueURLsAndDatesMap.set(
                  imagepath + documenturl,
                  verifieddate
                );
              }
            });

            return {
              reportname: item.reportname,
              reportdescription:
                item.data.length > 0 ? item.data[0].reportdescription : "",
              values: item.data.map(
                (value) =>
                  `${value.documenturl || ""} ${value.verifieddate || ""}`
              ),
              reportid: item.data.map((value) => value.reportid),
              documetURL: item.data.map(
                (value) => value.imagepath + value.documenturl
              ),
              verifieddate:
                item.data.length > 0 ? item.data[0].verifieddate : "-",
              // Extract unique URLs and their associated dates
              uniqueURLs: Array.from(uniqueURLsAndDatesMap, ([url, date]) => ({
                url,
                date,
              })),
            };
          }),
        };

        setFormattedDataForReportView(dataForReportsView);

        const dataForTable = {
          rows: labsData.map((item) => ({
            header: item.eventtitle,
            unit: item.resultuom,
            values: item.data.map((value) => ({
              resultvalue: value.resultvalue || "-",
              documentdate: value.verifieddate || "",
              trendcolor: value.trendcolor,
              upper_bound: value.upper_bound,
              lower_bound: value.lower_bound
            })),
          })),
        };
        setFormattedDataForTableView(dataForTable);

        setFetchedData(true);
      } catch (error) {
        // console.log(error, " error in fetching labs data");
        setFetchError(true);
      }
      dismissLoader();
    };

    // if (patientid && !fetchedData) {
    fetchData();
    // }
  }, [activeTab]);

  const [reportid, setReportid] = useState([]);
  // var inputForDelete = {
  //   patientid,
  //   reportid,
  // };
  const deletePatientReports = async () => {
    showLoader();
    try {
      for (const id of reportid) {
        const inputForDelete = {
          patientid,
          reportid: [{ id: id }],
        };

        await DeletePatientLabReport(inputForDelete);
      }
    } catch (error) {
      console.error(`Error deleting report with reportid: ${reportid}`, error);
    }
    showSuccessToast("Deleted Reports Successfully");
    dynamicModal.current.closeModal();
    openCustomModal.current.closeRight();

    const labsDataForReportView = await FetchLabsDataForReportsView(input);

    const dataForReportsView = {
      rows: labsDataForReportView?.map((item) => {
        const uniqueURLsAndDatesMap = new Map();
        item.data.forEach((value) => {
          const { imagepath, documenturl, verifieddate } = value;
          if (documenturl) {
            uniqueURLsAndDatesMap.set(imagepath + documenturl, verifieddate);
          }
        });

        return {
          reportname: item.reportname,
          reportdescription:
            item.data.length > 0 ? item.data[0].reportdescription : "",
          values: item.data.map(
            (value) => `${value.documenturl || ""} ${value.verifieddate || ""}`
          ),
          reportid: item.data.map((value) => value.reportid),
          documetURL: item.data.map(
            (value) => value.imagepath + value.documenturl
          ),
          verifieddate: item.data.length > 0 ? item.data[0].verifieddate : "-",
          // Extract unique URLs and their associated dates
          uniqueURLs: Array.from(uniqueURLsAndDatesMap, ([url, date]) => ({
            url,
            date,
          })),
        };
      }),
    };
    if (dataForReportsView && dataForReportsView.rows !== undefined) {
      setFormattedData(labsDataForReportView);
      setFormattedDataForReportView(dataForReportsView);
    } else {
      setFormattedData("");
      setFormattedDataForReportView("");
    }
    dismissLoader();
  };
  const backFunction = () => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("home")) {
      navigate("/home");
    } else if (currentUrl.includes("myhealth")) {
      navigate("/myhealth");
    }
  };
  var navigate = useNavigate();

  const handleTabClick = (index) => {
    showLoader();
    switch (index) {
      case 0:
        setActiveTab("TableViewTab");
        break;
      case 1:
        setActiveTab("ReportViewTab");
        break;
      default:
        break;
    }

    dismissLoader();
  };

  const [documentDate, setdocumentdate] = useState([
    "initial values",
    "new initial value 2",
  ]);
  const [headerName, setHeaderName] = useState("");
  const [reportName, setReportName] = useState("");
  const [reportDescription, setReportDescription] = useState("");

  const [documentDates, setDocumentDates] = useState([]);
  const [documentURLs, setDocumentURLs] = useState([]);

  const [verifiedDate, setVerifiedDate] = useState("");

  const openModalRightWithData =
    (
      header,
      values,
      documentURL,
      reportname,
      verifieddate,
      reportidArray,
      rowUniqueUrlsWithDate,
      reportdescription
    ) =>
    () => {
      // console.log(rowUniqueUrlsWithDate, "rowdata");
      setReportid(reportidArray);
      const uniqueDocumentURLs = [...new Set(documentURL)];

      // setDocumentURLs(uniqueDocumentURLs)
      setDocumentURLs(rowUniqueUrlsWithDate);

      setHeaderName(header);

      setDocumentDates(values);
      setReportDescription(reportdescription);
      setVerifiedDate(verifieddate);
      setReportName(reportname);
      openCustomModal.current.openRight();
    };

  // open delete popup and set reportid for delete
  const openDeletePopup = (reportidArray, reportname) => () => {
    setReportid(reportidArray);
    setReportName(reportname);
    dynamicModal.current.openModal();
  };
  const handleimageselection = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    showLoader();
    navigate("/uploaddocument", { state: { file } });
    dismissLoader();
  };
  const openUploadLabs = () => {
    fileInputRef.current.click();
  };
  const formatDate = (dateString) => {
    //if (!dateString) return '-';
    return moment(dateString).format("Do MMM, YYYY");
  };
  //Manual Entry Forms
  const [labTestNames , setLabTestNames] = useState([])
  const openManualForm = async ()=>{
    setShowPatientInfo(true);
    //here
    setRows([])
    setDoctorName("")
    setManualReportName(null)

    try {
      
      let input = {
        "countryid": getPatientCountryid(),
       };
       let result = await GetLabTestName(input);
       if(result?.output?.data){
        setLabTestNames(result.output.data)
        console.log(result , " result ")
       }else{
        setLabTestNames([]);
        console.log("No labs are available for this doctor")
       }
     
    } catch (error) {
      console.error(`Error in getting lab test names`, error);
    }
    fetchTreatmentTeam();
    enterManually.current.openRight();
  }

  const handleTestNameChange = (name, index) => {
    setInputId(index)
    const newRows = [...rows];
    newRows[index].test_name = name;
    if (!name) {
      newRows[index] = { ...initialRow }; // Reset the entire row if medicine name is cleared
    }
    setRows(newRows);
  };
  const handleTestResultChange = (result, index) => {
    const newRows = [...rows];
    newRows[index].resultvalue = result;
    setRows(newRows);
  };
  const handleunitChange = (unit, index) => {
    const newRows = [...rows];
    newRows[index].test_unit = unit;
    setRows(newRows);
  };
  const AddAnotherRowLabTest = () => {
    const initialRow = {
      test_name: '',
      resultvalue: '',
      test_unit: '',
    };
    setRows((prevRows) => [...prevRows, { ...initialRow }]);
    focusInput("LabNameInAdd"); // Focus logic
  };
  const PopulateRows = (testDetail) => {
    if(labTestDetail){
      for(let i = 0; i < testDetail.length; i++){

        const initialRow = {
          test_name: testDetail[i].eventtitle,
          resultvalue: "",
          test_unit: testDetail[i].resultuom,
          "upper_bound": testDetail[i].upperbound,
          "lower_bound": testDetail[i].lowerbound,
        };
        setRows((prevRows) => [...prevRows, { ...initialRow }]);
    }
    }
    focusInput("LabNameInAdd"); // Focus logic
  };
  const focusInput = (inputId) => {
    setTimeout(() => {
      const element = document.getElementById(inputId);
      if (element) {
        element.focus();
      }
    }, 100);
  };
  const confirmDeleteRow = (index) => {
    setRowToDelete(index);
    deleteRowModal.current.openModal();
  };
  const deleteRowHandler = () => {
    const newRows = rows.filter((_, i) => i !== rowToDelete);
    setRows(newRows);
    deleteRowModal.current.closeModal();
  };
  const SubmitForm = async () => {
    showLoader();
    const isValidRows = (rows, fieldNames) => {
      for (const row of rows) {
        for (const fieldName of fieldNames) {
          if (!row[fieldName]) {
            dismissLoader();
            // Assuming you have a function to show toast messages
            showErrorToast(`Please fill in the ${fieldName == "test_name"? "Test Name": "Result Value"}`);
            return false;
          }
        }
      }
      return true;
    };
    if (!isValidRows(rows, ['test_name', 'resultvalue'])) return;
      let FinalInput = {
        patient_info:{
          name: getPatientFullName(),
          age: getPatientAge(),
          doctor_name: doctorName,
          date_of_report: patientInfo.date_of_report ? patientInfo.date_of_report : moment().format("YYYY-MM-DD"),
          hospital_name: patientInfo.hospital_name,
          patientid: getLoginInfo(),
          activeuserid: getUserID(),
        },
        report_info:{
          type:"labs",
          name: manualreportname
        },
        test_list: rows.map((row) => ({
          test_name: row.test_name,
          test_unit: row.test_unit,
          resultvalue: row.resultvalue,
          "upper_bound": row.upper_bound,
          "lower_bound": row.lower_bound,
        })),
      };
      showLoader();
      const result = await AddMedicalRecord(FinalInput);
      if (result.output.result === "success") {
        //setShowSuccess(true);
        showSuccessToast("Report added Successfully!")
        enterManually.current.closeRight();
        //setActiveTab("TableViewTab");
        setTimeout(() => {
          
          window.location.reload()
        }, 500);
        //dismissLoader();
      } else {
        showErrorToast("Somthing went wrong");
        dismissLoader();
      }
  };
  const [ labTestDetail , setLabTestDetail] = useState([]);
  const SubmitPatientForm = async () => {
    // if(patientInfo.name == ''){
    //   showErrorToast("Please specify your name");
    //    return false;
    //  }else
      if(manualreportname == '' || manualreportname == null){
      showErrorToast("Please provide document name");
      return false;
    }
    else{
      try {
      
        let input = {
          "labtestid":selectedTestId,
        "countryid": getPatientCountryid(),
         };
         let result = await GetTestResultByLabTestId(input);
         if(result?.output?.data){
          setLabTestDetail(result.output.data)
          console.log(result , " result ")
          PopulateRows(result.output.data)
         }else{
          setLabTestDetail([]);
          AddAnotherRowLabTest();
          console.log("Something went wrong!")
         }
       
      } catch (error) {
        console.error(`Error in getting lab test names`, error);
      }
      setShowPatientInfo(false)

      //here
    }
  };

  const handleChange = (name, value) => {
    if(name == "name"){
      setPatientInfo((prev) => ({ ...prev, name: value }));
    }
    if(name = "doctor_name"){
      setPatientInfo((prev) => ({ ...prev, doctor_name: value }));
    }
  };
  // const handlehospitalChange = (e) => {
  //   setPatientInfo((prev) => ({ ...prev, hospital_name: e.target.value }));
  // };
  const handleDateChange = (selecteddate) => {
    const formattedDate = moment(selecteddate).format('YYYY-MM-DD');
    setPatientInfo((prev) => ({ ...prev, date_of_report: formattedDate }));
  };
  const handleChangeReportName = (e) => {
    setManualReportName(e.target.value);
  };
  const handledobChange = (selectedOption) => {
    setPatientInfo((prev) => ({ ...prev, age: selectedOption }));
  };

  const [upgradePlanModal , setUpgradePlanModal] = useState(false)
  const handleGetModalStatus = (openModal) => {
    
    setUpgradePlanModal(openModal)
    setTimeout(() => {
      setUpgradePlanModal(false)
    }, 100);
  };
  let dummyData = [
      {
        "testname": "Complete Blood Count (CBC) with Absolute Count",
        "id": 1
      },
      {
        "testname": "Hymoglobin",
        "id": 2
      },
      {
        "testname": "Test Report Name",
        "id": 3
      }
    ]

    let dummyTestDetail =  [
      {
        "id": 2,
        "patientid": 2037,
        "event_cd": null,
        "eventtitle": "RBC COUNT",
        "resultvalue": "Total RBC count",
        "actualresultvalue": null,
        "resultuom_cd": null,
        "resultuom": "mill\/cumm",
        "upperbound": "5.4",
        "lowerbound": "4.2",
        "countryid": 95,
        "labtestid": 1
      },
      {
        "id": 4,
        "patientid": 2037,
        "event_cd": null,
        "eventtitle": "PLATELET COUNT",
        "resultvalue": "320000",
        "actualresultvalue": null,
        "resultuom_cd": null,
        "resultuom": "cumm",
        "upperbound": "450000",
        "lowerbound": "150000",
        "countryid": 95,
        "labtestid": 1
      },
      {
        "id": 1,
        "patientid": 2037,
        "event_cd": null,
        "eventtitle": "HEMOGLOBIN",
        "resultvalue": "Complete Blood Count (CBC) with Absolute Count",
        "actualresultvalue": null,
        "resultuom_cd": null,
        "resultuom": "g\/dL",
        "upperbound": "18",
        "lowerbound": "12",
        "countryid": 95,
        "labtestid": 1
      },
      {
        "id": 3,
        "patientid": 2037,
        "event_cd": null,
        "eventtitle": "DIFFERENTIAL COUNT",
        "resultvalue": "60",
        "actualresultvalue": null,
        "resultuom_cd": null,
        "resultuom": "%",
        "upperbound": "60",
        "lowerbound": "40",
        "countryid": 95,
        "labtestid": 1
      }
    ] 
  const [testName, setTestName] = useState("");
  const [selectedTestId, setSelectedTestId] = useState(0);
  const setTestNameInDropDown = (value) =>{
    // console.log(value, " vlaue ")
    // const stringValue = String(value); // Convert value to string
   // setTestName(stringValue);
  //  setPatientInfo((prev) => ({ ...prev, name: value }));
  setManualReportName(value)

    let labTestSelected = labTestNames.find(item => item.testname == value);
    if(labTestSelected){
      setSelectedTestId(labTestSelected.id)
    }
  }


  const handletestNamechange = (e) =>{
    // console.log(value , " value")
    // const stringValue = String(value); // Convert value to string
    setTestName(e.target.value)
  }

  /// code for Doctor LIst - ----------------

  const [doctorName, setDoctorName] = useState("");
  const [suggestions, setSuggestions] = useState({});
  const [boolValForSuggestions , setBoolValForSuggestions] = useState(true);

  const [showDocNamesDropdown , setShowDocNamesDropdown] = useState(true)
  const [treatmentteamdata, setTreatmentTeamData] = useState("");
  const fetchTreatmentTeam = async () => {
    try {
      const result = await FetchTreatmentTeam({
        "patientid":getLoginInfo(),
      });
      if (result != null) {
        setTreatmentTeamData(result);
        
      } else {
        //alert("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };

  const filterSurgeonNames = ( surgeonName) => {
    const filtered = treatmentteamdata && treatmentteamdata.filter((surgeon) =>
      surgeon.doctorname?.toLowerCase().includes(surgeonName.toLowerCase())
    );
    setSuggestions(filtered);
    setBoolValForSuggestions(!boolValForSuggestions)  
    if(filtered){
      setShowDocNamesDropdown(true)
    }
  };

  const handleSurgeonNameChange = (index, surgeonName) => {
    setInputId(index)
    setDoctorName(surgeonName)
// here
    // Filter surgeon names based on input
    if (surgeonName) {
      filterSurgeonNames(surgeonName);
      setPatientInfo((prev) => ({ ...prev, doctor_name: surgeonName }));
    } else {
    setSuggestions([]);
    setBoolValForSuggestions(!boolValForSuggestions)
    }
  };

  const listOfSurgeonNames = ( isImage) => {
 
    const allSuggestions = treatmentteamdata && treatmentteamdata
   
  setSuggestions(allSuggestions);
    // setBoolValForSuggestions(!boolValForSuggestions) 
    if(isImage){
      setShowDocNamesDropdown(!showDocNamesDropdown)
      setShowReportNamesDropdown(false)
    }
  };

  const handleSelect = (suggestion) => {
    setDoctorName(suggestion.doctorname);
    setSuggestions([]);
  };

  // code for Report Name Dropdown -----------
  
  const [showReportNamesDropdown , setShowReportNamesDropdown] = useState(false)
  const [labTestNamesFiltered , setLabTestNamesFiltered] = useState([]);

  const filterSurgeonNamesReport = ( surgeonName) => {
    const filtered = labTestNames && labTestNames.filter((surgeon) =>
      surgeon.testname?.toLowerCase().includes(surgeonName.toLowerCase())
    );
    setLabTestNamesFiltered(filtered);
    setBoolValForSuggestions(!boolValForSuggestions)  
    console.log(labTestNames, " labTestNames")
    console.log(filtered, " filtered")
    if(filtered){
      setShowReportNamesDropdown(true)
    }
  };

  const handleReportNameChange = (index, surgeonName) => {
    setInputId(index)
    setManualReportName(surgeonName)

    // Filter surgeon names based on input
    if (surgeonName) {
      filterSurgeonNamesReport(surgeonName);
      // alert(surgeonName)
    setPatientInfo((prev) => ({ ...prev, name: surgeonName }));
    } else {
      
      setLabTestNamesFiltered([]);
      
    setBoolValForSuggestions(!boolValForSuggestions)
    }
  };

  const handleShowListOfTestNames = ( isImage) => {
 
    const allSuggestions = labTestNames && labTestNames
    setLabTestNamesFiltered(allSuggestions);
    // setBoolValForSuggestions(!boolValForSuggestions) 
    if(isImage){
      setShowReportNamesDropdown(!showReportNamesDropdown)
      setShowDocNamesDropdown(false)
    }
  };


  const handleSelectReport = (selectedTest) => {
    setSelectedTestId(selectedTest.id)
    setManualReportName(selectedTest.testname);
    setLabTestNamesFiltered([]);
  };

  return (
    <>
      <Box w="100%" display={{ base: "none", lg: "block" }}>
        <Box top="0">
          <Navbar   messageModal={upgradePlanModal} />
          {isLoading && <Loader />}
          <SecondaryNav
            withBack="Labs"
            goBack={backFunction}
            withBackBody={
              <>
                <Box display="flex" gap="10px" alignItems="center" pr="25px">
                  <TabSection
                    tabs={["Table view", "Reports view"]}
                    onClickTab={handleTabClick}
                  />
                  <input
                    type="file"
                    id="file-upload"
                    onChange={handleimageselection}
                    ref={fileInputRef} // Set the ref for the file input
                    accept="image/jpeg,image/jpg,image/png,image/tiff,application/pdf"
                    style={{ display: "none" }} // Hide the file input
                  />
                  <PrimaryButton
                    fontSize="14px"
                    variant="mdBtn"
                    buttonText="Enter Manually"
                    btnIcon="/assets/imgs/add.png"
                    onClick={openManualForm}
                  />
                  {formattedData && (
                    <>
                      <PrimaryButton
                        fontSize="14px"
                        variant="mdBtn"
                        buttonText="Upload report"
                        btnIcon="/assets/imgs/add.png"
                        onClick={openUploadLabs}
                      />
                      {/* <input
        type='file'
        id='file-upload'
        onChange={handleimageselection}
        ref={fileInputRef} // Set the ref for the file input
        style={{ display: 'none' }} // Hide the file input
      />  */}
                    </>
                  )}
                </Box>
              </>
            }
          />
        </Box>
        <Box>
          <Flex
            bg="#fcfcfd"
            flexDir="column"
            h="calc(100vh - 180px)"
            overflow="hidden auto"
          >
            {formattedData &&
              (activeTab === "TableViewTab" ? (
                <DynamicTable TableData={formattedDataForTableView} showUpgradePlanModal={handleGetModalStatus} />
              ) : (
                <Flex
                  wrap="wrap"
                  p="20px"
                  w="100%"
                  justifyContent="left"
                  gap="20px"
                >
                  {formattedDataForReportView &&
                    formattedDataForReportView.rows.map((row, index) => (
                      <Box
                        key={index}
                        flex="0 0 calc(33.333% - 20px)"
                        mb="20px"
                      >
                        <ReportViewCard
                          deleteBtn={openDeletePopup(
                            row.reportid,
                            row.reportname
                          )}
                          cardHeader={row.reportname ? row.reportname : "-"}
                          cardSecondary={formatDate(row.verifieddate)}
                          cardBtn={row.uniqueURLs.length > 0 ? row.uniqueURLs.length + " documents" : null}
                          cardText={row.reportdescription}
                          cardStyle={{h:"100%"}}
                          cardTextStyle={{
                            display: "-webkit-box",
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            wordBreak: "break-word",
                            lineHeight: "1.2",
                            height: "3.6em",
                          }}
                          onClick={openModalRightWithData(
                            row.header,
                            row.values,
                            row.documetURL,
                            row.reportname,
                            row.verifieddate,
                            row.reportid,
                            row.uniqueURLs,
                            row.reportdescription
                          )}
                        />
                      </Box>
                    ))}
                </Flex>
              ))}
            {formattedData == "" && (
              <Box
                w="100%"
                h="100vh"
                display="flex"
                flexDirection="column"
                gap="5px"
                justifyContent="center"
                alignItems="center"
              >
                <Image w="60px" src="/assets/svgs/biotech.svg" />
                <Box as="p">No data available</Box>

                <PrimaryButton
                  fontSize="14px"
                  variant="mdBtn"
                  buttonText="Upload report"
                  btnIcon="/assets/imgs/add.png"
                  onClick={openUploadLabs}
                />
                {/* <input
        type='file'
        id='file-upload'
        onChange={handleimageselection}
        ref={fileInputRef} // Set the ref for the file input
        style={{ display: 'none' }} // Hide the file input
      /> */}
              </Box>
            )}
          </Flex>
          <Footer />
        </Box>
        <ModalRight
          ref={openCustomModal}
          secondaryHeader={reportName}
          subHeader={formatDate(verifiedDate)}
          body={
            <Flex pt="15px" flexDirection="column" h="100%">
              <Box as="p" pb="20px" fontSize="14px" fontWeight="500">
                {reportDescription}{" "}
              </Box>

              {documentURLs.map((item, index) => (
                <Box key={index} mb="2">
                  <Button
                    as="a"
                    download={!item.url.includes("/null")}
                    w="100%"
                    h="48px"
                    borderRadius="10px"
                    border="1px solid #E6E7E9"
                    display="flex"
                    justifyContent="space-between"
                    bg="white"
                    color="#1C4EC5"
                    sx={{ _hover: { bg: "none" } }}
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontSize: "12px",
                    }}
                    onClick={() =>
                      downloadDocument(
                        item.url.includes("/null") ? undefined : item.url
                      )
                    }
                    cursor="pointer"
                  >
                    <Box
                      as="span"
                      title={reportName}
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      w="70%"
                    >
                      {reportName} <br />
                      {/* Display date */}
                      <p style={{ color: "black", fontSize: "11px" }}>
                        {formatDate(item.date)}
                      </p>
                    </Box>
                    {!item.url.includes("/null") && (
                      <Box as="span">
                        <Image src="/assets/imgs/download.png" />
                      </Box>
                    )}
                  </Button>
                </Box>
              ))}

              <Spacer />
              <Box>
                <PrimaryButtonIcon
                  buttonText="Delete"
                  onClick={deletePatientReports}
                  btnStyle={{ color: "#AA2E26" }}
                />
              </Box>
            </Flex>
          }
        />

        <DynamicModal
          ref={dynamicModal}
          modalHeader="Delete report?"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete <strong>"{reportName}"</strong>
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                onClick={deletePatientReports}
              />
            </>
          }
        />
      </Box>

      <ModalRight
        ref={enterManually}
        modalWidth={showpatientinfo ? "600px": "70vw"}
        bodyStyle={{ overflow: "hidden" }}
        header="Manual Report"
        hasFooter={true}
        body={
          <>
          {showpatientinfo ?(
            <Box overflowY="auto" p="24px" h="100%">
            <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">
              Please fill in the details below
            </Box>
            {/* <PrimaryInput
              name="name"
              inputPlace="Name*"
              variant="defaultSide"
              value={patientInfo.name}
              onChange={(e)=>handleChange("name", e.target.value)}
              maxLength={100}
            /> */}
            
            {/* <DropdownInput
              variant="bothSide"
              dropDownPlace="Age"
              options={ageinyears}
              defaultOption={patientInfo.age}
              onClick={handledobChange}
            /> */}
            {/* <PrimaryInput
              name="doctor_name"
              inputPlace="Doctor Name"
              variant="bothSide"
              value={patientInfo.doctor_name}
              onChange={(e)=>handleChange("doctor_name", e.target.value)}
              maxLength={50}
            /> */}
            <Box display="flex" alignItems="center" position="relative">
            <PrimaryInput
              variant="bothSide"
              inputType="text"
              inputPlace="Doctor Name"
              inputValue={doctorName}
              onChange={(e) =>
                handleSurgeonNameChange(1, handleAlphaDotAndSpace(e.target.value, 50))
              }
              onClick={() =>
                listOfSurgeonNames(true)
              }
              //if the inputIdProp have id then it will focus, if id is null it will not focus, 
              inputIdProp={inputId == 1 ? "Active" : null}
              boolProp={boolValForSuggestions}
            /> 
               <Image top="35%" right="3.5%" w="15px"  cursor="pointer" position="absolute"
                          onClick={() =>
                            listOfSurgeonNames( true)
                          }
                            pos='absolute' src="/assets/imgs/double-arrow.png" />
                            </Box>
                          
                    {showDocNamesDropdown &&  suggestions.length > 0 && (
                <Menu isOpen={true}>
                <MenuButton as={Box} w="100%" display="block" pos="relative" zIndex="999">
                  <Box pos="absolute" top="100%" left="0" w="100%" bg="white" borderTop="none" border="1px solid #E6E7E9" borderRadius="0 0 12px 12px" />
                </MenuButton>
                <MenuList w="100%" borderRadius="0 0 12px 12px" zIndex="999" bg="white" maxH="150px" overflow="hidden auto">
                  {suggestions.map((suggestion, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleSelect(suggestion)}
                      cursor="pointer"
                      p="1rem"
                      py="0.5rem"
                      borderBottom="1px solid #E6E7E9"
                    >
                      <Text fontSize="0.875rem" fontWeight="500">{suggestion.doctorname}</Text>
                    </MenuItem>
                  ))}
                  </MenuList>
                </Menu>
              )}


            <DatePicker
              name="date_of_report"
              disableFutureDates={true}
              placeholderProp="Date of Report"
              leftPos={{ left: '3px' }}
              onDateChange={(selectedDateValue) =>
                handleDateChange(selectedDateValue)
              }
              defaultDate={moment().format("DD-MM-YYYY")}
            />
            {/* <PrimaryInput
              name="hospital_name"
              inputPlace="Hospital Name"
              variant="bothSide"
              value={patientInfo.hospital_name}
              onChange={handlehospitalChange}
            /> */}
            {/* <PrimaryInput
              inputPlace="Document Name*"
              variant="downSide"
              value={manualreportname}
              onChange={handleChangeReportName}
              maxLength={50}
            /> */}
             {/* <DropdownInputForVitals
                variant="bothSide"
                dropDownPlace="Report Name"
                options={labTestNames && labTestNames.map(item => item.testname)}
                onClick={setTestNameInDropDown}
                // onChange={handletestNamechange}
                onChange={handleChangeReportName}
               readOnly=""
               id="testName"
              
              //  inputValueProp={testName}
             // defaultValue=""
              inputValueProp={manualreportname}
                maxLength={50}
              /> */}
<Box display="flex" alignItems="center" position="relative">
<PrimaryInput
              variant="bothSide"
              inputType="text"
              inputPlace="Report Name"
              inputValue={manualreportname}
              onChange={(e) =>
                handleReportNameChange(2, handleAlphaDotAndSpace(e.target.value, 50))
              }
              onClick={() =>
                handleShowListOfTestNames(true)
              }
              //if the inputIdProp have id then it will focus, if id is null it will not focus, 
              inputIdProp={inputId == 2 ? "Active" : null}
              boolProp={boolValForSuggestions}
            /> 
               <Image top="35%" right="3.5%" w="15px"  cursor="pointer" position="absolute"
                          onClick={() =>
                            handleShowListOfTestNames( true)
                          }
                            pos='absolute' src="/assets/imgs/double-arrow.png" />
          </Box>
                          
                    {showReportNamesDropdown &&  labTestNamesFiltered.length > 0 && (
                <Menu isOpen={true}>
                <MenuButton as={Box} w="100%" display="block" pos="relative" zIndex="999">
                  <Box pos="absolute" top="100%" left="0" w="100%" bg="white" borderTop="none" border="1px solid #E6E7E9" borderRadius="0 0 12px 12px" />
                </MenuButton>
                <MenuList w="100%" borderRadius="0 0 12px 12px" zIndex="999" bg="white" maxH="150px" overflow="hidden auto">
                  {labTestNamesFiltered.map((suggestion, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleSelectReport(suggestion)}
                      cursor="pointer"
                      p="1rem"
                      py="0.5rem"
                      borderBottom="1px solid #E6E7E9"
                    >
                      <Text fontSize="0.875rem" fontWeight="500">{suggestion.testname}</Text>
                    </MenuItem>
                  ))}
                  </MenuList>
                </Menu>
              )}
          </Box>
          ):(
            <SimpleTable
                NoDataHeader={false}
                isDataPresent={true}
                ThStyle={{ textAlign: "center", bg: "#FAFAFA" }}
                tableStyle={{
                  h: "calc(100vh - 170px)",
                  maxH: "calc(100vh - 170px)",
                  overflowY: "auto",
                  overflowX: "hidden",
                  width: "100%",
                  border: "1px solid #E6E7E9",
                  borderBottom: "none",
                }}
                tableHeaders={
                  <Tr bg='white' zIndex='9999' pos='relative'>
                    <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                      Test name
                    </Th><Th fontSize="0.87rem" fontWeight="500" pl="10px">
                      Unit
                    </Th>
                    <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                      Result
                    </Th>
                    <Th fontSize="0.87rem" fontWeight="500" >
                      <Flex w="100%" justifyContent="center" alignItems="center">
                        <PrimaryButton
                          btnIcon="/assets/svgs/add-white.svg"
                          iconSrc={true}
                          btnStyle={{ w: "25px", h: "25px", p: "0",m:"0" }}
                          iconSrcStyle={{ mr: "0", w: "10px" }}
                          onClick={AddAnotherRowLabTest}
                        />
                      </Flex>
                    </Th>
                  </Tr>
                }
                tableBody={
                  <>
                    {rows.map((row, index) => (
                      <Tr w="100%">
                        <Td p="0">
                          <Box w="100%" pos="relative" cursor="pointer">
                            <PrimaryInput
                              variant="bothSide"
                              inputPlace="Test name"
                              inputValue={row.test_name}
                              id="LabNameInAdd"
                              onChange={(e) =>
                                handleTestNameChange(
                                  e.target.value,
                                  index
                                )
                              }
                              autocomplete="off"
                              inputCssStyle={{
                                _hover: {
                                  border: "1px solid",
                                  borderRadius: "10px",
                                },
                              }}
                              maxLength={100}
                            />
                          </Box>
                        
                        </Td>
                        <Td p="0">
                          <PrimaryInput
                            variant="bothSide"
                            inputPlace='Unit'
                            inputValue={row.test_unit}
                            onChange={(e) =>
                              handleunitChange(e.target.value, index)
                            }
                            
                            maxLength={10}
                            inputCssStyle={{
                              _hover: { border: "1px solid", borderRadius: "10px" },
                            }}
                          />
                        </Td>

                        <Td p="0">
                          <PrimaryInput
                            variant="bothSide"
                            inputPlace="Result"
                            inputValue={row.resultvalue}
                            onChange={(e) =>
                              handleTestResultChange(e.target.value, index)
                            }
                            inputCssStyle={{
                              _hover: { border: "1px solid", borderRadius: "10px" },
                            }}
                            //isDisabled={true}
                            maxLength={30}
                          />
                        </Td>
                  

                        <Td p="0">
                          <Box
                            as="button"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            w="100%"
                             onClick={() => confirmDeleteRow(index)}
                          >
                            <Image src="/assets/svgs/delete.svg" opacity="0.7" />
                          </Box>
                        </Td>
                      </Tr>
                      
                     ))} 
                  </>
                }
            />
          )}
            
            {/* Table data end */}
          </>
        }
        footerActions={
          <>
          {showpatientinfo ?(
            <PrimaryButton buttonText="Next" onClick={SubmitPatientForm}/>
          ):(
            <PrimaryButton buttonText="Submit" onClick={SubmitForm}/>
          )}
          </>
        }
      />
      <DynamicModal
        ref={deleteRowModal}
        modalHeader="Delete row"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete?
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deleteRowHandler}
            />
          </>
        }
      />
      {/* Mobile responsive start */}
      <Box w="100%" bg="#fcfcfd" display={{ base: "block", lg: "none" }}>
        {isLoading && <Loader />}
        <MobileNavbar />
        <MobileSecondaryNav
          handlebackarrow={() => navigate("/myhealth")}
          header="Labs"
          bottomArea={
            <>
              <Box w="100%">
                <TabSection
                  tabs={["Table view", "Reports view"]}
                  onClickTab={handleTabClick}
                />
              </Box>
            </>
          }
        />

        <Box
          pos="fixed"
          bottom="10%"
          display="flex"
          justifyContent="center"
          w="100%"
          zIndex="99"
        >
          <PrimaryButton
            fontSize="14px"
            variant="mdBtn"
            buttonText="Upload report"
            btnIcon="/assets/imgs/add.png"
            onClick={openUploadLabs}
          />
        </Box>
        <Flex
          bg="#fcfcfd"
          flexDir="column"
          h="calc(100vh - 255px)"
          overflow="hidden auto"
        >
          {formattedData &&
            (activeTab === "TableViewTab" ? (
              <DynamicTable TableData={formattedDataForTableView} showUpgradePlanModal={handleGetModalStatus} />
            ) : (
              <SimpleGrid columns={1} gap="20px" p="1.5rem" pb="3.5rem">
                {formattedDataForReportView &&
                  formattedDataForReportView.rows.map((row, index) => (
                    <Box key={index}>
                      <ReportViewCard
                        deleteBtn={openDeletePopup(
                          row.reportid,
                          row.reportname
                        )}
                        cardHeader={row.reportname ? row.reportname : "-"}
                        cardSecondary={formatDate(row.verifieddate)}
                        cardBtn={row.uniqueURLs.length + " documents"}
                        cardText={row.reportdescription}
                        cardTextStyle={{
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          wordBreak: "break-word",
                          lineHeight: "1.2",
                          height: "3.6em",
                        }}
                        onClick={openModalRightWithData(
                          row.header,
                          row.values,
                          row.documetURL,
                          row.reportname,
                          row.verifieddate,
                          row.reportid,
                          row.uniqueURLs,
                          row.reportdescription
                        )}
                      />
                    </Box>
                  ))}
              </SimpleGrid>
            ))}
          {formattedData == "" && (
            <Box
              w="100%"
              h="100vh"
              display="flex"
              flexDirection="column"
              gap="5px"
              justifyContent="center"
              alignItems="center"
            >
              <Image w="60px" src="/assets/svgs/biotech.svg" />
              <Box as="p">No data available</Box>

              <PrimaryButton
                fontSize="14px"
                variant="mdBtn"
                buttonText="Upload report"
                btnIcon="/assets/imgs/add.png"
                onClick={openUploadLabs}
              />
              {/* <input
        type='file'
        id='file-upload'
        onChange={handleimageselection}
        ref={fileInputRef} // Set the ref for the file input
        style={{ display: 'none' }} // Hide the file input
      /> */}
            </Box>
          )}
        </Flex>
      </Box>
    </>
  );
}

export default LabsIndex;
