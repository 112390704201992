import axios from 'axios';
import { BaseUrl } from '../../../appconfig/appconfig';
import  FetchData, { } from '../../client';
import formapimodalaccount from '../../domains/profile';
import {getLoginInfo, getUserID}  from '../../../auth';
import formapimodalAlerts from '../../domains/profilealerts/alert';
import formAPIModelProfileAlerts from '../../domains/profilealerts/alerts';
import formAPIModelProfileInvoices from '../../domains/profileinvoices/profileinvoices';
import formAPIModelDoctors from '../../domains/profiledoctors/doctors';


const FetchDoctorsByAppointment = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    // to change API Name here
    const result = await FetchData("midoc_getdoctorlistbyappointment", inputData);
    
     if (result.output.data) {
     var finalOutput = formAPIModelDoctors(result.output.data)
     
     return finalOutput;
     }else{
      return result;
     }

  } catch (error) {
    console.error('Error Account fetching :', error);
    throw error;
  }
};




export {FetchDoctorsByAppointment};
