import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import PrivateRoute from './privateroute';
import LoginIndex from '../pages/login'
import SignUpIndex from '../pages/signup'
import MyHealthindex from '../pages/myhealth';
import HomeIndex from '../pages/home';
import OtpIndex from '../pages/signup/otp';

import VitalsIndex from '../pages/myhealth/vitals';
import ConditionIndex from '../pages/myhealth/conditions';
import LabsIndex from '../pages/myhealth/labs';
import ImagingIndex from '../pages/myhealth/imaging';

import SuccessIndex from '../pages/signup/success';
import AppointmentIndex from '../pages/appointments';
import ChatIndex from '../pages/chat';
import ProfileIndex from '../pages/profile';
import FamilyIndex from '../pages/profile/family';
import InvoiceIndex from '../pages/profile/invoices';
import ReportsIndex from '../pages/profile/reports';
import AlertsIndex from '../pages/profile/alerts';
import RewardsIndex from '../pages/profile/rewards';
import SubscriptionIndex from '../pages/profile/subscription';
import PharmacyIndex from '../pages/profile/pharmacy';
import OrdersIndex from '../pages/profile/orders';
import ProfileAppointmentIndex from '../pages/profile/profile-appointment';
import DoctorsIndex from '../pages/profile/doctors';
import MedicalRecordIndex from '../pages/profile/medicalrecord';
import SurgeriesIndex from '../pages/myhealth/surgeries';
import MedicationsIndex from '../pages/myhealth/medications';
import FamilyHistoryIndex from '../pages/myhealth/familyhistory';
import SocialHistoryIndex from '../pages/myhealth/socialhistory';
import VaccinationIndex from '../pages/myhealth/vaccinations';
import AllergiesIndex from '../pages/myhealth/allergies';
import UploadDocument from '../pages/myhealth/uploaddocument';
import ForgotPassword from '../pages/login/forgotpassword';
import OtpIndexForgotPassword from '../pages/login/otp';
import NewPassword from '../pages/login/newpassword';


import PrescriptionUpload from '../pages/myhealth/prescriptionupload';

import TestIndex from '../pages/test/test';



import ImagingUpload from '../pages/myhealth/imagingupload';
import SuccessUploadIndex from '../pages/myhealth/success';

import PatientMedicalRecord from '../pages/myhealth/patientmedicalrecord';

import TreatmentTeamIndex from '../pages/home/treatmentteam';
import { getToken } from '../auth';
import VideoIndex from '../pages/profile/videolibrary';
import PaymentIndex from '../pages/profile/paymentconfirmation';
import PatientImagingIndex from '../pages/myhealth/patientimaging';
import PatientLabsIndex from '../pages/myhealth/patientlabs';
import AllAppointment from '../pages/appointments/appointments';
import DoctorsListIndex from '../pages/appointments/doctors';
import DoctorProfileIndex from '../pages/appointments/doctorprofile';
import SuccessAppointments from '../pages/appointments/success';
import FailedAppointments from '../pages/appointments/failed';
import AppointmentDetails from '../pages/appointments/appointmentdetails';
import AppointmentPaymentIndex from '../pages/appointments/paymentconfirmation';
import ProfileAppointment from '../pages/profile/appointments';
import AITokensIndex from '../pages/profile/aitokens';
import VisitOverview from '../pages/appointments/visitoverview';
import RecentActivityIndex from '../pages/home/recentactivity';
import SuccessAppointmentsGuest from '../pages/login/success';
import FailedAppointmentsGuest from '../pages/login/failed';
import GenericUpload from '../pages/myhealth/genericupload';





function Routing() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
 // Save parameters as soon as possible, before any potential re-render or navigation
 const searchParams = new URLSearchParams(window.location.search);
 const id = searchParams.get('id');
 const name = searchParams.get('name');
 const date = searchParams.get('date');
 const time = searchParams.get('time');

 useEffect(() => {
   // Check if the parameters already exist in localStorage
   const storedParams = localStorage.getItem('doctorProfileParams');

   // Only store parameters if they aren't already stored
   if (!storedParams && id && name && date && time) {
     localStorage.setItem('doctorProfileParams', JSON.stringify({ id, name, date, time }));
   }
 }, [id, name, date, time]);
  const checkUserToken = () => {
    const userToken = getToken();
    if (!userToken || userToken == 'undefined') {
      setIsLoggedIn(false);
      setTimeout(() => {

        setIsLoggedIn(true);
      }, 300);
    } else if (userToken) {
      setIsLoggedIn(true);

    }

  }
  useEffect(() => {
    checkUserToken();
  }, []);

  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={isLoggedIn ? <Navigate to="/home" /> : <LoginIndex />} />
          <Route path='/login' element={<LoginIndex />} />
          <Route path='/signup' element={<SignUpIndex />} />
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path='/otpforgotpassword' element={<OtpIndexForgotPassword />} />
          <Route path='/newpassword' element={<NewPassword />} />
          <Route path='/patientmedicalrecord' element={ <PatientMedicalRecord />} />
          <Route path='/patientimaging' element={ <PatientImagingIndex />} />
          <Route path='/patientlabs' element={ <PatientLabsIndex />} />


          <Route
            path="/home"
            element={!isLoggedIn ? <Navigate to="/login" /> : <HomeIndex />}
          />
           <Route
            path="/home/recentactivity"
            element={!isLoggedIn ? <Navigate to="/login" /> : <RecentActivityIndex />}
          />
          <Route path='/appointments'
            element={!isLoggedIn ? <Navigate to="/login" /> : <AppointmentIndex />} />
          <Route path='/all-appoinments'
            element={!isLoggedIn ? <Navigate to="/login" /> : <AllAppointment />} />
          <Route path='/appointments/doctors'
            element={!isLoggedIn ? <Navigate to="/login" /> : <DoctorsListIndex />} />
          <Route path='/doctorprofile'
            element={!isLoggedIn ? <Navigate to="/login" /> : <DoctorProfileIndex />} />
          <Route path='/appointmentdetails'
            element={!isLoggedIn ? <Navigate to="/login" /> : <AppointmentDetails />} />
          <Route path='/chat'
            element={!isLoggedIn ? <Navigate to="/login" /> : <ChatIndex />} />
          <Route path='/myhealth'
            element={!isLoggedIn ? <Navigate to="/login" /> : <MyHealthindex />} />
          <Route path='/vitals' element={!isLoggedIn ? <Navigate to="/login" /> : <VitalsIndex />} />
          <Route path='/conditions' element={!isLoggedIn ? <Navigate to="/login" /> : <ConditionIndex />} />
          <Route path='/surgeries' element={!isLoggedIn ? <Navigate to="/login" /> : <SurgeriesIndex />} />
          <Route path='/medications' element={!isLoggedIn ? <Navigate to="/login" /> : <MedicationsIndex />} />
          <Route path='/allergies' element={!isLoggedIn ? <Navigate to="/login" /> : <AllergiesIndex />} />
          <Route path='/familyhistory' element={!isLoggedIn ? <Navigate to="/login" /> : <FamilyHistoryIndex />} />
          <Route path='/vaccination' element={!isLoggedIn ? <Navigate to="/login" /> : <VaccinationIndex />} />
          <Route path='/socialhistory' element={!isLoggedIn ? <Navigate to="/login" /> : <SocialHistoryIndex />} />
          <Route path='/myhealth/labs' element={!isLoggedIn ? <Navigate to="/login" /> : <LabsIndex />} />
          <Route path='/home/labs' element={!isLoggedIn ? <Navigate to="/login" /> : <LabsIndex />} />
          <Route path='/myhealth/imaging' element={!isLoggedIn ? <Navigate to="/login" /> : <ImagingIndex />} />
          <Route path='/otp' element={!isLoggedIn ? <Navigate to="/login" /> : <OtpIndex />} />
          <Route path='/success' element={!isLoggedIn ? <Navigate to="/login" /> : <SuccessIndex />} />
          <Route path='/successupload' element={!isLoggedIn ? <Navigate to="/login" /> : <SuccessUploadIndex />} />
          <Route path='/successappointments' element={!isLoggedIn ? <Navigate to="/login" /> : <SuccessAppointments />} />
          <Route path='/successappointmentsguest' element={!isLoggedIn ? <Navigate to="/login" /> : <SuccessAppointmentsGuest />} />
          <Route path='/failedappointments' element={!isLoggedIn ? <Navigate to="/login" /> : <FailedAppointmentsGuest />} />
          <Route path='/failedappointmentsguest' element={!isLoggedIn ? <Navigate to="/login" /> : <FailedAppointments />} />
          <Route path='/visitoverview'
            element={!isLoggedIn ? <Navigate to="/login" /> : <VisitOverview />} />
          <Route path='/profile' element={!isLoggedIn ? <Navigate to="/login" /> : <ProfileIndex />} />
          <Route path='/uploaddocument' element={!isLoggedIn ? <Navigate to="/login" /> : <UploadDocument />} />

          <Route path='/prescriptionupload' element={!isLoggedIn ? <Navigate to="/login" /> : <PrescriptionUpload />} />

          <Route path='/imagingupload' element={!isLoggedIn ? <Navigate to="/login" /> : <ImagingUpload />} />
          <Route path='/docupload' element={!isLoggedIn ? <Navigate to="/login" /> : <GenericUpload />} />
          <Route path='/family' element={!isLoggedIn ? <Navigate to="/login" /> : <FamilyIndex />} />
          <Route path='/reports' element={!isLoggedIn ? <Navigate to="/login" /> : <ReportsIndex />} />
          <Route path='/alerts' element={!isLoggedIn ? <Navigate to="/login" /> : <AlertsIndex />} />
          <Route path='/medicalrecord' element={!isLoggedIn ? <Navigate to="/login" /> : <MedicalRecordIndex />} />
          <Route path='/invoices' element={!isLoggedIn ? <Navigate to="/login" /> : <InvoiceIndex />} />
         <Route path='/profileappointment' element={!isLoggedIn ? <Navigate to="/login" /> : <ProfileAppointment />} /> 
          <Route path='/rewards' element={!isLoggedIn ? <Navigate to="/login" /> : <RewardsIndex />} />
          <Route path='/aitokens' element={!isLoggedIn ? <Navigate to="/login" /> : <AITokensIndex />} />
          <Route path='/orders' element={!isLoggedIn ? <Navigate to="/login" /> : <OrdersIndex />} />
          {/* <Route path='/profile-appointment' element={!isLoggedIn ? <Navigate to="/login" /> : <ProfileAppointmentIndex />} /> */}
          <Route path='/doctors' element={!isLoggedIn ? <Navigate to="/login" /> : <DoctorsIndex />} />
          <Route path='/subscription' element={!isLoggedIn ? <Navigate to="/login" /> : <SubscriptionIndex />} />
          <Route path='/videolibrary' element={!isLoggedIn ? <Navigate to="/login" /> : <VideoIndex />} />

         
          <Route path='/test' element={!isLoggedIn ? <Navigate to="/login" /> : <TestIndex />} />
          <Route path='/home/treatmentteam' element={!isLoggedIn ? <Navigate to="/login" /> : <TreatmentTeamIndex />} />
          <Route path='/payment' element={!isLoggedIn ? <Navigate to="/login" /> : <PaymentIndex />} />
          <Route path='/appointmentpayment' element={!isLoggedIn ? <Navigate to="/login" /> : <AppointmentPaymentIndex />} />

          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </Router>

    </div>
  )
}

export default Routing