import  FetchData, { } from '../../client';
import formAPIAllergies from '../../domains/allergies';
import formAPIConditions from '../../domains/conditions';
import formapimodalhealth from '../../domains/myhealth/myhealths';
import formapimodallabs from '../../domains/myhealthlabs/labs';
import labsReportViewFormatting from '../../domains/myhealthlabs/labsReportViewFormatting';
import {getLoginInfo, getUserID}  from '../../../auth';
import axios from 'axios';
import { getToken } from '../../../auth';
import { CONFIG } from '../../../appconfig/appconfig';

const getPatientAllergies = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_getpatientallergy", inputData);
       if (result && result.output && result.output.data && result.output.data != null) {
        var finalOutput =formAPIAllergies(result.output.data)
        }else{
         finalOutput = result;
        }
    
    return finalOutput;

  } catch (error) {
    console.error('Error fetching allergies  data:', error);
    throw error;
  }
};

const addAllergies = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    console.log(inputData,"addAllergies input")

        const result = await FetchData("midoc_addpatientallergy", inputData);
     console.log(result,"addAllergies db response")
    
    return result;

  } catch (error) {
    console.error('Error fetching medical conditions data:', error);
    throw error;
  }
};

const editAllergies = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_updatepatientallergy", inputData);
    
    return result;

  } catch (error) {
    console.error('Error fetching medical conditions data:', error);
    throw error;
  }
};

const deleteAllergies = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_deletepatientallergy", inputData);
    
    return result;

  } catch (error) {
    console.error('Error in deletepatientallergy :', error);
    throw error;
  }
};
const FetchAllergiesAIPrompts = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo();
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_allergiesgetprompts`, 
      inputData,
    {
      headers: headers,
    });
    
    return response.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchAllergiesAIAnswer = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo()
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_allergiesdescribeprompts`, 
      inputData,
    {
      headers: headers,
    });
    
    return response.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

export {getPatientAllergies, addAllergies, deleteAllergies, editAllergies, FetchAllergiesAIPrompts, FetchAllergiesAIAnswer};
