import  FetchData, { } from '../../client';
import formAPIModelFamilyHistory from '../../domains/familyhistorymyhealth/familyhistories';
import {getLoginInfo, getUserID}  from '../../../auth';

//reac the file

const FetchFamilyHistoryData = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_getpatientfamilylist", inputData);
    //console.log(result,"Family History db response")
    if (result.output.data) {
    var finalOutput = formAPIModelFamilyHistory(result.output.data)
    //console.log(finalOutput)
    }
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const AddFamilyHistory = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_addpatientfamily", inputData);
    //console.log(result,"Family add db response")
    // if (result.output.data) {
    // var finalOutput = formAPIModelFamilyHistory(result.output.data)
    // console.log(finalOutput)
    // }
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const EditFamilyHistory = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_updatepatientfamily", inputData);
    console.log(result,"Family add db response")
    // if (result.output.data) {
    // var finalOutput = formAPIModelFamilyHistory(result.output.data)
    // console.log(finalOutput)
    // }
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const DeleteFamilyHistory = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
        //console.log(inputData)
    const result = await FetchData("midoc_deletepatientfamily", inputData);
    //console.log(result,"Family add db response")
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

const getrelations = async (inputData) => {
  try {
    
    const result = await FetchData("midoc_getdefaultdata", inputData);
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

const getmartialstatus = async (inputData) => {
  try {
    
    const result = await FetchData("midoc_getdefaultdata", inputData);
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchFamilyList = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo();
    //inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_getpatientfamilylist", inputData);
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
export {FetchFamilyHistoryData,AddFamilyHistory,EditFamilyHistory, DeleteFamilyHistory, getmartialstatus, getrelations, FetchFamilyList};
