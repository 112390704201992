import moment from "moment";

export default function formAPIVaccinations(dataArray) {
  // Assuming dataArray is an array of objects
  return dataArray.map(({ 
    admindate,
    duedate, 
    id,
    isactive,
    vaccine_cd,
    vaccinename


    }) => {
    return Object.freeze({
      // admindate: moment(admindate).format("Do MMM YYYY"),
      // duedate: moment(duedate).format("Do MMM YYYY"), 
      admindate,
      duedate,
      id,
      isactive,
      vaccine_cd,
      vaccinename
      

    });
  });
}
