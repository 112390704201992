import moment from "moment";
export default function formAPIAppointment({
    appointmentdate,
    appointmenttime,
    appointmenttype,
    doctorimage,
    doctorname,
    hospitalname,
    imagepath,
    specality,
    isshowedup,
    appointmenfor,
    appointmentid,
    appointmenttype_cd,
    appointmentstatus,
   

})
{ 
  return Object.freeze({
    appointmentdate: appointmentdate ? moment(appointmentdate).format("YYYY-MM-DD"):"-",
    appointmenttime: appointmenttime,
    appointmenttype: appointmenttype,
    doctorimage: doctorimage,
    doctorname: doctorname,
    hospitalname: hospitalname,
    imagepath: imagepath,
    specality: specality,
    isshowedup: isshowedup,
    appointmenfor: appointmenfor,
    appointmentid:appointmentid,
    appointmenttype_cd:appointmenttype_cd,
    appointmentstatus:appointmentstatus,
   
  });
}