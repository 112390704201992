import moment from "moment";
export default function formAPIModelreaction({
  reaction,
  reaction_cd,
   

})
{
  return Object.freeze({
    reaction,
  reaction_cd,

   
  });
}