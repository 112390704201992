import axios from 'axios';
import { CONFIG } from '../../../appconfig/appconfig';
import  FetchData, { } from '../../client';
import formapimodalaccount from '../../domains/profile';
import {getLoginInfo, getUserID}  from '../../../auth';
import formapimodalAlerts from '../../domains/profilealerts/alert';
import formAPIModelProfileAlerts from '../../domains/profilealerts/alerts';


const FetchAlerts = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    // to change API Name here
    const result = await FetchData("midoc_getpatientnotificationpreference", inputData);
    console.log(result , " db response get alerts ")
     if (result.output.data) {
     var finalOutput = formAPIModelProfileAlerts(result.output.data)
     
     return finalOutput;
     }

  } catch (error) {
    console.error('Error Account fetching :', error);
    throw error;
  }
};

const addUpdateAlerts = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    // to change this API in below line
    const result = await FetchData("midoc_addpatientnotificationpreference", inputData);
     return result;

  } catch (error) {
    console.error('Error  in updating patient profile :', error);
    throw error;
  }
};





export {addUpdateAlerts, FetchAlerts};
