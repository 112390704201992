import { Box, Divider, Flex, Image, Text, useEditable, useStatStyles } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import Loader from "../../components/loader/loader";
import { PrimaryButton, Toaster } from "../../components";
import ProfileSideBar from "./profileSidebar";
import useLoader from "../../components/loader/loaderstates";
import Footer from "../../components/footer";
import GrayCard from "../../components/card";
import { COLORS } from "../../components/styles/colors";
import { FetchRewards } from "../../middleware/services/profile/rewards";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import MobileNavbar from "../../components/mobile-navbar";
import { useNavigate } from "react-router-dom";

const RewardsIndex = () => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();
  const [data , setData] = useState("")
  const [boxColor , setBoxColor] = useState("#E6E7E9")

  const goldBox = "#FCCE35";
  const bronzeBox = "#E98D3A";
  const silverBox = "#6A707D";
  const platinumBox = "#4A67AE";
  const emptyBox = "#E6E7E9";
  const greenBox = "#14C585";

  const [totalPoints , setTotalPoints] = useState("");
  const [currentPoints, setCurrentPoints] = useState("");
  const navigate = useNavigate()

   useEffect(() => {
    showLoader();

    var input = {}
    var getRewards = async () => {
      try {
        var result = await FetchRewards(input);
        console.log(result , " rewards Result ")
        if (result) {
         setData(result);
         setCurrentPoints(result.totalpoint)
         setTotalPoints(result.pointtounlocknext)
         switch (result.position) {
          case "Gold":
            setBoxColor("#FCCE35");
            break;
          case "Bronze":
            setBoxColor("#E98D3A");
            break;
          case "Silver":
            setBoxColor("#6A707D");
            break;
          case "Platinum":
            setBoxColor("#4A67AE");
            break;
          default:
            setBoxColor("#E6E7E9");
            break;
        }
        } else {
          showErrorToast("Something went wrong!")
        }

      } catch (error) {
        console.log(error, "error in fetching account data")
      }
      dismissLoader();
    }

    getRewards();
  }, [])
 const totalBoxes = 30;
 const filledBoxes = totalPoints !== null ? Math.floor((currentPoints / totalPoints) * totalBoxes) : 30 ;
 

  return (
    <>
    <Box w="100%" display={{base: "none", lg: "block"}}>
      <Box top="0" zIndex="banner">
        <Navbar />

        {isLoading && <Loader />}
        <SecondaryNav
          sideBarWidth="250px"
          defaultHeader="Profile"
          defaultSecondayHeader="Rewards"
        />
      </Box>
      <Box h="calc(100vh - 140px)" overflow="hidden auto">
        <Flex>
          <Box p="0" width="250px">
            <ProfileSideBar />
          </Box>



          <Flex flexDir="column" gap="20px" 
           // backgroundImage={COLORS.GOLD_REWARD}
            // backgroundImage={COLORS.BRONZE_REWARD}

            // backgroundImage={COLORS.SILVER_REWARD}
            // backgroundImage={COLORS.PLATINUM_REWARD}
            backgroundImage={
              data.position === "Gold" ? COLORS.GOLD_REWARD :
              data.position === "Bronze" ? COLORS.BRONZE_REWARD :
              data.position === "Silver" ? COLORS.SILVER_REWARD :
              data.position === "Platinum" ? COLORS.PLATINUM_REWARD :
              data.position === null ? COLORS.PLATINUM_REWARD :
              COLORS.BRONZE_REWARD // Default value if data.position doesn't match any of the above
            }
            w="100%" p="24px 32px">
            {/* Rewards and points start */}
            <Box w="100%">
                <Box
                    display="flex"
                    w="100%"
                    gap="20px"
                    overflowX="hidden"
                >
                    {/* Rewards start */}
                    <Box w="100%">
                    <Box>
                        <GrayCard
                        hasHeader={false}
                        cardHeader="Rewards"
                        cardHeaderIcon="/assets/imgs/stars.png"
                        minH="180px"
                        bodyStyle={{ p: "0", }}
                        cardBody={
                            <>
                            <Box p="20px">
                                <Box display="flex" justifyContent="space-between">
                                <Box as="p" fontSize="28px" fontWeight="700">
                                    {data.position  == null ? "Platinum" : data.position}
                                </Box>
                                <Image
                                    w="32px"
                                    h="32px"
                                    src={
                                      data.position === "Gold" ? "/assets/imgs/gold-badge.png" :
                                      data.position === "Bronze" ? "/assets/imgs/bronze-badge.png" :
                                      data.position === "Silver" ? "/assets/imgs/silver-badge.png" :
                                      data.position === "Platinum" ? "/assets/imgs/platinum-badge.png" :
                                      data.position === null ? "/assets/imgs/platinum-badge.png" :
                                      "/assets/imgs/bronze-badge.png" // Default value if data.position doesn't match any of the above
                                  }
                                />
                                </Box>
                                <Box>
                                <Text fontSize="12px" fontWeight="600">
                                  {
                                    data.pointtounlocknext ? (
                                      <>                      
                                       <strong>{data.totalpoint ? data.totalpoint : "-"}</strong> of <strong>{data.pointtounlocknext ? data.pointtounlocknext : "-" }</strong>{" "}
                                      points to unlock <strong>
                                      {data.position === "Bronze" ? "Silver" : 
                                       data.position === "Silver" ? "Gold" : 
                                       data.position === "Gold" ? "Platinum" : ""}
                                      </strong>
                                      </>

                                    ):(
                                      <>  
                                        <strong>{data.totalpoint ? data.totalpoint : "-"}</strong> {" "}
                                      points available 
                                      </>
                                    )
                                  }
                                   
                                </Text>
                                </Box>
                                
                            </Box>
                            </>
                        }
                        hasFooter={false}
                        simpleFooter={true}
                        footerArea={<>
                            <Box display="grid" gridTemplateColumns="repeat(auto-fill, minmax(12px, 1fr))" gap="5px" w="100%">
    {
        Array.from({ length: totalBoxes }).map((_, index) => (
            <Box
                key={index}
                bg={index < filledBoxes ? boxColor : emptyBox}
                width="12px"
                height="12px"
                borderRadius="2px"
            />
        ))
    }
</Box>

                        </>}
                        />
                    </Box>
                    </Box>
                    {/* Rewards end */}

                {/* Points start */}
                <Box w="100%">
                  <Box>
                    <GrayCard
                      hasHeader={false}
                      simpleFooter={true}
                      minH="180px"
                      cardHeader="Points"
                      cardHeaderIcon="/assets/imgs/stars.png"
                      bodyStyle={{ p: "0" }}
                      cardBody={
                        <>
                          <Box p="20px">
                            <Box display="flex" justifyContent="space-between">
                              <Box as="p" fontSize="28px" fontWeight="700">
                              {data.availablepoint ? data.availablepoint : "-"}
                              </Box>
                            </Box>
                            <Box>
                              <Text fontSize="12px" fontWeight="600">
                                Points available
                              </Text>
                            </Box>
                          </Box>
                        </>
                      }
                      hasFooter={false}
                      footerArea={
                        <>
                          <PrimaryButton
                            sx={{ height: "40px" }}
                            p="0"
                            buttonText="Redeem coming soon"
                            isDisabled={true}
                          />
                        </>
                      }
                    />
                  </Box>
                </Box>
                {/* Points end */}
              </Box>
            </Box>
            {/* Rewards and points end */}

            {/* Rewards history start */}
            <Box w="100%">

                <Box p="24px" border="1px solid #E6E7E9" borderRadius="12px" bg="white">
                    <Text fontSize="12px" fontWeight="600">REWARD HISTORY</Text>
                  {Array.isArray(data.data) && data.data.length > 0 ? (
  data.data.map((item, index) => (
    <Flex key={index} gap="15px" py="15px" borderBottom="1px solid #E6E7E9">
      <Box boxSize="40px" borderRadius='full' display="flex" justifyContent="center" alignItems="center" border="1px solid #E6E7E9">
          <Image boxSize="20px" src="/assets/svgs/ai-stars.svg" />
      </Box>
      <Box>
          <Text fontSize="14px" fontWeight="600">  {item.pointsearned} pts  {` (${item.rewardname})`}</Text>
          <Text fontSize="12px" fontWeight="500" color="#384052">{item.dateearned}</Text>
      </Box>
    </Flex>
  ))
) : (
  <Text>No data available</Text>
)}

                    

                </Box>

            </Box>
            {/* Rewards history end */}
          </Flex>
        </Flex>
        <Footer />
      </Box>
    </Box>

     {/* Mobile responsive start */}
     <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/profile')}
          header="Rewards" />
          
        <Box w="100%" h='calc(100vh - 190px)' overflow="hidden auto"
          backgroundImage={
            data.position === "Gold" ? COLORS.GOLD_REWARD :
            data.position === "Bronze" ? COLORS.BRONZE_REWARD :
            data.position === "Silver" ? COLORS.SILVER_REWARD :
            data.position === "Platinum" ? COLORS.PLATINUM_REWARD :
            data.position === null ? COLORS.PLATINUM_REWARD :
            COLORS.BRONZE_REWARD // Default value if data.position doesn't match any of the above
          }
        >
           {/* Rewards and points start */}
           <Box w="100%" p="1.5rem">
            <Box
              display="flex"
              w="100%"
              gap="20px"
              overflowX="hidden"
            >
                {/* Rewards start */}
                <Box w="100%">
                <Box>
                  <GrayCard
                    hasHeader={false}
                    cardHeader="Rewards"
                    cardHeaderIcon="/assets/imgs/stars.png"
                    minH="180px"
                    bodyStyle={{ p: "0", }}
                    cardBody={
                        <>
                        <Box p="20px">
                            <Box display="flex" justifyContent="space-between">
                            <Box as="p" fontSize="28px" fontWeight="700">
                                {data.position  == null ? "Bronze" : data.position}
                            </Box>
                            <Image
                                w="32px"
                                h="32px"
                                src={
                                  data.position === "Gold" ? "/assets/imgs/gold-badge.png" :
                                  data.position === "Bronze" ? "/assets/imgs/bronze-badge.png" :
                                  data.position === "Silver" ? "/assets/imgs/silver-badge.png" :
                                  data.position === "Platinum" ? "/assets/imgs/platinum-badge.png" :
                                  data.position === null ? "/assets/imgs/bronze-badge.png" :
                                  "/assets/imgs/bronze-badge.png" // Default value if data.position doesn't match any of the above
                              }
                            />
                            </Box>
                            <Box>
                            <Text fontSize="12px" fontWeight="600">
                              {
                                data.pointtounlocknext ? (
                                  <>                      
                                    <strong>{data.totalpoint ? data.totalpoint : "-"}</strong> of <strong>{data.pointtounlocknext ? data.pointtounlocknext : "-" }</strong>{" "}
                                  points to unlock <strong>
                                  {data.position === "Bronze" ? "Silver" : 
                                    data.position === "Silver" ? "Gold" : 
                                    data.position === "Gold" ? "Platinum" : ""}
                                  </strong>
                                  </>

                                ):(
                                  <>  
                                    <strong>{data.totalpoint ? data.totalpoint : "-"}</strong> {" "}
                                  points available 
                                  </>
                                )
                              }
                                
                            </Text>
                            </Box>
                            <Box display="grid" mt="1rem" gridTemplateColumns="repeat(auto-fill, minmax(12px, 1fr))" gap="3px" w="100%">
                              {
                                  Array.from({ length: 17 }).map((_, index) => (
                                      <Box
                                          key={index}
                                          bg={index < filledBoxes ? boxColor : emptyBox}
                                          width="12px"
                                          height="12px"
                                          borderRadius="2px"
                                      />
                                  ))
                              }
                            </Box>
                            
                        </Box>

                        </>
                    }
                    hasFooter={false}
                    simpleFooter={true}
                    footerArea={<>
                        <Flex w="100%" alignItems="center">
                          <Box p="0" w="100%">
                            <Box display="flex" justifyContent="space-between">
                              <Box as="p" fontSize="24px" fontWeight="700">
                              {data.availablepoint ? data.availablepoint : "-"}
                              </Box>
                            </Box>
                            <Box>
                              <Text fontSize="12px" fontWeight="600">
                                Points available
                              </Text>
                            </Box>
                          </Box>

                          <PrimaryButton
                            sx={{ height: "40px", fontSize: "12px" }}
                            p="0"
                            buttonText="Redeem coming soon"
                            isDisabled={true}
                          />
                        </Flex>
                    </>}
                  />
                </Box>
                </Box>
                {/* Rewards end */}
            </Box>
           </Box>
           {/* Rewards and points end */}

           {/* Rewards history start */}
           <Box w="100%" mt="1rem">
            <Box p="24px" bg="white">
              <Text fontSize="12px" fontWeight="600">
                REWARD HISTORY
              </Text>
              {Array.isArray(data.data) && data.data.length > 0 ? (
                data.data.map((item, index) => (
                  <Flex key={index} gap="15px" py="15px" borderBottom="1px solid #E6E7E9">
                    <Box
                      boxSize="40px"
                      borderRadius="full"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      border="1px solid #E6E7E9"
                    >
                      <Image boxSize="20px" src="/assets/svgs/ai-stars.svg" />
                    </Box>
                    <Box>
                      <Text fontSize="14px" fontWeight="600">
                        {" "}
                        {item.pointsearned} pts {` (${item.rewardname})`}
                      </Text>
                      <Text fontSize="12px" fontWeight="500" color="#384052">
                        {item.dateearned}
                      </Text>
                    </Box>
                  </Flex>
                ))
              ) : (
                <Text>No data available</Text>
              )}
            </Box>
          </Box>
          {/* Rewards history end */}
        </Box>
      </Box>
    </>
  );
};

export default RewardsIndex;
