import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/navbar";
import SideBar from "../../components/sidebar";
import SecondaryNav from "../../components/seconday-nav";
import { Box, Divider, Flex, Text, SimpleGrid } from "@chakra-ui/layout";
import {
  Image,
  Button,
  Spacer,
  Center,
  VStack,
  Checkbox,
  useSafeLayoutEffect,
  Stack,
  RadioGroup,
  Input,
  ListItem,
  List,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Heading
} from "@chakra-ui/react";
import {
  PrimaryButton,
  PrimaryButtonIcon,
  PrimaryInput,
  SearchBar,
  Toaster,
} from "../../components";
import DynamicModal from "../../components/dynamic-modal";
import ModalRight from "../../components/modal-right";
import {
  addMedicalConditions,
  deleteMedicalConditions,
  getMedicalConditions,
} from "../../middleware/services/myhealth/conditions";
import { getmedicalcondition } from "../../middleware/services/signup";
import {
  addSurgeries,
  deleteSurgeries,
  editSurgeries,
  getSurgeries,
  FetchSurgeriesAIAnswer,
  FetchSurgeriesAIPrompts
} from "../../middleware/services/myhealth/surgeries";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import DatePicker from "../../components/date-picker";
import { format, parseISO } from "date-fns";
import { uploadImageInProfile } from "../../middleware/services/profile/account";
import moment from "moment";
import Footer from "../../components/footer";
import { displayMillion_ThoudandValues, downloadDocument, handleAlphaDotAndSpace, handleAlphaNumericDotAndSpace, useScreenType } from "../../auth";
import { FetchTreatmentTeam } from "../../middleware/services/home";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { useNavigate } from "react-router-dom";
import TypingLoader from "../../components/typing-loader";
import AiModal from '../../components/ai-modal/index';
import { FetchMyHealthAIData} from "../../middleware/services/home";
import DropdownInputForVitals from "../../components/dropdown-input-forVitals";
import { FetchTokens } from "../../middleware/services/profile/rewards";


const SurgeriesIndex = () => {
  const dynamicModal = React.useRef();
  const addCondition = React.useRef();
  const addSurgeriesModal = React.useRef();
  const addCondition3 = React.useRef();
  const addCondition4 = React.useRef();
  const navigate = useNavigate()
  const { isLoading, showLoader, dismissLoader } = useLoader();

  const [data, setData] = useState("");
  const [treatmentteamdata, setTreatmentTeamData] = useState("");
  var [surgeriesList, setSurgeriesList] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedSurgeries, setSelectedSurgeries] = useState([]);
  const [patientid, setPatientid] = useState("");
  const [selectedDates, setSelectedDates] = useState({});
  const [id, setId] = useState("");
  const [surgeonName, setSurgeonName] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [procedureDate, setProcedureDate] = useState("");
  const [procedure, setProcedure] = useState("");
  const [procedureTitle, setProcedureTitle] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileNameForUpdate, setFileNameForUpdate] = useState('')
  const [documentURLs, setDocumentURLs] = useState([]);
  const [documentURLForEdit, setDocumentURLForEdit] = useState([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [filenameforUpload, setFilenameforUpload] = useState([]);
  const [todaysDate, setTodaysDate] = useState(moment().format("DD-MM-YYYY"));
  const indexMap = {};
  var inputForGet = {};
  const [inputValues, setInputValues] = useState({});
  const [suggestions, setSuggestions] = useState({});
  
  const [typedCondition , setTypedCondition] = useState("")

  const [boolValForSuggestions , setBoolValForSuggestions] = useState(true)
  const [showDocNamesDropdown , setShowDocNamesDropdown] = useState(true)
  const screenType = useScreenType()
  const chatContainerRef = useRef(null);
  const [aianswers, setAIAnswers] = useState([]);
  const [remainingquestions, setRemainingQuestions] = useState([]);
  const [aiquestion, setAIAnalysis] = useState(null);
  const [AiInput, setAiInput] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [animate, setAnimate] = useState(false);
  const AIChatModel = useRef();
  
  const [upgradePlanModal , setUpgradePlanModal] = useState(false)
  const [remainingTokens, setRemainingTokens] = useState();

  useEffect(() => {
    getTokens();
  }, [])
  var input = {}
    var getTokens = async () => {
      try {
        var result = await FetchTokens(input);
        if (result) {
          setRemainingTokens(result)
        } else {
          showErrorToast("Something went wrong!")
        }
      } catch (error) {
        console.log(error, "error in fetching account data")
      }
      //dismissLoader();
    }
  useEffect(() => {
    fetchData();
    fetchTreatmentTeam();
  }, []);

  useEffect(() => {
    setDocumentURLs(uploadedDocuments)
  }, [uploadedDocuments])


  const fetchData = async () => {

    showLoader();
    try {
      const result = await getSurgeries(inputForGet);
      if(result && result.output && result.output.result == "failure"){
        setData(null)
      }else if (result.output.data == null) {
        setData(null)
      } else if (result.output.data != null) {
        setData(result.output.data);
      }else {
        showErrorToast("Something went wrong!")
      }

    } catch (error) {
      console.error("Error in getsurgiries:", error);
    }
    dismissLoader();
  };

  const fetchSurgeriesList = async () => {
    try {
      const result = await getmedicalcondition({
        type: "surgical",
      });
     // console.log(result)
      if (result.output.result == "success") {
        setSurgeriesList(result.output.data);
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const fetchTreatmentTeam = async () => {
    try {
      const result = await FetchTreatmentTeam({
        patientid,
      });
    //  console.log(result, "modified treatment res");
      if (result != null) {
        setTreatmentTeamData(result);
        
      } else {
        //alert("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };


  const handleCheckboxChange = (condition) => {
    const isSelected = selectedSurgeries.some(
      (item) => item.condition_cd === condition.condition_cd
    );

    if (isSelected) {
      setSelectedSurgeries((prevConditions) =>
        prevConditions.filter(
          (item) => item.condition_cd !== condition.condition_cd
        )
      );
    } else {
      setSelectedSurgeries([...selectedSurgeries, condition]);
    }
  };

  const handleDatePickerChange = (conditionCd, selectedDate) => {

    const formattedDate = format(selectedDate, "yyyy-MM-dd");
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [conditionCd]: {
        date: formattedDate,
        surgeonName: prevDates[conditionCd]?.surgeonName || "",
        hospitalName: prevDates[conditionCd]?.hospitalName || "",
        fileName
      },
    }));
  };
  const filterSurgeonNames = (conditionCd, surgeonName) => {

    const filtered = treatmentteamdata && treatmentteamdata.filter((surgeon) =>
      surgeon.doctorname?.toLowerCase().includes(surgeonName.toLowerCase())
    );
    setSuggestions(prevSuggestions => ({
      ...prevSuggestions,
      [conditionCd]: filtered
    }));
    setBoolValForSuggestions(!boolValForSuggestions)  
    if(filtered){
      setShowDocNamesDropdown(true)
    }
  };

  const listOfSurgeonNames = (conditionCd , isImage) => {
 
    const allSuggestions = treatmentteamdata && treatmentteamdata

    setSuggestions(prevSuggestions => ({
      ...prevSuggestions,
      [conditionCd]: allSuggestions
    }));
    // setBoolValForSuggestions(!boolValForSuggestions) 
    if(isImage){
      setShowDocNamesDropdown(!showDocNamesDropdown)

    }
     
 
  };
  const listOfSurgeonNamesEdit = ( isImage) => {
 
    const allSuggestions = treatmentteamdata && treatmentteamdata

    setSuggestions(allSuggestions);
    // setBoolValForSuggestions(!boolValForSuggestions) 
    if(isImage){
      setShowDocNamesDropdown(!showDocNamesDropdown)

    }
     
 
  };
  const filterSurgeonNamesEdit = (surgeonName) => {
    const filtered = treatmentteamdata && treatmentteamdata.filter((surgeon) =>
      surgeon.doctorname?.toLowerCase().includes(surgeonName.toLowerCase())
    );
    setSuggestions(filtered);
    
    setBoolValForSuggestions(!boolValForSuggestions)  
  };
  const [ inputId , setInputId ] = useState("")
  const handleSurgeonNameChange = (conditionCd, surgeonName) => {
    setInputId(conditionCd)
    setInputValues(prevValues => ({
      ...prevValues,
      [conditionCd]: surgeonName
    }));

    // Filter surgeon names based on input
    if (surgeonName) {
      filterSurgeonNames(conditionCd, surgeonName);
    } else {
      setSuggestions(prevSuggestions => ({
        ...prevSuggestions,
        [conditionCd]: []
      }));
      
    setBoolValForSuggestions(!boolValForSuggestions)
    }

    // Update selectedDates state
    setSelectedDates(prevDates => ({
      ...prevDates,
      [conditionCd]: {
        date: prevDates[conditionCd]?.date || "",
        surgeonName: surgeonName,
        hospitalName: prevDates[conditionCd]?.hospitalName || "",
        fileName: prevDates[conditionCd]?.fileName || "",
      },
    }));
  };
  const handleSurgeonNameEditChange = (surgeonName) => {
    let filteredValue = surgeonName.replace(/[^0-9a-zA-Z\s.]/g, "")
    setInputValues(filteredValue);

    // Filter surgeon names based on input
    if (filteredValue) {
      filterSurgeonNamesEdit(filteredValue);
    } else {
      setSuggestions([]);
      
    setBoolValForSuggestions(!boolValForSuggestions)
    }
    setSurgeonName(filteredValue);
  };
  const handleSelect = (conditionCd, suggestion) => {
    setInputValues(prevValues => ({
      ...prevValues,
      [conditionCd]: suggestion.doctorname
    }));
    setSuggestions(prevSuggestions => ({
      ...prevSuggestions,
      [conditionCd]: []
    }));
    
    setBoolValForSuggestions(!boolValForSuggestions)
    setSelectedDates(prevDates => ({
      ...prevDates,
      [conditionCd]: {
        date: prevDates[conditionCd]?.date || "",
        surgeonName: suggestion.doctorname,
        hospitalName: prevDates[conditionCd]?.hospitalName || "",
        fileName: prevDates[conditionCd]?.fileName || "",
      },
    }));
    setShowDocNamesDropdown(!showDocNamesDropdown)
  };
  const handleEditSelect = (suggestion) => {
    setSurgeonName(suggestion.doctorname);
    setSuggestions([]);
    
    setBoolValForSuggestions(!boolValForSuggestions)
    
    setShowDocNamesDropdown(!showDocNamesDropdown)
  };
  const handleHospitalNameChange = (conditionCd, hospitalName) => {
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [conditionCd]: {
        date: prevDates[conditionCd]?.date || "",
        surgeonName: prevDates[conditionCd]?.surgeonName || "",
        hospitalName,
        fileName: prevDates[conditionCd]?.fileName || "",
      },
    }));
  };
  const handleDocumentUpload = (conditionCd, hospitalName) => {
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [conditionCd]: {
        date: prevDates[conditionCd]?.date || "",
        surgeonName: prevDates[conditionCd]?.surgeonName || "",
        hospitalName,
      },
    }));
  };
  
 // const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
  
 var inputForAdd = {
  procedures: selectedSurgeries.map((condition, index) => {
    const procedure = {
      proceduredate: selectedDates[condition.condition_cd]?.date || todaysDate,
      proceduretype_cd: "1",
      procedure_cd: condition.condition_cd,
      proceduretitle: condition.condition,
      nomenclatureid: condition.condition_cd,
      hospitalid: "1",
      hospitalname: selectedDates[condition.condition_cd]?.hospitalName || "",
      surgeonname: selectedDates[condition.condition_cd]?.surgeonName || "",
      documenttype_cd: "",
      documentcategory_cd: "",
      documentname: "",
      documentsize: "",
      documenturl: "www.google.com",
      documents: uploadedDocuments
        .filter((doc) => doc.condition_cd == condition.condition_cd)
        .map(({ condition_cd, filename }) => ({
          condition_cd,
          filename,
        })),
    };

    if (condition.conditiontype) {
      procedure.proceduretype = condition.conditiontype;
    }

    return procedure;
  }),
};



  const AddMedicalConditions = () => {
    setInputValues({})
    if (selectedSurgeries.length === 0  && typedCondition == "") {
      showErrorToast("Please select a surgery to continue");
    } else {
      if (typedCondition !== "") {
        const newSelectedCondition = {
          condition:typedCondition,
          condition_cd:null,
          conditiontype:"other"
        };
    
        setSelectedSurgeries((prevConditions) => {
          // Filter out any existing condition with the same type
          const filteredConditions = prevConditions.filter(
            (item) => item?.conditiontype !== "other"
          );
          // Add the new condition
          return [...filteredConditions, newSelectedCondition];
        });
      }

      setSelectedDates({})
      setSurgeonName("")
      setBoolValForSuggestions(false)
      addSurgeriesModal.current.openRight();
    }
  };
  const AddSurgeriesCall = () => {

    const addSurgeriesFunction = async () => {
      showLoader();
      try {
          // inputForAdd.procedures.forEach((procedure, index) => {
          //   if (moment(procedure.proceduredate, ['DD-MM-YYYY', 'YYYY-MM-DD'], true).isValid()) {
          //     inputForAdd.procedures[index].proceduredate = moment(procedure.proceduredate, 'DD-MM-YYYY').format('YYYY-MM-DD');
          //   }
          // });
          inputForAdd.procedures.forEach((procedure, index) => {
            const inputFormat = ['DD-MM-YYYY', 'YYYY-MM-DD'];
            const parsedDate = moment(procedure.proceduredate, inputFormat, true);
            //alert(procedure.proceduredate)
            if (parsedDate.isValid()) {
                // Use the detected format to parse the date string
                const detectedFormat = parsedDate.format('YYYY-MM-DD');
               // alert(detectedFormat)
                // If the detected format matches the expected 'DD-MM-YYYY', convert to 'YYYY-MM-DD'
                if (detectedFormat) {
                    inputForAdd.procedures[index].proceduredate = parsedDate.format('YYYY-MM-DD');
                }
            }
        });
        
        //  console.log(inputForAdd , " input for add surgeries")
        const result = await addSurgeries(inputForAdd);
        //console.log(result)
        if (result.output.result == "Successfully Inserted") {
          showSuccessToast("Surgery added Successfully.")
          setTimeout(() => {
            addSurgeriesModal.current.closeRight();
            addCondition.current.closeRight();
          }, 700);
          fetchData();
        } else {
          showErrorToast("Something went wrong!")
        }
        dismissLoader();
      } catch (error) {
        console.error("Error in add Surgeries:", error);
      }
    };
    const isDateMissing = selectedSurgeries.some(
      (condition) => !selectedDates[condition.condition_cd]?.date
    );

    const isSurgeonNameMissing = selectedSurgeries.some(
      (condition) => !selectedDates[condition.condition_cd]?.surgeonName
    );

    const isHospitalNameMissing = selectedSurgeries.some(
      (condition) => !selectedDates[condition.condition_cd]?.hospitalName
    );

    // if (isDateMissing) {
    //   showErrorToast("Please select the Date.");
    //   return false;
    // } else if (isSurgeonNameMissing) {
    //   showErrorToast("Please Enter Surgeon Name");
    //   return false;
    // } else if (isHospitalNameMissing) {
    //   showErrorToast("Please Enter Hospital Name");
    //   return false;
    // } else {
      showLoader();
      addSurgeriesFunction();

    //}

  };

  const { showSuccessToast, showErrorToast } = Toaster();
  var inputForDelete = {
    id,
  };
  const deletePatientSurgeries = async () => {
    const deleteSurgeriesFunction = async () => {
      try {
        showLoader();
        const result = await deleteSurgeries(inputForDelete);
        if (result.output.result == "success") {
          showSuccessToast("Deleted Successfully")
          dynamicModal.current.closeModal();
          fetchData();
        } else {
          showErrorToast("Something went wrong!")
        }
        dismissLoader();
      } catch (error) {
        console.error("Error in DashboardIndex:", error);
      }
    };
    deleteSurgeriesFunction();

  };
  const openDeleteModal = (id, proceduretitle) => {
    setId(id);
    setProcedure(proceduretitle);

    dynamicModal.current.openModal();

  };

  const openEditModal = (id, doctorName, hospitalname, proceduredate, proceduretitle, documentsObj) => {

    var formatedDate = moment(proceduredate).format("DD-MM-yyyy");
    setDocumentURLForEdit([])
    if (documentsObj != null) {

      setFilenameforUpload(documentsObj?.map(doc => doc.documentname));
    } else  if (documentsObj == null) {
      setFilenameforUpload([]);
        }
           
    const date = new Date(proceduredate);

    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

    const formattedDate = date.toISOString().split("T")[0];


    setId(id);
    setSurgeonName(doctorName);
    setHospitalName(hospitalname);
    setProcedureTitle(proceduretitle)
    setProcedureDate(formatedDate);
    setDocumentURLForEdit(documentsObj)
    setShowDocNamesDropdown(false)
    addCondition3.current.openRight();
  };



  const handleChangeDate = (selectedDate) => {

    const formattedDate = format(selectedDate, "dd-MM-yyyy");
    setProcedureDate(formattedDate);
  };
  const handleChangeSurgeonName = (event) => {
    var value = event.target.value;

    setSurgeonName(value);
  };
  const handleChangeHospitalName = (event) => {
    var value = event.target.value;
    let formattedValue = value.replace(/[^0-9a-zA-Z\s.]/g, "")
    setHospitalName(formattedValue);
  };


  const EditSurgicalHistory = async () => {
    let formatedproceduredate = moment(procedureDate, 'DD-MM-YYYY').format('YYYY-MM-DD');



    var inputForEdit = {
      id: id,
      proceduredate: formatedproceduredate,
      // "procedure_cd": "",
      // "proceduretype_cd": "",
      // "proceduretitle": "",
      // "nomenclatureid": "",
      // "hospitalid": "",
      hospitalname: hospitalName,
      surgeonname: surgeonName,
      // "documenttype_cd": "",
      // "documentcategory_cd": "",
      // "documentname": "",
      // "documenturl": "",
      // "documentsize": ""

      //documents: filenameforUpload.map(filename => ({ filename }))
      documents: filenameforUpload.map(filename => ({ filename }))
    };
    const editSurgeriesFunction = async () => {
      showLoader();
      try {
        const result = await editSurgeries(inputForEdit);
        if (result.output.result == "Successfully Updated") {
          showSuccessToast("Updated Successfully")
          fetchData();
          addCondition3.current.closeRight();
        } else {
          showErrorToast("Something went Wrong");
        }
        dismissLoader();
      } catch (error) {
        console.error("Error in editSurgeriesFunction:", error);
      }
    };
    editSurgeriesFunction();

    setFileNameForUpdate('')
  };
  const openSurgeriesModal = () => {
    if (surgeriesList == null) {
      fetchSurgeriesList();
    }
    setSearchValue("");
    setSelectedSurgeries([]);
    setUploadedDocuments([]);
    setTypedCondition("")
    addCondition.current.openRight();
  };
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return moment(dateString).format('Do MMM, YYYY');
  };

  const fileInputRef = useRef(null);
  const fileInputRefUpdate = useRef(null);

  const handleSurgeriesUpload = async (e) => {
    e.preventDefault();
    showLoader();
    if(e.target.files.length == 0){
      dismissLoader();
      return;
    }
    const file2 = e.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
    if (!allowedTypes.includes(file2.type)) {
      showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG file.");
      setTimeout(() => {
        
        dismissLoader();
      }, 1000);
      return false;
    }
    const condition = e.target.getAttribute('data-surgery');
    let condition_cd = e.target.getAttribute('data-surgery-cd');
   if(condition_cd === "null"){
    condition_cd = null
   }
    const reader = new FileReader();
    reader.readAsDataURL(file2);
    const result = await uploadImageInProfile(file2, file2.name);
    const filename = result.name;
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [condition]: {
        date: prevDates[condition]?.date || "",
        surgeonName: prevDates[condition]?.surgeonName || "",
        hospitalName: prevDates[condition]?.hospitalName || "",
        fileName: filename,
      },
    }));
    setUploadedDocuments((prevDocuments) => [
      ...prevDocuments,
      { condition_cd, filename },
    ]);
    e.target.value = ''; 
    dismissLoader();

  };
// useEffect(()=>{
//   console.log(uploadedDocuments , " uploadedDocuments")
// },[uploadedDocuments])

  const addSurgeriesUploadDocument = (condition) => {
   // alert(condition.condition_cd)
    if(condition.condition_cd){
      
    fileInputRef.current.setAttribute('data-surgery-cd', condition.condition_cd);
    fileInputRef.current.setAttribute('data-surgery', condition.condition);
    fileInputRef.current.click();
    
  }else{
    
    fileInputRef.current.setAttribute('data-surgery-cd', null);
    fileInputRef.current.setAttribute('data-surgery',  "other");
    fileInputRef.current.click();
  }
  }

  const updateSurgeriesUploadDocument = (surgery_cd) => {

    fileInputRefUpdate.current.setAttribute('data-surgery-cd', surgery_cd);
    fileInputRefUpdate.current.click();
  }
  const handleSurgeriesUploadUpdate = async (e) => {
    e.preventDefault();

    const surgery_cd = e.target.getAttribute('data-surgery-cd');
    showLoader();
    if(e.target.files.length == 0){
      dismissLoader();
      return;
    }
    const file2 = e.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
    if (!allowedTypes.includes(file2.type)) {
      showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG file.");
      setTimeout(() => {
        
        dismissLoader();
      }, 1000);
      return false;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file2);
    const result = await uploadImageInProfile(file2, file2.name);
    const filename = result.name;

    setFilenameforUpload(prevFilenames => [...prevFilenames, filename]);
   // setDocumentURLForEdit(prevFilenames => [...prevFilenames, { documenturl: filename, imagepath: "https://midocfileserver.blob.core.windows.net/midocdev/" }]);
   setDocumentURLForEdit(prevFilenames => [
    ...(prevFilenames ?? []),
    { documentname: filename, documenturl: filename, imagepath: "https://midocfileserver.blob.core.windows.net/midocdev/" }
  ]);

  e.target.value = ''; 
    showSuccessToast("Document Added Successfully.")
    dismissLoader();

  };

  // useEffect(() => {
  //   setDocumentURLForEdit(filenameforUpload)
  // }, [filenameforUpload])


  const openDocumentsModal = (documentsObj, proceduretitle, proceduredate) => {
    
    setProcedureDate(proceduredate);
    setDocumentURLs(documentsObj)
    setProcedureTitle(proceduretitle)
    addCondition4.current.openRight()
  }
  const handleDelete = (index) => {
    const updatedUrls = [...uploadedDocuments];
    updatedUrls.splice(index, 1);
    setUploadedDocuments(updatedUrls);
  };
  const handleDeleteDocInEdit = (index) => {
    const updatedUrls = [...documentURLForEdit];
    updatedUrls.splice(index, 1);
   // setUploadedDocuments(updatedUrls);

    setDocumentURLForEdit(updatedUrls);

    if (updatedUrls != null) {

      setFilenameforUpload(updatedUrls?.map(doc => doc.documentname));
    }
  };

  const css = `
  .css-ddkw9a {
    top: -2%!important;
  }
`

const handleCondition = (e) => {
  const inputText = e.target.value;
  const maxLength = 80;

  if (inputText.length <= maxLength) {
    let formatted = inputText.replace(/[^0-9a-zA-Z\s.]/g, "")
    setTypedCondition(formatted);
  }

}
useEffect(() => {
  if (animate) {
    const timer = setTimeout(() => {
      setAnimate(false);
    }, 500);
    return () => clearTimeout(timer);
  }
}, [animate]);

useEffect(() => {
  // Scroll to bottom whenever aianswers changes
  if (chatContainerRef.current) {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }
}, [aianswers]);
const fetchAiDoctorPrompts = async (surgeryname)=>{
  showLoader();
  setRemainingQuestions([])
  setAiInput(null);
  setAIAnswers([])
  setInitialRender(true)
  const result = await FetchMyHealthAIData({
    patientid,
  });
  let Aiprompts = { ...result, surgeryname };
    setAiInput(Aiprompts);
    const response = await FetchSurgeriesAIPrompts(Aiprompts);
    if (response.generated_question) {
      setAIAnalysis(response);
      setRemainingQuestions(response.generated_question);
      AIChatModel.current.openRight();
    }else{
     // showErrorToast("Ai tokens are not available");
     setUpgradePlanModal(true)
     setTimeout(() => {
       setUpgradePlanModal(false)
     }, 100);
      AIChatModel.current.closeRight();
    }
    dismissLoader();
}

const GetAIAnswer = async (prompt) => {
  // Display question immediately
  const newQuestion = { question: prompt, answer: "" };
  setAIAnswers((prevState) => [...prevState, newQuestion]);

  // Remove the question from remaining questions immediately
  setRemainingQuestions((prevState) => prevState.filter((question) => question.value !== prompt));

  // Trigger animation
  setAnimate(true);

  // Set initialRender to false after the first question is clicked
  setInitialRender(false);
  let AichatInput = { ...AiInput, query:prompt };


  const response = await FetchSurgeriesAIAnswer(AichatInput);

  if (response) {
    // Replace "typing" with actual answer
    setAIAnswers((prevState) =>
      prevState.map((item) =>
        item.question === prompt && item.answer === ""
          ? { question: item.question, answer: response.answer }
          : item
      )
    );
    getTokens();
  }else {
    // showErrorToast("Ai tokens are not available");
    setUpgradePlanModal(true)
    setTimeout(() => {
      setUpgradePlanModal(false)
    }, 100);
    AIChatModel.current.closeRight();
  }
};
  return (
    <>
    <style>{css}</style>
    {screenType == 'web' ? (
      <Box w="100%" display={{base: "none", lg: "block"}}>
        <Box top="0" zIndex="banner">
          <Navbar  messageModal={upgradePlanModal}  />
          {isLoading && <Loader />}
          <SecondaryNav
            defaultHeader="My Health"
            defaultSecondayHeader="Surgeries"
            defaultNavBody={
              <>
                <Box display="flex" gap="10px" alignItems="center" pr="25px">
                  {data != null && (
                    <PrimaryButton
                      fontSize="14px"
                      variant="mdBtn"
                      buttonText="Add Surgery"
                      onClick={openSurgeriesModal}
                      btnIcon="/assets/imgs/add.png"
                    />
                  )}
                </Box>
              </>
            }
          />
        </Box>
        <Box h="calc(100vh - 140px)" overflow="hidden auto">
        <Flex>
          <Box position="sticky" top="0" zIndex="sticky" h="100vh">
            <SideBar />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap="20px"
            w="100%"
            
          >
            {data &&
            <Box w="100%" h="100%" p={4} overflow="hidden auto">
              <SimpleGrid
                columns={{base: 1, lg: 2, xl: 3}}
                gap="20px"
              >
                {data && (
                  Array.isArray(data) &&
                  data.map(
                    (
                      {
                        proceduretitle,
                        proceduredate,
                        id,
                        hospitalname,
                        surgeonname,
                        documents

                      },
                      innerIndex
                    ) => (
                      <Box
                        key={innerIndex}
                        flex="0 0 calc(33.333% - 20px)"
                        mb="20px"
                        border="1px solid #E6E7E9"
                        borderRadius="12px"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        overflow="hidden"
                      >
                        <Box
                          as="button"
                          p="16px"
                          w="100%"
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          h="100%"
                        >
                          <Box display="flex" alignItems="center">
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center">
                              <Text
                                fontSize="16px"
                                textAlign="left"
                                fontWeight="600"
                              >
                                {proceduretitle}
                              </Text>
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                textAlign="left"
                                color="#384052"
                              >
                                {formatDate(proceduredate)}
                              </Text>
                            </Box>
                          </Box>
                          <Box display='flex' flexDir='column' gap="8px" alignItems="flex-end">
                          <Box
                                  as="button"
                                  // onClick={}
                                  border="1px solid #E6E7E9"
                                  borderRadius="full"
                                  w="36px"
                                  h="auto"
                                  p="6px"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  onClick={()=>fetchAiDoctorPrompts(proceduretitle)}
                                >
                                  <Image w="26px" src="/assets/svgs/ai-star-outline.svg" />
                          </Box>
                          <Box display='flex' w="100%" gap='0.5rem' mt='1rem' justifyContent='flex-end'>
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              onClick={() =>
                                openEditModal(
                                  id,
                                  surgeonname,
                                  hospitalname,
                                  proceduredate,
                                  proceduretitle,
                                  documents
                                )
                              }
                            >
                              <Image w="13px" src="/assets/imgs/edit-gray.png" />
                            </Box>
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              onClick={() => openDeleteModal(id, proceduretitle)}
                            >
                              <Image w="13px" src="/assets/imgs/delete.png" />
                            </Box>
                          </Box>
                          </Box>
                        </Box>
                        {/* <Divider /> */}
                        <Box p="20px" pb="0" borderTop="1px solid #E6E7E9">
                          <Box>
                            <Box display="flex" gap="5px"><Image src="/assets/svgs/stethoscope.svg" /><Box>{surgeonname ? surgeonname : "-" }</Box></Box>
                            <Box display="flex" gap="5px"><Image src="/assets/svgs/hospital.svg" /><Box>{hospitalname ? hospitalname : "-"}</Box></Box>
                          </Box>
                          <Box
                            as="button"
                            my="15px"
                            w="100%"
                            border="1px solid #E6E7E9"
                            borderRadius="10px"
                            p="12px 16px"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            onClick={() => openDocumentsModal(documents, proceduretitle, proceduredate)}
                          >
                            <Box mr="8px" fontSize="14px" fontWeight="600" >
                              {documents ? documents.length : "0"} documents
                            </Box>
                            <Box>
                              <Image src="/assets/imgs/right-icon.png" />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )
                  ))}
              </SimpleGrid>
              {/* <Divider /> */}
            </Box>
            }

            {/* No Data page */}
            {data == null && (
              <Box
                w="100%"
                mt="-100px"
                h="100%"
                display="flex"
                flexDirection="column"
                gap="5px"
                justifyContent="center"
                alignItems="center"
              >
                <Image w="60px" src="/assets/svgs/surgical-nodata.svg" />
                <Box as="p">No data available</Box>
                <PrimaryButton
                  fontSize="14px"
                  variant="mdBtn"
                  buttonText="Add Surgery"
                  onClick={openSurgeriesModal}
                  btnIcon="/assets/imgs/add.png"
                />
              </Box>
            )}
          </Box>

        </Flex>
            <Footer />
        </Box>
        {/* <Divider /> */}
      </Box>
    ) : (
      //Mobile responsive start
      <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/myhealth')}
          header="Surgeries"
        />
      
      {data && data && (
        <Box pos="fixed" bottom="10%" display="flex" justifyContent="center" w="100%" zIndex="99">
          <PrimaryButton
            fontSize="14px"
            variant="mdBtn"
            buttonText="Add Surgery"
            btnIcon="/assets/imgs/add.png"
            onClick={openSurgeriesModal}
            />
        </Box>
      )}

        <Box w="100%" p="1.5rem" pb="5rem" h='calc(100vh - 190px)' overflow="auto">
          {data &&
              <SimpleGrid
                columns={{base: 1, sm: 2}}
                gap="10px"
              >
                {data && (
                  Array.isArray(data) &&
                  data.map(
                    (
                      {
                        proceduretitle,
                        proceduredate,
                        id,
                        hospitalname,
                        surgeonname,
                        documents

                      },
                      innerIndex
                    ) => (
                      <Box
                        key={innerIndex}
                        flex="0 0 calc(33.333% - 20px)"
                        mb="20px"
                        border="1px solid #E6E7E9"
                        borderRadius="12px"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        overflow="hidden"
                      >
                        <Box
                          as="button"
                          p="16px"
                          w="100%"
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          h="100%"
                        >
                          <Box display="flex" alignItems="center">
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center">
                              <Text
                                fontSize="16px"
                                textAlign="left"
                                fontWeight="600"
                              >
                                {proceduretitle}
                              </Text>
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                textAlign="left"
                                color="#384052"
                              >
                                {formatDate(proceduredate)}
                              </Text>
                            </Box>
                          </Box>
                          <Box display="flex" gap="10px">
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              onClick={() =>
                                openEditModal(
                                  id,
                                  surgeonname,
                                  hospitalname,
                                  proceduredate,
                                  proceduretitle,
                                  documents
                                )
                              }
                            >
                              <Image w="13px" src="/assets/imgs/edit-gray.png" />
                            </Box>
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              onClick={() => openDeleteModal(id, proceduretitle)}
                            >
                              <Image w="13px" src="/assets/imgs/delete.png" />
                            </Box>
                          </Box>
                        </Box>
                        {/* <Divider /> */}
                        <Box p="20px" pb="0" borderTop="1px solid #E6E7E9">
                          <Box>
                            <Box display="flex" gap="5px"><Image src="/assets/svgs/stethoscope.svg" /><Box>{surgeonname ? surgeonname : "-" }</Box></Box>
                            <Box display="flex" gap="5px"><Image src="/assets/svgs/hospital.svg" /><Box>{hospitalname ? hospitalname : "-"}</Box></Box>
                          </Box>
                          <Box
                            as="button"
                            my="15px"
                            w="100%"
                            border="1px solid #E6E7E9"
                            borderRadius="10px"
                            p="12px 16px"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            onClick={() => openDocumentsModal(documents, proceduretitle, proceduredate)}
                          >
                            <Box mr="8px" fontSize="14px" fontWeight="600" >
                              {documents ? documents.length : "0"} documents
                            </Box>
                            <Box>
                              <Image src="/assets/imgs/right-icon.png" />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )
                  ))}
              </SimpleGrid>            
            }

            {/* No Data page */}
            {data == null && (
              <Box w="100%" mt="-100px" h="100vh" display="flex" flexDirection="column" gap="5px" justifyContent="center" alignItems="center">
                <Image w="60px" src="/assets/svgs/health-icon.svg" />
                <Box as="p">No data available</Box>
                <PrimaryButton fontSize="14px" variant="mdBtn" buttonText="Add Surgery" btnIcon="/assets/imgs/add.png" onClick={openSurgeriesModal} />
              </Box>
            )}
        </Box>
      </Box>
    )}
      {/* No Data page */}
      {/* {data == null && (
        <Box w="100%" mt="-100px" h="100%" display="flex" flexDirection="column" gap="5px" justifyContent="center" alignItems="center">
          <Image w="60px" src="/assets/svgs/health-icon.svg" />
          <Box as="p">No data available</Box>
          <PrimaryButton fontSize="14px" variant="mdBtn" buttonText="Add Surgery" btnIcon="/assets/imgs/add.png"
            onClick={openSurgeriesModal} />
        </Box>
      )} */}



      <DynamicModal
        ref={dynamicModal}
        modalHeader="Delete Surgery?"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete Surgery "{procedure}"
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deletePatientSurgeries}
            />
          </>
        }
      />

      <ModalRight
        ref={addCondition}
        header="Add Surgical History"
        body={
          <>
            <SearchBar
              passInputPlace="Search"
              onChange={(e) => setSearchValue(handleAlphaNumericDotAndSpace(e.target.value))}
              searchValue={searchValue}
            />
            <RadioGroup>
              <Stack direction="column" gap="0">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  borderBottom="1px solid #e2e8f0"
                  py="15px"
                >
                  <Box display="flex" justifyContent="end" w="100%">
  <Stack direction="column" gap="0" w="100%">
    {surgeriesList && surgeriesList.length > 0 ? (
      surgeriesList.flatMap((category) =>
        category.data.filter((condition) =>
          condition.condition.toLowerCase().includes(searchValue.toLowerCase())
        )
      ).length > 0 ? (
        surgeriesList.flatMap((category) =>
          category.data.filter((condition) =>
            condition.condition.toLowerCase().includes(searchValue.toLowerCase())
          )
        ).map((condition) => (
          <label key={condition.condition_cd}>
            <Box
              display="flex"
              justifyContent="space-between"
              borderBottom="1px solid #e2e8f0"
              p="15px"
              pl="5px"
              minH="50px"
              width="100%"
              sx={{
                _hover: { border: "2px solid #384052", borderRadius: "10px" },
              }}
            >
              <Box
                key={condition.condition_cd}
                display="flex"
                alignItems="center"
              >
                <Text fontSize="16px" lineHeight="normal" fontWeight="600">
                  {condition.condition}
                  <br />
                  <Box
                    as="span"
                    fontSize="12px"
                    fontWeight="500"
                    color="#6A707D"
                  ></Box>
                </Text>
              </Box>
              <Box display="flex" justifyContent="end">
                <Checkbox
                  colorScheme="green"
                  sx={{
                    ".chakra-checkbox__control": {
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                    },
                  }}
                                    onChange={() =>
                                      handleCheckboxChange(condition)
                                    }
                  isChecked={selectedSurgeries.some(
                                      (item) =>
                                        item.condition_cd ===
                                        condition.condition_cd
                  )}
                ></Checkbox>
              </Box>
            </Box>
          </label>
        ))
      ) : (
        <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
          No matching surgeries found!
        </Box>
      )
    ) : (
      <p>Loading...</p>
    )}
     <Divider/>
          <br/>
          <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                   Other Surgical History
                  </Heading>
        <PrimaryInput
         inputPlace="Surgical History"
         variant="bothSide"
                onChange={handleCondition} inputValue={typedCondition}
                />
                <br/>
  </Stack>
</Box>
                </Box>
              </Stack>
            </RadioGroup>
            <ModalRight
              ref={addSurgeriesModal}
              header="Add Surgical History"
              body={
                <>
                  <Box display="flex" flexDir="column" gap="20px">
                    {selectedSurgeries.map((condition, index) => (
                      <Box key={index}>
                        <Text fontSize="16px" py="10px" fontWeight="600">
                          {condition.condition}
                        </Text>
                      
                        <DatePicker
                          disableFutureDates={true}
                          placeholderProp="Date"
                          defaultDate={todaysDate}
                          onDateChange={(e) => handleDatePickerChange(condition.condition_cd, e)}
                          calendarStlye={{ transform: "scale(0.9)", right: "-22px", top: "30px" }}
                        />
                        <Box w="100%" pos='relative' cursor="pointer">
                        <PrimaryInput
                          variant="bothSide"
                          inputType="text"
                          inputPlace="Surgeon Name"
                          inputValue={inputValues[condition.condition_cd] || ''}
                          onChange={(e) =>
                            handleSurgeonNameChange(condition.condition_cd, handleAlphaDotAndSpace(e.target.value, 50))
                          }
                          onClick={() =>
                            listOfSurgeonNames(condition.condition_cd, false)
                          }
                          //if the inputIdProp have id then it will focus, if id is null it will not focus, 
                          inputIdProp={inputId == condition.condition_cd ? "Active" : null}
                          boolProp={boolValForSuggestions}
                        /> 

                        

                        <Image top="33%" right="6.5%" w="15px" 
                            onClick={() =>
                              listOfSurgeonNames(condition.condition_cd, true)
                            }
                             pos='absolute' src="/assets/imgs/double-arrow.png" />
                           
                         {/* {suggestions[condition.condition_cd] && suggestions[condition.condition_cd].length > 0 && (
                    <List pos="absolute" w="85%" maxH="150px" overflow="hidden auto" bg="white" zIndex="999" borderTop="none" border="1px solid #E6E7E9" borderRadius="0 0 12px 12px">
                      {suggestions[condition.condition_cd].map((suggestion, index) => (
                        <ListItem
                          key={index}
                          onClick={() => handleSelect(condition.condition_cd, suggestion)}
                          cursor="pointer"
                          p="1rem"
                          py="0.5rem"
                          borderBottom="1px solid #E6E7E9"
                        >
                          <Text fontSize="0.875rem" fontWeight="500">{suggestion.doctorname}</Text>
                        </ListItem>
                      ))}
                    </List>
                  )} */}
                  {showDocNamesDropdown && suggestions[condition.condition_cd] && suggestions[condition.condition_cd].length > 0 && (
                            <Menu isOpen={true}>
                              <MenuButton as={Box} w="100%" display="block" pos="relative" zIndex="999">
                                <Box pos="absolute" top="100%" left="0" w="100%" bg="white" borderTop="none" border="1px solid #E6E7E9" borderRadius="0 0 12px 12px" />
                              </MenuButton>
                              <MenuList w="100%" borderRadius="0 0 12px 12px" zIndex="999" bg="white" maxH="150px" overflow="hidden auto">
                                {suggestions[condition.condition_cd].map((suggestion, index) => (
                                  <MenuItem
                                    key={index}
                                    onClick={() => handleSelect(condition.condition_cd, suggestion)}
                                    cursor="pointer"
                                    p="1rem"
                                    w="100%"
                                    py="0.5rem"
                                    borderBottom="1px solid #E6E7E9"
                                  >
                                    <Text fontSize="0.875rem" fontWeight="500">{suggestion.doctorname}</Text>
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </Menu>
                          )}
                         </Box>
                        <PrimaryInput
                          variant="downSide"
                          inputType="text"
                          inputPlace="Hospital Name"
                          onChange={(e) =>
                            handleHospitalNameChange(condition.condition_cd, e.target.value)
                          }
                        /> 
                        {documentURLs?.map((document, index) => {
                          if (document.condition_cd == condition.condition_cd || (document.condition_cd == null && condition.condition_cd == null)) {
                            if (!indexMap[document.condition_cd]) {
                              indexMap[document.condition_cd] = 1;
                            }
                            const docIndex = indexMap[document.condition_cd];
                            indexMap[document.condition_cd]++; // Increment index for next document with same condition_cd

                            return (
                              <Box key={index} mb="2" mt="2">
                                <Button
                                  as="a"
                                  rel="noopener noreferrer"
                                  w="100%"
                                  h="48px"
                                  borderRadius="10px"
                                  border="1px solid #E6E7E9"
                                  display="flex"
                                  justifyContent="space-between"
                                  bg="white"
                                  color="#1C4EC5"
                                  sx={{ _hover: { bg: "none" } }}
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    fontSize: "12px",
                                  }}
                                >
                                  Document {docIndex}
                                  <Box as="span" onClick={() => handleDelete(index)}>
                                    <Image src="/assets/imgs/delete.png" />
                                  </Box>
                                </Button>
                              </Box>
                            );
                          }
                        })}
                        <Box onClick={() => addSurgeriesUploadDocument(condition)} as="button" display="flex" alignItems="center" gap="10px" mt="5px" fontSize="14px" fontWeight="600" color="#1C4EC5" textAlign="left">
                          <Image w="11px" src="/assets/imgs/add-blue.png" />
                          Add Document
                        </Box>

                       <input
                          type='file'
                          id='file-upload'
                          onChange={(e) => handleSurgeriesUpload(e)}
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                        />
                      </Box>
                    ))}
                  </Box>
                </>
              }
              footerActions={
                <>
                  <Box w="100%" display="flex" flexDirection="row" gap="15px">
                    <PrimaryButton
                      buttonText="Add Surgery"
                      isDisabled={false}
                      onClick={AddSurgeriesCall}
                    />
                  </Box>
                </>
              }
            />
          </>

        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Continue"
                isDisabled={false}
                onClick={AddMedicalConditions}
              />
            </Box>
          </>
        }
      />



      <ModalRight
        ref={addCondition3}
        header="Edit Surgical History "
        body={
          <>
            <Box display="flex" flexDir="column" gap="20px">
              <Box>
                <Text fontSize="16px" py="10px" fontWeight="600">
                  {procedureTitle}
                </Text>
                <DatePicker
                  disableFutureDates={true}
                  defaultDate={procedureDate}
                  onDateChange={(selectedDateValue) => handleChangeDate(selectedDateValue)}
                  placeholderProp=" Date"
                  inputStyle={{borderRadius: "10px 10px 0 0"}} />
               
               <Box w="100%" pos='relative' cursor="pointer">
                <PrimaryInput
                keyProp="uniqueKey"
                  variant="bothSide"
                  inputType="text"
                  inputPlace="Surgeon Name"
                  defaultValue={surgeonName}
                  inputValue={surgeonName}
                  onChange={(e) =>
                    handleSurgeonNameEditChange(handleAlphaDotAndSpace(e.target.value, 50))
                  }
                  onClick={() =>
                    listOfSurgeonNamesEdit( false)
                  }
                  
                  inputIdProp={"SurgeonNameInEdit"}
                  boolProp={boolValForSuggestions}
                />
                 <Image top="33%" right="6.5%" w="15px" 
                            onClick={() =>
                              listOfSurgeonNamesEdit( true)
                            }
                             pos='absolute' src="/assets/imgs/double-arrow.png" />
                           
                 { showDocNamesDropdown &&  suggestions && suggestions.length > 0 && (
                            <Menu isOpen={true}>
                              <MenuButton as={Box} w="100%" display="block" pos="relative" zIndex="999">
                                <Box pos="absolute" top="100%" left="0" w="100%" bg="white" borderTop="none" border="1px solid #E6E7E9" borderRadius="0 0 12px 12px" />
                              </MenuButton>
                              <MenuList w="100%" borderRadius="0 0 12px 12px" zIndex="999" bg="white" maxH="150px" overflow="hidden auto">
                                {suggestions.map((suggestion, index) => (
                                  <MenuItem
                                    key={index}
                                    onClick={() => handleEditSelect(suggestion)}
                                    cursor="pointer"
                                    p="1rem"
                                    w="100%"
                                    py="0.5rem"
                                    borderBottom="1px solid #E6E7E9"
                                  >
                                    <Text fontSize="0.875rem" fontWeight="500">{suggestion.doctorname}</Text>
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </Menu>
                          )}
                         </Box>
                <PrimaryInput
                  variant="downSide"
                  inputType="text"
                  inputPlace="Hospital Name"
                  defaultValue={hospitalName}
                  inputValue={hospitalName}
                  onChange={handleChangeHospitalName}
                />

                {documentURLForEdit?.map((document, index) => (
                  <Box key={index} mb="2" mt="2">
                    <Button
                      // as="a"
                      // href={document.imagepath + document.documenturl}
                      // target="_blank"
                      rel="noopener noreferrer"
                      w="100%"
                      h="48px"
                      borderRadius="10px"
                      border="1px solid #E6E7E9"
                      display="flex"
                      justifyContent="space-between"
                      bg="white"
                      color="#1C4EC5"
                      sx={{ _hover: { bg: "none" } }}
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        fontSize: "12px",
                      }}
                    >
                      Document {index + 1}
                      
                       <Box as="span" onClick={() => handleDeleteDocInEdit(index)}>
                                    <Image src="/assets/imgs/delete.png" />
                                  </Box>
                    </Button>
                  </Box>
                ))}
                <Box onClick={() => updateSurgeriesUploadDocument(id)} as="button" display="flex" alignItems="center" gap="10px" mt="5px" fontSize="14px" fontWeight="600" color="#1C4EC5" textAlign="left">
                  <Image w="11px" src="/assets/imgs/add-blue.png" />
                  Add Document
                </Box>
                <input
                  type='file'
                  id='file-upload'
                  onChange={(e) => handleSurgeriesUploadUpdate(e)}
                  ref={fileInputRefUpdate}
                  style={{ display: 'none' }}
                />
              </Box>
            </Box>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Update Surgical History"
                isDisabled={false}
                onClick={EditSurgicalHistory}
              />
            </Box>
          </>
        }
      />

      <ModalRight
        ref={addCondition4}
        secondaryHeader={procedureTitle}
        subHeader={formatDate(procedureDate)}
        body={
          <Flex pt="15px" flexDirection="column" h="100%">
            {documentURLs?.map((document, index) => (
              <Box key={index} mb="2"
              onClick={()=> downloadDocument(document.imagepath + document.documenturl)}
              cursor="pointer"
             >
                <Button
                 rel="noopener noreferrer"
                  w="100%"
                  h="48px"
                  borderRadius="10px"
                  border="1px solid #E6E7E9"
                  display="flex"
                  justifyContent="space-between"
                  bg="white"
                  color="#1C4EC5"
                  sx={{ _hover: { bg: "none" } }}
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    fontSize: "12px",
                  }}
                >
                  Document {index + 1}
                  <Box as="span">
                    <Image src="/assets/imgs/download.png" />
                  </Box>
                </Button>
              </Box>
            ))}
            <Spacer />
         
          </Flex>
        }
      />
 <AiModal
        ref={AIChatModel}
      modalSize="420px"
      //icon="/assets/imgs/ai-doctor.png"
      header={<>
        <Box display="flex" flexDir='column' gap='5px'>
            <Box display='flex' gap='6px'>
              <Text fontSize="1rem"
                fontWeight="700" 
                backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)" 
                color="transparent" 
                backgroundClip="text"
              >Aira - AI</Text>
              <Box display='flex' alignItems='center' gap='5px' border='1px solid #0d7e54' bg='#d5ece3'  p='3px 5px' borderRadius='full'>
                <Image src='/assets/imgs/spark.png' /> 
                <Text fontSize='10px' fontWeight='500' color='#000'>{remainingTokens && remainingTokens.remainingtokens ? displayMillion_ThoudandValues(remainingTokens.remainingtokens):"0"} tokens available</Text>
              </Box>
            </Box>
            <Text fontSize="0.75rem" fontWeight="400">Chat with your AI health guide</Text>
          </Box>
      </>}
      headerStyle={{ p: "24px 32px", lineHeight: "normal" }}
      closeBtnStyle={{ top: "18px" }}
      footerStyle={{ maxH: "35%", overflow: "hidden auto", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      withoutBg={true}
      backDropBlur="10px"
      body={
        <>
          <Flex flexDirection="column" height="100%">
            <Box
              p="24px 32px"
              ref={chatContainerRef}
              className="chat-container"
              style={{ overflowY: "auto", maxHeight: 'calc(100vh - 160px)' }}
            >
              {initialRender && remainingquestions.length > 0 && (
                <Box>
                  {remainingquestions.map((step, index) => (
                    <Box
                      key={index}
                      as="button"
                      w="100%"
                      p="16px"
                      mb="16px"
                      fontSize="14px"
                      fontWeight="600"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius="10px"
                      border="1px solid #7795DC"
                      bg="#EAEDF5"
                      color="#163E9E"
                      onClick={() => GetAIAnswer(step.value)}
                      className={`question-box ${animate ? 'slide-in' : ''}`}
                    >
                      {step.value ? step.value : "-"}
                      <Image src="/assets/imgs/right-icon.png" />
                    </Box>
                  ))}
                </Box>
              )}
              {aianswers.map((step, index) => (
                <React.Fragment key={index}>
                  <Box
                    as="div"
                    bg="#061027"
                    p="12px 16px"
                    w="fit-content"
                    float="right"
                    borderRadius="10px 10px 1px 10px"
                    className={`answer-box ${animate ? 'slide-in' : ''}`}
                  >
                    <Text fontSize="14px" fontWeight="500" color="white">
                      {step.question ? step.question : "-"}
                    </Text>
                  </Box>

                  <Box
                    as="div"
                    my="20px"
                    w="fit-content"
                    display="flex"
                    alignItems="end"
                    float="left"
                    className={`answer-box ${animate ? 'slide-in' : ''}`}
                  >
                    <Image
                      bg="#061027"
                      p="4px"
                      mr="8px"
                      borderRadius="6px"
                      src="/assets/imgs/ai-chat-logo.png"
                    />
                    <Text
                      bg="#E6E7E980"
                      p="12px 16px"
                      fontSize="14px"
                      fontWeight="500"
                      color="#061027"
                      borderRadius="10px 10px 10px 1px"
                    >
                      {step.answer ? step.answer : <TypingLoader />}
                    </Text>
                  </Box>
                </React.Fragment>
              ))}
            </Box>
          </Flex>
        </>
      }
      footerActions={
        !initialRender && (
          <Box
            p="24px 32px"
            display="flex"
            w="100%"
            h="100%"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box w="100%">
              {remainingquestions.map((step, index) => (
                <Box
                  key={index}
                  as="button"
                  w="100%"
                  p="16px"
                  mb="16px"
                  fontSize="14px"
                  fontWeight="600"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius="10px"
                  border="1px solid #7795DC"
                  bg="#EAEDF5"
                  color="#163E9E"
                  onClick={() => GetAIAnswer(step.value)}
                  className={`question-box ${animate ? 'slide-in' : ''}`}
                >
                  {step.value ? step.value : "-"}
                  <Image src="/assets/imgs/right-icon.png" />
                </Box>
              ))}
            </Box>
          </Box>
        )
      }
      />
    </>
  );
};

export default SurgeriesIndex;
