import moment from 'moment';
export default function formAPIoxygensaturation({
  vitaladdedday,
  vitaladdedtime,
  vitalvalue,
  vitaladdeddate



}, type) {
  let dateFormat = "DD-MMM";
  let formattedDate = "";
  if (type) {
    if (type === 'Month') {
      dateFormat = "DD-MMM";
    } else if (type === 'Year') {
      dateFormat = "DD MMM, YY";
    } else if (type === 'Week') {
      dateFormat = "ddd";
    } else {
      dateFormat = "HH:mm";
    }
  }

  //to show time in the tooltip
  let dateFormatForTooltip = "DD-MMM HH:mm";
  let tooltipLabel = "";
  if (type) {
    if (type === 'Month') {
      dateFormatForTooltip = "DD-MMM HH:mm";
    } else if (type === 'Year') {
      dateFormatForTooltip = "DD MMM, YY HH:mm";
    } else if (type === 'Week') {
      dateFormatForTooltip = "ddd HH:mm";
    } else {
      dateFormatForTooltip = "HH:mm";
    }
  }


  if (type === "Today") {
    formattedDate = moment(vitaladdedtime, "HH:mm").format(dateFormat);
    tooltipLabel = moment(vitaladdeddate).format(dateFormatForTooltip);
  } else {
    formattedDate = moment(vitaladdedday, "DD-MM-YY").format(dateFormat);

    //to show time in the tooltip
    tooltipLabel = moment(vitaladdeddate).format(dateFormatForTooltip);
  }


  return Object.freeze({
    value: vitalvalue ? parseFloat(vitalvalue) : 0,
    label: formattedDate ? formattedDate : '',
    tooltipLabel: tooltipLabel ? tooltipLabel : '',


  });
}