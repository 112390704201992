import axios from 'axios';
import { BaseUrl } from '../../../appconfig/appconfig';
import  FetchData, { } from '../../client';
import formapimodalaccount from '../../domains/profile';
import {getLoginInfo, getUserID}  from '../../../auth';
import formapimodalRewards from '../../domains/profilerewards';
import formapimodalAITokens from '../../domains/profileaitoken';


const FetchRewards = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_getreward", inputData);
    //console.log(result, " db result for rewards")
     if (result.output) {
     var finalOutput = formapimodalRewards(result.output)
     }
     return finalOutput;

  } catch (error) {
    console.error('Error rewards data :', error);
    throw error;
  }
};
const FetchTokens = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_getaitokenhistory", inputData);
     if (result.output) {
     var finalOutput = formapimodalAITokens(result.output)
     }
     return finalOutput;

  } catch (error) {
    console.error('Error rewards data :', error);
    throw error;
  }
};

export {FetchRewards, FetchTokens};
