import { Box, Flex, Image, Text } from '@chakra-ui/react';
import React, { useState, useEffect, useRef } from 'react';
import AiModal from '../ai-modal';
import { FetchMyHealthAIData, FetchAIPrompts, FetchAIAnswer } from "../../middleware/services/home";
import TypingLoader from "../../components/typing-loader";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import {
  Toaster,
} from "../../components";
import { FetchTokens } from "../../middleware/services/profile/rewards";
import { displayMillion_ThoudandValues } from '../../auth';

export const AiDoctor = ({showUpgradePlanModal}) => {
  const AIChatModel = useRef();
  const chatContainerRef = useRef(null);
  const [aianswers, setAIAnswers] = useState([]);
  const [remainingquestions, setRemainingQuestions] = useState([]);
  const [aiquestion, setAIAnalysis] = useState(null);
  const [AiInput, setAiInput] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [patientid, setPatientid] = useState();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [animate, setAnimate] = useState(false);
  const { showSuccessToast, showErrorToast } = Toaster();
  const [remainingTokens, setRemainingTokens] = useState();


  useEffect(() => {
      getTokens(); 
  }, [])
  var input = {}
  var getTokens = async () => {
    try {
      var result = await FetchTokens(input);
      if (result) {
        setRemainingTokens(result)
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.log(error, "error in fetching account data")
    }
    //dismissLoader();
  }
  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [animate]);

  useEffect(() => {
    // Scroll to bottom whenever aianswers changes
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [aianswers]);

  const openmodel = async () => {
    showLoader();
    const result = await FetchMyHealthAIData({
      patientid,
    });
    console.log(result);
    let Aiprompts = { ...result, patientid };
    setAiInput(Aiprompts)
    const response = await FetchAIPrompts(Aiprompts);
    // console.log(response , " Ai response console ");
    if (response.generated_prompts) {
      setAIAnalysis(response);
      setRemainingQuestions(response.generated_prompts);
      AIChatModel.current.openRight();
    }else  if (response?.output?.message == "No credits are available"){
      //showErrorToast("No sufficient tokens available");
      showUpgradePlanModal(true)
    // setTimeout(() => {
    //   showUpgradePlanModal(false)
    // }, 100);
    }
    dismissLoader();
  };

  const GetAIAnswer = async (prompt) => {
    // Display question immediately
    const newQuestion = { question: prompt, answer: "" };
    setAIAnswers((prevState) => [...prevState, newQuestion]);

    // Remove the question from remaining questions immediately
    setRemainingQuestions((prevState) => prevState.filter((question) => question.value !== prompt));

    // Trigger animation
    setAnimate(true);

    // Set initialRender to false after the first question is clicked
    setInitialRender(false);

    let AichatInput = {
      prompt: prompt,
      input: {
        ...AiInput
      }
    };

    const response = await FetchAIAnswer(AichatInput);
    if (response.selected_prompt) {
      // Replace "typing" with actual answer
      setAIAnswers((prevState) =>
        prevState.map((item) =>
          item.question === prompt && item.answer === ""
            ? { question: item.question, answer: response.information }
            : item
        )
      );
      getTokens();
    }else{
      showErrorToast("Ai tokens are not available");
      AIChatModel.current.closeRight();
    }
  };

  return (
    <>
      <div>
        <Box as="button" onClick={openmodel} zIndex="1399" borderRadius="full" display="flex" alignItems="center" justifyContent="center" gap="5px" w="220px" h="50px" pos="fixed" top="60%" left={{lg: "80%", xl: "80%"}} backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)">
        <Image w="16px" src="/assets/imgs/white-spark.png" />
        <Box display="flex" flexDir='column'>
        <Text textAlign="left" fontSize="14px" fontWeight="700" color="white">Aira - AI</Text>
        <Text fontSize="0.75rem" fontWeight="400" color="white">Chat with your AI health guide</Text>
        </Box>
      </Box>
    </div>
      {isLoading && <Loader />}
      <AiModal
        ref={AIChatModel}
        modalSize="420px"
        //icon="/assets/imgs/ai-doctor.png"
        header={<>
          <Box display="flex" flexDir='column' gap='5px'>
            <Box display='flex' gap='6px'>
              <Text fontSize="1rem"
                fontWeight="700" 
                backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)" 
                color="transparent" 
                backgroundClip="text"
              >Aira - AI</Text>
              <Box display='flex' alignItems='center' gap='5px' border='1px solid #0d7e54' bg='#d5ece3'  p='3px 5px' borderRadius='full'>
                <Image src='/assets/imgs/spark.png' /> 
                <Text fontSize='10px' fontWeight='500' color='#000'>{remainingTokens && remainingTokens.remainingtokens ? displayMillion_ThoudandValues(remainingTokens.remainingtokens):"0"} tokens available</Text>
              </Box>
            </Box>
            <Text fontSize="0.75rem" fontWeight="400">Chat with your AI health guide</Text>
          </Box>
        </>}
        headerStyle={{ p: "24px 32px", lineHeight: "normal" }}
        closeBtnStyle={{ top: "18px" }}
        footerStyle={{ maxH: "35%", overflow: "hidden auto", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
        withoutBg={true}
        backDropBlur="10px"
        body={
          <>
            <Flex flexDirection="column" height="100%">
              <Box
                p="24px 32px"
                ref={chatContainerRef}
                className="chat-container"
                style={{ overflowY: "auto", maxHeight: 'calc(100vh - 160px)' }}
              >
                {initialRender && remainingquestions.length > 0 && (
                  <Box>
                    {remainingquestions.map((step, index) => (
                      <Box
                        key={index}
                        as="button"
                        w="100%"
                        p="16px"
                        mb="16px"
                        fontSize="14px"
                        fontWeight="600"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        borderRadius="10px"
                        border="1px solid #7795DC"
                        bg="#EAEDF5"
                        color="#163E9E"
                        gap='1rem'
                        textAlign='left'
                        onClick={() => GetAIAnswer(step.value)}
                        className={`question-box ${animate ? 'slide-in' : ''}`}
                      >
                        {step.value ? step.value : "-"}
                        <Image src="/assets/imgs/right-icon.png" />
                      </Box>
                    ))}
                  </Box>
                )}
                {aianswers.map((step, index) => (
                  <React.Fragment key={index}>
                    <Box
                      as="div"
                      bg="#061027"
                      p="12px 16px"
                      w="fit-content"
                      float="right"
                      borderRadius="10px 10px 1px 10px"
                      className={`answer-box ${animate ? 'slide-in' : ''}`}
                    >
                      <Text fontSize="14px" fontWeight="500" color="white">
                        {step.question ? step.question : "-"}
                      </Text>
                    </Box>

                    <Box
                      as="div"
                      my="20px"
                      w="fit-content"
                      display="flex"
                      alignItems="end"
                      float="left"
                      className={`answer-box ${animate ? 'slide-in' : ''}`}
                    >
                      <Image
                        bg="#061027"
                        p="4px"
                        mr="8px"
                        borderRadius="6px"
                        src="/assets/imgs/ai-chat-logo.png"
                      />
                      <Text
                        bg="#E6E7E980"
                        p="12px 16px"
                        fontSize="14px"
                        fontWeight="500"
                        color="#061027"
                        borderRadius="10px 10px 10px 1px"
                      >
                        {step.answer ? step.answer : <TypingLoader />}
                      </Text>
                    </Box>
                  </React.Fragment>
                ))}
              </Box>
            </Flex>
          </>
        }
        footerActions={
          !initialRender && (
            <Box
              p="24px 32px"
              display="flex"
              w="100%"
              h="100%"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box w="100%">
                {remainingquestions.map((step, index) => (
                  <Box
                    key={index}
                    as="button"
                    w="100%"
                    p="16px"
                    mb="16px"
                    fontSize="14px"
                    fontWeight="600"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    borderRadius="10px"
                    border="1px solid #7795DC"
                    bg="#EAEDF5"
                    color="#163E9E"
                    gap='1rem'
                        textAlign='left'
                    onClick={() => GetAIAnswer(step.value)}
                    className={`question-box ${animate ? 'slide-in' : ''}`}
                  >
                    {step.value ? step.value : "-"}
                    <Image src="/assets/imgs/right-icon.png" />
                  </Box>
                ))}
              </Box>
            </Box>
          )
        }
      />
      <style jsx>{`
        .question-box, .answer-box {
          animation: slide-in 0.5s forwards;
        }
        @keyframes slide-in {
          from {
            transform: translateY(20px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
    </>
  );
};
