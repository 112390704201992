import React, { useEffect, useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Image,
  FormControl,
  FormLabel
} from '@chakra-ui/react';

import {styles, variantStyles} from "./styles"
import { inputDesign, activeLabelStyles } from "../styles/theme";

const DropdownInputForVitals = ({inputValueProp,validationType ,onChange, dropDownPlace, options, variant, onClick ,maxLength,onKeyDown,type,id, defaultOption, inputStyleCss, readOnly = "readonly", className, menuList }) => {
  const variantStyle = variantStyles[variant] || variantStyles.defaultSide;
  const [inputValue, setInputValue] = useState(defaultOption ? defaultOption : "");
  const [showMenu, setShowMenu] = useState(false);
  const isLabelActive = inputValue != "" || inputValueProp !== "" || inputValueProp !== null;
  useEffect(() => {
    if (defaultOption) {
      setInputValue(defaultOption);
    }
  }, [defaultOption]);

  const handleInputKeyDown = (e) => {
      setShowMenu(false);
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  return (
    <Menu isOpen={showMenu} onClose={() => setShowMenu(false)} isLazy>
      <FormControl sx={inputDesign.formStyles.container} {...inputStyleCss}>
      <InputGroup>
        <Input
          placeholder=" "
          value={inputValueProp}
          defaultValue={defaultOption}
          sx={{ ...styles, ...variantStyle, ...inputDesign.formStyles.input }}
          // onChange={(e) => setInputValueFunction(e.target.value)}
          onChange={onChange}
          onFocus={() => setShowMenu(true)}
          onKeyDown={handleInputKeyDown}
          readOnly={readOnly}
          cursor="pointer"
          id={id}
          maxLength={maxLength}
          type={type}
          className={className}
        />
        <InputRightElement width="3rem">
          <MenuButton
            as={Button}
            h="1.75rem"
            bg="none"
            sx={{top:"5px", _hover: {bg: "none"}, _active: {bg: "none"}}}
            size="sm"
            onClick={() => setShowMenu(!showMenu)}
          >
            <Image w="15px" src="/assets/imgs/double-arrow.png" />
          </MenuButton>
        </InputRightElement>
      </InputGroup>
      <FormLabel sx={{
            ...inputDesign.formStyles.label,
            ...(isLabelActive && activeLabelStyles), // Conditionally apply activeLabelStyles
          }}>{dropDownPlace}</FormLabel>
      </FormControl>
      <MenuList zIndex="999" maxH="200px" overflowY="auto" {...menuList}>
        {/* <MenuItem onClick={() => setInputValue('Male')}>Male</MenuItem>
        <MenuItem onClick={() => setInputValue('Female')}>Female</MenuItem>
        <MenuItem onClick={() => setInputValue('Other')}>Other</MenuItem> */}
        {options.map((option, index) => (
        <MenuItem key={index} 
        // onClick={() => setInputValue(option)}
        onClick={() => { setInputValue(option); onClick(option);}}
        >
          {option}
        </MenuItem>
      ))}
      </MenuList>
    </Menu>
  );
};

export default DropdownInputForVitals;
