import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Divider,
  Flex,
  Image,
  Text,
  Center,
  VStack,
  Input,
  RadioGroup,
  Stack,
  Checkbox,
  Heading,
  Spacer,
  SimpleGrid,
  Menu,
  MenuButton,
  Radio,
} from "@chakra-ui/react";
import {
  PrimaryButton,
  PrimaryInput,
  DropdownInput,
  PrimaryInputDropdown,
  SearchBar,
  Toaster,
} from "../../components";
import SecondaryNav from "../../components/seconday-nav";
import ProfileSideBar from "./profileSidebar";
import Navbar from "../../components/navbar";
import DoctorCard from "../../components/doctor-card";
import ModalRight from "../../components/modal-right";
import MiniDropdownInput from "../../components/mini-dropdown";
import { addFamilyMember, getFamiliyList, updateFamilyMember } from "../../middleware/services/profile/family";
import { DeleteFamilyHistory } from "../../middleware/services/myhealth/familyhistory";
import { getmedicalcondition } from "../../middleware/services/signup";
import { format } from "date-fns";
import DatePicker from "../../components/date-picker";
import { uploadImageInProfile } from "../../middleware/services/profile/account";
import Loader from "../../components/loader/loader";
import useLoader from "../../components/loader/loaderstates";
import moment from "moment";
import Footer from "../../components/footer"
import DynamicModal from "../../components/dynamic-modal";
import { getmartialstatus } from "../../middleware/services/myhealth/familyhistory";
import { json, useNavigate } from "react-router-dom";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { cmToFtConverter, ftToCmConverter,  kgToLbConverter, lbToKgConverter,   getLoginInfo, validationMobileNumber, } from '../../auth'
import DropdownInputForVitals from "../../components/dropdown-input-forVitals";



function FamilyIndex() {
  const addMember = useRef();
  const editMember = useRef();
  const Medicalconditionsmodel = useRef();
  const allergiesModal = useRef();
  var [patientid, setPatientid] = useState();
  const [firstname, setFirstName] = useState("")
  const [lastname, setLastname] = useState("")
  const [dob, setDob] = useState(null)
  const [gender, setGender] = useState("")
  const [relation, setRelation] = useState("")
  const [relation_cd, setRelation_cd] = useState("")
  const [email, setEmail] = useState("")
  const [mobileno, setMobileno] = useState("")
  const [bloodtype_cd, setBloodtype_cd] = useState("")
  const [height, setHeight] = useState(null)
  const [heightunit_cd, setHeightunit_cd] = useState('151')
  const [weight, setWeight] = useState(null)
  const [weightunit_cd, setWeightunit_cd] = useState('154')
  const [medicationname, setMedicationname] = useState("");
  const [familyimage, setfamilyimage] = useState('');

  var [allergiesdata, setAllergiesData] = useState(null);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [imageUpdated, setImageUpdated] = useState(false);
  const [familyMemberAdded, setFamilyMemberAdded] = useState(false)
  const [selectedAllergies, setSelectedAllergies] = useState([]);
  const [familyid, setFamilyId] = useState("")
  const [datePrePopulate, setDatePrePopulate] = useState('');
  const [imageBoolVal, setImageBoolVal] = useState(false);
  const [data, setData] = useState('');
  const [conditionsList, setConditionsList] = useState("");
  const [searchValue, setSearchValue] = useState('');
  const [imageUrl, setImageUrl] = useState("/assets/imgs/no-image-icon.png");
  const [imageAdded, setImageAdded] = useState(false);
  
  var [martialstatusdata, setMartialstatusData] = useState([]);
  
  var [martialstatuscomplete, setMartialstatusCompleteData] = useState([]);
  
  const [maritalstatus_cd, setMaritalstatusID] = useState(null);
  const [adopted, setAdoptedcheckbox] = useState(false);
  const [deceased, setdeceasedcheckbox] = useState(false);
  const [maritalstatus, setMaritalstatus] = useState("");

  const { showSuccessToast, showErrorToast } = Toaster();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [id, setID] = useState('');
  const [title, setTitle] = useState('');
  
 const [date, setTodaysDate] = useState(moment().format("YYYY-MM-DD"));
 const [time, setTime] = useState(moment().format("HH:mm"));

    //BMI states 

    const [vitalunitheight, setVitalUnitHeight] = useState("");
    const [vitalunitheight_cd, setVitalUnitHeight_cd] = useState("");
    const [BMICalculatorType, setBMICalculatorType] = useState('standard');
  
    const [vitalunitweight, setVitalUnitWeight] = useState("");
    
    const [feetValue , setFeetValue] = useState(null)
    const [inchValue , setInchValue] = useState(null)
    
    const [heightOptionsInBMI, setHeightOptionsInBMI] = useState([]);
    const [weightOptionsInBMI, setWeightOptionsInBMI] = useState([]);
    
   const [ weightUnitBMI , setWeightUnitBMI ]= useState("")
   const [ heightUnitBMI , setHeightUnitBMI ]= useState("")
   const [showInchesInput , setShowInchesInput] = useState(false)
   const [feetOptions, setFeetOptions] = useState([]);
   const [inchesOptions, setInchesOptions] = useState([]);

   const [inchToCalculate , setInchToCalculate] = useState("")

   const [typedCondition , setTypedCondition] = useState("")
   const [isAdd , setIsAdd] = useState(true)
   const [typedAllergy , setTypedAllergy] = useState("")
   const [allergiesContinueBtnDisabled, setAllergiesContinueBtnDisabled] = useState(true)
   
const [defaultCountryCode ,setDefaultCountryCode ] = useState("")
  
  const dynamicModal = React.useRef();
  const navigate = useNavigate();

  const relationOptions = [
    { label: 'Father', value: '272' },
    { label: 'Mother', value: '273' },
    { label: 'Brother', value: '274' },
    { label: 'Sister', value: '275' },
    { label: 'Grandfather', value: '276' },
    { label: 'Grandmother', value: '277' },
    { label: 'Uncle', value: '278' },
    { label: 'Aunt', value: '279' },
    { label: 'Cousin', value: '280' },
    { label: 'Husband', value: '281' },
    { label: 'Wife', value: '282' },
    { label: 'Spouse', value: '283' },
    { label: 'Father-in-law', value: '284' },
    { label: 'Mother-in-law', value: '285' },
    { label: 'Brother-in-law', value: '286' },
    { label: 'Sister-in-law', value: '287' },
    { label: 'Son', value: '288' },
    { label: 'Daughter', value: '289' },
    { label: 'Grandson', value: '290' },
    { label: 'Granddaughter', value: '291' },
    { label: 'Nephew', value: '292' },
    { label: 'Niece', value: '293' },
    { label: 'Godfather', value: '294' },
    { label: 'Godmother', value: '295' },
    { label: 'Godson', value: '296' },
    { label: 'Goddaughter', value: '297' },
    { label: 'Stepfather', value: '298' },
    { label: 'Stepmother', value: '299' },
  ];


  useEffect(() => {
    fetchData();
    
  }, [imageUpdated, familyMemberAdded]);


  useEffect(() => {
    if(imageAdded){
      addfamilyMemberImage();
      setImageAdded(false);
    }
  }, [imageAdded]);


  const fetchData = async () => {
    showLoader();
    try {
      var result = await getFamiliyList(input)
      if(result && result.output && result.output.result == "failure"){
        setData(null)
      }else if (result == null) {
        setData(null)
      } else if (result != null) {
        setData(result);
      }else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.log(error, "error in fetchin g familiy data")
    }
    dismissLoader();
  };

  const fetchAllergiesData = async () => {
    try {
      const response = await getmedicalcondition({
        type: "allergies",
        "patientid":getLoginInfo()
      });
      if (response.output.result == "success") {
        setAllergiesData(response.output.data);
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const fetchMedicalConditions = async () => {
    try {
      const result = await getmedicalcondition({
        type: "healthcondition",
        "patientid":getLoginInfo()
      });
      if (result.output.result == "success") {
        setConditionsList(result.output.data);
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const addfamilyMemberImage = () => {
    
    const updateFamilyImageFunc = async () => {
      showLoader();
      try {
        var result = await updateFamilyMember(inputForImageUpload);
        if(result.output.result == "success"){
          setImageUpdated(!imageUpdated)
        }else {
          showErrorToast("Something went wrong!")
        }
        dismissLoader();
      } catch (error) {
        console.log(error, "error in fetchin g familiy data image")
      }
    }
    updateFamilyImageFunc();
    fetchData();

  };



  var input = {

  }

  const addfamilyMember = () => {
    var inputForAdd = {
      mobileno,
      email,
      firstname,
      lastname,
      "fullname": firstname + " " + lastname,
      gender,
      "height":null,
      heightunit_cd,
      "weight": weight ? weight : null,
      weightunit_cd,
      dob,
      bloodtype_cd,
      relation,
      relation_cd,
      
    "vitaladdeddate": `${date} ${time}`,
      "maritalstatus_cd": maritalstatus_cd,
      "isadopted": adopted === false ? 0 : 1,
      "isdeceased": deceased === false ? 0 : 1,
      medicalcondition: selectedConditions?.map((condition, index) => ({
        //conditiontype_cd: index + 1, // Assuming index + 1 is the desired value
        condition_cd: condition.condition_cd,
        nomenclatureid: condition.condition_cd, 
        conditionidentifieddate: new Date().toISOString(), 
        
      "condition":condition.condition,
      "conditiontype":condition.category.conditiontype
      })),
      allergies: selectedAllergies?.map((allergy, index) => ({
          allergytype_cd: allergy.condition_cd, 
        allergy_cd: allergy.condition_cd,
        nomenclatureid: allergy.condition_cd, 
        allergyidentifieddate: new Date().toISOString(), 
        
      "condition":allergy.condition,
      ...(allergy.conditiontype && { conditiontype: allergy.conditiontype }),
      })),
      "medication": [
        {
          "medicationtype_cd": 1,
          "medication_cd": 1,
          "nomenclatureid": 1,
          medicationname,
          "starteddate": "2023-01-15T00:00:00",
          "endeddate": "2023-03-15T00:00:00"
        }
      ]
    }

    const addFamilyFunc = async () => {
      
        try {
          showLoader();
          if(height){
            inputForAdd.height = height;
          }else if (feetValue || inchValue){
            
            inputForAdd.height = feetValue && inchValue ? feetValue + "." + inchValue : feetValue;
          }
          
          var countryCode = localStorage.getItem("countrycode")
          var countryId = localStorage.getItem("countryid")
          
          inputForAdd.countrycode = countryCode
          inputForAdd.countryid = countryId

          var result = await addFamilyMember(inputForAdd)
          if (result.output.result == "success") {
            setFamilyMemberAdded(!familyMemberAdded)
            showSuccessToast("Member Added Successfully.")
            fetchData();
            addMember.current.closeRight();
          } else {
            showErrorToast("Something went wrong!")
          }
          dismissLoader();
        } catch (error) {
          console.log(error, "error in fetchin g familiy data")
        }
      
    }
    //setFamilyMemberAdded(!familyMemberAdded)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email !== "" && !emailRegex.test(email)) {
        showErrorToast("Enter a valid Email");
    } else {
      if(firstname == ""){
        showErrorToast("Please enter first name");
        return false;
      }else if(lastname == ""){
        showErrorToast("Please enter last name");
        return false;
      }else if(dob == null){
        showErrorToast("Please select date of birth");
        return false;
      }else if(relation_cd == ""){
        showErrorToast("Please select relationship");
        return false;
      }else if (BMICalculatorType == "standard" &&  (inchValue && ( inchValue < 0 || inchValue > 12)) ) {
        showErrorToast("Please enter a valid inch value");
        return false;
      }else {
        addFamilyFunc();
        fetchData();
      }
    
    }

  }


  const editfamilyMember = () => {
    var inputForUpdate = {
      familyid,
      mobileno,
      email,
      firstname,
      lastname,
      // "fullname": "Doe John",
      gender,
      "height":null,
      heightunit_cd,
      "weight": weight ? weight : null,
      weightunit_cd,
    "vitaladdeddate": `${date} ${time}`,
      // "age": 35,
      dob,
      bloodtype_cd,
      // "vaccinated": "No",
      // "allergyidentifieddate": "2024-02-15",
      medicalcondition: selectedConditions?.map((condition, index) => ({
        //conditiontype_cd: index + 1, // Assuming index + 1 is the desired value
        condition_cd: condition.condition_cd,
        nomenclatureid: condition.condition_cd, // You may adjust this value accordingly
        conditionidentifieddate: new Date().toISOString(), // Current date as an example
        
        "condition":condition.condition,
        "conditiontype":condition?.category?.conditiontype
      })),
      //"familyimage": "https://example.com/family.jpg",
      relation,
      relation_cd,
      
      "maritalstatus_cd": maritalstatus_cd,
      "isadopted": adopted === false ? 0 : 1,
      "isdeceased": deceased === false ? 0 : 1,
      allergies: selectedAllergies?.map((allergy, index) => ({
        allergytype_cd: allergy.condition_cd, 
        allergy_cd: allergy.condition_cd,
        nomenclatureid: allergy.condition_cd, // You may adjust this value accordingly
        allergyidentifieddate: new Date().toISOString(), // Current date as an example

        condition_type: allergy.condition_type ,
        "condition":allergy.condition,
        ...(allergy.conditiontype && { conditiontype: allergy.conditiontype }),
      })),
      medication: [
        {
          medicationtype_cd: 1,
          medication_cd: 1,
          nomenclatureid: 1,
          medicationname,
          starteddate: "2023-01-15T00:00:00",
          endeddate: "2023-03-15T00:00:00"
        }
      ]
    }

    const editFamilyFunc = async () => {
      if(firstname == ""){
        showErrorToast("Please enter first name")
        return false;
      }else if(lastname == ""|| lastname == null){
        showErrorToast("Please enter last name")
        return false;
      }else if(relation_cd == ""){
        showErrorToast("Please select any relation")
        return false;
      }else if (BMICalculatorType == "standard" &&  (inchValue && ( inchValue < 0 || inchValue > 12)) ) {
        showErrorToast("Please enter a valid inch value");
        return false;
      }else{
      try {
        showLoader();
        if( BMICalculatorType == "standard" && feetValue){
          
          inputForUpdate.height = feetValue && inchValue ? feetValue + "." + inchValue : feetValue;
        }else if ( BMICalculatorType == "metric" && height){
          inputForUpdate.height = height;
        }
        if(inputForUpdate.height == ""){
          inputForUpdate.height = null;
        }
        
         var countryCode = localStorage.getItem("countrycode")
         var countryId = localStorage.getItem("countryid")
         
         inputForUpdate.countrycode = countryCode
         inputForUpdate.countryid = countryId
         console.log(inputForUpdate , " inputForUpdate")
       var result = await updateFamilyMember(inputForUpdate);
        setFamilyMemberAdded(!familyMemberAdded)
        if (result.output.result == "success") {
          var patientDetails = localStorage.getItem("patientDetails")
          if(patientDetails){
            
          var formateddata = JSON.parse(patientDetails)
           var activeUserId = formateddata.activeuserid;
           if(activeUserId != null && activeUserId == familyid){
            localStorage.setItem("username" , firstname +" "+ lastname)
          }

          }
          
          showSuccessToast("Details Updated Successfully")
          fetchData();
          editMember.current.closeRight();
        //  window.location.reload();
        } else {
          showErrorToast("Something went wrong!")
        }
        dismissLoader();
      } catch (error) {
        console.log(error, "error in fetching edit familiy data")
      }
    }
    }
   

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email !== "" && !emailRegex.test(email)) {
        showErrorToast("Enter a valid Email");
    } else {
      setFamilyMemberAdded(!familyMemberAdded)
      editFamilyFunc();
      fetchData();
    }

  }

  var inputForImageUpload = {
    familyid,
    familyimage,
  }





  const handleFirstName = (e) => {
    setFirstName(e.target.value.replace(/[^a-zA-Z\s.]/g, "").slice(0,50))
  }
  const handleLastName = (e) => {
    setLastname(e.target.value.replace(/[^a-zA-Z\s.]/g, "").slice(0,50))
  }
  const handledob = (selectedDate) => {
    const formattedDate = format(selectedDate, "yyyy-MM-dd");
    setDob(formattedDate)
  }
  const handleEmail = (e) => {
    setEmail(e.target.value)
  }
  const handleMobile = (e) => {
    const inputText = e.target.value;
    const maxLength = 10;
    let valid =  validationMobileNumber(inputText, maxLength)
    setMobileno(valid);
    // if (inputText.length <= maxLength) {
    //   setMobileno(inputText);
    // }

  }
  const selectGender = (selectedOption) => {
    if (selectedOption == "Male") {
      setGender("2")
    } else if (selectedOption == "Female") {
      setGender("1")
    } else if (selectedOption == "Other") {
      setGender("3")
    }
  }

  const selectRelation = (selectedOption) => {
    const selectedRelation = relationOptions.find(option => option.label === selectedOption);

    if (selectedRelation) {
      setRelation_cd(selectedRelation.value);
    }
  };
  const selectBloodType = (selectedOption) => {

    if (selectedOption == "A+") {
      setBloodtype_cd("158")
    } else if (selectedOption == "A-") {
      setBloodtype_cd("159")
    } else if (selectedOption == "B+") {
      setBloodtype_cd("160")
    } else if (selectedOption == "B-") {
      setBloodtype_cd("161")
    } else if (selectedOption == "AB+") {
      setBloodtype_cd("162")
    } else if (selectedOption == "AB-") {
      setBloodtype_cd("163")
    } else if (selectedOption == "O+") {
      setBloodtype_cd("164")
    } else if (selectedOption == "O-") {
      setBloodtype_cd("165")
    }
  }

  const handleSetHeight = (event) => {


    let cleanedValue = event.target.value.replace(/[^0-9.]/g, '');

    if (cleanedValue.match(/[0-9]{4,}/)) {
      cleanedValue = cleanedValue.slice(0, -1);
    }
    const decimalIndex = cleanedValue.indexOf('.');
    if (decimalIndex !== -1) {
      const parts = cleanedValue.split('.');
      cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
    }

    setHeight(cleanedValue);

  };

  const handleChangeHeightUnit = (selectedOption) => {
    setHeightunit_cd(selectedOption.value);
  };

  const handleSetWeight = (event) => {


    let cleanedValue = event.target.value.replace(/[^0-9.]/g, '');

    if (cleanedValue.match(/[0-9]{4,}/)) {
      cleanedValue = cleanedValue.slice(0, -1);
    }
    const decimalIndex = cleanedValue.indexOf('.');
    if (decimalIndex !== -1) {
      const parts = cleanedValue.split('.');
      cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
    }

    setWeight(cleanedValue);
  };
  const handleChangeWeightUnit = (selectedOption) => {
    setWeightunit_cd(selectedOption.value);
  };

  const openMedicalConditionsModal = () => {
    // if (conditionsList == "") {
      fetchMedicalConditions();
    // }
    setTypedCondition("")
    setTypedAllergy("")
    Medicalconditionsmodel.current.openRight()
  }
  const openAllergiesModal = () => {
    // if (allergiesdata == null) {
      fetchAllergiesData();
    // }
    allergiesModal.current.openRight()
  }





  const handleCheckboxChange = (condition, category) => {
    const isSelected = selectedConditions?.some(
      (item) => item.condition_cd == condition.condition_cd
    );

    if (isSelected) {
      setSelectedConditions((prevConditions) =>
        prevConditions.filter(
          (item) => item.condition_cd !== condition.condition_cd
        )
      );
    } else {
        setSelectedConditions((prevConditions) => {
          if (!prevConditions) {
            prevConditions = [];
          }
          return [...prevConditions, { ...condition, category }];
        });
        
    }
  };



  //Allergies
  const handleAllergiesCheckboxChange = (condition) => {
    const isSelected = selectedAllergies?.some(
      (item) => item.condition_cd === condition.condition_cd
    );

    if (isSelected) {
      setSelectedAllergies((prevConditions) =>
        prevConditions.filter(
          (item) => item.condition_cd !== condition.condition_cd
        )
      );
    } else {
     setSelectedAllergies((prevConditions = []) => [
      ...prevConditions,
      condition
    ]);
     
      
    }
  };

  


  const medicationsHandleChange = (event) => {
    setMedicationname(event.target.value);
  };




  const inputRef = useRef(null);
  const changeImage = (familyid) => {
    if (inputRef.current) {
      inputRef.current.click(); 
    }
    setFamilyId(familyid)
    setImageBoolVal(!imageBoolVal)
  };
  const handleimageselection = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];

  const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
    if (!allowedTypes.includes(file.type)) {
      showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG file.");
      return false
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(file);
    const result = await uploadImageInProfile(file, file.name);
    var srcInResult = result.name;
    setfamilyimage(srcInResult)
    showSuccessToast("Image uploaded successfully.")
    setImageAdded(true)
  };

  const selectFamilyMemberImage = (familiyid) => {
    document.getElementById("file-upload").click();
    setFamilyId(familiyid)
  }


  const openEditModal = async (details) => {
    // BMI States on initial page load -
    setTypedCondition("")
    setTypedAllergy("")
    setIsAdd(false)
   // console.log(details, " details")
    setDefaultCountryCode(details.countryid)
    setVitalUnitHeight("ft");
    setVitalUnitWeight("lbs");

    setBMICalculatorType("standard")
    const tempOptions = [];
    const tempOptionsInches = [];
    const tempOptionsHeight = [];
    const tempOptionsWeightLB = [];
    for (let i = 1; i <= 10; i++) {
      tempOptions.push(i);
    }
    for (let i = 1; i <= 11; i++) {
      tempOptionsInches.push(i);
    }
    for (let i = 1; i <= 10; i++) {
      tempOptionsHeight.push(i);
       for (let j = 1; j <= 11; j++) {
        tempOptionsHeight.push((`${i}.${j}`));
            }
    }
    for (let i = 1; i <= 479; i++) {
      tempOptionsWeightLB.push(i);
    }
    setFeetOptions(tempOptions)
    setInchesOptions(tempOptionsInches)
    setHeightOptionsInBMI(tempOptionsHeight)
    setWeightOptionsInBMI(tempOptionsWeightLB)

    if(details.weightunit_cd && details.weightunit_cd == 154){
   
      var converterValue = kgToLbConverter(details.weight)
      setWeight(converterValue.value)
    }else{
    setWeight(details.weight)
    }
  
    if(details.heightunit_cd && details.heightunit_cd == 151){
      
      var converterValue = cmToFtConverter(details.height)
      setFeetValue(converterValue.feet)
      setInchValue(converterValue.inches)
      
    setInchToCalculate(converterValue.inchesToCalculate)
    }else if(details.heightunit_cd && details.heightunit_cd == 152){
      if(details.height){
        
      let parts = details.height.toString().split('.');
      setFeetValue(parts[0])
      setInchValue(parts[1])
      
    setInchToCalculate(parts[1])
    
  }
    }
    
    setFamilyId(details.familyid)
    setFirstName(details.firstname ? details.firstname : "-")
    setLastname(details.lastname ? details.lastname : "-")
    if(details.dob){
      setDob(details.dob)
    }else{
      setDob(null)
    }
    if (details.dob !== null) {
      var formatedDate = moment(details.dob).format("DD-MM-yyyy");
      setDatePrePopulate(formatedDate)
    } else {
      setDatePrePopulate("")
    }
    setGender(details.gender_cd)
    setHeight(details.height)

    //setHeightunit_cd(details.heightUnit)
   


   // setWeightunit_cd(details.weight_unit)
   
    
    setRelation(details.relation)
    setRelation_cd(details.relation_cd)
    
    setMobileno(details.mobileno)
    if(details.email != null){

      setEmail(details.email)
    }
    setBloodtype_cd(details.bloodtype_cd)
    const medicationArray = details.medications
    var MedicactionName = medicationArray.map(detail => detail.medicationname);
    //console.log(MedicactionName, "medication name")
    setMedicationname(MedicactionName[0])
    // setHeightunit_cd(details.heightunit_cd)
    setHeightunit_cd(152);
   // setWeightunit_cd(details.weightunit_cd)
    setWeightunit_cd(155); 

    const allergiesArray = details.allergies
    var allergienames = allergiesArray?.map(detail => detail.allergy)
    const selectedAllergies = allergiesArray?.map((allergy) => ({
      condition: allergy.allergy,
      condition_cd: allergy.allergy_cd,
      nomenclatureid: allergy.nomenclatureid, 
      allergyidentifieddate: allergy.allergyidentifieddate, 

      allergytype: allergy.allergytype
    }));

    setSelectedAllergies(selectedAllergies);

    const conditionsArray = details.medicalcondition
    var conditionnames = conditionsArray?.map(detail => detail.condition)

    setSelectedConditions(conditionsArray);


    var details1 = details.medicalcondition
    const conditions = details1?.map(detail => detail.condition);

     //Martial status
     try {
      const result = await getmartialstatus({
        type: "maritalstatus",
      });
      //console.log(result,"status");
      if (result != null) {
        const formattedOptions = result.output.data[0].data.map(option => (
          [option.condition]     
        ));
        setMartialstatusData(formattedOptions);
      }
      setMartialstatusCompleteData(result.output.data[0].data);
    } catch (error) {
      console.error("Error", error);
    }
    setMaritalstatus(details.maritalstatus)
    setAdoptedcheckbox(details.isadopted == "1" ? true : false)
    setdeceasedcheckbox(details.isdeceased == "1" ? true : false)

    editMember.current.openRight();

  }

  const openAddModal = async () => {
    setTypedCondition("")
    setTypedAllergy("")
    setIsAdd(false)

    setFirstName("")
    setLastname("")
    setGender(0)
    setDob(null)
    setMobileno("")
    setEmail("")

    setRelation_cd("")
    setBloodtype_cd("")

    setHeight(null)
    setWeight(null)
  //  setHeightunit_cd("151")
   // setWeightunit_cd("154")
    setHeightunit_cd(152)
    setWeightunit_cd(155)
    setSelectedConditions([])
    setMedicationname("")
    setSelectedAllergies([])

    // BMI States on initial page load -
    setVitalUnitHeight("ft");
    setVitalUnitWeight("lbs");

    setBMICalculatorType("standard")
    const tempOptions = [];
    const tempOptionsInches = [];
    const tempOptionsHeight = [];
    const tempOptionsWeightLB = [];
    for (let i = 1; i <= 10; i++) {
      tempOptions.push(i);
    }
    for (let i = 1; i <= 11; i++) {
      tempOptionsInches.push(i);
    }
    for (let i = 1; i <= 10; i++) {
      tempOptionsHeight.push(i);
       for (let j = 1; j <= 11; j++) {
        tempOptionsHeight.push((`${i}.${j}`));
            }
    }
    for (let i = 1; i <= 479; i++) {
      tempOptionsWeightLB.push(i);
    }
    setFeetOptions(tempOptions)
    setInchesOptions(tempOptionsInches)
    setHeightOptionsInBMI(tempOptionsHeight)
    setWeightOptionsInBMI(tempOptionsWeightLB)

    //Martial status
    try {
      const result = await getmartialstatus({
        type: "maritalstatus",
      });
      //console.log(result,"status");
      if (result != null) {
        const formattedOptions = result.output.data[0].data.map(option => (
          [option.condition]     
        ));
        setMartialstatusData(formattedOptions);
      }
      setMartialstatusCompleteData(result.output.data[0].data);
    } catch (error) {
      console.error("Error", error);
    }
    addMember.current.openRight()
  };

  const removeTag = (index) => {
    const updatedConditions = selectedConditions.filter((_, i) => i !== index);
    setSelectedConditions(updatedConditions);
  };
  const removeTagAllergiesFromAdd = (condition_cd) => {
    setSelectedAllergies(prevTags => prevTags.filter(tag => tag.condition_cd !== condition_cd));
  };
  const removeTagAllergiesFromEdit = (condition) => {
    setSelectedAllergies(prevTags => prevTags.filter(tag => tag.condition_cd !== condition));
  };
  const removeConditionFromEdit = (condition_cd) => {
    setSelectedConditions(prevTags => prevTags.filter(tag => tag.condition_cd !== condition_cd));
  };
  const addCondition4 = (condition) => {
    setSelectedConditions([...selectedConditions, condition]);
  };
  const handleMartitalstatusinput= (selectedOption) =>{
    var foundObject = martialstatuscomplete && martialstatuscomplete.find(x => x.condition == selectedOption);
    
    if (foundObject) {
      setMaritalstatusID(foundObject.condition_cd)
    } 
  }
  const handleAdoptedCheckboxChange= (e) =>{
    setAdoptedcheckbox(e.target.checked);
  }
  const handledeceasedCheckboxChange= (e) =>{
    setdeceasedcheckbox(e.target.checked);
  }
  const handleFamilyDelete = (id, firstname, lastname) =>{
    setID(id);
    setTitle(firstname+" "+lastname);
    dynamicModal.current.openModal();
  }
  const deletefamilyhistory = async()=>{
    const result = await DeleteFamilyHistory({
      patientid,
      familyid: id,
    });
    if(result){
      fetchData();
      dynamicModal.current.closeModal();
    }
  }

  
  //BMI functions 

  useEffect(()=>{
    if(vitalunitheight == "ft"){
      setShowInchesInput(true)
    }else{
      setShowInchesInput(false)
    }
  }, [vitalunitheight])

  const [ weightToCalculate ,setWeightToCalculate] = useState("")
  const switchBMICalculation = (value) => {
    const tempOptionsWeightLB = [];
    const tempOptionsHeight = [];
    setBMICalculatorType(value);
    
    if (value == "metric") {
     // setWeight("")
      setVitalUnitHeight("cms");
      setHeightunit_cd(151)

      setVitalUnitWeight("kg");
      setWeightunit_cd(154)

      if(weight){
        var converterValue = lbToKgConverter(weight)
        setWeight(converterValue.value)
        setWeightToCalculate(converterValue.valueToCalculate)
      }
      if(feetValue || inchValue){
       // if(height.includes(".")){
        
      var heightValueOfFeet = feetValue && inchValue ? feetValue + "." + inchValue : feetValue;
          var converterValue = ftToCmConverter(feetValue, inchToCalculate)
          setHeight(converterValue)
       // }
      }
      
      for (let i = 1; i <= 250; i++) {
        tempOptionsWeightLB.push(i);
      }
      for (let i = 30; i <= 303; i++) {
        tempOptionsHeight.push(i);
      }
    setHeightOptionsInBMI(tempOptionsHeight)
    setWeightOptionsInBMI(tempOptionsWeightLB)
    } else if (value == "standard") {
    //  setWeight("")
      setVitalUnitHeight("ft");
      setHeightunit_cd(152)

      setVitalUnitWeight("lbs");
      setWeightunit_cd(155)


      if(weight){
        var converterValue = kgToLbConverter(weightToCalculate)
        setWeight(converterValue.value)

      }

      if(height){

          var converterValue = cmToFtConverter(height)
          setFeetValue(converterValue.feet)
          setInchValue(converterValue.inches)
      }

      for (let i = 1; i <= 479; i++) {
        tempOptionsWeightLB.push(i);
      }
      for (let i = 1; i <= 10; i++) {
        tempOptionsHeight.push(i);
        for (let j = 1; j <= 9; j++) {
          tempOptionsHeight.push((`${i}.${j}`));
        }
      }
    setHeightOptionsInBMI(tempOptionsHeight)
    setWeightOptionsInBMI(tempOptionsWeightLB)
    } 
  };
  const setInchValueInDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setInchValue(stringValue)
    
    setInchToCalculate(stringValue)
  }
  const handleBMIinchValue = (e)=>{
    /// alert(e.target.value)
     var value = e.target.value;
     const numbersRegex = /^[0-9]+$/;
     if (numbersRegex.test(value) || value === "") { // Also allow empty value
      setInchValue(value)
      
    setInchToCalculate(value)
     }
     
   }
   const setFeetValueInDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setFeetValue(stringValue)
  }
  const handleBMIFeetValue = (e)=>{
    var value = e.target.value;
    const numbersRegex = /^[0-9]+$/;
    if (numbersRegex.test(value) || value === "") { 
      setFeetValue(value)
    }
    
  }

   // THis is BMI on change event
   const handlebmivitalvaluechange = (e) => {
    const { id, value } = e.target;
    const maxLength = 6;
    switch (id) {
      case "weight":
        let inputValue = value;
       const inputText = e.target.value;
        if (inputText.length <= maxLength) {
          
        let cleanedValue = inputText.replace(/[^0-9.]/g, '');
        if(cleanedValue.startsWith(".")){
          cleanedValue = cleanedValue.substring(1)
        }
        if (cleanedValue.match(/[0-9]{4,}/)) {
          cleanedValue = cleanedValue.slice(0, -1);
        }
        const decimalIndex = cleanedValue.indexOf('.');
        if (decimalIndex !== -1) {
          const parts = cleanedValue.split('.');
          cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
        }
        setWeight(cleanedValue);
        }
        break;
      case "height":
        const input = e.target.value;
      
        if (input.length <= maxLength) {
          
        let cleanedValue = input.replace(/[^0-9.]/g, '');
        if(cleanedValue.startsWith(".")){
          cleanedValue = cleanedValue.substring(1)
        }
        if (cleanedValue.match(/[0-9]{4,}/)) {
          cleanedValue = cleanedValue.slice(0, -1);
        }
        const decimalIndex = cleanedValue.indexOf('.');
        if (decimalIndex !== -1) {
          const parts = cleanedValue.split('.');
          cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
        }
        setHeight(cleanedValue);
        }
        break;
       
      default:
        break;
    }
  };
  const setHeightValueInBMIDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setHeight(stringValue)
  }
  const setWeightvalueInBMIDropdown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setWeight(stringValue)
  }

  const css = `
    .StandardMetric {
      border-top: none;
      border-bottom: none;
    }
  `
  const handleCondition = (e) => {
    const inputText = e.target.value;
    const maxLength = 80;
  
    if (inputText.length <= maxLength) {
      setTypedCondition(inputText);

     
    }
  
  }

  const AddMedicalConditions = () => {
   
    if (typedCondition !== "") {
      const newSelectedCondition = {
        category: {
          conditiontype: "other",
          conditiontype_cd: null,
        },
        condition: typedCondition,
        condition_cd: null,
      };
      if(isAdd){
        
      setSelectedConditions((prevConditions) => {
        // Filter out any existing condition with the same type
        const filteredConditions = prevConditions.filter(
          (item) => item.category.conditiontype !== "other"
        );
        // Add the new condition
        return [...filteredConditions, newSelectedCondition];
      });
      
    }

    if(!isAdd){
      
      setSelectedConditions((prevConditions) => {
        // Filter out any existing condition with the same type
        const filteredConditions = prevConditions.filter(
          (item) => item.conditiontype !== "other"
        );
        // Add the new condition
        return [...filteredConditions, newSelectedCondition];
      });
    }
    
  }
  Medicalconditionsmodel.current.closeRight()
    }

    const handleAllergy = (e) => {
      const inputText = e.target.value;
      const maxLength = 80;
    
      if (inputText.length <= maxLength) {
        setTypedAllergy(inputText);
      }
      if(e.target.value){
        setAllergiesContinueBtnDisabled(false)
      }else{
        setAllergiesContinueBtnDisabled(true)
      }
    
    }

    const closeAddAllergyModal =()=>{
        // enter manually 
        if (typedAllergy !== "") {
          const newSelectedCondition = {
            condition:typedAllergy,
            condition_type:typedAllergy,
            condition_cd:null,
            conditiontype:"other",
           // familyid,
          };
      
          setSelectedAllergies((prevConditions) => {
            // Filter out any existing condition with the same type
            const filteredConditions = prevConditions.filter(
              (item) => item?.conditiontype !== "other"
            );
            // Add the new condition
            return [...filteredConditions, newSelectedCondition];
          });
        }
      allergiesModal.current.closeRight()
    }
 


  return (
    <>
      <Box w="100%" display={{base: "none", lg: "block"}}>
        <Box top="0" zIndex="banner">
          <Navbar />

          {isLoading && <Loader />}
          <SecondaryNav
            sideBarWidth="250px"
            defaultHeader="Profile"
            defaultSecondayHeader="Family"
            defaultNavBody={
              <>
              {data &&
              data !== null && (
                <Box display="flex" gap="10px" alignItems="center" pr="25px">
                  <PrimaryButton
                    fontSize="14px"
                    variant="mdBtn"
                    buttonText="Add member"
                    btnIcon="/assets/imgs/add.png"
                    onClick={openAddModal}
                  />
                </Box>
                    )}
              </>
            }
          />
        </Box>
        <Box h="calc(100vh - 140px)" overflow="hidden auto">
        <Flex>
          <Box p="0" width="250px">
            <ProfileSideBar />
          </Box>

          <Flex bg="#fcfcfd" flexDirection="column" w="100%">

            {data && data != null ? (

              <Flex
                wrap="wrap"
                p="20px"
                w="100%"
                justifyContent="start"
                gap="20px"
              >

                {data && data.map((details, index) => (
                  <Box key={index} flex="0 0 calc(33.333% - 20px)" mb="20px">
                    <DoctorCard
                      cardBody={
                        <>
                          <Flex alignItems="flex-start" gap="10px">
                            <Box display="flex" w="100%" gap="15px">
                              <Box>
                                <Box w="42px" h="42px">
                                  <Image border="1px solid #E6E7E9" w="100%" h="100%" objectFit="contain" borderRadius="full" src={details.familyimage ? details.imagepath + details.familyimage : "/assets/imgs/no-image-icon.png"} />
                                  <Box as="Button" pos="relative" bottom="23px" left="30px" bg="white" p="6px" border="1px solid #E6E7E9" borderRadius="full"
                                    onClick={() => selectFamilyMemberImage(details.familyid)} >
                                    <input
                                      type="file"
                                      id="file-upload"
                                      style={{ display: "none" }}
                                      onChange={(e) => handleimageselection(e)}
                                    />
                                    <Image w="8px" h="auto" src='/assets/imgs/edit.png'
                                    />
                                  </Box>
                                </Box>
                              </Box>

                              <Box>
                                  <Box as="h1" fontSize="16px" fontWeight="600" className="textOverflow" maxW={{base: "180px", xl:"220px"}}>
                                    {details.firstname} {details.lastname}
                                  </Box>
                                  <Box as="p" fontSize="12px" fontWeight="400">
                                    {details.relation}
                                  </Box>
                              </Box>
                            <Spacer/>
                            <Box>
                              <Box as="button" onClick={()=> handleFamilyDelete(details.familyid,details.firstname, details.lastname)} display="flex" justifyContent="center" border="1px solid #E6E7E9" h="auto" w="32px" p="10px" borderRadius="full">
                                <Image w="10px" h="auto" src='/assets/imgs/delete.png'
                                />
                              </Box>
                            </Box>
                            </Box>
                          </Flex>
                          <Divider my="10px" />
                          <Flex gap="8px" align="center">
                            <Image src="/assets/svgs/phone.svg" boxSize="14px" />
                            <Text fontSize="12px" fontWeight="500">
                              {details.mobileno ? details.mobileno : "-"}
                            </Text>
                          </Flex>
                          <Flex gap="8px" align="center">
                            <Image src="/assets/svgs/mail.svg" boxSize="14px" />
                            <Text fontSize="12px" fontWeight="500">
                              {details.email ? details.email : "-"}
                            </Text>
                          </Flex>
                        </>
                      }
                      cardFooterText="View details"
                      onClick={() => openEditModal(details)}
                    />
                  </Box>

                ))}
              </Flex>
            ) : (
              <Box
                w="100%"
                h="100vh"
                display="flex"
                flexDirection="column"
                gap="5px"
                justifyContent="center"
                alignItems="center"
              >
                <Image w="60px" opacity="0.5" src="/assets/svgs/family-history-icon.svg" />
                <Box as="p">No data available</Box>
                <PrimaryButton
                  fontSize="14px"
                  variant="mdBtn"
                  buttonText="Add Member"
                  btnIcon="/assets/imgs/add.png"
                  onClick={openAddModal}
                />
              </Box>
            )}
          </Flex>
        </Flex>
        <Footer />
        </Box>
      </Box>

      {/* Mobile responsive start */}
      <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/profile')}
          header="Family"
        />

        <Box pos="fixed" bottom="10%" display="flex" justifyContent="center" w="100%" zIndex="99">
          {data &&
            data !== null && (
              <Box display="flex" gap="10px" alignItems="center" pr="25px">
                <PrimaryButton
                  fontSize="14px"
                  variant="mdBtn"
                  buttonText="Add member"
                  btnIcon="/assets/imgs/add.png"
                  onClick={openAddModal}
                />
              </Box>
          )}
        </Box>
        <Flex bg="#fcfcfd" flexDir="column" pb="3rem" h="calc(100vh - 200px)" overflow="hidden auto">
        {data && data != null ? (
          <SimpleGrid columns={1} gap="1rem" p="1.25rem">

            {data && data.map((details, index) => (
                <DoctorCard
                  cardBody={
                    <>
                      <Flex alignItems="flex-start" gap="10px">
                        <Box display="flex" w="100%" gap="15px">
                          <Box>
                            <Box w="42px" h="42px">
                              <Image border="1px solid #E6E7E9" w="100%" h="100%" objectFit="contain" borderRadius="full" src={details.familyimage ? details.imagepath + details.familyimage : "/assets/imgs/no-image-icon.png"} />
                              <Box as="Button" pos="relative" bottom="23px" left="30px" bg="white" p="6px" border="1px solid #E6E7E9" borderRadius="full"
                                onClick={() => selectFamilyMemberImage(details.familyid)} >
                                <input
                                  type="file"
                                  id="file-upload"
                                  style={{ display: "none" }}
                                  onChange={(e) => handleimageselection(e)}
                                />
                                <Image w="8px" h="auto" src='/assets/imgs/edit.png'
                                />
                              </Box>
                            </Box>
                          </Box>

                          <Box>
                              <Box as="h1" fontSize="16px" fontWeight="600" className="textOverflow" maxW={{base: "180px", xl:"220px"}}>
                                {details.firstname} {details.lastname}
                              </Box>
                              <Box as="p" fontSize="12px" fontWeight="400">
                                {details.relation}
                              </Box>
                          </Box>
                        <Spacer/>
                        <Box>
                          <Box as="button" onClick={()=> handleFamilyDelete(details.familyid,details.firstname, details.lastname)} display="flex" justifyContent="center" border="1px solid #E6E7E9" h="auto" w="32px" p="10px" borderRadius="full">
                            <Image w="10px" h="auto" src='/assets/imgs/delete.png'
                            />
                          </Box>
                        </Box>
                        </Box>
                      </Flex>
                      <Divider my="10px" />
                      <Flex gap="8px" align="center">
                        <Image src="/assets/svgs/phone.svg" boxSize="14px" />
                        <Text fontSize="12px" fontWeight="500">
                          {details.mobileno ? details.mobileno : "-"}
                        </Text>
                      </Flex>
                      <Flex gap="8px" align="center">
                        <Image src="/assets/svgs/mail.svg" boxSize="14px" />
                        <Text fontSize="12px" fontWeight="500">
                          {details.email ? details.email : "-"}
                        </Text>
                      </Flex>
                    </>
                  }
                  cardFooterText="View details"
                  onClick={() => openEditModal(details)}
                />
            ))}
          </SimpleGrid>
          ) : (
          <Box
            w="100%"
            h="100vh"
            display="flex"
            flexDirection="column"
            gap="5px"
            justifyContent="center"
            alignItems="center"
          >
            <Image w="60px" opacity="0.5" src="/assets/svgs/family-history-icon.svg" />
            <Box as="p">No data available</Box>
            <PrimaryButton
              fontSize="14px"
              variant="mdBtn"
              buttonText="Add Member"
              btnIcon="/assets/imgs/add.png"
              onClick={openAddModal}
            />
          </Box>
          )}
        </Flex>
      </Box>

      {/* Modal Section */}
      <ModalRight
        ref={addMember}
        modalWidth="360px"
        header="Add member"
        body={
          <>
            <Box w="100%" py="10px" px="0">
              <PrimaryInput inputPlace="First name*" onChange={handleFirstName} inputValue={firstname} />
              <PrimaryInput variant="bothSide" inputPlace="Last name*" onChange={handleLastName} inputValue={lastname} />
              <DropdownInput
                variant="bothSide"
                dropDownPlace="Gender"
                options={["Male", "Female", "Other"]}
                onClick={selectGender}
              />
              {/* <Flex w="100%">
                <MiniDropdownInput
                  variant="bothSide"
                  options={[
                    { label: "cm", value: "151" },
                    { label: "feet", value: "152" }
                  ]}
                  placeholderProp="Height"
                  id="heightUnits"
                  onSelect={handleChangeHeightUnit}
                  inputVal={height}
                  handleInputChange={handleSetHeight}
                  maxLength="6"
                />
                <MiniDropdownInput
                  variant="bothSide"
                  dropdownStyle={{ pl: "0" }}
                  placeholderProp="Weight"
                  options={[
                    { label: "kg", value: "154" },
                    { label: "Pound", value: "155" }
                  ]}
                  id="weightUnits"
                  onSelect={handleChangeWeightUnit}
                  inputVal={weight}
                  handleInputChange={handleSetWeight}
                  maxLength="6"
                />
              </Flex> */}
                {/* <Divider /> */}
                <RadioGroup 
                      onChange={switchBMICalculation}
                      value={BMICalculatorType}
                      w="100%"
                      bg="#FFFFFF"
                      border="1px solid #e2e8f0"
                      pl="10px"
                    >
                    <Box display="flex" justifyContent="start" gap="1.5rem" py="10px" w="100%">
                      <Radio value="standard" colorScheme="green">
                        Standard
                      </Radio>
                    
                      <Radio value="metric" colorScheme="green">
                        Metric
                      </Radio>

                    </Box>
                  </RadioGroup>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  // py="2 0px"
                  pl="10px"
                  w="100%"
                  bg="#FFFFFF"
                  border="1px solid #e2e8f0"
                >
                  <Box fontSize="14px" fontWeight="600" w="20%">
                      Weight
                      <Box
                        as="span"
                        color="#384052"
                        fontSize="12px"
                        fontWeight="500"
                      >
  
                      </Box>
                      <>
                          <Menu>
                            <MenuButton
                              sx={{
                                color: "#1C4EC5",
                                display: "flex",
                                alignItems: "center",
                                cursor:"default"
                              }}
                            >
                              {vitalunitweight}{" "}
                              {/* <Image
                                ml="5px"
                                pos="relative"
                                bottom="2px"
                                display="inline-block"
                                src="/assets/imgs/arrow-down-gray.png"
                              /> */}
                            </MenuButton>
                           
                          </Menu>
                      </>
                  </Box>
                     <DropdownInputForVitals
                        variant="bothSide"
                        dropDownPlace="Enter Value"
                        options={weightOptionsInBMI}
                        onClick={setWeightvalueInBMIDropdown}
                        onChange={handlebmivitalvaluechange}
                        readOnly=""
                        inputStyleCss={{w: "80%"}}
                        className="StandardMetric"
                        id="weight"
                        //  defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                        inputValueProp={weight}
                      />
                </Box>

                {/* <Divider /> */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  // py="20px"
                  pl="10px"
                  w="100%"
                  bg="#FFFFFF"
                  border="1px solid #e2e8f0"
                >
                  <Box fontSize="14px" fontWeight="600" w="20%">
                      Height
                      <Box
                        as="span"
                        color="#384052"
                        fontSize="12px"
                        fontWeight="500"
                      >
  
                      </Box>
                      <>
                      <Menu>
                            <MenuButton
                              sx={{
                                color: "#1C4EC5",
                                display: "flex",
                                alignItems: "center",
                                cursor:"default"
                              }}
                            >
                              {vitalunitheight}{" "}
                              {/* <Image
                                ml="5px"
                                pos="relative"
                                bottom="2px"
                                display="inline-block"
                                src="/assets/imgs/arrow-down-gray.png"
                              /> */}
                            </MenuButton>
                          </Menu>
                      </>
                  </Box>
                  {!showInchesInput && 
              
                  <DropdownInputForVitals
                    variant="bothSide"
                    dropDownPlace="Enter value"
                    options={heightOptionsInBMI}
                    onClick={setHeightValueInBMIDropDown}
                    onChange={handlebmivitalvaluechange}
                    readOnly=""
                    inputStyleCss={{w: "80%"}}
                    className="StandardMetric"
                    id="height"
                    // defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                    inputValueProp={height}
                  />
                  }
                  
                  {showInchesInput && 
                  <>
                   <Box w="80%" display="flex">
                      <DropdownInputForVitals
                        variant="bothSide"
                        dropDownPlace="Feet"
                        options={feetOptions}
                        onClick={setFeetValueInDropDown}
                        onChange={handleBMIFeetValue}
                        readOnly=""
                        inputStyleCss={{w: "50%"}}
                        className="StandardMetric"
                        id="height"
                        inputValueProp={feetValue}
                        maxLength={1}
                      />

                
                      <DropdownInputForVitals
                        variant="bothSide"
                        dropDownPlace="Inches"
                        options={inchesOptions}
                        onClick={setInchValueInDropDown}
                        onChange={handleBMIinchValue}
                        readOnly=""
                        inputStyleCss={{w: "50%"}}
                        className="StandardMetric"
                        id="height"
                        inputValueProp={inchValue}
                        maxLength={2}
                      />
                    </Box>
                  </>
                  }
                </Box>

                {/* <Divider /> */}

              <DatePicker
              disableFutureDates={true}
                onDateChange={(selectedDateValue) => handledob(selectedDateValue)}
                placeholderProp="Date of Birth*" />
              
              <DropdownInput
                variant="bothSide"
                dropDownPlace="Relationship*"
                options={relationOptions.map(option => option.label)}
                onClick={selectRelation}
              />
              {/* <PrimaryInput inputType="number" variant="bothSide" inputPlace="Mobile Number"
                onChange={handleMobile} inputValue={mobileno} /> */}
                     <PrimaryInputDropdown
              onChange={(e) => handleMobile(e)}
              inputValue={mobileno}
              //defaultValue={mobileno}
              id="mobile"
              variant="bothSide"
              placeholderProp="Phone number"
            />
              <PrimaryInput inputType="email" variant="bothSide" inputPlace="Email Address"
                onChange={handleEmail} />
              <DropdownInput
                variant="downSide"
                dropDownPlace="Blood Type"
                options={["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"]}
                onClick={selectBloodType}
              />
              <DropdownInput
                  variant="bothSide"
                  dropDownPlace="Marital status"
                  options={martialstatusdata}
                  onClick={handleMartitalstatusinput}
                />
            </Box>

            <Box mt="15px">
              {/* Medical Condition */}
              <Box
                as="div"
                role="button"
                aria-haspopup="dialog"
                onClick={openMedicalConditionsModal}
                tabIndex={0}
                bg="white"
                borderRadius="10px 10px 0 0"
                border="1px solid #E6E7E9"
                p={selectedConditions.length === 0 ? "8px 20px 8px 8px" : "2"}
                w="100%"
                display="flex"
                alignItems="center"
                gap="2"
                height="auto"
                minHeight="48px"
                flexWrap="wrap"
                justifyContent="space-between"
              >
                {selectedConditions?.length === 0 && (
                  <>
                  <Text pl="1" color="gray.400">
                    Medical Condition
                  </Text>
                  
                  <Image src="/assets/imgs/right-icon.png" />
                  </>
                )}
                {selectedConditions?.map((tag, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    gap="1"
                    bg="gray.100"
                    borderRadius="full"
                    p="1"
                  >
                    <Text fontSize="sm" px="5px">
                      {tag.condition}
                    </Text>
                    <Box
                      as="button"
                      p="5px"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeTag(index);
                      }}
                      aria-label="Remove condition"
                    >
                      <Image src="/assets/imgs/close.png" />
                    </Box>
                  </Box>
                ))}
              </Box>

              {/* <Input
                id="medications"
                placeholder="Medications"
                formLabel="Medical Conditions"
                onChange={medicationsHandleChange}
                height="48px"
                borderRadius="0"
                border="1px solid #CDCFD4"
              /> */}

              {/* Allergies */}
              <Box
                as="div"
                role="button"
                aria-haspopup="dialog"
                onClick={openAllergiesModal}
                tabIndex={0}
                bg="white"
                borderRadius="0 0 10px 10px"
                border="1px solid #E6E7E9"
                p={selectedAllergies.length === 0 ? "8px 20px 8px 8px" : "2"}
                w="100%"
                display="flex"
                alignItems="center"
                gap="2"
                height="auto"
                minHeight="48px"
                flexWrap="wrap"
                justifyContent="space-between"
              >
                {selectedAllergies?.length === 0 && (
                  <>
                  <Text pl="1" color="gray.400">
                    Allergies
                  </Text>
                  
                  <Image src="/assets/imgs/right-icon.png" />
                  </>
                )}
                {selectedAllergies?.map((tag, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    gap="1"
                    bg="gray.100"
                    borderRadius="full"
                    p="1"
                  >
                    <Text fontSize="sm" px="5px">
                      {tag.condition}
                    </Text>
                    <Box
                      as="button"
                      p="5px"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeTagAllergiesFromAdd(tag.condition_cd);
                      }}
                      aria-label="Remove condition"
                    >
                      <Image src="/assets/imgs/close.png" />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box py="20px">
                <Divider border="1px solid #E6E7E9" />
              </Box>
              <Box display="flex" gap="30px">
                <Checkbox
                  colorScheme="green"
                  sx={{
                    ".chakra-checkbox__control": {
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                    },
                  }}
                  onChange={handleAdoptedCheckboxChange}
                >
                  Adopted
                </Checkbox>
                <Checkbox
                  colorScheme="green"
                  sx={{
                    ".chakra-checkbox__control": {
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                    },
                  }}
                  onChange={handledeceasedCheckboxChange}
                >
                  
                  Deceased
                </Checkbox>
              </Box>
          </>
        }
        footerActions={
          <>
            <Box w="100%">
              <PrimaryButton buttonText="Add member" onClick={addfamilyMember} isDisabled={false} />
            </Box>
          </>
        }
      />

      <ModalRight
        ref={editMember}
        modalWidth="360px"
        header="Edit Member"
        body={
          <>
            <Box w="100%" py="10px" px="0">
              <PrimaryInput inputPlace="First name*" defaultValue={firstname} onChange={handleFirstName} />
              <PrimaryInput variant="bothSide" defaultValue={lastname} inputPlace="Last name*" onChange={handleLastName} />
              <DropdownInput
                variant="bothSide"
                dropDownPlace="Gender"
                options={["Male", "Female", "Other"]}
                onClick={selectGender}
                defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                inputValue={gender}
              />
              {/* <Flex w="100%">
                
                <MiniDropdownInput
                  variant="bothSide"
                  options={[
                    { label: "cm", value: 151 },
                    { label: "ft", value: 152 }
                  ]}
                  placeholderProp="Height"
                  id="heightUnits"
                  defaultOptionProp={heightunit_cd}
                  onSelect={handleChangeHeightUnit}
                  defaultValue={height}
                  inputVal={height}
                  handleInputChange={handleSetHeight}
                  maxLength="6"
                />
               
                <MiniDropdownInput
                  variant="bothSide"
                  dropdownStyle={{ pl: "0" }}
                  placeholderProp="Weight"
                  options={[
                    { label: "kg", value: 154 },
                    { label: "lb", value: 155 }
                  ]}
                  id="weightUnits"
                  onSelect={handleChangeWeightUnit}
                  defaultOptionProp={weightunit_cd}
                  inputVal={weight}
                  defaultValue={weight}
                  handleInputChange={handleSetWeight}
                  maxLength="6"
                />
              </Flex> */}

                 {/* <Divider /> */}
                 <RadioGroup 
                      onChange={switchBMICalculation}
                      value={BMICalculatorType}
                      w="100%"
                      bg="#FFFFFF"
                      border="1px solid #e2e8f0"
                      pl="10px"
                    >
                    <Box display="flex" justifyContent="start" gap="1.5rem" py="10px" w="100%">
                      <Radio value="standard" colorScheme="green">
                        Standard
                      </Radio>
                    
                      <Radio value="metric" colorScheme="green">
                        Metric
                      </Radio>

                    </Box>
                  </RadioGroup>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  // py="2 0px"
                  pl="10px"
                  w="100%"
                  bg="#FFFFFF"
                  border="1px solid #e2e8f0"
                >
                  <Box fontSize="14px" fontWeight="600" w="20%">
                      Weight
                      <Box
                        as="span"
                        color="#384052"
                        fontSize="12px"
                        fontWeight="500"
                      >
  
                      </Box>
                      <>
                          <Menu>
                            <MenuButton
                              sx={{
                                color: "#1C4EC5",
                                display: "flex",
                                alignItems: "center",
                                cursor:"default"
                              }}
                            >
                              {vitalunitweight}{" "}
                              {/* <Image
                                ml="5px"
                                pos="relative"
                                bottom="2px"
                                display="inline-block"
                                src="/assets/imgs/arrow-down-gray.png"
                              /> */}
                            </MenuButton>
                           
                          </Menu>
                      </>
                  </Box>
                     <DropdownInputForVitals
                        variant="bothSide"
                        dropDownPlace="Enter Value"
                        options={weightOptionsInBMI}
                        onClick={setWeightvalueInBMIDropdown}
                        onChange={handlebmivitalvaluechange}
                        readOnly=""
                        inputStyleCss={{w: "80%"}}
                        className="StandardMetric"
                        id="weight"
                        //  defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                        inputValueProp={weight}
                      />
                </Box>

                {/* <Divider /> */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  // py="20px"
                  pl="10px"
                  w="100%"
                  bg="#FFFFFF"
                  border="1px solid #e2e8f0"
                >
                  <Box fontSize="14px" fontWeight="600" w="20%">
                      Height
                      <Box
                        as="span"
                        color="#384052"
                        fontSize="12px"
                        fontWeight="500"
                      >
  
                      </Box>
                      <>
                      <Menu>
                            <MenuButton
                              sx={{
                                color: "#1C4EC5",
                                display: "flex",
                                alignItems: "center",
                                cursor:"default"
                              }}
                            >
                              {vitalunitheight}{" "}
                              {/* <Image
                                ml="5px"
                                pos="relative"
                                bottom="2px"
                                display="inline-block"
                                src="/assets/imgs/arrow-down-gray.png"
                              /> */}
                            </MenuButton>
                          </Menu>
                      </>
                  </Box>
                  {!showInchesInput && 
              
                  <DropdownInputForVitals
                    variant="bothSide"
                    dropDownPlace="Enter value"
                    options={heightOptionsInBMI}
                    onClick={setHeightValueInBMIDropDown}
                    onChange={handlebmivitalvaluechange}
                    readOnly=""
                    inputStyleCss={{w: "80%"}}
                    className="StandardMetric"
                    id="height"
                    // defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                    inputValueProp={height}
                  />
                  }
                  
                  {showInchesInput && 
                  <>
                   <Box w="80%" display="flex">
                      <DropdownInputForVitals
                        variant="bothSide"
                        dropDownPlace="Feet"
                        options={feetOptions}
                        onClick={setFeetValueInDropDown}
                        onChange={handleBMIFeetValue}
                        readOnly=""
                        inputStyleCss={{w: "50%"}}
                        className="StandardMetric"
                        id="height"
                        inputValueProp={feetValue}
                        maxLength={1}
                      />

                
                      <DropdownInputForVitals
                        variant="bothSide"
                        dropDownPlace="Inches"
                        options={inchesOptions}
                        onClick={setInchValueInDropDown}
                        onChange={handleBMIinchValue}
                        readOnly=""
                        inputStyleCss={{w: "50%"}}
                        className="StandardMetric"
                        id="height"
                        inputValueProp={inchValue}
                        maxLength={2}
                      />
                    </Box>
                  </>
                  }
                </Box>

                {/* <Divider /> */}
             
              <DatePicker
              disableFutureDates={true}
                onDateChange={(selectedDateValue) => handledob(selectedDateValue)}
                defaultDate={datePrePopulate}
                placeholderProp="Date of Birth*" />
             
              <DropdownInput
                variant="bothSide"
                dropDownPlace="Relationship*"
                options={relationOptions.map(option => option.label)}
                onClick={selectRelation}
                defaultOption={relation}

              />
              {/* <PrimaryInput inputType="number" variant="bothSide" inputPlace="Mobile Number"
                onChange={handleMobile} defaultValue={mobileno} inputValue={mobileno} /> */}
                   <PrimaryInputDropdown
              onChange={(e) => handleMobile(e)}
              inputValue={mobileno}
              defaultValue={mobileno}
              defaultCountryCode={defaultCountryCode}
              id="mobile"
              variant="bothSide"
              placeholderProp="Phone number"
            />
              <PrimaryInput inputType="email" variant="bothSide" inputPlace="Email Address"
                onChange={handleEmail} defaultValue={email} />
              <DropdownInput
                variant="bothSide"
                dropDownPlace="Blood Type"
                options={["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"]}
                onClick={selectBloodType}
                defaultOption={
                  bloodtype_cd == "158" ? "A+" :
                    bloodtype_cd == "159" ? "A-" :
                      bloodtype_cd == "160" ? "B+" :
                        bloodtype_cd == "161" ? "B-" :
                          bloodtype_cd == "162" ? "AB+" :
                            bloodtype_cd == "163" ? "AB-" :
                              bloodtype_cd == "164" ? "O+" :
                                bloodtype_cd == "165" ? "O-" :
                                  ""
                }

              />
               <DropdownInput
                  variant="downSide"
                  dropDownPlace="Marital status"
                  options={martialstatusdata}
                  defaultOption={maritalstatus}
                  onClick={handleMartitalstatusinput}
                />
            </Box>

            <Box mt="15px">
              {/* Medical Condition */}
              <Box
                as="div"
                role="button"
                aria-haspopup="dialog"
                onClick={openMedicalConditionsModal}
                tabIndex={0}
                bg="white"
                borderRadius="10px 10px 0 0"
                border="1px solid #E6E7E9"
                p={selectedConditions.length === 0 ? "8px 20px 8px 8px" : "2"}
                w="100%"
                display="flex"
                alignItems="center"
                gap="2"
                height="auto"
                minHeight="48px"
                flexWrap="wrap"
                justifyContent="space-between"
              >
              {selectedConditions?.length === 0 && (
                <>
                  <Text pl="1" color="gray.400">
                    Medical Condition
                  </Text>
                  
                  <Image src="/assets/imgs/right-icon.png" />
                  </>
                )}
                {selectedConditions?.map((condition, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    gap="1"
                    bg="gray.100"
                    borderRadius="full"
                    p="1"
                  >
                    <Text fontSize="sm" px="5px">
                      {condition.condition}
                    </Text>
                    <Box
                      as="button"
                      p="5px"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeConditionFromEdit(condition.condition_cd);
                      }}
                      aria-label="Remove condition"
                    >
                      <Image src="/assets/imgs/close.png" />
                    </Box>
                  </Box>
                ))}

              </Box>
              {/* <Input
                id="medications"
                placeholder="Medications"
                formLabel="Medical Conditions"
                defaultValue={medicationname}
                onChange={medicationsHandleChange}
                height="48px"
                borderRadius="0"
                border="1px solid #CDCFD4"
              /> */}

              {/* Allergies */}
              <Box
                as="div"
                role="button"
                aria-haspopup="dialog"
                onClick={openAllergiesModal}
                tabIndex={0}
                bg="white"
                borderRadius="0 0 10px 10px"
                border="1px solid #E6E7E9"
                p={selectedAllergies.length === 0 ? "8px 20px 8px 8px" : "2"}
                w="100%"
                display="flex"
                alignItems="center"
                gap="2"
                height="auto"
                minHeight="48px"
                flexWrap="wrap"
                justifyContent="space-between"
              >
              {selectedAllergies?.length === 0 && (
                <>
                  <Text pl="1" color="gray.400">
                    Allergies
                  </Text>
                  
                  <Image src="/assets/imgs/right-icon.png" />
                  </>
                )}
                {selectedAllergies?.map((tag, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    gap="1"
                    bg="gray.100"
                    borderRadius="full"
                    p="1"
                  >
                    <Text fontSize="sm" px="5px">
                      {tag.condition ? tag.condition : tag.allergytype}
                    </Text>
                    <Box
                      as="button"
                      p="5px"

                      onClick={(e) => {
                        e.stopPropagation();
                        removeTagAllergiesFromEdit(tag.condition_cd);
                      }}
                      aria-label="Remove condition"
                    >
                      <Image src="/assets/imgs/close.png" />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box py="20px">
                <Divider border="1px solid #E6E7E9" />
              </Box>
              <Box display="flex" gap="30px">
                <Checkbox
                  colorScheme="green"
                  sx={{
                    ".chakra-checkbox__control": {
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                    },
                  }}
                  defaultChecked={adopted === true}
                  onChange={handleAdoptedCheckboxChange}
                >
                  Adopted
                </Checkbox>
                <Checkbox
                  colorScheme="green"
                  sx={{
                    ".chakra-checkbox__control": {
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                    },
                  }}
                  defaultChecked={deceased === true}
                  onChange={handledeceasedCheckboxChange}
                >
                  
                  Deceased
                </Checkbox>
              </Box>
           
          </>
        }
        footerActions={
          <>
            <Box w="100%">
              <PrimaryButton buttonText="Update Member" onClick={editfamilyMember} isDisabled={false} />
            </Box>
          </>
        }
      />

      <ModalRight
        ref={Medicalconditionsmodel}
        header="Select Condition"
        modalWidth="360px"
        body={
          <>
            <SearchBar passInputPlace="Search condition" onChange={(e) => setSearchValue(e.target.value)} />
            <RadioGroup>
              <Stack direction="column" gap="0">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  borderBottom="1px solid #e2e8f0"
                >
                  <Box display="flex" justifyContent="end" w="100%">
                    <Stack direction="column" gap="0">
                      {conditionsList && conditionsList.length > 0 ? (
                        conditionsList.map((category) => {
                          const filteredConditions = category.data.filter((condition) =>
                            condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                          );

                          if (filteredConditions.length === 0) {
                            //  showErrorToast("nott found")
                            return null; // Skip rendering the heading if no matching conditions
                          }

                          return (
                            <React.Fragment key={category.conditiontype_cd}>
                              <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                                {category.conditiontype}
                              </Heading>
                              {filteredConditions.map((condition) => (
                                <label  key={condition.condition_cd} >
                                <Box
                                  key={condition.condition_cd}
                                  display="flex"
                                  justifyContent="space-between"
                                  borderBottom="1px solid #e2e8f0"
                                  // borderRadius="10px"
                                  p="15px"
                                  pl="5px"
                                  minH="50px"
                                  width="100%"
                                  sx={{
                                    _hover: { border: "2px solid #384052", borderRadius: "10px" },
                                  }}
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Text fontSize="16px" lineHeight="normal" fontWeight="600">
                                      {condition.condition}
                                     
                                    </Text>
                                  </Box>
                                  <Box display="flex" justifyContent="end">
                                    <Checkbox
                                      colorScheme="green"
                                      sx={{
                                        ".chakra-checkbox__control": {
                                          width: "20px",
                                          height: "20px",
                                          borderRadius: "5px",
                                        },
                                      }}
                                      onChange={() => handleCheckboxChange(condition, category)}
                                      isChecked={selectedConditions?.some(
                                        (item) => item.condition_cd === condition.condition_cd,
                                      )}
                                    ></Checkbox>
                                  </Box>
                                </Box>
                                </label>
                              ))}
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <p>No matching conditions found.</p>
                      )}
                       <Divider/>
          <br/>
          <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                   Other Condition
                  </Heading>
        <PrimaryInput
         inputPlace="Condition"
         variant="bothSide"
                onChange={handleCondition} inputValue={typedCondition}
                />
                <br/>
                    </Stack>
                  </Box>

                </Box>
              </Stack>
            </RadioGroup>
          </>
        }
        footerActions={<>
          <Box
            w="100%"
            display="flex"
            flexDirection="row"
            gap="15px">

            <PrimaryButton
              buttonText="Continue"
              isDisabled={false}
              onClick={AddMedicalConditions}
            />
          </Box>
        </>}
      />

      <ModalRight
        ref={allergiesModal}
        header="Select Allergies"
        body={
          <>
            <SearchBar
              passInputPlace="Search allergies"
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <RadioGroup>
              <Stack direction="column" gap="0">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  borderBottom="1px solid #e2e8f0"
                  py="15px"
                >
                  <Box display="flex" justifyContent="end" w="100%">
                    <Stack direction="column" gap="0" w="100%">
                      {allergiesdata && allergiesdata.length > 0 ? (
                        allergiesdata.map((category) =>
                          category.data
                            .filter((condition) =>
                              condition.condition
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                            )
                            .map((condition) => (
                              <label key={condition.condition_cd}>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                border="1px solid #e2e8f0"
                                borderRadius="10px"
                                p="15px"
                                height="50px"
                                width="100%"
                                sx={{
                                  _hover: {
                                    border: "2px solid #384052",
                                  },
                                }}
                              >
                                <Box
                                  key={condition.condition_cd}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Text
                                    fontSize="16px"
                                    fontWeight="600"
                                  >
                                    {condition.condition}
                                    <br />
                                    <Box
                                      as="span"
                                      fontSize="12px"
                                      fontWeight="500"
                                      color="#6A707D"
                                    ></Box>
                                  </Text>
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="end"
                                >
                                  <Checkbox
                                    colorScheme="green"
                                    sx={{
                                      ".chakra-checkbox__control": {
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "5px",
                                      },
                                    }}
                                    onChange={() =>
                                      handleAllergiesCheckboxChange(
                                        condition
                                      )
                                    }
                                    isChecked={selectedAllergies?.some(
                                      (item) =>
                                        item.condition_cd ===
                                        condition.condition_cd
                                    )}
                                  ></Checkbox>
                                </Box>
                              </Box>
                              </label>
                            ))
                        )
                      ) : (
                        <p>Loading...</p>
                      )}
                        <Divider/>
          <br/>
          <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                   Other Allergy
                  </Heading>
        <PrimaryInput
         inputPlace="Allergy"
         variant="bothSide"
                onChange={handleAllergy} inputValue={typedAllergy}
                />
                <br/>
                    </Stack>
                  </Box>
                </Box>
              </Stack>
            </RadioGroup>
          
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Continue"
                isDisabled={allergiesContinueBtnDisabled}
                onClick={closeAddAllergyModal}
              />
            </Box>
          </>
        }
      />
        <DynamicModal
          ref={dynamicModal}
          modalHeader="Delete Family?"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete?<br/>
                {title ? title : "-"} 
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                onClick={deletefamilyhistory}
              />
            </>
          }
        />
    </>
  );
}

export default FamilyIndex;
