import moment from "moment";
export default function formAPIDoctorAddresses({
  doctoraddressid,
address,
city,
hospitalname,
country,
state,
postalcode,
mobileno,
longitude,
latitude,
websiteurl,


address1,
address2,
pincode,

  
})
{ 
  return Object.freeze({
    doctoraddressid,
    address,
    city,
    hospitalname,
    country,
    state,
    postalcode,
    mobileno,
    longitude,
    latitude,
    websiteurl,

    
    address1,
    address2,
    pincode,

  });
}