import React, { useState } from 'react';

// RadioButton component definition
const RadioButton = ({ label, name, value, checked, onChange }) => {
  const css = `
    .radio-button-label {
      display: inline-flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      font-size: 16px;
      user-select: none;
      margin-right: 15px;
    }
    .radio-input {
      opacity: 0;
      position: absolute;
    }
    .custom-radio {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
      margin-right: 8px;
      border-radius: 50%;
      transition: all 0.2s ease-in-out;
      border: 1px solid #CDCFD4;
      background: #E6E7E933;
    }
    .radio-input:checked + .custom-radio {
      background-color: #4caf50;
      border-color: #4caf50;
    }
    .custom-radio::after {
      content: "";
      display: block;
      position: absolute;
      width: 3px;
      height: 7px;
      border: solid white;
      border-width: 0 1.5px 1.5px 0;
      transform: rotate(45deg);
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      top: 7px;
    }
    .radio-input:checked + .custom-radio::after {
      opacity: 1;
    }
  `;

  return (
    <>
      <style>{css}</style>
      <label className="radio-button-label">
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          className="radio-input"
        />
        <span className="custom-radio"></span>
        {label}
      </label>
    </>
  );
};

// RadioGroup component logic within the same file
const RadioList = ({ defaultValue, children, name, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);  // Call the onChange prop with the new value
    }
  };

  const renderedChildren = React.Children.map(children, child => 
    React.cloneElement(child, {
      checked: child.props.value === selectedValue,
      onChange: handleChange,
      name: name
    })
  );

  return <div>{renderedChildren}</div>;
};


export {RadioButton, RadioList}
