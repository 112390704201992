import  FetchData, { } from '../../client';
import formapimodalhealth from '../../domains/myhealth/myhealths';
import formAPIImaging from '../../domains/myhealthimaging/imaging';
import formapimodallabs from '../../domains/myhealthlabs/labs';
import {getLoginInfo, getUserID}  from '../../../auth';


const FetchImagingData = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_getpatienthealthreport", inputData);
    if (result.output.data) {
    var finalOutput = formAPIImaging(result.output.data)
    }
    
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};


export {FetchImagingData};
