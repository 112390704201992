import moment from "moment";
import formAPIModelOrdersConsultations from "./consultations";
import formAPIModelOrdersFollowUps from "./followups";
import formAPIModelLabs from "./labs";
import formAPIModelMedications from "./medications";
import formAPIModelProcedures from "./procedures";

export default function formAPIOrdersIndex({
  
  patientid,
  data,

  consultations,
  followup,
  labs,
  medication,
  procedures 
})
{
return Object.freeze({
  
  patientid,
  data,

  consultations: consultations !== null ? formAPIModelOrdersConsultations(consultations) : [],

  followup: followup !== null ? formAPIModelOrdersFollowUps(followup) : [],

  labs: labs !== null ? formAPIModelLabs(labs) : [],

  medication: medication !== null ? formAPIModelMedications(medication) : [],

  procedures: procedures !== null ? formAPIModelProcedures(procedures) : [],
  
});
}