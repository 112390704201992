export default function fromAPIModel({
  vitalname,
  vitalvalue,
  vitalunit,
  vitaladdeddate,
  lower_bound,
  upper_bound,

  // "STATUS": "90",
  lower_boundsystolic,
  upper_boundsystolic,
  upper_bounddiastolic,
  lower_bounddiastolic,
  systolic,
  diastolic
}) {
  let status;
  // let systolicStatus;
  // let diastolicStatus;
  if (vitalname != "BP") {
    const actualVitalValue = parseFloat(vitalvalue);
    if (!isNaN(actualVitalValue)) {
      if (actualVitalValue < lower_bound) {
        status = 'Low';
      } else if (actualVitalValue > upper_bound) {
        status = 'High';
      } else {
        status = 'Normal';
      }
    } 
  } else {

    // const actualSystolic = parseFloat(systolic);
    // const actualDiastolc = parseFloat(diastolic);
    // const lowerboundsystolic = parseFloat(lower_boundsystolic);
    // const upperboundsystolic = parseFloat(upper_boundsystolic);
    // const lowerbounddiastolic = parseFloat(lower_bounddiastolic);
    // const upperbounddiastolic = parseFloat(upper_bounddiastolic);
    // if (!isNaN(actualSystolic) && !isNaN(actualDiastolc) ) {
    //   if (actualSystolic < lowerboundsystolic && actualSystolic < 90 ) {
    //     systolicStatus = 'low';
    //   }
    //   if (actualSystolic < lowerboundsystolic && actualSystolic < 90 ) {
    //     systolicStatus = 'low';
    //   }
    //   if (actualSystolic < lowerboundsystolic && actualSystolic < 120 ) {
    //     systolicStatus = 'low';
    //   } if (actualSystolic < lowerboundsystolic && actualSystolic > 130 ) {
    //     systolicStatus = 'high';
    //   }
    //   if (actualSystolic > upperboundsystolic && actualSystolic < 90 ) {
    //     systolicStatus = 'low';
    //   }
    //   if (actualSystolic > upperboundsystolic && actualSystolic < 130 ) {
    //     systolicStatus = 'normal';
    //   }
    //   if (actualSystolic > upperboundsystolic && actualSystolic > 130 ) {
    //     systolicStatus = 'high';
    //   }
    //   if (((actualSystolic > lowerboundsystolic && actualSystolic < upperboundsystolic) && actualSystolic < 90 )) {
    //     systolicStatus = 'low';
    //   }
    //   if (((actualSystolic > lowerboundsystolic && actualSystolic < upperboundsystolic) && actualSystolic < 130 )) {
    //     systolicStatus = 'normal';
    //   }
    //   if (((actualSystolic > lowerboundsystolic && actualSystolic < upperboundsystolic) && actualSystolic > 130 )) {
    //     systolicStatus = 'high';
    //   }

    //   if (actualDiastolc < lowerbounddiastolic && actualDiastolc < 90 ) {
    //     systolicStatus = 'low';
    //   }
    //   if (actualDiastolc < lowerbounddiastolic && actualDiastolc < 90 ) {
    //     systolicStatus = 'low';
    //   }
    //   if (actualDiastolc < lowerbounddiastolic && actualDiastolc < 120 ) {
    //     systolicStatus = 'low';
    //   } if (actualDiastolc < lowerbounddiastolic && actualDiastolc > 130 ) {
    //     systolicStatus = 'high';
    //   }
    //   if (actualDiastolc > upperbounddiastolic && actualDiastolc < 90 ) {
    //     systolicStatus = 'low';
    //   }
    //   if (actualDiastolc > upperbounddiastolic && actualDiastolc < 130 ) {
    //     systolicStatus = 'normal';
    //   }
    //   if (actualDiastolc > upperbounddiastolic && actualDiastolc > 130 ) {
    //     systolicStatus = 'high';
    //   }
    //   if (((actualDiastolc > lowerbounddiastolic && actualDiastolc < upperbounddiastolic) && actualDiastolc < 90 )) {
    //     systolicStatus = 'low';
    //   }
    //   if (((actualDiastolc > lowerbounddiastolic && actualDiastolc < upperbounddiastolic) && actualDiastolc < 130 )) {
    //     systolicStatus = 'normal';
    //   }
    //   if (((actualDiastolc > lowerbounddiastolic && actualDiastolc < upperbounddiastolic) && actualDiastolc > 130 )) {
    //     systolicStatus = 'high';
    //   }

    // } else {
    //   status = 'normal';
    // }
    // const actualSystolic = parseFloat(systolic);
    // const actualDiastolc = parseFloat(diastolic);
    // const systolic = parseFloat(systolic);
    // const diastolic = parseFloat(diastolic);
    const lowerBoundsystolic = parseFloat(lower_boundsystolic);
    const upperBoundsystolic = parseFloat(upper_boundsystolic);
    const lowerBounddiastolic = parseFloat(lower_bounddiastolic)
    const upperBounddiastolic = parseFloat(upper_bounddiastolic)

    const actualSystolic = parseFloat(systolic);
    const actualDiastolic = parseFloat(diastolic);

    let systolicStatus = '';
    let diastolicStatus = '';

    if (!isNaN(actualSystolic) && !isNaN(actualDiastolic)) {
      if (actualSystolic <= lowerBoundsystolic && actualSystolic < 90) {
        systolicStatus = 'low';
      } else if (actualSystolic <= lowerBoundsystolic && actualSystolic < 120) {
        systolicStatus = 'low';
      } else if (actualSystolic <= lowerBoundsystolic && actualSystolic > 130) {
        systolicStatus = 'high';
      } else if (actualSystolic > upperBoundsystolic && actualSystolic < 90) {
        systolicStatus = 'low';
      } else if (actualSystolic > upperBoundsystolic && actualSystolic < 130) {
        systolicStatus = 'normal';
      } else if (actualSystolic > upperBoundsystolic && actualSystolic > 130) {
        systolicStatus = 'high';
      } else if (actualSystolic >= lowerBoundsystolic && actualSystolic <= upperBoundsystolic && actualSystolic < 90) {
        systolicStatus = 'low';
      } else if (actualSystolic >= lowerBoundsystolic && actualSystolic <= upperBoundsystolic && actualSystolic < 130) {
        systolicStatus = 'normal';
      } else if (actualSystolic >= lowerBoundsystolic && actualSystolic <= upperBoundsystolic && actualSystolic > 130) {
        systolicStatus = 'high';
      }

      if (actualDiastolic < lowerBounddiastolic && actualDiastolic < 60) {
        diastolicStatus = 'low';
      } else if (actualDiastolic < lowerBounddiastolic && actualDiastolic < 120) {
        diastolicStatus = 'low';
      } else if (actualDiastolic > upperBounddiastolic && actualDiastolic < 90) {
        diastolicStatus = 'low';
      } else if (actualDiastolic > upperBounddiastolic && actualDiastolic < 120) {
        diastolicStatus = 'normal';
      } else if (actualDiastolic > upperBounddiastolic && actualDiastolic >= 120) {
        diastolicStatus = 'high';
      } else if (actualDiastolic >= lowerBounddiastolic && actualDiastolic <= upperBounddiastolic && actualDiastolic < 90) {
        diastolicStatus = 'low';
      } else if (actualDiastolic >= lowerBounddiastolic && actualDiastolic <= upperBounddiastolic && actualDiastolic < 130) {
        diastolicStatus = 'normal';
      } else if (actualDiastolic >= lowerBounddiastolic && actualDiastolic <= upperBounddiastolic && actualDiastolic > 130) {
        diastolicStatus = 'high';
      }
      
      // Low 	low  	
      // High high	
      // Normal normaal

      // Low normal	
      // normal High 
      // high Low 

      if (systolicStatus === 'low' && diastolicStatus === 'low') {
        status = 'Low';
      } else if (systolicStatus === 'normal' && diastolicStatus === 'normal') {
        status = 'Normal';
      } else if (systolicStatus === 'high' && diastolicStatus === 'high') {
        status = 'High';
      } else if (systolicStatus === 'low' && diastolicStatus === 'high') {
        // Prioritize low over high for systolic low and diastolic high
        status = 'Low';
      } else if (systolicStatus === 'normal' && diastolicStatus === 'high') {
        // Prioritize high over normal for systolic normal and diastolic high
        status = 'High';
      } else if (systolicStatus === 'normal' && diastolicStatus === 'low') {
        // Prioritize high over low for systolic high and diastolic low
        status = 'Normal';
      }
      //the below 3 conditions were not there previously , *******
      // may require to change in future according to requirment
      else if (systolicStatus === 'low' && diastolicStatus === 'normal') {
        // Prioritize high over low for systolic high and diastolic low
        status = 'Normal';
      }else if (systolicStatus === 'high' && diastolicStatus === 'low') {
        // Prioritize high over low for systolic high and diastolic low
        status = 'High';
      }else if (systolicStatus === 'high' && diastolicStatus === 'normal') {
        // Prioritize high over low for systolic high and diastolic low
        status = 'High';
      }
    }
  }
  return Object.freeze({
    vitalname,
    vitalvalue,
    vitalunit,
    vitaladdeddate: vitaladdeddate,
    status,
    
  });
}
