import moment from "moment";

export default function formAPISurgeries(dataArray) {
  // Assuming dataArray is an array of objects
  return dataArray.map(({ 
    documentcount,
    documents,
    hospitalid,
    hospitalname,
    id,
    nomenclatureid,
    patientid,
    procedure_cd,
    proceduredate,
    proceduretitle,
    proceduretype_cd,
    surgeonname,


    }) => {
    return Object.freeze({
      documentcount,
      documents,
      hospitalid,
      hospitalname,
      id,
      nomenclatureid,
      patientid,
      procedure_cd,
      // proceduredate :  moment(proceduredate).format("Do MMM YYYY"),
      proceduredate,
      proceduretitle,
      proceduretype_cd,
      surgeonname,

    });
  });
}
