import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/navbar";
import SideBar from "../../components/sidebar";
import SecondaryNav from "../../components/seconday-nav";
import { Box, Divider, Flex, Text } from "@chakra-ui/layout";
import {
  Image,
  Button,
  Spacer,
  SimpleGrid,
  Checkbox,
  Stack,
  RadioGroup,
  Heading
} from "@chakra-ui/react";
import {
  PrimaryButton,
  PrimaryButtonIcon,
  PrimaryInput,
  SearchBar,
  Toaster,
} from "../../components";
import DynamicModal from "../../components/dynamic-modal";
import ModalRight from "../../components/modal-right";
import {
  addMedicalConditions,
  deleteMedicalConditions,
  getMedicalConditions,
} from "../../middleware/services/myhealth/conditions";
import {
  getallergies,
  getmedicalcondition,
} from "../../middleware/services/signup";
import {
  addAllergies,
  deleteAllergies,
  editAllergies,
  getPatientAllergies,
  FetchAllergiesAIAnswer,
  FetchAllergiesAIPrompts
} from "../../middleware/services/myhealth/allergies";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import DatePicker from "../../components/date-picker";
import { Form, useNavigate } from "react-router-dom";
import { format, setDate } from "date-fns";
import { uploadImageInProfile } from "../../middleware/services/profile/account";
import moment from "moment";
import Footer from "../../components/footer";
import { displayMillion_ThoudandValues, downloadDocument, getLoginInfo, handleAlphaNumericDotAndSpace } from "../../auth";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import TypingLoader from "../../components/typing-loader";
import AiModal from '../../components/ai-modal/index';
import { FetchMyHealthAIData} from "../../middleware/services/home";
import { FetchTokens } from "../../middleware/services/profile/rewards";

const AllergiesIndex = () => {
  const dynamicModal = React.useRef();
  const addCondition = React.useRef();
  const addCondition2 = React.useRef();
  const addCondition3 = React.useRef();
  const addCondition4 = React.useRef();
  const editConditionModal = React.useRef();
  const reactionListModal = React.useRef();
  const navigate = useNavigate()


  const { isLoading, showLoader, dismissLoader } = useLoader();

  const [data, setData] = useState("");
  var [conditionsList, setConditionsList] = useState(null);
  var [reactionList, setReactionList] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [selectedConditionsInEdit, setSelectedConditionsInEdit] = useState([]);
  const [selectedReactions, setSelectedReactions] = useState([]);
  const [patientid, setPatientid] = useState("");
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [selectedDates, setSelectedDates] = useState({});
  const [reasonsArray, setReasonsArray] = useState([]);
  const [allergiesContinueBtnDisabled, setAllergiesContinueBtnDisabled] = useState(true)
  const [reactionsContinueBtnDisabled, setReactionsContinueBtnDisabled] = useState(true)
  const [reactionsContinueBtnDisabledEdit, setReactionsContinueBtnDisabledEdit] = useState(true)
  const [reactionsContinueBtnDisabled2, setReactionsContinueBtnDisabled2] = useState(true)
  const [patientallergyid, setPatientallergyid] = useState("");
  const [allergytype, setAllergytype] = useState("");
  const [allergyID, setAllergieID] = useState("");
  const [identifiedData, setIdentifiedData] = useState("")
  const [documentURLs, setDocumentURLs] = useState([]);
  const [allergytype_cd , setAllergytype_cd ] = useState("")
  const [patientAllergyId , setPatientAllergyId ] = useState("")
  
  const [typedCondition , setTypedCondition] = useState("")
  const [typedReaction , setTypedReaction] = useState("")
  const indexMap = {};

  var inputForGet = {};
  const chatContainerRef = useRef(null);
  const [aianswers, setAIAnswers] = useState([]);
  const [remainingquestions, setRemainingQuestions] = useState([]);
  const [aiquestion, setAIAnalysis] = useState(null);
  const [AiInput, setAiInput] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [animate, setAnimate] = useState(false);
  const AIChatModel = useRef();
  const floatLabel = { color: "#384052", fontSize: "0.775rem", position: "relative", top: "-4px", left: "-4px", opacity: "0.5", width: "100%" };
  
  const [upgradePlanModal , setUpgradePlanModal] = useState(false)
  const [remainingTokens, setRemainingTokens] = useState();

  useEffect(() => {
    getTokens();
  }, [])
  var input = {}
  var getTokens = async () => {
    try {
      var result = await FetchTokens(input);
      if (result) {
        setRemainingTokens(result)
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.log(error, "error in fetching account data")
    }
    //dismissLoader();
  }
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
   
    if (selectedConditions.length === 0) {
      setAllergiesContinueBtnDisabled(true);
    } else {
      setAllergiesContinueBtnDisabled(false);
    }
  }, [selectedConditions]);

  useEffect(() => {
   let filteredReactions = reasonsArray.filter(item => item.allergy_id == allergyID)
   console.log(filteredReactions , " filteredReactions")
   if(filteredReactions.length > 0 ){
     setReactionsContinueBtnDisabled2(false)
   }else{
     setReactionsContinueBtnDisabled2(true)
   }
    // if (reasonsArray.length === 0) {
    //   setReactionsContinueBtnDisabled2(true);
    // } else {
    //   setReactionsContinueBtnDisabled2(false);
    // }
  }, [reasonsArray]);

  const getUniqueObjects = (arr, key) => {
    return arr.reduce((acc, current) => {
      if (!acc.some(item => item[key] === current[key])) {
        acc.push(current);
      }
      return acc;
    }, []);
  };

  useEffect(() => {
   
    const uniqueObjects = getUniqueObjects(reasonsArray, 'allergy_id');
  // console.log(uniqueArray.length , " uniqueArray.lengt")
    // console.log(selectedConditions.length , " selectedConditions.lengt")
    if(reasonsArray.length > 0 && uniqueObjects.length > 0){
      if(selectedConditions.length == uniqueObjects.length ){
      
        setReactionsContinueBtnDisabled(false);
        
      }else {
        setReactionsContinueBtnDisabled(true);
        
      }
    }
   
    // if (reasonsArray.length === 0) {
    //   setReactionsContinueBtnDisabled(true);
    // } else {
    //   setReactionsContinueBtnDisabled(false);
    // }
  }, [reasonsArray]);

     
  useEffect(() => {
    if (reasonsArray.length === 0) {
      setReactionsContinueBtnDisabledEdit(true);
    } else {
      setReactionsContinueBtnDisabledEdit(false);
    }
  }, [reasonsArray]);

  useEffect(() => {
    setDocumentURLs(uploadedDocuments)
  }, [uploadedDocuments])

  const fetchData = async () => {
    showLoader();
    try {
      const result = await getPatientAllergies(inputForGet);
      if(result && result.output && result.output.result == "failure"){
        setData(null)
      }else if (result && result.output && result.output.data == null) {
        setData(null)
      } else if (result != null) {
        setData(result);
      }else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.error("Error in get allergies:", error);
    }
    dismissLoader();
  };

  const fetchAllergiesList = async () => {
    try {
      const result = await getmedicalcondition({
        type: "allergies",
        
      "patientid":getLoginInfo()
      });
      if (result.output.result == "success") {
        setConditionsList(result.output.data);
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const fetchReactionsList = async () => {
    try {
      const result = await getmedicalcondition({
        type: "reaction",
        
      "patientid":getLoginInfo()
      });
      if (result.output.result == "success") {
        setReactionList(result.output.data);
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.error("Error", error);
    }
  };



  const handleCheckboxChange = (condition) => {
    const isSelected = selectedConditions.some(
      (item) => item.condition_cd === condition.condition_cd
    );

    if (isSelected) {
      setSelectedConditions((prevConditions) =>
        prevConditions.filter(
          (item) => item.condition_cd !== condition.condition_cd
        )
      );
    } else {
      setSelectedConditions([...selectedConditions, condition]);
    }
  };

  const handleReactionCheckboxChange = (condition, allergyid) => {
    const isSelected = reasonsArray.some(
      (item) =>
        item.allergy_id === allergyid &&
        item.condition_cd === condition.condition_cd
    );

    if (isSelected) {
      // If already selected, remove the item from reasonsArray
      setReasonsArray((prevConditions) =>
        prevConditions.filter(
          (item) =>
            item.allergy_id !== allergyid ||
            item.condition_cd !== condition.condition_cd
        )
      );
    } else {
      // If not selected, add the item to reasonsArray
      setReasonsArray([...reasonsArray, { ...condition, allergy_id: allergyid }]);
    }
  };

  const handleReactionCheckboxChangeInEdit = (reaction) => {
    // Check if the reaction object has condition and condition_cd attributes
    if (reaction.hasOwnProperty('condition') && reaction.hasOwnProperty('condition_cd')) {
      // Convert to reaction and reaction_cd attributes
      reaction = {
        reaction: reaction.condition,
        reaction_cd: reaction.condition_cd
      };
    }
  
    const isSelected = reasonsArray.some((item) => item.reaction === reaction.reaction);
  
    if (isSelected) {
      // If already selected, remove the item from reasonsArray
      setReasonsArray((prevConditions) =>
        prevConditions.filter((item) => item.reaction !== reaction.reaction)
      );
    } else {
      // If not selected, add the item to reasonsArray
      setReasonsArray([...reasonsArray, reaction]);
    }
  };
  
  const editReactions = ()=>{
   
      // enter manually 
      if (typedReaction !== "") {
        const newSelectedCondition = {
          reaction:typedReaction,
          reaction_cd:null,
          conditiontype:"other"
        };
    
        setReasonsArray((prevConditions) => {
          // Filter out any existing condition with the same type
          const filteredConditions = prevConditions.filter(
            (item) => item?.conditiontype !== "other"
          );
          // Add the new condition
          return [...filteredConditions, newSelectedCondition];
        });
      }
  
      //--------------------------------
  
    reactionListModal.current.closeRight()
  }

  

  const handleDateChange = (conditionCd, date) => {

    const formattedDate = format(date, "yyyy-MM-dd");
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [conditionCd]: formattedDate,
    }));
  };
  const [dateInEdit , setDateInEdit] = useState("")
  const handleDateChangeInEdit = ( date) => {
    setDateInEdit(date)
  };

  const AddReactionsInInput = (allergyid) => {
     // enter manually 
   
     if (typedReaction !== "") {
      const newSelectedCondition = {
        condition:typedReaction,
        allergy_id:allergyid,
        condition_cd:null,
        conditiontype:"other"
      };
  
      setReasonsArray((prevConditions) => {
        // Filter out any existing condition with the same type
        // const filteredConditions = prevConditions.filter(
        //   (item) => item?.conditiontype !== "other"
        // );
        // Add the new condition
        return [...prevConditions, newSelectedCondition];
      });
    }

    //--------------------------------

    addCondition3.current.closeRight();
   // const selectedReactionsArray = Object.values(selectedReactions);
   // handleReactionChange(allergyid, selectedReactionsArray);
  };

  // const handleReactionChange = (conditionCd, reactionsArray) => {
  // };

  var inputForAdd = {
    allergy: selectedConditions.map((condition, index) => ({
      condition: condition.condition,
      condition_cd: condition.condition_cd,
      allergytype_cd: condition.condition_cd,
      allergyidentifieddate: selectedDates[condition.condition_cd] || "",
      ...(condition.conditiontype && { conditiontype: condition.conditiontype }),
      reactions:
        reasonsArray
          .filter((reaction) => reaction.allergy_id === condition.condition_cd)
          .map((filteredReaction) => ({
            reaction_cd: filteredReaction.condition_cd,
            reaction: filteredReaction.condition,
          })) || [],
      documents: uploadedDocuments
        .filter((doc) => doc.condition_cd == condition.condition_cd)
        .map(({ condition_cd, filename }) => ({
          condition_cd,
          filename,
        })),
    })),
  };
  const AddMedicalConditions = () => {
    if (selectedConditions.length === 0 && typedCondition == "") {
      showErrorToast("Please Select a Allergy to Continue");
    } else {
        // enter manually 
        if (typedCondition !== "") {
          const newSelectedCondition = {
            condition:typedCondition,
            condition_cd:null,
            conditiontype:"other"
          };
      
          setSelectedConditions((prevConditions) => {
            // Filter out any existing condition with the same type
            const filteredConditions = prevConditions.filter(
              (item) => item?.conditiontype !== "other"
            );
            // Add the new condition
            return [...filteredConditions, newSelectedCondition];
          });
        }
  
        //--------------------------------
      setSelectedDates({})
      addCondition2.current.openRight();
    }
  };



  const AddMedicalConditionsCall = () => {
    const addConditions = async () => {
      showLoader();
      try {
        const result = await addAllergies(inputForAdd);
        if (result.output.result == "success") {
          showSuccessToast("Successfully Added")
          fetchData();
          addCondition2.current.closeRight();
          addCondition.current.closeRight();
          setAllergieID("")
        } else {
          showErrorToast("Something went wrong!")
        }
      } catch (error) {
        console.error("Error in allergies:", error);
      }
      dismissLoader();
    };

    const isDateMissing = selectedConditions.some(
      (condition) => !selectedDates[condition.condition_cd]
    );

    const isReactionMissing = selectedConditions.some(
      (condition) =>
        !reasonsArray.some((reason) => reason.allergy_id === condition.condition_cd)
    );
    if (isDateMissing) {
      showErrorToast("Please Select the Date");
      return false;
    }else if (isReactionMissing){
      showErrorToast("Please Select the reaction");
      return false;
    } else {
      addConditions();

    }
  };

  const EditMedicalConditionsCall = () => {
    
    let formatedproceduredate = moment(dateInEdit, 'DD-MM-YYYY').format('YYYY-MM-DD');
    var inputForEdit = {
      allergytype_cd,
      "allergyidentifieddate": formatedproceduredate,
      "patientallergyid": patientAllergyId,
        reactions: reasonsArray,
  
        documents: uploadedDocuments
          .map(({ condition_cd, filename }) => ({
            condition_cd,
            filename,
          })),
    };

    const editConditions = async () => {
      showLoader();
      try {
       const result = await editAllergies(inputForEdit);
        if (result.output.result == "success") {
          showSuccessToast("Successfully Updated")
          fetchData();
          reactionListModal.current.closeRight();
          editConditionModal.current.closeRight();
          setAllergieID("")
        } else {
          showErrorToast("Something went wrong!")
        }
      } catch (error) {
        console.error("Error in allergies:", error);
      }
      dismissLoader();
    };

      editConditions();

  };

  const { showSuccessToast, showErrorToast } = Toaster();
  var inputForDelete = {
    patientallergyid,
  };
  const deleteMedicalCondition = async () => {
    const deleteCondition = async () => {
      showLoader();
      try {
        const result = await deleteAllergies(inputForDelete);
        if (result.output.result == "success") {
          showErrorToast(allergytype + " has been Deleted");
          fetchData();
          dynamicModal.current.closeModal();
        } else {
          showErrorToast("Something went Wrong!")
        }
      } catch (error) {
        console.error("Error in DashboardIndex:", error);
      }
    };
    deleteCondition();

  };

  const openDeleteModal = (id, allergy) => {
    setPatientallergyid(id);
    setAllergytype(allergy);

    dynamicModal.current.openModal();
  };
  const showReactionList = (allergyid) => {
    let filteredReactions = reasonsArray.filter(item => item.allergy_id == allergyid)
  if(filteredReactions.length > 0 ){
     setReactionsContinueBtnDisabled2(false)
   }else{
     setReactionsContinueBtnDisabled2(true)
   }
   // if (reactionList == null) {
      fetchReactionsList();
   // }
   setTypedCondition("")
   setTypedReaction("")
    setSearchValue("")
    setAllergieID(allergyid);
    addCondition3.current.openRight();
  };

  const showReactionListInEdit = () => {
   // if (reactionList == null) {
      fetchReactionsList();
  //  }
    setSearchValue("")
    reactionListModal.current.openRight();
  };

  const removeTag = (index) => {
    const updatedReasonsArray = [...reasonsArray];
    updatedReasonsArray.splice(index, 1);
    setReasonsArray(updatedReasonsArray);
  };

  const removeReaction = (allergyId, reactionId) => {
    setReasonsArray((prev) =>
      prev.filter(
        (tag) => !(tag.allergy_id === allergyId && tag.condition_cd === reactionId)
      )
    );
  };

  const openAllergiesModal = () => {
   // if (conditionsList == null) {
      fetchAllergiesList();
  //  }
    setReasonsArray([])
    setSearchValue("");
    setSelectedConditions([]);
    setSelectedReactions([]);
    setUploadedDocuments([])
    setDocumentURLs([])
    setTypedCondition("")
    setTypedReaction("")
    addCondition.current.openRight();
  };



  const fileInputRef = useRef(null);
  const fileInputRefInEdit = useRef(null);

  const handleAllergiesUpload = async (e) => {
    e.preventDefault();

    let condition_cd = e.target.getAttribute('data-surgery-cd');
    if(condition_cd === "null"){
      condition_cd = null
     }
    showLoader();

    const file2 = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file2);
    const result = await uploadImageInProfile(file2, file2.name);
    const filename = result.name;
    setUploadedDocuments((prevDocuments) => [
      ...prevDocuments,
      { condition_cd, filename },
    ]);
    showSuccessToast("Document Uploaded Successfully.");
    e.target.value = '';
    dismissLoader();

  };

  const handleAllergiesUploadInEdit = async (e) => {
    e.preventDefault();

    const condition_cd = e.target.getAttribute('data-surgery-cd');
    showLoader();

    const file2 = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file2);
    const result = await uploadImageInProfile(file2, file2.name);
    const filename = result.name;
    setUploadedDocuments((prevDocuments) => [
      ...prevDocuments,
      { condition_cd, filename },
    ]);
    showSuccessToast("Document Uploaded Successfully.");
    e.target.value = '';
    dismissLoader();

  };


  const addSurgeriesUploadDocument = (condition_cd) => {

 

    if(condition_cd){
      
      fileInputRef.current.setAttribute('data-surgery-cd', condition_cd);
      fileInputRef.current.click();
      
    }else{
      
      fileInputRef.current.setAttribute('data-surgery-cd', null);
      fileInputRef.current.setAttribute('data-surgery',  "other");
      fileInputRef.current.click();
    }

  }
  const editSurgeriesUploadDocument = (condition_cd) => {

    fileInputRefInEdit.current.setAttribute('data-surgery-cd', condition_cd);
    fileInputRefInEdit.current.click();
  }

  const openDocumentsModal = (documentsObj, allergytype, allergyidentifieddate) => {
    setAllergytype(allergytype)
    setIdentifiedData(allergyidentifieddate)
    setDocumentURLs(documentsObj)
    addCondition4.current.openRight();
  }
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return moment(dateString).format('Do MMM, YYYY');
  };
  const handleDelete = (index) => {
    const updatedUrls = [...uploadedDocuments];
    updatedUrls.splice(index, 1);
    setUploadedDocuments(updatedUrls);
  };
  const openEditModal = (  patientallergyid, allergytype, allergyidentifieddate,
    documentcount,
    reaction,
    documents,
    allergytype_cd)=>{
      setSelectedConditionsInEdit([])
      setAllergytype_cd(allergytype_cd)
      setPatientAllergyId(patientallergyid)
    var formatedDateInEdit =  moment(allergyidentifieddate).format("DD-MM-YYYY")
    setDateInEdit(formatedDateInEdit)
    setDocumentURLs([])
    setReasonsArray([])

    setUploadedDocuments([])
    if(documents){
      var mappedFileNames = documents.map(doc =>({"document" : doc.documentname}))
      console.log(mappedFileNames , " mapped file names ")
      setUploadedDocuments((prevDocuments) => [
        ...prevDocuments,
        ...documents.map(doc =>(
          {"condition_cd" : allergytype_cd,
            "filename" : doc.documentname
          }
        ))
      ]);
    }
    

    reaction.forEach((item) => {
      // Assuming each drug has a "name" property
      const newReason = {
        reaction: item.reaction,
        reaction_cd: item.reaction_cd,
      };
      setReasonsArray((prevArray) => [...prevArray, newReason]);
    });
      setSelectedConditionsInEdit(allergytype)

    editConditionModal.current.openRight();
  }

  const handleCondition = (e) => {
    const inputText = e.target.value;
    const maxLength = 80;
  
    if (inputText.length <= maxLength) {
      setTypedCondition(inputText);
    }
    if(e.target.value){
      setAllergiesContinueBtnDisabled(false)
    }else{
      setAllergiesContinueBtnDisabled(true)
    }
  
  }

  const handleReaction = (e) => {
    const inputText = e.target.value;
    const maxLength = 80;
    
    if (inputText.length <= maxLength) {
      setTypedReaction(inputText);
    }
    if(e.target.value){
      setReactionsContinueBtnDisabled2(false)
      setReactionsContinueBtnDisabledEdit(false)
    }else{
      setReactionsContinueBtnDisabled2(true)
      setReactionsContinueBtnDisabledEdit(true)
    }
  
  }
  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [animate]);
  
  useEffect(() => {
    // Scroll to bottom whenever aianswers changes
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [aianswers]);
  const fetchAiDoctorPrompts = async (allergyname)=>{
    showLoader();
    setRemainingQuestions([])
    setAiInput(null);
    setAIAnswers([])
    setInitialRender(true)
    const result = await FetchMyHealthAIData({
      patientid,
    });
    let Aiprompts = { ...result, allergyname };
      setAiInput(Aiprompts);
      const response = await FetchAllergiesAIPrompts(Aiprompts);
      if (response.generated_question) {
        setAIAnalysis(response);
        setRemainingQuestions(response.generated_question);
        AIChatModel.current.openRight();
      }else{
       // showErrorToast("Ai tokens are not available");
        setUpgradePlanModal(true)
          setTimeout(() => {
            setUpgradePlanModal(false)
          }, 100);
        AIChatModel.current.closeRight();
      }
      dismissLoader();
  }
  
  const GetAIAnswer = async (prompt) => {
    // Display question immediately
    const newQuestion = { question: prompt, answer: "" };
    setAIAnswers((prevState) => [...prevState, newQuestion]);
  
    // Remove the question from remaining questions immediately
    setRemainingQuestions((prevState) => prevState.filter((question) => question.value !== prompt));
  
    // Trigger animation
    setAnimate(true);
  
    // Set initialRender to false after the first question is clicked
    setInitialRender(false);
    let AichatInput = { ...AiInput, query:prompt };
  
  
    const response = await FetchAllergiesAIAnswer(AichatInput);
  
    if (response) {
      // Replace "typing" with actual answer
      setAIAnswers((prevState) =>
        prevState.map((item) =>
          item.question === prompt && item.answer === ""
            ? { question: item.question, answer: response.answer }
            : item
        )
      );
      getTokens();
    }else {
     // showErrorToast("Ai tokens are not available");
      setUpgradePlanModal(true)
          setTimeout(() => {
            setUpgradePlanModal(false)
          }, 100);
      AIChatModel.current.closeRight();
    }
  };
  return (
    <>
      <Box w="100%" display={{base: "none", lg: "block"}}>
        <Box top="0" zIndex="banner">
          <Navbar  messageModal={upgradePlanModal} />
          {isLoading && <Loader />}
          <SecondaryNav
            defaultHeader="My Health"
            defaultSecondayHeader="Allergies"
            defaultNavBody={
              <>
                <Box display="flex" gap="10px" alignItems="center" pr="25px">
                  {data != null && (
                    <PrimaryButton
                      fontSize="14px"
                      variant="mdBtn"
                      buttonText="Add Allergy"
                      onClick={openAllergiesModal}
                      btnIcon="/assets/imgs/add.png"
                    />
                  )}
                </Box>
              </>
            }
          />
        </Box>
        <Box h="calc(100vh - 140px)" overflow="hidden auto">
        <Flex>
          <Box position="sticky" top="0" zIndex="sticky" h="100vh">
            <SideBar />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap="20px"
            w="100%"
            p={4}
            pl="25px"
          >
            <Box w="100%" h="auto">
              <Flex
                wrap="wrap"
                pt="20px"
                w="100%"
                justifyContent="left"
                gap="20px"
              >
                {data &&
                  Array.isArray(data) &&
                  data.map(
                    (
                      {
                        patientallergyid,
                        allergytype,
                        allergyidentifieddate,
                        documentcount,
                        reaction,
                        documents,
                        allergytype_cd,
                      },
                      innerIndex
                    ) => (
                      <Box
                        key={innerIndex}
                        flex="0 0 calc(33.333% - 20px)"
                        mb="20px"
                        border="1px solid #E6E7E9"
                        borderRadius="12px"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <Box
                          as="button"
                          p="20px"
                          w="100%"
                          justifyContent="space-between"
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box display="flex" alignItems="center">
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                              >
                                <Text
                                  fontSize="16px"
                                  textAlign="left"
                                  fontWeight="600"
                                  wordBreak="break-all"
                                >
                                  {allergytype}
                                </Text>
                                <Text
                                  fontSize="12px"
                                  fontWeight="500"
                                  textAlign="left"
                                  color="#384052"
                                >
                                  {formatDate(allergyidentifieddate)} <br />
                                </Text>
                              </Box>
                            </Box>
                        <Box display='flex' flexDir='column' gap="8px" alignItems="flex-end">
                        
                                 <Box
                                  as="button"
                                  // onClick={}
                                  border="1px solid #E6E7E9"
                                  borderRadius="full"
                                  w="36px"
                                  h="auto"
                                  p="6px"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  onClick={()=>fetchAiDoctorPrompts(allergytype)}
                                >
                                  <Image w="26px" src="/assets/svgs/ai-star-outline.svg" />
                                </Box>
                                <Box display='flex' w="100%" gap='0.5rem' mt='1rem' justifyContent='flex-end'>
                                <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              onClick={() =>
                                openEditModal(
                                  patientallergyid,
                                  allergytype,
                                  allergyidentifieddate,
                                  documentcount,
                                  reaction,
                                  documents,
                                  allergytype_cd
                                )
                              }
                            >
                              <Image w="13px" src="/assets/imgs/edit-gray.png" />
                            </Box>
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              onClick={() =>
                                openDeleteModal(patientallergyid, allergytype)
                              }
                            >
                              <Image w="13px" src="/assets/imgs/delete.png" />
                            </Box>
                          </Box>
                        </Box>
                          </Box>
                          <Divider my="15px" />
                          <Text
                            fontSize="12px"
                            fontWeight="500"
                            textAlign="left"
                            wordBreak="break-all"
                          >
                            {reaction.map((reactionItem, reactionIndex) => (
                              <React.Fragment key={reactionIndex}>
                              <span>{reactionItem.reaction}</span>
                              {reactionIndex !== reaction.length - 1 && <span>, </span>}
                            </React.Fragment>
                            ))}
                          </Text>

                          <Box
                            as="button"
                            mt="15px"
                            w="100%"
                            border="1px solid #E6E7E9"
                            borderRadius="10px"
                            p="12px 16px"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            onClick={() => openDocumentsModal(documents, allergytype, allergyidentifieddate)}

                          >
                            <Box mr="8px" fontSize="14px" fontWeight="600">
                              {documents ? documents.length : "0"} documents
                            </Box>
                            <Box>
                              <Image src="/assets/imgs/right-icon.png" />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )
                  )}
              </Flex>
            </Box>

            {/* No Data page */}
            {data == null && (
              <Box
                w="100%"
                mt="-100px"
                h="100vh"
                display="flex"
                flexDirection="column"
                gap="5px"
                justifyContent="center"
                alignItems="center"
              >
                <Image w="60px" opacity="0.5" src="/assets/svgs/allergy-nodata.svg" />
                <Box as="p">No data available</Box>
                <PrimaryButton
                  fontSize="14px"
                  variant="mdBtn"
                  buttonText="Add Allergy"
                  onClick={openAllergiesModal}
                  btnIcon="/assets/imgs/add.png"
                />
              </Box>
            )}
          </Box>


        </Flex>
        <Footer />
        </Box>
        <DynamicModal
          ref={dynamicModal}
          modalHeader="Delete report?"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete  <strong>"{allergytype}"</strong>
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                onClick={deleteMedicalCondition}
              />
            </>
          }
        />

        <ModalRight
          ref={addCondition}
          header="Add allergy"
          body={
            <>
              <SearchBar
                passInputPlace="Search Allergy"
                onChange={(e) => setSearchValue(handleAlphaNumericDotAndSpace(e.target.value))}
                searchValue={searchValue}
              />
              <RadioGroup>
                <Stack direction="column" gap="0">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #e2e8f0"
                    py="15px"
                  >
                    <Box display="flex" justifyContent="end" w="100%">
  <Stack direction="column" gap="0" w="100%">
    {conditionsList && conditionsList.length > 0 ? (
      conditionsList.some(category =>
        category.data.some(condition =>
          condition.condition.toLowerCase().includes(searchValue.toLowerCase())
        )
      ) ? (
        conditionsList.map(category => (
          <React.Fragment key={category.category_id}>
            {category.data
              .filter(condition =>
                condition.condition.toLowerCase().includes(searchValue.toLowerCase())
              )
              .map(condition => (
                <label key={condition.condition_cd}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #e2e8f0"
                    p="15px"
                    height="50px"
                    width="100%"
                    sx={{
                      _hover: {
                        border: "2px solid #384052",
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <Box
                      key={condition.condition_cd}
                      display="flex"
                      alignItems="center"
                    >
                      <Text fontSize="16px" fontWeight="600">
                        {condition.condition}
                        <br />
                        <Box
                          as="span"
                          fontSize="12px"
                          fontWeight="500"
                          color="#6A707D"
                        ></Box>
                      </Text>
                    </Box>
                    <Box display="flex" justifyContent="end">
                      <Checkbox
                        colorScheme="green"
                        sx={{
                          ".chakra-checkbox__control": {
                            width: "20px",
                            height: "20px",
                            borderRadius: "5px",
                          },
                        }}
                                        onChange={() =>
                                          handleCheckboxChange(condition)
                                        }
                        isChecked={selectedConditions.some(
                                          (item) =>
                                            item.condition_cd ===
                                            condition.condition_cd
                        )}
                      ></Checkbox>
                    </Box>
                  </Box>


                </label>
              ))}
          </React.Fragment>
        ))
      ) : (
        <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
          No matching allergies found!
        </Box>
      )
    ) : (
      <p>Loading...</p>
    )}
     <Divider/>
          <br/>
          <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                   Other Allergy
                  </Heading>
        <PrimaryInput
         inputPlace="Allergy"
         variant="bothSide"
                onChange={handleCondition} inputValue={typedCondition}
                />
                <br/>
  </Stack>
</Box>
                  </Box>
                </Stack>
              </RadioGroup>
              <ModalRight
                ref={addCondition3}
                handlebackarrow={() => addCondition3.current.closeRight()}
                withBackBtn={true}
                withBackHeader="Reaction"
                body={
                  <>
                    <SearchBar
                      passInputPlace="Search Reaction"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <RadioGroup>
                      <Stack direction="column" gap="0">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          borderBottom="1px solid #e2e8f0"
                          py="15px"
                        >
                          <Box display="flex" justifyContent="end" w="100%">
                            <Stack direction="column" gap="0" w="100%">
                              {reactionList && reactionList.length > 0 ? (
                                reactionList.map((category) =>
                                  category.data
                                    .filter((condition) =>
                                      condition.condition
                                        .toLowerCase()
                                        .includes(searchValue.toLowerCase())
                                    )
                                    .map((condition) => (
                                      <label key={condition.condition_cd} >

                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          borderBottom="1px solid #e2e8f0"
                                          p="15px"
                                          height="50px"
                                          width="100%"
                                          sx={{
                                            _hover: {
                                              border: "2px solid #384052",
                                              borderRadius: "10px",
                                            },
                                          }}
                                        >
                                          <Box
                                            key={condition.condition_cd}
                                            display="flex"
                                            alignItems="center"
                                          >
                                            <Text fontSize="16px" fontWeight="600">
                                              {condition.condition}
                                              <br />
                                              <Box
                                                as="span"
                                                fontSize="12px"
                                                fontWeight="500"
                                                color="#6A707D"
                                              ></Box>
                                            </Text>
                                          </Box>
                                          <Box display="flex" justifyContent="end">
                                            <Checkbox
                                              colorScheme="green"
                                              sx={{
                                                ".chakra-checkbox__control": {
                                                  width: "20px",
                                                  height: "20px",
                                                  borderRadius: "5px",
                                                },
                                              }}
                                              onChange={() =>
                                                handleReactionCheckboxChange(
                                                  condition, 
                                                  allergyID
                                                )
                                              }
                                              isChecked={reasonsArray.some(
                                                (item) =>
                                                  item.allergy_id === allergyID &&
                                                  item.condition_cd ===
                                                  condition.condition_cd
                                              )}
                                            ></Checkbox>
                                          </Box>
                                        </Box>

                                      </label>
                                    ))
                                )
                              ) : (
                                <p>Loading...</p>
                              )}
                               <Divider/>
                                <br/>
                                <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                                         Other Reactions
                                        </Heading>
                              <PrimaryInput
                               inputPlace="Reactions"
                               variant="bothSide"
                                      onChange={handleReaction} inputValue={typedReaction}
                               />
                               <br/>
                            </Stack>
                          </Box>
                        </Box>
                      </Stack>
                    </RadioGroup>
                  </>
                }
                footerActions={
                  <>
                    <Box w="100%" display="flex" flexDirection="row" gap="15px">
                      <PrimaryButton
                        buttonText="Add reactions"
                        isDisabled={reactionsContinueBtnDisabled2}
                        onClick={() => AddReactionsInInput(allergyID)}
                      />
                    </Box>
                  </>
                }
              />

              <ModalRight
                ref={addCondition2}
                handlebackarrow={() => addCondition2.current.closeRight()}
                withBackBtn={true}
                withBackHeader="Add allergy"
                body={
                  <>
                    <Box display="flex" flexDir="column" gap="20px">
                      {selectedConditions.map((condition, index) => (
                        <Box key={index}>
                          <Text fontSize="16px" py="10px" fontWeight="600">
                            {condition.condition}
                          </Text>
                          <DatePicker
                            disableFutureDates={true}
                            onDateChange={(selectedDateValue) => handleDateChange(condition.condition_cd, selectedDateValue)}
                            placeholderProp="Date*" />
                        
                          <Flex w="100%" alignItems="center" gap="2">
                            <Box
                              as="div"
                              role="button"
                              aria-haspopup="dialog"
                              display="flex"
                              alignItems="center"
                              tabIndex={0}
                              border="1px solid #e2e8f0"
                              borderRadius="0 0 10px 10px"
                              p="10px"
                              height="auto"
                              width="100%"
                              sx={{
                                _hover: { border: "2px solid #384052" },
                              }}
                              onClick={() => showReactionList(condition.condition_cd)}
                              minHeight="48px"
                              flexWrap="wrap"
                            >
                              <Box
                                key={condition.condition_cd}
                                display="flex"
                                alignItems="center"
                                flexWrap="wrap"
                                gap="5px"
                                w="100%"
                                justifyContent={reasonsArray.length === 0 ? "space-between" : "start"}>
                                {reasonsArray.length === 0 ? (
                                  <Text pl="2" color="gray.400">
                                    Reaction*
                                  </Text>
                                ) : reasonsArray.some(
                                  (item) => item.allergy_id === condition.condition_cd
                                ) ? (
                                  <Text pl="2" color="gray.400" sx={{ ...reasonsArray.length === 0 ? {} : floatLabel }} >Reaction*</Text>
                                ) : (
                                  <Text pl="2" color="gray.400">
                                    Reaction*
                                  </Text>
                                )}
                                {reasonsArray.length === 0 && (
                                  <Image src="/assets/imgs/right-icon.png" />
                                )}

                                {reasonsArray
                                  .filter(
                                    (tag) => tag.allergy_id == condition.condition_cd
                                  )
                                  .map((tag, index) => (
                                    <Box
                                      key={index}
                                      display="flex"
                                      alignItems="center"
                                      gap="5px"
                                      bg="gray.100"
                                      borderRadius="full"
                                      wordBreak="break-all"
                                      p="1"
                                    >
                                      <Text fontSize="sm" px="5px">
                                        {tag.condition}
                                      </Text>
                                      <Box
                                        as="button"
                                        p="5px"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // removeTag(index);
                                          removeReaction(condition.condition_cd, tag.condition_cd);
                                        }}
                                        aria-label="Remove condition"
                                      >
                                        <Image src="/assets/imgs/close.png" />
                                      </Box>
                                    </Box>
                                  ))}
                              </Box>
                              <Box display="flex" justifyContent="end"></Box>
                            </Box>
                          </Flex>
                          {documentURLs?.map((document, index) => {
                              if (document.condition_cd == condition.condition_cd || (document.condition_cd == null && condition.condition_cd == null)) {
                            if (!indexMap[document.condition_cd]) {
                                indexMap[document.condition_cd] = 1;
                              }
                              const docIndex = indexMap[document.condition_cd];
                              indexMap[document.condition_cd]++; // Increment index for next document with same condition_cd

                              return (
                                <Box key={index} mb="2" mt="2">
                                  <Button
                                    as="a"
                                    rel="noopener noreferrer"
                                    w="100%"
                                    h="48px"
                                    borderRadius="10px"
                                    border="1px solid #E6E7E9"
                                    display="flex"
                                    justifyContent="space-between"
                                    bg="white"
                                    color="#1C4EC5"
                                    sx={{ _hover: { bg: "none" } }}
                                    style={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Document {docIndex}
                                    <Box as="span" onClick={() => handleDelete(index)}>
                                      <Image src="/assets/imgs/delete.png" />
                                    </Box>
                                  </Button>
                                </Box>
                              );
                            }
                          })}
                          <Box onClick={() => addSurgeriesUploadDocument(condition.condition_cd)} as="button" display="flex" alignItems="center" gap="10px" mt="5px" fontSize="14px" fontWeight="600" color="#1C4EC5" textAlign="left">
                            <Image w="11px" src="/assets/imgs/add-blue.png" />
                            Add Document
                          </Box>
                          <input
                            type='file'
                            id='file-upload'
                            onChange={(e) => handleAllergiesUpload(e)}
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                          />
                          {/* <Divider /> */}
                        </Box>
                      ))}
                    </Box>
                  </>
                }
                footerActions={
                  <>
                    <Box w="100%" display="flex" flexDirection="row" gap="15px">
                      <PrimaryButton
                        buttonText="Add Allergy"
                        isDisabled={reactionsContinueBtnDisabled}
                        onClick={AddMedicalConditionsCall}
                      />
                    </Box>
                  </>
                }
              />

            </>
          }
          footerActions={
            <>
              <Box w="100%" display="flex" flexDirection="row" gap="15px">
                <PrimaryButton
                  buttonText="Continue"
                  isDisabled={allergiesContinueBtnDisabled}
                  onClick={AddMedicalConditions}
                />
              </Box>
            </>
          }
        />


        <ModalRight
          ref={addCondition4}
          secondaryHeader={allergytype}
          subHeader={formatDate(identifiedData) }
          body={
            <Flex pt="15px" flexDirection="column" h="100%">
              {documentURLs?.map((document, index) => (
                <Box key={index} mb="2"
                onClick={()=> downloadDocument(document.imagepath + document.documenturl)}
                cursor="pointer"
                >
                  <Button
                    rel="noopener noreferrer"
                    w="100%"
                    h="48px"
                    borderRadius="10px"
                    border="1px solid #E6E7E9"
                    display="flex"
                    justifyContent="space-between"
                    bg="white"
                    color="#1C4EC5"
                    sx={{ _hover: { bg: "none" } }}
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontSize: "12px",
                    }}
                  >
                    Document {index + 1}
                    <Box as="span">
                      <Image src="/assets/imgs/download.png" />
                    </Box>
                  </Button>
                </Box>
              ))}
              <Spacer />
             
            </Flex>
          }
        />

<ModalRight
                ref={editConditionModal}
                handlebackarrow={() => editConditionModal.current.closeRight()}
                withBackBtn={true}
                withBackHeader="Edit allergy"
                body={
                  <>
                    <Box display="flex" flexDir="column" gap="20px">
                        <Box>
                          <Text fontSize="16px" py="10px" fontWeight="600">
                            {selectedConditionsInEdit}
                          </Text>
                          <DatePicker
                            disableFutureDates={true}
                            defaultDate={dateInEdit}
                            onDateChange={(selectedDateValue) => handleDateChangeInEdit(selectedDateValue)}
                            placeholderProp="Date*" />
                        
                          <Flex w="100%" alignItems="center" gap="2">
                            <Box
                              as="div"
                              role="button"
                              aria-haspopup="dialog"
                              display="flex"
                              alignItems="center"
                              tabIndex={0}
                              border="1px solid #e2e8f0"
                              borderRadius="0 0 10px 10px"
                              p="10px"
                              height="auto"
                              width="100%"
                              sx={{
                                _hover: { border: "2px solid #384052" },
                              }}
                              onClick={() => showReactionListInEdit()}
                              minHeight="48px"
                              flexWrap="wrap"
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                flexWrap="wrap"
                                gap="5px"
                                w="100%"
                                justifyContent={reasonsArray.length === 0 ? "space-between" : "start"}>
                                {reasonsArray.length === 0 ? (
                                  <Text pl="2" color="gray.400">
                                    Reaction*
                                  </Text>
                                ) : reasonsArray.some(
                                  (item) => item.reaction === item.reaction,
                                ) ? (
                                  <Text pl="2" color="gray.400" sx={{ ...reasonsArray.length === 0 ? {} : floatLabel }} >Reaction*</Text>
                                ) : (
                                  <Text pl="2" color="gray.400">
                                    Reaction*
                                  </Text>
                                )}
                                {reasonsArray.length === 0 && (
                                  <Image src="/assets/imgs/right-icon.png" />
                                )}

                                {reasonsArray
                                  .filter(
                                    (tag) => tag.reaction === tag.reaction,
                                  )
                                  .map((tag, index) => (
                                    <Box
                                      key={index}
                                      display="flex"
                                      alignItems="center"
                                      gap="5px"
                                      bg="gray.100"
                                      borderRadius="full"
                                      p="1"
                                       wordBreak="break-all"
                                    >
                                      <Text fontSize="sm" px="5px">
                                        {tag.reaction}
                                      </Text>
                                      <Box
                                        as="button"
                                        p="5px"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                           removeTag(index);
                                        }}
                                        aria-label="Remove condition"
                                      >
                                        <Image src="/assets/imgs/close.png" />
                                      </Box>
                                    </Box>
                                  ))}
                              </Box>
                              <Box display="flex" justifyContent="end"></Box>
                            </Box>
                          </Flex>
                          {documentURLs?.map((document, index) => {
                          //  if (document.condition_cd == "0") {
                              if (!indexMap[document.condition_cd]) {
                                indexMap[document.condition_cd] = 1;
                              }
                           //   const docIndex = indexMap[document.condition_cd]; 
                              indexMap[document.condition_cd]++; // Increment index for next document with same condition_cd
                              
                              return (
                                <Box key={index} mb="2" mt="2">
                                  <Button
                                    as="a"
                                    rel="noopener noreferrer"
                                    w="100%"
                                    h="48px"
                                    borderRadius="10px"
                                    border="1px solid #E6E7E9"
                                    display="flex"
                                    justifyContent="space-between"
                                    bg="white"
                                    color="#1C4EC5"
                                    sx={{ _hover: { bg: "none" } }}
                                    style={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Document {index + 1}
                                    <Box as="span" onClick={() => handleDelete(index)}>
                                      <Image src="/assets/imgs/delete.png" />
                                    </Box>
                                  </Button>
                                </Box>
                              );
                            //}
                          })}
                          <Box onClick={() => editSurgeriesUploadDocument(allergytype_cd)} as="button" display="flex" alignItems="center" gap="10px" mt="5px" fontSize="14px" fontWeight="600" color="#1C4EC5" textAlign="left">
                            <Image w="11px" src="/assets/imgs/add-blue.png" />
                            Add Document
                          </Box>
                          <input
                            type='file'
                            id='file-upload'
                            onChange={(e) => handleAllergiesUploadInEdit(e)}
                            ref={fileInputRefInEdit}
                            style={{ display: 'none' }}
                          />
                          {/* <Divider /> */}
                        </Box>
                 
                    </Box>
                    <ModalRight
                ref={reactionListModal}
                handlebackarrow={() => reactionListModal.current.closeRight()}
                withBackBtn={true}
                withBackHeader="Reaction"
                body={
                  <>
                    <SearchBar
                      passInputPlace="Search Reaction"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <RadioGroup>
                      <Stack direction="column" gap="0">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          borderBottom="1px solid #e2e8f0"
                          py="15px"
                        >
                          <Box display="flex" justifyContent="end" w="100%">
                            <Stack direction="column" gap="0" w="100%">
                              {reactionList && reactionList.length > 0 ? (
                                reactionList.map((category) =>
                                  category.data
                                    .filter((condition) =>
                                      condition.condition
                                        .toLowerCase()
                                        .includes(searchValue.toLowerCase())
                                    )
                                    .map((condition) => (
                                      <label key={condition.condition_cd} >

                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          borderBottom="1px solid #e2e8f0"
                                          p="15px"
                                          height="50px"
                                          width="100%"
                                          sx={{
                                            _hover: {
                                              border: "2px solid #384052",
                                              borderRadius: "10px",
                                            },
                                          }}
                                        >
                                          <Box
                                            key={condition.condition_cd}
                                            display="flex"
                                            alignItems="center"
                                          >
                                            <Text fontSize="16px" fontWeight="600">
                                              {condition.condition}
                                              <br />
                                              <Box
                                                as="span"
                                                fontSize="12px"
                                                fontWeight="500"
                                                color="#6A707D"
                                              ></Box>
                                            </Text>
                                          </Box>
                                          <Box display="flex" justifyContent="end">
                                            <Checkbox
                                              colorScheme="green"
                                              sx={{
                                                ".chakra-checkbox__control": {
                                                  width: "20px",
                                                  height: "20px",
                                                  borderRadius: "5px",
                                                },
                                              }}
                                              onChange={() =>
                                                handleReactionCheckboxChangeInEdit(
                                                  condition
                                                )
                                              }
                                              isChecked={reasonsArray.some(
                                                (item) =>
                                                  item.reaction ===
                                                  condition.condition
          
                                              )}
                                            ></Checkbox>
                                          </Box>
                                        </Box>

                                      </label>
                                    ))
                                )
                              ) : (
                                <p>Loading...</p>
                              )}
                               <Divider/>
                                <br/>
                                <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                                         Other Reactions
                                        </Heading>
                              <PrimaryInput
                               inputPlace="Reactions"
                               variant="bothSide"
                                      onChange={handleReaction} inputValue={typedReaction}
                               />
                               <br/>
                            </Stack>
                          </Box>
                        </Box>
                      </Stack>
                    </RadioGroup>
                  </>
                }
                footerActions={
                  <>
                    <Box w="100%" display="flex" flexDirection="row" gap="15px">
                      <PrimaryButton
                        buttonText="Edit reactions"
                        isDisabled={reactionsContinueBtnDisabledEdit}
                        onClick={editReactions}
                      />
                    </Box>
                  </>
                }
              />
                  </>
                }
                
                footerActions={
                  <>
                    <Box w="100%" display="flex" flexDirection="row" gap="15px">
                      <PrimaryButton
                        buttonText="Update Allergy"
                        isDisabled={reactionsContinueBtnDisabledEdit}
                        onClick={EditMedicalConditionsCall}
                      />
                    </Box>
                  </>
                }
              />
               <AiModal
        ref={AIChatModel}
      modalSize="420px"
      //icon="/assets/imgs/ai-doctor.png"
      header={<>
        <Box display="flex" flexDir='column' gap='5px'>
            <Box display='flex' gap='6px'>
              <Text fontSize="1rem"
                fontWeight="700" 
                backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)" 
                color="transparent" 
                backgroundClip="text"
              >Aira - AI</Text>
              <Box display='flex' alignItems='center' gap='5px' border='1px solid #0d7e54' bg='#d5ece3'  p='3px 5px' borderRadius='full'>
                <Image src='/assets/imgs/spark.png' /> 
                <Text fontSize='10px' fontWeight='500' color='#000'>{remainingTokens && remainingTokens.remainingtokens ? displayMillion_ThoudandValues(remainingTokens.remainingtokens):"0"} tokens available</Text>
              </Box>
            </Box>
            <Text fontSize="0.75rem" fontWeight="400">Chat with your AI health guide</Text>
          </Box>
      </>}
      headerStyle={{ p: "24px 32px", lineHeight: "normal" }}
      closeBtnStyle={{ top: "18px" }}
      footerStyle={{ maxH: "35%", overflow: "hidden auto", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      withoutBg={true}
      backDropBlur="10px"
      body={
        <>
          <Flex flexDirection="column" height="100%">
            <Box
              p="24px 32px"
              ref={chatContainerRef}
              className="chat-container"
              style={{ overflowY: "auto", maxHeight: 'calc(100vh - 160px)' }}
            >
              {initialRender && remainingquestions.length > 0 && (
                <Box>
                  {remainingquestions.map((step, index) => (
                    <Box
                      key={index}
                      as="button"
                      w="100%"
                      p="16px"
                      mb="16px"
                      fontSize="14px"
                      fontWeight="600"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius="10px"
                      border="1px solid #7795DC"
                      bg="#EAEDF5"
                      color="#163E9E"
                      onClick={() => GetAIAnswer(step.value)}
                      className={`question-box ${animate ? 'slide-in' : ''}`}
                    >
                      {step.value ? step.value : "-"}
                      <Image src="/assets/imgs/right-icon.png" />
                    </Box>
                  ))}
                </Box>
              )}
              {aianswers.map((step, index) => (
                <React.Fragment key={index}>
                  <Box
                    as="div"
                    bg="#061027"
                    p="12px 16px"
                    w="fit-content"
                    float="right"
                    borderRadius="10px 10px 1px 10px"
                    className={`answer-box ${animate ? 'slide-in' : ''}`}
                  >
                    <Text fontSize="14px" fontWeight="500" color="white">
                      {step.question ? step.question : "-"}
                    </Text>
                  </Box>

                  <Box
                    as="div"
                    my="20px"
                    w="fit-content"
                    display="flex"
                    alignItems="end"
                    float="left"
                    className={`answer-box ${animate ? 'slide-in' : ''}`}
                  >
                    <Image
                      bg="#061027"
                      p="4px"
                      mr="8px"
                      borderRadius="6px"
                      src="/assets/imgs/ai-chat-logo.png"
                    />
                    <Text
                      bg="#E6E7E980"
                      p="12px 16px"
                      fontSize="14px"
                      fontWeight="500"
                      color="#061027"
                      borderRadius="10px 10px 10px 1px"
                    >
                      {step.answer ? step.answer : <TypingLoader />}
                    </Text>
                  </Box>
                </React.Fragment>
              ))}
            </Box>
          </Flex>
        </>
      }
      footerActions={
        !initialRender && (
          <Box
            p="24px 32px"
            display="flex"
            w="100%"
            h="100%"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box w="100%">
              {remainingquestions.map((step, index) => (
                <Box
                  key={index}
                  as="button"
                  w="100%"
                  p="16px"
                  mb="16px"
                  fontSize="14px"
                  fontWeight="600"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius="10px"
                  border="1px solid #7795DC"
                  bg="#EAEDF5"
                  color="#163E9E"
                  onClick={() => GetAIAnswer(step.value)}
                  className={`question-box ${animate ? 'slide-in' : ''}`}
                >
                  {step.value ? step.value : "-"}
                  <Image src="/assets/imgs/right-icon.png" />
                </Box>
              ))}
            </Box>
          </Box>
        )
      }
      />
      </Box>

      {/* Mobile responsive start */}
      <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/myhealth')}
          header="Allergies"
        />

        {data && data !== null && (
        <Box pos="fixed" bottom="10%" display="flex" justifyContent="center" w="100%" zIndex="99">
          <PrimaryButton
            fontSize="14px"
            variant="mdBtn"
            buttonText="Add Allergy"
            btnIcon="/assets/imgs/add.png"
            onClick={openAllergiesModal}
            />
        </Box>
        )}

        <Box w="100%" p="1.5rem" pb="5rem" h='calc(100vh - 190px)' overflow="auto">
          {data &&
              <SimpleGrid
                columns={{base: 1, sm: 2}}
                gap="10px"
              >
                {data &&
                  Array.isArray(data) &&
                  data.map(
                    (
                      {
                        patientallergyid,
                        allergytype,
                        allergyidentifieddate,
                        documentcount,
                        reaction,
                        documents,
                        allergytype_cd,
                      },
                      innerIndex
                    ) => (
                      <Box
                        key={innerIndex}
                        // flex="0 0 calc(33.333% - 20px)"
                        mb="20px"
                        border="1px solid #E6E7E9"
                        borderRadius="12px"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <Box
                          as="button"
                          p="20px"
                          w="100%"
                          justifyContent="space-between"
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box display="flex" alignItems="center">
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                              >
                                <Text
                                  fontSize="16px"
                                  textAlign="left"
                                  fontWeight="600"
                                >
                                  {allergytype}
                                </Text>
                                <Text
                                  fontSize="12px"
                                  fontWeight="500"
                                  textAlign="left"
                                  color="#384052"
                                >
                                  {formatDate(allergyidentifieddate)} <br />
                                </Text>
                              </Box>
                            </Box>
                            
                          <Box display="flex" gap="10px">
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              onClick={() =>
                                openEditModal(
                                  patientallergyid,
                                  allergytype,
                                  allergyidentifieddate,
                                  documentcount,
                                  reaction,
                                  documents,
                                  allergytype_cd
                                )
                              }
                            >
                              <Image w="13px" src="/assets/imgs/edit-gray.png" />
                            </Box>
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              onClick={() =>
                                openDeleteModal(patientallergyid, allergytype)
                              }
                            >
                              <Image w="13px" src="/assets/imgs/delete.png" />
                            </Box>
                            </Box>

                          </Box>
                          <Divider my="15px" />
                          <Text
                            fontSize="12px"
                            fontWeight="500"
                            textAlign="left"
                          >
                            {reaction.map((reactionItem, reactionIndex) => (
                              <React.Fragment key={reactionIndex}>
                              <span>{reactionItem.reaction}</span>
                              {reactionIndex !== reaction.length - 1 && <span>, </span>}
                            </React.Fragment>
                            ))}
                          </Text>

                          <Box
                            as="button"
                            mt="15px"
                            w="100%"
                            border="1px solid #E6E7E9"
                            borderRadius="10px"
                            p="12px 16px"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            onClick={() => openDocumentsModal(documents, allergytype, allergyidentifieddate)}

                          >
                            <Box mr="8px" fontSize="14px" fontWeight="600">
                              {documents ? documents.length : "0"} documents
                            </Box>
                            <Box>
                              <Image src="/assets/imgs/right-icon.png" />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )
                  )}
              </SimpleGrid>            
            }

            {/* No Data page */}
            {data == null && (
              <Box w="100%" mt="-100px" h="100vh" display="flex" flexDirection="column" gap="5px" justifyContent="center" alignItems="center">
                <Image w="60px" src="/assets/svgs/health-icon.svg" />
                <Box as="p">No data available</Box>
                <PrimaryButton fontSize="14px" variant="mdBtn" buttonText="Add allergy" btnIcon="/assets/imgs/add.png" onClick={openAllergiesModal} />
              </Box>
            )}
        </Box>
      </Box>
    </>
  );
};

export default AllergiesIndex;
