import { COLORS } from "../styles/colors";

export const styles = {
    borderRadius: "10px",
    fontSize: "14px",
    fontWeight: "600",
    background: COLORS.PRIMARY_GRADIENT,
    color: COLORS.PRIMARY_WHITE,
    boxShadow: "none",
    // padding: "25px",
    height: "48px",
    margin: "10px 0",
    cursor: "pointer",
    _disabled: {
      bg: COLORS.BTN_DISABLE,
      color: COLORS.BTN_DISABLE_TEXT,
      cursor: "not-allowed",
      _hover: {
        bg: COLORS.BTN_DISABLE
      }
    },
    _hover: {
      bg: COLORS.PRIMARY_COLOR
    },
  };

  export const submitStyle = {
    background: COLORS.BTN_DISABLE,
    color: COLORS.BTN_DISABLE_TEXT,
    padding: "25px",
    margin: "0",
    opacity: "50%",
    fontSize: "14px",
    fontWeight: "600",
  }
  
  export const variantStyles = {
   defaultBtn: {
    height: "48px",
    width: "100%"
   },
   mdBtn: {
    height: "40px",
    width: "146px"
   },
   deleteBtn: {
    bg: "radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, #AA2E26, #AA2E26);",
    _hover: {
      bg: "#AA2E26",
      transition: "background 0.2s ease-in-out",
    },
    _focus: {
      bg: "#AA2E26",
      transition: "background 0.2s ease-in-out",
    }
   },
   grayBtn: {
    bg: "#FCFCFD",
    height: "40px",
    width: "146px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#1C4EC5",
    boxShadow: "0px 0px 0px 3px #E6E7E94D, 0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset",
    _hover: {
      backgroundImage:"linear-gradient(to bottom, #FCFCFD, #E6E7E9)",
    }
   },
   
   grayBtnFull: {
    bg: "#FCFCFD",
    color: "#061027!important",
    height: "48px",
    fontSize: "14px",
    fontWeight: "600",
    width: "100%",
    boxShadow: "0px 0px 0px 3px #E6E7E94D, 0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset",
    _hover: {
      backgroundImage:"linear-gradient(to bottom, #FCFCFD, #E6E7E9)",
    }
   }
  }