import {
  Box,
  Button,
  Flex,
  Image,
  SimpleGrid,
  Spacer,
  Text,
  Toast,
} from "@chakra-ui/react";
import {
  PrimaryButton,
  PrimaryButtonIcon,
  PrimaryInput,
  Toaster,
} from "../../components";
import SecondaryNav from "../../components/seconday-nav";
import ProfileSideBar from "./profileSidebar";
import FilterButton from "../../components/filter-button";
import Navbar from "../../components/navbar";
import TabSection from "../../components/tabs";
import ReportViewCard from "../../components/report-card";
import {
  DeletePatientLabReport,
  FetchLabsDataForReportsView,
} from "../../middleware/services/myhealth/labs";
import React, { useEffect, useRef, useState } from "react";
import DynamicModal from "../../components/dynamic-modal";
import ModalRight from "../../components/modal-right";
import { FetchImagingData } from "../../middleware/services/myhealth/imaging";
import { useNavigate } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import moment from "moment";
import Footer from "../../components/footer";
import { downloadDocument, formatDate_Do_MMM_YYYY, useScreenType } from "../../auth";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";

function ReportsIndex() {
  const [type, setType] = useState("lab");

  const dynamicModal = React.useRef();

  const openCustomModal = React.useRef();
  const openCustomModal2 = React.useRef();

  const { isLoading, showLoader, dismissLoader } = useLoader();

  const { showSuccessToast, showErrorToast } = Toaster();
  var navigate = useNavigate();

  const [formattedDataForReportView, setFormattedDataForReportView] =
    useState(null);
  const [refresh, setRefresh] = useState(false);
  const [verifiedDateImaging, setVerifiedDateImaging] = useState("");

  const [header, setHeader] = useState("");
 const [normalResultText, setNormalResultText] = useState("");
 const [abnormalResultText, setAbnormalResultText] = useState("");
 const [interpretitionText, setInterpretitionText] = useState("");
 const [date, setDate] = useState("");
 const modalLearnMore = React.useRef();
 const screenType = useScreenType()
  const input = {
    type,
  };

  useEffect(() => {
    const fetchData = async () => {
        showLoader();
      try {
        // const labsData = await FetchLabsData(input);
        // setFormattedData(data);

        const labsDataForReportView = await FetchLabsDataForReportsView(input);
        
        const dataForReportsView = {
          rows: labsDataForReportView?.map((item) => {
            
            const uniqueURLsAndDatesMap = new Map();
            item.data.forEach((value) => {
              const { imagepath ,  documenturl, verifieddate } = value;
              if (documenturl) {
                uniqueURLsAndDatesMap.set(imagepath +documenturl, verifieddate);
              }
            });
        
            return {
              reportname: item.reportname,
              values: item.data.map(
                (value) =>
                  `${value.documenturl || ""} ${value.documentdate || ""}`
              ),
              reportid: item.data.map((value) => value.reportid),
             reportdescription:
                item.data.length > 0 ? item.data[0].reportdescription : "",
              documetURL: item.data.map(
                (value) => value.imagepath + value.documenturl
              ),
              verifieddate:
                item.data.length > 0 ? item.data[0].verifieddate : "-",
              // Extract unique URLs and their associated dates
              uniqueURLs: Array.from(uniqueURLsAndDatesMap, ([ url, date]) => ({
                 url,
                date,
              })),
            };
          }),
        };
        if(dataForReportsView  && dataForReportsView.rows !== undefined){

          setFormattedDataForReportView(dataForReportsView);
        }else{
           
          setFormattedDataForReportView(null);
        }

        // const dataForTable = {
        //   rows: labsData.map((item) => ({
        //     header: item.eventtitle,
        //     values: item.data.map((value) => ({
        //       resultvalue: value.resultvalue || "",
        //       documentdate: value.documentdate || "",
        //     })),
        //   })),
        // };
        // setFormattedDataForTableView(dataForTable)

        // setFetchedData(true);
      } catch (error) {
        console.log(error, " error in fetching labs data");
        //   setFetchError(true);
      }
       dismissLoader();
    };

    //if (patientid && !fetchedData) {
    fetchData();
    // }
  }, []);

  const [formattedData, setFormattedData] = useState("");

  const inputForImaging = {
    //patientid,
    type: "imaging",
  };
  useEffect(() => {
    const fetchDataImaging = async () => {
      // showLoader();
      try {
        const imagingData = await FetchImagingData(inputForImaging);
        // const uniqueURLs = [...new Set(imagingData.map(item => `${item.reportid}_${item.imagepath}${item.documenturl}`))];

        const dataForReportsView = {
          rows:
            imagingData &&
            imagingData.map((item) => {
              // Compute uniqueURLs based on reportid for each row
              const uniqueURLsForRow = [
                ...new Set([`${item.imagepath}${item.documenturl}`]),
              ];

              return {
                reportname: item.reportname ? item.reportname : "-",
                reportid: item.reportid,
                documetURL: item.imagepath + item.documenturl,
                reportdescription: item.reportdescription,
                verifieddate: item.verifieddate,
                uniqueURLs: uniqueURLsForRow,
                results_interpretation: item.results_interpretation,
                documentdate: item.documentdate,
              };
            }),
        };
        //  setFormattedDataForReportView(dataForReportsView)
        setFormattedData(dataForReportsView);
      } catch (error) {
        console.log(error, " error in fetching  imaging data");
        //  setFetchError(true);
      }
      //dismissLoader();
    };
    fetchDataImaging();
  }, [refresh]);

  const [reportid, setReportid] = useState([]);
  const openDeletePopup = (reportidArray, reportname, tab) => () => {
    const ids = Array.isArray(reportidArray) ? reportidArray : [reportidArray];

    setReportid(ids);

    // setReportid(reportidArray);
    setReportName(reportname);
    dynamicModal.current.openModal();
  };
  const deletePatientReports = async () => {
    showLoader();
    try {
      const reportIds = Array.isArray(reportid) ? reportid : [reportid];
      for (const id of reportIds) {
        const inputForDelete = {
          //  patientid,
          reportid: [{ id: id }],
        };

        var result = await DeletePatientLabReport(inputForDelete);
      }
    } catch (error) {
      console.error(`Error deleting report with reportid: ${reportid}`, error);
    }
    // showSuccessToast("Deleted Reports Successfully")
    dynamicModal.current.closeModal();
    openCustomModal.current.closeRight();
    openCustomModal2.current.closeRight();

    const labsDataForReportView = await FetchLabsDataForReportsView(input);
    const dataForReportsView = {
      rows: labsDataForReportView?.map((item) => {
        
        const uniqueURLsAndDatesMap = new Map();
        item.data.forEach((value) => {
          const { imagepath ,  documenturl, verifieddate } = value;
          if (documenturl) {
            uniqueURLsAndDatesMap.set(imagepath +documenturl, verifieddate);
          }
        });
    
        return {
          reportname: item.reportname,
          values: item.data.map(
            (value) =>
              `${value.documenturl || ""} ${value.documentdate || ""}`
          ),
          reportid: item.data.map((value) => value.reportid),
         reportdescription:
            item.data.length > 0 ? item.data[0].reportdescription : "",
          documetURL: item.data.map(
            (value) => value.imagepath + value.documenturl
          ),
          verifieddate:
            item.data.length > 0 ? item.data[0].verifieddate : "-",
          // Extract unique URLs and their associated dates
          uniqueURLs: Array.from(uniqueURLsAndDatesMap, ([ url, date]) => ({
             url,
            date,
          })),
        };
      }),
    };

    if (dataForReportsView && dataForReportsView.rows !== undefined) {
      setFormattedDataForReportView(dataForReportsView);
    } else {
      setFormattedDataForReportView("");
    }
    // dismissLoader();
    setRefresh(!refresh);
    dismissLoader();
  };

  const [headerName, setHeaderName] = useState("");
  const [reportName, setReportName] = useState("");
  const [reportDescription, setReportDescription] = useState("");

  const [documentDates, setDocumentDates] = useState([]);
  const [documentURLs, setDocumentURLs] = useState([]);
  const [verifiedDate, setVerifiedDate] = useState("");

  const openModalRightWithDataLabs =
    (header, values, documentURL, reportname, verifieddate, reportidArray, rowUniqueUrlsWithDate, reportdescription) =>
    () => {
      setReportid(reportidArray);

      // const uniqueDocumentURLs = [...new Set(documentURL)];
      // setDocumentURLs(uniqueDocumentURLs);
      
    console.log(rowUniqueUrlsWithDate , " rowUniqueUrlsWithDate")
    setDocumentURLs(rowUniqueUrlsWithDate)

      setHeaderName(header);
      setReportDescription(reportdescription)
      setDocumentDates(values);
      //  setDocumentURLs(documentURL);

      setReportName(reportname);
      setVerifiedDate(verifieddate);
      openCustomModal.current.openRight();
    };

  const [documentUrlImaging, setDocumentUrlImaging] = useState("");
  const openModalRightImaging =
    (row) => () => {
      //setHeaderName(header);
      setReportid(row.reportid);
      setVerifiedDateImaging(row.verifieddate)
      // setDocumentDates(values);
      setDocumentUrlImaging(row.documetURL);

      setReportName(row.reportname);
      setReportDescription(row.reportdescription)
      openCustomModal2.current.openRight();
    };

  const [activeTab, setActiveTab] = useState("LabsTab");

  const handleTabClick = (index) => {
    // showLoader();
    switch (index) {
      case 0:
        setActiveTab("LabsTab");
        break;
      case 1:
        setActiveTab("ImagingTab");
        break;
      default:
        break;
    }

    //  dismissLoader();
  };

  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  const handleImagingUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    showLoader();
    navigate("/imagingupload", { state: { file } });
    dismissLoader();
  };
  const handleLabsUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    showLoader();
    navigate("/uploaddocument", { state: { file } });
    dismissLoader();
  };
  const openUploadImaging = () => {
    fileInputRef.current.click();
  };
  const openUploadLabs = () => {
    fileInputRef2.current.click();
  };
  const formatDate = (dateString) => {
    return moment(dateString).format("Do MMM, YYYY");
  };

  const openLearnMoreModal = (modalData)=>{
    if(modalData){
      setHeader(modalData.reportname)
      setDate(formatDate_Do_MMM_YYYY(modalData.documentdate))
      setNormalResultText(modalData.results_interpretation.normal_results)
      setAbnormalResultText(modalData.results_interpretation.abnormal_results)
      setInterpretitionText(modalData.results_interpretation.results_interpretation)
    }
    modalLearnMore.current.openRight()
  }

  return (
    <>
    {screenType == 'web' ? (
      <Box w="100%">
        <Box top="0" zIndex="banner">
          <Navbar />

          {isLoading && <Loader />}
          <SecondaryNav
            sideBarWidth="250px"
            defaultHeader="Profile"
            defaultSecondayHeader="Reports"
            defaultNavBody={
              <>
                <Box display="flex" gap="10px" alignItems="center" pr="25px">
                  <TabSection
                    tabs={["Labs", "Imaging"]}
                    onClickTab={handleTabClick}
                  />
                  {activeTab == "ImagingTab" ? (
                    <PrimaryButton
                      fontSize="14px"
                      variant="mdBtn"
                      buttonText="Upload report"
                      btnIcon="/assets/imgs/add.png"
                      onClick={openUploadImaging}
                    />
                  ) : (
                    <PrimaryButton
                      fontSize="14px"
                      variant="mdBtn"
                      buttonText="Upload report"
                      btnIcon="/assets/imgs/add.png"
                      onClick={openUploadLabs}
                    />
                  )}

                  <input
                    type="file"
                    id="file-upload"
                    onChange={handleImagingUpload}
                    ref={fileInputRef} // Set the ref for the file input
                    style={{ display: "none" }} // Hide the file input
                  />
                  <input
                    type="file"
                    id="Labupload"
                    style={{ display: "none" }}
                    ref={fileInputRef2}
                    onChange={handleLabsUpload}
                  />
                </Box>
              </>
            }
          />
        </Box>
        <Box h="calc(100vh - 140px)" overflow="hidden auto">
          <Flex>
            <Box p="0" width="250px">
              <ProfileSideBar />
            </Box>
            <Flex bg="#fcfcfd" flexDirection="column" w="100%">
              {/* <Box p="20px" pb="0" display="flex" justifyContent="start">
              <FilterButton
                tabs={["All", "Chest X-Ray", "CT Scan", "MRI"]}
                onClick={(label) => console.log(label + " clicked")}
              />
            </Box> */}

              {formattedDataForReportView && activeTab === "LabsTab" ? (
                <Flex
                  wrap="wrap"
                  p="20px"
                  w="100%"
                  gap="20px"
                  justifyContent="flex-start"
                >
                  {formattedDataForReportView &&
                    formattedDataForReportView.rows.map((row, index) => (
                      <Box
                        key={index}
                        flex="0 0 calc(33.333% - 20px)"
                        mb="20px"
                      >
                        <ReportViewCard
                          deleteBtn={openDeletePopup(
                            row.reportid,
                            row.reportname,
                            "labs"
                          )}
                          cardHeader={row.reportname ? row.reportname : "-"}
                          cardHeight="205px"
                          maxCardHeight="235px"
                          cardSecondary={formatDate(row.verifieddate)}
                          cardBtn={row.uniqueURLs.length + " documents"}
                          cardText={row.reportdescription}
                          cardTextStyle={{
                            display: "-webkit-box",
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            wordBreak: "break-word",
                            lineHeight: "1.2",
                            height: "3.6em",
                          }}
                          onClick={openModalRightWithDataLabs(
                            row.header,
                            row.values,
                            row.documetURL,
                            row.reportname,
                            formatDate(row.verifieddate),
                            row.reportid,
                            row.uniqueURLs,
                            row.reportdescription
                          )}
                        />
                      </Box>
                    ))}
                </Flex>
              ) : activeTab === "ImagingTab" &&
                formattedData &&
                formattedData.rows !== undefined ? (
                <Flex
                  wrap="wrap"
                  p="20px"
                  w="100%"
                  gap="20px"
                  justifyContent="flex-start"
                >
                  {formattedData.rows &&
                    formattedData.rows.map((row, index) => (
                      <Box
                        key={index}
                        flex="0 0 calc(33.333% - 20px)"
                        mb="20px"
                      >
                        <ReportViewCard
                          deleteBtn={openDeletePopup(
                            row.reportid,
                            row.reportname,
                            "imaging"
                          )}
                          cardHeader={row.reportname ? row.reportname : "-"}
                          
                          headerClick={()=>openLearnMoreModal(row)}
                          cardSecondary={formatDate(row.verifieddate)}
                          cardBtn={row.uniqueURLs.length + " documents"}
                          cardText={row.reportdescription}
                          cardTextStyle={{
                            display: "-webkit-box",
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            wordBreak: "break-word",
                            lineHeight: "1.2",
                            height: "3.6em",
                          }}
                          onClick={openModalRightImaging(
                            row
                          )}
                        />
                      </Box>
                    ))}
                </Flex>
              ) : (
                <Box
                  w="100%"
                  h="100vh"
                  display="flex"
                  flexDirection="column"
                  gap="5px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image
                    w="60px"
                    src={
                      activeTab === "LabsTab"
                        ? "/assets/svgs/biotech.svg"
                        : "/assets/svgs/hand_bones.svg"
                    }
                  />
                  <Box as="p">
                    {activeTab === "LabsTab"
                      ? "No data available"
                      : "No data available"}
                  </Box>

                  <PrimaryButton
                    fontSize="14px"
                    variant="mdBtn"
                    buttonText="Upload report"
                    btnIcon="/assets/imgs/add.png"
                    onClick={
                      activeTab === "LabsTab"
                        ? openUploadLabs
                        : openUploadImaging
                    }
                  />
                </Box>
              )}
            </Flex>
          </Flex>
          <Footer />
        </Box>
      </Box>
    ) : (
      //Mobile responsive start
      <Box w="100%" bg="#fcfcfd">
      {isLoading && <Loader />}
      <input
                    type="file"
                    id="file-upload"
                    onChange={handleImagingUpload}
                    ref={fileInputRef} // Set the ref for the file input
                    style={{ display: "none" }} // Hide the file input
                  />
                  <input
                    type="file"
                    id="Labupload"
                    style={{ display: "none" }}
                    ref={fileInputRef2}
                    onChange={handleLabsUpload}
                  />
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/profile')}
          header="Reports"
          bottomArea={<>
            <Box w="100%">
            <TabSection
              tabs={["Labs", "Imaging"]}
              onClickTab={handleTabClick}
            />
            </Box>
          </>}
        />
        {formattedDataForReportView && formattedData !== null && (
            <Box pos="fixed" bottom="10%" display="flex" justifyContent="center" w="100%" zIndex="99">
            {activeTab == "ImagingTab" ? (
              <PrimaryButton
                fontSize="14px"
                variant="mdBtn"
                buttonText="Upload report"
                btnIcon="/assets/imgs/add.png"
                onClick={openUploadImaging}
              />
            ) : (
              <PrimaryButton
                fontSize="14px"
                variant="mdBtn"
                buttonText="Upload report"
                btnIcon="/assets/imgs/add.png"
                onClick={openUploadLabs}
              />
            )}
          </Box>
        )}
        

        <Flex bg="#fcfcfd" flexDir="column" pb="2.5rem" h="calc(100vh - 250px)" overflow="hidden auto">
          {formattedDataForReportView && activeTab === "LabsTab" ? (
            <SimpleGrid columns={1} gap="1rem" p="1.25rem">
              {formattedDataForReportView &&
                formattedDataForReportView.rows.map((row, index) => (
                    <ReportViewCard
                      deleteBtn={openDeletePopup(
                        row.reportid,
                        row.reportname,
                        "labs"
                      )}
                      cardHeader={row.reportname ? row.reportname : "-"}
                      cardHeight="205px"
                      maxCardHeight="235px"
                      cardSecondary={formatDate(row.verifieddate)}
                      cardBtn={row.uniqueURLs.length + " documents"}
                      cardText={row.reportdescription}
                      cardTextStyle={{
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        wordBreak: "break-word",
                        lineHeight: "1.2",
                        height: "3.6em",
                      }}
                      onClick={openModalRightWithDataLabs(
                        row.header,
                        row.values,
                        row.documetURL,
                        row.reportname,
                        formatDate(row.verifieddate),
                        row.reportid,
                        row.uniqueURLs,
                        row.reportdescription
                      )}
                    />                  
                ))}
            </SimpleGrid>
          ) : activeTab === "ImagingTab" &&
            formattedData &&
            formattedData.rows !== undefined ? (
            <SimpleGrid columns={1} gap="1rem" p="1.25rem">
              {formattedData.rows &&
                formattedData.rows.map((row, index) => (
                    <ReportViewCard
                      deleteBtn={openDeletePopup(
                        row.reportid,
                        row.reportname,
                        "imaging"
                      )}
                      cardHeader={row.reportname ? row.reportname : "-"}
                      
                      headerClick={()=>openLearnMoreModal(row)}
                      cardSecondary={formatDate(row.verifieddate)}
                      cardBtn={row.uniqueURLs.length + " documents"}
                      cardText={row.reportdescription}
                      cardTextStyle={{
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        wordBreak: "break-word",
                        lineHeight: "1.2",
                        height: "3.6em",
                      }}
                      onClick={openModalRightImaging(
                        row
                      )}
                    />
                ))}
            </SimpleGrid>
          ) : (
            <Box
              w="100%"
              h="100vh"
              display="flex"
              flexDirection="column"
              gap="5px"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                w="60px"
                src={
                  activeTab === "LabsTab"
                    ? "/assets/svgs/biotech.svg"
                    : "/assets/svgs/hand_bones.svg"
                }
              />
              <Box as="p">
                {activeTab === "LabsTab"
                  ? "No data available"
                  : "No data available"}
              </Box>

              <PrimaryButton
                fontSize="14px"
                variant="mdBtn"
                buttonText="Upload report"
                btnIcon="/assets/imgs/add.png"
                onClick={
                  activeTab === "LabsTab"
                    ? openUploadLabs
                    : openUploadImaging
                }
              />
            </Box>
          )}
        </Flex>
      </Box>
    )}
      <DynamicModal
        ref={dynamicModal}
        modalHeader="Delete report?"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete  <strong>"{reportName}"</strong>
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deletePatientReports}
            />
          </>
        }
      />
      <ModalRight
        ref={openCustomModal}
        secondaryHeader={reportName}
        subHeader={verifiedDate}
        body={
          <Flex pt="15px" flexDirection="column" h="100%">
            <Box as="p" pb="20px" fontSize="14px" fontWeight="500">
                      {reportDescription}  </Box>
            {documentURLs.map((value, index) => (
              <Box key={index} mb="2">
                <Button
                  as="a"
                  w="100%"
                  h="48px"
                  borderRadius="10px"
                  border="1px solid #E6E7E9"
                  display="flex"
                  justifyContent="space-between"
                  bg="white"
                  color="#1C4EC5"
                  sx={{ _hover: { bg: "none" } }}
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    fontSize: "12px",
                  }}
                  onClick={()=>downloadDocument(value.url.includes("/null") ? undefined : value.url)}
                  cursor="pointer"
                >
                  <Box
                    as="span"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    w="80%"
                  >
                    {reportName} <br/>
                    <p style={{color:"black", fontSize:"11px"}}>{formatDate(value.date)}</p>
                  </Box>
                  {!value.url.includes("/null") && (
                    <Box as="span">
                      <Image src="/assets/imgs/download.png" />
                    </Box>
                  )}
                </Button>
              </Box>
            ))}
            <Spacer />
            <Box>
              <PrimaryButtonIcon
                buttonText="Delete"
                btnStyle={{color:"#AA2E26"}}
                onClick={deletePatientReports}
                color="#AA2E26"
              />
            </Box>
          </Flex>
        }
      />

      <ModalRight
        ref={openCustomModal2}
        secondaryHeader={reportName}
        subHeader={formatDate(verifiedDateImaging)}
        body={
          <Flex pt="15px" flexDirection="column" h="100%">
             <Box as="p" pb="20px" fontSize="14px" fontWeight="500">
                      {reportDescription}  </Box>
            {/* ********* */}
            <Box mb="2">
              <Button
                as="a"
                w="100%"
                h="48px"
                borderRadius="10px"
                border="1px solid #E6E7E9"
                display="flex"
                justifyContent="space-between"
                bg="white"
                color="#1C4EC5"
                sx={{ _hover: { bg: "none" } }}
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  fontSize: "12px",
                }}
                onClick={()=>downloadDocument(documentUrlImaging)}
                cursor="pointer"
              >
                <Box
                  as="span"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  w="80%"
                >
                  {reportName} <br/>
                  <p style={{color:"black", fontSize:"11px"}}> {formatDate(verifiedDateImaging)}</p>
                </Box>
                {/* { !value.includes("/null") && ( */}
                <Box as="span">
                  <Image src="/assets/imgs/download.png" />
                </Box>
                {/* )} */}
              </Button>
            </Box>

            <Spacer />
            <Box>
              <PrimaryButtonIcon
                buttonText="Delete"
                btnStyle={{color:"#AA2E26"}}
                onClick={deletePatientReports}
                color="#AA2E26"
              />
            </Box>
          </Flex>
        }
      />


      <ModalRight
        ref={modalLearnMore}
        isBg={true}
        modalWidth="480px"
        customHeader={false}
        bodyStyle={{ p: "0", overflow: "hidden" }}
        footerStyle={{ borderTop: "1px solid #E6E7E9" }}
        body={
          <>
            <Box bg="#061027" p="35px">
              <Box display="flex" alignItems="center" gap="10px">
                <Box display="flex" flexDir="row" justifyContent="space-between" gap="5px" alignItems="center">
                  <Box fontSize="20px" fontWeight="600" color="#E6E7E9">
                      {header ? header : "-"}
                    <Box as="p" pt="15px" fontSize="14px" fontWeight="400" lineHeight="normal" color="white">
                      {date ? date : "-"}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box bg="#fbfbfc" overflow="hidden auto" h="calc(100% - 220px)">
              <Box overflowY="auto" p="24px 32px">
                <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">Normal results</Box>
                <Box as="h5" fontSize="16px" pb="20px" fontWeight="300">
                  {normalResultText ? normalResultText : "-"}
                </Box>

                <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">Abnormal results</Box>
                <Box as="h5" fontSize="16px" pb="20px" fontWeight="300">
                  {abnormalResultText ? abnormalResultText : "-"}
                </Box>
                <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">Report Interpretition</Box>
                <Box as="h5" fontSize="16px" pb="20px" fontWeight="300">
                  {interpretitionText ? interpretitionText : "-"}
                </Box>
              </Box>
            </Box>
          </>
        }
      />
    </>
  );
}

export default ReportsIndex;
