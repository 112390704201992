import { FormControl, FormLabel, Input, InputGroup, InputRightElement, Box, Image } from "@chakra-ui/react";
import { styles, variantStyles } from './styles';
import { inputDesign } from "../styles/theme";
import React, { useEffect, useRef, useState } from "react";
import { COLORS } from "../styles/colors";

const PrimaryInput = ({
  label,
  inputType,
  inputPlace,
  inputStyleCss,
  inputValue,
  onChange,
  onClick,
  onKeyDown,
  onKeyPress,
  onKeyUp,
  variant,
  defaultValue,
  inputCss,
  inputLabelCss,
  handleFileSelection, 
  isDisabled,
  uploadText,
  iconImg,
  docBtnWidth = "100%",
  docBtnColor = "black",
  id,
  inputBox,
  keyProp,
  iconImgCss,
  boolProp = false,
  inputIdProp,
  inputCssStyle,
  ...props
}) => {
  const variantStyle = variantStyles[variant] || variantStyles.defaultSide;
  const isFileInput = inputType === 'file';
  const [initialRender , setInitialRender] = useState(true)
  
useEffect(()=>{
  if(!initialRender){
  let element = document.getElementById(inputIdProp)
  if (element) {
    // will receive the id as Active or null
    //if the inputIdProp have id as Active then it will focus, if id is null it will not focus, 
    element.focus();
    setTimeout(() => {
      
    element.focus();
    }, 10);
  }
}else {
  setInitialRender(false);
}
},[boolProp])
  return (
    <FormControl sx={inputDesign.formStyles.container} {...inputStyleCss}>
      {isFileInput ? (
        <InputGroup boxShadow="0px 1px 3px #E6E7E9" borderRadius="10px" width={docBtnWidth} cursor="pointer">
          <InputRightElement w="100%" h="100%">
            <Image src={iconImg} pos="relative" left="10px" pr="10px" {...iconImgCss}/>
            <Box as="button" fontWeight="600" h='100%' color={docBtnColor} width='100%' bg="none" onClick={() => document.getElementById(id).click()} sx={{_hover: {bg:"none"}}} isDisabled={isDisabled}>
              {uploadText}
            </Box>
            <input
              type='file'
              id={id}
              style={{ display: 'none' }}
              onChange={handleFileSelection}
            />
          </InputRightElement>
          <Input
            type="text"
            background="#FCFCFD"
            boxShadow="0px 1px 3px #E6E7E9"
            sx={{_hover: {
              backgroundImage: "-webkit-linear-gradient(top, #FCFCFD, #E6E7E9)",
              backgroundImage: "-moz-linear-gradient(top, #FCFCFD, #E6E7E9)",
              backgroundImage: "-ms-linear-gradient(top, #FCFCFD, #E6E7E9)",
              backgroundImage: "-o-linear-gradient(top, #FCFCFD, #E6E7E9)",
              backgroundImage: "linear-gradient(to bottom, #FCFCFD, #E6E7E9)",
            },
            _disabled: {
              bg: COLORS.BTN_DISABLE,
              color: COLORS.BTN_DISABLE_TEXT,
              cursor: "not-allowed",
            },...inputBox}}
            border="none"
            height="48px"
            borderRadius="10px"
            value={inputValue}
            placeholder={inputPlace}
            readOnly // Make the text field read-only for file type
          />
        </InputGroup>
      ) : (
        <Input
        id={inputIdProp ? inputIdProp : null}
        key={keyProp}
          type={inputType}
          sx={{ ...styles, ...variantStyle, ...inputDesign.formStyles.input, ...inputCssStyle }}
          value={inputValue}
          defaultValue={defaultValue}
          onChange={onChange}
          onClick={onClick}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          placeholder=" "
          errorBorderColor="red.300"
          isDisabled={isDisabled}
          {...inputCss}
        />
      )}<FormLabel sx={inputDesign.formStyles.label} {...inputLabelCss}>{label || inputPlace}</FormLabel>
    </FormControl>
  );
};

export default PrimaryInput;
