import moment from "moment";
import formAPIModelconditiondata from "./conditions";

export default function formAPIConditions(dataArray) {
  // Assuming dataArray is an array of objects
  return dataArray.map(({ 
     conditiontype,
     conditiontype_cd, 
     contiondata 


    }) => {
    return Object.freeze({
      conditiontype,
      conditiontype_cd,
      contiondata : contiondata !== null ? formAPIModelconditiondata(contiondata):[],

    });
  });
}
