import formAPIcountry from "./country";


var formattedOutput = null;

const formAPIModelcountry = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIcountry(item));


  return formattedOutput;
};

export default formAPIModelcountry