import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import ProfileSideBar from "../../pages/profile/profileSidebar";
import SecondaryNav from "../../components/seconday-nav";
import { Box,Text, Flex, Image, SimpleGrid } from "@chakra-ui/react";
import Footer from "../../components/footer"
import Loader from "../../components/loader/loader";
import useLoader from "../../components/loader/loaderstates";
import DoctorCard from "../../components/doctor-card";
import { FetchDoctorsByAppointment } from "../../middleware/services/profile/doctors";
import { PrimaryButton, Toaster } from "../../components";
import { formatDate_Do_MMM_YYYY, navigate } from "../../auth";
import { useNavigate } from "react-router-dom";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";

const DoctorsIndex = () => {
  const { isLoading, showLoader, dismissLoader } = useLoader();  
  const { showSuccessToast, showErrorToast } = Toaster();
  const [data, setData] = useState([]);
  
  const [showNoData, setShowNoData] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    showLoader();
    var input = {};
    var fetchData = async () => {
      try {
        var result = await FetchDoctorsByAppointment(input)
        if(result){
          setData(result)
          setShowNoData(false)
        }else if (!result){
          setData([])
          setShowNoData(true)
        }else if (result.message == "No doctors are available"){
          setData([])
          setShowNoData(true)
        }else{
          setData([])
          
          setShowNoData(true)
          showErrorToast("Something went wrong!")
        }

      } catch (error) {
        console.log(error, "error in fetching account data")
      }
      dismissLoader();
    }

    fetchData();
    
  }, []);

  useEffect(()=>{
  if(data.length <= 0 || data && data.output &&  data.output.result && data.output.result == "failure"){
     
    setShowNoData(true)
  }else {
    
    setShowNoData(false)
  }
   
  },[data])

  return (
   <>
    <Box w="100%" display={{base: "none", lg: "block"}}>
        <Box top="0" zIndex="banner">
          <Navbar />

          {isLoading && <Loader />}
          <SecondaryNav
            sideBarWidth="250px"
            defaultHeader="Profile"
            defaultSecondayHeader="Doctors"
          />
        </Box>
        <Box h="calc(100vh - 140px)" overflow="hidden auto">
        <Flex>
          <Box p="0" width="250px">
            <ProfileSideBar />
          </Box>

          <Flex bg="#fcfcfd" flexDirection="column" w="100%">
              <Box w="100%" p="1.5rem 2rem">
                <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} spacing="1rem">
                  {data && data.length > 0 && data.map((item, index)=>
                   <DoctorCard 
                   onClick={()=> navigate("/appointmentdetails", { state: { appointmentid: item.appointmentid }})}
                   cardFooterText="View appointment"
                   cardBody={<>
                     <Box display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                       <Box display='flex' gap="0.75rem">
                         <Box boxSize="2.375rem" borderRadius='full' overflow="hidden">
                           <Image src={item.profilepictureurl ? item.imagepath + item.profilepictureurl :"/assets/imgs/no-image.png"}
                           />
                         </Box>
                         <Box>
                           <Text className="textOverflow" maxW="170px" fontSize="0.625rem" fontWeight="500" color="#384052" title={item.specality}>{item.specality}</Text>
                           <Text fontSize="0.875rem" fontWeight="600" color="#061027" className="textOverflow" title={item.fullname}>{item.fullname}</Text>
                         </Box>
                       </Box>
                       <Text fontSize="0.75rem" fontWeight="500" color="#384052">{formatDate_Do_MMM_YYYY(item.appointmentdate)}</Text>
                     </Box>
                   </>} 
                 />
                  )}
                 
                
                </SimpleGrid>
              </Box>
              {showNoData && (
                <>
                <Box
                w="100%"
                h="100vh"
                display="flex"
                flexDirection="column"
                gap="5px"
                justifyContent="center"
                alignItems="center"
              >
                <Image w="60px" opacity="0.5" src="/assets/svgs/stethoscope.svg" /> 
                <Box as="p">No data available</Box>
               
              </Box>
                </>
              )}
              {/* */}
          </Flex>
        </Flex>
        <Footer />
        </Box>
    </Box>

     {/* Mobile responsive start */}
     <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/profile')}
          header="Doctors"
        />

        <Flex bg="#fcfcfd" flexDir="column" h="calc(100vh - 200px)" overflow="hidden auto">
          <Box w="100%" p="1.5rem">
            <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} spacing="1rem">
              {data && data.length > 0 && data.map((item, index)=>
                <DoctorCard 
                onClick={()=> navigate("/appointmentdetails", { state: { appointmentid: item.appointmentid }})}
                cardFooterText="View appointment"
                cardBody={<>
                  <Box display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                    <Box display='flex' gap="0.75rem">
                      <Box boxSize="2.375rem" borderRadius='full' overflow="hidden">
                        <Image src={item.profilepictureurl ? item.imagepath + item.profilepictureurl :"/assets/imgs/no-image.png"}
                        />
                      </Box>
                      <Box>
                        <Text className="textOverflow" maxW="170px" fontSize="0.625rem" fontWeight="500" color="#384052" title={item.specality}>{item.specality}</Text>
                        <Text fontSize="0.875rem" fontWeight="600" color="#061027" className="textOverflow" title={item.fullname}>{item.fullname}</Text>
                      </Box>
                    </Box>
                    <Text fontSize="0.75rem" fontWeight="500" color="#384052">{formatDate_Do_MMM_YYYY(item.appointmentdate)}</Text>
                  </Box>
                </>} 
              />
              )}
              
            
            </SimpleGrid>
          </Box>
          {showNoData && (
            <>
            <Box
            w="100%"
            h="100vh"
            display="flex"
            flexDirection="column"
            gap="5px"
            justifyContent="center"
            alignItems="center"
          >
            <Image w="60px" opacity="0.5" src="/assets/svgs/stethoscope.svg" /> 
            <Box as="p">No data available</Box>
            
          </Box>
            </>
          )}
        </Flex>
      </Box>
   </>
  );
};

export default DoctorsIndex;
