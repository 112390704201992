import formAPIMedicalCondition from "../medicalconditions/medicalcondition";
import formAPIFamilyAllergy from "../profilefamily/allergy";
import formAPIModelFamilyAllergy from "./allergy";


var formattedOutput = null;

const formAPIModelAllergiesForFamilyHistory = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data?.map(item => formAPIModelFamilyAllergy(item));


  return formattedOutput
};

export default formAPIModelAllergiesForFamilyHistory