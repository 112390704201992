import { COLORS } from "../colors";
import { extendTheme } from "@chakra-ui/react";


export const theme = extendTheme({
  components: {
    Checkbox: {
      baseStyle: {
        control: {
          width: "23px",
          height: "23px",
          borderRadius: "8px",
        },
      },
    },
  },
});

export const activeLabelStyles = {
  transform: "scale(0.85) translateY(-10px)",
  fontSize: "sm",
  color: COLORS.INPUT_FOCUS_DARK,
};

export const inputDesign = {
formStyles: {
  container: {
    position: "relative",
    paddingTop: "0",
  },
  label: {
    position: "absolute",
    top: "6px",
    left: 0,
    zIndex: 99,
    mx: 2.5,
    px: 0,
    my: 2,
    opacity: "50%",
    transformOrigin: "left top",
    'input:focus + &': {
      ...activeLabelStyles,
    },
    'input:not(:placeholder-shown) + &': {
      ...activeLabelStyles,
    },
    pointerEvents: "none", 
  },
  input: {
    _focus: {
      "+ label": {
        ...activeLabelStyles,
      },
    },
    ":not(:placeholder-shown) + label": {
      ...activeLabelStyles,
    },
  },
}
}

export const inputStyles = {
  borderRadius: "10px",
  background: COLORS.PRIMARY_WHITE,
  boxShadow: "none",
  padding: "12px 10px 0 10px",
  width: "100%",
  height: "48px",
  cursor: "pointer",
  _disabled: {
    bg: COLORS.BTN_DISABLE,
    color: COLORS.BTN_DISABLE_TEXT,
    cursor: "not-allowed",
  },
  _hover: {
    borderRadius: "10px",
    border: `2px solid`,
    borderColor: COLORS.INPUT_FOCUS_DARK,
    // zIndex: "22",
    outline: "none",
    background: "white"
  },
  _focus: {
    borderRadius: "10px",
    border: "2px solid var(--input-focus-dark)",
    borderColor: COLORS.INPUT_FOCUS_DARK,
    // zIndex: "22",
    outline: "none",
    background: "white"
  },
  _focusVisible: {
    borderColor: "none",
    bg: "white"
  }
};

export const variantStyles = {
  defaultSide: {
    borderRadius: "10px 10px 0 0", 
  },
  rightSide: {
    borderRadius: "0 10px 0 0", 
  },
  leftSide: {
    borderRadius: "10px 0 0 0px",
  },
  bothSide: {
    borderRadius: "0",
  },
  downSide: {
    borderRadius: "0 0 10px 10px"
  },
  fullLeft: {
    borderRadius: "10px 0 0 10px"
  },
  fullRight: {
    borderRadius: "0 10px 10px 0"
  },
  fullRound: {
    borderRadius: "10px"
  },

};

