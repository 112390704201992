import React, { useState, useRef, useEffect } from 'react';
import { Box, Input, FormControl, Image, InputRightElement, FormLabel, InputGroup, useOutsideClick, Text } from '@chakra-ui/react';
import 'react-day-picker/dist/style.css';
import { DayPicker } from 'react-day-picker';
import format from 'date-fns/format';
import { isValid, isAfter, isBefore } from 'date-fns';
import moment from 'moment';


const COLORS = {
  INPUT_FOCUS_DARK: "#384052",
  PRIMARY_WHITE: "#ffffff",
  BTN_DISABLE: "#f0f0f0",
  BTN_DISABLE_TEXT: "#a0a0a0",
};

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
  fontSize: "sm",
  color: COLORS.INPUT_FOCUS_DARK,
  opacity: "50%",
};

const inputStyles = {
  borderRadius: "0.75rem",
  background: COLORS.PRIMARY_WHITE,
  boxShadow: "none",
  width: "100%",
  fontSize: "14px",
  fontWeight: "500",
  color: "#020610",
  height: "48px",
  _disabled: {
    bg: COLORS.BTN_DISABLE,
    color: COLORS.BTN_DISABLE_TEXT,
    cursor: "not-allowed",
  },
  _hover: {
    borderRadius: "10px",
    border: `2px solid`,
    borderColor: COLORS.INPUT_FOCUS_DARK,
    outline: "none",
    bg: "none",
  },
  _focus: {
    borderRadius: "10px",
    border: "2px solid",
    borderColor: COLORS.INPUT_FOCUS_DARK,
    outline: "none",
  },
};

function DatePickerAppointment({
  id,
  onDateChange,
  placeholderProp,
  defaultDate,
  dateWidth ="100%",
  onChange,
  leftPos,
  calendarStlye,
  datePickerStyle,
  disableFutureDates,
  minDate,
  maxDate,
  inputStyle,
  customText = false,
  customFormat = false,
}) {
  const [selected, setSelected] = useState(defaultDate ? moment(defaultDate, 'ddd, D MMM').toDate() : null);
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const outSideClick = useRef(null); 


  const handleInputClick = () => {
    setDatePickerVisible(prev => !prev);
  };

  const handleDateSelect = (date) => {
    if(date){
      setSelected(date);
      if (onDateChange) {
        onDateChange(date);
      } 
    }
    setDatePickerVisible(false);
    
  };

  const isLabelActive = selected != null;

  useOutsideClick({
    ref: outSideClick,
    handler: () => isDatePickerVisible && setDatePickerVisible(false)
  });

  const css = `
  .rdp-day_selected {
    background-color: #1F50C6
  }`

  // const disableDates = date => {
  //   // Disable future dates
  //   return isAfter(date, new Date());
  // };
  const disableDates = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date for comparison
  
    // Disable all dates before today
    if (isBefore(date, today)) {
      return true;
    }
  
    // If needed, disable future dates
    if (disableFutureDates && isAfter(date, today)) {
      return true;
    }
  
    // If minDate is defined, disable dates before the minimum date
    if (minDate && isBefore(date, new Date(minDate))) {
      return true;
    }
  
    // If maxDate is defined, disable dates after the maximum date
    if (maxDate && isAfter(date, new Date(maxDate))) {
      return true;
    }
  
    return false; // Enable all other dates
  };
  
  return (
    <>
    <style>{css}</style>
    <FormControl position="relative" width={dateWidth} ref={outSideClick} {...datePickerStyle}>
      <InputGroup borderRadius="0">
        <Input
          sx={inputStyles}
          type="text"
          id={id}
          defaultValue={defaultDate}
          value={selected ? moment(selected).format('ddd, D MMM'): ''}
          onClick={handleInputClick}
          onChange={onChange}
          readOnly
          placeholder={!isLabelActive ? placeholderProp : ''}
          p={isLabelActive ?  "12px 10px 0 10px" :  "0px 10px 0 10px"}
          textOverflow="ellipsis"
          paddingRight="20px"
          {...inputStyle}
        />
        <InputRightElement
          h="100%"
          children={
            customText ? (
              <Text fontSize="0.80rem" fontWeight="600" cursor="pointer" pos="relative" right="15px" color="#1C4EC5" onClick={handleInputClick}>{customText}</Text>
            ) : (
              <Image
                mr="8px"
                cursor="pointer"
                onClick={handleInputClick}
                w="15px"
                src="/assets/imgs/double-arrow.png"
                alt=""
              />
            )
          }
        />
      </InputGroup>
      <FormLabel
        sx={{
          ...activeLabelStyles,
          position: "absolute",
          top: "25px",
          pointerEvents: "none",
          opacity: isLabelActive ? 0.5 : 0,
          zIndex: isLabelActive ? 1 : -1,
          transition: "all 0.2s ease-out",
          left: "6px",
          ...leftPos
        }}
      >
        {placeholderProp}
      </FormLabel>
      {isDatePickerVisible && (
        <Box position="absolute" borderRadius="0 0 15px 15px" zIndex="9999" bg="white" boxShadow="0px 10px 15px -3px rgba(0,0,0,0.1)" transform="scale(0.9)" right="-18px" top="32px"  {...calendarStlye}>

        <DayPicker
            mode="single"
            fromYear={1901}
            toYear={2060}
            captionLayout="dropdown-buttons"
            selected={selected}
            onSelect={handleDateSelect}
            disabled={disableDates}
          />
        </Box>
      )}
    </FormControl>
    </>
  );
}

export default DatePickerAppointment;
