import { saveAs } from 'file-saver'
import axios from "axios";
import { CONFIG } from "./appconfig/appconfig";
import moment from 'moment';
import { useBreakpointValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';


export const supportEmail = "info@thecloudintelligence.com";

export const saveToken = (token) => {
    localStorage.setItem('token', token);
    localStorage.setItem("location", "")
  }; 
  export const getToken = () => {
    return localStorage.getItem('token');
  };
  
  export const removeToken = () => {
    localStorage.removeItem('token');
  };
  export const savePatientDetails = (data) => {
    // Stringify the data object into JSON format
    const jsonData = JSON.stringify(data);

    // Set the JSON string in local storage
    localStorage.setItem('patientDetails', jsonData);
};
function getLoginInfo() {
  const jsonData = localStorage.getItem('patientDetails');
  const data = JSON.parse(jsonData);
  const loginId = data ? data.id : null;
  
  return  loginId ;
}
function getUserID() {
  const jsonData = localStorage.getItem('patientDetails');
  const data = JSON.parse(jsonData);
  const activeUserID = data ? data.activeuserid : null;
  
  return  activeUserID ;
}
function getPatientProfile() {
  const jsonData = localStorage.getItem('patientDetails');
  const data = JSON.parse(jsonData);
  let patientImage = null;

  if (data && data.patientimage) {
    if (data.patientimage.startsWith('http')) {
      patientImage = data.patientimage;
    } else if (data.imagepath) {
      patientImage = data.imagepath + data.patientimage;
    }
  }

  return patientImage;
}
function getDob() {
  const jsonData = localStorage.getItem('patientDetails');
  const data = JSON.parse(jsonData);  
  const dob = data ? data.dob : null;  
  return  dob ;
}
function getusername() {
  const jsonData = localStorage.getItem('patientDetails');
  const data = JSON.parse(jsonData);
  const patientname = data ? data.firstname : null;
  
  return  patientname ;
}
function getgender() {
  const jsonData = localStorage.getItem('patientDetails');
  const data = JSON.parse(jsonData);
  const gender = data ? data.gender : 2;
  
  return  gender ;
}
export function getPatientFullName() {
  const jsonData = localStorage.getItem('patientDetails');
  const data = JSON.parse(jsonData);
  const firstName = data ? data.firstname  : null;
  const lastName = data ? data.lastname  : null;
    let patientFullName = firstName + " " + lastName;
  return  patientFullName ;
}
export function getPatientAge() {
  const patientAge = localStorage.getItem('age');
  return  patientAge ;
}
  // export const saveUsername = (username) => {
  //   localStorage.setItem('username', username);
  // };
  // export const saveLoginID = (loginid) => {
  //   localStorage.setItem('loginid', loginid);
  // };
  // export const savePatientImage = (image) => {
  //   localStorage.setItem('patientimage', image);
  // };
  // export const savePatientgender = (gender) => {
  //   localStorage.setItem('patientgender', gender);
  // };
  // export const savePatientdob = (dob) => {
  //   localStorage.setItem('patientdob', dob);
  // }; 
  // export const saveActivaUserID = (activeuserid) => {
  //   localStorage.setItem('activeuserid', activeuserid);
  // }; 
  const jsonData = localStorage.getItem('patientDetails');
const localdata = JSON.parse(jsonData);
const dob = localdata ? localdata.dob : null;


  const age = calculateAge(dob);
  function calculateAge(dateOfBirth) {
    const today = new Date();
    const dob = new Date(dateOfBirth);
    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }
    return age;
  }
  localStorage.setItem("age", age);


  export const uploadImageCommonFunction = async (file, filename) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('filename', filename);
  
      const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_documentupload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.statusText === 'OK') {
        const result = await response.data;
        return result;
      } else {
        const error = 'failed';
        return error;
      }
    } catch (err) {
      console.log(err);
      const error = 'failed';
      return error;
    }
  };
  const downloadDocument = async (url, file) => {
    try {
      // let testurl = "https://midocfileserver.blob.core.windows.net/midocdev/a2816fdf-2b18-4b75-932a-35361e988a11.jpg";
      // let testurl2 = "https://upload.wikimedia.org/wikipedia/en/6/6b/Hello_Web_Series_%28Wordmark%29_Logo.png";
      
      let filename = file? file: "report";
      const response = await fetch(url, {
        mode: 'cors' 
      });
      //console.log(response, " fetch result")
      const blob = await response.blob();
      
      
      saveAs(blob, filename);
    } catch (error) {
      console.error('Failed to download the image:', error);
    }
  };


 const formatDate_Do_MMM_YYYY = (dateString) => {
    if (!dateString) return '-';
    return moment(dateString).format('Do MMM, YYYY');
  };
  // const formatDate_DD_MM_YYYY = (dateString) => {
  //   if (!dateString || isNaN(Date.parse(dateString))) return '-';
  //   return moment(dateString).format('DD-MM-YYYY');
  // };
  const formatDate_DD_MM_YYYY = (dateString) => {
    // Check if the date string is valid using Moment.js with strict parsing
    if (!dateString || !moment(dateString, 'YYYY-MM-DD', true).isValid()) {
      return '-';
    }
    // If valid, format the date as 'DD-MM-YYYY'
    return moment(dateString).format('DD-MM-YYYY');
  };


  export const formatDate_YYYY_MM_DD = (dateString) => {
    if (!dateString) return '-';
    return moment(dateString).format('yyyy-MM-dd');
  };

  // const formatDate_DD_MM_YYYY = (dateString) => {
  //   if (!dateString) return '-';
  //   return moment(dateString).format('DD-MM-YYYY');
  // };


  export const formatDate_ddd_D_MMM = (dateString) => {
    if (!dateString) return '-';
    return moment(dateString).format("ddd, D MMM");
  };
  export const formatDate_yyyy = (dateString) => {
    if (!dateString) return '-';
    return moment(dateString).format("yyyy");
  };

  export const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  export const handleAlphaNumericDotAndSpace = (value) => {
    return value.replace(/[^0-9a-zA-Z\s.]/g, "");
  };

  export const handleAlphaDotAndSpace = (value, maxLength) => {

    let cleanedValue = value.replace(/[^a-zA-Z\s.]/g, '');
    if (cleanedValue.length > maxLength) {
      cleanedValue = cleanedValue.substring(0, maxLength);
    }
    return cleanedValue;
  };

  export const validationOfNumberWithOnly1Decimal = (inputValue, maxLength) => {
    // Remove all characters that are not digits or a decimal point
    let cleanedValue = inputValue.replace(/[^0-9.]/g, '');
  
    // Remove leading decimal point if present
    if (cleanedValue.startsWith(".")) {
      cleanedValue = cleanedValue.substring(1);
    }
  
    // Handle multiple decimal points
    const decimalIndex = cleanedValue.indexOf('.');
    if (decimalIndex !== -1) {
      const parts = cleanedValue.split('.');
      // Keep only the first two digits after the decimal point
      cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
    }
  
    // Enforce the max length after cleaning and truncating
    if (cleanedValue.length > maxLength) {
      cleanedValue = cleanedValue.substring(0, maxLength);
    }
  
    return cleanedValue;
  };

  export const validationMobileNumber = (inputValue, maxLength) => {
    // Remove all characters that are not digits or a decimal point
    let cleanedValue = inputValue.replace(/[^0-9]/g, '');
    
    // Enforce the max length after cleaning and truncating
    if (cleanedValue.length > maxLength) {
      cleanedValue = cleanedValue.substring(0, maxLength);
    }
  
    return cleanedValue;
  };
  

  export  function kgToLbConverter(weightInKg) {
    let value = null;
    let valueToCalculate = null;
    
    if(weightInKg){

      const conversionFactor = 2.20462;
  
      value = (weightInKg * conversionFactor).toFixed(2).toString();
      valueToCalculate = weightInKg * conversionFactor;
    }
    return {
      value,
      valueToCalculate
    }
}
export function lbToKgConverter(weightInLb) {
  let value = null;
  let valueToCalculate = null;

  if(weightInLb){
    
    const conversionFactor = 0.453592;
     value = (Math.floor(weightInLb * conversionFactor * 100) / 100).toFixed(2);
    // value = (weightInLb * conversionFactor).toFixed(2).toString();
    
     valueToCalculate = weightInLb * conversionFactor;
  }
return {
  value,
  valueToCalculate
}
}

export function lbToKgConverterUsingDBValue(weightInLb) {
  let value = null;
  let valueToCalculate = null;

  if (weightInLb) {
    
    value = (Math.floor(weightInLb / 2.20462 * 100) / 100).toFixed(2);
    //value = (weightInLb / 2.20462).toFixed(2).toString(); // Divide by factor to convert lb to kg
    valueToCalculate = weightInLb / 2.20462;
  }
  return {
    value,
    valueToCalculate,
  };
}

export function cmToFtConverter(heightInCm) {
  let feet = null ; 
  let inches = null ; 
  let inchesToCalculate = null; 
  
  if(heightInCm){
    const cmToInchesFactor = 0.393701; // 1 cm = 0.393701 inches
    const inchesPerFoot = 12; // 1 foot = 12 inches
  
    // Convert cm to total inches
    const totalInches = heightInCm / 2.54;
  
    // Calculate feet
     feet = Math.floor(totalInches / inchesPerFoot);
  
    // Calculate remaining inches
    // const inches = (totalInches % inchesPerFoot);
    // const incheValueForDisplay = Math.round(totalInches % inchesPerFoot);
    
     inches = Math.round(totalInches % inchesPerFoot);
     inchesToCalculate = (totalInches % inchesPerFoot);
  }
 
  return {
      feet: feet,
      inches: inches,
     // incheValueForDisplay: incheValueForDisplay
     inchesToCalculate: inchesToCalculate,
  };
} 

export function ftToCmConverterBasic(heightInFt) {
 // alert(heightInFt)
  const conversionFactor = 30.48;
  return (heightInFt * conversionFactor).toFixed(2);
}
export function ftToCmConverter(feetStr, inchesStr = 0) {
  let finalValue = null;
  if(feetStr || inchesStr){
    const feet = parseInt(feetStr, 10);
    const inches = parseFloat(inchesStr, 10);
  
    const totalInches = feet * 12 + inches;
    
  let fixedValue = totalInches * 2.54 // 2.54 cm per inch
    finalValue = fixedValue.toFixed(2)
  }
  
  return finalValue; 
}
  
export function isValidNumber(value) {
  // Regular expression to check if the value is a valid number (including decimal)
  const regex = /^[+-]?(\d+(\.\d*)?|\.\d+)$/;
  
  // Check if the value matches the number format
  return regex.test(value);
}



export const displayMillion_ThoudandValues = (value)=>{
  let updatedValue = value;
  let valueToString = value.toString();
  
  let length =  value.toString().length;
   if(length == 7){
    // if(valueToString.charAt(1) == 0){
    //   updatedValue = valueToString.charAt(0) + "Million"

    // } else {
      updatedValue = valueToString.charAt(0) + "." + valueToString.slice(1,5) + "Million"
    // }
  
   }else if(length == 6){
      if(valueToString.charAt(4) == 0){
    updatedValue = valueToString.slice(0,3) + "k"

      } else {
      updatedValue = valueToString.slice(0,3)  + "." + valueToString.charAt(3) + "k"
      }
   }else if(length == 5){
    if(valueToString.charAt(2) == 0){
  
      updatedValue = valueToString.slice(0,1)  +  "k"
        } else {
      updatedValue = valueToString.slice(0,2)  + "." + valueToString.charAt(2) + "k"
        }
    
   }else if(length == 4){
    if(valueToString.charAt(1) == 0){
  
      updatedValue = valueToString.charAt(0)  +  "k"
        } else {
      updatedValue = valueToString.charAt(0) + "." + valueToString.charAt(1) + "k"
        }
    
   }else {
    updatedValue = value
   }
   return updatedValue;
}


export function getPatientCountryid(){
  // let countryId = localStorage.getItem("countrycode");
  const jsonData = localStorage.getItem('patientDetails');
  const data = JSON.parse(jsonData);
  const countryid = data ? data.countryid : null;
  return countryid;
} 

export function calculateBMIStatus(bodymassindexvalue) {

  if (bodymassindexvalue != null) {
    if (bodymassindexvalue < 18.5) {
      return "Underweight";
    } else if (bodymassindexvalue >= 18.5 && bodymassindexvalue <= 24.9) {
      return "Normal";
    } else if (bodymassindexvalue >= 25 && bodymassindexvalue <= 29.9) {
      return "Overweight";
    } else {
      return "Obese";
    }
  } else {
    return "not available";
  }
}


  export const getCroppedImg = async (imageSrc, croppedAreaPixels) => {
    const image = new Image();
    image.src = imageSrc;
    await new Promise((resolve) => {
      image.onload = resolve;
    });
  
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;
  
    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );
  
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Canvas is empty'));
        }
      }, 'image/jpeg');
    });
  };
 

  export {getLoginInfo, getUserID,getPatientProfile,getusername, getDob,
     getgender, downloadDocument, formatDate_Do_MMM_YYYY,
     formatDate_DD_MM_YYYY
      };

  export const useScreenType = () => {
    const screenType = useBreakpointValue({
      base: 'mobile',
      sm: 'mobile',
      md: 'tablet',
      lg: 'web',
      xl: 'web',
    }, { ssr: false });
    return screenType;
  };
  
  export const useIsIOS = () => {
    const [isIOS, setIsIOS] = useState(false);
  
    useEffect(() => {
      const ua = navigator.userAgent || navigator.vendor || window.opera;
  
      // iOS detection
      if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
        setIsIOS(true);
      }
    }, []);
  
    return isIOS;
  };