import { Box, InputGroup, Flex, Center, VStack, Text } from "@chakra-ui/react";
import { PasswordInput, PrimaryButton, Toaster } from "../../components";
import { passwordupdate, verifyReferalCode } from "../../middleware/services/signup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Loader from "../../components/loader/loader";
import useLoader from "../../components/loader/loaderstates";
import PromoCode from "../../components/promocode";
import { useScreenType } from "../../auth";

function PasswordIndex() {
  const [patientid, setPatientid] = useState("");
  const [password, setPassword] = useState("");
  const [epassword, setEpassword] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [disableBtn, setDisableBtn] = useState(true);
  const [disablePromoBtn, setDisablePromoBtn] = useState(true);
  const [sendPromoCode, setSendPromoCode] = useState(false);

  const { showSuccessToast, showErrorToast } = Toaster();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const screenType = useScreenType()
  useEffect(() => {
    if (password == "" || epassword == "") {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [password, epassword]);
  useEffect(() => {
    if (promoCode == "") {
      setDisablePromoBtn(true);
    } else {
      setDisablePromoBtn(false);
    }
  }, [promoCode]);

  const handlePasswordChange = (e, inputType) => {
    const newPassword = e.target.value;

    const storedPatientId = localStorage.getItem("patientId");

    if (storedPatientId) {
      setPatientid(storedPatientId);
    }
    if (inputType === "password") {
      setPassword(newPassword);
    } else if (inputType === "confirmPassword") {
      setEpassword(newPassword);
    }
  };

  const SubmitFunction = async () => {
    if (password == "") {
      showErrorToast("Please Enter Password");
    } else if (epassword == "") {
      showErrorToast("Please Enter Confirm Password");
    } else if (password.length < 8 || epassword.length < 8) {
      showErrorToast("Password should contain minimum 8 characters.");
    } else if (password.trim() !== epassword.trim()) {
      showErrorToast("Password does not match!");
    } else {
      showLoader();
      var input = {
        patientid,
        password,
        epassword,
       // "referralcode":promoCode
      };
      var result = await passwordupdate(input);
      console.log(result, "password update ");
      // showSuccessToast("Password Updated Successfully");
      sessionStorage.setItem("value", password);
      setTimeout(() => {
        redirect("/success" , {state : {"promoCode" : sendPromoCode ? promoCode : ""}});
        
      }, 1000);
    }
    dismissLoader();
  };

  var redirect = useNavigate();

  const handleMobilepassKeyPress = (e) => {
    if (e.key === "Enter") {
      SubmitFunction();
    }
  };

  const handleApplyClick = async ()=>{
      showLoader();
      
    if(!promoCode){
      showErrorToast("Please enter referal code!");
      dismissLoader();
      return false;
    }
      try {
        let inputToVerifyReferal = {
          "referralcode":promoCode
        }
        const result = await verifyReferalCode(inputToVerifyReferal);
        if(result && result.output && result.output.result == "Failure"){
          showErrorToast("Referralcode is not valid")
          setSendPromoCode(false)
        } else if (result && result.output && result.output.result == "success") {
          showSuccessToast("Referal Code applied successfully!")
          setSendPromoCode(true)
        }else{
          console.error("Something went wrong in verify referalcode!")
        }
  
  
      } catch (error) {
        console.error("Error in verify referal code:", error);
      }
      dismissLoader();

  }

  return (
    <>
    {screenType == 'web' ? (
      <Flex h="100vh">
        {isLoading && <Loader />}
        <Box w="100%" p={4}>
          <Center h="100vh">
            <VStack spacing={0} w="450px">
              <Box
                as="p"
                fontSize="22px"
                fontWeight="600"
                mb={5}
                textAlign="center"
              >
                Lets setup a new password <br /> for your account
              </Box>
              <Box w="100%">
                <InputGroup>
                  <PasswordInput
                    passInputPlace="Enter your Password*"
                    id="password"
                    variant="defaultSide"
                    onChange={(e) => handlePasswordChange(e, "password")}
                  />
                </InputGroup>
                <InputGroup>
                  <PasswordInput
                    passInputPlace="Confirm your Password*"
                    id="confirmpassword"
                    variant="downSide"
                    onChange={(e) => handlePasswordChange(e, "confirmPassword")}
                    onKeyPress={handleMobilepassKeyPress}
                  />
                </InputGroup>
              </Box>

              <Box w="100%" my="2.5rem">
                <Text fontSize="1rem" fontWeight="600">Have a referal code?</Text>
                <Text fontSize="0.875rem" fontWeight="400" color="#718096" mb="1rem">Enter the code here to avail benefits</Text>
                <PromoCode 
                  // promoCode={promoCode}
                  // onChange={handlePromoCodeChange}
                   onApplyClick={handleApplyClick}
                  isDisabled={disablePromoBtn}
                  title="Have a referal code?"
                  description="Enter the code here to avail benefits"
                  placeholder="Referal code"
                  buttonText="Apply"
                  variant="fullRound"
                  promoCode={promoCode}
                  
                  onChange={(e)=>setPromoCode(e.target.value)}
                />
              </Box>

              <Box w="100%" mt="15px">
                <PrimaryButton
                  buttonText="Continue"
                  isDisabled={disableBtn}
                  onClick={SubmitFunction}
                />
              </Box>
              
              
            </VStack>
          </Center>
        </Box>
      </Flex>
    ) : (
      //Mobile responsive start
      <Flex flexDir='column'>
        <Box
          as="p"
          fontSize="22px"
          fontWeight="600"
          textAlign="center"
          color='white'
        >
          Lets setup a new password <br /> for your account
        </Box>
        <Box w="100%" bg="black" h="6px" borderRadius="full" my="1rem">
          <Box as="span" w="100%" bg='#14C585' h="100%" borderRadius="full" display="flex"></Box>
        </Box>
        <Box w="100%">
          <InputGroup>
            <PasswordInput
              passInputPlace="Enter your Password*"
              id="password"
              variant="defaultSide"
              onChange={(e) => handlePasswordChange(e, "password")}
            />
          </InputGroup>
          <InputGroup>
            <PasswordInput
              passInputPlace="Confirm your Password*"
              id="confirmpassword"
              variant="downSide"
              onChange={(e) => handlePasswordChange(e, "confirmPassword")}
              onKeyPress={handleMobilepassKeyPress}
              
            />
          </InputGroup>
        </Box>

        <Box w="100%" my="2.5rem">
          <Text fontSize="1rem" fontWeight="600">Have a referal code?</Text>
          <Text fontSize="0.875rem" fontWeight="400" color="#718096" mb="1rem">Enter the code here to avail benefits</Text>
          <PromoCode 
            // promoCode={promoCode}
            // onChange={handlePromoCodeChange}
             onApplyClick={handleApplyClick}
            isDisabled={disablePromoBtn}
            title="Have a referal code?"
            description="Enter the code here to avail benefits"
            placeholder="Referal code"
            buttonText="Apply"
            variant="fullRound"
            promoCode={promoCode}
            onChange={(e)=>setPromoCode(e.target.value)}
          />
        </Box>

        <Box w="100%" mt="15px">
          <PrimaryButton
            buttonText="Continue"
            isDisabled={disableBtn}
            onClick={SubmitFunction}
          />
        </Box>
      </Flex>
    )}
    </>
  );
}

export default PasswordIndex;
