import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Image,
  Text,
  Badge,
} from "@chakra-ui/react";

const ToggleTab = ({ title, body, onClick, iconSrc, BtnText, isBtnVisible = false, isOpen }) => {
  const [isExpanded, setIsExpanded] = useState(isOpen);

  useEffect(() => {
    setIsExpanded(isOpen);
  }, [isOpen]);

  const handleButtonClick = (event) => {
    event.stopPropagation(); // Prevent the click from propagating to the AccordionButton
    if (onClick) {
      onClick(event); // Execute the passed onClick function
    }
  };

  return (
    <>
      <Accordion allowToggle index={isExpanded ? [0] : []}>
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <AccordionButton p="1rem">
                <Box as="h2" fontSize="0.75rem" fontWeight="600" flex="1" textAlign="left">
                  {title}
                </Box>
                {isBtnVisible && (
                  <Badge
                    ml="1"
                    color="blue"
                    bg="transparent"
                    cursor="pointer"
                    onClick={handleButtonClick}
                    display="flex"
                    alignItems="center"
                    gap="0.25rem"
                  >
                    <Image src={iconSrc ? iconSrc : "/assets/imgs/add-blue.png"} />
                    <Text fontSize="0.875rem" fontWeight="600" textTransform="capitalize" color="#1C4EC5">
                      {BtnText}
                    </Text>
                  </Badge>
                )}
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>{body}</AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default React.memo(ToggleTab);
