import formAPIpastsurgicalhistory from "./pastsurgicalhistory";


var formattedOutput = null;

const formAPIModelpastsurgicalhistory = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIpastsurgicalhistory(item));


  return formattedOutput
};

export default formAPIModelpastsurgicalhistory