import formAPIweight from "./weight";


var formattedOutput = null;

const formAPIModelweight = (data, type) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIweight(item, type));


  return formattedOutput
};

export default formAPIModelweight