import formAPIDoctorAssessment from "./assessment";


var formattedOutput = null;

const formAPIModelDoctorAssessment = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIDoctorAssessment(item));


  return formattedOutput
};

export default formAPIModelDoctorAssessment