import  FetchData, { } from '../../client';
import formAPINotifications from '../../domains/notifications';
import {getLoginInfo, getUserID}  from '../../../auth';


const getNotifications = async (inputData) => {
  try {
    
    inputData.loginid = getLoginInfo();
    inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_getnotificationbypatientid", inputData);
    
       if (result.output.data) {
       var finalOutput = formAPINotifications(result.output.data)
       }
    
    return finalOutput;

  } catch (error) {
    console.error('Error fetching getNotifications data:', error);
    throw error;
  }
};


export {getNotifications };
