import formAPIoxygensaturation from "./oxygensaturation";


var formattedOutput = null;

const formAPIModeloxygensaturation = (data,type) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIoxygensaturation(item,type));


  return formattedOutput
};

export default formAPIModeloxygensaturation