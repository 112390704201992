import formAPIModelbloodpressure from "../bloodpressure/bloodpressures";
import formAPIModelbodytempurature from "../bodytempurature/bodytempuratures";
import formAPIModelheight from "../height/heights";
import formAPIModeloxygensaturation from "../oxygensaturation/oxygensaturations";
import formAPIModelpulse from "../pulse/pulses";
import formAPIModelrespiratoryrate from "../respiratoryrate/respiratoryrates";
import formAPIModelweight from "../weight/weights";

export default function formapimodalvitals({
  bloodpressure,
  bodymassindex,
  bodytempurature,
  height,
  oxygensaturation,
  pulse,
  respiratoryrate,
  weight, 
}, type)
{
return Object.freeze({
    bloodpressure:bloodpressure !== null ? formAPIModelbloodpressure(bloodpressure,type) : [],    
    bodytempurature: bodytempurature !== null ? formAPIModelbodytempurature(bodytempurature, type) : [],
    height : height != null ?  formAPIModelheight(height, type):[],
    oxygensaturation : oxygensaturation !== null ? formAPIModeloxygensaturation(oxygensaturation, type) : [],
    pulse: pulse !== null ? formAPIModelpulse(pulse, type) : [],
    respiratoryrate: respiratoryrate !== null ? formAPIModelrespiratoryrate(respiratoryrate, type) : [],
    weight:weight !== null ? formAPIModelweight(weight, type) : [],
    bodymassindexvalue: bodymassindex,
    bodymassindex:
      bodymassindex != null
        ? bodymassindex < 18.5
          ? parseInt((bodymassindex / 18.5) * 25)
          : bodymassindex <= 24.9
          ? parseInt(((bodymassindex - 18.5) / (24.9 - 18.5)) * 25 + 25)
          : bodymassindex <= 29.9
          ? parseInt(((bodymassindex - 25) / (29.9 - 25)) * 25 + 50)
          : parseInt(((bodymassindex - 30) / (100 - 30)) * 25 + 75)
        : "0",
    rotateangle:
      bodymassindex != null && bodymassindex < 18.5
        ? '-60'
        : bodymassindex != null && bodymassindex >= 18.5 && bodymassindex <= 24.9
        ? '0'
        : bodymassindex != null && bodymassindex >= 25 && bodymassindex <= 29.9
        ? '55'
        : '110',
});
}