import React, { useEffect, useState, useRef } from "react";
import {
  PrimaryButton,
  PrimaryButtonIcon,
  PrimaryInput,
  SearchBar,
  TagsInput,
  Toaster,
} from "../../components";
import DropdownInput from "../../components/dropdown-input";
import {
  getmedicalcondition,
  getallergies,
  addMedicalHistory,
} from "../../middleware/services/signup";
import ModalRight from "../../components/modal-right/index";
import {
  Flex,
  Image,
  Stack,
  Box,
  VStack,
  RadioGroup,
  Text,
  Checkbox,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  Center,
  Heading,
  Divider,
} from "@chakra-ui/react";
import Loader from "../../components/loader/loader";
import useLoader from "../../components/loader/loaderstates";

function MedicalHistory({ onSubmit }) {

  const rightModal = useRef();
  const allergiesModal = useRef();

  var [data, setData] = useState(null);
  var [allergiesdata, setAllergiesData] = useState(null);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [selectedAllergies, setSelectedAllergies] = useState([]);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [patientid, setPatientid] = useState("");
  const [medicationname, setMedicationname] = useState("");
  const [bloodtype_cd, setBloodtype_cd] = useState("");
  const [vaccinated, setVaccinated] = useState("");
  const [bloodTypeDefault, setBloodTypeDefault] = useState("");
  const [vaccineDefault, setVaccineDefault] = useState("");

  
  const [typedCondition , setTypedCondition] = useState("")
  const [typedAllergy , setTypedAllergy] = useState("")
  

  const { showSuccessToast, showErrorToast } = Toaster();
  
  const { isLoading, showLoader, dismissLoader } = useLoader();


  useEffect(() => {

    var storedMedicalDetails = localStorage.getItem("medicalDetails");
    // Parse the string back into an object
    var detailsFromLocal = JSON.parse(storedMedicalDetails);
    if(detailsFromLocal){
     // setBloodTypeDefault(detailsFromLocal.bloodtype_cd)
      if (detailsFromLocal.bloodtype_cd == 158) {
        setBloodTypeDefault("A+");
        setBloodtype_cd("158");
      } else if (detailsFromLocal.bloodtype_cd == 159) {
        setBloodTypeDefault("A-");
        setBloodtype_cd("159");
      } else if (detailsFromLocal.bloodtype_cd == 160) {
        setBloodTypeDefault("B+");
        setBloodtype_cd("160");
      } else if (detailsFromLocal.bloodtype_cd == 161) {
        setBloodTypeDefault("B-");
        setBloodtype_cd("161");
      } else if (detailsFromLocal.bloodtype_cd == 162) {
        setBloodTypeDefault("AB+");
        setBloodtype_cd("162");
      } else if (detailsFromLocal.bloodtype_cd == 163) {
        setBloodTypeDefault("AB-");
        setBloodtype_cd("163");
      } else if (detailsFromLocal.bloodtype_cd == 164) {
        setBloodTypeDefault("O+");
        setBloodtype_cd("164");
      } else if (detailsFromLocal.bloodtype_cd == 165) {
        setBloodTypeDefault("O-");
        setBloodtype_cd("165");
      }
      
      setVaccineDefault(detailsFromLocal.vaccinated)
      setSelectedAllergies(detailsFromLocal.allergies)
      setSelectedConditions(detailsFromLocal.medicalcondition)

    }

    fetchData();
    fetchAllergiesData();
    var patientID = localStorage.getItem("patientId");
    var medicalData = localStorage.getItem("medicalDetails");
    setPatientid(patientID);
  }, []);

  const fetchData = async () => {
    try {
      showLoader();
      const result = await getmedicalcondition({
        type: "healthcondition",
      });
      if (result != null) {
        setData(result.output.data);
      }
    } catch (error) {
      console.error("Error", error);
    }
    dismissLoader();
  };
  
  const fetchAllergiesData = async () => {
    try {
      const response = await getmedicalcondition({
        type: "allergies",
      });
      if (response != null) {
        setAllergiesData(response.output.data);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  
  const handleCheckboxChange = (condition , category) => {
    const isSelected = selectedConditions.some(
      (item) => item.condition_cd === condition.condition_cd
    );

    if (isSelected) {
     
      setSelectedConditions((prevConditions) =>
        prevConditions.filter(
          (item) => item.condition_cd !== condition.condition_cd
        )
      );
    } else {
      setSelectedConditions((prevConditions) => [
        ...prevConditions,
        { ...condition, category },
      ]);
    }
  };
  
  const handleAllergiesCheckboxChange = (condition) => {
    const isSelected = selectedAllergies.some(
      (item) => item.condition_cd === condition.condition_cd
    );
    if (isSelected) {
      setSelectedAllergies((prevConditions) =>
        prevConditions.filter(
          (item) => item.condition_cd !== condition.condition_cd
        )
      );
    } else {
      setSelectedAllergies([...selectedAllergies, condition]);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue) {
      event.preventDefault();
      setTags([...tags, inputValue]);
      setInputValue("");
    }
  };

  const removeTag = (index) => {
    const updatedConditions = selectedConditions.filter((_, i) => i !== index);
    setSelectedConditions(updatedConditions);
  };
  const removeTagAllergies = (index) => {
    const updatedAllergies = selectedAllergies.filter((_, i) => i !== index);
    setSelectedAllergies(updatedAllergies);
  };
  const removeAllergiesTag = (index) => {
    const updatedAllergies = selectedAllergies.filter((_, i) => i !== index);
    setSelectedAllergies(updatedAllergies);
  };

  const medicationsHandleChange = (event) => {
    const removeEmojis = event.target.value.replace(/[^\x00-\x7F]/g, '')
    setMedicationname(removeEmojis);
    console.log(removeEmojis)
  };


  const  SubmitFunction = () => {
    const newActiveComponent = "passwordIndex";
    onSubmit(newActiveComponent);

    const fetchData = async () => {
      try {
        showLoader();
        var input = {
          patientid,
          bloodtype_cd,
          vaccinated,
          allergies: selectedAllergies.map((condition, index) => ({
            condition: condition.condition,
            condition_cd: condition.condition_cd,
            allergytype_cd: condition.condition_cd,
            allergyidentifieddate: new Date().toISOString(), 
          })),
          medicalcondition: selectedConditions.map((condition, index) => ({
            condition: condition.condition,
            conditiontype_cd: condition.category.conditiontype_cd,
            condition_cd: condition.condition_cd,
            nomenclatureid: condition.condition_cd,
            conditionidentifieddate: new Date().toISOString(), 

            "conditiontype":condition?.category?.conditiontype
          })),
          medication: [
            {
              medicationtype_cd: 1,
              medication_cd: 1,
              nomenclatureid: 1,
              medicationname,
              starteddate: "2022-01-05T00:00:00",
              endeddate: "2022-02-05T00:00:00",
            },
          ],
        };
        //console.log(input, "input to add allerges")
        
        localStorage.setItem("medicalDetails", JSON.stringify(input));
        var result = await addMedicalHistory(input);
        //console.log(result, "result to add allerges");

        // showSuccessToast("Medical History Updated");

      } catch (error) {
        console.error("Error fetching password page :", error);
      }
      dismissLoader();
    };

    fetchData();
  };
  const handleSkip = () => {
    showLoader();
    setTimeout(() => {
      const newActiveComponent = "passwordIndex";

      onSubmit(newActiveComponent);
    }, 700);
    dismissLoader();
  };
  const isContinueButtonDisabled = () => {
     const isBloodTypeSelected = bloodtype_cd !== '' || bloodTypeDefault !== '';
    return !(
      (
        isBloodTypeSelected 
      )
    );
  };

  const selectBloodGroup = (selectedOption) => {

    if (selectedOption == "A+") {
      setBloodtype_cd("158")
    } else if (selectedOption == "A-") {
      setBloodtype_cd("159")
    } else if (selectedOption == "B+") {
      setBloodtype_cd("160")
    } else if (selectedOption == "B-") {
      setBloodtype_cd("161")
    } else if (selectedOption == "AB+") {
      setBloodtype_cd("162")
    } else if (selectedOption == "AB-") {
      setBloodtype_cd("163")
    } else if (selectedOption == "O+") {
      setBloodtype_cd("164")
    } else if (selectedOption == "O-") {
      setBloodtype_cd("165")
    }
  }

  const selectIsVacinated = (selectedOption) => {
    setVaccinated(selectedOption);
  };

  const openConditionsModal = ()=>{
    setSearchValue("")
    setTypedCondition("")
   rightModal.current.openRight();
  }

  const openAllergiesModal = ()=>{
    setSearchValue("")
    setTypedAllergy("")
    allergiesModal.current.openRight();
  }
  const handleCondition = (e) => {
    const inputText = e.target.value;
    const maxLength = 80;
  
    if (inputText.length <= maxLength) {
      setTypedCondition(inputText);

     
    }
  
  }

  const AddMedicalConditions = () => {
   
    if (typedCondition !== "") {
      const newSelectedCondition = {
        category: {
          conditiontype: "other",
          conditiontype_cd: null,
        },
        condition: typedCondition,
        condition_cd: null,
      };
    //  if(isAdd){
        
      setSelectedConditions((prevConditions) => {
        // Filter out any existing condition with the same type
        const filteredConditions = prevConditions.filter(
          (item) => item.category.conditiontype !== "other"
        );
        // Add the new condition
        return [...filteredConditions, newSelectedCondition];
      });
      
        
       }
          rightModal.current.closeRight()
    }

    
    const handleAllergy = (e) => {
      const inputText = e.target.value;
      const maxLength = 80;
    
      if (inputText.length <= maxLength) {
        setTypedAllergy(inputText);
      }
      
    
    }

    const closeAddAllergyModal =()=>{
        // enter manually 
        if (typedAllergy !== "") {
          const newSelectedCondition = {
            condition:typedAllergy,
            condition_type:typedAllergy,
            condition_cd:null,
            conditiontype:"other",
           // familyid,
          };
      
          setSelectedAllergies((prevConditions) => {
            // Filter out any existing condition with the same type
            const filteredConditions = prevConditions.filter(
              (item) => item?.conditiontype !== "other"
            );
            // Add the new condition
            return [...filteredConditions, newSelectedCondition];
          });
        }
      allergiesModal.current.closeRight()
    }

  return (
    <>
      <Flex display={{base: "none", lg: "flex"}}>
        
      {isLoading && <Loader />}
        <Box w="100%" p={4}>
          <Center>
            <VStack spacing={0} w="450px">
              <Box as="p" fontSize="22px" fontWeight="600" mb={5}>
                Lets dive deeper, tell us more <br /> about your medical history
              </Box>
              <Flex w="100%" mb="25px">
                <DropdownInput
                  variant="fullLeft"
                  dropDownPlace="Blood Type*"
                  options={["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"]}
                  onClick={selectBloodGroup}
                  readOnly={true}
                  defaultOption={bloodTypeDefault}
                  menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                />
                <DropdownInput
                  variant="fullRight"
                  dropDownPlace="COVID-19 Vaccination"
                  options={["Yes", "No"]}
                  onClick={selectIsVacinated}
                  readOnly={true}
                  defaultOption={vaccineDefault}
                  menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                />
              </Flex>
              <Flex w="100%" alignItems="center" gap="2">
                <Box
                  as="div"
                  role="button"
                  aria-haspopup="dialog"
                  onClick={openConditionsModal}
                  onKeyDown={handleKeyDown}
                  tabIndex={0}
                  borderRadius="10px 10px 0 0"
                  border="1px solid #CDCFD4"
                  p="2"
                  w="100%"
                  display="flex"
                  alignItems="center"
                  gap="2"
                  height="auto"
                  minHeight="48px"
                  maxHeight="100px"
                  overflowY="auto"
                  flexWrap="wrap"
                >
                  {selectedConditions.length === 0 && (
                    <Text pl="2" color="gray.400">
                      Medical Condition
                    </Text>
                  )}
                  {selectedConditions.map((tag, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      gap="1"
                      bg="gray.100"
                      borderRadius="full"
                      p="1"
                    >
                      <Text fontSize="sm" px="5px">
                        {tag.condition}
                      </Text>
                      <Box
                        as="button"
                        p="5px"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeTag(index);
                        }}
                        aria-label="Remove condition"
                      >
                        <Image src="/assets/imgs/close.png" />
                      </Box>
                    </Box>
                  ))}
                </Box>
                
                <ModalRight
                  ref={rightModal}
                  header="Select Condition"
                  body={
                    <>
                      <SearchBar
                        passInputPlace="Search condition"
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                      <RadioGroup>
                        <Stack direction="column" gap="0">
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            borderBottom="1px solid #e2e8f0"
                            py="15px"
                          >
                           <Box display="flex" justifyContent="space-between" w="100%">
  <Stack direction="column" w="100%" gap="0">
    {data && data.length > 0 ? (
      <>
        {data.map((category) => {
          const filteredConditions = category.data.filter((condition) =>
            condition.condition.toLowerCase().includes(searchValue.toLowerCase())
          );

          return (
            <React.Fragment key={category.conditiontype_cd}>
              {filteredConditions.length > 0 && (
                <>
                  <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                    {category.conditiontype}
                  </Heading>
                  {filteredConditions.map((condition) => (
                    <label key={condition.condition_cd}>
                      <Box
                        key={condition.condition_cd}
                        display="flex"
                        justifyContent="space-between"
                        borderBottom="1px solid #e2e8f0"
                        p="15px"
                        pl="5px"
                        minH="50px"
                        width="100%"
                        sx={{
                          _hover: { border: "2px solid #384052", borderRadius: "10px" },
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          w='240px'
                        >
                          <Text fontSize="16px" whiteSpace='pre-wrap' overflow='hidden' lineHeight="normal" fontWeight="600">
                            {condition.condition}
                          </Text>
                        </Box>
                        <Box display="flex" justifyContent="end">
                          <Checkbox
                            colorScheme="green"
                            sx={{
                              ".chakra-checkbox__control": {
                                width: "20px",
                                height: "20px",
                                borderRadius: "5px",
                              },
                            }}
                            onChange={() => handleCheckboxChange(condition, category)}
                            isChecked={selectedConditions.some(
                              (item) => item.condition_cd === condition.condition_cd,
                            )}
                          ></Checkbox>
                        </Box>
                      </Box>
                    </label>
                  ))}
                </>
              )}
            </React.Fragment>
          );
        })}
        {/* Display "No matching conditions found" only if no conditions were found in any category */}
        {data.every((category) =>
          category.data.every((condition) =>
            !condition.condition.toLowerCase().includes(searchValue.toLowerCase())
          )
        ) && (
          <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
          No matching conditions found!
        </Box>
        )}
        <Divider/>
          <br/>
          <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                   Other Condition
                  </Heading>
        <PrimaryInput
         inputPlace="Condition"
         variant="bothSide"
                onChange={handleCondition} inputValue={typedCondition}
                />
                <br/>
      </>
      
    ) : (
      <p>Loading...</p>
    )}
  </Stack>
</Box>
                          </Box>
                        </Stack>
                      </RadioGroup>
                    </>
                  }
                  footerActions={<>
                    <Box
                      w="100%"
                      display="flex"
                      flexDirection="row"
                      gap="15px">
        
                      <PrimaryButton
                        buttonText="Continue"
                        isDisabled={false}
                        onClick={AddMedicalConditions}
                      />
                    </Box>
                  </>}
                />
              </Flex>
              {/* <Flex w="100%">
                <Input
                  id="medications"
                  placeholder="Medications"
                  formLabel="Medical Conditions"
                  onChange={medicationsHandleChange}
                  value={medicationname}
                  height="48px"
                  borderRadius="0"
                  border="1px solid #CDCFD4"
                />
              </Flex> */}
              <Flex w="100%" alignItems="center" gap="2">
                <Box
                  as="div"
                  role="button"
                  aria-haspopup="dialog"
                  onClick={openAllergiesModal}
                  onKeyDown={handleKeyDown}
                  tabIndex={0}
                  borderRadius="0 0 10px 10px"
                  border="1px solid #CDCFD4"
                  p="2"
                  w="100%"
                  display="flex"
                  alignItems="center"
                  gap="2"
                  height="auto"
                  minHeight="48px"
                  maxHeight="100px"
                  overflowY="auto"
                  flexWrap="wrap"
                >
                  {selectedAllergies.length === 0 && (
                    <Text pl="2" color="gray.400">
                      Allergies
                    </Text>
                  )}
                  {selectedAllergies.map((tag, index) => (
                   
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      gap="1"
                      bg="gray.100"
                      borderRadius="full"
                      p="1"
                    >
                      <Text fontSize="sm" px="5px">
                        {tag.condition}
                      </Text>
                      <Box
                        as="button"
                        p="5px"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeTagAllergies(index);
                        }}
                        aria-label="Remove condition"
                      >
                        <Image src="/assets/imgs/close.png" />
                      </Box>
                    </Box>
                  ))}
                 
                </Box>
                <ModalRight
                  ref={allergiesModal}
                  header="Select Allergies"
                  body={
                    <>
                      <SearchBar
                        passInputPlace="Search allergies"
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                      <RadioGroup>
                        <Stack direction="column" gap="0">
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            borderBottom="1px solid #e2e8f0"
                            py="15px"
                          >
                           <Box display="flex" justifyContent="end" w="100%">
  <Stack direction="column" gap="0" w="100%">
    {allergiesdata && allergiesdata.length > 0 ? (
      allergiesdata.some(category =>
        category.data.some(condition =>
          condition.condition.toLowerCase().includes(searchValue.toLowerCase())
        )
      ) ? (
        allergiesdata.map(category => (
          <React.Fragment key={category.category_id}>
            {category.data
              .filter(condition =>
                condition.condition.toLowerCase().includes(searchValue.toLowerCase())
              )
              .map(condition => (
                <label key={condition.condition_cd}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #e2e8f0"
                    borderRadius="10px"
                    p="15px"
                    height="50px"
                    width="100%"
                    sx={{
                      _hover: {
                        border: "2px solid #384052",
                      },
                    }}
                  >
                    <Box
                      key={condition.condition_cd}
                      display="flex"
                      alignItems="center"
                    >
                                            <Text
                                              fontSize="16px"
                                              fontWeight="600"
                                            >
                        {condition.condition}
                        <br />
                        <Box
                          as="span"
                          fontSize="12px"
                          fontWeight="500"
                          color="#6A707D"
                        ></Box>
                      </Text>
                    </Box>
                                          <Box
                                            display="flex"
                                            justifyContent="end"
                                          >
                      <Checkbox
                        colorScheme="green"
                        sx={{
                          ".chakra-checkbox__control": {
                            width: "20px",
                            height: "20px",
                            borderRadius: "5px",
                          },
                        }}
                                              onChange={() =>
                                                handleAllergiesCheckboxChange(
                                                  condition
                                                )
                                              }
                        isChecked={selectedAllergies.some(
                                                (item) =>
                                                  item.condition_cd ===
                                                  condition.condition_cd
                        )}
                      ></Checkbox>
                    </Box>
                  </Box>
                </label>
              ))}
          </React.Fragment>
        ))
      ) : (
        <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
          No matching allergies found!
        </Box>
      )
    ) : (
      <p>Loading...</p>
    )}
     <Divider/>
          <br/>
          <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                   Other Allergy
                  </Heading>
        <PrimaryInput
         inputPlace="Allergy"
         variant="bothSide"
                onChange={handleAllergy} inputValue={typedAllergy}
                />
                <br/>
  </Stack>
</Box>
                          </Box>
                        </Stack>
                      </RadioGroup>
                    </>
                  }
                  footerActions={
                    <>
                      <Box w="100%" display="flex" flexDirection="row" gap="15px">
                        <PrimaryButton
                          buttonText="Continue"
                         // isDisabled={allergiesContinueBtnDisabled}
                          onClick={closeAddAllergyModal}
                        />
                      </Box>
                    </>
                  }
                />
              </Flex>
              <Box
                display="flex"
                gap="8px"
                my={5}
                flexDirection="row"
                alignItems={"center"}
              >
                <Box as="span">
                  <Image w="18px" src="/assets/imgs/info-safe.png" />
                </Box>
                <Box as="p" lineHeight="normal" fontSize="12px">
                  Your information is safe. Only our doctors
                  <br /> can access it for your personalized care.
                </Box>
              </Box>
              <Box w="100%" display="flex" flexDirection="row" gap="15px">
                <PrimaryButtonIcon buttonText="Skip" onClick={handleSkip} />
                <PrimaryButton
                  buttonText="Continue"
                  isDisabled={isContinueButtonDisabled()}
                  onClick={SubmitFunction}
                />
              </Box>
            </VStack>
          </Center>
        </Box>
      </Flex>

      <Flex display={{base: "flex", lg: "none"}} flexDir="column">
        <Box as="p" fontSize="22px" color="white" fontWeight="600">
          Lets dive deeper, tell us more about your medical history
        </Box>
        <Box w="100%" bg="black" h="6px" borderRadius="full" my="1rem">
          <Box as="span" w="66.66%" bg='#14C585' h="100%" borderRadius="full" display="flex"></Box>
        </Box>
        <Flex w="100%" flexDir="column" mb="25px">
          <DropdownInput
            variant="defaultSide"
            dropDownPlace="Blood Type*"
            options={["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"]}
            onClick={selectBloodGroup}
            readOnly={true}
            defaultOption={bloodTypeDefault}
            menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
          />
          <DropdownInput
            variant="downSide"
            dropDownPlace="COVID-19 Vaccination"
            options={["Yes", "No"]}
            onClick={selectIsVacinated}
            readOnly={true}
            defaultOption={vaccineDefault}
            menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
          />
        </Flex>
        <Flex w="100%" alignItems="center" gap="2">
          <Box
            as="div"
            role="button"
            aria-haspopup="dialog"
            onClick={openConditionsModal}
            onKeyDown={handleKeyDown}
            tabIndex={0}
            borderRadius="10px 10px 0 0"
            border="1px solid #CDCFD4"
            p="2"
            w="100%"
            display="flex"
            alignItems="center"
            gap="2"
            height="auto"
            minHeight="48px"
            maxHeight="100px"
            overflowY="auto"
            flexWrap="wrap"
          >
            {selectedConditions.length === 0 && (
              <Text pl="2" color="gray.400">
                Medical Condition
              </Text>
            )}
            {selectedConditions.map((tag, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                gap="1"
                bg="gray.100"
                borderRadius="full"
                p="1"
              >
                <Text fontSize="sm" px="5px">
                  {tag.condition}
                </Text>
                <Box
                  as="button"
                  p="5px"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeTag(index);
                  }}
                  aria-label="Remove condition"
                >
                  <Image src="/assets/imgs/close.png" />
                </Box>
              </Box>
            ))}
          </Box>
                
                <ModalRight
                  ref={rightModal}
                  header="Select Condition"
                  body={
                    <>
                      <SearchBar
                        passInputPlace="Search condition"
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                      <RadioGroup>
                        <Stack direction="column" gap="0">
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            borderBottom="1px solid #e2e8f0"
                            py="15px"
                          >
                           <Box display="flex" justifyContent="end" w="100%" >
                           <Stack direction="column" w="100%" gap="0">
    {data && data.length > 0 ? (
      <>
        {data.map((category) => {
          const filteredConditions = category.data.filter((condition) =>
            condition.condition.toLowerCase().includes(searchValue.toLowerCase())
          );

          return (
            <React.Fragment key={category.conditiontype_cd}>
              {filteredConditions.length > 0 && (
                <>
                  <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                    {category.conditiontype}
                  </Heading>
                  {filteredConditions.map((condition) => (
                    <label key={condition.condition_cd}>
                      <Box
                        key={condition.condition_cd}
                        display="flex"
                        justifyContent="space-between"
                        borderBottom="1px solid #e2e8f0"
                        p="15px"
                        pl="5px"
                        minH="50px"
                        width="100%"
                        sx={{
                          _hover: { border: "2px solid #384052", borderRadius: "10px" },
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          w='240px'
                        >
                          <Text fontSize="16px" whiteSpace='pre-wrap' overflow='hidden' lineHeight="normal" fontWeight="600">
                            {condition.condition}
                          </Text>
                        </Box>
                        <Box display="flex" justifyContent="end">
                          <Checkbox
                            colorScheme="green"
                            sx={{
                              ".chakra-checkbox__control": {
                                width: "20px",
                                height: "20px",
                                borderRadius: "5px",
                              },
                            }}
                            onChange={() => handleCheckboxChange(condition, category)}
                            isChecked={selectedConditions.some(
                              (item) => item.condition_cd === condition.condition_cd,
                            )}
                          ></Checkbox>
                        </Box>
                      </Box>
                    </label>
                  ))}
                </>
              )}
            </React.Fragment>
          );
        })}
        {/* Display "No matching conditions found" only if no conditions were found in any category */}
        {data.every((category) =>
          category.data.every((condition) =>
            !condition.condition.toLowerCase().includes(searchValue.toLowerCase())
          )
        ) && (
          <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
          No matching conditions found!
        </Box>
        )}
        <Divider/>
          <br/>
          <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                   Other Condition
                  </Heading>
        <PrimaryInput
         inputPlace="Condition"
         variant="bothSide"
                onChange={handleCondition} inputValue={typedCondition}
                />
                <br/>
      </>
      
    ) : (
      <p>Loading...</p>
    )}
  </Stack>
                            </Box>
                          </Box>
                        </Stack>
                      </RadioGroup>
                    </>
                  }
                  footerActions={<>
                    <Box
                      w="100%"
                      display="flex"
                      flexDirection="row"
                      gap="15px">
        
                      <PrimaryButton
                        buttonText="Continue"
                        isDisabled={false}
                        onClick={AddMedicalConditions}
                      />
                    </Box>
                  </>}
                />
              </Flex>

              <Flex w="100%" alignItems="center" gap="2">
                <Box
                  as="div"
                  role="button"
                  aria-haspopup="dialog"
                  onClick={openAllergiesModal}
                  onKeyDown={handleKeyDown}
                  tabIndex={0}
                  borderRadius="0 0 10px 10px"
                  border="1px solid #CDCFD4"
                  p="2"
                  w="100%"
                  display="flex"
                  alignItems="center"
                  gap="2"
                  height="auto"
                  minHeight="48px"
                  maxHeight="100px"
                  overflowY="auto"
                  flexWrap="wrap"
                >
                  {selectedAllergies.length === 0 && (
                    <Text pl="2" color="gray.400">
                      Allergies
                    </Text>
                  )}
                  {selectedAllergies.map((tag, index) => (
                   
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      gap="1"
                      bg="gray.100"
                      borderRadius="full"
                      p="1"
                    >
                      <Text fontSize="sm" px="5px">
                        {tag.condition}
                      </Text>
                      <Box
                        as="button"
                        p="5px"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeTagAllergies(index);
                        }}
                        aria-label="Remove condition"
                      >
                        <Image src="/assets/imgs/close.png" />
                      </Box>
                    </Box>
                  ))}
                 
                </Box>
                <ModalRight
                  ref={allergiesModal}
                  header="Select Allergies"
                  body={
                    <>
                      <SearchBar
                        passInputPlace="Search allergies"
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                      <RadioGroup>
                        <Stack direction="column" gap="0">
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            borderBottom="1px solid #e2e8f0"
                            py="15px"
                          >
                           <Box display="flex" justifyContent="end" w="100%">
  <Stack direction="column" gap="0" w="100%">
    {allergiesdata && allergiesdata.length > 0 ? (
      allergiesdata.some(category =>
        category.data.some(condition =>
          condition.condition.toLowerCase().includes(searchValue.toLowerCase())
        )
      ) ? (
        allergiesdata.map(category => (
          <React.Fragment key={category.category_id}>
            {category.data
              .filter(condition =>
                condition.condition.toLowerCase().includes(searchValue.toLowerCase())
              )
              .map(condition => (
                <label key={condition.condition_cd}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #e2e8f0"
                    borderRadius="10px"
                    p="15px"
                    height="50px"
                    width="100%"
                    sx={{
                      _hover: {
                        border: "2px solid #384052",
                      },
                    }}
                  >
                    <Box
                      key={condition.condition_cd}
                      display="flex"
                      alignItems="center"
                    >
                                            <Text
                                              fontSize="16px"
                                              fontWeight="600"
                                            >
                        {condition.condition}
                        <br />
                        <Box
                          as="span"
                          fontSize="12px"
                          fontWeight="500"
                          color="#6A707D"
                        ></Box>
                      </Text>
                    </Box>
                                          <Box
                                            display="flex"
                                            justifyContent="end"
                                          >
                      <Checkbox
                        colorScheme="green"
                        sx={{
                          ".chakra-checkbox__control": {
                            width: "20px",
                            height: "20px",
                            borderRadius: "5px",
                          },
                        }}
                                              onChange={() =>
                                                handleAllergiesCheckboxChange(
                                                  condition
                                                )
                                              }
                        isChecked={selectedAllergies.some(
                                                (item) =>
                                                  item.condition_cd ===
                                                  condition.condition_cd
                        )}
                      ></Checkbox>
                    </Box>
                  </Box>
                </label>
              ))}
          </React.Fragment>
        ))
      ) : (
        <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
          No matching allergies found!
        </Box>
      )
    ) : (
      <p>Loading...</p>
    )}
     <Divider/>
          <br/>
          <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                   Other Allergy
                  </Heading>
        <PrimaryInput
         inputPlace="Allergy"
         variant="bothSide"
                onChange={handleAllergy} inputValue={typedAllergy}
                />
                <br/>
  </Stack>
</Box>
                          </Box>
                        </Stack>
                      </RadioGroup>
                    </>
                  }
                  footerActions={
                    <>
                      <Box w="100%" display="flex" flexDirection="row" gap="15px">
                        <PrimaryButton
                          buttonText="Continue"
                         // isDisabled={allergiesContinueBtnDisabled}
                          onClick={closeAddAllergyModal}
                        />
                      </Box>
                    </>
                  }
                />
              </Flex>
              <Box
                display="flex"
                gap="8px"
                my={5}
                flexDirection="row"
                alignItems={"center"}
              >
                <Box as="span">
                  <Image w="18px" src="/assets/imgs/info-safe.png" />
                </Box>
                <Box as="p" lineHeight="normal" fontSize="12px">
                  Your information is safe. Only our doctors
                  <br /> can access it for your personalized care.
                </Box>
              </Box>
              <Box w="100%" display="flex" flexDirection="row" gap="15px">
                <PrimaryButtonIcon buttonText="Skip" onClick={handleSkip} />
                <PrimaryButton
                  buttonText="Continue"
                  isDisabled={isContinueButtonDisabled()}
                  onClick={SubmitFunction}
                />
              </Box>
      </Flex>
    </>
  );
}

export default MedicalHistory;
