import axios from 'axios';
import { CONFIG } from '../../../appconfig/appconfig';
import  FetchData, { } from '../../client';
import formapimodalaccount from '../../domains/profile';
import {getLoginInfo, getUserID}  from '../../../auth';
import formapimodalAlerts from '../../domains/profilealerts/alert';
import formAPIModelProfileAlerts from '../../domains/profilealerts/alerts';
import formAPIModelProfileInvoices from '../../domains/profileinvoices/profileinvoices';


const FetchInvoices = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    // to change API Name here
    const result = await FetchData("midoc_getpatientinvoice", inputData);
    console.log(result , " db response get invoices ")
     if (result.output.data) {
     var finalOutput = formAPIModelProfileInvoices(result.output.data)
     
     return finalOutput;
     }else{
      return result;
     }

  } catch (error) {
    console.error('Error Account fetching :', error);
    throw error;
  }
};




export {FetchInvoices};
