import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import { Box, Center } from "@chakra-ui/layout";
import {
  Text,
  Image,
  VStack,
  Stack,
  Circle,
  Flex,
  Grid,
  GridItem,
  CardFooter,
  CardBody,
  CardHeader,
  Card,
  Divider,
  SimpleGrid,
  Spacer,
  Heading,
  ListItem,
  UnorderedList
} from "@chakra-ui/react";
import { PrimaryButton, Toaster } from "../../components";
import DoctorCard from "../../components/doctor-card";
import GrayCard from "../../components/card";
import SimpleTable from "../../components/simple-table";
import { FetchHomeData,FetchRecentActivity, FetchMyHealthAIData,FetchAIPrompts,FetchAIAnswer, SubmitConsentForm} from "../../middleware/services/home";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../components/styles/colors";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import moment from "moment";
import Footer from "../../components/footer";
import ReportViewCard from "../../components/report-card";
import {
  FetchFamilyList
} from "../../middleware/services/myhealth/familyhistory";
import {
  SwitchUser
} from "../../middleware/services/login/index";
import {FetchPlans} from "../../middleware/services/profile/subscription";
import ModalRight from "../../components/modal-right";
import TypingLoader from "../../components/typing-loader";
import AiModal from "../../components/ai-modal";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // core Swiper styles
import 'swiper/css/navigation'; // navigation module styles
import { FetchAppointments } from "../../middleware/services/appointments";

import { FetchRewards, FetchTokens } from "../../middleware/services/profile/rewards";

import DynamicModal from "../../components/dynamic-modal";

import { FetchSpecialities } from "../../middleware/services/appointments";
import MobileNavbar from "../../components/mobile-navbar";
import { displayMillion_ThoudandValues, useScreenType } from "../../auth";
import { getNotifications } from "../../middleware/services/notifications";



const HomeIndex = () => {
  const dataTable = {
    "White blood cells": "",
    Hemoglobin: "",
    Hematocrit: "",
    "Platelet count": "",
    Chloride: "",
    Sodium: "",
    Potassium: "",
    CO2: "",
    BUN: "",
    Creatinine: "",
    Glucose: "",
  };

  const findDoctor = [
    {
      "imageUrl": "/assets/imgs/cardiology-final.png",
      "text": "Cardiology",
      "url": "/orthopedist"
    },
    {
      "imageUrl": "/assets/imgs/dentist-final.png",
      "text": "Dentist",
      "url": "/dentist"
    },
    {
      "imageUrl": "/assets/imgs/endocrinology-final.png",
      "text": "Endocrinology",
      "url": "/endocrinology"
    },
    {
      "imageUrl": "/assets/imgs/family-medicine-final.png",
      "text": "Family medicine",
      "url": "/family-medicine"
    },
    {
      "imageUrl": "/assets/imgs/gastroenterology-final.png",
      "text": "Gastroenterology",
      "url": "/gastroenterology"
    },
    {
      "imageUrl": "/assets/imgs/neurology-final.png",
      "text": "Neurology",
      "url": "/neurology"
    },
    {
      "imageUrl": "/assets/imgs/gynecology-final.png",
      "text": "Gynecology",
      "url": "/gynecology"
    },
    {
      "imageUrl": "/assets/imgs/nephrology-final.png",
      "text": "Nephrologist",
      "url": "/nephrologist"
    },
    {
      "imageUrl": "/assets/imgs/oncology-final.png",
      "text": "Oncology",
      "url": "/oncology"
    },
    {
      "imageUrl": "/assets/imgs/ophthalmology-final.png",
      "text": "Ophthalmology",
      "url": "/ophthalmology"
    },
    {
      "imageUrl": "/assets/imgs/orthopedics-final.png",
      "text": "Orthopedics",
      "url": "/orthopedics"
    },
    {
      "imageUrl": "/assets/imgs/pediatrics-final.png",
      "text": "Pediatrics",
      "url": "/pediatrics"
    },
    {
      "imageUrl": "/assets/imgs/psychiatry-final.png",
      "text": "Psychiatry",
      "url": "/psychiatry"
    },
    {
      "imageUrl": "/assets/imgs/pulmonology-final.png",
      "text": "Pulmonology",
      "url": "/pulmonology"
    },
    {
      "imageUrl": "/assets/imgs/surgery-final.png",
      "text": "Surgery",
      "url": "/surgery"
    },
    {
      "imageUrl": "/assets/imgs/other-final.png",
      "text": "Other",
      "url": "/other"
    },
  ]
  
  const grayFilter = `invert(45%) sepia(19%) saturate(245%) hue-rotate(183deg) brightness(92%) contrast(95%)`

  const activityArray = [
    { id: 748, uniqueName: "You have canceled an appointment.", iconUrl: "/assets/imgs/appointments.png" },
    { id: 747, uniqueName: "You have rescheduled an appointment.", iconUrl: "/assets/imgs/appointments.png" },
    { id: 746, uniqueName: "You have scheduled an appointment.", iconUrl: "/assets/imgs/appointments.png" },
    { id: 532, uniqueName: "You have updated your profile.", iconUrl: "/assets/imgs/account-gray.png" },
    { id: 531, uniqueName: "You have deleted Report.", iconUrl: "/assets/imgs/doc-white.png" },
    { id: 530, uniqueName: "You have deleted Prescription Report.", iconUrl: "/assets/imgs/medication.png" },
    { id: 529, uniqueName: "You have deleted Imaging Report.", iconUrl: "/assets/imgs/hand_bones.png" },
    { id: 528, uniqueName: "You have deleted Lab Report.", iconUrl: "/assets/imgs/biotech.png" },
    { id: 527, uniqueName: "You have deleted Height.", iconUrl: "/assets/imgs/measure.png" },
    { id: 526, uniqueName: "You have deleted Weight.", iconUrl: "/assets/imgs/measure.png" },
    { id: 525, uniqueName: "You have deleted Body Mass Index.", iconUrl: "/assets/imgs/measure.png" },
    { id: 524, uniqueName: "You have deleted Oxygen Saturation.", iconUrl: "/assets/imgs/heart.png" },
    { id: 523, uniqueName: "You have deleted Blood Pressure.", iconUrl: "/assets/imgs/heart.png" },
    { id: 522, uniqueName: "You have deleted Heart Rate.", iconUrl: "/assets/imgs/heart.png" },
    { id: 521, uniqueName: "You have deleted Body Temperature.", iconUrl: "/assets/imgs/measure.png" },
    { id: 520, uniqueName: "You have deleted Respiratory Rate.", iconUrl: "/assets/imgs/heart.png" },
    { id: 519, uniqueName: "You have deleted Social History.", iconUrl: "/assets/imgs/social-history-bar.png" },
    { id: 518, uniqueName: "You have deleted Vaccinations.", iconUrl: "/assets/imgs/syringe.png" },
    { id: 517, uniqueName: "You have deleted Family History.", iconUrl: "/assets/imgs/family-history.png" },
    { id: 516, uniqueName: "You have deleted Allergies.", iconUrl: "/assets/imgs/conditions.png" },
    { id: 515, uniqueName: "You have deleted Medications.", iconUrl: "/assets/imgs/medication.png" },
    { id: 514, uniqueName: "You have deleted Surgeries.", iconUrl: "/assets/imgs/surgeries.png" },
    { id: 513, uniqueName: "You have deleted Health conditions.", iconUrl: "/assets/imgs/conditions.png" },
    { id: 512, uniqueName: "You have updated Prescription Report.", iconUrl: "/assets/imgs/medication.png" },
    { id: 511, uniqueName: "You have updated Imaging Report.", iconUrl: "/assets/imgs/hand_bones.png" },
    { id: 510, uniqueName: "You have updated Lab Report.", iconUrl: "/assets/imgs/biotech.png" },
    { id: 509, uniqueName: "You have updated Height.", iconUrl: "/assets/imgs/measure.png" },
    { id: 508, uniqueName: "You have updated Weight.", iconUrl: "/assets/imgs/measure.png" },
    { id: 507, uniqueName: "You have updated Body Mass Index.", iconUrl: "/assets/imgs/measure.png" },
    { id: 506, uniqueName: "You have updated Oxygen Saturation.", iconUrl: "/assets/imgs/heart.png" },
    { id: 505, uniqueName: "You have updated Blood Pressure.", iconUrl: "/assets/imgs/heart.png" },
    { id: 504, uniqueName: "You have updated Heart Rate.", iconUrl: "/assets/imgs/heart.png" },
    { id: 503, uniqueName: "You have updated Body Temperature.", iconUrl: "/assets/imgs/measure.png" },
    { id: 502, uniqueName: "You have updated Respiratory Rate.", iconUrl: "/assets/imgs/heart.png" },
    { id: 501, uniqueName: "You have updated Social History.", iconUrl: "/assets/imgs/social-history-bar.png" },
    { id: 500, uniqueName: "You have updated Vaccinations.", iconUrl: "/assets/imgs/syringe.png" },
    { id: 499, uniqueName: "You have updated Family History.", iconUrl: "/assets/imgs/family-history.png" },
    { id: 498, uniqueName: "You have updated Allergies.", iconUrl: "/assets/imgs/conditions.png" },
    { id: 497, uniqueName: "You have updated Medications.", iconUrl: "/assets/imgs/medication.png" },
    { id: 496, uniqueName: "You have updated Surgeries.", iconUrl: "/assets/imgs/surgeries.png" },
    { id: 495, uniqueName: "You have updated Health conditions.", iconUrl: "/assets/imgs/conditions.png" },
    { id: 494, uniqueName: "You have added Prescription Report.", iconUrl: "/assets/imgs/medication.png" },
    { id: 493, uniqueName: "You have added Imaging Report.", iconUrl: "/assets/imgs/hand_bones.png" },
    { id: 492, uniqueName: "You have added Lab Report.", iconUrl: "/assets/imgs/biotech.png" },
    { id: 491, uniqueName: "You have added Height.", iconUrl: "/assets/imgs/measure.png" },
    { id: 490, uniqueName: "You have added Weight.", iconUrl: "/assets/imgs/measure.png" },
    { id: 489, uniqueName: "You have added Body Mass Index.", iconUrl: "/assets/imgs/measure.png" },
    { id: 488, uniqueName: "You have added Oxygen Saturation.", iconUrl: "/assets/imgs/heart.png" },
    { id: 487, uniqueName: "You have added Blood Pressure.", iconUrl: "/assets/imgs/heart.png" },
    { id: 486, uniqueName: "You have added Heart Rate.", iconUrl: "/assets/imgs/heart.png" },
    { id: 485, uniqueName: "You have added Body Temperature.", iconUrl: "/assets/imgs/measure.png" },
    { id: 484, uniqueName: "You have added Respiratory Rate.", iconUrl: "/assets/imgs/heart.png" },
    { id: 483, uniqueName: "You have added Social History.", iconUrl: "/assets/imgs/social-history-bar.png" },
    { id: 482, uniqueName: "You have added Vaccinations.", iconUrl: "/assets/imgs/syringe.png" },
    { id: 481, uniqueName: "You have added Family History.", iconUrl: "/assets/imgs/family-history.png" },
    { id: 480, uniqueName: "You have added Allergies.", iconUrl: "/assets/imgs/conditions.png" },
    { id: 479, uniqueName: "You have added Medications.", iconUrl: "/assets/imgs/medication.png" },
    { id: 478, uniqueName: "You have added Surgeries.", iconUrl: "/assets/imgs/surgeries.png" },
    { id: 477, uniqueName: "You have added Health conditions.", iconUrl: "/assets/imgs/conditions.png" }
];

const mapActivityToIcon = (description) => {
  const activity = activityArray.find(item => item.uniqueName === description);
  return activity ? activity.iconUrl : '/default-icon-url.png';
}


  const filledBox = "#FCCE35";
  const emptyBox = "#E6E7E9";
  const greenBox = "#14C585";
  const dataEntries = Object.entries(dataTable);
  var [data, setData] = useState(null);
  var [tokensdata, setTokensData] = useState(null);
  var [familydata, setFamilyData] = useState(null);
  var [recentactivity, setRecentActivity] = useState(null);
  var [patientid, setPatientid] = useState();
  var [activeuserid, setActiveUser] = useState();
  var [reportRecentActivity, setReportRecentActivity] = useState();
  var [treatmentTeamRecentActivity, setTreatmentTeamRecentActivity] = useState();
  var [medicationsRecentActivity, setMedicationsRecentActivity] = useState();
  var [showUpdate1, setShowUpdate1] = useState(true);
  var [showUpdate2, setShowUpdate2] = useState(true);
  var [showUpdate3, setShowUpdate3] = useState(true);
  var navigate = useNavigate();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const swiperRef = useRef(null);
  const [plansdata, setPlansData] = useState("");
  const upgradeModal = React.useRef()
  const AIChatModel = React.useRef();
  const chatContainerRef = React.useRef(null);
  const [aianswers, setAIAnswers] = useState(null);
  const [remainingquestions, setRemainingQuestions] = useState("");
  const [aiquestion, setAIAnalysis] = useState(null);
  const [aiprompts, setAIPrompts] = useState(null);
  const [AiInput, setAiInput] = useState(null);
  var [notificationData, setNotificationData] = useState(null);
  const swiperInstance = useRef(null);
  const switchUsers = useRef(null);
  const [animate, setAnimate] = useState(false);
  const infoModal = useRef();

 const { showSuccessToast, showErrorToast } = Toaster();
  const [rewardsData , setRewardsData] = useState("")
  const [boxColor , setBoxColor] = useState("#E6E7E9")
  const [aiboxColor , setAIBoxColor] = useState("#8aa3e2")

  const [totalPoints , setTotalPoints] = useState("");
  const [currentPoints, setCurrentPoints] = useState("");
  const [appointmentdata, setAppointmentdata] = useState("");
  const [totaltokens , setTotalTokens] = useState("");
  const [currenttokens, setCurrentTokens] = useState("");
  const [activityIcon, setActivityIcon] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const screenType = useScreenType()
  const [ infoArray , setInfoArray ] = useState([])
  const consentForm = useRef();
  const notification = useRef();


  var [specialitiesdata, setSpecialitiesData] = useState(null);
  const totalBoxes = 30;
  const filledBoxes = totalPoints !== null ? Math.floor((currentPoints / totalPoints) * totalBoxes) : 30 ;
  const AitokensfilledBoxes = totaltokens !== null ? Math.floor((currenttokens / totaltokens) * totalBoxes) : 30 ;

  const [upgradePlanModal , setUpgradePlanModal] = useState(false)
  
  const handleNavigation = (direction) => {
    if (swiperInstance.current && swiperInstance.current.swiper) {
      const swiper = swiperInstance.current.swiper;
      if (direction === 'prev' && swiper.slidePrev) {
        swiper.slidePrev();
      } else if (direction === 'next' && swiper.slideNext) {
        swiper.slideNext();
      }
    }
  };


  useEffect(() => {  
    fetchAllData();  
    const jsonData = localStorage.getItem('patientDetails');
    const data = JSON.parse(jsonData);
    if(data.isconsentformaccepted === 0){
      consentForm.current.openModal();
     }
    const newuser = sessionStorage.getItem('NewUser');
    if(newuser === 'true'){
      FetchAllPlans();
    }
  }, []);
  const fetchAllData = async () => {
    showLoader();
    try {
      await Promise.all([
        fetchAiDoctorPrompts(),
        fetchSpecialities(),
        fetchAppointments(),
        getTokens(),
        fetchData(),
        getRewards()
      ]);
    dismissLoader();
    } catch (error) {
      console.error("Error in fetching data:", error);
      dismissLoader();
    }
  };
  const fetchData = async () => {
    showLoader()
    try {
      const result = await FetchHomeData({
        patientid,
        activeuserid,
        "platformtype":"web"
      });

     // console.log(result , " result HOME page")
      const recentactivity = await FetchRecentActivity({
        patientid,
        activeuserid,
        type:"homepage"
      });
      if(recentactivity){
        console.log(recentactivity, 'from recent activity')
        setRecentActivity(recentactivity)
      }
      const response = await FetchFamilyList({
        patientid,
        type: "switchuser",
      });
     
      if(response.output.result === "success") {
        setFamilyData(response.output.data);

        var firstName = response.output.data[0].firstname
        var lastName = response.output.data[0].lastname
        if(firstName)setFirstName(firstName)
        if(lastName)setLastName(lastName)
        //dismissLoader();
      }
      if (result != null) {
       setData(result);
      localStorage.setItem("isread" , result.isread)
       setReportRecentActivity(result.timeDifferenceReports)
       setTreatmentTeamRecentActivity(result.timeDifferenceTreatmentTeam)
       setMedicationsRecentActivity(result.timeDifferenceMedications)
       if(result.timeDifferenceReports == "Not yet updated" || result.timeDifferenceReports == "Updated Just Now" ){
        setShowUpdate1(false);
      }
      if(result.timeDifferenceTreatmentTeam == "Not yet updated"|| result.timeDifferenceTreatmentTeam == "Updated Just Now" ){
        setShowUpdate2(false);
      }
      if(result.timeDifferenceMedications == "Not yet updated"|| result.timeDifferenceMedications == "Updated Just Now" ){
        setShowUpdate3(false);
      }
        
      } else {
        //alert("No data");
      }
      
    } catch (error) {
      console.error("Error in home:", error);
    }
    //dismissLoader();
  };
  const fetchAiDoctorPrompts = async ()=>{
    showLoader()
    const result = await FetchMyHealthAIData({
      patientid,
    });
    //console.log(result)
    let Aiprompts = { ...result, patientid };
    setAiInput(Aiprompts)
    const response = await FetchAIPrompts(Aiprompts);
    //console.log(response);
    if (response.generated_prompts) {
      setAIAnalysis(response);
      setAIPrompts(response);
    }
    //dismissLoader()
  }
  const fetchSpecialities = async () => {
    showLoader();
    try {
      const result = await FetchSpecialities({
        patientid,
        activeuserid,
        type:"speciality"
      });
      if (result != null) {
        setSpecialitiesData(result);
      } else {
        //alert("No data");
      }
      //dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
  const FetchAllPlans = async () => {
    showLoader()
    try {
      var result = await FetchPlans({
        patientid,
      })
      //console.log(result, " plans modified data")
      if (result) {
        setPlansData(result);
        upgradeModal.current.openRight();
        sessionStorage.removeItem('NewUser');
      } else {
        setPlansData("")
        //  showErrorToast("Something went wrong!")
      }

      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
  const fetchAppointments = async () => {
    showLoader();
    try {
      const result = await FetchAppointments({
        patientid,
        activeuserid,
        appointmentstatus:"1",
      });
      if (result != null) {
        setAppointmentdata(result);
      } else {
        //alert("No data");
      }
      //dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
  const getTokens = async () => {
    //showLoader()
    var input = {}
    try {
      var result = await FetchTokens(input);
      if (result) {
       setTokensData(result);
       setCurrentTokens(result.remainingtokens)
       setTotalTokens(result.totaltokens)
      
      } else {
        showErrorToast("Something went wrong!")
      }

    } catch (error) {
      console.log(error, "error in fetching account data")
    }
    //dismissLoader();
  }
    const getRewards = async () => {
      showLoader();
      var input = {}
      try {
        var result = await FetchRewards(input);
        //console.log(result , " rewards Result ")
        if (result) {
          setRewardsData(result);
         setCurrentPoints(result.totalpoint)
         setTotalPoints(result.pointtounlocknext)
         switch (result.position) {
          case "Gold":
            setBoxColor("#FCCE35");
            break;
          case "Bronze":
            setBoxColor("#E98D3A");
            break;
          case "Silver":
            setBoxColor("#6A707D");
            break;
          case "Platinum":
            setBoxColor("#4A67AE");
            break;
          default:
            setBoxColor("#E6E7E9");
            break;
        }
        } else {
          showErrorToast("Something went wrong!")
        }

      } catch (error) {
        console.log(error, "error in fetching account data")
      }
      //dismissLoader();
    }
  const upgradeplan = (planid)=>{
    navigate("/payment?id="+planid)
  }
  const navigateToVitals = () => {
    navigate("/vitals");
    window.scrollTo(0, 0);
  };
  const navigateToLabs = () => {
    navigate("/myhealth/labs");
    window.scrollTo(0, 0);
  };
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };
  const fileInputRef = useRef(null);
  const navigateToLabsupload = () => {
    fileInputRef?.current?.click();
    //navigate("labs");
    //window.scrollTo(0, 0);
  };
  const handleimageselection = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    showLoader();
    navigate("/docupload", { state: { file } });
  };
  const handleswitchuser = async (id, primaryuser, selectedUser)=>{
   // alert(selectedUser)
    if(selectedUser == 1){
      return false;
    }
    let input = {"patientid":"","activeuserid": id,"isprimaryuser":primaryuser  }
    try {
      showLoader()
      const result = await SwitchUser(input);
      //console.log(result, "switch response");
      
    localStorage.removeItem("username")
      if(result.output) {
          localStorage.setItem('patientDetails', JSON.stringify(result.output));
        window.location.reload()
      } else {
        //alert("No data");
        dismissLoader();
      }
    } catch (error) {
      console.error("Error in DashboardIndex:", error);
    }
  };
  const GetAIAnswer = async (prompt) => {
    AIChatModel.current.openRight();
    let AichatInput = {
      prompt: prompt,
      input: {
        ...AiInput
      }
    };

    // Display question immediately
    const newQuestion = { question: prompt, answer: "" };
    setAIAnswers((prevState) => {
      if (prevState === null) {
        return [newQuestion];
      } else {
        return [...prevState, newQuestion];
      }
    });
    //showLoader();
    const response = await FetchAIAnswer(AichatInput);
    // dismissLoader();

    if (response.selected_prompt) {
      // Replace "typing" with actual answer
      setAIAnswers((prevState) => {
        const updatedAnswers = prevState.map((item) => {
          if (item.question === prompt && item.answer === "") {
            return { question: item.question, answer: response.information };
          }
          return item;
        });
        getTokens();
        return updatedAnswers;
      });
      const indexesToRemove = [];
      aiquestion.generated_prompts.forEach((question, index) => {
        if (question.value === prompt) {
          indexesToRemove.push(index);
        }
      });

      // Remove the objects at the indexes collected above
      indexesToRemove.reverse().forEach((index) => {
        aiquestion.generated_prompts.splice(index, 1);
      });
      setRemainingQuestions(aiquestion.generated_prompts);

      window.scrollTo(0, document.body.scrollHeight);
    } else {
      // showErrorToast("Ai tokens are not available");
      setUpgradePlanModal(true)
      setTimeout(() => {
        setUpgradePlanModal(false)
      }, 100);
      AIChatModel.current.closeRight();
    }
  };

  const submitconsentform = async ()=>{
    const result = await SubmitConsentForm({
      patientid,
    });
    consentForm.current.closeModal();
    const patientDetails = JSON.parse(localStorage.getItem('patientDetails'));

    // Update the isconsentformaccepted attribute to 1
    if (patientDetails) {
      patientDetails.isconsentformaccepted = 1;
  
      // Save the updated patientDetails back to localStorage
      localStorage.setItem('patientDetails', JSON.stringify(patientDetails));
    }
  }
  const css = `
  .swiper-container{
    height: 100%; 
    max-height: 100vw;
    min-height: 0;
    min-width: 0;
    max-width: 100vw; 
    width: 100%;
    overflow: hidden;
  }
   
  .swiper-slide{
    width: 370px!important;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
  }

  .swiper {
    margin: 0!important;
  }
  
  .swiper-wrapper{
    max-height: 100%;
    height: 100%t;
    display: flex;
    gap: 20px;
  }
  `

  const formatTime = (timeString) => {
    if (!timeString) return "-";
    return moment(timeString, 'HH:mm:ss').format('HH:mm');
};
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const modalBodyRef = useRef();

  const handleScroll = () => {
    if (modalBodyRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = modalBodyRef.current;
      setIsScrolledToBottom(scrollTop + clientHeight >= scrollHeight - 10);
    }
  };

  useEffect(() => {
    const modalBodyElement = modalBodyRef.current;
    if (modalBodyElement) {
      modalBodyElement.addEventListener('scroll', handleScroll);
      // Perform an initial check to see if the content is already at the bottom
      handleScroll();
    }
    return () => {
      if (modalBodyElement) {
        modalBodyElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [modalBodyRef.current]);

  // Adding this to ensure initial render and re-calculation after any updates
  useEffect(() => {
    if (modalBodyRef.current) {
      setTimeout(handleScroll, 100); // Adding a small delay to ensure dimensions are correct
    }
  }, []);

  const redirectToSubscription = ()=>{
    showLoader()
    setTimeout(() => {
      
    navigate("/subscription")
    localStorage.setItem("openPlanModal" , true)
    }, 1000);
    dismissLoader()
  }
const openInfoModal = (desc)=>{
  if(desc && desc.info){

    setInfoArray(desc.info)
  }
  infoModal.current.openRight();
}

const showNotifications = () =>{

  const fetchNotifications  = async() =>{
    var input = {}
    showLoader();
    try {
      var result = await getNotifications(input)
     // console.log(result, "Notifictions formatted data")
     setNotificationData(result)
    } catch (error) {
      console.log(error, "error in fetching notifications")
    }
    dismissLoader();
  }
  fetchNotifications();
  notification.current.openRight()
}
//For mobile view
useEffect(() => {
  if (animate) {
    const timer = setTimeout(() => {
      setAnimate(false);
    }, 500);
    return () => clearTimeout(timer);
  }
}, [animate]);
const fetchAiDoctorPromptsForMobile = async ()=>{
  showLoader();
  const result = await FetchMyHealthAIData({
    patientid,
  });
  let Aiprompts = { ...result, patientid };
  setAiInput(Aiprompts)
  const response = await FetchAIPrompts(Aiprompts);
  if (response.generated_prompts) {
    setAIAnalysis(response);
    setRemainingQuestions(response.generated_prompts);
    AIChatModel.current.openRight();
    dismissLoader();  
  }
  dismissLoader();  
}

  return (
    <>
    <style>{css}</style>
    {screenType == 'web' ? (
      <Box w="100%">
        <Box top="0" zIndex="banner">
          <Navbar  messageModal={upgradePlanModal}  />
        </Box>
          {isLoading && <Loader />}
          <Box  
            // maxH="calc(100vh-100px)"
            height="calc(100vh - 70px)"
            overflowX="hidden"
            overflowY="auto">
          <Box w="100%">
            <Flex
              // bg="rgb(6,16,39)"
              bg={`url('/assets/imgs/bg-gradeint.png'), rgb(6,16,39)`}
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              backgroundPosition="center"
              // backgroundImage="radial-gradient(circle, rgba(6,16,39,1) 0%, rgba(9,23,58,1) 35%, rgba(9,23,58,1) 100%)"
              h="150px"
              justifyContent="space-around"
              alignItems="center"
            >
              <Flex
                templateColumns="repeat(7, 1fr)"
                w="100%"
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
                gap={6}
                p="0 100px"
              >
                <Box onClick={() => navigate("labs")}>
                  <Box
                    as="button"
                    display="flex"
                    flexDirection="column"
                    lineHeight="normal"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image src="/assets/imgs/biotech-white.png" />
                    <Box
                      as="p"
                      color="white"
                      mt="8px"
                      fontSize="14px"
                      whiteSpace="nowrap"
                      fontWeight="500"
                    >
                      Labs
                    </Box>
                  </Box>
                </Box>
                <Center h="50px" opacity="0.5" bg="#112F76">
                  <Divider orientation="vertical" />
                </Center>
                <Box>
                  <Box
                  onClick={() => navigate("/home/treatmentteam")}
                    as="button"
                    display="flex"
                    flexDirection="column"
                    lineHeight="normal"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image ml="-10px" src="/assets/imgs/treatment-team.png" />
                    <Box
                    
                      as="p"
                      color="white"
                      mt="8px"
                      fontSize="14px"
                      whiteSpace="nowrap"
                      fontWeight="500"
                    >
                      Treatment Team
                    </Box>
                  </Box>
                </Box>
                <Center h="50px" opacity="0.5" bg="#112F76">
                  <Divider orientation="vertical" />
                </Center>
                <Box>
                  <Box
                   onClick={() => navigate("/chat")}
                    as="button"
                    display="flex"
                    flexDirection="column"
                    lineHeight="normal"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image src="/assets/imgs/messages.png" />
                    <Box
                      as="p"
                      color="white"
                      mt="8px"
                      fontSize="14px"
                      whiteSpace="nowrap"
                      fontWeight="500"
                    >
                      Messages
                    </Box>
                  </Box>
                </Box>
                <Box colSpan={2} h="auto">
                  <Box
                    as="button"
                    borderRadius="full"
                  >
                    <Image
                      w="80px"
                      h="80px"
                      borderRadius="full"
                      src={
                        data && data.patientimage
                          ? data.patientimage
                          : "/assets/imgs/no-image-icon.png"
                      }
                      onClick={() => navigate("/profile")}
                    />
                  </Box>
                </Box>
                <Box>
                  <Box
                    as="button"
                    display="flex"
                    flexDirection="column"
                    lineHeight="normal"
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => navigate("/medications")}
                  >
                    <Image src="/assets/imgs/medication-white.png" />
                    <Box
                      as="p"
                      color="white"
                      mt="8px"
                      fontSize="14px"
                      whiteSpace="nowrap"
                      fontWeight="500"
                    >
                      Medications
                    </Box>
                  </Box>
                </Box>
                <Center h="50px" opacity="0.5" bg="#112F76">
                  <Divider orientation="vertical" />
                </Center>
                <Box>
                  <Box
                    as="button"
                    display="flex"
                    flexDirection="column"
                    lineHeight="normal"
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => navigate("/myhealth/imaging")}
                  >
                    <Image src="/assets/imgs/doc-white.png" />
                    <Box
                      as="p"
                      color="white"
                      mt="8px"
                      fontSize="14px"
                      whiteSpace="nowrap"
                      fontWeight="500"
                    >
                      Reports
                    </Box>
                  </Box>
                </Box>
                <Center h="50px" opacity="0.5" bg="#112F76">
                  <Divider orientation="vertical" />
                </Center>
                <Box>
                  <Box
                    as="button"
                    display="flex"
                    flexDirection="column"
                    lineHeight="normal"
                    alignItems="center"
                    justifyContent="center"
                    onClick={()=>navigate("/all-appoinments")}
                  >
                    <Image src="/assets/imgs/visits.png" />
                    <Box
                      as="p"
                      color="white"
                      mt="8px"
                      fontSize="14px"
                      whiteSpace="nowrap"
                      fontWeight="500"
                    >
                      Visits
                    </Box>
                  </Box>
                </Box>
              </Flex>
            </Flex>
          </Box>

          {/* Main content start */}
          <Flex flex="1" overflowX="hidden" overflowY="auto">
            {/* Left area start */}
            <Box w="75%" h="100%" borderRight="1px solid #E6E7E9">
              {/* Next steps to explore start */}
              <Box w="100%" px="30px" py="10px" >
                <Box display="flex" flexDirection="column">
                  <Box as="h1" fontSize="20px" fontWeight="600" my="20px">
                    Next steps to explore
                  </Box>
                  <Flex gap="20px" justifyContent="space-between">
                    <Box w="33.33%" cursor="pointer" boxShadow="0px 8px 20px 0px #E6E7E966" onClick={navigateToLabsupload}>
                      <Flex
                        p="16px 20px"
                        borderRadius="15px 15px 0 0"
                        h="70px"
                        gap="8px"
                        background="radial-gradient(circle, rgba(59,109,228,1) 0%, rgba(28,78,197,1) 100%)"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text fontSize="16px" fontWeight="600" color="white" lineHeight="normal">
                          Upload document
                        </Text>
                        <Box
                          w="64px"
                          h="40px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          border="4px solid #6C91EA"
                          borderRadius="full"
                          bg="white"
                        >
                          <Image src="/assets/imgs/biotech-home.png" />
                        </Box>
                      </Flex>

                      <Box
                        as="button"
                        w="100%"
                        border="1px solid #E6E7E9"
                        borderRadius="0 0 13px 13px"
                        bg="white"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p="12px"
                      >
                        <Text fontSize="12px" fontWeight="500">
                        {showUpdate1 && "Updated"} {reportRecentActivity?reportRecentActivity:"-"}
                        </Text>
                        <Image src="/assets/imgs/right-icon.png" />
                      </Box>
                    </Box>

                    <Box w="33.33%" cursor="pointer" boxShadow="0px 8px 20px 0px #E6E7E966" onClick={() => navigate("/home/treatmentteam")}>
                      <Flex
                        p="16px 20px"
                        borderRadius="15px 15px 0 0"
                        h="70px"
                        gap="8px"
                        alignItems="center"
                        justifyContent="space-between"
                        background="radial-gradient(circle, rgba(109,83,226,1) 0%, rgba(76,50,196,1) 100%)"
                      >
                        <Text fontSize="16px" fontWeight="600" color="white" lineHeight="normal">
                          View my treatment team
                        </Text>
                        <Box
                          w="64px"
                          h="40px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          border="4px solid #8C76F5"
                          borderRadius="full"
                          bg="white"
                        >
                          <Image src="/assets/imgs/treatment-violent.png" />
                        </Box>
                      </Flex>
                      <Box
                        as="button"
                        w="100%"
                        border="1px solid #E6E7E9"
                        borderRadius="0 0 13px 13px"
                        bg="white"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p="12px"
                      >
                        <Text fontSize="12px" fontWeight="500">
                        {showUpdate2 && "Updated"}  {treatmentTeamRecentActivity?treatmentTeamRecentActivity:"-"}
                        </Text>
                        <Image src="/assets/imgs/right-icon.png" />
                      </Box>
                    </Box>

                    <Box w="33.33%" cursor="pointer" boxShadow="0px 8px 20px 0px #E6E7E966" onClick={() => navigate("/medications")}>
                      <Flex
                        p="16px 20px"
                        borderRadius="15px 15px 0 0"
                        h="70px"
                        gap="8px"
                        alignItems="center"
                        justifyContent="space-between"
                        background="radial-gradient(circle, rgba(204,65,165,1) 0%, rgba(177,30,136,1) 100%)"
                      >
                        <Text fontSize="16px" fontWeight="600" color="white" lineHeight="normal">
                          Add new medications
                        </Text>
                        <Box
                          w="64px"
                          h="40px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          border="4px solid #E76DC5"
                          borderRadius="full"
                          bg="white"
                        >
                          <Image src="/assets/imgs/medication-pink.png" />
                        </Box>
                      </Flex>
                      <Box
                        as="button"
                        w="100%"
                        border="1px solid #E6E7E9"
                        borderRadius="0 0 13px 13px"
                        bg="white"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p="12px"
                      >
                        <Text fontSize="12px" fontWeight="500">
                        {showUpdate3 && "Updated"} {medicationsRecentActivity?medicationsRecentActivity:"-"}
                        </Text>
                        <Image src="/assets/imgs/right-icon.png" />
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              </Box>
              {/* Next steps to explore end */}

               {/* Appointment start */}

              <Box w="100%" px="20px" py="10px" mb="20px">
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                  <Box>
                    <Box as="h1" fontSize="20px" fontWeight="600">Appointments</Box>
                  </Box>
                  <Box>

                    <PrimaryButton variant="grayBtn" buttonText="View all" onClick={()=>navigate("/all-appoinments")}/>
                  </Box>
                </Box>
                {appointmentdata && appointmentdata != null ? (
                <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} spacing="1rem">
                  {appointmentdata && appointmentdata.slice(0,3).map((step, index) => (

                  <DoctorCard 
                    bodyStyle={{p:"20px 20px 0 20px"}}
                    cardBody={
                    <>
                      <Box
                          py="10px"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box display="flex" alignItems="center">
                            <Image
                              w="40px"
                              h="40px"
                              borderRadius="full"

                              src={step.doctorimage?step.imagepath+step.doctorimage:"/assets/imgs/no-image-icon.png"} 

                              mr="3"
                            />
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                            >
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#384052"
                              >

                                {step.specality? step.specality:"-"}
                              </Text>
                              <Text className="textOverflow" w="124px" fontSize="14px" fontWeight="600">
                              {step.doctorname? step.doctorname:"-"}
                              </Text>
                            </Box>
                          </Box>
                          <Text fontSize="0.625rem" fontWeight="600" color={step.appointmentstatus === 1? "#FC8935" : "#384052"} borderColor={step.appointmentstatus === 1? "#FC89351A" : "#f5f5f6"} border="1px solid" p="0.35rem 0.5rem" borderRadius="full" bg={step.appointmentstatus === 1? "#fff3eb" : "#f5f5f6"}>{step.appointmentstatus === 1? "Upcoming": step.appointmentstatus === 2?"Cancelled": step.appointmentstatus === 3 ? "Completed": step.appointmentstatus === 0 ? "Pending" :""}</Text>


                        </Box>
                          <Divider />
                          <Box display="flex" flexDirection="row" justifyContent="start" py="15px" gap="15px">
                            <Box display="flex" flexDirection="column" gap="6px">
                              <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                                <Image w="12px" h="13" src="/assets/imgs/calendar-blue.png" />
                                <Text fontSize="0.75rem" className="textOverflow" w="70px" fontWeight="500">{formatDate(step.appointmentdate)}</Text>
                              </Box>
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="13px" h="10px" src={step.appointmenttype_cd === "clinical visit"?"/assets/svgs/hospital.svg": "/assets/imgs/images-blue.png" }/>
                                <Text fontSize="0.75rem" className="textOverflow" w="70px" fontWeight="500">{step.appointmenttype_cd? step.appointmenttype_cd:"-"}</Text>
                              </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" gap="6px">
                              <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                                <Image w="13px" h="13px" src="/assets/imgs/schedule-blue.png" />
                                <Text fontSize="0.75rem" fontWeight="500">{formatTime(step.appointmenttime)}</Text>
                              </Box>
                              <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                                <Image w="14px" h="13px" src="/assets/imgs/hospital-blue.png" />
                                <Text fontSize="0.75rem" className="textOverflow" w="100px" fontWeight="500">{step.hospitalname? step.hospitalname:"-"}</Text>
                              </Box>
                            </Box>
                          </Box>
                    </>}
                    cardFooterText="View appointment"
                    onClick={()=> navigate("/appointmentdetails", { state: { appointmentid: step.appointmentid }})}
                  />
                  ))}
                </SimpleGrid>
                 ) : (
                  <Box fontSize="1rem" fontWeight="700" color="#6A707D" w="100%" display="flex" justifyContent="center">No data</Box>
                )}  
              </Box>
               {/* Appointment end */}
              {/* Get insight from Ai start */}
              <Box w="100%" px="20px" py="10px" mb="20px">
                <Box
                  boxShadow="0px 8px 20px 0px #E6E7E966"
                  border="1px solid #E6E7E9"
                  borderRadius="12px"
                  p="20px"
                  overflowX="hidden"
                >
                  <Box display="flex" mb="10px" justifyContent="space-between">
                    <Text fontSize="14px" fontWeight="600">
                      Get insights on your health with assistance from{" "}
                      <Box as="span" color="rgba(13, 126, 84, 1)">
                        AI
                      </Box>
                    </Text>
                    <Box display="flex" gap="20px">
                      <Box as="button" onClick={() => handleNavigation('prev')}>
                        <Image w="6px" src="/assets/imgs/left-icon.png" />
                      </Box>
                      <Box as="button" onClick={() => handleNavigation('next')}>
                        <Image w="6px" src="/assets/imgs/right-icon.png" />
                      </Box>
                    </Box>
                  </Box>
                  <Flex gap="10px">
                  <Swiper
                    onSwiper={handleNavigation}
                    slidesPerView={2}
                    ref={swiperInstance}
                  >
                  {aiprompts &&
                      aiprompts.generated_prompts &&
                      aiprompts.generated_prompts.map((step, index) => (
                   <SwiperSlide key={index}>
                    <Box
                    // key={index}
                      as="button"
                      bg="white"
                      p="8px 10px"
                      display="flex"
                      alignItems="center"
                      w="100%"
                      border="1px solid #E6E7E9"
                      borderRadius="full"
                      boxShadow="0px 8px 20px 0px #E6E7E966"
                    >
                      <Image src="/assets/imgs/spark.png" mr="8px" />
                      <Text fontSize="12px" fontWeight="600" color="#1C4EC5" 
                      onClick={() => GetAIAnswer(step.value)}>
                         {step.value ? step.value : "-"}
                      </Text>
                    </Box>
                    </SwiperSlide>
                     ))}
                  </Swiper>
                  </Flex>
                </Box>
              </Box>
              {/* Get insight from Ai end */}
              {/* Vitals start */}
              <Box
                display="flex"
                justifyContent="space-between"
                gap="20px"
                px="20px"
                my="20px"
                w="100%"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  gap="20px"
                  w="100%"
                >
                  {data && data.vitals ? (
                    <GrayCard
                      bodyStyle={{ p: "0" }}
                      cardHeader="vitals"
                      cardHeaderIcon="/assets/imgs/heart.png"
                      cardBody={
                        <>
                          {/* <Box
                        display="flex"
                        flexDirection="row"
                        gap="20px"
                        py="10px"
                        justifyContent="space-between"
                      >
                        {data &&
                          data.vitals &&
                          data.vitals.map((step, index) => (
                            <>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="end"
                              >
                                <Box>
                                  <Box
                                    as="p"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    {step.vitalname ? step.vitalname : "-"}
                                  </Box>
                                  <Box
                                    as="p"
                                    display="inline-block"
                                    fontSize="22px"
                                    fontWeight="600"
                                  >
                                    {step.vitalvalue ? step.vitalvalue : "-"}
                                  </Box>
                                  <Box
                                    display="inline-block"
                                    as="p"
                                    fontSize="15px"
                                    pl={"5px"}
                                    fontWeight="400"
                                  >
                                    {step.vitalunit ? step.vitalunit : ""}
                                  </Box>
                                  <Box
                                    as="p"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    {step.vitaladdeddate
                                      ? step.vitaladdeddate
                                      : "-"}
                                  </Box>
                                </Box>
                                <Box display="flex" flexDirection="row" alignItems="center">
            <Image w="12px" h="auto" src="/assets/imgs/check.png" />
            <Box as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Box>
          </Box>
                              </Box>
                              <Box borderRight="1px solid #E6E7E9"></Box>
                            </>
                          ))}
                      </Box> */}
                          {/* <Wrap justify="space-between" spacing={0}>
    {data && data.vitals && data.vitals.map((step, index) => (
      <WrapItem  gap="0" key={index} flexGrow={1} minW={{ base: "calc(50% - 10px)", md: "calc(20% - 16px)" }} maxW="calc(20% - 16px)">
        <Box borderWidth="1px" p={4} width="100%">
          <Text fontSize="12px" fontWeight="500" color="#6A707D">
            {step.vitalname || "-"}
          </Text>
          <Text display="inline-block" fontSize="22px" fontWeight="600">
            {step.vitalvalue || "-"}
          </Text>
          <Text display="inline-block" fontSize="15px" pl="5px" fontWeight="400">
            {step.vitalunit || ""}
          </Text>
          <Text fontSize="12px" fontWeight="500" color="#6A707D">
            {step.vitaladdeddate || "-"}
          </Text>

        </Box>
      </WrapItem>
    // ))}
  </Wrap> */}

                          <Box width="100%" overflowX="auto">
                            <SimpleGrid minChildWidth="220px">
                              {data &&
                                data.vitals &&
                                data.vitals.map((step, index) => (
                                  <Box key={index} borderWidth="1px" p={4}>
                                    <Text
                                      fontSize="12px"
                                      fontWeight="500"
                                      color="#6A707D"
                                    >
                                      {step.vitalname || "-"}
                                    </Text>
                                    <Text
                                      display="inline-block"
                                      fontSize="22px"
                                      fontWeight="600"
                                    >
                                      {step.vitalvalue || "-"}
                                    </Text>
                                    <Text
                                      display="inline-block"
                                      fontSize="15px"
                                      pl={"5px"}
                                      fontWeight="400"
                                    >
                                      {step.vitalunit || ""}
                                    </Text>
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      justifyContent="space-between"
                                    >
                                      <Box>
                                        <Text
                                          fontSize="12px"
                                          fontWeight="500"
                                          color="#6A707D"
                                        >
                                          {formatDate(step.vitaladdeddate)}
                                          {/* {step.vitaladdeddate || "-"} */}
                                        </Text>
                                      </Box>
                                      <Box display="flex" alignItems="center">
                                  <Image w="12px" h="auto" src={
                                      step.status && step.status.toLowerCase() === "low" ? "/assets/imgs/low-yellow.png" :
                                      step.status === "High" ? "/assets/imgs/high-red.png" :
                                      step.status && step.status.toLowerCase() === "normal" ? "/assets/imgs/check.png" : ""
                                    } />
                                  <Text as="span" fontSize="12px" fontWeight="600" ml="2px"> {step.status && (step.status.toLowerCase() === "normal" ? "Ok" : step.status)}</Text>
                                </Box>
                                    </Box>
                                  </Box>
                                ))}
                            </SimpleGrid>
                          </Box>
                        </>
                      }
                      cardFooterText="View All"
                      cardFooterClickEvent={navigateToVitals}
                    />
                  ) : (
                    <GrayCard
                      bodyStyle={{ p: "0 20px" }}
                      cardHeader="vitals"
                      cardHeaderIcon="/assets/imgs/heart.png"
                      cardBody={
                        <>
                          <Box
                            display="flex"
                            flexDirection="row"
                            gap="20px"
                            py="10px"
                            justifyContent="space-between"
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="end"
                            >
                              <Box>
                                <Box
                                  as="p"
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#6A707D"
                                >
                                  Body temperature
                                </Box>
                                <Box
                                  as="p"
                                  display="inline-block"
                                  fontSize="22px"
                                  fontWeight="600"
                                >
                                  -
                                </Box>
                              </Box>
                            </Box>
                            <Box borderRight="1px solid #E6E7E9"></Box>

                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="end"
                            >
                              <Box>
                                <Box>
                                  <Box
                                    as="p"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    Body temperature
                                  </Box>
                                  <Box
                                    as="p"
                                    display="inline-block"
                                    fontSize="22px"
                                    fontWeight="600"
                                  >
                                    -
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box borderRight="1px solid #E6E7E9"></Box>

                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="end"
                            >
                              <Box>
                                <Box>
                                  <Box
                                    as="p"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    Body temperature
                                  </Box>
                                  <Box
                                    as="p"
                                    display="inline-block"
                                    fontSize="22px"
                                    fontWeight="600"
                                  >
                                    -
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box borderRight="1px solid #E6E7E9"></Box>

                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="end"
                            >
                              <Box>
                                <Box>
                                  <Box
                                    as="p"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    Body temperature
                                  </Box>
                                  <Box
                                    as="p"
                                    display="inline-block"
                                    fontSize="22px"
                                    fontWeight="600"
                                  >
                                    -
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box borderRight="1px solid #E6E7E9"></Box>

                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="end"
                            >
                              <Box>
                                <Box>
                                  <Box
                                    as="p"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    Body temperature
                                  </Box>
                                  <Box
                                    as="p"
                                    display="inline-block"
                                    fontSize="22px"
                                    fontWeight="600"
                                  >
                                    -
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box borderRight="1px solid #E6E7E9"></Box>

                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="end"
                            >
                              <Box>
                                <Box>
                                  <Box
                                    as="p"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    Body temperature
                                  </Box>
                                  <Box
                                    as="p"
                                    display="inline-block"
                                    fontSize="22px"
                                    fontWeight="600"
                                  >
                                    -
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box borderRight="1px solid #E6E7E9"></Box>
                          </Box>
                        </>
                      }
                      cardFooterText="Add Data"
                      cardFooterClickEvent={navigateToVitals}
                    />
                  )}
                </Box>
              </Box>
              {/* Vitals end */}

              {/* Investigation start */}
              <Box
                display="flex"
                justifyContent="space-between"
                gap="20px"
                px="20px"
                mb="20px"
                w="100%"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  gap="20px"
                  w="100%"
                >
                  {data && data.investigations.length > 0 ? (
                    <GrayCard
                      bodyStyle={{ p: "0" }}
                      cardHeader="investigations"
                      cardHeaderIcon="/assets/imgs/investigation.png"
                      cardBody={
                        <>
                          <Box width="100%" overflowX="auto">
                            <SimpleGrid minChildWidth="220px">
                              {data &&
                                data.investigations &&
                                data.investigations.map((step, index) => (
                                  <Box key={index} borderWidth="1px" p={4}>
                                    <Text
                                      fontSize="12px"
                                      fontWeight="500"
                                      color="#6A707D"
                                    >
                                      {step.eventtitle ? step.eventtitle : "-"}
                                    </Text>
                                    <Text
                                      display="inline-block"
                                      fontSize="22px"
                                      fontWeight="600"
                                    >
                                      {step.actualresultvalue
                                        ? step.actualresultvalue
                                        : "-"}
                                    </Text>
                                    {/* <Text display="inline-block" fontSize="15px" fontWeight="400">
                              {row.resultMeasurement}
                            </Text> */}
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      justifyContent="space-between"
                                    >
                                      <Box>
                                        <Text
                                          fontSize="12px"
                                          fontWeight="500"
                                          color="#6A707D"
                                        >
                                          {formatDate(step.verifieddate)}
                                          {/* {step.verifieddate
                                        ? step.verifieddate
                                        : "-"} */}
                                        </Text>
                                      </Box>
                                      {step.actualresultvalue && (
                                        step.status === 'low' && step.trendColor === 'red' ? (
                                          <Box display="flex" alignItems="center" transform="scaleY(-1)">
                                            <Image w="12px" h="auto" src="/assets/imgs/red-chart.png" alt="Normal" />
                                            {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                          </Box>
                                        ) : step.status === 'high' && step.trendColor === 'red' ? (
                                          <Box display="flex" alignItems="center">
                                            <Image w="12px" h="auto" src="/assets/imgs/red-chart.png" alt="Normal" />
                                            {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                          </Box>
                                        ) : step.status === 'low' && step.trendColor === 'green' ? (
                                          <Box display="flex" alignItems="center">
                                            <Image w="12px" h="auto" src="/assets/imgs/green-chart.png" alt="Normal" />
                                            {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                          </Box>
                                        ) : step.status === 'high' && step.trendColor === 'green' ? (
                                          <Box display="flex" alignItems="center" transform="scaleY(-1)">
                                            <Image w="12px" h="auto" src="/assets/imgs/green-chart.png" alt="Normal" />
                                            {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                          </Box>
                                        ) : null
                                      )}

                                    </Box>
                                  </Box>
                                ))}
                            </SimpleGrid>
                          </Box>
                        </>
                      }
                      cardFooterText="View All Reports"
                      cardFooterClickEvent={navigateToLabs}
                    />
                  ) : (
                    <GrayCard
                      bodyStyle={{ p: "0" }}
                      cardHeader="investigations"
                      cardHeaderIcon="/assets/imgs/investigation.png"
                      cardBody={
                        <>
                          <Flex
                            direction="row"
                            justify="space-around"
                            h="full"
                            w="100%"
                            flexWrap="wrap"
                          >
                            {dataEntries.map((index) => (
                              <SimpleTable NoDataHeader={index} />
                            ))}
                          </Flex>
                        </>
                      }
                      cardFooterText="Add data"
                      cardFooterClickEvent={navigateToLabs}
                    />
                  )}
                </Box>
              </Box>
              {/* Investigation end */}

              {/* Share your records start */}
              <Box w="100%" px="20px" py="10px" mb="20px"  cursor="pointer" onClick={() => navigate("/medicalrecord")}>
                <Box
                  borderRadius="10px"
                  w="100%"
                  display="flex"
                  alignItems="center"
                  bg={`url('/assets/imgs/record-bg-stripe.png'), #1C4EC5`}
                  backgroundRepeat="no-repeat"
                  backgroundSize="contain"
                  backgroundPosition="right"
                >
                  <Flex
                    w="100%"
                    justifyContent="center"
                    alignItems="center"
                    p="20px"
                  >
                    <Box
                      bg="white"
                      w="48px"
                      h="48px"
                      mr="10px"
                      borderRadius="full"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image src="/assets/imgs/record-blue.png" />
                    </Box>
                    <Box>
                      <Text fontSize="20px" fontWeight="600" color="white">
                        Share your records
                      </Text>
                      <Text fontSize="14px" fontWeight="400" color="white">
                        Sharing your records with a physician ensures
                        personalized care and informed medical decisions.
                      </Text>
                    </Box>
                    <Spacer />
                    <Box float="right">
                      <Image src="/assets/imgs/white-right-arrow.png" />
                    </Box>
                  </Flex>
                </Box>
              </Box>
              {/* Share your records end */}

              {/* Find a Doctor */}
              <Box w="100%" px="30px" py="10px" mb="20px">
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Box as="h1" fontSize="20px" fontWeight="600">
                      Find a doctor
                    </Box>
                  </Box>
                  <Box>
                    <PrimaryButton variant="grayBtn" buttonText="View all" onClick={()=>navigate("/appointments/doctors", )}/>
                  </Box>
                </Box>
                <Box w="100%" mb="20px">
                <Grid
                  templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
                  gap="20px"
                  alignItems="center"
                >
                  {specialitiesdata && specialitiesdata.map((profile, index) => (
                    <Box
                      key={index}
                      as="button"
                      minW="200px"
                      h="105px"
                      borderRadius="8px"
                      display="flex"
                      flexDirection="column"
                      bg="#FCFCFD"
                      lineHeight="normal"
                      alignItems="center"
                      justifyContent="center"
                      onClick={()=> navigate("/appointments/doctors", { state: { specialityname: profile.specialityname } })}
                      boxShadow="0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset"
                    >
                      <Image src={profile.url? profile.imagepath+profile.url:"/assets/imgs/no-image.png"} />
                      <Box as="p" color="black" mt="8px" textAlign="center" fontSize="14px" whiteSpace="nowrap" fontWeight="500">
                      {profile.specialityname}
                      </Box>
                    </Box>
                  ))}
                </Grid>
                </Box>
              </Box>

              {/* Rewards and points start */}
                <Box
                    display="flex"
                    w="100%"
                    gap="20px"
                    px="30px"
                    py="10px"
                    overflowX="hidden"
                >
                    {/* Rewards start */}
                    <Box w="100%">
                    <Box>
                        <GrayCard
                        cardHeader="Rewards"
                        cardHeaderIcon="/assets/imgs/stars.png"
                        minH="200px"
                        bodyStyle={{ p: "0" }}
                        cardBody={
                            <>
                            <Box p="20px" pb="0">
                                <Box display="flex" justifyContent="space-between">
                                <Box as="p" fontSize="28px" fontWeight="700">
                                    {rewardsData.position}
                                </Box>
                                <Image
                                    w="32px"
                                    h="32px"
                                    src={
                                      rewardsData.position === "Gold" ? "/assets/imgs/gold-badge.png" :
                                      rewardsData.position === "Bronze" ? "/assets/imgs/bronze-badge.png" :
                                      rewardsData.position === "Silver" ? "/assets/imgs/silver-badge.png" :
                                      rewardsData.position === "Platinum" ? "/assets/imgs/platinum-badge.png" :
                                      "/assets/imgs/bronze-badge.png" // Default value if rewardsData.position doesn't match any of the above
                                  }
                                />
                                </Box>

                                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                <Box>
                                <Text fontSize="12px" fontWeight="600">
                                    
                                     {
                                    rewardsData.pointtounlocknext ? (
                                      <>                      
                                       <strong>{rewardsData.totalpoint ? rewardsData.totalpoint : "-"}</strong> of <strong>{rewardsData.pointtounlocknext ? rewardsData.pointtounlocknext : "-" }</strong>{" "}
                                      points to unlock <strong>
                                      {rewardsData.position === "Bronze" ? "Silver" : 
                                       rewardsData.position === "Silver" ? "Gold" : 
                                       rewardsData.position === "Gold" ? "Platinum" : ""}
                                      </strong>
                                      </>

                                    ):(
                                      <>  
                                        <strong>{rewardsData.totalpoint ? rewardsData.totalpoint : "-"}</strong> {" "}
                                      points available 
                                      </>
                                    )
                                  }
                                </Text>
                                </Box>

                                <Box>
                              <PrimaryButton
                               btnStyle={{ h:"40px" , p:"10px" ,  fontSize:"14px", color:"#1A202C !important", opacity:"0.4", m:'0'}}
                                buttonText="Redeem coming soon"
                                isDisabled={true}
                              />
                            </Box>
                            </Box>
                            </Box>
                            </>
                        }
                        hasFooter={false}
                        simpleFooter={true}
                        footerArea={<>
                            <Box display="flex" justifyContent="space-between" flexWrap="nowrap" gap="5px" w="100%">
    {
        Array.from({ length: totalBoxes }).map((_, index) => (
            <Box
                key={index}
                as="div"
                bg={index < filledBoxes ? boxColor : emptyBox}
                w={`${100 / 33}%`}
                h={`${100 / 33}%`}
                minH="12px"
                borderRadius="2px"
            />
        ))
    }
</Box>
                        </>}
                        />
                    </Box>
                    </Box>
                    {/* Rewards end */}

                {/* Points start */}
                {/* <Box w="100%">
                  <Box>
                    <GrayCard
                      cardHeader="Points"
                      cardHeaderIcon="/assets/imgs/stars.png"
                      bodyStyle={{ p: "0" }}
                      cardBody={
                        <>
                          <Box p="20px">
                            <Box display="flex" justifyContent="space-between">
                              <Box as="p" fontSize="28px" fontWeight="700">
                              {rewardsData.availablepoint ? rewardsData.availablepoint : "-"}
                              </Box>
                            </Box>
                            <Box>
                              <Text fontSize="12px" fontWeight="600">
                                Points available
                              </Text>
                            </Box>
                             <Box mt="15px">
                              <PrimaryButton
                                sx={{ height: "40px" }}
                                p="0"
                                buttonText="Redeem coming soon"
                                isDisabled={true}
                              />
                            </Box>
                          </Box>
                        </>
                      }
                      hasFooter={false}
                    />
                  </Box>
                </Box> */}
                {/* Points end */}


                 {/* AI Tokens start */}
           <Box
                    display="flex"
                    w="100%"
                    gap="20px"
                    overflowX="hidden"
                >
                    {/* Rewards start */}
                    <Box w="100%">
                    <Box>
                        <GrayCard
                        hasHeader={true}
                        cardHeader="AI Tokens"
                        cardHeaderIcon="/assets/imgs/stars.png"
                        minH="180px"
                        bodyStyle={{ p: "0", }}
                        cardBody={
                            <>
                            <Box p="20px" pb="0">
                                <Box display="flex" justifyContent="space-between">
                                <Box as="p" fontSize="28px" fontWeight="700">
                                {tokensdata && tokensdata.planname?tokensdata.planname:"-"}
                                </Box>
                               
                                </Box>
                                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                <Box>
                                <Text fontSize="12px" fontWeight="600">                   
                                       <strong>{tokensdata && tokensdata.remainingtokens ? displayMillion_ThoudandValues(tokensdata.remainingtokens):"0"}</strong> tokens available from <strong>{tokensdata && tokensdata.totaltokens ? displayMillion_ThoudandValues(tokensdata.totaltokens):"0"}</strong>{" "}
                                </Text>
                                </Box>
                                <Box h={tokensdata &&  tokensdata.planname == "Premium"  && "40px" }>
                                  {tokensdata &&  tokensdata.planname != "Premium" &&
                                   <PrimaryButton
                                   btnStyle={{ h:"40px" , p:"10px" ,  fontSize:"14px",  m:'0'}}
                                    buttonText="Upgrade Plan"
                                    onClick={redirectToSubscription}
                                    isDisabled={false}
                                  />
                                  }
                             
                            </Box>
                            </Box>
                                
                            </Box>
                            </>
                        }
                        hasFooter={false}
                        simpleFooter={true}
                        footerArea={<>
                            <Box display="flex" justifyContent="space-between" flexWrap="nowrap" gap="5px" w="100%">
                              {
                                  Array.from({ length: totalBoxes }).map((_, index) => (
                                      <Box
                                          key={index}
                                          as="div"
                                          bg={index < AitokensfilledBoxes ? aiboxColor : emptyBox}
                                          w={`${100 / 33}%`}
                                          h={`${100 / 33}%`}
                                          minH="12px"
                                          borderRadius="2px"
                                      />
                                  ))
                              }
                          </Box>

                        </>}
                        />
                    </Box>
                    </Box>
                    {/* Rewards end */}

                {/* Points start */}
                {/* <Box w="100%">
                  <Box>
                    <GrayCard
                      hasHeader={true}
                      simpleFooter={true}
                      minH="180px"
                      cardHeader="Tokens"
                      cardHeaderIcon="/assets/imgs/stars.png"
                      bodyStyle={{ p: "0" }}
                      cardBody={
                        <>
                          <Box p="20px">
                            <Box display="flex" justifyContent="space-between">
                              <Box as="p" fontSize="28px" fontWeight="700">
                              {tokensdata && tokensdata.remainingtokens ? tokensdata.remainingtokens:"0"}
                              </Box>
                            </Box>
                            <Box>
                              <Text fontSize="12px" fontWeight="600">
                                Tokens available
                              </Text>
                            </Box>
                          </Box>
                        </>
                      }
                      hasFooter={false}
                      // footerArea={
                      //   <>
                      //     <PrimaryButton
                      //       sx={{ height: "40px" }}
                      //       p="0"
                      //       buttonText="Redeem coming soon"
                      //       isDisabled={true}
                      //     />
                      //   </>
                      // }
                    />
                  </Box>
                </Box> */}
                {/* Points end */}
              </Box>
            {/* AI Tokens end */}
                </Box>
            {/* Rewards and points end */}

          


              {/* Update profile start */}
              <Box display="flex" w="100%" gap="20px" px="30px" py="10px">
                <GrayCard
                  cardHeader="Update Profile"
                  withIcon={false}
                  noIcon={
                    <>
                      {/* <Box display="flex" gap="10px" alignItems="center">
                        {Array.from({ length: 5 }).map((_, index) => (
                          <Box
                            key={index}
                            as="div"
                            bg={index < 3 ? greenBox : emptyBox}
                            w="12px"
                            h="12px"
                            borderRadius="2px"
                          ></Box>
                        ))}
                        <Box as="span" fontSize="12px" fontWeight="600">
                          75%
                        </Box>
                      </Box> */}
                    </>
                  }
                  bodyStyle={{ p: "0" }}
                  cardBody={
                    <>
                      <Box>
                        <Box
                          as="button"
                          textAlign="left"
                          p="20px"
                          display="flex"
                          flexDir="row"
                          gap="10px"
                          w="100%"
                          borderBottom="1px solid #D2DCF3"
                          onClick={() => navigate("/profile")}
                        >
                          <Box
                            display="flex"
                            w="40px"
                            h="40px"
                            alignItems="center"
                            justifyContent="center"
                            border="1px solid #D2DCF3"
                            p="6px"
                            borderRadius="10px"
                          >
                            <Image src="/assets/imgs/account_circle.png" />
                          </Box>
                          <Box
                            display="flex"
                            flexDir="row"
                            w="100%"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box>
                              <Box as="p" fontSize="14px" fontWeight="600">
                                Personal Details
                              </Box>
                              <Box as="p" fontSize="12px" fontWeight="400">
                                Update your latest information
                              </Box>
                            </Box>
                            <Box>
                              <Image src="/assets/imgs/right-icon.png" />
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          as="button"
                          textAlign="left"
                          p="20px"
                          display="flex"
                          flexDir="row"
                          gap="10px"
                          w="100%"
                          borderBottom="1px solid #D2DCF3"
                          onClick={() => navigate("/reports")}
                        >
                          <Box
                            display="flex"
                            w="40px"
                            h="40px"
                            alignItems="center"
                            justifyContent="center"
                            border="1px solid #D2DCF3"
                            p="6px"
                            borderRadius="10px"
                          >
                            <Image src="/assets/imgs/diagnosis-blue.png" />
                          </Box>
                          <Box
                            display="flex"
                            flexDir="row"
                            w="100%"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box>
                              <Box as="p" fontSize="14px" fontWeight="600">
                                Health Report
                              </Box>
                              <Box as="p" fontSize="12px" fontWeight="400">
                                {/* It's Been 40 Days Since Your Last Report */}
                                {showUpdate1 && "It's been updated"} {reportRecentActivity?reportRecentActivity:"-"}
                              </Box>
                            </Box>
                            <Box>
                              <Image src="/assets/imgs/right-icon.png" />
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          as="button"
                          textAlign="left"
                          p="20px"
                          display="flex"
                          flexDir="row"
                          gap="10px"
                          w="100%"
                          borderBottom="1px solid #D2DCF3"
                          onClick={() => navigate("/family")}
                        >
                          <Box
                            display="flex"
                            w="40px"
                            h="40px"
                            alignItems="center"
                            justifyContent="center"
                            border="1px solid #D2DCF3"
                            p="6px"
                            borderRadius="10px"
                          >
                            <Image src="/assets/imgs/family-setup.png" />
                          </Box>
                          <Box
                            display="flex"
                            flexDir="row"
                            w="100%"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box>
                              <Box as="p" fontSize="14px" fontWeight="600">
                                Family Setup
                              </Box>
                              <Box as="p" fontSize="12px" fontWeight="400">
                                Add additional family members
                              </Box>
                            </Box>
                            <Box>
                              <Image src="/assets/imgs/right-icon.png" />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  }
                  hasFooter={false}
                />
              </Box>
              {/* Update profile end */}
            </Box>
            {/* Left area end */}

            {/* Right area start */}
            <Box w="25%" h="100vh">
              {/* Recent activity start */}
              <Box w="100%" px={{lg: "20px", xl: "30px"}} py="10px" mb="20px" borderBottom="1px solid #E6E7E9">
                <Box as="h1" fontSize="20px" fontWeight="600" my="20px">
                  Recent activity
                </Box>
                <VStack align="stretch" maxH="380px" overflow="hidden auto">
                  
                    <Stack direction="column" align="center" w="100%">
                      {/* with button timeline start */}
                      {recentactivity && recentactivity.map((step, index)=> (
                      <Box display="flex" key={index} flexDir="column" alignItems="center" h="auto" mb="10px" gap="5px" w="100%">
                        <Box display="flex" justifyContent="space-between"  alignItems="flex-start" w="100%">
                          <Box display="flex" alignItems="center" gap="5px" w="100%">
                            {/* <Circle
                              size="10px"
                              bg="white"
                              border="2px solid #898D97"
                              borderRadius="full"
                              color="black"
                            /> */}
                            <Box boxSize="1.5rem" display="flex" justifyContent="center" alignItems="center" border="1px solid #898D97" borderRadius="full">
                              <Image w="0.75rem" 
                                filter={step.title == "Report" ? "brightness(0) saturate(100%) invert(50%) sepia(1%) saturate(0%) hue-rotate(226deg) brightness(95%) contrast(88%)" : ""}
                                src={mapActivityToIcon(step.description)}
                              />
                            </Box>
                            <Text fontSize="12px" fontWeight="500" color="#384052">{step.title? step.title:"-"}</Text>
                          </Box>

                          <Box display="flex" alignItems="center" gap="5px" whiteSpace="nowrap">
                          <Circle
                              size="5px"
                              bg="blue"
                              border="1px solid gray"
                              borderRadius="full"
                              color="blue"
                            />
                            <Text fontSize="10px" fontWeight="400" color="#384052">{step.timeDifference}</Text>
                          </Box>
                        </Box>
                        <Box display="flex" w="100%">
                          <Center h='50px' ml="12px" borderLeft="1px dotted #CDCFD4">
                          </Center>
                          <Text fontSize="14px" pl="1rem" fontWeight="500">{step.description? step.description:"-"}</Text>
                          </Box>
                          <Box display="flex" justifyContent="flex-start" w="100%" pl="13px">
                          {/* <Box
                            as="button"
                            bg="white"
                            p="8px 10px"
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            w="auto"
                            border="1px solid #E6E7E9"
                            borderRadius="full"
                            boxShadow="0px 8px 20px 0px #E6E7E966"
                          >
                            <Image src="/assets/imgs/spark.png" mr="8px" />
                            <Text fontSize="12px" fontWeight="600" color="#1C4EC5">
                              How often should I update my health data?
                            </Text>
                          </Box> */}
                        </Box>
                      </Box>
                      ))}
                      {/* with button timeline start */}
                    </Stack>
                  <Box w="100%">
                    <PrimaryButton onClick={()=> navigate("/home/recentactivity")} buttonText="View all" variant="grayBtnFull" btnStyle={{color: "#1C4EC5"}} />
                  </Box>
                </VStack>
              </Box>
              {/* Recent activity end */}

              {/* My family start */}
              <Box w="100%" px={{lg: "20px", xl: "30px"}} mb="20px">
                <Box as="h1" fontSize="20px" fontWeight="600" my="10px">
                  My family
                </Box>
                <Box display="flex" flexDir="column" gap="20px" maxH="calc(100vh - 200px)" overflow="auto" pr="15px">
                {familydata && familydata.map((user, index)=> (
                  <ReportViewCard
                  key={index}
                    deleteBtn={false} 
                    cardBtn={false} 
                    cardHeight={{lg: "125px", xl: "150px"}}
                    cardHeader={false}
                    cardBody={<>
                      <Box display="flex" gap="10px">
                      <Image
                        boxSize={{ lg: "40px", xl: "48px" }}
                        borderRadius="full"
                        src={
                          user.image
                            ? user.image.startsWith('http')
                              ? user.image
                              : user.imagepath
                              ? user.imagepath + user.image
                              : "/assets/imgs/no-image-icon.png"
                            : "/assets/imgs/no-image-icon.png"
                        }
                      />                       
                       <Box>
                          <Text fontSize={{lg: "12px", xl: "16px"}} lineHeight="normal" fontWeight="600" color="#061027">{user.fullname} {user.isprimaryuser === 1 ? "(You)" : null}</Text>
                          <Text fontSize="12px" fontWeight="500" color="#384052">{user.relation ? user.relation:"-"}</Text>
                        </Box>
                      </Box>
                    </>}
                    hasFooter={true}
                    cardFooterText={user.isselecteduser === 1 ? "Selected":"Switch user"}
                    disableBtn={user.isselecteduser === 1 ? true : false}
                    cardFooterClickEvent={()=>handleswitchuser(user.activeuserid, user.isprimaryuser, user.isselecteduser)}
                  />
                  ))}
                </Box>
              </Box>
              {/* My family end */}
            </Box>

            {/* Right area end */}
          </Flex>
          
      {/* <Box as="button" zIndex="1399" borderRadius="full" display="flex" alignItems="center" justifyContent="center" gap="10px" w="160px" h="64px" pos="fixed" top="60%" left="85%" backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)">
        <Image src="/assets/imgs/white-spark.png" />
        <Text fontSize="18px" fontWeight="600" color="white">AI Doctor</Text>
      </Box> */}
          <Footer />
          {/* Main content end */}
        </Box>
      </Box>
    ) : (
      //Mobile responsive start
      <Box bg="#fcfcfd" h='calc(100vh - 130px)' overflow="hidden auto">
        <MobileNavbar/>

        <Box bg="#061027">
          <Box bg={`url("/assets/imgs/square-bg.png")`} w="100%" h="100px" pos="absolute"></Box>
          <Flex w="100%" justifyContent="space-between" p="1rem" alignItems="center">
            <Box as="button" zIndex="99" onClick={()=> switchUsers.current.openRight()} display="flex" alignItems="center" gap='0.5rem'>
              <Text color="white" fontSize="1.75rem" fontWeight="600">{firstName ? firstName : ""}  {lastName ? lastName : ""}</Text>
              <Image boxSize="20px" src="/assets/imgs/double-arrow.png" />
            </Box>
            <Box as="button" onClick={showNotifications} zIndex='99' display="flex" alignItems="center" justifyContent="center" gap="10px" w="3rem" h="1.75rem" bg="#333B4E" border="1px solid #EAEDF5" borderRadius="full">
              <Image src="/assets/imgs/notifications.png" /> 
            </Box>
          </Flex>
          <Box
              position="relative"
              // bg="#0c123b"
              // rounded="full"
              w="100%"
              h="320px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              // top="26%"
              // left="10%"
              bg={`url('/assets/svgs/mobile-home-nav.svg'), #061027`} bgPos="center" bgRepeat="no-repeat"
            >
              <Box as="button" onClick={() => navigate("/medications")} position="absolute" top="12%" left="50%" transform="translateX(-50%)" display="flex" justifyContent="center" flexDir="column" alignItems="center">
                <Image w="1rem" h="auto" src="/assets/imgs/medication-white.png" alt="Clipboard List Icon" mb="2" />
                <Text color="white" fontSize="sm">Medications</Text>
              </Box>
              <Box as="button" onClick={() => navigate("labs")} position="absolute" top="33%" left="18%" display="flex" justifyContent="center" flexDir="column" alignItems="center">
                <Image w="1rem" h="auto" src="/assets/imgs/biotech-white.png" alt="Flask Round Icon" mb="2" />
                <Text color="white" fontSize="sm">Labs</Text>
              </Box>
              <Box as="button" onClick={() => navigate("/myhealth/imaging")} position="absolute" top="33%" right="18%" display="flex" justifyContent="center" flexDir="column" alignItems="center">
                <Image w="1rem" h="auto" src="/assets/imgs/doc-white.png" alt="File Icon" mb="2" />
                <Text color="white" fontSize="sm">Reports</Text>
              </Box>
              <Box as="button" onClick={() => navigate("/chat")} position="absolute" bottom="5" left="50%" transform="translateX(-50%)" display="flex" justifyContent="center" flexDir="column" alignItems="center">
                <Image w="1rem" h="auto" src="/assets/imgs/messages.png" alt="Reply Icon" mb="2" />
                <Text color="white" fontSize="sm">Messages</Text>
              </Box>
              <Box as="button" onClick={() => navigate("/home/treatmentteam")} position="absolute" bottom="15%" w="20%" left="13%" display="flex" justifyContent="center" flexDir="column" alignItems="center">
                <Image w="1rem" h="auto" src="/assets/imgs/treatment-team.png" alt="Users Icon" mb="2" />
                <Text color="white" fontSize="sm" lineHeight="normal" textAlign="center">Treatment team</Text>
              </Box>
              <Box as="button" onClick={() => navigate("/all-appoinments")} position="absolute" bottom="20%" right="20%" display="flex" justifyContent="center" flexDir="column" alignItems="center">
                <Image w="1rem" h="auto" src="/assets/imgs/visits.png" alt="Briefcase Medical Icon" mb="2" />
                <Text color="white" fontSize="sm">Visits</Text>
              </Box>
              <Image zIndex="10" pos="relative" boxSize="85px" borderRadius="full" top="8.5%" left="0.5%" 
                onClick={()=>navigate('/profile')}
                cursor='pointer'
                src={
                  data && data.patientimage
                    ? data.patientimage
                    : "/assets/imgs/no-image-icon.png"
                }
              />
          </Box>
        </Box>

        {/* Ai doctor button start */}
        <Box display="flex" onClick={fetchAiDoctorPromptsForMobile} justifyContent="space-between" alignItems="center" p="1.25rem 1.5rem" backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)">
          <Box>
            <Text fontSize="1.25rem" fontWeight="500" color='white'>Aira - AI</Text>
            <Text fontSize="0.65rem" color='white'>Chat with your AI health guide</Text>
          </Box>
          
          <Image src="/assets/svgs/arrow-right.svg" />
        </Box>
        {/* Ai doctor button end */}

        {/* Next steps to explore start */}
        <Box w="100%" px="30px" py="10px" >
          <Box display="flex" flexDirection="column">
            <Box as="h1" fontSize="20px" fontWeight="600" my="20px">
              Next steps to explore
            </Box>
            <Flex gap="20px" justifyContent="space-between" flexDir="column">
              <Box w="100%" cursor="pointer" boxShadow="0px 8px 20px 0px #E6E7E966" onClick={navigateToLabsupload}>
                <Flex
                  p="16px 20px"
                  borderRadius="15px 15px 0 0"
                  h="70px"
                  gap="8px"
                  background="radial-gradient(circle, rgba(59,109,228,1) 0%, rgba(28,78,197,1) 100%)"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text fontSize="16px" fontWeight="600" color="white" lineHeight="normal">
                    Upload latest lab reports
                  </Text>
                  <Box
                    w="64px"
                    h="40px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border="4px solid #6C91EA"
                    borderRadius="full"
                    bg="white"
                  >
                    <Image src="/assets/imgs/biotech-home.png" />
                  </Box>
                </Flex>

                <Box
                  as="button"
                  w="100%"
                  border="1px solid #E6E7E9"
                  borderRadius="0 0 13px 13px"
                  bg="white"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p="12px"
                >
                  <Text fontSize="12px" fontWeight="500">
                  {showUpdate1 && "Updated"} {reportRecentActivity?reportRecentActivity:"-"}
                  </Text>
                  <Image src="/assets/imgs/right-icon.png" />
                </Box>
              </Box>

              <Box w="100%" cursor="pointer" boxShadow="0px 8px 20px 0px #E6E7E966" onClick={() => navigate("/home/treatmentteam")}>
                <Flex
                  p="16px 20px"
                  borderRadius="15px 15px 0 0"
                  h="70px"
                  gap="8px"
                  alignItems="center"
                  justifyContent="space-between"
                  background="radial-gradient(circle, rgba(109,83,226,1) 0%, rgba(76,50,196,1) 100%)"
                >
                  <Text fontSize="16px" fontWeight="600" color="white" lineHeight="normal">
                    View my treatment team
                  </Text>
                  <Box
                    w="64px"
                    h="40px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border="4px solid #8C76F5"
                    borderRadius="full"
                    bg="white"
                  >
                    <Image src="/assets/imgs/treatment-violent.png" />
                  </Box>
                </Flex>
                <Box
                  as="button"
                  w="100%"
                  border="1px solid #E6E7E9"
                  borderRadius="0 0 13px 13px"
                  bg="white"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p="12px"
                >
                  <Text fontSize="12px" fontWeight="500">
                  {showUpdate2 && "Updated"}  {treatmentTeamRecentActivity?treatmentTeamRecentActivity:"-"}
                  </Text>
                  <Image src="/assets/imgs/right-icon.png" />
                </Box>
              </Box>

              <Box w="100%" cursor="pointer" boxShadow="0px 8px 20px 0px #E6E7E966" onClick={() => navigate("/medications")}>
                <Flex
                  p="16px 20px"
                  borderRadius="15px 15px 0 0"
                  h="70px"
                  gap="8px"
                  alignItems="center"
                  justifyContent="space-between"
                  background="radial-gradient(circle, rgba(204,65,165,1) 0%, rgba(177,30,136,1) 100%)"
                >
                  <Text fontSize="16px" fontWeight="600" color="white" lineHeight="normal">
                    Add new medications
                  </Text>
                  <Box
                    w="64px"
                    h="40px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border="4px solid #E76DC5"
                    borderRadius="full"
                    bg="white"
                  >
                    <Image src="/assets/imgs/medication-pink.png" />
                  </Box>
                </Flex>
                <Box
                  as="button"
                  w="100%"
                  border="1px solid #E6E7E9"
                  borderRadius="0 0 13px 13px"
                  bg="white"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p="12px"
                >
                  <Text fontSize="12px" fontWeight="500">
                  {showUpdate3 && "Updated"} {medicationsRecentActivity?medicationsRecentActivity:"-"}
                  </Text>
                  <Image src="/assets/imgs/right-icon.png" />
                </Box>
              </Box>
            </Flex>
          </Box>
        </Box>
        {/* Next steps to explore end */}

        {/* Appointment start */}
        <Box w="100%" px="20px" py="10px" mb="20px">
          <Box display="flex" flexDirection="row" mb="1rem" alignItems="center" justifyContent="space-between">
            <Box>
              <Box as="h1" fontSize="20px" fontWeight="600">Appointments</Box>
            </Box>
            <Box as="button" onClick={()=>navigate("/all-appoinments")}>
              View all
            </Box>
          </Box>
          {appointmentdata && appointmentdata != null ? (
          <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} spacing="1rem">
            {appointmentdata && appointmentdata.slice(0,3).map((step, index) => (

            <DoctorCard 
              bodyStyle={{p:"20px 20px 0 20px"}}
              cardBody={
              <>
                <Box
                    py="10px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      <Image
                        w="40px"
                        h="40px"
                        borderRadius="full"

                        src={step.doctorimage?step.imagepath+step.doctorimage:"/assets/imgs/no-image-icon.png"} 

                        mr="3"
                      />
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Text
                          fontSize="12px"
                          fontWeight="500"
                          color="#384052"
                        >

                          {step.specality? step.specality:"-"}
                        </Text>
                        <Text className="textOverflow" w="124px" fontSize="14px" fontWeight="600">
                        {step.doctorname? step.doctorname:"-"}
                        </Text>
                      </Box>
                    </Box>
                    <Text fontSize="0.625rem" fontWeight="600" color={step.appointmentstatus === 1? "#FC8935" : "#384052"} borderColor={step.appointmentstatus === 1? "#FC89351A" : "#f5f5f6"} border="1px solid" p="0.35rem 0.5rem" borderRadius="full" bg={step.appointmentstatus === 1? "#fff3eb" : "#f5f5f6"}>{step.appointmentstatus === 1? "Upcoming": step.appointmentstatus === 2?"Cancelled": step.appointmentstatus === 3 ? "Completed": step.appointmentstatus === 0 ? "Pending" :""}</Text>


                  </Box>
                    <Divider />
                    <Box display="flex" flexDirection="row" justifyContent="start" py="15px" gap="15px">
                      <Box display="flex" flexDirection="column" gap="6px">
                        <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                          <Image w="12px" h="13" src="/assets/imgs/calendar-blue.png" />
                          <Text fontSize="0.75rem" className="textOverflow" w="70px" fontWeight="500">{formatDate(step.appointmentdate)}</Text>
                        </Box>
                        <Box display="flex" alignItems="center" gap="0.5rem">
                          <Image w="13px" h="10px" src={step.appointmenttype_cd === "clinical visit"?"/assets/svgs/hospital.svg": "/assets/imgs/images-blue.png" }/>
                          <Text fontSize="0.75rem" className="textOverflow" w="70px" fontWeight="500">{step.appointmenttype_cd? step.appointmenttype_cd:"-"}</Text>
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="column" gap="6px">
                        <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                          <Image w="13px" h="13px" src="/assets/imgs/schedule-blue.png" />
                          <Text fontSize="0.75rem" fontWeight="500">{formatTime(step.appointmenttime)}</Text>
                        </Box>
                        <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                          <Image w="14px" h="13px" src="/assets/imgs/hospital-blue.png" />
                          <Text fontSize="0.75rem" className="textOverflow" w="100px" fontWeight="500">{step.hospitalname? step.hospitalname:"-"}</Text>
                        </Box>
                      </Box>
                    </Box>
              </>}
              cardFooterText="View appointment"
              onClick={()=> navigate("/appointmentdetails", { state: { appointmentid: step.appointmentid }})}
            />
            ))}
          </SimpleGrid>
            ) : (
            <Box fontSize="1rem" fontWeight="700" color="#6A707D" w="100%" display="flex" justifyContent="center">No data</Box>
          )}  
        </Box>
        {/* Appointment end */}

        {/* Get Ai insight start */}
        <Box w="100%" px="20px" py="10px" mb="20px">
          <Box>
            <Box display="flex" mb="10px" justifyContent="space-between">
              <Text as="h1" fontSize="20px" fontWeight="600">
                Get insights on your health with assistance from{" "}
                <Box as="span" color="rgba(13, 126, 84, 1)">
                  AI
                </Box>
              </Text>
            </Box>
            <Flex gap="10px" flexDir="column">
            {/* <Swiper
              onSwiper={handleNavigation}
              slidesPerView={2}
              ref={swiperInstance}
            > */}
            {aiprompts &&
                aiprompts.generated_prompts &&
                aiprompts.generated_prompts.map((step, index) => (
            // <SwiperSlide key={index}>
            <Box key={index}>
              <Box
                as="button"
                bg="white"
                p="8px 10px"
                display="flex"
                alignItems="center"
                w="100%"
                border="1px solid #E6E7E9"
                borderRadius="full"
                boxShadow="0px 8px 20px 0px #E6E7E966"
              >
                <Image src="/assets/imgs/spark.png" mr="8px" />
                <Text fontSize="12px" fontWeight="600" color="#1C4EC5" 
                onClick={() => GetAIAnswer(step.value)}>
                  {step.value ? step.value : "-"}
                </Text>
              </Box>
              </Box>
              ))}
            {/* </SwiperSlide> */}
            {/* </Swiper> */}
            </Flex>
          </Box>
        </Box>
        {/* Get Ai insight end */}

        {/* Vitals start */}
        <Box
          display="flex"
          justifyContent="space-between"
          gap="20px"
          px="20px"
          my="20px"
          w="100%"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="20px"
            w="100%"
          >
            {data && data.vitals ? (
              <GrayCard
                bodyStyle={{ p: "0" }}
                cardHeader="vitals"
                cardHeaderIcon="/assets/imgs/heart.png"
                cardBody={
                  <>
                    {/* <Box
                  display="flex"
                  flexDirection="row"
                  gap="20px"
                  py="10px"
                  justifyContent="space-between"
                >
                  {data &&
                    data.vitals &&
                    data.vitals.map((step, index) => (
                      <>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="end"
                        >
                          <Box>
                            <Box
                              as="p"
                              fontSize="12px"
                              fontWeight="500"
                              color="#6A707D"
                            >
                              {step.vitalname ? step.vitalname : "-"}
                            </Box>
                            <Box
                              as="p"
                              display="inline-block"
                              fontSize="22px"
                              fontWeight="600"
                            >
                              {step.vitalvalue ? step.vitalvalue : "-"}
                            </Box>
                            <Box
                              display="inline-block"
                              as="p"
                              fontSize="15px"
                              pl={"5px"}
                              fontWeight="400"
                            >
                              {step.vitalunit ? step.vitalunit : ""}
                            </Box>
                            <Box
                              as="p"
                              fontSize="12px"
                              fontWeight="500"
                              color="#6A707D"
                            >
                              {step.vitaladdeddate
                                ? step.vitaladdeddate
                                : "-"}
                            </Box>
                          </Box>
                          <Box display="flex" flexDirection="row" alignItems="center">
      <Image w="12px" h="auto" src="/assets/imgs/check.png" />
      <Box as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Box>
    </Box>
                        </Box>
                        <Box borderRight="1px solid #E6E7E9"></Box>
                      </>
                    ))}
                </Box> */}
                    {/* <Wrap justify="space-between" spacing={0}>
{data && data.vitals && data.vitals.map((step, index) => (
<WrapItem  gap="0" key={index} flexGrow={1} minW={{ base: "calc(50% - 10px)", md: "calc(20% - 16px)" }} maxW="calc(20% - 16px)">
  <Box borderWidth="1px" p={4} width="100%">
    <Text fontSize="12px" fontWeight="500" color="#6A707D">
      {step.vitalname || "-"}
    </Text>
    <Text display="inline-block" fontSize="22px" fontWeight="600">
      {step.vitalvalue || "-"}
    </Text>
    <Text display="inline-block" fontSize="15px" pl="5px" fontWeight="400">
      {step.vitalunit || ""}
    </Text>
    <Text fontSize="12px" fontWeight="500" color="#6A707D">
      {step.vitaladdeddate || "-"}
    </Text>

  </Box>
</WrapItem>
// ))}
</Wrap> */}

                    <Box width="100%" overflowX="auto">
                      <SimpleGrid minChildWidth="220px">
                        {data &&
                          data.vitals &&
                          data.vitals.map((step, index) => (
                            <Box key={index} borderWidth="1px" p={4}>
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#6A707D"
                              >
                                {step.vitalname || "-"}
                              </Text>
                              <Text
                                display="inline-block"
                                fontSize="22px"
                                fontWeight="600"
                              >
                                {step.vitalvalue || "-"}
                              </Text>
                              <Text
                                display="inline-block"
                                fontSize="15px"
                                pl={"5px"}
                                fontWeight="400"
                              >
                                {step.vitalunit || ""}
                              </Text>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                              >
                                <Box>
                                  <Text
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    {formatDate(step.vitaladdeddate)}
                                    {/* {step.vitaladdeddate || "-"} */}
                                  </Text>
                                </Box>
                                <Box display="flex" alignItems="center">
                            <Image w="12px" h="auto" src={
                                step.status && step.status.toLowerCase() === "low" ? "/assets/imgs/low-yellow.png" :
                                step.status === "High" ? "/assets/imgs/high-red.png" :
                                step.status && step.status.toLowerCase() === "normal" ? "/assets/imgs/check.png" : ""
                              } />
                            <Text as="span" fontSize="12px" fontWeight="600" ml="2px"> {step.status && (step.status.toLowerCase() === "normal" ? "Ok" : step.status)}</Text>
                          </Box>
                              </Box>
                            </Box>
                          ))}
                      </SimpleGrid>
                    </Box>
                  </>
                }
                cardFooterText="View All"
                cardFooterClickEvent={navigateToVitals}
              />
            ) : (
              <GrayCard
                bodyStyle={{ p: "0 20px" }}
                cardHeader="vitals"
                cardHeaderIcon="/assets/imgs/heart.png"
                cardBody={
                  <>
                    <Box
                      display="flex"
                      flexDirection="row"
                      gap="20px"
                      py="10px"
                      justifyContent="space-between"
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="end"
                      >
                        <Box>
                          <Box
                            as="p"
                            fontSize="12px"
                            fontWeight="500"
                            color="#6A707D"
                          >
                            Body temperature
                          </Box>
                          <Box
                            as="p"
                            display="inline-block"
                            fontSize="22px"
                            fontWeight="600"
                          >
                            -
                          </Box>
                        </Box>
                      </Box>
                      <Box borderRight="1px solid #E6E7E9"></Box>

                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="end"
                      >
                        <Box>
                          <Box>
                            <Box
                              as="p"
                              fontSize="12px"
                              fontWeight="500"
                              color="#6A707D"
                            >
                              Body temperature
                            </Box>
                            <Box
                              as="p"
                              display="inline-block"
                              fontSize="22px"
                              fontWeight="600"
                            >
                              -
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box borderRight="1px solid #E6E7E9"></Box>

                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="end"
                      >
                        <Box>
                          <Box>
                            <Box
                              as="p"
                              fontSize="12px"
                              fontWeight="500"
                              color="#6A707D"
                            >
                              Body temperature
                            </Box>
                            <Box
                              as="p"
                              display="inline-block"
                              fontSize="22px"
                              fontWeight="600"
                            >
                              -
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box borderRight="1px solid #E6E7E9"></Box>

                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="end"
                      >
                        <Box>
                          <Box>
                            <Box
                              as="p"
                              fontSize="12px"
                              fontWeight="500"
                              color="#6A707D"
                            >
                              Body temperature
                            </Box>
                            <Box
                              as="p"
                              display="inline-block"
                              fontSize="22px"
                              fontWeight="600"
                            >
                              -
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box borderRight="1px solid #E6E7E9"></Box>

                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="end"
                      >
                        <Box>
                          <Box>
                            <Box
                              as="p"
                              fontSize="12px"
                              fontWeight="500"
                              color="#6A707D"
                            >
                              Body temperature
                            </Box>
                            <Box
                              as="p"
                              display="inline-block"
                              fontSize="22px"
                              fontWeight="600"
                            >
                              -
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box borderRight="1px solid #E6E7E9"></Box>

                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="end"
                      >
                        <Box>
                          <Box>
                            <Box
                              as="p"
                              fontSize="12px"
                              fontWeight="500"
                              color="#6A707D"
                            >
                              Body temperature
                            </Box>
                            <Box
                              as="p"
                              display="inline-block"
                              fontSize="22px"
                              fontWeight="600"
                            >
                              -
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box borderRight="1px solid #E6E7E9"></Box>
                    </Box>
                  </>
                }
                cardFooterText="Add Data"
                cardFooterClickEvent={navigateToVitals}
              />
            )}
          </Box>
        </Box>
        {/* Vitals end */}

        {/* Investigation start */}
        <Box
          display="flex"
          justifyContent="space-between"
          gap="20px"
          px="20px"
          mb="20px"
          w="100%"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="20px"
            w="100%"
          >
            {data && data.investigations.length > 0 ? (
              <GrayCard
                bodyStyle={{ p: "0" }}
                cardHeader="investigations"
                cardHeaderIcon="/assets/imgs/investigation.png"
                cardBody={
                  <>
                    <Box width="100%" overflowX="auto">
                      <SimpleGrid minChildWidth="220px">
                        {data &&
                          data.investigations &&
                          data.investigations.map((step, index) => (
                            <Box key={index} borderWidth="1px" p={4}>
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#6A707D"
                              >
                                {step.eventtitle ? step.eventtitle : "-"}
                              </Text>
                              <Text
                                display="inline-block"
                                fontSize="22px"
                                fontWeight="600"
                              >
                                {step.actualresultvalue
                                  ? step.actualresultvalue
                                  : "-"}
                              </Text>
                              {/* <Text display="inline-block" fontSize="15px" fontWeight="400">
                        {row.resultMeasurement}
                      </Text> */}
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                              >
                                <Box>
                                  <Text
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    {formatDate(step.verifieddate)}
                                    {/* {step.verifieddate
                                  ? step.verifieddate
                                  : "-"} */}
                                  </Text>
                                </Box>
                                {step.status === 'low' && step.trendColor === 'red' ? (
                                <Box display="flex" alignItems="center" transform="scaleY(-1)">
                                  <Image w="12px" h="auto" src="/assets/imgs/red-chart.png" alt="Normal" />
                                  {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                </Box>
                              ) : step.status === 'high' && step.trendColor === 'red' ? (
                                <Box display="flex" alignItems="center">
                                  <Image w="12px" h="auto" src="/assets/imgs/red-chart.png" alt="Normal" />
                                  {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                </Box>
                              ) : step.status === 'low' && step.trendColor === 'green' ? (
                                <Box display="flex" alignItems="center">
                                  <Image w="12px" h="auto" src="/assets/imgs/green-chart.png" alt="Normal" />
                                  {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                </Box>
                              ) : step.status === 'high' && step.trendColor === 'green' ? (
                                <Box display="flex" alignItems="center" transform="scaleY(-1)">
                                  <Image w="12px" h="auto" src="/assets/imgs/green-chart.png" alt="Normal" />
                                  {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                </Box>
                              ) : null}
                              </Box>
                            </Box>
                          ))}
                      </SimpleGrid>
                    </Box>
                  </>
                }
                cardFooterText="View All Reports"
                cardFooterClickEvent={navigateToLabs}
              />
            ) : (
              <GrayCard
                bodyStyle={{ p: "0" }}
                cardHeader="investigations"
                cardHeaderIcon="/assets/imgs/investigation.png"
                cardBody={
                  <>
                    <Flex
                      direction="row"
                      justify="space-around"
                      h="full"
                      w="100%"
                      flexWrap="wrap"
                    >
                      {dataEntries.map((index) => (
                        <SimpleTable NoDataHeader={index} />
                      ))}
                    </Flex>
                  </>
                }
                cardFooterText="Add data"
                cardFooterClickEvent={navigateToLabs}
              />
            )}
          </Box>
        </Box>
        {/* Investigation end */}
        
        {/* Share your records start */}
        <Box w="100%" px="20px" py="10px" mb="20px"  cursor="pointer" onClick={() => navigate("/medicalrecord")}>
          <Box
            borderRadius="10px"
            w="100%"
            display="flex"
            alignItems="center"
            bg={`url('/assets/imgs/record-bg-stripe.png'), #1C4EC5`}
            backgroundRepeat="no-repeat"
            backgroundSize="contain"
            backgroundPosition="right"
          >
            <Flex
              w="100%"
              justifyContent="center"
              alignItems="center"
              p="20px"
              gap='1rem'
            >
              <Box
                bg="white"
                p="12px"
                borderRadius="full"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Image w="30px" src="/assets/imgs/record-blue.png" />
              </Box>
              <Box>
                <Text fontSize="1rem" fontWeight="600" color="white">
                  Share your records
                </Text>
                <Text fontSize="12px" fontWeight="400" color="white">
                  Sharing your records with a physician ensures
                  personalized care and informed medical decisions.
                </Text>
              </Box>
              <Spacer />
              <Box float="right">
                <Image w="12px" src="/assets/imgs/white-right-arrow.png" />
              </Box>
            </Flex>
          </Box>
        </Box>
        {/* Share your records end */}

        {/* Find a Doctor start*/}
        <Box w="100%" px="20px" py="10px" mb="20px">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb="1rem"
        >
          <Box>
            <Box as="h1" fontSize="20px" fontWeight="600">
              Find a doctor
            </Box>
          </Box>
          <Box>
            <Box as="button" onClick={()=>navigate("/appointments/doctors", )}>
              View all
            </Box>
          </Box>
        </Box>
        <Box w="100%" mb="20px">
        <SimpleGrid
          columns={{base: 2, md: 3}}
          gap="1rem"
          alignItems="center"
        >
          {specialitiesdata && specialitiesdata.map((profile, index) => (
            <Box
              key={index}
              as="button"
              minW="100px"
              h="105px"
              borderRadius="8px"
              display="flex"
              flexDirection="column"
              bg="#FCFCFD"
              lineHeight="normal"
              alignItems="center"
              justifyContent="center"
              onClick={()=> navigate("/appointments/doctors", { state: { specialityname: profile.specialityname } })}
              boxShadow="0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset"
            >
              <Image src={profile.url? profile.imagepath+profile.url:"/assets/imgs/no-image.png"} />
              <Box as="p" color="black" mt="8px" textAlign="center" fontSize="14px" whiteSpace="nowrap" fontWeight="500">
              {profile.specialityname}
              </Box>
            </Box>
          ))}
        </SimpleGrid>
        </Box>
        </Box>
        {/* Find a Doctor end*/}
        

        {/* Rewards, Points, Ai tokens, Tokens start */}
        <Box px="20px" display="flex" gap="1rem" flexWrap="wrap" py="10px" mb="20px">
          {/* Rewards start */}
            <Box
              display="flex"
              w="100%"
              gap="20px"
              py="10px"
              overflowX="hidden"
            >
              {/* Rewards start */}
              <Box w="100%">
              <Box>
                  <GrayCard
                  cardHeader="Rewards"
                  cardHeaderIcon="/assets/imgs/stars.png"
                  minH="200px"
                  bodyStyle={{ p: "0" }}
                  cardBody={
                      <>
                      <Box p="20px" pb="0">
                          <Box display="flex" justifyContent="space-between">
                          <Box as="p" fontSize="28px" fontWeight="700">
                              {rewardsData.position}
                          </Box>
                          <Image
                              w="32px"
                              h="32px"
                              src={
                                rewardsData.position === "Gold" ? "/assets/imgs/gold-badge.png" :
                                rewardsData.position === "Bronze" ? "/assets/imgs/bronze-badge.png" :
                                rewardsData.position === "Silver" ? "/assets/imgs/silver-badge.png" :
                                rewardsData.position === "Platinum" ? "/assets/imgs/platinum-badge.png" :
                                "/assets/imgs/bronze-badge.png" // Default value if rewardsData.position doesn't match any of the above
                            }
                          />
                          </Box>

                          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                          <Box>
                          <Text fontSize="12px" fontWeight="600">
                              
                                {
                              rewardsData.pointtounlocknext ? (
                                <>                      
                                  <strong>{rewardsData.totalpoint ? rewardsData.totalpoint : "-"}</strong> of <strong>{rewardsData.pointtounlocknext ? rewardsData.pointtounlocknext : "-" }</strong>{" "}
                                points to unlock <strong>
                                {rewardsData.position === "Bronze" ? "Silver" : 
                                  rewardsData.position === "Silver" ? "Gold" : 
                                  rewardsData.position === "Gold" ? "Platinum" : ""}
                                </strong>
                                </>

                              ):(
                                <>  
                                  <strong>{rewardsData.totalpoint ? rewardsData.totalpoint : "-"}</strong> {" "}
                                points available 
                                </>
                              )
                            }
                          </Text>
                          </Box>

                          <Box>
                        <PrimaryButton
                          btnStyle={{ h:"40px" , p:"10px" ,  fontSize:"14px", color:"#1A202C !important", opacity:"0.4", m:'0'}}
                          buttonText="Redeem coming soon"
                          isDisabled={true}
                        />
                      </Box>
                      </Box>
                      </Box>
                      </>
                  }
                  hasFooter={false}
                  simpleFooter={true}
                  footerArea={<>
                      <Box display="flex" justifyContent="space-between" flexWrap="nowrap" gap="5px" w="100%">
                      {
                          Array.from({ length: totalBoxes }).map((_, index) => (
                              <Box
                                  key={index}
                                  as="div"
                                  bg={index < filledBoxes ? boxColor : emptyBox}
                                  w={`${100 / 33}%`}
                                  h={`${100 / 33}%`}
                                  minH="12px"
                                  borderRadius="2px"
                              />
                          ))
                      }
                  </Box>
                  </>}
                  />
              </Box>
              </Box>
            </Box>
          {/* Rewards end */}

          {/* Ai tokens start */}
          <Box w="100%">
            <Box>
                <GrayCard
                hasHeader={true}
                cardHeader="AI Tokens"
                cardHeaderIcon="/assets/imgs/stars.png"
                minH="180px"
                bodyStyle={{ p: "0", }}
                cardBody={
                    <>
                    <Box p="20px" pb="0">
                        <Box display="flex" justifyContent="space-between">
                        <Box as="p" fontSize="28px" fontWeight="700">
                        {tokensdata && tokensdata.planname?tokensdata.planname:"-"}
                        </Box>
                        
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                        <Box>
                        <Text fontSize="12px" fontWeight="600">                   
                                <strong>{tokensdata && tokensdata.remainingtokens ? displayMillion_ThoudandValues(tokensdata.remainingtokens):"0"}</strong> tokens available from <strong>{tokensdata && tokensdata.totaltokens ? displayMillion_ThoudandValues(tokensdata.totaltokens):"0"}</strong>{" "}
                        </Text>
                        </Box>
                        <Box h={tokensdata &&  tokensdata.planname == "Premium"  && "40px" }>
                          {tokensdata &&  tokensdata.planname != "Premium" &&
                            <PrimaryButton
                            btnStyle={{ h:"40px" , p:"10px" ,  fontSize:"14px",  m:'0'}}
                            buttonText="Upgrade Plan"
                            onClick={redirectToSubscription}
                            isDisabled={false}
                          />
                          }
                      
                    </Box>
                    </Box>
                        
                    </Box>
                    </>
                }
                hasFooter={false}
                simpleFooter={true}
                footerArea={<>
                    <Box display="flex" justifyContent="space-between" flexWrap="nowrap" gap="5px" w="100%">
                      {
                          Array.from({ length: totalBoxes }).map((_, index) => (
                              <Box
                                  key={index}
                                  as="div"
                                  bg={index < AitokensfilledBoxes ? aiboxColor : emptyBox}
                                  w={`${100 / 33}%`}
                                  h={`${100 / 33}%`}
                                  minH="12px"
                                  borderRadius="2px"
                              />
                          ))
                      }
                  </Box>

                </>}
                />
            </Box>
          </Box>
          {/* Ai tokens end */}

        </Box>
        {/* Rewards, Points, Ai tokens, Tokens end */}

        {/* Update profile start */}
        <Box display="flex" w="100%" gap="20px" px="20px" py="10px">
          <GrayCard
            cardHeader="Update Profile"
            withIcon={false}
            noIcon={
              <>
                {/* <Box display="flex" gap="10px" alignItems="center">
                  {Array.from({ length: 5 }).map((_, index) => (
                    <Box
                      key={index}
                      as="div"
                      bg={index < 3 ? greenBox : emptyBox}
                      w="12px"
                      h="12px"
                      borderRadius="2px"
                    ></Box>
                  ))}
                  <Box as="span" fontSize="12px" fontWeight="600">
                    75%
                  </Box>
                </Box> */}
              </>
            }
            bodyStyle={{ p: "0" }}
            cardBody={
              <>
                <Box>
                  <Box
                    as="button"
                    textAlign="left"
                    p="20px"
                    display="flex"
                    flexDir="row"
                    gap="10px"
                    w="100%"
                    borderBottom="1px solid #D2DCF3"
                    onClick={() => navigate("/profile")}
                  >
                    <Box
                      display="flex"
                      w="40px"
                      h="40px"
                      alignItems="center"
                      justifyContent="center"
                      border="1px solid #D2DCF3"
                      p="6px"
                      borderRadius="10px"
                    >
                      <Image src="/assets/imgs/account_circle.png" />
                    </Box>
                    <Box
                      display="flex"
                      flexDir="row"
                      w="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box>
                        <Box as="p" fontSize="14px" fontWeight="600">
                          Personal Details
                        </Box>
                        <Box as="p" fontSize="12px" fontWeight="400">
                          Update your latest information
                        </Box>
                      </Box>
                      <Box>
                        <Image src="/assets/imgs/right-icon.png" />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    as="button"
                    textAlign="left"
                    p="20px"
                    display="flex"
                    flexDir="row"
                    gap="10px"
                    w="100%"
                    borderBottom="1px solid #D2DCF3"
                    onClick={() => navigate("/reports")}
                  >
                    <Box
                      display="flex"
                      w="40px"
                      h="40px"
                      alignItems="center"
                      justifyContent="center"
                      border="1px solid #D2DCF3"
                      p="6px"
                      borderRadius="10px"
                    >
                      <Image src="/assets/imgs/diagnosis-blue.png" />
                    </Box>
                    <Box
                      display="flex"
                      flexDir="row"
                      w="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box>
                        <Box as="p" fontSize="14px" fontWeight="600">
                          Health Report
                        </Box>
                        <Box as="p" fontSize="12px" fontWeight="400">
                          {/* It's Been 40 Days Since Your Last Report */}
                          {showUpdate1 && "It's been updated"} {reportRecentActivity?reportRecentActivity:"-"}
                        </Box>
                      </Box>
                      <Box>
                        <Image src="/assets/imgs/right-icon.png" />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    as="button"
                    textAlign="left"
                    p="20px"
                    display="flex"
                    flexDir="row"
                    gap="10px"
                    w="100%"
                    borderBottom="1px solid #D2DCF3"
                    onClick={() => navigate("/family")}
                  >
                    <Box
                      display="flex"
                      w="40px"
                      h="40px"
                      alignItems="center"
                      justifyContent="center"
                      border="1px solid #D2DCF3"
                      p="6px"
                      borderRadius="10px"
                    >
                      <Image src="/assets/imgs/family-setup.png" />
                    </Box>
                    <Box
                      display="flex"
                      flexDir="row"
                      w="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box>
                        <Box as="p" fontSize="14px" fontWeight="600">
                          Family Setup
                        </Box>
                        <Box as="p" fontSize="12px" fontWeight="400">
                          Add additional family members
                        </Box>
                      </Box>
                      <Box>
                        <Image src="/assets/imgs/right-icon.png" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            }
            hasFooter={false}
          />
        </Box>
        {/* Update profile end */}
      </Box>
      //Mobile responsive end
    )}
      <ModalRight 
      ref={upgradeModal}
      modalWidth="360px"
      header="Upgrade subscription"
      bodyStyle={{p: "24px 32px"}}
      body={
        <>
           {plansdata &&  plansdata.map((plan, index) => (
          <Card key={index} className="border-pro-plan" mb="20px" boxShadow="0px 8px 24px 0px #B4B4B452">
            <CardHeader backgroundImage="linear-gradient(180deg, #72DCB6 -675%, rgba(114, 220, 182, 0) 100%)" borderRadius="12px">
              <Flex flexDir="column" justifyContent="end" h="100%">
                  <Flex justifyContent="space-between" w="100%" alignItems="center">
                      <Text fontSize="24px" fontWeight="600">{plan.subscriptionname ? plan.subscriptionname :"-"}</Text>
                      {plan.iscurrent === 1 &&
                      <Box  fontSize="10px" fontWeight="700" border="1px solid #A1E8CE" textAlign="center" borderRadius="full" p="5px 10px" color="#0C7650">
                          CURRENT PLAN
                      </Box>
                      }
                      
                  </Flex>
                  {plan.subscriptionname === "Basic" ? (
                  <Text color="#384052" fontSize="20px" fontWeight="500">
                    Free                  
                  </Text>
                  ):(
                    <Text color="#384052" fontSize="20px" fontWeight="500">
                    ${plan.subscriptionprice?plan.subscriptionprice:"0"}/Year <Box as="span" pl="5px" fontSize="8px" fontWeight="500">*Billed annually</Box>
                </Text>
                  )}
                 
              </Flex>
              <Divider mt="15px" />
            </CardHeader>

            <CardBody py="0">
              <Flex flexDir="column" gap="10px">
              {plan.subscriptiondescription && plan.subscriptiondescription.map((desc, index) => (
                  <Box key={index} display="flex" gap="10px" alignItems="center">
                      <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                      <Text fontSize="14px" fontWeight="700"><Box as="span" fontWeight="500">{desc.name?desc.name:"-"}</Box></Text>
                      {plan.subscriptiondescription[index].info &&
                       <Image cursor="pointer" w="12px" h="auto" src="/assets/svgs/info.svg" onClick={()=>openInfoModal(plan.subscriptiondescription[index])} />
                      }
                  </Box>
              ))}
              </Flex>
            </CardBody>

            <CardFooter>
              <PrimaryButton isDisabled={plan.iscurrent === 1 || plan.subscriptionplanid === 1} buttonText={plan.iscurrent === 1?"Selected":"Select"} onClick={()=>upgradeplan(plan.subscriptionplanid)} btnStyle={{m: "0"}}/>
            </CardFooter>
          </Card>
          ))}

        </>
      }
      />
       <AiModal
        ref={AIChatModel}
        modalSize="420px"
        //icon="/assets/imgs/ai-doctor.png"
        header={<>
          <Box display="flex" flexDir='column' gap='5px'>
            <Box display='flex' gap='6px'>
              <Text fontSize="1rem"
                fontWeight="700" 
                backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)" 
                color="transparent" 
                backgroundClip="text"
              >Aira - AI</Text>
              <Box display='flex' alignItems='center' gap='5px' border='1px solid #0d7e54' bg='#d5ece3'  p='3px 5px' borderRadius='full'>
                <Image src='/assets/imgs/spark.png' /> 
                <Text fontSize='10px' fontWeight='500' color='#000'>{currenttokens && currenttokens ? displayMillion_ThoudandValues(currenttokens):"0"} tokens available</Text>
              </Box>
            </Box>
            <Text fontSize="0.75rem" fontWeight="400">Chat with your AI health guide</Text>
          </Box>
        </>}
        headerStyle={{ p: "24px 32px", lineHeight: "normal" }}
        closeBtnStyle={{ top: "18px" }}
        footerStyle={{maxH: "35%",overflow: "hidden auto", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}
        withoutBg={true}
        backDropBlur="10px"
        body={
          <>
            <Flex flexDirection="column" height="100%">
              <Box
                p="24px 32px"
                ref={chatContainerRef}
                className="chat-container"
                style={{ overflowY: "auto" }}
              >
                {aianswers &&
                  aianswers.map((step, index) => (
                    <>
                      <Box
                        key={index}
                        as="div"
                        bg="#061027"
                        p="12px 16px"
                        w="fit-content"
                        float="right"
                        borderRadius="10px 10px 1px 10px"
                      >
                        <Text fontSize="14px" fontWeight="500" color="white">
                          {step.question ? step.question : "-"}
                        </Text>
                      </Box>

                      <Box
                        as="div"
                        my="20px"
                        w="fit-content"
                        display="flex"
                        alignItems="end"
                        float="left"
                      >
                        <Image
                          bg="#061027"
                          p="4px"
                          mr="8px"
                          borderRadius="6px"
                          src="/assets/imgs/ai-chat-logo.png"
                        />
                        <Text
                          bg="#E6E7E980"
                          p="12px 16px"
                          fontSize="14px"
                          fontWeight="500"
                          color="#061027"
                          borderRadius="10px 10px 10px 1px"
                        >
                          {step.answer ? step.answer : <TypingLoader />}
                        </Text>
                      </Box>
                    </>
                  ))}
              </Box>
            </Flex>
          </>
        }
        footerActions={
          <>
            <Box
              p="24px 32px"
              display="flex"
              w="100%"
              h="100%"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box w="100%">
                {remainingquestions &&
                  remainingquestions.map((step, index) => (
                    <Box
                      key={index}
                      as="button"
                      w="100%"
                      p="16px"
                      mb="16px"
                      fontSize="14px"
                      fontWeight="600"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius="10px"
                      border="1px solid #7795DC"
                      bg="#EAEDF5"
                      color="#163E9E"
                      gap='1rem'
                        textAlign='left'
                      onClick={() => GetAIAnswer(step.value)}
                    >
                      {step.value ? step.value : "-"}
                      <Image src="/assets/imgs/right-icon.png" />
                    </Box>
                  ))}
                
              </Box>
            </Box>
          </>
        }
      />

      <DynamicModal
        ref={consentForm}
        modalAttributes={{closeOnOverlayClick: false}}
        hasCloseBtn={false}
        modalStyle={{minW:"600px", maxW:"600px", maxH:"100vh"}}
        modalHeader="Consent Form"
        modalBody={<>
          <Box p={6} h="calc(80vh - 100px)" overflow="hidden auto" lineHeight="1.6" ref={modalBodyRef}>
            <Heading as="h2" fontSize="1.5rem" mb={4}>
            Our Commitment to Privacy
            </Heading>

            <Text mb={4}>
            At Midoc, your privacy is paramount. We are committed to protecting the privacy of your personal information while providing you with the highest level of service. This Privacy Policy explains how Midoc, LLC ("Midoc," "we," or "us") may collect, use, and share your personal information when you access our services through the Midoc website or our mobile application (collectively, the "Services").            </Text>

            <Heading as="h3" size="md" mb={2}>
            Consent
            </Heading>

            <Text mb={4}>
            By using our Services, you consent to the terms outlined in this Privacy Policy. If you do not agree with any part of this policy, please do not use our Services. Continued use of the Services following any changes to this policy will signify your acceptance of those changes.            </Text>



            <Text mb={2}> Personal Information You Provide:</Text>
            <UnorderedList mb={4}>
              <ListItem><strong>Registration and Account Setup:</strong> We collect personal details when you register or set up an account, which may include your name, email address, phone number, and address.</ListItem>
              <ListItem><strong>Health Records:</strong> Through our mobile application, you may voluntarily provide medical and health-related information to enhance service customization.</ListItem>
              <ListItem><strong>Interactions:</strong> We gather information when you interact with our Services, such as customer service inquiries, responses to surveys, and participation in promotions.</ListItem>
              
            </UnorderedList>

            <Text mb={2}> Automated Information Collection:</Text>
            <UnorderedList mb={4}>
              <ListItem><strong>Cookies and Tracking Technologies:</strong> We use cookies to enhance your experience, personalize content and ads, and analyze our site traffic. We also employ web beacons to collect aggregate statistics about website usage.</ListItem>
              <ListItem><strong>Geo-location Data:</strong> With your permission, we may collect precise location data to provide location-specific services.</ListItem>              
            </UnorderedList>

            <Text mb={2}> How We Use Your Information</Text>
            <UnorderedList mb={4}>
              <ListItem><strong>Service Provision:</strong> To manage your account, provide customer support, process transactions, and send important account and service information.</ListItem>
              <ListItem><strong>Improvement and Personalization: </strong> To enhance and personalize your experience, and to develop new features and services that meet your needs.</ListItem>
              <ListItem><strong>Compliance and Protection:</strong>To comply with legal requirements and protect the rights and safety of Midoc and its users.</ListItem>
              
            </UnorderedList>

            <Text mb={2}> Sharing of Information</Text>
            <UnorderedList mb={4}>
              <ListItem><strong>With Service Providers:</strong>We share information with third-party service providers to facilitate services on our behalf, such as payment processing, data analysis, email delivery, and hosting services.</ListItem>
              <ListItem><strong>Legal and Safety Reasons:</strong>We may disclose information to respond to legal processes or to protect the rights, property, or safety of Midoc, our users, or others.</ListItem>
              <ListItem><strong>Business Transfers: </strong>In the event of a merger, sale, or asset transfer, your information may be disclosed and transferred as part of the transaction.</ListItem>
              
            </UnorderedList>

            <Text mb={2}> Your Rights and Choices</Text>
            <UnorderedList mb={4}>
              <ListItem><strong>Account Information: </strong>You can review, update, or delete your account information at any time.</ListItem>
              <ListItem><strong>Cookies: </strong> You can configure your browser to reject cookies or to alert you when cookies are being sent.</ListItem>
              <ListItem><strong>Opt-Out: </strong>You may opt out of receiving promotional communications from us by following the instructions in the communication.</ListItem>
              
            </UnorderedList>

            <Heading as="h3" size="md" mb={2}>
            Children’s Privacy
            </Heading>

            <Text mb={4}>
            We do not knowingly collect or solicit information from anyone under the age of 13. If you are under 13, please do not attempt to register for the Services or send any personal information about yourself to us.
          </Text>

          <Heading as="h3" size="md" mb={2}>
          International Users.
            </Heading>

            <Text mb={4}>
            Our Services are hosted in the United States and are intended for users located within the United States. If you are located outside of the United States, please be aware that any information you provide to us will be transferred to and processed in the United States.
             </Text>

             <Heading as="h3" size="md" mb={2}>
             Changes to This Policy
            </Heading>

            <Text mb={4}>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We encourage you to review our Privacy Policy whenever you access the Services to stay informed about our information practices and the ways you can help protect your privacy.
              </Text>

              <Text mb={4}>
              If you have any questions about this Privacy Policy or our privacy practices, 
              please contact us at: privacy@midoc.com.
                 </Text>


           
          </Box>
        </>}
        hasFooter={true}
        modalFooterBtn={<>
          <PrimaryButton buttonText="Agree" onClick={submitconsentform} isDisabled={!isScrolledToBottom}/>
        </>}
      />

      <ModalRight
        ref={switchUsers}
        modalWidth="360px"
        header="Switch User"
        bodyStyle={{p: "24px 32px"}}
        body={<>
          <Box display="flex" flexDir="column" gap="20px">
              {familydata && familydata.map((user, index)=> (
                <ReportViewCard
                key={index}
                  deleteBtn={false} 
                  cardBtn={false} 
                  cardHeight={{lg: "125px", xl: "150px"}}
                  cardHeader={false}
                  cardBody={<>
                    <Box display="flex" gap="10px">
                        <Image boxSize="64px" borderRadius="full" src={user.imagepath && user.image ? user.imagepath + user.image : "/assets/imgs/no-image-icon.png"} />
                      <Box>
                        <Text fontSize={{lg: "12px", xl: "16px"}} lineHeight="normal" fontWeight="600" color="#061027">{user.fullname} {user.isprimaryuser === 1 ? "(You)" : null}</Text>
                        <Text fontSize="12px" fontWeight="500" color="#384052">{user.relation ? user.relation:"-"}</Text>
                      </Box>
                    </Box>
                  </>}
                  hasFooter={true}
                  disableBtn={user.isselecteduser === 1 ? true : false}
                  cardFooterText={user.isselecteduser === 1 ? "Selected":"Switch user"}
                  cardFooterClickEvent={()=>handleswitchuser(user.activeuserid, user.isprimaryuser)}
                />
            ))}
          </Box>
        </>}
      />

      <ModalRight
        ref={infoModal}
        header="You have access to"
        // subHeader="You have access to."
        body={
          <Flex pt="15px" flexDirection="column" h="100%">
            {
              infoArray && infoArray.map((item, key)=>(
                <Box index={key} display="flex" alignItems="baseline" justifyContent="left" my="0.6rem">
                <Image mx="0.5rem" w="12px" h="auto" src="/assets/imgs/check-black.png" />
                <Text fontSize="14px" fontWeight="600">{item.name}</Text>
                </Box>
              ))
            }
         
          </Flex>
        }
      />

    <ModalRight 
      ref={notification}
      modalWidth="480px"
      header="Notifications"
        body={<>
        {data && data.length> 0 ?(
          data.map((notification , index)=>(
            <Box key={index} display="flex" gap="10px" py="20px" borderBottom="1px solid #E6E7E9" alignItems="flex-start" justifyContent='space-between'>
              <Flex gap='0.5rem' alignItems='center'>
                <Box display="flex" p="6px"  w="30px" h="auto" alignItems="baseline" borderRadius="full" border="1px solid #E6E7E9" bg="white">
                  <Image w="40px"  src='/assets/imgs/calendar.png' />
                </Box>
                <Box>
                <Box as='p' fontSize="12px" fontWeight="500">
                {notification.notificationtype 
                  ? notification.notificationtype.charAt(0).toUpperCase() + notification.notificationtype.slice(1)
                  : "-"}
              </Box>
                  <Box as='p' fontSize="14px" maxW='300px' title={notification.notificationcontent} fontWeight="500">{notification.notificationcontent}</Box>
                </Box>
              </Flex>
              <Flex alignItems="center">
                <Box as='span' w="4px" p="2px" h="4px" bg="#1C4EC5" mr="4px" borderRadius="full"></Box>
                <Box as='span' fontSize="10px" fontWeight="400">{notification.timeDifference}</Box>
              </Flex>
            </Box>
          ))
 
        ):(
          <Flex flexDir="column" minH="100vh" justifyContent="center" alignItems="center">
          <Image src='/assets/svgs/notifications.svg' />
          <Text fontSize="16px" fontWeight="500">Notifications</Text>
        </Flex> 
        )}
         
          {/* <Box display="flex" gap="10px" py="20px" borderBottom="1px solid #E6E7E9" alignItems="flex-start">
            <Box display="flex" p="5px"  w="40px" h="auto" alignItems="baseline" borderRadius="full" border="1px solid #E6E7E9" bg="white">
              <Image w="40px"  src='/assets/imgs/calendar.png' />
            </Box>
            <Box>
              <Box as='p' fontSize="12px" fontWeight="500">Appointments</Box>
              <Box as='p' fontSize="14px" fontWeight="500">Your appointment is in 15 minutes. Get ready for your scheduled consultation.</Box>
            </Box>
            <Flex alignItems="center">
              <Box as='span' w="4px" p="2px" h="4px" bg="#1C4EC5" mr="4px" borderRadius="full"></Box>
              <Box as='span' fontSize="10px" fontWeight="400">2m</Box>
            </Flex>
          </Box>
          <Box display="flex" gap="10px" py="20px" borderBottom="1px solid #E6E7E9" alignItems="flex-start">
            <Box display="flex" p="5px"  w="40px" h="auto" alignItems="baseline" borderRadius="full" border="1px solid #E6E7E9" bg="white">
              <Image w="40px"  src='/assets/imgs/calendar.png' />
            </Box>
            <Box>
              <Box as='p' fontSize="12px" fontWeight="500">Appointments</Box>
              <Box as='p' fontSize="14px" fontWeight="500">Your appointment is in 15 minutes. Get ready for your scheduled consultation.</Box>
            </Box>
            <Flex alignItems="center">
              <Box as='span' w="4px" p="2px" h="4px" bg="#1C4EC5" mr="4px" borderRadius="full"></Box>
              <Box as='span' fontSize="10px" fontWeight="400">2m</Box>
            </Flex>
          </Box> */}

         
        </>}
    />

      <input
        type='file'
        id='file-upload'
        onChange={handleimageselection}
        ref={fileInputRef} // Set the ref for the file input
        style={{ display: 'none' }} // Hide the file input
      />
    </>
  );
};

export default HomeIndex;
