import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { Box } from '@chakra-ui/react';

const ImageCropper = ({ src, onCropComplete }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropChange = useCallback((crop) => {
    setCrop(crop);
  }, []);

  const onZoomChange = useCallback((zoom) => {
    setZoom(zoom);
  }, []);

  const onCropCompleteCallback = useCallback((croppedArea, croppedAreaPixels) => {
    onCropComplete(croppedArea, croppedAreaPixels);
  }, [onCropComplete]);

  return (
    <Box position="relative" width="100%" height="100%">
      <Cropper
        image={src}
        crop={crop}
        zoom={zoom}
        aspect={1}
        cropShape="round"
        showGrid={false}
        onCropChange={onCropChange}
        onZoomChange={onZoomChange}
        onCropComplete={onCropCompleteCallback}
        style={{
            cropAreaStyle: {
              border: '2px solid white', 
            },
          }}
      />
    </Box>
  );
};

export default ImageCropper;
