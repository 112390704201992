import React, { useState,useEffect,useRef } from "react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import { Box,Text, Flex, Image, Divider, SimpleGrid } from "@chakra-ui/react";
import Footer from "../../components/footer"
import { PrimaryButton, SearchBar, Toaster } from "../../components";
import DoctorCard from "../../components/doctor-card";
import FilterButton from "../../components/filter-button";
import ModalRight from "../../components/modal-right";
import { useLocation, useNavigate } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import {FetchDoctors } from "../../middleware/services/appointments";
import moment from "moment";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import MobileNavbar from "../../components/mobile-navbar";
import { useScreenType } from "../../auth";
import LocationSearch from '../../components/navbar/searchbar';
import { CONFIG } from '../../appconfig/appconfig';



const DoctorsListIndex = () => {
  const location = useLocation();
  const filterModal = useRef()
  const selectLocation = useRef()
  var [data, setData] = useState(null);
  const navigate = useNavigate()
  var [patientid, setLongitude] = useState();
  var [activeuserid, setActiveUser] = useState();
  var [filterlatitude, setLatitude] = useState();
  var [filterlongitude, setLongitude] = useState();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [search, setSearch] = useState("");
  const [showhidesearch, ShowHideSearch] = useState(false);
  const [selectedfilters, setSelectedFilters] = useState("relevance");
  const { specialityname } = location.state || {};
  const screenType = useScreenType()
  const { showSuccessToast, showErrorToast } = Toaster();
  const switchLocation = useRef()
  const [currentlocation, setLocation] = useState({
    latitude: null,
    longitude: null,
    address: '',
  });

  useEffect(() => {
    const detailsFromLocal = localStorage.getItem("location");
    if(detailsFromLocal){
      var locationdetails = JSON.parse(detailsFromLocal);
      fetchDoctors("relevance",locationdetails.latitude, locationdetails.longitude);
      setLatitude(locationdetails.latitude);
      setLongitude(locationdetails.longitude)
    }else{
      fetchDoctors("relevance");
    }
    
  }, []);

  const fetchDoctors = async (tab, lat, long) => {
    showLoader();
    try {
      const result = await FetchDoctors({
        patientid,
        activeuserid,
        type:"all",
        speciality: specialityname,
        orderby: tab,
        latitude: lat,
        longitude: long,
      });
      if (result != null) {
        setData(result);
      } else {
        //alert("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };
  const formatTime = (timeString) => {
    if (!timeString) return "-";
    return moment(timeString, 'HH:mm:ss').format('HH:mm');
};
const SearchIconClicked = () => {
  ShowHideSearch(true);
};
const closeSearchBar =()=>{
  setSearch("")
  ShowHideSearch(false)
  fetchDoctors("relevance", filterlatitude, filterlongitude);
}
const handlefilterswitch = (index) => {
  if(index === "Relevance"){
    fetchDoctors("relevance", currentlocation.latitude, currentlocation.longitude);
    setFilters("relevance")
  }else if(index === "Ratings"){
    fetchDoctors("ratings",currentlocation.latitude, currentlocation.longitude);
    setFilters("ratings")
  }else if(index === "Experience"){
    fetchDoctors("experience",currentlocation.latitude, currentlocation.longitude);
    setFilters("experience")
  }
  else if(index === "Cost"){
    fetchDoctors("cost",currentlocation.latitude, currentlocation.longitude);
    setFilters("cost")
  }
 
};
const backFunction = ()=>{
  navigate("/appointments")
}

const [filters, setFilters] = useState({
  fees: null,
  ratings: null,
  experience: null,
});

const handleFilterChange = (filterType, value) => {
  setFilters((prevFilters) => ({
    ...prevFilters,
    [filterType]: value,
  }));
};
const applyfilter = async (tab) => {
  showLoader();
  try {
    const result = await FetchDoctors({
      patientid,
      activeuserid,
      "experience": filters.experience,
      "fee": filters.fees,
      "orderby": "relevance",
      "ratings": filters.ratings,
      "type": "all"
    });
    if (result != null) {
      setData(result);
      filterModal.current.closeRight()
    } else {
      setData("");
      filterModal.current.closeRight()
    }
    dismissLoader();
  } catch (error) {
    console.error("Error in home:", error);
  }
};
const searchdoctor = async (e) => {
  const value = e.target.value;
  if (e.key === "Enter") {
    showLoader();
    try {
      const result = await FetchDoctors({
        patientid,
        activeuserid,
        type:"all",
        speciality: specialityname,
        orderby: selectedfilters,
        searchtext: value
      });
      if (result != null) {
        setData(result);
      } else {
        //alert("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  }
};
const getLocation = () => {
  switchLocation.current.closeRight();
  filterModal.current.closeRight();
  if (navigator.geolocation) {
    const options = {
      enableHighAccuracy: true, // Request high accuracy
      timeout: 10000, // Wait up to 10 seconds before giving up
      maximumAge: 0 // Do not use cached location
    };

    navigator.geolocation.getCurrentPosition(showPosition, showError, options);
  } else {
    alert("Geolocation is not supported by this browser.");
  }
};

const showPosition = async (position) => {
  const lat = position.coords.latitude;
  const lon = position.coords.longitude;
  const address = await getAddress(lat, lon);
  setLocation({
    latitude: lat,
    longitude: lon,
    address: address,
  });
  const data = {
    lat: lat,
    lng: lon,
  }
  handleLocationSelect(data)
};

const showError = (error) => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      // showErrorToast("User denied the request for Geolocation.");        
      break;
    case error.POSITION_UNAVAILABLE:
      // showErrorToast("Location information is unavailable.");
      break;
    case error.TIMEOUT:
      // showErrorToast("The request to get user location timed out.");
      break;
    case error.UNKNOWN_ERROR:
      // showErrorToast("An unknown error occurred.");
      break;
    default:
      showErrorToast("An unknown error occurred.");
  }
};

const getAddress = async (lat, lon) => {
  const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${CONFIG.GoogleMapKey}`);
  const data = await response.json();
  console.log(data)
  if (data.status === "OK") {
    return data.results[0].formatted_address;
  } else {
    return "Address not found";
  }
};
const handleLocationSelect = async (selectedlocation) => {
  setLocation({
    latitude: selectedlocation.lat,
    longitude: selectedlocation.lng,
    address: selectedlocation.address,
  });
  showLoader();
  try {
    const result = await FetchDoctors({
      patientid,
      activeuserid,
      type:"dashboard",
      "orderby": "relevance",
      latitude: selectedlocation.lat,
      longitude: selectedlocation.lng,
    });
    if (result != null) {
      setData(result);
      switchLocation.current.closeRight();
      filterModal.current.closeRight()
    } else {
      //alert("No data");
    }
    dismissLoader();
  } catch (error) {
    console.error("Error in home:", error);
  }
};
const tabListCSS = `
  @media (max-width: 600px) {
  /* Container holding the tabs */
  .doctorFilter {
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;  /* Hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* Hide scrollbar in Firefox */
  }

  .doctorFilter::-webkit-scrollbar {
    display: none;  /* Hide scrollbar in Chrome, Safari, and Opera */
  }

  /* Tab list styles */
  .chakra-tabs__tablist {
    display: flex;
    flex-wrap: nowrap;
  }

  /* Tab button styles */
  .chakra-tabs__tab {
    flex: 0 0 auto;
  }
}

`
  return (
    <>
    <style>{tabListCSS}</style>
    {screenType == 'web' ? (
      <Box w="100%">
      <Box top="0" zIndex="banner">
          <Navbar />
          {isLoading && <Loader />}
          <SecondaryNav
            withBack={specialityname? specialityname:"Doctors List"}
            goBack={backFunction}
            withBackBody={<>
                <Box display="flex" gap="10px" alignItems="center" pr="25px">
                 <Box
                    as="button"
                    bg="none"
                    p="10px"
                    border="1px solid #E6E7E9"
                    borderRadius="10px"
                    onClick={()=> filterModal.current.openRight()}
                  >
                    <Image w="18px" src="/assets/svgs/filter.svg" />                  
                  </Box>
                 
                 <Box
                    as="button"
                    bg="none"
                    p="10px"
                    border="1px solid #E6E7E9"
                    borderRadius="10px"
                  >
                    <Image w="18px" src="/assets/imgs/search.png" 
                    onClick={SearchIconClicked}/>                  
                  </Box>
                </Box>
            </>}
          />
        </Box>
        <Box h="100%">
        {showhidesearch && (
             <Box p="1.25rem 2rem" bg="white" border="1px solid #E6E7E9">
                <SearchBar passInputPlace="Search" 
                 //onChange={(event) => setSearch(event.target.value)}
                 onKeyPress={searchdoctor}
                 iconSrc="/assets/imgs/remove-icon.png"
                     handleClick={closeSearchBar}/>
            </Box> 
          )}
            <Box p="20px" pb="0" display="flex" justifyContent="start">
              <FilterButton
                tabs={["Relevance", "Experience", "Cost"]}
                onClick={handlefilterswitch}
              />
            </Box>
          <Flex h={showhidesearch ? "calc(100vh - 330px)" : "calc(100vh - 240px)"} flexDir="column" bg="#fcfcfd" p="1.25rem 2rem" overflow="hidden auto">
            {/* Main container start */}
            <Box w="100%">
            <Box w="100%">
            {data && data.length > 0 ? (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing="1rem">
                {data && data
                    .map((step, index) => (
                    <DoctorCard 
                    key={index}
                      
                      cardBody={<>
                        <Box>
                          <Box display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                            <Box display='flex' gap="0.75rem">
                              <Box boxSize="3.5rem" borderRadius='full' overflow="hidden">
                                <Image w="100%" h='100%' src={step.profilepictureurl? step.imagepath+step.profilepictureurl:"/assets/imgs/no-image.png" } />
                              </Box>
                              <Box>

                                <Text fontSize="0.625rem" w="120px" className="textOverflow" fontWeight="500" color="#384052" title={step.specality?step.specality:"-"}>{step.specality?step.specality:"-"}</Text>
                                <Text fontSize="0.875rem" fontWeight="600" color="#061027" className="textOverflow" title={step.fullname?step.fullname:"-"}>{step.fullname?step.fullname:"-"}</Text>
                                <Text fontSize="0.75rem" fontWeight="400" color="#384052" w="120px" className="textOverflow" title={step.qualification?step.qualification:"-"}>{step.qualification?step.qualification:"-"}</Text>

                              </Box>
                            </Box>
                          </Box>

                          <Divider my="0.75rem" />
                          <Box display='flex' flexDir="column">
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="12px" h="13px" src="/assets/svgs/location-blue.svg" />
                                <Text fontSize="0.75rem" className="textOverflow" w="229px" fontWeight="500">{step.address? step.address:"-"}</Text>
                              </Box>
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="13px" h="10px" src="/assets/svgs/work-blue.svg" />
                                <Text fontSize="0.75rem" fontWeight="500">{step.yearsofexperience?step.yearsofexperience+ " years of experience":"-"}</Text>
                              </Box>
                          </Box>

                          <Box display="flex" gap="0.5rem" mt="0.75rem">
                            {/* <Box display="inline-flex" gap="0.25rem" border='1px solid #E6E7E9' borderRadius="full" p="0.15rem 0.5rem">
                              <Image src="/assets/svgs/Stars.svg" />
                              <Text fontSize="0.75rem" fontWeight="500" color="#384052">{step.ratings? step.ratings:"-"}</Text>
                            </Box> */}
                            <Box display="inline-flex" gap="0.25rem" border='1px solid #E6E7E9' borderRadius="full" p="0.15rem 0.5rem">
                              <Image src="/assets/svgs/pricing-blue.svg" />
                              <Text fontSize="0.75rem" fontWeight="500" color="#384052">{step.fee? "$"+step.fee:"$0"}</Text>
                            </Box>
                          </Box>
                        </Box>
                      </>}
                      cardFooterText="Book appointment"
                      onClick={()=> navigate("/doctorprofile", { state: { doctorid: step.doctorid, fullname: step.fullname }})}
                    />
                  ))
                }
                </SimpleGrid>
                 ) : (
                  <>
                  <Box display="flex" flexDir="column" justifyContent="center" alignItems="center" h="calc(100vh - 160px)" overflow="hidden auto">
                    <Image w="3.5rem" opacity='0.5' src="/assets/svgs/doctors.svg" />
                    <Text fontSize="1rem" fontWeight="700" color="#6A707D">No doctors</Text>
                    </Box>
                  </>
                )}
            </Box>
            </Box>

             {/* No appoinments start */}
              {/* <Image w="3.5rem" src="/assets/svgs/notifications.svg" />
              <Text fontSize="1rem" fontWeight="700" color="#6A707D">No appointments</Text> */}
              {/* No appoinments end */}

            {/* Main container end */}
           
            {/* <Image w="25%"  src="/assets/imgs/midoc-black.png" />
            <Text fontSize="35px" mt="35px" fontWeight="700" backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)" color="transparent" backgroundClip="text">Coming soon</Text> */}
          </Flex>
          <Footer />
        </Box>
      </Box>
     ) : ( 
      //Mobile responsive start
      <Box w="100%" bg="#fcfcfd">
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/appointments')}
          header={specialityname? specialityname:"Doctors List"}
          bottomArea={<>
            <Box display="flex" gap="10px" justifyContent="center" alignItems="center" w="100%">
              <Box w="100%">
                <SearchBar
                  passInputPlace="Search medicines"
                  onKeyPress={searchdoctor}
                  handleClick={closeSearchBar}
                />
              </Box>
            </Box>
          </>}
          rightArea={<>
            <Box
              as="button"
              bg="none"
              p="10px"
              onClick={()=> filterModal.current.openRight()}
            >
              <Image w="18px" src="/assets/svgs/filter.svg" />                  
            </Box>
          </>}
        />

        <Box p="1rem" display="flex" className="doctorFilter" justifyContent="start">
          <FilterButton
            tabs={["Relevance", "Experience", "Cost"]}
            onClick={handlefilterswitch}
          />
        </Box>

        <Box w="100%" p="1.25rem"  h='calc(100vh - 330px)' overflow="hidden auto">
          {/* Appointments start */}
            <Box w="100%">
            {data && data.length > 0 ? (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing="1rem">
                {data && data
                    .map((step, index) => (
                    <DoctorCard 
                    key={index}
                      
                      cardBody={<>
                        <Box>
                          <Box display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                            <Box display='flex' gap="0.75rem">
                              <Box boxSize="3.5rem" borderRadius='full' overflow="hidden">
                                <Image w="100%" h='100%' src={step.profilepictureurl? step.imagepath+step.profilepictureurl:"/assets/imgs/no-image.png" } />
                              </Box>
                              <Box>

                                <Text fontSize="0.625rem" w="120px" className="textOverflow" fontWeight="500" color="#384052" title={step.specality?step.specality:"-"}>{step.specality?step.specality:"-"}</Text>
                                <Text fontSize="0.875rem" fontWeight="600" color="#061027" className="textOverflow" title={step.fullname?step.fullname:"-"}>{step.fullname?step.fullname:"-"}</Text>
                                <Text fontSize="0.75rem" fontWeight="400" color="#384052" w="120px" className="textOverflow" title={step.qualification?step.qualification:"-"}>{step.qualification?step.qualification:"-"}</Text>

                              </Box>
                            </Box>
                          </Box>

                          <Divider my="0.75rem" />
                          <Box display='flex' flexDir="column">
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="12px" h="13px" src="/assets/svgs/location-blue.svg" />
                                <Text fontSize="0.75rem" className="textOverflow" w="229px" fontWeight="500">{step.address? step.address:"-"}</Text>
                              </Box>
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="13px" h="10px" src="/assets/svgs/work-blue.svg" />
                                <Text fontSize="0.75rem" fontWeight="500">{step.yearsofexperience?step.yearsofexperience+ " years of experience":"-"}</Text>
                              </Box>
                          </Box>

                          <Box display="flex" gap="0.5rem" mt="0.75rem">
                            {/* <Box display="inline-flex" gap="0.25rem" border='1px solid #E6E7E9' borderRadius="full" p="0.15rem 0.5rem">
                              <Image src="/assets/svgs/Stars.svg" />
                              <Text fontSize="0.75rem" fontWeight="500" color="#384052">{step.ratings? step.ratings:"-"}</Text>
                            </Box> */}
                            <Box display="inline-flex" gap="0.25rem" border='1px solid #E6E7E9' borderRadius="full" p="0.15rem 0.5rem">
                              <Image src="/assets/svgs/pricing-blue.svg" />
                              <Text fontSize="0.75rem" fontWeight="500" color="#384052">{step.fee? "$"+step.fee:"$0"}</Text>
                            </Box>
                          </Box>
                        </Box>
                      </>}
                      cardFooterText="Book appointment"
                      onClick={()=> navigate("/doctorprofile", { state: { doctorid: step.doctorid, fullname: step.fullname }})}
                    />
                  ))
                }
            </SimpleGrid>
              ) : (
              <>
              <Box display="flex" flexDir="column" justifyContent="center" alignItems="center" h="calc(100vh - 160px)" overflow="hidden auto">
                <Image w="3.5rem" opacity='0.5' src="/assets/svgs/doctors.svg" />
                <Text fontSize="1rem" fontWeight="700" color="#6A707D">No doctors</Text>
                </Box>
              </>
            )}

            </Box>
          {/* Appointments end */}
        </Box>
      </Box>
      )}
      <ModalRight 
        ref={filterModal}
        modalWidth="360px"
        header="Filters"
        bodyStyle={{p: "24px 32px"}}
        body={
          <>
                     <Box w="100%">
       {/* Fees start */}
       <Box>
        <Text fontSize="0.675rem" fontWeight="600" color="#384052" textTransform="uppercase">Fees</Text>
        <Box display="flex" py="1rem" gap="0.5rem" borderBottom="1px solid #E6E7E9">
          {['$', '$$', '$$$', '$$$$'].map((fee, index) => (
            <Box
              key={fee}
              border="1px solid #E6E7E9"
              borderRadius="full"
              bg={filters.fees === index + 1 ? '#061027' : 'white'}
              p="0.25rem 1.25rem"
              as="button"
              onClick={() => handleFilterChange('fees', index + 1)}
            >
              <Text fontSize="0.875rem" fontWeight="600" color={filters.fees === index + 1 ? 'white' : '#384052'}>
                {fee}
              </Text>
            </Box>
          ))}
        </Box>
      </Box>
      {/* Fees end */}

      {/* Rating start */}
      {/*  <Box mt="1rem">
        <Text fontSize="0.675rem" fontWeight="600" color="#384052" textTransform="uppercase">Ratings</Text>
        <Box display="flex" py="1rem" gap="0.5rem" flexWrap="wrap" borderBottom="1px solid #E6E7E9">
          {['⭐', '⭐⭐', '⭐⭐⭐', '⭐⭐⭐⭐', '⭐⭐⭐⭐⭐'].map((rating, index) => (
            <Box
              key={index}
              as="button"
              border="1px solid #E6E7E9"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="full"
              bg={filters.ratings === index + 1 ? '#061027' : 'white'}
              gap="0.25rem"
              w={`${3.25 + index}rem`}
              h="2rem"
              onClick={() => handleFilterChange('ratings', index + 1)}
            >
              {[...Array(index + 1)].map((_, starIndex) => (
                <Image key={starIndex} src={filters.ratings === index + 1 ? "/assets/svgs/white-star.svg" : "/assets/svgs/gray-star.svg"} />
              ))}
            </Box>
          ))}
        </Box>
      </Box>*/}
      {/* Rating end */}

      {/* Experience start */}
      <Box mt="1rem">
        <Text fontSize="0.675rem" fontWeight="600" color="#384052" textTransform="uppercase">Experience</Text>
        <Box display="flex" py="1rem" gap="0.5rem" flexWrap="wrap" borderBottom="1px solid #E6E7E9">
          {['2-5', '6-10', '11-15', '16-20', '17-30'].map((exp) => (
            <Box
              key={exp}
              border="1px solid #E6E7E9"
              borderRadius="full"
              bg={filters.experience === exp ? '#061027' : 'white'}
              p="0.25rem 1.25rem"
              as="button"
              onClick={() => handleFilterChange('experience', exp)}
            >
              <Text fontSize="0.875rem" fontWeight="600" color={filters.experience === exp ? 'white' : '#384052'}>
                {exp}
              </Text>
            </Box>
          ))}
        </Box>
      </Box>
      {/* Experience end */}

      {/* Location start */}
      <Box mt="1rem">
                    <Box display="flex" pb="1rem" borderBottom="1px solid #E6E7E9">
                        <Box as="button" onClick={()=> switchLocation.current.openRight()} display="flex" justifyContent="space-between" w="100%" border="1px solid #E6E7E9" borderRadius="0.625rem" bg='white' p="0.875rem 1rem">
                            <Text fontSize="0.875rem" fontWeight="600" color="#384052">Location</Text>
                            <Box display="flex" gap="0.5rem" alignItems="center">
                                <Text fontSize="0.875rem" fontWeight="600" opacity="0.5" color="#050D1FCC">Current</Text>
                                <Image src="/assets/imgs/right-icon.png" />
                            </Box>
                        </Box>
                    </Box>
                </Box>
      {/* Location end */}
      

    </Box>
            
          </>
        }

        footerActions={<>
            <Box w="100%" display="flex" gap="1rem">
                <PrimaryButton variant="grayBtnFull" buttonText="Reset" 
                onClick={() => setFilters({
            fees: '',
            ratings: '',
            experience: '',
          })}/>
                <PrimaryButton buttonText="Apply" onClick={applyfilter}/>
            </Box>
        </>}
      />

      <ModalRight 
        ref={selectLocation}
        modalWidth="360px"
        handlebackarrow={()=> selectLocation.current.closeRight()}
        withBackBtn={true}
        withBackHeader={<>
            <Box display="flex" w="100%" flexDir="column" gap="1rem">
                <Box>
                <Text as="h2" fontSize="1.25rem" textAlign="center" fontWeight="600" color="#050D1F">Select Locations</Text>
                </Box>
                    <SearchBar passInputPlace="Search location"  />
                <Box display="flex" gap="0.5rem" my="0.5rem">
                    <Image src="/assets/svgs/detect-location-blue.svg" />
                    <Text fontSize="0.875rem" fontWeight="600" color="#1C4EC5">Auto detect location</Text>
                </Box>
            </Box>
        </>}
        bodyStyle={{p: "24px 32px"}}
        body={
          <>
            <Box w="100%">
              <Text fontSize="0.675rem" fontWeight="600" color="#384052" textTransform="uppercase">Search results</Text>
              <Box display="flex" flexDir="column">
                <Box display="flex" gap="0.75rem" alignItems="baseline" borderBottom="1px solid #E6E7E9" py="1rem">
                  <Image src="/assets/svgs/location-blue.svg" />
                  <Box>
                    <Text fontSize="1rem" fontWeight="600" color="#061027">8 Silverbell Grove</Text>
                    <Text fontSize="0.675rem" fontWeight="600" color="#384052">Scarborough, M123H3, Canada</Text>
                  </Box>
                </Box>
                <Box display="flex" gap="0.75rem" alignItems="baseline" borderBottom="1px solid #E6E7E9" py="1rem">
                  <Image src="/assets/svgs/location-blue.svg" />
                  <Box>
                    <Text fontSize="1rem" fontWeight="600" color="#061027">8 Silverbell Grove</Text>
                    <Text fontSize="0.675rem" fontWeight="600" color="#384052">Scarborough, M123H3, Canada</Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        }
      />
       <ModalRight 
        ref={switchLocation}
        modalWidth="360px"
        header={<>
        <Box my="1rem">
          <Text as="h2" fontSize="1.5rem" fontWeight="600" color="#050D1F">Switch Location</Text>
        </Box>
          <Box display="flex" flexDir="column" gap="1rem">
          <LocationSearch onSelect={handleLocationSelect} />
              <Box display="flex" gap="0.5rem">
                <Image src="/assets/svgs/detect-location-blue.svg" />
                <Text fontSize="0.875rem" cursor="pointer" fontWeight="600" color="#1C4EC5" onClick={getLocation}>Auto detect location</Text>
              </Box>
            </Box>
        </>}
        bodyStyle={{p: "24px 32px"}}
        body={
          <>
          </>
        }
      />
    </>
  );
};

export default DoctorsListIndex;
