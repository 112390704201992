import formAPIMedicalCondition from "../medicalconditions/medicalcondition";


var formattedOutput = null;

const formAPIModelMedicalCondition = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data?.map(item => formAPIMedicalCondition(item));


  return formattedOutput
};

export default formAPIModelMedicalCondition