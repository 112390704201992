
const labsReportViewFormatting = (inputData) => {
  const formattedData = [];

  inputData.forEach(item => {
    const existingEntry = formattedData.find(entry => entry.reportname === item.reportname);

    if (existingEntry) {
      // If entry already exists, add the current item to its 'data' array
      existingEntry.data.push({
        eventtitle: item.eventtitle,
        reportdescription: item.reportdescription,
        documenturl: item.documenturl,
        imagepath: item.imagepath || "https://midocfileserver.blob.core.windows.net/midocdev/",
        reportname: item.reportname,       
        performdate: item.performdate || "-",
        longtext: item.longtext,
        eventtitle: item.eventtitle,
        documentid: item.documentid,
        documentdate: item.documentdate || "-",
        verifieddate: item.verifieddate || "-",
        reportid:item.reportid,
        resultvalue: item.resultvalue,

      });
    } else {
      // If entry doesn't exist, create a new entry
      formattedData.push({
        reportname: item.reportname,
        data: [
          {
            eventtitle: item.eventtitle ,
            reportdescription: item.reportdescription,
            documenturl: item.documenturl,
            imagepath: item.imagepath || "https://midocfileserver.blob.core.windows.net/midocdev/",
            reportname: item.reportname,
            resultvalue: item.resultvalue,
            performdate: item.performdate || "-",
            longtext: item.longtext,
            eventtitle: item.eventtitle,
            documentid: item.documentid,
            documentdate: item.documentdate || "-",
            verifieddate: item.verifieddate || "-",
            reportid:item.reportid,
          }
        ]
      });
    }
  });

  return formattedData;
};

export default labsReportViewFormatting;