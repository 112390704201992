import formAPISocialHistoryDrugs from "./socialhistory";
import formAPISocialHistory from "./socialhistory";


var formattedOutput = null;

const formAPIModelSocialHistoryDrugNames = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data?.map(item => formAPISocialHistoryDrugs(item));


  return formattedOutput
};

export default formAPIModelSocialHistoryDrugNames