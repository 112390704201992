export default function formAPIModeldemogrpich({
    bmi,
    height,
    weight,
    weightunit,
    heightunit
})
{
  return Object.freeze({
    bmi,
    height,
    weight,
    weightunit,
    heightunit
  });
}