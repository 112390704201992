import  FetchData, { } from '../../client';
import  formapimodalvitals from '../../domains/vitalcharts/vitalcharts';
import {getLoginInfo, getUserID}  from '../../../auth';

const FetchVitalsData = async (inputData, type) => {
  try {
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
      //console.log(inputData)
    const result = await FetchData("midoc_getpatientvitals", inputData);
    console.log(result,"vitals db res")
    if (result.output.data) {
    var finalOutput = formapimodalvitals(result.output.data, type)
    }
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const AddVitalData = async (inputData) => {
  try {
    
    inputData.patientid = getLoginInfo();
    inputData.activeuserid = getUserID();
    //console.log(inputData,"vitals add db inputData")
    const result = await FetchData("midoc_addpatientvitals", inputData);
    //console.log(result,"vitals add db res")
    // if (result.output.data) {
    // var finalOutput = formapimodalvitals(result.output.data)
    // }
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchAIAnalysis = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo()
  
    const result = await FetchData("midoc_ai_vitalanalysis", inputData);
    //console.log(result,"AI Response")
    // if (result.output.data) {
    // var finalOutput = formapimodalvitals(result.output.data)
    // }
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchAIAnswer = async (inputData) => {
  try {
    inputData.patientid = getLoginInfo()
    const result = await FetchData("midoc_ai_describeprompts", inputData);
    //console.log(result,"AI Chat Response")
    // if (result.output.data) {
    // var finalOutput = formapimodalvitals(result.output.data)
    // }
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

export {FetchVitalsData, AddVitalData, FetchAIAnalysis, FetchAIAnswer};
