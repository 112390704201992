import {
  Flex,
  VStack,
  Box,
  Image,
  Text,
  Spacer,
  Center,
  Divider,
} from "@chakra-ui/react";
import { useRef, useState,useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Logout } from "../../middleware/services/login/index";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import DynamicModal from "../../components/dynamic-modal";
import { PrimaryButton } from "../../components";
import { FetchRefferal } from '../../middleware/services/profile/account'
import CopyToClipboard from "react-copy-to-clipboard";
import { COLORS } from "../../components/styles/colors";


function ProfileSideBar() {
  const [activeLink, setActiveLink] = useState(null);
  var navigate = useNavigate();
  var [patientid, setPatientid] = useState();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const referFriend = useRef();
  const [data, setData] = useState("");
  const [copied, setCopied] = useState(false);



  const location = useLocation();
  const linkStyle = {
    textDecoration: "none",
    color: "black",
    display: "flex",
    alignItems: "center",
    height: "100%",
    padding: "0 15px",
    fontSize: "14px",
    fontWeight: "600",
    height: "48px",
  };

  const activeLinkStyle = {
    ...linkStyle,
    border: "1px solid #E6E7E9",
    background: "white",
    borderRadius: "12px",
    color: "#1C4EC5",
  };
  
  const handleNavLinkClick = (to) => {
    setActiveLink(to);
  };
  const handleLogout = async () => {
    try {
      const result = await Logout({
        patientid,
      });
      //console.log(result, "logout");
      showLoader();
      if (result.output.result === "success") {
        localStorage.clear();
        sessionStorage.clear();

        navigate("/login", { replace: true });
        dismissLoader();
      } else {
        //alert("No data");
      }
    } catch (error) {
      console.error("Error in DashboardIndex:", error);
    }
  };
  const copyURLToClipBoard =  () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
};

const referAFriendFunction = ()=>{
  showLoader();
  var input = {};
  var fetchData = async () => {
    try {
      var result = await FetchRefferal(input)
      //console.log(result, " ref modified data")
      if (result.output.referralcode) {
        setData(result.output.referralcode)
      } else {
        setData("")
        //  showErrorToast("Something went wrong!")
      }

    } catch (error) {
      console.log(error, "error in fetching account data")
    }
    dismissLoader();
  }

  fetchData();
  referFriend.current.openModal()
}
  return (
    <>
      {isLoading && <Loader />}
      <VStack
        minW="250px"
        h="100%"
        bg="#FCFCFD"
        borderRight="1px solid #E6E7E9"
        p={6}
        spacing={8}
        align="stretch"
        overflow="hidden auto"
      >
        <Box as="aside">
          <NavLink
            to="/profile"
            style={
              activeLink === "/profile" || location.pathname === "/profile"
                ? activeLinkStyle
                : linkStyle
            }
            onClick={() => handleNavLinkClick("/profile")}
          >
            <Image
              boxSize="18px"
              objectFit="contain"
              src={
                activeLink === "/profile" || location.pathname === "/profile"
                  ? "/assets/imgs/account_circle.png"
                  : "/assets/imgs/account-gray.png"
              }
              alt="Home"
            />
            <Box as="span" fontSize="14px" ml="3">
              Account
            </Box>
          </NavLink>
          <NavLink
            to="/rewards"
            style={
              activeLink === "/rewards" || location.pathname === "/rewards"
                ? activeLinkStyle
                : linkStyle
            }
            onClick={() => handleNavLinkClick("/rewards")}
          >
            <Image
              boxSize="18px"
              objectFit="contain"
              filter={
                activeLink === "/rewards" || location.pathname === "/rewards"
                  ? COLORS.BLUE_FILTER
                  : ""
              }
              src={
                activeLink === "/rewards" || location.pathname === "/rewards"
                  ? "/assets/svgs/rewards.svg"
                  : "/assets/svgs/rewards.svg"
              }
              alt="Rewards"
            />
            <Box as="span" fontSize="14px" ml="3">
              Rewards
            </Box>
          </NavLink>
          <NavLink
            to="/aitokens"
            style={
              activeLink === "/aitokens" || location.pathname === "/aitokens"
                ? activeLinkStyle
                : linkStyle
            }
            onClick={() => handleNavLinkClick("/aitokens")}
          >
            <Image
              boxSize="18px"
              objectFit="contain"
              filter={
                activeLink === "/aitokens" || location.pathname === "/aitokens"
                  ? COLORS.BLUE_FILTER
                  : ""
              }
              src={
                activeLink === "/aitokens" || location.pathname === "/aitokens"
                  ? "/assets/svgs/ai-star-outline.svg"
                  : "/assets/svgs/ai-star-outline.svg"
              }
              alt="AI Tokens"
            />
            <Box as="span" fontSize="14px" ml="3">
              AI Tokens
            </Box>
          </NavLink>
          <NavLink
            to="/reports"
            style={
              activeLink === "/reports" || location.pathname === "/reports"
                ? activeLinkStyle
                : linkStyle
            }
            onClick={() => handleNavLinkClick("/reports")}
          >
            <Image
              boxSize="18px"
              objectFit="contain"
              src={
                activeLink === "/reports" || location.pathname === "/reports"
                  ? "/assets/imgs/diagnosis.png"
                  : "/assets/imgs/diagnosis-gray.png"
              }
              alt="Reports"
            />
            <Box as="span" fontSize="14px" ml="3">
              Reports
            </Box>
          </NavLink>
          <NavLink
            to="/family"
            style={
              activeLink === "/family" || location.pathname === "/family"
                ? activeLinkStyle
                : linkStyle
            }
            onClick={() => handleNavLinkClick("/family")}
          >
            <Image
              boxSize="18px"
              objectFit="contain"
              src={
                activeLink === "/family" || location.pathname === "/family"
                  ? "/assets/imgs/family-blue.png"
                  : "/assets/imgs/family.png"
              }
              alt="family"
            />
            <Box as="span" fontSize="14px" ml="3">
              Family
            </Box>
          </NavLink>
          <NavLink
            to="/orders"
            style={
              activeLink === "/orders" || location.pathname === "/orders"
                ? activeLinkStyle
                : linkStyle
            }
            onClick={() => handleNavLinkClick("/orders")}
          >
            <Image
              boxSize="18px"
              objectFit="contain"
              src={
                activeLink === "/orders" || location.pathname === "/orders"
                  ? "/assets/imgs/clinical_notes-blue.png"
                  : "/assets/imgs/clinical_notes.png"
              }
              alt="Orders"
            />
            <Box as="span" fontSize="14px" ml="3">
              Orders
            </Box>
          </NavLink>
          <NavLink
            to="/profileappointment"
            style={
              activeLink === "/profileappointment" || location.pathname === "/profileappointment"
                ? activeLinkStyle
                : linkStyle
            }
            onClick={() => handleNavLinkClick("/profileappointment")}
          >
            <Image
              boxSize="18px"
              objectFit="contain"
              src="/assets/imgs/switch_account.png"
              alt="appointments"
            />
            {/* <Image boxSize="18px" objectFit="contain" src={activeLink === "/" || location.pathname === "/" ? "/assets/imgs/account_circle.png" : "/assets/imgs/account-gray.png"}  alt="Home" /> */}
            <Box as="span" fontSize="14px" ml="3">
              Appointments
            </Box>
          </NavLink>
          <NavLink
            to="/doctors"
            style={
              activeLink === "/doctors" || location.pathname === "/doctors"
                ? activeLinkStyle
                : linkStyle
            }
            onClick={() => handleNavLinkClick("/doctors")}
          >
            <Image
              boxSize="18px"
              objectFit="contain"
              src={
                activeLink === "/doctors" || location.pathname === "/doctors"
                  ? "/assets/imgs/stethoscope-blue.png"
                  : "/assets/imgs/stethoscope.png"
              }
              alt="Doctors"
            />
            <Box as="span" fontSize="14px" ml="3">
              Doctors
            </Box>
          </NavLink>
          {/* <NavLink
            to="/pharmacy"
            style={
              activeLink === "/pharmacy" || location.pathname === "/pharmacy"
                ? activeLinkStyle
                : linkStyle
            }
            onClick={() => handleNavLinkClick("/pharmacy")}
          >
            <Image
              boxSize="18px"
              objectFit="contain"
              src={
                activeLink === "/pharmacy" || location.pathname === "/pharmacy"
                  ? "/assets/imgs/medical_services-blue.png"
                  : "/assets/imgs/medical_services.png"
              }
              alt="Pharmacy"
            />
            <Box as="span" fontSize="14px" ml="3">
              Pharmacy
            </Box>
          </NavLink> */}
          <NavLink
            to="/medicalrecord"
            style={
              activeLink === "/medicalrecord" ||
              location.pathname === "/medicalrecord"
                ? activeLinkStyle
                : linkStyle
            }
            onClick={() => handleNavLinkClick("/medicalrecord")}
          >
            <Image
              boxSize="18px"
              objectFit="contain"
              src={
                activeLink === "/medicalrecord" ||
                location.pathname === "/medicalrecord"
                  ? "/assets/imgs/book_2-blue.png"
                  : "/assets/imgs/book_2.png"
              }
              alt="Home"
            />
            <Box as="span" fontSize="14px" ml="3">
              Share records
            </Box>
          </NavLink>
          <NavLink
            to="/invoices"
            style={
              activeLink === "/invoices" || location.pathname === "/invoices"
                ? activeLinkStyle
                : linkStyle
            }
            onClick={() => handleNavLinkClick("/invoices")}
          >
            <Image
              boxSize="18px"
              objectFit="contain"
              src="/assets/imgs/request_quote.png"
              alt="invoices"
            />
            {/* <Image boxSize="18px" objectFit="contain" src={activeLink === "/" || location.pathname === "/" ? "/assets/imgs/account_circle.png" : "/assets/imgs/account-gray.png"}  alt="Home" /> */}
            <Box as="span" fontSize="14px" ml="3">
              Invoices
            </Box>
          </NavLink>
          <NavLink
            to="/subscription"
            style={
              activeLink === "/subscription" ||
              location.pathname === "/subscription"
                ? activeLinkStyle
                : linkStyle
            }
            onClick={() => handleNavLinkClick("/subscription")}
          >
            <Image
              boxSize="18px"
              objectFit="contain"
              src={
                activeLink === "/subscription" ||
                location.pathname === "/subscription"
                  ? "/assets/imgs/currency_exchange-blue.png"
                  : "/assets/imgs/currency_exchange.png"
              }
              alt="Home"
            />
            <Box as="span" fontSize="14px" ml="3">
              Subscription
            </Box>
          </NavLink>
          {/* <NavLink to="" style={activeLink === "" || location.pathname === "" ? activeLinkStyle : linkStyle}  onClick={() => handleNavLinkClick("/")}>
          <Image boxSize="18px" objectFit="contain" src="/assets/imgs/credit_card.png" alt="Home" />
          <Image boxSize="18px" objectFit="contain" src={activeLink === "/" || location.pathname === "/" ? "/assets/imgs/account_circle.png" : "/assets/imgs/account-gray.png"}  alt="Home" />
          <Box as="span" fontSize="14px" ml="3">Payment methods</Box>
        </NavLink> */}
          {/* <NavLink to="/refer" style={activeLink === "/refer" || location.pathname === "/refer" ? activeLinkStyle : linkStyle}  onClick={() => handleNavLinkClick("/")}> */}
          <NavLink
            style={linkStyle}
            onClick={referAFriendFunction}
          >
            <Image
              boxSize="18px"
              objectFit="contain"
              src="/assets/imgs/group.png"
              alt="Home"
            />
            {/* <Image boxSize="18px" objectFit="contain" src={activeLink === "/refer" || location.pathname === "/refer" ? "/assets/imgs/account_circle.png" : "/assets/imgs/account-gray.png"}  alt="Home" /> */}
            <Box as="span" fontSize="14px" ml="3">
              Refer a friend
            </Box>
          </NavLink>

          <NavLink
            to="/videolibrary"
            style={
              activeLink === "/videolibrary" || location.pathname === "/videolibrary"
                ? activeLinkStyle
                : linkStyle
            }
            onClick={() => handleNavLinkClick("/videolibrary")}
          >
            <Image
              boxSize="18px"
              objectFit="contain"
              src={
                activeLink === "/videolibrary" || location.pathname === "/videolibrary"
                  ? "/assets/imgs/notifications_unread-blue.png"
                  : "/assets/imgs/notifications_unread.png"
              }
              alt="videolibrary"
            />
            <Box as="span" fontSize="14px" ml="3">
             Video Library
            </Box>
          </NavLink>

          <NavLink
            to="/alerts"
            style={
              activeLink === "/alerts" || location.pathname === "/alerts"
                ? activeLinkStyle
                : linkStyle
            }
            onClick={() => handleNavLinkClick("/alerts")}
          >
            <Image
              boxSize="18px"
              objectFit="contain"
              src={
                activeLink === "/alerts" || location.pathname === "/alerts"
                  ? "/assets/imgs/notifications_unread-blue.png"
                  : "/assets/imgs/notifications_unread.png"
              }
              alt="alerts"
            />
            <Box as="span" fontSize="14px" ml="3">
              Alerts
            </Box>
          </NavLink>
          <NavLink
            to=""
            style={
              activeLink === "" || location.pathname === ""
                ? activeLinkStyle
                : linkStyle
            }
            onClick={() => handleNavLinkClick("/")}
          >
            <Image
              boxSize="18px"
              objectFit="contain"
              src="/assets/imgs/logout.png"
              alt="Home"
            />
            <Box
              as="span"
              color="#AA2E26"
              onClick={handleLogout}
              fontSize="14px"
              ml="3"
            >
              Logout
            </Box>
          </NavLink>
        </Box>
      </VStack>

      <DynamicModal
        hasHeader={false}
        hasFooter={false}
        hasCloseBtn={false}
        modalBodyStyle={{ w: "360px", maxWidth: "360px", m: "auto",  p:"24px" }}
        ref={referFriend}
        modalBody={
          <>
            <Box w="100%">
              <Flex w="100%" justifyContent="center" alignItems="center">
                <Box display="flex" alignItems="center" zIndex="6">
                  <Box borderRadius="full" mr="-8%" border="2px solid #E6E7E9">
                    <Image
                      boxSize="40px"
                      borderRadius="full"
                      border="1px solid rgb(183 183 183)"
                      src="/assets/svgs/Avatar (4).svg"
                    />
                  </Box>
                  <Box borderRadius="full" border="2px solid #E6E7E9">
                    <Image
                      boxSize="64px"
                      borderRadius="full"
                      border="1px solid rgb(183 183 183)"
                      src="/assets/svgs/Avatar (2).svg"
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  borderRadius="full"
                  mx="-8%"
                  border="2px solid #E6E7E9"
                  zIndex="10"
                >
                  <Image
                    boxSize="74px"
                    borderRadius="full"
                    border="1px solid rgb(183 183 183)"
                    src="/assets/svgs/Avatar.svg"
                  />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                  zIndex="6"
                >
                  <Box
                    borderRadius="full"
                    border="2px solid #E6E7E9"
                    zIndex="3"
                  >
                    <Image
                      boxSize="64px"
                      borderRadius="full"
                      border="1px solid rgb(183 183 183)"
                      src="/assets/svgs/Avatar (1).svg"
                    />
                  </Box>
                  <Box
                    borderRadius="full"
                    ml="-8%"
                    border="2px solid #E6E7E9"
                    zIndex="2"
                  >
                    <Image
                      boxSize="40px"
                      borderRadius="full"
                      border="1px solid rgb(183 183 183)"
                      src="/assets/svgs/Avatar (3).svg"
                    />
                  </Box>
                </Box>
              </Flex>
              <Box py="10px">
                <Text textAlign="center" fontSize="24px" fontWeight="600">Refer a friend</Text>
                <Text textAlign="center" fontSize="14px" fontWeight="400">
                  Refer a friend for a free month subscription upto 12 months!
                </Text>
              </Box>
              <Divider my="10px" />
              <Text py="10px" textAlign="center" fontSize="14px" fontWeight="600">Share this link with your friend</Text>
              <Box
                w="100%"
                as="button"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                border="1px solid rgba(230, 231, 233, 1)"
                p="14px 16px"
                borderRadius="10px"
              >
                <Box
                  as="span"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  textAlign="left"
                  whiteSpace="nowrap"
                  w="75%"
                >
                  {data && data? data:""}
                </Box>
                {!copied ? (
                <Box as="button" pr="5px">
                  <CopyToClipboard
                     text={data}
                     onCopy={copyURLToClipBoard}>
                       <Image src="/assets/imgs/copy.png" />
                  </CopyToClipboard>
                </Box>
                ):(
                 <p>Copied</p>
                )}
              </Box>

              <PrimaryButton
                variant="grayBtnFull"
                btnStyle={{
                  marginTop: "20px",
                  marginBottom: "0",
                  color: "#1C4EC5",
                  width: "100%!important",
                  height: "40px!important",
                }}
                buttonText="Dismiss"
                onClick={() => referFriend.current.closeModal()}
              />
            </Box>
          </>
        }
      />
    </>
  );
}

export default ProfileSideBar;
