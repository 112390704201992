import formAPIModeldemogrpich from "../demograpich/demographic";
import formAPIModelchiefcomplaint from "../chiefcomplaint/chiefcomplaint";
import formAPIModelhistoryofpresentillness from "../historyofpresentillness/historyofpresentillness";
import formAPIModelphysicalexam from "../physicalexam/physicalexams";
import formAPIModeldoctorsassessment from "../doctorsassessment/doctorsassessments";
import formAPIModeltreatmentplan from "../treatmentplan/treatmentplans";
import formAPIModelpastmedicalhistory from "../pastmedicalhistory/pastmedicalhistories";
import formAPIModelpastsurgicalhistory from "../pastsurgicalhistory/pastsurgicalhistories";
import formAPIModelfamilyhistory from "../familyhistory/familyhistories";
import formAPIModelsocialhistory from "../socialhistory/socialhistories";
import formAPIModelcurrentmedications from "../currentmedications/currentmedications";
import formAPIModelvitals from "../vitals/vitals";
import formAPIModelinvestigation from "../investigations/investigations";
import formAPIModellabs from "../labs/lab";
import formAPIModelimaging from "../imaging/imaging";
import formAPIModeltreatmentteam from "../treatmentteam/treatmentteams";
import formAPIModelreportsData from "../reportdata/reportsdata";
import formAPIpatientData from "../patientinfo/patientinfo";
import formAPIModelreviewofsystem from "../reviewofsystem/reviewofsystems";

export default function formapimodalhealth({
  demographics,
  chiefcomplaint,
  historyofpresentillness,
  physicalexam,
  doctorsassessment,
  treatmentplan,
  pastmedicalhistory,
  pastsurgicalhistory,
  familyhistory,
  socialhistory,
  currentmedications,
  vitals,
  investigations,
  labs,
  imaging,
  treatmentteam,
  reportdata,
  patientinfo,
  reviewofsystem,
  orderprocedure,
  orderlab,
  followupdetail,
  orderprescription,

})
{
return Object.freeze({
  demographics:demographics !== null ? formAPIModeldemogrpich(demographics) : {},
  chiefcomplaint:chiefcomplaint !== null ? formAPIModelchiefcomplaint(chiefcomplaint) : null,
  historyofpresentillness: historyofpresentillness !== null ? historyofpresentillness: null,
  physicalexam : physicalexam != null ?  formAPIModelphysicalexam(physicalexam):null,
  reviewofsystem : reviewofsystem != null ?  formAPIModelreviewofsystem(reviewofsystem):null,
  doctorsassessment : doctorsassessment !== null ? formAPIModeldoctorsassessment(doctorsassessment) : null,
  treatmentplan: treatmentplan !== null ? formAPIModeltreatmentplan(treatmentplan) : [],
  pastmedicalhistory: pastmedicalhistory !== null ? formAPIModelpastmedicalhistory(pastmedicalhistory) : [],
  pastsurgicalhistory:pastsurgicalhistory !== null ? formAPIModelpastsurgicalhistory(pastsurgicalhistory) : [],
  familyhistory:familyhistory !== null ? formAPIModelfamilyhistory(familyhistory) : [],
  socialhistory:socialhistory !== null ? formAPIModelsocialhistory(socialhistory) : [],
  currentmedications:currentmedications !== null ? formAPIModelcurrentmedications(currentmedications) : [],
  vitals:vitals !== null ? formAPIModelvitals(vitals) : {},
  investigations:investigations !== null ? formAPIModelinvestigation(investigations) : [],
  labs:labs !== null ? formAPIModellabs(labs) : [],
  imaging:imaging !== null ? formAPIModelimaging(imaging) : {},
  treatmentteam:treatmentteam !== null ? formAPIModeltreatmentteam(treatmentteam) : [],
  patientinfo:patientinfo && formAPIpatientData(patientinfo),
  reportdata: reportdata &&  formAPIModelreportsData(reportdata),
});
}