import formAPIFamilyHistory from "./familyhistory";


var formattedOutput = null;

const formAPIModelFamilyHistory = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data?.map(item => formAPIFamilyHistory(item));


  return formattedOutput
};

export default formAPIModelFamilyHistory