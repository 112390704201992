import formAPIMedicalCondition from "../medicalconditions/medicalcondition";
import formAPIFamilyMedicalCondition from "./medicalcondition";


var formattedOutput = null;

const formAPIModelFamilyMedicalCondition = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data?.map(item => formAPIFamilyMedicalCondition(item));


  return formattedOutput
};

export default formAPIModelFamilyMedicalCondition