import formAPIModelMedicalCondition from '../../domains/medicalconditions/medicalconditions.';
import moment from "moment";
export default function formAPIfamilyhistory({
    isadopted,
    isdeceased,
    relation,
    relation_cd,
    dob,
     updateddate,
     fullname,
     medicalcondition
   

})
{ 
  return Object.freeze({
    isadopted,
    isdeceased,
    relation,
    relation_cd,
    dob,
    updateddate,   
    fullname,
    medicalcondition : medicalcondition !== null ? formAPIModelMedicalCondition(medicalcondition) : [],
  });
}