import { Box, Divider, Flex, Image, Text, useEditable, useStatStyles } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import Loader from "../../components/loader/loader";
import { PrimaryButton, Toaster } from "../../components";
import ProfileSideBar from "./profileSidebar";
import useLoader from "../../components/loader/loaderstates";
import Footer from "../../components/footer";
import GrayCard from "../../components/card";
import { COLORS } from "../../components/styles/colors";
import { FetchTokens } from "../../middleware/services/profile/rewards";
import { useNavigate } from "react-router-dom";
import { px } from "framer-motion";
import { displayMillion_ThoudandValues } from "../../auth";

const AITokensIndex = () => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();
  const [data , setData] = useState("")
  const [boxColor , setBoxColor] = useState("#8aa3e2")
  var navigate = useNavigate();

  const goldBox = "#FCCE35";
  const bronzeBox = "#E98D3A";
  const silverBox = "#6A707D";
  const platinumBox = "#4A67AE";
  const emptyBox = "#E6E7E9";
  const greenBox = "#14C585";

  const [totalPoints , setTotalPoints] = useState("");
  const [currentPoints, setCurrentPoints] = useState("");

  useEffect(() => {
    showLoader();

    var input = {}
    var getTokens = async () => {
      try {
        var result = await FetchTokens(input);
        if (result) {
         setData(result);
         setCurrentPoints(result.remainingtokens)
         setTotalPoints(result.totaltokens)
        } else {
          showErrorToast("Something went wrong!")
        }

      } catch (error) {
        console.log(error, "error in fetching account data")
      }
      dismissLoader();
    }

    getTokens();
  }, [])
 const totalBoxes = 30;
  const filledBoxes = totalPoints !== null ? Math.floor((currentPoints / totalPoints) * totalBoxes) : 30 ;
 
  const redirectToSubscription = ()=>{
    showLoader()
    setTimeout(() => {
      
    navigate("/subscription");
    localStorage.setItem("openPlanModal" , true)
    }, 1000);
    dismissLoader()
  }
  const commaValues = (value)=>{
    
    var updatedValue = value;
    var stringValue = value.toString();
    var length = value.toString().length
  
    if(length == 5){
      updatedValue= stringValue.slice(0,2) + "," + stringValue.slice(2)
    }
    return updatedValue;
  }

  return (
    <Box w="100%">
      <Box top="0" zIndex="banner">
        <Navbar />

        {isLoading && <Loader />}
        <SecondaryNav
          sideBarWidth="250px"
          defaultHeader="Profile"
          defaultSecondayHeader="Tokens"
        />
      </Box>
      <Box h="calc(100vh - 140px)" overflow="hidden auto">
        <Flex>
          <Box p="0" width="250px">
            <ProfileSideBar />
          </Box>



          <Flex flexDir="column" gap="20px" 
            backgroundImage={COLORS.PLATINUM_REWARD}
            w="100%" p="24px 32px">
            {/* Rewards and points start */}
            <Box w="100%">
                <Box
                    display="flex"
                    w="100%"
                    gap="20px"
                    overflowX="hidden"
                >
                    {/* Rewards start */}
                    <Box w="100%">
                    <Box>
                        <GrayCard
                        hasHeader={false}
                        cardHeader="Rewards"
                        cardHeaderIcon="/assets/imgs/stars.png"
                        minH="150px"
                        bodyStyle={{ p: "0", }}
                        cardBody={
                            <>
                            <Box p="20px">
                                <Box display="flex" justifyContent="space-between">
                                <Box as="p" fontSize="28px" fontWeight="700">
                                    {data.planname?data.planname:"-"}
                                </Box>
                               
                                </Box>
                                <Box>
                                <Text fontSize="12px" fontWeight="600">                   
                                       <strong>{data.remainingtokens ? displayMillion_ThoudandValues(data.remainingtokens):"0"}</strong> tokens available from <strong>{data.totaltokens ? displayMillion_ThoudandValues(data.totaltokens):"0"}</strong>{" "}
                                </Text>
                                </Box>
                                
                            </Box>
                            </>
                        }
                        hasFooter={false}
                        simpleFooter={true}
                        footerArea={<>
                            <Box display="flex" justifyContent="space-between" flexWrap="nowrap" gap="5px" w="100%">
                              {
                                  Array.from({ length: totalBoxes }).map((_, index) => (
                                      <Box
                                          key={index}
                                          as="div"
                                          bg={index < filledBoxes ? boxColor : emptyBox}
                                          w={`${100 / 33}%`}
                                          h={`${100 / 33}%`}
                                          minH="12px"
                                          borderRadius="2px"
                                      />
                                  ))
                              }
                          </Box>

                        </>}
                        />
                    </Box>
                    </Box>
                    {/* Rewards end */}

                {/* Points start */}
                <Box w="100%">
                  <Box>
                    <GrayCard
                      hasHeader={false}
                      simpleFooter={true}
                      minH="150px"
                      cardHeader="Points"
                      cardHeaderIcon="/assets/imgs/stars.png"
                      bodyStyle={{ p: "0" }}
                      cardBody={
                        <>
                          <Box p="20px" paddingBottom="0px">
                            <Box display="flex" justifyContent="space-between">
                              <Box as="p" fontSize="28px" fontWeight="700">
                              {data.remainingtokens ? displayMillion_ThoudandValues(data.remainingtokens):"0"}
                              </Box>
                            </Box>
                            <Box>
                              <Text fontSize="12px" fontWeight="600">
                                Tokens available
                              </Text>
                            </Box>
                          </Box>
                        </>
                      }
                      hasFooter={false}
                      footerArea={
                        <>
                        
                        {data &&  data.planname != "Premium" &&
                          <PrimaryButton
                             btnStyle={{ h:"40px" , px:"20px" , py:"10px",  fontSize:"14px",  m:'0'}}
                            buttonText="Upgrade Plan"
                            onClick={redirectToSubscription}
                            isDisabled={false}
                          />
                        }
                        </>
                      }
                    />
                  </Box>
                </Box>
                {/* Points end */}
              </Box>
            </Box>
            {/* Rewards and points end */}

             {/* Rewards history start */}
             <Box w="100%">
                <Box p="24px" border="1px solid #E6E7E9" borderRadius="12px" bg="white">
                    <Text fontSize="12px" fontWeight="600">TOKENS HISTORY</Text>
                  {Array.isArray(data.data) && data.data.length > 0 ? (
                    data.data.map((item, index) => (
                      <Flex key={index} gap="15px" py="15px" borderBottom="1px solid #E6E7E9">
                        <Box boxSize="40px" borderRadius='full' display="flex" justifyContent="center" alignItems="center" border="1px solid #E6E7E9">
                            <Image boxSize="20px" src="/assets/svgs/ai-stars.svg" />
                        </Box>
                        <Box>
                            <Text fontSize="14px" fontWeight="600">{item.pointsdebited? commaValues(item.pointsdebited)+ " tokens":"-"} {item.entitytype? "("+item.entitytype+")":""}</Text>
                            <Text fontSize="12px" fontWeight="500" color="#384052">{item.createddate}</Text>
                        </Box>
                      </Flex>
                    ))
                  ) : (
                    <Text>No data available</Text>
                  )}
                </Box>

            </Box>
            {/* Rewards history end */}
          </Flex>
        </Flex>
        <Footer />
      </Box>
    </Box>
  );
};

export default AITokensIndex;
