import moment from "moment";
export default function formAPItreatmentteam({
    entitytype,
    doctorname,
    date,
    ismidocdoctor,
    doctorid,
    doctorimage,
    imagepath,
    isdoctoractive
   

})
{
  return Object.freeze({
    entitytype,
    doctorname,
    date: date,
    ismidocdoctor,
    doctorid,
    doctorimage,
    imagepath,
    isdoctoractive
   
  });
}