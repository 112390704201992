import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Input,
  FormControl,
  Image,
  InputRightElement,
  FormLabel,
  InputGroup,
  useOutsideClick,
} from "@chakra-ui/react";
import "react-day-picker/dist/style.css";
import { DayPicker } from "react-day-picker";
import format from "date-fns/format";
import { isValid, isAfter, isBefore } from "date-fns";
import moment from "moment";

const COLORS = {
  INPUT_FOCUS_DARK: "#384052",
  PRIMARY_WHITE: "#ffffff",
  BTN_DISABLE: "#f0f0f0",
  BTN_DISABLE_TEXT: "#a0a0a0",
};

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
  fontSize: "sm",
  color: COLORS.INPUT_FOCUS_DARK,
  opacity: "50%",
};

const inputStyles = {
  borderRadius: "0px",
  background: COLORS.PRIMARY_WHITE,
  boxShadow: "none",
  width: "100%",
  height: "48px",
  _disabled: {
    bg: COLORS.BTN_DISABLE,
    color: COLORS.BTN_DISABLE_TEXT,
    cursor: "not-allowed",
  },
  _hover: {
    borderRadius: "10px",
    border: `2px solid`,
    borderColor: COLORS.INPUT_FOCUS_DARK,
    outline: "none",
    bg: "none",
  },
  _focus: {
    borderRadius: "10px",
    border: "2px solid",
    borderColor: COLORS.INPUT_FOCUS_DARK,
    outline: "none",
  },
};

function DatePickerMonthYear({
  id,
  onDateChange,
  placeholderProp,
  defaultDate,
  value, // Accept the value prop to control the date picker
  dateWidth = "100%",
  onChange,
  leftPos,
  calendarStlye,
  datePickerStyle,
  disableFutureDates,
  minDate,
  maxDate,
  maxYear,
  minYear,
  datePickerInputStyle,
}) {
  const [selected, setSelected] = useState(
    defaultDate ? moment(defaultDate, "MM-yyyy").toDate() : null
  );
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const outSideClick = useRef(null);

  const [year, setYear] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setSelected(value ? moment(value, "MM-yyyy").toDate() : null); // Update internal state when the value prop changes
    // alert("change")
    setIsOpen(true);
  }, [value]);

  const handleInputClick = () => {
    setDatePickerVisible((prev) => !prev);
    setIsOpen(true);
  };

  const handleDateSelect = (date) => {
    if (date) {
      setSelected(date);
      if (onDateChange) {
        onDateChange(date);
      }
    }
    setDatePickerVisible(false);
  };
  let defaultMonth;
  let defatultMonthLocal = localStorage.getItem("defaultMonthDatePicker");
  if (defatultMonthLocal) {
    defaultMonth = defatultMonthLocal;
  } else {
    defaultMonth = moment().month(0).startOf("month").toDate();
  }

  const handleDateSelectCustom = (date) => {
    defaultMonth = date;
    localStorage.setItem("defaultMonthDatePicker", defaultMonth);
    setYear(date.getFullYear());
    if (date) {
      setSelected(date);
      if (onDateChange) {
        onDateChange(date);
      }
    }
    //  alert(year + " " )
    //  if(year != date.getFullYear()){
    //setDatePickerVisible(false);
    // }
  };

  useEffect(() => {
    let count = 0;

    const dropdownContainer = document.querySelector(
      "#month-year .rdp .rdp-months .rdp-month .rdp-caption .rdp-caption_dropdowns"
    );

    if (dropdownContainer) {
      dropdownContainer.addEventListener("click", function (event) {
        if (event.target.name == "months") {
          count = 0;
        }
        if (event.target != "" && event.target.name == "years") {
          if (count > 0) {
            setTimeout(() => {
              setDatePickerVisible(false);
            }, 250);
          }
          count = count + 1;
        } else if (event.target != "" && event.target.name == "months") {
          count = 0;
        }
      });
    }
  }, [year, value]);

  const isLabelActive = selected != null;

  useOutsideClick({
    ref: outSideClick,
    handler: () => isDatePickerVisible && setDatePickerVisible(false),
  });

  const css = `
  .rdp-day_selected {
    background-color: #1F50C6
  }
    #month-year .rdp .rdp-months .rdp-month .rdp-table {
    display:none
    }

     #month-year .rdp .rdp-months .rdp-month .rdp-caption .rdp-nav {
    display:none
    }
    
  `;

  // const disableDates = date => {
  //   // Disable future dates
  //   return isAfter(date, new Date());
  // };
  const disableDates = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date for comparison

    if (disableFutureDates) {
      return isAfter(date, today); // Disable all future dates
    }

    if (minDate && isBefore(date, today)) {
      return true; // Disable all dates before the minimum date
    }

    if (maxDate && isAfter(date, new Date(maxDate))) {
      return true; // Disable all dates after the maximum date
    }

    return false;
  };

  return (
    <>
      <style>{css}</style>
      <FormControl
        position="relative"
        width={dateWidth}
        ref={outSideClick}
        {...datePickerStyle}
        id="month-year"
      >
        <InputGroup borderRadius="0">
          <Input
            sx={{ ...inputStyles, ...datePickerInputStyle }}
            type="text"
            id={id}
            defaultValue={defaultDate}
            value={selected ? moment(selected).format("MM-yyyy") : ""}
            onClick={handleInputClick}
            onChange={onChange}
            readOnly
            placeholder={!isLabelActive ? placeholderProp : ""}
            p={isLabelActive ? "12px 10px 0 10px" : "0px 10px 0 10px"}
            textOverflow="ellipsis"
            paddingRight="20px"
          />
          <InputRightElement
            h="100%"
            children={
              <Image
                mr="8px"
                cursor="pointer"
                onClick={handleInputClick}
                w="15px"
                src="/assets/imgs/double-arrow.png"
                alt=""
              />
            }
          />
        </InputGroup>
        <FormLabel
          sx={{
            ...activeLabelStyles,
            position: "absolute",
            top: "25px",
            pointerEvents: "none",
            opacity: isLabelActive ? 0.5 : 0,
            zIndex: isLabelActive ? 1 : -1,
            transition: "all 0.2s ease-out",
            left: "6px",
            ...leftPos,
          }}
        >
          {placeholderProp}
        </FormLabel>
        {isDatePickerVisible && (
          <Box
            position="absolute"
            borderRadius="0 0 15px 15px"
            zIndex="9999"
            bg="white"
            boxShadow="0px 10px 15px -3px rgba(0,0,0,0.1)"
            transform="scale(0.9)"
            right="-18px"
            top="43px"
            {...calendarStlye}
            id="month-year"
          >
            <DayPicker
              mode="single"
              fromYear={minYear ? minYear : moment.year()}
              toYear={maxYear ? maxYear : 2060}
              captionLayout="dropdown-buttons"
              selected={selected}
              onSelect={handleDateSelect}
              disabled={disableDates}
              onMonthChange={handleDateSelectCustom}
              defaultMonth={defaultMonth}
            />
          </Box>
        )}
      </FormControl>
    </>
  );
}

export default DatePickerMonthYear;
