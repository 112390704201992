import moment from "moment";
export default function formAPIInvoices({
    amount,
    invoiceurl,
    createddate,
    id,
    imagepath,
    isactive,
    paymenttype,
    patientid,

    
    // invoicedate,
    // currencyunit,
    // invoicename,
   

})
{
  return Object.freeze({
    amount:amount+".00",
    invoiceurl,
    createddate,
    id,
    imagepath,
    isactive,
    paymenttype,
    patientid,

    // invoicedate : moment(invoicedate).format("Do MMM YYYY"),
    // currencyunit,
    // invoicename : invoicename ? invoicename : "-"
   
  });
}