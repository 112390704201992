import moment from "moment";
export default function formAPISocialHistoryDrugs({
   name,
   

})
{ 
  return Object.freeze({
   
    name,
   
  });
}