
import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import {
  Flex,
  Box,
  IconButton,
  Image,
  useColorMode,
  Spacer,
  VStack,
  HStack,
  Avatar,
  Badge,
  Text,
  Divider,
  InputGroup,
  Input,
  InputRightElement,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Card,
  CardFooter,
  Button,
  useBreakpointValue,
  Textarea,
  Link,
} from "@chakra-ui/react";
import { PrimaryButton, PrimaryButtonIcon, SearchBar } from "../../components";
import TypingLoader from "../../components/typing-loader";
import TextArea from "../../components/text-area";
import { COLORS } from "../../components/styles/colors";
import AiModal from "../../components/ai-modal";
import ModalRight from "../../components/modal-right";
import Footer from "../../components/footer";
import DoctorCard from "../../components/doctor-card";
import GrayCard from "../../components/card";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { fetchDoctors, FetchChatData, FetchDoctorChatMedia, AddMessage, FetchPatientChatMedia , updatechatmessageread} from "../../middleware/services/messages";
import moment from "moment";
import { downloadDocument, uploadImageCommonFunction, useIsIOS } from "../../auth";
import Toaster from "../../components/toaster";
import { Global, css } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  CreatePaymentIntent,
  SavePaymentInitialCall,
} from "../../middleware/services/payment/payment";
import CheckoutForm from "./checkout-form";
import { CONFIG } from '../../appconfig/appconfig';




const ChatIndex = () => {
  const tabs = ["one", "two", "three", "seven", "six", "four", "nine"];
  const [activeTab, setActiveTab] = useState("appointments");
  const docProfile = React.useRef();
  var [patientid, setPatientID] = useState("");
  var [doctorid, setDoctorID] = useState("");
  var [doctorname, setDoctorName] = useState("");
  var [data, setData] = useState(null);
  var [doctorsdata, setDoctorsData] = useState(null);
  var [chatsdata, setChatData] = useState([]);
  var [ChatMedia, setChatMedia] = useState(null);
  var [ChatHeader, setChatHeader] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [search, setSearch] = useState("");
  var [activechatbox, setActivechatbox] = useState("");
  const chatContainerRef = React.useRef(null);
  const messageRef = useRef(null);
  const lastMessageRef = useRef(null);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const { showSuccessToast, showErrorToast } = Toaster();
  var [doctormessage, setDoctorMessage] = useState("");
  const [showhidesearch, ShowHideSearch] = useState(false);
  const [chatInputHeight, setChatInputHeight] = useState('48px');
  const [isDoctorListVisible, setIsDoctorListVisible] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const textareaRef = useRef(null);
  const [height, setHeight] = useState('48px');
  const [messageValue, setMessageValue] = useState("")

  const { id, name,appointmentDate , appointmentId, appointmentTime } = location.state || {};
  const [screenType, setScreenType] = useState('web');
  const isIos = useIsIOS();

  var [ChatEnableDetails, setChatEnableDetails] = useState(null);
  var [DoctorCurrency, setDoctorCurrency] = useState("");
  const [clientSecret, setClientSecret] = useState(null);
    const [stripePromise, setStripePromise] = useState(null);
    var [refreshchat, setRefreshChat] = useState(true);

    var [isDoctorActive, setIsDoctorActive] = useState(0);
    const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setScreenType('mobile');
      } else {
        setScreenType('web');
      }
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    const handleResize = () => {
      if (chatContainerRef.current) {
        const chatSectionHeight = window.innerHeight - 73 - 91 - chatInputHeight;
        chatContainerRef.current.style.height = `${chatSectionHeight}px`;
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [chatInputHeight]);
  useEffect(() => {
    if (messageRef.current) {
      setIsEmpty(messageRef.current.textContent.trim() === '');
    }
  }, []);

  const maxHeight = 100; // 6.25rem converted to pixels (6.25 * 16)
  // const minHeight = 80;

  const handleInputChange = (e) => {
    if (messageRef.current) {
      const value = messageRef.current.textContent;
      // setChatInputHeight(`${Math.min(e.target.scrollHeight, maxHeight)}px`);
      messageRef.current.style.height = "48px"; // Reset the height
      messageRef.current.style.height = `${Math.min(messageRef.current.scrollHeight, maxHeight)}px`;
      setIsEmpty(value.trim() === '');
    }
  };
useEffect(() => {
  FetchDoctors();     
}, [screenType]);
const FetchDoctors = async () => {
  showLoader()
  try {
    const result = await fetchDoctors({
      patientid,
    });
    if (result != null) {
      setDoctorsData(result);
      if (screenType === 'web') {
        openchat(result[0]);
      }
    } else {
    }
    dismissLoader()
  } catch (error) {
    console.error("Error in home:", error);
  }
};
const openchat = async (doctordata, type)=>{  
  if(id && type !== "current"){
    fetchchatdata(id);
    setActivechatbox(id);
    setDoctorID(id);
    setDoctorName(doctorname);
    setIsDoctorListVisible(false);
    setMessageValue("");
    if(messageRef.current != null){
      messageRef.current.value = '';
    }
    

  }else{
    fetchchatdata(doctordata.doctorid);
    setActivechatbox(doctordata.doctorid);
    setDoctorID(doctordata.doctorid);
    setDoctorName(doctordata.doctorname);
    setMessageValue("");
    if(messageRef.current != null){
      messageRef.current.value = '';
    }
  }
  if(screenType === "mobile"){
    setIsDoctorListVisible(false);
  }
  
}

const goBackToDoctorList = () => {
  setIsDoctorListVisible(true);
}

const fetchchatdata = async (id) => {
  setChatData("");
  setRefreshChat(true)
  //showLoader()
  try {
    const result = await FetchChatData({
      doctorid: id,
      patientid,
    });
    if (result != null) {
      setChatData(result.finalOutput);   
      setChatHeader(result.chatheaderoutput)   
      setChatEnableDetails(result.chatenablesettings);
     // console.log(result.chatheaderoutput.isdoctoractive, ' result ')
      setIsDoctorActive(result.chatheaderoutput.isdoctoractive)
      if(result.chatenablesettings){
        if(result.chatenablesettings.sessionstatus == 1) {
          setRefreshChat(false); // this will be set only if sessionstatus is 1
        }
        if(result.chatenablesettings.currencyname == "USD"){
          setDoctorCurrency("$")    
        }else{
          setDoctorCurrency(result.chatenablesettings.currencyname)
        }     
      } 
      const response = await fetchDoctors({
        doctorid: id,
      });
      if (response != null) {
        setDoctorsData(response);
      }   
    } else {
    }
    
    //dismissLoader()
  } catch (error) {
    console.error("Error in home:", error);
  }
};
const openchatmedia = async () => {
  showLoader()
  try {
    const result = await FetchDoctorChatMedia({
      patientid,
      doctorid,
      type:"all",
    });
    //console.log(result, "doctor chat media");
    if (result != null) {
      setChatMedia(result);
      
      docProfile.current.openRight()
    } else {
    }
    dismissLoader()
  } catch (error) {
    console.error("Error in home:", error);
  }
};
const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};
const handlemessageKeyPress = (e) => {
  if (e.key === 'Enter') {
    if (e.altKey) {
      // Allow line break with Alt+Enter
      e.preventDefault();
      document.execCommand('insertLineBreak');
    } else {
      // Prevent default behavior (line break) and send the message
      e.preventDefault();
      sendmessage();
    }
  }
};
const handleInput = (e)=> {
  setMessageValue(e.target.value)
}
const sendmessage = async () =>{     
  if (loading) return; // Prevent multiple clicks
  setLoading(true); // Set loading state 
  try { 
  //showLoader()
  const todayDateTimeUTC = moment.utc();
  const todayDateTimeLocal = todayDateTimeUTC.local();
  const formattedTime = todayDateTimeLocal.format('hh:mm A');
  const fullDateTime = todayDateTimeLocal.format();
 if(messageValue === ""){
    showErrorToast("Please type something")
 }else{
  const newMessage = {
    doctorid,
    patientid,
    chat: messageValue,
    ispatient: 1,
    chattype: "text",
    formattedtime: formattedTime,
    createddate: fullDateTime,
};
 
    const result = await AddMessage(newMessage);
    if (result) {
      // Update the chat state with the new message
      setChatData(prevChats => {
        const updatedChats = prevChats ? [...prevChats, newMessage] : [newMessage];
        return updatedChats;
      });
      // setDoctorMessage("");
       lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
      messageRef.current.value = ''; // Clear the input after sending
      setMessageValue("");
      if(messageRef.current.value == ''){
        messageRef.current.style.height = chatInputHeight;
      }
      setIsEmpty(true); 
      // setChatInputHeight(0)
      // setIsFocused(false); // Reset focus state
      if(refreshchat == true){
        fetchchatdata(doctorid)
      }
  } else {
      console.error("Failed to send message. Result is null.");
  }
 }    
    //dismissLoader()
  } catch (error) {
    console.error("Error in home:", error);
  }finally {
    setLoading(false); // Reset loading state after processing
  }
}
const scrollToBottom = () => {
  if (chatContainerRef.current) {
    chatContainerRef.current.scrollTop =
      chatContainerRef.current.scrollHeight;
  }
};
useLayoutEffect(() => {
  scrollToBottom();
}, [chatsdata]);
const handleuploadImageAttachment = () => {
  document.getElementById("uploadimage").click();
}
const uploadimages = async (e) => {
  e.preventDefault();
  showLoader();
  if (e.target.files.length == 0) {
    dismissLoader();
    return;
  }    
  const file = e.target.files[0];
  const reader = new FileReader();
  reader.readAsDataURL(file);
  const result = await uploadImageCommonFunction(file, file.name);        
  const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
  const maxSizeInMB = 5;
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  const documenturl = result.name;

  if (result) {

    var fileSize = file.size / 1024;
    var docSize = fileSize.toFixed(2)
    if (file.size > maxSizeInBytes) {
      showErrorToast("File size exceeds 5MB. Please select a smaller file.");
    } else if (!allowedTypes.includes(file.type)) {
      showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG");
    } else {
      
      try {
        const result = await AddMessage({
          doctorid:doctorid,
          patientid,
          ispatient: 1,
          chattype:"image",
          chat: documenturl,
        });
        if (result != null) {
          fetchchatdata(doctorid);
          setDoctorMessage("");
          lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
        } else {
        }
        //dismissLoader()
      } catch (error) {
        console.error("Error in home:", error);
      }


    }
  }
  e.target.value = '';
  dismissLoader();

};

const handleuploadDocumentAttachment = () => {
  document.getElementById("uploaddocument").click();
}
const uploaddocument = async (e) => {
  e.preventDefault();
  showLoader();
  if (e.target.files.length == 0) {
    dismissLoader();
    return;
  }    
  const file = e.target.files[0];
  const reader = new FileReader();
  reader.readAsDataURL(file);
  const result = await uploadImageCommonFunction(file, file.name);        
  const allowedTypes = ["application/pdf"];
  const maxSizeInMB = 5;
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  const documenturl = result.name;

  if (result) {

    var fileSize = file.size / 1024;
    var docSize = fileSize.toFixed(2)
    if (file.size > maxSizeInBytes) {
      showErrorToast("File size exceeds 5MB. Please select a smaller file.");
    } else if (!allowedTypes.includes(file.type)) {
      showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG");
    } else {
      
      try {
        const result = await AddMessage({
          doctorid:doctorid,
          patientid,
          ispatient: 1,
          chattype:"document",
          chat: documenturl,
          "documentsize":file.size
        });
        if (result != null) {
          fetchchatdata(doctorid);
          setDoctorMessage("");
          lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
        } else {
        }
        //dismissLoader()
      } catch (error) {
        console.error("Error in home:", error);
      }


    }
  }
  e.target.value = '';
  dismissLoader();

};

const handleFocus = () => {
  setIsFocused(true);
};

const handleBlur = () => {
  if (messageRef.current) {
    const value = messageRef.current.textContent;
    setIsEmpty(value.trim() === '');
  }
  setIsFocused(false);
};
const SearchIconClicked = () => {
  ShowHideSearch(true);
};
const closeSearchBar =()=>{
  setSearch("")
  ShowHideSearch(false)
}
const renderDate = (date) => {
  if (moment(date).isSame(moment(), 'day')) return "Today";
  if (moment(date).isSame(moment().subtract(1, 'days'), 'day')) return "Yesterday";
  return moment(date).format('DD/MM/YYYY');
};
// const navigatemap = (lat, long) => {
//   const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${long}&travelmode=driving`;
//   window.open(url, '_blank');
// }
const navigatemap = (address1 , address2, city, country) => {
  var address = address1 + address2 + city+country
  const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}&travelmode=driving`;
  window.open(url, '_blank');
};
//Payment for doctor chat
const PayNow = async (fee)=>{
  showLoader();
  setClientSecret(null);
  setStripePromise(null);
  const amount = parseFloat(fee) * 100;
  try {
      const result = await CreatePaymentIntent({
        amount: amount,
      });
      result.amount = fee;
      result.doctorid = doctorid;
      result.paymenttypeid = "3";
      const resp = await SavePaymentInitialCall(result);
      //console.log(resp,"Initial db resp");
      setClientSecret(result.client_secret);
      setStripePromise(loadStripe(CONFIG.StripePublicKey));
    } catch (error) {
      console.error("Error in Payment:", error);
    }
    dismissLoader();
}
// useEffect(() => {
//   const checkTextarea = () => {
//     const textarea = textareaRef.current;
//     console.log("Checking Textarea element:", textarea);

//     if (textarea) {
//       const handleInput = () => {
//         console.log("Handling input");
//         // Reset height to auto to recalculate scrollHeight
//         textarea.style.height = '48px';
//         console.log("Scroll height:", textarea.scrollHeight);
//         // Calculate new height based on scrollHeight, with a max of 100px
//         const newHeight = Math.min(textarea.scrollHeight, 100);
//         console.log("New height:", newHeight);
//         // Set the height state to the new value
//         setHeight(`${newHeight}px`);
//         textarea.style.height = `${newHeight}px`;
//       };

//       textarea.addEventListener('input', handleInput);
//       console.log("Event listener added");

//       // Initial resize on mount
//       handleInput();

//       return () => {
//         textarea.removeEventListener('input', handleInput);
//         console.log("Event listener removed");
//       };
//     } else {
//       setTimeout(checkTextarea, 100); // Retry after 100ms if textarea is not yet available
//     }
//   };

//   checkTextarea();
// }, []);


const stickyButtonsCSS = `
  .stickyButtons {
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .stickyButtons::-webkit-scrollbar {
    display: none;
  }
`
  return (
    <>
    <style>{stickyButtonsCSS}</style>
    <Global
        styles={css`
          .activeTab .whiteBorder::after {
            content: '';
            position: absolute;
            left: 0%;
            transform: translateX(-20%);
            transform: translateY(440%);
            border-radius: 5px 5px 0 0;
            top: 50%;
            background: #fbfbfc;
            height: 5px;
            width: 100%;
            z-index: 1;
          }
        `}
      />

    {screenType == 'web' ? (
      <Box w="100%" overflow="hidden">
        <Box top="0" zIndex="banner">
          <Navbar />
        </Box>
        {isLoading && <Loader />}
        {doctorsdata && doctorsdata != null ?(
          <>
        <Flex
          align="center"
          alignItems="center"
          bg="#FFFFFF"
          color="white"
          h="70px"
          borderBottom="1px solid #E6E7E9"
        >
          <Flex
            maxW="360px"
            w="100%"
            flexDir="column"
            as="aside"
            align="center"
            px="32px"
            h="full"
          >
            <Flex
              justifyContent="space-between"
              w="100%"
              h="100%"
              alignItems="center"
            >
              <Box as="h1" color="black" fontSize="20px">
                Chats
              </Box>
              <Box as="button">
                <Image
                  p="10px"
                  borderRadius="10px"
                  border="1px solid #E6E7E9"
                  src="/assets/imgs/search.png"
                  onClick={SearchIconClicked}
                />
              </Box>
            </Flex>
          </Flex>
          <Divider orientation="vertical" />
          <Flex flexDir="column" w="100%">
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="start"
              px="32px"
            >
              <Box cursor="pointer" onClick={openchatmedia}>
                <Box as="h2" color="black" fontSize="14px" fontWeight="600">
                {ChatHeader && ChatHeader.doctorname? ChatHeader.doctorname:"-"}
                </Box>
                <Text color="black" fontSize="10px" fontWeight="500">
                      {appointmentDate && appointmentTime ? (
                        <>
                          {formatDate(appointmentDate)} {appointmentTime}
                        </>
                      ) : (
                        <>
                          {formatDate(ChatHeader?.appointmentdate) || "-"} {ChatHeader?.appointmenttime || "-"}
                        </>
                      )}
                    </Text>
              </Box>
              <Box as="button" onClick={openchatmedia}>
                <Image
                  w="40px"
                  h="40px"
                  src={ChatHeader && ChatHeader.doctorimage? ChatHeader.imagepath + ChatHeader.doctorimage:"/assets/imgs/no-image.png"}
                  borderRadius="full"
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <Flex w="100%" overflow="hidden" h="100vh">
          {/* {/* Left content start * /} */}
          <VStack
            maxW="360px"
            w="100%"
            bg="#fcfcfd"
            gap="0"
            flexDir="column"
            as="aside"
            align="center"
            h="calc(100vh - 140px)"
            overflowY="auto"
          >
             {showhidesearch && (
            <Box
              w="100%"
              p="20px 32px"
              borderTop="1px solid #D2DCF3"
              borderBottom="1px solid #D2DCF3"
            >
              <SearchBar passInputPlace="Search Doctor" 
              onChange={(event) => setSearch(event.target.value)}
              iconSrc="/assets/imgs/remove-icon.png"
                  handleClick={closeSearchBar}/>
            </Box>
             )}
            {/* {/* Chats section start * /} */}
            <Box display="flex" alignItems="center" h="calc(100vh - 100px)" flexDir="column" w="100%">
            {doctorsdata && doctorsdata.length > 0 ? (
              doctorsdata.filter(step =>
                  step.doctorname.toLowerCase().includes(search.toLowerCase())
              ).length > 0 ? (
                  doctorsdata.filter(step =>
                      step.doctorname.toLowerCase().includes(search.toLowerCase())
                  ).map((step, index) => (
                      <React.Fragment key={index}>
                          <Flex
                              w="100%"
                              as="button"
                              bg={step.doctorid === activechatbox ? "#EAEDF5": "white"}
                              flexDir="column"
                              p="20px 32px"
                              onClick={()=> openchat(step, "current")}
                          >
                              <Box display="flex" w="100%">
                                  <Image
                                      boxSize="2.75rem"
                                      src={step.profilepictureurl? step.imagepath+step.profilepictureurl:"/assets/imgs/no-image.png"}
                                      borderRadius="full"
                                  />
                                  <Flex
                                      gap="10px"
                                      justifyContent="space-between"
                                      w="100%"
                                      alignItems="center"
                                  >
                                      <Box pl="10px">
                                          <Text
                                              color="black"
                                              fontSize="10px"
                                              textAlign="left"
                                              fontWeight="500"
                                              maxW="170px"
                                              className="textOverflow"
                                          >
                                              {step.specality}
                                          </Text>
                                          <Box as="h2" display="flex" color="black" fontSize="14px" fontWeight="600" className="textOverflow" w="100%">
                                              {step.doctorname}
                                          </Box>
                                      </Box>
                                      <Box borderRadius="full" 
                                      bg={step.doctorid === activechatbox ? "#1C4EC5": ""}
                                       border="1px solid #D2DCF3" p="12px">
                                          <Image w="14px" src={step.doctorid === activechatbox ? "/assets/svgs/chat-white.svg": "/assets/svgs/chat.svg" }/>
                                      </Box>
                                  </Flex>
                              </Box>
                              <Text pt="10px" fontSize="14px" fontWeight="500" textAlign="left" className="textOverflow" w="90%">
                                 {step.chiefcomplaint}
                              </Text>
                          </Flex>
                          <Box
                          bg={step.doctorid === activechatbox ? "#EAEDF5": "#F9FAFB"}
                              w="100%"
                              borderTop="1px solid #E6E7E9"
                              borderBottom="1px solid #E6E7E9"
                              p="12px 32px"
                              pr="38px"
                              display="flex"
                              h="43px"
                              justifyContent="space-between"
                              alignItems="center"
                              onClick={()=> openchat(step,"current")}
                          >
                              <Text fontSize="12px" fontWeight="500">
                                  {formatDate(step.appointmentdate)}
                              </Text>
                              {step.countofunreadmessages > 0 && (
                              <Text
                                  bg="#1C4EC5"
                                  p="3px 6px"
                                  w="30px"
                                  h="20px"
                                  color="white"
                                  textAlign="center"
                                  fontSize="10px"
                                  fontWeight="600"
                                  borderRadius="full"
                              >
                                  {step.countofunreadmessages}
                              </Text>
                               )}
                          </Box>
                      </React.Fragment>
                  ))
              ) : (
                    <Box m="auto" display="flex" justifyContent="center" alignItems="center" flexDir="column">
                      <Image boxSize="64px" src="/assets/svgs/no-patient.svg" />
                      <Text fontSize="1rem" fontWeight="500" color="#6A707D">No doctor found</Text>
                    </Box>
              )
          ) : null}
                  </Box>

            {/* {/* Chats section end * /} */}
            {/* {/* Chats section start * /} */}
            {/* <Flex
              w="100%"
              as="button"
              bg="#EAEDF5"
              flexDir="column"
              p="20px 32px"
            >
              <Box display="flex" w="100%">
                <Image
                  w="44px"
                  src="/assets/imgs/adnan.png"
                  borderRadius="full"
                />
                <Flex
                  gap="10px"
                  justifyContent="space-between"
                  w="100%"
                  alignItems="center"
                >
                  <Box pl="10px">
                    <Text
                      color="black"
                      fontSize="10px"
                      textAlign="left"
                      fontWeight="500"
                    >
                      Consulted: 8th Jan, 2024
                    </Text>
                    <Box as="h2" color="black" fontSize="14px" fontWeight="600">
                      Dr. Samantha Raulf
                    </Box>
                  </Box>
                  <Box
                    borderRadius="full"
                    border="1px solid #EAEDF5"
                    bg="#1C4EC5"
                    p="12px"
                  >
                    <Image w="14px" src="/assets/svgs/chat-white.svg" />
                  </Box>
                </Flex>
              </Box>
              <Text pt="10px" fontSize="14px" fontWeight="500" textAlign="left">
                Vomiting's and dizziness
              </Text>
            </Flex>
            <Box
              bg="#EAEDF5"
              w="100%"
              borderTop="1px solid #D2DCF3"
              borderBottom="1px solid #D2DCF3"
              p="12px 32px"
              pr="38px"
              display="flex"
              h="43px"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text fontSize="12px" fontWeight="500">
                Consulted: 10th Jan, 2024
              </Text>
            </Box> */}
            {/* {/* Chats section end * /} */}
          </VStack>
          {/* {/* Left content end * /} */}
          <Divider orientation="vertical" h="100vh" />
          {/* {/* Main Content start * /} */}
          <Box flexDir="column" bg="#fcfcfd" w="100%" overflowY="auto">
            <Flex flexDir="column" h="calc(100vh - 140px)">
            {/* <Box
                pos="sticky"
                top="20px"
                display="flex"
                justifyContent="center"
              >
                <Text
                  borderRadius="full"
                  p="4px 12px"
                  bg="white"
                  fontSize="10px"
                  fontWeight="600"
                  border="1px solid #E6E7E9"
                >
                  Today
                </Text>
              </Box> */}
              <Box ref={chatContainerRef} overflowY="auto" p="24px">
              {chatsdata && chatsdata.map((msg, index)=> {
                 const createdDate = moment(msg.createddate);
                 const showDate = index === 0 || !moment(chatsdata[index - 1].createddate).isSame(msg.createddate, 'day');
 
                 return (
                   <React.Fragment key={index}>
                     {showDate && (
                       <Text borderRadius="full" p="4px 12px" w="90px" textAlign='center' my="1rem" zIndex="9" mx="auto" pos="sticky"  top="0"  bg="white" fontSize="10px" fontWeight="600" border="1px solid #E6E7E9">
                         {renderDate(createdDate)}
                         </Text>
                     )}
                  <Stack
                    direction="row"
                    justifyContent={msg.ispatient && msg.ispatient === 1 ? "end" : "start"}
                    key={index}
                  >
                    
                    <Box>
                      <Box
                        key={msg.id}
                        bg={msg.ispatient && msg.ispatient === 1 ? "#061027" : msg.chattype === "image" ? "" : "#E6E7E980"}
                        maxW={msg.chattype === "image" ? "13.75rem" : "25rem"}
                        maxH={msg.chattype === "image" ? "13.75rem" : " "}
                        // wordWrap="break-word"
                        p={msg.chattype === "document" ? "2px" : msg.chattype === "image" ? "" : "12px 16px"}
                        w="fit-content"
                        borderRadius={
                          msg.ispatient && msg.ispatient === 0
                            ? "10px 10px 10px 1px"
                            : "10px 10px 1px 10px"
                        }
                      >
                        {msg.chattype === "image"  ? (
                          <Card w="13rem" h="13rem" overflow="hidden" p="0" justifyContent="center">
                          <Image
                            src={`${msg.imagepath}${msg.chat}`} 
                            onClick={
                              
                              ()=>
                                msg.ispatient && msg.ispatient == 1 ? 
                              window.open(msg.imagepath + msg.chat, "_blank")
                                 :
                                 downloadDocument(msg.imagepath + msg.chat, "image")
                                

                              }
                            objectFit="cover"
                            w="100%"
                            h="-webkit-fill-available"
                            borderRadius={
                              msg.ispatient && msg.ispatient === 0
                                ? "10px 10px 10px 1px"
                                : "10px 10px 1px 10px"
                            }
                          />
                          </Card>
                        ) : 
                        msg.chattype === "document" ? (
                          // <Image src={msg.text} objectFit="cover" borderRadius={msg.sender === "doctor" ? "10px 10px 10px 1px" : "10px 10px 1px 10px"} />
                          <Card w="285px" h="194px" overflow="hidden" p="0">
                             <Box h="60%" mx="auto">
                            <Image
                              //objectFit="cover"
                              src={"/assets/imgs/pdf-image.jpeg"}
                              alt='Document'
                              onClick={() => {window.open(msg.imagepath + msg.chat, '_blank')}}
                              cursor="pointer"
                              w="140px"
                              h="125px"
                            />
                            </Box>
                            <CardFooter
                              h="40%"
                              justify="space-between"
                              bg={
                                msg.ispatient && msg.ispatient === 0 ? "#EBECED" : "#061027"
                              }
                              flexWrap="wrap"
                            >
                              <Box  display="flex" w="100%" justifyContent="space-between" alignItems='center'>
                                <Box>
                                  <Text
                                    fontSize="14px"
                                    fontWeight="600"
                                    color={
                                      msg.ispatient && msg.ispatient === 0
                                        ? "#384052"
                                        : "#E6E7E9"
                                    }
                                  >
                                    Document.pdf
                                  </Text>
                                  {msg.documentsize && msg.documentsize != null &&
                                  <Text
                                    fontSize="12px"
                                    fontWeight="400"
                                    color={
                                      msg.ispatient && msg.ispatient === 0
                                        ? "#384052"
                                        : "#898D97"
                                    }
                                  >
                                      {msg.documentsize > 1048576 ?
                                      (
                                        (msg.documentsize / 1048576).toFixed(0) + ' MB'
                                      ):(
                                        (msg.documentsize / 1024).toFixed(0) + ' KB'
                                        )
                                        
                                        } 
                                  </Text>
                                  }
                                  {/* <Text
                                    fontSize="12px"
                                    fontWeight="400"
                                    color={
                                      msg.sender === "doctor"
                                        ? "#384052"
                                        : "#898D97"
                                    }
                                  >
                                    2 MB
                                  </Text> */}
                                </Box>
                                <Box
                                  boxSize="36px"
                                  borderRadius="full"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  bg={
                                    msg.ispatient && msg.ispatient === 0
                                      ? "white"
                                      : "#0B1F4F"
                                  }
                                  cursor="pointer" 
                                  onClick={()=>downloadDocument(msg.imagepath + msg.chat, "Document.pdf")}
                                >
                                  <Image
                                    src={
                                      msg.ispatient && msg.ispatient === 0
                                        ? "/assets/imgs/download.png"
                                        : "/assets/imgs/download-white.png"
                                    }
                                  />
                                </Box>
                              </Box>
                            </CardFooter>
                          </Card>
                        ) : (
                          <Text
                            color={
                              msg.ispatient && msg.ispatient === 1 ? "white" : "#061027"
                            }
                            fontSize="14px"
                            fontWeight="500"
                          >
                            {msg.chat}
                          </Text>
                        )}
                      </Box>
                      <Box
                        as="span"
                        fontSize="10px"
                        my="3px"
                        fontWeight="500"
                        color="#6A707D"
                        float={msg.ispatient && msg.ispatient === 1 ? "right" : "left"}
                      >
                        {msg.formattedtime? msg.formattedtime:""}
                      </Box>
                    </Box>
                  </Stack>
                  </React.Fragment>
                        );
                 })}
                 {/*  For Call */}
                {/* <Box
                  maxW="345px"
                  bg="white"
                  border="1px solid #E6E7E9"
                  borderRadius="12px"
                  p="16px"
                >
                  <Flex gap="15px">
                    <Box>
                      <Image
                        borderRadius="full"
                        w="40px"
                        src="/assets/imgs/adnan.png"
                      />
                    </Box>
                    <Box display="flex" flexDir="column" textAlign="left">
                      <Text fontSize="14px" fontWeight="500" color="#061027">
                        Join video consulation with
                      </Text>
                      <Text fontSize="14px" fontWeight="500" color="#061027">
                        Dr. Samantha Raulf
                      </Text>
                    </Box>
                  </Flex>
                  <PrimaryButton
                    btnStyle={{
                      m: "0",
                      mt: "10px",
                      boxShadow: "0px 8px 20px 0px #E6E7E966",
                    }}
                    btnIcon="/assets/imgs/videocam.png"
                    buttonText="Join call"
                  />{" "}
                  <PrimaryButton
                    isDisabled={true}
                    btnStyle={{
                      m: "0",
                      mt: "10px",
                      boxShadow: "0px 8px 20px 0px #E6E7E966",
                      _disabled: { color: "#898D97", bg: COLORS.BTN_DISABLE },
                    }}
                    btnIcon="/assets/imgs/videocam.png"
                    btnDisabledIcon="/assets/imgs/videocam-gray.png"
                    buttonText="Join call"
                  />
                </Box> */}
              </Box>
              {/* <TypingLoader /> */}
              <Spacer />
              <Box display="flex" gap="10px" bg="none" p="16px 24px">
                <Box
                  bg="#EAEDF5"
                  as="button"
                  border="1px solid #7795DC"
                  borderRadius="full"
                  p={{sm: "10px", xl: "12px 20px"}}
                  color="#163E9E"
                  fontSize="12px"
                  fontWeight="600"
                  onClick={()=> {
                    if(isDoctorActive == 1){

                      navigate("/doctorprofile", { state: { doctorid: doctorid, fullname: doctorname }})
                    }else if(isDoctorActive == 0){
                      showErrorToast("Doctor Unavailable!")
                    }
                  }
                  }
                >
                  Book a new appointment
                </Box>
                <Box
                  bg="#EAEDF5"
                  as="button"
                  border="1px solid #7795DC"
                  borderRadius="full"
                  p="12px 20px"
                  color="#163E9E"
                  fontSize="12px"
                  fontWeight="600"
                  onClick={() => navigate("/appointmentdetails", {
                    state: {
                      //  appointmentid: ChatHeader && ChatHeader.appointmentid
                      appointmentid: appointmentId ? appointmentId : ChatHeader && ChatHeader.appointmentid
                    }
                  })}
                >
                  View booking details
                </Box>
                <Box
                  bg="#EAEDF5"
                  as="button"
                  border="1px solid #7795DC"
                  borderRadius="full"
                  p="12px 20px"
                  color="#163E9E"
                  fontSize="12px"
                  fontWeight="600"
                  onClick={openchatmedia}
                >
                  View doctors profile
                </Box>
              </Box>
              <Box bg="white" h="auto" borderTop="1px solid #E6E7E9">
              {ChatEnableDetails === null || ChatEnableDetails.sessionstatus === 1 ?
                (
                <Flex
                  w="100%"
                  p="16px 32px 24px 32px"
                  gap="15px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Menu>
                    <MenuButton as="button">
                      <Box
                        as="button"
                        p="12px 18px"
                        w="48px"
                        h="48px"
                        bg="#163E9E"
                        display="flex"
                        alignItems="center"
                        border="1px solid #E6E7E9"
                        borderRadius="full"
                      >
                        <Image w="11px" src="/assets/svgs/file-attach-white.svg" />
                      </Box>
                    </MenuButton>
                    <MenuList p="2px" minW="160px" mr="25px" zIndex="overlay">
                      <MenuItem
                        fontSize="14px"
                        fontWeight="500"
                        color="#384052"
                        onClick={handleuploadDocumentAttachment}
                      >
                        <Input
                          type="file"
                          accept=".pdf,.txt"
                          hidden
                          id="uploaddocument"
                          onChange={(e) => uploaddocument(e)}
                        />
                        <Box
                          border="1px solid #E6E7E9"
                          w="28px"
                          h="28px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mr="5px"
                          borderRadius="full"
                          p="5px"
                          onClick={handleuploadDocumentAttachment}
                          id="uploaddocument"
                        >
                          <Image src="/assets/imgs/upload-doc-blue.png" />
                        </Box>
                        Documents
                      </MenuItem>
                      <MenuItem
                        fontSize="14px"
                        fontWeight="500"
                        color="#384052"
                        onClick={handleuploadImageAttachment}
                      >
                        <Input
                          type="file"
                          accept=".png,.jpg,.jpeg"
                          hidden
                          id="uploadimage"
                          onChange={(e) => uploadimages(e)}
                        />
                        <Box
                          border="1px solid #E6E7E9"
                          w="24px"
                          h="24px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mr="5px"
                          borderRadius="full"
                          p="5px"
                          onClick={handleuploadImageAttachment}
                          id="uploadimage"
                        >
                          <Image src="/assets/imgs/select-images-blue.png" />
                        </Box>
                        Images
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <Box w="100%">
                    <Box
                      tabIndex="-1"
                      w="100%"
                      bg="#FCFCFD"
                      border="1px solid #E6E7E9"
                      borderRadius="1rem"
                      display="flex"
                      alignItems="flex-end"
                    >
                      <Textarea
                        ref={messageRef}
                        onKeyDown={handlemessageKeyPress}
                        onInput={handleInputChange}
                        className="chatInput"
                        onChange={handleInput}
                        height={chatInputHeight}
                        minH="unset"
                        maxH="100px"
                        borderRadius="1rem"
                        overflowY="auto"
                        border="none"
                        resize="none"
                        _focus={{ boxShadow: "none", outline: "none" }}
                        placeholder="Type a message..."
                      />
                      <Box
                        as="button"
                        onClick={sendmessage}
                        bg="#163E9E"
                        w="2rem"
                        h="2rem"
                        borderRadius="full"
                        p="10px"
                        m="5px 10px 5px 5px"
                        userSelect="none"
                        isDisabled={loading} // Disable button while loading
                      >
                        <Image src="/assets/svgs/send-button.svg" />
                      </Box>
                    </Box>
                  </Box>
                </Flex>
               ) : (
                null
              )
            }
              {ChatEnableDetails && ChatEnableDetails.sessionstatus === 0 && ChatEnableDetails.paymentrequested === 0 &&
              <Flex
                w="100%"
                p="16px 32px 24px 32px"
                gap="15px"
                justifyContent="center"
                alignItems="left"
              >
              
                <Text textAlign="left" fontSize="10px">* Chat will be enabled once doctor request for payment.</Text>
              </Flex>
              }
                  {ChatEnableDetails && ChatEnableDetails.paymentstatus != 1 && ChatEnableDetails.paymentrequested == 1 &&
                    <Flex
                    w="100%"
                    p="16px 32px 24px 32px"
                    gap="15px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <PrimaryButton onClick={()=> PayNow(ChatEnableDetails.fee)} buttonText={"Pay now "+ "$" + ChatEnableDetails.fee} btnStyle={{m: 0}} />
                  </Flex>
                  }
                {/* <Flex h="100%" justifyContent="center" alignItems="center" fontSize="14px" fontWeight="500" color="#898D97">
          Chat closed
        </Flex> */}
              </Box>
            </Flex>
          </Box>
          {/* Main Content end */}
        </Flex>

        {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm doctorid={doctorid} clientSecret={clientSecret} />
        </Elements>
      )}
        </>
 ):(
  <Box display="flex" justifyContent="center" flexDir="column" gap="10px" alignItems="center" h="100vh">
  <Image src="/assets/svgs/no-chats.svg"/>
  <Text>No chats</Text>
</Box>
         )}
      </Box>
    ) : (
      //mobile responsive
      <Box overflow="hidden">
          {isLoading && <Loader />}

          {isDoctorListVisible ? (
            <>
              <MobileNavbar/>
              {/* Menu section start */}
              <Box bg="#061027" p="1.5rem">
                <Text mb="1rem" fontSize="1.5rem" fontWeight="600" color="white">Chats</Text>
                <Box w="100%">
                  <SearchBar 
                    passInputPlace="Search"
                    onChange={(event) => setSearch(event.target.value)}
                  />
                </Box>
              </Box>
              {/* Menu section start */}
              <Box bg="#fcfcfd" h='calc(100vh - 260px)' p="1.25rem" overflow="hidden auto">
                {/* Doctor chat list start */}
                <Box w="100%">
                <Box display="flex" alignItems="center" flexDir="column" gap="1rem" w="100%">
                {doctorsdata && doctorsdata.length > 0 ? (
                  doctorsdata.filter(step =>
                      step.doctorname.toLowerCase().includes(search.toLowerCase())
                  ).length > 0 ? (
                      doctorsdata.filter(step =>
                          step.doctorname.toLowerCase().includes(search.toLowerCase())
                      ).map((step, index) => (
                          <Box border="1px solid #E6E7E966" borderRadius="12px" w="100%" key={index}>
                              <Flex
                                  w="100%"
                                  as="button"
                                  bg="white"
                                  flexDir="column"
                                  p="20px"
                                  onClick={()=> openchat(step, "current")}
                                  borderRadius="12px 12px 0 0"
                              >
                                  <Box display="flex" w="100%">
                                      <Image
                                          boxSize="2.75rem"
                                          src={step.profilepictureurl? step.imagepath+step.profilepictureurl:"/assets/imgs/no-image.png"}
                                          borderRadius="full"
                                      />
                                      <Flex
                                          gap="10px"
                                          justifyContent="space-between"
                                          w="100%"
                                          alignItems="center"
                                      >
                                          <Box pl="10px">
                                              <Text
                                                  color="black"
                                                  fontSize="10px"
                                                  textAlign="left"
                                                  fontWeight="500"
                                              >
                                                  {step.specality}
                                              </Text>
                                              <Box as="h2" color="black" fontSize="14px" fontWeight="600" className="textOverflow" w="100%">
                                                  {step.doctorname}
                                              </Box>
                                          </Box>
                                          <Box borderRadius="full" 
                                          // bg={step.doctorid === activechatbox ? "#1C4EC5": ""}
                                          border="1px solid #D2DCF3" p="12px">
                                              <Image w="14px" src="/assets/svgs/chat.svg"/>
                                          </Box>
                                      </Flex>
                                  </Box>
                                  <Text pt="10px" fontSize="14px" fontWeight="500" textAlign="left" className="textOverflow" w="90%">
                                    {step.chiefcomplaint}
                                  </Text>
                              </Flex>
                              <Box
                              bg="#F9FAFB"
                                  w="100%"
                                  borderTop="1px solid #E6E7E9"
                                  borderBottom="1px solid #E6E7E9"
                                  p="22px"
                                  pr="38px"
                                  display="flex"
                                  h="43px"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  onClick={()=> openchat(step,"current")}
                                  borderRadius="0 0 12px 12px"
                              >
                                  <Text fontSize="12px" fontWeight="500">
                                      {formatDate(step.appointmentdate)}
                                  </Text>
                                  {step.countofunreadmessages > 0 && (
                                  <Text
                                      bg="#1C4EC5"
                                      p="3px 6px"
                                      w="30px"
                                      h="20px"
                                      color="white"
                                      textAlign="center"
                                      fontSize="10px"
                                      fontWeight="600"
                                      borderRadius="full"
                                  >
                                      {step.countofunreadmessages}
                                  </Text>
                                  )}
                              </Box>
                          </Box>
                      ))
                  ) : (
                        <Box m="auto" display="flex" justifyContent="center" alignItems="center" flexDir="column" h='calc(100vh - 260px)'>
                          <Image boxSize='64px' src="/assets/svgs/no-patient.svg" />
                          <Text fontSize="1rem" fontWeight="500" color="#6A707D">No doctor found</Text>
                        </Box>
                  )
              ) : (
                <Box display="flex" justifyContent="center" flexDir="column" gap="10px" alignItems="center" h="calc(100vh - 260px)">
                  <Image src="/assets/svgs/no-chats.svg"/>
                  <Text>No chats</Text>
                </Box>
              )}
                  </Box>
                </Box>
                {/* Doctor chat list end */}
              </Box>
            </>
          ) : (
            <>
              <MobileSecondaryNav 
                handlebackarrow={goBackToDoctorList}
                headerStyle={{alignItems: "center", textAlign: "center"}}
                header={<>
                  <Box cursor="pointer" onClick={openchatmedia}>
                    <Box as="h2" color="black" fontSize="1rem" fontWeight="600">
                    {ChatHeader && ChatHeader.doctorname? ChatHeader.doctorname:"-"}
                    </Box>
                    <Text color="black" fontSize="10px" fontWeight="500">
                      {appointmentDate && appointmentTime ? (
                        <>
                          {formatDate(appointmentDate)} {appointmentTime}
                        </>
                      ) : (
                        <>
                          {formatDate(ChatHeader?.appointmentdate) || "-"} {ChatHeader?.appointmenttime || "-"}
                        </>
                      )}
                    </Text>
                  </Box>
                </>}
                rightArea={<>
                  <Box as="button" onClick={openchatmedia}>
                    <Image
                      w="40px"
                      h="40px"
                      src={ChatHeader && ChatHeader.doctorimage? ChatHeader.imagepath + ChatHeader.doctorimage:"/assets/imgs/no-image.png"}
                      borderRadius="full"
                    />
                  </Box>
                </>}
              />
              <Box overflowY="auto" p="24px" h={isIos ? 'calc(100vh - 330px)' : 'calc(100vh - 300px)'}>
              {chatsdata && chatsdata.map((msg, index)=> {
                 const createdDate = moment(msg.createddate);
                 const showDate = index === 0 || !moment(chatsdata[index - 1].createddate).isSame(msg.createddate, 'day');
 
                 return (
                   <React.Fragment key={index}>
                     {showDate && (
                       <Text borderRadius="full" p="4px 12px" w="90px" textAlign='center' my="1rem" zIndex="9" mx="auto" pos="sticky"  top="0"  bg="white" fontSize="10px" fontWeight="600" border="1px solid #E6E7E9">
                         {renderDate(createdDate)}
                         </Text>
                     )}
                  <Stack
                    direction="row"
                    justifyContent={msg.ispatient && msg.ispatient === 1 ? "end" : "start"}
                    key={index}
                  >
                    
                    <Box>
                      <Box
                        key={msg.id}
                        bg={msg.ispatient && msg.ispatient === 1 ? "#061027" : msg.chattype === "image" ? "" : "#E6E7E980"}
                        maxW={msg.chattype === "image" ? "13.75rem" : "25rem"}
                        maxH={msg.chattype === "image" ? "13.75rem" : " "}
                        // wordWrap="break-word"
                        p={msg.chattype === "document" ? "2px" : msg.chattype === "image" ? "" : "12px 16px"}
                        w="fit-content"
                        borderRadius={
                          msg.ispatient && msg.ispatient === 0
                            ? "10px 10px 10px 1px"
                            : "10px 10px 1px 10px"
                        }
                      >
                        {msg.chattype === "image"  ? (
                          <Card w="13rem" h="13rem" overflow="hidden" p="0" justifyContent="center">
                          <Image
                            src={`${msg.imagepath}${msg.chat}`} 
                          //  onClick={()=>downloadDocument(msg.imagepath + msg.chat, "image")}
                          onClick={
                              
                              ()=>
                                msg.ispatient && msg.ispatient == 1 ? 
                              window.open(msg.imagepath + msg.chat, "_blank")
                                 :
                                 downloadDocument(msg.imagepath + msg.chat, "image")
                                

                              }
                            objectFit="cover"
                            w="100%"
                            h="-webkit-fill-available"
                            borderRadius={
                              msg.ispatient && msg.ispatient === 0
                                ? "10px 10px 10px 1px"
                                : "10px 10px 1px 10px"
                            }
                          />
                          </Card>
                        ) : 
                        msg.chattype === "document" ? (
                          // <Image src={msg.text} objectFit="cover" borderRadius={msg.sender === "doctor" ? "10px 10px 10px 1px" : "10px 10px 1px 10px"} />
                          <Card w="220px" h="194px" overflow="hidden" p="0">
                             <Box h="60%" mx="auto">
                            <Image
                              //objectFit="cover"
                              src={"/assets/imgs/pdf-image.jpeg"}
                              alt='Document'
                              onClick={() => {window.open(msg.imagepath + msg.chat, '_blank')}}
                              cursor="pointer"
                              w="140px"
                              h="125px"
                            />
                            </Box>
                            <CardFooter
                              h="40%"
                              justify="space-between"
                              bg={
                                msg.ispatient && msg.ispatient === 0 ? "#EBECED" : "#061027"
                              }
                              flexWrap="wrap"
                            >
                              <Box  display="flex" w="100%" justifyContent="space-between" alignItems='center'>
                                <Box>
                                  <Text
                                    fontSize="14px"
                                    fontWeight="600"
                                    color={
                                      msg.ispatient && msg.ispatient === 0
                                        ? "#384052"
                                        : "#E6E7E9"
                                    }
                                  >
                                    Document.pdf
                                  </Text>
                                  {msg.documentsize && msg.documentsize != null &&
                                  <Text
                                    fontSize="12px"
                                    fontWeight="400"
                                    color={
                                      msg.ispatient && msg.ispatient === 0
                                        ? "#384052"
                                        : "#898D97"
                                    }
                                  >
                                      {msg.documentsize > 1048576 ?
                                      (
                                        (msg.documentsize / 1048576).toFixed(0) + ' MB'
                                      ):(
                                        (msg.documentsize / 1024).toFixed(0) + ' KB'
                                        )
                                        
                                        } 
                                  </Text>
                                  }
                                  {/* <Text
                                    fontSize="12px"
                                    fontWeight="400"
                                    color={
                                      msg.sender === "doctor"
                                        ? "#384052"
                                        : "#898D97"
                                    }
                                  >
                                    2 MB
                                  </Text> */}
                                </Box>
                                <Box
                                  boxSize="36px"
                                  borderRadius="full"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  bg={
                                    msg.ispatient && msg.ispatient === 0
                                      ? "white"
                                      : "#0B1F4F"
                                  }
                                  cursor="pointer" 
                                  onClick={()=>downloadDocument(msg.imagepath + msg.chat, "Document.pdf")}
                                >
                                  <Image
                                    src={
                                      msg.ispatient && msg.ispatient === 0
                                        ? "/assets/imgs/download.png"
                                        : "/assets/imgs/download-white.png"
                                    }
                                  />
                                </Box>
                              </Box>
                            </CardFooter>
                          </Card>
                        ) : (
                          <Text
                            color={
                              msg.ispatient && msg.ispatient === 1 ? "white" : "#061027"
                            }
                            fontSize="14px"
                            fontWeight="500"
                          >
                            {msg.chat}
                          </Text>
                        )}
                      </Box>
                      <Box
                        as="span"
                        fontSize="10px"
                        my="3px"
                        fontWeight="500"
                        color="#6A707D"
                        float={msg.ispatient && msg.ispatient === 1 ? "right" : "left"}
                      >
                        {msg.formattedtime? msg.formattedtime:""}
                      </Box>
                    </Box>
                  </Stack>
                  </React.Fragment>
                        );
                 })}
                 {/*  For Call */}
                {/* <Box
                  maxW="345px"
                  bg="white"
                  border="1px solid #E6E7E9"
                  borderRadius="12px"
                  p="16px"
                >
                  <Flex gap="15px">
                    <Box>
                      <Image
                        borderRadius="full"
                        w="40px"
                        src="/assets/imgs/adnan.png"
                      />
                    </Box>
                    <Box display="flex" flexDir="column" textAlign="left">
                      <Text fontSize="14px" fontWeight="500" color="#061027">
                        Join video consulation with
                      </Text>
                      <Text fontSize="14px" fontWeight="500" color="#061027">
                        Dr. Samantha Raulf
                      </Text>
                    </Box>
                  </Flex>
                  <PrimaryButton
                    btnStyle={{
                      m: "0",
                      mt: "10px",
                      boxShadow: "0px 8px 20px 0px #E6E7E966",
                    }}
                    btnIcon="/assets/imgs/videocam.png"
                    buttonText="Join call"
                  />{" "}
                  <PrimaryButton
                    isDisabled={true}
                    btnStyle={{
                      m: "0",
                      mt: "10px",
                      boxShadow: "0px 8px 20px 0px #E6E7E966",
                      _disabled: { color: "#898D97", bg: COLORS.BTN_DISABLE },
                    }}
                    btnIcon="/assets/imgs/videocam.png"
                    btnDisabledIcon="/assets/imgs/videocam-gray.png"
                    buttonText="Join call"
                  />
                </Box> */}
              </Box>
              {/* <TypingLoader /> */}
              <Spacer />
              <Box display="flex" gap="10px" bg="none" p="16px 24px" className="stickyButtons">
                <Box
                  bg="#EAEDF5"
                  as="button"
                  border="1px solid #7795DC"
                  borderRadius="full"
                  p="12px 20px"
                  color="#163E9E"
                  fontSize="12px"
                  fontWeight="600"
                  onClick={()=> navigate("/doctorprofile", { state: { doctorid: doctorid, fullname: doctorname }})}
                >
                  Book a new appointment
                </Box>
                <Box
                  bg="#EAEDF5"
                  as="button"
                  border="1px solid #7795DC"
                  borderRadius="full"
                  p="12px 20px"
                  color="#163E9E"
                  fontSize="12px"
                  fontWeight="600"
                  onClick={() => navigate("/appointmentdetails", { state: { 
                    appointmentid: appointmentId ? appointmentId : ChatHeader && ChatHeader.appointmentid} 
                  })}
                >
                  View booking details
                </Box>
                <Box
                  bg="#EAEDF5"
                  as="button"
                  border="1px solid #7795DC"
                  borderRadius="full"
                  p="12px 20px"
                  color="#163E9E"
                  fontSize="12px"
                  fontWeight="600"
                  onClick={openchatmedia}
                >
                  View doctors profile
                </Box>
              </Box>
              <Box bg="white" minH="80px" borderTop="1px solid #E6E7E9">
                <Flex
                  w="100%"
                  p="16px"
                  gap="15px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Menu>
                    <MenuButton as="button">
                      <Box
                        as="button"
                        p="12px 18px"
                        w="48px"
                        h="48px"
                        bg="#163E9E"
                        display="flex"
                        alignItems="center"
                        border="1px solid #E6E7E9"
                        borderRadius="full"
                      >
                        {/* <Image w="11px" src="/assets/svgs/file-attach.svg" /> */}
                        <Image
                          w="11px"
                          src="/assets/svgs/file-attach-white.svg"
                        />
                      </Box>
                    </MenuButton>
                    <MenuList p="2px" minW="160px" mr="25px" zIndex="overlay">
                      <MenuItem
                        fontSize="14px"
                        fontWeight="500"
                        color="#384052"
                        onClick={handleuploadDocumentAttachment}
                      >
                         <Input
                                  type="file"
                                  accept=".pdf,.txt"
                                  hidden
                                  id="uploaddocument"
                                  onChange={(e) => uploaddocument(e)}
                                />
                        <Box
                          border="1px solid #E6E7E9"
                          w="28px"
                          h="28px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mr="5px"
                          borderRadius="full"
                          p="5px"
                          onClick={handleuploadDocumentAttachment}  id="uploaddocument"
                        >
                          <Image src="/assets/imgs/upload-doc-blue.png" />
                        </Box>
                        Documents
                      </MenuItem>
                      <MenuItem
                        fontSize="14px"
                        fontWeight="500"
                        color="#384052"
                        onClick={handleuploadImageAttachment}
                      >
                         <Input
                                  type="file"
                                  accept=".png,.jpg,.jpeg"
                                  hidden
                                  id="uploadimage"
                                  onChange={(e) => uploadimages(e)}
                                />
                        <Box
                          border="1px solid #E6E7E9"
                          w="28px"
                          h="28px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mr="5px"
                          borderRadius="full"
                          p="5px"
                          onClick={handleuploadImageAttachment} id="uploadimage"
                        >
                          <Image src="/assets/imgs/select-images-blue.png" />
                        </Box>
                        Images
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <Box w="100%">
                        <Box tabIndex="-1" w="100%" bg="#FCFCFD" border="1px solid #E6E7E9" borderRadius="1rem" display="flex" alignItems="flex-end">
                          {/* <Box
                            p={2}
                            bg="#FCFCFD"
                            borderRadius="1rem"
                            minHeight="3rem"
                            maxHeight="6.25rem"
                            overflow="hidden"
                            userSelect="text"
                            w="100%"
                            whiteSpace="pre-wrap"
                            wordBreak="break-word"
                            role="textbox"
                            contentEditable
                            spellCheck="true"
                            ref={messageRef}
                            aria-label="Type a message"
                            tabIndex={10}
                            display="flex"
                            flexDirection="column"
                            _focus={{ boxShadow: "none", outline: "none" }}
                            onKeyDown={handlemessageKeyPress}
                            onInput={handleInputChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            color={!isFocused && isEmpty ? '#888888' : '#061027'}
                          >
                             {!isFocused && isEmpty && (
                                <span style={{ pointerEvents: 'none', color: '#888888' }}>
                                  Type a message...
                                </span>
                              )}
                          </Box> */}
                          <Textarea
                            ref={messageRef}
                            onKeyDown={handlemessageKeyPress}
                            onInput={handleInputChange}
                            // onFocus={handleFocus}
                            // onBlur={handleBlur}
                            className="chatInput"
                            onChange={handleInput}
                            height={chatInputHeight}
                            minH='unset'
                            maxH='100px'
                            borderRadius="1rem"
                            overflowY='auto'
                            border='none'
                            resize='none'
                            _focus={{ boxShadow: "none", outline: "none" }}
                            placeholder='Type a message...'
                          />
                          {/* <Input onInput={handleInputChange} onKeyDown={handlemessageKeyPress} ref={messageRef} p="0.5rem" bg="transparent" overflow="hidden auto" lineHeight="normal" placeholder="Message" resize="none" as="textarea" border="none" borderRadius="1rem" overflowWrap="break-word" whiteSpace="pre-wrap" w="100%" _focus={{ boxShadow: "none", outline: "none" }} /> */}
                          <Box as="button" onClick={sendmessage} bg="#163E9E" w="2rem" h="2rem" borderRadius="full" p="10px" m="5px 10px 5px 5px" userSelect="none">
                            <Image src="/assets/svgs/send-button.svg" />
                          </Box>
                        </Box>
                    {/* <InputGroup>
                      <TextArea
                        pr="3rem"
                        bg="#FCFCFD"
                        h="48px"
                        p="12px 18px"
                        w="100%"
                        placeholder="Message"
                        borderRadius="full"
                        sx={{
                          _hover: { border: "1px solid #E6E7E9" },
                          _focus: { border: "1px solid #E6E7E9" },
                        }}
                      />
                      <InputRightElement width="3rem" pr="1rem" h="100%">
                        <Box>
                          <Image src="/assets/svgs/voice-icon.svg" />
                        </Box>
                      </InputRightElement>
                    </InputGroup> */}
                  </Box>
                </Flex>
                {/* <Flex h="100%" justifyContent="center" alignItems="center" fontSize="14px" fontWeight="500" color="#898D97">
          Chat closed
        </Flex> */}
              </Box>
            </>
          )}
          
      </Box>
    )}
      <ModalRight
        ref={docProfile}
        isBg={true}
        modalWidth="480px"
        customHeader={false}
        bodyStyle={{ p: "0", overflow: "hidden" }}
        footerStyle={{borderTop: "1px solid #E6E7E9"}}
        body={
          <>
            <Box bg="#061027" p="24px" pb="0">
              <Box display="flex" alignItems="center" gap="10px">
                <Box>
                  <Image
                    w="80px"
                    h="80px"
                    borderRadius="full"
                    src={ChatMedia && ChatMedia.doctorimage? ChatMedia.imagepath+ChatMedia.doctorimage:"/assets/imgs/no-image.png"}
                  />
                </Box>
                <Box display="flex" flexDir="column" maxWidth="80%">
                  <Text fontSize="12px" fontWeight="500" color="#E6E7E9"   textOverflow="ellipsis" overflowY="hidden" whiteSpace="nowrap" title={ChatMedia?.specality}>
                  {ChatMedia && ChatMedia.specality? ChatMedia.specality:"-"}
                  </Text>
                  <Text fontSize="20px" fontWeight="600" color="white" >
                  {ChatMedia && ChatMedia.doctorname? ChatMedia.doctorname:"-"}
                  </Text>
                  <Text fontSize="14px" fontWeight="400" color="#E6E7E9"  textOverflow="ellipsis" overflowY="hidden" whiteSpace="nowrap" title={ChatMedia?.qualification}>
                  {ChatMedia && ChatMedia.qualification? ChatMedia.qualification:"-"}
                  </Text>
                </Box>
              </Box>
              <Box
                bg="#0B1F4F80"
                my="15px"
                borderRadius="full"
                p="8px 16px"
                display="flex"
                justifyContent="space-around"
              >
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Image w="18px" src="/assets/imgs/stars-doc.png" mr="5px" />
                  <Text color="white" fontSize="14px" fontWeight="500">
                  {ChatMedia && ChatMedia.ratings? ChatMedia.ratings:"-"}
                  </Text>
                </Box>
                <Box as="span" border="1px solid #0B1F4F"></Box>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Image w="18px" src="/assets/imgs/payments.png" mr="5px" />
                  <Text color="white" fontSize="14px" fontWeight="500">
                  {ChatMedia && ChatMedia.fee? "$"+ChatMedia.fee:"-"}
                  </Text>
                </Box>
                <Box as="span" border="1px solid #0B1F4F"></Box>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Image w="18px" src="/assets/imgs/work.png" mr="5px" />
                  <Text color="white" fontSize="14px" fontWeight="500">
                  {ChatMedia && ChatMedia.yearsofexperience? ChatMedia.yearsofexperience+" +yrs":"-"}
                  </Text>
                </Box>
              </Box>
              {/* Tab buttons start */}
              <Box display='flex' gap="1rem">
                <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "appointments" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" py="1rem" onClick={() => setActiveTab('appointments')} color={activeTab == "appointments" ? "white" : "#A5A5A6"}><Text>Appointments</Text><Box as="span" className="whiteBorder"></Box></Box>
                <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "about" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" py="1rem" onClick={() => setActiveTab('about')} color={activeTab == "about" ? "white" : "#A5A5A6"}><Text>About</Text><Box as="span" className="whiteBorder"></Box></Box>
                <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "images" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" py="1rem" onClick={() => setActiveTab('images')} color={activeTab == "images" ? "white" : "#A5A5A6"}><Text>Images</Text><Box as="span" className="whiteBorder"></Box></Box>
                <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "voice" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" py="1rem" onClick={() => setActiveTab('voice')} color={activeTab == "voice" ? "white" : "#A5A5A6"}><Text>Voice</Text><Box as="span" className="whiteBorder"></Box></Box>
                <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "links" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" py="1rem" onClick={() => setActiveTab('links')} color={activeTab == "links" ? "white" : "#A5A5A6"}><Text>Links</Text><Box as="span" className="whiteBorder"></Box></Box>
                <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "documents" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" py="1rem" onClick={() => setActiveTab('documents')} color={activeTab == "documents" ? "white" : "#A5A5A6"}><Text>Documents</Text><Box as="span" className="whiteBorder"></Box></Box>
              </Box>
              {/* Tabs button end */}
            </Box>
            <Box bg="#fbfbfc" overflow="hidden auto" h="calc(100% - 220px)">
              <VStack>
                <Flex flexDir="column" w="100%">
                {activeTab === "appointments" && (
                  <>
                    {ChatMedia && ChatMedia.appointment.length > 0 ? (
                      <Box w="100%" p="1.5rem 2rem">
                        <Box display="flex" flexDir="column" gap="1rem">
                          {ChatMedia.appointment.map((appointment, index) => (
                            <DoctorCard 
                              key={index} // Ensure each card has a unique key
                              cardFooterText="View details"
                              onClick={()=> navigate("/appointmentdetails", { state: { appointmentid: appointment.appointmentid}})}
                              cardBody={
                                <>
                                  <Box>
                                    <Box display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                                      <Box>
                                        <Text fontSize="0.625rem" fontWeight="600" color="#384052">{appointment.appointmenttype_cd === 693? "Clinic Visit": "Video Visit"}</Text>
                                        <Text fontSize="1rem" fontWeight="600" color="#061027">{formatDate(appointment.appointmentdate)}</Text>
                                      </Box>
                                      <Text fontSize="0.625rem" fontWeight="600" color={appointment.isshowedup === 1? "#FC8935" : "#384052"} borderColor={appointment.isshowedup === 1? "#FC89351A" : "#f5f5f6"} border="1px solid #FC89351A" p="0.5rem" borderRadius="full" bg={appointment.isshowedup === 1? "#fff3eb" : "#f5f5f6"}>{appointment.isshowedup === 1? "Upcoming":appointment.isshowedup === 2?"Cancelled":appointment.isshowedup === 3? "Completed": appointment.isshowedup === 0 ? "Pending":appointment.isshowedup === 4 ? "No show":""}</Text>
                                    </Box>
                                    <Text mt="0.5rem" fontSize="0.875rem" fontWeight="500" color="#384052">{appointment.appointmenfor}</Text>
                                  </Box>
                                </>
                              }
                            />
                          ))}
                        </Box>
                      </Box>
                    ) : (
                      <Box display="flex" flexDir="column" justifyContent="center" alignItems="center" gap="1rem" w="100%" overflow="hidden" h="calc(100vh - 375px)">
                        <Image src="/assets/svgs/videocam-no-data.svg" />
                        <Text fontSize="1rem" fontWeight="500" color="#6A707D">No appointments</Text>
                      </Box>
                    )}
                  </>
                )}
                    {activeTab == "about" && (
                      <Box w="100%" p="1.5rem 2rem">
                        <Flex flexDir="column" gap="1rem">
                          <GrayCard 
                             cardHeader="QUALIFICATION"
                             cardHeaderIcon="/assets/svgs/qualification.svg"
                             cardBody={
                              <>
                               {ChatMedia && ChatMedia.aboutdoctor && ChatMedia.aboutdoctor.qualifications && ChatMedia.aboutdoctor.qualifications.length > 0 ? (
                                  ChatMedia.aboutdoctor.qualifications.map((step, index) => (
                                    <Text key={index} fontSize="0.875rem" fontWeight="600">
                                    {step.qualification}
                                    </Text>
                                  ))
                                ) : (
                                  <Box>No data</Box>
                                )}
                               
                               
                              </>
                            }
                             hasFooter={false}
                          />

                          <GrayCard 
                             cardHeader="services & procedures"
                             cardHeaderIcon="/assets/svgs/vaccines-and-dose.svg"
                             bodyStyle={{py: "0"}}
                            cardBody = {
                            <>
                              <Box w="100%" display='flex'>
                                <Box w="100%">
                                  {ChatMedia && ChatMedia.aboutdoctor && ChatMedia.aboutdoctor.services && ChatMedia.aboutdoctor.services.length > 0 ? (
                                    ChatMedia.aboutdoctor.services.map((step, index) => (
                                      <Box
                                        key={index}
                                        display="flex"
                                        flexDirection="column"
                                        flexWrap="wrap"
                                        maxHeight="7.5rem"
                                      >
                                        <Box
                                          display="flex"
                                          gap="0.75rem"
                                          alignItems="center"
                                          py="0.75rem"
                                          borderBottom="1px solid #E6E7E9"
                                        >
                                          <Image opacity="0.75" src="/assets/imgs/check-black.png" />
                                          <Text fontSize="0.875rem" fontWeight="600">
                                            {step.servicename}
                                          </Text>
                                        </Box>
                                      </Box>
                                    ))
                                  ) : (
                                    <Box>No data</Box>
                                  )}
                                </Box>
                                
                              </Box>
                            </>
                          }
                             hasFooter={false}
                          />

                          <GrayCard 
                             cardHeader="Hospital details"
                             cardHeaderIcon="/assets/svgs/hospital-gray.svg"
                             cardBody = {
                              <>
                                {ChatMedia && ChatMedia.aboutdoctor && ChatMedia.aboutdoctor.addresses && ChatMedia.aboutdoctor.addresses.length > 0 ? (
                                  ChatMedia.aboutdoctor.addresses.map((step, index) => (
                                    <React.Fragment key={index}>
                                      <Box display="flex" justifyContent="space-between">
                                        <Box>
                                          <Text fontSize="0.875rem" fontWeight="600">
                                            {step.hospitalname? step.hospitalname:"-"}
                                          </Text>
                                          <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                                            {step.address? step.address+", "+step.city+", "+step.country:"-"}
                                            
                                          </Text>
                                        </Box>
                            
                                        <Box display="flex" justifyContent="space-between" gap="0.5rem">
                                          <Box
                                            as="button"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            p="0.5rem"
                                            boxSize="2.25rem"
                                            border="1px solid #E6E7E9"
                                            borderRadius="full"
                                          >
                                            <Image src="/assets/svgs/call-gray.svg" />
                                          </Box>
                                          <Box
                                            as="button"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            p="0.5rem"
                                            boxSize="2.25rem"
                                            border="1px solid #E6E7E9"
                                            borderRadius="full"
                                            onClick={(e)=>navigatemap(step.address1, step.address2, step.city, step.country)}
                                          >
                                            <Image src="/assets/svgs/location-gray.svg" />
                                          </Box>
                                        </Box>
                                      </Box>
                                      <Divider my="0.75rem" />
                                      <Box display="flex" gap="0.5rem">
                                        <Image src="/assets/svgs/telephone-blue.svg" />
                                        <Text fontSize="0.75rem" fontWeight="500">
                                          {step.mobile?step.mobile:"-"}
                                        </Text>
                                      </Box>
                                      <Box display="flex" gap="0.5rem">
                                        <Image src="/assets/svgs/website-blue.svg" />
                                        <a>
                                        <Link href={ChatMedia.websiteurl && ChatMedia.uniqueid ? ChatMedia.websiteurl + ChatMedia.uniqueid :"-"}
                                     isExternal  fontSize="0.75rem" fontWeight="500">
                                          {ChatMedia.websiteurl && ChatMedia.uniqueid ? ChatMedia.websiteurl + ChatMedia.uniqueid :"-"}
                                        </Link>
                                        </a>
                                      </Box>
                                    </React.Fragment>
                                  ))
                                ) : (
                                  <Box>No data</Box>
                                )}
                              </>
                              }
                             hasFooter={false}
                          />
                          <Text fontSize="0.75rem" fontWeight="600" color="#6A707D"><strong>Refund Policy:</strong>  In the rare event of dissatisfaction with a consultation, please contact our support team within 48 hours for a refund.</Text>
                        </Flex>
                      </Box>
                    )}
                    {activeTab === "images" && (
                        <>
                            {ChatMedia && ChatMedia.IMAGE.length > 0 ? (
                                <>
                                    {ChatMedia.IMAGE.map((img, index) => (
                                        <Box key={index}>
                                            <Box p="1.5rem" w="100%">
                                                <Text textTransform="uppercase" fontSize="0.75rem" fontWeight="600">{formatDate(img.createddate)}</Text>
                                            </Box>
                                            <Box display="flex" flexWrap="wrap" w="100%" h="100%">
                                                    <Box flex="1 0 33.333%" border="1px solid white" height="33.333%">
                                                        <Image src={img.chat? img.imagepath+img.chat:""} objectFit="cover" 
                                                        width="33%" 
                                                        height="33%" 
                                                        onClick={() => {window.open(img.imagepath + img.chat, '_blank')}}/>
                                                    </Box>
                                            </Box>
                                        </Box>
                                    ))}
                                </>
                            ) : (
                                <Box display="flex" flexDir="column" justifyContent="center" alignItems="center" gap="1.25rem" h="calc(100vh - 160px)">
                                    <Image src="/assets/svgs/no-images.svg" />
                                    <Text fontSize="1rem" fontWeight="500" color="#6A707D">No images</Text>
                                </Box>
                            )}
                        </>
                    )}
                    {activeTab == "voice" && (
                        <>
                        {/* <Box p="1.5rem" w="100%">
                            <Text textTransform="uppercase" fontSize="0.75rem" fontWeight="600">jan 2024</Text>
                        </Box>
                        <Box display="flex" flexDir="column" gap="0.5rem" w="100%" px="1.5rem">
                            <Box display="flex" gap="0.65rem" w="100%" border="1px solid #E6E7E9" borderRadius="0.75rem" p="1rem" bg="white">
                                <Box display="flex" justifyContent="center" alignItems="center" bg="#0B0B0D" borderRadius="full" w="2.5rem" h="2.5rem">
                                    <Image src="/assets/svgs/play.svg" />
                                </Box>
                                <Box>
                                    <Text fontSize="0.875rem" fontWeight="600">You</Text>
                                    <Text fontSize="0.75rem" fontWeight="400" color="#384052">0:19  •  Jan 1, 2024 at 12:40 PM</Text>
                                </Box>
                            </Box>
                            
                            <Box display="flex" flexDir="column" gap="0.65rem" w="100%" border="1px solid #E6E7E9" borderRadius="0.75rem" p="1rem" bg="white">
                                <Box display="flex" gap="0.65rem" w="100%">
                                    <Box display="flex" justifyContent="center" alignItems="center" bg="#0B0B0D" borderRadius="full" w="2.5rem" h="2.5rem">
                                        <Image src="/assets/svgs/pause.svg" />
                                    </Box>
                                    <Box>
                                        <Text fontSize="0.875rem" fontWeight="600">You</Text>
                                        <Text fontSize="0.75rem" fontWeight="400" color="#384052">0:19  •  Jan 1, 2024 at 12:40 PM</Text>
                                    </Box>
                                </Box>
                                <Box h="4px" mt="5px" w="100%" borderRadius="full" bg="#E6E7E9">
                                    <Box bg="#121316" w="35%" h="100%" borderRadius="full"></Box>
                                </Box>
                            </Box>
                        </Box> */}
                        <Box display="flex" flexDir="column" justifyContent="center" alignItems="center" gap="1.25rem" h="calc(100vh - 160px)">
                            <Image src="/assets/svgs/no-voice.svg" />
                            <Text fontSize="1rem" fontWeight="500" color="#6A707D">No voice notes</Text>
                        </Box>
                        </>
                    )}
                    {activeTab == "links" && (
                        <>
                        {/* <Box p="1.5rem" w="100%">
                            <Text textTransform="uppercase" fontSize="0.75rem" fontWeight="600">jan 2024</Text>
                        </Box>
                        <Box display="flex" flexDir="column" gap="0.5rem" w="100%" px="1.5rem">
                            <Box display="flex" gap="0.65rem" w="100%" border="1px solid #E6E7E9" borderRadius="0.75rem" p="1rem" bg="white">
                                <Box display="flex" justifyContent="center" alignItems="center" borderRadius="0.75rem" w="2.5rem" h="2.5rem">
                                    <Image src="/assets/svgs/supa.svg" />
                                </Box>
                                <Box>
                                    <Text fontSize="0.875rem" fontWeight="600">You</Text>
                                    <Text fontSize="0.75rem" fontWeight="400" color="#384052">https://www.google.com</Text>
                                </Box>
                            </Box>
                        </Box> */}

                         <Box display="flex" flexDir="column" justifyContent="center" alignItems="center" gap="1.25rem" h="calc(100vh - 160px)">
                            <Image src="/assets/svgs/no-link.svg" />
                            <Text fontSize="1rem" fontWeight="500" color="#6A707D">No links</Text>
                        </Box>
                        </>
                    )}
                    {activeTab == "documents" && (
                         <>
                         {ChatMedia && ChatMedia.document.length > 0 ? (
                             <>
                                 {ChatMedia.document.map((img, index) => (
                                  <>
                                    <Box p="1.5rem" w="100%" key={index}>
                                        <Text textTransform="uppercase" fontSize="0.75rem" fontWeight="600">{formatDate(img.createddate)}</Text>
                                    </Box>
                                    <Box display="flex" flexDir="column" gap="0.5rem" w="100%" px="1.5rem">
                                        <Box display="flex" w="100%" justifyContent="space-between" alignItems="center" border="1px solid #E6E7E9" borderRadius="0.75rem" p="1rem" bg="white" boxShadow="0px 8px 20px 0px #E6E7E966">
                                            <Box display="flex" w="100%" gap="0.65rem"  onClick={() => {window.open(img.imagepath + img.chat, '_blank')}}>
                                                <Box display="flex" justifyContent="center" alignItems="center" objectFit="contain" borderRadius="0.75rem" w="2.5rem" h="2.5rem">
                                                    <Image w="2.5rem" h="2.5rem" src="/assets/svgs/pdf-icon.svg" borderRadius="0.75rem" />
                                                </Box>
                                                <Box>
                                                    <Text fontSize="0.875rem" fontWeight="600">{img.chattype+".pdf"}</Text>
                                                    {/* <Text fontSize="0.75rem" fontWeight="400" color="#384052">24MB  •  Jan 1, 2024 at 12:40 PM</Text> */}
                                                </Box>
                                            </Box>

                                            <Box as="button" display="flex" justifyContent="center" alignItems="center" border="1px solid #E6E7E9" w="2.25rem" h="2.25rem" borderRadius="full">
                                                <Image src="/assets/imgs/download.png" onClick={()=>downloadDocument(img.imagepath + img.chat, "Document.pdf")}/>
                                            </Box>
                                        </Box>
                                    </Box>
                                    </>
                                ))}
                                </>
                                ) : (
                                      <Box display="flex" flexDir="column" justifyContent="center" alignItems="center" gap="1.25rem" h="calc(100vh - 160px)">
                                        <Image src="/assets/svgs/no-documents.svg" />
                                        <Text fontSize="1rem" fontWeight="500" color="#6A707D">No documents</Text>
                                    </Box> 
                                    )}
                                  </>
                    )}
                </Flex>

              </VStack>
            </Box>
          </>
        }
        hasFooter={activeTab == "appointments" ? true : false}
        footerActions={<>
        {activeTab == "appointments" && (
          <PrimaryButton buttonText="Book appointment" 
          onClick={()=> navigate("/doctorprofile", { state: { doctorid: doctorid, fullname: doctorname }})}/>
          
        )}
        </>}
      />
    </>
  );
};

export default ChatIndex;
