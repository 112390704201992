import { Box, Button, Flex, Image, SimpleGrid, Spacer, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import Loader from "../../components/loader/loader";
import { PrimaryButton, Toaster } from "../../components";
import ProfileSideBar from "./profileSidebar";
import useLoader from "../../components/loader/loaderstates";
import Footer from "../../components/footer"
import GrayCard from "../../components/card";
import { FetchInvoices } from "../../middleware/services/profile/invoices";
import ModalRight from "../../components/modal-right";
import { downloadDocument, formatDate_Do_MMM_YYYY } from "../../auth";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import MobileNavbar from "../../components/mobile-navbar";
import { useNavigate } from "react-router-dom";

const InvoicesIndex = () => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const detailsmodel = React.useRef();
  const navigate = useNavigate()
  
  useEffect(() => {
    showLoader();
    var input = {};
    var fetchData = async () => {
      try {
        var result = await FetchInvoices(input)
        if(result){
          setData(result)
        }else if (result && result.data && result.data == ""){
          setData("")
        }else{
          setData("")
          showErrorToast("Something went wrong!")
        }

      } catch (error) {
        console.log(error, "error in fetching account data")
      }
      dismissLoader();
    }

    fetchData();

  }, []);

  const openDetailsModal = (item)=>{
    setName(item.invoicename)
    setDate(item.invoicedate)
    setInvoiceUrl(item.invoiceurl)
    detailsmodel.current.openRight();
  }

  return (
    <>
      <Box w="100%" display={{base: "none", lg: "block"}}>
        <Box top="0" zIndex="banner">
          <Navbar />

          {isLoading && <Loader />}
          <SecondaryNav
            sideBarWidth="250px"
            defaultHeader="Profile"
            defaultSecondayHeader="Invoices"
            
          />
        </Box>
        <Box h="calc(100vh - 140px)" overflow="hidden auto">
          <Flex>
            <Box p="0" width="250px">
              <ProfileSideBar />
            </Box>

            <Flex bg="#fcfcfd" h="100%" flexWrap="wrap" gap="16px" w="100%" p="24px 28px">
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <Flex key={index} w="calc(33.333% - 10.67px)" p="0">
                  <GrayCard
                    cardMinHeight="100px"
                    hasHeader={false}
                    cardBody={
                      <>
                        <Flex justifyContent="space-between">
                          <Box>
                            <Text fontSize="14px" fontWeight="600">{item.paymenttype}</Text>
                            <Text fontSize="12px" fontWeight="500" color="#384052">{formatDate_Do_MMM_YYYY(item.createddate)}</Text>
                          </Box>
                          <Box>
                            {/* <a href={item.invoiceurl} target="_blank" rel="noopener noreferrer"> */}
                              <Box as="button" boxSize="36px" borderRadius='full' display="flex" justifyContent="center" alignItems="center" border="1px solid #E6E7E9"
                                    onClick={()=>downloadDocument(item.imagepath + item.invoiceurl, "Invoice")}
                                    cursor="pointer"
                                    >
                                <Image src="/assets/imgs/download.png" />
                              </Box>
                            {/* </a> */}
                          </Box>
                        </Flex>
                        <Text mt="10px" fontSize="14px" fontWeight="600">{`${item.currencyunit ? item.currencyunit : "$"} ${item.amount}`}</Text>
                      </>
                    }
                    hasFooter={false}
                    // cardFooterText="View details"
                    // hasFooter={
                    //           <>
                    //           footer</>
                    // }
                    // footerOnClick={() => openDetailsModal(item)}
                  />
                </Flex>
              ))
            ) : (
              <Box
                w="100%"
                h="100vh"
                display="flex"
                flexDirection="column"
                gap="5px"
                justifyContent="center"
                alignItems="center"
              >
                <Image w="60px" opacity="0.5" src="/assets/svgs/request_quote.svg" />
                <Box as="p">No data available</Box>
                </Box>
            )}
          </Flex>

          </Flex>
        <Footer />
        </Box>
        <ModalRight
          ref={detailsmodel}
          secondaryHeader={name ? name : '-'}
          subHeader={date ? date : '-'}
          body={
            <Flex pt="15px" flexDirection="column" h="100%">
                <Box mb="2">
                  <Button
                    as="a"
                    rel="noopener noreferrer"
                    w="100%"
                    h="48px"
                    borderRadius="10px"
                    border="1px solid #E6E7E9"
                    display="flex"
                    justifyContent="space-between"
                    bg="white"
                    color="#1C4EC5"
                    sx={{ _hover: { bg: "none" } }}
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontSize: "12px",
                    }}
                    onClick={()=>downloadDocument(invoiceUrl)}
                    cursor="pointer"
                  >
                    Document 
                    <Box as="span">
                      <Image src="/assets/imgs/download.png" />
                    </Box>
                  </Button>
                </Box>
              <Spacer />
             
            </Flex>
          }
        />
      </Box>

      {/* Mobile responsive start */}
      <Box w="100%" bg="#fcfcfd" display={{base: "block", lg: "none"}}>
      {isLoading && <Loader />}
        <MobileNavbar/>
        <MobileSecondaryNav 
          handlebackarrow={()=> navigate('/profile')}
          header="Invoices"
        />

        <Flex bg="#fcfcfd" flexDir="column" p="1.5rem" h="calc(100vh - 200px)" overflow="hidden auto">
          <SimpleGrid columns={1} gap="1rem">
          {data && data.length > 0 ? (
            data.map((item, index) => (
                <GrayCard
                  key={index}
                  cardMinHeight="100px"
                  hasHeader={false}
                  cardBody={
                    <>
                      <Flex justifyContent="space-between">
                        <Box>
                          <Text fontSize="14px" fontWeight="600">{item.paymenttype}</Text>
                          <Text fontSize="12px" fontWeight="500" color="#384052">{formatDate_Do_MMM_YYYY(item.createddate)}</Text>
                        </Box>
                        <Box>
                          {/* <a href={item.invoiceurl} target="_blank" rel="noopener noreferrer"> */}
                            <Box as="button" boxSize="36px" borderRadius='full' display="flex" justifyContent="center" alignItems="center" border="1px solid #E6E7E9"
                                  onClick={()=>downloadDocument(item.imagepath + item.invoiceurl, "Invoice")}
                                  cursor="pointer"
                                  >
                              <Image src="/assets/imgs/download.png" />
                            </Box>
                          {/* </a> */}
                        </Box>
                      </Flex>
                      <Text mt="10px" fontSize="14px" fontWeight="600">{`${item.currencyunit ? item.currencyunit : "$"} ${item.amount}`}</Text>
                    </>
                  }
                  hasFooter={false}
                />
            ))
          ) : (
            <Box
              w="100%"
              h="100vh"
              display="flex"
              flexDirection="column"
              gap="5px"
              justifyContent="center"
              alignItems="center"
            >
              <Image w="60px" opacity="0.5" src="/assets/svgs/request_quote.svg" />
              <Box as="p">No data available</Box>
            </Box>
          )}
          </SimpleGrid>
        </Flex>
        </Box>
    </>
  );
};

export default InvoicesIndex;
