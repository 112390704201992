import formAPIreportData from "./reportdata";



var formattedOutput = null;

const formAPIModelreportsData = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIreportData(item));


  return formattedOutput
};

export default formAPIModelreportsData