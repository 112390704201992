import React from 'react';
import { tabContainer, tabBtn } from './styles'
import { Tabs, TabList, Tab } from '@chakra-ui/react';

function FilterButton({ tabs, onClick }) {
  return (
    <Tabs variant="soft-rounded">
      <TabList sx={tabContainer}>
        {tabs.map((tabLabel, index) => (
          <Tab key={index} onClick={() => onClick(tabLabel)} sx={tabBtn}>
            {tabLabel}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
}

export default FilterButton;
