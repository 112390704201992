import moment from "moment";
import formAPIreaction from "./reactions";

export default function formAPIAllergies(dataArray) {
  // Assuming dataArray is an array of objects
  return dataArray.map(({ 
    allergyidentifieddate,
    allergytype, 
    allergytype_cd,
    documentcount,
    documents,
    patientallergyid,
    reaction,


    }) => {
    return Object.freeze({
   // allergyidentifieddate : moment(allergyidentifieddate).format("Do MMM YYYY"),
    allergyidentifieddate,
    allergytype, 
    allergytype_cd,
    documentcount,
    documents,
    patientallergyid,
    reaction : reaction !== null ? formAPIreaction(reaction):[],
    });
  });
}
